// @flow
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'

type SelectSenderIdentityAction = {
  type: 'SELECT_SENDER_IDENTITY',
  payload: SenderIdentityRecord,
  ...
}

type UpdateSelectedSenderIdentityAction = {
  type: 'UPDATE_SELECTED_SENDER_IDENTITY',
  payload: SenderIdentityRecord,
  ...
}

type CancelCreatingAndSetPreviousAction = {
  type: 'CANCEL_CREATING_AND_SELECT_PREVIOUS',
  payload: void,
  ...
}
type CancelCreatingAndSetNewAction = {
  type: 'CANCEL_CREATING_AND_SELECT_NEW',
  payload: SenderIdentityRecord,
  ...
}

type InitCreatingSenderIdentityAction = {
  type: 'INIT_CREATING_SENDER_IDENTITY',
  ...
}

export type SelectSenderIdentitiesActions =
  | SelectSenderIdentityAction
  | UpdateSelectedSenderIdentityAction
  | InitCreatingSenderIdentityAction
  | CancelCreatingAndSetPreviousAction
  | CancelCreatingAndSetNewAction

export const selectSenderIdentity = (
  senderIdentity: SenderIdentityRecord
): DispatchExtraBoundFn<Promise<SenderIdentityRecord>> => {
  return dispatch => {
    return dispatch({ type: 'SELECT_SENDER_IDENTITY', payload: senderIdentity })
  }
}

export const updateSelectedSenderIdentity = (
  senderIdentity: SenderIdentityRecord
): DispatchExtraBoundFn<Promise<SenderIdentityRecord>> => {
  return dispatch => {
    return dispatch({ type: 'UPDATE_SELECTED_SENDER_IDENTITY', payload: senderIdentity })
  }
}
export const initCreatingSenderIdentity = (): DispatchExtraBoundFn<Promise<void>> => {
  return dispatch => {
    return dispatch({ type: 'INIT_CREATING_SENDER_IDENTITY', payload: null })
  }
}

export const cancelCreatingAndSetPrevious = (): DispatchExtraBoundFn<Promise<void>> => {
  return dispatch => {
    return dispatch({ type: 'CANCEL_CREATING_AND_SELECT_PREVIOUS', payload: null })
  }
}

export const cancelCreatingAndSetNew = (
  senderIdentity: SenderIdentityRecord
): DispatchExtraBoundFn<Promise<void>> => {
  return dispatch => {
    return dispatch({ type: 'CANCEL_CREATING_AND_SELECT_NEW', payload: senderIdentity })
  }
}
