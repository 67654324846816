// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { Checkbox } from 'components/form/index'
import { colors, schemes } from 'components/styled/tokens'

import { ChannelLabelContainer, ChannelText } from './styles'

const CHANNEL_COLORS = {
  ios: ['#777777', '#6B6B6B'],
  android: ['#79ad45', '#6D9D39'],
  webpush: ['#41a0d2', '#2E96CC'],
}

const CHANNEL_NAMES = {
  ios: 'iOS',
  android: 'Android',
  webpush: 'Web',
}

const CHANNEL_ICONS = {
  webpush: 'webpush-fill',
}

type ChannelCheckboxProps = {
  channel: 'ios' | 'android' | 'webpush',
  checked: boolean,
  onChange: ('ios' | 'android' | 'webpush') => void,
  isDisabled: boolean,
  ...
}

export const ChannelCheckbox: React.ComponentType<ChannelCheckboxProps> = ({
  channel,
  checked,
  onChange,
  isDisabled,
}: ChannelCheckboxProps) => {
  const onChangeCallback = React.useCallback(() => onChange(channel), [channel, onChange])

  const channelIcon = React.useMemo(() => {
    // $FlowExpectedError en effet tous les channels ne sont pas dans l'objet, c'est voulu et handle
    return CHANNEL_ICONS[channel] || channel
  }, [channel])

  const channelLabelId = React.useId()

  return (
    <ChannelLabelContainer
      isDisabled={isDisabled}
      checked={checked}
      channel={CHANNEL_NAMES[channel]}
      channelColor={CHANNEL_COLORS[channel]}
    >
      <Checkbox
        disabled={isDisabled}
        checked={checked}
        onChange={onChangeCallback}
        style={{ alignSelf: 'start' }}
        ariaLabelledBy={channelLabelId}
      />
      <Icon
        icon={channelIcon}
        size={28}
        color={checked ? schemes.lightlucent['100'] : colors.textDisabled}
        thickness={3}
      />
      <ChannelText id={channelLabelId}>{CHANNEL_NAMES[channel]}</ChannelText>
    </ChannelLabelContainer>
  )
}
