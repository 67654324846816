// @flow

import styled, { type StyledComponent } from 'styled-components'

import {
  opacifyDanger,
  opacifySuccess,
  stroke,
  strokeInput,
  text,
  textDanger,
  textLight,
  textSuccess,
} from 'components/styled/tokens/colors'
import * as shadows from 'components/styled/tokens/shadows'

export const InfoPanelHeader: StyledComponent<{ ... }, { ... }, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0 14px;

  h3 {
    flex: 1 1 auto;
    font-weight: 500;
    color: ${text};
  }
`

export const InfoPanelContent: StyledComponent<{ ... }, { ... }, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  border-top: 1px solid ${stroke};

  button {
    justify-content: start;
    padding-right: 22px;
    padding-left: 12px;

    .styled-button-prefix {
      margin-right: 8px;
    }
  }
  button + button {
    margin-top: 4px;
  }
`

const COLORS = {
  default: [text, strokeInput],
  success: [textSuccess, opacifySuccess],
  error: [textDanger, opacifyDanger],
}
type InfoPanelCounterContainerProps = { $state: 'default' | 'success' | 'error', ... }
export const InfoPanelCounterContainer: StyledComponent<
  InfoPanelCounterContainerProps,
  *,
  typeof InfoPanelContent,
> = styled(InfoPanelContent)`
  position: relative;
  padding: 14px;
  font-weight: 500;

  .section-icon {
    display: block;
    position: absolute;
    top: 11px;
    right: 15px;
    width: 22px;
    height: 22px;
    padding: 2px 0 0 0;
    font-size: 12px;
    text-align: center;
    color: ${(p: InfoPanelCounterContainerProps) => COLORS[p.$state][0]};
    background-color: ${(p: InfoPanelCounterContainerProps) => COLORS[p.$state][1]};
    border-radius: 4px;
  }

  .styled-counter {
    color: ${(p: InfoPanelCounterContainerProps) => COLORS[p.$state][0]};
  }
  .styled-hint {
    margin-top: 4px;
    color: ${textLight};
    font-weight: 400;
  }
`

export const InfoPanelContainer: StyledComponent<{ ... }, { ... }, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: white;
  border: 1px solid ${stroke};
  border-radius: 8px;
  box-shadow: ${shadows.block};
`
