// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'components/common/svg-icon'

import {
  AndroidContainer,
  AndroidAppName,
  AndroidIcon,
  AndroidTitle,
  AndroidDateLabel,
  AndroidMessageContainer,
  AndroidBody,
  Thumb,
  ChromeIconContainer,
} from './android.styles'

import {
  LegacyEditableField,
  LegacyMediaField,
  type ThemeProps,
} from 'com.batch/message-builder/ui/components/common'

export const Android = ({ variant }: { variant: 'a' | 'b', ... }): React.Node => {
  const { previewPlatform, isExpanded, app } = useTheme<ThemeProps>()
  return (
    <AndroidContainer>
      {previewPlatform === 'webAndroid' ? (
        <ChromeIconContainer>
          <img src="/medias/icons/chrome-colored.svg" alt="Google Chrome icon" />
        </ChromeIconContainer>
      ) : (
        <AndroidIcon />
      )}

      <AndroidAppName>
        {previewPlatform === 'webAndroid' ? 'Google Chrome' : app?.name}
      </AndroidAppName>
      <AndroidDateLabel>Now</AndroidDateLabel>

      <Icon icon="chevron-up" />

      <AndroidMessageContainer>
        <AndroidTitle>
          <LegacyEditableField field="title" variant={variant} />
        </AndroidTitle>
        <AndroidBody>
          <LegacyEditableField field="message" variant={variant} />
        </AndroidBody>
      </AndroidMessageContainer>
      {/* Si on a un icon, on l'affiche en mode collapsed */}
      <LegacyMediaField variant={variant} field="icon">
        {iconProps => (
          <LegacyMediaField variant={variant} field="media">
            {({ url, overlay }) => (
              <Thumb $url={!isExpanded && iconProps.url ? iconProps.url : url}>
                {!isExpanded && iconProps.url ? iconProps.overlay : overlay}
              </Thumb>
            )}
          </LegacyMediaField>
        )}
      </LegacyMediaField>
    </AndroidContainer>
  )
}
