// @flow

import * as React from 'react'
import { Route, Routes } from 'react-router-dom'

type ProjectRouterWrapperProps = {
  children: React.Node,
}

export const ProjectRouterWrapper = ({ children }: ProjectRouterWrapperProps): React.Node => {
  return (
    <Routes>
      <Route path=":companyId/projects/:projectId">{children}</Route>
      <Route path=":companyId">{children}</Route>
    </Routes>
  )
}
