// @flow

import Immutable, { type Set, type List, type Map } from 'immutable'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import { isUserAllowedOnApp, userCanAdministrate } from 'components/account/team/utils'
import { Button, ButtonLink } from 'components/common/button'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import {
  FullPageContent,
  FullPageTitle,
  FullPageSubtitle,
  FullPageFooter,
} from 'components/common/full-page'
import { trackEvent } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'
import { Table, TableHeader, TableCellHeader } from 'components/common/table'
import { Checkbox, Input } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import { ScrollContainer, Empty } from './create-step-team.styles'
import { UserRow } from './step-team-user-row'

import { type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'
import { type UserRecord } from 'com.batch.redux/user.records'

import { useConfirmWithMfa } from 'com.batch/shared/ui/hooks/use-confirm-with-mfa'

type StepTeamProps = {
  company: CompanyRecord,
  app: AppRecord,
  users: List<UserRecord>,
  persistUsers: (allowedUsers: Set<number>) => Promise<Map<number, UserRecord>>,
}

const StepTeamRaw = ({ company, app, users, persistUsers }: StepTeamProps) => {
  const confirm = useConfirmWithMfa()
  const isMobile = app.platform !== 'webpush'

  // compute what users are allowed on initial load
  const initSet = React.useMemo(
    () =>
      Immutable.Set(
        users
          .filter(user => isUserAllowedOnApp({ app, company, user }))
          .map(user => Number(user.id))
      ),
    [users, app, company]
  )
  const [loading, setLoading] = React.useState(false)
  const [searchMode, setSearchMode] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [allowed, setAllowed] = React.useState<Set<number>>(initSet)
  const allowedCount = allowed.size

  const onUserSearchBlur = React.useCallback(() => {
    if (search) trackEvent('SEARCH_USERS', { location: 'create app', keywords: search })
  }, [search])

  // refresh init set on users load
  React.useEffect(() => {
    setAllowed(initSet)
  }, [initSet])

  const integrateUrl = generateUrl('app_settings', {
    companyId: company.id,
    appId: app.id,
    activeTab: 'push',
    popin: 'integrate',
  })
  const adminSet = users.filter(u => userCanAdministrate(u, company)).map(u => u.id || 0)
  const filteredUsers = users.filter(
    user =>
      search === '' ||
      user.firstName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  const handleOnClickInvite = React.useCallback(() => {
    setLoading(true)
    const added = allowed.subtract(initSet).filter(Boolean)
    const removed = initSet.subtract(allowed).filter(Boolean)
    confirm({
      title: 'Invite users to an app',
      message: (
        <p>
          You’re about to invite {allowed.size} user{allowed.size > 1 ? 's' : ''} to the app{' '}
          <ConfirmHighlight>{app.name}</ConfirmHighlight>
        </p>
      ),
      confirm: 'Yes, invite',
    }).then(
      () => {
        // tracking
        const location = 'new app'
        added.forEach(user_id => {
          trackEvent('APP_ADD_USER', { location, user_id, app_id: app.id })
        })
        removed.forEach(user_id => {
          trackEvent('APP_REMOVE_USER', { location, user_id, app_id: app.id })
        })
        persistUsers(allowed).then(
          () => {
            window.location.href = integrateUrl
          },
          () => setLoading(false)
        )
      },
      () => {
        setLoading(false)
      }
    )
  }, [allowed, app.id, app.name, confirm, initSet, integrateUrl, persistUsers])

  const handleOnClickSearch = React.useCallback(() => setSearchMode(true), [setSearchMode])

  const handleOnChangeCheckAll = React.useCallback(() => {
    setAllowed(
      allowedCount > adminSet.size
        ? Immutable.Set(adminSet)
        : Immutable.Set(filteredUsers.map(u => u.id || 0))
    )
  }, [allowedCount, adminSet, filteredUsers])

  const handleOnKeyPress = React.useCallback(e => e.key === 'Enter' && e.preventDefault(), [])

  const handleOnKeyDown = React.useCallback(e => {
    if (e.keyCode === 27) {
      setSearchMode(false)
      setSearch('')
    }
  }, [])

  const handleOnSearch = React.useCallback(e => setSearch(e.target.value), [setSearch])

  const renderEmpty = () => (
    <Empty>
      <h4>No results</h4>
      <p>No user matchers your criterias. Please try again with different criteria.</p>
    </Empty>
  )

  return (
    <FullPageContent style={{ width: 680 }}>
      <Helmet>
        <title>Batch - New {isMobile ? 'app' : 'website'} team access</title>
      </Helmet>
      <FullPageTitle>Invite your team</FullPageTitle>
      <FullPageSubtitle style={{ maxWidth: 480 }}>
        <strong style={{ display: 'block', marginBottom: 8 }}>
          Congratulations! Your app has been added.
        </strong>
        You can now invite team members to this app.
      </FullPageSubtitle>
      <Table template="50px 350px 1fr 99px" style={{ border: 'none', boxShadow: 'none' }}>
        <ScrollContainer>
          <TableHeader>
            {searchMode ? (
              <Input
                value={search}
                onChange={handleOnSearch}
                onKeyDown={handleOnKeyDown}
                onBlur={onUserSearchBlur}
                onKeyPress={handleOnKeyPress}
                placeholder="Search for users..."
                suffix={{
                  kind: 'icon',
                  value: 'close',
                  handler: () => {
                    setSearchMode(false)
                    setSearch('')
                  },
                }}
                autoFocus
                style={{
                  gridColumn: '1/ 5',
                  paddingRight: 14,
                  boxShadow: 'none',
                }}
              />
            ) : (
              <React.Fragment>
                <TableCellHeader align="center">
                  <Checkbox
                    checked={
                      filteredUsers.size === allowedCount ? true : allowedCount === 0 ? false : null
                    }
                    handleIndeterminate
                    onChange={handleOnChangeCheckAll}
                  />
                </TableCellHeader>
                <TableCellHeader> Users</TableCellHeader>
                <TableCellHeader>Permissions</TableCellHeader>
                <TableCellHeader align="center">
                  <Button
                    kind="inline"
                    intent="neutral"
                    type="button"
                    onClick={handleOnClickSearch}
                  >
                    <Icon icon="search" />
                  </Button>
                </TableCellHeader>
              </React.Fragment>
            )}
          </TableHeader>
          {filteredUsers.size > 0
            ? filteredUsers.map(user => (
                <UserRow
                  key={user.id}
                  allowed={allowed}
                  company={company}
                  setAllowed={setAllowed}
                  user={user}
                />
              ))
            : renderEmpty()}
        </ScrollContainer>
        <FullPageFooter style={{ paddingBottom: 0 }}>
          <ButtonLink style={{ marginRight: 20 }} kind="inline" href={integrateUrl}>
            I'll do it later
          </ButtonLink>
          <Button onClick={handleOnClickInvite} kind="primary" intent="action" isLoading={loading}>
            Invite {allowed.size} user{allowed.size > 1 && 's'}
          </Button>
        </FullPageFooter>
      </Table>
    </FullPageContent>
  )
}

export const StepTeam: React.ComponentType<StepTeamProps> = React.memo<StepTeamProps>(StepTeamRaw)
