// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { type CommonSelectProps } from 'components/form/fields/select/select.helper'

import { FilterSelectNoSearch } from './filter-select-no-search'
import { FilterSelectSearch } from './filter-select-search'

export type FilterVariant = 'default' | 'filled'

export type BaseFilterSelectProps<T> = {
  ...CommonSelectProps<T>,
  isSearchable?: boolean,
  loadOptions?: string => Promise<List<T>>,
  onChange: (?T, ?boolean) => void,
  options?: List<T>,
  placeholder?: string,
  expandable?: boolean,
  value: ?T,
  variant?: FilterVariant,
}
export type FilterSelectProps<T> = {
  ...BaseFilterSelectProps<T>,
  isSearchable?: boolean,
}

export function FilterSelect<T>({
  isSearchable = true,
  ...rest
}: FilterSelectProps<T>): React.Node {
  return isSearchable ? <FilterSelectSearch {...rest} /> : <FilterSelectNoSearch {...rest} />
}
