// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { useToggle } from 'components/_hooks'

import { PushTesterPopin } from './push-tester-popin'
import { PushTesterTemporaryPopin } from './push-tester-temporary-popin'

import { LayerBarPushTest } from '../../push/layer/layer-bar-push-test'
import { type TestDeviceRecord } from 'com.batch.redux/_records'

export const PushTester = (): React.Node => {
  const popinPushTesterState = useToggle()
  const popinPushTesterTemporaryDeviceState = useToggle()
  const [search, setSearch] = React.useState('')
  const [selectedDevices, setSelectedDevices] = React.useState<List<TestDeviceRecord>>(
    new Immutable.List()
  )

  return (
    <React.Fragment>
      <LayerBarPushTest
        openPopinDevices={popinPushTesterState.open}
        openPopinTemporaryDevice={popinPushTesterTemporaryDeviceState.open}
      />
      {popinPushTesterState.value && (
        <PushTesterPopin
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          close={popinPushTesterState.close}
          search={search}
          setSearch={setSearch}
        />
      )}

      {popinPushTesterTemporaryDeviceState.value && (
        <PushTesterTemporaryPopin close={popinPushTesterTemporaryDeviceState.close} />
      )}
    </React.Fragment>
  )
}
