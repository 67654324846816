// @flow

import Immutable, { type List, type RecordFactory, type RecordOf, Record } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type ReachabilityCounterProps = {
  subscriptionsLastMonth: number,
  unsubscriptionsLastMonth: number,
}
export const ReachabilityCounterFactory: RecordFactory<ReachabilityCounterProps> = Record(
  ({ subscriptionsLastMonth: 0, unsubscriptionsLastMonth: 0 }: ReachabilityCounterProps)
)
export type ReachabilityCounterRecord = RecordOf<ReachabilityCounterProps>

type ReachabilityTimeserieProps = {
  subscriptions: number,
  unsubscriptions: number,
  date: string,
  period: string,
}
export const ReachabilityTimeserieFactory: RecordFactory<ReachabilityTimeserieProps> = Record(
  ({
    subscriptions: 0,
    unsubscriptions: 0,
    date: '',
    period: '',
  }: ReachabilityTimeserieProps)
)
export type ReachabilityTimeserieRecord = RecordOf<ReachabilityTimeserieProps>

type ReachabilityTimeserieStateProps = {
  loadingState: fetchingState,
  counter: ReachabilityCounterRecord,
  timeserie: List<ReachabilityTimeserieRecord>,
}
export const ReachabilityTimeserieStateFactory: RecordFactory<ReachabilityTimeserieStateProps> =
  Record(
    ({
      loadingState: STATUS.INIT,
      counter: ReachabilityCounterFactory(),
      timeserie: new Immutable.List(),
    }: ReachabilityTimeserieStateProps)
  )
export type ReachabilityTimeserieStateRecord = RecordOf<ReachabilityTimeserieStateProps>

type ChannelsReachabilityStateProps = {
  email: ReachabilityTimeserieStateRecord,
  push: ReachabilityTimeserieStateRecord,
  sms: ReachabilityTimeserieStateRecord,
  webpush: ReachabilityTimeserieStateRecord,
}
export const ChannelsReachabilityStateFactory: RecordFactory<ChannelsReachabilityStateProps> =
  Record(
    ({
      email: ReachabilityTimeserieStateFactory(),
      push: ReachabilityTimeserieStateFactory(),
      sms: ReachabilityTimeserieStateFactory(),
      webpush: ReachabilityTimeserieStateFactory(),
    }: ChannelsReachabilityStateProps)
  )
export type ChannelsReachabilityStateRecord = RecordOf<ChannelsReachabilityStateProps>
