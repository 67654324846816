// @flow

import { type Dayjs } from 'dayjs'
import * as React from 'react'
import { type Node } from 'react'

import { EventFormatter } from 'components/campaign/event-formatter'
import ReviewField from 'components/campaign/review-field'
import { Box, BoxBody, BoxFooter, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import Hint from 'components/common/hint'
import Loader from 'components/common/loader-legacy'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Timezone } from 'components/styled/text'
import { schemes } from 'components/styled/tokens'

import { date, dateTime } from 'com.batch.common/date-formats'
import { dayjs } from 'com.batch.common/dayjs.custom'
import {
  computeRepeatWord,
  formatInstanceId,
  isCampaignRunning,
  capitalize,
  computeNextPushDate,
} from 'com.batch.common/utils'

import { PushDate, PushDateAbsolute, PushDateTitle } from './rsr-timing.styles'
import { FadedLabel, Item, ValueAlt } from './rsr.styles'

import { type CampaignRecord, type AppRecord } from 'com.batch.redux/_records'
import { fetchCampaignDetailAnalytics } from 'com.batch.redux/dataCampaign.api'
import { DayDataFactory } from 'com.batch.redux/dataCampaign.records'

import { DELAY_MODE } from 'constants/common'

type RSRTimingProps = {
  campaign: CampaignRecord,
  app: AppRecord,
  lastPushDate: ?Dayjs,
  ...
}

const RSRTiming = ({ campaign, app, lastPushDate }: RSRTimingProps): React.Node => {
  const isRecurring = !!campaign.repeatFrequency
  const isPush = campaign.type === 'push'
  const sendType = campaign.sendType
  const tc = campaign.triggerConfig
  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title={isPush ? 'Timing' : 'Trigger'} />
      </BoxHeader>
      <BoxBody>
        {!isPush && (
          <Item name="Event">
            <EventFormatter eventCode={campaign.trigger} eventLabel={campaign.triggerLabel} />
          </Item>
        )}
        {sendType === 'trigger' && (
          <React.Fragment>
            <Item name="Trigger event">
              <ReviewField>
                <EventFormatter eventCode={tc.enterEvent} eventQuery={tc.enterEventQuery} />
              </ReviewField>
              {tc.hasInstanceId && (
                <React.Fragment>
                  (for each different <ReviewField>{formatInstanceId(tc.instanceId)}</ReviewField>)
                </React.Fragment>
              )}
            </Item>
            <Item name="Push timing" borderTop>
              {tc.delayMode === DELAY_MODE.TIMER ? (
                <React.Fragment>
                  {tc.pushTimer.fullText}
                  {tc.pushTimerMode === 'before' ? ' before ' : ' after '}
                  {tc.pushTimerReference ? (
                    <ReviewField>{tc.pushTimerReference}</ReviewField>
                  ) : (
                    <ReviewField>
                      <EventFormatter eventCode={tc.enterEvent} eventQuery={tc.enterEventQuery} />
                    </ReviewField>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Immediately after
                  <ReviewField>
                    <EventFormatter eventCode={tc.enterEvent} eventQuery={tc.enterEventQuery} />
                  </ReviewField>
                </React.Fragment>
              )}
            </Item>
            {tc.delayMode === DELAY_MODE.TIMER && (
              <Item name="Cancel on" borderTop>
                {tc.hasExitEvent ? (
                  tc.exitEvents.map(({ eventId, query }, index) => (
                    <React.Fragment key={index}>
                      <ReviewField>
                        <EventFormatter eventCode={eventId} eventQuery={query} />
                      </ReviewField>
                      {campaign.triggerConfig.exitEvents.size - 2 > index && ', '}
                      {campaign.triggerConfig.exitEvents.size - 2 === index && ' or '}
                    </React.Fragment>
                  ))
                ) : (
                  <FadedLabel>—</FadedLabel>
                )}
              </Item>
            )}

            <Item name="Grace period" borderTop>
              {tc.hasGrace ? (
                <ReviewField>{tc.grace.fullText}</ReviewField>
              ) : (
                <FadedLabel>—</FadedLabel>
              )}
            </Item>
            <Item name="Capping" borderTop>
              {tc.hasCapping ? (
                <React.Fragment>
                  No more than <ReviewField style={{ margin: '0 4px' }}>{tc.capping}</ReviewField>{' '}
                  notification
                  {tc.capping > 1 && 's'} per user
                </React.Fragment>
              ) : (
                <FadedLabel>—</FadedLabel>
              )}
            </Item>
            <Item name="Starts" borderTop>
              {tc.hasStart ? <Date date={tc.start} utc={true} /> : <FadedLabel>—</FadedLabel>}
            </Item>
            <Item name="Ends" borderTop>
              {tc.hasEnd ? <Date date={tc.end} utc={true} /> : <FadedLabel>—</FadedLabel>}
            </Item>
          </React.Fragment>
        )}
        <Item name="Status" borderTop={!isPush || sendType === 'trigger'}>
          <Status campaign={campaign} />
        </Item>
        {!isPush && (
          <Item name="Priority" borderTop>
            {capitalize(campaign.inAppPriority)}
          </Item>
        )}
        {(!isPush || isRecurring) && (
          <Item name="Capping" borderTop>
            {campaign.capping ? (
              <React.Fragment>
                Display only
                <ValueAlt>{campaign.capping}</ValueAlt>
                time per user
              </React.Fragment>
            ) : (
              <FadedLabel>—</FadedLabel>
            )}
          </Item>
        )}
        {!isPush && (
          <Item name="Grace period" borderTop>
            {campaign.gracePeriod ? (
              <React.Fragment>
                Display only once every
                <ValueAlt>
                  {campaign.gracePeriod % 24 === 0
                    ? `${campaign.gracePeriod / 24} day${campaign.gracePeriod / 24 > 1 ? 's' : ''}`
                    : `${campaign.gracePeriod} hour${campaign.gracePeriod > 1 ? 's' : ''}`}
                </ValueAlt>
                per user
              </React.Fragment>
            ) : (
              <FadedLabel>—</FadedLabel>
            )}
          </Item>
        )}

        {isPush && isRecurring && (
          <Item name="Rule" borderTop>
            Recurring
          </Item>
        )}

        {isRecurring && <RecurringItems campaign={campaign} />}
        {(!isRecurring || !isPush) && campaign.start && <ScheduledItems campaign={campaign} />}
        {!isPush && (
          <Item name="Ending" borderTop>
            {campaign.end ? (
              <Date date={campaign.end} utc={!campaign.tzAware} />
            ) : (
              <FadedLabel>Never</FadedLabel>
            )}
          </Item>
        )}
      </BoxBody>
      {isRecurring || !isPush ? (
        <BoxFooter style={{ height: 76, padding: '0 20px' }} isEditable>
          <RecurringFooter campaign={campaign} app={app} lastPushDate={lastPushDate} />
        </BoxFooter>
      ) : null}
    </Box>
  )
}

export default RSRTiming

const Date = ({ date, utc }: { date: ?Dayjs, utc: boolean, ... }) => {
  const mom = dayjs(date ?? '')
  return (
    <React.Fragment>
      {mom.format(dateTime)}{' '}
      {utc ? (
        <Tooltip
          tooltip={`${mom.add(dayjs().utcOffset(), 'minute').format('HH:mm')} (UTC + ${
            dayjs().utcOffset() / 60
          }) in your local time`}
        >
          <Timezone style={{ margin: '0 0 0 10px' }}>UTC</Timezone>
        </Tooltip>
      ) : (
        <Tooltip tooltip="Scheduled to match each user respective timezone.">
          <Timezone style={{ margin: '0 0 0 10px' }}>Local</Timezone>
        </Tooltip>
      )}
    </React.Fragment>
  )
}

const Status = ({ campaign }: { campaign: CampaignRecord, ... }): Node => {
  const { state, repeatFrequency } = campaign
  const isPush = campaign.type === 'push'
  switch (state) {
    case 'DRAFT':
      return (
        <React.Fragment>
          <Icon icon="draft" size={12} color="#a9aeb5" style={{ margin: '0 13px 0 0' }} />
          Draft
        </React.Fragment>
      )
    case 'RUNNING': {
      const inProgress = isCampaignRunning(campaign)
      return isPush ? (
        <React.Fragment>
          <Icon
            icon="waiting-fill"
            color={inProgress ? schemes.blue['60'] : schemes.orange['60']}
            style={{ margin: '0 14px 0 0' }}
          />
          {inProgress ? (
            <React.Fragment>
              Sending in progress{' '}
              {campaign.sendType && 'scheduled' && campaign.tzAware && (
                <Hint>
                  This campaign is sent in the user's local time : sending will finish when the
                  scheduled time is passed in the last timezone (UTC +14),{' '}
                  {!campaign.start ? '' : campaign.start.add(14, 'hour').format('YYYY/MM/DD HH:mm')}{' '}
                  in your local time.
                </Hint>
              )}
            </React.Fragment>
          ) : !repeatFrequency ? (
            'Waiting for send date'
          ) : (
            'Running, waiting for next send date'
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Icon icon="waiting-fill" color={schemes.blue['60']} style={{ margin: '0 10px 0 0' }} />{' '}
          Running
        </React.Fragment>
      )
    }
    case 'STOPPED':
      return (
        <React.Fragment>
          <Icon icon="error" color={schemes.red['60']} style={{ margin: '0 10px 0 0' }} /> Stopped
        </React.Fragment>
      )
    case 'COMPLETED':
      return (
        <React.Fragment>
          <Icon icon="success" color={schemes.blue['60']} style={{ margin: '0 10px 0 0' }} />{' '}
          {isPush ? 'Sent' : 'Ended'}
        </React.Fragment>
      )
  }
  return null
}

const ScheduledItems = ({ campaign }: { campaign: CampaignRecord, ... }) => {
  const isPush = campaign.type === 'push'
  return (
    <Item name={isPush ? 'Sent On' : 'Starting'} borderTop>
      <Date date={campaign.start} utc={!campaign.tzAware} />
    </Item>
  )
}
const RecurringItems = ({ campaign }: { campaign: CampaignRecord, ... }) => (
  <React.Fragment>
    <Item name="Interval" borderTop>
      Every {computeRepeatWord(campaign.repeatUnit, campaign.repeatFrequency, campaign.start)} at{' '}
      {campaign.start && campaign.start.format('HH[h]mm')}{' '}
      {!campaign.tzAware && <Timezone style={{ margin: '0 0 0 8px' }}>UTC</Timezone>}
    </Item>

    <Item name="First Send" borderTop>
      {!!campaign.start && campaign.start.format('DD/MM/YYYY')}
    </Item>

    {campaign.end !== null ? (
      <Item name="Until" borderTop>
        {!!campaign.end && campaign.end.format('DD/MM/YYYY')}
      </Item>
    ) : null}

    {Number(campaign.capping) > 1 ? (
      <Item name="Capping" borderTop>
        <FadedLabel>Send only</FadedLabel> {campaign.capping}
        <FadedLabel>&nbsp;push per user</FadedLabel>
      </Item>
    ) : null}
  </React.Fragment>
)

type RecurringFooterProps = RSRTimingProps

const RecurringFooter = ({ campaign, app }: RecurringFooterProps): React.Node => {
  const { start, end, repeatFrequency, repeatUnit } = campaign
  const nextPushDate = start
    ? computeNextPushDate({ start, end, repeatFrequency, repeatUnit })
    : null
  const isPush = campaign.type === 'push'

  // ça pue, je dois demander tous les hits pour du recurring / trigger / in-app & prendre le dernier
  // jour où on a eu de la data. j'utilise pas le lastSend que steph remonte sur le push recurring
  // & j'ai potentiellement déjà fait le hit sur le screen analyutics, donc faudrait pe stocker dans redux...

  const [lastData, setLastData] = React.useState<?Dayjs>(null)
  const [loadingData, setLoadingData] = React.useState(false)
  React.useEffect(() => {
    setLoadingData(true)
    fetchCampaignDetailAnalytics({ app, token: campaign.token }).then(
      data => {
        // on ne considère que les jours où un truc à réellement eu lieu pour afficher la last date
        // permet de skip si on a pas afficher d'in-app ou si on a rien envoyé un jour donné
        const meaningfullData = data.filter(
          dayData => ((dayData.inapp?.total.display ?? 0) + dayData.push.total.sent ?? 0) > 0
        )
        if (meaningfullData.size)
          setLastData(meaningfullData.reverse().get(0, DayDataFactory()).period)
        setLoadingData(false)
      },
      () => setLoadingData(false)
    )
  }, [app, campaign.token])
  return (
    <React.Fragment>
      <PushDate>
        <PushDateTitle>Last {isPush ? 'Push' : 'Trigger'}</PushDateTitle>
        <Loader loading={loadingData} size={'tiny'} left={true} height={22}>
          {lastData ? (
            <React.Fragment>
              <PushDateAbsolute>{dayjs(lastData).format(date)}</PushDateAbsolute>
              {dayjs(lastData).fromNow()}
            </React.Fragment>
          ) : (
            '—'
          )}
        </Loader>
      </PushDate>
      {isPush && campaign.state === 'RUNNING' && nextPushDate && (
        <PushDate>
          <PushDateTitle>Next Push</PushDateTitle>
          <PushDateAbsolute>{nextPushDate.format(date)}</PushDateAbsolute>
          {nextPushDate.fromNow()}
        </PushDate>
      )}
    </React.Fragment>
  )
}
