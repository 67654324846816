/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Select } from 'components/form'

import { OperatorAndNegateFormatter, type operatorAndNegate } from './left-helper'

import { setInputType } from 'com.batch.redux/query/query.api'
import { allOperators, type ConditionRecord } from 'com.batch.redux/query/query.records'
import { CountFunction } from 'com.batch.redux/query/query.records.functions'
import {
  ContainsOperator,
  EqualOperator,
  ExistsOperator,
} from 'com.batch.redux/query/query.records.operators'

type LeftTagProps = {
  condition: ConditionRecord,
  updateCondition: ConditionRecord => void,
  negated: boolean,
  negate: () => void,
  unNegate: () => void,
  ...
}

type option = {
  label: string,
  action: ConditionRecord => ConditionRecord,
  valueMatcher: ConditionRecord => boolean,
  ...
}
const OPTIONS: List<option> = new Immutable.List().push(
  ...[
    {
      label: 'tags',
      valueMatcher: (condition: ConditionRecord) => condition.functions.size === 0,
      action: (condition: ConditionRecord) =>
        setInputType(
          condition.set('functions', new Immutable.List()).set('operator', ContainsOperator)
        ),
    },
    {
      label: 'count',
      valueMatcher: (condition: ConditionRecord) => condition.functions.size === 1,
      action: (condition: ConditionRecord) =>
        setInputType(
          condition
            .set('functions', new Immutable.List().push(CountFunction))
            .set('operator', EqualOperator)
        ),
    },
  ]
)

export const LeftTag = ({
  condition,
  updateCondition,
  negated,
  negate,
  unNegate,
}: LeftTagProps): React.Node => {
  const availableOperators = React.useMemo(() => {
    const producedType = condition.functions.reduce(
      (type, func) => func.produce,
      condition.attribute?.type ?? 'STRING'
    )
    return allOperators.filter(
      operator => operator !== ExistsOperator && operator.accept.has(producedType)
    )
  }, [condition.attribute, condition.functions])

  const operatorWithNegate: List<operatorAndNegate> = React.useMemo(() => {
    let tmp: Array<operatorAndNegate> = []
    availableOperators.forEach(operator => {
      tmp.push({ operator, negate: false })
      if (operator.negate !== '') {
        tmp.push({ operator, negate: true })
      }
    })
    return new Immutable.List().push(...tmp)
  }, [availableOperators])
  return (
    <FlexLine>
      <FlexLineItem>
        <Select
          style={{ width: 120 }}
          options={OPTIONS}
          optionToString={option => option?.label ?? ''}
          value={OPTIONS.find(option => option.valueMatcher(condition))}
          onChange={option => {
            option && updateCondition(option.action(condition))
          }}
        />
      </FlexLineItem>
      <FlexLineItem>
        <Select
          style={{ width: 220 }}
          optionFormatter={OperatorAndNegateFormatter}
          optionToString={option =>
            option ? option.operator.value + (option.operator.negate ? 'yes' : 'no') : ''
          }
          options={operatorWithNegate}
          value={operatorWithNegate.find(
            ({ operator, negate }) =>
              operator.value === condition.operator.value && negated === negate
          )}
          onChange={opt => {
            if (opt) {
              updateCondition(setInputType(condition.set('operator', opt.operator)))
              if (opt.negate !== negated) {
                if (opt.negate) {
                  negate()
                } else {
                  unNegate()
                }
              }
            }
          }}
        />
      </FlexLineItem>
    </FlexLine>
  )
}
