// @flow
import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

type InAppCappingProps = {
  enabled: boolean,
  level: 'global' | 'session',
  capping: number,
  periodUnit: 'h' | 'd',
  periodValue: number,
  ...
}

export const InAppCappingFactory: RecordFactory<InAppCappingProps> = Immutable.Record(
  ({
    enabled: false,
    level: 'session',
    capping: 1,
    periodUnit: 'h',
    periodValue: 1,
  }: InAppCappingProps)
)

export type InAppCappingRecord = RecordOf<InAppCappingProps>
