// @flow

import Immutable, { type RecordOf, type RecordFactory, type Set } from 'immutable'

import { type availableIcons } from 'components/common/svg-icon'

import * as Types from './query.types'
import { type OursQLType } from './query.types'

type OperatorProps = {
  value: string,
  label: string,
  negate: string, // when !='', the operator can be negated (= $not the condition)
  icon: availableIcons,
  advanced: boolean, // advanced operator are not shown, but we can parse / understand them
  accept: Set<OursQLType>,
  input: OursQLType | 'identity',
  ...
}
export const OperatorFactory: RecordFactory<OperatorProps> = Immutable.Record(
  ({
    value: '',
    label: '',
    negate: '',
    icon: 'equal',
    advanced: false,
    accept: Immutable.Set(),
    input: Types.STRING,
  }: OperatorProps)
)

export type OperatorRecord = RecordOf<OperatorProps>

export const ExistsOperator: OperatorRecord = OperatorFactory({
  value: 'exists',
  label: 'exists',
  icon: 'exist',
  accept: Immutable.Set([
    Types.DATE,
    Types.FLOAT,
    Types.INTEGER,
    Types.BOOLEAN,
    Types.STRING,
    Types.VERSION,
    Types.URL,
  ]),
  input: Types.BOOLEAN,
})

// standard operators --------------

export const LowerOperator: OperatorRecord = OperatorFactory({
  value: 'lt',
  label: 'is less than',
  icon: 'lower',
  accept: Immutable.Set([Types.FLOAT, Types.INTEGER, Types.VERSION]),
  input: 'identity',
})

export const LowerOrEqualOperator: OperatorRecord = OperatorFactory({
  value: 'lte',
  icon: 'lower-equal',
  label: 'is less than (or =)',
  accept: Immutable.Set([Types.FLOAT, Types.INTEGER, Types.VERSION]),
  input: 'identity',
})

export const EqualOperator: OperatorRecord = OperatorFactory({
  value: 'eq',
  label: 'is equal to',
  negate: 'is not equal to',
  icon: 'equal',
  accept: Immutable.Set([Types.BOOLEAN, Types.FLOAT, Types.INTEGER, Types.STRING, Types.VERSION]),
  input: 'identity',
})

export const GreaterOperator: OperatorRecord = OperatorFactory({
  value: 'gt',
  label: 'is greater than',
  icon: 'greater',
  accept: Immutable.Set([Types.FLOAT, Types.INTEGER, Types.VERSION]),
  input: 'identity',
})
export const GreaterOrEqualOperator: OperatorRecord = OperatorFactory({
  value: 'gte',
  label: 'is greater than (or =)',
  icon: 'greater-equal',
  accept: Immutable.Set([Types.FLOAT, Types.INTEGER, Types.VERSION]),
  input: 'identity',
})

// age operators --------------

export const AgeLowerOperator: OperatorRecord = OperatorFactory({
  value: 'lt',
  label: 'occurred less than',
  icon: 'lower',
  accept: Immutable.Set([Types.AGE]),
  input: 'identity',
})

export const AgeLowerOrEqualOperator: OperatorRecord = OperatorFactory({
  value: 'lte',
  icon: 'lower-equal',
  label: 'occurred less than (or =)',
  accept: Immutable.Set([Types.AGE]),
  input: 'identity',
})

export const AgeEqualOperator: OperatorRecord = OperatorFactory({
  value: 'eq',
  label: 'occurred',
  negate: 'did not occur',
  icon: 'equal',
  accept: Immutable.Set([Types.AGE]),
  input: 'identity',
})

export const AgeGreaterOperator: OperatorRecord = OperatorFactory({
  value: 'gt',
  label: 'occurred more than',
  icon: 'greater',
  accept: Immutable.Set([Types.AGE]),
  input: 'identity',
})
export const AgeGreaterOrEqualOperator: OperatorRecord = OperatorFactory({
  value: 'gte',
  label: 'occurred more than (or =)',
  icon: 'greater-equal',
  accept: Immutable.Set([Types.AGE]),
  input: 'identity',
})

// date operators --------------

export const DateLowerOperator: OperatorRecord = OperatorFactory({
  value: 'lt',
  label: 'is before',
  icon: 'lower',
  accept: Immutable.Set([Types.DATE]),
  input: 'identity',
})

export const DateLowerOrEqualOperator: OperatorRecord = OperatorFactory({
  value: 'lte',
  icon: 'lower-equal',
  label: 'is before (or =)',
  accept: Immutable.Set([Types.DATE]),
  input: 'identity',
})

export const DateGreaterOperator: OperatorRecord = OperatorFactory({
  value: 'gt',
  label: 'is after',
  icon: 'greater',
  accept: Immutable.Set([Types.DATE]),
  input: 'identity',
})
export const DateGreaterOrEqualOperator: OperatorRecord = OperatorFactory({
  value: 'gte',
  label: 'is after (or =)',
  icon: 'greater-equal',
  accept: Immutable.Set([Types.DATE]),
  input: 'identity',
})

export const StartsWithOperator: OperatorRecord = OperatorFactory({
  value: 'startsWith',
  label: 'starts with',
  accept: Immutable.Set([Types.STRING]),
  icon: 'starts-with',
  input: Types.STRING,
  negate: 'does not start with',
})

export const EndsWithOperator: OperatorRecord = OperatorFactory({
  value: 'endsWith',
  label: 'ends with',
  icon: 'ends-with',
  accept: Immutable.Set([Types.STRING]),
  input: Types.STRING,
  negate: 'does not end with',
})

export const InOperator: OperatorRecord = OperatorFactory({
  value: 'in',
  label: 'is one of',
  icon: 'one-of',
  accept: Immutable.Set([Types.STRING, Types.VERSION]),
  input: Types.STRING,
})

export const NotInOperator: OperatorRecord = OperatorFactory({
  value: 'notIn',
  label: 'is not one of',
  icon: 'one-of',
  accept: Immutable.Set([Types.STRING, Types.VERSION]),
  input: Types.STRING,
})

export const ContainsOperator: OperatorRecord = OperatorFactory({
  value: 'containsAny',
  label: 'contains any of',
  icon: 'one-of',
  accept: Immutable.Set([Types.TAG]),
  input: Types.STRING,
})

export const ContainsAll: OperatorRecord = OperatorFactory({
  value: 'containsAll',
  label: 'contains all of',
  icon: 'all-of',
  accept: Immutable.Set([Types.TAG]),
  input: Types.STRING,
})

export const DoesNotContainSomeOperator: OperatorRecord = OperatorFactory({
  value: 'doesNotContainSome',
  label: 'does not contain some of',
  icon: 'one-of',
  accept: Immutable.Set([Types.TAG]),
  input: Types.STRING,
})

export const DoesNotContainAllOperator: OperatorRecord = OperatorFactory({
  value: 'doesNotContainAll',
  label: 'does not contain all',
  icon: 'all-of',
  accept: Immutable.Set([Types.TAG]),
  input: Types.STRING,
})
