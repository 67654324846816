// @flow

import styled, {
  keyframes,
  css,
  type KeyFrames,
  type CSSRules,
  type StyledComponent,
  type PropsWithTheme,
} from 'styled-components'

import { schemes } from 'components/styled/tokens'

// ====================== EMPTY STATE BUILD ANIMATION
export const builtEmptyStateAnimation = (): CSSRules => css`
  animation-name: ${LoadingCellAnim};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

// ====================== EMPTY STATE ANIMATIONS
export const LoadingCellAnim: KeyFrames = keyframes`
0% { background-color: ${schemes.grayscale['10']}}
50% { background-color: ${schemes.grayscale['20']} }
100% { background-color: ${schemes.grayscale['10']} }
`

export const LoadingSvgAnim: KeyFrames = keyframes`
0% { fill: ${schemes.grayscale['10']}}
50% { fill: ${schemes.grayscale['20']} }
100% { fill: ${schemes.grayscale['10']} }
`

export const LoadingSvgStrokeAnim: KeyFrames = keyframes`
0% { stroke: ${schemes.grayscale['10']}}
50% { stroke: ${schemes.grayscale['20']} }
100% { stroke: ${schemes.grayscale['10']} }
`

export const LoadingSectionAnim: KeyFrames = keyframes`
0% { background: ${schemes.grayscale['10']}}
50% { background: ${schemes.grayscale['20']} }
100% { background: ${schemes.grayscale['10']} }
`

export const EmptyField: StyledComponent<
  {
    _width?: number | string,
    _height?: number,
    _display?: 'inline' | 'block' | 'inline-block',
    forceLoading?: boolean,
    ...
  },
  *,
  HTMLElement,
> = styled.div`
  width: ${(
    p: PropsWithTheme<
      {
        _display?: 'inline' | 'block' | 'inline-block',
        _height?: number,
        _width?: number | string,
        forceLoading?: boolean,
        ...
      },
      any,
    >
  ) => (p._width ? `${p._width}${typeof p._width === 'number' ? 'px' : ''}` : '48px')};
  height: ${(
    p: PropsWithTheme<
      {
        _display?: 'inline' | 'block' | 'inline-block',
        _height?: number,
        _width?: number | string,
        forceLoading?: boolean,
        ...
      },
      any,
    >
  ) => p._height ?? 13}px;
  display: ${(
    p: PropsWithTheme<
      {
        _display?: 'inline' | 'block' | 'inline-block',
        _height?: number,
        _width?: number | string,
        forceLoading?: boolean,
        ...
      },
      any,
    >
  ) => p._display ?? 'inline-block'};
  border-radius: 2px;

  ${(
    p: PropsWithTheme<
      {
        _display?: 'inline' | 'block' | 'inline-block',
        _height?: number,
        _width?: number | string,
        forceLoading?: boolean,
        ...
      },
      any,
    >
  ) =>
    p.theme.isLoading || p.forceLoading
      ? builtEmptyStateAnimation()
      : css`
          background-color: ${schemes.grayscale['30']};
        `}
`
export const EmptyCircle: StyledComponent<{ _size?: number, ... }, *, HTMLElement> = styled.div`
  width: ${(p: PropsWithTheme<{ _size?: number, ... }, any>) => (p._size ? p._size : '10')}px;
  height: ${(p: PropsWithTheme<{ _size?: number, ... }, any>) => (p._size ? p._size : '10')}px;
  display: inline-block;
  border-radius: ${(p: PropsWithTheme<{ _size?: number, ... }, any>) =>
    p._size ? p._size : '10'}px;
  padding: 0;

  ${(p: PropsWithTheme<{ _size?: number, ... }, any>) =>
    p.theme.isLoading
      ? builtEmptyStateAnimation()
      : css`
          background-color: ${schemes.grayscale['30']};
        `}
`
