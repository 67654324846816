// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { CodeContainer } from './code.style'

type CodeProps = {
  size?: 'normal' | 'small',
  children: React.Node,
  disabledCopy?: boolean,
  accessNotAllowed?: boolean,
  style?: { [string]: string | number, ... },
  styleText?: { [string]: string | number, ... },
  tooltip?: React.Node,
  disableTitle?: boolean,
  ...
}

export function Code({
  size = 'normal',
  children,
  disabledCopy,
  style,
  styleText,
  accessNotAllowed = false,
  tooltip,
  disableTitle,
  ...props
}: CodeProps): React.Node {
  const [copied, setCopied] = React.useState<boolean>(false)

  const CopyRef: { current: any, ... } = React.createRef()

  const handleCopyClick = React.useCallback(() => {
    if (!disabledCopy) {
      if (CopyRef.current) {
        var range = new Range()
        var selection = window.getSelection()
        range.selectNode(CopyRef.current)
        selection.removeAllRanges()
        selection.addRange(range)

        try {
          var result = document.execCommand('copy')
          if (result) setCopied(true)
        } catch (err) {
          console.log(err)
        }
        selection = window.getSelection()
        if (typeof selection.removeRange === 'function') selection.removeRange(range)
        else if (typeof selection.removeAllRanges === 'function') selection.removeAllRanges()
      }
    }
  }, [CopyRef, disabledCopy])

  React.useEffect(() => {
    const switchCopied = setTimeout(() => setCopied(false), 1800)
    return () => clearTimeout(switchCopied)
  })

  return !disabledCopy ? (
    <Tooltip
      tooltip={
        tooltip ?? (
          <div style={{ padding: '6px 8px' }}>
            {copied ? (
              <React.Fragment>
                Copied <Icon icon="check" size={12} />
              </React.Fragment>
            ) : (
              <React.Fragment> Click to copy </React.Fragment>
            )}
          </div>
        )
      }
      noPadding
      placement="bottom"
    >
      <CodeContainer
        {...props}
        size={size}
        style={style}
        disabledCopy={disabledCopy}
        onClick={handleCopyClick}
        accessNotAllowed={accessNotAllowed}
        title={typeof children === 'string' && !disableTitle ? children.substring(0, 80) : null}
      >
        <span ref={CopyRef} style={{ whiteSpace: 'pre', maxWidth: '100%' }}>
          {accessNotAllowed ? 'Require higher permission to display' : children}
        </span>
      </CodeContainer>
    </Tooltip>
  ) : (
    <CodeContainer
      id={children}
      size={size}
      style={style}
      disabledCopy={disabledCopy}
      onClick={handleCopyClick}
    >
      <span ref={CopyRef} style={styleText}>
        {accessNotAllowed ? 'Require higher permission to display' : children}
      </span>
    </CodeContainer>
  )
}
