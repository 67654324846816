// @flow

import { type Dayjs } from 'dayjs'
import { type Map, type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useResizeObserver } from 'components/_hooks'
import { Notif } from 'components/campaign/preview/notif'
import { PreviewContainer } from 'components/campaign/preview/preview-container'
import { Icon } from 'components/common/svg-icon'
import LandingPreview from 'components/landing/landing-preview'

import { LockScreen, LockScreenDate, LockScreenTime, NotifClose } from './preview.styles'

import { type AppRecord, type Variant } from 'com.batch.redux/_records'
import { type InAppContentRecord } from 'com.batch.redux/content.records'
import { StandalonePreviewSelector } from 'com.batch.redux/targeting.selector.composed'
import { SelectThemeField } from 'com.batch.redux/theme'
import { type AbTestedThemeRecord, type FieldKind } from 'com.batch.redux/theme.records'

type OwnProps = {
  style?: Object,
  previewMode: 'normal' | 'expanded' | 'landing',
  schedulingType?: schedulingType,
  previewWeb: string,
  variant: ?('a' | 'b'),
  width?: number,
  height?: number,
}

type StateProps = {
  getVariantData: (variantId: Variant) => {
    title: string,
    message: string,
    image: string,
    audio: string,
    video: string,
    ...
  },
  app: AppRecord,
  variantsThemes: AbTestedThemeRecord,
  hasLanding: boolean,
  safariIcon: string,
  loadingThemes: boolean,
  selectedField: string,
  getLandingVariantData: (variantId: Variant) => InAppContentRecord,
  templatedFields: Set<string>,
  mode: Map<Variant, 'light' | 'dark'>,
  date: Dayjs,
  icon: string,
  hasPush: boolean,
  image: string,
  audio: string,
  video: string,
  focusField: ({ field: ?FieldKind, variant: ?Variant }) => void,
}

type StandalonePreviewProps = {
  ...OwnProps,
  ...StateProps,
}
const StandalonePreviewBase = React.forwardRef((props: StandalonePreviewProps, ref) => {
  const {
    app,
    variantsThemes,
    date,
    style,
    getLandingVariantData,
    variant,
    icon,
    safariIcon,
    width,
    selectedField,
    templatedFields,
    schedulingType,
    focusField,
    loadingThemes,
    previewMode,
    previewWeb,
    getVariantData,
  } = props
  const theme = variantsThemes.get(variant ?? 'a')
  const ratio = width ? Math.min(1, width / 400) : 1
  const computedStyle = {
    ...style,
    transform: `scale(${ratio},${ratio})`,
    transformOrigin: 'top left',
  }

  const { title, message, image, audio, video } = getVariantData(variant ?? 'a')
  const expanded = previewMode === 'expanded'
  const landingData = getLandingVariantData(variant ?? 'a')

  const isAndroid =
    app.platform === 'android' || (app.platform === 'webpush' && previewWeb === 'android')

  let appName = app.name
  if (app.platform === 'webpush') {
    appName = app.bundleId.replace('http://', '').replace('https://', '')
    const tmp = appName.split('/')
    appName = tmp[0]
  }
  const hideStatus =
    !!theme &&
    !!theme.payloadVars &&
    theme.payloadVars.get('kind', '') === 'banner' &&
    !theme.payloadVars.get('banner_bottom', true)

  return previewMode === 'landing' ? (
    <div ref={ref} style={{ ...computedStyle, height: 680 }}>
      <PreviewContainer
        app={app}
        hideStatus={hideStatus}
        previewMode={previewMode}
        lightStatusBar={!!theme && theme.statusBarMode === 'light'}
        webviewBackground={!!theme && theme.payloadVars.kind === 'webview'}
        time={date}
      >
        <LandingPreview
          theme={theme}
          loadingThemes={loadingThemes}
          platform={app.platform}
          mode={schedulingType === 'campaigns' ? 'campaign' : 'automation'}
          imageUsesTemplate={templatedFields.includes('landingImage')}
          data={landingData}
          focusField={focusField}
          focusedField={selectedField}
          variant={variant}
        />
      </PreviewContainer>
    </div>
  ) : (
    <div ref={ref} style={computedStyle}>
      <PreviewContainer
        app={app}
        mode={props.mode.get(variant ?? 'a')}
        previewMode={previewMode}
        lightStatusBar
        webPreviewMode={previewWeb}
        time={date}
      >
        {previewMode === 'normal' && (app.platform !== 'webpush' || isAndroid) && (
          <LockScreen>
            <LockScreenTime>{date.format('HH:mm')}</LockScreenTime>
            {app.platform === 'windows' ? (
              <LockScreenDate>
                {date.format('dddd')}
                <br />
                {date.format('MMMM DD')}
              </LockScreenDate>
            ) : (
              <LockScreenDate>{date.format('dddd, MMMM DD')}</LockScreenDate>
            )}
          </LockScreen>
        )}
        {app.platform === 'ios' && expanded && (
          <NotifClose>
            <Icon icon="close" size={12} />
          </NotifClose>
        )}
        <Notif
          previewWeb={previewWeb}
          icon={icon}
          image={image}
          app={app}
          iconSafari={safariIcon}
          audio={audio}
          video={video}
          expanded={expanded}
          title={title}
          message={message}
          templatedFields={templatedFields}
        />
      </PreviewContainer>
    </div>
  )
})
export const StandalonePreviewConnected = (props: OwnProps): React.Node => {
  const [ref, width] = useResizeObserver()
  const dispatch = useDispatch()
  const focusField = React.useCallback(
    ({ field, variant }: { field: ?FieldKind, variant: ?('a' | 'b') }) => {
      dispatch(SelectThemeField({ field, variant }))
    },
    [dispatch]
  )
  const stateProps = useSelector(StandalonePreviewSelector)
  return (
    <StandalonePreviewBase
      ref={ref}
      {...props}
      {...stateProps}
      width={width}
      focusField={focusField}
    />
  )
}

export const StandalonePreview = (props: StandalonePreviewProps): React.Node => {
  const [ref, width] = useResizeObserver()
  return <StandalonePreviewBase ref={ref} {...props} width={width} />
}

export const SizelessPreviewConnected = (props: OwnProps): React.Node => {
  const dispatch = useDispatch()
  const focusField = React.useCallback(
    ({ field, variant }: { field: ?FieldKind, variant: ?('a' | 'b') }) => {
      dispatch(SelectThemeField({ field, variant }))
    },
    [dispatch]
  )
  const stateProps = useSelector(StandalonePreviewSelector)
  return <StandalonePreviewBase {...props} {...stateProps} focusField={focusField} />
}
