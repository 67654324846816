// @flow

import Immutable from 'immutable'
import { from, of, pipe } from 'rxjs'
import { concatMap, filter, map, mergeMap } from 'rxjs/operators'

import { CustomAudienceFactory, type State } from 'com.batch.redux/_records'
import { fetchCustomAudienceEstimate } from 'com.batch.redux/audience.api'

export const fetchCustomAudienceEstimateEpic = (
  action$: any,
  state$: { value: State, ... }
): any => {
  return action$.pipe(
    filter(action => action.type === 'FETCH_CUSTOM_AUDIENCES_SUCCESS'),
    map(({ payload }) => {
      return state$.value.audience.idsPerPage
        .get(payload.page, new Immutable.List())
        .map(id => state$.value.audience.entities.get(id, CustomAudienceFactory()))
        .valueSeq()
        .toArray()
    }),

    mergeMap(audiences =>
      of(...audiences).pipe(
        filter(audience => audience.estimate === null),
        concatMap(audience => {
          const name = audience.name
          const estimatePromise = fetchCustomAudienceEstimate(state$.value.app.current.id, audience)
          return from(
            estimatePromise.then(
              estimate => ({ name, estimate }),
              () => ({ name, estimate: 'error' })
            )
          )
        })
      )
    ),
    pipe(map(payload => ({ type: 'UPDATE_CUSTOM_AUDIENCE_ESTIMATE', payload })))
  )
}
