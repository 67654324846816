// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { Highlight } from 'components/common/highlight'

import { QueryBuilderContext } from './query-builder.context'

import { getAPIQueryForIdSelector } from 'com.batch.redux/query/query.selector'

export const JsonQuery: React.ComponentType<{}> = React.forwardRef((props, ref) => {
  const getAPIQueryForId = useSelector(getAPIQueryForIdSelector)
  const { queryId } = React.useContext(QueryBuilderContext)
  const query = React.useMemo(() => getAPIQueryForId(queryId), [getAPIQueryForId, queryId])

  return (
    <React.Fragment>
      <textarea
        ref={ref}
        defaultValue={JSON.stringify(query, null, 2)}
        style={{ position: 'absolute', opacity: 0 }}
      />
      <Highlight
        language="json"
        style={{
          borderRadius: 0,
          minHeight: '300px',
          overflow: 'visible',
          overflowY: 'visible',
          overflowX: 'auto',
        }}
      >
        {JSON.stringify(query, null, 2)}
      </Highlight>
    </React.Fragment>
  )
})
