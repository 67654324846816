// @flow
import { type Set } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableRow } from 'components/common/table'
import {
  fillBackground,
  textDisabled,
  textLight,
  textNeutral,
  textSuccess,
} from 'components/styled/tokens/colors'

import { getVariantLabel, kformat } from 'com.batch.common/utils'

import { type VariantId } from 'com.batch/message/models/message.records'
import {
  type OrchestrationVariantRecord,
  type VariantDataRecord,
  type VariantStatsProps,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-variant.record'
import { formatRate } from 'com.batch/orchestration-analytics/ui/components/format-rate'

type Props = {
  variantStats: OrchestrationVariantRecord,
  winnerVariantId: ?VariantId,
  selectWinner: () => any,
  loadingWinnerId: ?VariantId,
  canPickWinner: boolean,
  channels: Set<ChannelUntilCleanup>,
}
export const ABReportRow = ({
  variantStats,
  winnerVariantId,
  selectWinner,
  loadingWinnerId,
  canPickWinner,
  channels,
}: Props): React.Node => {
  const isWinner = React.useMemo(
    () => winnerVariantId === variantStats.variantId,
    [variantStats.variantId, winnerVariantId]
  )

  // Styles de chaque cells
  const styles: { [$Keys<VariantStatsProps>]: { color: string, fontWeight: number } } =
    React.useMemo(() => {
      // Si la ligne est winner alors toutes les cells sont vertes et en bold
      if (isWinner) {
        return {
          delivered: { color: textSuccess, fontWeight: 500 },
          open: { color: textSuccess, fontWeight: 500 },
          click: { color: textSuccess, fontWeight: 500 },
          bounce: { color: textSuccess, fontWeight: 500 },
          unsubscribe: { color: textSuccess, fontWeight: 500 },
        }
      }

      const getColorForCell = (metric: VariantDataRecord) => {
        // Si il y a un winner (mais du coup que c'est pas cette ligne, cf condition précédente) alors la ligne est grisée fortement
        if (winnerVariantId) return textDisabled
        // Sinon on regarde si la celle est winner ou pas, gris le cas échéant
        return metric.winner ? textSuccess : textLight
      }

      const getFontWeight = (metric: VariantDataRecord) => (metric.winner ? 500 : 400)

      return {
        delivered: {
          color: getColorForCell(variantStats.stats.delivered),
          fontWeight: getFontWeight(variantStats.stats.delivered),
        },
        open: {
          color: getColorForCell(variantStats.stats.open),
          fontWeight: getFontWeight(variantStats.stats.open),
        },
        click: {
          color: getColorForCell(variantStats.stats.click),
          fontWeight: getFontWeight(variantStats.stats.click),
        },
        bounce: {
          color: getColorForCell(variantStats.stats.bounce),
          fontWeight: getFontWeight(variantStats.stats.bounce),
        },
        unsubscribe: {
          color: getColorForCell(variantStats.stats.unsubscribe),
          fontWeight: getFontWeight(variantStats.stats.unsubscribe),
        },
      }
    }, [variantStats, isWinner, winnerVariantId])

  return (
    <TableRow
      style={{
        background: isWinner
          ? `linear-gradient(90deg, transparent 0%, ${fillBackground} 3%, ${fillBackground} 50%, ${fillBackground} 97%, transparent 100%)`
          : 'white',
      }}
    >
      <TableCell
        align="left"
        style={{
          color: isWinner ? textSuccess : winnerVariantId ? textDisabled : textNeutral,
          fontWeight: 500,
        }}
      >
        {isWinner && <Icon icon="cup-fill" style={{ marginRight: 8 }} />} Variant{' '}
        {getVariantLabel(variantStats.variantId)}
      </TableCell>
      <TableCell align="right" style={{ ...styles.delivered }}>
        {kformat(variantStats.stats.delivered.value)}
      </TableCell>
      <TableCell align="right" style={{ ...styles.open }}>
        {formatRate(variantStats.stats.open.rate)}
      </TableCell>
      {channels.includes('email') && (
        <TableCell align="right" style={{ ...styles.click }}>
          {formatRate(variantStats.stats.click.rate)}
        </TableCell>
      )}
      <TableCell
        align="right"
        style={{
          ...styles.bounce,
          marginRight: !canPickWinner && !channels.includes('email') ? 28 : 12,
        }}
      >
        {formatRate(variantStats.stats.bounce.rate)}
      </TableCell>
      {channels.includes('email') && (
        <TableCell
          align="right"
          style={{ ...styles.unsubscribe, marginRight: canPickWinner ? 12 : 28 }}
        >
          {formatRate(variantStats.stats.unsubscribe.rate)}
        </TableCell>
      )}
      {canPickWinner && (
        <TableCell align="right">
          <Button
            addOn="prefix"
            intent="neutral"
            kind="inline"
            onClick={selectWinner}
            isLoading={variantStats.variantId === loadingWinnerId}
            disabled={Boolean(loadingWinnerId) && variantStats.variantId !== loadingWinnerId}
          >
            <Icon icon="cup" />
            Pick winner
          </Button>
        </TableCell>
      )}
    </TableRow>
  )
}
