// @flow

import * as colors from './colors'
import * as schemes from './schemes'

export const raised = `0px 1px 3px ${schemes.darklucent['05']}`
export const block = `0 1px 8px ${schemes.darklucent['02']}, 0 1px 3px ${schemes.darklucent['10']}`
export const overlay = `0px 1px 4px 0px ${schemes.darklucent['10']}, 0px 1px 12px 0px ${schemes.darklucent['10']}`
export const depth = `0px 1px 4px  ${schemes.darklucent['05']}`
export const depthAction = ` 0 0 0 1px ${colors.strokeAction}, 0px 2px 5px ${schemes.darklucent['05']}`
export const depthDanger = ` 0 0 0 1px ${colors.strokeDanger}, 0px 2px 5px ${schemes.darklucent['05']}`
