// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'

import { HeaderBoxTitle } from 'components/common/box'
import { EmptyField } from 'components/common/empty-states'

import { getProfileName } from 'com.batch/profile/infra/formats/get-profile-name'
import { ProfileUsernameContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { getFullName } from 'com.batch/profile/ui/components/view-models-generators/identity-title.selectors'

type Props = {
  email: ?string,
  isLoading: boolean,
  ...
}

export const ProfileUsername = ({ email, isLoading }: Props): React.Node => {
  const fullName = useSelector(getFullName)
  const profileName = React.useMemo(() => getProfileName(fullName, email), [fullName, email])
  return (
    <ProfileUsernameContainer>
      {isLoading ? (
        <React.Fragment>
          <EmptyField _width={60} _height={16} />
          <EmptyField _width={60} _height={16} />
        </React.Fragment>
      ) : (
        <HeaderBoxTitle title={<span className="fs-exclude">{profileName}</span>} />
      )}
    </ProfileUsernameContainer>
  )
}
