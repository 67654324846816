// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { EventFormatter } from 'components/campaign/event-formatter'
import { FlexLine, FlexLineItem, FlexLineItemTitle } from 'components/common/flexline'
import Hint from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'
import { Utc } from 'components/common/utc'
import { ReviewBigText, ReviewSmallText } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router/router'
import {
  kformat,
  computeRepeatWord,
  rateDuration,
  durationLongerThanHours,
} from 'com.batch.common/utils'

import { currentAppSelector } from 'com.batch.redux/app'
import { currentCampaign } from 'com.batch.redux/campaign.selector'
import { estimateSelector } from 'com.batch.redux/targeting'

import { DELAY_MODE } from 'constants/common'

export const PushSpecificReview = (): React.Node => {
  const campaign = useSelector(currentCampaign)
  const estimate = useSelector(estimateSelector)
  const app = useSelector(currentAppSelector)
  const state = campaign.state
  const past = state === 'COMPLETED'
  const sendType = campaign.sendType
  const tzAware = campaign.tzAware
  const start = campaign.start
  const end = campaign.end
  const ct = campaign.triggerConfig
  const timeToSend = tzAware ? 'timeToSendBiggestTz' : 'timeToSendAll'
  return (
    <div>
      <FlexLine
        separator
        style={{
          padding: '15px 20px',
        }}
        top
      >
        <FlexLineItem grow={1}>
          <FlexLineItemTitle>Reach</FlexLineItemTitle>
          <ReviewBigText>
            {estimate.get('loading') ? '~' : kformat(estimate.getIn(['tokens', 'matching'], '~'))}{' '}
            people
          </ReviewBigText>
        </FlexLineItem>

        {/* SCHEDULED =======================================*/}

        {sendType === 'scheduled' && (
          <FlexLineItem grow={1}>
            <FlexLineItemTitle>Sen{past ? 'd' : 't'} on</FlexLineItemTitle>
            <ReviewBigText disabled={!start}>
              {start ? start.format('MMM D, YYYY') : '—'}
            </ReviewBigText>
          </FlexLineItem>
        )}
        {sendType === 'scheduled' && (
          <FlexLineItem grow={1}>
            <FlexLineItemTitle>At</FlexLineItemTitle>
            <ReviewBigText disabled={!start}>
              {start ? (
                <React.Fragment>
                  {start.format('HH:mm')}
                  <ReviewSmallText>
                    {tzAware ? "(in the user's timezone)" : <Utc m={start} />}
                  </ReviewSmallText>
                </React.Fragment>
              ) : (
                '—'
              )}
            </ReviewBigText>
          </FlexLineItem>
        )}

        {/* NOW =======================================*/}

        {sendType === 'now' && (
          <FlexLineItem grow={1}>
            <FlexLineItemTitle>It will be sent</FlexLineItemTitle>
            <ReviewBigText>Now</ReviewBigText>
          </FlexLineItem>
        )}
        {/* RECURRING =======================================*/}
        {sendType === 'recurring' && start && (
          <FlexLineItem grow={1}>
            <FlexLineItemTitle>Starting</FlexLineItemTitle>
            <ReviewBigText disabled={!start}>
              {start ? start.format('MMM D, YYYY') : '—'}
            </ReviewBigText>
          </FlexLineItem>
        )}
        {sendType === 'recurring' && end && (
          <FlexLineItem grow={1}>
            <FlexLineItemTitle>Until</FlexLineItemTitle>
            <ReviewBigText disabled={!end}>{end.format('MMM D, YYYY')}</ReviewBigText>
          </FlexLineItem>
        )}
        {sendType === 'recurring' && (
          <FlexLineItem grow={1}>
            <FlexLineItemTitle>{past ? 'It was sent' : 'It will be sent'}</FlexLineItemTitle>
            <ReviewBigText>
              Every {computeRepeatWord(campaign.repeatUnit, campaign.repeatFrequency, start)}
            </ReviewBigText>
          </FlexLineItem>
        )}
        {sendType === 'recurring' && (
          <FlexLineItem grow={1}>
            <FlexLineItemTitle>At</FlexLineItemTitle>
            <ReviewBigText disabled={!start}>
              {start ? (
                <React.Fragment>
                  {start.format('HH:mm')}
                  <ReviewSmallText>
                    {tzAware ? "(in the user's timezone)" : <Utc m={start} />}
                  </ReviewSmallText>
                </React.Fragment>
              ) : (
                '—'
              )}
            </ReviewBigText>
          </FlexLineItem>
        )}
      </FlexLine>
      {sendType === 'trigger' && (
        <FlexLine
          top
          separator
          style={{
            padding: '15px 20px',
          }}
        >
          <FlexLineItem style={{ marginRight: 52 }}>
            <FlexLineItemTitle>Triggers on</FlexLineItemTitle>
            <ReviewBigText disabled={!ct.enterEvent}>
              {ct.enterEvent ? (
                <EventFormatter eventCode={ct.enterEvent} eventQuery={ct.enterEventQuery} />
              ) : (
                '—'
              )}
            </ReviewBigText>
          </FlexLineItem>
          {ct.delayMode === DELAY_MODE.TIMER && (
            <FlexLineItem style={{ marginRight: 52 }}>
              <FlexLineItemTitle>Waits for</FlexLineItemTitle>
              <ReviewBigText>{ct.pushTimer.valid && ct.pushTimer.fullText}</ReviewBigText>
            </FlexLineItem>
          )}
          {ct.hasExitEvent && (
            <FlexLineItem>
              <FlexLineItemTitle>Cancels on</FlexLineItemTitle>
              {ct.exitEvents.filter(e => Boolean(e.eventId)).size === 0 ? (
                <ReviewBigText disabled>—</ReviewBigText>
              ) : (
                <ReviewBigText>
                  {ct.exitEvents
                    .filter(e => !!e.eventId)
                    .map(({ eventId, query }, k) => (
                      <React.Fragment key={k}>
                        <EventFormatter eventCode={eventId} eventQuery={query} />
                        {k !== ct.exitEvents.size - 1 && <span>, </span>}
                      </React.Fragment>
                    ))}
                </ReviewBigText>
              )}
            </FlexLineItem>
          )}
          <FlexLineItem grow={1} />
          {ct.hasGrace && Boolean(ct.grace) && (
            <FlexLineItem style={{ marginRight: ct.hasCapping && ct.capping ? 52 : 0 }}>
              <FlexLineItemTitle>Grace period</FlexLineItemTitle>
              {ct.grace.valid ? (
                <ReviewBigText>{ct.grace.fullText}</ReviewBigText>
              ) : (
                <ReviewBigText disabled>—</ReviewBigText>
              )}
            </FlexLineItem>
          )}
          {ct.hasCapping && Boolean(ct.capping) && (
            <FlexLineItem>
              <FlexLineItemTitle>Capping</FlexLineItemTitle>
              <ReviewBigText>Max {ct.capping} per user</ReviewBigText>
            </FlexLineItem>
          )}
        </FlexLine>
      )}
      {sendType === 'trigger' && (ct.hasStart || ct.hasEnd) && (
        <FlexLine
          separator
          style={{
            padding: '15px 20px',
          }}
        >
          {ct.hasStart && (
            <FlexLineItem grow={1}>
              <FlexLineItemTitle>Start{past ? 'ed' : 's'} on</FlexLineItemTitle>
              <ReviewBigText>{ct.start && ct.start.format('MMM D, YYYY')}</ReviewBigText>
              <ReviewSmallText>
                {ct.start && ct.start.format('HH:mm')}
                {ct.start && <Utc m={ct.start} />}
              </ReviewSmallText>
            </FlexLineItem>
          )}
          {ct.hasEnd && (
            <FlexLineItem>
              <FlexLineItemTitle>End{past ? 'ed' : 's'} on</FlexLineItemTitle>
              <ReviewBigText>{ct.end && ct.end.format('MMM D, YYYY')}</ReviewBigText>
              <ReviewSmallText>
                {ct.end && ct.end.format('HH:mm')}
                {ct.end && <Utc m={ct.end} />}
              </ReviewSmallText>
            </FlexLineItem>
          )}
        </FlexLine>
      )}

      {sendType !== 'trigger' && (
        <FlexLine
          separator
          style={{
            padding: '15px 20px',
          }}
        >
          <FlexLineItem>
            <FlexLineItemTitle>
              {tzAware ? 'Longest send' : 'Estimate time'}
              {tzAware ? (
                <Hint>
                  Time to send the biggest timezone{' '}
                  {kformat(estimate.getIn(['tokens', 'matchingBiggestTz']))}
                </Hint>
              ) : (
                <Hint>Time to send the {kformat(estimate.getIn(['tokens', 'matching']))}</Hint>
              )}
            </FlexLineItemTitle>
            <div style={{ display: 'flex', alignItems: 'flex-end', gap: 16 }}>
              <ReviewBigText>{rateDuration(estimate.getIn(['tokens', timeToSend]))} </ReviewBigText>
              {durationLongerThanHours(estimate.getIn(['tokens', timeToSend]), 12) && (
                <p style={{ color: colors.textWarning }}>
                  <Icon icon="danger" style={{ marginRight: 8 }} />
                  Sending cannot last more than 12h, augment your sending speed or reduce target
                  size.
                </p>
              )}
            </div>
            <span>
              {estimate.getIn(['tokens', timeToSend], 0) > 1 &&
                estimate.getIn(['tokens', 'sendRate'], 0) < 1000000 && (
                  <a
                    href={generateUrl('company_billing', {
                      companyId: app.companyId,
                    })}
                    className="review__premiumbox__upgrade"
                    target="_blank"
                  >
                    Send it {/* harder, better, */} faster, {/* stronger, */} upgrade your plan.
                  </a>
                )}
            </span>
          </FlexLineItem>
        </FlexLine>
      )}
    </div>
  )
}
