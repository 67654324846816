// @flow

import { animated } from '@react-spring/web'
import styled, { type StyledComponent } from 'styled-components'

export const CollapsePanelContainer: StyledComponent<{ $isStandalone: boolean, ... }, *, *> =
  styled(animated.div)`
    display: grid;
    margin-top: ${(props: { $isStandalone: boolean, ... }) => (props.$isStandalone ? '0' : '8px')};

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  `

export const CollapsePanelContent: StyledComponent<{ ... }, *, *> = styled('div')`
  overflow: hidden;
`
