// @flow

import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Tooltip } from 'components/common/tooltip'

import { formatAudienceType } from 'com.batch.common/utils'

import { type CustomAudienceRecord } from 'com.batch.redux/_records'

export const OptionAudience = (
  option: CustomAudienceRecord,
  { context }: { context: 'value' | 'menu', ... }
): React.Node => {
  return context === 'value' ? (
    <React.Fragment>
      {option.description ? (
        <Tooltip tooltip={option.name}>
          <span>{option.description}</span>
        </Tooltip>
      ) : (
        option.name
      )}
    </React.Fragment>
  ) : (
    <Grid template="1fr auto">
      <span>{option.description || option.name}</span>
      <div style={{ textAlign: 'right' }}>
        <em>{formatAudienceType(option.type)}</em>
      </div>
    </Grid>
  )
}
