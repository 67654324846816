// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import * as colors from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'

import { ToggleSwitch } from './button.styles'

type SwitchContainerProps = { disabled?: boolean, ... }
export const SwitchContainer: StyledComponent<SwitchContainerProps, { ... }, *> = styled.label`
  input {
    display: none;
  }

  cursor: ${(p: SwitchContainerProps) => (p.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  /* remove label custom style */
  color: ${colors.text};
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 0;
`
type SwitchElementProps = { $isActive: boolean, disabled?: boolean, ... }
export const SwitchElement: StyledComponent<SwitchElementProps, *, HTMLElement> = styled.div`
  display: flex;
  width: 24px;
  flex: 0 0 24px;
  height: 15px;
  border-radius: 100px;
  align-items: center;
  ${(props: SwitchElementProps) =>
    props.$isActive
      ? css`
          background: ${(p: any) => (p.disabled ? colors.fillDisabled : colors.fillAction)};
          &:hover {
            background: ${(p: any) => (p.disabled ? colors.fillDisabled : colors.fillActionHover)};
          }
        `
      : css`
          background: ${(p: any) => (p.disabled ? colors.fillDisabled : schemes.grayscale['30'])};
          &:hover {
            background: ${(p: any) => (p.disabled ? colors.fillDisabled : schemes.grayscale['40'])};
          }
        `}

  ${ToggleSwitch} {
    transform: ${(p: SwitchElementProps) => (p.$isActive ? 'translateX(9px)' : 'none')};
  }
`
