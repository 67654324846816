// @flow
import styled, { type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

type Props = {
  $withAPIFlag: boolean,
  ...
}
export const OrchestrationNameWithFlagAndChannels: StyledComponent<
  Props,
  { ... },
  HTMLSpanElement,
> = styled.span`
  display: grid;
  grid-template-columns: 1fr ${(p: Props) => (p.$withAPIFlag ? 'auto' : '')};
  grid-column-gap: 8px;
  grid-row-gap: 4px;
  align-items: center;
`

export const OrchestrationChannelFlags: StyledComponent<{ ... }, { ... }, HTMLUListElement> =
  styled.ul`
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    grid-column-gap: 12px;
    line-height: 12px;
    li {
      > span {
        padding-top: 2px;
      }
      list-style: none;
      color: ${colors.textLight};
      ${IconContainer} {
        margin-right: 8px;
      }
    }
  `
