// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useFetchAppFromRouter } from 'components/_hooks'
import {
  PushOverviewMetricsEmptyTemplate,
  PushOverviewMetricsRateEmptyTemplate,
} from 'components/analytics/notification-utils'
import { PushOverviewMetric } from 'components/analytics/push-overview-metric'
import { PushOverviewMetrics } from 'components/analytics/push-overview-metrics'
import { TooltipDetail } from 'components/campaign/review/data/delivery.styles'
import { ttOpenRate } from 'components/campaign/review/review.data.selectors'
import { getChartFakeData } from 'components/charts/chart-helper'
import { LineChart } from 'components/charts/line-chart'
import { Box } from 'components/common/box'
import { Wrapper, AnalyticsEmptyIcon, GlobalErrorIcon } from 'components/common/empty-states'
import { FlexLine, FlexLineItem } from 'components/common/flexline'

import { numberFormat, kformat } from 'com.batch.common/utils'

import { NotificationTable } from './notification-table'
import { useRange } from './use-range'

import { intentToFetchNotifications } from 'com.batch.redux/dataCampaign'
import {
  createNotificationAggregateSelector,
  sentDataPointSelector,
  createLoadingSelector,
  isEmptySelector,
  statusSelector,
} from 'com.batch.redux/dataCampaign.selector'
import { setAnalyticsTab } from 'com.batch.redux/stat'

import { STATUS } from 'constants/common'

const COLOR = '#2A4365'

const emptyChart = getChartFakeData({})

export const Notification = (): React.Node => {
  const dispatch = useDispatch()
  const { pushAnalyticsTab } = useParams()
  const pushAnalyticsTabWithAllAsDefault = React.useMemo(
    () =>
      pushAnalyticsTab === 'campaign'
        ? 'campaign'
        : pushAnalyticsTab === 'transac'
          ? 'transac'
          : 'all',
    [pushAnalyticsTab]
  )

  const { range } = useRange()
  const app = useFetchAppFromRouter()
  // ====================== REDUX STATE
  const loadingSummary = useSelector(createLoadingSelector('summary'))
  const loadingTable = useSelector(
    createLoadingSelector(
      pushAnalyticsTabWithAllAsDefault === 'all' || pushAnalyticsTabWithAllAsDefault === undefined
        ? 'summary'
        : pushAnalyticsTabWithAllAsDefault === 'campaign'
          ? 'marketing'
          : 'transactional'
    )
  )
  const status = useSelector(statusSelector)
  const sum = useSelector(createNotificationAggregateSelector(p => p.id.kind === 'category'))
  const isEmpty = useSelector(isEmptySelector)
  const series = useSelector(sentDataPointSelector)
  const sumCampaign = useSelector(
    createNotificationAggregateSelector(p => p.id.kind === 'category' && p.id.value === 'marketing')
  )
  const sumTransac = useSelector(
    createNotificationAggregateSelector(
      p => p.id.kind === 'category' && p.id.value === 'transactional'
    )
  )

  // ====================== LOCAL STATE
  const [overIndex, setOverIndex] = React.useState<?number>(null)
  const allNotifications = sum.push

  // ====================== EMPTY STATE
  const isLoading = status === STATUS.INIT || status === STATUS.LOADING
  const isError = status === STATUS.ERROR
  const isEmptyAfterLoading = isEmpty && !isLoading
  const isGlobalOverlayShown = isError || isEmptyAfterLoading
  const isLoadingSummary = (isLoading && !loadingTable) || loadingSummary

  // ====================== EFFECTS

  React.useEffect(() => {
    dispatch(setAnalyticsTab('push'))
    dispatch(
      intentToFetchNotifications({
        from: range.from,
        to: range.to,
        category:
          pushAnalyticsTabWithAllAsDefault === 'all'
            ? 'summary'
            : pushAnalyticsTabWithAllAsDefault === 'campaign'
              ? 'marketing'
              : 'transactional',
      })
    )
    // je ne passe pas directement range car l'injector est mal foutu (pas immutbale, pointer diff)
  }, [dispatch, range.from, range.to, pushAnalyticsTabWithAllAsDefault])

  return (
    <Wrapper
      isEmpty={isError || isEmptyAfterLoading}
      isLoading={isLoadingSummary}
      isOverlayShown={isGlobalOverlayShown}
      overlayProps={
        isError
          ? {
              status: 'empty-page',
              title: "Couldn't load data",
              description:
                'There has been an error during the loading of this page, try to refresh or contact the support team if this continues to happen.',
              content: <GlobalErrorIcon />,
              refresh: () => {
                dispatch(setAnalyticsTab('push'))
                dispatch(
                  intentToFetchNotifications({
                    from: range.from,
                    to: range.to,
                    category:
                      pushAnalyticsTabWithAllAsDefault === 'all'
                        ? 'summary'
                        : pushAnalyticsTabWithAllAsDefault === 'campaign'
                          ? 'marketing'
                          : 'transactional',
                  })
                )
              },
            }
          : {
              status: 'empty-page',
              title: 'No data for this date range',
              description:
                'We haven’t been able to find any data for the selected dates. If the SDK is up and running,      you may need to select a different/larger date range.',
              content: <AnalyticsEmptyIcon />,
              links: [
                {
                  name: 'Troubleshooting',
                  href: 'https://doc.batch.com/dashboard/analytics/troubleshooting.html',
                },
                {
                  name: 'Install the SDK',
                  href: 'https://batch.com/download',
                },
              ],
            }
      }
    >
      <Box
        style={{
          overflow: 'hidden',
          borderTop: isLoadingSummary || isError ? '3px solid #b8b8b8' : '',
        }}
      >
        <Helmet>
          <title>Push — Analytics — {app?.name}</title>
        </Helmet>
        <FlexLine sameHeight>
          <PushOverviewMetrics emptyTemplate={<PushOverviewMetricsEmptyTemplate />}>
            <PushOverviewMetric
              grow={1}
              value={allNotifications.total.sent}
              color={COLOR}
              label="Total Push Sent"
              tooltip={`${numberFormat(
                allNotifications.total.sent
              )} sent on the Live API key, ${numberFormat(
                allNotifications.total.devSent
              )} sent on the DEV API key`}
            />
            <PushOverviewMetric
              small
              value={sumCampaign.push.total.sent}
              color={COLOR}
              label="Campaigns"
              tooltip={`${numberFormat(
                sumCampaign.push.total.sent
              )} sent from the dashboard or the campaign API`}
            />
            <PushOverviewMetric
              small
              value={sumTransac.push.total.sent}
              color={COLOR}
              label="Transactional"
              tooltip={`${numberFormat(
                sumTransac.push.total.sent
              )} sent from the transactional API`}
            />
            <PushOverviewMetric
              small
              value={sum.errors.reduce((acc, current) => current + acc, 0)}
              color="crimson"
              alignRight
              label="Errors"
              tooltip={
                <React.Fragment>
                  {new Immutable.List().push(...sum.errors).map(([code, count]) => (
                    <TooltipDetail key={code}>
                      <strong>{kformat(count)}</strong>
                      <label>{code}</label>
                    </TooltipDetail>
                  ))}
                </React.Fragment>
              }
            />
          </PushOverviewMetrics>
        </FlexLine>
        <LineChart
          sets={loadingSummary || isEmpty ? emptyChart : series}
          height={80}
          setOverIndex={setOverIndex}
          overIndex={overIndex}
          timeline
          loading={loadingSummary || isEmptyAfterLoading || isError || isEmpty}
        />
      </Box>

      <Box
        style={{
          overflow: 'hidden',
          borderTop: isLoadingSummary || isError ? '3px solid #b8b8b8' : '',
        }}
      >
        <FlexLine sameHeight>
          <PushOverviewMetrics emptyTemplate={<PushOverviewMetricsRateEmptyTemplate />}>
            <PushOverviewMetric
              isRatio
              value={allNotifications.total.openRate}
              color="#00A1E0"
              label="Open rate"
              tooltip={ttOpenRate[app?.openRateAlg ?? 'ACCURATE']}
            />
            <PushOverviewMetric
              value={allNotifications.total.open}
              small
              color="#00A1E0"
              label="Direct open"
              tooltip={`${numberFormat(
                allNotifications.total.open
              )} direct opened on the Live API key, ${numberFormat(
                allNotifications.total.devOpen
              )} direct opened on the DEV API key`}
            />
            <PushOverviewMetric
              value={allNotifications.total.influencedOpen}
              small
              color="#00A1E0"
              label="Influenced open"
              tooltip={`${numberFormat(allNotifications.total.influencedOpen)} influenced opened`}
            />
            <FlexLineItem grow={1} tinyContainer style={{ boxShadow: '0 2px 0 0 #00A1E0 inset' }} />
            <PushOverviewMetric
              borderLeft
              value={allNotifications.total.reengageRate}
              isRatio
              color="#109010"
              label="Reengage rate"
              tooltip="People who received the notification and became engaged 3 days after receiving it"
            />
            <PushOverviewMetric
              small
              value={allNotifications.total.reengaged}
              color="#109010"
              label="Users"
              tooltip={`${numberFormat(allNotifications.total.reengaged)} reengaged users`}
            />
            <FlexLineItem grow={1} tinyContainer style={{ boxShadow: '0 2px 0 0 #109010 inset' }} />
          </PushOverviewMetrics>
        </FlexLine>
      </Box>
      {app ? (
        <NotificationTable
          tab={pushAnalyticsTabWithAllAsDefault}
          loading={loadingTable}
          app={app}
          isGlobalOverlayShown={isGlobalOverlayShown}
          range={range}
          hasMarketing={(sumCampaign.push.total.sent ?? 0) > 0}
          hasTransac={(sumTransac.push.total.sent ?? 0) > 0}
        />
      ) : null}
    </Wrapper>
  )
}
