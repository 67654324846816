// @flow

import styled, { type StyledComponent, type PropsWithTheme } from 'styled-components'

import * as colorsLegacy from 'components/styled/colors'
import { LinkArrow } from 'components/styled/text'
import { colors, schemes } from 'components/styled/tokens'

// ====================== STYLED COMPONENTS
export const NotifierContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  position: fixed;
  padding-top: 20px;
  padding-right: 24px;
  top: 0;
  right: 0;
  width: calc(300px + 24px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1101;
  pointer-events: none;
  overflow: visible;
`
type NotifierMessageContainerProps = { noTimer: boolean, ... }
type ThemeProps = { kind: 'success' | 'error' | 'danger', ... }
type ThemedNotifierMessageContainerProps = PropsWithTheme<NotifierMessageContainerProps, ThemeProps>
export const NotifierMessageContainer: StyledComponent<
  NotifierMessageContainerProps,
  ThemeProps,
  HTMLElement,
> = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 35px minmax(0px, 1fr) 43px;
  width: fit-content;
  max-width: 300px;
  min-height: 40px;
  padding: 5px 6px 5px 7px;
  margin-bottom: 10px;
  background-color: ${schemes.grayscale['80']};
  border-radius: 3px;
  pointer-events: auto;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    min-height: 10px;
    height: calc(100% - 10px);
    width: 3px;
    background-color: ${(p: ThemedNotifierMessageContainerProps) =>
      p.theme.kind === 'success'
        ? colorsLegacy.good.shade.s1
        : p.theme.kind === 'error'
          ? colors.fillDanger
          : '#FFF'};
    border-radius: 3px;
  }
`

export const NotifierIcon: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px 0 9px;
  font-size: 16px;

  color: ${(p: PropsWithTheme<any, any>) =>
    p.theme.kind === 'success'
      ? colorsLegacy.good.shade.s1
      : p.theme.kind === 'error'
        ? colors.textDanger
        : '#FFF'};
  svg {
    margin-top: -5px;
  }
`

export const NotifierContent: StyledComponent<{ _hasLinks: boolean, ... }, *, HTMLElement> =
  styled.div`
    align-self: center;

    h4,
    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: ${schemes.lightlucent['100']};
    }

    h4 {
      margin-bottom: 0;
      font-weight: 500;
    }

    .styled-notifier-msg {
      ${'' /* margin: 4px 0; */}
      margin-top: 6px;
      margin-bottom: ${(p: PropsWithTheme<{ _hasLinks: boolean, ... }, any>) =>
        p._hasLinks ? 4 : 6}px;
    }

    .styled-notifier-links {
      margin-bottom: 6px;
      line-height: 16px;
    }
  `

export const NotifierClose: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;

  button {
    margin: 0 8px;
  }

  svg {
    color: ${schemes.lightlucent['100']};
  }
`

export const NotifierLink: StyledComponent<*, *, *> = styled(LinkArrow)`
  &,
  &:visited {
    color: ${colors.textActionContrast};
  }

  &:hover,
  &:active {
    color: ${schemes.blue['40']};
  }
`
