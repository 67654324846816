// @flow
import { type List } from 'immutable'
import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
// import { Ellipsis } from 'components/styled/text'

import {
  getPushSubscriptionMessage,
  getWebpushSubscriptionMessage,
} from 'com.batch/profile/infra/formats/get-push-subscription-message'
import {
  type InstallRecord,
  type ProfileWebpushPlatformsRecord,
} from 'com.batch/profile/models/profile.records'
import {
  ProfileBodyTitle,
  ChannelBlock,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { PushSubscriptionBlock } from 'com.batch/profile/ui/components/profile-hero/push-subscription-block/push-subscription-block'

type Props = {
  isLoading?: boolean,
  mobilePlatforms: List<InstallRecord>,
  webpushPlatforms: List<ProfileWebpushPlatformsRecord>,
}

export const PushChannelBlock = ({
  isLoading = false,
  mobilePlatforms,
  webpushPlatforms,
}: Props): React.Node => {
  const pushSubscriptionStatus = React.useMemo(
    () => getPushSubscriptionMessage(mobilePlatforms),
    [mobilePlatforms]
  )

  const webpushSubscriptionStatus = React.useMemo(
    () => getWebpushSubscriptionMessage(webpushPlatforms),
    [webpushPlatforms]
  )

  return (
    <ChannelBlock>
      <ProfileBodyTitle>
        <h2>
          <Skeleton w={22} h={22}>
            <Icon icon="push" />
          </Skeleton>
          <Skeleton w={80} h={22} style={{ marginLeft: 8 }}>
            <span>Push</span>
          </Skeleton>
        </h2>
      </ProfileBodyTitle>

      <Grid gap={16} template="repeat(2, minmax(100px, 1fr))">
        <PushSubscriptionBlock
          icon="mobile"
          isLoading={isLoading}
          message={pushSubscriptionStatus.message}
          subscriptionIcon={pushSubscriptionStatus.subscriptionIcon}
          subscriptionStatus={pushSubscriptionStatus.subscriptionStatus}
          title="Mobile app"
          tooltipMessage={pushSubscriptionStatus.tooltipMessage}
          tooltipTitle={pushSubscriptionStatus.tooltipTitle}
        />
        <PushSubscriptionBlock
          icon="world"
          isLoading={isLoading}
          message={webpushSubscriptionStatus.message}
          subscriptionIcon={webpushSubscriptionStatus.subscriptionIcon}
          subscriptionStatus={webpushSubscriptionStatus.subscriptionStatus}
          title="Web"
          tooltipMessage={webpushSubscriptionStatus.tooltipMessage}
        />
      </Grid>
    </ChannelBlock>
  )
}
