// @flow

import * as React from 'react'
import { type Node } from 'react'

import { FormGroup } from './wrap-label.styles'

type WrapLabelProps = {
  label: Node,
  children: ?Node,
  checkbox?: boolean,
  id?: string,
  ...
}
export const WrapLabel = ({
  label,
  children,
  checkbox = false,
  id,
  ...props
}: WrapLabelProps): React$Node => {
  if (checkbox) {
    return (
      <div className="checkbox">
        <label>
          {children}
          {label}
        </label>
      </div>
    )
  }
  return (
    <FormGroup {...props}>
      {id ? (
        <label htmlFor={id} style={{ marginBottom: 8 }}>
          {label}
        </label>
      ) : (
        <label style={{ marginBottom: 8 }}>{label}</label>
      )}
      {children}
    </FormGroup>
  )
}
