// @flow

import { type Dayjs } from 'dayjs'
import * as Immutable from 'immutable'
import { type Map, type RecordFactory, type RecordOf, type Set } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type fetchingState } from './_records'

import {
  EspConfigurationFactory,
  type EspConfigurationRecord,
} from 'com.batch.redux/corelogic/records/esp-configuration.records'

import {
  type SspConfigurationRecord,
  SspConfigurationFactory,
} from 'com.batch/sms/infra/models/ssp-configuration.record'

// ====================== PROJECTS RECORD
type ProjectProps = {
  id: string,
  name: string,
  iconUrl: string,
  loadingState: fetchingState,
  appCount: number,
  appIds: Set<number>,
  emailConfigured: boolean,
  smsConfigured: boolean,
  pushConfigured: boolean,
  webPushConfigured: boolean,
  anyPushConfigured: boolean,
  companyId: number,
  projectKey: string,
  espConfiguration?: EspConfigurationRecord,
  createdAt: Dayjs,
  sspConfiguration?: SspConfigurationRecord,
}

export const ProjectFactory: RecordFactory<ProjectProps> = Immutable.Record(
  ({
    id: '',
    name: '',
    loadingState: 'INIT',
    iconUrl: '',
    appCount: 0,
    emailConfigured: false,
    smsConfigured: false,
    pushConfigured: false,
    webPushConfigured: false,
    anyPushConfigured: false,
    companyId: 0,
    appIds: Immutable.Set(),
    projectKey: '',
    espConfiguration: EspConfigurationFactory(),
    createdAt: dayjs(),
    sspConfiguration: SspConfigurationFactory(),
  }: ProjectProps)
)
export type ProjectRecord = RecordOf<ProjectProps>

type ProjectStateProps = {
  entities: Map<string, ProjectRecord>,
  currentProjectId: ?string,
  ...
}
export const ProjectStateFactory: RecordFactory<ProjectStateProps> = Immutable.Record(
  ({
    entities: Immutable.Map(),
    currentProjectId: null,
  }: ProjectStateProps)
)

export type ProjectStateRecord = RecordOf<ProjectStateProps>
