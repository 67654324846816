// @flow
import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Separator } from 'components/styled/utils'

import { kformat } from 'com.batch.common/utils'

import { ChannelMetricsBox } from './channels-metrics.styles'

import { ChannelMetric } from 'com.batch/profilebase/ui/components/channels-metrics/channel-metric'

type Props = {
  email: number,
  sms: number,
  push: number,
  webpush: number,
}
export const ChannelsMetrics = ({ email, sms, push, webpush }: Props): React.Node => (
  <ChannelMetricsBox>
    <Grid gap={0} template={'1fr 1px 2fr 1px 1fr'} alignItems="stretch">
      <ChannelMetric
        channel="email"
        metrics={[{ value: kformat(email), label: 'profiles with email address' }]}
      />
      <Separator fullHeight />
      <ChannelMetric
        channel="push"
        metrics={[
          {
            value: kformat(push),
            label: (
              <React.Fragment>
                with a mobile app <Icon icon="mobile" />
              </React.Fragment>
            ),
          },
          {
            value: kformat(webpush),
            label: (
              <React.Fragment>
                with a web browser <Icon icon="world" />
              </React.Fragment>
            ),
          },
        ]}
      />
      <Separator fullHeight />
      <ChannelMetric
        channel="sms"
        metrics={[{ value: kformat(sms), label: 'profiles with phone number' }]}
      />
    </Grid>
  </ChannelMetricsBox>
)
