// @flow
import * as React from 'react'

import { BoxHeader } from 'components/common/box'

import { type IdentityRecord } from 'com.batch/profile/models/profile.records'
import { ProfileAvatar } from 'com.batch/profile/ui/components/profile-hero/profile-avatar'
import { ProfileHeroInformation } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { ProfileIdentifier } from 'com.batch/profile/ui/components/profile-hero/profile-identifier'
import { ProfileLang } from 'com.batch/profile/ui/components/profile-hero/profile-lang'
import { ProfileLastVisit } from 'com.batch/profile/ui/components/profile-hero/profile-last-visit'
import { ProfileUsername } from 'com.batch/profile/ui/components/profile-hero/profile-username'
import { ProfileUtc } from 'com.batch/profile/ui/components/profile-hero/profile-utc'

type Props = {
  identity: IdentityRecord,
  email: ?string,
  profileId: string,
  isLoading: boolean,
  timezone: ?string,
  lastVisitDate: ?string,
  ...
}

export const ProfileHeroHeader = ({
  isLoading,
  identity,
  email,
  profileId,
  timezone,
  lastVisitDate,
}: Props): React.Node => {
  return (
    <BoxHeader>
      <div>
        <ProfileAvatar profileId={profileId} />
        <ProfileHeroInformation isLoading={isLoading}>
          <ProfileUsername isLoading={isLoading} email={email} />
          <div className="details">
            <ProfileLastVisit isLoading={isLoading} date={lastVisitDate} />
            <ProfileLang
              isLoading={isLoading}
              language={identity.language}
              region={identity.region}
            />
            <ProfileUtc isLoading={isLoading} timezone={timezone} />
          </div>
        </ProfileHeroInformation>
      </div>
      <ProfileIdentifier isLoading={isLoading} id={identity.customUserId} />
    </BoxHeader>
  )
}
