// @flow

import * as React from 'react'

/*
  Detects when the user drags a file within the page and drop it
*/

export function useDraggingFile(): { isDragging: boolean, dropped: boolean, ... } {
  const [isDragging, setIsDragging] = React.useState(false)
  const [dropped, setDropped] = React.useState(false)

  const currentDragEnterTarget = React.useRef<?EventTarget>(null)

  const onDragStart = React.useCallback((e: DragEvent) => {
    e.preventDefault()
  }, [])
  const onDragEnter = React.useCallback((e: DragEvent) => {
    currentDragEnterTarget.current = e.target
    if (document.body?.style && document.body.style.cursor !== 'grabbing') {
      document.body.style.cursor = 'grabbing'
    }
  }, [])
  const onDrop = React.useCallback((e: DragEvent) => {
    e.preventDefault()
    setIsDragging(false)
    setDropped(true)
    if (document.body?.style) {
      document.body.style.cursor = ''
    }
  }, [])
  const onDragOver = React.useCallback(
    (e: DragEvent) => {
      e.preventDefault()
      if (!isDragging) {
        setIsDragging(true)
      }
      if (dropped) {
        setDropped(false)
      }
    },
    [isDragging, dropped]
  )
  const onDragLeave = React.useCallback((e: DragEvent) => {
    /*
      As dragenter fires before dragleave, if the current dragleave target is the same as
      the current dragenter target that means the cursor did not enter another element thus
      exited the window
    */
    if (currentDragEnterTarget.current === e.target) {
      setIsDragging(false)
      if (document.body?.style) {
        document.body.style.cursor = ''
      }
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('dragstart', onDragStart)
    document.addEventListener('dragenter', onDragEnter)
    document.addEventListener('dragover', onDragOver)
    document.addEventListener('dragleave', onDragLeave)
    document.addEventListener('drop', onDrop)

    return () => {
      document.removeEventListener('dragstart', onDragStart)
      document.removeEventListener('dragenter', onDragEnter)
      document.removeEventListener('dragover', onDragOver)
      document.removeEventListener('dragleave', onDragLeave)
      document.removeEventListener('drop', onDrop)
    }
  }, [onDragEnter, onDragOver, onDragLeave, onDrop, onDragStart])

  return { isDragging, dropped }
}
