// @flow

import * as React from 'react'

import { Input, AutoComplete } from 'components/form'

import { requiredString } from 'com.batch.common/validators'

import { useSearchOptions, type InputProps } from './helper'

import { StartsWithOperator, EndsWithOperator } from 'com.batch.redux/query/query.records.operators'

type LabelValue = { label: string, value: string, ... }
const stringToLabelValue = (value: string) => ({
  label: value,
  value,
})

const OptFormater = (opt: LabelValue | { ...LabelValue, __isNew__: boolean, ... }) =>
  opt?.__isNew__ ? (
    <React.Fragment>
      <em>Create </em>
      {opt.value}
    </React.Fragment>
  ) : (
    opt.value
  )

export const InputString = ({ condition, updateCondition, isInvalid }: InputProps): React.Node => {
  const attributeId = React.useMemo(
    () => condition?.attribute?.api ?? '',
    [condition?.attribute?.api]
  )
  const isTag = condition?.attribute?.type === 'TAG'
  const loadOptions = useSearchOptions(attributeId, isTag)
  const loadOptionsAsLabelValue = React.useCallback(
    (q: string) => loadOptions(q).then(opts => opts.map(stringToLabelValue)),
    [loadOptions]
  )

  const showSelect = React.useMemo(
    () => condition.operator !== StartsWithOperator && condition.operator !== EndsWithOperator,
    [condition.operator]
  )

  const [invalid, setInvalid] = React.useState(false)
  const [isTouched, setIsTouched] = React.useState(false)

  const onChangeLocal = (value: string) => {
    setInvalid(!requiredString(value))
    updateCondition(condition.set('value', condition.value.set('string', value ?? '')))
  }

  const onBlur = () => {
    setIsTouched(true)
  }

  return showSelect ? (
    <AutoComplete
      key={attributeId}
      optionToString={opt => opt?.label ?? ''}
      loadOptions={loadOptionsAsLabelValue}
      invalid={(invalid && isTouched) || isInvalid}
      optionCreator={text => {
        return { label: text, value: text }
      }}
      onBlur={onBlur}
      optionFormatter={OptFormater}
      value={stringToLabelValue(condition.get('value').string)}
      onChange={opt => onChangeLocal(opt?.value ?? '')}
    />
  ) : (
    <Input
      type="text"
      value={condition.get('value').string}
      onBlur={onBlur}
      invalid={(invalid && isTouched) || isInvalid}
      onChange={evt => onChangeLocal(evt.target.value)}
    />
  )
}
