// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'components/common/svg-icon'

import {
  BrowserIcon,
  Message,
  Notif,
  NotifSettings,
  ReceivedDate,
  Sender,
  Thumb,
  Title,
} from './web-mac.styles'

import {
  LegacyEditableField,
  LegacyMediaField,
  type ThemeProps,
} from 'com.batch/message-builder/ui/components/common'

type WebMacProps = { variant: 'a' | 'b', ... }

export const WebMac = ({ variant }: WebMacProps): React.Node => {
  const { app, isExpanded } = useTheme<ThemeProps>()
  const website = React.useMemo(() => {
    const tmp = app?.bundleId.replace('http://', '').replace('https://', '').split('/')
    if (tmp) {
      return tmp[0]
    }
  }, [app?.bundleId])

  return (
    <Notif>
      <NotifSettings>
        <Icon icon="options" style={{ marginRight: 10 }} />
        <Icon icon="chevron-down" />
      </NotifSettings>
      {/* Si on a un icon, on l'affiche en mode collapsed */}
      <LegacyMediaField variant={variant} field="icon">
        {iconProps => (
          <LegacyMediaField variant={variant} field="media">
            {({ url, overlay }) => (
              <Thumb $url={!isExpanded && iconProps.url ? iconProps.url : url}>
                {!isExpanded && iconProps.url ? iconProps.overlay : overlay}
              </Thumb>
            )}
          </LegacyMediaField>
        )}
      </LegacyMediaField>
      <Title>
        <LegacyEditableField field="title" variant={variant} />
      </Title>
      <ReceivedDate>Now</ReceivedDate>
      <BrowserIcon>
        <img src="/medias/icons/chrome-colored.svg" alt="Google Chrome icon" />
      </BrowserIcon>
      <Sender>{website}</Sender>
      <Message>
        <LegacyEditableField field="message" variant={variant} />
      </Message>
    </Notif>
  )
}
