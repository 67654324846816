// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Title } from 'components/styled/text'
import { texts } from 'components/styled/tokens'

import { capitalize, kformat, percentage, pluralizeAndKformat } from 'com.batch.common/utils'

import { PlatformFilter, type PlatformFilterOption } from './platform-filter'

import { orchestrationPlatformsSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { STAT_TYPE } from 'com.batch/orchestration-analytics/infra/types/stat-type'
import { type StatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import {
  StatsBlocksRow,
  StatsContainer,
} from 'com.batch/orchestration-analytics/ui/components/orchestration-analytics/orchestration-analytics.styles'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { type PlatformFilterDimensionValue } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { MetricBlock } from 'com.batch/shared/ui/component/metric-block/metric-block'
import { MetricBlockCard } from 'com.batch/shared/ui/component/metric-block/metric-block.styles'
import { Trend, TrendTooltip } from 'com.batch/shared/ui/component/trend/trend'
import { STATUS } from 'constants/common'

export const PushAnalytics = (): React.Node => {
  const dispatch = useDispatch()
  const { token }: { token: string } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const isOrchestration = React.useMemo(() => schedulingType === 'automations', [schedulingType])
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const stats: ?StatsRecord = React.useMemo(
    () => analyticsMap.getIn([token, 'channels', 'push', 'stats']),
    [analyticsMap, token]
  )
  const loadingState: LoadingState = React.useMemo(
    () => analyticsMap.get(token)?.pushOnlyLoadingState ?? 'INIT',
    [analyticsMap, token]
  )
  const isLoading = React.useMemo(
    () => [STATUS.LOADING, STATUS.INIT].includes(loadingState),
    [loadingState]
  )
  const isOverlayShown = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])
  const { dateRange, filters } = useGetAnalyticsFilters()

  const orchestrationPlatforms = useSelector(orchestrationPlatformsSelector)
  const [selectedPlatformOption, setSelectedPlatformOption] = React.useState<PlatformFilterOption>(
    () => {
      if (orchestrationPlatforms.has('ios') && orchestrationPlatforms.has('android')) {
        return 'mobile'
      }
      return orchestrationPlatforms.first()
    }
  )
  const platformFilter = React.useMemo(() => {
    let platformFilterList = new Immutable.List<PlatformFilterDimensionValue>()
    switch (selectedPlatformOption) {
      case 'mobile':
        platformFilterList = platformFilterList.push('IOS', 'ANDROID')
        break
      case 'ios':
        platformFilterList = platformFilterList.push('IOS')
        break
      case 'android':
        platformFilterList = platformFilterList.push('ANDROID')
        break
      case 'webpush':
        platformFilterList = platformFilterList.push('WEBPUSH')
        break
    }
    return platformFilterList
  }, [selectedPlatformOption])

  React.useEffect(() => {
    if (token) {
      const tokens = new Immutable.List().push(token)
      dispatch(
        fetchDynamicStats({
          tokens,
          filters: filters.set('platform', platformFilter),
          dateRange,
          config: 'orchestrationStatsPushOnly',
          includePreviousPeriod: true,
        })
      ).catch(() => {})
    }
  }, [dispatch, token, filters, dateRange, platformFilter])

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ margin: '0 0 24px' }}>
        <Grid template="auto auto" gap={8}>
          <Title>
            <Icon icon="push" size={22} />
            Push
          </Title>
          {orchestrationPlatforms.size > 1 && (
            <PlatformFilter
              selectedPlatformOption={selectedPlatformOption}
              setSelectedPlatformOption={setSelectedPlatformOption}
            />
          )}
        </Grid>
      </ChannelSectionTitle>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={GlobalErrorOverlayProps}
        boxed
      >
        <StatsBlocksRow>
          <MetricBlockCard>
            <MetricBlock
              variant="success"
              label={capitalize(STAT_TYPE.SENT)}
              value={kformat(stats?.sent.value ?? 0)}
              hint="Push notifications are sent to all opted-in devices associated to Profiles"
              trend={
                dateRange && isOrchestration ? (
                  <Trend
                    rate={stats?.sent.trend ?? 0}
                    tooltip={
                      <TrendTooltip
                        metricContent={
                          <span>
                            <strong>{kformat(stats?.sent.previousPeriodValue ?? 0)}</strong> sent
                            push notification
                            {stats?.sent.previousPeriodValue ?? 0 === 1 ? '' : 's'}
                          </span>
                        }
                        dateRange={dateRange}
                      />
                    }
                  />
                ) : undefined
              }
            />
          </MetricBlockCard>
          <MetricBlockCard>
            <MetricBlock
              variant="action"
              label={capitalize(STAT_TYPE.OPENED)}
              value={
                <span>
                  {percentage(stats?.open.rate ?? 0, 2, false, false)}
                  <span style={texts.metric}>%</span>
                </span>
              }
              subtitle={pluralizeAndKformat('unique open', stats?.open.unique ?? 0)}
              trend={
                dateRange && isOrchestration ? (
                  <Trend
                    rate={stats?.open.trend ?? 0}
                    tooltip={
                      <TrendTooltip
                        metricContent={
                          <span>
                            <strong>{percentage(stats?.open.previousPeriodRate ?? 0, 2)}</strong>{' '}
                            opened push notifications
                          </span>
                        }
                        dateRange={dateRange}
                      />
                    }
                  />
                ) : undefined
              }
            />
          </MetricBlockCard>
          <MetricBlockCard>
            <MetricBlock
              variant="danger"
              label={capitalize(STAT_TYPE.BOUNCED)}
              value={
                <span>
                  {percentage(stats?.bounce.rate ?? 0, 2, false, false)}
                  <span style={texts.metric}>%</span>
                </span>
              }
              subtitle={`${kformat(stats?.bounce.value ?? 0)} unique bounces`}
              trend={
                dateRange && isOrchestration ? (
                  <Trend
                    rate={stats?.bounce.trend ?? 0}
                    isGrowthPositive={false}
                    tooltip={
                      <TrendTooltip
                        metricContent={
                          <span>
                            <strong>{percentage(stats?.bounce.previousPeriodRate ?? 0, 2)}</strong>{' '}
                            bounced push notifications
                          </span>
                        }
                        dateRange={dateRange}
                      />
                    }
                  />
                ) : undefined
              }
            />
          </MetricBlockCard>
        </StatsBlocksRow>
      </Wrapper>
    </StatsContainer>
  )
}
