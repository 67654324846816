// @flow
import { validateJSON } from 'com.batch.common/utils'

import {
  type PushSettingsRecord,
  type PushContentRecord,
} from 'com.batch/message/models/message.records'

export const getPushSettingsErrors = (settings: PushSettingsRecord): Array<string> => {
  let err: Array<string> = []
  if (settings.customPayload) {
    if (!validateJSON(settings.customPayload)) {
      err.push('Custom payload is not valid JSON')
    }
  }
  if (settings.ttlEnabled && !settings.ttl.valid)
    err.push('Expiration (TTL) must be between 1 minute & 28 days.')
  if (settings.iosOverride.customPayload) {
    if (!validateJSON(settings.iosOverride.customPayload)) {
      err.push('iOS custom payload is not valid JSON')
    }
  }
  if (settings.androidOverride.customPayload) {
    if (!validateJSON(settings.androidOverride.customPayload)) {
      err.push('Android custom payload is not valid JSON')
    }
  }
  return err
}

/* extracted to an helper file to avoid cycle dep */
export const getPushContentError = (contentForLang: PushContentRecord): Array<string> => {
  let err: Array<string> = []
  if (!contentForLang.content.pushTitle) err.push('Notification title is missing')
  if (!contentForLang.content.pushBody) err.push('Notification body is missing')

  return err
}
