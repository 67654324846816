// @flow

import * as React from 'react'
import { ThemeContext } from 'styled-components'

import { Button } from 'components/common/button'
import { EmptyField } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { randomSize } from 'com.batch.common/utils'

import { type ImageMetaProps } from './upload-image'
import {
  ImageRender,
  ImageRenderIcon,
  ImageRenderText,
  ImageRenderButton,
} from './upload-image.styles'

import { TYPE_ICON } from 'constants/push-settings/icons'

type UploadImageRenderProps = {
  value: string,
  isEditing: boolean,
  message: string,
  imageMeta: ImageMetaProps,
  removeFile: () => any,
  type: 'smallIcon' | 'defaultIcon',
  ...
}

export const UploadImageRender = ({
  value,
  isEditing,
  removeFile,
  imageMeta,
  message,
  type,
}: UploadImageRenderProps): React.Node => {
  const iconNotConfig = !isEditing && !value
  const theme = React.useContext(ThemeContext)
  const [emptyFieldSize] = React.useState(`${randomSize(20, 90)}%`)

  return (
    <ImageRender
      template={`84px minmax(100px, 1fr) ${isEditing ? '35px' : ''}`}
      gap={15}
      margin={[8, 8, 8, isEditing ? 8 : 0]}
    >
      <ImageRenderIcon iconNotConfig={iconNotConfig} image={value} type={type} />
      <ImageRenderText>
        {theme.isLoading ? (
          <EmptyField _width={emptyFieldSize} />
        ) : iconNotConfig ? (
          <p className="styled_img-render-no-config-text">Icon not configured</p>
        ) : (
          <React.Fragment>
            {!!message && (
              <p
                style={{
                  fontWeight: isEditing ? '500' : '400',
                  color: isEditing ? colors.textNeutral : 'inherit',
                }}
              >
                {message}
              </p>
            )}
            {isEditing && (
              <p>
                {imageMeta.width} x {imageMeta.height}
                {type === TYPE_ICON.SMALL && ' - Android visual processing'}
              </p>
            )}
          </React.Fragment>
        )}
      </ImageRenderText>
      {isEditing && (
        <ImageRenderButton>
          <Button type="button" onClick={removeFile}>
            <Icon icon="close" />
          </Button>
        </ImageRenderButton>
      )}
    </ImageRender>
  )
}
