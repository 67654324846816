// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { LanguageContainer, LanguageDeleteButton } from './legacy-lang-picker.styles'

import { type LanguageRecord } from 'com.batch.redux/_records'

type LangProps = {
  language: ?LanguageRecord,
  isActive: boolean,
  isValid?: boolean,
  isInvalid?: boolean,
  activate: () => void,
  remove?: () => void,
  ...
}

/**
 * @deprecated MEP lang picker
 */
export const LegacyLang: React.ComponentType<LangProps> = React.memo(
  ({ isActive, language, isValid, isInvalid, activate, remove }: LangProps) => {
    const removeAndPrevent = React.useCallback(
      evt => {
        remove?.()
        evt.stopPropagation()
      },
      [remove]
    )
    return (
      <LanguageContainer
        isActive={isActive}
        validity={isValid ? true : isInvalid ? false : undefined}
        isDeletable={Boolean(language && language.value !== 'default')}
        onClick={activate}
      >
        <span className="styled-icon">
          {isValid && <Icon icon="success" />}
          {isInvalid && <Icon icon="error" />}
        </span>
        <span className="styled-text">{language ? language.label : 'Default'}</span>
        {language && language.value !== 'default' && (
          <LanguageDeleteButton onClick={removeAndPrevent}>
            <Icon icon="remove" thickness={1.3} />
          </LanguageDeleteButton>
        )}
      </LanguageContainer>
    )
  }
)

LegacyLang.displayName = 'Lang'
