// @flow

import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { Title } from 'components/account/account.styles'
import { BillingPageLegacy } from 'components/account/plans/billing-page'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  profileVolumeSelector,
  sentVolumeSeclector,
  billingPageIsLoadingSelector,
} from 'com.batch/account/store/volumes.selector'
import { currentCompanySelector } from 'com.batch.redux/company'
import { projectsSelector } from 'com.batch.redux/project.selector'

import { getMonthList } from 'com.batch/account/infra/formats/get-month-list'
import { BillingSection } from 'com.batch/account/ui/components/billing-section'
import { VolumesSection } from 'com.batch/account/ui/components/volumes/volumes-section'
import { fetchMonthlyActiveProfiles } from 'com.batch/account/usecases/fetch-monthly-active-profiles'
import { fetchMonthlySentVolumes } from 'com.batch/account/usecases/fetch-monthly-sent'

export const BillingPage = (): React.Node => {
  const dispatch = useDispatch()
  const [search] = useSearchParams()

  const company = useSelector(currentCompanySelector)
  const projects = useSelector(projectsSelector)
  const profileVolume = useSelector(profileVolumeSelector)
  const sentVolume = useSelector(sentVolumeSeclector)
  const isLoading = useSelector(billingPageIsLoadingSelector)

  const hasProjects = React.useMemo(() => projects.size > 0, [projects])

  const startedDate = React.useMemo(() => {
    const olderProject = projects.map(p => p.createdAt).min()
    return olderProject ? olderProject.startOf('month') : dayjs().utc().startOf('month')
  }, [projects])

  const listMonthFilter = React.useMemo(() => getMonthList(startedDate), [startedDate])

  React.useEffect(() => {
    if (hasProjects) {
      const qProject = decodeURI(search.get('project') ?? '')
      const projectKeys =
        qProject && qProject !== 'all'
          ? [projects.find(f => f.id === qProject).projectKey]
          : projects.map(p => p.projectKey).toArray()

      const qMonth = decodeURI(search.get('month') ?? '')
      const month = (
        qMonth && qMonth !== 'Current month'
          ? listMonthFilter.find(f => f.name === qMonth).date
          : dayjs()
      )
        .startOf('month')
        .format('YYYY-MM')

      dispatch(fetchMonthlyActiveProfiles({ projectKeys, month })).catch(error =>
        console.error('Error :', error)
      )
      dispatch(fetchMonthlySentVolumes({ projectKeys, month })).catch(error =>
        console.error('Error :', error)
      )
    }
  }, [hasProjects, dispatch, projects, search, listMonthFilter])

  return (
    <React.Fragment key={company.id}>
      <ThemeProvider theme={{ isLoading }}>
        <Title>Plans &amp; billing</Title>
        {hasProjects ? (
          <React.Fragment>
            <VolumesSection
              projects={projects}
              profileVolume={profileVolume}
              sentVolume={sentVolume}
            />
            <BillingSection />
          </React.Fragment>
        ) : (
          <BillingPageLegacy />
        )}
      </ThemeProvider>
    </React.Fragment>
  )
}
