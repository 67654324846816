// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { schemes } from 'components/styled/tokens'

type TargetingSeparatorProps = { borderTop?: boolean, ... }
export const TargetingSeparator: StyledComponent<TargetingSeparatorProps, *, HTMLHeadingElement> =
  styled.h4`
    background: #fcfcfd;
    padding: 18px 28px;
    font-size: 13px;
    font-weight: 500;
    color: #adb1b8;
    color: #8d939d;
    border-bottom: 1px solid #f3f4f8;
    border-bottom: 1px solid ${schemes.darklucent['05']};
    ${(props: TargetingSeparatorProps) =>
      props.borderTop &&
      css`
        border-top: 1px solid #f3f4f8;
      `};
  `
