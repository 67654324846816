// @flow

import styled, { type StyledComponent } from 'styled-components'

export const PreviewSwitchContainer: StyledComponent<*, { ... }, *> = styled.div`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`
