// @flow
import * as React from 'react'

type RestrictedInputProps = {
  transform?: (text: string) => string,
  drop: string,
  onChange: (evt: SyntheticInputEvent<HTMLInputElement>) => void,
  ...
}
class RestrictedInput extends React.PureComponent<RestrictedInputProps> {
  onChange: (evt: SyntheticInputEvent<HTMLInputElement>) => void = (
    evt: SyntheticInputEvent<HTMLInputElement>
  ) => {
    if (typeof this.props.transform === 'function') {
      // $FlowFixMe
      evt.target.value = this.props.transform.call(evt.target.value)
    }
    evt.target.value = evt.target.value.replace(new RegExp(this.props.drop, 'g'), '')
    this.props.onChange(evt)
  }
  render(): React.Node {
    const { drop, transform, onChange, ...rest } = this.props //eslint-disable-line
    return <input {...rest} onChange={this.onChange} />
  }
}

export default RestrictedInput
