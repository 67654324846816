// @flow

import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchBoundFn } from 'com.batch.redux/_records'

import { fetchTemplate } from 'com.batch/message/usecases/fetch-templates'

export type UpdateSmsContentAction = {
  type: 'UPDATE_SMS_CONTENT',
  payload: {
    messageId: string,
    lang: string,
    field: 'smsMessage',
    value: string,
  },
}

let debounces: { [string]: TimeoutID } = {}
export const updateSmsContent = ({
  messageId,
  lang,
  field,
  value,
  isInstant = false,
}: {
  messageId: string,
  lang: string,
  field: 'smsMessage',
  value: string,
  isInstant?: boolean,
}): DispatchBoundFn<void> => {
  const contentIdentifier = `${messageId}_${lang}_${field}`
  clearTimeout(debounces[contentIdentifier])
  return dispatch => {
    dispatch({
      type: 'UPDATE_SMS_CONTENT',
      payload: {
        messageId,
        lang,
        field,
        value,
      },
    })

    debounces[contentIdentifier] = setTimeout(
      () => {
        if (textUsesTemplating(value)) {
          dispatch(fetchTemplate({ messageId, field, lang, value, type: 'TEMPLATE' }))
            .then(res => {
              const { template, value } = res[0]
              dispatch({
                type: 'UPDATE_SMS_TEMPLATE',
                payload: {
                  messageId,
                  lang,
                  field,
                  template: template ?? value,
                },
              })
            })
            .catch(e => {
              if (!e?.aborted) console.log(e)
            })
        } else {
          dispatch({
            type: 'UPDATE_SMS_TEMPLATE',
            payload: {
              messageId,
              lang,
              field,
              template: value,
            },
          })
        }
      },
      isInstant ? 0 : 500
    )
  }
}
