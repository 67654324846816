// @flow
import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

export type ClickLinkProps = {
  name: string,
  count: number,
  unique: number,
  rate: number,
}

export type ClickLinkRecord = RecordOf<ClickLinkProps>
export const ClickLinkFactory: RecordFactory<ClickLinkProps> = Immutable.Record(
  ({
    name: '',
    count: 0,
    rate: 0,
    unique: 0,
  }: ClickLinkProps)
)
