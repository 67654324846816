// @flow

import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'
import request from 'superagent-interface-promise'

import { normalizeDemoCodes } from './codes.api'
import {
  CodeStateFactory,
  type CodeStateRecord,
  type DemoCodeRecord,
  type State,
} from './console.records'

import { type DispatchOnlyBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'

// ====================== ACTIONS TYPE
type loadDemoCodesAction = {
  type: 'LOAD_DEMO_CODES',
  payload: null,
  ...
}
type loadDemoCodesSuccessAction = {
  type: 'LOAD_DEMO_CODES_SUCCESS',
  payload: List<DemoCodeRecord>,
  ...
}
type loadDemoCodesFailureAction = {
  type: 'LOAD_DEMO_CODES_FAILURE',
  payload: null,
  ...
}

type generateDemoCodeAction = {
  type: 'GENERATE_DEMO_CODE',
  payload: null,
  ...
}
type generateDemoCodeSuccessAction = {
  type: 'GENERATE_DEMO_CODE_SUCCESS',
  payload: DemoCodeRecord,
  ...
}
type generateDemoCodeFailureAction = {
  type: 'GENERATE_DEMO_CODE_FAILURE',
  payload: null,
  ...
}

type removeDemoCodeAction = {
  type: 'REMOVE_DEMO_CODE',
  payload: null,
  ...
}
type removeDemoCodeSuccessAction = {
  type: 'REMOVE_DEMO_CODE_SUCCESS',
  payload: string,
  ...
}
type removeDemoCodeFailureAction = {
  type: 'REMOVE_DEMO_CODE_FAILURE',
  payload: null,
  ...
}

type setAppDemoCodeAction = {
  type: 'SET_APP_DEMO_CODE',
  payload: DemoCodeRecord,
  ...
}

// ====================== ACTIONS
export const loadDemoCodes = (): DispatchOnlyBoundFn<Promise<List<DemoCodeRecord>>> => dispatch =>
  promiseActionCreator({
    actionName: 'LOAD_DEMO_CODES',
    dispatch,
    promise: request
      .get('/console/api/demo-codes')
      .then(({ body }) => {
        try {
          if (!Array.isArray(body)) return new Immutable.List()
          return new Immutable.List().push(...body.map(normalizeDemoCodes))
        } catch (err) {
          console.warn(err)
          return new Immutable.List()
        }
      }, console.log)
      .catch(err => {
        console.log(err)
        return new Immutable.List()
      }),
    payload: null,
  })

export const generateDemoCode =
  (newCode: {
    code: string,
    description: string,
    ios: string,
    android: string,
    ...
  }): DispatchOnlyBoundFn<Promise<List<DemoCodeRecord>>> =>
  dispatch => {
    return promiseActionCreator({
      actionName: 'GENERATE_DEMO_CODE',
      dispatch,
      promise: request
        .post('/console/api/demo-codes')
        .send(newCode)
        .then(({ body }) => normalizeDemoCodes(body)),
      payload: null,
    })
  }

export const removeDemoCode =
  (code: string): DispatchOnlyBoundFn<Promise<string>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'REMOVE_DEMO_CODE',
      dispatch,
      promise: request.delete('/console/api/demo-codes?code=' + code).then(() => code),
      payload: code,
    })

export const setAppDemoCode = (
  code: DemoCodeRecord
): { type: 'SET_APP_DEMO_CODE', payload: DemoCodeRecord, ... } => {
  return { type: 'SET_APP_DEMO_CODE', payload: code }
}

// ====================== COMBINE ACTION TYPE
type supportedActions =
  | loadDemoCodesAction
  | loadDemoCodesSuccessAction
  | loadDemoCodesFailureAction
  | generateDemoCodeAction
  | generateDemoCodeSuccessAction
  | generateDemoCodeFailureAction
  | removeDemoCodeAction
  | removeDemoCodeSuccessAction
  | removeDemoCodeFailureAction
  | setAppDemoCodeAction

// ====================== SELECTOR
const codesStateSelelctor = (state: State) => state.code

export const demoCodesSelector: State => List<DemoCodeRecord> = createSelector(
  codesStateSelelctor,
  (codeState: CodeStateRecord) => codeState.entities.valueSeq().toList()
)

// ====================== REDUCER
export const codeReducer = (
  state: CodeStateRecord = CodeStateFactory(),
  action: supportedActions
): CodeStateRecord => {
  switch (action.type) {
    case 'LOAD_DEMO_CODES':
    case 'GENERATE_DEMO_CODE':
    case 'REMOVE_DEMO_CODE':
      return state.set('loading', true)

    case 'LOAD_DEMO_CODES_SUCCESS': {
      let entitiesMap: Map<string, DemoCodeRecord> = Immutable.Map()
      action.payload.forEach(code => {
        entitiesMap = entitiesMap.set(code.id, code)
      })

      return state
        .set('loading', false)
        .set('entities', entitiesMap)
        .set('count', action.payload.size)
    }
    case 'GENERATE_DEMO_CODE_SUCCESS':
      return state
        .set('loading', false)
        .set('entities', state.entities.set(action.payload.id, action.payload))

    case 'REMOVE_DEMO_CODE_SUCCESS':
      return state.set('loading', false).set('entities', state.entities.remove(action.payload))

    case 'LOAD_DEMO_CODES_FAILURE':
    case 'GENERATE_DEMO_CODE_FAILURE':
    case 'REMOVE_DEMO_CODE_FAILURE':
      return state.set('loading', false)

    case 'SET_APP_DEMO_CODE':
      return state.set('entities', state.entities.set(action.payload.id, action.payload))
  }
  return state
}
