// @flow
import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTransmissionType } from 'components/app/push/sender-identity/get-transmission-type'
import { Button } from 'components/common/button'
import { Code } from 'components/common/code'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Grid } from 'components/common/grid'
import { Label } from 'components/common/label/label.styles'
import { Icon } from 'components/common/svg-icon'
import { Input, InputWrapper, Select } from 'components/form'

import {
  SenderIdentityAddress,
  SenderIdentityButton,
  SenderIdentityButtonZone,
  SenderIdentityForm,
} from './sender-identity.styles'

import {
  automationsUsingSelectedSenderIdentitySelector,
  canCancelSelector,
  canSaveSelector,
  creatingSenderIdentitySelector,
  previousSenderIdentitySelector,
  selectedSenderIdentityHasChangedSelector,
  selectedSenderIdentitySelector,
} from 'com.batch.redux/corelogic/selectors/sender-identity.selector'
import { createSenderIdentity } from 'com.batch.redux/corelogic/usecases/sender-identity/create-sender-identities'
import { deleteSenderIdentity } from 'com.batch.redux/corelogic/usecases/sender-identity/delete-sender-identities'
import {
  cancelCreatingAndSetPrevious,
  selectSenderIdentity,
  updateSelectedSenderIdentity,
} from 'com.batch.redux/corelogic/usecases/sender-identity/select-sender-identities'
import { updateSenderIdentity } from 'com.batch.redux/corelogic/usecases/sender-identity/update-sender-identities'
import { sendingDomainsSelector } from 'com.batch.redux/project.selector'

const IdentityToString = (op: ?string) => op ?? ''

export const SenderIdentitySelectedItem: React.ComponentType<{ ... }> = React.memo(() => {
  const dispatch = useDispatch()
  const selectedItem = useSelector(selectedSenderIdentitySelector)
  const previousItem = useSelector(previousSenderIdentitySelector)
  const creating = useSelector(creatingSenderIdentitySelector)
  const hasChanged = useSelector(selectedSenderIdentityHasChangedSelector)
  const sendingDomains = useSelector(sendingDomainsSelector)
  const canSave = useSelector(canSaveSelector)
  const canCancel = useSelector(canCancelSelector)
  const countOrchestrations = useSelector(automationsUsingSelectedSenderIdentitySelector)

  const sortedSendingDomains = React.useMemo(
    () => sendingDomains.map(sendingDomain => sendingDomain.sendingDomain).sort(),
    [sendingDomains]
  )

  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())

  const onSenderIdentityChange = React.useCallback(
    (key, value) => {
      dispatch(updateSelectedSenderIdentity(selectedItem.set(key, value)))
    },
    [dispatch, selectedItem]
  )

  const onSave = React.useCallback(() => {
    let err = []
    if (selectedItem.sendingName.trim() === '') err.push(['name', 'Please enter a display name'])
    if (selectedItem.sendingPrefix.trim() === '' || selectedItem.sendingPrefix.indexOf(' ') !== -1)
      err.push(['prefix', 'Please enter a valid prefix for the sending address'])
    if (selectedItem.sendingDomain.trim() === '')
      err.push(['domain', 'Please select a sending domain'])
    const currentErrors = Immutable.Map(err)
    setErrors(currentErrors)
    if (currentErrors.size === 0) {
      if (creating) {
        const { sendingName, sendingPrefix, sendingDomain, transmissionTypes } = selectedItem
        dispatch(createSenderIdentity(sendingName, sendingPrefix, sendingDomain, transmissionTypes))
      } else {
        dispatch(updateSenderIdentity(selectedItem))
      }
    }
  }, [dispatch, creating, selectedItem, setErrors])

  const onCancel = React.useCallback(() => {
    setErrors(Immutable.Map())
    if (creating) {
      dispatch(cancelCreatingAndSetPrevious())
    } else if (hasChanged) {
      dispatch(selectSenderIdentity(previousItem))
    }
  }, [dispatch, creating, hasChanged, previousItem])

  const onSendingDomainChange = React.useCallback(
    (opt: ?string) => {
      if (!opt) return

      dispatch(
        updateSelectedSenderIdentity(
          selectedItem
            .set('sendingDomain', opt)
            .set(
              'transmissionTypes',
              sendingDomains.find(domain => domain.sendingDomain === opt)?.transmissionTypes
            )
        )
      )
    },
    [dispatch, selectedItem, sendingDomains]
  )
  const onSendingNameChange = React.useCallback(
    evt => onSenderIdentityChange('sendingName', evt.target.value),
    [onSenderIdentityChange]
  )
  const onSendingPrefixChange = React.useCallback(
    evt => onSenderIdentityChange('sendingPrefix', evt.target.value),
    [onSenderIdentityChange]
  )

  const deleteConfirmModal = React.useCallback(() => {
    confirm({
      sensitive: true,
      title: 'Delete this sender?',
      message: (
        <div>
          <p>Deleting this sender setting won't affect your email sending information.</p>
          {countOrchestrations > 0 && (
            <p>
              You currently have <ConfirmHighlight>{countOrchestrations}</ConfirmHighlight>{' '}
              orchestration{countOrchestrations > 1 && 's'} that might use this sender.
            </p>
          )}
        </div>
      ),
      confirm: 'Yes, delete it',
    }).then(
      () => dispatch(deleteSenderIdentity(selectedItem.id)),
      () => {}
    )
  }, [dispatch, selectedItem, countOrchestrations])

  const optionFormatter = React.useCallback(
    sendingDomain => (
      <Grid template="1fr auto">
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {sendingDomain}
        </span>
        <div>
          <Label
            variant="neutral"
            style={{
              height: 20,
              marginLeft: 8,
              padding: '0 4px',
              display: 'inline-block',
            }}
          >
            {getTransmissionType(
              sendingDomains.find(domain => domain.sendingDomain === sendingDomain)
                ?.transmissionTypes
            )}
          </Label>
        </div>
      </Grid>
    ),
    [sendingDomains]
  )

  return (
    <React.Fragment>
      <SenderIdentityForm>
        <InputWrapper label="Display name" feedback={errors.get('name')} htmlFor="name">
          <Input
            type="text"
            id="name"
            label="Display name"
            value={selectedItem.sendingName}
            onChange={onSendingNameChange}
          />
        </InputWrapper>
        <InputWrapper
          label="Sender address"
          feedback={errors.get('prefix') || errors.get('prefix')}
        >
          <SenderIdentityAddress>
            <Input
              type="text"
              value={selectedItem.sendingPrefix}
              style={{ width: '100%' }}
              onChange={onSendingPrefixChange}
              aria-label="Sending prefix"
            />
            @
            <Select
              style={{ width: '100%' }}
              isSearchable={true}
              localSearchEveryWhere
              isClearable={false}
              value={selectedItem.sendingDomain}
              options={sortedSendingDomains}
              optionToString={IdentityToString}
              optionFormatter={optionFormatter}
              onChange={onSendingDomainChange}
            />
          </SenderIdentityAddress>
        </InputWrapper>
        {selectedItem.id > 0 && (
          <InputWrapper label="Sender ID">
            <Code className="fs-exclude">{selectedItem.id}</Code>
          </InputWrapper>
        )}
      </SenderIdentityForm>
      <SenderIdentityButtonZone>
        <div>
          {!creating && (
            <Button kind="secondary" intent="danger" addOn="suffix" onClick={deleteConfirmModal}>
              Delete
              <Icon icon="delete" />
            </Button>
          )}
        </div>
        <div>
          <SenderIdentityButton onClick={onCancel} disabled={!canCancel}>
            Cancel
          </SenderIdentityButton>
          <SenderIdentityButton kind="primary" intent="action" onClick={onSave} disabled={!canSave}>
            {creating ? 'Create' : 'Update'}
          </SenderIdentityButton>
        </div>
      </SenderIdentityButtonZone>
    </React.Fragment>
  )
})
