// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import {
  opacifyAction,
  opacifyDanger,
  opacifyFeature,
  opacifyTech,
  opacifySuccess,
  opacifyWarning,
  textTechDark,
  text,
  textContrast,
  strokeContrast,
  textSuccess,
  textAction,
  textFeature,
} from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'

export type LabelVariant =
  | 'default'
  | 'action'
  | 'feature'
  | 'tech'
  | 'neutral'
  | 'success'
  | 'warning'
  | 'danger'
  | 'contrast'

type LabelProps = { variant?: LabelVariant, ... }
export const Label: StyledComponent<LabelProps, *, HTMLSpanElement> = styled.span`
  border-radius: 6px;
  padding: 3px 5px;
  gap: 6px;
  ${(props: LabelProps) => {
    switch (props.variant) {
      case 'action':
        return css`
          color: ${textAction};
          background: ${opacifyAction};
        `
      case 'feature':
        return css`
          color: ${textFeature};
          background: ${opacifyFeature};
        `
      case 'tech':
        return css`
          color: ${textTechDark};
          background: ${opacifyTech};
        `
      case 'neutral':
        return css`
          color: ${text};
          background: ${schemes.darklucent['05']};
        `
      case 'success':
        return css`
          color: ${textSuccess};
          background: ${opacifySuccess};
        `
      case 'warning':
        return css`
          color: ${schemes.orange['90']};
          background: ${opacifyWarning};
        `
      case 'danger':
        return css`
          color: ${schemes.red['90']};
          background: ${opacifyDanger};
        `
      case 'contrast':
        return css`
          color: ${textContrast};
          background: ${strokeContrast};
        `
      default:
        return css`
          border: 1px solid ${schemes.darklucent['20']};
        `
    }
  }}
`
