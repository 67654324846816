// @flow
import Immutable, { type List } from 'immutable'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type FetchUnifiedCustomerEventValuesFailureAction = {
  type: 'FETCH_UNIFIED_CUSTOMER_EVENT_VALUES_FAILURE',
  ...
}

export const fetchUnifiedCustomerEventValues = ({
  eventName,
  attributeName,
  type,
  searchTerm,
}: {
  eventName: string,
  attributeName: string,
  type: 'ATTRIBUTES' | 'TAGS' | 'LABELS',
  searchTerm?: string,
  ...
}): DispatchExtraBoundFn<Promise<List<string>>> => {
  return async (dispatch, getState, { ucpGateway }) => {
    const project = currentProjectSelector(getState())

    try {
      return await ucpGateway.fetchUnifiedCustomerEventValues({
        project,
        eventName,
        attributeName,
        type,
        searchTerm,
      })
    } catch (err) {
      dispatch({
        type: 'FETCH_UNIFIED_CUSTOMER_EVENT_VALUES_FAILURE',
        payload: err,
      })

      return new Immutable.List()
    }
  }
}
