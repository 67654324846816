// @flow

import Prism from 'prismjs'
import * as React from 'react'

import { HightLightContainer } from './highlight.styles'

type HighlightProps = {
  language?: string,
  children: React.Node,
  backgroundColor?: string,
  style?: any,
  isSensitive?: boolean,
  ...
}

export const Highlight = ({ language = '', isSensitive, ...props }: HighlightProps): React.Node => {
  const ref = React.createRef<HTMLElement | null>()
  const { children, style } = props

  React.useEffect(() => {
    if (ref.current) {
      Prism.highlightAll()
    }
  }, [ref, children])

  return (
    <HightLightContainer style={{ ...style }}>
      <code ref={ref} className={`${isSensitive ? 'fs-exclude' : ''} language-${language}`}>
        {children}
      </code>
    </HightLightContainer>
  )
}

export default Highlight
