// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'
import { IconContainer } from 'components/common/svg-icon'
import { Tag } from 'components/common/tags/tag.styles'

export const ButtonShowMore: StyledComponent<*, *, *> = styled(Button)`
  height: auto;
  padding: 0;
  ${IconContainer} {
    margin-left: 6px;
  }
`
type TagsListContainerProps = { nbItemsCollapsable: number, isCollapsed: boolean, ... }
export const TagsListContainer: StyledComponent<TagsListContainerProps, *, HTMLDivElement> =
  styled.div`
    display: flex;
    flex-wrap: wrap;

    ${Tag} {
      margin-top: 3px;
      margin-bottom: 3px;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    ${(props: TagsListContainerProps) =>
      props.nbItemsCollapsable > 0 &&
      !props.isCollapsed &&
      css`
        ${Tag}:nth-child(+n+${props.nbItemsCollapsable + 1}) {
          display: none;
        }
      `}

    ${ButtonShowMore} {
      margin-left: 5px;
    }
  `
