// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper } from 'components/form/input-wrapper'

import { InputContainer } from '../styles'

type PasswordProps = {
  label?: ReactNode,
  hint?: ReactNode,
  feedback?: ReactNode,
  additional?: ReactNode,
  htmlFor?: string,
  invalid: boolean,
  value: string,
  setValue: string => void,
  ...
}

const Password = ({
  label,
  hint,
  feedback,
  additional,
  htmlFor,
  invalid,
  value,
  setValue,
}: PasswordProps): React.Node => {
  const [shown, setShown] = React.useState(false)

  const handleOnClickReveal = React.useCallback(() => {
    setShown(!shown)
  }, [shown])

  const handleOnChange = React.useCallback(evt => setValue(evt.target.value), [setValue])

  return (
    <InputWrapper
      label={label}
      hint={hint}
      feedback={feedback}
      additional={additional}
      htmlFor={htmlFor}
    >
      <InputContainer invalid={invalid}>
        <input
          id={htmlFor}
          placeholder="Password"
          type={shown ? 'text' : 'password'}
          style={{ flex: '1 1 auto', minWidth: 0 }}
          value={value}
          onChange={handleOnChange}
        />
        <div className="styled-input-addons styled-addon-suf">
          <Button
            type="button"
            kind="discreet"
            addOn="prefix"
            onClick={handleOnClickReveal}
            tabIndex="-1"
          >
            {shown ? (
              <React.Fragment>
                <Icon icon="reveal-false" /> Hide
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon icon="reveal" /> Show
              </React.Fragment>
            )}
          </Button>
        </div>
      </InputContainer>
    </InputWrapper>
  )
}

export { Password }
