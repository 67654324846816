// @flow
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Skeleton } from 'components/common/empty-states'

import { MetricContainer, MetricSubtitle, MetricTitle } from './metric.styles'

export type MetricsSizeProps = 'XL' | 'M'
export type MetricsAlignProps = 'column' | 'row'
type Props = {
  size?: MetricsSizeProps,
  align?: MetricsAlignProps,
  value: React.Node,
  subtitle?: React.Node,
  skeletonMaxWidth?: number,
}

export const Metric = ({
  size = 'XL',
  align = 'column',
  value,
  subtitle,
  skeletonMaxWidth = 170,
}: Props): React.Node => (
  <ThemeProvider theme={{ metricSize: size, metricAlign: align }}>
    <MetricContainer>
      <Skeleton w={(70 * skeletonMaxWidth) / 100} h={size === 'M' ? 32 : 44}>
        <MetricTitle>{value}</MetricTitle>
      </Skeleton>

      {subtitle && (
        <Skeleton
          w={skeletonMaxWidth}
          h={16}
          style={align === 'row' ? { marginLeft: 6 } : { marginTop: 10 }}
        >
          <MetricSubtitle>{subtitle}</MetricSubtitle>
        </Skeleton>
      )}
    </MetricContainer>
  </ThemeProvider>
)
