// @flow

import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { useSelector } from 'react-redux'

import { ConfirmModal, type ConfirmModalProps } from 'components/common/confirm-modal'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { currentUserSelector } from 'com.batch.redux/user.selector'

import { ConfirmMfa } from 'com.batch/shared/ui/component/confirm-mfa/confirm-mfa'

type ConfirmWithMfaProps = ConfirmModalProps & {
  onModalShown?: () => void,
  ...$Exact<ConfirmModalProps>,
}

export const useConfirmWithMfa = (): ((props: ConfirmWithMfaProps) => Promise<boolean>) => {
  const user = useSelector(currentUserSelector)
  return async (props: ConfirmWithMfaProps) => {
    const body = document.body
    if (!body) throw new Error('confirm called in a non DOM context')
    const wrapper = body.appendChild(document.createElement('div'))
    const root = createRoot(wrapper)
    let resolver = () => undefined
    let rejecter = () => undefined

    const promise = new Promise<boolean>((resolve, reject) => {
      resolver = () => {
        root.unmount()
        setTimeout(() => wrapper.remove())
        resolve(true)
      }
      rejecter = () => {
        root.unmount()
        setTimeout(() => wrapper.remove())
        reject(false)
      }
    })

    let authRequired

    try {
      await request.get(generateUrl('api_login_mfa_check'))
      authRequired = false
    } catch (e) {
      authRequired = true
    }
    if (props.onModalShown) {
      props.onModalShown()
    }
    root.render(
      <ConfirmModal
        {...props}
        resolver={resolver}
        rejecter={rejecter}
        mfa={
          <ConfirmMfa
            user={user}
            onSuccess={resolver}
            onCancel={rejecter}
            cancel={props.cancel ?? 'Cancel'}
            confirm={props.confirm}
            sensitive={props.sensitive}
            authRequired={authRequired}
          />
        }
      />
    )
    return promise
  }
}
