// @flow
import dayjs from 'dayjs'
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { Ellipsis } from 'components/styled/text'

import {
  EventRowContent,
  EventRowDetails,
  EventRowContentInformation,
  EventRowDetailsSource,
} from '../event-row/event-row.styles'
import { APIIcon } from '../icons'
import { Timeline } from '../timeline/timeline'

import { convertAttributesToJson } from 'com.batch/profile/infra/formats/convert-attributes-to-json'
import { type CustomEventRecord } from 'com.batch/profile/models/event.records'
import { EventRowAttributes } from 'com.batch/profile/ui/components/events-block/event-row/event-row-attributes'
import { Time } from 'com.batch/profile/ui/components/events-block/timeline/time'

type Props = {
  event: CustomEventRecord,
}

const platform = {
  IOS: {
    icon: 'ios',
    label: 'iOS',
  },
  ANDROID: {
    icon: 'android',
    label: 'Android',
  },
  WEBPUSH: {
    icon: 'webpush',
    label: 'Web',
  },
}

export const CustomEventRow = ({ event }: Props): React.Node => {
  const { attributes } = event
  const hasPropertiesOrLabel = React.useMemo(
    () => attributes.size !== 0 || event.label !== '',
    [attributes.size, event.label]
  )
  const [isAttributesShown, setIsAttributesShown] = React.useState<boolean>(false)

  convertAttributesToJson(attributes)

  const isSdkEvent = React.useMemo(() => {
    return (
      event.source === 'ANDROID' ||
      event.source === 'IOS' ||
      event.source === 'WEBPUSH' ||
      event.source === 'SDK'
    )
  }, [event.source])

  const sendDateUtc = React.useMemo(() => {
    return dayjs.utc(event.sendDate)
  }, [event.sendDate])

  const sendDate = React.useMemo(() => {
    return sendDateUtc.local()
  }, [sendDateUtc])

  const hasAttributes = React.useMemo(() => event.attributes.size > 0, [event.attributes.size])

  const handleClickExpandBtn = React.useCallback(
    () => setIsAttributesShown(!isAttributesShown),
    [isAttributesShown]
  )

  return (
    <EventRowContent>
      <Time date={sendDate} />
      <Timeline />
      <EventRowContentInformation expanded={hasPropertiesOrLabel}>
        <EventRowDetails
          onClick={handleClickExpandBtn}
          kind="inline"
          intent="neutral"
          $isToggable={hasAttributes}
        >
          <Ellipsis>{event.name}</Ellipsis>
          {hasAttributes && (
            <span>
              &nbsp;with {attributes.size} {attributes.size > 1 ? 'attributes' : 'attribute'}
            </span>
          )}

          <EventRowDetailsSource>
            {isSdkEvent ? (
              <Icon icon={platform[event.platform].icon} style={{ marginLeft: 4 }} />
            ) : (
              <APIIcon />
            )}
          </EventRowDetailsSource>
          {hasAttributes && (
            <Icon
              className="toggle-icon"
              style={{ marginLeft: '7px' }}
              icon={isAttributesShown ? 'chevron-up' : 'chevron-down'}
            />
          )}
        </EventRowDetails>
        {hasAttributes && isAttributesShown && (
          <div>
            <EventRowAttributes
              attributes={event.attributes}
              eventName={event.name}
              isShown={isAttributesShown}
            />
          </div>
        )}
      </EventRowContentInformation>
    </EventRowContent>
  )
}
