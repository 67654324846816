// @flow
import Immutable from 'immutable'

import { computeRate } from './compute'
import { parseTrends } from './parse-trends'

import {
  type StatsRecord,
  StatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { type BaseOrchestrationStats } from 'com.batch/shared/infra/types/grpc-stats-service'

export const parseBaseStats = (
  currentPeriod: BaseOrchestrationStats,
  previousPeriod: ?BaseOrchestrationStats
): StatsRecord => {
  // Stats service values
  const delivered = currentPeriod.delivered ?? 0
  const sent = currentPeriod.sent ?? 0
  const click = currentPeriod.click ?? 0
  const bounce = currentPeriod.bounce ?? 0
  const uniqueClick = currentPeriod.uniqueClick ?? 0
  const open = currentPeriod.open ?? 0
  const spam = currentPeriod.spam ?? 0
  const deliveredSMSNbParts = currentPeriod.deliveredSMSNbParts ?? 0
  const skippedSuppressionList = currentPeriod.skippedSuppressionList ?? 0
  const unsubscribe = currentPeriod.unsubscribe ?? 0
  const uniqueUnsubscribe = currentPeriod.uniqueUnsubscribe ?? 0
  const mpp = currentPeriod.mpp ?? 0
  const uniqueOpen = currentPeriod.uniqueOpen ?? 0
  const uniqueMpp = currentPeriod.uniqueMpp ?? 0
  const injection = currentPeriod.injection ?? 0

  // Computed values
  let sending = sent - delivered - bounce - skippedSuppressionList
  // C'est possible que le delay soit négatif quand les stats sont décalées / résorbées après coup
  if (sending < 0) sending = 0
  const bounceRate = computeRate(bounce, sent)
  const clickRate = computeRate(uniqueClick, delivered)
  const deliveredRate = computeRate(delivered, sent)
  const skippedRate = computeRate(skippedSuppressionList, sent)
  const unsubscribeRate = computeRate(uniqueUnsubscribe, delivered)
  const sendingRate = computeRate(sending, sent)
  const openRate = computeRate(uniqueOpen, delivered)
  const uniqueMppRate = computeRate(uniqueMpp, delivered)

  const trends = parseTrends(
    {
      sent,
      delivered,
      openRate,
      clickRate,
      bounceRate,
      unsubscribeRate,
    },
    previousPeriod
  )

  return StatsFactory({
    sent: Immutable.Record({
      value: sent,
      trend: trends.sent.trend,
      previousPeriodValue: trends.sent.previousPeriodValue,
    })(),
    bounce: Immutable.Record({
      value: bounce,
      rate: bounceRate,
      trend: trends.bounce.trend,
      previousPeriodRate: trends.bounce.previousPeriodRate,
    })(),
    click: Immutable.Record({
      value: click,
      rate: clickRate,
      unique: uniqueClick,
      trend: trends.click.trend,
      previousPeriodRate: trends.click.previousPeriodRate,
    })(),
    mpp: Immutable.Record({ value: mpp, unique: uniqueMpp, rate: uniqueMppRate })(),
    delivered: Immutable.Record({
      value: delivered,
      rate: deliveredRate,
      trend: trends.delivered.trend,
      previousPeriodValue: trends.delivered.previousPeriodValue,
    })(),
    open: Immutable.Record({
      value: open,
      rate: openRate,
      unique: uniqueOpen,
      trend: trends.open.trend,
      previousPeriodRate: trends.open.previousPeriodRate,
    })(),
    unsubscribe: Immutable.Record({
      value: unsubscribe,
      rate: unsubscribeRate,
      trend: trends.unsubscribe.trend,
      previousPeriodRate: trends.unsubscribe.previousPeriodRate,
      unique: uniqueUnsubscribe,
    })(),
    skippedSuppressionList: Immutable.Record({
      value: skippedSuppressionList,
      rate: skippedRate,
    })(),
    sending: Immutable.Record({ value: sending, rate: sendingRate })(),
    injection,
    spam,
    deliveredSMSNbParts,
  })
}
