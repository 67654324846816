// @flow

import * as React from 'react'

import { Button } from 'components/common/button'
import { Code } from 'components/common/code'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper } from 'components/form'

import { type ServiceAccountKeyRecord } from 'com.batch/settings/models/fcm-config.records'

type Props = {
  serviceAccountKey: ServiceAccountKeyRecord,
  index: number,
  onRemoveConfig: (index: number, privateKeyId: string) => () => void,
  isEditing: boolean,
  isDeletable: boolean,
  isLoading: boolean,
  ...
}

export const ConfigGrid = ({
  serviceAccountKey,
  index,
  onRemoveConfig,
  isEditing,
  isDeletable,
  isLoading,
}: Props): React.Node => {
  const isFirst = React.useMemo(() => index === 0, [index])
  const { projectId, clientEmail, privateKeyId } = serviceAccountKey

  return (
    <React.Fragment>
      <InputWrapper label={isFirst ? 'Project_id' : 'Additional(s) Project_id'}>
        <Code className="fs-exclude">{projectId}</Code>
      </InputWrapper>
      <InputWrapper label={isFirst ? 'Client_email' : 'Additional(s) Client_email'}>
        <Code className="fs-exclude">{clientEmail}</Code>
      </InputWrapper>
      <Grid template={isFirst && !isEditing ? '1fr' : '1fr auto'}>
        <InputWrapper label={isFirst ? 'Private_key_id' : 'Additional(s) Private_key_id'}>
          <Code className="fs-exclude">{privateKeyId}</Code>
        </InputWrapper>
        {isDeletable && (
          <Button
            kind="discreet"
            intent="danger"
            onClick={onRemoveConfig(index, privateKeyId)}
            disabled={isLoading}
          >
            <Icon icon="delete" />
          </Button>
        )}
      </Grid>
    </React.Fragment>
  )
}
