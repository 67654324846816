// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { filters } from 'components/campaign/campaign-list-utils'
import { Filter } from 'components/campaign/filters/filter'
import { Tooltip } from 'components/common/tooltip'
import { Checkbox } from 'components/form'

import { type CampaignActiveFiltersRecord, type State } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'

type SourceFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord,
  onChange: any => void,
  isAutomation: boolean,
  ...
}

const hasEditorialDashboardSelector = (state: State) => state.company.hasEditorialDashboard

export const SourceFilter = ({
  localActiveFilters,
  onChange,
  isAutomation,
}: SourceFilterProps): React.Node => {
  const hasEditorialDashboard = useSelector(hasEditorialDashboardSelector)
  const app = useSelector(currentAppSelector)
  return (
    <Filter isEmbedded={true} title="Sources">
      {filters
        .filter(f => f.category === 'sources')
        .filter(source => source.name !== 'editorial' || (hasEditorialDashboard && !isAutomation))
        .map(source => (
          <Tooltip
            key={source.name}
            isTooltipEmpty={source.name !== 'apiPublic' || app.features.has('api-marketing')}
            tooltip={'Upgrade your plan to create API campaigns.'}
            placement={'left'}
            minWidth={189}
          >
            <div>
              <Checkbox
                key={source.name}
                name={source.name}
                label={source.label}
                checked={localActiveFilters.commons.has(source)}
                className={`sources_${source.label.toLocaleLowerCase().trim()}`}
                onChange={() => onChange(source)}
                disabled={source.name === 'apiPublic' && !app.features.has('api-marketing')}
              />
            </div>
          </Tooltip>
        ))}
    </Filter>
  )
}
