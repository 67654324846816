// @flow

import * as React from 'react'
import { type Node } from 'react'

import { Icon } from 'components/common/svg-icon'

import { FeedbackContainer } from './feedback.styles'

type FeedbackProps = {
  type?: 'success' | 'error' | 'loading' | 'insight' | 'warning',
  message?: string | Node,
  ...
}

const FeedbackRaw = ({ type = 'insight', message, ...rest }: FeedbackProps): React.Node => {
  return (
    <FeedbackContainer {...rest} _type={type}>
      {type === 'loading' && <Icon icon="spinner" className="styled-feedback-icon" />}
      {message}
    </FeedbackContainer>
  )
}

const Feedback: React.ComponentType<FeedbackProps> = React.memo<FeedbackProps>(FeedbackRaw)

export { Feedback }
