// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { type StateToggler } from 'components/_hooks'
import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
  FooterBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Input } from 'components/form'

import { isEmail } from 'com.batch.common/utils'

import { sendTestEmail } from 'com.batch/email/usecases/send-test-email'
import { type EmailContentRecord } from 'com.batch/message/models/message.records'

const localStorageSaveEmailKey = 'send-test-email'

type PopinSendTestEmailProps = {
  togglePopin: StateToggler,
  content: ?EmailContentRecord,
}

export const PopinSendTestEmail = ({
  togglePopin,
  content,
}: PopinSendTestEmailProps): React.Node => {
  const dispatch = useDispatch()
  const [recipients, setRecipients] = React.useState(
    localStorage.getItem(localStorageSaveEmailKey) ?? ''
  )

  const hasRecipients = Boolean(recipients)
  const listRecipients = recipients.split(',')
  const validEmails = listRecipients.every(recipient => {
    return isEmail(recipient.trim())
  })

  const contentTooltip = (): string => {
    if (!hasRecipients) {
      return 'Email is empty'
    }

    if (!validEmails) {
      return 'Email(s) is invalid'
    }
    return ''
  }

  const onSendTestEmail = React.useCallback((): void => {
    if (!content) return
    dispatch(sendTestEmail(content, recipients))
    localStorage.setItem(localStorageSaveEmailKey, recipients)
    togglePopin.close()
  }, [content, dispatch, recipients, togglePopin])
  const onRecipientsChange = React.useCallback(event => setRecipients(event.target.value), [])
  return (
    <ThemeProvider theme={{ isDark: false }}>
      <Popin
        opened={togglePopin.value}
        close={togglePopin.close}
        style={{ width: 480, overflowY: 'hidden' }}
      >
        <Box>
          <BoxHeader large>
            <HeaderBoxTitle
              title="Send a test"
              detail={'Add one or several emails separated with commas (,)'}
            />
            <HeaderBoxActions large>
              <Button kind="inline" intent="neutral" onClick={togglePopin.close}>
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody $padding>
            <Input
              id="name"
              placeholder="Enter hello@brand.com"
              value={recipients}
              onChange={onRecipientsChange}
              autoFocus
            />
          </BoxBody>
          <BoxFooter isEditable>
            <Button kind="inline" onClick={togglePopin.close}>
              Cancel
            </Button>
            <FooterBoxActions>
              <Tooltip
                placement="bottom"
                tooltip={<React.Fragment>{contentTooltip()}</React.Fragment>}
                isTooltipEmpty={hasRecipients && validEmails}
              >
                <span>
                  <Button
                    kind="primary"
                    intent="action"
                    disabled={!hasRecipients || !validEmails}
                    onClick={onSendTestEmail}
                  >
                    Send test
                  </Button>
                </span>
              </Tooltip>
            </FooterBoxActions>
          </BoxFooter>
        </Box>
      </Popin>
    </ThemeProvider>
  )
}
