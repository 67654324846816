/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { NotificationListEmptyTemplate } from 'components/analytics/notification-utils'
import { Box } from 'components/common/box'
import { Wrapper } from 'components/common/empty-states'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Pager } from 'components/common/pager'
import { Table, TableCellOrder, TableHeader, TableBody, TableFooter } from 'components/common/table'
import { SubNav, SubNavLink } from 'components/styled/subnav'

import { dayjs, type DateRange } from 'com.batch.common/dayjs.custom'
import { useGenerateAnalyticsUrl } from 'com.batch.common/router'

import { NotificationCsv } from './notification-csv'
import { NotificationRow } from './notification-row'

import { type AppRecord } from 'com.batch.redux/_records'
import { DayDataFactory } from 'com.batch.redux/dataCampaign.records'
import {
  createNotificationListSelector,
  loadedPartsSelector,
  allDayDataSelector,
} from 'com.batch.redux/dataCampaign.selector'

type NotificationTableProps = {
  isGlobalOverlayShown: boolean,
  tab: 'all' | 'campaign' | 'transac',
  loading: boolean,
  hasMarketing: boolean,
  hasTransac: boolean,
  app: AppRecord,
  range: DateRange,
  ...
}

const emptyList = new Immutable.List().push(
  ...new Array(7).fill().map((o, i) => DayDataFactory({ period: dayjs().add(i, 'day') }))
)
const NB_PER_PAGE = 100

export const NotificationTable = ({
  tab,
  loading,
  isGlobalOverlayShown,
  app,
  range,
  hasMarketing,
  hasTransac,
}: NotificationTableProps): React.Node => {
  // ====================== LOCAL STATE
  const [sortBy, setSortBy] = React.useState<'period' | 'sent' | 'open' | 'errors' | 'reengaged'>(
    'period'
  )
  const [sortOrder, setSortOrder] = React.useState<'dsc' | 'asc'>('dsc')
  const updateSort = (field: 'period' | 'sent' | 'open' | 'errors' | 'reengaged') => {
    setSortBy(field)
    setSortOrder(field === sortBy ? (sortOrder === 'asc' ? 'dsc' : 'asc') : 'dsc')
  }
  const [page, setPage] = React.useState(1)

  // ====================== REDUX STATE
  const allData = useSelector(allDayDataSelector)
  const loaded = useSelector(loadedPartsSelector)
  const listSelector = React.useMemo(() => {
    const f = tab === 'all' ? 'category' : tab === 'campaign' ? 'token' : 'groupId'
    return createNotificationListSelector(f)
  }, [tab])
  const dataByDayMap = useSelector(listSelector)
  const list = dataByDayMap.toList()
  const sortedList = React.useMemo(() => {
    return list.sort((a, b) => {
      const ret = sortOrder === 'asc' ? -1 : 1
      switch (sortBy) {
        case 'period':
          return a.period.isBefore(b.period) ? ret : -ret
        case 'sent':
          return (a.push.total.sent ?? 0) < (b.push.total.sent ?? 0) ? ret : -ret
        case 'open':
          return (a.push.total.openAlg ?? 0) < (b.push.total.openAlg ?? 0) ? ret : -ret
        case 'reengaged':
          return (a.push.total.reengaged ?? 0) < (b.push.total.reengaged ?? 0) ? ret : -ret
        default:
          return a.errors.reduce((acc, current) => current + acc, 0) <
            b.errors.reduce((acc, current) => current + acc, 0)
            ? ret
            : -ret
      }
    })
  }, [list, sortBy, sortOrder])

  const slicedData = React.useMemo(() => {
    return sortedList.slice((page - 1) * NB_PER_PAGE, page * NB_PER_PAGE)
  }, [page, sortedList])
  const getUrl = useGenerateAnalyticsUrl()

  React.useEffect(() => {
    setPage(1)
  }, [tab])

  return (
    <React.Fragment>
      <FlexLine>
        <FlexLineItem grow={1}>
          <SubNav style={{ fontSize: 14 }}>
            <SubNavLink as={NavLink} to={getUrl({ tab: 'push' })} end>
              Daily
            </SubNavLink>
            {hasMarketing && (
              <SubNavLink as={NavLink} to={getUrl({ tab: 'push', kind: 'campaign' })} end>
                Campaign (period)
              </SubNavLink>
            )}
            {hasTransac && (
              <SubNavLink as={NavLink} to={getUrl({ tab: 'push', kind: 'transac' })} end>
                Transactional (period)
              </SubNavLink>
            )}
          </SubNav>
        </FlexLineItem>
        <FlexLineItem>
          <NotificationCsv app={app} range={range} />
        </FlexLineItem>
      </FlexLine>
      <Box style={{ margin: '24px 0 0 0', overflow: 'hidden' }}>
        <Wrapper
          isEmpty={list.size <= 0 || isGlobalOverlayShown}
          isLoading={loading}
          isOverlayShown={!loading && list.size <= 0 && !isGlobalOverlayShown}
          overlayProps={{
            status: 'empty',
            title: 'No data for the selected period',
            links: [
              {
                name: 'Learn more about detailed stats',
                href: 'https://doc.batch.com/dashboard/analytics/notifications.html#_detailed-stats',
                isDocLink: true,
              },
            ],
          }}
        >
          <Table template={`2fr repeat(4, 1fr) ${tab === 'all' ? '49px' : ''}`}>
            <TableHeader>
              <TableCellOrder
                sort={sortBy === 'period' ? sortOrder : false}
                onClick={() => updateSort('period')}
              >
                {tab === 'transac' ? 'Group ID' : tab === 'campaign' ? 'Campaign' : 'Period'}
              </TableCellOrder>
              <TableCellOrder
                align="right"
                sort={sortBy === 'sent' ? sortOrder : false}
                onClick={() => updateSort('sent')}
              >
                Sent
              </TableCellOrder>
              <TableCellOrder
                align="right"
                sort={sortBy === 'open' ? sortOrder : false}
                onClick={() => updateSort('open')}
              >
                Open
              </TableCellOrder>
              <TableCellOrder
                align="right"
                sort={sortBy === 'reengaged' ? sortOrder : false}
                onClick={() => updateSort('reengaged')}
              >
                Reengaged
              </TableCellOrder>
              <TableCellOrder
                align="right"
                sort={sortBy === 'errors' ? sortOrder : false}
                onClick={() => updateSort('errors')}
              >
                Errors
              </TableCellOrder>
              {tab === 'all' && <div />}
            </TableHeader>
            <TableBody emptyTemplate={<NotificationListEmptyTemplate />}>
              {(loading || sortedList.size <= 0 ? emptyList : slicedData).map(row => {
                return (
                  <NotificationRow
                    key={row.hashCode()}
                    appId={app.id}
                    companyId={app.companyId ?? 0}
                    loaded={loaded}
                    data={row}
                    mode={
                      tab === 'all' ? 'period' : tab === 'transac' ? 'transactional' : 'campaign'
                    }
                    app={app}
                    all={allData}
                  />
                )
              })}
            </TableBody>
          </Table>
          {sortedList.size > NB_PER_PAGE && (
            <TableFooter>
              <Pager
                page={page}
                total={sortedList.size}
                nbPerPage={NB_PER_PAGE}
                selectPage={setPage}
                forceLoading={loading}
              />
            </TableFooter>
          )}
        </Wrapper>
      </Box>
    </React.Fragment>
  )
}
