// @flow
import { type Dayjs } from 'dayjs'
import * as React from 'react'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { randomSize, humanizeDayjs, pluralize } from 'com.batch.common/utils'

// import { sampleBounceByDayChartData } from './bounce-by-days-chart.sample'

import { type OrchestrationAnalyticsDateRangeRecord } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { type pushCampaignSendType } from 'com.batch.redux/_records'

import { bouncesColors } from 'com.batch/orchestration-analytics/constants/stat-type-colors'
import { BounceByDaysWrapper } from 'com.batch/orchestration-analytics/ui/components/bounce-by-days-chart/bounce-by-days-chart.styles'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'
import { BarChartProvider, BarChart } from 'com.batch/shared/ui/component/charts/bar-chart'
import { STATUS } from 'constants/common'

type Props = {
  bounces?: BarChartData[],
  setDateOnView: (?Dayjs) => void,
  loadingState?: LoadingState,
  recurrence: 'DAILY' | 'WEEKLY' | 'MONTHLY',
  sendType: pushCampaignSendType,
  totalBounces: number,
  dateRangeFilter: ?OrchestrationAnalyticsDateRangeRecord,
}

const RECURRENCE = { DAILY: 'day', WEEKLY: 'week', MONTHLY: 'month' }

export const BounceByDaysChart = ({
  bounces = [],
  setDateOnView,
  loadingState,
  sendType,
  recurrence,
  totalBounces,
  dateRangeFilter,
}: Props): React.Node => {
  const groups = [
    { name: 'block', color: bouncesColors.block },
    { name: 'hard', color: bouncesColors.hard },
    { name: 'soft', color: bouncesColors.soft },
  ]

  const { from, to } = React.useMemo(() => {
    let from = dayjs.utc().subtract(6, 'day')
    let to = dayjs.utc()

    if (dateRangeFilter) {
      from = dateRangeFilter.get('from')
      to = dateRangeFilter.get('to')
    } else {
      const bouncesDates = bounces.map(bounce => bounce.date)
      if (bouncesDates.length > 0) {
        from = dayjs.min(bouncesDates)
        to = dayjs.max(bouncesDates)
      }
    }

    return { from, to }
  }, [dateRangeFilter, bounces])
  const diff = React.useMemo(
    () => to.diff(from, RECURRENCE[recurrence]) + 1,
    [from, to, recurrence]
  )
  const nbBars = React.useMemo(() => (diff > 7 ? diff : 7), [diff])
  const isUntilToday = React.useMemo(
    () => (to ? to.isSame(dayjs.utc(), RECURRENCE[recurrence]) : true),
    [to, recurrence]
  )
  const isYearOverlapping = React.useMemo(() => from.year() !== to.year(), [from, to])
  const barPadding = React.useMemo(() => nbBars / 100, [nbBars])
  const timelineLabelNumber = React.useMemo(() => (nbBars > 7 ? 5 : 7), [nbBars])

  const dateRange = React.useMemo(
    () =>
      Array.from({ length: nbBars }, (_, index) => {
        if (recurrence === 'WEEKLY') return from.startOf('week').add(index, 'week').add(1, 'day')
        else if (recurrence === 'MONTHLY') {
          return from.startOf('month').add(index, 'month')
        }
        return from.add(index, 'day')
      }),

    [nbBars, recurrence, from]
  )

  const overwriteDateName = React.useMemo(
    () =>
      recurrence === 'MONTHLY'
        ? dateRange.map(date => ({ date: date, name: date.format('MMM') }))
        : [
            ...(isUntilToday
              ? [
                  { date: dateRange[diff - 1], name: 'Today' },
                  { date: dateRange[0], name: humanizeDayjs({ date: dateRange[0] }) },
                ]
              : []),
          ],
    [dateRange, recurrence, isUntilToday, diff]
  )

  const skeletonData = React.useMemo(
    () =>
      dateRange.map(date => {
        const hard = randomSize(100, 600)
        const soft = randomSize(100, 600)
        const block = randomSize(100, 600)
        const total = hard + block + soft
        return {
          date: date,
          hard: {
            value: hard,
            rate: hard / total,
          },
          soft: {
            value: soft,
            rate: soft / total,
          },
          block: {
            value: block,
            rate: block / total,
          },
        }
      }),
    [dateRange]
  )

  const hasNoBounces = React.useMemo(() => {
    return (
      sendType === 'scheduled' ||
      (sendType !== 'scheduled' && loadingState === STATUS.LOADED && bounces.length === 0)
    )
  }, [sendType, loadingState, bounces])

  const data = React.useMemo(() => {
    return loadingState === STATUS.LOADING || loadingState === STATUS.INIT || hasNoBounces
      ? skeletonData
      : bounces
  }, [loadingState, hasNoBounces, skeletonData, bounces])

  const noDataMsg = React.useMemo(() => {
    if (sendType === 'scheduled') return 'No data per day on a campaign.'
    if (totalBounces === 0) return 'No bounces for this automation.'
    switch (recurrence) {
      case 'DAILY':
        return `This graph shows bounces over the selected ${pluralize(
          'day',
          diff
        )}.\\ANo bounces occurred during the selected ${pluralize('day', diff)}.`
      case 'WEEKLY':
        return `This graph shows bounces over the selected ${pluralize(
          'week',
          diff
        )}.\\ANo bounces occurred during the selected ${pluralize('week', diff)}.`
      case 'MONTHLY':
        return `This graph shows bounces over the selected ${pluralize(
          'month',
          diff
        )}.\\ANo bounces occurred during the selected ${pluralize('month', diff)}.`
    }
  }, [sendType, recurrence, totalBounces, diff])

  return (
    <BounceByDaysWrapper noDataMsg={noDataMsg} overlay={hasNoBounces}>
      <BarChartProvider
        isLoading={loadingState === STATUS.LOADING}
        isEmpty={hasNoBounces}
        dateRange={dateRange}
        setDateOnView={setDateOnView}
      >
        <BarChart
          data={data}
          groups={groups}
          height={270}
          barPadding={barPadding}
          scaleLineNumber={6}
          showTimeline
          scaleLineKind="overTheMaxBar"
          timelineRenameDates={overwriteDateName}
          timelineLabelNumber={timelineLabelNumber}
          timelineCenteredEnds={!isUntilToday || recurrence === 'MONTHLY'}
          dateFormat={isYearOverlapping ? 'MMM YYYY' : 'DD MMM'}
          barMinHeight={14}
          disabledBarHeight={0}
          disableAfterDay={to}
        />
      </BarChartProvider>
    </BounceByDaysWrapper>
  )
}
