// @flow
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_HIGH, PASTE_COMMAND } from 'lexical'
import * as React from 'react'

/**
 * Intercepte "coller" afin de supprimer les retours a la ligne en fin de string probablement involontairement copiés
 */
export const RemoveLineBreaksOnPastePlugin = (): null => {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      event => {
        const clipboardData = event.clipboardData
        if (!clipboardData) {
          return true
        }

        const pastedText = clipboardData.getData('text/plain')

        // tous les line breaks subséquents en FIN de string uniquement
        const sanitizedText = pastedText.replace(/^\r?\n+|\r?\n+$/g, '')

        // Update du champ
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            selection.insertText(sanitizedText)
          }
        })

        // Prevent the default paste behavior
        event.preventDefault()
        return true
      },
      COMMAND_PRIORITY_HIGH
    )
  }, [editor])

  return null
}
