// @flow
import dayjs from 'dayjs'

import { type AudiencesModel } from 'com.batch/audience/infra/types/grpc-audience-service'
import {
  ProfileAudienceFactory,
  type ProfileAudienceRecord,
} from 'com.batch/audience/model/audience-profile.records'

export const parseProjectAudienceList = (raw: AudiencesModel): ProfileAudienceRecord => {
  let audiences = ProfileAudienceFactory({
    name: raw.name,
    displayName: raw.displayName,
    type: raw.type,
    nbIdentifier: raw.nbIdentifier,
    created: dayjs(raw.created),
    updated: dayjs(raw.updated),
  })

  return audiences
}
