// @flow

import styled, { type StyledComponent } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

import { ResetInput, InputItemStyles } from './../styles'

// ====================== STYLED COMPONENTES
type CodeContainerProps = { length?: number, ... }
const CodeContainer: StyledComponent<CodeContainerProps, *, HTMLElement> = styled.div`
  display: inline-flex;
  position: relative;
  padding: 0;
  /* overflow: hidden;
  border-radius: 4px; */
  font-size: 14px;

  &:before,
  &:after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    content: '';
  }
  &:before {
    z-index: 1;
    right: 1em;
    left: 0;
    ${InputItemStyles}
  }
  &:focus-within:before {
    border: 1px solid ${schemes.blue['30']};
    box-shadow: 0 0 0 1px ${schemes.blue['30']};
  }
  &:after {
    z-index: 3;
    right: 0;
    width: 1em;
    cursor: default;
  }

  /* AuthCode - Code input */
  .styled-code-input {
    ${ResetInput}

    display: block;
    position: relative;
    z-index: 2;
    padding: 1.2em 0 1em 1.2em;
    width: ${(p: CodeContainerProps) => (p.length || 6) * 2.6 + 1.4}em;
    overflow: hidden;
    font-family: 'Lucida Console', 'Monaco', monospace;
    font-variant-numeric: tabular-nums;
    letter-spacing: 2em;
    text-transform: uppercase;
    &[data-com-onepassword-filled] {
      border-radius: 6px;
      box-shadow: 0 0 0 2px ${schemes.blue['30']};
    }
  }

  /* AuthCode - Code frame */
  .styled-code-frame {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 2px;
    height: 100%;
    width: calc(100% - 1.1em - 3px);
    pointer-events: none;

    line {
      stroke: ${colors.stroke};
      stroke-width: 1px;
      strokelinecap: round;
      vector-effect: non-scaling-stroke;
    }
  }
`

export { CodeContainer }
