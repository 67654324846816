// @flow

import {
  type InlineEditorStateRecord,
  InlineEditorStateFactory,
} from 'com.batch/message-builder/store/inline-editor.state'

import {
  type BlurInlineEditorAction,
  type SetInlineEditorAction,
} from 'com.batch/message-builder/usecases/manage-inline-editor'

type Action = SetInlineEditorAction | BlurInlineEditorAction
export const inlineEditorReducer = (
  state: InlineEditorStateRecord = InlineEditorStateFactory(),
  action: Action
): InlineEditorStateRecord => {
  switch (action.type) {
    case 'SET_INLINE_EDITOR_CONFIG':
      return action.payload
    case 'BLUR_INLINE_EDITOR':
      if (state.personalizationModalIsOpen) {
        return state.set('selection', action.payload.selection)
      } else {
        if (state.get('field') === action.payload.field) {
          return state.set('field', null)
        } else {
          return state
        }
      }
    default:
      return state
  }
}
