// @flow

import * as React from 'react'
import styled, { css, useTheme, type StyledComponent } from 'styled-components'

import { Box } from 'components/common/box'
import { LoadingCellAnim, EmptyField } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { TableTemplateCell } from 'components/common/table/index'
import * as colorsLegacy from 'components/styled/colors'
import { Ellipsis } from 'components/styled/text'
import { colors, schemes, Opacity } from 'components/styled/tokens'

import { randomSize } from 'com.batch.common/utils'

// Userbase
export const UserbaseHeader: StyledComponent<*, *, *> = styled.div`
  display: flex;
  align-items: center;

  & > * {
    min-width: fit-content;
    text-align: left;

    &:last-of-type {
      margin-left: 28px;
    }

    @media (min-width: 650px) {
      text-align: right;
    }
  }
`

export const UserbaseAttributeContainer: StyledComponent<*, *, *> = styled.div`
  & > ${Grid} {
    margin: 30px 0;
  }

  @media (max-width: 650px) {
    & > ${Grid} {
      grid-template-columns: 1fr;

      h4 {
        margin-bottom: 20px;
      }

      .styled-toggle {
        display: none;
      }
    }
  }
`

// Clusters
export const ClusterTitle: StyledComponent<*, *, HTMLElement> = styled.h3`
  color: ${colors.textNeutral};
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin: 0 0 2px 0;
`

export const ClusterContent: StyledComponent<{ empty?: boolean, ... }, *, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const ClusterNumber: StyledComponent<*, *, HTMLElement> = styled.span`
  margin-top: -2px;
  font-size: 28px;
  font-weight: 300;
  color: ${colors.text};

  span {
    margin: 0 -10px 0 6px;
    font-size: 13px;
    font-weight: 500;
    color: ${colors.textNeutral};
  }
`

export const ClusterSub: StyledComponent<*, *, HTMLElement> = styled.span`
  margin-top: 4px;
  font-size: 1em;
  font-weight: 400;
  color: ${colors.textLight};
  line-height: 1;
`

export const ClusterSection: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 124px;
  padding: 2px 22px 0 22px;

  & + & {
    border-top: 1px solid ${colorsLegacy.stroke.lighter};

    ${ClusterContent}:before {
      font-size: 24px;
      padding-bottom: 0px;
    }
    ${ClusterNumber} {
      margin: 4px 0 1px 0;
      font-size: 24px;
    }
  }

  &:first-child {
    padding-top: 0px;
  }
`

export const Cluster: StyledComponent<{ isEmpty: boolean, ... }, *, *> = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  border-radius: 3px 3px 8px 8px;
  &:before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: 4px;
    ${
      '' /* background-color: ${props => (props.theme.isEmpty ? schemes.grayscale['30'] : props.color)}; */
    }
    border-radius: 3px 3px 0px 0px;

    ${(props: any) =>
      props.theme.isLoading
        ? css`
            animation-name: ${LoadingCellAnim};
            animation-duration: 2s;
            animation-iteration-count: infinite;
          `
        : css`
            background-color: ${props.isEmpty ? schemes.grayscale['30'] : props.color};
          `}
  }
`

export const ClusterLine: StyledComponent<*, *, *> = styled(Grid)`
  text-align: center;
  ${Cluster} {
    margin-bottom: 18px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: minmax(150px, 1fr);
  }
`

// Datas
export const Datas: StyledComponent<*, *, *> = styled(Grid)`
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media (max-width: 1200px) {
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: minmax(100px, 1fr);
  }
`

export const DatasHeader: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 8px 16px;
  font-size: 15px;

  ${Ellipsis} {
    margin-left: 12px;
    font-size: 15px;
    font-weight: 500;
    color: ${colors.text};
  }
`

const LegendedNumberContainer: StyledComponent<*, *, HTMLElement> = styled.dl`
  dd {
    color: ${colors.text};
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 5px;
  }
  dt {
    font-size: 14px;
    font-weight: 500;
    color: ${colors.textLight};
  }
`

export const LegendedNumber = ({
  legend,
  children,
}: {
  forceEmpty?: boolean,
  legend: React.Node,
  children: React.Node,
  ...
}): React.Node => {
  const theme = useTheme()
  const hideContent = theme.isEmpty || theme.isLoading
  const width = React.useMemo(() => randomSize(115, 90), [])
  return (
    <LegendedNumberContainer>
      <dd>{!hideContent ? children : <EmptyField _width={width} _height={21} />}</dd>
      <dt>{!hideContent ? legend : <EmptyField height={13} />}</dt>
    </LegendedNumberContainer>
  )
}

export const AttributeContainer: StyledComponent<*, *, *> = styled(Box)`
  margin-bottom: 0;
  min-height: 334px;
  background-color: white;
  table {
    background-color: white;
  }
`

export const NoAttributeContent: StyledComponent<*, *, HTMLElement> = styled.span`
  height: 264px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`
type AttributeTeaseWrapperProps = { tease?: boolean, ... }
export const AttributeTeaseWrapper: StyledComponent<AttributeTeaseWrapperProps, *, HTMLElement> =
  styled.div`
    position: relative;
    height: 288px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:after {
      display: ${(p: AttributeTeaseWrapperProps) => (p.tease ? 'block' : 'none')};
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: linear-gradient(
        180deg,
        ${Opacity(colors.fillBackground, 0.01)},
        ${Opacity(colors.fillBackground, 0.7)} 30%,
        ${Opacity(colors.fillBackground, 0.89)} 100%
      );
      border-radius: 0 0 3px 3px;
    }
  `

export const AttributeTableEmptyTemplate = (): React.Node => (
  <React.Fragment>
    <TableTemplateCell template="1fr" />
    <TableTemplateCell template="60px" align="end" />
    <TableTemplateCell template="60px" align="end" />
  </React.Fragment>
)
