// @flow
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { getOptinStatusMessages } from 'com.batch/profile/infra/formats/get-optin-status-messages'
import { getPlatformMockup } from 'com.batch/profile/infra/formats/get-platform-mockup'
import {
  type PlatformKindType,
  type SubscriptionStatus,
} from 'com.batch/profile/models/profile.records'
import {
  ProfileMockupOptinContainer,
  OptinStatusContainer,
} from 'com.batch/profile/ui/components/profile-hero/profile-platforms/profile-mockup-optin.styles'

type Props = {
  kind: PlatformKindType,
  size: 'short' | 'expanded',
  device: string,
  notifType?: string[],
  subscriptionStatus: SubscriptionStatus,
  isLoading?: boolean,
}

export const ProfileMockupOptin = ({
  kind,
  size,
  device,
  notifType = ([]: Array<string>),
  subscriptionStatus,
  isLoading,
}: Props): React.Node => {
  const optinMessage = React.useMemo(
    () => getOptinStatusMessages({ kind, subscriptionStatus, size, notifType }),
    [kind, subscriptionStatus, size, notifType]
  )

  const mockup = React.useMemo(() => getPlatformMockup(device), [device])

  return (
    <ProfileMockupOptinContainer $size={size}>
      <img src={mockup} alt="Device mockup" />
      {subscriptionStatus !== 'UNKNOWN' && (
        <ThemeProvider theme={{ platformButtomKind: kind, isLoading }}>
          <OptinStatusContainer status={isLoading ? undefined : subscriptionStatus} $size={size}>
            <Skeleton w={96} h={16} display="block">
              <span>{optinMessage.title}</span>
              <Icon icon={optinMessage.icon} />
            </Skeleton>
            {size === 'expanded' && (
              <Skeleton w={140} h={16} display="block">
                <div className="message">{optinMessage.message}</div>
              </Skeleton>
            )}
          </OptinStatusContainer>
        </ThemeProvider>
      )}
    </ProfileMockupOptinContainer>
  )
}
