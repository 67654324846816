// @flow

import { type Set, type Map } from 'immutable'
import { createSelector } from 'reselect'

import { TargetStateFactory, type TargetStateRecord } from './target.records'

import {
  type EstimateProfileRecord,
  EstimateProfileFactory,
} from '../../modules/orchestration/models/profile-estimate.records'
import { type State, type EstimateRecord, EstimateFactory } from 'com.batch.redux/_records'

type extract<T> = State => T

export const targetStateSelector = (state: State): Map<string, TargetStateRecord> => state.target

export const defaultTargetStateSelector: extract<TargetStateRecord> = createSelector(
  targetStateSelector,
  targetMap => targetMap.get('default', TargetStateFactory())
)

export const selectedSegmentCodeGetterSelector: extract<(string) => Set<string>> = createSelector(
  targetStateSelector,
  targetMap => {
    return (id: string) => targetMap.get(id, TargetStateFactory()).segments
  }
)

export const subscriptionStatusSelector: extract<'marketing' | 'fullbase'> = createSelector(
  targetStateSelector,
  targetMap => {
    return targetMap.get('default', TargetStateFactory()).subscriptionStatus
  }
)

export const selectedLanguagesConfigGetterSelector: extract<
  (string) => { set: Set<string>, inverted: boolean },
> = createSelector(targetStateSelector, targetMap => {
  return (id: string) => {
    const target = targetMap.get(id, TargetStateFactory())
    return { set: target.languages, inverted: target.languagesInverted }
  }
})

export const selectedRegionsConfigGetterSelector: extract<
  (string) => { set: Set<string>, inverted: boolean },
> = createSelector(targetStateSelector, targetMap => {
  return (id: string) => {
    const target = targetMap.get(id, TargetStateFactory())
    return { set: target.regions, inverted: target.regionsInverted }
  }
})
export const attributesLoadedSelector: extract<boolean> = createSelector(
  targetStateSelector,
  targetMap => targetMap.get('default', TargetStateFactory()).attributesLoaded
)

export const estimateGetterSelector: extract<(string) => EstimateRecord> = createSelector(
  targetStateSelector,
  targetMap => (id: string) =>
    targetMap
      .get(id, TargetStateFactory())
      .estimates.get(targetMap.get(id, TargetStateFactory()).currentEstimateHash, EstimateFactory())
)
export const estimateProfileGetterSelector: extract<(string) => EstimateProfileRecord> =
  createSelector(
    targetStateSelector,
    targetMap => (id: string) =>
      targetMap
        .get(id, TargetStateFactory())
        .profileEstimates.get(
          targetMap.get(id, TargetStateFactory()).currentEstimateHash,
          EstimateProfileFactory()
        )
  )
