// @flow

import setTimeout from 'core-js/features/set-timeout'
import { useCombobox } from 'downshift'
import Immutable, { type List } from 'immutable'
import { debounce } from 'lodash-es'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { useWidthObserver, usePopper, useToggle } from 'components/_hooks'
import { Icon } from 'components/common/svg-icon'

import { SelectDropdown } from './select-dropdown'
import {
  selectPopperConfig,
  type SelectMenuPropsType,
  type CommonSelectProps,
} from './select.helper'
import {
  SelectContainer,
  SelectField,
  SelectValueContainer,
  SelectInput,
  SelectClear,
  SelectPlaceholder,
} from './select.styles'

type AutoCompleteProps<T> = {
  ...CommonSelectProps<T>,
  value: ?T,
  optionToString: (?T) => string,
  optionCreator: string => T,
  onChange: (?T) => void,
  invalid?: boolean,
  isDisabled?: boolean,
  loadOptions: string => Promise<List<T>>,
}

/*
  actuellement utilisé nulle part, pas pu tester si mon changement est ok, et peut être à delete
*/

export function AutoComplete<T>({
  placeholder,
  isClearable,
  loadOptions,
  optionCreator,
  optionToString,
  onBlur,
  onFocus,
  menuOffset = 20,
  optionMenuShownCount,
  isDisabled,
  value,
  optionFormatter,
  optionMenuStyle,
  optionMenuHeight,
  onChange,
  ...rest
}: AutoCompleteProps<T>): React.Node {
  const [localOptions, setLocalOptions] = React.useState<List<T>>(new Immutable.List())
  const inputRef = React.useRef()
  const theme = useTheme()
  const computedDisabled = React.useMemo(() => {
    return Boolean(isDisabled || theme?.disabledMode)
  }, [isDisabled, theme?.disabledMode])

  const [triggerRef, popperRef, popperInstance] = usePopper(selectPopperConfig)
  const loadOptionsDebounced = React.useRef(
    debounce(query => {
      loadingState.open()
      loadOptions(query).then(options => {
        loadingState.close()
        setLocalOptions(options)
        popperInstance?.update()
      })
    }, 200)
  )

  const loadingState = useToggle(false)

  const {
    getMenuProps,
    isOpen,
    closeMenu,
    highlightedIndex,
    setHighlightedIndex,
    getItemProps,
    setInputValue,
    getInputProps,
  } = useCombobox({
    items: localOptions.toArray(),
    itemToString: optionToString,
    circularNavigation: true,
    selectedItem: value ?? null,
    onIsOpenChange: ({ isOpen }) => {
      if (isOpen) {
        setTimeout(() => {
          inputRef.current?.select()
          inputRef.current?.focus()
          loadOptionsDebounced.current(inputRef.current?.value ?? '')
        }, 0)
      } else {
        setTimeout(() => {
          inputRef.current?.blur()
        }, 0)
      }
      if (onBlur && !isOpen) {
        onBlur()
      }
      if (onFocus && isOpen) {
        onFocus()
      }
    },
    onInputValueChange: ({ inputValue, type }) => {
      if (type === useCombobox.stateChangeTypes.InputChange) {
        onChange(inputValue ? optionCreator(inputValue) : null)
        loadingState.open()
        loadOptionsDebounced.current(inputValue)
      }
    },
    onSelectedItemChange: changes => {
      onChange(changes.selectedItem)
      setLocalOptions(new Immutable.List())
      setInputValue('')
    },
  })
  const showClearButton = isClearable && !!value
  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })

  const inputProps = getInputProps({
    ref: inputRef,
    disabled: isDisabled,
    placeholder: value ? optionToString(value) : placeholder,
    autoFocus: rest?.autoFocus ?? false,
  })

  const width = useWidthObserver(triggerRef, 200)
  const isOptionSelected = React.useCallback((option: T) => value === option, [value])
  const onClear = React.useCallback(
    (evt: SyntheticMouseEvent<HTMLElement>) => {
      evt?.stopPropagation()
      onChange(null)
      closeMenu()
    },
    [closeMenu, onChange]
  )

  React.useLayoutEffect(() => {
    if (isOpen) {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
      }))
    } else {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
      }))
    }
  }, [isOpen, popperInstance])
  return (
    <SelectContainer
      ref={triggerRef}
      style={{ paddingLeft: 12 }}
      isFocused={isOpen}
      isDisabled={computedDisabled}
    >
      <SelectInput style={{ textIndent: 4 }} {...inputProps} isOpen={isOpen} />
      <SelectField
        isDisabled={computedDisabled}
        isFocused={isOpen}
        template={`minmax(100px, 1fr) ${showClearButton ? '33px 1px' : ''}`}
        gap={0}
      >
        <SelectValueContainer>
          {!isOpen &&
            (value ? (
              optionFormatter ? (
                optionFormatter(value, { context: 'value' })
              ) : (
                optionToString(value)
              )
            ) : (
              <SelectPlaceholder isDisabled={Boolean(isDisabled)}>{placeholder}</SelectPlaceholder>
            ))}
        </SelectValueContainer>
        {showClearButton && (
          <SelectClear onClick={onClear}>
            <Icon icon="close" />
          </SelectClear>
        )}
      </SelectField>
      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        width={width + menuOffset}
        getItemProps={getItemProps}
        optionMenuStyle={optionMenuStyle}
        optionMenuHeight={optionMenuHeight}
        optionMenuShownCount={optionMenuShownCount}
        optionToString={optionToString}
        optionFormatter={optionFormatter}
        options={localOptions}
        isOpen={isOpen}
        // isLoading={loadingState.value}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        isOptionSelected={isOptionSelected}
      />
    </SelectContainer>
  )
}
