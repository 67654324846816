// @flow

import { type Set } from 'immutable'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type PushPlatforms } from './types/push.message.types'
import { type SendTestPushResponseType } from './types/send-test-push.types'

import { type SendTestRecipientId } from '../usecases/send-test-push'

import {
  type PushContentRecord,
  type PushSettingsRecord,
} from 'com.batch/message/models/message.records'

export const sendTestPushRequest = ({
  projectKey,
  push: { content, androidContent, iosContent, webContent },
  settings: { priority, customPayload, androidOverride, iosOverride },
  platforms,
  recipientId: { kind, id },
}: {
  projectKey: string,
  push: PushContentRecord,
  settings: PushSettingsRecord,
  platforms: Set<ProjectPlatforms>,
  recipientId: SendTestRecipientId,
}): Promise<SendTestPushResponseType> => {
  let pushPlatforms: Array<PushPlatforms> = []
  if (platforms.has('ios')) pushPlatforms.push('PUSH_PLATFORM_IOS')
  if (platforms.has('android')) pushPlatforms.push('PUSH_PLATFORM_ANDROID')
  if (platforms.has('webpush')) pushPlatforms.push('PUSH_PLATFORM_WEB')

  return request.post<SendTestPushResponseType>(
    generateUrl('api_grpc_omnichannel_transmission_send_test', { methodName: 'Send' }),
    {
      projectKey: {
        textual: {
          text: projectKey,
        },
      },
      [(kind: string)]: id,
      platforms: pushPlatforms,
      pushMessageContentTemplate: {
        content: {
          title: content.templates.pushTitle,
          body: content.templates.pushBody,
          pushType: content.pushType,
          pushPriority: priority,
          picture: content.templates.pushPicture ? { url: content.templates.pushPicture } : null,
          deepLink: content.deeplink,
          customPayload,
        },
        androidOverride: {
          customPayload: androidOverride.customPayload,
          deepLink: androidContent.deeplink,
          collapseKey: androidOverride.collapseKey,
          icon: content.templates.pushIcon ? { url: content.templates.pushIcon } : null,
        },
        iosOverride: {
          customPayload: iosOverride.customPayload,
          deepLink: iosContent.deeplink,
          iosVideo: iosContent.video ? { url: iosContent.video } : null,
          iosAudio: iosContent.audio ? { url: iosContent.audio } : null,
        },
        webPushOverride: {
          deepLink: webContent.deeplink,
          icon: content.templates.pushIcon ? { url: content.templates.pushIcon } : null,
        },
      },
    }
  )
}
