// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Box, BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { colors } from 'components/styled/tokens'

import { ProfileCopyableIdContainer } from 'com.batch/profile/ui/components/profile-hero/profile-copyable-id/profile-copyable-id.styles'
import { CopyableText } from 'com.batch/shared/ui/component/copy/copyable.styles'

export const PlatformModalBody: StyledComponent<*, *, *> = styled(BoxBody)`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) auto;
  background-color: ${colors.fillDepth};
  border-radius: 0 0 7px 7px;

  & > div {
    &:first-of-type {
      padding: 28px 24px 20px 20px;
    }
    &:last-of-type {
      potition: relative;
      overflow: hidden;
      height: 234px;
    }
  }

  ${ProfileCopyableIdContainer} {
    margin-bottom: 28px;

    &:last-of-type {
      margin-bottom: 38px;
    }
  }

  ${CopyableText} {
    margin-top: 6px;
  }
`

export const ButtonReload: StyledComponent<*, *, *> = styled(Button)`
  font-size: 11px;
`

export const PlatformModalBoxHeader: StyledComponent<*, *, *> = styled(BoxHeader)`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) repeat(3, auto);
  column-gap: 8px;

  & > *:last-of-type {
    margin-right: -10px;
  }

  & > ${BoxTitle} > span {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    color: ${colors.textLight};

    & > i {
      margin-right: 8px;
    }
  }
`

export const PlatformModalBox: StyledComponent<*, *, *> = styled(Box)`
  width: 701px;
`
