// @flow

import dayjs from 'dayjs'
import Immutable from 'immutable'

import {
  OrchestrationAnalyticsDateRangeFactory,
  type OrchestrationAnalyticsDateRangeRecord,
  OrchestrationAnalyticsFactory,
  type OrchestrationAnalyticsRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { type DynamicDimension } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'

const getDateRange = (urlParams: URLSearchParams): ?OrchestrationAnalyticsDateRangeRecord => {
  const dateFromString = urlParams.get('from')
  const dateToString = urlParams.get('to')

  return dateFromString && dateToString
    ? OrchestrationAnalyticsDateRangeFactory({
        from: dayjs(dateFromString),
        to: dayjs(dateToString),
      })
    : null
}

const getFilters = (
  urlParams: URLSearchParams
): ?Immutable.Map<DynamicDimension, Immutable.List<string>> => {
  let restoredFilters = Immutable.Map<DynamicDimension, Immutable.List<string>>()

  const steps = urlParams.get('step')
  if (steps) {
    const splittedSteps = steps.split(',')
    restoredFilters = restoredFilters.set('step', new Immutable.List().push(...splittedSteps))
  }

  const languages = urlParams.get('content_language')
  if (languages) {
    const splittedLanguages = languages.split(',')
    restoredFilters = restoredFilters.set(
      'content_language',
      new Immutable.List().push(...splittedLanguages)
    )
  }

  return restoredFilters.size > 0 ? restoredFilters : null
}

export const restoreOrchestrationAnalyticsFilters = (): ?[
  [string, OrchestrationAnalyticsRecord],
] => {
  const urlParams = new URLSearchParams(window.location.search)
  const restoredDateRange = getDateRange(urlParams)
  const restoredFilters = getFilters(urlParams)

  const token = window.location.href.match(/([a-zA-Z]+)_([a-zA-Z0-9]+)/)?.[0]

  // Si pas de token ou pas de filtre rien ne sert de restaurer
  if (!token || (!restoredDateRange && !restoredFilters)) return null

  return [
    [
      token,
      OrchestrationAnalyticsFactory({
        dateRange: restoredDateRange,
        filters: restoredFilters ?? Immutable.Map(),
      }),
    ],
  ]
}
