// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { CepNavbar } from 'components/navigation/cep-navbar'
import { MepNavbar } from 'components/navigation/mep-navbar'
import { useAdminHotkeys } from 'components/project/admin-hotkeys'

import { currentAppSelector } from 'com.batch.redux/app'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'

type Props = {
  subNavItems?: React.Node,
}
export const NavbarConnected: React.ComponentType<Props> = React.memo(
  ({ subNavItems }): React.Node => {
    const project = useSelector(optionalCurrentProjectSelector)
    const app = useSelector(currentAppSelector)
    useAdminHotkeys()

    if (!project) {
      return <MepNavbar showIntegrate={app.showIntegrate} app={app} subNavItems={subNavItems} />
    }
    return <CepNavbar subNavItems={subNavItems} />
  }
)
