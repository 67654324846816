// @flow
import Immutable, { type RecordOf, type RecordFactory } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

type EstimateCount = {
  uniqueCount: number,
  nonUniqueCount: number,

  // push platform specific
  ios: number,
  android: number,
  webpush: number,
}
export type EstimateCountRecord = RecordOf<EstimateCount>
export const EstimateCountFactory: RecordFactory<EstimateCount> = Immutable.Record(
  ({
    uniqueCount: 0,
    nonUniqueCount: 0,
    ios: 0,
    android: 0,
    webpush: 0,
  }: EstimateCount)
)
type EstimateByChannel = {
  profileCount: number,
  reachableProfileCount: number,
  email: EstimateCountRecord,
  sms: EstimateCountRecord,
  push: EstimateCountRecord,
}
export type EstimateByChannelRecord = RecordOf<EstimateByChannel>
export const EstimateByChannelFactory: RecordFactory<EstimateByChannel> = Immutable.Record(
  ({
    profileCount: 0,
    reachableProfileCount: 0,
    email: EstimateCountFactory(),
    sms: EstimateCountFactory(),
    push: EstimateCountFactory(),
  }: EstimateByChannel)
)

type EstimateProfile = {
  loading: fetchingState,
  total: EstimateByChannelRecord,
  totalOptin: EstimateByChannelRecord,
  matching: EstimateByChannelRecord,
  matchingOptin: EstimateByChannelRecord,
}
export type EstimateProfileRecord = RecordOf<EstimateProfile>
export const EstimateProfileFactory: RecordFactory<EstimateProfile> = Immutable.Record(
  ({
    loading: 'INIT',
    total: EstimateByChannelFactory(),
    totalOptin: EstimateByChannelFactory(),
    matching: EstimateByChannelFactory(),
    matchingOptin: EstimateByChannelFactory(),
  }: EstimateProfile)
)
