// @flow

export type StatType =
  | 'delivered'
  | 'opened'
  | 'clicked'
  | 'bounced'
  | 'unsubscribed'
  | 'skipped'
  | 'sending'

export const STAT_TYPE = Object.freeze({
  DELIVERED: 'delivered',
  OPENED: 'opened',
  CLICKED: 'clicked',
  BOUNCED: 'bounced',
  UNSUBSCRIBED: 'unsubscribed',
  SENDING: 'sending',
  SKIPPED: 'skipped',
  SENT: 'sent',
})
