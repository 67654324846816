// @flow

import Immutable, { type List, type Map } from 'immutable'
import * as React from 'react'

import { usePopper, useClickOutsideOnExistingRefs } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import {
  type allTimeRangeShortcutType,
  type dateShortcut,
} from 'components/form/fields/date-picker/date-picker-shortcuts'
import { InputWrapper } from 'components/form/input-wrapper'
import { InputContainer } from 'components/form/styles'

import { type DateRange, type Dayjs, dayjs } from 'com.batch.common/dayjs.custom'

import { DayPickerContainer, InputButton } from './date-picker.styles'
import { DateRangePickerPopover } from './date-range-picker-popover'

type DateRangePickerProps = {
  range?: ?$ReadOnly<DateRange>,
  setRange?: DateRange => any,
  position?: 'left' | 'right',
  disabledDays?: (day: Date) => boolean,
  shortcuts?: List<dateShortcut | allTimeRangeShortcutType>,
  placeholder?: string,
  icon?: availableIcons,
  changePlaceholderOnFocus?: boolean,
  singleDayRange?: boolean,
  hasInputs?: boolean,
  onClose?: (range?: ?$ReadOnly<DateRange>) => void,
  clearable?: boolean,
  ...
}

export const DateRangePicker = ({
  range,
  setRange,
  clearable = true,
  position,
  shortcuts,
  disabledDays,
  icon,
  placeholder = 'DD/MM/YYYY',
  singleDayRange = false,
  hasInputs = true,
  onClose,
  changePlaceholderOnFocus = false,
  ...rest
}: DateRangePickerProps): React.Node => {
  const [inputs, setInputs] = React.useState<Map<string, string>>(
    Immutable.Map()
      .set('from', range?.from?.format('DD/MM/YYYY') ?? '')
      .set('to', range?.to?.format('DD/MM/YYYY') ?? '')
  )
  const [opened, setOpened] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const [from, setFrom] = React.useState<?Dayjs>(range?.from ?? null)
  const [to, setTo] = React.useState<?Dayjs>(range?.to ?? null)

  const hasSecondInput = React.useMemo(() => {
    return (
      !changePlaceholderOnFocus ||
      (changePlaceholderOnFocus && (opened || (inputs.get('from') && inputs.get('to'))))
    )
  }, [changePlaceholderOnFocus, opened, inputs])

  React.useEffect(() => {
    setInputs(
      Immutable.Map()
        .set('from', range?.from?.startOf('day').local().format('DD/MM/YYYY') ?? '')
        .set('to', range?.to?.startOf('day')?.local().format('DD/MM/YYYY') ?? '')
    )
    setFrom(range?.from ?? null)
    setTo(range?.to ?? null)
  }, [range])

  // ====================== DatePicker Modifier
  const [containerRef, popperRef] = usePopper({
    modifiers: [
      { name: 'applyStyles' },
      { name: 'eventListeners' },
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
    ],
    placement: position === 'left' ? 'bottom-end' : 'bottom-start',
  })

  const showErrors: boolean = !!error && !opened && !changePlaceholderOnFocus

  // ====================== Component Ref
  const inputFromRef = React.useRef()
  const inputToRef = React.useRef()

  // ====================== Callbacks

  const close = React.useCallback(
    newRange => {
      setOpened(false)
      if (onClose) {
        onClose(newRange ? newRange : null)
      }
    },
    [onClose]
  )
  const handleRangeSubmit = React.useCallback(() => {
    if (opened) {
      if (!error && !!from && !!to && setRange) {
        setRange({ from: from.startOf('day'), to: to.startOf('day') })
      }
      if (!!from && !!to) {
        setOpened(false)
      } else {
        close()
      }
    }
  }, [error, from, to, setRange, opened, close])

  useClickOutsideOnExistingRefs(handleRangeSubmit, [containerRef, popperRef])

  const setDay = React.useCallback(
    (newDay: Dayjs) => {
      let key: 'to' | 'from' =
        !from || (!!from && !!to) || (!!from && newDay.isBefore(from)) ? 'from' : 'to'
      setError('')
      if (key === 'from') {
        setFrom(newDay)
        setTo(null)
        setInputs(inputs.set('from', newDay.format('DD/MM/YYYY').toString()).set('to', ''))
        setError('Invalid Range.')
        inputToRef.current?.focus()
      } else if (!!from && (singleDayRange || !newDay.isSame(from))) {
        setTo(newDay)
        setInputs(inputs.set('to', newDay.format('DD/MM/YYYY').toString()))
        if (setRange) {
          setRange({ from: from, to: newDay })
        }
        close({ from: from, to: newDay })
      }
    },
    [from, to, singleDayRange, inputs, setRange, close]
  )

  const handleInputsChange = React.useCallback(
    (key: 'from' | 'to', evt) => {
      let value = evt.target.value
      let newDay = value ? dayjs(value, 'DD/MM/YYYY', 'fr', true) : null
      setError('')

      // Manage inputs changes
      if (value.length <= 10 && value.match(/^[0-9/]*$/)) {
        setInputs(inputs.set(key, value))
        if (value.length < 10) key === 'from' ? setFrom(null) : setTo(null)
        // When we have a valid date
        else if (
          !!newDay &&
          newDay.isValid() &&
          (!disabledDays || !disabledDays(newDay.toDate()))
        ) {
          if (key === 'from' && (!to || to.isAfter(newDay))) setFrom(newDay)
          else if (key === 'to' && (!from || from.isBefore(newDay))) setTo(newDay)
          else setError('Invalid range.')
        }
        // Error : if the format is invalid
        else
          setError(
            !!newDay && newDay.isValid() ? 'Date out of range.' : 'Wrong date format DD/MM/YYYY.'
          )
      }
    },
    [inputs, disabledDays, to, from]
  )

  const handleInputsKeyPress = React.useCallback(
    (key: 'from' | 'to', evt) => {
      switch (evt.key) {
        case 'Enter':
          key === 'from' ? inputFromRef.current?.blur() : inputToRef.current?.blur()
          handleRangeSubmit()
          break

        case 'Tab':
          evt.preventDefault()
          if (key === 'from') {
            inputFromRef.current?.blur()
            inputToRef.current?.focus()
          } else {
            inputToRef.current?.blur()
            handleRangeSubmit()
          }
      }
    },
    [handleRangeSubmit, inputFromRef]
  )

  const clearAll = React.useCallback(() => {
    close()
    setInputs(Immutable.Map().set('from', '').set('to', ''))
    setFrom(null)
    setTo(null)
  }, [close, setInputs])

  const open = React.useCallback(() => {
    if (!opened) {
      setOpened(true)
    }
  }, [opened])

  const onInputKeyDown = React.useCallback(
    (key: 'from' | 'to') => (evt: SyntheticKeyboardEvent<HTMLInputElement>) => {
      handleInputsKeyPress(key, evt)
    },
    [handleInputsKeyPress]
  )

  const onInputChange = React.useCallback(
    (key: 'from' | 'to') => (evt: SyntheticInputEvent<HTMLInputElement>) => {
      handleInputsChange(key, evt)
    },
    [handleInputsChange]
  )

  const clearErrors = React.useCallback(() => {
    setError('')
  }, [])

  // ====================== Render
  return (
    <DayPickerContainer
      {...rest}
      ref={containerRef}
      isRange
      position={position ?? 'left'}
      $hasValue={!!from && !!to}
      opened={opened}
      $hasIcon={!!icon}
    >
      {hasInputs ? (
        <InputWrapper feedback={showErrors ? error : ''}>
          <InputContainer invalid={showErrors} onClick={open} data-testid="button">
            {icon && (
              <div className="styled-input-addons styled-addon-pre">
                <Icon icon={icon} style={{ marginLeft: 10, marginRight: 8 }} />
              </div>
            )}
            <InputButton
              ref={inputFromRef}
              type="text"
              placeholder={changePlaceholderOnFocus && opened ? 'Start date' : placeholder}
              value={inputs.get('from')}
              onFocus={open}
              onKeyDown={onInputKeyDown('from')}
              onChange={onInputChange('from')}
              data-testid="from"
              style={{ padding: 0, width: hasSecondInput ? '' : 90 }}
            />
            {hasSecondInput && (
              <React.Fragment>
                <hr />
                <input
                  ref={inputToRef}
                  type="text"
                  placeholder={changePlaceholderOnFocus && opened ? 'End date' : placeholder}
                  value={inputs.get('to')}
                  onFocus={open}
                  onKeyDown={onInputKeyDown('to')}
                  onChange={onInputChange('to')}
                  style={{ padding: 0 }}
                  data-testid="to"
                />
              </React.Fragment>
            )}
          </InputContainer>
          {clearable && hasSecondInput && (inputs.get('from') || inputs.get('to')) && (
            <Button
              intent="neutral"
              kind="discreet"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={clearAll}
              aria-label="Clear date fields"
            >
              <Icon icon={'close'} />
            </Button>
          )}
        </InputWrapper>
      ) : (
        <Button kind="inline" intent="action" addOn="prefix" onClick={open}>
          <Icon icon="calendar" />
          {inputs.get('from') && inputs.get('to') ? (
            <React.Fragment>
              {inputs.get('from')} to {inputs.get('to')}
            </React.Fragment>
          ) : (
            'All time'
          )}
        </Button>
      )}

      {opened && (
        <DateRangePickerPopover
          ref={popperRef}
          setFrom={setFrom}
          setTo={setTo}
          close={close}
          clearErrors={clearErrors}
          from={from}
          to={to}
          disabledDays={disabledDays}
          setRange={setRange}
          setInputs={setInputs}
          shortcuts={shortcuts}
          setDay={setDay}
        />
      )}
    </DayPickerContainer>
  )
}
