// @flow

import * as React from 'react'

import { TableTemplateCell } from 'components/common/table'

type AudienceEmptyListProps = { ... }

export const AudienceEmptyList = ({}: AudienceEmptyListProps): React.Node => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" align="end" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}
