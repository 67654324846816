// @flow

import styled, { css, type StyledComponent } from 'styled-components'

type LoaderContainerProps = { overlay?: boolean, padding: ?number, ... }
export const LoaderContainer: StyledComponent<LoaderContainerProps, *, HTMLElement> = styled.div`
  position: relative;
  ${(props: LoaderContainerProps) =>
    !props.overlay &&
    css`
      min-height: 120px;
    `}

  .styled-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: ${(props: LoaderContainerProps) => props.padding ?? 0}px;

    ${(props: LoaderContainerProps) =>
      props.overlay &&
      css`
        z-index: 100;
        position: absolute;

        ${'' /* opacity: 0.7; */}
        ${'' /* background-color: #f5f5f5; */}
        background-color: white;
        background-blend-mode: lighten;
      `}
  }
`
