// @flow

import { type Browser } from 'com.batch/profile/infra/debug/models/shared.model'

export const browsersList: Array<Browser> = ['CHROME', 'SAFARI', 'EDGE', 'FIREFOX', 'UNKNOWN']
export const browsers = {
  CHROME: {
    icon: 'chrome-colored',
    label: 'Chrome',
  },
  SAFARI: {
    icon: 'safari',
    label: 'Safari',
  },
  EDGE: {
    icon: 'edge',
    label: 'Edge',
  },
  FIREFOX: {
    icon: 'firefox',
    label: 'Firefox',
  },
  UNKNOWN: {
    icon: 'webpush',
    label: 'Web browser',
  },
}
