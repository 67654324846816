// @flow
import Immutable, { type OrderedSet } from 'immutable'

import { stringToUtcDayJs } from 'com.batch.common/dayjs.custom'

import {
  customEventTypeToEventDataTypeAttribute,
  addPrefixOnValue,
  customDataTypeToTypeAttribute,
} from '../helpers'
import {
  AttributeFactory,
  EventDataAttributeFactory,
  type AttributeRecord,
  type EventDataAttributeRecord,
} from 'com.batch.redux/_records'
import { type CustomDataSummary } from 'com.batch.redux/adapters/ucp/models/custom-data'
import { TypesIcons, LabelEventParam, TagEventParam } from 'com.batch.redux/attribute.api'

export const parseCustomDataToAttributeRecords = (
  customData: CustomDataSummary
): AttributeRecord => {
  let allowedKeys: OrderedSet<EventDataAttributeRecord> = Immutable.OrderedSet()
  if (customData.type === 'EVENTS') {
    if (customData.hasLabel) {
      allowedKeys = allowedKeys.add(LabelEventParam)
    }
    if (customData.hasTag) {
      allowedKeys = allowedKeys.add(TagEventParam)
    }
    if (customData.attributes) {
      if (Array.isArray(customData.attributes)) {
        customData.attributes.forEach(attr => {
          allowedKeys = allowedKeys.add(
            EventDataAttributeFactory({
              name: attr.name,
              type: customEventTypeToEventDataTypeAttribute(customData.type, attr.type),
              kind: 'attribute',
              icon: TypesIcons[attr.type],
            })
          )
        })
      }
    }
  }

  const attribute = AttributeFactory({
    type: customDataTypeToTypeAttribute(customData.type, customData.lastReceivedType),
    lastReceivedType: customData.lastReceivedType,
    lastUpdate: stringToUtcDayJs(customData.lastUpdate),
    id: addPrefixOnValue({ type: customData.type, value: customData.key }),
    label: customData.key,
    allowedKeys,
    icon: TypesIcons[customData?.type === 'TAGS' ? 'TAG' : customData.lastReceivedType],
  })

  return attribute
}
