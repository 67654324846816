// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ParseReachabilityResponse } from 'com.batch/profilebase/infra/parses/parse-reachability'
import { type PlatformFilterType } from 'com.batch/shared/infra/types/push-platform-filter.type'

type FetchEmailReachabilityTimeserieStateAction = ReduxAction<'FETCH_EMAIL_REACHABILITY', null>
type FetchEmailReachabilityTimeserieStateSuccessAction = ReduxAction<
  'FETCH_EMAIL_REACHABILITY_SUCCESS',
  ParseReachabilityResponse,
>
export type FetchEmailReachabilityTimeserieStateFailureAction = ReduxAction<
  'FETCH_EMAIL_REACHABILITY_FAILURE',
  Error,
>
export type FetchEmailReachabilityTimeserieActions =
  | FetchEmailReachabilityTimeserieStateAction
  | FetchEmailReachabilityTimeserieStateSuccessAction
  | FetchEmailReachabilityTimeserieStateFailureAction

type FetchSmsReachabilityTimeserieStateAction = ReduxAction<'FETCH_SMS_REACHABILITY', null>
type FetchSmsReachabilityTimeserieStateSuccessAction = ReduxAction<
  'FETCH_SMS_REACHABILITY_SUCCESS',
  ParseReachabilityResponse,
>
export type FetchSmsReachabilityTimeserieStateFailureAction = ReduxAction<
  'FETCH_SMS_REACHABILITY_FAILURE',
  Error,
>
export type FetchSmsReachabilityTimeserieActions =
  | FetchSmsReachabilityTimeserieStateAction
  | FetchSmsReachabilityTimeserieStateSuccessAction
  | FetchSmsReachabilityTimeserieStateFailureAction

type FetchPushReachabilityTimeserieStateAction = ReduxAction<'FETCH_PUSH_REACHABILITY', null>
type FetchPushReachabilityTimeserieStateSuccessAction = ReduxAction<
  'FETCH_PUSH_REACHABILITY_SUCCESS',
  ParseReachabilityResponse,
>
export type FetchPushReachabilityTimeserieStateFailureAction = ReduxAction<
  'FETCH_PUSH_REACHABILITY_FAILURE',
  Error,
>
export type FetchPushReachabilityTimeserieActions =
  | FetchPushReachabilityTimeserieStateAction
  | FetchPushReachabilityTimeserieStateSuccessAction
  | FetchPushReachabilityTimeserieStateFailureAction

type FetchWebpushReachabilityTimeserieStateAction = ReduxAction<'FETCH_WEBPUSH_REACHABILITY', null>
type FetchWebpushReachabilityTimeserieStateSuccessAction = ReduxAction<
  'FETCH_WEBPUSH_REACHABILITY_SUCCESS',
  ParseReachabilityResponse,
>
export type FetchWebpushReachabilityTimeserieStateFailureAction = ReduxAction<
  'FETCH_WEBPUSH_REACHABILITY_FAILURE',
  Error,
>
export type FetchWebpushReachabilityTimeserieActions =
  | FetchWebpushReachabilityTimeserieStateAction
  | FetchWebpushReachabilityTimeserieStateSuccessAction
  | FetchWebpushReachabilityTimeserieStateFailureAction

export const fetchReachabilityTimeserie = (
  channel: 'SMS' | 'EMAIL' | 'PUSH' | 'WEBPUSH',
  platformFilter?: PlatformFilterType
): DispatchExtraBoundFn<Promise<ParseReachabilityResponse>> => {
  return (dispatch, getState, { ucpDataService }) => {
    const { projectKey } = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: ucpDataService.fetchReachabilityTimeserie({
        projectKey,
        channel,
        platform: platformFilter ?? 'PLATFORM_FILTER_UNSPECIFIED',
      }),
      actionName: `FETCH_${channel}_REACHABILITY`,
    })
  }
}
