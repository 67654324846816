// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { useClickOutside, useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import Loader from 'components/common/loader-legacy'
import { Icon, IconContainer } from 'components/common/svg-icon'
import { schemes } from 'components/styled/tokens'

type PreviewModeTemplateProps = {
  loading: boolean,
  next: () => void,
  prev: () => void,
  indice: number,
  ...
}

const Cont: StyledComponent<*, *, HTMLElement> = styled.div`
  position: relative;
`
type PanelProps = { open: boolean, ... }
const Panel: StyledComponent<PanelProps, *, HTMLElement> = styled.div`
  display: ${(props: PanelProps) => (props.open ? 'block' : 'none')};
  position: absolute;
  bottom: 6px;
  z-index: 9999;
  right: 10px;
  padding: 12px 18px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0 1px 24px rgba(0, 0, 0, 0.06);
`

const Title: StyledComponent<*, *, HTMLElement> = styled.label`
  margin: 0 14px 0 0;
  font-size: 11px;
  font-weight: 500;
  color: #a9aeb5;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  flex: 0 0 100px;
`
const NextAndPrevButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: center;
  background: ${schemes.darklucent['10']};
  border: none;
  color: ${schemes.grayscale['60']};
  border-radius: 4px;
  width: 25px;
  height: 25px;
  ${IconContainer} {
    line-height: unset;
  }
  &:hover,
  &:focus {
    color: ${schemes.grayscale['70']};
    background: ${schemes.darklucent['05']};
  }
`

export const PreviewModeTemplate = (props: PreviewModeTemplateProps): React.Node => {
  const { prev, next, indice, loading } = props
  const openState = useToggle()
  const [ref] = useClickOutside(() => {
    if (open) {
      openState.close()
    }
  })
  return (
    <Cont>
      <Button isActive={openState.value} onClick={openState.open}>
        <Icon icon="macros" />
      </Button>
      <Panel open={openState.value} ref={ref}>
        <FlexLine style={{ width: 320 }}>
          <Title>Device sample</Title>
          <FlexLineItem width={110} style={{ display: 'flex' }}>
            <NextAndPrevButton onClick={prev} style={{ marginRight: 5 }}>
              <Icon icon="chevron-left" />
            </NextAndPrevButton>

            <NextAndPrevButton onClick={next}>
              <Icon icon="chevron-right" />
            </NextAndPrevButton>
          </FlexLineItem>
          <FlexLineItem width={30}>
            {loading && (
              <div style={{ width: '30px' }}>
                <Loader size="tiny" loading />
              </div>
            )}
          </FlexLineItem>
          <FlexLineItem width={90}>{indice === 0 ? 10 : indice}/10 Device</FlexLineItem>
        </FlexLine>
      </Panel>
    </Cont>
  )
}
