// @flow

import * as React from 'react'
import { ThemeContext } from 'styled-components'

type PushOverviewMetricsProps = {
  emptyTemplate: React.Node,
  children: React.Node,
  ...
}

export function PushOverviewMetrics({
  emptyTemplate,
  children,
}: PushOverviewMetricsProps): React.Node {
  const themeContext = React.useContext(ThemeContext)

  return (
    <React.Fragment>
      {themeContext.isEmpty || themeContext.isLoading ? emptyTemplate : children}
    </React.Fragment>
  )
}
