// @flow

import { color as D3Color } from 'd3-color'
import { pie as D3Pie, arc as D3Arc } from 'd3-shape'
import * as React from 'react'

import { useWidthObserver } from 'components/_hooks'
import { TooltipMetric, TooltipDescription } from 'components/campaign/review/data/delivery.styles'
import { DumbPortal } from 'components/common/dumb-portal'
import { TooltipContainer, TooltipInner, TooltipArrow } from 'components/common/tt'

import { numberFormat } from 'com.batch.common/utils'

type DonutProps = {
  size: number,
  overOffset: number,
  duration: number,
  values: Array<number>,
  ids: Array<number>,
  colors: Array<string>,
  maxWidth: number,
  tooltips: Array<{
    id: number,
    label: string,
    color: string,
    value: number,
    tooltip: string | null,
  }>,
}

export const Donut: React.ComponentType<DonutProps> = React.memo(
  ({ values, colors, size, overOffset, maxWidth, tooltips }) => {
    const [overId, setOverId] = React.useState<?number>(null)
    const [tooltipX, setTooltipX] = React.useState(0)
    const [tooltipY, setTooltipY] = React.useState(0)
    const ref = React.useRef(null)
    const width = useWidthObserver(ref, 20)
    const { pie, d3colors, arc, tooltip } = React.useMemo(() => {
      const w = Math.min(maxWidth, width)
      const radius = w / 2 - overOffset * 2
      const d3colors = colors.map(D3Color)
      const pie = D3Pie()
        .value(d => d)
        .sort(null)
        .startAngle(-Math.PI - 0.4)
        .endAngle(Math.PI - 0.4)
        .padAngle(w * 0.00004)(values)

      const arc = (d: any) => {
        return D3Arc()
          .outerRadius(radius)
          .innerRadius(radius - size)
          .cornerRadius(1.5)(d)
      }
      const tooltip =
        typeof overId !== 'undefined' && overId !== null && tooltips[overId]
          ? tooltips[overId]
          : null
      return { d3colors, pie, arc, tooltip }
    }, [colors, maxWidth, overId, overOffset, size, tooltips, values, width])

    const onMouseLeave = React.useCallback(() => {
      setOverId(null)
    }, [])
    const createOnMouseMove = React.useCallback(
      (i: number) => (evt: SyntheticMouseEvent<HTMLElement>) => {
        setOverId(i)
        setTooltipX(evt.nativeEvent.pageX - 300)
        setTooltipY(evt.nativeEvent.pageY - 60)
      },
      []
    )
    return (
      <div style={{ position: 'relative', minWidth: 100, flexGrow: 1 }} ref={ref}>
        {tooltip && (
          <DumbPortal>
            <TooltipContainer
              placement="left"
              style={{
                position: 'absolute',
                width: 270,
                top: tooltipY,
                left: tooltipX,
                opacity: overId !== null ? 1 : 0,
              }}
            >
              <TooltipArrow placement="left" />
              <TooltipInner style={{ width: 260, maxWidth: 260 }} noPadding>
                <TooltipMetric color={tooltip.color}>
                  <label>{tooltip.label}</label>
                  <strong>{numberFormat(tooltip.value)}</strong>
                </TooltipMetric>
                {tooltip.tooltip && <TooltipDescription>{tooltip.tooltip}</TooltipDescription>}
              </TooltipInner>
            </TooltipContainer>
          </DumbPortal>
        )}
        <svg width={width} height={width}>
          <g transform={`translate(${width / 2},${width / 2})`}>
            {pie.map((d, i) => (
              <path
                d={arc(d)}
                key={i}
                id={`donut-id-${i})`}
                onMouseLeave={onMouseLeave}
                onMouseMove={createOnMouseMove(i)}
                opacity={overId === null || overId === i ? 1 : 0.7}
                fill={d3colors[i]}
              />
            ))}
          </g>
        </svg>
      </div>
    )
  }
)
