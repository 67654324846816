// @flow
import styled, { css, type CSSRules, type StyledComponent } from 'styled-components'

import { schemes, rules, colors } from 'components/styled/tokens'

import { InputContainer } from '../form/styles'

//  the isSearchable Search for select component
export const SelectSearchInput: StyledComponent<*, *, *> = styled.input`
  padding: 0px 0px 0px 12px;
  width: 100%;
  background: transparent;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  border: none;
  position: sticky;
  top: 0;
  *:focus {
    outline: none;
  }
  &::placeholder {
    font-weight: 500;
    color: ${colors.textLight};
    font-size: 14px;
  }
`

//  RULES Style
export const InputItemStyles: CSSRules = css`
  width: 100%;
  background: transparent;
  height: 36px;
  border-radius: 5px;
  padding: 0 12px;
  color: ${colors.textLight};
  box-shadow: none;
  border: none;

  &:hover {
    background-color: ${colors.opacifyHover};

    .styled-addon-pre,
    .styled-input-addon {
      i {
        color: ${colors.text};
      }
    }
  }

  &:focus-within {
    color: ${colors.text};
    background-color: ${colors.opacifyActive};
    box-shadow: none;
    .styled-addon-pre,
    .styled-input-addon {
      i {
        color: ${colors.text};
      }
    }
  }
`

// container for the search component
export const FilterSearchContainer: StyledComponent<
  { $hasValue: boolean, ... },
  *,
  typeof InputContainer,
> = styled(InputContainer)`
  ${InputItemStyles}
  ${(props: { $hasValue: boolean, ... }) =>
    props.$hasValue ? rules.FilterWithValue : rules.FilterWithoutValue}
  .styled-input-addons > i {
    margin-top: -3px;
  }
`
// Maybe rename...
export const WrapperSearchInput: StyledComponent<*, *, HTMLElement> = styled.div`
  *:focus {
    outline: none;
  }
  padding: 0px 12px 0px 12px;
  display: flex;
  align-items: center;
  background: ${schemes.lightlucent['90']};
  margin-bottom: 0px; 
  border-bottom: 1px solid ${colors.stroke};
  position: sticky;
  top: 0;
  z-index: 2;
  height: 36px;
  padding: 0 12px;
  color: ${colors.text};


  .styled-addon-pre,
    .styled-input-addon {
      i {
   color: ${colors.textLight};
      }
    }

  &:hover,
  &:focus {
    color: ${colors.text};

    .styled-input-addons {
      &.styled-addon-pre {
        padding-left: 0px;
      }

      > i {
        line-height: 14px;
        margin-top: -1px;
        color: ${colors.text};
      }
    }

  
  
`
