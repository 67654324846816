// @flow
'use strict'
import * as React from 'react'
import { createRoot } from 'react-dom/client'

import App from 'components/console/app'

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
}
