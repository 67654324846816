// @flow

import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

export const color = '#7c7d81'
type ThemeProps = { placeholder?: boolean, ... }
type LabelPlaceholderProps = { placeholder_width?: number, ... }
type OnlyThemeProps = PropsWithTheme<{ ... }, ThemeProps>
type ThemedLabelPlaceholderProps = PropsWithTheme<LabelPlaceholderProps, ThemeProps>
export const LabelPlaceholder: StyledComponent<LabelPlaceholderProps, ThemeProps, HTMLSpanElement> =
  styled.span`
    ${(props: ThemedLabelPlaceholderProps) =>
      props.theme.placeholder &&
      css`
        position: relative;
        color: transparent !important;
        white-space: nowrap;
        overflow: hidden;
        letter-spacing: -10000px !important;

        * {
          display: none !important;
          visibility: hidden !important;
        }

        &:before {
          display: inline-block;
          content: 'p';
          width: ${(props: any) => props.placeholder_width || '64%'};
          max-width: 200px;
          min-width: 48px;
          background-color: ${color};
          border-radius: 2px;
        }
      `}
  `

export const TextPlaceholder: StyledComponent<{ ... }, ThemeProps, HTMLParagraphElement> = styled.p`
  ${(props: OnlyThemeProps) =>
    props.theme.placeholder &&
    css`
      position: relative;
      color: transparent !important;
      letter-spacing: -1000000px !important;
      overflow: hidden;

      > * {
        display: none !important;
        visibility: hidden !important;
      }

      &:before {
        display: inline;
        position: relative;
        z-index: 5;
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet odio et urna consectetur semper. Phasellus lobortis et odio in sollicitudin.';
        background-color: ${color};
        letter-spacing: 0;
      }
    `}
`

export const ButtonPlaceholder: StyledComponent<{ ... }, ThemeProps, HTMLButtonElement> =
  styled.button`
    ${(props: OnlyThemeProps) =>
      props.theme.placeholder &&
      css`
        position: relative;
        background: hsla(228, 2%, 50%, 0.28) !important;
        box-shadow: none !important;
        color: transparent !important;
        overflow: hidden;

        &:after {
          position: absolute;
          top: calc(50% - 0.5em);
          left: 10px;
          right: 10px;
          height: 1em;
          content: '';
          background-color: ${color};
          border-radius: 2px;
        }
      `}
  `

export const HiddenPlaceholder: StyledComponent<*, ThemeProps, HTMLSpanElement> = styled.span`
  ${(props: PropsWithTheme<any, { placeholder?: boolean, ... }>) =>
    props.theme.placeholder &&
    css`
      opacity: 0 !important;
    `}
`

export const ShapePlaceholder: StyledComponent<{ ... }, { placeholder?: boolean, ... }, Element> =
  styled.path`
    ${(props: OnlyThemeProps) =>
      props.theme.placeholder &&
      css`
        fill: ${color};
      `}
  `
