// @flow

import styled, { css, type StyledComponent } from 'styled-components'

type FlexLineProps = {
  mb?: boolean,
  height?: number,
  separator?: boolean,
  shouldWrap?: boolean,
  condensed?: boolean,
  center?: boolean,
  container?: boolean,
  notRounded?: boolean,
  sameHeight?: boolean,
  top?: boolean,
  vertical?: boolean,
  grow?: boolean,
  ...
}
// $FlowFixMe borken libdef in 4.x
/**
 * @deprecated do not use this legacy stuff
 */
export const FlexLine: StyledComponent<FlexLineProps, { ... }, HTMLDivElement> = styled.div`
  display: flex;
  min-height: ${(props: FlexLineProps) => (props.condensed ? '0px' : '34px')};
  flex-wrap: ${(props: FlexLineProps) => (props.shouldWrap ? 'wrap' : 'nowrap')};
  height: ${(props: FlexLineProps) => (props.height ? `${props.height}px` : 'auto')};
  justify-content: ${(props: FlexLineProps) => (props.center ? 'center' : 'flex-start')};
  flex-direction: ${(props: FlexLineProps) => (props.vertical ? 'column' : 'row')};
  margin-bottom: ${(props: FlexLineProps) => (props.mb ? '15px' : '0')};
  ${(props: FlexLineProps) => (props.grow ? 'flex-grow:1;' : '')} ${(props: FlexLineProps) =>
    props.separator ? 'border-top: 1px solid #F2F3F8;' : ''} ${(props: FlexLineProps) =>
    props.container
      ? `border-radius: ${props.notRounded ? '0' : '0 0 5px 5px'}; padding:32px;background: #fff;`
      : ''} align-items: ${(props: FlexLineProps) =>
    props.top ? 'flex-start' : props.sameHeight ? 'stretch' : 'center'};
  @media (max-width: 650px) {
    flex-direction: column;
  }
`

export const ConditionLine: StyledComponent<FlexLineItemProps, { ... }, typeof FlexLine> = styled(
  FlexLine
)`
  border-bottom: 1px solid #f2f3f8;
  padding-bottom: 8px;
  margin-bottom: 7px;
  padding-right: 30px;
`
type FlexLineItemTitleProps = { sm?: boolean, mb?: number, ... }
export const FlexLineItemTitle: StyledComponent<FlexLineItemTitleProps, { ... }, HTMLDivElement> =
  styled.div`
    color: #9ca4b5;
    font-weight: 500;
    font-size: ${(props: FlexLineItemTitleProps) => (props.sm ? '10px' : '12px')};
    ${(props: FlexLineItemTitleProps) =>
      props.mb
        ? css`
            margin-bottom: 10px;
          `
        : ''} text-transform: uppercase;
  `
type FlexLineItemProps = {
  width?: number,
  bl?: boolean,
  right?: boolean,
  fit?: boolean,
  switch?: boolean,
  scrollable?: boolean,
  ellipsis?: boolean,
  container?: boolean,
  hideUnder?: number,
  tinyContainer?: boolean,
  contentVA?: boolean,
  center?: boolean,
  grow?: number,
  ...
}

// $FlowFixMe borken libdef in 4.x
export const FlexLineItem: StyledComponent<FlexLineItemProps, { ... }, HTMLDivElement> = styled.div`
  ${(props: FlexLineItemProps) =>
    props.ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
  ${(props: FlexLineItemProps) =>
    props.hideUnder &&
    css`
      @media screen and (max-width: ${(props: any) => props.hideUnder}px) {
        display: none;
      }
    `}
  ${(props: FlexLineItemProps) =>
    props.fit
      ? css`
          overflow: auto;
        `
      : ''}
  flex-shrink: ${(props: FlexLineItemProps) => (props.width ? 0 : 1)};
  min-width: 0px;
  text-align: ${(props: FlexLineItemProps) => (props.right ? 'right' : 'inherit')};
  flex-basis: ${(props: FlexLineItemProps) => (props.width ? props.width + 'px' : 'auto')};
  overflow-y: ${(props: FlexLineItemProps) => (props.scrollable ? 'auto' : 'inherit')};
  flex-grow: ${(props: FlexLineItemProps) => (props.grow ? `${props.grow}` : 0)};
  align-items: ${(props: FlexLineItemProps) => (props.center ? 'center' : 'start')};
  ${(props: FlexLineItemProps) =>
    props.bl
      ? css`
          border-left: 1px solid #f2f3f8;
        `
      : css`
          &:last-child {
            padding-right: 0;
          }
        `}
  ${(props: FlexLineItemProps) =>
    props.container
      ? css`
          border-radius: 0 0 5px 5px;
          background: #fff;
        `
      : ''}
  padding: ${(props: FlexLineItemProps) =>
    props.container ? '32px' : props.tinyContainer ? '16px 20px' : '0 10px 0 0'};
  ${(props: FlexLineItemProps) =>
    props.switch
      ? css`
          padding-bottom: 4px;
        `
      : '0'};
  ${(props: FlexLineItemProps) =>
    props.contentVA
      ? css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `
      : ''};
  @media (max-width: 650px) {
    flex-basis: auto;
    border-left: none;
    flex-shrink: 0;
    padding: ${(props: FlexLineItemProps) => (props.container ? '8px' : '0 0 8px 0')};
  }
`

export const FlexLineItemOption: StyledComponent<FlexLineItemProps, { ... }, typeof FlexLineItem> =
  styled(FlexLineItem)`
    font-size: 13px;
    font-weight: normal;
  `

export const FlexLineItemSwitch: StyledComponent<FlexLineItemProps, { ... }, typeof FlexLineItem> =
  styled(FlexLineItem)`
    padding-bottom: 4px;
  `
