// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { BoxBody } from 'components/common/box/box.styles'
import { TemplateWizard } from 'components/template/template-wizard'

import { previewLanguageSelector } from 'com.batch/message/store/message.selector'
import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selector'
import { getSmsContentForActiveLanguageSelector } from 'com.batch/sms/store/sms.selector'
import { personalisationAttrSelector } from 'com.batch.redux/attribute.selector'
import { triggerEventSelector } from 'com.batch.redux/campaign.selector'

import { Preview, PreviewMockup, type ThemeProps } from 'com.batch/message-builder/ui/components'
import {
  InlineContainer,
  PreviewContainer,
  StickyInfoPanelContainer,
  VariantContainer,
} from 'com.batch/message-builder/ui/components/common.styles'
import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'
import { SmsLayerBar } from 'com.batch/sms/ui/components/sms-layer-bar'
import { updateSmsContent } from 'com.batch/sms/usecases/update-sms-content'
import { updateSmsTemplate } from 'com.batch/sms/usecases/update-sms-template'

type SmsBuilderProps = {
  messageId: string,
}
export const SmsBuilder = ({ messageId }: SmsBuilderProps): React.Node => {
  const dispatch = useDispatch()
  const editorConfig = useSelector(inlineEditorConfigSelector)
  const editing = React.useMemo(() => editorConfig.field, [editorConfig.field])
  const attributes = useSelector(personalisationAttrSelector)
  const contextEventId = useSelector(triggerEventSelector)
  const lang = useSelector(previewLanguageSelector)
  const getContent = useSelector(getSmsContentForActiveLanguageSelector)
  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])

  const previewTheme = React.useMemo<ThemeProps>(
    () => ({
      previewPlatform: 'sms',
      isFocused: editing === 'message' || editing === 'title' || editing === 'smsMessage',
    }),
    [editing]
  )

  const updateTemplate = React.useCallback(
    (toAppend: string) => {
      const { selection } = editorConfig
      const message = content.get('smsMessage', '')
      const computedMessage =
        message.substring(0, selection[0]) + toAppend + message.substring(selection[1])
      dispatch(
        updateSmsContent({
          messageId,
          lang,
          field: 'smsMessage',
          value: computedMessage,
          isInstant: true,
        })
      )
    },
    [dispatch, messageId, lang, content, editorConfig]
  )

  const closeTemplateWizard = React.useCallback(() => {
    dispatch(setInlineEditor(editorConfig.set('personalizationModalIsOpen', false)))
  }, [dispatch, editorConfig])

  React.useEffect(() => {
    dispatch(updateSmsTemplate({ messageId, lang }))
  }, [dispatch, messageId, lang])

  return (
    <BoxBody style={{ height: '100%' }}>
      <VariantContainer $channel="sms" $form="orchestration">
        <TemplateWizard
          appendText={updateTemplate}
          attributes={attributes}
          close={closeTemplateWizard}
          contextEventId={contextEventId}
          opened={editorConfig.personalizationModalIsOpen}
        />

        <React.StrictMode>
          <ThemeProvider theme={previewTheme}>
            <InlineContainer>
              <StickyInfoPanelContainer id={'js-variant-a'} />

              <SmsLayerBar messageId={messageId} />

              <PreviewMockup />
              <PreviewContainer>
                <Preview messageId={messageId} previewPlatform="sms" variant="a" />
              </PreviewContainer>
            </InlineContainer>
          </ThemeProvider>
        </React.StrictMode>
      </VariantContainer>
    </BoxBody>
  )
}
