// @flow
import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { orchestrationPlatformsSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { currentProjectPlatformsSelector } from 'com.batch.redux/app'

import { updatePushPlatforms } from 'com.batch/push/usecases/update-push-platforms'
import {
  PlatformsIcons,
  SelectPlatform,
  formatPlatformName,
} from 'com.batch/shared/ui/component/select-platform/select-platform'

const platformList = ['ios', 'android', 'webpush']
export const formatPlatformList = (platforms: Set<ProjectPlatforms>): string =>
  platforms
    .sort((a, b) => platformList.indexOf(a) - platformList.indexOf(b))
    .toList()
    .reduce(
      (acc, val, i) =>
        acc +
        (i === platforms.size - 1 && platforms.size > 1 ? ' & ' : i > 0 ? ', ' : '') +
        formatPlatformName(val),
      ''
    )

type PlatformPickerProps = {
  campaignState?: campaignStateType,
  hasPushIcon?: boolean,
  stepMessageNodeId?: string,
}
export const PlatformPicker = ({
  campaignState,
  hasPushIcon = false,
  stepMessageNodeId,
}: PlatformPickerProps): React.Node => {
  const dispatch = useDispatch()
  const projectPlatforms = useSelector(currentProjectPlatformsSelector)
  const orchestrationPlatforms = useSelector(orchestrationPlatformsSelector)
  const platforms = React.useMemo(
    () => (campaignState === 'COMPLETED' ? orchestrationPlatforms : projectPlatforms),
    [campaignState, orchestrationPlatforms, projectPlatforms]
  )

  const getMessageConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(() => {
    return getMessageConfig({ stepMessageNodeId })
  }, [getMessageConfig, stepMessageNodeId])
  const selectedPlatforms = React.useMemo(() => config?.platforms ?? new Immutable.Set(), [config])
  const onPlatformsChange = React.useCallback(
    platforms => {
      dispatch(updatePushPlatforms({ stepMessageNodeId, platforms: platforms }))
    },
    [dispatch, stepMessageNodeId]
  )
  const disabledPlatforms = React.useMemo(() => {
    let disabledPlatforms = new Immutable.Set<ProjectPlatforms>()
    if (config.pushSettings.filterPushTokens === 'imported') {
      disabledPlatforms = disabledPlatforms.add('webpush')
    }
    return disabledPlatforms
  }, [config.pushSettings.filterPushTokens])

  const tooltipContent = React.useMemo(() => {
    const base = 'This message will be sent to '
    if (selectedPlatforms.size === 0) return null
    else if (selectedPlatforms.size === 1)
      return base + formatPlatformName(selectedPlatforms.first())
    return base + formatPlatformList(selectedPlatforms)
  }, [selectedPlatforms])

  if (platforms.size === 0) return null

  return platforms.size > 1 ? (
    <SelectPlatform
      platforms={platforms}
      value={selectedPlatforms}
      onChange={onPlatformsChange}
      hasPushIcon={hasPushIcon}
      tooltip={tooltipContent}
      showTooltip={selectedPlatforms.size > 0}
      disabledPlatforms={disabledPlatforms}
      platformTooltips={
        config.pushSettings.filterPushTokens === 'imported'
          ? {
              webpush: 'Web push is disabled when imported tokens targeting is on',
            }
          : undefined
      }
    />
  ) : (
    <PlatformsIcons platforms={platforms} hasPushIcon={hasPushIcon} />
  )
}
