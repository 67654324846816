// @flow
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { type AttributeRecord } from 'com.batch.redux/_records'

type AttributeProps = {
  attribute: AttributeRecord,
  handler: () => void,
  locked: boolean,
  disabled?: boolean,
  active: boolean,
  ...
}

const Attribute = ({
  attribute,
  handler,
  locked,
  active,
  disabled,
}: AttributeProps): React.Node => {
  const nullHandler = () => {}

  return locked ? (
    <Tooltip tooltip="This feature is not availble on your plan. Contact us to find out more.">
      <span
        className="aTooltipr attr--locked"
        style={{
          pointerEvents: 'none',
          paddingLeft: 10,
          paddingRight: 10,
          height: 30,
          display: 'inline-block',
        }}
      >
        <Icon icon="lock" size={10} style={{ margin: '0 6px 0 0' }} />
        <span className="attr__text"> {attribute.label}</span>
      </span>
    </Tooltip>
  ) : (
    <Button
      height={30}
      style={{ margin: 6, display: 'inline-flex' }}
      onClick={disabled && !active ? nullHandler : handler}
      isActive={active}
      kind="secondary"
      intent={active ? 'action' : 'neutral'}
      addOn="prefix"
    >
      <Icon icon={attribute.icon} size={10} />
      <span className="attr__text">{attribute.label}</span>
    </Button>
  )
}

export default Attribute
