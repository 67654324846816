// @flow

import styled, { type StyledComponent } from 'styled-components'

import { textLight, text } from 'components/styled/tokens/colors'

export const AttributesSheetTitle: StyledComponent<*, *, *> = styled.div`
  display: inline-flex;
  gap: 5px;
  color: ${textLight};

  & div {
    display: inline-flex;
    gap: 5px;
    color: ${text};

    &:after {
      content: '—';
    }
  }
`

export const AttributesSheetContainer: StyledComponent<*, *, *> = styled.div`
  height: calc(100vh - 56px);

  span {
    white-space: normal;
    overflow: auto;
    word-break: break-all;
  }

  & pre {
    max-height: none;
    height: 100%;
    margin-right: -1px;
    margin-left: -1px;
  }
`
