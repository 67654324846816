// @flow

import { type ReduxAction } from 'com.batch.redux/_records'

import { type JourneySettingsRecord } from 'com.batch/orchestration-journey/models/journey.records'

export type InitSettingsAction = ReduxAction<'INIT_JOURNEY_SETTINGS', JourneySettingsRecord>

export const initSettings = (settings: JourneySettingsRecord): InitSettingsAction => {
  return {
    type: 'INIT_JOURNEY_SETTINGS',
    payload: settings,
  }
}
