// @flow

import * as Immutable from 'immutable'
import { type List } from 'immutable'
import { createSelector } from 'reselect'

import { legacyPromiseActionCreator } from './actionCreator'

import {
  type ClusterStateRecord,
  type ClusterDataRecord,
  type State,
  type ClusterRatioRecord,
  type ClusterRecord,
  type ReduxAction,
  type DispatchBoundFn,
  ClusterStateFactory,
  ClusterRatioFactory,
} from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { campaignTypeSelector } from 'com.batch.redux/campaign.selector'
import api from 'com.batch.redux/cluster.api'

// ========================================================
// ACTION NAME CONSTANTS
// ========================================================
export const TOGGLE_ALIVE_DISPLAY: string = 'TOGGLE_ALIVE_DISPLAY'
export const FETCH_USERBASE: string = 'FETCH_USERBASE'
export const FETCH_USERBASE_SUCCESS: string = 'FETCH_USERBASE_SUCCESS'
export const FETCH_USERBASE_FAILURE: string = 'FETCH_USERBASE_FAILURE'

const tokenRatio: ClusterRatioRecord = ClusterRatioFactory({
  mode: 'tokenRate',
  desc: '% algorithm = opt-ins / installs',
})
const optinRatio: ClusterRatioRecord = ClusterRatioFactory({
  mode: 'optinRate',
  desc: '% algorithm = opt-ins / tokens',
})

// ========================================================
// SELECTORS
// ========================================================
export const totalRaw = (state: State): ClusterDataRecord => state.cluster.all

const clusterSelector = (state: State) => state.cluster

export const clustersRaw: State => List<ClusterRecord> = createSelector(
  clusterSelector,
  clusters => {
    return new Immutable.List().push(
      clusters.N,
      clusters.Np,
      clusters.E,
      clusters.Er,
      clusters.D,
      clusters.Dp,
      clusters.Du,
      clusters.I
    )
  }
)

export const ratioMode: (state: State) => typeof tokenRatio = createSelector(
  currentAppSelector,
  totalRaw,
  (app, total) => {
    const optinRate = total.optinRate ? total.optinRate : 0
    if (app && ['ios', 'webpush'].indexOf(app.platform) !== -1 && total && optinRate >= 0.95) {
      return tokenRatio
    } else {
      return optinRatio
    }
  }
)

export const clustersAvailableForTargeting: State => List<ClusterRecord> = createSelector(
  clusterSelector,
  currentAppSelector,
  campaignTypeSelector,
  (clusters, app, kind) => {
    const hasImported = app.get('pushImported', false)
    let list = new Immutable.List()
      .push(clusters.N)
      .push(clusters.E)
      .push(clusters.D)
      .push(clusters.Du.set('code', 'U'))
    if (app.get('demo')) {
      list = list.push(clusters.Np).push(clusters.Er).push(clusters.Dp)
    }
    return hasImported && kind === 'push' ? list.push(clusters.I) : list
  }
)

// ========================================================
// ACTIONS
// ========================================================
export const fetchUserbase = (appId: number): DispatchBoundFn<any> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: appId,
      promise: api.fetchUserbase(appId),
      actionName: FETCH_USERBASE,
    })
}

export const toggleAliveDisplay = (): ReduxAction<'TOGGLE_ALIVE_DISPLAY', null> => {
  return { type: 'TOGGLE_ALIVE_DISPLAY', payload: null }
}
// ========================================================
// REDUCER
// ========================================================
function clusterReducer(
  state: ClusterStateRecord = ClusterStateFactory(),
  action: { type: string, payload: any, ... }
): ClusterStateRecord {
  const payload = action.payload

  switch (action.type) {
    case TOGGLE_ALIVE_DISPLAY:
      return state.set('showAlive', !state.showAlive)
    case FETCH_USERBASE:
      return state.set('status', 'LOADING').set('loading', true)
    case FETCH_USERBASE_FAILURE:
      return state.set('status', 'ERROR').set('loading', false)
    case FETCH_USERBASE_SUCCESS:
      return (
        state
          // $FlowFixMe c'est de la magie ça, le mergeDeep conserve bien les records...
          .mergeDeep(Immutable.fromJS(payload))
          .set('status', 'LOADED')
          .set('loading', false)
      )
    default:
      return state
  }
}

export default clusterReducer
