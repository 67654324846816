// @flow

import { type List } from 'immutable'
import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { SidebarApp, Container as AppContainer } from './sidebar-app'
import { type SidebarAppsProps } from './sidebar-apps'
import { SidebarAppsList, Container as AppsContainer } from './sidebar-apps-list'
import { SidebarMobileMenu } from './sidebar-mobile-menu'
import { SidebarSearch, Container as SearchContainer } from './sidebar-search'
import { MOBILE_PADDING, INNER_PADDING, PADDING } from './sidebar.styles'

import { type AppRecord } from 'com.batch.redux/_records'

export const SidebarAppsMobile = (props: SidebarAppsProps): React.Node => {
  const { currentApp, apps, company, openNew, project } = props
  const [search, setSearch] = React.useState<string>('')
  const filteredApps = filterApps(apps, search)

  const Label = (
    <React.Fragment>
      <SidebarApp
        app={currentApp}
        style={{
          flex: '1 1 auto',
          marginLeft: -INNER_PADDING,
          background: 'none',
          color: colors.text,
        }}
      />
      <Icon icon="select" />
    </React.Fragment>
  )

  return (
    <SidebarMobileMenu label={Label} border="right">
      <Container>
        <SidebarSearch cantClose onSearch={setSearch} search={search} />
        <SidebarAppsList apps={filteredApps} company={company} currentApp={currentApp} />
        <SidebarMobileMenuExtraItem onClick={openNew}>
          <Icon icon="add" style={{ marginRight: 4 }} />
          Add a new app or website
        </SidebarMobileMenuExtraItem>
        {project && (
          <SidebarMobileMenuExtraItem
            href={`/${company.id}/projects/${project.id}/apps/${project.appIds.first().toString()}`}
          >
            Switch to project view
          </SidebarMobileMenuExtraItem>
        )}
      </Container>
    </SidebarMobileMenu>
  )
}

const filterApps = (apps: List<AppRecord>, search: string) => {
  if (search && search !== '') return apps.filter(a => a.name.search(new RegExp(search, 'i')) >= 0)
  return apps
}

const SidebarMobileMenuExtraItem: StyledComponent<*, *, HTMLElement> = styled.a`
  display: block;
  padding: 19px ${MOBILE_PADDING}px 20px ${MOBILE_PADDING}px;
  font-weight: 500;
  color: ${colors.textLight};
  background: ${colors.fill};
  border-top: 1px solid ${colors.stroke};
  cursor: pointer;

  &:hover {
    color: ${colors.fillActionHover};
  }

  &:active {
    color: ${colors.fillActionActive};
  }
`

export const Container: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 80vh;

  ${SearchContainer} {
    position: absolute;
    z-index: 10;
    top: ${MOBILE_PADDING}px;
    left: ${MOBILE_PADDING}px;
    right: ${MOBILE_PADDING}px;
  }

  ${AppsContainer} {
    flex: 0 1 auto;
    padding: ${MOBILE_PADDING}px;
    padding-top: ${2 * MOBILE_PADDING + 36}px;

    ${AppContainer} {
      margin-left: -${INNER_PADDING}px;
      margin-right: -${INNER_PADDING}px;
    }
  }

  ${SidebarMobileMenuExtraItem} {
    flex: 0 0 auto;
  }

  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: ${PADDING}px;
    height: ${MOBILE_PADDING + 32}px;
    background: ${colors.fill};
    content: '';
  }
`

export default SidebarAppsMobile
