// @flow

import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

type Props = {
  onClick: () => void,
  isDisabled: boolean,
  ...
}

export const AddAnotherBtn = ({ isDisabled, onClick }: Props): React.Node => {
  return (
    <div>
      <Button kind="inline" addOn="prefix" onClick={onClick} disabled={isDisabled}>
        <Icon icon="add" />
        Add another configuration
      </Button>
    </div>
  )
}
