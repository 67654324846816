// @flow

import { arc as D3Arc } from 'd3-shape'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { arcWidth } from 'com.batch/shared/ui/component/charts/donut-chart/arc'
import {
  LoadingPath,
  PlaceholderPath,
} from 'com.batch/shared/ui/component/charts/donut-chart/donut-chart.styles'

type Props = {
  radius: number,
  d: any,
  ...
}

export const LoadingArc = ({ radius, d }: Props): React.Node => {
  const { isEmpty } = useTheme()
  const arc = React.useMemo(
    () => d => {
      return D3Arc()
        .innerRadius(radius - arcWidth)
        .outerRadius(radius)(d)
    },
    [radius]
  )
  if (isEmpty) {
    return <PlaceholderPath d={arc(d)} />
  }
  return <LoadingPath d={arc(d)} />
}
