// @flow

import { type List, type Set } from 'immutable'
import * as React from 'react'

import { useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper, Select } from 'components/form'

import { LegacyLang } from './legacy-lang'
import { LanguagePickerContainer } from './legacy-lang-picker.styles'

import { type LanguageRecord, LanguageFactory } from 'com.batch.redux/_records'

type LangPickerProps = {
  pickableLanguages: List<LanguageRecord>,
  pickedLanguages: List<LanguageRecord>,
  addTranslation: ({ lang: LanguageRecord, ... }) => any,
  removeTranslation: ({ lang: LanguageRecord, ... }) => any,
  setActiveTranslation: string => any,
  validLanguagesId: Set<string>,
  activeTranslation: ?LanguageRecord,
  defaultLanguageId: ?string,
  ...
}

const languageOptionFormatter = (lang: LanguageRecord) => lang.label
const languageOptionToString = (lang: ?LanguageRecord) => lang?.label ?? ''
/**
 * @deprecated MEP lang picker
 * @type {React$StatelessFunctionalComponent<LangPickerProps>}
 */
export const LegacyLangPicker: React.ComponentType<LangPickerProps> = React.memo(
  ({
    pickableLanguages,
    pickedLanguages,
    addTranslation,
    removeTranslation,
    validLanguagesId,
    setActiveTranslation,
    activeTranslation,
    defaultLanguageId,
  }: LangPickerProps) => {
    const selectShown = useToggle()

    // si l'app a un default language, on le supprime des options disponibles
    const removeDefaultLanguageOptions = React.useMemo(() => {
      return pickableLanguages.filter(
        lang => !defaultLanguageId || lang.value !== defaultLanguageId
      )
    }, [defaultLanguageId, pickableLanguages])

    const defaultLanguageLabel = React.useMemo(() => {
      return pickableLanguages.find(lang => lang.value === defaultLanguageId)?.label ?? ''
    }, [defaultLanguageId, pickableLanguages])

    const defaultLanguageWithLabel = React.useMemo(() => {
      return LanguageFactory({
        value: 'default',
        label: defaultLanguageLabel ? `Default (${defaultLanguageLabel})` : 'Default',
      })
    }, [defaultLanguageLabel])

    const onLanguageRemove = React.useCallback(
      (lang: LanguageRecord) => () => {
        removeTranslation({ lang })
      },
      [removeTranslation]
    )

    const onLanguageActivate = React.useCallback(
      (lang: LanguageRecord) => () => {
        setActiveTranslation(lang.value)
      },
      [setActiveTranslation]
    )

    const onLanguageChange = React.useCallback(
      (lang: ?LanguageRecord) => {
        if (lang) addTranslation({ lang })
        selectShown.toggle()
      },
      [addTranslation, selectShown]
    )

    return (
      <InputWrapper>
        <LanguagePickerContainer>
          {pickedLanguages.map(lang => (
            <LegacyLang
              key={lang.value}
              language={lang.value === 'default' ? defaultLanguageWithLabel : lang}
              defaultLanguageId={defaultLanguageId}
              isActive={lang.value === activeTranslation?.value}
              isValid={validLanguagesId.has(lang.value)}
              remove={onLanguageRemove(lang)}
              activate={onLanguageActivate(lang)}
            />
          ))}
          {selectShown.value ? (
            <Select
              options={removeDefaultLanguageOptions}
              placeholder="Languages"
              style={{ margin: '-2px 10px -1px 0', minHeight: 28 }}
              autoFocus
              isSearchable
              onChange={onLanguageChange}
              menuOffset={40}
              optionFormatter={languageOptionFormatter}
              optionToString={languageOptionToString}
              value={null}
            />
          ) : (
            <React.Fragment>
              <hr />
              <Button
                style={{ marginLeft: 8, marginBottom: 8, height: 28, width: 28 }}
                onClick={selectShown.toggle}
              >
                <Icon icon="add" />
              </Button>
            </React.Fragment>
          )}
        </LanguagePickerContainer>
      </InputWrapper>
    )
  }
)

LegacyLangPicker.displayName = 'LangPicker'
