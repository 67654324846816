// @flow
import * as Sentry from '@sentry/browser'
import * as React from 'react'

import { Button } from 'components/common/button'
import NoData from 'components/common/nodata'

type ErrorCatcherProps = {
  children: React.Node,
  ...
}
type ErrorCatcherState = {
  hasError: boolean,
  error: any,
  ...
}
class ErrorCatcher extends React.Component<ErrorCatcherProps, ErrorCatcherState> {
  constructor(props: ErrorCatcherProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error,
    })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render(): React.Node {
    // $FlowFixMe don't worry
    if (this.state.hasError && !module.hot) {
      return (
        <div>
          <NoData title="Sorry but something went wrong." error icon="error">
            <p>
              This is not supposed to happen. Make sure you are using an up-to-date webbrowser, and
              if that's the case, please let us know exactly how you managed to trigger this error
              so we can fix it quickly.
            </p>
            <p style={{ marginTop: 10, display: 'flex', gap: 10, justifyContent: 'center' }}>
              <Button onClick={() => window.location.reload()} kind="primary" intent="action">
                Reload this page
              </Button>
              <Button
                onClick={() =>
                  typeof window.Intercom === 'function'
                    ? window.Intercom('showNewMessage')
                    : (window.location = 'mailto:support@batch.com')
                }
                kind="primary"
                intent="action"
              >
                Contact us
              </Button>
            </p>
          </NoData>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorCatcher
