// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'

import { type StateToggler } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { getEmailContentLoadingStateSelector } from 'com.batch/email/store/email-automation.selector'

import { STATUS } from 'constants/common'

type Props = {
  hasMandatoryInfo: boolean,
  togglePopinSendTest: StateToggler,
  messageId: string,
  buttonProps?: any,
  /**
   * Permet d'ajouter des actions au click en plus de l'ouverture de la modale de test.
   */
  onClickAddons?: (() => Promise<void>) | (() => void),
}

export const SendTestEmailButton = ({
  hasMandatoryInfo,
  togglePopinSendTest,
  buttonProps,
  onClickAddons,
  messageId,
}: Props): React.Node => {
  const getLoadingState = useSelector(getEmailContentLoadingStateSelector)
  const loadingState = React.useMemo(() => getLoadingState(messageId), [getLoadingState, messageId])

  const isLoading = React.useMemo(() => loadingState === STATUS.LOADING, [loadingState])

  const onClick = React.useCallback(() => {
    // Est-ce qu'on a des actions à faire avant d'ouvrir la modale de test ?
    if (onClickAddons) {
      const onClickAddonsResult = onClickAddons()
      // Vérification si onClickAddons est une Promise pour attendre avant d'ouvrir la modale
      if (onClickAddonsResult instanceof Promise) {
        // $FlowFixMe ne comprend pas que onClickAddonsResult est une Promise
        onClickAddonsResult.then(togglePopinSendTest.open)
      } else {
        togglePopinSendTest.open()
      }
    } else {
      // Pas d'action à effectuer avant
      togglePopinSendTest.open()
    }
  }, [onClickAddons, togglePopinSendTest])

  return (
    <Tooltip
      placement="bottom"
      tooltip={!hasMandatoryInfo && 'Please fill in the missing fields'}
      isTooltipEmpty={hasMandatoryInfo}
    >
      <span>
        <Button
          {...buttonProps}
          disabled={!hasMandatoryInfo || buttonProps?.disabled}
          addOn="prefix"
          onClick={onClick}
          isLoading={isLoading}
          data-testid="button_send_test_email"
        >
          <Icon icon="send" /> Send test
        </Button>
      </span>
    </Tooltip>
  )
}
