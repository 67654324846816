// @flow
import * as React from 'react'

import { formatPushToken, formatIdentifier } from 'com.batch.common/utils'

import * as colors from '../styled/tokens/colors'
import { type TestDeviceRecord } from 'com.batch.redux/_records'

type DeviceProps = {
  device: TestDeviceRecord,
  platform: Platforms,
  ...
}

export const Device = ({ device, platform }: DeviceProps): React.Node => (
  <div className="pttoken">
    <div className="pttoken__name" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div
        style={{
          overflow: 'hidden',
          width: 130,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: colors.text,
        }}
      >
        {device.name}
      </div>
      <span
        className="pull-right label label-default"
        style={{ display: 'flex', alignItems: 'center', background: colors.fillNeutral }}
      >
        {formatIdentifier(platform, device.kind)}
      </span>
    </div>

    <div className="pttoken__footer">
      <span className="pttoken__footer__code fs-exclude" style={{ color: colors.textLight }}>
        {formatPushToken(device.value)}
      </span>
      <span className="pttoken__footer__date" style={{ color: colors.textLight }}>
        {!!device.created && device.created.format('DD/MM/YYYY HH:mm')}
      </span>
    </div>
  </div>
)
