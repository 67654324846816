/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { Button } from 'components/common/button'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import { InputWrapper, Input, Select, Radio, Editor } from 'components/form'

import { validateJSON } from 'com.batch.common/utils'

import {
  SdkActionOptionFormater,
  ActionContainer,
  ActionConfig,
  AdditionalContainer,
  SdkSecondaryOptionFormater,
} from './cta-builder.helper'
import { CtaModal } from './cta-builder.modal'

import { sdkNativeActions, type SdkActionRecord } from 'com.batch.redux/content.records'
import {
  mlvlCheckerSelector,
  getMlvlForNativeAction,
  type SdkFeatureState,
} from 'com.batch.redux/sdk.selector'

export type BatchNativeSdkActionName = 'batch.deeplink' | 'batch.dismiss'

type CtaBuilderProps = {
  updateLabel?: string => any,
  label?: string,
  id?: string,
  placeholder: string,
  platform: Platforms,
  action: SdkActionRecord,
  hasTemplate?: boolean,
  onFocus?: () => void,
  updateAction: (action: SdkActionRecord) => any,
  forcedFocus: boolean,
}
type SecondaryChoice = {
  label: string,
  modal: 'EVENT' | 'ADD_TAG' | 'REMOVE_TAG',
  description: string,
  dropdownSprite: [number, number, number, number],
  icon: availableIcons,
  sdkState: SdkFeatureState,
}
const SECONDARY_CHOICES: List<SecondaryChoice> = new Immutable.List().push(
  {
    icon: 'action',
    dropdownSprite: [-64, -416, -20, -416],
    modal: 'EVENT',
    label: 'Track event',
    description: 'Track an event each time users interact with the message',
    sdkState: {
      state: 'ok',
      mlvl: 0,
      public: '',
      installs: 0,
      ratio: 0,
    },
  },
  {
    icon: 'label',
    dropdownSprite: [-64, -460, -20, -460],
    modal: 'ADD_TAG',
    label: 'Add tag',
    description: 'Add any information to users interacting with the message',
    sdkState: {
      state: 'ok',
      mlvl: 0,
      public: '',
      installs: 0,
      ratio: 0,
    },
  },
  {
    icon: 'label',
    dropdownSprite: [-64, -504, -20, -504],
    modal: 'REMOVE_TAG',
    label: 'Remove tag',
    description: 'Remove any information to users interacting with the message',
    sdkState: {
      state: 'ok',
      mlvl: 0,
      public: '',
      installs: 0,
      ratio: 0,
    },
  }
)

const FILTER_SMALL_THEME = { kind: 'filter', size: 'small' }
export const CtaBuilder = ({
  action,
  platform,
  label,
  id,
  placeholder,
  hasTemplate,
  updateAction,
  updateLabel,
  onFocus,
  forcedFocus,
}: CtaBuilderProps): React.Node => {
  // ------ redux
  const mlvlChecker = useSelector(mlvlCheckerSelector)

  // ------ local state
  const [bufferArgs, setBufferArgs] = React.useState(action.customActionArgs)
  const [editedIndex, setEditedIndex] = React.useState<null | number>(null)
  const [validJson, setValidJson] = React.useState(true)
  const [modal, setModal] = React.useState<false | 'ADD_TAG' | 'REMOVE_TAG' | 'EVENT'>(false)

  const availableNativeActions = React.useMemo(
    () =>
      sdkNativeActions
        .filter(act => act.platforms.has(platform))
        .map(act => {
          const mlvl = getMlvlForNativeAction(act)
          return act.set('sdkState', mlvlChecker(mlvl))
        }),
    [mlvlChecker, platform]
  )

  const secondaryChoices: List<SecondaryChoice> = React.useMemo(
    () =>
      SECONDARY_CHOICES.map(act => {
        return { ...act, sdkState: mlvlChecker(8) }
      }),
    [mlvlChecker]
  )

  // ------ callbacks
  const validateJsonAndUpdate = React.useCallback(
    (args: string) => {
      setBufferArgs(args)
      const valid = validateJSON(args)
      setValidJson(valid)
      updateAction(action.set('customActionArgs', valid ? args.trim() : ''))
    },
    [action, updateAction]
  )

  return (
    <InputWrapper
      label={placeholder}
      style={{ margin: '0 0 32px 0' }}
      labelStyle={{
        marginBottom: -6,
      }}
    >
      {modal && (
        <CtaModal
          close={() => setModal(false)}
          mode={modal}
          action={action}
          editedIndex={editedIndex}
          update={updateAction}
        />
      )}
      {updateLabel && (
        <Editor
          onChange={updateLabel}
          value={label ?? ''}
          id={id ?? ''}
          style={{ ...(!hasTemplate ? { marginBottom: 8 } : {}) }}
          onFocus={onFocus}
          forcedFocus={forcedFocus}
          placeholder={hasTemplate ? placeholder : 'Button text'}
        />
      )}
      <ActionContainer hasConfiguration>
        <Select
          optionFormatter={SdkActionOptionFormater}
          isSearchable
          optionMenuStyle={{ height: 66, paddingTop: 4 }}
          optionMenuHeight={66}
          menuOffset={140}
          optionMenuShownCount={5}
          optionToString={opt => opt?.label ?? ''}
          options={availableNativeActions}
          onChange={act => {
            if (act) updateAction(action.set('action', act))
          }}
          value={availableNativeActions.find(act => act.value === action.action.value)}
        />
        <ActionConfig>
          {action && action.action.value === 'batch.clipboard' && (
            <Editor
              onChange={evt => updateAction(action.set('copyText', evt))}
              value={action.copyText}
              id={id ?? ''}
              style={{ marginBottom: 10 }}
              onFocus={onFocus}
              forcedFocus={forcedFocus}
              placeholder="PROMO 5..."
            />
          )}
          {action && action.action.value === 'custom' && (
            <React.Fragment>
              <Input
                type="text"
                style={{ marginBottom: 10 }}
                value={action.customActionName}
                placeholder="Action name"
                onChange={evt => updateAction(action.set('customActionName', evt.target.value))}
              />
              <Input
                rows={3}
                type="text"
                invalid={!validJson}
                value={bufferArgs}
                placeholder="Custom JSON..."
                onChange={evt => validateJsonAndUpdate(evt.target.value)}
              />
            </React.Fragment>
          )}
          {action &&
            (action.action.value === 'batch.deeplink' ||
              action.action.value === 'batch.clipboard') && (
              <React.Fragment>
                <Editor
                  onChange={evt => updateAction(action.set('deeplinkUrl', evt.trim()))}
                  value={action.deeplinkUrl}
                  id={id ?? ''}
                  onFocus={onFocus}
                  forcedFocus={forcedFocus}
                  placeholder={
                    action.action.value === 'batch.deeplink'
                      ? 'Deeplink URL'
                      : 'Add an optional deeplink...'
                  }
                />
                {(action.action.value === 'batch.deeplink' || action.deeplinkUrl) && (
                  <div style={{ marginTop: 10 }} role="radiogroup" aria-label="Deeplink action">
                    <Radio
                      checked={action.deeplinkInternal}
                      label="Open in app"
                      onChange={() => updateAction(action.set('deeplinkInternal', true))}
                    />
                    <Radio
                      checked={!action.deeplinkInternal}
                      style={{ marginLeft: 40 }}
                      label="Open in browser"
                      onChange={() => updateAction(action.set('deeplinkInternal', false))}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
        </ActionConfig>
        {action.additional.map((additional, key) => {
          return (
            <AdditionalContainer key={key}>
              <Icon icon={additional.mode === 'EVENT' ? 'action' : 'label'} thickness={1.3} />
              <span>
                {additional.mode === 'EVENT'
                  ? 'Track event'
                  : additional.mode === 'ADD_TAG'
                    ? 'Add tag'
                    : 'Remove tag'}
              </span>
              <span>
                <Button
                  kind="inline"
                  intent="action"
                  style={{ height: 28 }}
                  onClick={() => {
                    setEditedIndex(key)
                    setModal(additional.mode)
                  }}
                >
                  Edit
                </Button>
              </span>
              <Button
                style={{ height: 28, width: 28 }}
                intent="danger"
                kind="discreet"
                onClick={() =>
                  updateAction(action.set('additional', action.additional.splice(key, 1)))
                }
              >
                <Icon icon="delete" />
              </Button>
            </AdditionalContainer>
          )
        })}
        <ThemeProvider theme={FILTER_SMALL_THEME}>
          <div style={{ maxWidth: 175, marginTop: 8 }}>
            <Select
              style={{ minWidth: 190 }}
              menuOffset={280}
              optionMenuShownCount={6}
              value={null}
              optionToString={opt => opt?.label ?? ''}
              optionMenuStyle={{ height: 56, padding: 0 }}
              optionMenuHeight={56}
              placeholder="Add secondary action"
              optionFormatter={SdkSecondaryOptionFormater}
              options={secondaryChoices}
              onChange={opt => {
                if (opt) {
                  setEditedIndex(null)
                  setModal(opt.modal)
                }
              }}
            />
          </div>
        </ThemeProvider>
      </ActionContainer>
    </InputWrapper>
  )
}
