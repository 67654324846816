// @flow

import styled, { type StyledComponent } from 'styled-components'

export const HightLightContainer: StyledComponent<*, { ... }, *> = styled.pre`
  max-height: 300px;
  border: none;
  overflow: auto;
  background: rgb(45, 59, 70);
  padding: 6px 12px;
  font-size: 12.2px;
  code {
    color: #fff;
    font-size: inherit;
    padding: 0;
    background: inherit;
    background-color: inherit;
  }
  &[class*='language-'] {
    color: #657b83;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: inherit;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.4em;
    tab-size: 4;
    hyphens: none;
  }

  &[class*='language-'] {
    background: #073642;
  }

  /* Code blocks */
  &[class*='language-'] {
    padding: 1em;
    overflow: auto;
    border-radius: 0.3em;
  }

  :not(pre) > code[class*='language-'],
  &[class*='language-'] {
    background-color: rgb(45, 59, 70);
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    padding: 0.1em;
    border-radius: 0.3em;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #93a1a1;
  }

  .token.punctuation {
    color: white;
  }

  .token.namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: gold;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.url,
  .token.inserted {
    color: #02b2fc;
  }

  .token.entity {
    color: #657b83; /* base00 */
    background: #eee8d5; /* base2 */
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #a5ff90;
  }

  .token.function,
  .token.class-name {
    color: white;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #cb4b16;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
`
