// @flow

import Immutable, { type List, type Set } from 'immutable'
import { get as _get } from 'lodash-es'
import request from 'superagent-interface-promise'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router/router'

import { type TestDeviceRecord, type AppRecord, TestDeviceFactory } from 'com.batch.redux/_records'

export const normalizeDevice: (raw: {
  id: number,
  app: number,
  distribution: boolean,
  name: string,
  created: string,
  updated: string,
  value: string,
  kind: $PropertyType<TestDeviceRecord, 'kind'>,
  ...
}) => TestDeviceRecord = raw => {
  return TestDeviceFactory({
    id: raw.id,
    appId: raw.app,
    name: raw.name,
    value: raw.value,
    kind: raw.kind,
    distribution: raw.distribution,
    created: dayjs(raw.created, 'YYYY/MM/DD HH:mm'),
    updated: dayjs(raw.updated, 'YYYY/MM/DD HH:mm'),
  })
}

export const saveDevice = ({
  device,
  app,
}: {
  device: TestDeviceRecord,
  app: AppRecord,
  ...
}): Promise<TestDeviceRecord> =>
  request.post(`/api/app/${app.id}/test-device`, device).then(
    response => {
      return { device: normalizeDevice(response.body) }
    },
    error => {
      throw {
        error: error.body,
        device,
      }
    }
  )

export const deleteDevice = ({
  device,
  app,
}: {
  device: TestDeviceRecord,
  app: AppRecord,
  ...
}): Promise<TestDeviceRecord> =>
  request.delete(`/api/app/${app.id}/test-device/${device.id}`).then(
    () => {
      return { device }
    },
    error => {
      throw {
        error: error.body,
        device,
      }
    }
  )

const formatTestFeedback = (
  feedbacks: Array<{
    installation_id: ?string,
    apikey?: string,
    error?: string,
    type?: string,
    ...
  }>
) => {
  const installs = feedbacks.reduce((prev, current) => prev + (current.installation_id ? 1 : 0), 0)
  const sends = feedbacks.reduce((prev, current) => prev + (current.error ? 0 : 1), 0)
  const errors = feedbacks.reduce((prev, current) => prev + (current.error ? 1 : 0), 0)
  let message = ''
  let error = sends > 0 ? '' : null
  let logResults: Array<{ install: string, result: string, ... }> = []
  feedbacks.forEach(f => {
    if (typeof f.installation_id === 'undefined' || !f.installation_id) {
      logResults.push({ install: '-', result: 'No install found' })
    } else {
      if (typeof f.error === 'undefined' || !f.error) {
        logResults.push({ install: f.installation_id, result: 'SUCCESS' })
      } else {
        logResults.push({ install: f.installation_id, result: f.error })
      }
    }
  })
  // $FlowFixMe define "miser le mauvais 🐴"
  console.table(logResults)
  if (sends > 0) {
    const countInfo =
      sends > 1 || (sends !== installs && installs > 0) ? ` ${sends} of ${installs}` : ''
    message = `Test push sent to ${countInfo} installation${sends > 1 ? 's' : ''}`
  } else {
    message = 'Unable to send message'
    error = feedbacks.reduce((prev, current) => {
      if (current.error) {
        return `${prev}${prev ? ' / ' : ''}${current.error}`
      } else {
        return prev
      }
    }, '')
  }
  return { installs, sends, errors, message, error }
}
export const test = ({
  appId,
  device,
  message,
  media,
  advanced,
  landing,
}: {
  appId: number,
  device: TestDeviceRecord,
  message: {
    body: string,
    title: ?string,
    deeplink?: string,
    media?: {
      picture?: ?string,
      video?: ?string,
      audio?: ?string,
      ...
    },
    ...
  },
  media: {
    icon?: ?string,
    picture?: ?string,
    video?: ?string,
    audio?: ?string,
    ...
  },
  advanced: any,
  landing: any,
  ...
}): Promise<any> => {
  let postData: {
    message: string,
    title: ?string,
    payload: ?string,
    landing: ?any,
    icon?: ?string,
    image?: ?string,
    video?: ?string,
    audio?: ?string,
    pushToken?: ?string,
    prod?: boolean,
    wpTemplate?: 'GENERIC',
    recipientKind: string,
    recipientValue: string | null,
    deeplink?: string,
    ...
  } = {
    message: message.body,
    title: message.title,
    payload: advanced.customPayload,
    landing: landing ? landing : null,
    recipientKind: device.kind,
    recipientValue: device.value !== 'INTEGRATION_TOKEN' ? device.value.trim() : null,
    icon: media.icon,
    image: media.picture,
    video: media.video,
    audio: media.audio,
  }

  // SORRY C'EST DEGEUX
  if (message.media) {
    if (message.media.picture) {
      postData.image = message.media.picture
    } else if (message.media.video) {
      postData.video = message.media.video
    } else if (message.media.audio) {
      postData.audio = message.media.audio
    }
  }

  if (message.deeplink) {
    postData.deeplink = message.deeplink
  } else if (advanced.deeplink) {
    postData.deeplink = advanced.deeplink
  }

  if (device.kind === 'token') {
    postData.prod = device.distribution
    postData.wpTemplate = 'GENERIC'
  }

  return request.post(`/api/app/${appId}/push-campaign/test`, postData).then(
    response => {
      return { ...formatTestFeedback(response.body), device }
    },
    error => {
      if (Array.isArray(error.body)) {
        throw {
          ...formatTestFeedback(error.body),
          device,
        }
      }
      throw {
        device,
        message: 'Unable to send message',
        error: _get(error, 'body.errors[0].message', 'Unknown error'),
      }
    }
  )
}

export const getTestDevicesForApps = (apps: Set<AppRecord>): Promise<List<TestDeviceRecord>> => {
  return request
    .get(
      generateUrl('api_testdevice_list', {
        appIds: apps
          .map(app => app.id)
          .toArray()
          .join(','),
      })
    )
    .then(({ body }) => new Immutable.List().push(...body.map(normalizeDevice)))
}
