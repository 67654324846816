// @flow

import styled, { type StyledComponent } from 'styled-components'

export const ActionPickerContainer: StyledComponent<void, void, HTMLDivElement> = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`

export const ActionFieldLabel: StyledComponent<void, void, HTMLSpanElement> = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
`
