// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

//  ====================== COMPONENTS
import { ButtonLink } from 'components/common/button'
import Highlight from 'components/common/highlight'

import { type SdkRecord, type SafariWebsitesRecord } from 'com.batch.redux/_records'
import { currentSafariSetupValidSelector } from 'com.batch.redux/app'

import type { List } from 'immutable' // eslint-disable-line

type SetupWebProps = {
  apiKey: string,
  webpushAuth: string,
  bundleId: string,
  defaultLanguageId: string,
  vapidPublicKey: string,
  subdomain: string,
  sdk: SdkRecord,
  configSafari: List<SafariWebsitesRecord>,
  defaultIcon: string,
  smallIcon: string,
  ...
}
const SetupWeb = ({
  apiKey,
  webpushAuth,
  sdk,
  vapidPublicKey,
  subdomain,
  configSafari,
  defaultIcon,
  smallIcon,
}: SetupWebProps): React.Node => {
  const safariSetupValid = useSelector(currentSafariSetupValidSelector)

  const websitePushId = React.useCallback(
    (certificateName: string) => certificateName.split(': ')[1],
    []
  )

  const displayObjectSafari = React.useCallback(() => {
    return configSafari
      .toJSON()
      .map((safari, index) => {
        const firstElement = index === 0 ? '' : '          '
        const lastElement = configSafari.toJSON().length - 1 !== index ? '\n' : ''
        return `${firstElement}'${safari.domain}': '${websitePushId(
          safari.certificateName
        )}', ${lastElement}`
      })
      .join('')
  }, [configSafari, websitePushId])

  return (
    <div>
      <p>
        First,{' '}
        <ButtonLink
          style={{ height: 28 }}
          kind="primary"
          intent="action"
          href={`https://download.batch.com/sdk/web/BatchSDK-web-${sdk.version || ''}.zip`}
        >
          download the service worker
        </ButtonLink>{' '}
        and extract it in the root of your website.
      </p>

      <p>
        You will need to include the following code at the bottom of the body of the pages where you
        want your users to subscribe:
      </p>
      <Highlight language="javascript">{`<script type="text/javascript">
      /* Load remote Batch SDK JavaScript code */
(function(b,a,t,c,h,e,r){h='batchSDK';b[h]=b[h]||function() {
    (b[h].q=b[h].q||[]).push(arguments)};e=a.createElement(t),r=a.getElementsByTagName(t)[0];
    e.async=1;e.src=c;r.parentNode.insertBefore(e,r);})(window,document,'script','https://via.batch.com/v4/bootstrap.min.js');

/* Initiate Batch SDK opt-in UI configuration (native prompt) */
var batchSDKUIConfig = {
    native: {}
};

/* Use a specific configuration for Firefox and Safari browsers (custom prompt) */
if (navigator.userAgent.indexOf("Firefox") !== -1 || (navigator.userAgent.indexOf("Safari") !== -1 &&
navigator.userAgent.indexOf("Chrome") === -1)) {
    batchSDKUIConfig = {
        alert: {
          ${defaultIcon ? '' : "icon: 'https://secure.url.to.your.icon.jpg'"}
        }
    }
}

/* Finalize the Batch SDK setup */
batchSDK('setup', {
    apiKey: '${apiKey}',
    subdomain: '${subdomain}',
    authKey: '${webpushAuth}',
    dev: true, // remove this for prod
    vapidPublicKey: '${vapidPublicKey}',
    ui: batchSDKUIConfig,
    ${defaultIcon ? '' : "// defaultIcon: 'https://path.to/my/logo-192/png', // for Chrome desktop"}
    ${smallIcon ? '' : "// smallIcon: 'https://path.to/my/icon-96.png', // for Chrome Android"}
    ${
      safariSetupValid
        ? `safari: {
          ${displayObjectSafari()}
    }`
        : ''
    }
});
</script>
      `}</Highlight>
    </div>
  )
}

export default SetupWeb
