// @flow
// ====================== LIBS
import { type List, type Map } from 'immutable'
import * as React from 'react'

import { TableBody } from 'components/common/table'

import { Row } from './row'

import { type ProfileAudienceRecord } from 'com.batch/audience/model/audience-profile.records'
import {
  type EstimateProfileRecord,
  EstimateProfileFactory,
} from 'com.batch/orchestration/models/profile-estimate.records'
import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'

type RowProps = {
  audiences: List<ProfileAudienceRecord>,
  remove: (aud: ProfileAudienceRecord) => any,
  search: string,
  templateSize: number,
  emptyTemplate: React.Node,
  estimate: Map<string, EstimateProfileRecord>,
}

export const Body = ({
  audiences,
  remove,
  search,
  templateSize,
  emptyTemplate,
  estimate,
}: RowProps): React.Node => {
  const [editLine, setEditLine] = React.useState<number | null>(null)
  const onLineChange = React.useCallback((line: number | null) => () => setEditLine(line), [])

  return (
    <TableBody emptyTemplate={emptyTemplate} templateSize={templateSize}>
      <NoResultWrapper
        isEmpty={audiences.size === 0 && !!search}
        entityName="audience"
        height={400}
      >
        {audiences.map((audience: ProfileAudienceRecord, index: number) => {
          const line = index + 1
          const isEditing = line === editLine

          return (
            <Row
              key={audience.name}
              closeEditing={onLineChange(null)}
              setEditing={onLineChange(line)}
              isEditing={isEditing}
              aud={audience}
              estimate={estimate.get(audience.name, EstimateProfileFactory())}
              remove={remove}
              index={index}
            />
          )
        })}
      </NoResultWrapper>
    </TableBody>
  )
}
