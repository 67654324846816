// @flow
import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { type StyledPreviewTheme } from 'components/campaign/preview/preview.theme'
import { IconContainer } from 'components/common/svg-icon'

type NoPropsButTheme = PropsWithTheme<{ ... }, StyledPreviewTheme>
const Editable: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div``

const editablePart = css`
  ${(props: any) =>
    props.placeholder &&
    props.editingField !== props.field &&
    css`
      ::before {
        color: #9197a3;
        font-weight: 600;
        display: inline;
        content: attr(placeholder);
      }
    `}
  ${(props: any) =>
    props.editingField === props.field &&
    css`
      -webkit-line-clamp: unset;
    `}
`

export const DebugDevice: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  background-size: 1000px 1000px;
  background-position: -260px -60px;
  position: relative;
`
export const PreviewDevicePadding: StyledComponent<{ ... }, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    opacity: 1;
    width: 400px;
    height: ${(props: NoPropsButTheme) => props.theme.height};
    padding: ${(props: NoPropsButTheme) => props.theme.containerPadding};
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    ${(props: NoPropsButTheme) =>
      props.theme.platform === 'android' &&
      css`
        font-family: Roboto, Arial, sans-serif;
      `};
  `
export const PreviewDevice: StyledComponent<{ ... }, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: ${(props: NoPropsButTheme) => (props.theme.platform === 'webpush' ? 0 : 2)};
    border-radius: ${(props: NoPropsButTheme) => props.theme.borderRadius};
    box-shadow: ${(props: NoPropsButTheme) =>
      props.theme.platform === 'webpush'
        ? 'none'
        : '0px 1px 6px rgba(0, 0, 0, 0.16), 0px 2px 28px rgba(0, 0, 0, 0.08)'};
    background-size: cover;
    background-position: top left;
    background-image: ${(props: NoPropsButTheme) =>
      props.theme.platform === 'webpush'
        ? 'none'
        : `url(/medias/img/notif-preview/legacy/mockups/${props.theme.platform}_light.png)`};
    width: 400px;
    height: ${(props: NoPropsButTheme) => props.theme.height};

    /* Webpush only */
    &:before,
    &:after {
      display: ${(props: NoPropsButTheme) =>
        props.theme.platform === 'webpush' ? 'block' : 'none'};
      position: absolute;
      top: 0;
      border-radius: 0 10px 0 0;
      content: '';
    }
    &:after {
      width: 100%;
      height: 100%;
      left: 0;
      background-image: url(/medias/img/notif-preview/legacy/mockups/webpush.png);
      background-size: cover;
      background-position: 100% 0;
      box-shadow:
        inset 0 -2px 4px rgba(0, 0, 0, 0.01),
        inset 0 4px 12px rgba(0, 0, 0, 0.02);
      border-top: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      overflow: hidden;
      mask-image: url(/medias/img/notif-preview/legacy/mockups/webpush_mask.png);
      mask-size: 100% 100%;
    }
    &:before {
      width: 60%;
      height: 60%;
      right: 0;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04);
    }
  `
export const PreviewContent: StyledComponent<{ ... }, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    flex-grow: 1;
    border-radius: ${(props: NoPropsButTheme) => props.theme.containerRadius};
    position: relative;
    .landingPreview {
      transform-origin: 0 1px;
      width: ${(props: NoPropsButTheme) => props.theme.landingWidth};
      height: ${(props: NoPropsButTheme) => props.theme.landingHeight};
      transform: scale(
        ${(props: NoPropsButTheme) => props.theme.landingScale},
        ${(props: NoPropsButTheme) => props.theme.landingScale}
      );
    }
    background-color: ${(props: NoPropsButTheme) =>
      props.theme.platform === 'webpush' ? 'transparent' : 'HSL(0, 0%, 18%)'};
    color: #fff;
    text-align: center;
  `

export const LockScreen: StyledComponent<{ ... }, StyledPreviewTheme, HTMLDivElement> = styled.div`
  ppointer-events: none;
  padding: ${(props: NoPropsButTheme) => props.theme.ls.padding};
  font-weight: ${(props: NoPropsButTheme) => props.theme.ls.fontWeight};
  ${(props: NoPropsButTheme) =>
    props.theme.platform === 'windows' &&
    css`
      text-align: left;
      position: absolute;
      bottom: 0;
      left: 0;
    `};
`
export const LockScreenDate: StyledComponent<{ ... }, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    font-size: ${(props: NoPropsButTheme) => props.theme.ls.date.fontSize};
    font-weight: ${(props: NoPropsButTheme) => props.theme.ls.date.fontWeight};
    line-height: ${(props: NoPropsButTheme) => props.theme.ls.date.lineHeight};
  `
export const LockScreenTime: StyledComponent<{ ... }, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    font-size: ${(props: NoPropsButTheme) => props.theme.ls.time.fontSize};
    letter-spacing: ${(props: NoPropsButTheme) => props.theme.ls.time.letterSpacing};
    line-height: ${(props: NoPropsButTheme) => props.theme.ls.time.lineHeight};
  `
type NotifCloseProps = { fixed?: boolean, ... }
type ThemedNotifCloseProps = PropsWithTheme<NotifCloseProps, StyledPreviewTheme>
export const NotifClose: StyledComponent<NotifCloseProps, *, HTMLDivElement> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props: ThemedNotifCloseProps) =>
    props.fixed
      ? css`
          position: fixed;
          top: 20px;
          right: 20px;
          z-index: 1102;
          cursor: pointer;
        `
      : css`
          position: absolute;
          top: 55px;
          right: 20px;
        `}
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  color: #000000;
  text-align: center;

  ${IconContainer} {
    line-height: 1em;
  }
`
type NotifAreaProps = {
  os?: string,
  browser?: string,
  style?: { ... },
  isProject?: boolean,
  hasMedia?: boolean,
  ...
}
type ThemeNotifAreaProps = PropsWithTheme<NotifAreaProps, StyledPreviewTheme>
export const NotifArea: StyledComponent<NotifAreaProps, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    text-align: left;
    overflow: hidden;
    position: relative;
    color: ${(props: ThemeNotifAreaProps) => props.theme.notif.color};

    ${(props: ThemeNotifAreaProps) =>
      !props.isProject &&
      css`
        display: flex;
        flex-direction: ${(p: any) => (p.theme.expanded && p.hasMedia ? 'column' : 'row')};
        border-radius: ${(p: any) =>
          p.theme.platform === 'ios' && !p.theme.expanded ? '25px' : '10px'};
      `}

    ${(props: ThemeNotifAreaProps) =>
      props.theme.platform === 'webpush'
        ? props.os === 'mac'
          ? props.browser === 'safari'
            ? css`
                padding: 12px;
                background: #f2f2f2;
                border-radius: 10px;
                border: 1px solid #e9e9e9;
              `
            : css`
                border-radius: 14px;
                padding: 10px;
                background-color: HSL(0, 0%, 94%);
                box-shadow: rgb(203, 203, 203) 0px 0px 10px 0px;

                ${NotifContentText} {
                  padding: 2px;
                }

                ${NotifAppName} {
                  // padding: 0 3px;
                }
              `
          : props.browser === 'chrome'
            ? css`
                background-color: HSL(36, 4%, 21%);
                box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.16);
                padding: 0px;
                display: flex;
                flex-direction: column;
                ${NotifContent} {
                  padding: 15px 14px 13px 14px;
                  align-items: flex-start;
                  order: 2;
                }
                ${NotifContentText} {
                  margin-top: -5px;
                  padding: 2px;
                }
                ${NotifAppName} {
                  color: HSL(26, 1%, 61%);
                  font-size: 11px;
                  // padding: 0 3px;

                  &:before {
                    content: 'Google Chrome • ';
                  }
                }
                ${NotifContentTextTitle} {
                  color: #fff;
                  font-weight: 600;
                  font-size: 13px;
                  white-space: pre-line;

                  &::before {
                    color: rgba(255, 255, 255, 0.94);
                  }
                }
                ${NotifContentTextMessage} {
                  font-weight: 400;
                  font-size: 13px;
                  white-space: pre-line;

                  &::before {
                    font-weight: 400;
                  }
                }
                color: HSL(31, 1%, 72%);
              `
            : css`
                border-radius: 0px;
                padding: 10px;
                box-shadow: rgb(203, 203, 203) 0px 0px 10px 0px;
                background-color: HSL(0, 0%, 100%);

                ${NotifAppName} {
                  // padding: 0 3px;
                }

                ${NotifContentText} {
                  padding: 2px;
                }
              `
        : css`
            border-radius: ${props.theme.platform === 'ios' &&
            !props.isProject &&
            !props.theme.expanded
              ? '25px'
              : props.theme.notif.borderRadius};
            background-color: ${props.theme.expanded
              ? props.theme.notif.expanded.backgroundColor
              : props.theme.notif.backgroundColor};
          `}

  
  margin: ${(props: ThemeNotifAreaProps) =>
      props.theme.expanded ? props.theme.notif.expanded.margin : props.theme.notif.margin};
  `
type NotifHeadProps = { isProject?: boolean, hasIcon?: boolean, ... }
type ThemeNotifHeadProps = PropsWithTheme<NotifHeadProps, StyledPreviewTheme>
export const NotifHead: StyledComponent<NotifHeadProps, *, HTMLDivElement> = styled.div`
  margin-bottom: ${(p: ThemeNotifHeadProps) => (p.hasIcon ? '10px' : 'unset')};
  ${(props: PropsWithTheme<{ hasIcon?: boolean, isProject?: boolean, ... }, any>) =>
    props.isProject
      ? css`
          display: flex;
          align-items: center;
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
        `
      : css`
          display: ${props.theme.platform === 'ios' ? 'flex' : 'unset'};
          align-items: ${props.theme.platform === 'ios' ? 'center' : 'unset'};
          width: ${props.theme.platform === 'android' ? '100%' : 'unset'};
        `}

  padding: ${(props: ThemeNotifHeadProps) =>
    props.theme.expanded
      ? props.theme.notif.head.expanded.padding
      : props.theme.platform === 'ios'
        ? '14px'
        : '12px 14px 9px'};

  ${IconContainer} {
    margin-right: 8px;
  }
`
type NotifAppIconProps = { icon: string, isProject?: boolean, ... }
type ThemedNotifAppIconProps = PropsWithTheme<NotifAppIconProps, StyledPreviewTheme>
export const NotifAppIcon: StyledComponent<NotifAppIconProps, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    height: ${(p: ThemedNotifAppIconProps) => (p.icon ? '34px' : '37px')};
    width: ${(p: ThemedNotifAppIconProps) => (p.icon ? '34px' : '37px')};
    border-radius: ${(p: ThemedNotifAppIconProps) => (p.icon ? '7px' : '10px')};
    margin-right: ${(p: ThemedNotifAppIconProps) => (p.isProject ? 'unset' : '12px')};
    align-self: center;
    background: ${(p: ThemedNotifAppIconProps) => (!p.icon ? '#ACB1B9' : 'unset')};
    background-size: cover;
    background-image: url(${(props: ThemedNotifAppIconProps) => props.icon});
    ${editablePart}
  `
type NotifAppNameProps = { browser?: string, ... }
type ThemedNotifAppNameProps = PropsWithTheme<NotifAppNameProps, StyledPreviewTheme>
export const NotifAppName: StyledComponent<NotifAppNameProps, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    flex-grow: ${(props: ThemedNotifAppNameProps) => props.theme.notif.head.appName.grow};
    font-size: ${(props: ThemedNotifAppNameProps) => props.theme.notif.head.appName.fontSize};
    color: ${(props: ThemedNotifAppNameProps) => props.theme.notif.head.appName.color};
    text-transform: ${(props: ThemedNotifAppNameProps) =>
      props.browser === 'safari' ? 'uppercase' : props.theme.notif.head.appName.textTransform};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 6px;

    ${(props: ThemedNotifAppNameProps) =>
      props.browser === 'safari' &&
      css`
        display: flex;
        justify-content: space-between;

        span {
          text-transform: lowercase;
        }
      `}
    ${editablePart}
  `
type NotifDateProps = { isProject?: boolean, ... }
type ThemeNotifDateProps = PropsWithTheme<NotifDateProps, StyledPreviewTheme>
export const NotifDate: StyledComponent<NotifDateProps, StyledPreviewTheme, HTMLDivElement> =
  styled.div`
    margin-left: ${(p: ThemeNotifDateProps) =>
      p.isProject && p.theme.platform === 'ios'
        ? '8px'
        : !p.isProject && p.theme.platform === 'ios'
          ? '20px '
          : 'unset'};
    flex: 0 0 auto;
    align-self: flex-end;
    ${(props: ThemeNotifDateProps) =>
      props.theme.platform === 'ios' &&
      css`
        align-self: flex-start;
        line-height: ${(props: any) => (props.theme.expanded ? 'normal' : '17px')};
      `}
    i {
      line-height: 10px;
      font-weight: 600;
    }
    color: ${(props: ThemeNotifDateProps) =>
      props.theme.expanded
        ? props.theme.notif.head.date.expanded.color
        : props.theme.notif.head.date.color};
    letter-spacing: ${(props: ThemeNotifDateProps) =>
      props.theme.expanded
        ? props.theme.notif.head.date.expanded.letterSpacing
        : props.theme.notif.head.date.letterSpacing};
    font-size: ${(props: ThemeNotifDateProps) =>
      props.theme.expanded
        ? props.theme.notif.head.date.expanded.fontSize
        : props.theme.notif.head.date.fontSize};
    font-weight: ${(props: ThemeNotifDateProps) => props.theme.notif.head.date.fontWeight};
    ${editablePart}
  `
export const NotifContent: StyledComponent<
  { browser?: string, hasMedia?: boolean, isProject?: boolean, ... },
  *,
  HTMLDivElement,
> = styled.div`
  display: flex;
  max-width: 100%;

  ${(
    props: PropsWithTheme<{ browser?: string, hasMedia?: boolean, isProject?: boolean, ... }, any>
  ) =>
    props.isProject &&
    css`
      display: flex;
      align-items: center;
      padding: ${props.theme.platform === 'ios' && props.hasMedia && !props.theme.expanded
        ? '10px 11px 17px 11px'
        : props.theme.platform === 'ios' && props.theme.expanded && props.hasMedia
          ? '10px 11px 10px 11px'
          : props.theme.platform === 'ios'
            ? '10px 11px 12px 11px'
            : '0px 11px 12px 11px'};
    `}

  // -------------------------

${(
    props: PropsWithTheme<{ browser?: string, hasMedia?: boolean, isProject?: boolean, ... }, any>
  ) =>
    props.theme.platform === 'android' && !props.isProject
      ? css`
          align-items: center;
          width: ${props.hasMedia && !props.theme.expanded ? '280px' : '100%'};
        `
      : !props.isProject && props.theme.platform === 'ios'
        ? css`
            align-items: center;
            width: ${props.hasMedia && !props.theme.expanded ? '220px' : 'calc(100% - 150px)'};
          `
        : css``}




  ${(
    props: PropsWithTheme<{ browser?: string, hasMedia?: boolean, isProject?: boolean, ... }, any>
  ) =>
    props.theme.platform === 'webpush' &&
    css`
      align-items: ${props.browser === 'safari' ? 'start' : 'center'};
      font-size: 14px;
      line-height: 1.5em;
    `}
  padding: ${(
    props: PropsWithTheme<{ browser?: string, hasMedia?: boolean, isProject?: boolean, ... }, any>
  ) => props.browser === 'safari' && '0 10px 10px 0'};
`
export const NotifContentText: StyledComponent<
  { isProject?: boolean, hasMedia?: boolean, ... },
  *,
  HTMLDivElement,
> = styled.div`
  flex-grow: 1;
  order: 1;
  unicode-bidi: plaintext;

  ${(props: PropsWithTheme<{ hasMedia?: boolean, isProject?: boolean, ... }, any>) =>
    props.isProject &&
    css`
      margin-left: ${props.theme.platform === 'ios' && '8px'};
      max-width: ${props.hasMedia && !props.theme.expanded && props.theme.platform === 'ios'
        ? '264px'
        : '100%'};
      width: ${props.hasMedia && !props.theme.expanded && props.theme.platform === 'ios'
        ? 200
        : 'calc(100% - 200px)'};
    `};

  font-size: ${(props: PropsWithTheme<{ hasMedia?: boolean, isProject?: boolean, ... }, any>) =>
    props.theme.notif.content.fontSize}px;
  line-height: ${(props: PropsWithTheme<{ hasMedia?: boolean, isProject?: boolean, ... }, any>) =>
    props.theme.notif.content.lineHeight}px;
  color: ${(props: PropsWithTheme<{ hasMedia?: boolean, isProject?: boolean, ... }, any>) =>
    props.theme.expanded
      ? props.theme.notif.content.expanded.color
      : props.theme.notif.content.color};
  ${(props: PropsWithTheme<{ hasMedia?: boolean, isProject?: boolean, ... }, any>) =>
    props.theme.platform === 'webpush' &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `};
  ${(props: PropsWithTheme<{ hasMedia?: boolean, isProject?: boolean, ... }, any>) =>
    props.theme.platform === 'ios' &&
    props.theme.expanded &&
    css`
      font-size: ${(props: any) => props.theme.notif.content?.expanded?.fontSize ?? 17}px;
      line-height: 17px;
    `};
`
type NotifContentTextTitleProps = {
  isProject?: boolean,
  hasMessage?: boolean,
  isProject?: boolean,
  hasMedia?: boolean,
  limit?: boolean,
  browser?: string,
  ...
}
type NotifContentTextTitlePropsWithTheme = PropsWithTheme<
  NotifContentTextTitleProps,
  StyledPreviewTheme,
>
export const NotifContentTextTitle: StyledComponent<
  NotifContentTextTitleProps,
  StyledPreviewTheme,
  *,
> = styled(Editable)`
  ${(props: NotifContentTextTitlePropsWithTheme) =>
    props.isProject && props.theme.platform === 'android'
      ? css`
          max-width: ${props.hasMedia && !props.theme.expanded ? 258 : 300}px;
          width: ${props.hasMedia && !props.theme.expanded ? 258 : 300}px;
        `
      : !props.isProject && props.theme.platform === 'android'
        ? css`
            max-width: unset;
            width: ${props.hasMedia && !props.theme.expanded ? 340 : 332}px;
          `
        : css``}

  width: ${(p: NotifContentTextTitlePropsWithTheme) =>
    !p.hasMedia && p.theme.platform === 'android' && 330}px;
  margin-bottom: ${(p: NotifContentTextTitlePropsWithTheme) =>
    !p.hasMessage && p.theme.platform === 'ios' && !p.isProject ? 18 : 0}px;
  font-weight: 600;
  unicode-bidi: plaintext;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${(props: NotifContentTextTitlePropsWithTheme) =>
    props.theme.notif.content.title.fontWeight};

  color: ${(props: NotifContentTextTitlePropsWithTheme) => props.theme.notif.content.title.color};
  ${(props: NotifContentTextTitlePropsWithTheme) =>
    props.limit &&
    css`
      max-width: 290px;
    `}

  ${(props: NotifContentTextTitlePropsWithTheme) =>
    !props.theme.expanded &&
    css`
      white-space: nowrap;
    `}

  ${(props: NotifContentTextTitlePropsWithTheme) =>
    props.theme.platform === 'webpush' && props.browser === 'chrome'
      ? css`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          white-space: normal;
          margin: 2px 0 4px 0;
        `
      : css`
          flex-grow: 2;
          cursor: text;
        `};
  ${editablePart}
`

const TextMessageClamp = {
  mac: { firefox: 1, chrome: 1, safari: 1 },
  win: { firefox: 'none', chrome: 4 },
}

type NotifContentTextMessageProps = {
  isProject?: boolean,
  hasIcon?: boolean,
  hasMedia?: boolean,
  browser?: string,
  os?: 'mac' | 'win',
  ...
}
type NotifContentTextMessagePropsWithTheme = PropsWithTheme<
  NotifContentTextMessageProps,
  StyledPreviewTheme,
>
export const NotifContentTextMessage: StyledComponent<
  NotifContentTextMessageProps,
  StyledPreviewTheme,
  *,
> = styled(Editable)`
  text-overflow: ellipsis;
  overflow: hidden;
  unicode-bidi: plaintext;
  text-align: start;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: vertical;

  ${(props: NotifContentTextMessagePropsWithTheme) =>
    props.isProject
      ? css`
          width: ${props.hasMedia && !props.theme.expanded && props.theme.platform === 'ios'
            ? '225px'
            : !props.theme.expanded && props.theme.platform === 'android'
              ? 'calc(100% - 10px)'
              : '100%'};
        `
      : !props.isProject && props.theme.platform === 'android'
        ? css`
            width: ${(!props.theme.expanded && props.hasMedia) || props.hasIcon ? 290 : 332}px;
            margin-top: 4px;
          `
        : css`
            width: ${!props.theme.expanded && props.hasMedia ? 233 : 260}px;
          `};

  ${(props: NotifContentTextMessagePropsWithTheme) =>
    props.theme.platform === 'android' &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${props.hasMedia
        ? !props.theme.expanded
          ? 1
          : 3
        : !props.theme.expanded
          ? 1
          : 8};
      -webkit-box-orient: vertical;
    `}
  ${(props: NotifContentTextMessagePropsWithTheme) =>
    props.theme.platform === 'ios' &&
    css`
      -webkit-line-clamp: ${props.theme.expanded ? 7 : 4};
      -webkit-box-orient: vertical;
    `};
  color: ${(props: NotifContentTextMessagePropsWithTheme) =>
    props.theme.expanded
      ? props.theme.notif.content.expanded.color
      : props.theme.notif.content.color};
  ${(props: NotifContentTextMessagePropsWithTheme) =>
    props.theme && props.theme.platform === 'webpush' && props.os && props.browser
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: ${(props: any) => TextMessageClamp[props.os][props.browser]};
          -webkit-box-orient: vertical;
        `
      : ''}
  ${editablePart}
`

export const NotifContentMediaThumb: StyledComponent<
  { os: string, src: string, hasMedia?: boolean, isProject?: boolean, hasIcon?: boolean, ... },
  *,
  HTMLDivElement,
> = styled.div`
  ${(
    props: PropsWithTheme<
      {
        hasIcon?: boolean,
        hasMedia?: boolean,
        isProject?: boolean,
        os: string,
        src: string,
        ...
      },
      any,
    >
  ) =>
    props.theme.platform === 'android' &&
    css`
      width: 45px;
      height: 45px;
      margin-right: ${props.isProject ? '4px' : '10px'};
      flex-basis: 45px;
      position: ${props.hasIcon ? 'absolute' : 'unset'};
      right: ${props.hasIcon ? '0px' : 'unset'};
      top: ${props.hasIcon ? '20px' : 'unset'};
    `}

  margin-top: ${(
    props: PropsWithTheme<
      {
        hasIcon?: boolean,
        hasMedia?: boolean,
        isProject?: boolean,
        os: string,
        src: string,
        ...
      },
      any,
    >
  ) =>
    props.theme.platform === 'ios' && props.hasMedia && !props.theme.expanded
      ? '24px'
      : props.isProject
        ? '0px'
        : '10px'};
  border-radius: ${(
      props: PropsWithTheme<
        {
          hasIcon?: boolean,
          hasMedia?: boolean,
          isProject?: boolean,
          os: string,
          src: string,
          ...
        },
        any,
      >
    ) => (props.theme.platform === 'ios' && !props.theme.expanded ? '7px' : '10px')}
    ${(
      props: PropsWithTheme<
        {
          hasIcon?: boolean,
          hasMedia?: boolean,
          isProject?: boolean,
          os: string,
          src: string,
          ...
        },
        any,
      >
    ) =>
      props.theme.expanded && props.theme.platform !== 'android'
        ? 'display: none;'
        : 'display: block'};
  flex-basis: ${(
    props: PropsWithTheme<
      {
        hasIcon?: boolean,
        hasMedia?: boolean,
        isProject?: boolean,
        os: string,
        src: string,
        ...
      },
      any,
    >
  ) => props.theme.notif.media.thumb.size};
  flex-shrink: 0;
  order: ${(
    props: PropsWithTheme<
      {
        hasIcon?: boolean,
        hasMedia?: boolean,
        isProject?: boolean,
        os: string,
        src: string,
        ...
      },
      any,
    >
  ) => (props.theme.platform === 'webpush' && props.os === 'win' ? 0 : 2)};

  width: ${(
    props: PropsWithTheme<
      {
        hasIcon?: boolean,
        hasMedia?: boolean,
        isProject?: boolean,
        os: string,
        src: string,
        ...
      },
      any,
    >
  ) => props.theme.notif.media.thumb.size};
  height: ${(
    props: PropsWithTheme<
      {
        hasIcon?: boolean,
        hasMedia?: boolean,
        isProject?: boolean,
        os: string,
        src: string,
        ...
      },
      any,
    >
  ) => props.theme.notif.media.thumb.size};
  border-radius: ${(
    props: PropsWithTheme<
      {
        hasIcon?: boolean,
        hasMedia?: boolean,
        isProject?: boolean,
        os: string,
        src: string,
        ...
      },
      any,
    >
  ) => props.theme.notif.media.thumb.borderRadius};
  background-image: url(${(
    props: PropsWithTheme<
      {
        hasIcon?: boolean,
        hasMedia?: boolean,
        isProject?: boolean,
        os: string,
        src: string,
        ...
      },
      any,
    >
  ) => props.src});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  ${editablePart}
`

export const NotifContentVideoThumb: StyledComponent<{ os?: string, ... }, *, HTMLDivElement> =
  styled.div`
    display: block;
    position: relative;
    flex-basis: ${(props: PropsWithTheme<{ os?: string, ... }, any>) =>
      props.theme.notif.media.thumb.size};
    flex-shrink: 0;
    order: ${(props: PropsWithTheme<{ os?: string, ... }, any>) =>
      props.theme.platform === 'webpush' && props.os === 'win' ? 0 : 2};
    margin: ${(props: PropsWithTheme<{ os?: string, ... }, any>) => {
      if (props.theme.platform === 'webpush' && props.os === 'win') return '0 15px 0 0'
      if (props.theme.platform === 'ios' && !props.theme.expanded) return '24px 0 0 3px'
      return '0 0 0 3px'
    }};
    width: ${(props: PropsWithTheme<{ os?: string, ... }, any>) =>
      props.theme.notif.media.thumb.size};
    height: ${(props: PropsWithTheme<{ os?: string, ... }, any>) =>
      props.theme.notif.media.thumb.size};
    border-radius: ${(props: PropsWithTheme<{ os?: string, ... }, any>) =>
      props.theme.notif.media.thumb.borderRadius};
    overflow: hidden;

    ${IconContainer} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    ${editablePart}
  `

export const NotifMedia: StyledComponent<{ hasMedia?: boolean, ... }, *, HTMLDivElement> =
  styled.div`
    line-height: 0;
    padding: ${(props: PropsWithTheme<{ hasMedia?: boolean, ... }, any>) =>
      props.theme.notif.media.expanded.padding};
    background: #fff;
    ${(props: PropsWithTheme<{ hasMedia?: boolean, ... }, any>) =>
      props.theme.platform === 'android'
        ? css`
            background-image: url(${(props: any) => props.imgSrc});
            background-size: ${(props: any) => (props.hasMedia ? 'cover' : 'initial')};
            background-position: center center;
            background-repeat: no-repeat;
            width: calc(100% - 20px);
            margin: 0 10px 10px 10px;
            height: ${340 / 2.1}px;
          `
        : ''}
    ${(props: PropsWithTheme<{ hasMedia?: boolean, ... }, any>) =>
      props.theme.platform === 'ios'
        ? css`
            margin-right: -1;
          `
        : ''}

  img,
  audio,
  video {
      width: 100%;
      max-height: ${(props: PropsWithTheme<{ hasMedia?: boolean, ... }, any>) =>
        props.theme.platform === 'ios' ? '28em' : '14em'};
      max-width: 100%;
      object-fit: cover;
      object-position: center;
    }
    audio {
      width: 100%;
    }
    ${editablePart}
  `

export const NotifContentBrowserThumb: StyledComponent<
  { icon?: string, browser: string, os: string, ... },
  *,
  HTMLDivElement,
> = styled.div`
  background-image: url(${(
    props: PropsWithTheme<{ browser: string, icon?: string, os: string, ... }, any>
  ) => (props.icon ? props.icon : `/medias/img/notif-preview/legacy/${props.browser}.svg`)});
  background-size: 30px 30px;
  flex: 0 0 30px;
  height: 30px;
  margin-right: 15px;
  order: 0;

  ${(props: PropsWithTheme<{ browser: string, icon?: string, os: string, ... }, any>) =>
    props.os === 'win' && props.browser === 'firefox'
      ? css`
          flex: 0 0 60px;
          height: 60px;
          background-size: 60px 60px;
        `
      : props.browser === 'safari'
        ? css`
            background-size: 18px 18px;
            background-repeat: no-repeat;
            margin-right: 5px;
            height: 18px;
            flex: 0 0 18px;
          `
        : ''}
  ${editablePart}
`

export const StatusBar: StyledComponent<{ light: boolean, ... }, *, HTMLDivElement> = styled.div`
  pointer-events: none;
  position: absolute;
  margin: ${(props: PropsWithTheme<{ light: boolean, ... }, any>) => props.theme.containerPadding};
  padding: 4px 8px;
  top: 5px;
  height: 16px;
  left: 0;
  width: 374px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${(props: PropsWithTheme<{ light: boolean, ... }, any>) =>
    props.light ? '#FFF' : '#000'};
`
export const StatusBarOperator: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  display: flex;
  align-items: baseline;
  padding-left: 4px;
`
export const StatusBarMain: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  text-align: center;
  padding-left: 8px;
  flex-grow: 1;
`

const getBatteryColor = (charging: boolean, level: number, light: boolean) => {
  if (level < 0.1) {
    return 'crimsom'
  }
  if (level < 0.2) {
    return 'orange'
  }
  if (charging && level < 1) {
    return 'HSL(127, 100%, 50%)'
  }
  return light ? '#FFF' : '#000'
}

export const StatusBarBattery: StyledComponent<
  { light: boolean, charging: boolean, level: number, ... },
  *,
  HTMLDivElement,
> = styled.div`
  border: 1px solid
    rgba(
      ${(props: PropsWithTheme<{ charging: boolean, level: number, light: boolean, ... }, any>) =>
          props.light ? '255,255,255,' : '0,0,0,'}
        0.3
    );
  flex: 0 0 22px;
  height: 10px;
  border-radius: 3px;
  display: flex;
  padding: 1px;
  div {
    background: ${(
      props: PropsWithTheme<{ charging: boolean, level: number, light: boolean, ... }, any>
    ) => getBatteryColor(props.charging, props.level, props.light)};
    flex: 0 0
      ${(props: PropsWithTheme<{ charging: boolean, level: number, light: boolean, ... }, any>) =>
        Math.floor(props.level * 100)}%;
    border-radius: 2px
      ${(props: PropsWithTheme<{ charging: boolean, level: number, light: boolean, ... }, any>) =>
        props.level < 0.9 ? ' 0 0 ' : ' 2px 2px '}
      2px;
  }
  position: relative;
  &:after {
    position: absolute;
    top: 1.5px;
    right: -4px;
    content: '';
    display: block;
    border-radius: 0 50px 50px 0;
    width: 1.6px;
    height: 4px;
    background: rgba(
      ${(props: PropsWithTheme<{ charging: boolean, level: number, light: boolean, ... }, any>) =>
          props.light ? '255,255,255,' : '0,0,0,'}
        0.3
    );
  }
`
export const OpBar: StyledComponent<{ num: number, off?: boolean, ... }, *, HTMLDivElement> =
  styled.div`
    border-radius: 1px;
    background-color: currentColor;
    flex: 0 0 3px;
    margin-right: 1px;
    height: ${(props: PropsWithTheme<{ num: number, off?: boolean, ... }, any>) =>
      props.num * 2 + 2}px;
    opacity: ${(props: PropsWithTheme<{ num: number, off?: boolean, ... }, any>) =>
      props.off ? 0.5 : 1};
  `

export const AndroidTime: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  flex: 0 0 40px;
`

export const AndroidBattery: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  background: currentColor;
  display: block;
  position: relative;
  margin-right: 4px;
  width: 7px;
  height: 11px;
  border-radius: 1px;
  &:before {
    border-radius: 1px 1px 0 0;
    width: 3px;
    height: 1px;
    background: currentColor;
    position: absolute;
    content: '';
    top: -1px;
    left: 2px;
  }
`
export const AndroidSvg: StyledComponent<{ ... }, *, *> = styled.svg`
  fill: currentColor;
`
export const CommandCenter: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  background: #202020;
  border-radius: 8px;
  z-index: 3;
  padding: 8px 12px;
  position: absolute;
  top: 100px;
  left: 20px;
  right: 20px;
  width: 360px;
  height: 110px;
`
export const CommandCenterTitle: StyledComponent<{ ... }, *, HTMLHeadingElement> = styled.h1`
  display: flex;
  color: #fff;
  font-size: 11px;
  div {
    flex: 1 0 0;
  }
`

export const CommandCenterBar: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`
export const CommandCenterButton: StyledComponent<{ active?: boolean, ... }, *, HTMLDivElement> =
  styled.div`
    background: ${(props: PropsWithTheme<{ active?: boolean, ... }, any>) =>
      props.active ? '#2780E1' : '#4E4E4E'};
    border-radius: 50%;
    width: 38px;
    height: 38px;
    flex-basis: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props: PropsWithTheme<{ active?: boolean, ... }, any>) =>
      props.active ? '#2C2D24' : '#CBCBCB'};
  `

export const CommandCenterHandle: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  background: #e9e9e9;
  width: 30px;
  height: 5px;
  border-radius: 5px;
  margin: 10px auto;
`
export const ImageLoaderContainer: StyledComponent<
  { hasIcon?: boolean, hasMedia?: boolean, ... },
  *,
  HTMLDivElement,
> = styled.div`
  display: ${(p: PropsWithTheme<{ hasIcon?: boolean, hasMedia?: boolean, ... }, any>) =>
    p.hasMedia ? 'flex' : 'unset'};
  align-items: ${(p: PropsWithTheme<{ hasIcon?: boolean, hasMedia?: boolean, ... }, any>) =>
    p.hasMedia ? 'center' : 'unset'};
  position: ${(p: PropsWithTheme<{ hasIcon?: boolean, hasMedia?: boolean, ... }, any>) =>
    p.hasIcon ? 'relative' : 'unset'};
  right: ${(p: PropsWithTheme<{ hasIcon?: boolean, hasMedia?: boolean, ... }, any>) =>
    p.hasIcon ? 2 : 0};
  margin-top: ${(p: PropsWithTheme<{ hasIcon?: boolean, hasMedia?: boolean, ... }, any>) =>
    p.hasIcon ? 10 : 0};
  padding: ${(p: PropsWithTheme<{ hasIcon?: boolean, hasMedia?: boolean, ... }, any>) =>
    p.theme.platform === 'ios' && !p.theme.expanded ? 10 : 0}px;
`

export const InnerNotifHeadContainer: StyledComponent<
  { hasIcon?: boolean, hasMedia?: boolean, ... },
  *,
  HTMLDivElement,
> = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const NotifImageLoaderContainerIOS: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  position: absolute;
  right: 10px;
  top: 30px;
`
export const NotifDateContainer: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  display: flex;
  align-items: center;
  line-height: normal;
  gap: 10;
`
