// @flow

import Immutable, { type Map } from 'immutable'

import {
  BounceDetailFactory,
  type BounceDetailRecord,
  DataFactory,
} from 'com.batch/orchestration-analytics/models/bounce-detail-record'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

export const parseBounceByDays = (
  stats: ?Array<OrchestrationStatsDynamicDimension>
): Map<string, BounceDetailRecord> => {
  const dayIndex = stats ? stats[0].dimensionNames.indexOf('day') : -1

  return stats && dayIndex !== -1
    ? stats.reduce((acc: Map<string, BounceDetailRecord>, { currentPeriod, dimensionValues }) => {
        const day = dimensionValues[dayIndex]
        const bounce = currentPeriod.bounce ?? 0

        if (currentPeriod.bounce) {
          const hardBounce = currentPeriod.hardBounce ?? 0
          const softBounce = currentPeriod.softBounce ?? 0
          const blockBounce = currentPeriod.blockBounce ?? 0

          return acc.set(
            day,
            BounceDetailFactory({
              total: currentPeriod.bounce,
              hard: DataFactory({
                value: currentPeriod.hardBounce,
                rate: bounce > 0 ? hardBounce / bounce : 0,
              }),
              soft: DataFactory({
                value: softBounce,
                rate: bounce > 0 ? softBounce / bounce : 0,
              }),
              block: DataFactory({
                value: blockBounce,
                rate: bounce > 0 ? blockBounce / bounce : 0,
              }),
            })
          )
        }
        return acc
      }, Immutable.Map())
    : Immutable.Map()
}
