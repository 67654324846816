// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import {
  useAction,
  useCampaignInfoFromRouter,
  useSetEditingCampaignFromRouterLegacy,
  useToggle,
} from 'components/_hooks'
import {
  Button,
  ButtonLink,
  ButtonNavLink,
  DropdownMenu,
  Switch,
  useDropdown,
} from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import Loader from 'components/common/loader-legacy'
import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'
import { Sticky } from 'components/common/scroll'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { LightHeader } from 'components/styled/blocs'
import { Tag, type TagProps } from 'components/styled/tag'
import { Timezone } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateCampaignUrl } from 'com.batch.common/router'

import { ReplicateConnected } from './replicate-connected'
import { Retargeting } from './retargeting'
import ReviewDataMenu from './review-data-menu'
import ReviewMenu from './review-menu'
import { ReviewReviewMenu } from './review-review-menu'
import ReviewScreenData from './review-screen-data'
import { ReviewScreenReview } from './review-screen-review'
import {
  ReviewActions,
  ReviewDate,
  ReviewHeader,
  ReviewInfos,
  ReviewNav,
  ReviewStatus,
  ReviewTitle,
} from './review-screen.styles'

import { currentAppSelector } from 'com.batch.redux/app'
import { archive, setActiveTranslation, toggleState } from 'com.batch.redux/campaign.action'
import {
  campaignConfigSelector,
  campaignRequireThemeSelector,
  currentCampaignData,
  pickedLanguagesSelector,
} from 'com.batch.redux/campaign.selector'
import { devApiKeyFeatureSelector } from 'com.batch.redux/company.selector'
import { fetchCampaignsData } from 'com.batch.redux/dataCampaign'
import { changePreferedInstall } from 'com.batch.redux/template'
import { FetchThemes } from 'com.batch.redux/theme'
import { InAppVariantsThemeSelector } from 'com.batch.redux/theme.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

export const ReviewScreen = ({ tab }: { tab: 'analytics' | 'review' }): React.Node => {
  // =================================================== ROUTER

  const app = useSelector(currentAppSelector)
  const { campaignType } = useCampaignInfoFromRouter()
  const { campaign, campaignFetchingState } = useSetEditingCampaignFromRouterLegacy(true)

  // =================================================== STATE
  const [isHeaderStuck, setIsHeadersStuck] = React.useState(false)
  const [currentReviewStep, setCurrentReviewStep] = React.useState<string | null>(null)
  const fullscreenPreviewState = useToggle()
  const [currentDataStep, setCurrentDataStep] = React.useState<string | null>(null)
  const retargetModalState = useToggle()
  const replicateModalState = useToggle()
  const [previewMode, setPreviewMode] = React.useState<'normal' | 'expanded' | 'landing'>(
    campaignType === 'push' ? 'normal' : 'landing'
  )
  const [previewWeb, setPreviewWeb] = React.useState<string>('chrome-win')

  const { triggerProps, dropdownProps, closeDropdown } = useDropdown({ placement: 'bottom' })
  // =================================================== REDUX

  const data = useSelector(currentCampaignData)
  const user = useSelector(currentUserSelector)
  const variantsThemes = useSelector(InAppVariantsThemeSelector)
  const pickedLanguages = useSelector(pickedLanguagesSelector)
  const campaingConfig = useSelector(campaignConfigSelector)
  const activeLanguageId = campaingConfig.activeLanguageId || 'default'
  const template = useSelector(state => state.template)
  const campaignRequireTheme = useSelector(campaignRequireThemeSelector)
  const hasDevApiKeyFeature = useSelector(devApiKeyFeatureSelector)
  const changePreferedInstallBound = useAction(changePreferedInstall)
  const fetchThemesBound = useAction(FetchThemes)
  const fetchCampaignsDataBound = useAction(fetchCampaignsData)
  const archiveBound = useAction(archive)
  const toggleStateBound = useAction(toggleState)
  const setActiveTranslationBound = useAction(setActiveTranslation)

  const installId = template.preferedInstallId
  const installIds = template.pinnedInstallIds
  const templateLoading = template.loading
  const hasMacro = template.keyForField.size > 0

  const hasMissingTheme = React.useMemo(() => {
    return !variantsThemes.a || !variantsThemes.b
  }, [variantsThemes])

  React.useEffect(() => {
    fetchThemesBound()
    if (data === undefined && app.id) {
      fetchCampaignsDataBound({
        app: app,
        tokens: [campaign.token],
      })
    }
  }, [app, campaign.token, data, fetchCampaignsDataBound, fetchThemesBound])

  const handleDelete = React.useCallback(() => {
    closeDropdown()
    confirm({
      sensitive: true,
      title: 'Delete this campaign?',
      message: (
        <p>
          This will delete <ConfirmHighlight>{campaign.name}</ConfirmHighlight> campaign immediatly.
        </p>
      ),
    }).then(
      () => archiveBound(campaign),
      () => {}
    )
  }, [closeDropdown, campaign, archiveBound])

  const handleToggleState = React.useCallback(() => {
    campaign.state === 'RUNNING'
      ? `Are you sure want to stop campaign "${campaign.name}"?`
      : `Are you sure you want to run campaign "${campaign.name}"?`
    confirm({
      title: 'Confirm campaign state change',
      message:
        campaign.state === 'RUNNING'
          ? `Are you sure want to stop campaign "${campaign.name}"?`
          : `Are you sure you want to run campaign "${campaign.name}"?`,
    }).then(() => {
      toggleStateBound({
        campaign,
        appId: app.id,
      })
    })
  }, [app.id, campaign, toggleStateBound])

  const getTags = (): TagProps[] => {
    let tags: Array<TagProps> = []
    if (campaign.sendType === 'recurring') tags.push({ children: 'Recurring' })
    if (campaign.sendType === 'trigger') tags.push({ children: 'Trigger' })
    if (campaign.abtesting.enabled) tags.push({ children: 'A/B testing' })
    if (campaign.translations.count() > 1) tags.push({ children: 'Multi language' })
    if (hasMacro) tags.push({ children: 'Dynamic content' })
    if (campaign.hasLanding && campaign.type === 'push') tags.push({ children: 'Mobile landing' })

    tags = tags.concat(
      campaign.pickedLabels.map(l => ({ children: l.name, intent: 'feature' })).toArray()
    )

    return tags
  }

  const canRetarget = React.useMemo((): boolean => {
    if (!app) return false
    return (
      (campaign.type === 'push' || app.demo) && // faut que ce soit du push ou la sample
      !!app.features.includes('query-retargeting') && // qu'on aie la feature
      !!campaign.start &&
      campaign.start.isBefore(dayjs.utc()) &&
      campaign.state !== 'DRAFT' && // faut que la campagne aie run
      ((!!campaign.start &&
        campaign.start.isAfter(
          dayjs()
            .utc()
            .subtract(app.ttlRetargeting ?? 14, 'day')
        )) || // on a une startdate et elle est entre now & now - ttl
        (campaign.repeatFrequency > 0 &&
          (!campaign.end ||
            // $FlowFixMe
            (campaign.state === 'RUNINNG' &&
              campaign.end.isAfter(
                dayjs()
                  .utc()
                  .subtract(app.ttlRetargeting ?? 14, 'day')
              )))))
    )
  }, [app, campaign.end, campaign.repeatFrequency, campaign.start, campaign.state, campaign.type])

  const isPush = campaign.type === 'push'
  const isRecurring = campaign.sendType === 'recurring' || !isPush
  const tags = getTags()

  const formUrl: string = generateCampaignUrl('form', campaign.schedulingType, {
    companyId: app.companyId,
    appId: app.id,
    campaignType: campaign.type,
    id: campaign.token,
  })

  const onOpenReplicateModal = React.useCallback(() => {
    closeDropdown()
    replicateModalState.open()
  }, [closeDropdown, replicateModalState])

  const onOpenRetargetModal = React.useCallback(() => {
    closeDropdown()
    retargetModalState.open()
  }, [closeDropdown, retargetModalState])

  const shouldDisableReplicate = React.useMemo(() => {
    if (campaign.type === 'in-app') {
      return hasMissingTheme
    }
    if (campaign.type === 'push') {
      return campaignRequireTheme ? hasMissingTheme : false
    }

    return false
  }, [campaign.type, campaignRequireTheme, hasMissingTheme])

  if (campaignFetchingState === 'LOADING') return <Loader loading={true} height={400} size="fat" />

  if (campaignFetchingState === 'ERROR') {
    return (
      <div className="text-center">
        <p>We were unable to load your campaign data.</p>
        <p>
          <br />{' '}
        </p>
        <ButtonLink kind="primary" intent="action" href={window.location.href}>
          Reload the page
        </ButtonLink>
      </div>
    )
  }

  return (
    <PageTracker
      category={trackingCategories[campaign.schedulingType]}
      name={tab === 'review' ? trackingPages.review : trackingPages.data}
    >
      <div style={{ backgroundColor: '#f9f9f9' }}>
        <Sticky onStickyChange={setIsHeadersStuck}>
          <ThemeProvider theme={{ fixed: isHeaderStuck }}>
            <LightHeader id="fixedReviewHead">
              <ReviewHeader top fixed={isHeaderStuck}>
                <ReviewInfos>
                  <ReviewTitle fixed={isHeaderStuck}>
                    <ReviewStatus>
                      {campaign.state === 'COMPLETED' ? (
                        <Icon icon="success" size={12} color="#79b34d" />
                      ) : (
                        <Tooltip
                          placement="left"
                          tooltip={
                            <div>
                              This campaign is <strong>{campaign.state}</strong>.<br />
                              Click to{' '}
                              <strong>
                                {campaign.state === 'RUNNING' ? 'STOP' : 'RUN'}
                              </strong> it.{' '}
                            </div>
                          }
                        >
                          <Switch
                            isActive={campaign.state === 'RUNNING'}
                            disabled={campaign.loading}
                            onChange={handleToggleState}
                            style={{ margin: '0 0 0 -3px' }}
                          />
                        </Tooltip>
                      )}
                    </ReviewStatus>
                    <h1>
                      <Tooltip
                        tooltip={<div style={{ textAlign: 'left' }}>{campaign.name}</div>}
                        minWidth={Math.min(500, Math.max(50, campaign.name.length * 10))}
                      >
                        <span style={{ display: 'flex', alignItems: 'stretch' }}>
                          {campaign.name}
                          <span style={{ marginLeft: 9, transform: 'translate3d(0, -3px, 0)' }}>
                            {campaign.api && (
                              <Timezone style={{ margin: '0 6px 0 0' }}>Api</Timezone>
                            )}
                            {campaign.devOnly && <Timezone>Dev</Timezone>}
                          </span>
                        </span>
                      </Tooltip>
                    </h1>
                  </ReviewTitle>
                  <ReviewDate
                    campaign={campaign}
                    lastSend={data ? data.lastSend : undefined}
                    separator={tags.length > 0}
                  />
                  {tags.map((t, i) => (
                    <Tag key={i} intent={t.intent}>
                      {t.children}
                    </Tag>
                  ))}
                </ReviewInfos>
                <ReviewActions>
                  <Button {...triggerProps} addOn="suffix" addOnGap={12} kind="secondary">
                    Actions <Icon icon="select" />
                  </Button>
                  <DropdownMenu {...dropdownProps}>
                    <Tooltip
                      tooltip="You cannot replicate this campaign because its theme has been deleted"
                      placement="left"
                      isTooltipEmpty={!shouldDisableReplicate}
                    >
                      <Button onClick={onOpenReplicateModal} disabled={shouldDisableReplicate}>
                        Replicate
                      </Button>
                    </Tooltip>
                    {canRetarget && (
                      <Button
                        onClick={onOpenRetargetModal}
                        disabled={!app.features.has('query-retargeting')}
                      >
                        Retargeting
                      </Button>
                    )}
                    <Button onClick={handleDelete} danger>
                      Delete
                    </Button>
                  </DropdownMenu>

                  {campaign.state !== 'COMPLETED' && (
                    <ButtonNavLink
                      kind="primary"
                      intent="action"
                      style={{ marginLeft: 8 }}
                      disabled={
                        !user.permissionsForCurrentApp.has(
                          campaign.type === 'push' ? 'push:write' : 'in-app:write'
                        )
                      }
                      to={formUrl}
                    >
                      Edit
                    </ButtonNavLink>
                  )}
                </ReviewActions>
              </ReviewHeader>
              <ReviewNav>
                <ReviewMenu campaign={campaign} app={app} activeTab={tab} fixed={isHeaderStuck}>
                  {tab === 'analytics' && (
                    <ReviewDataMenu
                      abTesting={campaign.abtesting.enabled}
                      isPush={campaign.type === 'push'}
                      trigger={campaign.sendType === 'trigger'}
                      recurring={isRecurring}
                      activeStep={currentDataStep}
                      theme={isHeaderStuck ? 'light' : 'normal'}
                    />
                  )}
                  {tab === 'review' && (
                    <ReviewReviewMenu
                      activeStep={currentReviewStep}
                      toggleFullScreen={fullscreenPreviewState.toggle}
                      isPush={isPush}
                      hasLanding={campaign.hasLanding}
                      templateLoading={templateLoading}
                      updatePreviewMode={setPreviewMode}
                      updatePreviewWeb={setPreviewWeb}
                      previewMode={previewMode}
                      previewWeb={previewWeb}
                      platform={app.platform}
                      changePreferedInstall={changePreferedInstallBound}
                      installIds={installIds}
                      installId={installId}
                    />
                  )}
                </ReviewMenu>
              </ReviewNav>
            </LightHeader>
          </ThemeProvider>
        </Sticky>
        <ReplicateConnected opened={replicateModalState.value} close={replicateModalState.close} />
        <Retargeting
          app={app}
          campaign={campaign}
          opened={retargetModalState.value}
          onClose={retargetModalState.close}
        />

        <div id="fixedReviewContent">
          {tab === 'review' && (
            <ReviewScreenReview
              campaign={campaign}
              app={app}
              data={data}
              fullscreenPreview={fullscreenPreviewState.value}
              toggleFullScreen={fullscreenPreviewState.toggle}
              templateLoading={templateLoading}
              updatePreviewMode={setPreviewMode}
              updatePreviewWeb={setPreviewWeb}
              updateLanguage={setActiveTranslationBound}
              activeLanguageId={activeLanguageId}
              pickedLanguages={pickedLanguages}
              previewMode={previewMode}
              previewWeb={previewWeb}
              changePreferedInstall={changePreferedInstallBound}
              installIds={installIds}
              installId={installId}
              headerStuck={isHeaderStuck}
              onScrollEnter={setCurrentReviewStep}
            />
          )}
          {tab === 'analytics' && (
            <ReviewScreenData
              campaign={campaign}
              app={app}
              variantsThemes={variantsThemes}
              data={data}
              onScrollEnter={setCurrentDataStep}
              hasDevApiKeyFeature={hasDevApiKeyFeature}
            />
          )}
        </div>
      </div>
    </PageTracker>
  )
}
