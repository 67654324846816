// @flow
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot, $setSelection } from 'lexical'
import * as React from 'react'

import { lexicalInitState } from 'components/form/fields/editor/lexical-init-state'

/**
 * Permet de mettre à jour la valeur du champ Lexical si value en input change et est différente
 */
export const SyncValuePlugin = ({ value }: { value: string }): null => {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    editor.getEditorState().read(() => {
      const currentContent = $getRoot().getTextContent()
      if (currentContent !== value) {
        editor.update(() => {
          const root = $getRoot()
          root.clear()

          lexicalInitState(value)()

          // le fait d'update vient focus l'input .. nul https://github.com/facebook/lexical/issues/4474
          $setSelection(null)
        })
      }
    })
  }, [value, editor])

  return null
}
