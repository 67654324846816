// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { AuthCode } from 'components/form'
import { colors } from 'components/styled/tokens'

import { RegisterBlock } from './register.styles'

export type Register2FASetupProps = {
  qrCodeUri: ?string,
  validate: (code: string) => Promise<true | string>,
  ...
}

export const Register2FASetup = ({ qrCodeUri, validate }: Register2FASetupProps): React.Node => {
  const [code, setCode] = React.useState<string>('')
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const onBlur = (code: string) => {
    if (code.length === 0) {
      setError('')
    } else if (code.length !== 6) {
      setError('Your code should be composed of 6 digits.')
    } else {
      setLoading(true)
      validate(code).then((errorOrTrue: string | true) => {
        setLoading(false)
        setError('')
        if (errorOrTrue !== true) setError(errorOrTrue)
      })
    }
  }
  return (
    <React.Fragment>
      <RegisterBlock>
        <h3>Get the necessary app</h3>
        <p>
          Download and install{' '}
          <a href="https://authy.com" target="_blank">
            Authy
          </a>{' '}
          or any other two-factor authentication app (read more{' '}
          <a
            href="https://help.batch.com/en/articles/3411448-recommended-authentication-apps-for-2fa"
            target="_blank"
          >
            here
          </a>
          ).
        </p>
      </RegisterBlock>
      <Register2FAScan>
        <Register2FAScanContent>
          <h3>Scan this QR Code</h3>
          <p>Open your authentication app and create a new account. Then, scan this QR Code.</p>
        </Register2FAScanContent>
        <Register2FAQrCode style={{ backgroundImage: `url(${qrCodeUri || ''})` }} />
      </Register2FAScan>
      <RegisterBlock>
        <h3>Enter your verification code</h3>
        <p>
          Once the QR Code is scanned, enter the 6-digit verification code generated by the app.
        </p>
        <AuthCode
          style={{ margin: '24px 0 -6px -2px' }}
          value={code}
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="one-time-code"
          onChange={setCode}
          loading={loading}
          onBlur={onBlur}
          onFocus={() => {
            if (code.length === 6 && !!error) {
              setCode('')
              setError('')
            }
          }}
          invalid={!!error}
          feedback={error}
        />
      </RegisterBlock>
    </React.Fragment>
  )
}

export const Register2FAScanContent: StyledComponent<*, *, HTMLDivElement> = styled.div`
  p ${IconContainer} {
    margin: -10px 2px;
  }
`

export const Register2FAQrCode: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: block;
  position: relative;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  background-position: -12px -12px;
  background-size: calc(100% + 24px);
  box-shadow: 0 1px 6px hsla(0, 0%, 0%, 0.02);
  border-radius: 6px;

  &:after {
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    content: '';
    border: 1px solid ${colors.stroke};
    border-radius: 12px;
  }
`

export const Register2FAScan: StyledComponent<*, *, typeof RegisterBlock> = styled(RegisterBlock)`
  display: flex;

  ${Register2FAQrCode} {
    align-self: center;
    margin: -6px 0px -10px 38px;
    margin: -24px 0 -24px 38px;
  }
`
