// @flow

import Immutable, { type List, type RecordFactory, type RecordOf } from 'immutable'

import { type VariantId } from 'com.batch/message/models/message.records'

type VariantDataProps = {
  value: number,
  rate: number,
  winner: boolean,
}
export type VariantDataRecord = RecordOf<VariantDataProps>
export const VariantDataFactory: RecordFactory<VariantDataProps> = Immutable.Record(
  ({
    value: 0,
    rate: 0,
    winner: false,
  }: VariantDataProps)
)

export type VariantStatsProps = {
  delivered: VariantDataRecord,
  open: VariantDataRecord,
  click: VariantDataRecord,
  bounce: VariantDataRecord,
  unsubscribe: VariantDataRecord,
}
export const VariantStatsFactory: RecordFactory<VariantStatsProps> = Immutable.Record({
  delivered: VariantDataFactory(),
  open: VariantDataFactory(),
  click: VariantDataFactory(),
  bounce: VariantDataFactory(),
  unsubscribe: VariantDataFactory(),
})
export type VariantStatsRecord = RecordOf<VariantStatsProps>

export type OrchestrationVariantProps = {
  variantId: VariantId,
  stats: VariantStatsRecord,
}
export const OrchestrationVariantFactory: RecordFactory<OrchestrationVariantProps> =
  Immutable.Record({
    variantId: 0,
    stats: VariantStatsFactory(),
  })
export type OrchestrationVariantRecord = RecordOf<OrchestrationVariantProps>

export type OrchestrationStatsByVariantRecord = List<OrchestrationVariantRecord>
