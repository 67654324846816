// @flow

import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import S3Upload from 'react-s3-uploader/s3upload'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { UploadImageRender } from './upload-image-render'
import { UploadImageContainer, ImageUploader } from './upload-image.styles'

import { MediaUrlLoading } from '../media-url-builder/media-url.styles'
import { type AppRecord } from 'com.batch.redux/_records'

export type ImageMetaProps = {
  width: number,
  height: number,
  size: number,
  ...
}
type UploadImageProps = {
  app: AppRecord,
  isEditing?: boolean,
  value?: string,
  fileSize?: number,
  minImgSize?: number,
  maxImgSize?: number,
  allowedFiles?: Array<'image/jpeg' | 'image/png'>,
  uploadFile: string => any,
  removeFile: () => any,
  uploadRecommendations?: string,
  message?: string,
  type: 'smallIcon' | 'defaultIcon',
  id?: string,
  ...
}

export const UploadImage = ({
  app,
  isEditing = true,
  value,
  minImgSize,
  maxImgSize,
  allowedFiles = ['image/jpeg', 'image/png'],
  uploadRecommendations,
  message,
  uploadFile,
  removeFile,
  type,
  id,
}: UploadImageProps): React.Node => {
  // ====================== Component states
  const [imageMeta, setImageMeta] = React.useState<ImageMetaProps>({
    width: 0,
    height: 0,
    size: 0,
  })
  const [error, setError] = React.useState<boolean>(false)
  const [uploading, setUploading] = React.useState<boolean>(false)

  // ====================== Use effect
  // This useEffect is used to get sizes of the image,
  // if the component already has an url.
  React.useEffect(() => {
    if (!!value && imageMeta.width === 0 && imageMeta.height === 0) {
      const img = new Image()
      img.onload = () => {
        setImageMeta({
          width: img.width,
          height: img.height,
          size: 0,
        })
      }
      img.src = value
    }
  }, [value, imageMeta])

  // ====================== Callbacks
  // S3 config
  const s3Config = React.useMemo(() => {
    setError(false)
    setUploading(false)
    return {
      accept: { 'image/*': ['.jpg', '.jpeg', '.png'] },
      onFinishS3Put: file => {
        uploadFile(file.publicUrl)
      },
      onError: () => {},
      uploadRequestHeaders: {},
      signingUrl: `/api/app/${app.id}/s3config`,
    }
  }, [app, uploadFile])

  // Drop image
  const onDrop = React.useCallback(
    (acceptedFiles: Array<File>) => {
      setUploading(true)
      const file = acceptedFiles[0]
      if (!file) {
        setUploading(false)
        return
      }

      const dataURL: string = window.URL.createObjectURL(file)

      const img = new Image()
      img.onload = () => {
        let validMinImgSize =
          !minImgSize || (!!minImgSize && minImgSize <= img.width && minImgSize <= img.height)
        let validMaxImgSize =
          !maxImgSize || (!!maxImgSize && maxImgSize >= img.width && maxImgSize >= img.height)

        if (validMinImgSize && validMaxImgSize) {
          setImageMeta({
            width: img.width,
            height: img.height,
            size: file.size,
          })
          // uploadFile(file.name)
          const options = {
            ...s3Config,
            files: [file],
          }

          new S3Upload(options) // eslint-disable-line
          window.URL.revokeObjectURL(file)
        } else {
          setUploading(false)
          setError(true)
        }
      }
      img.src = dataURL
    },
    [maxImgSize, minImgSize, s3Config]
  )

  const { getRootProps, getInputProps, open, isDragActive, isDragReject } = useDropzone({
    onDrop,
    multiple: false,
    accept: allowedFiles.join(', '),
    noClick: true,
    noKeyboard: true,
  })

  // ====================== Content upload image
  const mode: 'field' | 'loading' | 'active' = uploading
    ? 'loading'
    : !value && isEditing
      ? 'active'
      : 'field'

  // Miss Focus in this button
  const mediaUrlContent = React.useCallback(() => {
    switch (mode) {
      case 'active':
        return (
          <React.Fragment>
            <p>
              Drop your image here or{' '}
              <Button kind="secondary" intent="action" onClick={open}>
                Browse images
              </Button>
            </p>
            {uploadRecommendations && (
              <p className="styled_upload-img-recommendation">{uploadRecommendations}</p>
            )}
          </React.Fragment>
        )

      case 'loading':
        return (
          <MediaUrlLoading>
            <Icon icon="spinner" /> <span>Uploading file</span>
          </MediaUrlLoading>
        )

      default:
        return (
          <UploadImageRender
            value={value ?? ''}
            isEditing={isEditing}
            message={message ?? ''}
            removeFile={removeFile}
            imageMeta={imageMeta}
            type={type}
          />
        )
    }
  }, [mode, value, imageMeta, type, message, isEditing, uploadRecommendations, open, removeFile])

  return (
    <UploadImageContainer
      {...getRootProps()}
      isEditing={isEditing}
      isDragActive={!isDragReject && isDragActive}
      isDragReject={isDragReject || error}
    >
      <ImageUploader error={error && !isDragActive} isFilled={isEditing && !value}>
        <input {...getInputProps()} id={id} />
        {mediaUrlContent()}
      </ImageUploader>
    </UploadImageContainer>
  )
}
