// @flow
import { List } from 'immutable'
import * as React from 'react'

import { Grid } from 'components/common/grid'

import { type GCMConfigRecord } from 'com.batch.redux/_records'

import { LegacyConfigGrid } from 'com.batch/settings/ui/components/settings-fcm/config-grid/legacy-config-grid'
import { LegacyBlockContainer } from 'com.batch/settings/ui/components/settings-fcm/legacy-block/legacy-block.styles'
import { Collapse } from 'com.batch/shared/ui/component/collapse/collapse'

type Props = {
  configs: List<GCMConfigRecord>,
}

export const LegacyBlock = ({ configs }: Props): React.Node => {
  return (
    <LegacyBlockContainer>
      <Collapse btnLabel="Legacy FCM Config">
        <Grid alignItems="start" template="fit-content(33.33%) 0px 1fr" gap={16}>
          {configs.map((conf, index) => (
            <LegacyConfigGrid key={index} conf={conf} index={index} />
          ))}
        </Grid>
      </Collapse>
    </LegacyBlockContainer>
  )
}
