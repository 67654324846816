// @flow

import styled, { type StyledComponent } from 'styled-components'

export const ColorPickerContent: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 39px;

  .chrome-picker {
    position: absolute;
    z-index: 999;
    top: 39px;

    .flexbox-fix:last-child {
      display: none !important;
    }
  }
`
type ColorPickerPreviewProps = { color: string, ... }
export const ColorPickerPreview: StyledComponent<ColorPickerPreviewProps, *, HTMLDivElement> =
  styled.div`
    width: 39px;
    border: 1px solid #e7e9f2;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    -ms-flex: 0 0 39px;
    flex: 0 0 39px;
    height: 39px;
    position: relative;
    margin: 0 0 0 0;
    background-color: ${(props: ColorPickerPreviewProps) => props.color};

    &:after {
      position: absolute;
      display: block;
      content: ' ';
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 8px 8px;
      border-color: transparent transparent #212c3c transparent;
    }
  `
