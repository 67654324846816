// @flow
import { findChannelFromActionType } from 'com.batch/profilebase/infra/formats/find-channel-from-action-type'
import {
  type ChannelsReachabilityStateRecord,
  ChannelsReachabilityStateFactory,
} from 'com.batch/profilebase/models/reachability-timeserie.records'
import {
  type FetchWebpushReachabilityTimeserieActions,
  type FetchPushReachabilityTimeserieActions,
  type FetchEmailReachabilityTimeserieActions,
  type FetchSmsReachabilityTimeserieActions,
} from 'com.batch/profilebase/usecases/fetch-reachability-timeserie'
import { STATUS } from 'constants/common'

const initialState = ChannelsReachabilityStateFactory()

type ChannelReachalibiltyActions =
  | FetchWebpushReachabilityTimeserieActions
  | FetchPushReachabilityTimeserieActions
  | FetchEmailReachabilityTimeserieActions
  | FetchSmsReachabilityTimeserieActions

export function ChannelsReachabilityReducer(
  state: ChannelsReachabilityStateRecord = initialState,
  action: ChannelReachalibiltyActions
): ChannelsReachabilityStateRecord {
  switch (action.type) {
    case 'FETCH_EMAIL_REACHABILITY':
    case 'FETCH_PUSH_REACHABILITY':
    case 'FETCH_SMS_REACHABILITY':
    case 'FETCH_WEBPUSH_REACHABILITY': {
      const channel = findChannelFromActionType(action.type)
      return state.set(channel, state.get(channel).set('loadingState', STATUS.LOADING))
    }

    case 'FETCH_EMAIL_REACHABILITY_SUCCESS':
    case 'FETCH_PUSH_REACHABILITY_SUCCESS':
    case 'FETCH_SMS_REACHABILITY_SUCCESS':
    case 'FETCH_WEBPUSH_REACHABILITY_SUCCESS': {
      const channel = findChannelFromActionType(action.type)
      return state.set(
        channel,
        state
          .get(channel)
          .set('loadingState', STATUS.LOADED)
          .set('counter', action.payload.counter)
          .set('timeserie', action.payload.timeserie)
      )
    }

    case 'FETCH_EMAIL_REACHABILITY_FAILURE':
    case 'FETCH_PUSH_REACHABILITY_FAILURE':
    case 'FETCH_SMS_REACHABILITY_FAILURE':
    case 'FETCH_WEBPUSH_REACHABILITY_FAILURE': {
      const channel = findChannelFromActionType(action.type)
      return state.set(channel, state.get(channel).set('loadingState', STATUS.ERROR))
    }

    default:
      return state
  }
}
