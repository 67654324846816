// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/common/button'
import { Form, ConfirmPassword } from 'components/form'

import * as api from './api'
import { RegWizard } from './register-containers'
import { RegisterStep, RegisterStepFooter } from './register.styles'

import { Title, Text } from '../auth.styles'

export const RegisterPassword = ({
  email,
  isInvite,
}: {
  email: string,
  isInvite: boolean,
  ...
}): React.Node => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordConfirm, setPasswordConfirm] = React.useState('')
  const navigate = useNavigate()
  const onSubmit = React.useCallback(() => {
    if (password.trim().length === 0) {
      setError('Please choose a password.')
    } else if (password.trim().length < 8) {
      setError('Your password must be longer than 8 characters.')
    } else if (passwordConfirm.trim().length === 0) {
      setError('Please confirm your password.')
    } else if (password !== passwordConfirm) {
      setError("Passwords don't match.")
    } else {
      setError('')
      setLoading(true)
      api
        .savePassword(email, password)
        .then(() => {
          navigate('/register/2fa', { replace: true })
        })
        .catch(error => {
          setLoading(false)
          setError(error)
        })
    }
  }, [email, navigate, password, passwordConfirm])
  const onPasswordChange = React.useCallback(([p1, p2]) => {
    setPassword(p1)
    setPasswordConfirm(p2)
  }, [])
  return (
    <RegWizard animate total={isInvite ? 3 : 5} step={isInvite ? 2 : 3}>
      <RegisterStep>
        <Title>Password</Title>
        <Text>
          {!isInvite && 'Your email is now confirmed. '}Please choose a strong & unique password to
          protect your account.
        </Text>
        <Form onSubmit={onSubmit}>
          {/* This field is password managers, that way they can save the username in addition to the password */}
          <input style={{ display: 'none' }} type="email" name="email" defaultValue={email} />

          <ConfirmPassword
            autoFocus
            name="password"
            invalid={!!error}
            feedback={error}
            values={[password, passwordConfirm]}
            setValues={onPasswordChange}
          />

          <RegisterStepFooter>
            <Button kind="primary" intent="action" type="submit" isLoading={loading}>
              Continue
            </Button>
          </RegisterStepFooter>
        </Form>
      </RegisterStep>
    </RegWizard>
  )
}
