// @flow
import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'

import { TimelineIcon } from '../icons'
import { TimelineContent } from '../timeline/timeline.styles'

export const Timeline = (): React.Node => {
  return (
    <TimelineContent>
      <Skeleton w={8} h={8} style={{ borderRadius: '50%' }}>
        <TimelineIcon />
      </Skeleton>
    </TimelineContent>
  )
}
