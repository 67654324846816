// @flow

export type automationStateType = campaignStateType

export const STATE_AUTOMATION: { [automationStateType]: automationStateType, ... } = Object.freeze({
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  COMPLETED: 'COMPLETED',
})
