// @flow

// api_salesforce_accounts_latest qui retourne les 30 derniers comptes
// api_salesforce_accounts_search qui prend un paramètre query
// api_salesforce_account qui prend un paramètre id

import promiseDebounce from 'es6-promise-debounce'
import Immutable, { type Map } from 'immutable'
import * as React from 'react'

import { Select } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

type SalesforceSelectProps = {
  accountId: ?string,
  onChange: (?string) => any,
  ...
}

type SalesForceAccount = {
  id: string,
  name: string,
  ...
}

let CACHE: Map<string, SalesForceAccount> = Immutable.Map()

const loadOptions = async (inputValue: string) => {
  const url = generateUrl(
    inputValue === '' ? 'api_salesforce_accounts_latest' : 'api_salesforce_accounts_search',
    { query: inputValue }
  )
  const response = await fetch(url)
  const data: Array<SalesForceAccount> = await response.json()
  if (!data) return new Immutable.List()
  data.forEach((account: SalesForceAccount) => {
    CACHE = CACHE.set(account.id, account)
  })
  return new Immutable.List().push(...data)
}
const loadOptionsDebounced = promiseDebounce(loadOptions, 400)
const getById = async (id: string) => {
  try {
    const response = await fetch(generateUrl('api_salesforce_account', { id }))
    const account = await response.json()
    CACHE = CACHE.set(id, account)
    return new Immutable.List().push(...account)
  } catch (_) {
    return new Immutable.List()
  }
}

export const SalesforceSelect = ({ accountId, onChange }: SalesforceSelectProps): React.Node => {
  React.useEffect(() => {
    if (!!accountId && !CACHE.has(accountId)) {
      CACHE = CACHE.set(accountId, { id: accountId, name: 'Loading' })
      getById(accountId).then(account => {
        console.log(account)
      })
    }
  }, [accountId])
  return (
    <Select
      value={accountId ? CACHE.get(accountId) : null}
      isSearchable
      isClearable
      optionToString={option => option?.name ?? ''}
      placeholder="Type at least 3 chars to search "
      onChange={acc => onChange(acc ? acc.id : null)}
      loadOptions={loadOptionsDebounced}
    />
  )
}
