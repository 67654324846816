// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { Dropdown } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Tooltip } from 'components/common/tooltip'
import { FilterSearch } from 'components/filter'
import { darklucent } from 'components/styled/tokens/schemes'

import { ProjectsDropdownContainer, ProjectAvatar } from './dropdown.styles'
import { ProjectListItem } from './projects-list-item'

import { type AppRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'

type ProjectsDropdownProps = {
  projects: List<ProjectRecord>,
  project: ProjectRecord,
  generateUrlForProject: (project: ProjectRecord) => string,
  apps: List<AppRecord>,
  ...
}

export const ProjectsDropdown = ({
  projects,
  project,
  generateUrlForProject,
}: ProjectsDropdownProps): React.Node => {
  const [query, setQuery] = React.useState('')
  const [width, setWidth] = React.useState(0)

  const onSearchChanged = React.useCallback(query => {
    setQuery(query)
  }, [])

  const filteredProjects = React.useMemo(
    () =>
      projects.filter(
        project => !query || project.name.toLowerCase().includes(query.toLowerCase())
      ),
    [projects, query]
  )

  const spanRef = React.useRef<?HTMLElement>(null)
  const dropdownRef = React.useRef<?HTMLElement>(null)

  React.useEffect(() => {
    if (dropdownRef.current?.clientWidth && width === 0) {
      setWidth(dropdownRef.current?.clientWidth)
    }
  }, [dropdownRef.current?.clientWidth, width])

  return (
    <Dropdown
      label={
        <Tooltip
          tooltip={project.name}
          minWidth={200}
          placement="bottom-start"
          isTooltipEmpty={
            !(spanRef.current && spanRef.current.scrollWidth > spanRef.current.clientWidth)
          }
        >
          <Grid template="32px 1fr" style={{ minWidth: 90 }}>
            <ProjectAvatar project={project} />
            <span ref={spanRef} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {project.name}
            </span>
          </Grid>
        </Tooltip>
      }
      buttonProps={{
        kind: 'inlineDark',
        style: {
          justifyContent: 'flex-start',
          textAlign: 'left',
          maxWidth: 320,
          height: 44,
          marginRight: 10,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          padding: '6px 12px 6px 6px',
          columnGap: '16px',
          borderRadius: '8px',
        },
      }}
      style={{
        padding: 0,
        borderRadius: '8px',
        border: 0,
        boxShadow: `0 0 0 1px ${darklucent['20']}, 0px 1px 4px 0px rgba(15, 15, 15, 0.06), 0px 1px 12px 0px rgba(15, 15, 15, 0.08)`,
      }}
    >
      <ProjectsDropdownContainer style={{ minWidth: width > 0 ? width : 220 }} ref={dropdownRef}>
        {projects.size > 5 && (
          <FilterSearch
            value={query}
            expandable={false}
            onChange={onSearchChanged}
            placeholder="Search projects"
            fullPlaceholder
          />
        )}
        <div className="dropdown-section projects-list">
          {filteredProjects?.size > 0 ? (
            filteredProjects.map(pr => (
              <ProjectListItem
                key={pr.id}
                project={pr}
                href={generateUrlForProject(pr)}
                selected={project.id === pr.id}
              />
            ))
          ) : (
            <p className="projects-empty">No projects matching your search</p>
          )}
        </div>
      </ProjectsDropdownContainer>
    </Dropdown>
  )
}
