// @flow

import Immutable, { type List } from 'immutable'

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { SegmentFactory, type SegmentRecord } from 'com.batch/segments/models/segment.records'

type FetchSegmentListAction = ReduxAction<
  'FETCH_SEGMENT_LIST',
  {
    page: number,
    nbPerPage: number,
  },
>

export type FetchSegmentListFailureAction = ReduxAction<'FETCH_SEGMENT_LIST_FAILURE', string>

export type FetchSegmentListSuccessAction = ReduxAction<
  'FETCH_SEGMENT_LIST_SUCCESS',
  {
    segments: List<SegmentRecord>,
    nbPerPage: number,
    page: number,
    total: number,
    totalMatching: number,
    sortBy: ?('displayName' | 'updatedAt'),
    sortDirection: ?('asc' | 'dsc'),
    search: string,
    trashCache: boolean,
  },
>

export type FetchSegmentActionType =
  | FetchSegmentListAction
  | FetchSegmentListFailureAction
  | FetchSegmentListSuccessAction

export type SetPageSegmentAction = ReduxAction<'SET_PAGE_SEGMENT_LIST', number>

export const setSegmentPage = (page: number): SetPageSegmentAction => ({
  type: 'SET_PAGE_SEGMENT_LIST',
  payload: page,
})

export const fetchSegmentList = ({
  page,
  pageSize = 10,
  search,
  sortDirection,
  sortField,
  forceTrashCache = false,
}: {
  page: number,
  pageSize: number,
  search: ?string,
  sortDirection: ?('asc' | 'dsc'),
  sortField: ?('displayName' | 'updatedAt'),
  forceTrashCache?: boolean,
}): DispatchExtraBoundFn<
  Promise<{
    count: number,
    segments: Immutable.List<SegmentRecord>,
  }>,
> => {
  return async function (
    dispatch,
    getState,
    { segmentsService }
  ): Promise<{ count: number, segments: Immutable.List<SegmentRecord> }> {
    const state = getState()
    const project = currentProjectSelector(state)
    const segmentState = state.segments

    // if filters or sort changed, we need to fetch the list again across all pages
    const trashCache =
      search !== segmentState.search ||
      segmentState.sortBy !== sortField ||
      segmentState.sortDirection !== sortDirection ||
      forceTrashCache

    if (!trashCache && segmentState.segmentsPerPage.has(page)) {
      dispatch(setSegmentPage(page))
      return Promise.resolve({
        count: segmentState.total,
        segments: new Immutable.List().push(...segmentState.segments.valueSeq()),
      })
    }

    const segmentResult = await promiseActionCreator({
      dispatch,
      promise: segmentsService
        .fetchSegmentList({
          projectKey: project.projectKey,
          page: Math.max(0, page - 1),
          search: search ?? '',
          pageSize,
          sortDirection: !sortField
            ? 'SORT_DIRECTION_DESC'
            : sortDirection === 'asc'
              ? 'SORT_DIRECTION_ASC'
              : sortDirection === 'dsc'
                ? 'SORT_DIRECTION_DESC'
                : 'SORT_DIRECTION_UNSPECIFIED',
          sortField:
            sortField === 'displayName' ? 'SORT_FIELD_DISPLAY_NAME' : 'SORT_FIELD_CREATED_AT',
          total: trashCache ? null : segmentState.total,
          totalMatching: trashCache ? null : segmentState.totalMatching,
        })
        .then(response => {
          const segmentList = response.segments.map(segment => {
            return SegmentFactory({
              displayName: segment.displayName,
              name: segment.name,
              query: segment.query,
              campaignCount: segment.campaignCount,
              automationCount: segment.automationCount,
              campaignRunningCount: segment.campaignRunningCount,
              automationRunningCount: segment.automationRunningCount,
            })
          })
          return {
            segments: new Immutable.List().push(...segmentList),
            countTotal: response.countTotal,
            totalMatching: response.countFiltered,
            page,
            pageSize,
            total: response.countTotal,
            sortBy: sortField,
            sortDirection: sortDirection,
            search: search ?? '',
            trashCache,
          }
        }),
      actionName: 'FETCH_SEGMENT_LIST',
    })

    return {
      count: segmentResult.countTotal,
      segments: segmentResult.segments,
    }
  }
}
