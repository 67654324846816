// @flow

import Immutable, { type Set } from 'immutable'

export type SMS_ERROR_CODES = 'MSG_EMPTY' | 'MSG_TOO_LONG'
export const SMS_MAX_PARTS = 4
export const validateSmsMessage = ({
  message,
  parts,
}: {
  message: string,
  parts: number,
  ...
}): Set<SMS_ERROR_CODES> => {
  const errors: Array<SMS_ERROR_CODES> = []
  if (message.length === 0) errors.push('MSG_EMPTY')
  if (parts > SMS_MAX_PARTS) errors.push('MSG_TOO_LONG')

  return Immutable.Set(errors)
}
export const validateSmsFields = ({
  message,
  parts,
}: {
  message: string,
  parts: number,
  ...
}): Set<SMS_ERROR_CODES> => {
  return validateSmsMessage({ message, parts })
}
