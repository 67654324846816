// @flow
/* eslint-disable react/jsx-no-bind */
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/common/button'
import { ColorPicker } from 'components/common/color-picker'
import { confirm } from 'components/common/confirm'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Grid } from 'components/common/grid'
import Loader from 'components/common/loader-legacy'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { BlocCenter, BlocContainer, Fixed } from 'components/styled/blocs'
import { InteChoice } from 'components/styled/inte-choice'
import { Title, LinkDoc } from 'components/styled/text'
import { Tester } from 'components/tester/tester'

import { slugify, capitalize } from 'com.batch.common/utils'

import * as Preview from './preview-vect'
import { Theme } from './theme'

import { Icon } from '../common/svg-icon'
import { currentAppSelector } from 'com.batch.redux/app'
import {
  FetchRunningCampaignForTheme,
  SetActiveTheme,
  SaveTheme,
  FetchThemes,
  UpdateThemePayload,
  UpdateTheme,
} from 'com.batch.redux/theme'
import { type ThemeProps, type PayloadProps } from 'com.batch.redux/theme.records'
import {
  ThemesFetchedSelector,
  ThemesLoadingSelector,
  EditedThemeSelector,
} from 'com.batch.redux/theme.selector'

const kinds = [
  { label: 'Fullscreen', kind: 'universal' },
  { label: 'Banner', kind: 'banner' },
  { label: 'Modal', kind: 'modal' },
  { label: 'Image', kind: 'image' },
  { label: 'Webview', kind: 'webview' },
]

type ThemeEditorProps = {
  id: number | 'new',
  ...
}

// ====================== THEME EDITOR ============================

export const ThemeEditor = ({ id }: ThemeEditorProps): React.Node => {
  // redux
  const dispatch = useDispatch()
  const theme = useSelector(EditedThemeSelector)
  const app = useSelector(currentAppSelector)
  const loading = useSelector(ThemesLoadingSelector)
  const fetched = useSelector(ThemesFetchedSelector)

  // local state
  const [isWizardActive, setIsWizardActive] = React.useState(id === 'new' && !theme.fromDuplication)
  const [linkedCampaignsLoading, setLinkedCampaignsLoading] = React.useState(false)
  const [codeTouched, setCodeTouched] = React.useState(id !== 'new')

  // effects
  React.useEffect(() => {
    // load themes if they not already loaded / loading
    if (id !== 'new' && !fetched && !loading) {
      dispatch(FetchThemes())
    }
  }, [dispatch, fetched, id, loading])

  React.useEffect(() => {
    // load linked campaigns if not already done
    if (!theme.linkedCampaignsLoaded && !linkedCampaignsLoading && theme.id !== 'new') {
      setLinkedCampaignsLoading(true)
      dispatch(FetchRunningCampaignForTheme(theme)).then(
        () => {
          setLinkedCampaignsLoading(false)
        },
        () => setLinkedCampaignsLoading(false)
      )
    }
  }, [dispatch, linkedCampaignsLoading, theme])

  React.useEffect(() => {
    // update the edited theme id
    if (id !== theme.id) {
      dispatch(SetActiveTheme(id))
    }
  }, [dispatch, id, theme.id])

  // callbacks
  const updateValue = React.useCallback(
    (what: $Keys<ThemeProps>, value: any) => {
      dispatch(UpdateTheme({ what, value }))
      if (what === 'name' && !codeTouched) {
        dispatch(UpdateTheme({ what: 'code', value: slugify(value) }))
      }
      if (what === 'code') {
        setCodeTouched(true)
      }
    },
    [codeTouched, dispatch]
  )
  const updatePayload = React.useCallback(
    (what: $Keys<PayloadProps>, value: any) => {
      if (what === 'kind' && (value === 'image' || value === 'webview')) {
        setIsWizardActive(false)
      }
      dispatch(UpdateThemePayload({ what, value }))
      if (what === 'stack_cta_h') {
        dispatch(UpdateThemePayload({ what: 'attach_cta_bottom', value: value }))
      }
    },
    [dispatch]
  )

  const resetWizard = React.useCallback(() => {
    updateValue('dark', false)
    updateValue('color', '#353960')
    updatePayload('kind', '')
  }, [updatePayload, updateValue])

  const save = React.useCallback(() => {
    if (theme.campaigns.size === 0) {
      dispatch(SaveTheme(theme, theme.fromDuplication))
    } else {
      const sample = theme.campaigns.first()
      if (!sample) {
        return
      }
      confirm({
        message: (
          <article>
            <p>
              This theme is currently used in <code>{theme.campaigns.size}</code> running campaigns.
            </p>
            <p>
              Updating the theme won't update the related campaigns, but may lead in inconsistency
              between the dashboard preview and the displayed message, and is not recommanded.
            </p>
            <p>
              It is usually prefered to create a new theme, and to delete the old one once no
              campaign uses them.
            </p>
            <p>
              Example :{' '}
              <a
                href={`/${app.companyId ?? ''}/apps/${sample.appId}/campaigns/${
                  sample.type === 'in-app' ? 'in-app' : 'push'
                }/${sample.token}/`}
              >
                {sample.name}
              </a>
            </p>
          </article>
        ),
        title: 'Are you sure you want to update this theme',
        confirm: 'Save anyway',
      }).then(
        () => {
          dispatch(SaveTheme(theme, theme.fromDuplication))
        },
        () => {}
      )
    }
  }, [app.companyId, dispatch, theme])
  // render
  return (
    <React.Fragment>
      <Grid template="1fr 220px">
        <Title>
          Themes / {id === 'new' ? 'New ' : 'Edit '}
          {`${
            theme.payloadVars.kind === 'universal'
              ? 'Fullscreen'
              : theme.payloadVars.kind
                ? capitalize(theme.payloadVars.kind)
                : ''
          } theme`}
        </Title>
        <div style={{ textAlign: 'right', marginBottom: 24 }}>
          {isWizardActive && !theme.payloadVars.kind && (
            <LinkDoc
              intent="action"
              href="https://doc.batch.com/dashboard/in-app-messaging/overview#choosing-the-right-format"
              target="_blank"
            >
              Choose the right format
            </LinkDoc>
          )}
        </div>
      </Grid>
      {!isWizardActive && (
        <Grid template="50% 1fr 140px 60px" margin={[0, 0, 20, 0]}>
          <input
            type="text"
            autoFocus
            placeholder="Pick a name for your theme"
            className="form-control"
            value={theme.name}
            onChange={evt => updateValue('name', evt.target.value)}
          />
          <div />

          <Tester app={app} btnText="Preview" valid={theme.id !== 'new'} isThemeTester />

          <Button
            onClick={save}
            kind="primary"
            intent="action"
            disabled={theme.loading || theme.name.length < 3 || theme.code.length < 3}
          >
            Save
          </Button>
        </Grid>
      )}
      {id === 'new' && isWizardActive ? (
        theme.payloadVars.kind ? (
          <BlocContainer>
            <BlocCenter>
              <Fixed max={300}>
                <div className="form-group">
                  <p style={{ fontWeight: 500 }}>Theme style</p>

                  <TableToggle>
                    <TableToggleItem
                      active={theme.dark === false}
                      onClick={() => updateValue('dark', false)}
                    >
                      Light
                    </TableToggleItem>
                    <TableToggleItem active={theme.dark} onClick={() => updateValue('dark', true)}>
                      Dark
                    </TableToggleItem>
                  </TableToggle>
                </div>

                <div className="form-group">
                  <p style={{ fontWeight: 500 }}>Theme color</p>
                  <ColorPicker
                    color={theme.color}
                    onChange={color => updateValue('color', color)}
                  />
                </div>
                <div className="form-group">
                  <FlexLine>
                    <FlexLineItem>
                      <Button onClick={resetWizard} kind="primary" inent="neutral" addOn="prefix">
                        <Icon icon="chevron-left" />
                        Reset
                      </Button>
                    </FlexLineItem>
                    <FlexLineItem grow={1}>
                      <Button
                        onClick={() => {
                          if (theme.payloadVars.kind === 'modal') {
                            updatePayload('detached', true)
                          }
                          setIsWizardActive(false)
                        }}
                        kind="primary"
                        intent="action"
                        disabled={!theme.payloadVars.kind || theme.dark === null}
                        addOn="suffix"
                      >
                        Continue
                        <Icon icon="arrow-long-right" />
                      </Button>
                    </FlexLineItem>
                  </FlexLine>
                </div>
              </Fixed>
            </BlocCenter>
            <BlocCenter bl>
              <Fixed width={200}>
                {theme.payloadVars.kind &&
                  Preview['PV' + theme.payloadVars.kind]({
                    color: theme.color,
                    dark: theme.dark,
                    width: 200,
                  })}
              </Fixed>
            </BlocCenter>
          </BlocContainer>
        ) : (
          <div className="boxed">
            <div className="boxed__body">
              <div
                className="split"
                style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}
              >
                {kinds.map(item => (
                  <InteChoice key={item.kind} style={{ flexBasis: '197px', flexGrow: 0 }}>
                    {Preview['PV' + item.kind]({
                      color: theme.color,
                      dark: theme.dark,
                      width: 120,
                    })}

                    <Button
                      kind="primary"
                      intent="action"
                      onClick={() => updatePayload('kind', item.kind)}
                      style={{ width: '100%' }}
                    >
                      {item.label}
                    </Button>
                  </InteChoice>
                ))}
              </div>
            </div>
          </div>
        )
      ) : (
        <Loader loading={loading} overlay>
          <Theme updatePayload={updatePayload} updateValue={updateValue} theme={theme} app={app} />
        </Loader>
      )}
    </React.Fragment>
  )
}
