// @flow
import { type List } from 'immutable'

import { type ClickLinkRecord } from '../models/click-links-detail.record'
import {
  type OrchestrationAnalyticsDateRangeRecord,
  type OrchestrationAnalyticsFilters,
} from '../store/orchestration-analytics.state'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type FetchClicksByUrlAction = {
  type: 'FETCH_CLICKS_BY_URL',
  payload: {
    token: string,
  },
}
export type FetchClicksByUrlSuccessAction = {
  type: 'FETCH_CLICKS_BY_URL_SUCCESS',
  payload: List<ClickLinkRecord>,
  meta: {
    token: string,
  },
}
export type FetchClicksByUrlFailureAction = {
  type: 'FETCH_CLICKS_BY_URL_FAILURE',
  payload: {
    error: {
      message: string,
    },
    aborted: boolean,
  },
  meta: {
    token: string,
  },
}

export type FetchClicksByUrlActions =
  | FetchClicksByUrlAction
  | FetchClicksByUrlSuccessAction
  | FetchClicksByUrlFailureAction

let abortFetchClicksByUrlController: AbortController = new AbortController()
export const fetchClicksByUrl =
  ({
    token,
    filters,
    dateRange,
  }: {
    token: string,
    filters: OrchestrationAnalyticsFilters,
    dateRange: ?OrchestrationAnalyticsDateRangeRecord,
  }): DispatchExtraBoundFn<Promise<List<ClickLinkRecord>>> =>
  async (dispatch, getState, { dataService }) => {
    abortFetchClicksByUrlController.abort()
    abortFetchClicksByUrlController = new AbortController()

    const state = getState()
    const project = currentProjectSelector(state)

    const includeFilterDimensions = filters.reduce(
      (acc, values, dimensionName) => [
        ...acc,
        { dimensionName, dimensionValues: values.toArray() },
      ],
      []
    )
    const dateRangeFilter = dateRange
      ? {
          dateFrom: dateRange.from,
          dateTo: dateRange.to,
        }
      : undefined

    return promiseActionCreator({
      dispatch,
      meta: { token },
      payload: {
        token,
      },
      promise: dataService.fetchClicksByUrl({
        project,
        token,
        ...dateRangeFilter,
        includeFilterDimensions,
        abortSignal: abortFetchClicksByUrlController.signal,
      }),
      actionName: 'FETCH_CLICKS_BY_URL',
    })
  }
