// @flow

import Immutable, { type List, type RecordFactory, type RecordOf } from 'immutable'

import { type CappingRuleRecord } from 'com.batch/capping/model/capping-rule'

type LabelProps = {
  description: string,
  code: string,
  orchestrationCount: number,
  isMutating: boolean,
  isDeleted?: boolean,
  cappingRules: List<CappingRuleRecord>,
  ...
}

export type LabelRecord = RecordOf<LabelProps>

export const LabelFactory: RecordFactory<LabelProps> = Immutable.Record(
  ({
    description: '',
    code: '',
    orchestrationCount: 0,
    isMutating: false,
    isDeleted: false,
    cappingRules: new Immutable.List(),
  }: LabelProps)
)
