// @flow
import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchBoundFn } from 'com.batch.redux/_records'

import { fetchTemplates } from 'com.batch/message/usecases/fetch-templates'

export type UpdatePushTemplateAction = {
  type: 'UPDATE_PUSH_TEMPLATE',
  payload: {
    messageId: string,
    lang: string,
    content: {
      pushBody?: string,
      pushTitle?: string,
      pushPicture?: string,
      pushIcon?: string,
    },
  },
}

export const updatePushTemplate = ({
  messageId,
  lang,
}: {
  messageId: string,
  lang: string,
}): DispatchBoundFn<UpdatePushTemplateAction> => {
  return (dispatch, getState) => {
    const state = getState()
    const push = state.message.push.getIn([messageId, 'localizedContent', lang])

    const action: UpdatePushTemplateAction = {
      type: 'UPDATE_PUSH_TEMPLATE',
      payload: {
        messageId,
        lang,
        content: {},
      },
    }

    if (push) {
      const contentsToTemplate = []
      const { content } = push

      if (textUsesTemplating(content.get('pushBody'))) {
        contentsToTemplate.push({
          messageId,
          lang,
          parent: 'content',
          field: 'pushBody',
          value: content.get('pushBody') ?? '',
          type: 'TEMPLATE',
        })
      }
      if (textUsesTemplating(content.get('pushTitle'))) {
        contentsToTemplate.push({
          messageId,
          lang,
          parent: 'content',
          field: 'pushTitle',
          value: content.get('pushTitle') ?? '',
          type: 'TEMPLATE',
        })
      }
      if (textUsesTemplating(content.get('pushPicture'))) {
        contentsToTemplate.push({
          messageId,
          lang,
          parent: 'content',
          field: 'pushPicture',
          value: content.get('pushPicture') ?? '',
          type: 'TEMPLATE',
        })
      }
      if (textUsesTemplating(content.get('pushIcon'))) {
        contentsToTemplate.push({
          messageId,
          lang,
          parent: 'content',
          field: 'pushIcon',
          value: content.get('pushIcon') ?? '',
          type: 'TEMPLATE',
        })
      }

      if (contentsToTemplate.length === 0) {
        return dispatch(action)
      }
      return dispatch(fetchTemplates(contentsToTemplate))
        .then(res => {
          res.forEach(({ field, parent, template }) => {
            action.payload[parent][field] = template
          })
          dispatch(action)
        })
        .catch(e => {
          console.error(
            'Error while fetching templates:\n',
            e.error?.errors?.[0]?.message ?? 'Unknown error'
          )
        })
    } else {
      return dispatch(action)
    }
  }
}
