/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { NameCountOptionFormater } from 'components/campaign/campaign-list-utils'
import { Box, BoxBody, HeaderBoxTitle, HeaderBoxActions, BoxHeader } from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { Wrapper, SettingsEmptyIcon, GlobalErrorOverlayProps } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import { TableFooter } from 'components/common/table/table.styles'
import { FilterSelectMulti, FilterSearch } from 'components/filter'
import { Title } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'
import { getPlatformDoc } from 'com.batch.common/utils'

import { GdprData } from './gdpr-data'
import { GdprList } from './gdpr-list'
import { GDPRNew } from './gdpr-new'
import { type GDPRrequest, type urlParams } from './gdpr.types'

import { type AppRecord } from 'com.batch.redux/_records'
import { listGDPRrequests, sendGDPRrequest } from 'com.batch.redux/app.api'
import { fetchGDPR } from 'com.batch.redux/stat.api.analytics'
import { type GDPRByDayRecord } from 'com.batch.redux/stat.records'
import { showToastLegacy, showToast } from 'com.batch.redux/toaster'

type GDPRAppProps = {
  app: AppRecord,
  newPopinOpened: boolean,
  ...
}

type originFilter = 'dashboard' | 'api'
type OriginOption = { name: string, value: originFilter, count: number }
const optionToString = (opt: ?OriginOption) => opt?.name ?? ''

export const GDPRApp = ({ app, newPopinOpened }: GDPRAppProps): React.Node => {
  // ====================== LOCAL STATE
  const [requests, setRequests] = React.useState<Array<GDPRrequest>>([])
  const [loading, setLoading] = React.useState(false)
  const [dataLoading, setDataLoading] = React.useState(false)
  const [data, setData] = React.useState<List<GDPRByDayRecord>>(new Immutable.List())
  const [hasData, setHasData] = React.useState(false)
  const [errorRequests, setErrorRequests] = React.useState(false)
  const [errorData, setErrorData] = React.useState(false)

  // ====================== URL DERIVED STATE
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location])
  const qsSortBy = queryParams.get('sortBy') ?? 'request_date'
  const qsSortOrder = queryParams.get('sortOrder') ?? 'dsc'
  const qsPage = queryParams.has('page') ? parseInt(queryParams.get('page')) : 1
  const qsQ = queryParams.get('q') ?? ''
  const qsFilter = queryParams.get('filter') ?? ''
  const filterQueryList = qsFilter.split(',')
  const qsFrom =
    queryParams.get('from') ?? dayjs.utc().endOf('day').subtract(30, 'day').format('YYYY-MM-DD')
  const qsTo = queryParams.get('to') ?? dayjs.utc().endOf('day').format('YYYY-MM-DD')

  const OriginOptions: Immutable.List<OriginOption> = React.useMemo(
    () =>
      new Immutable.List().push(
        ...[
          {
            name: 'Dashboard',
            value: 'dashboard',
            count: requests.filter(q => q.trigger_type === 'dashboard').length,
          },
          {
            name: 'API',
            value: 'api',
            count: requests.filter(q => q.trigger_type === 'api').length,
          },
        ]
      ),
    [requests]
  )

  const originsValue = React.useMemo(
    () => OriginOptions.filter(elm => filterQueryList.includes(elm.value)),
    [OriginOptions, filterQueryList]
  )

  const filteredRequests = React.useMemo(() => {
    return requests
      .filter(
        req =>
          (qsFilter.length === 0 || qsFilter.includes(req.trigger_type ?? '')) &&
          (qsQ === '' || req.id.value.indexOf(qsQ) !== -1 || req.request_id.indexOf(qsQ) !== -1)
      )
      .sort((a, b) => {
        const s = qsSortOrder === 'asc' ? 1 : -1
        switch (qsSortBy) {
          case 'status': {
            const aScore = a.error ? 0 : a.status === 'pending' ? 1 : 2
            const bScore = b.error ? 0 : b.status === 'pending' ? 1 : 2
            return aScore > bScore ? s : -s
          }
          case 'request_type':
            return a.request_type > b.request_type ? -s : s
          default:
            return a.request_date.isAfter(b.request_date) ? s : -s
        }
      })
  }, [requests, qsFilter, qsQ, qsSortOrder, qsSortBy])

  const pagedAndFilteredRequests = filteredRequests.slice((qsPage - 1) * 10, qsPage * 10)

  // empty state constants
  const requestsEmpty = React.useMemo(() => requests.length === 0, [requests])
  const requestsTableIsEmpty = React.useMemo(
    () => requestsEmpty && !errorRequests,
    [requestsEmpty, errorRequests]
  )

  // loading
  const isLoading = React.useMemo(() => loading || dataLoading, [loading, dataLoading])

  // error
  const bothErrors = React.useMemo(() => errorRequests && errorData, [errorRequests, errorData])

  // ====================== CALLBACKS
  const refreshRequests = React.useCallback(() => {
    setLoading(true)
    listGDPRrequests({ app }).then(data => {
      setLoading(false)

      if (data.error) setErrorRequests(true)
      else {
        setRequests(data)
      }
    })
  }, [app])

  const handleSubmit = React.useCallback(
    (
      idType: 'installation_id' | 'custom_id' | 'advertising_id',
      isErasure: boolean,
      value: string
    ) => {
      return sendGDPRrequest({
        app,
        mode: idType,
        req: isErasure ? 'remove' : 'review',
        value,
      }).then(
        () => {
          dispatch(showToast({ kind: 'success', message: 'Privacy request created' }))
          refreshRequests()
        },
        err => {
          dispatch(showToastLegacy(`Unable to create request: ${err.body}`))
        }
      )
    },
    [app, dispatch, refreshRequests]
  )

  const buildSearch = React.useCallback(
    ({ q, page, sortBy, sortOrder, filter, from, to }: urlParams) =>
      `page=${page ?? qsPage}&q=${q ?? qsQ}&sortBy=${sortBy ?? qsSortBy}&sortOrder=${
        sortOrder ?? qsSortOrder
      }&filter=${filter ?? qsFilter}&from=${from ?? qsFrom}&to=${to ?? qsTo}`,
    [qsFilter, qsFrom, qsPage, qsQ, qsSortBy, qsSortOrder, qsTo]
  )

  const updateUrl = React.useCallback(
    (props: urlParams) => {
      navigate({
        search: buildSearch(props),
      })
    },
    [buildSearch, navigate]
  )

  const onFormatFilterChange = React.useCallback(
    opt => {
      const optList = opt ? opt.map(e => e.value).join() : ''
      updateUrl({ filter: optList })
    },
    [updateUrl]
  )

  const originTerm = React.useMemo(
    () => count => (count === 1 ? originsValue.first().name : `${count} formats`),
    [originsValue]
  )

  // ====================== EFFECTS
  // initial load (api back)
  React.useEffect(() => {
    if (!errorRequests) refreshRequests()
  }, [refreshRequests, errorRequests])

  // data load (steph)
  React.useEffect(() => {
    if (!errorData) {
      setDataLoading(true)
      fetchGDPR({
        app,
        start: dayjs(qsFrom, 'YYYY-MM-DD').endOf('day'),
        end: dayjs(qsTo, 'YYYY-MM-DD').endOf('day'),
      }).then(
        d => {
          setDataLoading(false)
          if (d.error) setErrorData(true)
          else {
            setHasData(d.hasData)
            setData(d.data)
          }
        },
        () => {
          setErrorData(true)
          setDataLoading(false)
        }
      )
    }
  }, [app, qsFrom, qsTo, errorData])
  const showEmptyState = React.useMemo(
    () => requestsTableIsEmpty && !hasData,
    [requestsTableIsEmpty, hasData]
  )

  const isAllowedToMakeNewRequest = useIsCurrentUserAllowedTo(['app', 'gdpr:write'])
  // ====================== RENDER
  return (
    <Wrapper
      isEmpty={showEmptyState}
      isLoading={isLoading}
      isOverlayShown={!isLoading && showEmptyState}
      overlayProps={
        bothErrors
          ? GlobalErrorOverlayProps
          : {
              status: 'empty-page',
              title: 'No request sent yet',
              description: (
                <React.Fragment>
                  <p style={{ marginBottom: 10 }}>
                    This page provides a way to let you handle GDPR Data Access and Data Removal
                    requests easily.
                  </p>
                  <PermissionButton
                    intent="action"
                    kind="primary"
                    to={`${generateUrl('app_settings_gdpr_new', {
                      appId: app.id,
                      companyId: app.companyId,
                    })}?${buildSearch({})}`}
                    isAllowed={isAllowedToMakeNewRequest}
                  >
                    New request
                  </PermissionButton>
                </React.Fragment>
              ),
              content: <SettingsEmptyIcon />,
              links: [
                {
                  name: 'Privacy center',
                  href: 'https://doc.batch.com/dashboard/settings/app-settings#privacy-center',
                },
                {
                  name: 'Install the SDK',
                  href: `https://doc.batch.com/${getPlatformDoc(app.platform)}/prerequisites`,
                },
              ],
            }
      }
    >
      {newPopinOpened && <GDPRNew app={app} handleSubmit={handleSubmit} />}
      <Grid template={'1fr auto'} style={{ marginBottom: 38 }}>
        <Title overEmptyState style={{ marginBottom: 0 }}>
          Privacy center
        </Title>
        <PermissionButton
          intent="action"
          kind="primary"
          addOn="prefix"
          to={`${generateUrl('app_settings_gdpr_new', {
            appId: app.id,
            companyId: app.companyId,
          })}?${buildSearch({})}`}
          isAllowed={isAllowedToMakeNewRequest}
          data-testid="btn-gdpr-new-request"
        >
          <Icon icon="add" />
          New request
        </PermissionButton>
      </Grid>

      <GdprData
        hasData={hasData}
        data={data}
        updateUrl={updateUrl}
        from={qsFrom}
        to={qsTo}
        error={errorData}
      />

      <div style={{ minHeight: 600 }}>
        <Box>
          <Wrapper
            isEmpty={(!isLoading && !bothErrors && errorRequests) || showEmptyState}
            isLoading={isLoading}
            isOverlayShown={!isLoading && !bothErrors && errorRequests}
            overlayProps={{
              status: 'error',
              title: 'Something went wrong',
              refresh: () => window.location.reload(),
            }}
          >
            <BoxHeader>
              <HeaderBoxTitle
                title="Requests"
                suffix={
                  <em
                    style={{
                      fontWeight: 400,
                      whiteSpace: 'nowrap',
                      minWidth: 0,
                      paddingLeft: 10,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: 14,
                    }}
                  >
                    From API and dashboard only
                  </em>
                }
              />
              <HeaderBoxActions>
                <FilterSearch
                  identifier="requests"
                  value={qsQ}
                  onChange={v => updateUrl({ q: v })}
                  disabled={isLoading}
                  expandedMaxWidth={280}
                />

                <FilterSelectMulti
                  isSearchable={false}
                  value={originsValue}
                  onChange={onFormatFilterChange}
                  placeholder="Any origin"
                  options={OriginOptions}
                  optionToString={optionToString}
                  optionFormatter={NameCountOptionFormater}
                  isDisabled={isLoading}
                  term={originTerm}
                />
              </HeaderBoxActions>
            </BoxHeader>
            <BoxBody>
              <GdprList
                filteredRequests={pagedAndFilteredRequests}
                platform={app.platform}
                sortBy={qsSortBy}
                sortOrder={qsSortOrder}
                updateUrl={updateUrl}
              />
            </BoxBody>
            {filteredRequests.length > 10 && (
              <TableFooter>
                <Pager
                  page={qsPage}
                  total={filteredRequests.length}
                  nbPerPage={10}
                  selectPage={page => updateUrl({ page })}
                />
              </TableFooter>
            )}
          </Wrapper>
        </Box>
      </div>
    </Wrapper>
  )
}
