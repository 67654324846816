/* eslint-disable react/jsx-no-bind */
// @flow

import { type OrderedMap } from 'immutable'
/* 
  la signature n'est pas la même entre lodash et lodash-es, si on importe lodash-es il prend des types qui ne fonctionne pas. 
  on remplace de toutes façons lodash par lodash-es pour les peer deps dans webpack.config, donc ça change rien de laisser cet import
*/
import { map as _map, curry as _curry } from 'lodash'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { Box, BoxHeader, HeaderBoxActions, BoxSection, BoxFooter } from 'components/common/box'
import { Button, useBlurOnClickCallback } from 'components/common/button'
import { EmptyField, Skeleton } from 'components/common/empty-states'
import Hint from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Copy } from 'components/form'
import { ActionsGroup } from 'components/styled/blocs'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'
import { ucFirst, coordinatesToDms } from 'com.batch.common/utils'

import AttrTree from './da-tree'
import { DebugTrigger, type DebugTriggerResult } from './debug-trigger'
import {
  SearchAttribute,
  SearchAttributeClear,
  AttributeArea,
  InstallSummary,
  InstallArea,
  InstallLabel,
  InstallRow,
  InstallValue,
  InstallRowSeparator,
  InstallReachLabel,
  InstallReach,
} from './debug.style'

import { type AppRecord } from 'com.batch.redux/_records'
import { type DebugInstallRecord } from 'com.batch.redux/stat.records'

type DebugInstallAttribute = {
  label: string,
  value?: number | string | Array<string> | null | number,
  items: Array<DebugInstallAttribute>,
  isArray: boolean,
}

type DebugInstallProps = {
  user: DebugInstallRecord,
  startOpen: boolean,
  app: AppRecord,
  refresh: (installId: string) => void,
}
const hideWhenNull = ['b.device_category', 'b.os_model']
const since = (when: any | void) => {
  if (typeof when === 'undefined' || !dayjs.isDayjs(when)) {
    return ''
  }
  return dayjs
    .duration(parseInt(when.format('x')) - parseInt(dayjs().utc().format('x')))
    .humanize(true)
}

const colls = [
  { label: 'Native attributes', prefix: ['b.', 'geo_'], defaultIsOpen: false },
  { label: 'Custom attributes', prefix: ['c.'], defaultIsOpen: true },
  { label: 'Tags', prefix: ['t.'], defaultIsOpen: false },
  { label: 'Custom events', prefix: ['e.'], defaultIsOpen: true },
  { label: 'User attributes', prefix: ['u.'], defaultIsOpen: true },
  { label: 'User tag collections', prefix: ['ut.'], defaultIsOpen: false },
]

const reduceGeoAttributes = (
  allAttributes: OrderedMap<string, any>,
  attributes: OrderedMap<string, any>,
  [label, rawValue]: [string, any]
): OrderedMap<string, any> => {
  if (label.indexOf('geo_') === 0) {
    if (label === 'geo_latitude') {
      const latitude = rawValue
      const longitude = allAttributes.toArray().find(([label]) => label === 'geo_longitude')

      if (longitude) {
        attributes = attributes.concat([
          ['last_location', coordinatesToDms(latitude, longitude[1])],
        ])
      }
    }
  } else {
    attributes = attributes.concat([[label, rawValue]])
  }

  return attributes
}

const buildItem = (label: string, rawValue: string | { ... } | number) => {
  let items = []
  let value = null
  let isArray = false
  if (typeof rawValue === 'object') {
    if (dayjs.isDayjs(rawValue)) {
      value = rawValue
    } else if (Array.isArray(rawValue)) {
      isArray = true
      items = rawValue.map(av => {
        if (typeof av === 'string' || typeof av === 'number') {
          return buildItem('', av)
          // eslint-disable-next-line no-prototype-builtins
        } else if (av.hasOwnProperty('tag')) {
          return buildItem('', av.tag)
        } else {
          return buildItem(av.label, av.value)
        }
      })
    } else {
      // $FlowFixMe rewrite this unreadable mess
      items = _map(rawValue, (ov, key) => buildItem(key, ov))
    }
  } else {
    value = rawValue
  }

  return {
    label,
    value,
    items,
    isArray,
  }
}

const filterAttr = (
  items: Array<DebugInstallAttribute>,
  term: string
): Array<DebugInstallAttribute> => {
  if (!term) {
    return items.filter(i => i.value !== null || !hideWhenNull.includes(i.label))
  }
  return items.filter(item => {
    if (hideWhenNull.includes(item.label) && item.value === null) return false
    if (!item.isArray && item.label === '') {
      return item.value && item.value.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1
    } else if (item.items.length === 0) {
      return item.label && item.label.toLowerCase().indexOf(term.toLowerCase()) !== -1
    } else {
      const childItems = filterAttr(item.items, term)
      return childItems.length > 0
    }
  })
}

const filterCollections = (
  term: string,
  collections: Array<{
    data: Array<DebugInstallAttribute>,
    defaultIsOpen: boolean,
    label: string,
    prefix: Array<string>,
  }>
) => {
  return collections.map(coll => {
    return { ...coll, data: filterAttr(coll.data, term) }
  })
}

const DebugInstallRaw = ({ startOpen, user, app, refresh }: DebugInstallProps) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [open, setOpen] = React.useState(startOpen)
  const [debugTrigger, setDebugTrigger] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [refreshTriggerCounter, setRefreshTriggerCounter] = React.useState(0)
  const [results, setResults] = React.useState<Array<DebugTriggerResult>>([])

  const hasTriggerFeature =
    app.platform === 'webpush'
      ? app.features.includes('webpush-trigger-campaigns')
      : app.features.includes('trigger-campaigns')

  React.useEffect(() => {
    if (refreshTriggerCounter > 0 && hasTriggerFeature) {
      setLoading(true)
      fetch(
        generateUrl('api_data_user_debug_trigger', { appId: app.id, di: user.installationId })
      ).then(response => {
        setLoading(false)
        if (response.ok) {
          return response.json().then(function (data) {
            setResults(
              data
                .filter(d => d !== null)
                .sort((a, b) => (a.entered > b.entered ? -1 : 1))
                .map(d => {
                  d.entered = d.entered === null ? null : dayjs.unix(d.entered)
                  d.exited = d.exited === null ? null : dayjs.unix(d.exited)
                  return d
                })
            )
          })
        }
      })
    }
  }, [app.id, user.installationId, hasTriggerFeature, refreshTriggerCounter])

  const buildCollections = React.useCallback((attributes: OrderedMap<string, any>) => {
    return colls.map(coll => {
      return {
        ...coll,
        data: attributes
          .filter((attr, index) => {
            let found = false
            coll.prefix.forEach(p => {
              if (index.substring(0, p.length) === p) {
                found = true
              }
            })
            return found
          })
          .toArray() // $FlowFixMe
          .reduce(_curry(reduceGeoAttributes)(attributes), [])
          .map(indexAndAttr => buildItem(indexAndAttr[0], indexAndAttr[1]))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      }
    })
  }, [])

  const collections = React.useMemo(
    () => buildCollections(user.attributes),
    [user.attributes, buildCollections]
  )
  const filteredCollections = React.useMemo(
    () => filterCollections(searchTerm, collections),
    [searchTerm, collections]
  )

  const openBlur = useBlurOnClickCallback(() => {
    setOpen(!open)
  }, [open])

  const onRefreshClick = useBlurOnClickCallback(() => {
    refresh(user.installationId)
    setRefreshTriggerCounter(refreshTriggerCounter + 1)
  }, [refresh, refreshTriggerCounter, user.installationId])

  // I think loading doesnt work as expected in the box.
  const RefreshButton = (
    <Button kind="inline" style={{ width: 35 }} disabled={!open} onClick={onRefreshClick}>
      <Icon icon={user.loading ? 'reload-animated' : 'reload'} />
    </Button>
  )

  // empty state constants
  const theme = useTheme()
  const emptyContent = React.useMemo(() => theme && (theme.isEmpty || theme.isLoading), [theme])

  const toggleOnBlur = useBlurOnClickCallback(() => {
    setOpen(!open)
  }, [open])

  const getCarrierCode = React.useCallback(() => {
    const carrierCode = user.prettifiedValues.get('b.carrier_code', '')

    // 6553565535 is a dummy value since Apple killed the Carrier feature
    return carrierCode === 6553565535 ? '' : ` on ${carrierCode}`
  }, [user.prettifiedValues])

  return (
    <Box style={{ overflow: 'hidden' }}>
      <BoxHeader style={{ border: 'none' }}>
        <Skeleton w={518} h={18}>
          <Button
            kind="discreet"
            style={{ fontSize: 16, width: '100%', justifyContent: 'left' }}
            onClick={toggleOnBlur}
          >
            {app.platform === 'webpush' ? (
              <span>
                {user.attributes.get('b.browser_model', user.attributes.get('b.browser'))}
              </span>
            ) : (
              <span>
                {app.platform === 'ios' && user.prettifiedValues.get('b.device_type')}
                {app.platform !== 'ios' && !!user.attributes.get('b.device_brand', false) && (
                  <React.Fragment>
                    {ucFirst(user.attributes.get('b.device_brand', ''))}{' '}
                    {user.prettifiedValues.get('b.device_type')}
                  </React.Fragment>
                )}
                {!!user.attributes.get('b.carrier_code', false) && `${getCarrierCode()}`}
              </span>
            )}
            {user.attributes.get('b.install_date', false) && (
              <React.Fragment>
                <span style={{ color: '#858ea1', marginLeft: 10, marginRight: 10 }}> — </span>
                <span style={{ color: '#858ea1', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  Last start {since(user.attributes.get('b.last_visit_date'))}
                </span>
              </React.Fragment>
            )}
          </Button>
        </Skeleton>
        {emptyContent ? (
          <div>
            <EmptyField _width={21} _height={21} style={{ marginRight: 24 }} />
            <EmptyField _width={21} _height={21} style={{ marginLeft: 8 }} />
          </div>
        ) : (
          <HeaderBoxActions>
            {hasTriggerFeature && (
              <ActionsGroup>
                {emptyContent ? (
                  <React.Fragment>
                    <EmptyField _width={21} _height={21} style={{ marginRight: 24 }} />
                    <EmptyField _width={21} _height={21} style={{ marginLeft: 8 }} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      kind="inline"
                      disabled={!open}
                      isActive={debugTrigger === false && open}
                      onClick={() => setDebugTrigger(false)}
                    >
                      Data
                    </Button>
                    <Button
                      kind="inline"
                      disabled={!open}
                      isActive={debugTrigger === true && open}
                      style={{ marginLeft: 8 }}
                      onClick={() => {
                        setDebugTrigger(true)
                        if (refreshTriggerCounter === 0) {
                          setRefreshTriggerCounter(1)
                        }
                      }}
                    >
                      Trigger&nbsp;campaigns
                    </Button>
                  </React.Fragment>
                )}
              </ActionsGroup>
            )}
            <ActionsGroup>
              <span style={{ marginLeft: 8, marginRight: 8 }}>
                {open ? (
                  <Tooltip tooltip="Reload install data" minWidth={140}>
                    {RefreshButton}
                  </Tooltip>
                ) : (
                  RefreshButton
                )}
              </span>
              <Button style={{ width: 35 }} kind="inline" onClick={openBlur}>
                <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
              </Button>
            </ActionsGroup>
          </HeaderBoxActions>
        )}
      </BoxHeader>

      {open &&
        (debugTrigger ? (
          <DebugTrigger app={app} results={results} loading={loading} />
        ) : (
          <InstallArea>
            <InstallSummary>
              <BoxSection style={{ borderRight: 'none' }}>
                <InstallRow>
                  <InstallLabel>
                    <Skeleton w={50} h={14}>
                      Locale
                    </Skeleton>
                  </InstallLabel>
                  <InstallValue>
                    <Skeleton w={250} h={14}>
                      <React.Fragment>
                        {!!user.attributes.get('b.region', false) && (
                          <strong>
                            <img
                              src={`/medias/img/flags/${user.attributes
                                .get('b.region', 'xx')
                                .toLowerCase()}.png`}
                              style={{ margin: '-2px 8px 0 0px' }}
                              alt={`Flag of ${user.attributes.get('b.region') ?? 'World'}`}
                            />

                            {user.prettifiedValues.get('b.region')}
                          </strong>
                        )}{' '}
                        ({user.timezone}){' '}
                        {!!user.attributes.get('b.language', false) && (
                          <React.Fragment>
                            speaks <strong>{user.prettifiedValues.get('language_tag')}</strong>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    </Skeleton>
                  </InstallValue>
                </InstallRow>
                {app.platform !== 'webpush' && (
                  <InstallRow>
                    <InstallLabel>
                      <Skeleton w={86} h={14}>
                        Notifications
                      </Skeleton>
                    </InstallLabel>
                    <InstallValue>
                      <Skeleton w={153} h={14}>
                        {user.notificationStatus}
                      </Skeleton>
                    </InstallValue>
                  </InstallRow>
                )}
                <InstallRow>
                  <InstallLabel>
                    <Skeleton w={50} h={14}>
                      API KEY
                    </Skeleton>
                  </InstallLabel>
                  <InstallValue>
                    <Copy isSensitive value={user.apiKey} />
                  </InstallValue>
                </InstallRow>
                <InstallRow>
                  <InstallLabel>
                    <Skeleton w={127} h={14}>
                      <React.Fragment>
                        <a href={`debug?custom_id=${user.customId || ''}`} className="fs-exclude">
                          Custom User ID
                        </a>
                        <Hint>
                          Unique user identifier that you can set in Batch SDK to target a specific
                          user.
                        </Hint>
                      </React.Fragment>
                    </Skeleton>
                  </InstallLabel>
                  <InstallValue>
                    <Copy isSensitive value={user.customId || ''} />
                  </InstallValue>
                </InstallRow>
                <InstallRow>
                  <InstallLabel>
                    <Skeleton w={127} h={14}>
                      <React.Fragment>
                        <a
                          className="fs-exclude"
                          href={`debug?installation_id=${user.installationId}`}
                        >
                          Installation ID
                        </a>
                        <Hint>Unique installation identifier generated by Batch SDK.</Hint>
                      </React.Fragment>
                    </Skeleton>
                  </InstallLabel>
                  <InstallValue>
                    {user.installationId ? (
                      <Copy isSensitive value={user.installationId} />
                    ) : (
                      "We don't have an installation for this user yet."
                    )}
                  </InstallValue>
                </InstallRow>
                <InstallRow>
                  <InstallLabel>
                    <Skeleton w={73} h={14}>
                      <React.Fragment>
                        Push token{' '}
                        {!!user.device && !user.device.distribution && app.platform === 'ios' && (
                          <span className="text-muted">(sandbox)</span>
                        )}
                      </React.Fragment>
                    </Skeleton>
                  </InstallLabel>
                  <InstallValue>
                    <Copy value={user.device ? user.device.value : ''} />
                  </InstallValue>
                </InstallRow>

                <InstallRow>
                  {app.platform === 'webpush' ? null : emptyContent ? (
                    <React.Fragment>
                      <InstallRowEmpty />
                      <InstallRowEmpty />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <InstallLabel stack>App version</InstallLabel>
                      <InstallValue
                        style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                      >
                        {user.attributes.get('b.app_version')}
                      </InstallValue>
                      <InstallRowSeparator />
                      <InstallLabel stack>OS version</InstallLabel>
                      <InstallValue
                        style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                      >
                        {user.attributes.get('b.os_version')}
                      </InstallValue>
                      <InstallRowSeparator />
                    </React.Fragment>
                  )}

                  {emptyContent ? (
                    <React.Fragment>
                      <InstallLabel stack>
                        <EmptyField _width={75} _height={14} />
                      </InstallLabel>
                      <InstallValue
                        style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                      >
                        <EmptyField _width={33} _height={14} />
                      </InstallValue>
                      <InstallRowSeparator />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <InstallLabel stack>SDK Version</InstallLabel>
                      <InstallValue
                        style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                      >
                        {user.attributes.get('batch_sdk_version')}
                      </InstallValue>
                    </React.Fragment>
                  )}
                </InstallRow>
              </BoxSection>

              <BoxFooter style={{ borderRight: 'none', height: 76, padding: '0 20px' }} isEditable>
                <div className="audience">
                  <InstallReachLabel className="audience__title">
                    <Skeleton w={59} h={12}>
                      Installed
                    </Skeleton>
                  </InstallReachLabel>
                  <div className="audience__reach">
                    <InstallReach>
                      <Skeleton w={129} h={23}>
                        {since(user.attributes.get('b.install_date'))}
                      </Skeleton>
                    </InstallReach>
                  </div>
                </div>
              </BoxFooter>
            </InstallSummary>
            <BoxSection
              dark
              style={{
                flex: '1 0 400px',
                borderRadius: '0 0 5px 0',
                marginRight: -1,
                marginTop: -1,
                border: 'none',
              }}
            >
              <AttributeArea>
                {!emptyContent && (
                  <React.Fragment>
                    <SearchAttribute>
                      <Icon
                        icon="search"
                        size={11}
                        style={{ color: '#ffffff', margin: '0 12px 0 4px' }}
                      />
                      <input
                        placeholder="Search from attributes or events...."
                        type="text"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={searchTerm}
                        onChange={evt => setSearchTerm(evt.target.value)}
                      />
                      {!!searchTerm && (
                        <SearchAttributeClear onClick={() => setSearchTerm('')}>
                          <Icon icon="close" color="#FFF" size={7} />
                        </SearchAttributeClear>
                      )}
                    </SearchAttribute>
                    {filteredCollections.map((c, index) => {
                      return (
                        collections[index].data.length > 0 && (
                          <AttrTree
                            label={c.label}
                            key={index}
                            defaultIsOpen={c.defaultIsOpen}
                            level={0}
                            searchTerm={searchTerm}
                            items={c.data}
                            isArray={false}
                            matchAbove={false}
                            value={null}
                          />
                        )
                      )
                    })}
                  </React.Fragment>
                )}
              </AttributeArea>
            </BoxSection>
          </InstallArea>
        ))}
    </Box>
  )
}

const InstallRowEmpty: React.ComponentType<{}> = React.memo<{}>((): React.Node => {
  return (
    <React.Fragment>
      <InstallLabel stack>
        <EmptyField _width={75} _height={14} />
      </InstallLabel>
      <InstallValue style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <EmptyField _width={33} _height={14} />
      </InstallValue>
      <InstallRowSeparator />
    </React.Fragment>
  )
})

export const DebugInstall: React.ComponentType<DebugInstallProps> =
  React.memo<DebugInstallProps>(DebugInstallRaw)
