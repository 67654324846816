// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { AvatarPlatform } from 'components/common/avatar.styles'
import {
  BoxHeader,
  HeaderBoxTitle,
  BoxBody,
  BoxFooter,
  BoxSearch,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Checkbox } from 'components/form'

import { PushTesterModal } from './push-tester.styles'

import { AppFactory, type TestDeviceRecord } from 'com.batch.redux/_records'
import { currentCampaignAppsSelector } from 'com.batch.redux/campaign.selector'
import { listDevices, sendTestForCampaign, devicesSelector } from 'com.batch.redux/testDevice'

type PushTesterPopinProps = {
  close: () => void,
  search: string,
  setSearch: string => void,
  selectedDevices: List<TestDeviceRecord>,
  setSelectedDevices: (List<TestDeviceRecord>) => void,
  ...
}

export const PushTesterPopin = ({
  close,
  search,
  setSearch,
  selectedDevices,
  setSelectedDevices,
}: PushTesterPopinProps): React.Node => {
  const dispatch = useDispatch()

  const apps = useSelector(currentCampaignAppsSelector)

  const send = React.useCallback(() => {
    selectedDevices.forEach(device => {
      const app = apps.find(app => app.id === device.appId)
      if (app) dispatch(sendTestForCampaign({ device, forcedApp: app }))
    })
    close()
  }, [apps, close, dispatch, selectedDevices])

  React.useEffect(() => {
    dispatch(listDevices(apps))
  }, [apps, dispatch])

  const $search = React.useRef<*>()
  React.useEffect(() => {
    if ($search.current) $search.current.focus()
  })

  const devices = useSelector(devicesSelector)
  const list = React.useMemo(
    () =>
      devices
        .filter(device => apps.map(app => app.id).has(device.appId))
        .filter(
          device => search === '' || device.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        )
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
    [apps, devices, search]
  )

  const createOnDeviceSelectedCallback = React.useCallback(
    (d: TestDeviceRecord) => () => {
      const index = selectedDevices.findIndex(device => d.id === device.id)
      setSelectedDevices(index !== -1 ? selectedDevices.remove(index) : selectedDevices.push(d))
    },
    [selectedDevices, setSelectedDevices]
  )
  return (
    <Popin opened close={close} style={{ width: 700 }}>
      <PushTesterModal>
        <BoxHeader large>
          <HeaderBoxTitle
            title="Send a push test"
            detail="Preview your push on 1 or more of your test devices"
          />
          <HeaderBoxActions large>
            <Button onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody
          style={{
            flex: '1 1 300px',
            maxHeight: 300,
            overflowY: 'auto',
            borderRadius: 0,
          }}
        >
          <BoxSearch
            placeholder="Search a device"
            value={search}
            onChange={setSearch}
            ref={$search}
          />

          <div style={{ marginTop: 8, marginBottom: 8 }}>
            {list.map(d => {
              const app = apps.find(app => app.id === d.appId, AppFactory())
              const active = selectedDevices.includes(d)
              return (
                <div style={{ marginLeft: 8, marginRight: 8, marginTop: 8 }} key={d.id}>
                  <Button
                    addOn="suffix"
                    addOnGap={22}
                    kind="inline"
                    style={{ width: '100%', textAlign: 'left', height: '44px' }}
                    onClick={createOnDeviceSelectedCallback(d)}
                  >
                    <span>
                      <Checkbox
                        checked={active}
                        style={{ pointerEvents: 'none', marginRight: 12 }}
                        ariaLabelledBy={`device-${d.id}`}
                      />
                      <span className="styled-name" id={`device-${d.id}`}>
                        {d.name}
                      </span>
                    </span>
                    <AvatarPlatform icon={app.platform} size={15} />
                  </Button>
                </div>
              )
            })}
          </div>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" onClick={close}>
            Cancel
          </Button>
          <Button
            kind="primary"
            intent="action"
            onClick={send}
            disabled={selectedDevices.size === 0}
          >
            {selectedDevices.size < 2 ? 'Send test' : `Send ${selectedDevices.size} tests`}
          </Button>
        </BoxFooter>
      </PushTesterModal>
    </Popin>
  )
}
