// @flow
import styled, { type StyledComponent } from 'styled-components'

import { EventRowContentSkeleton } from 'com.batch/profile/ui/components/events-block/event-row/event-row.styles'

export const EventListContainer: StyledComponent<*, *, *> = styled.div`
    min-height: 224px;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .events {
        padding: 8px 0;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            height: 8px;
            background: rgb(225, 225, 225);
            width: 1.5px;
            top: 0;
            left: 90.5px;
        }

        &::after {
            content: '';
            position: absolute;
            height: 8px;
            background: rgb(225, 225, 225);
            width: 1.5px;
            bottom: 0;
            left: 90.5px;
        }
    }

    ${EventRowContentSkeleton}:first-child {
        padding-top: 10px;

        &::before {
            content: '';
            position: absolute;
            height: 12px;
            background: rgb(225, 225, 225);
            width: 1.5px;
            top: 0;
            left: 73.5px;
        }
    }

}
`
