// @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { Button } from 'components/common/button'
import { Sticky } from 'components/common/scroll'

import { DateRangeFilter } from './date-range-filter'
import { FiltersContainer } from './filters.styles'
import { LanguageFilter } from './language-filter'
import { StepFilter } from './step-filter'

import { globallyConfiguredLanguagesSelector } from 'com.batch/message/store/message.selector'

import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import {
  updateAnalyticsFilters,
  updateAnalyticsDateRange,
} from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'

type FiltersProps = {
  showStepFilter: boolean,
}

export const Filters = ({ showStepFilter }: FiltersProps): React.Node => {
  const dispatch = useDispatch()
  const { token }: { token: string } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const { filters, dateRange } = useGetAnalyticsFilters()
  const configuredLanguages = useSelector(globallyConfiguredLanguagesSelector)

  const clearFilters = React.useCallback(() => {
    dispatch(updateAnalyticsFilters({ token, filters: Immutable.Map() }))
    dispatch(updateAnalyticsDateRange({ token, dateRange: null }))
  }, [dispatch, token])

  const [isStuck, setIsStuck] = React.useState(false)

  const availableFiltersCount = React.useMemo(() => {
    let count = 0
    if (schedulingType !== 'campaigns') count++
    if (showStepFilter) count++
    if (configuredLanguages.size > 1) count++

    return count
  }, [configuredLanguages.size, schedulingType, showStepFilter])

  return (
    <ThemeProvider theme={{ kind: 'filter' }}>
      <Sticky onStickyChange={setIsStuck}>
        <FiltersContainer $isStuck={isStuck}>
          {schedulingType !== 'campaigns' && <DateRangeFilter />}
          <div style={{ display: 'grid', gridAutoFlow: 'column', gap: 5, flexGrow: 1 }}>
            {showStepFilter && <StepFilter />}
            {configuredLanguages.size > 1 && <LanguageFilter />}
          </div>
          {availableFiltersCount > 1 && (
            <Button
              onClick={clearFilters}
              disabled={filters.size === 0 && !dateRange}
              style={{ flexShrink: 0 }}
              data-track="analytics-filter-clear"
            >
              Clear filters
            </Button>
          )}
        </FiltersContainer>
      </Sticky>
    </ThemeProvider>
  )
}
