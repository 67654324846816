// @flow
import { scaleBand, scaleLinear } from 'd3-scale'
import { stack, stackOrderNone } from 'd3-shape'
import { type Dayjs } from 'dayjs'
import * as React from 'react'

import { useWidthObserver } from 'components/_hooks/use-resize-observer'
import { schemes } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { Bar } from './bar'
import { BarChartContext } from './bar-chart.provider'
import { BarChartSvg } from './bar-chart.styles'
import { ScaleLine } from './scale-line'
import { Timeline } from './timeline'

import {
  type BarChartData,
  type Group,
  type BarData,
  type FullRangeBarChartData,
} from 'com.batch/shared/infra/types/chart-data'
import { StripePattern } from 'com.batch/shared/ui/component/charts/donut-chart/patterns/stripe-pattern'

type Props = {
  data: Array<BarChartData>,
  groups: Array<Group>,
  barPadding?: number,
  barMinHeight?: number,
  dateFormat?: string,
  disabledBarHeight?: number,
  disabledBarColor?: string,
  height?: number,
  scaleLineKind?: 'none' | 'onTheMaxBar' | 'overTheMaxBar' | 'underTheMaxBar',
  scaleLineNumber?: number,
  showTimeline?: boolean,
  timelineLabelNumber?: number,
  timelineRenameDates?: Array<{ date: Dayjs, name: string }>,
  timelineCenteredEnds?: boolean,
  disableAfterDay?: Dayjs,
}

export const BarChart = ({
  barMinHeight = 10,
  barPadding = 0.3,
  data,
  dateFormat = 'DD MMM',
  disabledBarHeight = 50,
  disabledBarColor,
  groups = [],
  height = 200,
  scaleLineKind = 'onTheMaxBar',
  scaleLineNumber = 4,
  showTimeline = false,
  timelineLabelNumber,
  timelineRenameDates,
  timelineCenteredEnds = false,
  disableAfterDay,
}: Props): React.Node => {
  const { dateRange, hoveredDate, isLoading, isEmpty, setHoveredDate } =
    React.useContext(BarChartContext)

  const ref = React.useRef<any | HTMLElement>(null)
  const width = useWidthObserver(ref, 100)

  // DATAS
  const fullRangeBarChartData: Array<FullRangeBarChartData> = React.useMemo(() => {
    let emptyValue = {}
    groups.forEach(g => (emptyValue = { ...emptyValue, [g.name]: { value: 0, rate: 0 } }))

    return dateRange.map(date => {
      const baseData = {
        date: date,
        isDisabled: date.isAfter(disableAfterDay ?? dayjs(), 'day'),
      }
      const foundData = data.find(f => f.date.format(dateFormat) === date.format(dateFormat))
      return foundData ? { ...foundData, ...baseData } : { ...baseData, ...emptyValue }
    })
  }, [data, dateRange, groups, dateFormat, disableAfterDay])

  const stackSeries = stack()
    .keys(groups.map(g => g.name))
    .value((d: BarData, key) => {
      return d[key].value
    })
    .order(stackOrderNone)
  const series = stackSeries(fullRangeBarChartData.filter(f => !f.isDisabled))

  // SCALE LINE
  const scaleline = React.useMemo(() => {
    const nbParts = scaleLineNumber - 1

    let result = {
      nbLines: scaleLineNumber,
      nbParts,
      partHeight: height / nbParts,
      lineMax: scaleLineNumber - 1,
    }

    if (scaleLineKind === 'overTheMaxBar') result.lineMax = scaleLineNumber - 2
    if (scaleLineKind === 'underTheMaxBar') result.nbLines = scaleLineNumber - 1

    return result
  }, [scaleLineNumber, scaleLineKind, height])

  // MAX VALUE & HEIGHT
  const maxValue = React.useMemo(() => {
    // find the max value
    const sums = data.map(item => groups.reduce((acc, g) => acc + item[g.name].value, 0))
    const max = Math.max(...sums)
    // Rounded max value, we don't want decimal values on the scale line
    const roundedMax = Math.ceil(max / scaleline.lineMax) * scaleline.lineMax
    return roundedMax
  }, [data, groups, scaleline])

  const maxHeight = React.useMemo(() => scaleline.partHeight * scaleline.lineMax, [scaleline])

  // SCALES
  const xScale = React.useMemo(
    () =>
      scaleBand<String>()
        .domain(fullRangeBarChartData.map(d => d.date.format(dateFormat)))
        .range([0, width - (scaleLineKind === 'none' ? 0 : 50)]),
    [scaleLineKind, fullRangeBarChartData, width, dateFormat]
  )

  const yScale = React.useMemo(
    () => scaleLinear().domain([0, maxValue]).range([maxHeight, 0]),
    [maxValue, maxHeight]
  )

  // BARS SIZE, POSITION AND DESIGN
  const barWidth: number = React.useMemo(
    () => xScale.copy().padding(barPadding).bandwidth(),
    [xScale, barPadding]
  )

  const hasBarsStiped = React.useMemo(() => groups.some(g => g.isStriped === true), [groups])

  const getBarScale = React.useCallback(
    (s, index) => {
      const start = s[0]
      const end = s[1]

      const xSizes = {
        x: xScale(s.data.date.format(dateFormat)) + xScale.bandwidth() / 2 - barWidth / 2,
        width: barWidth,
      }

      // MANAGE MIN HEIGHT
      const heighestSerieY = series.map(serie => serie[index][1])[series.length - 1]

      if (maxHeight - yScale(heighestSerieY) < barMinHeight) {
        const currentEnd = end === 0 ? end : (barMinHeight * end) / heighestSerieY
        const currentStart = start === 0 ? start : (barMinHeight * start) / heighestSerieY

        return {
          ...xSizes,
          y: maxHeight - currentEnd,
          height: currentEnd - currentStart,
        }
      }

      // DEFAULT
      return {
        ...xSizes,
        y: yScale(end),
        height: yScale(start) - yScale(end),
      }
    },
    [series, xScale, yScale, maxHeight, barMinHeight, barWidth, dateFormat]
  )

  const barsPosition = React.useMemo(() => {
    return `translate(0,${scaleLineKind === 'overTheMaxBar' ? maxHeight / scaleline.lineMax : 0})`
  }, [maxHeight, scaleLineKind, scaleline])

  // MULTI GROUP : FIND BAR POSITION
  const isFirstBarPart = React.useCallback(
    (serieIndex, index) => series[0][index][0] === series[serieIndex][index][0],
    [series]
  )
  const isLastBarPart = React.useCallback(
    (serieIndex, index) => series[series.length - 1][index][1] === series[serieIndex][index][1],
    [series]
  )

  const onBarHover = React.useCallback(
    (date: Dayjs | null) => () => setHoveredDate && setHoveredDate(date),
    [setHoveredDate]
  )

  return (
    <React.Fragment>
      <BarChartSvg ref={ref} height={height}>
        {hasBarsStiped && (
          <defs>
            <StripePattern />
          </defs>
        )}

        {scaleLineKind !== 'none' && (
          <ScaleLine
            nbLines={scaleline.nbLines}
            nbParts={scaleline.nbParts}
            lineMax={scaleline.lineMax}
            width={width}
            height={height}
            max={maxValue}
            isEmpty={isEmpty}
            isLoading={isLoading}
          />
        )}

        <g transform={barsPosition}>
          {series.map((serie, sIndex) =>
            serie.map((s, index) => {
              const barScale = getBarScale(s, index)
              return (
                <Bar
                  key={`scale_line_${index}`}
                  color={groups[sIndex].color}
                  currentDate={s.data.date}
                  dateFormat={dateFormat}
                  hasBorderRadius={{
                    bottom: isFirstBarPart(sIndex, index),
                    top: isLastBarPart(sIndex, index),
                  }}
                  height={barScale.height}
                  hoveredDate={hoveredDate}
                  isEmpty={isEmpty}
                  isLoading={isLoading}
                  isStriped={groups[sIndex]?.isStriped ?? false}
                  width={barScale.width}
                  x={barScale.x}
                  y={barScale.y}
                />
              )
            })
          )}

          {fullRangeBarChartData
            .filter(f => f.isDisabled)
            .map((item, index) => (
              <Bar
                isLoading={isLoading}
                isEmpty={isEmpty}
                key={`hidden_label_${index}`}
                width={barWidth}
                height={disabledBarHeight}
                x={xScale(item.date.format(dateFormat))}
                y={maxHeight - disabledBarHeight}
                color={disabledBarColor ?? schemes.darklucent['20']}
                currentDate={item.date}
                hasBorderRadius={{
                  bottom: true,
                  top: true,
                }}
                dateFormat={dateFormat}
              />
            ))}
        </g>

        <g>
          {fullRangeBarChartData
            .filter(f => !f.isDisabled)
            .map((item, index) => (
              <rect
                key={index}
                x={xScale(item.date.format(dateFormat))}
                y={0}
                width={xScale.bandwidth()}
                height={height}
                fill="transparent"
                onMouseEnter={onBarHover(item.date)}
                onMouseLeave={onBarHover(null)}
              />
            ))}
        </g>
      </BarChartSvg>

      {showTimeline && (
        <Timeline
          isEmpty={isEmpty}
          isLoading={isLoading}
          dateFormat={dateFormat}
          fullRangeBarChartData={fullRangeBarChartData}
          hideDisabledLabel={true}
          hoveredDate={hoveredDate}
          numberLabels={timelineLabelNumber}
          setHoveredDate={setHoveredDate}
          width={width}
          xScale={xScale}
          renameDates={timelineRenameDates}
          centeredEnds={timelineCenteredEnds}
        />
      )}
    </React.Fragment>
  )
}
