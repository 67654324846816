// @flow

import * as React from 'react'

type ReactRef<T> = { current: T | null, ... }

export function useClickOutsideOnExistingRefs(
  callback: () => void,
  refs: Array<ReactRef<HTMLElement>>
): void {
  const mouseDownTarget = React.useRef<null | Node>(null)
  const onMouseDown = React.useCallback((event: Event) => {
    if (event.target && event.target instanceof Node) {
      mouseDownTarget.current = event.target
    }
  }, [])
  const onMouseUp = React.useCallback(
    (event: MouseEvent) => {
      const isMouseUpOuside =
        refs.filter(
          ref =>
            ref.current &&
            event.target instanceof Node &&
            !ref.current.contains(event.target) &&
            ref.current !== event.target
        ).length === refs.length

      const isMouseDownOuside =
        mouseDownTarget.current &&
        mouseDownTarget.current instanceof Node &&
        refs.filter(
          ref =>
            ref.current &&
            ref.current !== event.target &&
            !ref.current.contains(mouseDownTarget.current)
        ).length == refs.length
      if (isMouseUpOuside && isMouseDownOuside) {
        callback()
      }
    },
    [callback, mouseDownTarget, refs]
  )
  React.useEffect(() => {
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('mousedown', onMouseDown)
    return () => {
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mousedown', onMouseDown)
    }
  })
}

export function useClickOutside(callback: () => void): [ReactRef<HTMLElement>] {
  const ref = React.useRef<null | HTMLElement>(null)
  useClickOutsideOnExistingRefs(callback, [ref])
  return [ref]
}

export default useClickOutside
