// @flow

import * as React from 'react'

import { BoxHeader, HeaderBoxActions, HeaderBoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { stroke } from 'components/styled/tokens/colors'

import { CollapsePanel } from './collapse/collapse'

type AdvancedSettingsProps = {
  children: React.Node,
  campaignFormMode?: boolean,
  topShadow?: boolean,
  openDirection?: 'down' | 'up',
}

export const AdvancedSettingsPanel: React.ComponentType<AdvancedSettingsProps> = React.memo(
  ({ children, campaignFormMode, topShadow = true, openDirection = 'down' }) => {
    const [showAdvanced, setShowAdvanced] = React.useState(false)

    const onToggleAdvancedSettings = React.useCallback(() => {
      setShowAdvanced(prev => !prev)
    }, [])

    const chevronIcon = React.useMemo(() => {
      if (openDirection === 'up') return showAdvanced ? 'chevron-down' : 'chevron-up'
      else return showAdvanced ? 'chevron-up' : 'chevron-down'
    }, [openDirection, showAdvanced])

    return (
      <React.Fragment>
        <div
          style={{
            overflow: 'hidden',
            paddingTop: 10,
            marginTop: -10,
            flex: '0 0 62px',
            minHeight: 62,
          }}
        >
          <BoxHeader
            style={{
              cursor: 'pointer',
              height: 52,
              borderTop: '1px solid rgba(233,233,233,1)',
              borderRadius: campaignFormMode && !showAdvanced ? '0 0 8px 8px' : 0,
              boxShadow: topShadow
                ? '0px 1px 3px 0px rgba(15, 15, 15, 0.08), 0px 1px 8px 0px rgba(15, 15, 15, 0.02)'
                : 'none',
              borderBottom: showAdvanced ? `1px solid ${stroke}` : 'none',
            }}
            onClick={onToggleAdvancedSettings}
            data-testid="button_show_advanced_settings"
          >
            <HeaderBoxTitle title="Advanced settings" style={{ color: colors.text }} />
            <HeaderBoxActions>
              <Button style={{ height: 28, width: 28 }}>
                <Icon icon={chevronIcon} />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
        </div>
        <CollapsePanel
          isOpened={showAdvanced}
          scrollIntoView={true}
          id="collapse-advanced-settings"
        >
          {children}
        </CollapsePanel>
      </React.Fragment>
    )
  }
)
AdvancedSettingsPanel.displayName = 'AdvancedSettingsPanel'
