// @flow

import * as React from 'react'

import { Alert } from 'components/common/alert'
import Highlight from 'components/common/highlight'
import { Tabs, Tab } from 'components/common/tabbed'

type SetupFlutterProps = {
  platform: Platforms,
  hasDevApiKeyFeature: boolean,
  ...
}
const SetupFlutter = ({ platform, hasDevApiKeyFeature }: SetupFlutterProps): React.Node => {
  return (
    <React.Fragment>
      <h3>Installation</h3>
      <p>
        <i>batch_flutter requires Flutter 2.0 and Dart 2.12 or higher.</i>
      </p>

      <Highlight language="bash">{'flutter pub add batch_flutter'}</Highlight>

      <h3>Setting up the plugin</h3>

      <p>Batch requires that the native projects are modified to configure the plugin.</p>

      <p>
        This is required as Android and iOS might kill your applications process only to launch it
        at a later date to handle background tasks, such as displaying a notification. Integrating
        natively allows Batch to work even if the Flutter engine is not started.
      </p>

      {hasDevApiKeyFeature ? (
        <React.Fragment>
          <p>
            Before proceeding, you will need your Batch API keys. In the following documentation
            samples <code>YOUR_BATCH_API_KEY</code> should be replaced with your Batch Dev or Live
            API key. You'll find the API keys needed to set up the SDK in Settings → General:
          </p>

          <ul>
            <li>
              <strong>Dev API key</strong>: Use it for development or testing purposes. This API key
              won't trigger stats computation. Please take care not to ship an app on a Store with
              this API key.
            </li>
            <li>
              <strong>Live API key</strong>: Should be used in a production environment only and in
              the final version of your app on the Store.
            </li>
          </ul>
        </React.Fragment>
      ) : (
        <p>
          Before proceeding, you will need your Batch API key. In the following documentation
          samples <code>YOUR_BATCH_API_KEY</code> should be replaced with your Batch API key. You'll
          find the API key needed to set up the SDK in Settings → General.
        </p>
      )}

      <Alert icon="help" kind="info">
        Note that you should not use the same API key for iOS and Android. If you created different
        apps on the Batch dashboard for testing purposes, you can use the Live Api Key.
      </Alert>

      {platform === 'android' && (
        <React.Fragment>
          <h4>Adding Firebase Cloud Messaging</h4>
          <p>
            Batch requires Firebase Cloud Messaging (FCM) for push to be enabled. It can be added
            using a Flutter plugin, or natively in the Android project by following{' '}
            <a href="https://firebase.google.com/docs/android/setup" target="_blank">
              Firebase's native FCM documentation
            </a>
            .
          </p>

          <h4>Adding Huawei Push (Optional)</h4>

          <p>
            Batch supports notifications using Huawei Mobile Services (HMS) for devices that don't
            support Play Services. If you require this, please follow the{' '}
            <a
              href="https://flutter-doc-preview.batchers.vercel.app/android/huawei"
              target="_blank"
            >
              native Android Huawei documentation
            </a>
            .
          </p>

          <h4>Setting up a custom application class</h4>

          <p>
            If you already have a custom <code>android.app.Application</code> subclass registered,
            you can skip this step.
          </p>

          <p>Open your project's Android folder in Android Studio.</p>

          <p>
            First, create a new class that subclasses <code>android.app.Application</code>. It can
            be named however you want, but we will call it <code>ExampleApplication</code> for the
            sake of this example. Place it in your root package.
          </p>

          <p>
            Then, add an <code>onCreate()</code> override. The class should look like this:
          </p>

          <Tabs id="android-setup" bsStyle="pills" animation={false}>
            <Tab eventKey={0} title="Java">
              <Highlight language="groovy">
                {`import android.app.Application;

public class ExampleApplication extends Application {
  @Override
  public void onCreate() {
      super.onCreate();
  }
}`}
              </Highlight>
            </Tab>
            <Tab eventKey={1} title="Kotlin">
              <Highlight language="kotlin">
                {`import android.app.Application

class ExampleApplication: Application() {
    override fun onCreate() {
        super.onCreate()
    }
}`}
              </Highlight>
            </Tab>
          </Tabs>

          <p>
            Finally, open your <code>AndroidManifest.xml</code> and find the{' '}
            <code>application</code> tag. In it, add an <code>android:name</code> attribute, and set
            the value to your class' name, prefixed by a dot (<code>.</code>).
          </p>

          <Highlight language="xml">
            {`<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    package="com.batch.batch_flutter_example">
   <application
        android:name=".ExampleApplication" >`}
          </Highlight>

          <h4>Enabling the plugin</h4>

          <p>
            In your <code>Application</code> subclass' <code>onCreate()</code> method, call{' '}
            <code>BatchFlutterPlugin.setup(this)</code>. <br />
            Example:
          </p>
          <Tabs id="android-setup" bsStyle="pills" animation={false}>
            <Tab eventKey={0} title="Java">
              <Highlight language="groovy">
                {`import android.app.Application;
import com.batch.batch_flutter.BatchFlutterPlugin;

public class ExampleApplication extends Application {
    @Override
    public void onCreate() {
        super.onCreate();
        // Configuration can also be provided as metadata in AndroidManifest.xml. This will be explained
        // in the documentation's next steps.
        BatchFlutterPlugin.getConfiguration(this)
                .setAPIKey("YOUR_API_KEY");
        BatchFlutterPlugin.setup(this);
    }
}`}
              </Highlight>
            </Tab>
            <Tab eventKey={1} title="Kotlin">
              <Highlight language="kotlin">
                {`import android.app.Application
import com.batch.batch_flutter.BatchFlutterPlugin

class ExampleApplication: Application() {
    override fun onCreate() {
        super.onCreate()
        // Configuration can also be provided as metadata in AndroidManifest.xml. This will be explained
        // in the documentation's next steps.
        BatchFlutterPlugin.getConfiguration(this)
                .setAPIKey("YOUR_API_KEY")
        BatchFlutterPlugin.setup(this)
    }
}`}
              </Highlight>
            </Tab>
          </Tabs>
        </React.Fragment>
      )}

      {platform === 'ios' && (
        <React.Fragment>
          <p>
            Run <code>flutter build ios --no-codesign</code> after adding the plugin to your{' '}
            <code>pubspec</code> to initialize the native dependency.
          </p>

          <h4>Minimum iOS version</h4>

          <p>
            Default iOS Flutter projects can run on iOS 9.0 or higher. However, Batch requires iOS
            10. This can lead to the following error when <code>flutter build ios</code> is ran:
          </p>

          <Highlight language="bash">
            {`Specs satisfying the 'batch_flutter (from '.symlinks/plugins/batch_flutter/ios')'
dependency were found, but they required a higher minimum deployment target.`}
          </Highlight>

          <p>To fix this, you need to make your application require iOS 10 or higher:</p>

          <ul>
            <li>
              Open <code>ios/Podfile</code> and add <code>platform :ios, '10.0'</code> at the
              beginning of the file. Make sure you do not have any other line starting with{' '}
              <code>platform :ios</code>. If your app should require an higher iOS version, replace{' '}
              <code>10.0</code> with your minimum OS version.
            </li>
            <li>
              Update the iOS deployment target in the Xcode project. This can be done later, as this
              will only produce a warning for now, but it must be fixed before submitting to the
              store.
            </li>
          </ul>

          <h4>Enable Push Capabilities</h4>

          <p>
            Open the <code>xcworkspace</code> in the <code>ios/</code> folder. In the project
            window:
          </p>

          <ul>
            <li>Select your project in the sidebar</li>
            <li>
              Go to <code>Signing & Capabilities</code>
            </li>
            <li>
              Press on <code>+ Capability</code>
            </li>
            <li>
              Add <code>Push Notifications</code>
            </li>
          </ul>

          <h4>Modifying the AppDelegate</h4>

          <p>
            Open <code>AppDelegate.swift</code> (or .m if you have an Objective-C plugin). In it:
          </p>

          <ul>
            <li>
              Import the <code>Batch</code> and <code>batch_flutter</code> modules.
            </li>
            <li>
              Inside of <code>didFinishLaunchingWithOptions</code>:
            </li>
            <li style={{ marginLeft: '3rem' }}>
              Add <code>BatchUNUserNotificationCenterDelegate.registerAsDelegate()</code> if you do
              not already have a custom <code>UNUserNotificationCenterDelegate</code>. If you do,
              please{' '}
              <a
                href="https://doc.batch.com/ios/advanced/intercepting-notifications#ios-10"
                target="_blank"
              >
                follow this documentation
              </a>
              .
            </li>
            <li style={{ marginLeft: '3rem' }}>
              Call <code>BatchFlutterPlugin.setup()</code>.
            </li>
            <li style={{ marginLeft: '3rem' }}>
              Call <code>BatchPush.refreshToken()</code>.
            </li>
          </ul>

          <p>Here is an example Swift implementation of the AppDelegate:</p>

          <Highlight language="swift">
            {`import UIKit
import Flutter
import batch_flutter
import Batch

@UIApplicationMain
@objc class AppDelegate: FlutterAppDelegate {
  override func application(
    _ application: UIApplication,
    didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?
  ) -> Bool {
    GeneratedPluginRegistrant.register(with: self)
    
    // If you already have a UNUserNotificationCenterDelegate, please follow
    // https://doc.batch.com/ios/advanced/intercepting-notifications#ios-10
    // to integrate Batch in it.
    // Note that as a result, changing the value of 'BatchPush.showsiOSForegroundNotifications'
    // won't have any effect.
    BatchUNUserNotificationCenterDelegate.registerAsDelegate()
    // API Key can also be configured in the Info.plist. This will be explained
    // in the documentation's next steps.
    BatchFlutterPlugin.configuration.APIKey = "YOUR_API_KEY"
    BatchFlutterPlugin.setup()
    BatchPush.refreshToken()
    
    return super.application(application, didFinishLaunchingWithOptions: launchOptions)
  }
}`}
          </Highlight>

          <h4>Rich notifications</h4>

          <p>
            Rich notifications require setting up a Notification Service Extension. Please follow
            the native{' '}
            <a
              href="https://flutter-doc-preview.batchers.vercel.app/ios/sdk-integration/rich-notifications-setup"
              target="_blank"
            >
              Rich notifications setup documentation
            </a>
            .
          </p>

          <Alert icon="help" kind="info">
            Note: Due to a{' '}
            <a href="https://github.com/flutter/flutter/issues/76868" target="_blank">
              Flutter bug
            </a>
            , we advise using CocoaPods to add <code>BatchExtension</code> rather than SPM. If
            you're getting an error saying "Packages are not supported when using legacy build
            locations, but the current project has them enabled", this might be the underlying
            issue.
          </Alert>
        </React.Fragment>
      )}

      <h3>Configuring the plugin</h3>

      <p>
        As shown by the examples earlier, the plugin needs to be configured with the API Key. There
        are two ways to do so:
      </p>

      <ul>
        <li>
          Code configuration, in your Application subclass on Android, <code>Application</code>{' '}
          delegate on iOS.
        </li>
        <li>
          Static configuration, using <code>AndroidManifest.xml</code> on Android,{' '}
          <code>Info.plist</code> on iOS.
        </li>
      </ul>

      <p>
        Not configuring an API key will result in any <code>batch_flutter</code> dart method call to
        throw an exception.
      </p>

      {platform === 'android' && (
        <React.Fragment>
          <p>
            Code based configuration should be done in your <code>Application</code> subclass, right
            before <code>BatchFlutterPlugin.setup</code>: configuration changes made after calling
            this method will not be applied.
          </p>
          <p>
            Call <code>BatchFlutterPlugin.getConfiguration(this)</code> to get the configuration
            object, which can be used as a builder. Changes will automatically be saved.
            <br />
            Example:
          </p>
          <Tabs id="android-setup" bsStyle="pills" animation={false}>
            <Tab eventKey={0} title="Java">
              <Highlight language="groovy">
                {`BatchFlutterPlugin.getConfiguration(this)
                .setAPIKey("YOUR_API_KEY");`}
              </Highlight>
            </Tab>
            <Tab eventKey={1} title="Kotlin">
              <Highlight language="kotlin">
                {`BatchFlutterPlugin.getConfiguration(this)
                .setAPIKey("YOUR_API_KEY")`}
              </Highlight>
            </Tab>
          </Tabs>
          <p>The following methods are available:</p>
          <ul>
            <li>
              <code>setAPIKey</code>: Batch API Key.
            </li>
            <li>
              <code>setCanUseAdvertisingID</code>: Enable usage of the Google/HMS Advertising ID.
              Default: true.
            </li>
            <li>
              <code>setCanUseAdvancedDeviceInformation</code>: Enable usage of advanced device
              information. See Android -> Custom data -> Advanced for more info. Default: true.
            </li>
            <li>
              <code>setInitialDoNotDisturbState</code>: Enables{' '}
              <a
                href="https://flutter-doc-preview.batchers.vercel.app/flutter/messaging"
                target="_blank"
              >
                Do Not Disturb
              </a>{' '}
              by default. Default: false.
            </li>
          </ul>
          <Alert icon="help" kind="info">
            Note: if you call <code>getConfiguration</code> and have also configured the plugin
            using the manifest, the object's default values will mirror your manifest values.
          </Alert>
          <p>Manifest configuration automatically looks for the following keys:</p>
          <ul>
            <li>
              <code>com.batch.flutter.apikey</code>: Batch API Key.
            </li>
            <li>
              <code>com.batch.flutter.use_gaid</code>: Enable usage of the Google/HMS Advertising
              ID. Default: true.
            </li>
            <li>
              <code>com.batch.flutter.use_advanced_device_information</code>: Enable usage of
              advanced device information. See Android -> Custom data -> Advanced for more info.
              Default: true.
            </li>
            <li>
              <code>com.batch.flutter.do_not_disturb_initial_state</code>: Enables{' '}
              <a
                href="https://flutter-doc-preview.batchers.vercel.app/flutter/messaging"
                target="_blank"
              >
                Do Not Disturb
              </a>{' '}
              by default. Default: false.
            </li>
          </ul>
          <p>
            Boolean keys should be set as <code>"true"</code>/<code>"false"</code>.
          </p>
        </React.Fragment>
      )}

      {platform === 'ios' && (
        <React.Fragment>
          <p>
            Code based configuration should be done in your application delegate class, right before
            <code>BatchFlutterPlugin.setup</code>: configuration changes made after calling this
            method will not be applied.
          </p>
          <p>
            Call <code>BatchFlutterPlugin.configuration</code> to get the configuration object.
            Changes will automatically be saved.
            <br />
            Example:
          </p>

          <Highlight language="swift">
            {'BatchFlutterPlugin.configuration.APIKey = "YOUR_API_KEY"'}
          </Highlight>

          <p>The following properties can be used to configure the plugin:</p>

          <ul>
            <li>
              <code>APIKey</code> (string): Batch API Key.
            </li>
            <li>
              <code>canUseIDFA</code> (boolean): Enables IDFA collection. Default: false.
            </li>
            <li>
              <code>canUseAdvancedDeviceInformation</code> (boolean): Enable usage of advanced
              device information. See iOS -> Custom data -> Advanced for more info. Default: true.
            </li>
            <li>
              <code>initialDoNotDisturbState</code> (boolean): Enables{' '}
              <a
                href="https://flutter-doc-preview.batchers.vercel.app/flutter/messaging"
                target="_blank"
              >
                Do Not Disturb
              </a>{' '}
              by default. Default: false.
            </li>
          </ul>

          <Alert icon="help" kind="info">
            Note: if you call <code>configuration</code> and have also configured the plugin using
            the Info.plist, the object's default values will mirror your manifest values.
          </Alert>

          <p>
            <code>Info.plist</code>-based configuration looks for the following keys:
          </p>

          <ul>
            <li>
              <code>BatchFlutterAPIKey</code> (string): Batch API Key.
            </li>
            <li>
              <code>BatchFlutterCanUseIDFA</code> (boolean): Enables IDFA collection. Default:
              false.
            </li>
            <li>
              <code>BatchFlutterCanUseAdvancedDeviceInformation</code> (boolean): Enable usage of
              advanced device information. See iOS -> Custom data -> Advanced for more info.
              Default: true.
            </li>
            <li>
              <code>BatchFlutterDoNotDisturbInitialState</code> (boolean): Enables{' '}
              <a
                href="https://flutter-doc-preview.batchers.vercel.app/flutter/messaging"
                target="_blank"
              >
                Do Not Disturb
              </a>{' '}
              by default. Default: false.
            </li>
          </ul>

          <Alert icon="help" kind="info">
            Note: IDFA collection requires that the app is linked to{' '}
            <code>AdSupport.framework</code> and complies to <code>AppTrackingTransparency</code>.
          </Alert>
        </React.Fragment>
      )}

      <h3>Asking for the permission to display notifications</h3>

      <p>
        On iOS, your app needs to request for the permission to display notifications. This is done
        on the Flutter side of the app, as it can be asked as a step of an onboarding. Once the user
        consents, the push token will automatically be fetched by the SDK.
      </p>

      <p>
        This can be done using <code>BatchPush.instance.requestNotificationAuthorization()</code>:
      </p>

      <Highlight language="dart">
        {`import 'package:batch_flutter/batch_push.dart';

@override
Widget build(BuildContext context) {
  return ElevatedButton(
        child: Text("Request notification permission (iOS)"),
        onPressed: () =>
            BatchPush.instance.requestNotificationAuthorization(),
      )
}`}
      </Highlight>
    </React.Fragment>
  )
}

export default SetupFlutter
