// @flow
import * as React from 'react'
import styled from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

const NoResultsContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
const NoResultTitle = styled.h3`
  color: ${colors.text};
  font-size: 16px;
  margin-bottom: 4px;
`
const NoResultDesc = styled.p`
  color: ${colors.textLight};
  font-size: 14px;
`
type NoResultWrapperProps = {
  children: React.Node,
  entityName: string,
  isEmpty: boolean,
  height?: number,
}
export const NoResultWrapper = ({
  isEmpty,
  entityName,
  children,
  height = 280,
}: NoResultWrapperProps): React.Node => {
  return !isEmpty ? (
    children
  ) : (
    <NoResultsContainer style={{ height }}>
      <div>
        <NoResultTitle>
          <Icon icon="no-result" style={{ marginRight: 6 }} />
          No {entityName} to display
        </NoResultTitle>
        <NoResultDesc>There’s nothing matching your criteria, try something else.</NoResultDesc>
      </div>
    </NoResultsContainer>
  )
}
