// @flow
import * as React from 'react'

import { EmptyField } from 'components/common/empty-states'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { ProfileLastVisitContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  isLoading: boolean,
  date: ?string,
  ...
}
export const ProfileLastVisit = ({ date, isLoading }: Props): React.Node => {
  if (isLoading)
    return (
      <ProfileLastVisitContainer>
        <EmptyField _width={135} />
      </ProfileLastVisitContainer>
    )

  if (date && dayjs(date).isValid())
    return (
      <ProfileLastVisitContainer>
        <span>Last visit {dayjs(date).fromNow()}</span>
      </ProfileLastVisitContainer>
    )

  return null
}
