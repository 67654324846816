// @flow
import styled, { type StyledComponent } from 'styled-components'

import { colors, texts } from 'components/styled/tokens'

import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'

export const PushSubscriptionTitle: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  font: ${texts.label.font};
  cursor: pointer;
`

type PushSubscriptionMessageProps = {
  subscriptionStatus: SubscriptionStatus,
  ...
}
export const PushSubscriptionMessage: StyledComponent<
  PushSubscriptionMessageProps,
  {},
  HTMLDivElement,
> = styled.div`
  margin-top: 8px;
  color: ${(p: PushSubscriptionMessageProps) =>
    p.subscriptionStatus === 'UNSUBSCRIBED' ? colors.textDisabled : colors.textLight};
`
