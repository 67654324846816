// @flow
import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

export const mergeLoadingState = (loadingStatesArray: Array<fetchingState>): fetchingState => {
  if (loadingStatesArray.some(ls => ls === STATUS.ERROR)) return STATUS.ERROR
  if (loadingStatesArray.every(ls => ls === STATUS.LOADED)) return STATUS.LOADED
  if (loadingStatesArray.every(ls => ls === STATUS.INIT)) return STATUS.INIT
  return STATUS.LOADING
}
