// @flow

import * as React from 'react'
// import { hot } from 'react-hot-loader/root'
import { Route, Outlet, Routes } from 'react-router-dom'

import { AppList } from './app/app-list'
import { AppView } from './app/app-view'
import { ConsoleRequireAppFromRouter } from './app/console-require-app-from-router'
import { CompanyList } from './company/company-list'
import { CompanyView } from './company/company-view'
import { ConsoleRequireCompanyFromRouter } from './company/console-require-company-from-router'
import { Container } from './console.style'
import { DemoCodesList } from './demo-codes/demo-codes-list'
import { Header } from './header'
import { ProjectList } from './project/project-list'
import { ProjectView } from './project/project-view'
import { ConsoleRequireUserFromRouter } from './user/console-require-user-from-router'
import { UserForm } from './user/user-form'
import { UserList } from './user/user-list'

import { type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'
import { UserFactory } from 'com.batch.redux/user.records'

export const ConsoleRouter = (): React.Node => {
  const renderUserForm = React.useCallback(user => <UserForm user={user} />, [])
  const createRenderAppPageForCompanyAndTab = React.useCallback(
    (app, tab) => company => <AppView app={app} company={company} tab={tab} />,
    []
  )
  const createRenderAppPage = React.useCallback(
    (tab: 'exports' | 'general') => (app: AppRecord) =>
      !app.companyId ? (
        'impossible'
      ) : (
        <ConsoleRequireCompanyFromRouter
          forcedCompanyId={app.companyId}
          render={createRenderAppPageForCompanyAndTab(app, tab)}
        />
      ),
    [createRenderAppPageForCompanyAndTab]
  )
  const creeateRenderCompanyPage = React.useCallback(
    tab => (company: CompanyRecord) => <CompanyView company={company} tab={tab} />,
    []
  )
  return (
    <Routes>
      <Route
        element={
          <React.Fragment>
            <Header />
            <Container>
              <Outlet />
            </Container>
          </React.Fragment>
        }
      >
        <Route path="/console/users/new" element={<UserForm user={UserFactory()} />} />
        <Route
          path="/console/users/:userId"
          element={<ConsoleRequireUserFromRouter render={renderUserForm} />}
        />
        <Route
          path="/console/apps/:appId"
          element={<ConsoleRequireAppFromRouter render={createRenderAppPage('general')} />}
        />
        <Route
          path="/console/apps/:appId/exports"
          element={<ConsoleRequireAppFromRouter render={createRenderAppPage('exports')} />}
        />
        <Route
          path="/console/company/:companyId"
          element={<ConsoleRequireCompanyFromRouter render={creeateRenderCompanyPage('general')} />}
        />
        <Route
          path="/console/company/:companyId/features"
          element={
            <ConsoleRequireCompanyFromRouter render={creeateRenderCompanyPage('features')} />
          }
        />
        <Route
          path="/console/company/:companyId/sso"
          element={<ConsoleRequireCompanyFromRouter render={creeateRenderCompanyPage('sso')} />}
        />
        <Route path="/console/users" element={<UserList />} />
        <Route path="/console/apps" element={<AppList />} />
        <Route path="/console/companies" element={<CompanyList />} />
        <Route path="/console/projects/:projectKey" element={<ProjectView />} />
        <Route path="/console/projects" element={<ProjectList />} />
        <Route path="/console/demo-codes" element={<DemoCodesList />} />
        <Route>No match 404</Route>
      </Route>
    </Routes>
  )
}
