// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'

type TabItemProps = {
  isActive?: boolean,
  isDisabled?: boolean,
  children: ReactNode,
  onClick?: () => any,
  style?: { [string]: string | number },
  ...
}

export function TabButtonItem({
  isActive = false,
  isDisabled = false,
  children,
  onClick,
  style,
  ...rest
}: TabItemProps): React.Node {
  const onClickHandler = React.useCallback(
    evt => {
      evt.preventDefault()
      !!onClick && !isDisabled && onClick()
    },
    [isDisabled, onClick]
  )
  return (
    <button
      {...rest}
      type="button"
      className={`${isActive ? 'active ' : ''}${isDisabled ? 'disabled ' : ''}`}
      style={style}
      onClick={onClickHandler}
    >
      {children}
    </button>
  )
}
