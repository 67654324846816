// @flow

import styled, { type StyledComponent } from 'styled-components'

import { darklucent } from 'components/styled/tokens/schemes'

import { ConfigGridContainer } from 'com.batch/settings/ui/components/settings-fcm/config-grid/config-grid.styles'

export const ConfigsWrapper: StyledComponent<*, *, HTMLDivElement> = styled.div`
  padding: 0 20px;

  > div {
    padding: 16px 0;
  }

  > ${ConfigGridContainer} {
    padding-bottom: 0;
  }

  > div:not(:last-child) {
    border-bottom: 1px solid ${darklucent[20]};
  }
`
