// @flow
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Loader from 'components/common/loader-legacy'

import { fetchCompany } from '../redux/company'
import { type State } from '../redux/console.records'
import { type CompanyRecord, CompanyFactory } from 'com.batch.redux/_records'

type RequireUserProps = {
  forcedCompanyId?: number,
  render: (company: CompanyRecord) => any,
}

const companiesSelector = (state: State) => state.company.entities

export const ConsoleRequireCompanyFromRouter = ({
  forcedCompanyId,
  render,
}: RequireUserProps): React.Node => {
  const dispatch = useDispatch()
  const currentCompanyId = React.useRef(-1)
  const { companyId } = useParams()
  const companies = useSelector(companiesSelector)
  const usedCompanyId = React.useMemo(
    () => parseInt(forcedCompanyId ?? companyId, 10),
    [forcedCompanyId, companyId]
  )
  React.useEffect(() => {
    if (!companies.has(usedCompanyId) && currentCompanyId.current !== usedCompanyId) {
      currentCompanyId.current = usedCompanyId
      dispatch(fetchCompany(usedCompanyId))
    }
  }, [dispatch, usedCompanyId, companies])

  return companies.has(usedCompanyId) ? (
    render(companies.get(usedCompanyId, CompanyFactory()))
  ) : (
    <Loader loading={true} height={400} size="fat" />
  )
}
