// @flow

import { type Dayjs } from 'dayjs'
import Immutable from 'immutable'

import { legacyPromiseActionCreator, promiseActionCreator } from './actionCreator'
import api, { normalizeCompany } from './company.api'

import {
  type State,
  type CompanyRecord,
  type GDPRRecord,
  type DispatchBoundFn,
  CompanyFactory,
} from 'com.batch.redux/_records'
import { persistBillingInfo, getSubscriptionDetails } from 'com.batch.redux/billing.api'
import { type BillingRecord } from 'com.batch.redux/billing.records'
import {
  type listByCompanySuccess,
  type deleteUserSuccessAction,
  type InviteUserSuccessAction,
} from 'com.batch.redux/user'

import { type FetchMonthlyActiveProfilesActions } from 'com.batch/account/usecases/fetch-monthly-active-profiles'
import { type FetchMonthlySentVolumesActions } from 'com.batch/account/usecases/fetch-monthly-sent'
import { STATUS } from 'constants/common'

// ========================================================
// SELECTOR
// ========================================================

export const currentCompanySelector = (state: State): CompanyRecord => state.company

// ========================================================
// INITIAL STATE
// ========================================================
const initialState: CompanyRecord =
  !!window && !!window.company ? normalizeCompany(window.company) : CompanyFactory()

// ========================================================
// ACTIONS
// ========================================================
export const fetchCompany = (id: number): DispatchBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: {},
      promise: api.fetch(id),
      actionName: 'FETCH_COMPANY',
    })
}

export const updateCompany = (company: CompanyRecord): DispatchBoundFn<Promise<any>> => {
  return dispatch =>
    promiseActionCreator({
      dispatch,
      payload: company,
      promise: api.updateCompany(company),
      actionName: 'UPDATE_COMPANY',
    })
}
export const resetCompanyRestApiKey = (
  company: CompanyRecord
): DispatchBoundFn<Promise<CompanyRecord>> => {
  return dispatch =>
    promiseActionCreator({
      dispatch,
      payload: company,
      promise: api.resetCompanyRestApiKey(company),
      actionName: 'RESET_REST_API_KEY',
    })
}
export const enrichBillingInfo = (company: CompanyRecord): DispatchBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: company,
      promise: getSubscriptionDetails(company),
      actionName: 'GET_BILLING_DETAILS',
    })
}
export const persistBilling = ({
  company,
  billingInfo,
  paymentMethod,
}: {
  company: CompanyRecord,
  billingInfo: ?BillingRecord,
  paymentMethod: ?string,
  ...
}): DispatchBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { company, billingInfo, paymentMethod },
      promise: persistBillingInfo({ company, billingInfo, paymentMethod }),
      actionName: 'PERSIST_BILLING_INFO',
    })
}

type PersistBillingAction = {
  type: 'PERSIST_BILLING_INFO',
  payload: { company: CompanyRecord, billingInfo: BillingRecord, cardToken: ?string, ... },
  ...
}

export type PersistBillingSuccessAction = {
  type: 'PERSIST_BILLING_INFO_SUCCESS',
  payload: CompanyRecord,
  ...
}

export type PersistBillingFailureAction = {
  type: 'PERSIST_BILLING_INFO_FAILURE',
  payload: { error: string, ... },
  ...
}

type GetBillingDetailsAction = {
  type: 'GET_BILLING_DETAILS',
  payload: { company: CompanyRecord, ... },
  ...
}
type SubDetails = {
  cancelUnpaidAt: ?Dayjs,
  nextInvoice: ?Dayjs,
  cancelSubAt: ?Dayjs,
  ...
}
type GetBillingDetailsSuccessAction = {
  type: 'GET_BILLING_DETAILS_SUCCESS',
  payload: SubDetails,
  ...
}

export type GetBillingDetailsFailureAction = {
  type: 'GET_BILLING_DETAILS_FAILURE',
  payload: string,
  ...
}

type FC = {
  type: 'FETCH_COMPANY',
  payload: null,
  ...
}

type FCS = {
  type: 'FETCH_COMPANY_SUCCESS',
  payload: CompanyRecord,
  ...
}

type updateCompanyAction = {
  type: 'UPDATE_COMPANY',
  payload: CompanyRecord,
  ...
}
export type updateCompanySuccessAction = {
  type: 'UPDATE_COMPANY_SUCCESS',
  payload: CompanyRecord,
  ...
}
export type updateCompanyFailureAction = {
  type: 'UPDATE_COMPANY_FAILURE',
  payload: CompanyRecord,
  ...
}
export type resetRestApiKeySuccessAction = {
  type: 'RESET_REST_API_KEY_SUCCESS',
  payload: CompanyRecord,
  ...
}
export type resetRestApiKeyFailureAction = {
  type: 'RESET_REST_API_KEY_FAILURE',
  payload: CompanyRecord,
  ...
}

export const saveGDPR = (
  company: CompanyRecord,
  data: GDPRRecord
): DispatchBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: {},
      promise: api.saveGDPR(company, data),
      actionName: 'SAVE_GDPR',
    })
}

export type SG = {
  type: 'SAVE_GDPR',
  payload: null,
  ...
}

export type SGS = {
  type: 'SAVE_GDPR_SUCCESS',
  payload: CompanyRecord,
  ...
}
export type SGF = {
  type: 'SAVE_GDPR_FAILURE',
  payload: {
    error: true,
    message: string,
    ...
  },
  ...
}

type CompanyActions =
  | FC
  | FCS
  | SG
  | SGS
  | SGF
  | updateCompanyAction
  | updateCompanySuccessAction
  | updateCompanyFailureAction
  | resetRestApiKeySuccessAction
  | resetRestApiKeyFailureAction
  | PersistBillingAction
  | PersistBillingSuccessAction
  | PersistBillingFailureAction
  | GetBillingDetailsAction
  | GetBillingDetailsSuccessAction
  | GetBillingDetailsFailureAction
  | listByCompanySuccess
  | deleteUserSuccessAction
  | InviteUserSuccessAction
  | FetchMonthlyActiveProfilesActions
  | FetchMonthlySentVolumesActions

// ========================================================
// REDUCER
// ========================================================
export default function companyReducer(
  state: CompanyRecord = initialState,
  action: CompanyActions
): CompanyRecord {
  switch (action.type) {
    case 'INVITE_USER_SUCCESS':
      return state.set('users', state.users.add(action.payload.user.id || 0))
    case 'DELETE_USER_FOR_COMPANY_SUCCESS': {
      const deletedUserId = action.payload.id
      return state
        .set(
          'users',
          state.users.filter(id => id !== deletedUserId)
        )
        .set('usedSeats', state.usedSeats - 1)
    }
    case 'LIST_USERS_BY_COMPANY_SUCCESS':
      return state.set('users', Immutable.OrderedSet(action.payload.keySeq()))
    case 'SAVE_GDPR':
    case 'UPDATE_COMPANY':
    case 'FETCH_COMPANY':
      return state.set('loading', true)
    case 'FETCH_COMPANY_SUCCESS':
    case 'UPDATE_COMPANY_SUCCESS':
    case 'RESET_REST_API_KEY_SUCCESS':
      return action.payload
    case 'SAVE_GDPR_FAILURE':
    case 'UPDATE_COMPANY_FAILURE':
    case 'RESET_REST_API_KEY_FAILURE':
      return state.set('loading', false)
    case 'SAVE_GDPR_SUCCESS':
      return action.payload
    case 'PERSIST_BILLING_INFO':
    case 'GET_BILLING_DETAILS':
      return state.set('billing', state.billing.set('loading', true))
    case 'GET_BILLING_DETAILS_SUCCESS':
      return state.set(
        'billing',
        state.billing
          .set('loading', false)
          .set(
            'nextInvoice',
            action.payload.cancelSubAt
              ? action.payload.cancelSubAt
              : action.payload.nextInvoice
                ? action.payload.nextInvoice
                : state.billing.nextInvoice
          )
          .set('autorenew', !action.payload.cancelSubAt)
          .set('cancelUnpaidAt', action.payload.cancelUnpaidAt)
      )
    case 'PERSIST_BILLING_INFO_SUCCESS':
      return state.set('billing', action.payload.billing)
    case 'PERSIST_BILLING_INFO_FAILURE':
    case 'GET_BILLING_DETAILS_FAILURE':
      return state.set('billing', state.billing.set('loading', false))

    case 'FETCH_MONTHLY_ACTIVE_PROFILES':
      return state.set(
        'volumes',
        state.volumes.set('profile', state.volumes.profile.set('loadingState', STATUS.LOADING))
      )
    case 'FETCH_MONTHLY_ACTIVE_PROFILES_SUCCESS':
      return state.set(
        'volumes',
        state.volumes.set(
          'profile',
          state.volumes.profile
            .set('loadingState', STATUS.LOADED)
            .set('active', state.volumes.profile.active.set('total', action.payload))
        )
      )
    case 'FETCH_MONTHLY_ACTIVE_PROFILES_FAILURE':
      return state.set(
        'volumes',
        state.volumes.set('profile', state.volumes.profile.set('loadingState', STATUS.ERROR))
      )
    case 'FETCH_MONTHLY_SENT_VOLUMES':
      return state.set(
        'volumes',
        state.volumes.set('sent', state.volumes.sent.set('loadingState', STATUS.LOADING))
      )
    case 'FETCH_MONTHLY_SENT_VOLUMES_SUCCESS':
      return state.set(
        'volumes',
        state.volumes.set(
          'sent',
          state.volumes.sent
            .set('loadingState', STATUS.LOADED)
            .set('email', action.payload.email)
            .set('sms', action.payload.sms)
        )
      )
    case 'FETCH_MONTHLY_SENT_VOLUMES_FAILURE':
      return state.set(
        'volumes',
        state.volumes.set('sent', state.volumes.sent.set('loadingState', STATUS.ERROR))
      )
    default:
      return state
  }
}
