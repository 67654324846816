// @flow

import * as React from 'react'

import { EventFormatter, EventFormatterContainer } from 'components/campaign/event-formatter'
import { Grid } from 'components/common/grid'
import { Tag } from 'components/common/tag'

import { computeRepeatWord } from 'com.batch.common/utils'

import { type CampaignRecord } from 'com.batch.redux/_records'

const getAttributesLabel = (attr: string, label: string | null): string => {
  return label
    ? label
    : attr === 'be.install'
      ? 'INSTALLATION'
      : attr === 'be.install_optin'
        ? 'INSTALLED THEN OPTED-IN'
        : attr === 'be.first_subscription_optin'
          ? 'SUBSCRIPTION'
          : attr.toLowerCase() === 'now'
            ? 'AS SOON AS POSSIBLE'
            : attr.toLowerCase() === 'next_session'
              ? 'NEW SESSION'
              : attr.substring(attr.indexOf('.') + 1)
}

export const CampaignOption = (
  option: CampaignRecord,
  { context }: { context: 'menu' | 'value', ... }
): React.Node => {
  const scheduledHasTrigger = !!option.trigger || !!option.triggerLabel

  return context === 'value' ? (
    option.name
  ) : (
    <Grid template="1fr auto">
      <span>{option.name}</span>
      <Tag style={{ textAlign: 'right' }}>
        {option.sendType === 'scheduled' && scheduledHasTrigger && (
          <React.Fragment>
            Triggers on{' '}
            <EventFormatterContainer>
              {getAttributesLabel(option.trigger, option.triggerLabel ?? null)}
            </EventFormatterContainer>
          </React.Fragment>
        )}
        {option.sendType === 'scheduled' && !!option.start && !scheduledHasTrigger && (
          <span>{option.start.format('DD/MM/YY[ at ]HH[h]mm')}</span>
        )}
        {option.sendType === 'recurring' && (
          <span>
            Every {computeRepeatWord(option.repeatUnit, option.repeatFrequency, option.start)}
            {!!option.start && option.start.format(' [at ]HH[h]mm')}
          </span>
        )}
        {option.sendType === 'trigger' && (
          <React.Fragment>
            Triggers on{' '}
            <EventFormatter
              eventCode={option.triggerConfig.enterEvent}
              eventQuery={option.triggerConfig.enterEventQuery}
            />
          </React.Fragment>
        )}
        {!!option.tzAware || option.sendType === 'trigger' || option.type !== 'push' ? null : (
          <span> UTC</span>
        )}
      </Tag>
    </Grid>
  )
}
