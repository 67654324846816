// @flow
import { pie as D3Pie } from 'd3-shape'
import * as React from 'react'
import { useTheme } from 'styled-components'

import {
  type ChartRefData,
  type ChartData,
  type PieData,
} from 'com.batch/shared/infra/types/chart-data'
import { DonutChartContainer } from 'com.batch/shared/ui/component/charts/donut-chart/donut-chart.styles'
import { LoadingArc } from 'com.batch/shared/ui/component/charts/donut-chart/loading-arc'
import { Arc } from 'com.batch/shared/ui/component/charts/modular-donus-chart/donut-chart/arc'

const generateRandomArrayForLoadingPie = () => {
  const randomValue = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min
  const firstValue = randomValue(1, 100)
  const secondValue = randomValue(1, 100 - firstValue)
  const thirdValue = 100 - (firstValue + secondValue)
  return [firstValue, secondValue, thirdValue]
}

type Props = {
  data: ChartData[],
  clickedIndex: number,
  setClickedIndex: (value: number) => () => void,
  hoveredIndex: number | null,
  setHoveredIndex: (index: number | null) => () => void,
  middleText?: React.Node,
  width: number,
  height: number,
  hasTags?: boolean,
  ...
}

export const DonutChart = ({
  data,
  clickedIndex,
  setClickedIndex,
  hoveredIndex,
  setHoveredIndex,
  middleText,
  width,
  height,
  hasTags = false,
}: Props): React.Node => {
  const { isLoading, isEmpty } = useTheme()

  const isHover = React.useMemo(() => hoveredIndex !== clickedIndex, [clickedIndex, hoveredIndex])
  const radius: number = React.useMemo(() => Math.min(width, height) / 2, [height, width])

  const dataWithRef: ChartRefData[] = React.useMemo(() => {
    return data.map((d, i) => ({
      ...d,
      ref: i,
    }))
  }, [data])

  const dataFiltered = React.useMemo(() => {
    return dataWithRef.filter(d => d.value > 0)
  }, [dataWithRef])

  const pie = React.useMemo(
    () =>
      D3Pie()
        .value(d => d.value)
        .sort(null)
        // gap between segments
        .padAngle(0.03)(dataFiltered),
    [dataFiltered]
  )

  const loadingPie = React.useMemo(
    () =>
      D3Pie()
        .value(d => d)
        .sort(null)
        // gap between segments
        .padAngle(0.03)(generateRandomArrayForLoadingPie()),
    []
  )

  const getColor = React.useCallback(
    index => {
      if (clickedIndex === index) return data[index].color.active
      if (isHover && hoveredIndex === index) return data[index].color.hover
      return data[index].color.default
    },
    [hoveredIndex, clickedIndex, data, isHover]
  )

  React.useEffect(() => {
    if (!isLoading) {
      const currentData = dataWithRef[hoveredIndex ?? 0]
      // if the activeIndex set by default have 0 as value, so we choose another index
      if (currentData.value === 0) {
        const nextData = dataWithRef.find(d => d.value > 0)
        if (nextData) {
          setHoveredIndex(nextData.ref)
          setClickedIndex(nextData.ref)
        }
      }
    }
  }, [hoveredIndex, dataWithRef, isLoading, setHoveredIndex, setClickedIndex])

  return (
    <DonutChartContainer>
      {middleText && !isEmpty ? middleText : null}
      <svg width={width} height={height} style={{ overflow: 'visible' }}>
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {isLoading || isEmpty
            ? loadingPie.map((d: any, index: number) => (
                <LoadingArc key={index} d={d} radius={radius} />
              ))
            : pie.map((d: PieData) => (
                <Arc
                  key={d.data.ref}
                  color={getColor(d.data.ref)}
                  pattern={dataWithRef[d.data.ref].color.pattern}
                  radius={radius}
                  onClick={setClickedIndex(d.data.ref)}
                  onMouseEnter={setHoveredIndex(d.data.ref)}
                  onMouseLeave={setHoveredIndex(null)}
                  clickedIndex={clickedIndex}
                  index={d.data.ref}
                  d={d}
                  rate={dataWithRef[d.data.ref].rate ?? 0}
                  hasTag={hasTags}
                />
              ))}
        </g>
      </svg>
    </DonutChartContainer>
  )
}
