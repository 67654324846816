// @flow

import Immutable, { type Map, type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { useAction, useUserHasPermission } from 'components/_hooks'
import { ConfirmHighlight } from 'components/common/confirm.styles'

import { GlobalApp } from './global-app'
import { GlobalProjects } from './global-project'

import { optionalCurrentProjectSelector } from 'com.batch.redux//project.selector'
import { type AppRecord, type CompanyRecord, type SdkRecord } from 'com.batch.redux/_records'
import { pickableSdksSelector } from 'com.batch.redux/app'
import { archiveApp, saveApp } from 'com.batch.redux/app.action'
import { fetchAppStore } from 'com.batch.redux/app.api'
import { devApiKeyFeatureSelector } from 'com.batch.redux/company.selector'
import { showToast } from 'com.batch.redux/toaster'
import { companyStateSelector, currentUserSelector } from 'com.batch.redux/user.selector'

import { useConfirmWithMfa } from 'com.batch/shared/ui/hooks/use-confirm-with-mfa'

type GlobalSettingsProps = { app: AppRecord }

export type CommonGlobalProps = {
  name: string,
  setName: string => void,
  icon: string,
  setIcon: string => void,
  sdk: string,
  setSdk: string => void,
  errors: Map<string, string>,
  setErrors: (Map<string, string>) => void,
  bundleId: string,
  setBundleId: string => void,
  openPopin: boolean,
  setOpenPopin: boolean => void,
  iconLoading: boolean,
  setIconLoading: boolean => void,
  buttonLoading: string,
  archiveApp: typeof archiveApp,
  fetchIcon: AppRecord => void,
  setButtonLoading: string => void,
  fetchIconError: string,
  setFetchIconError: string => void,
  userNotAllowedToUpdade: boolean,
  remove: () => void,
  save: () => void,
  sdks: List<SdkRecord>,
  hasChanges: boolean,
  showToast: typeof showToast,
  company: CompanyRecord,
  hasDevApiKeyFeature: boolean,
  app: AppRecord,
}

export const GlobalSettings = ({ app }: GlobalSettingsProps): React.Node => {
  const confirm = useConfirmWithMfa()
  // ====================== Redux
  const project = useSelector(optionalCurrentProjectSelector)
  const user = useSelector(currentUserSelector)
  const company = useSelector(companyStateSelector)
  const sdks = useSelector(pickableSdksSelector)
  const hasDevApiKeyFeature = useSelector(devApiKeyFeatureSelector)
  const saveAppBound = useAction(saveApp)
  const archiveAppBound = useAction(archiveApp)
  const showToastBound = useAction(showToast)

  // ====================== Component state
  const [name, setName] = React.useState<string>(app.name)
  const [icon, setIcon] = React.useState<string>(app.icon)
  const [sdk, setSdk] = React.useState<string>(app.sdk)
  const [bundleId, setBundleId] = React.useState<string>(app.bundleId)

  const [openPopin, setOpenPopin] = React.useState<boolean>(false)

  const [iconLoading, setIconLoading] = React.useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = React.useState<string>('')

  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const [fetchIconError, setFetchIconError] = React.useState<string>('')

  // ====================== Component constants
  const userNotAllowedToUpdade = !useUserHasPermission(user, ['app', 'settings:infos:write'])

  React.useEffect(() => {
    setName(app.name)
    setIcon(app.icon)
    setSdk(app.sdk)
    setBundleId(app.bundleId)
  }, [app.name, app.bundleId, app.icon, app.defaultLanguageId, app.sdk])

  const save = React.useCallback(
    e => {
      e?.preventDefault()
      setButtonLoading('updating')

      const err = []

      if (name.length === 0) err.push(['name', 'An application name is required'])
      if (
        app.platform === 'webpush' &&
        !bundleId.match(
          // eslint-disable-next-line no-useless-escape
          /^(http(s)?:\/\/)(www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256})(\.[a-z]{2,6})([-a-zA-Z0-9@:%_\+.~#?&=\/]*)$/
        )
      )
        err.push(['bundleId', 'This website URL is invalid.'])

      if (err.length === 0) {
        setErrors(Immutable.Map())

        const newApp = app
          .set('name', name)
          .set('icon', icon)
          .set('bundleId', bundleId)
          .set('sdk', sdk)

        const saveNewApp = () => {
          // $FlowFixMe THUNKABLE
          saveAppBound(newApp).then(({ app }: any) => {
            showToastBound({ kind: 'success', message: 'General settings saved' })
            setIcon(app.icon)
            setButtonLoading('')
          })
        }

        saveNewApp()
      } else {
        setErrors(Immutable.Map(err))
        setButtonLoading('')
      }
    },
    [app, bundleId, icon, name, saveAppBound, sdk, showToastBound]
  )

  const fetchIcon = React.useCallback(
    (updatedApp: AppRecord) => {
      const err = []

      if (
        app.platform === 'webpush' &&
        !bundleId.match(
          // eslint-disable-next-line no-useless-escape
          /^(http(s)?:\/\/)(www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256})(\.[a-z]{2,6})([-a-zA-Z0-9@:%_\+.~#?&=\/]*)$/
        )
      )
        err.push(['bundleId', 'No icon could be found for this website'])

      if (err.length === 0) {
        setErrors(Immutable.Map())

        let errorMsg = `You need enter a valid ${
          app.platform === 'ios' ? 'App' : app.platform === 'android' ? 'Play' : 'Microsoft'
        } Store URL`

        if (updatedApp?.bundleId.length === 0) {
          setFetchIconError(errorMsg)
        } else {
          setIconLoading(true)
          fetchAppStore(app.platform === 'webpush' ? updatedApp : updatedApp.bundleId).then(
            fetchedApp => {
              if (fetchedApp.icon) {
                setIcon(fetchedApp.icon)
                setBundleId(fetchedApp.bundleId)

                if (app.platform !== 'webpush') {
                  setOpenPopin(false)
                  setFetchIconError('')
                }
                showToastBound({ kind: 'success', message: 'App icon fetched' })
              } else {
                app.platform !== 'webpush'
                  ? setFetchIconError(errorMsg)
                  : showToastBound({ message: 'No icon could be found for this website' })
              }

              setIconLoading(false)
            },
            error => {
              setFetchIconError(errorMsg)
              setIconLoading(false)
              showToastBound({ message: 'Unable to retrieve icon:', error: error.error })
            }
          )
        }
      } else {
        setErrors(Immutable.Map(err))
      }
    },
    [app.platform, bundleId, showToastBound]
  )

  const remove = React.useCallback(() => {
    setButtonLoading('removing')
    confirm({
      message: (
        <article>
          <p>
            You’re about to delete the <ConfirmHighlight>{app.name}</ConfirmHighlight> app. This
            can’t be undone and all the data associated will be lost. action
          </p>
          <p>
            If you use our APIs, any attempt to use the API key <code>{app.apiKey}</code> will be
            rejected.
          </p>
        </article>
      ),
      sensitive: true,
      title: 'Delete app',
      confirm: 'Yes, delete',
    })
      .then(
        () => {
          archiveAppBound(app)
        },
        () => {}
      )
      .then(() => {
        setButtonLoading('')
      })
  }, [app, archiveAppBound, confirm])

  const hasChanges = React.useMemo(
    () => name !== app.name || icon !== app.icon || sdk !== app.sdk || bundleId !== app.bundleId,
    [app.bundleId, app.name, app.icon, app.sdk, sdk, icon, name, bundleId]
  )

  //  React useMemo common props.
  const commonProps = React.useMemo(() => {
    return {
      name,
      setName,
      icon,
      setIcon,
      fetchIcon,
      sdk,
      setSdk,
      errors,
      setErrors,
      bundleId,
      setBundleId,
      openPopin,
      setOpenPopin,
      iconLoading,
      setIconLoading,
      buttonLoading,
      setButtonLoading,
      fetchIconError,
      setFetchIconError,
      userNotAllowedToUpdade,
      save,
      remove,
      hasChanges,
      sdks,
      app,
      company,
      hasDevApiKeyFeature,
      archiveApp: archiveAppBound,
      showToast: showToastBound,
    }
  }, [
    name,
    icon,
    fetchIcon,
    sdk,
    errors,
    bundleId,
    openPopin,
    iconLoading,
    buttonLoading,
    fetchIconError,
    userNotAllowedToUpdade,
    save,
    remove,
    hasChanges,
    sdks,
    app,
    company,
    hasDevApiKeyFeature,
    archiveAppBound,
    showToastBound,
  ])

  return project ? (
    <GlobalProjects {...commonProps} project={project} />
  ) : (
    <GlobalApp {...commonProps} />
  )
}
