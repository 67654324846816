// @flow

import { get as _get } from 'lodash-es'

import { type Dispatch } from 'com.batch.redux/_records'

export function promiseActionCreator<T>({
  dispatch,
  actionName,
  promise,
  payload = null,
  meta = null,
}: {
  dispatch: Dispatch,
  actionName: string,
  promise: Promise<T>,
  payload?: any,
  meta?: any,
  ...
}): Promise<T> {
  dispatch({ type: actionName, payload })
  return promise
    .then(async result => {
      dispatch({ type: `${actionName}_SUCCESS`, payload: result, meta })
      return result
    })
    .catch(error => {
      if (
        !error?.aborted &&
        _get(error, 'message.message', '') !==
          'Request has been terminated\nPossible causes: the network is offline, Origin is not allowed by Access-Control-Allow-Origin, the page is being unloaded, etc.'
      ) {
        dispatch({ type: `${actionName}_FAILURE`, payload: error, meta })
      }
      return Promise.reject(error)
    })
}

export function legacyPromiseActionCreator({
  dispatch,
  payload = null,
  promise,
  actionName,
  successCallback,
  errorCallback,
  meta,
}: {
  dispatch: Dispatch,
  payload?: any,
  promise: Promise<any>,
  actionName: string,
  successCallback?: (result: any) => void,
  errorCallback?: (result: any) => void,
  meta?: any,
  ...
}): Promise<any> {
  dispatch({ type: actionName, payload, meta })
  return promise.then(
    (result: any) => {
      dispatch({ type: `${actionName}_SUCCESS`, payload: result, meta })
      if (typeof successCallback === 'function') {
        successCallback(result)
      }
      return result
    },
    error => {
      if (
        _get(error, 'message.message', '') !==
        'Request has been terminated\nPossible causes: the network is offline, Origin is not allowed by Access-Control-Allow-Origin, the page is being unloaded, etc.'
      ) {
        dispatch({ type: `${actionName}_FAILURE`, payload: error, meta })
      }
      if (typeof errorCallback === 'function') {
        errorCallback()
      }
      // en vrai on devrait retourner Promise.reject(error) mais les side effects de ce change font bien trop peur
      return error
    }
  )
}

export const simpleAction: (actionName: string) => * = actionName => {
  return {
    actionName,
    payload: null,
  }
}
