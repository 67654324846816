// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type DeleteCappingRulesAction = ReduxAction<'DELETE_CAPPING_RULES', string>

export type DeleteCappingRulesFailureAction = ReduxAction<'DELETE_CAPPING_RULES_FAILURE', string>

export type DeleteCappingRulesSuccessAction = ReduxAction<'DELETE_CAPPING_RULES_SUCCESS', string>

export type DeleteCappingRulesActionType =
  | DeleteCappingRulesAction
  | DeleteCappingRulesFailureAction
  | DeleteCappingRulesSuccessAction

export const deleteCappingRules = (labelCode: string): DispatchExtraBoundFn<Promise<string>> => {
  return async (dispatch, getState, { metadataService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: metadataService
        .unsetCappingRules({ projectKey: project.projectKey, labelCodes: [labelCode] })
        .then(() => labelCode)
        .catch(() => {
          throw new Error('Unable to delete label')
        }),
      payload: labelCode,
      actionName: 'DELETE_CAPPING_RULES',
    })
  }
}
