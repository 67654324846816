// @flow

import * as React from 'react'

import { type EventRecord } from 'com.batch/profile/models/event.records'
import { CustomEventRow } from 'com.batch/profile/ui/components/events-block/event-row/custom-event-row'
import { LightMessagingEvent } from 'com.batch/profile/ui/components/events-block/event-row/light-messaging-event'
import { MessagingEventRow } from 'com.batch/profile/ui/components/events-block/event-row/messaging-event-row'

type Props = {
  event: EventRecord,
  previousEvent: EventRecord,
  nextEvent: EventRecord,
}

export const EventRow = ({ event, previousEvent, nextEvent }: Props): React.Node => {
  const messagingEvent = event.messagingEvent
  const isSameOrchestration = (firstEvent: EventRecord, secondEvent: EventRecord) => {
    if (
      !firstEvent?.messagingEvent ||
      !secondEvent?.messagingEvent ||
      firstEvent.messagingEvent.orchestrationMetadata.orchestrationID !==
        secondEvent.messagingEvent.orchestrationMetadata.orchestrationID
    ) {
      return false
    }

    if (firstEvent.messagingEvent.eventID === secondEvent.messagingEvent.eventID) {
      return false
    }

    const currentChannel = firstEvent.messagingEvent.channel
    if (currentChannel === 'email') {
      return (
        firstEvent.messagingEvent.emailAdditionalData?.mailID ===
        secondEvent?.messagingEvent?.emailAdditionalData?.mailID
      )
    }
    if (currentChannel === 'sms') {
      return (
        firstEvent.messagingEvent.smsAdditionalData?.smsID ===
        secondEvent?.messagingEvent?.smsAdditionalData?.smsID
      )
    }
    return (
      firstEvent?.messagingEvent?.pushAdditionalData?.transmissionUnitID ===
      secondEvent?.messagingEvent?.pushAdditionalData?.transmissionUnitID
    )
  }

  return event.customEvent ? (
    <CustomEventRow event={event.customEvent} />
  ) : messagingEvent ? (
    isSameOrchestration(event, previousEvent) ? (
      <LightMessagingEvent
        event={messagingEvent}
        nextEvent={isSameOrchestration(event, nextEvent) ? nextEvent?.messagingEvent : undefined}
      />
    ) : (
      <MessagingEventRow
        event={messagingEvent}
        nextEvent={isSameOrchestration(event, nextEvent) ? nextEvent?.messagingEvent : undefined}
      />
    )
  ) : null
}
