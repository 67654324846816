// @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useToggle } from 'components/_hooks'
import { Box } from 'components/common/box'
import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableBody,
  TableCellHeader,
  TableHeader,
  TableTemplateCell,
} from 'components/common/table'
import { Title } from 'components/styled/text'
import { colors } from 'components/styled/tokens'
import { textLight, textSuccess } from 'components/styled/tokens/colors'
import { grayscale } from 'components/styled/tokens/schemes'

import { getVariantLabel } from 'com.batch.common/utils'

import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { type MessageConfigRecord, type VariantId } from 'com.batch/message/models/message.records'
import { fetchOrchestration } from 'com.batch/orchestration/usecases/fetch-orchestration'
import {
  OrchestrationVariantFactory,
  VariantStatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-variant.record'
import { ABReportRow } from 'com.batch/orchestration-analytics/ui/components/a-b-report-block/a-b-report-row'
import {
  StatsBlocksRow,
  StatsContainer,
} from 'com.batch/orchestration-analytics/ui/components/orchestration-analytics/orchestration-analytics.styles'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { selectExperimentWinner } from 'com.batch/orchestration-analytics/usecases/select-experiment-winner'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { CollapseChevron, CollapsePanel } from 'com.batch/shared/ui/component/collapse/collapse'
import { STATUS } from 'constants/common'

const EmptyTemplate = ({ columns }: { columns: number }): React.Node => (
  <React.Fragment>
    <TableTemplateCell template="1fr" />
    {Array(columns)
      .fill(0)
      .map((_, i) => (
        <TableTemplateCell template="1fr" align="end" key={i} />
      ))}
  </React.Fragment>
)

export const ABReportBlock = (): React.Node => {
  const { token }: { token: string } = useParams()

  const dispatch = useDispatch()

  const orchestration = useSelector(orchestrationMetaSelector)
  const getMessageConfig = useSelector(getMessageConfigSelector)
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { filters, dateRange } = useGetAnalyticsFilters()

  // const [isCollapsePanelOpened, setIsCollapsePanelOpened] = React.useState(false)
  const collapsePanelState = useToggle()
  const [loadingWinnerId, setLoadingWinnerId] = React.useState(null) // permet de savoir quel bouton Pick win est en isLoading, et de disable les autres

  const messageConfig: MessageConfigRecord = React.useMemo(
    () => getMessageConfig({ stepMessageNodeId: null }),
    [getMessageConfig]
  )

  const analyticsState = React.useMemo(() => analyticsMap.get(token), [analyticsMap, token])

  const isLoading = React.useMemo(
    () => analyticsState?.variantsLoadingState === STATUS.LOADING,
    [analyticsState?.variantsLoadingState]
  )
  const isOverlayShown = React.useMemo(
    () => analyticsState?.variantsLoadingState === STATUS.ERROR,
    [analyticsState?.variantsLoadingState]
  )
  const isExperimentCompleted = React.useMemo(
    () => Boolean(messageConfig.experiment.winnerVariantId),
    [messageConfig.experiment.winnerVariantId]
  )

  // On peut choisir un winner uniquement sur une automation recurring qui est toujours en cours d'a/b (ongoing) et non completed ni draft
  const canPickWinner = React.useMemo(
    () =>
      !isExperimentCompleted &&
      orchestration.sendType === 'recurring' &&
      ['RUNNING', 'STOPPED'].includes(orchestration.state),
    [isExperimentCompleted, orchestration.sendType, orchestration.state]
  )

  // On map depuis la liste des variants de l'orchestration car la data ne renvoie pas le variant si pas de données. Or, on souhaite afficher une ligne par variant tout de meme.
  const variantStats = React.useMemo(() => {
    const variantsData = analyticsState?.variants ?? new Immutable.List()

    return messageConfig.experiment.variants
      .map(
        (variantMessageId, variantId) =>
          variantsData.find(v => v.variantId === variantId) ??
          OrchestrationVariantFactory({ variantId, stats: VariantStatsFactory() })
      )
      .toList()
  }, [analyticsState?.variants, messageConfig.experiment.variants])

  // Load des stats
  React.useEffect(() => {
    if (token) {
      const tokens = new Immutable.List().push(token)

      dispatch(
        fetchDynamicStats({
          tokens,
          // Si l'experiment est terminé les filtres ne s'appliquent pas, si non, oui
          filters: isExperimentCompleted ? Immutable.Map() : filters,
          dateRange: isExperimentCompleted ? null : dateRange,
          config: 'orchestrationStatsByVariant',
        })
      ).catch(() => {})
    }
  }, [
    dispatch,
    token,
    filters,
    dateRange,
    messageConfig.experiment.winnerVariantId,
    isExperimentCompleted,
  ])

  const handleSelectWinner = React.useCallback(
    (variantId: VariantId) => async () => {
      // Confirmation
      const didConfirm = await confirm({
        title: 'Pick this variant as a winner?',
        message: (
          <p>
            After selection, only the chosen variant is sent. Metrics for all variants remain
            frozen, updating only for prior sends.
          </p>
        ),
        confirm: 'Yes, pick it',
      })

      if (!didConfirm) return

      // Selection du winner
      try {
        setLoadingWinnerId(variantId)
        await dispatch(
          selectExperimentWinner({
            orchestrationId: orchestration.id,
            variantId: variantId,
          })
        )

        // màj de l'orchestration avec les id de winner, date, etc
        dispatch(fetchOrchestration(orchestration.id))
      } catch (e) {
        // handled in redux _FAILURE
      } finally {
        setLoadingWinnerId(null)
      }
    },
    [dispatch, orchestration.id]
  )

  const ABReportContent = React.useMemo(
    () => () => (
      <StatsBlocksRow id="demo">
        <Wrapper
          isLoading={isLoading}
          isEmpty={false}
          isOverlayShown={isOverlayShown}
          overlayProps={GlobalErrorOverlayProps}
          boxed
        >
          <Box
            style={{
              width: 1148,
            }}
          >
            <Table
              rowHeight={52}
              template={
                canPickWinner
                  ? `1fr repeat(${orchestration.channels.includes('email') ? 5 : 3}, 133px) 150px`
                  : `1fr repeat(${orchestration.channels.includes('email') ? 5 : 3}, 133px)`
              }
              style={{ overflow: 'hidden' }}
            >
              <TableHeader>
                <TableCellHeader style={{ color: colors.text, fontSize: 16, fontWeight: 500 }}>
                  Variant name
                </TableCellHeader>
                <TableCellHeader style={{ color: colors.textLight }} align="right">
                  Delivered
                </TableCellHeader>
                <TableCellHeader style={{ color: colors.textLight }} align="right">
                  Opened
                </TableCellHeader>
                {orchestration.channels.includes('email') && (
                  <TableCellHeader style={{ color: colors.textLight }} align="right">
                    Clicked
                  </TableCellHeader>
                )}
                <TableCellHeader
                  style={{
                    color: colors.textLight,
                    marginRight:
                      !canPickWinner && !orchestration.channels.includes('email') ? 28 : 12,
                  }}
                  align="right"
                >
                  Bounced
                </TableCellHeader>
                {orchestration.channels.includes('email') && (
                  <TableCellHeader
                    style={{ color: colors.textLight, marginRight: canPickWinner ? 12 : 28 }}
                    align="right"
                  >
                    Unsubscribed
                  </TableCellHeader>
                )}
                {canPickWinner && (
                  <TableCellHeader
                    style={{ color: colors.textLight, marginRight: 28 }}
                    align="right"
                  ></TableCellHeader>
                )}
              </TableHeader>
              <TableBody
                emptyTemplate={
                  <EmptyTemplate
                    columns={
                      orchestration.channels.includes('email')
                        ? canPickWinner
                          ? 6
                          : 5
                        : canPickWinner
                          ? 4
                          : 3
                    }
                  />
                }
                templateSize={4}
              >
                {variantStats.map(variant => (
                  <ABReportRow
                    key={variant.variantId}
                    variantStats={variant}
                    winnerVariantId={messageConfig.experiment.winnerVariantId}
                    selectWinner={handleSelectWinner(variant.variantId)}
                    loadingWinnerId={loadingWinnerId}
                    canPickWinner={canPickWinner}
                    channels={orchestration.channels}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Wrapper>
      </StatsBlocksRow>
    ),
    [
      isLoading,
      isOverlayShown,
      canPickWinner,
      orchestration.channels,
      variantStats,
      messageConfig.experiment.winnerVariantId,
      handleSelectWinner,
      loadingWinnerId,
    ]
  )

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ marginTop: 0, marginBottom: isExperimentCompleted ? 16 : 28 }}>
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: 12 }}>
          <Title>
            <Icon icon="experiment" />
            A/B Testing
          </Title>
          {isExperimentCompleted && (
            <span style={{ color: textSuccess, fontWeight: 500 }}>
              <Icon icon="cup-fill" /> Variant{' '}
              {getVariantLabel(messageConfig.experiment.winnerVariantId ?? 0)} was elected as the
              winner on{' '}
              {messageConfig.experiment.winningVariantSelectionDate &&
                messageConfig.experiment.winningVariantSelectionDate.format('DD/MM/YYYY')}
            </span>
          )}
        </div>
        {isExperimentCompleted && (
          <Button onClick={collapsePanelState.toggle} addOn="suffix" intent="neutral">
            More details
            <CollapseChevron isOpened={collapsePanelState.value} openingDirection="down" />
          </Button>
        )}
      </ChannelSectionTitle>

      {!isExperimentCompleted ? (
        <ABReportContent />
      ) : (
        <CollapsePanel isOpened={collapsePanelState.value} id="a-b-report">
          <div
            style={{
              background: grayscale['10'],
              color: textLight,
              borderRadius: 8,
              width: '100%',
              padding: 12,
              marginBottom: 28,
            }}
          >
            This table shows the data of the experiment and will not be affected by filters.
          </div>

          <ABReportContent />
        </CollapsePanel>
      )}
    </StatsContainer>
  )
}
