// @flow
import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'

import { currentCampaign } from './campaign.selector'
import {
  AbTestedThemeFactory,
  type AbTestedThemeRecord,
  FieldFactory,
  ThemeFactory,
  type FieldRecord,
  type ThemeRecord,
  type ThemeStateRecord,
} from './theme.records'

import {
  type AbTestedThemeCodeRecord,
  type fetchingState,
  type State,
  type Variant,
} from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'

import { STATUS } from 'constants/common'

type extract<T> = State => T

const ThemeSliceSelector: (state: State) => ThemeStateRecord = state => state.theme

export const AllThemesSelector: (state: State) => Map<number | 'new', ThemeRecord> = createSelector(
  ThemeSliceSelector,
  (ts: ThemeStateRecord) => {
    return ts.entities
  }
)
export const ThemesSelector: extract<List<ThemeRecord>> = createSelector(
  AllThemesSelector,
  currentAppSelector,
  (themes, app) => {
    return new Immutable.List().push(
      ...themes.filter(t => t.apps.has(app.get('id'))).toIndexedSeq()
    )
  }
)

export const OtherThemesSelector: extract<List<ThemeRecord>> = createSelector(
  AllThemesSelector,
  currentAppSelector,
  (themes, app) => {
    return new Immutable.List().push(
      ...themes
        .filter(t => !t.apps.has(parseInt(app.get('id'))) && t.apps.size !== 0 && t.id !== 'new')
        .toIndexedSeq()
    )
  }
)
const InAppThemeCodes: extract<AbTestedThemeCodeRecord> = createSelector(currentCampaign, inapp =>
  inapp.get('variantsThemeCodes')
)

export const InAppVariantsThemeSelector: extract<AbTestedThemeRecord> = createSelector(
  AllThemesSelector,
  InAppThemeCodes,
  (themes, inAppThemeCodes) => {
    const variants = ['a', 'b']

    return AbTestedThemeFactory(
      variants.map(variant => {
        return [
          variant,
          inAppThemeCodes.get(variant) === null
            ? ThemeFactory()
            : themes.find(theme => theme.code === inAppThemeCodes.get(variant), ThemeFactory()),
        ]
      })
    )
  }
)

export const InAppVariantFieldsSelector: extract<Map<Variant, List<FieldRecord>>> = createSelector(
  InAppVariantsThemeSelector,
  variantsThemes => {
    const variants = ['a', 'b']

    const variantsFields: { [Variant]: List<FieldRecord>, ... } = {}
    variants.forEach(variant => {
      const theme = variantsThemes.get(variant)

      let orderedFields: List<FieldRecord> = new Immutable.List()
      if (!theme) {
        variantsFields[variant] = orderedFields
        return
      }
      const ids = ['image', 'header', 'title', 'text', 'global', 'cta1', 'cta2']
      ids.forEach(id => {
        const f = theme.fields.get(id)
        if (typeof f !== 'undefined' && !f.hidden) {
          orderedFields = orderedFields.push(f)
        }
      })
      if (theme.payloadVars.kind === 'webview') {
        orderedFields = orderedFields.push(
          FieldFactory({
            id: 'webviewUrl',
            label: 'External URL',
          })
        )
      }
      orderedFields = orderedFields.push(
        FieldFactory({
          id: 'trackingId',
          label: 'Tracking ID',
        })
      )

      variantsFields[variant] = orderedFields
    })

    return Immutable.Map(variantsFields)
  }
)
export const ThemesFetchedSelector: (state: State) => boolean = createSelector(
  ThemeSliceSelector,
  (ts: ThemeStateRecord) => {
    return ts.loadingState === STATUS.LOADED
  }
)

export const ThemesErrorSelector: (state: State) => boolean = createSelector(
  ThemeSliceSelector,
  (ts: ThemeStateRecord) => {
    return ts.loadingState === STATUS.ERROR
  }
)

export const SelectedFieldSelector: (state: State) => ?FieldRecord = state =>
  state.theme.entities
    .get(state.theme.editing, ThemeFactory())
    .fields.find(f => f.id === state.theme.selectedField)

export const ThemesLoadingSelector: (state: State) => boolean = createSelector(
  ThemeSliceSelector,
  (ts: ThemeStateRecord) => {
    return ts.loadingState === STATUS.LOADING
  }
)
export const themesLoadingStateSelector: (state: State) => fetchingState = createSelector(
  ThemeSliceSelector,
  (ts: ThemeStateRecord) => {
    return ts.loadingState
  }
)

const EditedThemeIdSelector: (state: State) => number | 'new' = createSelector(
  ThemeSliceSelector,
  (ts: ThemeStateRecord) => {
    return ts.editing
  }
)

export const EditedThemeSelector: (state: State) => ThemeRecord = createSelector(
  AllThemesSelector,
  EditedThemeIdSelector,
  (themes: Map<number | 'new', ThemeRecord>, id: number | 'new') => {
    return themes.get(id, ThemeFactory())
  }
)
