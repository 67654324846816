// @flow

import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

type SspConfigurationProps = {
  senders: Immutable.List<string>,
  maxParts: number,
}

export const SspConfigurationFactory: RecordFactory<SspConfigurationProps> = Immutable.Record(
  ({
    senders: new Immutable.List(),
    maxParts: 0,
  }: SspConfigurationProps)
)
export type SspConfigurationRecord = RecordOf<SspConfigurationProps>
