// @flow

import { type RecordOf, Record, type RecordFactory } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type ProfilesDistributionProps = {
  total: number,
  email: number,
  phoneNumber: number,
  push: number,
  importedTokens: number,
  webpush: number,
  install: number,
  customId: number,
  orphans: number,
}
export const ProfilesDistributionFactory: RecordFactory<ProfilesDistributionProps> = Record({
  total: 0,
  email: 0,
  phoneNumber: 0,
  push: 0,
  importedTokens: 0,
  webpush: 0,
  install: 0,
  customId: 0,
  orphans: 0,
})
export type ProfilesDistributionRecord = RecordOf<ProfilesDistributionProps>

type ProfilesDistributionStateProps = {
  loadingState: fetchingState,
  distribution: ProfilesDistributionRecord,
}

export const ProfilesDistributionStateFactory: RecordFactory<ProfilesDistributionStateProps> =
  Record(
    ({
      loadingState: STATUS.INIT,
      distribution: ProfilesDistributionFactory(),
    }: ProfilesDistributionStateProps)
  )
export type ProfilesDistributionStateRecord = RecordOf<ProfilesDistributionStateProps>
