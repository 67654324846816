// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'

import { type DateRange, dayjs } from 'com.batch.common/dayjs.custom'

import { ShortcutsContainer } from './date-picker.styles'

export type dayShortcutType = {
  name: string,
  day: Dayjs,
}
export type allTimeRangeShortcutType = {
  name: string,
  all: boolean,
}
export type rangeShortcutType = {
  ...DateRange,
  name: string,
}
export type dateShortcut = dayShortcutType | rangeShortcutType
type datePickerShortcutsProps = {
  setDay: ({ day?: Dayjs, from?: Dayjs, to?: Dayjs }) => any,
  shortcuts: List<dateShortcut | allTimeRangeShortcutType>,
  activeDays: List<Dayjs>,
  reset?: () => any,
  ...
}

export const BASIC_DATE_PICKER_SHORTCUTS: List<dateShortcut> = new Immutable.List().push(
  ...[
    { name: 'One week ago', day: dayjs.utc().subtract(1, 'week') },
    { name: 'Next week', day: dayjs.utc().add(1, 'week') },
    { name: 'One month ago', day: dayjs.utc().subtract(1, 'month') },
    { name: 'Next month', day: dayjs.utc().add(1, 'month') },
    { name: 'One year ago', day: dayjs.utc().subtract(1, 'year') },
    { name: 'Next year', day: dayjs.utc().add(1, 'year') },
  ]
)

export const BASIC_DATE_RANGE_PICKER_SHORTCUTS: List<dateShortcut> = new Immutable.List().push(
  ...[
    {
      name: 'Last week',
      from: dayjs.utc().startOf('week').subtract(1, 'week').weekday(1),
      to: dayjs.utc().startOf('week').subtract(1, 'week').weekday(7),
    },
    { name: 'Current week', from: dayjs.utc().weekday(1), to: dayjs.utc().weekday(7) },
    {
      name: 'Next week',
      from: dayjs.utc().startOf('week').add(1, 'week').weekday(1),
      to: dayjs.utc().startOf('week').add(1, 'week').weekday(7),
    },
    {
      name: 'Last month',
      from: dayjs.utc().subtract(1, 'month').startOf('month'),
      to: dayjs.utc().subtract(1, 'month').endOf('month'),
    },
    {
      name: 'Current month',
      from: dayjs.utc().startOf('month'),
      to: dayjs.utc().endOf('month'),
    },
    {
      name: 'Next month',
      from: dayjs.utc().add(1, 'month').startOf('month'),
      to: dayjs.utc().add(1, 'month').endOf('month'),
    },
    {
      name: 'last 30 days',
      from: dayjs.utc().subtract(30, 'day'),
      to: dayjs.utc(),
    },
    {
      name: 'last 90 days',
      from: dayjs.utc().subtract(90, 'day'),
      to: dayjs.utc(),
    },
  ]
)

export const DatePickerShortcuts = ({
  setDay,
  shortcuts,
  activeDays,
  reset,
}: datePickerShortcutsProps): React.Node => {
  return (
    <ShortcutsContainer>
      {shortcuts.map((sc, i) => {
        let selected = false

        if (sc.day) {
          let found = activeDays.filter(d => d.isSame(sc.day ?? '', 'day'))
          if (found.size > 0) selected = true
        }

        if (sc.from && sc.to) {
          let foundFrom = activeDays.filter(d => d.isSame(sc.from ?? '', 'day'))
          let foundTo = activeDays.filter(o => o.isSame(sc.to ?? '', 'day'))
          if (foundFrom.size > 0 && foundTo.size > 0) selected = true
        }

        if (sc.all && activeDays.size === 0) selected = true

        return (
          <Button
            key={i}
            kind="inline"
            intent="neutral"
            isActive={selected}
            style={{ height: 28, width: 110 }}
            onClick={() => {
              if (sc.all && reset) {
                return reset()
              }
              return sc.day ? setDay({ day: sc.day }) : setDay({ from: sc.from, to: sc.to })
            }}
          >
            {sc.name}
          </Button>
        )
      })}
    </ShortcutsContainer>
  )
}
