// @flow

import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useCampaignInfoFromRouter } from './use-campaign-info-from-router'
import { useCurrentCompanyHasFeature } from './use-company-feature'

import { optionalCurrentProjectSelector } from '../../redux/project.selector'
import { type CampaignRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { fetchAttributesAndValues } from 'com.batch.redux/attribute'
import { setEditingCampaign } from 'com.batch.redux/campaign.action'
import { currentCampaign } from 'com.batch.redux/campaign.selector'
import { attributesLoadedSelector } from 'com.batch.redux/target/target.selector'

export const useSetEditingCampaignFromRouterLegacy = (
  withAttributes: boolean = false
): { campaign: CampaignRecord, campaignFetchingState: LoadingState, ... } => {
  const fakeComponentDidMount = React.useRef(false)
  const app = useSelector(currentAppSelector)
  const campaign = useSelector(currentCampaign)
  const attributesLoaded = useSelector(attributesLoadedSelector)
  const { token, campaignType, schedulingType } = useCampaignInfoFromRouter()

  const dispatch = useDispatch()
  const maybeProject = useSelector(optionalCurrentProjectSelector)
  const companyHasCepLegacyRecurring = useCurrentCompanyHasFeature('cep-show-legacy-recurring')
  const recurringLegacyDisabled = Boolean(maybeProject) && !companyHasCepLegacyRecurring
  React.useEffect(() => {
    if (!fakeComponentDidMount.current) {
      fakeComponentDidMount.current = true

      dispatch(
        setEditingCampaign({
          token,
          type: campaignType,
          schedulingType,
          estimate: true,
          recurringLegacyDisabled,
        })
      )
      if (withAttributes && !attributesLoaded) {
        dispatch(fetchAttributesAndValues({ app }))
      }
    }
  }, [
    app,
    campaignType,
    dispatch,
    schedulingType,
    token,
    withAttributes,
    attributesLoaded,
    recurringLegacyDisabled,
  ])

  return {
    campaign,
    campaignFetchingState:
      campaign && campaign.type && (campaign.id || token === 'new') && !campaign.loading
        ? 'LOADED'
        : campaign.loading
          ? 'LOADING'
          : 'ERROR',
  }
}
