// @flow
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { FilterSelectMulti } from 'components/filter/filter-select-multi/filter-select-multi'

import {
  languages,
  globallyConfiguredLanguagesSelector,
} from 'com.batch/message/store/message.selector'
import { orchestrationAnalyticsFiltersSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { type LanguageRecord } from 'com.batch.redux/_records'

import { updateAnalyticsFilters } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'

export const LanguageFilter = (): React.Node => {
  const dispatch = useDispatch()
  const { token }: { token: string } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const getAnalyticsFilters = useSelector(orchestrationAnalyticsFiltersSelector)
  const filters = React.useMemo(() => getAnalyticsFilters(token), [getAnalyticsFilters, token])
  const configuredLanguages = useSelector(globallyConfiguredLanguagesSelector)
  const selectedLanguages: List<LanguageRecord> = React.useMemo(
    () =>
      languages.filter(language =>
        filters.get('content_language', Immutable.Map()).includes(language.value)
      ),
    [filters]
  )

  const sortedLanguages = React.useMemo(() => {
    const defaultLang = languages.find(language => language.value === 'default')
    return new Immutable.List().push(
      defaultLang,
      ...languages
        .filter(
          language => language.value !== 'default' && configuredLanguages.includes(language.value)
        )
        .sortBy(language => language.label)
    )
  }, [configuredLanguages])

  const onLanguageChange = React.useCallback(
    (languages: List<LanguageRecord>) => {
      dispatch(
        updateAnalyticsFilters({
          token,
          filters:
            languages.size > 0
              ? filters.set(
                  'content_language',
                  languages.map(language => language.value)
                )
              : filters.delete('content_language'),
        })
      )
    },
    [dispatch, token, filters]
  )
  const optionToString = React.useCallback((language: ?LanguageRecord) => language?.label ?? '', [])
  const formatTerm = React.useCallback(
    (count: number) => {
      if (count === 1) {
        return selectedLanguages.first()?.label
      } else if (count === 2) {
        return `${selectedLanguages.first()?.label}, ${selectedLanguages.last()?.label}`
      } else {
        return `${count} languages`
      }
    },
    [selectedLanguages]
  )

  return (
    <FilterSelectMulti
      id="analytics-filter-lang"
      options={sortedLanguages}
      value={selectedLanguages}
      onChange={onLanguageChange}
      optionToString={optionToString}
      placeholder={'All languages'}
      term={formatTerm}
      toggleButtonWidth={schedulingType === 'campaigns' ? '100%' : 321}
      menuStyle={{ maxWidth: 300 }}
    />
  )
}
