// @flow

import Immutable, { type Map } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { FlexLineItem } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Select, Input, TabButton, TabButtonItem } from 'components/form'

import { CappingRow, CappingData } from './pressure.styles'

import { type InAppCappingRecord } from 'com.batch.redux/capping.records'

const optToString = (opt: ?{ label: string, value: string }) => opt?.label ?? ''
const options = new Immutable.List().push(
  ...[
    {
      label: 'session',
      value: 'session',
    },
    { label: 'interval of', value: 'global' },
  ]
)
type PressureInAppRowProps = {
  capping: InAppCappingRecord,
  onChange: InAppCappingRecord => void,
  isEditing: boolean,
  add?: () => void,
  remove?: () => void,
  ...
}

export const PressureInAppRow = ({
  capping,
  add,
  remove,
  onChange,
  isEditing,
}: PressureInAppRowProps): React.Node => {
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  // ====================== COMPONENT CALLBACKS - manage errors
  const checkErrors = React.useCallback(capping => {
    if (
      capping.periodUnit === 'h' &&
      (isNaN(capping.periodValue) || capping.periodValue > 24 || capping.periodValue < 1)
    )
      setErrors(errors =>
        errors.set('period', 'Please provide a time frame between 1 hour and 24 hours.')
      )
    else if (
      capping.periodUnit === 'd' &&
      (isNaN(capping.periodValue) || capping.periodValue > 31 || capping.periodValue < 1)
    )
      setErrors(errors =>
        errors.set('period', 'Please provide a time frame between 1 day and 31 days.')
      )
    else setErrors(errors => errors.delete('period'))
    if (capping.capping < 1 || isNaN(capping.capping))
      setErrors(errors => errors.set('capping', 'Please provide a capping greater than 0.'))
    else setErrors(errors => errors.delete('capping'))
  }, [])

  React.useEffect(() => {
    checkErrors(capping)
  }, [capping, checkErrors])

  const coOnCappingChange = React.useCallback(
    evt => onChange(capping.set('capping', parseInt(evt.target.value))),
    [capping, onChange]
  )
  const onLevelChange = React.useCallback(
    opt => {
      if (opt) onChange(capping.set('level', opt.value === 'global' ? 'global' : 'session'))
    },
    [capping, onChange]
  )
  const onPeriodChange = React.useCallback(
    evt => onChange(capping.set('periodValue', parseInt(evt.target.value))),
    [capping, onChange]
  )
  const createOnPeriodUnitchange = React.useCallback(
    (unit: 'd' | 'h') => () => {
      onChange(capping.set('periodUnit', unit))
    },
    [capping, onChange]
  )
  return (
    <CappingRow>
      <FlexLineItem>Display no more than</FlexLineItem>
      <FlexLineItem style={isEditing ? { width: 80 } : {}}>
        {isEditing ? (
          <Input
            type="number"
            allowedCharsRegex={/[0-9]/}
            value={isNaN(capping.capping) ? '' : capping.capping}
            invalid={errors.has('capping') && !add}
            invalidMessage={errors.get('capping')}
            placeholder={'2...'}
            onChange={coOnCappingChange}
          />
        ) : (
          <CappingData>{capping.capping}</CappingData>
        )}
      </FlexLineItem>
      <FlexLineItem>in-app message every</FlexLineItem>

      {isEditing && (
        <FlexLineItem style={{ width: 150 }}>
          <Select
            style={{ minHeight: 28 }}
            optionToString={optToString}
            options={options}
            value={options.find(opt => opt.value === capping.level)}
            onChange={onLevelChange}
          />
        </FlexLineItem>
      )}
      {capping.level === 'global' ? (
        <React.Fragment>
          <FlexLineItem style={isEditing ? { width: 80 } : {}}>
            {isEditing ? (
              <Input
                type="number"
                placeholder={'1...'}
                allowedCharsRegex={/[0-9]/}
                value={isNaN(capping.periodValue) ? '' : capping.periodValue}
                invalid={errors.has('period') && !add}
                invalidMessage={errors.get('period')}
                onChange={onPeriodChange}
              />
            ) : (
              <CappingData>{capping.periodValue}</CappingData>
            )}
          </FlexLineItem>
          <FlexLineItem>
            {isEditing ? (
              <TabButton size="small">
                <TabButtonItem
                  isActive={capping.periodUnit === 'h'}
                  onClick={createOnPeriodUnitchange('h')}
                >
                  hours
                </TabButtonItem>
                <TabButtonItem
                  isActive={capping.periodUnit === 'd'}
                  onClick={createOnPeriodUnitchange('d')}
                >
                  days
                </TabButtonItem>
              </TabButton>
            ) : (
              <CappingData>
                {capping.periodUnit === 'h' ? 'hour' : 'day'}
                {capping.periodValue > 1 && 's'}
              </CappingData>
            )}
          </FlexLineItem>
        </React.Fragment>
      ) : (
        !isEditing && (
          <FlexLineItem>
            <CappingData>session</CappingData>
          </FlexLineItem>
        )
      )}
      <FlexLineItem grow={1}>&nbsp;</FlexLineItem>
      <FlexLineItem>
        {isEditing && remove && (
          <Button type="button" style={{ height: 28, width: 28 }} kind="inline" onClick={remove}>
            <Icon icon="delete" />
          </Button>
        )}
        {add && (
          <Tooltip
            tooltip="Please fill the input(s) to add this rule"
            isTooltipEmpty={errors.size === 0}
          >
            <div>
              <Button
                type="button"
                kind="inline"
                style={{ height: 28 }}
                disabled={errors.size > 0}
                onClick={add}
              >
                Add this rule
              </Button>
            </div>
          </Tooltip>
        )}
      </FlexLineItem>
    </CappingRow>
  )
}
