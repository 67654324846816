// @flow

import { type OrderedSet } from 'immutable'
import * as React from 'react'

import PreviewToolbar from 'components/campaign/preview/preview-toolbar'
import { ToolbarContainer } from 'components/campaign/review/review/rsr.styles'
import { ReviewNavSecondLinks, SubLink } from 'components/campaign/review/review-screen.styles'
import { FlexLine } from 'components/common/flexline'

import { changePreferedInstall } from 'com.batch.redux/template'

type Props = {
  activeStep: ?string,
  isPush: boolean,
  installId: ?string,
  toggleFullScreen: () => void,
  installIds: OrderedSet<string>,
  changePreferedInstall: typeof changePreferedInstall,
  templateLoading: boolean,
  platform: string,
  hasLanding: boolean,
  previewMode: 'normal' | 'expanded' | 'landing',
  previewWeb: string,
  platform: string,
  updatePreviewMode: (previewMode: 'normal' | 'expanded' | 'landing') => void,
  updatePreviewWeb: (previewWeb: string) => void,
}

export const ReviewReviewMenu = ({
  activeStep,
  isPush,
  installId,
  toggleFullScreen,
  installIds,
  changePreferedInstall,
  templateLoading,
  platform,
  hasLanding,
  previewMode,
  previewWeb,
  updatePreviewMode,
  updatePreviewWeb,
}: Props): React.Node => {
  return (
    <FlexLine style={{ justifyContent: 'space-between' }}>
      <ReviewNavSecondLinks style={{ flex: 1 }}>
        <SubLink active={activeStep === 'targeting'} href="#targeting">
          Targeting
        </SubLink>
        <SubLink
          active={activeStep === 'timing' || activeStep === 'trigger'}
          href={isPush ? '#timing' : '#trigger'}
        >
          {isPush ? 'Timing' : 'Trigger'}
        </SubLink>
        <SubLink active={activeStep === 'content'} href="#content">
          Content
        </SubLink>
        {isPush && hasLanding && (
          <SubLink active={activeStep === 'landing'} href="#mobile-landing">
            Mobile landing
          </SubLink>
        )}

        <SubLink active={activeStep === 'settings'} href="#settings">
          Settings
        </SubLink>
      </ReviewNavSecondLinks>
      <ToolbarContainer>
        <PreviewToolbar
          hasLanding={hasLanding}
          isPush={isPush}
          toggleFullScreen={toggleFullScreen}
          installLoading={templateLoading}
          previewMode={previewMode}
          isWeb={platform === 'webpush'}
          previewWeb={previewWeb}
          updatePreviewMode={updatePreviewMode}
          updatePreviewWeb={updatePreviewWeb}
          changePreferedInstall={changePreferedInstall}
          installIds={installIds}
          installId={installId}
        />
      </ToolbarContainer>
    </FlexLine>
  )
}
