// @flow

import Immutable, { type List } from 'immutable'
import { createSelector } from 'reselect'
import request from 'superagent-interface-promise'

import { generateUrl } from 'com.batch.common/router'

import {
  UserStateFactory,
  type UserStateRecord,
  type UserFilterRecord,
  type State,
} from './console.records'

import { type DispatchOnlyBoundFn } from 'com.batch.redux/_records'
import { legacyPromiseActionCreator, promiseActionCreator } from 'com.batch.redux/actionCreator'
import { normalizeUser } from 'com.batch.redux/user.api'
import { type UserRecord, UserFactory } from 'com.batch.redux/user.records'

type deleteUserAction = {
  type: 'DELETE_USER',
  payload: UserRecord,
  ...
}
type deleteUserSuccessAction = {
  type: 'DELETE_USER_SUCCESS',
  payload: UserRecord,
  ...
}
type deleteUserFailureAction = {
  type: 'DELETE_USER_FAILURE',
  payload: UserRecord,
  ...
}
type disable2FAAction = {
  type: 'DISABLE_2FA',
  payload: null,
  ...
}
type disable2FASuccessAction = {
  type: 'DISABLE_2FA_SUCCESS',
  payload: UserRecord,
  ...
}
type disable2FAFailureAction = {
  type: 'DISABLE_2FA_FAILURE',
  payload: null,
  ...
}

type persistUserAction = {
  type: 'PERSIST_USER',
  payload: UserRecord,
  ...
}
type persistUserSuccessAction = {
  type: 'PERSIST_USER_SUCCESS',
  payload: UserRecord,
  ...
}
type persistUserFailureAction = {
  type: 'PERSIST_USER_FAILURE',
  payload: { user: UserRecord, error: any, ... },
  ...
}

type updateFiltersAction = {
  type: 'UPDATE_USERS_FILTER',
  payload: UserFilterRecord,
  ...
}

type updateUserPageAction = {
  type: 'UPDATE_USERS_PAGE',
  payload: number,
  ...
}

type loadUsersAction = {
  type: 'LOAD_USERS',
  payload: null,
  ...
}
type loadUsersSuccessAction = {
  type: 'LOAD_USERS_SUCCESS',
  payload: { count: number, entities: List<UserRecord>, nbPerPage: number, page: number, ... },
  ...
}
type loadUsersFailureAction = {
  type: 'LOAD_USERS_FAILURE',
  payload: string | null,
  ...
}

type fetchUserAction = {
  type: 'FETCH_USER',
  payload: null,
  ...
}
type fetchUserSuccessAction = {
  type: 'FETCH_USER_SUCCESS',
  payload: UserRecord,
  ...
}
type fetchUserFailureAction = {
  type: 'FETCH_USER_FAILURE',
  payload: null,
  ...
}

type handledActions =
  | updateFiltersAction
  | updateUserPageAction
  | loadUsersAction
  | loadUsersSuccessAction
  | loadUsersFailureAction
  | fetchUserAction
  | fetchUserFailureAction
  | fetchUserSuccessAction
  | persistUserAction
  | persistUserSuccessAction
  | persistUserFailureAction
  | deleteUserAction
  | deleteUserSuccessAction
  | deleteUserFailureAction
  | disable2FAAction
  | disable2FASuccessAction
  | disable2FAFailureAction

// ====================== SELECTORS
const userStateSelector = (state: State) => state.user

export const usersPageSelectors: State => List<UserRecord> = createSelector(
  userStateSelector,
  (userState: UserStateRecord) => {
    const idsList =
      userState.idsPerPage.get(userState.page, new Immutable.List()) || new Immutable.List()
    return idsList.map(id => userState.entities.get(id, UserFactory()))
  }
)

// ====================== ACTIONS
export const updateFilters = (filter: UserFilterRecord): updateFiltersAction => {
  return {
    type: 'UPDATE_USERS_FILTER',
    payload: filter,
  }
}

export const disable2FA =
  (user: UserRecord): DispatchOnlyBoundFn<Promise<UserRecord>> =>
  dispatch =>
    legacyPromiseActionCreator({
      actionName: 'DISABLE_2FA',
      dispatch,
      promise: request
        .post(generateUrl('console_api_user_disable_2FA', { userId: user.id }))
        .then(({ body }) => normalizeUser(body)),
      payload: null,
    })

export const persistUser =
  (user: UserRecord, sendMail: boolean = false): DispatchOnlyBoundFn<Promise<UserRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'PERSIST_USER',
      dispatch,
      promise: request
        .post(generateUrl('console_api_user_persist'), {
          user,
          sendMail: user.id ? false : sendMail,
        })
        .then(
          ({ body }) => normalizeUser(body),
          ({ body }) => {
            throw body.errors
          }
        ),
      payload: user,
    })

export const deleteUser =
  (user: UserRecord): DispatchOnlyBoundFn<Promise<UserRecord>> =>
  dispatch =>
    legacyPromiseActionCreator({
      actionName: 'DELETE_USER',
      dispatch,
      promise: request
        .delete(generateUrl('console_api_user_delete', { userId: user.id }))
        .then(() => user),
      payload: user,
    })

export const setPage = (page: number): updateUserPageAction => {
  return { type: 'UPDATE_USERS_PAGE', payload: page }
}

export const loadUsers = (): loadUsersAction => {
  return { type: 'LOAD_USERS', payload: null }
}
export const loadUsersSuccess = ({
  count,
  page,
  nbPerPage,
  entities,
}: {
  count: number,
  page: number,
  nbPerPage: number,
  entities: List<UserRecord>,
  ...
}): loadUsersSuccessAction => {
  return { type: 'LOAD_USERS_SUCCESS', payload: { count, entities, page, nbPerPage } }
}
export const loadUsersFailure = (error: string): loadUsersFailureAction => {
  return { type: 'LOAD_USERS_FAILURE', payload: error }
}

export const fetchUser =
  (id: number): DispatchOnlyBoundFn<Promise<UserRecord>> =>
  dispatch =>
    legacyPromiseActionCreator({
      actionName: 'FETCH_USER',
      dispatch,
      promise: request
        .get(generateUrl('console_api_user', { userId: id }))
        .then(({ body }) => normalizeUser(body)),
      payload: null,
    })

const init: UserStateRecord = UserStateFactory()

export function userReducer(
  state: UserStateRecord = init,
  action: handledActions
): UserStateRecord {
  switch (action.type) {
    case 'DELETE_USER_SUCCESS': {
      const deleteUser = action.payload
      return state.set(
        'idsPerPage',
        state.idsPerPage.map(ids => ids.filter(id => id !== deleteUser.id))
      )
    }
    case 'PERSIST_USER_SUCCESS':
    case 'DISABLE_2FA_SUCCESS': {
      const updatedUser = action.payload
      return state
        .set(
          'entities',
          state.entities.has(updatedUser.id || 0)
            ? state.entities.set(updatedUser.id || 0, updatedUser)
            : state.entities.set(updatedUser.id || 0, updatedUser)
        )
        .set('newUser', UserFactory())
    }
    case 'UPDATE_USERS_PAGE':
      return state.set('page', action.payload)
    case 'UPDATE_USERS_FILTER':
      return state
        .set('filters', action.payload)
        .set('idsPerPage', Immutable.Map()) // we reset cause results may differ
        .set('count', 0) // we reset cause results may differ
    case 'LOAD_USERS':
    case 'FETCH_USER':
      return state.set('loading', true)
    case 'LOAD_USERS_FAILURE':
      return state.set('loading', false)
    case 'FETCH_USER_SUCCESS':
      return state
        .set('loading', false)
        .set('entities', state.entities.set(action.payload.id || 0, action.payload))
    case 'LOAD_USERS_SUCCESS': {
      let entitiesMap = state.entities
      let ids: Array<number> = []
      action.payload.entities.forEach(user => {
        if (user.id) {
          entitiesMap = entitiesMap.set(user.id, user)
        }
        if (user.id) {
          ids.push(user.id)
        }
      })
      return state
        .set(
          'idsPerPage',
          state.idsPerPage.set(action.payload.page, new Immutable.List().push(...ids))
        )
        .set('entities', entitiesMap)
        .set('loading', false)
        .set('count', action.payload.count)
        .set('page', action.payload.page)
        .set('nbPerPage', action.payload.nbPerPage)
    }
    default:
      return state
  }
}
