// @flow

import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

type OrchestrationListStatsStateProps = {
  loadingState: fetchingState,
  delivered: number,
  openRate: number,
  ...
}

export const OrchestrationListStatsStateFactory: RecordFactory<OrchestrationListStatsStateProps> =
  Immutable.Record(
    ({
      loadingState: 'INIT',
      delivered: 0,
      openRate: 0,
    }: OrchestrationListStatsStateProps)
  )

export type OrchestrationListStatsStateRecord = RecordOf<OrchestrationListStatsStateProps>
