// @flow

import { useTransition } from '@react-spring/web'
import * as React from 'react'

import {
  Box,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  FooterBoxLeft,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { SideSheetContainer } from './sidesheet.styles'

export type SideSheetDirection = 'left' | 'right'

type SideSheetProps = {
  isOpened: boolean,
  children: React.Node,
  footerActions?: React.Node,
  footer?: React.Node,
  headerTitle?: React.Node,
  close: () => void | Promise<void>,
  step?: 'enter' | 'targeting',
  direction?: SideSheetDirection,
  width?: number,
  scrollable?: boolean,
  id?: string,
  template?: string,
  footerStyle?: Object,
}

const SideSheetRaw = ({
  isOpened,
  children,
  close,
  footer,
  footerActions,
  footerStyle,
  headerTitle,
  direction = 'left',
  width = 350,
  scrollable = false,
  id,
  template,
}: SideSheetProps) => {
  const transformProps = React.useMemo(() => {
    const initial = {
      opacity: 0,
      [String(direction)]: `-${width}px`,
    }
    return {
      initial,
      config: {
        mass: 1,
        friction: 28,
        tension: 220,
      },
      from: { ...initial },
      enter: {
        opacity: 1,
        [String(direction)]: '0',
      },
      leave: { ...initial },
    }
  }, [direction, width])

  const transitions = useTransition(isOpened, transformProps)
  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') close()
    },
    [close]
  )
  React.useEffect(() => {
    if (isOpened) document.addEventListener('keydown', handleKeyDown)
    else document.removeEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, isOpened])

  return (
    <React.Fragment>
      {transitions((style, item) =>
        item ? (
          <SideSheetContainer
            style={style}
            $template={template}
            $width={width}
            data-testid="sidesheet"
            $scrollable={scrollable}
            id={id}
          >
            <Box>
              {headerTitle && (
                <BoxHeader>
                  <HeaderBoxTitle title={headerTitle} />
                  <HeaderBoxActions>
                    <Button kind="inline" intent="neutral" onClick={close}>
                      <Icon icon="close" />
                    </Button>
                  </HeaderBoxActions>
                </BoxHeader>
              )}
              {children}
              {footerActions && (
                <BoxFooter style={{ borderTop: `1px solid ${colors.stroke}`, ...footerStyle }}>
                  {footer && <FooterBoxLeft>{footer}</FooterBoxLeft>}
                  <FooterBoxActions>{footerActions}</FooterBoxActions>
                </BoxFooter>
              )}
            </Box>
          </SideSheetContainer>
        ) : null
      )}
    </React.Fragment>
  )
}

export const SideSheet: React.ComponentType<SideSheetProps> = React.memo(SideSheetRaw)
SideSheet.displayName = 'SideSheet'
