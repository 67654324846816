// @flow

import * as React from 'react'

// eslint-disable-next-line import/no-cycle
import { confirm } from 'components/common/confirm'
import { DocLink } from 'components/styled/text'

import { type TriggerConfigRecord } from 'com.batch.redux/_records'

export function useConfirmEditTrigger(
  update: TriggerConfigRecord => void,
  warnOnEdit: boolean
): TriggerConfigRecord => void {
  const [skipWarning, setSkipWarning] = React.useState(false)
  return React.useCallback(
    (config: TriggerConfigRecord) => {
      if (skipWarning || !warnOnEdit) {
        update(config)
      } else {
        confirm({
          title: 'Edit this campaign?',
          message: (
            <React.Fragment>
              <p>
                Editing a running trigger campaign might affect the users currently in your
                campaign.
              </p>
              <DocLink href={'https://help.batch.com/en/articles/3778317'} target="_blank">
                Impact of modifying a trigger campaign
              </DocLink>
            </React.Fragment>
          ),
          confirm: 'Yes, edit it',
          sensitive: true,
        }).then(
          () => {
            setSkipWarning(true)
            update(config)
          },
          () => {}
        )
      }
    },
    [skipWarning, update, warnOnEdit]
  )
}
