// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { savedRecipientsIdsKey } from './popin-send-test-push'

import { sendTestPush, type SendTestRecipientId } from '../usecases/send-test-push'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import {
  LayerBarDropdown,
  LayerBarButton,
} from 'com.batch/message-builder/ui/components/layerbar-button'
import { restoreValuesFromLocalStorage } from 'com.batch/shared/usecases/save-restore-local'

type PushLayerBarSendTestProps = {
  handleSendTestClick: () => void,
  stepMessageNodeId: ?string,
}

export const PushLayerBarSendTest: React.ComponentType<PushLayerBarSendTestProps> = React.memo(
  ({ handleSendTestClick, stepMessageNodeId }) => {
    const dispatch = useDispatch()
    const { id: projectId } = useSelector(currentProjectSelector)
    const scopedSavedTestIdsKey = React.useMemo(
      () => `${projectId}-${savedRecipientsIdsKey}`,
      [projectId]
    )
    const savedIds = restoreValuesFromLocalStorage<SendTestRecipientId>(scopedSavedTestIdsKey)
    const [isSendingTo, setIsSendingTo] = React.useState('')

    const sendTestOptions = React.useMemo(
      () => [
        ...savedIds.map(recipientId => ({
          label: recipientId.id,
          action: () => {
            setIsSendingTo(recipientId.id)
            dispatch(sendTestPush({ stepMessageNodeId, recipientId }))
              .catch(() => {})
              .finally(() => {
                setIsSendingTo('')
              })
          },
          loading: isSendingTo === recipientId.id,
          disabled: !!isSendingTo,
        })),
      ],
      [dispatch, stepMessageNodeId, savedIds, isSendingTo]
    )

    return savedIds.length > 0 ? (
      <LayerBarDropdown
        label="Send test"
        icon={<img src="/medias/img/builders/icons/send-big.svg" alt="Arrow icon" />}
        options={sendTestOptions}
        defaultOption={{
          label: 'New device',
          action: handleSendTestClick,
          disabled: !!isSendingTo,
          isDefault: true,
        }}
      />
    ) : (
      <LayerBarButton
        label="Send test"
        icon={<img src="/medias/img/builders/icons/send-big.svg" alt="Arrow icon" />}
        onClick={handleSendTestClick}
      />
    )
  }
)
PushLayerBarSendTest.displayName = 'PushLayerBarSendTest'
