// @flow

import * as React from 'react'

import { type Pattern } from 'com.batch/shared/infra/types/chart-data'

type Props = {
  pattern: ?Pattern,
  color: ?string,
  isClicked: boolean,
  ...
}

export const LegendIcon = ({ pattern, color, isClicked }: Props): React.Node => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none">
      <rect x={4} y={4} width={14} height={14} rx={4} fill={color} />
      {pattern && (
        <React.Fragment>
          {pattern.content}
          <rect
            x={4}
            y={4}
            width={14}
            height={14}
            rx={4}
            fill={`url(#${pattern.id})`}
            style={{ opacity: 0.12 }}
          />
        </React.Fragment>
      )}
      {isClicked && (
        <rect x={1.5} y={1.5} width={19} height={19} rx={5} stroke={color} strokeWidth={2} />
      )}
    </svg>
  )
}
