// @flow

import * as React from 'react'

import { RatioTagContainer } from 'com.batch/shared/ui/component/charts/donut-chart/ratio-tag.styles'

type Props = {
  rate: number,
  x: number,
  y: number,
  onClick: (index: number) => void,
  onMouseLeave: () => void,
  onMouseEnter: (index: number) => void,
  ...
}

const wrapperOffset = 6 // for display the box-shadow

export const RatioTag = ({
  rate,
  onClick,
  onMouseLeave,
  onMouseEnter,
  x,
  y,
}: Props): React.Node => {
  const tagRef = React.useRef(null)
  const [elementWidth, setElementWidth] = React.useState<number>(0)
  const [elementHeight, setElementHeight] = React.useState<number>(0)

  const xPos = React.useMemo(() => {
    return x - elementWidth / 2
  }, [elementWidth, x])

  const yPos = React.useMemo(() => {
    return y - elementHeight / 2
  }, [elementHeight, y])

  React.useEffect(() => {
    setElementWidth(tagRef.current?.offsetWidth ? tagRef.current?.offsetWidth + wrapperOffset : 0)
    setElementHeight(
      tagRef.current?.offsetHeight ? tagRef.current?.offsetHeight + wrapperOffset : 0
    )
  }, [])

  return (
    <foreignObject
      width={elementWidth}
      height={elementHeight}
      x={xPos}
      y={yPos}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      style={{ transition: 'x 0.1s ease-out, y 0.1s ease-out' }}
    >
      <RatioTagContainer
        style={{ margin: wrapperOffset / 2 }}
        ref={tagRef}
        xmlns="http://www.w3.org/1999/xhtml"
      >
        {rate}%
      </RatioTagContainer>
    </foreignObject>
  )
}
