// @flow
import * as React from 'react'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { StickyDayContent } from './sticky-day.styles'

type StickyDayProps = {
  date: string,
  firstElement: boolean,
  ...
}

export const StickyDay = ({ date, firstElement }: StickyDayProps): React.Node => {
  const now = new Date()

  const day = dayjs(date).format('Do MMMM')
  const diffDate = dayjs(now).diff(dayjs(date), 'day')

  const reference = diffDate === 0 ? 'Today' : diffDate === 1 ? 'Yesterday' : ''

  return (
    <StickyDayContent firstElement={firstElement}>
      {day} <span>{reference}</span>
    </StickyDayContent>
  )
}
