// @flow

import Immutable from 'immutable'

import { type SenderIdentityModel } from 'com.batch.redux/adapters/sender-identity/models/sender-identity'
import {
  SenderIdentityFactory,
  type SenderIdentityRecord,
} from 'com.batch.redux/corelogic/records/sender-identity.records'

export const parseSenderIdentityModelToRecords = (
  raw: SenderIdentityModel
): SenderIdentityRecord => {
  return SenderIdentityFactory({
    id: raw.id,
    sendingName: raw.sendingName,
    sendingPrefix: raw.sendingPrefix,
    sendingDomain: raw.sendingDomain,
    transmissionTypes: Immutable.Set(raw.transmissionTypes),
  })
}

export const parseSenderIdentityRecordToModel = (
  raw: SenderIdentityRecord,
  projectKey: string
): SenderIdentityModel => {
  return {
    id: raw.id,
    projectKey: {
      textual: {
        text: projectKey,
      },
    },
    sendingName: raw.sendingName,
    sendingPrefix: raw.sendingPrefix,
    sendingDomain: raw.sendingDomain,
    transmissionTypes: raw.transmissionTypes.toArray(),
  }
}
