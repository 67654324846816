// @flow
import { CHANNEL_THEMES } from 'com.batch/profilebase/constants/channel-themes'
import { statTypeColors } from 'com.batch/profilebase/constants/stat-type-colors'
import { type AnalyticsChannel } from 'com.batch/profilebase/infra/types/channel-type'
import { type SubDistributionRecord } from 'com.batch/profilebase/models/sub-distribution.records'
import { type ChartData } from 'com.batch/shared/infra/types/chart-data'

const WORDING = {
  email: {
    data: ['subscribed', 'unsubscribed', 'unknown'],
    subscribed: 'Subscribed',
    unsubscribed: 'Unsubscribed',
    unknown: 'Never subscribed',
  },
  sms: {
    data: ['subscribed', 'unsubscribed', 'unknown'],
    subscribed: 'Subscribed',
    unsubscribed: 'Unsubscribed',
    unknown: 'Never subscribed',
  },
  push: {
    data: ['subscribed', 'unsubscribed'],
    subscribed: 'Subscribed',
    unsubscribed: 'Not subscribed',
    unknown: '',
  },
}

const PIE_CHART_COLOR = {
  subscribed: statTypeColors.subscribed,
  unsubscribed: statTypeColors.unsubscribed,
  unknown: statTypeColors.known,
}

export const convertMarketingSuscriptionPieChartData = (
  channel: AnalyticsChannel,
  distribution: SubDistributionRecord
): ChartData[] =>
  WORDING[channel].data.map(e => ({
    value: distribution[e].value,
    rate: distribution[e].rate,
    label: WORDING[channel][e],
    legend: '',
    color:
      e === 'subscribed' && CHANNEL_THEMES[channel]
        ? CHANNEL_THEMES[channel].colors.donutChart
        : PIE_CHART_COLOR[e],
  }))
