// @flow
import * as React from 'react'

import { EventRowSkeleton } from './event-row/event-row-skeleton'

import { EventListContainer } from 'com.batch/profile/ui/components/events-block/event-list/event-list.styles'

export const EventEmptyList = (): React.Node => {
  return (
    <EventListContainer>
      <EventRowSkeleton />
      <EventRowSkeleton />
      <EventRowSkeleton />
      <EventRowSkeleton />
      <EventRowSkeleton />
      <EventRowSkeleton />
    </EventListContainer>
  )
}
