// @flow
import Immutable, { type Map, type List } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  OrchestrationAnalyticsFactory,
  type OrchestrationAnalyticsRecord,
  type OrchestrationAnalyticsDateRangeRecord,
} from './orchestration-analytics.state'

import { type DynamicDimension } from '../usecases/fetch-dynamic-stats.helper'
import { type State } from 'com.batch.redux/_records'

import {
  getDailyRange,
  getTemporalityRange,
} from 'com.batch/orchestration-analytics/infra/formats/get-range'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'

type Extract<T> = State => T

export const orchestrationAnalyticsMapSelector: Extract<
  Map<string, OrchestrationAnalyticsRecord>,
> = state => state.orchestrationAnalytics

export const orchestrationAnalyticsFiltersSelector: State => string => Map<
  DynamicDimension,
  List<string>,
> = createSelector(
  orchestrationAnalyticsMapSelector,
  orchestrationAnalytics => token =>
    orchestrationAnalytics.get(token, OrchestrationAnalyticsFactory()).filters
)
export const orchestrationAnalyticsDateRangeSelector: State => string => ?OrchestrationAnalyticsDateRangeRecord =
  createSelector(
    orchestrationAnalyticsMapSelector,
    orchestrationAnalytics => token =>
      orchestrationAnalytics.get(token, OrchestrationAnalyticsFactory()).dateRange
  )

export const bounceByDaysRangeSelector: State => string => BarChartData[] = createSelector(
  orchestrationAnalyticsMapSelector,
  orchestrationAnalytics => token => {
    const bounces = orchestrationAnalytics.getIn(
      [token, 'channels', 'email', 'bounces', 'periods'],
      Immutable.Map()
    )

    const dateRange = orchestrationAnalytics.getIn([token, 'dateRange'])
    let from = null
    let to = dayjs.utc()

    if (dateRange) {
      from = dateRange.from
      to = dateRange.to
    } else {
      const bouncesDates = bounces.reduce((acc, _, key) => [...acc, dayjs(key)], [])
      if (bouncesDates.length > 0) {
        from = dayjs.min(bouncesDates)
        to = dayjs.max(bouncesDates)
      }
    }

    if (!from) return []

    const diff = to.diff(from, 'day')
    switch (true) {
      case diff < 31:
        return getDailyRange({ bounces, from, to })
      case diff < 77:
        return getTemporalityRange({ bounces, from, to, unit: 'week' })
      default:
        return getTemporalityRange({ bounces, from, to, unit: 'month' })
    }
  }
)
