// @flow
import * as React from 'react'

import { useBlurOnClickCallback } from 'components/_hooks/use-blur-on-click'
import { useToggle } from 'components/_hooks/use-toggle'
import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'

import {
  BoxTitle,
  BoxSearchContainer,
  BoxContainer,
  BoxHeader,
  HeaderBoxActions,
} from './box.styles'

export type HeaderBoxTitleProps = {
  title: React.Node | string,
  prefix?: React.Node,
  suffix?: React.Node,
  detail?: React.Node | string,
  style?: Object,
  ...
}

export const HeaderBoxTitle = ({
  title,
  prefix,
  suffix,
  detail,
  style,
}: HeaderBoxTitleProps): React.Node => {
  return (
    <BoxTitle withPrefix={prefix} withSuffix={suffix} withDetail={detail} style={style}>
      {detail ? (
        <React.Fragment>
          <div>
            {prefix}
            {title}
          </div>
          <div style={{ marginTop: '6px', display: 'flex', alignItems: 'center', fontSize: 14 }}>
            <span style={{ marginRight: '12px' }}>{detail}</span> {prefix}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {prefix}
          {title}
          {suffix}
        </React.Fragment>
      )}
    </BoxTitle>
  )
}

export interface BoxSearchType {
  value: string;
  onChange: (value: string) => any;
  placeholder?: string;
}

export const BoxSearch: React.ComponentType<BoxSearchType> = React.forwardRef(
  (props: BoxSearchType, $ref) => {
    const handleChange = React.useCallback(
      e => {
        if (!e) {
          props.onChange('')
        } else {
          props.onChange(e.target.value)
        }
      },
      [props]
    )
    return (
      <BoxSearchContainer>
        <Icon icon="search" thickness={1.3} />
        <input
          value={props.value}
          onChange={handleChange}
          placeholder={props.placeholder || 'Search ...'}
          ref={$ref}
        />
        {props.value.length > 0 && (
          <Button kind="inline" height={28} onClick={handleChange}>
            Clear
          </Button>
        )}
      </BoxSearchContainer>
    )
  }
)

type BoxCollapseProps = {
  title: React.Node,
  children: React.Node,
  isDefaultOpened?: boolean,
  ...
}

export const BoxCollapse = ({
  title,
  children,
  isDefaultOpened = false,
}: BoxCollapseProps): React.Node => {
  const state = useToggle(isDefaultOpened)

  const toggleBlur = useBlurOnClickCallback(() => {
    state.toggle
  }, [state.toggle])

  return (
    <BoxContainer style={{ overflow: 'hidden' }}>
      <BoxHeader
        onClick={state.toggle}
        style={{
          borderBottom: state.value ? 'inherit' : 'none',
          paddingRight: 20,
          cursor: 'pointer',
        }}
      >
        {title}
        <HeaderBoxActions>
          <Button onClick={toggleBlur}>
            <Icon icon={state.value ? 'chevron-up' : 'chevron-down'} />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>
      {state.value && children}
    </BoxContainer>
  )
}
