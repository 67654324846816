// @flow

import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { colors } from 'components/styled/tokens'
import { grayscale } from 'components/styled/tokens/schemes'

import {
  BaseThumb,
  InactiveEditorContainer,
  type ThemeProps,
} from 'com.batch/message-builder/ui/components/common'

type ThemeOnlyProps = PropsWithTheme<{ ... }, ThemeProps>
export const Notif: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  background: ${(p: ThemeOnlyProps) => (p.theme.isFocused ? grayscale[10] : grayscale['05'])};
  transition: background-color 0.2s ease;
  border-radius: 6px;
  overflow: hidden;
  pointer-events: ${(p: ThemeOnlyProps) => (p.theme.disabledMode ? 'none' : 'unset')};
  margin-top: ${(p: ThemeOnlyProps) => (p.theme.abTestingEnabled ? '197' : '160')}px;
  margin-bottom: ${(p: ThemeOnlyProps) => (p.theme.abTestingEnabled ? '83' : '90')}px;
  width: 386px;
`
export const NotifContent: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  padding: 10px 10px 10px 10px;
`
export const Sender: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  color: ${colors.textLight};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 142%;
  padding: 0 6px;
  grid-area: sender;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

type ThumbProps = { $url: string, ... }
type ThumbPropsAndTheme = PropsWithTheme<ThumbProps, ThemeProps>

export const Thumb: StyledComponent<ThumbProps, ThemeProps, *> = styled(BaseThumb)`
  display: ${(p: ThumbPropsAndTheme) =>
    !!p.$url || p.theme.draggingState !== 'NONE' ? 'block' : 'none'};
  position: relative;
  width: 100%;
  max-height: 320px;
  ${(p: ThumbPropsAndTheme) =>
    !!p.$url || p.theme.draggingState !== 'NONE'
      ? css`
          min-height: 160px;
        `
      : ''};
  overflow: hidden;

  ${(p: ThumbPropsAndTheme) =>
    p.$url &&
    css`
      background-image: url(${p.$url});
    `}
  background-size: cover;
  background-position: center center;
  img {
    width: 100%;
    display: block;
    visibility: hidden;
  }
`
type SmallIconProps = { $url: string, ... }
type SmallIconPropsAndTheme = PropsWithTheme<SmallIconProps, ThemeProps>

export const SmallIcon: StyledComponent<SmallIconProps, ThemeProps, *> = styled(BaseThumb)`
  display: ${(p: SmallIconPropsAndTheme) => (p.$url ? 'block' : 'none')};
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;

  ${(p: SmallIconPropsAndTheme) =>
    p.$url &&
    css`
      background-image: url(${p.$url});
    `}
  background-size: cover;
  background-position: center center;
  margin-top: 8px;
  grid-area: small;
`
export const Header: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  grid-column-gap: 8px;
  margin-bottom: 4px;
  align-items: center;
  i {
    align-self: start;
    justify-self: start;
    color: ${colors.textDisabled};
  }
  svg {
    display: block;
    width: 16px;
    height: 16px;
  }
`
export const ContentContainer: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  display: grid;
  ${(p: ThemeOnlyProps) =>
    p.theme.hasIcon
      ? css`
          grid-template-columns: 60px 1fr;
          grid-template-rows: auto;
          grid-template-areas:
            'small title'
            'small message'
            'small sender';
        `
      : css`
          grid-template-rows: auto;
          grid-template-areas:
            'title'
            'message'
            'sender';
        `}
`
export const BrowserName: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.text};
`
export const Title: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  color: ${colors.text};
  grid-area: title;
  font-style: normal;
  font-weight: 500;
  margin-bottom: ${(p: ThemeOnlyProps) => (p.theme.isFocused ? '6px' : 'unset')};
  transition: margin-bottom 0.2s ease;
  ${InactiveEditorContainer} {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
export const Message: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> = styled.div`
  color: ${colors.text};
  grid-area: message;
  margin-top: -2px;
  ${InactiveEditorContainer} {
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
`
