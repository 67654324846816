// @flow

import { get } from 'lodash-es'
import styled, { type StyledComponent } from 'styled-components'

import * as colorsLegacy from 'components/styled/colors'
import { colors } from 'components/styled/tokens'

// ====================== Some constants
const COLORS = {
  success: colorsLegacy.good.shade.s4,
  error: colors.textDanger,
  loading: colors.textAction,
  insight: colors.textLight,
  warning: '#ee8749',
}

// ====================== STYLED COMPONENTS
type FeedbackContainerProps = { _type: $Keys<typeof COLORS>, fontWeight?: number, ... }
const FeedbackContainer: StyledComponent<FeedbackContainerProps, *, HTMLElement> = styled.div`
  display: inline-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1em;
  font-weight: ${(p: FeedbackContainerProps) => p.fontWeight || 400};
  color: ${(p: FeedbackContainerProps) => get(COLORS, p._type, COLORS.insight)};
  line-height: 1.4em;
  .styled-feedback-icon {
    margin: 0 10px 0 2px;
    font-size: calc(1em - 1px);
  }
`

export { FeedbackContainer }
