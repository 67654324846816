// @flow

import { type List, type Map } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'

import { useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'

import { AddConditionModal } from './add-condition-modal'
import { Condition } from './condition'
import { JsonModal } from './json-modal'
import { LogicalToggle } from './logical-toggle'
import { QueryBuilderContext } from './query-builder.context'
import { LEVEL_COLORS, LogicalContainer, StyledToolbar } from './query.styles'

import { SegmentsCollectionAttribute, AudienceAttribute } from '../../redux/attribute.api'
import {
  QueryAttributeFactory,
  type QueryAttributeRecord,
  type ConditionRecord,
  type LogicalNodeRecord,
  ConditionFactory,
} from '../../redux/query/query.records'
import { actions, api } from 'com.batch.redux/query/query'

type LogicalProps = {
  node: LogicalNodeRecord,
  level: number,
  queryId: string,
  position: string,
  setTouched: () => void,
  conditions: Map<string, ConditionRecord>,
  attributes: List<QueryAttributeRecord>,
  isProfile?: boolean,
  parentIsLogicalNot?: boolean,
  hideQueryViewer?: boolean,
}

export const Logical = ({
  level,
  node,
  attributes,
  conditions,
  setTouched,
  queryId,
  position,
  isProfile,
  hideQueryViewer,
}: LogicalProps): React.Node => {
  const theme = useTheme()
  const { context, maxDepthReached } = React.useContext(QueryBuilderContext)
  const dispatch = useDispatch()

  // ------ Local state
  const attributeModalState = useToggle()

  // ------ Derived
  const disabledMode = React.useMemo(() => theme?.disabledMode, [theme])
  const showSegments = React.useMemo(
    () => (queryId === 'targeting' || queryId.startsWith('YESNO')) && isProfile,
    [isProfile, queryId]
  )

  const attributesWithSegmentFilteredOutWhenNotAvailable = React.useMemo(
    () => attributes.filter(attr => Boolean(showSegments) || attr.api !== 'bt.segments'),
    [attributes, showSegments]
  )
  const showUseAudiences = React.useMemo(
    () => attributes.some(attr => attr.api === 'bt.custom_audiences'),
    [attributes]
  )
  // ------ Callbacks
  const addLogicalAnd = React.useCallback(() => {
    dispatch(actions.addNode({ value: 'and', position, queryId }))
  }, [dispatch, position, queryId])
  const removeLogical = React.useCallback(() => {
    node.descendants.forEach(
      c => typeof c === 'string' && dispatch(actions.removeCondition({ id: c, queryId }))
    )
    dispatch(actions.removeCondition({ id: node.id, queryId }))
  }, [dispatch, node.id, node.descendants, queryId])
  const updateLogical = React.useCallback(
    (value: 'and' | 'or') => {
      setTouched()
      dispatch(actions.updateNode({ value, position, queryId }))
    },
    [dispatch, position, queryId, setTouched]
  )
  const addCondition = React.useCallback(() => {
    setTouched()
    dispatch(
      actions.addCondition({
        condition: ConditionFactory(),
        parentId: position,
        queryId,
      })
    )
  }, [dispatch, queryId, position, setTouched])

  const addConditionForAttribute = React.useCallback(
    (attribute: QueryAttributeRecord) => {
      setTouched()
      dispatch(
        actions.addCondition({
          condition: api.buildDefaultCondition(attribute),
          parentId: position,
          queryId,
        })
      )
    },
    [dispatch, queryId, position, setTouched]
  )

  const addConditionWithSegment = React.useCallback(() => {
    setTouched()
    dispatch(
      actions.addCondition({
        condition: api.buildDefaultCondition(
          QueryAttributeFactory({
            label: SegmentsCollectionAttribute.label,
            api: SegmentsCollectionAttribute.id,
            icon: SegmentsCollectionAttribute.icon,
            type: 'TAG',
          })
        ),
        parentId: position,
        queryId,
      })
    )
  }, [dispatch, queryId, position, setTouched])
  const addConditionWithAudience = React.useCallback(() => {
    setTouched()
    dispatch(
      actions.addCondition({
        condition: api.buildDefaultCondition(
          QueryAttributeFactory({
            label: AudienceAttribute.label,
            api: AudienceAttribute.id,
            icon: AudienceAttribute.icon,
            type: 'TAG',
          })
        ),
        parentId: position,
        queryId,
      })
    )
  }, [dispatch, queryId, position, setTouched])

  const barLayout = React.useMemo(() => {
    const nbBtn = (showUseAudiences ? 1 : 0) + (showSegments ? 1 : 0)
    switch (nbBtn) {
      case 1:
        return '136px 130px 130px 1fr 143px'
      case 2:
        return '136px 130px 130px 130px 1fr 143px'
      default:
        return '136px 130px 1fr 143px'
    }
  }, [showUseAudiences, showSegments])
  return (
    <LogicalContainer
      level={level}
      disabledMode={disabledMode}
      isNot={node.value === 'not'}
      isEmpty={node.descendants.size === 0 && level === 1}
    >
      {node.descendants.size === 0 && level === 1 && (
        <div className="styled-empty">
          <div>
            {level !== 1 && (
              <div style={{ paddingBottom: level === 1 ? 12 : 0 }}>No filter added</div>
            )}

            {level === 1 && context === 'targeting' && (
              <div>
                <Button
                  height={28}
                  disabled={disabledMode || maxDepthReached}
                  addOn="prefix"
                  kind="secondary"
                  style={{ marginRight: 10 }}
                  onClick={attributeModalState.open}
                >
                  <Icon icon="add" />
                  Add condition
                </Button>
                {showSegments && (
                  <Button
                    height={28}
                    disabled={disabledMode || maxDepthReached}
                    addOn="prefix"
                    kind="secondary"
                    style={{ marginRight: 10 }}
                    onClick={addConditionWithSegment}
                  >
                    <Icon icon="segments" />
                    Use segment
                  </Button>
                )}
                {showUseAudiences && (
                  <Button
                    height={28}
                    disabled={disabledMode || maxDepthReached}
                    addOn="prefix"
                    kind="secondary"
                    style={{ marginRight: 10 }}
                    onClick={addConditionWithAudience}
                  >
                    <Icon icon="targeting" />
                    Use audience
                  </Button>
                )}
                <Button
                  height={28}
                  addOn="prefix"
                  kind="inline"
                  onClick={addLogicalAnd || maxDepthReached}
                  disabled={level > 2 || disabledMode}
                  style={{ marginRight: 10 }}
                >
                  <Icon icon="enter" />
                  Create subgroup
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={
          'styled-descendants' + (context === 'event_filter' && level === 1 ? ' styled-scroll' : '')
        }
      >
        {node.descendants.map((child, indice) => {
          const condition =
            typeof child === 'string'
              ? conditions.get(child, ConditionFactory())
              : ConditionFactory()
          return (
            <React.Fragment key={typeof child === 'string' ? child : child.id}>
              {typeof child === 'string' ? (
                <Condition
                  disabledMode={disabledMode}
                  id={child}
                  queryId={queryId}
                  setTouched={setTouched}
                  withSeparator={condition.eventFilters.size === 0}
                  parentIsLogicalNot={node.value === 'not'}
                  attributes={attributesWithSegmentFilteredOutWhenNotAvailable}
                  condition={conditions.get(child, ConditionFactory())}
                />
              ) : node.value === 'not' ? (
                node.descendants.map((child, childKey) => {
                  return (
                    typeof child === 'string' && (
                      <Condition
                        id={child}
                        queryId={queryId}
                        withSeparator={
                          childKey !== node.descendants.size - 1 &&
                          condition.eventFilters.size === 0
                        }
                        setTouched={setTouched}
                        parentIsLogicalNot
                        attributes={attributesWithSegmentFilteredOutWhenNotAvailable}
                        disabledMode={disabledMode}
                        condition={condition}
                      />
                    )
                  )
                })
              ) : (
                <Logical
                  node={child}
                  setTouched={setTouched}
                  parentIsLogicalNot={node.value === 'not'}
                  attributes={attributes}
                  conditions={conditions}
                  level={level + 1}
                  position={child.id}
                  queryId={queryId}
                  isProfile={isProfile}
                  hideQueryViewer={hideQueryViewer}
                />
              )}
              {node.value !== 'not' &&
                node.descendants.size > 1 &&
                indice < node.descendants.size - 1 && (
                  <LogicalToggle
                    style={{
                      borderBottomLeftRadius: 0,
                      backgroundColor: LEVEL_COLORS[(level - 1) % 3],
                    }}
                    value={node.value}
                    onChange={updateLogical}
                  />
                )}
            </React.Fragment>
          )
        })}
      </div>
      {attributeModalState.value && (
        <AddConditionModal
          attributes={attributesWithSegmentFilteredOutWhenNotAvailable}
          closeModal={attributeModalState.close}
          addCondition={addConditionForAttribute}
        />
      )}
      {node.value !== 'not' &&
        context === 'targeting' &&
        (level > 1 || node.descendants.size > 0) && (
          <StyledToolbar withSeparator={level > 1}>
            <Grid template={barLayout}>
              {disabledMode ? (
                <React.Fragment>
                  <div />
                  <div />
                  <div />
                  {showUseAudiences && <div />}
                  {showSegments && <div />}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    height={28}
                    addOn="prefix"
                    kind="secondary"
                    disabled={maxDepthReached}
                    onClick={attributeModalState.open}
                  >
                    <Icon icon="add" />
                    Add condition
                  </Button>
                  {showSegments && (
                    <Button
                      height={28}
                      disabled={disabledMode || maxDepthReached}
                      addOn="prefix"
                      kind="secondary"
                      style={{ marginRight: 10 }}
                      onClick={addConditionWithSegment}
                    >
                      <Icon icon="segments" />
                      Use segment
                    </Button>
                  )}
                  {showUseAudiences && (
                    <Button
                      height={28}
                      disabled={disabledMode || maxDepthReached}
                      addOn="prefix"
                      kind="secondary"
                      style={{ marginRight: 10 }}
                      onClick={addConditionWithAudience}
                    >
                      <Icon icon="targeting" />
                      Use audience
                    </Button>
                  )}
                  <Button
                    height={28}
                    addOn="prefix"
                    kind="inline"
                    onClick={addLogicalAnd}
                    disabled={level > 2 || maxDepthReached}
                  >
                    <Icon icon="enter" />
                    Create subgroup
                  </Button>
                  <span />
                  {level > 1 && (
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        height={28}
                        addOn="suffix"
                        intent="danger"
                        kind="inline"
                        onClick={removeLogical}
                      >
                        <Icon icon="delete" />
                        Delete group
                      </Button>
                    </div>
                  )}
                </React.Fragment>
              )}
              {level === 1 && !hideQueryViewer && (
                <div style={{ textAlign: 'right' }}>
                  <JsonModal />
                </div>
              )}
            </Grid>
          </StyledToolbar>
        )}
      {node.value !== 'not' && context !== 'targeting' && !disabledMode && (
        <StyledToolbar withSeparator={level > 1}>
          <Grid template="1fr auto">
            <div />
            <Button height={28} addOn="prefix" kind="inline" onClick={addCondition}>
              <Icon icon="add" />
              Add condition
            </Button>
          </Grid>
        </StyledToolbar>
      )}
    </LogicalContainer>
  )
}
