// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { strokeDanger, textSuccess } from 'components/styled/tokens/colors'

import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'

// ====================== TYPES
type BooleanAttributeProps = {
  name: string,
  value?: boolean,
  isCopyable?: boolean,
  ...
}

export const BooleanAttributeRow = ({
  name,
  value,
  isCopyable = true,
}: BooleanAttributeProps): React.Node => {
  const boolStr = value ? 'Yes' : 'No'
  return (
    <AttributeRow name={name} icon="boolean" copyableValue={boolStr} isCopyable={isCopyable}>
      {value ? (
        <Icon icon="check" color={textSuccess} />
      ) : (
        <Icon icon="close" color={strokeDanger} />
      )}
      {boolStr}
    </AttributeRow>
  )
}
