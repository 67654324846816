// @flow

import styled, { type StyledComponent } from 'styled-components'

import { OptionContainer } from 'components/form/fields/checkbox.styles'

export const CheckableProject: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  ${OptionContainer} {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    margin: 16px 0;
  }
`
