// @flow

import * as React from 'react'

import { Button } from 'components/common/button'

const valuePattern = {
  negative: '-?[0-9]*',
  positive: '[0-9]*',
}

const ageRegex = {
  negative: /^(\-?\d+)([dhm])$/,
  positive: /^(\d+)([dhm])$/,
}

const units = [
  {
    key: 'm',
    label: 'minutes',
  },
  {
    key: 'h',
    label: 'hours',
  },
  {
    key: 'd',
    label: 'days',
  },
]

type AgePickerProps = {
  age: string,
  negative?: boolean,
  autofocus?: boolean,
  defaultUnit?: string,
  placeholder?: string,
  allowedUnits: Array<'m' | 'h' | 'd'>,
  onChange: (age: string) => any,
  ...
}

type AgePickerState = {
  stateIsValid: boolean,
  value: string,
  unit: string,
  ...
}

/**
 * Fully uncontrolled component with a key, because props and state reconciliation is hard.
 * The key shouldn't be the age.
 *
 * @see: https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
 */
class AgePicker extends React.Component<AgePickerProps, AgePickerState> {
  constructor(props: AgePickerProps) {
    super(props)

    this.state = Object.assign(
      { stateIsValid: true, value: '', unit: '' },
      this.getValueAndUnitFromProps()
    )
  }

  getPatternAndRegexKey(): 'negative' | 'positive' {
    return this.props.negative ? 'negative' : 'positive'
  }

  getValueAndUnitFromProps(): { value: string, unit: string, ... } {
    const { age, defaultUnit, allowedUnits } = this.props

    const patternAndRegexKey = this.getPatternAndRegexKey()
    const unserializedAge = age.match(ageRegex[patternAndRegexKey])

    let value = ''
    let unit = defaultUnit || allowedUnits[0]
    if (unserializedAge) {
      ;[value, unit] = unserializedAge.slice(1)
    }

    return { value, unit }
  }

  onChange(value: string, unit: string) {
    const { onChange } = this.props
    const age = `${value.trim()}${unit}`
    const patternAndRegexKey = this.getPatternAndRegexKey()

    if (age.match(ageRegex[patternAndRegexKey]) || value == '') {
      onChange(value !== '' ? age : '')
      this.setState({ stateIsValid: true })
    } else {
      this.setState({ stateIsValid: false })
    }

    this.setState({ value, unit })
  }

  render(): React.Node {
    const { autofocus = false, placeholder, allowedUnits } = this.props
    const { value, unit: selectedUnit } = this.state
    const patternAndRegexKey = this.getPatternAndRegexKey()
    const filteredUnits = units.filter(unit => allowedUnits.indexOf(unit.key) !== -1)

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 4 }}>
        <input
          type="text"
          placeholder={placeholder}
          pattern={valuePattern[patternAndRegexKey]}
          onChange={(event: SyntheticInputEvent<any>) =>
            this.onChange(event.target.value, selectedUnit)
          }
          autoFocus={autofocus}
          className="form-control v text-right"
          value={value}
          required
          style={{ minWidth: '71px', paddingRight: '18px' }}
        />

        {filteredUnits.map(unit => (
          <Button
            kind="inline"
            key={unit.key}
            style={{ marginLeft: 3, padding: 15 }}
            isActive={unit.key === selectedUnit}
            onClick={() => this.onChange(value, unit.key)}
          >
            {unit.label}
          </Button>
        ))}
      </div>
    )
  }
}

export default AgePicker
