// @flow

import { type List } from 'immutable'
import { useCallback } from 'react'

import { type sortDirection } from 'com.batch/shared/hooks/use-sort-params'

type Comparative = $Shape<{
  [key: string]: any,
  ...
}>

/**
 * Custom hook to handle sorting of entities given a specific key
 */
export const useSort = <T: Comparative>(): ({
  sortEntitiesByKey: (entities: List<T>, key: string, direction: sortDirection) => List<T>,
  ...
}) => {
  const sortEntitiesByKey = useCallback(
    (entities: List<T>, key: string, direction: sortDirection): List<T> => {
      return entities.sort((a, b) => {
        const bigger = direction === 'asc' ? 1 : -1
        const smaller = direction === 'asc' ? -1 : 1

        if (a[key] < b[key]) {
          return smaller
        }

        if (a[key] > b[key]) {
          return bigger
        }
        return 0
      })
    },
    []
  )

  return { sortEntitiesByKey }
}
