// @flow

import { type Dayjs } from 'dayjs'
import * as React from 'react'

import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
} from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { DebugTriggerHour } from './debug.style'

import { Ellipsis } from '../../styled/text'
import { type AppRecord } from 'com.batch.redux/_records'

export type DebugTriggerResult = {
  entered: Dayjs,
  exited?: ?Dayjs,
  campaign_token: string,
  campaign_name: string,
  exit_cause: string,
  ...
}

type DebugTriggerProps = {
  results: Array<DebugTriggerResult>,
  app: AppRecord,
  loading: boolean,
  ...
}

type sortableFields = 'entered' | 'exited' | 'exit_cause' | 'campaign_name'

const GridTemplate = 'minMax(130px,1fr) minMax(190px,232px) minMax(190px,232px) minMax(100px,212px)'

const ExitTooltip = {
  push_triggered: 'Push triggered',
  cancel_event: 'Exit event was triggered and cancelled the campaign',
  stop: 'Campaign was stopped',
  push_done: 'Push sent',
  no_pushtoken: 'No push token found, unable to send notification',
  push_skipped: 'Push sent was skipped due to capping restriction',
  push_token_not_registered: 'Push token was no longer registered, unable to send notification',
  query_mismatch: 'User no longer matched the targeting rule for the trigger campaign',
}

export const DebugTrigger = ({ results, app, loading }: DebugTriggerProps): React.Node => {
  const [sortField, setSortField] = React.useState<sortableFields>('entered')
  const [sortOrder, setSortOrder] = React.useState<'dsc' | 'asc'>('dsc')

  const sort = React.useCallback(
    (field: sortableFields) => {
      if (field === sortField) {
        setSortOrder(sortOrder === 'asc' ? 'dsc' : 'asc')
      } else {
        setSortOrder('dsc')
        setSortField(field)
      }
    },
    [sortField, sortOrder]
  )

  const sortedResults = results.sort((a, b) => {
    const offset = sortOrder === 'asc' ? -1 : 1
    switch (sortField) {
      case 'entered':
        return a.entered.isBefore(b.entered) ? offset : -offset
      case 'exited':
        const aExit = a.exited || dayjs.utc()
        const bExit = b.exited || dayjs.utc()
        return aExit.isBefore(bExit) ? offset : -offset
      default:
        return (a[sortField] ? a[sortField].toLowerCase() : '') <
          (b[sortField] ? b[sortField].toLowerCase() : '')
          ? offset
          : -offset
    }
  })
  // ----------- Callbacks ---
  const onSortCreator = React.useCallback((field: sortableFields) => () => sort(field), [sort])
  return !loading && results.length === 0 ? (
    <div
      style={{
        display: 'flex',
        color: '#8F959E',
        fontSize: 15,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: 500,
      }}
    >
      <div>
        This installation did not trigger any Trigger campaign.
        <p style={{ fontSize: 13, lineHeight: '20px', marginTop: 5 }}>
          Only data from the last 10 days are displayed.
        </p>
      </div>
    </div>
  ) : (
    <Table template={GridTemplate}>
      <TableHeader>
        <TableCellOrder
          sort={sortField === 'campaign_name' ? sortOrder : undefined}
          onClick={onSortCreator('campaign_name')}
        >
          Campaign name
        </TableCellOrder>

        <TableCellOrder
          sort={sortField === 'entered' ? sortOrder : undefined}
          onClick={onSortCreator('entered')}
        >
          Entered
        </TableCellOrder>

        <TableCellOrder
          sort={sortField === 'exited' ? sortOrder : undefined}
          onClick={onSortCreator('exited')}
        >
          Exited
        </TableCellOrder>

        <TableCellOrder
          sort={sortField === 'exit_cause' ? sortOrder : undefined}
          onClick={onSortCreator('exit_cause')}
        >
          Exit cause
        </TableCellOrder>
      </TableHeader>
      <TableBody style={{ overflowY: 'auto', height: 456 }}>
        {sortedResults.map((r, key) => (
          <TableRow key={key} template={GridTemplate}>
            <TableCell>
              <a
                href={`/${app.companyId || ''}/apps/${app.id}/campaigns/push/${r.campaign_token}`}
                target="_blank"
              >
                <FlexLine style={{ minWidth: 0 }}>
                  <FlexLineItem ellipsis>{r.campaign_name}</FlexLineItem>
                  <FlexLineItem width={15}>
                    <Icon icon="arrow-long-right" />
                  </FlexLineItem>
                </FlexLine>
              </a>
            </TableCell>
            <TableCell>
              {r.entered.format('YYYY-MM-DD')}&nbsp;
              <DebugTriggerHour>{r.entered.format('HH:mm:ss')}</DebugTriggerHour>
            </TableCell>
            <TableCell>
              {r.exited ? (
                <React.Fragment>
                  {r.exited.format('YYYY-MM-DD')}&nbsp;
                  {r.exited && <DebugTriggerHour>{r.exited.format('HH:mm:ss')}</DebugTriggerHour>}
                </React.Fragment>
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell>
              <Tooltip
                isTooltipEmpty={!ExitTooltip.hasOwnProperty(r.exit_cause)}
                tooltip={ExitTooltip.hasOwnProperty(r.exit_cause) ? ExitTooltip[r.exit_cause] : ''}
              >
                <Ellipsis>{r.exit_cause}</Ellipsis>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
        <div
          style={{
            backgroundColor: '#fafafa',
            color: '#8F959E',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
            minHeight: 455 - results.length * 49,
          }}
        >
          Only data from the last 10 days are displayed.
        </div>
      </TableBody>
    </Table>
  )
}
