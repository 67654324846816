// @flow

// @todo : typer les props, j'ai fait dun * partout là

import styled, { type PropsWithTheme, type StyledComponent } from 'styled-components'

import { SubNav } from 'components/styled/subnav'
import { Title } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

export const BatchBoxShadow =
  'box-shadow: 0 1px 3px rgba(50, 50, 93, 0.1), 0 6px 8px 0px rgba(50, 50, 93, 0.04);'
type TypoBlocProps = { center?: boolean, ... }
export const TypoBloc: StyledComponent<TypoBlocProps, { ... }, HTMLDivElement> = styled.div`
  text-align: ${({ center }: TypoBlocProps) => (center ? 'center' : 'auto')};
  padding: 0 25px;
  p {
    margin-bottom: 10px;
  }
`

type ContentProps = {
  top?: boolean,
  menu?: boolean,
  bottom?: boolean,
  noPadding?: boolean,
  ...
}
export const Content: StyledComponent<ContentProps, *, HTMLDivElement> = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: ${(props: ContentProps) => {
    if (props.top) return '26px 32px 28px 32px'
    if (props.menu) return '0 32px 0 32px'
    if (props.bottom) return '32px 32px 0 32px'
    if (props.noPadding) return '0'
    return '38px 32px'
  }};
`

export const HeaderTitle: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: inline-flex;
  display: block;
  /* align-items: last baseline; */
  /* margin: 0 120px 16px 0; */
  margin: 0 120px 0 0;

  @media screen and (max-width: 1380px) {
    margin: 0 100px 16px 0;
  }
  @media screen and (max-width: 1300px) {
    margin: 0 20px 16px 0;
  }

  ${Title} {
    flex: 0 0 auto;
    margin: 0 24px 0 0;
  }
  ${Title} + ${SubNav} {
    display: block;
    margin: 8px 0 0 0;
  }
`
export const ActionsGroup: StyledComponent<*, *, *> = styled.div`
  display: inline-flex;

  & + & {
    position: relative;
    margin-left: 20px;

    &:after {
      position: absolute;
      top: 6px;
      bottom: 6px;
      left: -10px;
      content: '';
      border-left: 1px solid ${colors.stroke};
    }
  }
`
type HeaderActionsProps = { grow?: boolean, ... }
export const HeaderActions: StyledComponent<HeaderActionsProps, *, HTMLDivElement> = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  flex: ${(props: HeaderActionsProps) => (props.grow ? '1' : 'none')};
`
export const Header: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin: 8px 0 38px 0;
`
type FixedTheme = { fixed?: boolean, ... }

export const LightHeader: StyledComponent<{ ... }, FixedTheme, HTMLDivElement> = styled.div`
  border-bottom: 1px solid #e6e9f1;
  box-shadow: ${(props: PropsWithTheme<{ ... }, FixedTheme>) =>
    props.theme.fixed ? '4px 1px 8px rgba(0, 0, 0, 0.03)' : 'none'};
  z-index: 2;
  position: relative;
`

type ThemedLightHeaderMainProps = PropsWithTheme<ContentProps, FixedTheme>
export const LightHeaderMain: StyledComponent<ContentProps, FixedTheme, HTMLDivElement> =
  styled.div`
    position: relative;
    margin: 0 auto;
    padding: ${(props: ThemedLightHeaderMainProps) => {
      if (props.top) return '28px 32px 28px 32px'
      if (props.menu) return '0 32px 0 32px'
      if (props.bottom) return '32px 32px 0 32px'
      if (props.noPadding) return '0'
      return '38px 32px'
    }};
    background-color: ${(props: ThemedLightHeaderMainProps) =>
      props.theme.fixed ? '#ffffff' : '#f6f7fa'};
    background-color: #f6f6f6;
    background-color: #fbfbfb;
    background-color: ${(p: ThemedLightHeaderMainProps) => (p.theme.fixed ? '#fff' : '#fbfbfb')};

    @media screen and (min-width: 1500px) {
      padding: 28px calc((100% - 1200px) / 2 + 32px);
    }
  `

export const LightHeaderSub: StyledComponent<*, *, HTMLDivElement> = styled.div`
  padding: 0 32px;
  background-color: ${(props: PropsWithTheme<any, any>) =>
    props.theme.fixed ? 'rgba(254, 254, 254, 0.98)' : '#f3f4f8'};
  background-color: #f7f7f7;
  background-color: #fbfbfb;
  border-top: 1px solid #edeff5;

  @media screen and (min-width: 1500px) {
    padding: 0 calc((100% - 1200px) / 2 + 32px);
  }
`

export const DarkBox: StyledComponent<*, *, HTMLDivElement> = styled.div`
  background: HSL(224, 27%, 97%);
  padding: 1px 0;
`

export const BlocContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #f2f3f8;
  display: flex;
  justify-content: center;
  align-items: center;
`
type BlocCenterProps = { bl?: boolean, ... }
export const BlocCenter: StyledComponent<BlocCenterProps, *, HTMLDivElement> = styled.div`
  flex: 0 0 50%;
  padding: 10px;
  ${(props: BlocCenterProps) => (props.bl ? 'border-left: 1px solid #F2F3F8;' : '')} > * {
    margin: 0 auto;
    position: relative;
  }
`
type FixedProps = { max?: number, width?: number, ... }
export const Fixed: StyledComponent<FixedProps, *, HTMLDivElement> = styled.div`
  width: ${(props: FixedProps) => (props.width ? `${props.width}px` : 'auto')};
  max-width: ${(props: FixedProps) => (props.max ? `${props.max}px` : 'none')};
`

export const Well: StyledComponent<*, *, HTMLDivElement> = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f2f3f8;
  border-radius: 3px;
  text-align: center;
`

export const BoxInfo: StyledComponent<*, *, HTMLDivElement> = styled.div`
  background: #fdfdfd;
  padding: 16px 32px;
  border-bottom: 1px solid ${colors.stroke};
  margin-bottom: 24px;
  color: #5f666c;
`
