// @flow

import * as React from 'react'
import { createRoot } from 'react-dom/client'

// ========================= COMPONENTS
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { WrapLabel } from 'components/common/form/wrap-label'
import { Popin } from 'components/common/popin/popin'

type DialogBaseProps = {
  message: React.Node,
  label: string,
  defaultValue: ?string,
  title?: string,
  confirm?: string,
  cancel?: string,
}

type DialogProps = {
  ...DialogBaseProps,
  resolver: (value: string) => void,
  rejecter: () => void,
}

const Dialog = ({
  message = '',
  confirm = 'ok',
  label,
  title = 'Are you sure?',
  cancel,
  defaultValue,
  resolver,
  rejecter,
}: DialogProps) => {
  const [value, setValue] = React.useState(defaultValue ?? '')
  const onValueChange = React.useCallback(
    (e: SyntheticInputEvent<HTMLInputElement>) => setValue(e.target.value),
    []
  )
  const onConfirm = React.useCallback(
    () => (value ? resolver(value) : rejecter()),
    [rejecter, resolver, value]
  )
  return (
    <Popin close={rejecter} opened style={{ maxWidth: '620px' }}>
      <Box noBorder>
        <BoxHeader>
          <HeaderBoxTitle title={title ?? ''} />
        </BoxHeader>
        <BoxBody padding>
          {message}
          <WrapLabel label={label}>
            <input type="text" className="form-control" value={value} onChange={onValueChange} />
          </WrapLabel>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" onClick={rejecter}>
            {cancel}
          </Button>
          <FooterBoxActions>
            <Button kind="primary" intent="action" onClick={onConfirm}>
              {confirm}
            </Button>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    </Popin>
  )
}

const confirm = (props: DialogBaseProps): Promise<string> => {
  const body = document.body
  if (!body) throw new Error('confirm called in a non DOM context')
  body.classList.add('modal-open')
  const wrapper = body.appendChild(document.createElement('div'))

  const root = createRoot(wrapper)
  let resolver: string => void = () => undefined
  let rejecter = () => undefined
  const promise = new Promise<string>((resolve, reject) => {
    resolver = (value: string) => {
      root.unmount()
      setTimeout(() => wrapper.remove())
      resolve(value)
    }
    rejecter = () => {
      root.unmount()
      setTimeout(() => wrapper.remove())
      reject(false)
    }
  })
  root.render(<Dialog {...props} resolver={resolver} rejecter={rejecter} />)
  return promise
}

export default confirm
