// @flow
import Immutable, { type List } from 'immutable'

import { configs, type parserReturns } from './fetch-dynamic-stats.helper'

import {
  type OrchestrationAnalyticsDateRangeRecord,
  type OrchestrationAnalyticsFilters,
} from '../store/orchestration-analytics.state'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

const abortFetchDynamicStatsControllers: {
  [string]: AbortController,
} = {}
export const fetchDynamicStats = ({
  tokens,
  filters = Immutable.Map(),
  dateRange = null,
  config,
  includePreviousPeriod = false,
}: {
  tokens: List<string>,
  filters?: OrchestrationAnalyticsFilters,
  dateRange?: ?OrchestrationAnalyticsDateRangeRecord,
  config: $Keys<typeof configs>,
  includePreviousPeriod?: boolean,
}): DispatchExtraBoundFn<Promise<parserReturns>> => {
  const { actionName, groupBy, parser } = configs[config]

  if (!abortFetchDynamicStatsControllers[actionName])
    abortFetchDynamicStatsControllers[actionName] = new AbortController()

  return async (dispatch, getState, { dataService }) => {
    abortFetchDynamicStatsControllers[actionName].abort()
    abortFetchDynamicStatsControllers[actionName] = new AbortController()

    const { groupDimensionNames, includeFilterDimensions } = groupBy
    const state = getState()
    const project = currentProjectSelector(state)
    const includeFilterDimensionsOverride = [
      ...(includeFilterDimensions || []),
      ...filters.reduce(
        (acc, values, dimensionName) => [
          ...acc,
          { dimensionName, dimensionValues: values.toArray() },
        ],
        []
      ),
    ]
    const dateRangeFilter = dateRange
      ? {
          dateFrom: dateRange.from,
          dateTo: dateRange.to,
        }
      : undefined

    return promiseActionCreator({
      dispatch,
      meta: { tokens },
      payload: { tokens },
      promise: dataService.fetchDynamicStats({
        project,
        tokens,
        groupDimensionNames,
        includeFilterDimensions: includeFilterDimensionsOverride,
        ...dateRangeFilter,
        parser,
        abortSignal: abortFetchDynamicStatsControllers[actionName].signal,
        includePreviousPeriod,
      }),
      actionName,
    })
  }
}
