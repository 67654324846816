// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import { type StateToggler } from 'components/_hooks'
import { Button, DropdownMenu, useDropdown } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { HorizontalDivider } from 'components/styled/utils'

import { getAreRequiredEmailFieldsFilledSelector } from 'com.batch/email/store/email-automation.selector'
import { previewLanguageSelector } from 'com.batch/message/store/message.selector'

import { SendTestEmailButton } from 'com.batch/email/ui/components/send-test-email-button'
import { updateEmailContent } from 'com.batch/email/usecases/update-email-content'
import { Toolbar, ToolbarDivider } from 'com.batch/shared/ui/component/toolbar/toolbar.styles'

type PreviewToolbarProps = {
  sendTestEmailPopinState: StateToggler,
  dynamicContentPopinState: StateToggler,
  editTemplate: () => void,
  messageId: string,
}
export const EmailPreviewToolbar = ({
  sendTestEmailPopinState,
  dynamicContentPopinState,
  editTemplate,
  messageId,
}: PreviewToolbarProps): React.Node => {
  const { dropdownProps, triggerProps, closeDropdown } = useDropdown({ placement: 'top' })
  const getAreRequiredEmailFieldsFilled = useSelector(getAreRequiredEmailFieldsFilledSelector)
  const areRequiredEmailFieldsFilled = React.useMemo(
    () => getAreRequiredEmailFieldsFilled(messageId),
    [getAreRequiredEmailFieldsFilled, messageId]
  )
  const dispatch = useDispatch()
  const lang = useSelector(previewLanguageSelector)

  const clearContent = React.useCallback(() => {
    dispatch(
      updateEmailContent({
        messageId,
        lang,
        field: 'html',
        value: null,
        htmlEditorConfig: null,
        isInstant: true,
      })
    )
  }, [dispatch, lang, messageId])

  const handlePreviewAs = React.useCallback(() => {
    dynamicContentPopinState.open()
    closeDropdown()
  }, [closeDropdown, dynamicContentPopinState])

  const theme = useTheme()
  return (
    <Toolbar style={{ position: 'absolute', bottom: 12 }}>
      <Button intent="action" onClick={editTemplate} data-testid="button_view_edit_email">
        <Icon icon={theme?.disabledMode ? 'view' : 'edit'} style={{ marginRight: 10 }} />
        {theme?.disabledMode ? 'View' : 'Edit'} email
      </Button>

      <ToolbarDivider />

      <SendTestEmailButton
        messageId={messageId}
        hasMandatoryInfo={areRequiredEmailFieldsFilled}
        togglePopinSendTest={sendTestEmailPopinState}
      />

      <ToolbarDivider />

      <Button intent="action" {...triggerProps}>
        <Icon icon="options" />
      </Button>

      <DropdownMenu {...dropdownProps}>
        <Button addOn="prefix" onClick={handlePreviewAs}>
          <Icon icon="user" />
          Preview as ...
        </Button>
        <HorizontalDivider margin="4px" />
        <Button
          addOn="prefix"
          onClick={clearContent}
          disabled={theme?.disabledMode}
          intent="danger"
        >
          <Icon icon="delete" />
          Delete
        </Button>
      </DropdownMenu>
    </Toolbar>
  )
}
