// @flow

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { FOCUS_COMMAND, BLUR_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical'
import { useLayoutEffect } from 'react'

export function LexicalFocusPlugin({
  onBlur,
  onFocus,
}: {
  onFocus?: () => void,
  onBlur?: () => void,
  ...
}): null {
  const [editor] = useLexicalComposerContext()

  useLayoutEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          onFocus && onFocus()
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onBlur && onBlur()
          return true
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [editor, onBlur, onFocus])
  return null
}
