// @flow
import Immutable, { type List, type Map } from 'immutable'

import { type MessageConfigRecord, type VariantId } from './message.records'

import { type LanguageRecord } from 'com.batch.redux/_records'

export const getMessageId = (config: MessageConfigRecord): string => {
  let value: ?string = config.messageTypedId
  if (config.experiment.enabled)
    value = config.experiment.variants.get(config.experiment.selectedVariantId)
  if (!value) throw new Error('Undefined message id')
  return value
}

export const getMessageIds = (config: MessageConfigRecord): List<string> => {
  if (!config.experiment.enabled) return new Immutable.List().push(config.messageTypedId)
  return config.experiment.variants.toList()
}

export const getMessageKey = (lang: LanguageRecord): string =>
  `Message${lang.value === 'default' ? '' : ` (${lang.label.toLowerCase()})`}`

/*
  we need a total of 100% or backend crashes
  this is a really dumb func to handle our case with 2, 3 or 4 variants
*/
export const getWeight = ({
  variantId,
  count,
}: {
  variantId: VariantId,
  count: number,
}): number => {
  if (count === 0) return 1
  if (count === 3) return variantId === 1 ? 34 : 33
  return 100 / count
}

export const coerceNumberToVariantId = (value?: number): VariantId => {
  switch (value) {
    case 1:
    case 2:
    case 3:
    case 4:
      return value
    default:
      return 1
  }
}

export const removeVariantIdWithoutGap = (
  variants: Map<VariantId, string>,
  variantId: VariantId
): Map<VariantId, string> => {
  // si on enlève le dernier item, rien à faire de compliqué
  if (variantId === variants.size) return variants.filter((_, id) => id !== variantId)
  /* 
      sinon on fait une nouvelle map où:
        - on ne garde pas l'item à l'index variantId
        - on décale les index supérieurs à variantId de - 1
   */
  let newVariants: Map<VariantId, string> = Immutable.Map()
  variants.forEach((value, id) => {
    if (id === variantId) return
    newVariants = newVariants.set(id > variantId ? coerceNumberToVariantId(id - 1) : id, value)
  })
  return newVariants
}
