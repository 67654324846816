// @flow

export const customDataTypeToTypeAttribute = (
  type?: 'TAGS' | 'ATTRIBUTES' | 'EVENTS',
  typeValue: string
): string => {
  if (type === 'TAGS') return 'TAG'
  if (type === 'EVENTS') return 'EVENT'
  if (type === 'ATTRIBUTES') {
    if (typeValue === 'BOOL') return 'BOOLEAN'
    if (typeValue === 'LONG') return 'INTEGER'
    if (typeValue === 'DOUBLE') return 'FLOAT'
    return typeValue
  }
  return typeValue
}

type EventDataTypeAttribute =
  | 'URL'
  | 'STRING'
  | 'BOOLEAN'
  | 'INTEGER'
  | 'DATE'
  | '__LABEL__'
  | '__TAG__'

export const customEventTypeToEventDataTypeAttribute = (
  type?: 'TAGS' | 'ATTRIBUTES' | 'EVENTS',
  typeAttribute: 'URL' | 'STRING' | 'BOOL' | 'LONG' | 'DATE' | 'DOUBLE'
): EventDataTypeAttribute => {
  if (type === 'TAGS') return '__TAG__'
  if (typeAttribute === 'BOOL') return 'BOOLEAN'
  if (typeAttribute === 'LONG' || typeAttribute === 'DOUBLE') return 'INTEGER'
  return typeAttribute
}

export const addPrefixOnValue = ({
  type,
  value,
}: {
  type?: 'TAGS' | 'ATTRIBUTES' | 'EVENTS',
  value: string,
  ...
}): string => {
  if (type === 'TAGS') return `t.${value}`
  if (type === 'EVENTS') return `e.${value}`
  return value
}
