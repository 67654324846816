// @flow
import { type Map } from 'immutable'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'

export const retrieveFirstNameAndLastName = (
  attributes: Map<string, QueryValue>
): { firstName: ?string, lastName: ?string, ... } => {
  const firstName = findValueByKey(attributes, [
    'firstname',
    'first_name',
    'firstName',
    'first-name',
  ])
  const lastName = findValueByKey(attributes, ['lastname', 'last_name', 'lastName', 'last-name'])
  return { firstName, lastName }
}

const findValueByKey = (map: Map<string, QueryValue>, keysToFind: Array<string>): ?string => {
  const matchingKey = keysToFind.find(key => map.has(key))
  return matchingKey ? map.get(matchingKey)?.stringValue : null
}
