// @flow

import { type Map } from 'immutable'

import { type ProfileDataRecord } from 'com.batch/profile/models/profile.records'

export const getDefaultProfileId = (profiles: Map<string, ProfileDataRecord>): string => {
  const firstKey = profiles.keySeq().first()
  return profiles.get(firstKey)?.id ?? ''
}
