// @flow
import * as React from 'react'
import { useTheme } from 'styled-components'

import { EmptyField } from 'components/common/empty-states'

import { LegendIcon } from './legend-icon'
import { LegendContainer, Legend } from './legend.styles'

import { type ChartData } from 'com.batch/shared/infra/types/chart-data'

type Props = {
  numberLegendLoading?: number,
  data: Array<ChartData>,
  clickedIndex: number,
  setClickedIndex: (index: number) => void,
  hoveredIndex: number | null,
  setHoveredIndex: (index: number | null) => void,
}
export const Legends = ({
  data,
  numberLegendLoading = 3,
  clickedIndex,
  setClickedIndex,
  hoveredIndex,
  setHoveredIndex,
}: Props): React.Node => {
  const { isLoading, isEmpty } = useTheme()
  const isHover = React.useMemo(() => hoveredIndex !== clickedIndex, [clickedIndex, hoveredIndex])

  const getColor = React.useCallback(
    (index: number) => {
      if (index === clickedIndex) return data[index].color.active
      if (isHover && index === hoveredIndex) return data[index].color.hover
      return data[index].color.default
    },
    [data, clickedIndex, isHover, hoveredIndex]
  )

  const isActiveLegend = React.useCallback(
    (index: number) => index === clickedIndex,
    [clickedIndex]
  )

  return (
    <LegendContainer>
      {isLoading || isEmpty
        ? Array.from({ length: numberLegendLoading }, (_, index: number) => (
            <Legend key={index}>
              <LegendIcon pattern={null} isClicked={false} color="red" />
              <EmptyField _width={80} _height={14} />
            </Legend>
          ))
        : data.map((d, index) => (
            <Legend
              key={index}
              onClick={setClickedIndex(index)}
              onMouseEnter={setHoveredIndex(index)}
              onMouseLeave={setHoveredIndex(null)}
              // disabled={value === 0}
              active={isActiveLegend(index)}
            >
              <LegendIcon
                pattern={d.color.pattern}
                isClicked={isActiveLegend(index)}
                color={getColor(index)}
              />
              {d.label}
            </Legend>
          ))}
    </LegendContainer>
  )
}
