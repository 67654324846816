// @flow

import * as React from 'react'

// ====================== COMPONENT
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Form, InputWrapper, Input } from 'components/form'
import { CapsLabel, LinkArrow } from 'components/styled/text'

import {
  CertifSettingsInfo,
  CertifExpirDate,
  CertifExpirStatus,
  CertifExpirRelative,
  CertifSettingsExtra,
  CertifSettingsDocLink,
} from './settings.styles'

import { type P8ConfigRecord } from 'com.batch.redux/_records'

type SettingsReviewP8Props = {
  config: P8ConfigRecord,
  updateTopic: (topic: string) => ?Promise<void>,
  ...
}

export const SettingsReviewP8: React.ComponentType<SettingsReviewP8Props> = React.memo(
  ({ config, updateTopic }: SettingsReviewP8Props) => {
    const [topic, setTopic] = React.useState<string>(config.topic)
    const [isEditing, setIsEditing] = React.useState<boolean>(false)
    const [saving, setSaving] = React.useState<boolean>(false)
    const onEdit = React.useCallback(() => setIsEditing(true), [])
    const onCancel = React.useCallback(() => {
      setIsEditing(false)
      setTopic(config.topic)
    }, [config.topic])
    const onTopicChange = React.useCallback(evt => setTopic(evt.target.value), [])
    const onSubmit = React.useCallback(() => {
      setSaving(true)
      const promise = updateTopic(topic)
      if (promise) {
        const done = () => {
          setIsEditing(false)
          setSaving(false)
        }
        promise.then(done, done)
      }
    }, [topic, updateTopic])

    return (
      <Grid template="55% 1fr" alignItems="stretch">
        <div style={{ borderRight: '1px solid #eff0f4' }}>
          <CertifSettingsInfo>
            <CapsLabel>Expires</CapsLabel>
            <div>
              <CertifExpirRelative muted>&mdash;</CertifExpirRelative>
              <CertifExpirDate intent="success">This key does not expire.</CertifExpirDate>
              <CertifExpirStatus intent="success">
                <Tooltip tooltip="Your certificate is up to date">
                  <Icon icon="success" style={{ padding: 4 }} />
                </Tooltip>
              </CertifExpirStatus>
            </div>
          </CertifSettingsInfo>

          <CertifSettingsInfo>
            <CapsLabel>Format</CapsLabel>
            <span>JWT APN .p8 Private Key (p8)</span>
          </CertifSettingsInfo>

          <CertifSettingsInfo noBorder>
            <CapsLabel>Uploaded on</CapsLabel>
            <span>{config.uploadedOn.format('DD/MM/YYYY')}</span>
          </CertifSettingsInfo>
        </div>

        <div style={{ padding: '40px 22px', height: 382, position: 'relative' }}>
          <p style={{ marginBottom: 10 }}>Additional informations</p>

          <Form onSubmit={onSubmit}>
            <CertifSettingsExtra>
              <CapsLabel>Topic</CapsLabel>
              {!isEditing ? (
                <span>{topic}</span>
              ) : (
                <InputWrapper>
                  <Input
                    name="topic"
                    disabled={saving}
                    value={topic}
                    className="fs-exclude"
                    onChange={onTopicChange}
                    style={{ marginLeft: -12 }}
                    aria-label="Topic"
                  />
                </InputWrapper>
              )}
            </CertifSettingsExtra>

            <CertifSettingsExtra>
              <CapsLabel>Team ID</CapsLabel>
              {!isEditing ? (
                <span>{config.teamId}</span>
              ) : (
                <InputWrapper>
                  <Input
                    readOnly
                    disabled
                    name="team"
                    className="fs-exclude"
                    value={config.teamId}
                    style={{ marginLeft: -12 }}
                    aria-label="Team ID"
                  />
                </InputWrapper>
              )}
            </CertifSettingsExtra>

            <CertifSettingsExtra>
              <CapsLabel>Key ID</CapsLabel>
              {!isEditing ? (
                <span>{config.keyId}</span>
              ) : (
                <InputWrapper>
                  <Input
                    readOnly
                    disabled
                    name="key"
                    className="fs-exclude"
                    value={config.keyId}
                    style={{ marginLeft: -12 }}
                    aria-label="Key ID"
                  />
                </InputWrapper>
              )}
            </CertifSettingsExtra>

            {!isEditing ? (
              <Button kind="secondary" onClick={onEdit} style={{ marginTop: 14 }}>
                Edit informations
              </Button>
            ) : (
              <React.Fragment>
                <Button type="button" kind="secondary" onClick={onCancel} style={{ marginTop: 14 }}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  kind="primary"
                  intent="action"
                  style={{ marginTop: 14, marginLeft: 12 }}
                >
                  Validate
                </Button>
              </React.Fragment>
            )}
          </Form>

          <CertifSettingsDocLink>
            Need more help? Let's check the{' '}
            <LinkArrow
              intent="action"
              href="https://doc.batch.com/ios/prerequisites.html#_creating-a-certificate"
              target="_blank"
            >
              documentation
            </LinkArrow>
          </CertifSettingsDocLink>
        </div>
      </Grid>
    )
  }
)
