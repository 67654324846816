// @flow

import * as React from 'react'

import { useQuery } from 'components/_hooks'
import { Grid } from 'components/common/grid'
import { Hint } from 'components/common/hint'
import { TableCellOrder, TableHeader } from 'components/common/table'

import { type AudienceListSortTable } from 'com.batch/audience/store/audience-profile.state'

type HeaderProps = {
  onSortChange: (field: AudienceListSortTable) => () => void,
  ...
}

const coerceOrder = (value: ?string) => (value === 'asc' || value === 'dsc' ? value : 'dsc')
export const Header = ({ onSortChange }: HeaderProps): React.Node => {
  const query = useQuery()
  const qSortBy = query.get('orderBy') || 'updated'

  const qSortOrder = coerceOrder(query.get('orderDirection'))

  return (
    <TableHeader style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
      <TableCellOrder
        sort={qSortBy === 'display_name' ? qSortOrder : undefined}
        onClick={onSortChange('display_name')}
      >
        Display name
      </TableCellOrder>

      <TableCellOrder
        sort={qSortBy === 'name' ? qSortOrder : undefined}
        onClick={onSortChange('name')}
      >
        Name
      </TableCellOrder>

      <TableCellOrder
        sort={qSortBy === 'type' ? qSortOrder : undefined}
        onClick={onSortChange('type')}
      >
        Type
      </TableCellOrder>

      <TableCellOrder
        align="right"
        sort={qSortBy === 'nb_ids' ? qSortOrder : undefined}
        onClick={onSortChange('nb_ids')}
        style={{ marginRight: '16px' }}
      >
        <Grid template="auto auto" gap={0}>
          <span>
            Estimate
            <Hint minTooltipWidth={370}>
              <div style={{ textAlign: 'left' }}>
                <p style={{ marginBottom: 4, lineHeight: '18px' }}>
                  The <strong>estimated</strong> number of <strong>profiles</strong> Batch knows
                  amongst IDs provided in your audience.
                </p>
                <p style={{ lineHeight: '18px' }}>
                  These numbers are calculated live on a <strong>sample of 32.000 profiles</strong>{' '}
                  from your userbase. On a large userbase or with a small audience, the estimate may
                  be <strong>inaccurate</strong>.
                </p>
              </div>
            </Hint>
          </span>
          <span>/ Ids</span>
        </Grid>
      </TableCellOrder>

      <TableCellOrder
        sort={qSortBy === 'updated' ? qSortOrder : undefined}
        onClick={onSortChange('updated')}
      >
        Last update
      </TableCellOrder>

      <div />
    </TableHeader>
  )
}
