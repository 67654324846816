// @flow

import Immutable, { type List } from 'immutable'

import { type CappingRulePayload } from '../../shared/infra/metadata-service/metadata-service.api'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { CappingRuleFactory } from 'com.batch/capping/model/capping-rule'
import { LabelFactory, type LabelRecord } from 'com.batch/labels/models/labels.records'

type FetchProjectLabelsAndCappingRulesAction = ReduxAction<
  'FETCH_PROJECT_LABELS_AND_CAPPING_RULES',
  null,
>

export type FetchProjectLabelsAndCappingRulesFailureAction = ReduxAction<
  'FETCH_PROJECT_LABELS_AND_CAPPING_RULES_FAILURE',
  null,
>

export type FetchProjectLabelsAndCappingRulesSuccessAction = ReduxAction<
  'FETCH_PROJECT_LABELS_AND_CAPPING_RULES_SUCCESS',
  List<LabelRecord>,
>

export type FetchProjectLabelsAndCappingRulesActionType =
  | FetchProjectLabelsAndCappingRulesAction
  | FetchProjectLabelsAndCappingRulesSuccessAction
  | FetchProjectLabelsAndCappingRulesFailureAction

export const fetchLabelsAndCappingRules = (): DispatchExtraBoundFn<Promise<List<LabelRecord>>> => {
  return async (
    dispatch,
    getState,
    { metadataService, orchestrationService }
  ): Promise<List<LabelRecord>> => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator<List<LabelRecord>>({
      dispatch,
      promise: metadataService
        .fetchProject({ projectKey: project.projectKey })
        .then(projectResult => {
          if (!projectResult.labels) {
            return new Immutable.List()
          }

          const labelCodes = projectResult.labels.map(label => label.code)

          return orchestrationService
            .getLabelCountByLabelCodes({
              projectKey: project.projectKey,
              labelCodes: new Immutable.List().push(...labelCodes),
            })
            .then(labelCounts => {
              let cappingRulesMap = new Immutable.Map<string, Array<CappingRulePayload>>()
              if (projectResult.labelCappings) {
                Object.keys(projectResult.labelCappings).forEach(labelCode => {
                  cappingRulesMap = cappingRulesMap.set(
                    labelCode,
                    projectResult.labelCappings?.[labelCode]?.cappings ??
                      ([]: Array<CappingRulePayload>)
                  )
                })
              }

              return new Immutable.List<LabelRecord>().push(
                ...projectResult.labels.map(label => {
                  const cappingRules = cappingRulesMap.get(label.code, [])
                  return LabelFactory({
                    description: label.description,
                    code: label.code,
                    orchestrationCount: labelCounts.get(label.code) || 0,
                    cappingRules: new Immutable.List().push(
                      ...(cappingRules.map(cappingRule =>
                        CappingRuleFactory({
                          durationUnit: cappingRule.durationUnit,
                          labelCode: cappingRule.label,
                          durationValue: cappingRule.durationValue,
                          capping: cappingRule.capping,
                          isPersisted: true,
                        })
                      ) ?? [])
                    ),
                  })
                })
              )
            })
        }),
      actionName: 'FETCH_PROJECT_LABELS_AND_CAPPING_RULES',
    })
  }
}
