// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { useCurrentCompanyHasOneFeatureAmongst } from 'components/_hooks'
import { SubNav } from 'components/navigation/sub-nav.styles'
import { useMatchRoutes } from 'components/navigation/use-match-routes'
import { ChannelSubNav } from 'components/project/navigation/channel-sub-nav'
import { Navbar } from 'components/project/navigation/navbar'

import { type ChannelInUrl } from 'com.batch.common/router'

import { appsSelector, currentAppSelector, currentProjectAppsSelector } from 'com.batch.redux/app'
import { currentProjectSelector, projectsSelector } from 'com.batch.redux/project.selector'
import { companyStateSelector, currentUserSelector } from 'com.batch.redux/user.selector'

type Props = {
  subNavItems?: React.Node,
}
export const CepNavbar: React$ComponentType<Props> = React.memo(({ subNavItems }): React.Node => {
  const user = useSelector(currentUserSelector)
  const company = useSelector(companyStateSelector)
  const project = useSelector(currentProjectSelector)
  const projects = useSelector(projectsSelector)
  const apps = useSelector(currentProjectAppsSelector)

  const allApps = useSelector(appsSelector)
  const app = useSelector(currentAppSelector)

  const { hasChannelsNav, isOmnichannelPath, isEmailPath, isCepAutomations, isCepCampaigns } =
    useMatchRoutes()
  const currentCompanyShouldSeeMepAutomations = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
    'inapp-campaigns',
  ])

  const currentCompanyShouldSeeLegacyMepCampaings = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-campaign',
    'cep-show-legacy-campaign-omni',
  ])

  const hideSubnavOnCepOrchestrations = React.useMemo(
    () =>
      (isCepAutomations && !currentCompanyShouldSeeMepAutomations) ||
      (isCepCampaigns && !currentCompanyShouldSeeLegacyMepCampaings),
    [
      isCepAutomations,
      isCepCampaigns,
      currentCompanyShouldSeeMepAutomations,
      currentCompanyShouldSeeLegacyMepCampaings,
    ]
  )

  const channel: ChannelInUrl = React.useMemo(() => {
    return app.id === 0 ? 'email' : { appId: app.id }
  }, [app.id])

  const channelForChannelSubNav: ChannelInUrl = React.useMemo(() => {
    if (isEmailPath) {
      return 'email'
    }
    if (isOmnichannelPath) {
      return null
    }
    if (app.id === 0) {
      return { appId: app.id }
    }
    return { appId: app.id }
  }, [app.id, isEmailPath, isOmnichannelPath])

  return (
    <React.Fragment>
      <Navbar
        company={company}
        project={project}
        user={user}
        channel={channel}
        allApps={allApps}
        projects={projects}
      />

      {subNavItems && <SubNav>{subNavItems}</SubNav>}

      {hasChannelsNav && !hideSubnavOnCepOrchestrations && (
        <ChannelSubNav
          apps={apps}
          project={project}
          channel={channelForChannelSubNav}
          gotBorder
          isTransparent
        />
      )}
    </React.Fragment>
  )
})
