// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Box, BoxHeader, BoxBody, BoxTitle } from 'components/common/box'
import { HintIcon } from 'components/common/hint'
import { Label } from 'components/common/label/label.styles'
import { colors } from 'components/styled/tokens'

export const MetricBlockCard: StyledComponent<*, *, *> = styled(Box)`
  margin-bottom: 0;
  background: #ffffff;

  ${BoxTitle} {
    gap: 6px;
  }

  ${BoxHeader} {
    border-bottom: 0;
    padding: 0 14px;
  }

  ${HintIcon} {
    color: ${colors.textDisabled};
    font-size: 0.8em;
    margin-right: 0;

    &:hover {
      color: ${colors.textLight};
    }
  }
`

type ThemeProps = { isLoading: boolean, ... }
export const MetricBlockContainer: StyledComponent<{ ... }, ThemeProps, HTMLDivElement> =
  styled.div`
    width: 100%;
    min-width: 215px;

    ${BoxHeader} {
      height: auto;
      padding: 16px 16px 10px;
    }

    ${BoxBody} {
      padding: 0 20px 16px 20px;
    }

    ${BoxTitle} {
      justify-content: space-between;
      width: 100%;
    }
  `

export const MetricBlockBadge: StyledComponent<*, *, *> = styled(Label)`
  display: flex;
  place-content: center;
  width: fit-content;
  margin-bottom: 0;
  padding: 3px 5px;
  border-radius: 6px;
  gap: 0;

  i {
    font-size: 0.9em;
    margin-right: 4px;
  }
`
