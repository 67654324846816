// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'

import { Box, BoxBody, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Code } from 'components/common/code'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'

import { capitalize, textUsesTemplating } from 'com.batch.common/utils'

import { Item, FadedLabel, LinkedContent, TextContent } from './rsr.styles'

import { type CampaignRecord } from 'com.batch.redux/_records'
import {
  type AbTestedPushRecord,
  type PushSettingsRecord,
  type attachmentKind,
} from 'com.batch.redux/content.records'

type abTesting = 'a' | 'b'

type RSRContentProps = {
  campaign: CampaignRecord,
  languagePicker: ReactNode,
  abTesting: abTesting,
  onAbTesting: (variant: abTesting) => any,
  platform: string,
  abTestedPushContent: AbTestedPushRecord,
  pushSettings: PushSettingsRecord,
  ...
}

const RSRContent = ({
  campaign,
  languagePicker,
  abTesting,
  onAbTesting,
  platform,
  abTestedPushContent,
  pushSettings,
}: RSRContentProps): React.Node => {
  const isPush = campaign.type === 'push'
  const variants = campaign.abtesting.activeVariants
  const config = {
    a: variants.includes('a') ? (variants.includes('b') ? '50' : 100) : 0,
    b: variants.includes('b') ? (variants.includes('a') ? '50' : 100) : 0,
  }
  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title="Content" />
      </BoxHeader>

      <BoxBody>
        <Item name="Language">
          <div style={{ position: 'relative', zIndex: 10 }}>{languagePicker}</div>
        </Item>

        {campaign.abtesting.enabled ? (
          <Item name="A/B Testing" borderTop>
            <div style={{ margin: '-6px 0 -6px -4px' }}>
              <TableToggle inline>
                <TableToggleItem active={abTesting === 'a'} alt onClick={() => onAbTesting('a')}>
                  Version A <span>&nbsp;&mdash; {config.a}%</span>
                </TableToggleItem>
                <TableToggleItem active={abTesting === 'b'} alt onClick={() => onAbTesting('b')}>
                  Version B <span>&nbsp;&mdash; {config.b}%</span>
                </TableToggleItem>
              </TableToggle>
            </div>
          </Item>
        ) : null}
        {isPush && (
          <Item name="Title" borderTop word>
            {abTestedPushContent.get(abTesting).title ? (
              <TextContent style={{ whiteSpace: 'pre-wrap' }}>
                {abTestedPushContent.get(abTesting).title.value}
              </TextContent>
            ) : (
              <FadedLabel>—</FadedLabel>
            )}
          </Item>
        )}
        {isPush && (
          <Item name="Message" borderTop word>
            <TextContent style={{ whiteSpace: 'pre-wrap' }}>
              {abTestedPushContent.get(abTesting).message.value}
            </TextContent>
          </Item>
        )}
        {!campaign.hasLanding ? (
          <Item name="Deeplink" borderTop>
            {abTestedPushContent.get(abTesting).deeplink ? (
              abTestedPushContent.get(abTesting).deeplink
            ) : (
              <FadedLabel>—</FadedLabel>
            )}
          </Item>
        ) : null}
        <Attachment
          pushSettings={pushSettings}
          mediaUrl={abTestedPushContent.get(abTesting).mediaUrl}
          mediaKind={abTestedPushContent.get(abTesting).mediaKind}
          platform={platform}
        />
      </BoxBody>
    </Box>
  )
}

export default RSRContent
type AttachmentType = { type: string, url: ?string, extension: ?string, hasPerso: boolean, ... }
const Attachment = ({
  pushSettings,
  mediaUrl,
  mediaKind,
  platform,
}: {
  pushSettings: PushSettingsRecord,
  mediaUrl: string,
  mediaKind: attachmentKind,
  platform: string,
  ...
}) => {
  let attachment: AttachmentType = {
    type: capitalize(mediaKind),
    url: mediaUrl,
    extension: null,
    hasPerso: false,
  }
  let icon: AttachmentType = {
    url: pushSettings.iconUrl,
    type: 'Img',
    extension: null,
    hasPerso: false,
  }
  ;[attachment, icon].map(e => {
    if (e.url) {
      let tmp = e.url.split('.')
      e.hasPerso = textUsesTemplating(e.url)
      e.extension = tmp[tmp.length - 1]
      return e
    }
  })

  return (
    <React.Fragment>
      <Item name="Attachment" borderTop>
        {attachment.url ? (
          attachment.hasPerso ? (
            <Code
              style={{
                display: 'inline-flex',
                width: 'fit-content',
                maxWidth: 'calc(100% - 125px)',
                marginLeft: 0,
              }}
            >
              {attachment.url}
            </Code>
          ) : (
            <LinkedContent linkLabel={`View ${attachment.type}`} linkUrl={attachment.url}>
              {attachment.type}
              {attachment.extension && (
                <FadedLabel> ({attachment.extension.toUpperCase()})</FadedLabel>
              )}
            </LinkedContent>
          )
        ) : (
          <FadedLabel>—</FadedLabel>
        )}
      </Item>
      {(platform === 'android' || platform === 'webpush') && (
        <Item name="Icon" borderTop>
          {icon.url && icon.extension ? (
            <LinkedContent linkLabel="View icon" linkUrl={icon.url}>
              Icon <FadedLabel>({icon.extension.toUpperCase()})</FadedLabel>
            </LinkedContent>
          ) : (
            <FadedLabel>—</FadedLabel>
          )}
        </Item>
      )}
    </React.Fragment>
  )
}
