// @flow
// ====================== LIBS
import { type List } from 'immutable'
import * as React from 'react'

import { TableBody } from 'components/common/table'

import { type SegmentRecord } from 'com.batch/segments/models/segment.records'
import { Row } from 'com.batch/segments/ui/components/row'
import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'

type RowProps = {
  segments: List<SegmentRecord>,
  templateSize: number,
  emptyTemplate: React.Node,
  baseUrl: string,
}

export const Body = ({ segments, templateSize, emptyTemplate, baseUrl }: RowProps): React.Node => {
  return (
    <TableBody templateSize={templateSize} emptyTemplate={emptyTemplate}>
      <NoResultWrapper isEmpty={segments.size === 0} entityName="segments" height={400}>
        {segments.map((segment: SegmentRecord, index: number) => {
          return (
            <Row
              key={segment.name}
              segment={segment}
              index={index}
              editUrl={`${baseUrl}/${segment.name}`}
            />
          )
        })}
      </NoResultWrapper>
    </TableBody>
  )
}
