// @flow
import { type Dayjs } from 'dayjs'
import * as React from 'react'

export const BarChartContext: React.Context<{
  dateRange: Array<Dayjs>,
  hoveredDate: ?Dayjs,
  isLoading: boolean,
  isEmpty: boolean,
  setHoveredDate: (date: ?Dayjs) => void,
}> = React.createContext({
  dateRange: [],
  hoveredDate: null,
  isLoading: false,
  isEmpty: false,
  setHoveredDate: () => {},
})

type BarChartProviderProps = {
  children: React.Node,
  dateRange: Array<Dayjs>,
  isLoading?: boolean,
  isEmpty?: boolean,
  setDateOnView?: (date: ?Dayjs) => void,
}
export const BarChartProvider = ({
  children,
  dateRange = [],
  isLoading = false,
  isEmpty = false,
  setDateOnView,
}: BarChartProviderProps): React.Node => {
  const [hoveredDate, setHoveredDate] = React.useState(null)

  const onHoveredDateChange = React.useCallback(
    (date: ?Dayjs) => {
      setHoveredDate(date)
      setDateOnView && setDateOnView(date)
    },
    [setDateOnView]
  )

  return (
    <BarChartContext.Provider
      value={{
        dateRange,
        hoveredDate,
        isLoading,
        isEmpty,
        setHoveredDate: onHoveredDateChange,
      }}
    >
      {children}
    </BarChartContext.Provider>
  )
}
