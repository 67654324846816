// @flow
import Immutable from 'immutable'

import { filters } from 'components/campaign/campaign-list-utils'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  type CampaignActiveFiltersRecord,
  CampaignActiveFiltersFactory,
  DateRangeFilterFactory,
} from 'com.batch.redux/_records'

/*
  parse la query string, et construit une instance de CampaignActiveFiltersRecord avec :
    - les data passées en URL en priorité
    - si ces paramères sont absents de l'URL, la valeur stockée dans la string json persistedFilters
  ⚠️ en mode campaigns, on prend que status et sources, les filtres channels & when ne sont pas appliqués
*/
export const buildActiveFilters = ({
  queryString,
  persistedCommaSeparatedFilters,
  schedulingType,
  platform,
}: {
  queryString: URLSearchParams,
  persistedCommaSeparatedFilters: string,
  schedulingType: schedulingType,
  platform: Platforms,
}): CampaignActiveFiltersRecord => {
  let persistedArray = persistedCommaSeparatedFilters.split(',').filter(Boolean)
  const labelsStr = queryString.get('filters[labels]')
  const statusStr = queryString.get('filters[status]')
  const sourcesStr = queryString.get('filters[sources]')
  const whenStr = queryString.get('filters[when]')
  const channelsStr = queryString.get('filters[channels]')
  const name = queryString.get('filters[name]')

  const fromStr = queryString.get('filters[from]')
  const toStr = queryString.get('filters[to]')
  const from = fromStr && dayjs(fromStr, 'YYYY-MM-DD').startOf('day')
  const to = toStr && dayjs(toStr, 'YYYY-MM-DD').endOf('day')

  const commonFilters = filters.filter(f => {
    switch (f.category) {
      case 'status':
        return statusStr ? statusStr.split('|').includes(f.name) : persistedArray.includes(f.name)
      case 'sources':
        return sourcesStr ? sourcesStr.split('|').includes(f.name) : persistedArray.includes(f.name)
      case 'when':
        return (
          schedulingType === 'automations' &&
          (whenStr ? whenStr.split('|').includes(f.name) : persistedArray.includes(f.name))
        )
      case 'channels':
        return (
          schedulingType === 'automations' &&
          (channelsStr ? channelsStr.split('|').includes(f.name) : persistedArray.includes(f.name))
        )
      default:
        return false
    }
  })

  return CampaignActiveFiltersFactory({
    labels: Immutable.Set(
      labelsStr
        ? labelsStr
            .split('|')
            .filter(Boolean)
            .map(l => parseInt(l, 10))
        : []
    ),
    query: name ? name : '',
    commons: commonFilters.filter(filter => {
      if (platform !== 'webpush') return true
      return filter.category !== 'channels' || filter.name !== 'in-app'
    }),
    dateRange:
      from && from.isValid() && to && to.isValid()
        ? DateRangeFilterFactory({
            from,
            to,
          })
        : null,
  })
}
