// @flow
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Loader from 'components/common/loader-legacy'

import { fetchApp } from '../redux/app'
import { type State } from '../redux/console.records'
import { type AppRecord, AppFactory } from 'com.batch.redux/_records'

type RequireUserProps = {
  forcedAppId?: number,
  render: (app: AppRecord) => any,
}

const appsSelector = (state: State) => state.app.entities

export const ConsoleRequireAppFromRouter = ({
  forcedAppId,
  render,
}: RequireUserProps): React.Node => {
  const dispatch = useDispatch()
  const fetchingAppId = React.useRef(-1)
  const { appId } = useParams()
  const apps = useSelector(appsSelector)
  const usedAppId = React.useMemo(() => parseInt(forcedAppId ?? appId, 10), [forcedAppId, appId])
  React.useEffect(() => {
    if (!apps.has(usedAppId) && fetchingAppId.current !== usedAppId) {
      fetchingAppId.current = usedAppId
      dispatch(fetchApp(usedAppId))
    }
  }, [dispatch, usedAppId, apps])

  return apps.has(usedAppId) ? (
    render(apps.get(usedAppId, AppFactory()))
  ) : (
    <Loader loading={true} height={400} size="fat" />
  )
}
