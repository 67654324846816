// @flow

import * as React from 'react'

import { BoxInfo } from 'components/styled/blocs'

export const Notice = (): React.Node => {
  return (
    <BoxInfo style={{ padding: '16px 20px', marginBottom: 0 }}>
      <p>
        For FCM v1 HTTP Protocol, the Batch dashboard needs your Google Service Account key
        available in your Firebase Project settings.
      </p>
      <p>
        Make sure the project ID referenced in your Service Account key is the Firebase project ID
        of your app.
      </p>
      <p>
        If you need to change your Firebase project ID but don't want to lose any token in the
        process, you can provide multiple Service Account keys, and Batch will use the appropriate
        one when sending notifications.
      </p>
    </BoxInfo>
  )
}
