// @flow

import { NavLink } from 'react-router-dom'
import styled, { css, type StyledComponent } from 'styled-components'

import { LabelPlaceholder } from 'components/styled/placeholder'
import { colors } from 'components/styled/tokens'

export const SubNavItem: StyledComponent<*, *, HTMLLIElement> = styled.li`
  list-style: none;
  display: inline-block;
`
type SubNavLinkProps = {
  active?: boolean,
  disabled?: boolean,
  ...
}
export const SubNavLink: StyledComponent<SubNavLink, *, typeof LabelPlaceholder> = styled(
  // $FlowFixMe
  LabelPlaceholder.withComponent('a')
)`
  display: inline-flex;
  padding: 0.3em 0;
  color: ${(props: SubNavLinkProps) =>
    props.disabled ? colors.textDisabled : props.active ? colors.text : colors.textLight};
  border-bottom: 2px solid
    ${(props: SubNavLinkProps) => (props.active ? colors.strokeAction : 'transparent')};
  cursor: ${(props: SubNavLinkProps) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props: SubNavLinkProps) =>
    props.disabled
      ? css`
          &:hover {
            color: ${colors.textDisabled};
          }
        `
      : css`
          &:hover {
            color: ${colors.text};
          }
          &:disabled {
            color: ${colors.textDisabled};
          }
          &.active {
            color: ${colors.text};
            border-bottom-color: ${colors.strokeAction};
          }
        `}
`

export const SubNavRouterLink: StyledComponent<*, *, typeof NavLink> = styled(
  // $FlowFixMe
  SubNavLink.withComponent(NavLink)
)``

export const SubNav: StyledComponent<*, *, HTMLElement> = styled.ul`
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  font-size: 16px;
  text-align: left;

  ${SubNavLink}, ${SubNavRouterLink} {
    margin: 0 24px 0 0;
  }
`
