/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable from 'immutable'
import { get as _get } from 'lodash-es'
import * as React from 'react'
import request from 'superagent-interface-promise'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import {
  Box,
  BoxHeader,
  BoxBody,
  HeaderBoxTitle,
  BoxFooter,
  FooterBoxActions,
} from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { Wrapper, GlobalErrorOverlayProps } from 'components/common/empty-states'
import { trackEvent, TrackingContext } from 'components/common/page-tracker'
import { Empty, EmptyTitle, EmptyText } from 'components/styled/empty'
import { Title } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { SettingsCommon } from './settings-common'
import { EmptyScopedIOs } from './settings-push-empty-states'
import { SettingsReviewP12 } from './settings-review-p12'
import { SettingsReviewP8 } from './settings-review-p8'
import { defaultUpload, SettingsUpload, type UploadState } from './settings-upload'

import {
  P8ConfigFactory,
  P12ConfigFactory,
  PushConfigFactory,
  type PushConfigRecord,
  type AppRecord,
} from 'com.batch.redux/_records'
import { showToast } from 'com.batch.redux/toaster'

import { useConfirmWithMfa } from 'com.batch/shared/ui/hooks/use-confirm-with-mfa'
import { STATUS } from 'constants/common'

type SettingsIOSProps = {
  config: PushConfigRecord,
  advanced: boolean,
  app: AppRecord,
  savePushConfig: (config: PushConfigRecord, file: ?File, password: ?string) => Promise<void>,
  showToast: typeof showToast,
  ...
}

const commonOptions = Immutable.Set(['ttl', 'priority'])

export const SettingsIOS: React.ComponentType<SettingsIOSProps> = React.memo(
  ({ config, advanced, app, savePushConfig, showToast }: SettingsIOSProps) => {
    const confirm = useConfirmWithMfa()
    const [upload, setUpload] = React.useState<UploadState>(defaultUpload)
    const [isEditing, setIsEditing] = React.useState<boolean>(false)
    const [isPreview, setIsPreview] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const isEmpty = React.useMemo(
      () => !isPreview && !isEditing && !config.p8 && !config.p12,
      [config.p8, config.p12, isEditing, isPreview]
    )

    const { eventLocation } = React.useContext(TrackingContext)

    const saveCurrentPushConfig = React.useCallback(
      ({
        config,
        file,
        password,
        successMsg,
      }: {
        config: PushConfigRecord,
        file?: ?File,
        password?: ?string,
        successMsg: ?string,
        ...
      }) => {
        const done = () => {
          setIsEditing(false)
        }
        return savePushConfig(config, file, password).then((resp: any) => {
          done()
          !!successMsg && resp.status === 200 && showToast({ kind: 'success', message: successMsg })
        }, done)
      },
      [savePushConfig, showToast]
    )

    const updateTopic = React.useCallback(
      (topic: string) => {
        if (config.p8) {
          return saveCurrentPushConfig({
            config: config.set('p8', config.p8.set('topic', topic)),
            successMsg: 'APNS additional informations saved',
          })
        }
      },
      [config, saveCurrentPushConfig]
    )

    const saveCertificate = React.useCallback(
      (certificate: UploadState) => {
        const currentConfig =
          certificate.mode === 'P8'
            ? PushConfigFactory({
                defaultTtl: config.defaultTtl,
                defaultPriority: config.defaultPriority,
                maxRate: config.maxRate,
                p8: P8ConfigFactory({
                  topic: certificate.topic,
                  teamId: certificate.teamId,
                  keyId: certificate.keyId,
                  privateKey: undefined,
                  valid: false,
                }),
              })
            : PushConfigFactory({
                defaultTtl: config.defaultTtl,
                defaultPriority: config.defaultPriority,
                maxRate: config.maxRate,
                p12: P12ConfigFactory({
                  certificateName: certificate.cn ? certificate.cn : '',
                }),
              })
        saveCurrentPushConfig({
          config: currentConfig,
          file: certificate.file,
          password: certificate.password,
          successMsg: 'APNS certificate saved',
        })
        trackEvent('CERTIFICATE_UPLOAD', { location: eventLocation })
        setIsPreview(false)
      },
      [
        config.defaultPriority,
        config.defaultTtl,
        config.maxRate,
        eventLocation,
        saveCurrentPushConfig,
      ]
    )

    const handleOnSave = React.useCallback(
      upload => {
        if (isLoading) {
          return
        }
        setIsLoading(true)
        confirm({
          title: 'Save iOS push configuration',
          message: (
            <React.Fragment>
              <p>
                Please make sure this new configuration is valid or your push delivery capacities
                will be impacted.
              </p>
              <p>Just in case, keep a copy of your previous configuration as a backup.</p>
            </React.Fragment>
          ),
          confirm: 'Yes, save',
        })
          .then(
            () => saveCertificate(upload),
            () => {}
          )
          .then(() => {
            setIsLoading(false)
          })
      },
      [confirm, isLoading, saveCertificate]
    )

    const isReadyForSave = React.useCallback(
      (upload: UploadState) =>
        (upload.isValid &&
          upload.mode === 'P12' &&
          upload.expire &&
          upload.expire.isAfter(dayjs())) ||
        (upload.isValid && upload.mode === 'P8' && upload.authCheck),
      []
    )

    const settingsLoading = React.useMemo(
      () =>
        app.loadingState === STATUS.LOADING || app.loadingState === STATUS.INIT || config.loading,
      [app.loadingState, config.loading]
    )

    const parseFile = React.useCallback(
      (file: ?File, callback: ?(UploadState) => void) => {
        const fd = new FormData()
        let guessedKeyId = ''
        if (file && file.name.indexOf('AuthKey_') !== -1) {
          guessedKeyId = file.name.replace('AuthKey_', '').replace('.p8', '')
        }

        let params: UploadState = {
          file: file,
          authCheck: upload.authCheck,
          cn: upload.cn,
          expire: upload.expire,
          hasParsedExtra: upload.hasParsedExtra,
          isValid: upload.isValid,
          mode: upload.mode,
          isWrongPass: false,
          isUploaded: true,
          isParsing: true,
          password: upload.password,
          teamId: upload.teamId,
          topic: upload.topic,
          keyId: upload.keyId ? upload.keyId : guessedKeyId,
        }
        setUpload(params)

        // $FlowFixMe
        fd.append('file', file)
        if (params.password) {
          fd.append('password', params.password)
        }
        if (params.teamId) {
          fd.append('teamId', params.teamId)
        }
        if (params.topic) {
          fd.append('topic', params.topic)
        }
        if (params.keyId) {
          fd.append('keyId', params.keyId)
        }

        request
          .post(`/api/app/${app.id}/certificate/parse`)
          .send(fd)
          .then(
            response => {
              const res = response.body
              let resParams: UploadState = {
                ...params,
                hasParsedExtra: false,
                mode: res.mode,
                isValid: res.valid,
                isWrongPass: !res.valid && !!params.password,
                isParsing: false,
                cn: '',
                authCheck: !!res.apns_check_ok,
                expire: undefined,
              }
              if (_get(res, 'CN')) {
                resParams.cn = _get(res, 'CN')
              }
              if (_get(res, 'expire')) {
                resParams.expire = dayjs(_get(res, 'expire'), 'YYYY/MM/DD HH:mm')
              }
              if (params.teamId && params.topic && params.keyId) {
                resParams.hasParsedExtra = true
              }
              setUpload(resParams)
              if (callback) {
                callback(resParams)
              }
            },
            () => {
              setUpload({
                ...params,
                isValid: false,
                authCheck: false,
                error: 'Server error.',
              })
            }
          )
      },
      [
        app.id,
        upload.authCheck,
        upload.cn,
        upload.expire,
        upload.hasParsedExtra,
        upload.isValid,
        upload.keyId,
        upload.mode,
        upload.password,
        upload.teamId,
        upload.topic,
        setUpload,
      ]
    )

    const isError = React.useMemo(() => app.loadingState === STATUS.ERROR, [app.loadingState])

    const isAllowedToUploadFile = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
    return (
      <React.Fragment>
        <Title overEmptyState>iOS Push Settings</Title>
        <Wrapper
          isEmpty={isError}
          isLoading={settingsLoading}
          isOverlayShown={isError && !settingsLoading}
          overlayProps={GlobalErrorOverlayProps}
        >
          <Box>
            <BoxHeader>
              <HeaderBoxTitle title="Apple Push Notification Service (APNS)" />
            </BoxHeader>

            <BoxBody style={{ height: isEditing ? '306px' : 'auto' }}>
              {settingsLoading ? (
                <EmptyScopedIOs />
              ) : isEditing || isPreview ? (
                <SettingsUpload
                  isReadyForSave={isReadyForSave}
                  parseFile={parseFile}
                  saveHandler={certificate => handleOnSave(certificate)}
                  upload={upload}
                  isLoading={isLoading}
                  setUpload={value => {
                    setUpload(value)
                  }}
                />
              ) : config.p12 ? (
                <SettingsReviewP12 config={config.p12} />
              ) : config.p8 ? (
                <SettingsReviewP8
                  showUpload={() => setIsEditing(true)}
                  config={config.p8}
                  updateTopic={updateTopic}
                />
              ) : (
                isEmpty && (
                  <Empty style={{ position: 'relative', height: 172 }}>
                    <EmptyTitle>iOS APNS credentials</EmptyTitle>
                    <EmptyText>
                      Before you can send any push notifications, your app needs an up to date iOS
                      APNS configurations. Let’s upload it now!{' '}
                    </EmptyText>
                  </Empty>
                )
              )}
            </BoxBody>

            <BoxFooter isEditable={isEditing}>
              {isEditing ? (
                <React.Fragment>
                  <Button kind="inline" intent="neutral" onClick={() => setIsEditing(false)}>
                    Cancel
                  </Button>
                  <FooterBoxActions>
                    {((upload.isValid && upload.mode !== 'P8') || !upload.isUploaded) && (
                      <PermissionButton
                        intent="action"
                        kind="primary"
                        disabled={!isReadyForSave(upload)}
                        onClick={() => {
                          handleOnSave(upload)
                        }}
                        isAllowed={isAllowedToUploadFile}
                      >
                        Use this {upload.mode === 'P8' ? '.p8' : '.p12'} file
                      </PermissionButton>
                    )}

                    {upload.mode === 'P8' && (
                      <PermissionButton
                        intent="action"
                        kind="primary"
                        disabled={
                          !upload.topic || !upload.teamId || !upload.keyId || upload.isParsing
                        }
                        onClick={() => {
                          parseFile(upload.file, state => {
                            if (isReadyForSave(state)) handleOnSave(upload)
                          })
                        }}
                        isAllowed={isAllowedToUploadFile}
                      >
                        Use this {upload.mode === 'P8' ? '.p8' : '.p12'} file
                      </PermissionButton>
                    )}
                  </FooterBoxActions>
                </React.Fragment>
              ) : isPreview ? (
                <FooterBoxActions>
                  <Button
                    type="button"
                    kind="primary"
                    intent="action"
                    onClick={() => {
                      setIsEditing(true)
                      setIsPreview(false)
                      setUpload(defaultUpload)
                    }}
                  >
                    Upload another file (reset)
                  </Button>
                </FooterBoxActions>
              ) : (
                <div />
              )}
              {isPreview ? (
                <PermissionButton
                  intent="action"
                  kind="primary"
                  disabled={!isReadyForSave(upload)}
                  onClick={() => {
                    saveCertificate(upload)
                  }}
                  isAllowed={isAllowedToUploadFile}
                >
                  Use this {upload.mode === 'P8' ? '.p8' : '.p12'} file
                </PermissionButton>
              ) : (
                !isEditing && (
                  <Button
                    intent="action"
                    kind="primary"
                    disabled={settingsLoading}
                    onClick={() => {
                      setUpload(defaultUpload)
                      setIsEditing(true)
                    }}
                  >
                    Upload your .p8 or .p12 file
                  </Button>
                )
              )}
            </BoxFooter>
          </Box>

          {advanced && (config.p8 || config.p12) ? (
            <SettingsCommon
              config={config}
              updateConfig={savePushConfig}
              show={commonOptions}
              app={app}
              showToast={msg => showToast(msg)}
            />
          ) : null}
        </Wrapper>
      </React.Fragment>
    )
  }
)
