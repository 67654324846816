// @flow
import { type DateRange } from 'com.batch.common/dayjs.custom'
import { last7Days } from 'com.batch.common/predefined-ranges'
import { generateUrl } from 'com.batch.common/router/router'

import { type OrchestrationListDefinition } from '../infra/export-service/export-service'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

export const getOrchestrationsExportFileUrl = (): DispatchExtraBoundFn<Promise<string>> => {
  return async (dispatch, getState, { exportService }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    try {
      const dateRange: DateRange = state.orchestrationList.dateRange ?? {
        from: last7Days.from,
        to: last7Days.to,
      }
      const definition: OrchestrationListDefinition = {
        startDate: dateRange.from.hour(0).minute(0).second(0).toISOString(),
        endDate: dateRange.to.hour(0).minute(0).second(0).toISOString(),
        orchestrationChannels: state.orchestrationList.channels
          .toArray()
          .map(c => (c === 'email' ? 'EMAIL' : c === 'push' ? 'PUSH' : 'SMS')),
        orchestrationSources: ['DASHBOARD', 'API'],
        orchestrationStates: state.orchestrationList.statuses.toArray(),
        orchestrationLabels: state.orchestrationList.labels.toArray(),
        orchestrationTypes:
          state.orchestrationList.mode === 'automations' ? ['RECURRENT', 'TRIGGER'] : ['ONETIME'],
      }
      const id = await exportService.create({
        projectKey: project.projectKey,
        source: 'DASHBOARD',
        definition: {
          scope: 'ORCHESTRATION_LIST',
          orchestrationsList: definition,
        },
      })
      const request = await exportService.fetchRequestUntilReady(id, project.projectKey)
      const fileId = request?.files?.[0]?.id
      if (!fileId) {
        throw new Error('No file found in export request')
      }
      return generateUrl('api_export_download', {
        projectKey: project.projectKey,
        fileId: encodeURIComponent(fileId.data),
        filename: `${state.orchestrationList.mode}_metrics_${dateRange.from.format(
          'YYYYMMDD'
        )}-${dateRange.to.format('YYYYMMDD')}.csv`,
      })
    } catch (error) {
      dispatch(
        showToast({
          kind: 'error',
          message:
            error.message === 'timeout'
              ? 'Export request timed out'
              : 'Unknown error during export request',
        })
      )
      throw error
    }
  }
}
