// @flow

import dayjs from 'dayjs'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import { generateOrchestrationUrl } from 'com.batch.common/router'

import MessageEmailIllustration from '../../../../../../../public/medias/img/illustrations/profile/message_email.svg'
import MessagePushIllustration from '../../../../../../../public/medias/img/illustrations/profile/message_push.svg'
import MessageSMSIllustration from '../../../../../../../public/medias/img/illustrations/profile/message_sms.svg'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type MessagingEventRecord } from 'com.batch/profile/models/event.records'
import {
  ChannelBadge,
  statusConfig,
} from 'com.batch/profile/ui/components/events-block/event-row/channel-badge'
import {
  MessagingEventName,
  MessagingEventRowBox,
  MessagingEventRowContainer,
  MessagingEventText,
  MessagingEventTooltip,
} from 'com.batch/profile/ui/components/events-block/event-row/event-row.styles'
import { Time } from 'com.batch/profile/ui/components/events-block/timeline/time'

type Props = {
  event: MessagingEventRecord,
  nextEvent?: MessagingEventRecord,
}

export const MessagingEventRow = ({ event, nextEvent }: Props): React.Node => {
  const project = useSelector(currentProjectSelector)
  const sendDateUtc = React.useMemo(() => {
    return dayjs.unix(event.eventDate).utc()
  }, [event.eventDate])

  const eventDate = React.useMemo(() => {
    return sendDateUtc.local()
  }, [sendDateUtc])

  const hasStep = React.useMemo(
    () => Boolean(event.orchestrationMetadata.stepName),
    [event.orchestrationMetadata.stepName]
  )

  const isAutomation = React.useMemo(() => {
    return (
      Boolean(event.orchestrationMetadata.orchestrationType) &&
      (Boolean(event.orchestrationMetadata.stepID) ||
        event.orchestrationMetadata.orchestrationType === 'ORCHESTRATION_TYPE_RECURRENT' ||
        event.orchestrationMetadata.orchestrationType === 'ORCHESTRATION_TYPE_TRIGGER')
    )
  }, [event.orchestrationMetadata.orchestrationType, event.orchestrationMetadata.stepID])

  const sendType = React.useMemo(() => {
    if (isAutomation) {
      return event.orchestrationMetadata.orchestrationType === 'ORCHESTRATION_TYPE_RECURRENT'
        ? 'recurring'
        : 'trigger'
    }
    return 'now'
  }, [event.orchestrationMetadata.orchestrationType, isAutomation])

  const url = React.useMemo(() => {
    return generateOrchestrationUrl({
      channel: event.channel,
      page: 'form',
      sendType: sendType,
      companyId: project.companyId,
      projectId: project.id,
      token: event.orchestrationMetadata.orchestrationID,
    })
  }, [
    sendType,
    event.channel,
    event.orchestrationMetadata.orchestrationID,
    project.companyId,
    project.id,
  ])

  return (
    <MessagingEventRowContainer
      $variant={nextEvent ? statusConfig[nextEvent.metric]?.variant : undefined}
    >
      <Time date={eventDate} />
      <MessagingEventRowBox>
        <div>
          <ChannelBadge event={event} />
          <MessagingEventText>
            <Tooltip
              tooltip={
                <MessagingEventTooltip>
                  <div>{event.orchestrationMetadata.orchestrationName}</div>
                  <HorizontalDivider color={colors.strokeContrast} />
                  <div>
                    {isAutomation ? 'Click to access automation' : 'Click to access campaign'}
                  </div>
                </MessagingEventTooltip>
              }
              placement="bottom"
              maxWidth={294}
              minWidth={180}
            >
              <MessagingEventName $twoLines={!hasStep}>
                <Ellipsis>
                  <NavLink target="_blank" to={url}>
                    {event.orchestrationMetadata.orchestrationName}
                  </NavLink>
                </Ellipsis>
                <Icon icon="arrow-long-right" />
              </MessagingEventName>
            </Tooltip>

            {event.orchestrationMetadata.stepName && (
              <Tooltip
                tooltip={
                  <MessagingEventTooltip>
                    <div>{event.orchestrationMetadata.stepName}</div>
                    <HorizontalDivider color={colors.strokeContrast} />
                    <div>Click to access automation step</div>
                  </MessagingEventTooltip>
                }
                placement="bottom"
                maxWidth={294}
                minWidth={220}
              >
                <MessagingEventName>
                  <Ellipsis>
                    <NavLink
                      target="_blank"
                      to={`${url}?stepId=${event.orchestrationMetadata.stepID}`}
                    >
                      {event.orchestrationMetadata.stepName}
                    </NavLink>
                  </Ellipsis>
                  <Icon icon="arrow-long-right" />
                </MessagingEventName>
              </Tooltip>
            )}
          </MessagingEventText>
        </div>
        {event.channel === 'email' && <MessageEmailIllustration width="166" height={92} />}
        {event.channel === 'push' && <MessagePushIllustration width="166" height={92} />}
        {event.channel === 'sms' && <MessageSMSIllustration width="166" height={92} />}
      </MessagingEventRowBox>
    </MessagingEventRowContainer>
  )
}
