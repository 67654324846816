// @flow

import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { LoadingCellAnim } from 'components/common/empty-states/'
import { Grid } from 'components/common/grid'
import { schemes, colors } from 'components/styled/tokens'

import { TYPE_ICON } from 'constants/push-settings/icons'

const UploadImageTextCommun = css`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
`
const UploadImageTitle = css`
  ${UploadImageTextCommun}

  font-weight: 500;
  color: ${colors.textNeutral};
`
const UploadImageText = css`
  ${UploadImageTextCommun}

  font-weight: normal;
  color: ${colors.textLight};
`
type UploadImageContainerProps = {
  isEditing: boolean,
  isDragActive: boolean,
  isDragReject: boolean,
  ...
}
export const UploadImageContainer: StyledComponent<UploadImageContainerProps, *, HTMLElement> =
  styled.div`
    width: 100%;
    height: 120px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px dashed
      ${(p: UploadImageContainerProps) =>
        p.isEditing
          ? p.isDragActive
            ? schemes.blue['30']
            : p.isDragReject
              ? schemes.red['30']
              : schemes.darklucent['20']
          : 'white'};

    ${(p: UploadImageContainerProps) =>
      p.isEditing &&
      css`
        background-color: ${schemes.grayscale['01']};
      `}
  `
type ImageUploaderProps = { error: boolean, isFilled: boolean, ... }
export const ImageUploader: StyledComponent<ImageUploaderProps, *, HTMLElement> = styled.div`
  width: 100%;
  ${(p: ImageUploaderProps) =>
    p.isFilled &&
    css`
      text-align: center;
    `}

  & > p {
    &:first-of-type {
      ${UploadImageTitle};
      display: inline-flex;
      align-items: center;

      button {
        margin-left: 5px;
      }
    }

    &.styled_upload-img-recommendation {
      position: absolute;
      bottom: 5px;
      right: 0;
      width: 100%;
      ${UploadImageText}
      ${(p: ImageUploaderProps) =>
        p.error &&
        css`
          color: ${colors.textDanger};
        `}
    }
  }
`

export const ImageRender: StyledComponent<*, *, typeof Grid> = styled(Grid)`
  min-height: 84px;
  width: calc(100% - 16px);
`
type ImageRenderIconProps = {
  iconNotConfig: boolean,
  image: string,
  type: 'smallIcon' | 'defaultIcon',
  ...
}
type ThemeProps = { isLoading: boolean, ... }
type ThemedImageRenderIconProps = PropsWithTheme<ImageRenderIconProps, ThemeProps>
export const ImageRenderIcon: StyledComponent<ImageRenderIconProps, ThemeProps, HTMLElement> =
  styled.div`
    position: relative;
    width: 84px;
    height: 84px;
    background-color: ${colors.fillDisabled};
    border-radius: 16px;
    overflow: hidden;
    margin-left: 10px;

    ${(props: ThemedImageRenderIconProps) =>
      props.theme.isLoading
        ? css`
            animation-name: ${LoadingCellAnim};
            animation-duration: 2s;
            animation-iteration-count: infinite;
          `
        : css`
            background-color: ${colors.fillDisabled};
          `}

    ${(p: ThemedImageRenderIconProps) =>
      Boolean(p.image) &&
      p.type === TYPE_ICON.DEFAULT &&
      css`
        background-color: inherit;
        background-image: url(${p.image});
        background-repeat: no-repeat;
        background-size: cover;
      `}

  ${(p: ThemedImageRenderIconProps) =>
      Boolean(p.image) &&
      p.type === TYPE_ICON.SMALL &&
      css`
        &:after {
          content: '';
          position: absolute;
          background-image: url(${p.image});
          background-repeat: no-repeat;
          background-size: cover;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          filter: brightness(0) contrast(0.3);
        }
      `}
  `
export const ImageRenderText: StyledComponent<*, *, HTMLElement> = styled.div`
  ${UploadImageText}
  margin-left: 12px;

  p {
    margin-bottom: 4px;

    &.styled_img-render-no-config-text {
      ${UploadImageTitle};
      color: ${colors.textDisabled};
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
export const ImageRenderButton: StyledComponent<*, *, HTMLElement> = styled.div`
  text-align: center;
`
