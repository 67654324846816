// @flow
import * as React from 'react'
import { type Node } from 'react'
import styled, { type StyledComponent } from 'styled-components'

import Hint from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { HiddenPlaceholder } from 'components/styled/placeholder'
import { colors } from 'components/styled/tokens'

import { kformat, percentage, numberFormat } from 'com.batch.common/utils'

type MetricProps = {
  children: Node,
  appendNode?: React.Node,
  isRatio?: boolean,
  value: number,
  ratio?: number,
  color?: string,
  alternateTooltipIcon?: boolean,
  labelColor?: string,
  small?: boolean,
  smallNoArrow?: boolean,
  align?: 'center' | 'right' | 'left',
  tooltip?: Node,
}
const Metric = ({
  children,
  value,
  ratio,
  appendNode,
  alternateTooltipIcon = false,
  color,
  small,
  smallNoArrow,
  align,
  isRatio = false,
  tooltip,
}: MetricProps): React.Node => {
  const formated = isRatio ? percentage(value, 2) : kformat(value)
  const isAbbreviation = !!value && !isRatio && formated !== value.toString()
  return (
    <MetricContainer small={!!small} align={align ?? 'inherit'}>
      <MetricLabel small={small}>
        {small && !smallNoArrow && <Icon icon="enter" style={{ margin: '0 6px 0 0' }} />}
        {children}
        {tooltip && (
          <Hint
            noMargin
            style={{ margin: '0 -6px 0 0' }}
            minTooltipWidth={240}
            icon={alternateTooltipIcon ? 'details' : 'help'}
          >
            {tooltip}
          </Hint>
        )}
      </MetricLabel>
      {isAbbreviation ? (
        <Tooltip
          tooltip={<MetricValueTooltip>{numberFormat(value)}</MetricValueTooltip>}
          minWidth={120}
          placement="right"
        >
          <MetricValue small={!!small || !!smallNoArrow}>{formated}</MetricValue>
        </Tooltip>
      ) : (
        <MetricValue small={!!small || !!smallNoArrow}>{formated}</MetricValue>
      )}
      {!!ratio && (
        <MetricRatio small={!!small || !!smallNoArrow} color={color}>
          {percentage(ratio)}
        </MetricRatio>
      )}
      {appendNode}
    </MetricContainer>
  )
}

export default Metric
export const MetricLabelText: StyledComponent<*, *, HTMLElement> = styled.span`
  display: inline-block;
  margin: 0 4px 0 0;
`
type MetricLabelProps = { color?: string, ... }
export const MetricLabel: StyledComponent<MetricLabelProps, *, HTMLElement> = styled.label`
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: ${(props: MetricLabelProps) => (props.color ? props.color : '#89909a')};
  letter-spacing: 1px;
  text-transform: uppercase;
`
type MetricValueProps = { small?: boolean, ... }
export const MetricValue: StyledComponent<MetricValueProps, { ... }, HTMLElement> =
  styled.label.attrs({
    width: '2.5em',
  })`
    display: inline;
    font-size: ${(props: MetricValueProps) => (props.small ? 24 : 28)}px;
    font-weight: 300;
    color: ${colors.text};
    letter-spacing: 0.5px;
    padding: 8px;
    margin: -8px -8px -8px ${(props: MetricValueProps) => (props.small ? -8 : -8)}px;
  `
const MetricValueTooltip: StyledComponent<*, *, HTMLElement> = styled.p`
  display: block;
  margin: -4px;
  text-align: left;
`
export const MetricRatio: StyledComponent<{ color?: string, ... }, *, *> = styled(
  // $FlowFixMe withComponent
  HiddenPlaceholder.withComponent('span')
)`
  display: inline-block;
  margin: 0 0 0 6px;
  font-size: 13px;
  font-weight: 600;
  color: ${(props: { color?: string, ... }) => (props.color ? props.color : '#8e959e')};
`
type MetricContainerProps = { align?: 'center' | 'right' | 'left' | 'inherit', ... }
export const MetricContainer: StyledComponent<MetricContainerProps, *, HTMLElement> = styled.div`
  display: block;
  text-align: ${(props: MetricContainerProps) => props.align};
  cursor: default;

  ${MetricLabel} {
    margin: ${(props: MetricContainerProps) =>
      props.align === 'left' ? '0 10px 4px 0' : '0 0 4px 0'};
  }
`
