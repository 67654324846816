// @flow

import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from 'components/common/button'
import { Form, InputWrapper, FormRow, Input, FormActions } from 'components/form'

import { isEmail } from 'com.batch.common/utils'

import {
  Title,
  AccountSection,
  SectionTitle,
  SectionInfo,
  SectionForm,
  Note,
} from './account.styles'

import { updateUser } from 'com.batch.redux/user'
import { type UserRecord } from 'com.batch.redux/user.records'
import { currentUserSelector } from 'com.batch.redux/user.selector'

export const Settings = (): React.Node => {
  const user = useSelector(currentUserSelector)
  const dispatch = useDispatch()
  const [editedUser, setEditedUser] = React.useState<UserRecord>(user)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())

  const createEditUser = React.useCallback(
    (field: 'firstName' | 'lastName' | 'email') => evt =>
      setEditedUser(editedUser.set(field, evt.target.value)),
    [editedUser]
  )

  const onSubmit = React.useCallback(() => {
    let err = []
    if (editedUser.firstName.trim() === '') err.push(['firstName', 'Please enter a valid name.'])
    else if (editedUser.firstName.trim().length < 2)
      err.push(['firstName', 'This first name is too short.'])

    if (editedUser.lastName.trim() === '') err.push(['lastName', 'Please enter a valid name.'])
    else if (editedUser.lastName.trim().length < 2)
      err.push(['lastName', 'This last name is too short.'])
    if (!isEmail(editedUser.email)) err.push(['email', 'Please provide a valid email address.'])
    const errors = Immutable.Map(err)
    if (errors.size === 0) {
      dispatch(updateUser(editedUser)).then(console.log, console.warn)
    }
    setErrors(errors)
  }, [dispatch, editedUser])

  return (
    <React.Fragment>
      <Title>Account settings</Title>
      <AccountSection>
        <SectionInfo>
          <SectionTitle>Information</SectionTitle>
        </SectionInfo>
        <SectionForm>
          <Form horizontal onSubmit={onSubmit}>
            <InputWrapper
              label="Full name"
              feedback={errors.get('firstName', errors.get('lastName'))}
            >
              <FormRow>
                <Input
                  id="first"
                  placeholder="First name"
                  invalid={errors.has('firstName')}
                  value={editedUser.firstName}
                  onChange={createEditUser('firstName')}
                  aria-label="First name"
                />
                <Input
                  placeholder="Last name"
                  invalid={errors.has('lastName')}
                  value={editedUser.lastName}
                  onChange={createEditUser('lastName')}
                  aria-label="Last name"
                />
              </FormRow>
            </InputWrapper>
            <InputWrapper label="Email" feedback={errors.get('email')} htmlFor="mail">
              <Input
                id="mail"
                invalid={errors.has('email')}
                placeholder="Email"
                value={editedUser.email}
                onChange={createEditUser('email')}
              />
              {user.nextEmail && (
                <Note>
                  You asked us to change your email to <strong>{user.nextEmail}</strong>. The change
                  will be effective once you haved clicked the confirmation link in the email we
                  sent you.
                </Note>
              )}
            </InputWrapper>

            <FormActions>
              <Button
                intent="action"
                kind="primary"
                type="submit"
                isLoading={user.loading}
                disabled={
                  user.firstName === editedUser.firstName &&
                  user.lastName === editedUser.lastName &&
                  user.email === editedUser.email
                }
              >
                Update
              </Button>
            </FormActions>
          </Form>
        </SectionForm>
      </AccountSection>
    </React.Fragment>
  )
}
