// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import request from 'superagent-interface-promise'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper, Input } from 'components/form'
import {
  MediaUrlContainer,
  MediaUrlLoading,
  MediaUrlText,
} from 'components/form/fields/media-url-builder/media-url.styles'
import { LinkDoc } from 'components/styled/text'
import { schemes } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { WebpushSafariExpired } from '../webpush-safari-expired'

// TYPES

type SafariWebsiteModalProps = {
  appId: number,
  opened: boolean,
  close: () => any,
  certificateName: string,
  certificateExpirationDate: string,
  uploadedAt: string,
  domain: string,
  save: (domain: string, file: ?File, password: string) => void,
  isEditing: boolean,
  certificatesName: List<string>,
  isLoading: boolean,
  ...
}
export type UploadState = {
  password: string,
  isValid: boolean,
  isUploaded: boolean,
  isWrongPass: boolean,
  file: ?File,
  mode: 'P12',
  cn: ?string,
  expire: string,
  uploadedAt: ?string,
  itAlreadyExist: boolean,
  ...
}

export const SafariWebsiteModal = ({
  appId,
  close,
  certificateName,
  certificateExpirationDate,
  save,
  domain,
  opened,
  uploadedAt,
  isEditing,
  certificatesName,
  isLoading,
}: SafariWebsiteModalProps): React.Node => {
  // ======================= STATE GLOBAL
  const [domainLocal, setDomainLocal] = React.useState<string>(domain)
  const [certificatePassword, setCertificatePassword] = React.useState<string>('')
  const [uploading, setUploading] = React.useState<boolean>(false)
  const [upload, setUpload] = React.useState<UploadState>({
    password: '',
    isValid: false,
    isUploaded: false,
    itAlreadyExist: false,
    isWrongPass: false,
    file: undefined,
    mode: 'P12',
    cn: certificateName ?? '',
    expire: certificateExpirationDate ?? undefined,
    uploadedAt: uploadedAt ?? undefined,
  })

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDropAccepted: files => parseFile(files[0]),
    accept: {
      'application/x-pkcs12': ['.p12'],
    },
    multiple: false,
    maxSize: 8192,
    disablePreview: false,
  })

  const parseFile = React.useCallback(
    (file: ?File, callback: ?(UploadState) => void) => {
      const fd = new FormData()
      // $FlowFixMe
      fd.append('file', file)
      fd.append('password', certificatePassword)
      setUploading(true)

      let params = {
        file: file,
        password: certificatePassword,
      }

      request
        .post(`/api/app/${appId}/certificate/parse`)
        .send(fd)
        .then(response => {
          const res = response.body
          const certificateExist = certificatesName.includes(res.CN)

          let resParams: UploadState = {
            ...params,
            mode: res.mode,
            isValid: res.valid,
            itAlreadyExist: res.valid && certificateExist,
            isUploaded: true,
            isWrongPass: !res.valid && !!params.password,
            cn: res.CN,
            expire: res.expire,
            uploadedAt: dayjs().format('DD/MM/YYYY'),
          }
          setUpload(resParams)
          setUploading(false)

          if (callback) {
            callback(resParams)
          }
        })
    },
    [appId, certificatePassword, certificatesName]
  )

  const isReadyForSave = React.useCallback(
    (upload: UploadState) =>
      upload.isValid && upload.mode === 'P12' && dayjs(upload.expire).isAfter(dayjs()),
    []
  )

  // ====================== CONTENT
  const mode: 'field' | 'preview' | 'loading' | 'active' | 'rejected' | 'invalid' = uploading
    ? 'loading'
    : isDragReject
      ? 'rejected'
      : (!upload.isValid && upload.isWrongPass) || (upload.isValid && upload.itAlreadyExist)
        ? 'invalid'
        : isDragActive
          ? 'active'
          : upload.isUploaded || isEditing
            ? 'preview'
            : 'field'

  const dropzoneContent = React.useCallback(() => {
    switch (mode) {
      case 'active':
        return <MediaUrlText>Drop your p12 file here</MediaUrlText>

      case 'rejected':
      case 'invalid':
        return (
          <React.Fragment>
            <MediaUrlText>Drop your P12 file here or</MediaUrlText>
            <Button addOn="prefix" kind="secondary" intent="action">
              <Icon icon="file" />
              Browse your files
            </Button>
            <MediaUrlText isInvalid>
              {upload.itAlreadyExist
                ? 'This certificate is already used'
                : 'This certificate can’t be uploaded'}
            </MediaUrlText>
          </React.Fragment>
        )

      case 'loading':
        return (
          <MediaUrlLoading>
            <Icon icon="spinner" /> <span>Uploading file</span>
          </MediaUrlLoading>
        )

      case 'preview':
        return (
          <Grid
            template={`${upload.isUploaded && !upload.isValid ? '100% ' : '2fr '}200px`}
            margin={[16, 20, 16, 20]}
          >
            <div>
              <Grid template="1fr 1fr" margin={[0, 0, 22, 0]} gap={30}>
                <InputWrapper label="Name">
                  <span>{upload.cn ?? 'Unknown'}</span>
                </InputWrapper>
                <InputWrapper label="Expires in" style={{ marginTop: 0 }}>
                  {upload.expire ? (
                    <WebpushSafariExpired expire={upload.expire} displayIcon={false} />
                  ) : (
                    'Unknown'
                  )}
                </InputWrapper>
              </Grid>
              <Grid template="1fr 1fr" gap={30}>
                <InputWrapper label="Format">{upload.mode}</InputWrapper>
                <InputWrapper label="Uploaded on" style={{ marginTop: 0 }}>
                  <span>{upload.uploadedAt ?? 'Unknown'}</span>
                </InputWrapper>
              </Grid>
            </div>
            <div style={{ justifySelf: 'end' }}>
              <Button addOn="prefix" kind="secondary" intent="action">
                <Icon icon="file" />
                Replace p12 file
              </Button>
            </div>
          </Grid>
        )

      default:
        return (
          <React.Fragment>
            Drop your P12 file here or
            <Button addOn="prefix" kind="secondary" intent="action">
              <Icon icon="file" />
              Browse your files
            </Button>
          </React.Fragment>
        )
    }
  }, [
    mode,
    upload.cn,
    upload.expire,
    upload.uploadedAt,
    upload.mode,
    upload.isValid,
    upload.isUploaded,
    upload.itAlreadyExist,
  ])

  const isAllowedToWritePush = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
  const onDomainLocalChange = React.useCallback(evt => setDomainLocal(evt.target.value), [])
  const onPasswordChange = React.useCallback(evt => setCertificatePassword(evt.target.value), [])
  const onSubmit = React.useCallback(() => {
    if (upload.file !== undefined) {
      parseFile(upload.file, state => {
        if (isReadyForSave(state)) save(domainLocal, state.file, state.password)
      })
    } else {
      save(domainLocal, null, certificatePassword)
    }
  }, [certificatePassword, domainLocal, isReadyForSave, parseFile, save, upload.file])
  return (
    <Popin
      opened={opened}
      close={close}
      style={{ maxWidth: '1200px', minWidth: '800px', overflowY: 'hidden' }}
    >
      <Box>
        <BoxHeader>
          <HeaderBoxTitle
            title={`${isEditing ? 'Edit' : 'Add'} allowed domain`}
            sufffix={
              <LinkDoc
                href="https://doc.batch.com/web/overview.html"
                intent="action"
                target="_blank"
              >
                Help
              </LinkDoc>
            }
          />
          <HeaderBoxActions>
            <Button kind="inline" intent="neutral" onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>

        <BoxBody $padding>
          <InputWrapper label="Allowed domain" htmlFor="allowed-domain">
            <Input
              id="allowed-domain"
              placeholder="https://www.batch.com"
              name="allowed domain"
              onChange={onDomainLocalChange}
              value={domainLocal}
              hint="Add the domain linked to your certificate"
            />
          </InputWrapper>

          <InputWrapper label="Safari certificate" htmlFor="safari-certificate">
            <MediaUrlContainer
              {...getRootProps()}
              mode={mode}
              isActive={mode === 'active'}
              isDragReject={mode === 'rejected'}
              isInvalid={upload.isUploaded && !upload.isValid}
              style={{
                height: '164px',
                justifyContent:
                  upload.isUploaded && !upload.isValid && !uploading ? 'start' : 'center',
              }}
            >
              <input {...getInputProps()} id="safari-certificate" />
              {dropzoneContent()}
            </MediaUrlContainer>
          </InputWrapper>
          {upload.isUploaded && !!upload.file && !upload.isValid && (
            <InputWrapper
              label="Certificate password"
              invalid={upload.isWrongPass}
              feedbackType="insight"
              feedback={
                upload.isWrongPass
                  ? 'Unable to read certificate with given password. Please try again.'
                  : ''
              }
              htmlFor="password"
            >
              <Input
                id="password"
                name="password"
                onChange={onPasswordChange}
                value={certificatePassword}
              />
              <div style={{ color: schemes.grayscale['60'], fontWeight: 500, marginTop: '12px' }}>
                It seems you uploaded a protected file, enter the password.
              </div>
            </InputWrapper>
          )}
        </BoxBody>
        <BoxFooter isEditable>
          <PermissionButton kind="inline" onClick={close} isAllowed={isAllowedToWritePush}>
            Cancel
          </PermissionButton>
          <PermissionButton
            intent="action"
            kind="primary"
            onClick={onSubmit}
            isAllowed={isAllowedToWritePush}
            disabled={!isEditing ? !domainLocal || !upload.file : !domainLocal}
            isLoading={isLoading}
          >
            {isEditing ? 'Edit domain' : 'Add new domain'}
          </PermissionButton>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
