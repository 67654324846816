// @flow
import styled, { type PropsWithTheme, type StyledComponent, css } from 'styled-components'

import { colors } from 'components/styled/tokens'

export const StickyDayContent: StyledComponent<{ firstElement: boolean, ... }, *, *> = styled.div`
  background: rgba(255, 255, 255, 0.97);
  ${(props: PropsWithTheme<{ firstElement: boolean, ... }, any>) =>
    !props.firstElement &&
    css`
      border-top: 1px solid rgba(15, 15, 15, 0.12);
    `}
  border-bottom: 1px solid rgba(15, 15, 15, 0.12);
  box-shadow: 0px 1px 3px rgba(15, 15, 15, 0.03);
  padding: 9px 20px;
  position: sticky;
  font-weight: 500;
  top: -1px;
  z-index: 1;
  color: ${colors.text};

  span {
    color: ${colors.textLight};
    font-weight: 400;
    margin-left: 4px;
  }
`
