// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useMatch } from 'react-router-dom'

import {
  useCurrentCompanyHasAllFeatures,
  useCurrentCompanyHasFeature,
  useCurrentCompanyHasOneFeatureAmongst,
} from 'components/_hooks'
import { useSelectedApp } from 'components/app/use-selected-app'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { HardLinkOrNavLink } from 'components/navigation/hard-link-or-nav-link'
import { useProjectChannels } from 'components/project/use-project-channels'

import { generateSettingsUrl } from 'com.batch.common/router'

import { currentAppSelector, currentProjectAppsSelector } from 'com.batch.redux/app'
import { currentCompanySelector } from 'com.batch.redux/company'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

export const ProjectSettingsMenu = (): React.Node => {
  const user = useSelector(currentUserSelector)
  const currentApp = useSelector(currentAppSelector)
  const { hasOneCepChannelConfigured } = useProjectChannels()
  const app = useSelectedApp()
  const apps = useSelector(currentProjectAppsSelector)

  const firstPushApp = React.useMemo(() => {
    return apps.find(a => a.platform === 'ios' || a.platform === 'android')
  }, [apps])

  // Restriction for team
  const userCanAdministrate = user.permissionsForCurrentCompany.has('administrate')

  const company = useSelector(currentCompanySelector)
  const currentProject = useSelector(currentProjectSelector)

  const matchSettings = useMatch('/:companyId/projects/:projectId/apps/:appId/settings/:activeTab')
  const matchProjectSettings = useMatch('/:companyId/projects/:projectId/settings/:activeTab')

  const hasCurrentApp = React.useMemo(() => currentApp.id !== 0, [currentApp])

  /* when we come from php on a channel page, all links on this page
  will lead to another app ; to make this work, we need a hard reload
  */
  const requireHardReload = React.useMemo(() => !hasCurrentApp, [hasCurrentApp])

  // -----  derived
  const activeTab = React.useMemo(
    () => matchSettings?.params?.activeTab ?? matchProjectSettings?.params?.activeTab ?? '',
    [matchProjectSettings?.params?.activeTab, matchSettings?.params?.activeTab]
  )

  const companyHasOneLegacyForm = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-campaign',
    'cep-show-legacy-campaign-omni',
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
    'inapp-campaigns',
  ])
  const currentCompanyCanUseMobileLandings = useCurrentCompanyHasAllFeatures([
    'cep-show-legacy-campaign',
    'inapp-messaging',
  ])
  const currentCompanyHasInAppCampaigns = useCurrentCompanyHasFeature('inapp-campaigns')

  const companyNeedsThemes = currentCompanyCanUseMobileLandings || currentCompanyHasInAppCampaigns

  return (
    <React.Fragment>
      <HardLinkOrNavLink
        hardReload={requireHardReload}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          projectId: currentProject.id,
          activeTab: 'global',
        })}
        data-testid="subnav-general"
      >
        General
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={requireHardReload}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: hasOneCepChannelConfigured && currentApp.id === 0 ? 'email' : { appId: app.id },
          projectId: currentProject.id,
          activeTab: currentApp.id === 0 ? 'email' : 'push',
        })}
        isActive={activeTab === 'push' || activeTab === 'email'}
        data-testid="subnav-channels"
      >
        Channels
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={requireHardReload}
        disabled={!userCanAdministrate}
        addOn={!userCanAdministrate ? 'suffix' : undefined}
        $withIconSuffix={!userCanAdministrate}
        as={userCanAdministrate ? NavLink : 'button'}
        url={
          !userCanAdministrate
            ? ''
            : generateSettingsUrl({
                companyId: company.id,
                channel: { appId: app.id },
                projectId: currentProject.id,
                activeTab: 'team',
              })
        }
        data-testid="subnav-team"
      >
        <span>Team</span>
        {!userCanAdministrate && (
          <Tooltip
            tooltip="Only an administrator can view and edit users access"
            placement="bottom"
          >
            <span className="styled-button-suffix" style={{ marginLeft: 4 }}>
              <Icon icon="lock" size={12} />
            </span>
          </Tooltip>
        )}
      </HardLinkOrNavLink>
      {companyNeedsThemes && Boolean(firstPushApp) && (
        <HardLinkOrNavLink
          hardReload={requireHardReload || app.platform === 'webpush'}
          url={generateSettingsUrl({
            companyId: company.id,
            channel: { appId: app.platform === 'webpush' ? firstPushApp.id : app.id },
            projectId: currentProject.id,
            activeTab: 'themes',
          })}
          data-testid="subnav-themes"
        >
          Themes
        </HardLinkOrNavLink>
      )}
      <HardLinkOrNavLink
        className={!hasCurrentApp && activeTab === 'labels' ? 'active' : ''}
        hardReload={Boolean(matchSettings)}
        style={{ marginRight: 12 }}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: 'email',
          projectId: currentProject.id,
          activeTab: 'labels',
          ...(hasOneCepChannelConfigured ? { channel: 'email' } : { channel: { appId: app.id } }),
        })}
        isActive={activeTab === 'labels'}
        data-testid="subnav-labels"
      >
        Labels
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        className={!hasCurrentApp && activeTab === 'cappings' ? 'active' : ''}
        hardReload={Boolean(matchSettings)}
        url={generateSettingsUrl({
          companyId: company.id,
          projectId: currentProject.id,
          activeTab: 'cappings',
          ...(hasOneCepChannelConfigured ? { channel: 'email' } : { channel: { appId: app.id } }),
        })}
        isActive={activeTab === 'cappings'}
        data-testid="subnav-cappings"
      >
        Cappings
      </HardLinkOrNavLink>
      {companyHasOneLegacyForm && (
        <HardLinkOrNavLink
          hardReload={requireHardReload}
          style={{ marginRight: 12 }}
          url={generateSettingsUrl({
            companyId: company.id,
            channel: { appId: app.id },
            projectId: currentProject.id,
            activeTab: 'debug',
          })}
          isActive={activeTab === 'debug'}
          data-testid="subnav-debug"
        >
          Debug
        </HardLinkOrNavLink>
      )}
    </React.Fragment>
  )
}
