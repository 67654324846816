// @flow

import styled, { type StyledComponent } from 'styled-components'

export const PreviewScroll: StyledComponent<*, *, HTMLElement> = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 0;
  overflow-y: scroll;
  background-image: url('/medias/img/builders/push-background.webp');
  background-repeat: repeat;
  background-size: 400px 400px;

  &::-webkit-scrollbar {
    width: 14px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    background: #edeef0;
    border-radius: 16px;
    border: 5px solid #fafafc;
  }
`
type PreviewScrollContentProps = { isWeb?: boolean, ... }
export const PreviewScrollContent: StyledComponent<PreviewScrollContentProps, *, HTMLElement> =
  styled.div`
    max-width: ${(props: PreviewScrollContentProps) => (props.isWeb ? 440 : 360)}px;
    margin: 0px auto 0 auto;
    max-height: 780px;
    padding: 20px 20px 0 20px;
    position: relative;
  `

export const PushPreviewToolbarContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  bottom: 0;
  position: absolute;
  z-index: 3;
  background: rgba(255, 255, 255, 0.98);
  border-top: 1px solid HSL(228, 19%, 95%);
  left: 0;
  right: 0;
  padding: 6px 24px;
`
