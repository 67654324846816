// @noflow

import * as Immutable from 'immutable'

import * as Types from 'com.batch.common/legacy-query-types'

import * as ACT from './action-name'
import api, { rawEstimateToRecord } from './targeting.api'

import { EstimateFactory } from 'com.batch.redux/_records'

export const KIND_CONDITION = 'condition'
export const KIND_LOGICAL = 'logical'
const LOGICAL_AND = 'and'
const LOGICAL_OR = 'or'
export const initialState = Immutable.fromJS({
  loading: false,
  awaitingParse: false,
  conditions: {},
  query: null,
  queryError: false,
  estimate: EstimateFactory(),
  tree: {
    kind: 'logical',
    value: LOGICAL_AND,
    id: 'root',
    child: [],
  },
})
  .set('clusters', Immutable.Set(['N', 'E', 'D', 'U']))
  .set('regions', new Immutable.OrderedSet())
  .set('languages', new Immutable.OrderedSet())

let conditionId = 97
let conditionCycle = 0

export const generateConditionId = () => {
  const id = `${String.fromCharCode(conditionId)}${conditionCycle}`
  conditionId++
  if (conditionId > 122) {
    conditionId = 97
    conditionCycle++
  }
  return id
}

export const buildPath = (root, id, path = ['tree']) => {
  const kind = root.getIn([...path, 'kind'])
  if (root.getIn([...path, 'id']) === id) {
    return path
  }
  if (kind === KIND_LOGICAL) {
    let found = []
    root.getIn([...path, 'child']).forEach((value, key) => {
      try {
        found = buildPath(root, id, [...path, 'child', key])
        if (found.length > 0) {
          return false // exit the forEach
        }
      } catch (err) {
        console.log(err)
      }
    })
    return found
  }
  throw 'condition path does not exist'
}

// ========================================================
// ACTION NAME CONSTANTS
// ========================================================
export const TARGETING_NOOP = 'TARGETING_NOOP'
export const TARGETING_INIT = 'TARGETING_INIT'
export const TARGETING_PARSE = 'TARGETING_PARSE'
export const TARGETING_PARSE_ERROR = 'TARGETING_PARSE_ERROR'
export const TARGETING_VALIDATE_CONDITION = 'TARGETING_VALIDATE_CONDITION'
export const TARGETING_ESTIMATE_BUSY = 'TARGETING_ESTIMATE_BUSY'
export const TARGETING_ESTIMATE_SUCCESS = 'TARGETING_ESTIMATE_SUCCESS'
export const TARGETING_ESTIMATE_FAILURE = 'TARGETING_ESTIMATE_FAILURE'
export const TARGETING_ESTIMATE_LOADING = 'TARGETING_ESTIMATE_LOADING'
export const TARGETING_QUERY_CHANGE = 'TARGETING_QUERY_CHANGE'
export const TARGETING_ADD_CLUSTER = 'TARGETING_ADD_CLUSTER'
export const TARGETING_UPD_REGION = 'TARGETING_UPD_REGION'
export const TARGETING_UPD_LANGUAGE = 'TARGETING_UPD_LANGUAGE'
export const TARGETING_UPD_AUDIENCE = 'TARGETING_UPD_AUDIENCE'
export const TARGETING_REMOVE_CLUSTER = 'TARGETING_REMOVE_CLUSTER'
export const TARGETING_UPDATE_CONDITION = 'TARGETING_UPDATE_CONDITION'
export const TARGETING_ADD_CONDITION = 'TARGETING_ADD_CONDITION'
export const TARGETING_ADD_LOGICAL = 'TARGETING_ADD_LOGICAL'
export const TARGETING_REMOVE_LOGICAL = 'TARGETING_REMOVE_LOGICAL'
export const TARGETING_REMOVE_CONDITION = 'TARGETING_REMOVE_CONDITION'
export const TARGETING_TOGGLE_LOGICAL = 'TARGETING_TOGGLE_LOGICAL'
export const TARGETING_TOGGLE_CONDITION = 'TARGETING_TOGGLE_CONDITION'
export const TARGETING_RETRY = 'TARGETING_RETRY'

// ========================================================
// SELECTORS
// ========================================================
export * from './targeting.selector'

// ========================================================
// ACTIONS
// ========================================================

// add a condition for {attribute}.
// if afterConditionId is not provided, add it to the root of the tree
export const addCondition = ({ attribute, logicalId = 'root' }) => {
  const id = generateConditionId()
  const defaultFunc = api.getDefaultFunctionForAttribute(attribute)
  const defaultOperator = api.getDefaultOperatorForAttributeAndFunc(attribute, defaultFunc)
  return {
    type: TARGETING_ADD_CONDITION,
    payload: {
      id,
      attribute,
      defaultFunc,
      defaultOperator,
      logicalId,
    },
  }
}

export const queryParseAction = ({ awaits, attributes, values, query }) => {
  if (awaits && query) {
    let parsingResult = null
    try {
      parsingResult = api.parseQuery(query, attributes, values)
      return {
        type: TARGETING_PARSE,
        payload: parsingResult,
      }
    } catch (err) {
      console.log(err)
      return {
        type: TARGETING_PARSE_ERROR,
        payload: null,
      }
    }
  } else {
    return {
      type: TARGETING_NOOP,
      payload: {},
    }
  }
}
export const updateCustomAudiences = audiences => {
  return {
    type: TARGETING_UPD_AUDIENCE,
    payload: { audiences },
  }
}

export const addLogical = logicalId => {
  const id = generateConditionId()
  return { type: TARGETING_ADD_LOGICAL, payload: { id, logicalId } }
}
export const updateCondition = ({ id, changes }) => {
  return { type: TARGETING_UPDATE_CONDITION, payload: { id, changes } }
}
export const deleteCondition = id => {
  return { type: TARGETING_REMOVE_CONDITION, payload: { id } }
}
export const deleteLogical = id => {
  return { type: TARGETING_REMOVE_LOGICAL, payload: { id } }
}
export const toggleLogical = id => {
  return { type: TARGETING_TOGGLE_LOGICAL, payload: { id } }
}
export const toggleCondition = id => {
  return { type: TARGETING_TOGGLE_CONDITION, payload: { id } }
}
export const updateClusterActiveState = (code, active) => {
  return {
    type: active ? TARGETING_ADD_CLUSTER : TARGETING_REMOVE_CLUSTER,
    payload: code,
  }
}
export const refreshEstimate = () => {
  return { type: TARGETING_RETRY, payload: null }
}
export const initEstimate = () => {
  return {
    type: TARGETING_PARSE,
    payload: {
      tree: initialState.get('tree'),
      conditions: initialState.get('conditions'),
    },
  }
}
export const estimateBusyAction = () => {
  return {
    type: TARGETING_ESTIMATE_BUSY,
    payload: null,
  }
}

export const conditionValidationAction = ({ id, valid }) => {
  return {
    type: TARGETING_VALIDATE_CONDITION,
    payload: { id, valid },
  }
}
export const estimateLoadingAction = () => {
  return {
    type: TARGETING_ESTIMATE_LOADING,
    payload: null,
  }
}
export const estimateSucceessAction = result => {
  return {
    type: TARGETING_ESTIMATE_SUCCESS,
    payload: rawEstimateToRecord(result),
  }
}
export const estimateFailureAction = error => {
  return {
    type: TARGETING_ESTIMATE_FAILURE,
    payload: { error },
  }
}
export const queryChangeAction = result => (dispatch, getState) => {
  const state = getState()
  if (JSON.stringify(state.targeting.get('query')) !== JSON.stringify(result)) {
    dispatch({
      type: TARGETING_QUERY_CHANGE,
      payload: result,
    })
  }
}

export const updateLangRegion = (value, isRegion = false) => {
  return {
    type: isRegion ? TARGETING_UPD_REGION : TARGETING_UPD_LANGUAGE,
    payload: value,
  }
}

// ========================================================
// REDUCER
// ========================================================
const initialConditionState = Immutable.fromJS({
  attribute: null,
  func: null,
  value: null,
  negate: false,
  operator: null,
  args: {},
})

function conditionReducer(state = initialConditionState, action) {
  const payload = action.payload
  switch (action.type) {
    case TARGETING_VALIDATE_CONDITION:
      return state.set('valid', payload.valid)
    case TARGETING_TOGGLE_CONDITION:
      return state.set('negate', !state.get('negate', false))
    case TARGETING_ADD_CONDITION: {
      const { attribute, defaultFunc, defaultOperator } = payload
      const args =
        attribute.allowedKeys.size === 1
          ? Immutable.Map([
              [
                '__KEY__',
                `${attribute.allowedKeys.first().name}|||${attribute.allowedKeys.first().kind}`,
              ],
            ])
          : Immutable.Map()
      return state.merge(
        Immutable.Map({
          attribute: attribute,
          func: defaultFunc,
          args,
          operator: defaultOperator,
          value:
            attribute.get('type') === Types.BOOLEAN ||
            (defaultOperator && defaultOperator.get('forcedInputType', '') === 'boolean')
              ? true
              : '',
        })
      )
    }
    case TARGETING_UPDATE_CONDITION: {
      let value = null
      let updatePath = []
      switch (payload.changes.what) {
        case '__KEY__':
        case '__VALUE__':
        case '__LABEL__':
        case '__PERIOD__':
        case '__POSITION__':
        case '__RADIUS__':
        case '__TOKEN__':
          updatePath.push('args')
          updatePath.push(payload.changes.what)
          value = payload.changes.value
          if (payload.changes.what === '__KEY__') {
            state = state.set('args', state.get('args').delete('__VALUE__'))
          }
          break
        case 'func':
          updatePath.push('func')
          value = api.getFunctionByValue(payload.changes.value)

          // quand un tag passe de CONTENT (fake function pour désigner la collection) ) à COUNT
          // => on veut reset l'operator à =
          if (state.getIn(['func', 'value'], '') === 'content') {
            state = state.set('operator', api.getOperatorByValue('='))
          }
          // quand un tag passe à CONTENT (fake function pour désigner la collection) )
          // => on veut reset l'operator à $contains
          if (payload.changes.value === 'content') {
            state = state.set('operator', api.getOperatorByValue('contains any of'))
          }

          if (payload.changes.value === 'date') {
            state = state.set('operator', api.getOperatorByValue('>'))
          }
          state = state.set('value', null)
          break
        case 'operator':
          updatePath.push('operator')
          value = api.getOperatorByValue(payload.changes.value)
          if (
            state.getIn(updatePath).get('arrayValue') !== value.get('arrayValue') ||
            value.get('forcedInputType', false) !==
              state.getIn(updatePath).get('forcedInputType', false)
          ) {
            state = state.set(
              'value',
              value.get('forcedInputType', false) === Types.BOOLEAN ? true : null
            )
          }
          break
        case 'value':
          // if(updatePath.getIn(['func','returns']))
          updatePath.push('value')
          value = payload.changes.value
          break
        default:
          return state
      }
      return state.setIn(updatePath, value)
    }
    default:
      return state
  }
}

function targetingReducer(state = initialState, action) {
  const payload = action.payload
  switch (action.type) {
    case ACT.SAVE_CAMPAIGN_SUCCESS:
      return initialState
    case TARGETING_INIT:
      return payload
    case TARGETING_PARSE:
      return state
        .set('awaitingParse', false)
        .set('queryError', false)
        .set('conditions', payload.conditions)
        .set('tree', payload.tree)
    case TARGETING_PARSE_ERROR:
      return state.set('awaitingParse', false).set('queryError', true)
    case TARGETING_QUERY_CHANGE:
      return state.set('query', payload)
    case TARGETING_ESTIMATE_LOADING:
      return state.setIn(['estimate', 'loading'], true).setIn(['estimate', 'error'], false)
    case TARGETING_ESTIMATE_FAILURE:
      return state.setIn(['estimate', 'loading'], false).setIn(['estimate', 'error'], true)
    case TARGETING_ESTIMATE_SUCCESS:
      return state.set('estimate', payload)
    case TARGETING_UPD_REGION:
      return state.set('regions', Immutable.Set(payload === null ? [] : payload.map(v => v.value)))
    case TARGETING_UPD_LANGUAGE:
      return state.set(
        'languages',
        Immutable.Set(payload === null ? [] : payload.map(v => v.value))
      )
    case TARGETING_ADD_CONDITION:
      const path = [...buildPath(state, payload.logicalId), 'child']
      return state
        .setIn(['conditions', payload.id], conditionReducer(initialConditionState, action))
        .setIn(
          path,
          state.getIn(path).push(
            Immutable.Map({
              kind: KIND_CONDITION,
              id: payload.id,
            })
          )
        )
    case TARGETING_ADD_LOGICAL:
      const logicalPath = [...buildPath(state, payload.logicalId), 'child']
      return state.setIn(
        logicalPath,
        state.getIn(logicalPath).push(
          Immutable.Map({
            kind: KIND_LOGICAL,
            value: LOGICAL_AND,
            child: new Immutable.List(),
            id: payload.id,
          })
        )
      )
    case TARGETING_VALIDATE_CONDITION:
      const pos = ['conditions', payload.id]
      // do not change the object if we did not change
      if (state.getIn(pos).get('valid') === payload.valid) {
        return state
      } else {
        return state.setIn(pos, conditionReducer(state.getIn(pos), action))
      }
    case TARGETING_TOGGLE_CONDITION:
    case TARGETING_UPDATE_CONDITION:
      const updatePath = ['conditions', payload.id]
      return state.setIn(updatePath, conditionReducer(state.getIn(updatePath), action))
    case TARGETING_REMOVE_CONDITION:
      return state.deleteIn(['conditions', payload.id]).deleteIn(buildPath(state, payload.id))
    case TARGETING_REMOVE_LOGICAL:
      return state.deleteIn(buildPath(state, payload.id))
    case TARGETING_TOGGLE_LOGICAL:
      const logicalValuePath = [...buildPath(state, payload.id), 'value']
      return state.setIn(
        logicalValuePath,
        state.getIn(logicalValuePath) === LOGICAL_AND ? LOGICAL_OR : LOGICAL_AND
      )
    case TARGETING_ADD_CLUSTER:
      return state.set('clusters', state.get('clusters').add(payload))
    case TARGETING_REMOVE_CLUSTER:
      let newState = state.set('clusters', state.get('clusters').remove(payload))
      if (newState.get('clusters').size === 0) {
        newState = newState.set('clusters', initialState.get('clusters'))
      }
      return newState
    default:
      return state
  }
}

export default targetingReducer
