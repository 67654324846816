// @flow

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createRangeSelection, $getRoot, $setSelection } from 'lexical'
import * as React from 'react'

export function LexicalSelectionPlugin({ selection }: { selection: [number, number], ... }): null {
  const [editor] = useLexicalComposerContext()
  // there is a better way, I hope, but documentation is lagging
  React.useLayoutEffect(() => {
    editor.update(() => {
      const root = $getRoot()
      const textNodes = root.getAllTextNodes()
      // pour l'instant on a tjrs un seul text node au moment de la restauration, les plugins tag & logical s'activent ensuite
      const textNode = textNodes[0]
      if (textNode) {
        const sel = $createRangeSelection()
        sel.focus.set(textNode.getKey(), selection[0], 'text')
        sel.anchor.set(textNode.getKey(), selection[0], 'text')
        $setSelection(sel)
      }
    })
    // we want to restore caret position on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])
  return null
}
