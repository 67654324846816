// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Box, BoxHeader, BoxTitle } from 'components/common/box'
import { Grid } from 'components/common/grid'

export const ProfilebasMetricsGrid: StyledComponent<{ ... }, {}, typeof Grid> = styled(Grid)`
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

export const ProfilebaseBox: StyledComponent<{ ... }, {}, typeof Box> = styled(Box)`
    margin-bottom: 0;
    background: #ffffff;

    ${BoxTitle} {
        gap: 6px;
    }

    ${BoxHeader} {
        border-bottom: 0;
    }
}
`
