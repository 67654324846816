// @flow
import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

type StickyContainerProps = {
  stuck: boolean,
  ...
}
const StickyContainer: StyledComponent<StickyContainerProps, *, HTMLElement> = styled.div`
  @media (min-height: 700px) {
    position: sticky;
    top: 0;
    z-index: ${(props: StickyContainerProps) => (props.stuck ? 200 : 40)};
    margin: ${(props: StickyContainerProps) => (props.stuck ? -1 : 0)}px 0 0 1px;
  }
`
const ScrollTrackerContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  position: relative;
  padding-bottom: 0.01px; /* Avoid overflowing margins */
  &:last-child {
    padding-bottom: 400px;
  }
`
type ScrollTrackerAnchorProps = { anchorOffset: number }
const ScrollTrackerAnchor: StyledComponent<*, *, HTMLElement> = styled.div`
  position: absolute;
  top: ${(props: ScrollTrackerAnchorProps) => `${props.anchorOffset || 0}px`};
`

type StickyProps = {
  onStickyChange?: (isStuck: boolean) => void,
  children: React.Node,
}
export const Sticky = ({ children, onStickyChange }: StickyProps): React.Node => {
  const [isStuck, setIsStuck] = React.useState(false)
  const io = React.useMemo(
    () =>
      new IntersectionObserver(
        entries => {
          const isStuck =
            entries[0].isIntersecting && window.matchMedia('(min-height: 700px)').matches
          if (onStickyChange) onStickyChange(isStuck)
          setIsStuck(isStuck)
        },
        { rootMargin: '0% 0% -100% 0%' }
      ),
    [onStickyChange]
  )

  const containerRef = React.useRef<?HTMLElement>(null)

  React.useLayoutEffect(() => {
    const container = containerRef.current
    if (container) io.observe(container)

    return () => {
      if (container) io.unobserve(container)
    }
  }, [io])
  return (
    <StickyContainer ref={containerRef} stuck={isStuck}>
      {children}
    </StickyContainer>
  )
}
type ScrollTrackerProps = {
  children: React.Node,
  id: string,
  anchorOffset: number,
  onScrollEnter?: () => void,
  onScrollLeave?: () => void,
}
export const ScrollTracker = ({
  children,
  id,
  anchorOffset,
  onScrollEnter,
  onScrollLeave,
}: ScrollTrackerProps): React.Node => {
  const io = React.useMemo(
    () =>
      new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            onScrollEnter && onScrollEnter()
          } else {
            onScrollLeave && onScrollLeave()
          }
        },
        { rootMargin: '-50% 0%' }
      ),
    [onScrollEnter, onScrollLeave]
  )
  const containerRef = React.useRef<?HTMLElement>(null)

  React.useLayoutEffect(() => {
    const container = containerRef.current
    if (container) io.observe(container)

    return () => {
      if (container) io.unobserve(container)
    }
  }, [io])

  return (
    <ScrollTrackerContainer ref={containerRef}>
      <ScrollTrackerAnchor id={id} anchorOffset={anchorOffset} />
      {children}
    </ScrollTrackerContainer>
  )
}
