// @flow

import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { LoadingSvgAnim } from 'components/common/empty-states/'
import * as colors from 'components/styled/colors'
import { ShapePlaceholder, HiddenPlaceholder } from 'components/styled/placeholder'
import { schemes } from 'components/styled/tokens'

export const LegendTimeLabel: StyledComponent<{ ... }, { ... }, *> = styled(
  // $FlowExpectedError lib def not up to date
  HiddenPlaceholder.withComponent('text')
)`
  font-weight: 500;
  fill: #c1c1c1;
  text-anchor: middle;
`
type ThemeProps = { isLoading?: boolean, ... }
type NoPropsThemeProps = PropsWithTheme<{ ... }, ThemeProps>
export const LegendTimeLabelSkeleton: StyledComponent<{ ... }, { isLoading?: boolean, ... }, *> =
  styled(
    // $FlowExpectedError lib def not up to date
    LegendTimeLabel.withComponent('rect')
  )`
    ${(props: NoPropsThemeProps) =>
      props.theme.isLoading &&
      css`
        animation-name: ${LoadingSvgAnim};
        animation-duration: 2s;
        animation-iteration-count: infinite;
      `}
  `
type LegendTimeCurrentProps = { height: number, x: number, ... }
export const LegendTimeCurrent: StyledComponent<LegendTimeCurrentProps, { ... }, *> = styled(
  // $FlowExpectedError lib def not up to date
  HiddenPlaceholder.withComponent('g')
)`
  width: 140px;
  height: ${(props: LegendTimeCurrentProps) => props.height - 1}px;
  x: ${(props: LegendTimeCurrentProps) => props.x - 70}px;
  transform: translate3d(${(props: LegendTimeCurrentProps) => props.x}px, 0, 0);
  transition: all ease-out 0.2s;

  rect {
    width: 140px;
    height: ${(props: LegendTimeCurrentProps) => props.height - 1}px;
    x: -70px;
  }
  text {
    font-weight: 500;
    fill: #3e5067;
    text-anchor: middle;
  }
`

export const LegendTimeRect: StyledComponent<{ ... }, { ... }, *> = styled.rect`
  fill: #fcfcfd;
  pointer-events: none;
  cursor: pointer;
`

export const LegendTimeContainer: StyledComponent<{ ... }, { ... }, *> = styled.g`
  pointer-events: none;
`

export const LegendTimeSeparator: StyledComponent<{ ... }, { isLoading?: boolean, ... }, *> =
  styled.line`
    stroke: ${(props: PropsWithTheme<{ ... }, { isLoading?: boolean, ... }>) =>
      props.theme.isLoading ? colors.stroke.lighter : '#fafafb'};
  `

export const ChartTtip: StyledComponent<{ ... }, { ... }, *> = styled(
  // $FlowExpectedError lib def not up to date
  HiddenPlaceholder.withComponent('div')
)`
  background: ${schemes.grayscale['80']};
  text-align: left;
  width: 140px;
  border-radius: 4px;
  pointer-events: none;
  font-weight: bold;
  position: absolute;
  top: 20px;
  font-size: 11px;
  color: #fff;
  z-index: 3;
  transition: all ease-out 0.2s;
`

export const ChartTtipElement: StyledComponent<{ ... }, { ... }, *> = styled.div`
  margin: 0 10px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  &:last-child {
    border-bottom: none;
  }
`

export const ChartTtipTitle: StyledComponent<{ color: string, ... }, { ... }, *> = styled.div`
  display: flex;
  align-items: start;
  span {
    color: #b1b0b0;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 16px;
    line-height: 10px;
    flex-grow: 1;
  }
  svg {
    fill: ${(props: PropsWithTheme<{ color: string, ... }, { ... }>) => props.color};
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
`

export const ChartTtipValue: StyledComponent<{ ... }, { ... }, *> = styled.div`
  display: block;
  line-height: 29px;
  font-weight: 300;
  font-size: 24px;
`

export const ChartNoData: StyledComponent<{ height: number, ... }, { ... }, *> = styled.div`
  height: ${(props: PropsWithTheme<{ height: number, ... }, { ... }>) => props.height}px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
`

export const ChartBar: StyledComponent<{ ... }, { ... }, *> = styled(ShapePlaceholder)``

export const ChartScale: StyledComponent<{ ... }, { ... }, *> = styled(
  // $FlowExpectedError lib def not up to date
  HiddenPlaceholder.withComponent('text')
)``

export const ChartScaleSkeleton: StyledComponent<{ ... }, { isLoading?: boolean, ... }, *> = styled(
  // $FlowExpectedError lib def not up to date
  ChartScale.withComponent('rect')
)`
  ${(props: NoPropsThemeProps) =>
    props.theme.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`

export const ChartBarSkeleton: StyledComponent<
  { ... },
  { isLoading?: boolean, ... },
  typeof ChartBar,
> = styled(ChartBar)`
  fill: '#c1c1c1';
  ${(props: NoPropsThemeProps) =>
    props.theme.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`
