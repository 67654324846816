// @flow
import { createSelector } from 'reselect'

import { type State } from 'com.batch.redux/_records'

import {
  type SubDistributionStateRecord,
  type ChannelsDistributionStateRecord,
} from 'com.batch/profilebase/models/sub-distribution.records'

type extract<T> = State => T

const channelsDistributionSelector = (state: State): ChannelsDistributionStateRecord =>
  state.profilebase.channelsDistribution

export const channelDistributionSelector = (
  channel: 'push' | 'email' | 'sms' | 'webpush'
): extract<SubDistributionStateRecord> =>
  createSelector(
    channelsDistributionSelector,
    channelsDistribution => channelsDistribution[channel]
  )
