// @flow
import Immutable, { type RecordFactory, type RecordOf, type Map, type List } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import { type ProfileAudienceRecord } from 'com.batch/audience/model/audience-profile.records'
import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

export type AudienceListSortTable = 'name' | 'display_name' | 'nb_ids' | 'type' | 'updated'

type ProfileAudienceStateProps = {
  entities: Map<string, ProfileAudienceRecord>,
  loadingState: fetchingState,
  creatingState: fetchingState,
  page: number,
  count: number,
  idsPerPage: Map<number, List<string>>,
  nbPerPage: number,
  sortBy: AudienceListSortTable,
  sortDirection: 'asc' | 'dsc',
  search: string,
  estimatesForAudiences: Map<string, EstimateProfileRecord>,
  ...
}

export const ProfileAudienceStateFactory: RecordFactory<ProfileAudienceStateProps> =
  Immutable.Record(
    ({
      entities: Immutable.Map(),
      loadingState: 'LOADED',
      creatingState: 'INIT',
      page: 1,
      count: 0,
      nbPerPage: 10,
      idsPerPage: Immutable.Map(),
      sortBy: 'nb_ids',
      sortDirection: 'dsc',
      search: '',
      estimatesForAudiences: Immutable.Map(),
    }: ProfileAudienceStateProps)
  )

export type ProfileAudienceStateRecord = RecordOf<ProfileAudienceStateProps>
