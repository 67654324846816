// @flow
import { type Set } from 'immutable'
import * as React from 'react'

import { type FilterPushTokensType } from 'com.batch/message/models/message.records'
import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

type Step = {
  label: string,
  count: number,
  ratio: number,
}

const buildStep = (wording: string, nb: number, total: number): Step => {
  return {
    label: wording,
    count: nb,
    ratio: total ? nb / total : 0,
  }
}

const wordingForChannel = (
  channel: ChannelUntilCleanup,
  filterPushTokens?: FilterPushTokensType
): string => {
  switch (channel) {
    case 'email':
      return 'email address'
    case 'sms':
      return 'phone number'
    case 'push':
      if (filterPushTokens === 'collected') {
        return 'collected push token'
      } else if (filterPushTokens === 'imported') {
        return 'imported push token'
      }
      return 'push token'
  }
}

type Props = {
  filterPushTokens: FilterPushTokensType,
  subscriptionStatus: 'fullbase' | 'marketing',
  forceNonOptin: boolean,
  estimate: EstimateProfileRecord,
  channel: ?ChannelUntilCleanup,
  pushPlatforms?: Set<ProjectPlatforms>,
}

export const useEstimateProfileChannelTooltipHelper = ({
  filterPushTokens,
  subscriptionStatus,
  forceNonOptin,
  estimate,
  channel,
  pushPlatforms,
}: Props): { steps: Array<Step>, pushPlatformSteps: Array<Step> } => {
  const steps = React.useMemo(() => {
    let steps: Array<Step> = []
    const total = estimate.total.profileCount
    steps.push(buildStep('Total profiles', total, total))
    const matching = estimate.matching.profileCount
    if (matching !== total) steps.push(buildStep('Matching targeting', matching, total))
    const reachable = channel
      ? estimate.matching[channel].nonUniqueCount
      : estimate.matching.reachableProfileCount
    steps.push(
      buildStep(
        channel ? 'With ' + wordingForChannel(channel, filterPushTokens) : 'Has email, push or sms',
        reachable,
        total
      )
    )

    if (channel) {
      if (channel !== 'push') {
        if (subscriptionStatus === 'marketing') {
          const optedIn = estimate.matchingOptin[channel].nonUniqueCount
          steps.push(buildStep('Opted-in', optedIn, total))
          const unique = estimate.matchingOptin[channel].uniqueCount
          steps.push(buildStep('Unique', unique, total))
        } else {
          const unique = estimate.matching[channel].uniqueCount
          steps.push(buildStep('Unique', unique, total))
        }
      }
    } else {
      if (subscriptionStatus === 'marketing' && !forceNonOptin) {
        const optedIn = estimate.matchingOptin.reachableProfileCount
        steps.push(buildStep('Opted-in', optedIn, total))
      }
    }
    return steps
  }, [filterPushTokens, subscriptionStatus, forceNonOptin, estimate, channel])

  const pushPlatformSteps = React.useMemo<Array<Step>>(() => {
    let pushPlatformSteps: Array<Step> = []
    const total = estimate.total.profileCount

    if (channel === 'push' && pushPlatforms) {
      if (pushPlatforms.includes('ios'))
        pushPlatformSteps.push(buildStep('iOS', estimate.matchingOptin.push.ios, total))
      if (pushPlatforms.includes('android'))
        pushPlatformSteps.push(buildStep('Android', estimate.matchingOptin.push.android, total))
      if (pushPlatforms.includes('webpush')) {
        pushPlatformSteps.push(buildStep('Web', estimate.matchingOptin.push.webpush, total))
      }
    }
    return pushPlatformSteps
  }, [estimate, channel, pushPlatforms])

  return { steps, pushPlatformSteps }
}
