// @flow

import { capitalize } from 'com.batch.common/utils'

import { type AnalyticsChannel } from 'com.batch/profilebase/infra/types/channel-type'
import { PUSH_PLATFORM_FILTER } from 'com.batch/shared/constants/push-platform-filter.constants'
import { type PushPlatformFilterType } from 'com.batch/shared/infra/types/push-platform-filter.type'

export const formatChannelName = (
  channel: AnalyticsChannel,
  platformFilter?: ?PushPlatformFilterType,
  cap?: boolean
): string => {
  if (channel === 'push')
    return platformFilter ? platformFilter.title : PUSH_PLATFORM_FILTER[0].title
  if (channel === 'sms') return 'SMS'
  return cap ? capitalize(channel) : channel
}
