// @flow

import * as Immutable from 'immutable'
import { type List, type RecordFactory, type RecordOf } from 'immutable'

import { type availableIcons } from 'components/common/svg-icon'

// ====================== TOASTER RECORD
type ToasterProps = {
  id: number,
  kind: string,
  icon: availableIcons,
  s: 'normal' | 'small',
  title: string,
  message: string,
  links: Array<{ name: string, href: string, ... }>,
  error: string | null,
}
export const ToasterFactory: RecordFactory<ToasterProps> = Immutable.Record(
  ({
    id: 0,
    kind: '',
    icon: 'success',
    s: 'small',
    title: '',
    message: '',
    links: [],
    error: null,
  }: ToasterProps)
)

export type ToasterRecord = RecordOf<ToasterProps>

export type ToasterStateRecord = List<ToasterRecord>
