// @flow

import * as React from 'react'
import styled, { keyframes, type StyledComponent, type KeyFrames } from 'styled-components'

export type FliperProps = {
  children: React.Node,
  className?: string,
  style?: Object,
  ...
}

export type FliperState = {
  current?: React.Node,
  previous?: React.Node,
  iteration: number,
  ...
}

export class Fliper extends React.Component<FliperProps, FliperState> {
  constructor(props: FliperProps) {
    super(props)
    this.state = { current: null, previous: null, iteration: 0 }
  }
  static getDerivedStateFromProps(
    props: FliperProps,
    state: FliperState
  ): { current: React.Node, previous: ?React.Node, iteration: number, ... } {
    return {
      current: props.children,
      previous: state.current,
      iteration:
        state.current && props.children !== state.current ? state.iteration + 1 : state.iteration,
    }
  }

  render(): React.Node {
    const { className, style } = this.props
    const { current, previous, iteration } = this.state

    return (
      <FliperContainer className={className} style={style}>
        {previous && current !== previous && (
          <FliperPrevious key={iteration + 1}>{previous}</FliperPrevious>
        )}
        <FliperCurrent key={iteration}>{current}</FliperCurrent>
      </FliperContainer>
    )
  }
}

export const FLIPER_DURATION = 0.3

export const FliperAnimation = (offset: number = 0): KeyFrames => keyframes`
  0%{ transform: translate3d(0, ${offset}%, 0); }
  100%{ transform: translate3d(0, ${offset - 100}%, 0); }
`

export const FliperValue: StyledComponent<*, *, HTMLSpanElement> = styled.span`
  display: inline-block;
  animation-duration: ${FLIPER_DURATION}s;
  animation-timing-function: ease;
  animation-fill-mode: both;
`

export const FliperCurrent: StyledComponent<*, *, typeof FliperValue> = styled(FliperValue)`
  animation-name: ${(p: { init?: boolean, ... }) => (p.init ? 'none' : FliperAnimation(100))};
  animation-name: none;
`

export const FliperPrevious: StyledComponent<*, *, typeof FliperValue> = styled(FliperValue)`
  position: absolute;
  top: 0;
  left: 0;
  animation-name: ${FliperAnimation()};

  & + ${FliperCurrent} {
    animation-name: ${FliperAnimation(100)};
  }
`

export const FliperContainer: StyledComponent<*, *, HTMLSpanElement> = styled.span`
  display: inline-block;
  position: relative;
  overflow: hidden;
`

export default Fliper
