// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TemplateWizard } from 'components/template/template-wizard'

import { updateEmailContent } from './usecases/update-email-content'

import { type EmailContentRecord } from '../message/models/message.records'
import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selector'
import { personalisationAttrSelector } from 'com.batch.redux/attribute.selector'
import { triggerEventSelector } from 'com.batch.redux/campaign.selector'

import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'

type MessageTemplateWizardProps = {
  messageId: string,
  lang: string,
  content: EmailContentRecord,
}
export const MessageTemplateWizard = ({
  messageId,
  lang,
  content,
}: MessageTemplateWizardProps): React.Node => {
  const dispatch = useDispatch()
  const attributes = useSelector(personalisationAttrSelector)
  const contextEventId = useSelector(triggerEventSelector)
  const editorConfig = useSelector(inlineEditorConfigSelector)

  const updateTemplate = React.useCallback(
    (toAppend: string) => {
      const { selection } = editorConfig
      const field = editorConfig.field === 'replyTo' ? 'replyTo' : 'subject'
      const message = content?.get(field, '') ?? ''
      const computedMessage =
        message.substring(0, selection[0]) + toAppend + message.substring(selection[1])
      dispatch(
        updateEmailContent({
          messageId,
          lang,
          field,
          value: computedMessage,
          isInstant: true,
        })
      )
    },
    [dispatch, messageId, lang, content, editorConfig]
  )

  const closeTemplateWizard = React.useCallback(() => {
    dispatch(setInlineEditor(editorConfig.set('personalizationModalIsOpen', false)))
  }, [dispatch, editorConfig])

  return (
    <TemplateWizard
      appendText={updateTemplate}
      messageId={messageId}
      attributes={attributes}
      contextEventId={contextEventId}
      opened={editorConfig.personalizationModalIsOpen}
      close={closeTemplateWizard}
    />
  )
}
