// @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button'
import { Input, InputWrapper } from 'components/form'
import { colors } from 'components/styled/tokens'

import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'

import { getMessageId } from 'com.batch/message/models/message.helper'
import { type PushContentRecord } from 'com.batch/message/models/message.records'
import { PushActionPicker } from 'com.batch/push/ui/push-action-picker/push-action-picker'
import { updatePushMessageRecord } from 'com.batch/push/usecases/update-push-content'

type PushAction = 'nothing' | 'deeplink' | 'landing'
const contentStores = {
  ios: 'iosContent',
  android: 'androidContent',
  webpush: 'webContent',
  all: 'content',
}
type Props = {
  content: PushContentRecord,
  stepMessageNodeId: ?string,
  lang: string,
}
export const PushDeeplinkField = ({ content, stepMessageNodeId, lang }: Props): React.Node => {
  const dispatch = useDispatch()
  const [pushAction, setPushAction] = React.useState<PushAction>(
    content.iosContent.deeplink || content.androidContent.deeplink || content.content.deeplink
      ? 'deeplink'
      : 'nothing'
  )
  const getConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(() => {
    return getConfig({ stepMessageNodeId })
  }, [getConfig, stepMessageNodeId])
  const messageId = React.useMemo(() => getMessageId(config), [config])

  const [splitPerPlatform, setSplitPerPlatform] = React.useState(
    config.platforms.some(p => !!content.get(contentStores[p]).deeplink)
  )

  const handleOnDeeplinkChange = React.useCallback(
    (platform: $Keys<typeof contentStores>) => evt => {
      dispatch(
        updatePushMessageRecord({
          messageId,
          lang,
          content: content.setIn([contentStores[platform], 'deeplink'], evt.target.value),
        })
      )
    },
    [content, dispatch, lang, messageId]
  )

  const cleanDeeplinks = React.useCallback(
    (splitted: boolean) => {
      dispatch(
        updatePushMessageRecord({
          messageId,
          lang,
          content: splitted
            ? content
                .setIn(['iosContent', 'deeplink'], '')
                .setIn(['androidContent', 'deeplink'], '')
                .setIn(['webContent', 'deeplink'], '')
            : content.setIn(['content', 'deeplink'], ''),
        })
      )
    },
    [content, dispatch, lang, messageId]
  )

  const onPushActionChange = React.useCallback(
    action => {
      // Si on passe de deeplink à rien, on supprime les deeplinks
      if (action?.value === 'nothing') {
        cleanDeeplinks(splitPerPlatform)
      }
      setPushAction(action ? action.value : 'nothing')
    },
    [cleanDeeplinks, splitPerPlatform]
  )

  // Quand on passe de split <=> no split, on supprime les deeplinks par plateforme ou le deeplink global
  const handleOnSplitPerPlatformChange = React.useCallback(() => {
    setSplitPerPlatform(s => !s)
    cleanDeeplinks(splitPerPlatform)
  }, [splitPerPlatform, cleanDeeplinks])

  // Si on deselect jusqu'à n'avoir qu'1 plateforme => disable split + clean
  React.useEffect(() => {
    if (config.platforms.size === 1 && splitPerPlatform) {
      setSplitPerPlatform(false)
      cleanDeeplinks(true)
    }
  }, [config.platforms, splitPerPlatform, cleanDeeplinks])

  const actions = React.useMemo(() => {
    const nothing = {
      label: 'Redirect to app',
      description: 'Head to the app home screen',
      value: 'nothing',
    }
    const deeplink = {
      label: 'Open deeplink',
      description: 'Open a specific link/screen in the app',
      value: 'deeplink',
    }

    if (config.platforms.size === 1 && config.platforms.has('webpush')) {
      nothing.label = 'Redirect to site'
      nothing.description = 'Head to the website home'
      deeplink.description = 'Open a specific link'
    } else if (config.platforms.has('webpush')) {
      nothing.label = 'Redirect to app/site'
      nothing.description = 'Head to the app home screen/website home'
    }

    return new Immutable.List().push(nothing, deeplink)
  }, [config.platforms])

  return (
    <InputWrapper>
      <PushActionPicker
        value={actions.find(o => o.value === pushAction)}
        onChange={onPushActionChange}
        options={actions}
      >
        {pushAction === 'deeplink' &&
          (splitPerPlatform ? (
            <div
              style={{
                flexGrow: 1,
                paddingTop: 26,
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              {config.platforms.includes('ios') && (
                <Input
                  value={content.iosContent.deeplink}
                  onChange={handleOnDeeplinkChange('ios')}
                  placeholder="app://deeplink-destination"
                  style={{ width: '100%' }}
                  prefix={{ value: 'ios', color: colors.textLight }}
                  autoFocus
                  arial-label="iOS deeplink"
                />
              )}

              {config.platforms.includes('android') && (
                <Input
                  value={content.androidContent.deeplink}
                  onChange={handleOnDeeplinkChange('android')}
                  placeholder="app://deeplink-destination"
                  style={{ width: '100%' }}
                  prefix={{ value: 'android' }}
                  arial-label="Android deeplink"
                />
              )}

              {config.platforms.includes('webpush') && (
                <Input
                  value={content.webContent.deeplink}
                  onChange={handleOnDeeplinkChange('webpush')}
                  placeholder="https://deeplink-destination"
                  style={{ width: '100%' }}
                  prefix={{ value: 'webpush', style: { marginTop: -3 }, color: colors.textLight }}
                  arial-label="Web deeplink"
                />
              )}
            </div>
          ) : (
            <Input
              value={content.content.deeplink}
              onChange={handleOnDeeplinkChange('all')}
              placeholder="app://deeplink-destination"
              style={{ width: '100%', marginTop: 26 }}
              autoFocus
              aria-label="Common deeplink"
            />
          ))}

        <div style={{ position: 'absolute', right: 0, top: 0 }}>
          {pushAction === 'deeplink' && config.platforms.size > 1 && (
            <Switch isActive={splitPerPlatform} onChange={handleOnSplitPerPlatformChange}>
              <span style={{ fontWeight: 400, color: colors.textLight }}>Split by platform</span>
            </Switch>
          )}
        </div>
      </PushActionPicker>
    </InputWrapper>
  )
}
