// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Box } from 'components/common/box'

export const StatsContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  margin-bottom: 48px;

  @media screen and (min-width: 1148px) {
    align-items: center;
  }
`

export type StatsBlocksRowProps = { marginBottom: boolean | number, marginTop: boolean | number }
export const StatsBlocksRow: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(p: StatsBlocksRowProps) =>
    p.marginBottom ? (typeof p.marginBottom === 'number' ? `${p.marginBottom}px` : '32px') : '0'};
  margin-top: ${(p: StatsBlocksRowProps) =>
    p.marginTop ? (typeof p.marginTop === 'number' ? `${p.marginTop}px` : '32px') : '0'};
  gap: 32px;
  width: 1148px;

  ${Box} {
    margin-bottom: 0;
  }
`
