// @flow

import * as React from 'react'

import { TableRow, TableCell } from 'components/common/table'

import { kformat } from 'com.batch.common/utils'

import { BounceProviderCell } from './bounce-per-mailbox-provider.styles'

import { formatRate } from '../format-rate'

import { type EmailProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-provider.record'

type BouncePerMailboxProviderRowProps = {
  provider: EmailProviderRecord,
}

export const BouncePerMailboxProviderRow = ({
  provider,
}: BouncePerMailboxProviderRowProps): React.Node => (
  <TableRow>
    <TableCell style={{ overflowX: 'hidden' }}>
      <BounceProviderCell>{provider.name}</BounceProviderCell>
    </TableCell>
    <TableCell align="right" sortable>
      {kformat(provider.stats.sent)}
    </TableCell>
    <TableCell align="right" sortable>
      {formatRate(provider.stats.bounce.rate)}
    </TableCell>
    <TableCell align="right">{kformat(provider.stats.bounce.hardBounce)}</TableCell>
    <TableCell align="right">{kformat(provider.stats.bounce.softBounce)}</TableCell>
    <TableCell align="right">{kformat(provider.stats.bounce.blockBounce)}</TableCell>
  </TableRow>
)
