// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SelectMultiLine } from 'components/form/fields/select/select-multi-line'
import { MAX_ITEMS_IN_ARRAY } from 'components/query/query.const'

import { OptionAudience } from './audience-option'

import {
  ProfileAudienceFactory,
  type ProfileAudienceRecord,
} from '../../model/audience-profile.records'
import { audiencesMapSelector } from 'com.batch/audience/store/audience-profile.selector'

import { fetchAudiencesList } from 'com.batch/audience/usecases/fetch-audience-list'
import { fetchAudiencesByName } from 'com.batch/audience/usecases/fetch-audiences-by-name'
import { setDeletedAudiences } from 'com.batch/audience/usecases/set-deleted-audience'

type AudiencePickerProps = {
  value: List<string>,
  isDisabled: ?boolean,
  onChange: (List<string>) => void,
}

const optionToStringDisplayName = (opt: ?ProfileAudienceRecord) => opt?.displayName ?? ''

export const AudiencePicker = ({
  onChange,
  value,
  isDisabled,
}: AudiencePickerProps): React.Node => {
  const audiences = useSelector(audiencesMapSelector)
  const [loaded, setLoaded] = React.useState(false)
  const [firstRun, setFirstRun] = React.useState(true)
  const dispatch = useDispatch()
  const selectedAudiences = React.useMemo(() => {
    return value
      .map(audienceName => audiences.get(audienceName, ProfileAudienceFactory({ name: '' })))
      .filter(a => a.name !== '')
  }, [audiences, value])

  React.useEffect(() => {
    /*
      we need records to display our select, so we fetch missing audiences
      (records not already in redux)
      if needed, and set a loaded flag when we are good
      firstRun is a hack because recursion, should not be needed.
    */

    const missingAudiences = value.filter(
      audienceName => !selectedAudiences.find(a => a.name === audienceName)
    )

    if (firstRun && missingAudiences.size > 0) {
      dispatch(fetchAudiencesByName({ names: missingAudiences.toArray() }))
    } else {
      if (missingAudiences.size > 0) {
        dispatch(setDeletedAudiences(missingAudiences.toArray()))
      }
      setLoaded(true)
    }

    setFirstRun(false)
  }, [value, dispatch, onChange, firstRun, selectedAudiences])

  const handleChange = React.useCallback(
    audiencesValue => {
      onChange(audiencesValue.map(a => a.name))
    },
    [onChange]
  )

  const loadOptions = React.useCallback(
    async (query: string) => {
      try {
        const resp = await dispatch(
          fetchAudiencesList({
            page: 1,
            sortBy: 'name',
            sortDirection: 'asc',
            search: query ? query : '',
            trashCache: false,
          })
        )
        return resp.entities.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
      } catch (err) {
        console.log(err)
        return new Immutable.List()
      }
    },
    [dispatch]
  )

  return loaded ? (
    <SelectMultiLine
      maxItems={MAX_ITEMS_IN_ARRAY}
      isDisabled={Boolean(isDisabled)}
      itemName="audience"
      optionFormatter={OptionAudience}
      loadOptions={loadOptions}
      value={selectedAudiences}
      optionToString={optionToStringDisplayName}
      onChange={handleChange}
      ariaLabel="Audience selection"
    />
  ) : (
    <div />
  )
}
