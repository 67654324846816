// @flow

import * as React from 'react'

import Highlight from 'components/common/highlight'

import { type SdkRecord } from 'com.batch.redux/_records'

type SetupUnityProps = {
  apiKey: string,
  devApiKey: string,
  senderId: ?string,
  bundleId: string,
  platform: Platforms,
  sdk: SdkRecord,
  hasDevApiKeyFeature: boolean,
  ...
}
const SetupUnity = ({
  apiKey,
  devApiKey,
  senderId,
  bundleId,
  platform,
  sdk,
  hasDevApiKeyFeature,
}: SetupUnityProps): React.Node => {
  return (
    <div>
      <p>
        Batch Unity is compatible with <strong>Unity 5.2+</strong> and with both{' '}
        <strong>iOS 6+</strong> <em>(with Xcode 6+)</em> and <strong>Android 2.3+</strong>.
      </p>
      <p>
        Other platforms supported by Unity{' '}
        <em>(such as the Web Player or Standalone executables)</em> are not supported by Batch. This
        also applies to testing your game in the Unity Editor itself even if you are building for
        Android or iOS.
      </p>
      <h3>Intallation</h3>
      <p>
        The only thing you need to do is to{' '}
        <a href={`https://download.batch.com/sdk/unity/BatchSDK-unity-${sdk.version || ''}.zip`}>
          download Batch Unity package ({sdk.version})
        </a>{' '}
        and import it using the Assets menu.
      </p>
      {platform === 'android' && (
        <div>
          <h3>Configure your app manifest</h3>
          <p>
            If you haven't done so recently, please update your Android SDK to the latest version of
            the build-tools, platform-tools and SDK platform. Make sure that your Unity Activity
            class (specified in the <code>Assets/Plugins/Android/AndroidManifest.xml</code>) is{' '}
            <code>com.batch.android.unity.BatchPlayerActivity</code>.
          </p>
          <Highlight language="xml">{`<?xml version="1.0" encoding="utf-8"?>
<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    package="{{$ctrl.state.app.bundleId}}"
    android:versionCode="1"
    android:versionName="1.0" >

    <uses-sdk
        android:minSdkVersion="8"
        android:targetSdkVersion="20" />

    <uses-permission android:name="android.permission.INTERNET" />

    <permission android:name="${bundleId}.permission.C2D_MESSAGE" android:protectionLevel="signature"/>
    <uses-permission android:name="${bundleId}.permission.C2D_MESSAGE"/>

    <uses-permission android:name="android.permission.WAKE_LOCK" />
    <uses-permission android:name="android.permission.VIBRATE" />
    <uses-permission android:name="com.google.android.c2dm.permission.RECEIVE"/>
          `}</Highlight>
          <p>
            You must now declare the Batch service and receiver. They will handle notifications and
            display them for you, without any additional code.
          </p>
          <p>Here's how to declare it in your application tag:</p>
          <Highlight language="xml">{`<application
... >

<meta-data
    android:name="com.google.android.gms.version"
    android:value="@integer/google_play_services_version" />

<service android:name="com.batch.android.BatchPushService" />
<receiver android:name="com.batch.android.BatchPushReceiver" android:permission="com.google.android.c2dm.permission.SEND">
  <intent-filter>
    <action android:name="com.google.android.c2dm.intent.RECEIVE" />
    <category android:name="${bundleId}" />
  </intent-filter>
</receiver>
      
      </application>
      `}</Highlight>
          <h3>Starting the SDK</h3>
          <Highlight language="js">{`using UnityEngine;
using System;
using System.Collections;
using System.Collections.Generic;
using Batch;

public class MainMenu: MonoBehaviour
{

    public BatchPlugin BatchPlugin;

    void Start()
    {
        BatchPlugin.Push.GCMSenderID = "${senderId || ''}";
        Config config = new Config();
        ${
          hasDevApiKeyFeature
            ? `config.AndroidAPIKey = "${devApiKey}"; // dev key
        //config.AndroidAPIKey = "${apiKey}"; live key
        `
            : `config.AndroidAPIKey = "${apiKey}";`
        }
        BatchPlugin.Push.Setup();
        BatchPlugin.StartPlugin(config);
    }
}
          `}</Highlight>
        </div>
      )}
      {platform == 'ios' && (
        <div>
          <h3>Starting the SDK</h3>
          <Highlight language="cs">
            {`using UnityEngine;
using System;
using System.Collections;
using System.Collections.Generic;
using Batch;

public class MainMenu: MonoBehaviour
{

    public BatchPlugin BatchPlugin;

    void Start()
    {
        Config config = new Config();
        ${
          hasDevApiKeyFeature
            ? `config.IOSAPIKey = "${devApiKey}"; // dev key
        // config.IOSAPIKey = "${apiKey}"; // live key`
            : `config.IOSAPIKey = "${apiKey}";`
        }
        BatchPlugin.Push.Setup();
        BatchPlugin.StartPlugin(config);
        // note that you should probably move this call to a more appropriate place
        // in the lifecycle of your app
        BatchPlugin.Push.RegisterForRemoteNotifications();
    }
}
          `}
          </Highlight>
        </div>
      )}
    </div>
  )
}

export default SetupUnity
