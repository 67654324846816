// @flow

import { schemes } from 'components/styled/tokens'

import {
  type AnalyticsChannel,
  type channelThemeType,
} from 'com.batch/profilebase/infra/types/channel-type'

export const CHANNEL_THEMES: { [key: AnalyticsChannel]: channelThemeType } = {
  email: {
    icon: 'mail-rounded',
    title: 'Email',
    labelVariant: 'tech',
    colors: {
      text: schemes.yellow['90'],
      badge: schemes.yellow['00'],
      plotChart: schemes.yellow['40'],
      donutChart: {
        default: schemes.yellow['40'],
        hover: schemes.yellow['50'],
        active: schemes.yellow['50'],
        middle: schemes.yellow['60'],
      },
    },
  },
  sms: {
    icon: 'sms',
    title: 'SMS',
    labelVariant: 'action',
    colors: {
      text: schemes.blue['90'],
      badge: schemes.blue['00'],
      plotChart: schemes.blue['50'],
      donutChart: {
        default: schemes.blue['50'],
        hover: schemes.blue['60'],
        active: schemes.blue['60'],
        middle: schemes.blue['90'],
      },
    },
  },
  push: {
    icon: 'push',
    title: 'Push',
    labelVariant: 'feature',
    colors: {
      text: schemes.purple['90'],
      badge: schemes.purple['00'],
      plotChart: schemes.purple['50'],
      donutChart: {
        default: schemes.purple['50'],
        hover: schemes.purple['60'],
        active: schemes.purple['60'],
        middle: schemes.purple['90'],
      },
    },
  },
}
