// @flow

import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  HeaderBoxActions,
  BoxBody,
  BoxFooter,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Code } from 'components/common/code'
import { Grid } from 'components/common/grid'
import Loader from 'components/common/loader-legacy'
import { Icon } from 'components/common/svg-icon'
import { UploadFiles } from 'components/common/upload-files'
import { InputWrapper, Input, TabButton, TabButtonItem } from 'components/form'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { slugify } from 'com.batch.common/utils'

import { UploadAudiencesContainer, PreviewAudiences } from './audience.styles'

import { type AppRecord } from 'com.batch.redux/_records'
import { createCustomAudience } from 'com.batch.redux/audience'

type AudienceUploadProps = {
  app: AppRecord,
  creating: boolean,
  createCustomAudience: typeof createCustomAudience,
  close: () => void,
  ...
}
type AllowedAudienceType = 'custom_ids' | 'advertising_ids' | 'install_ids'

export const AudienceUpload = ({
  app,
  createCustomAudience,
  creating,
  close,
}: AudienceUploadProps): React.Node => {
  // state
  const [progress, setProgress] = React.useState<number | null>(null)
  const [name, setName] = React.useState<string>('')
  const [description, setDescription] = React.useState<string>('')
  const [preview, setPreview] = React.useState<Array<string>>([])
  const [type, setType] = React.useState<AllowedAudienceType>('custom_ids')
  const [file, setFile] = React.useState<?File>(null)
  const [error, setError] = React.useState<Map<string, string>>(Immutable.Map([]))

  const onTypeChange = React.useCallback((t: AllowedAudienceType) => () => setType(t), [])

  // parse current file, set preview error & file on local state
  const parseFile = React.useCallback((file: File) => {
    if (file) {
      const reader = new FileReader()

      reader.onloadend = evt => {
        // $FlowFixMe libdef ?
        if (evt.target.readyState === 2) {
          // $FlowFixMe libdef ?
          setPreview(evt.target.result.split('\n').slice(0, 10))
        }
      }
      reader.readAsBinaryString(file.slice(0, 3000))
    }
  }, [])

  const previewContainer = (
    <PreviewAudiences>
      {preview
        .filter(p => p.trim().length > 0)
        .map((p, i) => (
          <Grid key={p + i} template="35px minmax(100px, 1fr)" gap={0}>
            <div>{i + 1}</div>
            <div style={{ paddingRight: 20 }}>
              <Code disabledCopy size="small">
                {p.includes(',') ? p.split(',')[0] : p}
              </Code>
            </div>
          </Grid>
        ))}
    </PreviewAudiences>
  )

  // save a file
  const save = React.useCallback(
    evt => {
      evt.preventDefault()
      const err = []

      if (description.length === 0) err.push(['description', 'You must enter a display name'])

      if (file === null) err.push(['files', 'You must upload an audience file'])

      if (name.length !== 0) {
        if (!name.match(/^[0-9A-Z_-]*$/)) {
          err.push(['name', 'This field can only contain alphanumeric characters'])
        }
      }

      let n =
        name.length === 0
          ? `${slugify(file ? file.name : '')}_${dayjs().utc().format('DDMMYYYY-HHmm')}`
          : name

      if (err.length === 0 && n && description && file) {
        setError(Immutable.Map())
        createCustomAudience(app.id, n, type, description, file, setProgress)
      } else {
        setError(Immutable.Map(err))
      }
    },
    [app.id, description, name, type, file, createCustomAudience]
  )

  const onDescriptionChange = React.useCallback(evt => setDescription(evt.target.value), [])
  const onNameChange = React.useCallback(
    evt => setName(evt.target.value.toUpperCase().replace(/[^0-9a-zA-Z-_]/, '')),
    []
  )
  const onParseFile = React.useCallback(
    file => {
      setFile(file)
      parseFile(file)
    },
    [parseFile]
  )
  const onRemoveFile = React.useCallback(() => {
    setPreview([])
    setFile(null)
  }, [])
  return (
    <Loader loading={false} overlay>
      <ThemeProvider theme={{ isEmpty: false }}>
        <Box as="form" onSubmit={save} style={{ marginBottom: 0 }}>
          <BoxHeader>
            <HeaderBoxTitle title="Upload an audience" />
            <HeaderBoxActions>
              <Button kind="inline" onClick={close} type="button">
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>

          <BoxBody style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
            <Grid template="1fr 390px" gap={0} alignItems="stretch">
              <UploadAudiencesContainer side="left">
                <InputWrapper
                  label="Display name"
                  hintMinSize={300}
                  hint={
                    <div style={{ textAlign: 'left', fontWeight: 300 }}>
                      <div style={{ fontWeight: 500 }}>Display name of custom audience</div>
                      Define a clean and optional label for dashboard operational usage only that
                      won’t impact SDK tagging, API calls, etc
                    </div>
                  }
                  hintPlacement="right"
                  feedback={error.get('description')}
                  htmlFor="description"
                >
                  <Input
                    id="description"
                    value={description}
                    placeholder="My audience"
                    onChange={onDescriptionChange}
                    disabled={creating}
                    invalid={error.has('description')}
                  />
                </InputWrapper>

                <InputWrapper
                  label="Name"
                  hintMinSize={300}
                  hint={
                    <div style={{ textAlign: 'left', fontWeight: 300 }}>
                      The audience name will be used to target this audience via the Batch SDKs or
                      APIs. <br />
                      It must be alphanumeric with no special characters allowed except dash (-) and
                      underscore (_).
                    </div>
                  }
                  hintPlacement="right"
                  feedback={error.get('name')}
                  htmlFor="name"
                >
                  <Input
                    id="name"
                    value={name}
                    placeholder="MY-AUDIENCE_010120 (optional)"
                    onChange={onNameChange}
                    disabled={creating}
                    invalid={error.has('name')}
                  />
                </InputWrapper>

                <InputWrapper label="Audience type">
                  <TabButton grow disabled={creating}>
                    <TabButtonItem
                      isActive={type === 'custom_ids'}
                      onClick={onTypeChange('custom_ids')}
                      isDisabled={creating}
                    >
                      Custom&nbsp;user&nbsp;ID
                    </TabButtonItem>
                    <TabButtonItem
                      isActive={type === 'advertising_ids'}
                      onClick={onTypeChange('advertising_ids')}
                      isDisabled={creating}
                    >
                      Advertising&nbsp;ID
                      {app.platform === 'ios' && <React.Fragment>&nbsp;(IDFA)</React.Fragment>}
                      {app.platform === 'android' && <React.Fragment>&nbsp;(GAID)</React.Fragment>}
                    </TabButtonItem>
                    <TabButtonItem
                      isActive={type === 'install_ids'}
                      onClick={onTypeChange('install_ids')}
                      isDisabled={creating}
                    >
                      Installation&nbsp;ID
                    </TabButtonItem>
                  </TabButton>
                </InputWrapper>
              </UploadAudiencesContainer>

              <UploadAudiencesContainer side="right" hasFile={!!file}>
                <UploadFiles
                  progress={progress}
                  isSaving={creating}
                  maxSizeFiles={150 * 1024 * 1024}
                  parseFile={onParseFile}
                  removeFile={onRemoveFile}
                  extensionsForMimeType={{ 'text/plain': ['.txt', '.csv'], 'text/csv': ['.csv'] }}
                  preview={previewContainer}
                  previewPosition="in"
                  noFileErrorMsg={error.get('files')}
                />
              </UploadAudiencesContainer>
            </Grid>
          </BoxBody>

          <BoxFooter isEditable>
            <Button
              kind="inline"
              intent="neutral"
              onClick={close}
              disabled={creating}
              type="button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              kind="primary"
              intent="action"
              disabled={!description || file === null}
              isLoading={creating}
            >
              Add audience
            </Button>
          </BoxFooter>
        </Box>
      </ThemeProvider>
    </Loader>
  )
}
