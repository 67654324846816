// @flow
import * as React from 'react'

import { BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { kformat } from 'com.batch.common/utils'

import { ImportedTokensBox, ImportedTokensIllustration } from './imported-tokens-block.styles'

import { Metric } from 'com.batch/shared/ui/component/metric/metric'

type Props = {
  value: number,
}

export const ImportedTokensBlock = ({ value = 0 }: Props): React.Node => (
  <ImportedTokensBox>
    <BoxHeader>
      <BoxTitle>
        <Skeleton w={125} h={16}>
          <Icon icon="import" /> Imported tokens
        </Skeleton>
      </BoxTitle>
    </BoxHeader>
    <BoxBody>
      <Metric
        value={kformat(value)}
        subtitle={`push token${
          value > 0 ? 's' : ''
        } have been imported from your previous provider but not yet detected by our systems`}
        align="row"
      />
    </BoxBody>
    <ImportedTokensIllustration />
  </ImportedTokensBox>
)
