// @flow

import styled, { type StyledComponent } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

type SvgLabelProps = { active?: boolean, disabled?: boolean, ... }
export const SvgLabel: StyledComponent<SvgLabelProps, { ... }, HTMLLabelElement> = styled.label`
  border: 1px solid ${colors.stroke};
  background: ${(props: SvgLabelProps) =>
    props.active ? colors.fillAction : colors.fillBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  user-select: none;
  margin: 0;
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
    border-left: 0;
  }
  svg {
    pointer-events: none;
  }
  box-shadow: ${(props: SvgLabelProps) =>
    props.active ? `inset 0 -2px 0 0 ${schemes.blue['30']}` : 'none'};
  border-bottom-color: ${(props: SvgLabelProps) =>
    props.active ? schemes.blue['30'] : colors.stroke};
  input {
    display: none;
  }
  span {
    display: block;
    padding: 4px 10px 16px 10px;
    color: ${(props: SvgLabelProps) => (props.active ? colors.text : colors.textLight)};
    font-size: 14px;
    line-height: 17px;
  }
`

export const IconAndTextContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 5px 10px 5px;
  div {
    padding: 10px;
  }
`
