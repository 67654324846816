// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Tag } from 'components/common/tag'
import { InputContainer } from 'components/form/styles'
import { colors, schemes } from 'components/styled/tokens'

export const SubHeader: StyledComponent<{ ... }, *, HTMLHeadingElement> = styled.h3`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  color: ${colors.textLight};
  padding: 10px 0 2px 0px;

  p {
    padding-top: 2px;
  }
`
export const HeaderContainer: StyledComponent<{ ... }, *, HTMLElement> = styled.header`
  background: ${colors.fill};
  padding: 16px 0;
  border-bottom: 1px solid ${colors.stroke};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.02);
  .styled-nameInput {
    height: 40px;
    padding-left: 8px;
    padding-bottom: 2px;
    font-size: 26px;
    font-weight: 300;
    letter-spacing: -0.02em;
  }
`

export const ButtonContainer: StyledComponent<{ ... }, *, HTMLElement> = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`

export const HeaderContent: StyledComponent<{ ... }, *, HTMLElement> = styled.div`
  padding: 0 20px;
`
export const HeaderFlex: StyledComponent<{ ... }, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  ${InputContainer} {
    flex-grow: 1;
  }
`
export const HeaderTitleContainer: StyledComponent<{ ... }, *, HTMLElement> = styled.div`
  display: flex;
  gap: 20px;
`

export const HeaderActions: StyledComponent<{ ... }, *, HTMLElement> = styled.div`
  display: flex;
`

export const LabelList: StyledComponent<{ ... }, *, HTMLElement> = styled.div`
  white-space: nowrap;
`

export const LabelTag: StyledComponent<{ ... }, { ... }, *> = styled(Tag)`
  height: 28px;
  margin: auto 0;
  padding: 4px 8px;
  background-color: ${schemes.purple['00']};
  color: ${schemes.purple[60]};

  :hover {
    background-color: ${schemes.purple[10]};
  }
`
