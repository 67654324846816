// @flow

import dayjs from 'dayjs'
import Immutable from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { DateRangePicker } from 'components/form'

import { type DateRange } from 'com.batch.common/dayjs.custom'

import { orchestrationAnalyticsDateRangeSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { OrchestrationAnalyticsDateRangeFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { updateAnalyticsDateRange } from 'com.batch/orchestration-analytics/usecases/analytics-filters'

const ranges = [
  {
    name: 'Last 7 days',
    from: dayjs().subtract(6, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  {
    name: 'Last 30 days',
    from: dayjs().subtract(29, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  {
    name: 'Last 90 days',
    from: dayjs().subtract(89, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
  {
    name: 'Last 365 days',
    from: dayjs().subtract(354, 'day').startOf('day'),
    to: dayjs().endOf('day'),
  },
]
const rangeList = new Immutable.List().push(...ranges)

const areRangesEqual = (first: ?DateRange, sec: ?DateRange) =>
  first?.from.format('YYYYMMDD') === sec?.from.format('YYYYMMDD') &&
  first?.to.format('YYYYMMDD') === sec?.to.format('YYYYMMDD')

export const DateRangeFilter = (): React.Node => {
  const dispatch = useDispatch()
  const { token }: { token: string } = useParams()
  const getAnalyticsDateRange = useSelector(orchestrationAnalyticsDateRangeSelector)
  const dateRange = React.useMemo(
    () => getAnalyticsDateRange(token),
    [getAnalyticsDateRange, token]
  )

  const setRange = React.useCallback(
    range => {
      if (!areRangesEqual(dateRange, range)) {
        const newRange = range
          ? OrchestrationAnalyticsDateRangeFactory({
              from: range.from,
              to: range.to,
            })
          : null
        dispatch(updateAnalyticsDateRange({ token, dateRange: newRange }))
      }
    },
    [dispatch, token, dateRange]
  )

  const isDatePassed = React.useCallback(
    (day: Date) => dayjs().hour(23).minute(59).add(1, 'hour').isBefore(day),
    []
  )

  return (
    <DateRangePicker
      data-track="analytics-filter-date"
      range={dateRange}
      setRange={setRange}
      onClose={setRange}
      shortcuts={rangeList}
      icon="calendar"
      disabledDays={isDatePassed}
      placeholder="Since launch"
      changePlaceholderOnFocus
      style={{ width: '100%' }}
      singleDayRange
    />
  )
}
