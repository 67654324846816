// @flow

import * as React from 'react'

import { Separator } from 'components/app/custom-data/custom-data.styles'
import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  FooterBoxActions,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Radio } from 'components/form'
import { LinkDoc } from 'components/styled/text'

import { formatPrice } from 'com.batch.common/utils'

import { BillingForm } from './billing-form'
import { Card } from './card'
import { Checkout } from './checkout'
import {
  PlanButton,
  PlanList,
  PlanButtonDesc,
  PlanButtonPrice,
  PlanButtonLabel,
} from './plan.styles'
import { Steps } from './steps'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { plans } from 'com.batch.redux/billing.api'
import { type PlanRecord } from 'com.batch.redux/billing.records'

const pickablePlans = plans.filter(p => !p.legacy && p.code !== 'free2').toIndexedSeq()

type WizardProps = {
  company: CompanyRecord,
  opened: boolean,
  close: () => void,
  ...
}

type currencyKind = 'eur' | 'usd'
type cycleKind = 'monthly' | 'yearly'
type stepKind = 'plan' | 'form' | 'card' | 'checkout'

const stepTitle = {
  plan: 'Choose your plan',
  form: 'Enter your billing details',
  card: 'Enter your credit card details',
  checkout: 'Confirm your purchase',
}

export const Wizard = ({ company, opened, close }: WizardProps): React.Node => {
  const billing = company.billing

  const [step, setStep] = React.useState<stepKind>('plan')
  const [selectedPlan, setSelectedPlan] = React.useState<?PlanRecord>(null)
  const [currency, setCurrency] = React.useState<currencyKind>(company.billing.currency)
  const [cycle, updateCycle] = React.useState<cycleKind>(company.billing.cycle || 'monthly')
  const isDevYearlyGoingToStartupMonthy =
    cycle === 'monthly' &&
    company.billing.plan.code === 'pro2' &&
    company.billing.cycle === 'yearly' &&
    selectedPlan &&
    selectedPlan.code === 'startup'

  const targetPlanIsStripable =
    !isDevYearlyGoingToStartupMonthy &&
    (!selectedPlan || selectedPlan.code === 'startup' || selectedPlan.code === 'pro2')
  const createOnCycleChange = React.useCallback((cycle: cycleKind) => () => updateCycle(cycle), [])
  const createOnStepChange = React.useCallback((step: stepKind) => () => setStep(step), [])
  const createOnCurrencyChange = React.useCallback(
    (currency: currencyKind) => () => setCurrency(currency),
    []
  )
  const createOnPlanChange = React.useCallback(
    (plan: PlanRecord) => () => {
      if (plan.code !== billing.plan.code) setSelectedPlan(plan)
    },
    [billing.plan.code]
  )
  const onNext = React.useCallback(() => {
    if (targetPlanIsStripable) {
      setStep('form')
    } else {
      typeof window.Intercom === 'function'
        ? window.Intercom('showNewMessage')
        : (window.location = 'mailto:support@batch.com')
      close()
    }
  }, [close, targetPlanIsStripable])
  return (
    <Popin opened={opened} close={close} style={{ width: 600 }}>
      <Box noBorder>
        <BoxHeader>
          <HeaderBoxTitle title={stepTitle[step]} />
          <HeaderBoxActions>
            <LinkDoc href="https://batch.com/pricing" intent="action" target="_blank">
              Learn more
            </LinkDoc>
            <Separator style={{ marginLeft: 0 }} />

            <Button kind="inline" onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        {step === 'plan' && (
          <React.Fragment>
            <BoxBody $padding>
              <FlexLine role="radiogroup" aria-label="Cycle & currency">
                <FlexLineItem>
                  <Radio
                    checked={cycle === 'monthly'}
                    label="Monthly"
                    onChange={createOnCycleChange('monthly')}
                  />
                </FlexLineItem>
                <FlexLineItem>
                  <Radio
                    checked={cycle === 'yearly'}
                    label="Yearly"
                    onChange={createOnCycleChange('yearly')}
                  />
                </FlexLineItem>
                <FlexLineItem
                  style={{ borderLeft: '1px solid #E3E3E3', marginLeft: 20, paddingLeft: 20 }}
                >
                  &nbsp;
                </FlexLineItem>
                <FlexLineItem>
                  <Radio
                    disabled={billing.subscriptionStatus !== 'none'}
                    checked={currency === 'usd'}
                    label="USD"
                    onChange={createOnCurrencyChange('usd')}
                  />
                </FlexLineItem>
                <FlexLineItem>
                  <Radio
                    disabled={billing.subscriptionStatus !== 'none'}
                    checked={currency === 'eur'}
                    label="EUR"
                    onChange={createOnCurrencyChange('eur')}
                  />
                </FlexLineItem>
              </FlexLine>
              <PlanList>
                {pickablePlans.map(plan => {
                  const currencyPrice = currency === 'eur' ? plan.eur : plan.usd
                  const price = currencyPrice
                    ? cycle === 'monthly'
                      ? currencyPrice.monthly
                      : currencyPrice.yearly
                    : 0
                  return (
                    <PlanButton
                      key={plan.code}
                      current={plan.code === billing.plan.code}
                      selected={selectedPlan?.code === plan.code}
                      onClick={createOnPlanChange(plan)}
                    >
                      <PlanButtonDesc>
                        <strong>
                          {plan.name}
                          {plan.code === billing.plan.code && (
                            <React.Fragment>
                              {' '}
                              <PlanButtonLabel>current</PlanButtonLabel>
                            </React.Fragment>
                          )}
                        </strong>
                        {plan.descr}
                      </PlanButtonDesc>
                      <PlanButtonPrice>
                        {price === 0 ? (
                          <React.Fragment>
                            <strong>***</strong>contact us
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <strong>{formatPrice(price, currency, true)}</strong>/{' '}
                            {cycle === 'monthly' ? 'month' : 'year'}
                          </React.Fragment>
                        )}
                      </PlanButtonPrice>
                    </PlanButton>
                  )
                })}
              </PlanList>
            </BoxBody>
            <BoxFooter>
              <Steps
                active={0}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: 'auto',
                }}
              />
              <FooterBoxActions>
                <Button kind="primary" intent="action" disabled={!selectedPlan} onClick={onNext}>
                  {targetPlanIsStripable ? 'Continue' : 'Contact us'}
                </Button>
              </FooterBoxActions>
            </BoxFooter>
          </React.Fragment>
        )}
        {step === 'form' && (
          <BillingForm
            company={company}
            prev={createOnStepChange('plan')}
            next={createOnStepChange('card')}
          />
        )}
        {step === 'card' && (
          <Card
            company={company}
            elementId="card-stripe-popin"
            prev={createOnStepChange('form')}
            next={createOnStepChange('checkout')}
          />
        )}
        {step === 'checkout' && selectedPlan && (
          <Checkout
            company={company}
            plan={selectedPlan}
            cycle={cycle}
            currency={currency}
            prev={createOnStepChange('card')}
          />
        )}
      </Box>
    </Popin>
  )
}
