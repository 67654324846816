// @flow

import Immutable, { type OrderedSet, type Set } from 'immutable'

import { ucFirst } from 'com.batch.common/utils'

import { type CompanyRecord, type AppRecord } from 'com.batch.redux/_records'
import {
  type UserRecord,
  type groupOnlyPermissionType,
  CompanyUserPermissionsFactory,
} from 'com.batch.redux/user.records'

export const getPermissions = (user: UserRecord): OrderedSet<groupOnlyPermissionType> =>
  (user.companiesPermissions.first() || { permissions: Immutable.OrderedSet() }).permissions

export const getPermissionsForUserAndCompany = (
  user: UserRecord,
  company: CompanyRecord
): OrderedSet<groupOnlyPermissionType> => {
  const compPerm = user.companiesPermissions.get(company.id, CompanyUserPermissionsFactory())
  return compPerm.permissions.filter(
    p => p.indexOf('group:') === 0 && (company.hasEditorialDashboard || p !== 'group:editorial')
  )
}
export const userCanAdministrate = (user: UserRecord, company: CompanyRecord): boolean => {
  const compPerm = user.companiesPermissions.get(company.id, CompanyUserPermissionsFactory())
  return compPerm.permissions.has('group:administrate')
}

export const isUserAllowedOnApp = ({
  app,
  company,
  user,
}: {
  app: AppRecord,
  company: CompanyRecord,
  user: UserRecord,
  ...
}): boolean => {
  const compPerm = user.companiesPermissions.get(company.id, CompanyUserPermissionsFactory())
  return compPerm.permissions.has('group:administrate') || compPerm.apps.has(app.id)
}
export const getAllowedAppsOnCompanyForUser = (
  company: CompanyRecord,
  user: UserRecord
): Set<number> => {
  return user.companiesPermissions.get(company.id, CompanyUserPermissionsFactory()).apps
}
export const getAllowedAppsCountOnCompanyForUser = ({
  company,
  user,
  appsCount,
}: {
  company: CompanyRecord,
  user: UserRecord,
  appsCount: number,
  ...
}): number => {
  const compPerm = user.companiesPermissions.get(company.id, CompanyUserPermissionsFactory())
  return compPerm.permissions.has('group:administrate') ? appsCount : compPerm.apps.size
}
export const toggleAppForUser = ({
  app,
  company,
  user,
}: {
  app: AppRecord,
  company: CompanyRecord,
  user: UserRecord,
  ...
}): UserRecord => {
  const compPerm = user.companiesPermissions.get(company.id, CompanyUserPermissionsFactory())
  return user.set(
    'companiesPermissions',
    user.companiesPermissions.set(
      company.id,
      compPerm.set(
        'apps',
        compPerm.apps.has(app.id) ? compPerm.apps.remove(app.id) : compPerm.apps.add(app.id)
      )
    )
  )
}

export const permissionNameFormatter = (p: groupOnlyPermissionType): string =>
  ucFirst(p.replace('group:', ''))

// @warning : this is dependant on the roles setup PHP side in service.yaml, both needs to be updated

export const ROLES: Array<{
  id: groupOnlyPermissionType,
  desc: string,
  hint?: string,
  autoGrantedWith: Array<groupOnlyPermissionType>,
  ...
}> = [
  {
    id: 'group:administrate',
    desc: 'Can manage all apps, company settings, ...',
    hint: 'Please, be sure to give this permission to the right user',
    autoGrantedWith: [],
  },
  {
    id: 'group:app',
    desc: 'Can edit apps, manage apps settings, ...',
    autoGrantedWith: ['group:administrate'],
  },
  {
    id: 'group:campaign',
    desc: 'Can create, edit and archive campaigns',
    autoGrantedWith: ['group:administrate'],
  },
  {
    id: 'group:review',
    desc: 'Can view analytics, apps and campaigns (without edition)',
    autoGrantedWith: ['group:administrate', 'group:app', 'group:campaign'],
  },
  {
    id: 'group:privacy',
    desc: 'Can manage GDPR settings',
    autoGrantedWith: ['group:administrate'],
  },
  {
    id: 'group:editorial',
    desc: 'Can access editorial.batch.com',
    autoGrantedWith: [],
  },
]

export const resolvePermissions = (
  permissions: Array<groupOnlyPermissionType>
): Array<groupOnlyPermissionType> => {
  let resolvedPermissions = [...permissions]

  permissions.forEach(permission => {
    const newPermissions = ROLES.filter(p => p.autoGrantedWith.includes(permission)).map(p => p.id)

    resolvedPermissions.push(...newPermissions)
  })

  return resolvedPermissions
}

export const ensureSameOrderForRoles = (
  roles: Array<groupOnlyPermissionType>
): Array<groupOnlyPermissionType> => {
  let ordered: Array<groupOnlyPermissionType> = []
  ROLES.forEach(role => {
    if (roles.indexOf(role.id) !== -1) {
      ordered.push(role.id)
    }
  })
  return ordered
}
