// @flow

import { type Set } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'
import request from 'superagent-interface-promise'

import { useIsCurrentUserAllowedTo, useCurrentCompanyHasOneFeatureAmongst } from 'components/_hooks'
import { Box, BoxHeader, HeaderBoxTitle, BoxBody, BoxFooter } from 'components/common/box'
import { PermissionButton, Switch } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { FlexLine } from 'components/common/flexline'
import { Grid } from 'components/common/grid'
import Hint from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'
import { Input, InputWrapper, TabButton, TabButtonItem } from 'components/form'
import { LinkDocumentation } from 'components/styled/text'

import { config as conf } from 'com.batch.common/config'
import { kformat, numberFormat } from 'com.batch.common/utils'

import { Text, SettingsPushItem } from './settings.styles'

import { type PushConfigRecord, type AppRecord } from 'com.batch.redux/_records'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'
import { type MessageType } from 'com.batch.redux/toaster'

type SettingsCommonProps = {
  config: PushConfigRecord,
  app: AppRecord,
  updateConfig: (config: PushConfigRecord, file: ?File, password: ?string) => Promise<void>,
  show: Set<'priority' | 'ttl' | 'collapseKey'>,
  showToast: MessageType => any,
}

const SettingsCommonRaw = ({ config, updateConfig, show, app, showToast }: SettingsCommonProps) => {
  const [data, setData] = React.useState<PushConfigRecord>(config)
  const [nbPushToken, setNbPushToken] = React.useState<number>(0)
  const [warnRateTooLow, setWarnRateTooLow] = React.useState<boolean>(false)

  const maybeProject = useSelector(optionalCurrentProjectSelector)
  const cepCompanyCanUseLegacyPush = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-trigger',
    'cep-show-legacy-recurring',
    'cep-show-legacy-campaign',
  ])

  const hasLegacyPush = React.useMemo(() => {
    return cepCompanyCanUseLegacyPush || !maybeProject
  }, [cepCompanyCanUseLegacyPush, maybeProject])

  const maxMax: number = conf.pushRate[app.status]

  const canUpdate =
    data.defaultTtl !== config.defaultTtl ||
    data.defaultCollapseKey !== config.defaultCollapseKey ||
    data.maxRate !== config.maxRate ||
    data.defaultPriority !== config.defaultPriority

  var minutesToDeliver: number = 0
  var alertRateLevel: 'success' | 'warning' = 'success'
  var alertRateMessage: string = ''
  // var warnRateTooLow: boolean = false

  // Count the minutesToDeliver time and create the message
  if (data.maxRate) {
    minutesToDeliver = nbPushToken / data.maxRate
    let tokensMessage = ` to send to ${kformat(nbPushToken)} tokens`
    let message = ''

    if (minutesToDeliver < 1) {
      let s = Math.round(minutesToDeliver * 60)
      message = `~ ${s === 0 ? 1 : s} sec ${tokensMessage}`
    } else {
      var h = (minutesToDeliver / 60) | 0
      let m = minutesToDeliver % 60 | 0

      let symbol = h > 12 ? '+ ' : '~ '
      let minutesMessage =
        m > 0 && h < 12 ? `${m} ${h === 0 ? `minute${m > 1 ? 's' : ''}` : ''}` : ''
      let hoursMessage =
        h > 0 ? `${h > 12 ? 12 : h} ${m === 0 || h > 12 ? `hour${h > 1 ? 's' : ''}` : 'h '}` : ''

      message = symbol + hoursMessage + minutesMessage + tokensMessage
    }

    alertRateLevel = minutesToDeliver > 180 ? 'warning' : 'success'
    alertRateMessage = message
  }

  // fetch estimate
  React.useEffect(() => {
    request.post(`/api/app/${app.id}/data/pushtokens`, { regions: [], languages: [] }).then(
      ({ body }) => {
        setNbPushToken(body.results.total_reachable)
      },
      () => {
        setNbPushToken(0)
      }
    )
  }, [app.id])

  const update = React.useCallback(() => {
    // manage invalid value
    if (data.maxRate === null || (!!data.maxRate && data.maxRate <= maxMax && data.maxRate >= 60)) {
      setWarnRateTooLow(false)
      // we don't want to save a rate = maxRate, in this case we are not limited
      updateConfig(
        data.set('maxRate', data.maxRate)
        // data.set('maxRate', data.maxRate === maxMax ? null : data.maxRate)
      ).then(() => {
        showToast({ kind: 'success', message: 'Default push settings saved' })
      })
    } else {
      setWarnRateTooLow(true)
    }
  }, [data, maxMax, showToast, updateConfig])

  const handleSubmit = React.useCallback(() => {
    if (minutesToDeliver >= 360 && !!data.maxRate && data.maxRate <= maxMax && data.maxRate >= 60) {
      let message =
        minutesToDeliver < 720 ? (
          <article>
            <p>
              Please be aware that push sent via the transactional API may not be delivered if the
              time to send exceeds 6 hours.{' '}
            </p>
            <p>It is highly recommended to set a higher rate.</p>
          </article>
        ) : (
          <article>
            <div>Please be aware that:</div>
            <div style={{ marginTop: 15 }}>
              • push sent via the transactional API may not be delivered if the time to send exceeds
              6 hours.
            </div>
            <div style={{ marginTop: 15 }}>
              • push sent via marketing campaigns may not be delivered if the time to send exceeds
              12 hours.
            </div>
            <div style={{ marginTop: 15, fontWeight: 500 }}>
              It is highly recommended to set a higher rate.
            </div>
          </article>
        )

      confirm({
        title: 'Use a low rate push delivery speed?',
        message: message,
        sensitive: true,
        confirm: 'Yes, use it',
      }).then(
        () => update(),
        () => {}
      )
    } else {
      update()
    }
  }, [data.maxRate, maxMax, minutesToDeliver, update])

  const platform = React.useMemo(
    () =>
      app.platform === 'ios' || app.platform === 'android' || app.platform === 'webpush'
        ? app.platform
        : 'webpush',
    [app.platform]
  )
  const onMaxRateBlur = React.useCallback(() => {
    if (!isNaN(data.maxRate) && data.maxRate !== undefined && data.maxRate !== null) {
      if ((data.maxRate ?? 0) < 60) {
        setData(data => data.set('maxRate', 60))
      }

      if ((data.maxRate ?? 0) > maxMax) {
        setData(data => data.set('maxRate', maxMax))
      }
    }
  }, [data, maxMax])
  const onMaxRateToggle = React.useCallback(
    (isActive: boolean) =>
      setData(data =>
        data.set('maxRate', !isActive ? null : config.maxRate ? config.maxRate : maxMax)
      ),
    [config.maxRate, maxMax]
  )
  const onMaxRateChange = React.useCallback(
    evt => setData(data => data.set('maxRate', parseInt(evt.target.value))),
    []
  )
  const onTtlToggle = React.useCallback(
    enabled => setData(data => data.set('defaultTtl', enabled ? 24 : null)),
    []
  )
  const onTtlChange = React.useCallback(
    evt => setData(data => data.set('defaultTtl', parseInt(evt.target.value))),
    []
  )
  const isAllowedToUpdateCommonPushSettings = useIsCurrentUserAllowedTo([
    'app',
    'push:config:write',
  ])
  const setNormalPriority = React.useCallback(
    () => setData(data => data.set('defaultPriority', 'NORMAL')),
    []
  )
  const setHighPriority = React.useCallback(
    () => setData(data => data.set('defaultPriority', 'HIGH')),
    []
  )
  const onToggleCollapseKey = React.useCallback(
    enabled => setData(data => data.set('defaultCollapseKey', enabled ? 'default' : '')),
    []
  )
  const onCollapseKeyChange = React.useCallback(evt => {
    setData(data => data.set('defaultCollapseKey', evt.target.value))
  }, [])
  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title="Default push settings" />
      </BoxHeader>

      <BoxBody>
        <FlexLine shouldWrap>
          {hasLegacyPush && (
            <SettingsPushItem platform={platform} isRate>
              <Grid template="1fr 150px" style={{ alignItems: 'start' }}>
                <InputWrapper
                  label={
                    <Switch
                      isActive={data.maxRate !== null}
                      onChange={onMaxRateToggle}
                      style={{ marginTop: -6 }}
                    >
                      Manage push delivery speed{' '}
                      <Hint minTooltipWidth={300}>
                        Define a number of push sent per minute. By default, the push delivery speed
                        is set to the maximum speed defined on your plan.
                      </Hint>
                    </Switch>
                  }
                >
                  <React.Fragment>
                    <FlexLine>
                      <Input
                        type="number"
                        min={60}
                        max={maxMax}
                        allowedCharsRegex={/[0-9]/}
                        style={{ width: 100 }}
                        placeholder={data.maxRate === null || isNaN(data.maxRate) ? maxMax : ''}
                        value={data.maxRate === null || isNaN(data.maxRate) ? '' : data.maxRate}
                        onChange={onMaxRateChange}
                        onBlur={onMaxRateBlur}
                        aria-label="Number of push per minute"
                      />

                      <Text
                        kind={data.maxRate === null ? 'disabled' : 'default'}
                        style={{ marginLeft: 16 }}
                      >
                        push per minute
                      </Text>

                      {nbPushToken > 0 && alertRateMessage.length > 0 && !!data.maxRate && (
                        <React.Fragment>
                          <Text kind={alertRateLevel} style={{ paddingLeft: 23 }}>
                            <Icon icon="waiting" style={{ marginRight: 7 }} />
                            <span>{alertRateMessage}</span>
                          </Text>

                          <Hint minTooltipWidth={300}>
                            <span>
                              This time is the estimation to send to all users. Please be aware that
                              users may receive push at an inopportune time (e.g: overnight) if the
                              time to send is too long.
                            </span>
                          </Hint>
                        </React.Fragment>
                      )}
                    </FlexLine>

                    {data.maxRate !== null && (
                      <Text
                        kind={!warnRateTooLow ? 'option' : 'danger'}
                        size={12}
                        weight={500}
                        style={{ marginTop: 10 }}
                      >
                        Sendings will be automatically stopped after 12 hours or 6 hours for
                        transactional API. You must enter a number between 60 and{' '}
                        {numberFormat(maxMax)}.
                      </Text>
                    )}
                  </React.Fragment>
                </InputWrapper>
                <LinkDocumentation
                  href="https://help.batch.com/en/articles/4207443-how-can-i-adjust-the-delivery-speed-of-my-push-notification?location=conversation#h_9559d05626"
                  target="_blank"
                  intent="action"
                  style={{ textAlign: 'right' }}
                >
                  Documentation
                </LinkDocumentation>
              </Grid>
            </SettingsPushItem>
          )}
          {show.has('ttl') && (
            <SettingsPushItem platform={platform}>
              <InputWrapper
                label={
                  <Switch
                    isActive={data.defaultTtl !== null}
                    onChange={onTtlToggle}
                    style={{ marginTop: -6 }}
                  >
                    Expiration (TTL)
                    <Hint minTooltipWidth={300}>
                      By default, Batch will not set notifications to expire, which means that if
                      the user's device is offline for 2 weeks and then goes back online, it will
                      receive all notifications sent during this period. You can change this
                      behavior here. This default setting does not apply to API created campaigns.
                    </Hint>
                  </Switch>
                }
                htmlFor="default-ttl"
              >
                <Grid template="60px 1fr">
                  <Input
                    type="number"
                    allowedCharsRegex={/[0-9]/}
                    value={data.defaultTtl !== null ? data.defaultTtl : ''}
                    disabled={data.defaultTtl === null}
                    onChange={onTtlChange}
                    id="default-ttl"
                    aria-label="Expiration time"
                  />
                  <Text kind={data.defaultTtl === null ? 'disabled' : 'default'}>hours</Text>
                </Grid>
              </InputWrapper>
            </SettingsPushItem>
          )}

          {show.has('priority') && (
            <SettingsPushItem platform={platform}>
              <InputWrapper
                label="Priority"
                labelStyle={{ fontWeight: 300 }}
                hintMinSize={300}
                hint="Default is high. Normal priority send the push message at a time that takes into
                    account power considerations for the device. Notifications with this priority
                    might be grouped and delivered in bursts. They are throttled, and in some cases
                    are not delivered. Only for dashboard created campaigns.
"
              >
                <TabButton>
                  <TabButtonItem
                    isActive={data.defaultPriority === 'NORMAL'}
                    onClick={setNormalPriority}
                  >
                    Normal
                  </TabButtonItem>
                  <TabButtonItem
                    isActive={data.defaultPriority === 'HIGH'}
                    onClick={setHighPriority}
                  >
                    High
                  </TabButtonItem>
                </TabButton>
              </InputWrapper>
            </SettingsPushItem>
          )}
          {show.has('collapseKey') && (
            <SettingsPushItem platform={platform}>
              <InputWrapper
                label={
                  <Grid template="auto 1fr">
                    <Switch
                      isActive={Boolean(data.defaultCollapseKey)}
                      onChange={onToggleCollapseKey}
                      style={{ marginTop: -6 }}
                    >
                      Collapse key
                      <Hint>
                        Defines how notifications are managed when an offline device goes online. If
                        enabled, the device will only show the most recent notification. If
                        disabled, it will show all the notifications received when the device was
                        offline. You should disable the collapse key if all your notifications
                        matter (E.g. messages, etc). You can use up to 3 different collapse keys if
                        you want users to get only one notification of each kind when coming online
                        (E.g. marketing, alert, etc). Only for dashboard created campaigns.
                      </Hint>
                    </Switch>
                  </Grid>
                }
              >
                <Input
                  style={{ maxWidth: 136 }}
                  value={data.defaultCollapseKey}
                  disabled={!data.defaultCollapseKey}
                  onChange={onCollapseKeyChange}
                  aria-label="Collapse key"
                />
              </InputWrapper>
            </SettingsPushItem>
          )}
        </FlexLine>
      </BoxBody>

      <BoxFooter>
        <PermissionButton
          intent="action"
          onClick={handleSubmit}
          kind="primary"
          disabled={!canUpdate}
          isAllowed={isAllowedToUpdateCommonPushSettings}
        >
          Update push settings
        </PermissionButton>
      </BoxFooter>
    </Box>
  )
}

const SettingsCommon: React.ComponentType<SettingsCommonProps> =
  React.memo<SettingsCommonProps>(SettingsCommonRaw)
export { SettingsCommon }
