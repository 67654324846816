// @flow

import styled, { type StyledComponent } from 'styled-components'

import { BoxBody } from 'components/common/box'
import { FeedbackContainer } from 'components/form/feedback.styles'
import { InputWrapperContainer } from 'components/form/input-wrapper.styles'

export const StepsWrapper: StyledComponent<*, *, HTMLElement> = styled.div`
  position: relative;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 20px;
  }

  ${InputWrapperContainer} ${FeedbackContainer} {
    display: block;
  }
`

export const DeleteCompanyModalContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  width: 500px;

  ${BoxBody} {
    padding: 30px;
    border-radius: 0;
  }
`
