// @flow

import * as React from 'react'

import { ReviewNavSecondLinks, SubLink } from 'components/campaign/review/review-screen.styles'

type ReviewDataMenuProps = {
  abTesting: boolean,
  recurring: boolean,
  trigger: boolean,
  activeStep: ?string,
  theme: ?string,
  isPush: boolean,
  ...
}
const ReviewDataMenu = ({
  abTesting,
  recurring,
  activeStep,
  isPush,
  trigger,
}: ReviewDataMenuProps): React.Node => {
  return (
    <ReviewNavSecondLinks>
      <SubLink active={activeStep === 'summary'} href="#summary">
        Summary
      </SubLink>
      <SubLink active={activeStep === 'performance'} href="#performance">
        Performance
      </SubLink>
      {abTesting && (
        <SubLink active={activeStep === 'ab-testing'} href="#ab-testing">
          A/B testing
        </SubLink>
      )}
      {isPush && (
        <SubLink active={activeStep === 'delivery'} href="#delivery">
          Delivery
        </SubLink>
      )}
      {trigger && (
        <SubLink active={activeStep === 'journey'} href="#journey">
          User journey
        </SubLink>
      )}
      {(recurring || trigger) && (
        <SubLink active={activeStep === 'history'} href="#history">
          History
        </SubLink>
      )}
    </ReviewNavSecondLinks>
  )
}

export default ReviewDataMenu
