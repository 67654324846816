// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'

import { TooltipPermission, type AccessNotAllowedProps } from 'components/common/tooltip-permission'

import { SegmentedControlContainer } from './toggle-button.styles'

type TabButtonProps = {
  ...AccessNotAllowedProps,
  grow?: boolean,
  size?: 'normal' | 'small',
  invalid?: boolean,
  disabled?: boolean,
  children: ReactNode,
  style?: { [string]: string | number },
}

export function TabButton({
  grow = false,
  size = 'normal',
  children,
  style,
  invalid,
  disabled,
  accessNotAllowed,
  accessNotAllowedMsg,
}: TabButtonProps): React.Node {
  return (
    <TooltipPermission
      accessNotAllowed={accessNotAllowed ?? false}
      accessNotAllowedMsg={accessNotAllowedMsg}
    >
      <SegmentedControlContainer
        style={style}
        grow={grow}
        size={size}
        invalid={invalid}
        disabled={disabled || accessNotAllowed}
      >
        {children}
      </SegmentedControlContainer>
    </TooltipPermission>
  )
}
