// @flow

import * as React from 'react'

import { BoxFooter, FooterBoxActions } from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { LinkDoc } from 'components/styled/text'

type Props = {
  isEditing: boolean,
  cancel: () => void,
  save: () => void,
  openConfirmEdit: () => void,
  isAllowed: boolean,
  isDisabled: boolean,
  showUpdateBtn: boolean,
  isLoading: boolean,
}

export const SettingsAndroidFcmFooter = ({
  isEditing,
  cancel,
  save,
  openConfirmEdit,
  isAllowed,
  isDisabled,
  showUpdateBtn,
  isLoading,
}: Props): React.Node => {
  return (
    <BoxFooter isEditable={isEditing}>
      {isEditing && (
        <Button kind="inline" onClick={cancel}>
          Cancel
        </Button>
      )}
      <FooterBoxActions>
        <LinkDoc
          intent="action"
          href="https://doc.batch.com/android/prerequisites.html"
          target="_blank"
          style={{ marginRight: 8 }}
        >
          Documentation
        </LinkDoc>
        {showUpdateBtn && (
          <PermissionButton
            intent="action"
            kind="primary"
            onClick={openConfirmEdit}
            isAllowed={isAllowed}
            disabled={isDisabled}
          >
            Update FCM config
          </PermissionButton>
        )}
        {isEditing && (
          <Button type="button" kind="primary" intent="action" onClick={save} isLoading={isLoading}>
            Save FCM config
          </Button>
        )}
      </FooterBoxActions>
    </BoxFooter>
  )
}
