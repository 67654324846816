// @flow
import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { HintIcon } from 'components/common/hint'
import { IconContainer } from 'components/common/svg-icon'
import { colors, texts } from 'components/styled/tokens'

import { type MetricsAlignProps, type MetricsSizeProps } from './metric'

type MetricsThemeProps = { metricAlign: MetricsAlignProps, metricSize: MetricsSizeProps }
type MetricProps = PropsWithTheme<{}, MetricsThemeProps>

export const MetricContainer: StyledComponent<{}, MetricsThemeProps, HTMLDivElement> = styled.div`
  display: flex;

  ${(props: MetricProps) =>
    props.theme.metricAlign === 'row' &&
    css`
      flex-direction: row;
      align-items: center;
    `}

  ${(props: MetricProps) =>
    props.theme.metricAlign === 'column' &&
    css`
      flex-direction: column;
      & > div:first-of-type {
        display: flex;
        align-items: baseline;
      }
    `}
    ${HintIcon} {
    padding: 0;
    margin: 4px;
  }
`

export const MetricTitle: StyledComponent<{}, MetricsThemeProps, HTMLHeadingElement> = styled.h1`
  color: ${colors.text};

  ${(props: MetricProps) =>
    props.theme.metricSize === 'XL' &&
    css`
      ${texts.metricXl};
    `}

  ${(props: MetricProps) =>
    props.theme.metricSize === 'M' &&
    css`
      ${texts.metric};
    `}

    ${(props: MetricProps) =>
    props.theme.metricAlign === 'column' &&
    css`
      margin-bottom: 2px;
    `}
`

export const MetricSubtitle: StyledComponent<{}, MetricsThemeProps, HTMLSpanElement> = styled.span`
  font-weight: 400;
  color: ${colors.textLight};

  ${IconContainer} {
    margin: 0 4px;
  }

  ${(props: MetricProps) =>
    props.theme.metricAlign === 'row' &&
    css`
      margin-left: 6px;
    `};
`

type MetricLegendProps = { $color: string, ... }
export const MetricLegend: StyledComponent<MetricLegendProps, {}, HTMLSpanElement> = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  background-color: ${(props: MetricLegendProps) => props.$color};
  border-radius: 4px;
  margin: 4px;
`
