// @flow
import styled, { css, type PropsWithTheme, type StyledComponent } from 'styled-components'

import { EmptyField } from 'components/common/empty-states'
import { colors } from 'components/styled/tokens'

import { PLATFORM_BUTTOM_THEME } from 'com.batch/profile/constants/platform-button-theme'
import {
  type PlatformKindType,
  type SubscriptionStatus,
} from 'com.batch/profile/models/profile.records'

type PlatformButtonTheme = { platformButtomKind: PlatformKindType }
type OptionStatusContainerProps = {
  $size?: 'short' | 'expanded',
  status?: SubscriptionStatus,
}
type OptinStatusContainerWithTheme = PropsWithTheme<OptionStatusContainerProps, PlatformButtonTheme>
export const OptinStatusContainer: StyledComponent<
  OptionStatusContainerProps,
  PlatformButtonTheme,
  HTMLDivElement,
> = styled.div`
  position: absolute;
  font-weight: 500;
  line-height: 20px;
  border-radius: ${(props: OptinStatusContainerWithTheme) =>
    props.$size === 'expanded' ? 12 : 6}px;

  & > div.message {
    mergin-top: 4px;
    font-weight: 400;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  & ${EmptyField}:first-child {
    margin-bottom: 8px;
  }

  ${(props: OptinStatusContainerWithTheme) =>
    props.$size === 'short'
      ? css`
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          top: 40px;
          left: 22px;
          width: 96px;
          padding: 3px 8px;
          font-size: 12px;
        `
      : css`
          top: 94px;
          left: 48px;
          width: 224px;
          padding: 10px 12px;
          font-size: 14px;

          span {
            margin-right: 6px;
          }
        `}
  ${(props: OptinStatusContainerWithTheme) =>
    props.status === 'SUBSCRIBED'
      ? css`
          color: ${props.$size === 'short'
            ? PLATFORM_BUTTOM_THEME[props.theme.platformButtomKind].color.optin
            : PLATFORM_BUTTOM_THEME[props.theme.platformButtomKind].color.optinExpended};
          background-color: ${PLATFORM_BUTTOM_THEME[props.theme.platformButtomKind].background
            .optin};

          & > div.message {
            color: ${colors.textLight};
          }
        `
      : css`
          color: ${colors.textLight};
          border: 1px dashed ${colors.stroke};
        `}
`

type ProfileMockupOptinContainerProps = {
  $size: 'short' | 'expanded',
}
export const ProfileMockupOptinContainer: StyledComponent<
  ProfileMockupOptinContainerProps,
  {},
  HTMLDivElement,
> = styled.div`
  position: relative;
  width: ${(props: PropsWithTheme<ProfileMockupOptinContainerProps, {}>) =>
    props.$size === 'short' ? 140 : 320}px;

  & > img {
    width: 100%;
  }
`
