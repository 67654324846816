/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StandalonePreview } from 'components/campaign/preview/standalone-preview'
import RestrictedInput from 'components/common/restricted-input'
// import { SampleSelect } from './sample-select'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import Field from 'components/theme/field'
import { StyleEditor } from 'components/theme/style-editor'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type Variant, type AppRecord } from 'com.batch.redux/_records'
import { InAppContentFactory } from 'com.batch.redux/content.records'
import { SelectThemeField, UpdateThemeField, UpdateThemeFieldStyle } from 'com.batch.redux/theme'
import {
  AbTestedThemeFactory,
  type FieldKind,
  type FieldRecord,
  type PayloadProps,
  type StyleProperty,
  type ThemeProps,
  type ThemeRecord,
} from 'com.batch.redux/theme.records'
import { SelectedFieldSelector } from 'com.batch.redux/theme.selector'

type ThemeComponentProps = {
  theme: ThemeRecord,
  app: AppRecord,
  updatePayload: ($Keys<PayloadProps>, any) => void,
  updateValue: ($Keys<ThemeProps>, any) => void,
}

const fieldIdS: Array<FieldKind> = [
  'general',
  'image',
  'header',
  'title',
  'text',
  'cta1',
  'cta2',
  'global',
  'close',
  'dismissIndicator',
]

export const Theme = ({
  theme,
  app,
  updatePayload,
  updateValue,
}: ThemeComponentProps): React.Node => {
  // redux -------------------------------------
  const field = useSelector(SelectedFieldSelector)
  const dispatch = useDispatch()
  // callbacks ---------------------------------
  const selectField = React.useCallback(
    ({ field, variant }: { field: ?FieldKind, variant: ?Variant }) => {
      dispatch(SelectThemeField({ field, variant }))
    },
    [dispatch]
  )
  const createStyleUpdater = React.useCallback(
    (field: FieldRecord) => (property: StyleProperty, value: any) => {
      dispatch(UpdateThemeFieldStyle({ field: field, property, value }))
    },
    [dispatch]
  )
  const toggleFieldCreator = React.useCallback(
    (field: FieldRecord) => {
      return () => {
        dispatch(UpdateThemeField(field.set('hidden', !field.hidden)))
      }
    },
    [dispatch]
  )
  const setCTABorderRadius = React.useCallback(
    (value: string) => {
      theme.fields
        .filter(f => f.id === 'cta1' || f.id === 'cta2')
        .forEach(field => {
          dispatch(UpdateThemeFieldStyle({ field: field, property: 'borderRadius', value }))
        })
    },
    [dispatch, theme.fields]
  )
  // derived ------------------------------------
  const showAlignementTopBottom = theme.payloadVars.kind === 'banner'
  const showAttachedMode =
    theme.payloadVars.kind !== 'universal' &&
    theme.payloadVars.kind !== 'image' &&
    theme.payloadVars.kind !== 'webview'
  const showOSUIColor = theme.payloadVars.kind === 'universal'
  const showImagePlacement = theme.payloadVars.kind === 'universal'
  const showStackButtons =
    theme.payloadVars.kind !== 'image' && theme.payloadVars.kind !== 'webview'
  const showFullScreen = theme.payloadVars.kind === 'image'
  return (
    <div className="editor">
      <div className="editor__content">
        <div className="boxed">
          <h2 className="boxed__head">Content</h2>
          {fieldIdS
            .filter(
              id =>
                id !== 'image' || theme.payloadVars.kind !== 'image' || theme.payloadVars.fullscreen
            )
            .map(fieldId => {
              const f = theme.fields.get(fieldId)
              return (
                f && (
                  <Field
                    key={fieldId}
                    field={f}
                    tooltip={
                      f.id === 'dismissIndicator'
                        ? 'Dismiss after 10 seconds'
                        : f.id === 'close' && theme.payloadVars.kind === 'webview'
                          ? 'Mandatory button to provide an emergency exit out to your users, specially during WebView loading.'
                          : ''
                    }
                    select={() => selectField({ field: f.id, variant: null })}
                    toggle={toggleFieldCreator(f)}
                  />
                )
              )
            })}
        </div>
        <div className="alert muted">
          Be aware that this is just a preview, you should always send a working test on your device
          before sending to your end-users.
        </div>
      </div>
      <div className="editor__preview">
        {/* <div className="mb-4x">{theme.payloadVars.kind !== 'webview' && <SampleSelect />}</div>{' '} */}
        <StandalonePreview
          getVariantData={() => {
            return { title: '', message: '', audio: '', video: '', image: '' }
          }}
          app={app}
          hasLanding
          hasPush={false}
          icon=""
          safariIcon={app.pushConfig.safariWebsiteIcon}
          templatedFields={Immutable.Set()}
          loadingThemes={false}
          mode={Immutable.Map({ a: 'light', b: 'light' })}
          previewWeb=""
          variant={null}
          variantsThemes={AbTestedThemeFactory({ a: theme, b: theme })}
          date={dayjs()}
          focusField={selectField}
          selectedField={field ? field.id : ''}
          previewMode="landing"
          getLandingVariantData={() => InAppContentFactory()}
          audio=""
          image=""
          video=""
        />
      </div>
      <div className="editor__config">
        <div className="boxed">
          <h2 className="boxed__head">
            Customize
            {field && (
              <span className="btn btn--action btn--xsm btn--nohover btn--dark">{field.label}</span>
            )}
          </h2>
          <div className="boxed__body">
            {field && field.id === 'dismissIndicator' && theme.payloadVars.kind === 'image' && (
              <p>
                The dismiss indicator for <code>image</code> theme is a circle around the close
                button. Hence its color depends on the close button.
              </p>
            )}
            {field && field.id === 'general' && (
              <div className="form-group form-group--inline">
                <label className="form-group--inline__label" htmlFor="theme-code">
                  Code
                </label>{' '}
                <div className="col-sm-10">
                  <RestrictedInput // $FlowFixMe[method-unbinding]
                    transform={String.prototype.toUpperCase}
                    drop="[^A-Z0-9-_]"
                    type="text"
                    readOnly={theme.id !== 'new'}
                    onChange={evt => updateValue('code', evt.target.value)}
                    value={theme.code}
                    className="form-control"
                    id="theme-code"
                  />
                </div>
              </div>
            )}
            {field && showAlignementTopBottom && field.id === 'general' && (
              <div className="form-group form-group--inline">
                <p className="form-group--inline__label" style={{ fontWeight: 500 }}>
                  Placement
                </p>{' '}
                <div className="col-sm-10">
                  <TableToggle>
                    <TableToggleItem
                      active={theme.payloadVars.banner_bottom === false}
                      onClick={() => updatePayload('banner_bottom', false)}
                    >
                      Top
                    </TableToggleItem>
                    <TableToggleItem
                      active={theme.payloadVars.banner_bottom === true}
                      onClick={() => updatePayload('banner_bottom', true)}
                    >
                      Bottom
                    </TableToggleItem>
                  </TableToggle>
                </div>
              </div>
            )}
            {field && showFullScreen && field.id === 'general' && (
              <div className="form-group form-group--inline">
                <p className="form-group--inline__label" style={{ fontWeight: 500 }}>
                  Fullscreen
                </p>{' '}
                <div className="col-sm-10">
                  <TableToggle>
                    <TableToggleItem
                      active={theme.payloadVars.fullscreen === true}
                      onClick={() => updatePayload('fullscreen', true)}
                    >
                      True
                    </TableToggleItem>
                    <TableToggleItem
                      active={theme.payloadVars.fullscreen === false}
                      onClick={() => updatePayload('fullscreen', false)}
                    >
                      False
                    </TableToggleItem>
                  </TableToggle>
                </div>
              </div>
            )}
            {field && showAttachedMode && field.id === 'general' && (
              <div className="form-group form-group--inline">
                <p className="form-group--inline__label" style={{ fontWeight: 500 }}>
                  Attached
                </p>{' '}
                <div className="col-sm-10">
                  <TableToggle>
                    <TableToggleItem
                      active={theme.payloadVars.detached === false}
                      onClick={() => updatePayload('detached', false)}
                    >
                      True
                    </TableToggleItem>
                    <TableToggleItem
                      active={theme.payloadVars.detached === true}
                      onClick={() => updatePayload('detached', true)}
                    >
                      False
                    </TableToggleItem>
                  </TableToggle>
                </div>
              </div>
            )}
            {field && showOSUIColor && field.id === 'general' && (
              <div className="form-group form-group--inline">
                <p className="form-group--inline__label" style={{ fontWeight: 500 }}>
                  OS UI color
                </p>{' '}
                <div className="col-sm-10">
                  <TableToggle>
                    <TableToggleItem
                      active={theme.statusBarMode === 'light'}
                      onClick={() => updateValue('statusBarMode', 'light')}
                    >
                      Light
                    </TableToggleItem>
                    <TableToggleItem
                      active={theme.statusBarMode === 'dark'}
                      onClick={() => updateValue('statusBarMode', 'dark')}
                    >
                      Dark
                    </TableToggleItem>
                  </TableToggle>
                </div>
              </div>
            )}
            {field && showImagePlacement && field.id === 'general' && (
              <div className="form-group form-group--inline">
                <p className="form-group--inline__label" style={{ fontWeight: 500 }}>
                  Image pos.
                </p>{' '}
                <div className="col-sm-10">
                  <TableToggle>
                    <TableToggleItem
                      active={theme.payloadVars.flip_hero_v === false}
                      onClick={() => updatePayload('flip_hero_v', false)}
                    >
                      Top
                    </TableToggleItem>
                    <TableToggleItem
                      active={theme.payloadVars.flip_hero_v === true}
                      onClick={() => updatePayload('flip_hero_v', true)}
                    >
                      Bottom
                    </TableToggleItem>
                  </TableToggle>
                </div>
              </div>
            )}
            {field && showStackButtons && field.id === 'general' && (
              <React.Fragment>
                <div className="form-group form-group--inline">
                  <p className="form-group--inline__label" style={{ fontWeight: 500 }}>
                    CTA mode:
                  </p>{' '}
                  <div className="col-sm-10">
                    <TableToggle>
                      <TableToggleItem
                        active={theme.payloadVars.stack_cta_h === true}
                        onClick={() => {
                          setCTABorderRadius('0px')
                          updatePayload('stack_cta_h', true)
                        }}
                      >
                        Horizontal
                      </TableToggleItem>
                      <TableToggleItem
                        active={theme.payloadVars.stack_cta_h === false}
                        onClick={() => {
                          setCTABorderRadius('10px')
                          updatePayload('stack_cta_h', false)
                        }}
                      >
                        Vertical
                      </TableToggleItem>
                    </TableToggle>
                  </div>
                </div>
                {!theme.payloadVars.stack_cta_h && (
                  <div className="form-group form-group--inline">
                    <p className="form-group--inline__label" style={{ fontWeight: 500 }}>
                      CTA position:
                    </p>{' '}
                    <div className="col-sm-10">
                      <TableToggle>
                        <TableToggleItem
                          active={theme.payloadVars.attach_cta_bottom === true}
                          onClick={() => {
                            updatePayload('attach_cta_bottom', true)
                          }}
                        >
                          Bottom
                        </TableToggleItem>
                        <TableToggleItem
                          active={theme.payloadVars.attach_cta_bottom === false}
                          onClick={() => {
                            updatePayload('attach_cta_bottom', false)
                          }}
                        >
                          Free
                        </TableToggleItem>
                      </TableToggle>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}

            {!!field && <StyleEditor field={field} update={createStyleUpdater(field)} />}
            {theme.payloadVars.kind === 'webview' &&
              field &&
              field.id === 'general' &&
              theme.fields.getIn(['general', 'style', 'backgroundOpacity'], 0) !== 100 && (
                <div style={{ color: '#ED8545', marginTop: 20, marginBottom: 0 }}>
                  Be aware that HTML WebView format doesn't support app interaction underneath.
                </div>
              )}
          </div>
        </div>
        {theme.payloadVars.kind === 'webview' && field && field.id === 'general' && (
          <div className="alert muted">
            Background design serves as a frame for loading purposes and is handled as a native
            component
          </div>
        )}
      </div>
    </div>
  )
}
