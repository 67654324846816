// @flow

import Immutable from 'immutable'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import {
  SenderIdentityFactory,
  type SenderIdentityRecord,
} from 'com.batch.redux/corelogic/records/sender-identity.records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type TransmissionType } from 'com.batch/shared/models/transmission-type'

type CreateSenderIdentityAction = {
  type: 'CREATE_SENDER_IDENTITY',
  ...
}
export type CreateSenderIdentityActionSuccessAction = {
  type: 'CREATE_SENDER_IDENTITY_SUCCESS',
  payload: SenderIdentityRecord,
  ...
}
type CreateSenderIdentityActionFailureAction = {
  type: 'CREATE_SENDER_IDENTITY_FAILURE',
  payload: {
    error: string,
    ...
  },
  ...
}

export type CreateSenderIdentityActions =
  | CreateSenderIdentityAction
  | CreateSenderIdentityActionSuccessAction
  | CreateSenderIdentityActionFailureAction

export const createSenderIdentity = (
  sendingName: string,
  sendingPrefix: string,
  sendingDomain: string,
  transmissionTypes: Immutable.Set<TransmissionType>
): DispatchExtraBoundFn<Promise<SenderIdentityRecord>> => {
  return (dispatch, getState, { senderIdentityGateway }) => {
    const project = currentProjectSelector(getState())
    const senderIdentity = SenderIdentityFactory({
      id: -1,
      sendingName,
      sendingPrefix,
      sendingDomain,
      transmissionTypes,
    })
    return promiseActionCreator({
      dispatch,
      promise: senderIdentityGateway.createSenderIdentity(project.projectKey, senderIdentity),
      actionName: 'CREATE_SENDER_IDENTITY',
    })
  }
}
