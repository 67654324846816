// @flow

import { scaleBand } from 'd3-scale'
import { List } from 'immutable'
import * as React from 'react'

import { schemes, colors } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  LegendTimeLabel,
  LegendTimeCurrent,
  LegendTimeContainer,
  LegendTimeSeparator,
  LegendTimeRect,
  LegendTimeLabelSkeleton,
} from './styles'

import { DataSetFactory, DataPointFactory, type DataSetRecord } from '../chart-helper'

const DOMAINFORMAT = 'YYYYMMDDHH'

type TimelineProps = {
  sets: List<DataSetRecord>,
  overIndex: ?number,
  dateFormat: string,
  height: number,
  width: number,
  nbTicks: number,
  xScale: typeof scaleBand,
  translateY: number,
  loading?: boolean,
  position?: 'top' | 'bottom',
  ...
}

const TimelineBase = ({
  sets,
  overIndex,
  dateFormat,
  height,
  width,
  nbTicks,
  xScale,
  translateY,
  loading,
  position,
}: TimelineProps): React.Node => {
  const set = sets.get(0, DataSetFactory())
  const d = set ? set.data.get(overIndex ?? 0, DataPointFactory()).date : dayjs()
  const dd = d.format(DOMAINFORMAT)
  const every = Math.max(1, Math.floor(set.data.size / nbTicks))
  let tv = []
  let ind = 0
  let safeg = 20

  while (ind < set.data.size && safeg > 0) {
    tv.push(set.data.get(ind, DataPointFactory()).date)
    ind += every
    safeg--
  }
  return (
    <LegendTimeContainer transform={`translate(0 ${translateY})`}>
      <LegendTimeRect x={-1} y={-1} width={width + 1} height={height + 0.5} />
      {position === 'top' && (
        <LegendTimeSeparator x1={-1} y1={height - 1.5} x2={width + 1} y2={height - 1.5} />
      )}
      <line x1={-1} y1={height - 0.5} x2={width + 1} y2={height - 0.5} stroke="#F6F6FA" />
      <line x1={-1} y1={height + 0.5} x2={width + 1} y2={height + 0.5} stroke="#FEFEFE" />
      <line x1={-1} y1={height + 1.5} x2={width + 1} y2={height + 1.5} stroke="#FEFEFE" />
      {tv.map((date, i) => {
        const x = xScale(date.format(DOMAINFORMAT))
        return !loading ? (
          <LegendTimeLabel
            key={date.isValid() ? date.format(DOMAINFORMAT) : i}
            y={height / 2 + 4}
            x={x + xScale.bandwidth() / 2}
          >
            {date.format(dateFormat)}
          </LegendTimeLabel>
        ) : (
          <LegendTimeLabelSkeleton
            y={height / 3}
            x={x + xScale.bandwidth() / 2}
            key={date.isValid() ? date.format(DOMAINFORMAT) : i}
            fill={schemes.grayscale['30']}
            width={40}
            height={13}
            rx={2}
          />
        )
      })}

      {!loading && overIndex !== null && (
        <LegendTimeCurrent
          height={height - 1}
          x={Math.min(width - 55, Math.max(35, xScale(dd) + xScale.bandwidth() / 2))}
        >
          <rect
            fill="url(#barchart-currentLegendGradient)"
            x={-70}
            height={height - 2}
            width={140}
          />
          <text y={height / 2 + 4.5} width="140">
            {d.format(dateFormat)}
          </text>
        </LegendTimeCurrent>
      )}
      {translateY > 3 && (
        <line x1={0} x2={width} y1={height - 34} y2={height - 34} stroke={colors.stroke} />
      )}
    </LegendTimeContainer>
  )
}

export const Timeline: React.ComponentType<TimelineProps> = React.memo<TimelineProps>(TimelineBase)
