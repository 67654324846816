// @flow
import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EmailAnalytics } from './email-analytics'
import { PushAnalytics } from './push-analytics'
import { SmsAnalytics } from './sms-analytics'

import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'

type ChannelsAnalyticsProps = {
  channels: Set<ChannelUntilCleanup>,
}
export const ChannelsAnalytics = ({ channels }: ChannelsAnalyticsProps): React.Node => {
  const { token }: { token: string, ... } = useParams()
  const dispatch = useDispatch()
  const { filters, dateRange } = useGetAnalyticsFilters()
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const loadingState: LoadingState = React.useMemo(
    () => analyticsMap.get(token)?.channelsLoadingState ?? 'INIT',
    [analyticsMap, token]
  )
  const hasEmailOrSms = React.useMemo(
    () => channels.has('email') || channels.has('sms'),
    [channels]
  )

  React.useEffect(() => {
    if (token && hasEmailOrSms) {
      const tokens = new Immutable.List().push(token)
      dispatch(
        fetchDynamicStats({
          tokens,
          filters,
          dateRange,
          config: 'orchestrationStatsByChannel',
          includePreviousPeriod: true,
        })
      ).catch(() => {})
    }
  }, [dispatch, token, filters, dateRange, hasEmailOrSms])

  return (
    <React.Fragment>
      {channels.has('email') && <EmailAnalytics loadingState={loadingState} />}
      {channels.has('push') && <PushAnalytics />}
      {channels.has('sms') && <SmsAnalytics loadingState={loadingState} />}
    </React.Fragment>
  )
}
