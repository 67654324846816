// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Text } from '../auth.styles'

// $FlowFixMe
import Illustration from 'illustrations/onboarding_2fa_illustration.svg'

export const Register2FAIntro = ({ enforce2FA }: { enforce2FA: boolean, ... }): React.Node => (
  <React.Fragment>
    {enforce2FA ? (
      <Text>
        You will need to use two-factor authentication to access the Batch dashboard. Add this extra
        layer of security to protect your account.
      </Text>
    ) : (
      <Text>
        You can add an extra layer of security requiring a mobile-device based verification, to
        protect your account.
      </Text>
    )}
    <Illustration2FA />
  </React.Fragment>
)

export const Illustration2FA: StyledComponent<
  *,
  *,
  React.ComponentType<typeof Illustration>,
> = styled(Illustration)`
  width: auto;
  height: 240px;
  margin: 32px 0 0 -2px;
`
