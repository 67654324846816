// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

type DragAudiencesProps = { isDragActive: boolean, isInvalid: boolean, ... }
export const DragAudiences: StyledComponent<DragAudiencesProps, *, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 21px;
  height: 100%;
  width: 100%;
  background-color: ${(props: DragAudiencesProps) =>
    props.isDragActive ? 'rgba(2, 178, 252, 0.02)' : 'transparent'};
  border: ${(props: DragAudiencesProps) => (props.isDragActive ? 2 : 1)}px dashed
    ${(props: DragAudiencesProps) =>
      props.isDragActive ? colors.textAction : props.isInvalid ? colors.textDanger : colors.stroke};
  border-radius: 15px;
  outline: none;

  &:hover,
  &:focus {
    cursor: ${(props: DragAudiencesProps) => (props.isDragActive ? 'copy' : 'pointer')};
    background-color: ${(props: DragAudiencesProps) =>
      props.isDragActive ? 'rgba(2, 178, 252, 0.02)' : 'rgba(33, 43, 55, 0.01)'};
  }

  & > p {
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    color: ${colors.textLight};

    &:last-of-type {
      margin-top: ${(props: DragAudiencesProps) => (props.isDragActive ? 142 : 131)}px;
      line-height: 20px;
      text-align: center;
      color: ${(props: DragAudiencesProps) => (props.isInvalid ? colors.textDanger : colors.text)};
    }
  }
`
type UploadContainerProps = { progress?: number, isInvalid?: boolean, ... }
export const UploadContainer: StyledComponent<UploadContainerProps, *, HTMLElement> = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .styled-upload-file {
    position: relative;
    width: 302px;
    padding: 2px;
    border-radius: 8px;
    overflow: hidden;

    .styled-progress-loader-file {
      position: absolute;
      top: -2px;
      left: -2px;

      rect {
        width: 296px;
        height: 60px;
        stroke: ${colors.textAction};
        stroke-dasharray: 712 712;
        stroke-dashoffset: ${(p: UploadContainerProps) =>
          p.progress ? 500 - 500 * (p.progress / 100) : 712};
      }
    }
    
    .styled-upload-container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: ${colors.fill};
      border-radius: 6px;
      border: 1px solid ${(props: UploadContainerProps) =>
        props.isInvalid ? colors.textDanger : colors.stroke};
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);

      .styled-upload-infos {
        display: flex;
        padding: 12px 20px 12px 0;

        > div {
          &:first-of-type {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 54px;
          }

          &:last-of-type {
            max-width: calc(100% - 54px);
            line-height: 16px;
            color: ${colors.text};

            span {
              display: block;
              color: ${colors.textLight};
            }
          }
        }
      }
    }
    }
  }

  .styled-upload-file-footer {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(250, 250, 250, 0) 0%,
      rgba(250, 250, 250, 1) 70%,
      rgba(250, 250, 250, 1) 100%
    );
    padding-top: 100px;

    > div {
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 20px;
      color: ${colors.textDanger};
    }
  }
`
type FileIconContainerProps = { isInvalid: boolean, hasExtension: boolean, ... }
export const FileIconContainer: StyledComponent<FileIconContainerProps, *, HTMLElement> =
  styled.div`
    position: relative;
    padding-top: 2px;
    height: 32px;
    width: 32px;

    path {
      color: ${colors.stroke};
      fill: ${colors.stroke};
    }

    ${(props: FileIconContainerProps) =>
      props.hasExtension &&
      css`
        > span {
          position: absolute;
          top: 16px;
          left: 1px;
          display: block;
          padding: 1px 2px;
          color: white;
          font-weight: bold;
          font-size: 8px;
          line-height: 10px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          background: ${(props: any) =>
            props.isInvalid
              ? schemes.red['60']
              : 'linear-gradient(178.2deg, #848d98 2.42%, #717a85 97.5%)'};
          border-radius: 3px;
        }
      `}
  `
