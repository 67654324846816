// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

type PlanAreaProps = { trial?: boolean, withTrial?: boolean, ... }
export const PlanArea: StyledComponent<PlanAreaProps, { ... }, HTMLDivElement> = styled.div`
  font-size: ${(props: PlanAreaProps) => (props.trial ? 14 : 17)}px;
  background: ${(props: PlanAreaProps) => (props.trial ? '#FAFAFA' : '#ffffff')};
  border-radius: ${(props: PlanAreaProps) =>
    props.trial ? '0 0 8px 8px' : props.withTrial ? '8px 8px 0 0' : '8px'};
  padding: 25px;
  border: 1px solid #e4e4e4;
  ${(props: PlanAreaProps) =>
    props.trial &&
    css`
      border-top: none;
      padding: 15px 25px;
    `}
  display: flex;
`
export const PlanSection: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  flex: 1 0 100px;
`
export const PlanPriceSection: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  flex: 0 0 80px;
  text-align: right;
`
export const PlanTrialUntil: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  flex: 1 0 90px;
  text-align: right;
`
export const PlanName: StyledComponent<{ ... }, *, HTMLHeadingElement> = styled.h3`
  font-size: 1em;
  font-weight: 600;
  color: #34404f;
`

export const PlanPrice: StyledComponent<{ ... }, *, HTMLHeadingElement> = styled.h4`
  font-size: 18px;
  font-weight: 200;
`
export const PlanFrequency: StyledComponent<{ ... }, *, HTMLSpanElement> = styled.span`
  font-size: 1em;
`
export const PlanSeats: StyledComponent<{ ... }, *, HTMLParagraphElement> = styled.p`
  padding-top: 4px;
`

export const InvoiceTable: StyledComponent<{ ... }, *, HTMLTableElement> = styled.table`
  width: 100%;
  color: #28303a;

  td,
  th {
    padding: 10px 5px;
  }

  th {
    font-weight: bold;

    &.tdStatus {
      text-align: center;
    }
  }

  tbody td {
    border-bottom: 1px solid #f1f1f1;
    font-size: 12px;
    color: #949494;

    &.tdNumber {
      color: #949494;
      font-size: 12px;
      text-align: right;
      font-family: 'Fira Code', 'Menlo', 'Segoe UI mono', monospace;
    }

    &.tdStatus {
      text-align: center;
    }
  }
`
export const PlanList: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  margin: 20px 0 8px 0;
`

export const PlanButtonDesc: StyledComponent<{ ... }, *, HTMLSpanElement> = styled.span`
  flex: 1 0 150px;
  color: #838384;

  strong {
    font-weight: 600;
    font-size: 1.1em;
    padding-bottom: 0.2em;
    color: #2e3336;
    display: block;
  }
`
export const PlanButtonLabel: StyledComponent<{ ... }, *, HTMLSpanElement> = styled.span`
  background: #f0f0f0;
  color: #747474;
  font-size: 10px;
  text-transform: uppercase;
  padding: 4px 5px;
  border-radius: 2px;
  margin-left: 5px;
`

export const PlanButtonPrice: StyledComponent<{ ... }, *, HTMLSpanElement> = styled.span`
  flex: 0 0 70px;
  text-align: right;
  color: #9e9e9e;

  strong {
    font-size: 1.3em;
    color: #474c4e;
    display: block;
    font-weight: 200;
  }
`
type PlanButtonProps = { current?: boolean, selected?: boolean | null, ... }
export const PlanButton: StyledComponent<PlanButtonProps, *, HTMLButtonElement> = styled.button`
  outline: none;
  background: #fff;
  text-align: left;
  padding: 20px;
  display: flex;
  width: 100%;
  border: 1px solid #ededed;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &:not(:last-child) {
    border-bottom-color: #fff;
  }

  ${(props: PlanButtonProps) =>
    props.current
      ? css`
          cursor: default;
        `
      : css`
          &:hover {
            background: #f9f9f9;
          }
        `}

  ${(props: PlanButtonProps) =>
    props.selected &&
    css`
      background: #f5fcff;
      border: 1px solid ${colors.strokeAction} !important;
    `}
`

export const StripeStyle = {
  base: {
    color: '#323639',
  },
}
type CardContainerProps = { brand: string, ... }
export const CardContainer: StyledComponent<{ brand: string, ... }, *, HTMLDivElement> = styled.div`
  background: #f5f5f5;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  padding: 9px 1em 9px 40px;
  color: #79808a;
  display: flex;

  code {
    background: transparent;
    color: #79808a;
    flex-grow: 1;
  }

  span {
    flex: 0 0 40px;
    text-align: right;
  }

  ${(props: CardContainerProps) =>
    (props.brand === 'visa' || props.brand === 'mastercard') &&
    css`
      background-image: url(/medias/img/${(props: CardContainerProps) => props.brand}.png);
      background-repeat: no-repeat;
      background-size: 32px 20px;
      background-position: 10px 9px;
    `}
`

export const StripeCardContainer: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 3px;

  &.StripeElement--focus {
    border: 1px solid ${colors.strokeAction};
    box-shadow: 0 0 0 1px ${colors.strokeAction};
  }

  padding: 11px 10px 10px 10px;
`
export const CardButtonContainer: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 0 0;
`
export const CardButton: StyledComponent<{ ... }, *, HTMLButtonElement> = styled.button`
  display: inline-flex;
  align-items: center;
  color: ${colors.textLight};
  border: none;
  background: none;
`

export const CartLinePrice: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  flex: 0 0 110px;
  text-align: right;
`
type CartLineProps = { total?: boolean, ... }
export const CartLine: StyledComponent<CartLineProps, *, HTMLDivElement> = styled.div`
  border-bottom: 1px solid #f5f5f9;
  padding: 15px 25px;
  display: flex;
  align-items: center;

  label {
    flex-grow: 1;
  }

  ${(props: CartLineProps) =>
    props.total &&
    css`
      background-color: #fcfcfd;
      padding: 25px 25px;
      border-bottom: none;

      label {
        color: #535353;
        font-size: 24px;

        p {
          font-size: 12px;
        }
      }

      ${CartLinePrice} {
        font-size: 24px;
      }
    `}
`
