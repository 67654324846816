// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { type CommonSelectProps } from 'components/form/fields/select/select.helper'

import { FilterSelectMultiNoSearch } from './filter-select-multi-no-search'
import { FilterSelectMultiSearch } from './filter-select-multi-search'

export type BaseFilterMultiProps<T> = {
  ...CommonSelectProps<T>,
  onChange: (List<T>) => void,
  options: List<T>,
  placeholder?: string,
  style?: { [string]: string | number, ... },
  toggleButtonWidth?: string | number,
  term?: number => string,
  value: List<T>,
}
export type FilterSelectMultiProps<T> = {
  ...BaseFilterMultiProps<T>,
  isSearchable?: boolean,
  searchMatchFunction?: (string, T) => boolean,
}

export function FilterSelectMulti<T>({
  isSearchable = true,
  ...rest
}: FilterSelectMultiProps<T>): React.Node {
  return isSearchable ? (
    <FilterSelectMultiSearch {...rest} />
  ) : (
    <FilterSelectMultiNoSearch {...rest} />
  )
}
