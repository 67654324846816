// @flow

import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { FlexLineItem } from 'components/common/flexline'
import Loader from 'components/common/loader-legacy'
import { trackEvent } from 'components/common/page-tracker'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Checkbox } from 'components/form'

import { kformat } from 'com.batch.common/utils'

import ReplicateReview from './replicate-review'
import {
  AppScrollableContainer,
  PickableApp,
  PickableAppCheck,
  PickableAppEstimate,
  PickableAppIcon,
  PickableAppName,
  PickableAppPlatform,
  ReplicateSearch,
} from './replicate.styles'

import { currentAppSelector, replicableAppsSelector } from 'com.batch.redux/app'
import { fetchApps } from 'com.batch.redux/app.action'
import { actions } from 'com.batch.redux/campaign'
import {
  campaignReplicationSelector,
  campaignRequireThemeSelector,
  currentCampaign,
} from 'com.batch.redux/campaign.selector'
import { estimateForCampaign } from 'com.batch.redux/stat.api'
import { FetchThemes, LinkTheme } from 'com.batch.redux/theme'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'
import { InAppVariantsThemeSelector } from 'com.batch.redux/theme.selector'

import { STATUS } from 'constants/common'

type ReplicateConnectedProps = {
  opened: boolean,
  close: () => void,
  ...
}

export const ReplicateConnected: React.ComponentType<ReplicateConnectedProps> =
  React.memo<ReplicateConnectedProps>(({ opened, close }: ReplicateConnectedProps) => {
    const app = useSelector(currentAppSelector)
    // =================================================== STATE
    const [selected, setSelected] = React.useState(Immutable.Set([app.id]))
    const [estimates, setEstimates] = React.useState(Immutable.Map())
    const [term, setTerm] = React.useState('')

    // =================================================== REDUX
    const dispatch = useDispatch()
    const campaign = useSelector(currentCampaign)
    const results = useSelector(campaignReplicationSelector)
    const variantsTheme = useSelector(InAppVariantsThemeSelector)
    const replicableApps = useSelector(replicableAppsSelector)
    const targeting: Map<string, any> = useSelector(state => state.targeting)
    const themeLoadingState = useSelector(state => state.theme.loadingState)
    const appsState = useSelector(state => state.app)
    const campaignRequireTheme = useSelector(campaignRequireThemeSelector)

    const appsLoaded = appsState.loaded
    const appsLoading = appsState.loading

    const apps = React.useMemo(
      () =>
        replicableApps.filter(app => {
          return !term ? true : app.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
        }),
      [replicableApps, term]
    )

    const count = React.useMemo(() => selected.size, [selected])
    const reviewing = React.useMemo(() => results.size > 0, [results])

    const toggleApp = React.useCallback(
      (appId: number) => {
        if (selected.includes(appId)) {
          setSelected(selected.remove(appId))
        } else {
          setSelected(selected.add(appId))
          if (estimates.get(appId, 'NA') === 'NA') {
            setEstimates(estimates.set(appId, 'loading'))
            estimateForCampaign({
              appId,
              campaign: campaign,
              clusters: targeting.get('clusters', new Immutable.List()).toJS(),
              targeting: targeting.get('query', null),
              languages: targeting.get('languages', new Immutable.List()).toJS(),
              regions: targeting.get('regions', new Immutable.List()).toJS(),
              requiredMlvl: 0,
            })
              .then(
                response => {
                  setEstimates(estimates.set(appId, response.body.results.matching))
                },
                () => {
                  setEstimates(estimates.set(appId, 'error'))
                }
              )
              .catch(() => setEstimates(estimates.set(appId, 'error')))
          }
        }
      },
      [campaign, estimates, selected, targeting]
    )

    const clearAndClose = React.useCallback(() => {
      dispatch(actions.clearReplicateResult())
      setSelected(Immutable.Set([app.id]))
      close()
    }, [app.id, close, dispatch])

    const confirmReplicateWithTheme = React.useCallback(
      (theme: AbTestedThemeRecord) => {
        const needToLinkThemeTo =
          theme.get('a')?.id === 'new'
            ? Immutable.Set()
            : selected.subtract([...(theme.get('a')?.apps ?? []), ...(theme.get('b')?.apps ?? [])])

        if (needToLinkThemeTo.size > 0) {
          return confirm({
            title: 'Confirm theme attachment',
            message: (
              <p>
                By replicating your campaign, you are about to attach the theme of the campaign{' '}
                <code>{theme.get('a')?.name}</code> {theme.get('b')?.name ?? ''} to{' '}
                {needToLinkThemeTo.size} app
                {needToLinkThemeTo.size > 1 && 's'}
              </p>
            ),
          }).then(
            () => {
              Promise.all(
                needToLinkThemeTo.toArray().map(appId => {
                  trackEvent('THEME_ATTACHED', { attach_source: 'campaign_replicate', count: 1 })

                  const aTheme = theme.get('a')
                  if (aTheme) dispatch(LinkTheme(aTheme, true, appId))

                  const bTheme = theme.get('b')
                  if (bTheme) dispatch(LinkTheme(bTheme, true, appId))
                })
              ).then(
                () => {
                  dispatch(actions.replicate(selected.toArray()))
                },
                () => {}
              )
            },
            () => {}
          )
        } else {
          return dispatch(actions.replicate(selected.toArray()))
        }
      },
      [dispatch, selected]
    )

    const confirmReplicate = React.useCallback(() => {
      return dispatch(actions.replicate(selected.toArray()))
    }, [dispatch, selected])

    React.useEffect(() => {
      // on ne trigger le load des apps que lors du premier affichage
      if (opened && !appsLoading && !appsLoaded && app.companyId) {
        dispatch(fetchApps(app.companyId))
        if (themeLoadingState === STATUS.INIT) {
          // we need the loaded themes for campaign serialization
          dispatch(FetchThemes())
        }
      }
    }, [app.companyId, opened, appsLoading, appsLoaded, dispatch, themeLoadingState])
    const onQueryChange = React.useCallback(evt => setTerm(evt.target.value), [setTerm])
    const onAppToggled = React.useCallback((appId: number) => () => toggleApp(appId), [toggleApp])
    const onReplicate = React.useCallback(() => {
      if (campaignRequireTheme && (variantsTheme.get('a') || variantsTheme.get('b')))
        confirmReplicateWithTheme(variantsTheme)
      else confirmReplicate()
    }, [campaignRequireTheme, confirmReplicate, confirmReplicateWithTheme, variantsTheme])

    const isReplicating = React.useMemo(() => {
      return results.reduce((acc, current) => current.loading || acc, false)
    }, [results])

    const closeUnlessInProgress = React.useCallback(() => {
      if (!isReplicating) clearAndClose()
    }, [isReplicating, clearAndClose])

    return (
      <Popin close={closeUnlessInProgress} opened={opened}>
        <Box style={{ width: '640px' }}>
          <BoxHeader>
            <HeaderBoxTitle title={'Replicate this campaign on the following apps'} />
            <HeaderBoxActions>
              <Button onClick={close}>
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody>
            {reviewing && <ReplicateReview results={results} />}
            {!reviewing && (
              <ReplicateSearch>
                <FlexLineItem width={30}>
                  <Icon icon="search" size={12} />
                </FlexLineItem>
                <FlexLineItem grow={1}>
                  <input type="text" placeholder="Search by name" onChange={onQueryChange} />
                </FlexLineItem>
              </ReplicateSearch>
            )}
            {!reviewing && (
              <AppScrollableContainer>
                <Loader
                  overlay
                  loading={app.loadingState === STATUS.LOADING || app.loadingState === STATUS.INIT}
                >
                  {apps.map(app => {
                    const estimate = estimates.get(app.id, 'NA')
                    return (
                      <PickableApp key={app.id} pickable>
                        <PickableAppCheck>
                          <Checkbox
                            onChange={onAppToggled(app.id)}
                            checked={selected.includes(app.id)}
                            label=""
                          />
                        </PickableAppCheck>
                        <PickableAppIcon icon={app.icon} platform={app.platform}>
                          <Icon
                            icon={app.platform !== 'webpush' ? 'mobile' : 'computer'}
                            size={12}
                          />
                        </PickableAppIcon>
                        <PickableAppPlatform>
                          {app.platform && <Icon icon={app.platform} size={9} />}
                        </PickableAppPlatform>
                        <PickableAppName>{app.name}</PickableAppName>
                        <PickableAppEstimate>
                          {estimate === 'NA' ? (
                            ''
                          ) : estimate === 'error' ? (
                            'Error fetching estimate'
                          ) : estimate === 'loading' ? (
                            <Loader loading size="tiny" right />
                          ) : (
                            `${kformat(estimate)} tokens`
                          )}
                        </PickableAppEstimate>
                      </PickableApp>
                    )
                  })}
                </Loader>
              </AppScrollableContainer>
            )}
          </BoxBody>
          <BoxFooter isEditable>
            <Button kind="inline" onClick={closeUnlessInProgress}>
              Cancel
            </Button>
            <FooterBoxActions>
              <Button intent="action" kind="primary" onClick={onReplicate} disabled={isReplicating}>
                {count === 0
                  ? 'Pick at least one app'
                  : `Replicate on ${count > 1 ? `these apps (${count})` : 'this app'}`}
              </Button>
            </FooterBoxActions>
          </BoxFooter>
        </Box>
      </Popin>
    )
  })
