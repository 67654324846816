// @flow

import Immutable, { type List, type Map } from 'immutable'
import * as React from 'react'

import { useIsCurrentUserAllowedTo, useToggle } from 'components/_hooks'
import { BoxBody, BoxFooter, FooterBoxActions } from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { LinkDoc } from 'components/styled/text'

import {
  GCMConfigFactory,
  type GCMConfigRecord,
  type PushConfigRecord,
} from 'com.batch.redux/_records'
import { type MessageType, showToast } from 'com.batch.redux/toaster'

import { AddBlock } from 'com.batch/settings/ui/components/settings-fcm/add-block/add-block'
import { LegacyConfigGrid } from 'com.batch/settings/ui/components/settings-fcm/config-grid/legacy-config-grid'
import { InitialNotice } from 'com.batch/settings/ui/components/settings-fcm/notice/initial-notice'

type SettingsAndroidFCMProps = {
  config: PushConfigRecord,
  savePushConfig: (config: PushConfigRecord, file: ?File, password: ?string) => Promise<void>,
  showToast: MessageType => typeof showToast,
  ...
}

export const SettingsAndroidFcmLegacy = ({
  config,
  savePushConfig,
  showToast,
}: SettingsAndroidFCMProps): React.Node => {
  // ====================== LOCAL STATE
  const [configs, setConfigs] = React.useState<List<GCMConfigRecord>>(
    config.gcm.size === 0 ? new Immutable.List().push(GCMConfigFactory()) : config.gcm
  )
  const manualModeState = useToggle(false)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())

  // ====================== DERIVED
  const noConfig = config.gcm.size === 0
  // ====================== CALLBACKS
  const clearErrors = React.useCallback(() => setErrors(Immutable.Map()), [setErrors])
  const setAuto = React.useCallback(() => {
    manualModeState.close()
    clearErrors()
    setConfigs(config.gcm)
  }, [manualModeState, clearErrors, config.gcm])

  const save = React.useCallback(() => {
    savePushConfig(config.set('gcm', configs)).then(() => {
      manualModeState.close()
      showToast({ kind: 'success', message: 'Push configuration saved' })
    })
  }, [config, configs, manualModeState, savePushConfig, showToast])

  const confirmManual = React.useCallback(() => {
    confirm({
      width: 490,
      message: (
        <article>
          <p>
            Push tokens are associated to your FCM Sender ID/Server API Key pair. If you change
            these, you won’t be able to push previously collected push tokens.
          </p>
        </article>
      ),
      title: 'Confirm push configuration change',
    }).then(manualModeState.open, () => {})
  }, [manualModeState.open])

  const validateAndSave = React.useCallback(() => {
    let err = []
    configs.map((conf, index) => {
      if (conf.serverApiKey.length < 12)
        err.push([`serverApiKey_${index}`, 'Please provide a valid Server API Key'])
      if (conf.senderId.length < 8)
        err.push([`senderId_${index}`, 'Please provide a valid Sender ID'])
    })
    if (err.length === 0) {
      configs.forEach((value, index) => {
        const match: boolean = !!configs
          .delete(index)
          .find(v => v.senderId === value.senderId && value.senderId, false)
        if (match) {
          err.push([`senderId_${index}`, 'This sender ID is already in use.'])
        }
      })
    }
    if (err.length === 0) {
      save()
      setErrors(Immutable.Map())
    } else {
      setErrors(Immutable.Map(err))
    }
  }, [configs, save])
  const isAllowedToChangeFCMConfig = useIsCurrentUserAllowedTo(['app', 'push:config:write'])

  const onSenderIdChange = React.useCallback(
    index => evt => {
      setConfigs(configs.setIn([index, 'senderId'], evt.target.value.trim()))
    },
    [configs]
  )
  const onServerApiKeyChange = React.useCallback(
    index => evt => {
      setConfigs(configs.setIn([index, 'serverApiKey'], evt.target.value.trim()))
    },
    [configs]
  )
  const onRemoveConfig = React.useCallback(
    (index: number) => () => {
      clearErrors()
      setConfigs(configs.delete(index))
    },
    [clearErrors, configs]
  )
  const onCreateNewConfig = React.useCallback(
    () => setConfigs(configs.push(GCMConfigFactory())),
    [configs]
  )
  return (
    <React.Fragment>
      <BoxBody>
        <InitialNotice />
        {noConfig && !manualModeState.value ? (
          <AddBlock onClick={manualModeState.open} isAllowed={isAllowedToChangeFCMConfig} />
        ) : (
          <Grid
            alignItems="start"
            template="minMax(100px, 1fr) 34px minMax(100px, 2fr)"
            style={{ margin: '0 20px 4px 20px' }}
          >
            {configs.map((conf, index) => (
              <LegacyConfigGrid
                conf={conf}
                index={index}
                key={index}
                errors={errors}
                edit={manualModeState.value}
                onRemoveConfig={onRemoveConfig}
                onServerApiKeyChange={onServerApiKeyChange}
                onSenderIdChange={onSenderIdChange}
              />
            ))}
          </Grid>
        )}
        {manualModeState.value && (
          <Button
            kind="inline"
            addOn="prefix"
            style={{ margin: '-5px 0 20px 26px', height: 28 }}
            onClick={onCreateNewConfig}
          >
            <Icon icon="add" />
            Add another configuration
          </Button>
        )}
      </BoxBody>

      <BoxFooter isEditable={manualModeState.value}>
        {manualModeState.value && (
          <Button kind="inline" onClick={setAuto}>
            Cancel
          </Button>
        )}
        <FooterBoxActions>
          <LinkDoc
            intent="action"
            href="https://doc.batch.com/android/prerequisites.html"
            target="_blank"
            style={{ marginRight: 8 }}
          >
            Documentation
          </LinkDoc>
          {config.gcm.size > 0 && !manualModeState.value && (
            <PermissionButton
              intent="action"
              kind="primary"
              onClick={confirmManual}
              isAllowed={isAllowedToChangeFCMConfig}
            >
              Update FCM config
            </PermissionButton>
          )}
          {manualModeState.value && (
            <Button type="button" kind="primary" intent="action" onClick={validateAndSave}>
              Save FCM config
            </Button>
          )}
        </FooterBoxActions>
      </BoxFooter>
    </React.Fragment>
  )
}
