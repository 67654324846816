// @flow

type Props = {
  projectKey: {
    textual: {
      text: string,
    },
  },
}
export const generateProjectKeyBlockRequest = (projectKey: string): Props => {
  return {
    projectKey: {
      textual: {
        text: projectKey,
      },
    },
  }
}
