// @flow
import { type Map } from 'immutable'

import { type State } from 'com.batch.redux/_records'
import {
  type AbTestedInAppRecord,
  type AbTestedPushRecord,
  type ContentStateRecord,
  type PushSettingsRecord,
} from 'com.batch.redux/content.records'

import {
  type SmsMessageRecord,
  type EmailMessageRecord,
} from 'com.batch/message/models/message.records'

export const pushSelector = (state: State): Map<string, AbTestedPushRecord> => state.content.push
export const smsSelector = (state: State): Map<string, SmsMessageRecord> => state.message.sms
export const inappSelector = (state: State): Map<string, AbTestedInAppRecord> => state.content.inapp
export const contentStateSelector = (state: State): ContentStateRecord => state.content
export const emailSelector = (state: State): Map<string, EmailMessageRecord> => state.message.email
export const pushSettingsSelector = (state: State): PushSettingsRecord => state.content.pushSettings
export const campaignNameSelector = (state: State): string => state.content.campaignName
