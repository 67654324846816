// @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { Icon } from 'components/common/svg-icon'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { Phrase, Value, ValueLink, Operator, Verb, Func, EventLabel } from './rsr-condition.styles'

import { CustomAudience } from '../custom-audience'
import { Item } from '../rsr.styles'
import { geoipCityNameGetterSelector } from 'com.batch.redux/attribute.selector'
import {
  type QbConditionRecord,
  type QbFuncRecord,
  type QbOperatorRecord,
} from 'com.batch.redux/targeting.records'

type RsrConditionProps = {
  condition: QbConditionRecord,
  getCampaignName: (token: string) => string,
  last?: boolean,
  ...
}

const ageRegex = /(\d+)([dhm])/
const units = {
  d: 'day',
  h: 'hour',
  m: 'month',
}

const ValueRenderer = ({
  value,
  suffix = '',
  isGeoCity,
}: {
  value: any,
  suffix?: string,
  isGeoCity?: boolean,
  ...
}) => {
  const getGeoName = useSelector(geoipCityNameGetterSelector)
  if (value === null) {
    return null
  }
  if (dayjs.isDayjs(value)) {
    return value.format('DD/MM/YYYY HH:mm')
  }
  if (Immutable.Set.isSet(value)) {
    return value.map(audience => (
      <CustomAudience audience={audience} last={audience === value.last()} key={audience.id} />
    ))
  }
  if (Array.isArray(value) || Immutable.List.isList(value)) {
    const count = Immutable.List.isList(value) ? value.count() : value.length
    return value.map((item, key) => {
      return (
        <React.Fragment key={key}>
          <Value comma={key !== count - 1}>
            {isGeoCity ? getGeoName(item.value) : item.pretty}
          </Value>
          {/* {key !== count - 1 ? ', ' : ''} */}
        </React.Fragment>
      )
    })
  }
  if (typeof value === 'object') {
    if (typeof value.pretty === 'boolean') {
      return <Value>{value.pretty ? 'true' : 'false'}</Value>
    } else {
      return <Value>{value.pretty}</Value>
    }
  }
  if (typeof value === 'string' && value.match(ageRegex)) {
    // $FlowFixMe yeah yeah
    const [, duration, unitKey] = ageRegex.exec(value)
    const unit = units[unitKey]
    return (
      <Value>{`${value.substring(0, 1) === '-' ? '-' : ''}${duration} ${unit}${
        parseInt(duration) > 1 ? 's' : ''
      } ${suffix}`}</Value>
    )
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return <Value>{`${value} ${suffix}`}</Value>
  }
  if (typeof value === 'boolean') {
    return <Value>{value ? 'true' : 'false'}</Value>
  }
  if (typeof value === 'number') {
    return <Value>{value.toString()}</Value>
  }
  return value
}

const operatorPhraseForDate = (ope: QbOperatorRecord) => {
  switch (ope.value) {
    case '>':
      return 'more than'
    case '≥':
      return 'more than or exactly'
    case '<':
      return 'less than'
    case '≤':
      return 'less than or exactly'
    case '=':
      return ''
    default:
      return ope.value
  }
}
const operatorPhrase = (ope: QbOperatorRecord, value: any) => {
  switch (ope.value) {
    case '>':
      return 'is greater than'
    case '≥':
      return 'is greater than or equals to'
    case '<':
      return 'is lower than'
    case '≤':
      return 'is lower than or equals to'
    case '=':
      return 'is'
    case 'IN':
      return 'is one of'
    case 'does not contain some of':
      return !!value && value.length === 1
        ? 'does not contain'
        : 'does not contain some of those tags:'
    case 'does not contain all':
      return !!value && value.length === 1
        ? 'does not contain'
        : 'does not contain any of those tags:'
    case 'contains any of':
      return !!value && value.length === 1 ? 'contains' : 'contains some of those tags:'
    case 'contains all of':
      return !!value && value.length === 1 ? 'contains' : 'contains all of those tags:'
    default:
      return ope.value
  }
}
const operatorPhraseForAudience = (ope: QbOperatorRecord, value: any) => {
  switch (ope.value) {
    case 'does not contain some of':
      return value.size === 1 ? 'is not in:' : 'is not in some of those audiences:'
    case 'does not contain all':
      return value.size === 1 ? 'is not in:' : 'is not in any of those audiences:'
    case 'contains any of':
      return value.size === 1 ? 'is in:' : 'is in some of those audiences:'
    case 'contains all of':
      return value.size === 1 ? 'is in:' : 'is in all of those audiences:'
    default:
      return ope.value
  }
}
const FuncRenderer = ({
  cond,
  func,
  getCampaignName,
}: {
  cond: QbConditionRecord,
  func: QbFuncRecord,
  getCampaignName: (token: string) => string,
  ...
}) => {
  const ope = cond.operator
  switch (func.value) {
    case 'nextBirthday':
      return (
        <React.Fragment>
          <Func>next anniversary</Func>
          <Verb>is in</Verb>
          <Operator>{operatorPhraseForDate(ope)}</Operator>
          <ValueRenderer value={cond.value} />
        </React.Fragment>
      )
    case 'lastBirthday':
      return (
        <React.Fragment>
          <Func>last anniversary</Func>
          <Verb>was</Verb>
          <Operator>{operatorPhraseForDate(ope)}</Operator>
          <ValueRenderer value={cond.value} suffix="ago" />
        </React.Fragment>
      )
    case 'ageLastEvent':
      return (
        <React.Fragment>
          <Func>last occurrence</Func>
          <Verb>was</Verb>
          <Operator>{operatorPhraseForDate(ope)}</Operator>
          <ValueRenderer value={cond.value} suffix="ago" />
        </React.Fragment>
      )
    case 'count':
      return (
        <React.Fragment>
          <Func>occurred</Func>
          <Operator>{operatorPhraseForDate(ope)}</Operator>
          <ValueRenderer value={cond.value} suffix="times" />
        </React.Fragment>
      )
    case 'countSince':
      return (
        <React.Fragment>
          <Func>occurred</Func>
          <Operator>{operatorPhraseForDate(ope)}</Operator>
          <ValueRenderer value={cond.value} suffix="times" />
          <Verb>in the last</Verb>
          <ValueRenderer value={cond.args.get('__PERIOD__', '')} />
        </React.Fragment>
      )
    case 'isNear': {
      const positionArgument = cond.args.get('__POSITION__', Immutable.Map({ lat: 0, lng: 0 }))
      const pos = Immutable.isImmutable(positionArgument) // $FlowExpectedError legacy file quickly and badly typed
        ? positionArgument.toJS()
        : positionArgument
      return (
        <React.Fragment>
          <Verb>was tracked in a</Verb>
          {/* $FlowExpectedError legacy file quickly and badly typed*/}
          <ValueRenderer value={`${cond.args.get('__RADIUS__', 500)}`} suffix="m" />
          <Verb>radius around</Verb>
          <ValueLink
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${
              /* $FlowExpectedError legacy file quickly and badly typed*/
              pos.lat
            },${
              /* $FlowExpectedError legacy file quickly and badly typed*/
              pos.lng
            }`}
          >
            {/* $FlowExpectedError legacy file quickly and badly typed*/}
            {pos.lat.toFixed(3)}, {pos.lng.toFixed(3)}{' '}
            <Icon icon="external" size={9} style={{ margin: '-1px 3px 0 5px' }} color="#7F8691" />
          </ValueLink>
        </React.Fragment>
      )
    }
    case 'age':
      return (
        <React.Fragment>
          <Verb>was</Verb>
          <Operator>{operatorPhraseForDate(ope)}</Operator>
          <ValueRenderer value={cond.value} suffix="ago" />
        </React.Fragment>
      )
    case 'lastCampaign':
      return (
        <a
          href={`../${
            /* $FlowExpectedError legacy file quickly and badly typed*/
            cond.args.get('__TOKEN__', '')
          }/review`}
        >
          {
            /* $FlowExpectedError legacy file quickly and badly typed*/
            getCampaignName(cond.args.get('__TOKEN__', ''))
          }
        </a>
      )
    default:
      return (
        <React.Fragment>
          <Verb>{func.value === 'countForTag' ? 'number of tags' : func.value}</Verb>
          <Verb>{operatorPhrase(cond.operator)}</Verb>
          <ValueRenderer value={cond.value} />
        </React.Fragment>
      )
  }
}
const RsrCondition = ({
  condition,
  getCampaignName,
  last = false,
}: RsrConditionProps): React.Node => {
  let filterKey = ''
  let filterValue = condition.args.get('__VALUE__', false)
  const label = condition.args.get('__LABEL__', false)
  const key = condition.args.get('__KEY__', false)
  if (key) {
    /* $FlowExpectedError legacy file quickly and badly typed*/
    const [filterName] = key.split('|||')
    filterKey = filterName
  } else if (label) {
    filterKey = 'label'
    filterValue = label
  }
  return (
    <Item
      icon={condition.attribute.icon}
      name={`${condition.negate ? 'NOT ' : ''}${condition.attribute.label}`}
      autoSize
      dotted={!last}
      inline
    >
      <Phrase>
        {!!filterKey && !!filterValue && (
          <EventLabel>
            {filterKey}: {filterValue}
          </EventLabel>
        )}
        {condition.func ? (
          <FuncRenderer func={condition.func} cond={condition} getCampaignName={getCampaignName} />
        ) : condition.operator.value === 'exists' ? (
          <Value>{condition.value ? 'exists' : 'does not exist'}</Value>
        ) : (
          <React.Fragment>
            {condition.attribute.id === 'bt.custom_audiences' ? (
              <Operator>{operatorPhraseForAudience(condition.operator, condition.value)}</Operator>
            ) : (
              <Operator>{operatorPhrase(condition.operator, condition.value)}</Operator>
            )}
            <ValueRenderer
              value={condition.value}
              isGeoCity={condition.attribute.id === 'b.city_code'}
            />
          </React.Fragment>
        )}
      </Phrase>
    </Item>
  )
}

export default RsrCondition
