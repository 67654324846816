// @flow

import * as Immutable from 'immutable'

import {
  ProfileAudienceStateFactory,
  type ProfileAudienceStateRecord,
} from './audience-profile.state'

import { type DeleteAudiencesActions } from 'com.batch/audience/usecases/delete-audience'
import {
  type FetchAudiencesActions,
  type SetPageAudienceAction,
} from 'com.batch/audience/usecases/fetch-audience-list'
import { type FetchAudiencesByNameActions } from 'com.batch/audience/usecases/fetch-audiences-by-name'
import { type FetchEstimateActions } from 'com.batch/audience/usecases/fetch-estimate-audience'
import { type SaveAudienceActions } from 'com.batch/audience/usecases/save-audience'
import { type FetchDeletedAudiencesActions } from 'com.batch/audience/usecases/set-deleted-audience'
import { type UpdateNameAudienceActions } from 'com.batch/audience/usecases/update-audience'
import { EstimateProfileFactory } from 'com.batch/orchestration/models/profile-estimate.records'

const initialState = ProfileAudienceStateFactory()

type Actions =
  | FetchAudiencesActions
  | DeleteAudiencesActions
  | SetPageAudienceAction
  | UpdateNameAudienceActions
  | SaveAudienceActions
  | FetchEstimateActions
  | FetchAudiencesByNameActions
  | FetchDeletedAudiencesActions

export function audienceProfileReducer(
  state: ProfileAudienceStateRecord = initialState,
  action: Actions
): ProfileAudienceStateRecord {
  switch (action.type) {
    case 'UPDATE_AUDIENCE_ESTIMATE': {
      return state.set(
        'estimatesForAudiences',
        state.estimatesForAudiences.set(
          action.payload.audienceName,
          EstimateProfileFactory({
            loading: 'LOADING',
          })
        )
      )
    }
    case 'SET_DELETED_AUDIENCES': {
      const updatedEntities = state.get('entities').merge(action.payload)
      return state.set('entities', updatedEntities)
    }

    case 'UPDATE_AUDIENCE_ESTIMATE_FAILURE': {
      return state.set(
        'estimatesForAudiences',
        state.estimatesForAudiences.set(
          action.payload.audienceName,
          EstimateProfileFactory({ loading: 'ERROR' })
        )
      )
    }
    case 'UPDATE_AUDIENCE_ESTIMATE_SUCCESS': {
      return state.set(
        'estimatesForAudiences',
        state.estimatesForAudiences.set(action.payload.audienceName, action.payload.estimate)
      )
    }
    case 'FETCH_AUDIENCE_LIST':
      return state.set('loadingState', 'LOADING')
    case 'SET_PAGE_AUDIENCE_LIST':
      return state.set('page', action.payload)
    case 'FETCH_AUDIENCE_LIST_SUCCESS': {
      return state
        .set('page', action.payload.page)
        .set('search', action.payload.search)
        .set('sortBy', action.payload.sortBy)
        .set('sortDirection', action.payload.sortDirection)
        .set('loadingState', 'LOADED')
        .set('count', action.payload.count)
        .set(
          'entities',
          state.entities.merge(Immutable.Map(action.payload.entities.map(aud => [aud.name, aud])))
        )
        .set(
          'idsPerPage',
          action.payload.trashCache
            ? Immutable.Map([[action.payload.page, action.payload.entities.map(aud => aud.name)]])
            : state.idsPerPage.set(
                action.payload.page,
                action.payload.entities.map(aud => aud.name)
              )
        )
    }
    case 'FETCH_AUDIENCES_BY_NAME_SUCCESS': {
      let entities = state.entities
      action.payload?.forEach(audience => {
        entities = entities.set(audience.name, audience)
      })
      return state.set('entities', entities)
    }
    case 'FETCH_AUDIENCE_LIST_FAILURE':
      return state.set('loadingState', 'ERROR')
    case 'UPDATE_AUDIENCE':
      return state.setIn(['entities', action.payload, 'loading'], 'LOADING')
    case 'UPDATE_AUDIENCE_SUCCESS':
      return state.set('entities', state.entities.set(action.payload.name, action.payload))
    case 'UPDATE_AUDIENCE_FAILURE':
      return state.setIn(['entities', action.payload, 'loading'], 'ERROR')
    case 'SAVE_AUDIENCE':
      return state.setIn(['entities', action.payload, 'loading'], 'LOADING')
    case 'SAVE_AUDIENCE_SUCCESS':
      return state.setIn(['entities', action.payload, 'loading'], 'LOADED')
    case 'SAVE_AUDIENCE_FAILURE':
      return state.setIn(['entities', action.payload.name, 'loading'], 'ERROR')
    case 'DELETE_AUDIENCE':
      return state.setIn(['entities', action.payload, 'loading'], 'LOADING')
    case 'DELETE_AUDIENCE_SUCCESS':
      return state
        .setIn(['entities', action.payload, 'loading'], 'LOADED')
        .set(
          'idsPerPage',
          state.idsPerPage.map(ids => ids.filter(id => id !== action.payload))
        )
        .set(
          'entities',
          state.entities.filter(aud => aud.name !== action.payload)
        )
    case 'DELETE_AUDIENCE_FAILURE':
      return state.setIn(['entities', action.payload.name, 'loading'], 'LOADED')
    default:
      return state
  }
}
