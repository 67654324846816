// @flow

import * as React from 'react'

import { useCurrentCompanyFilterActiveFeatures } from 'components/_hooks/use-company-feature'
import { CreateCampaignDropdownItem } from 'components/campaign/dropdowns/create-campaign-dropdown-item'
import { Dropdown, ButtonNavLink } from 'components/common/button'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import { HorizontalDivider } from 'components/styled/utils'

import { generateUrl } from 'com.batch.common/router/router'

import { type ProjectRecord } from 'com.batch.redux/project.records'

type Props = {
  project: ProjectRecord,
  isAutomation?: boolean,
  disabled?: boolean,
  testId?: string,
}
type LinkData = {
  name: string,
  url?: string,
  routeName: string,
  icon?: availableIcons,
  configurationFieldName?: 'anyPushConfigured' | 'smsConfigured' | 'emailConfigured',
  requiredFeature?: FeatureCode,
  isAutomation?: boolean,
  channelParam?: ChannelUntilCleanup,
  testId?: string,
}
const POSSIBLE_LINKS: Array<LinkData> = [
  {
    name: 'Omnichannel trigger',
    routeName: 'project_trigger_new',
    isAutomation: true,
    testId: 'dropdown_item_create_automation_omnichannel_trigger',
  },
  {
    name: 'Recurring Email',
    routeName: 'project_recurring_new',
    icon: 'mail',
    configurationFieldName: 'emailConfigured',
    requiredFeature: 'email-recurring',
    isAutomation: true,
    channelParam: 'email',
    testId: 'dropdown_item_create_automation_recurring_email',
  },
  {
    name: 'Recurring Push',
    routeName: 'project_recurring_new',
    icon: 'push',
    configurationFieldName: 'anyPushConfigured',
    requiredFeature: 'cep-push-recurring',
    isAutomation: true,
    channelParam: 'push',
    testId: 'dropdown_item_create_automation_recurring_push',
  },
  {
    name: 'Recurring SMS',
    routeName: 'project_recurring_new',
    icon: 'sms',
    configurationFieldName: 'smsConfigured',
    requiredFeature: 'cep-sms-recurring',
    isAutomation: true,
    channelParam: 'sms',
    testId: 'dropdown_item_create_automation_recurring_sms',
  },
  {
    name: 'Email message',
    routeName: 'project_campaign_new',
    icon: 'mail',
    configurationFieldName: 'emailConfigured',
    requiredFeature: 'email-campaign',
    isAutomation: false,
    channelParam: 'email',
    testId: 'dropdown_item_create_campaign_email',
  },
  {
    name: 'Push message',
    routeName: 'project_campaign_new',
    icon: 'push',
    configurationFieldName: 'anyPushConfigured',
    requiredFeature: 'cep-push-campaign',
    isAutomation: false,
    channelParam: 'push',
    testId: 'dropdown_item_create_campaign_push',
  },
  {
    name: 'SMS message',
    routeName: 'project_campaign_new',
    icon: 'sms',
    configurationFieldName: 'smsConfigured',
    requiredFeature: 'cep-sms-campaign',
    isAutomation: false,
    channelParam: 'sms',
    testId: 'dropdown_item_create_campaign_sms',
  },
]

const featuresToCheck = [
  'email-recurring',
  'cep-push-recurring',
  'cep-sms-recurring',
  'email-campaign',
  'cep-push-campaign',
  'cep-sms-campaign',
]

export const CreateOrchestrationButton = ({
  project,
  testId = '',
  disabled = false,
  isAutomation = false,
}: Props): React.Node => {
  const features = useCurrentCompanyFilterActiveFeatures(featuresToCheck)
  const links = React.useMemo(() => {
    return POSSIBLE_LINKS.filter(link => {
      if (link.isAutomation !== isAutomation) return false
      if (link.configurationFieldName && !project[link.configurationFieldName]) return false
      if (link.requiredFeature && !features.includes(link.requiredFeature)) return false
      return true
    }).map(link => {
      const params = link.channelParam
        ? {
            companyId: project.companyId,
            projectId: project.id,
            channel: link.channelParam,
          }
        : {
            companyId: project.companyId,
            projectId: project.id,
          }
      link.url = generateUrl(link.routeName, params)
      return link
    })
  }, [features, isAutomation, project])
  switch (links.length) {
    case 0:
      return null
    case 1:
      return <SingleLink link={links[0]} data-testid={testId} disabled={disabled} />
    default:
      return (
        <DropdownMenu
          links={links}
          data-testid={testId}
          disabled={disabled}
          isAutomation={isAutomation}
        />
      )
  }
}

const SingleLink = ({ link, ...rest }: { link: LinkData, ... }): React.Node => {
  return (
    <ButtonNavLink {...rest} to={link.url} intent="action" kind="primary">
      {link.name}
    </ButtonNavLink>
  )
}
const DropdownMenu = ({
  links,
  isAutomation,
  ...rest
}: {
  links: Array<LinkData>,
  isAutomation: boolean,
  disabled?: boolean,
  'data-testid'?: string,
}): React.Node => {
  return (
    <Dropdown
      label={isAutomation ? 'New automation' : 'New campaign'}
      buttonProps={{
        intent: 'action',
        kind: 'primary',
        ...rest,
      }}
      forcedWidth={180}
      placement="bottom-end"
      offset={[0, 5]}
    >
      {links.map((link, index) => (
        <React.Fragment key={index}>
          <CreateCampaignDropdownItem to={link?.url ?? ''} testId={link.testId}>
            {link.icon && <Icon icon={link.icon} />}
            {link.name}
          </CreateCampaignDropdownItem>
          {index === 0 && isAutomation && <HorizontalDivider margin="4px" />}
        </React.Fragment>
      ))}
    </Dropdown>
  )
}
