// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { AppAvatar as Avatar, AppAvatarContainer } from 'components/common/avatar'
import { colors, schemes } from 'components/styled/tokens'

import { INNER_PADDING } from './sidebar.styles'

import { type AppRecord } from 'com.batch.redux/_records'

export type SidebarAppProps = {
  app: AppRecord,
  current?: boolean,
  href?: string,
  ...
}

export const SidebarApp = (props: SidebarAppProps): React.Node => {
  const { app, current, ...rest } = props

  return (
    <Container {...rest} as={current ? 'div' : 'a'} title={app.name}>
      <Avatar
        placeholder={app.name.split(' ')[0]}
        platform={app.platform}
        url={app.icon}
        size={current ? 36 : 34}
      />
      <Name title={app.name}>{app.name}</Name>
    </Container>
  )
}

export const Name: StyledComponent<*, *, HTMLElement> = styled.span`
  display: block;
  font-size: 1em;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`

export const Container: StyledComponent<*, *, HTMLElement> = styled.a`
  display: flex;
  align-items: center;
  padding: ${INNER_PADDING - 3}px ${INNER_PADDING + 4}px ${INNER_PADDING - 3}px ${INNER_PADDING}px;
  overflow: hidden;
  border-radius: 4px;
  color: ${colors.textLight};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${colors.text};
    background: ${schemes.darklucent['10']};
  }
  &:active {
    background: ${schemes.darklucent['20']};
  }

  ${AppAvatarContainer} {
    flex: 0 0 auto;
    margin: 0 14px 0 0;
  }
  ${Name} {
    flex: 1 1 auto;
  }

  @media (max-width: 980px) {
    &,
    &:hover {
      background: none;
    }
  }
`

export default SidebarApp
