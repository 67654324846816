// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { BillingForm } from './billing-form'
import { Card } from './card'
import { Invoices } from './invoices'
import { PlanSection } from './plan-section'

import { AccountSection, SectionTitle, SectionInfo, SectionForm } from '../account.styles'
import { currentCompanySelector } from 'com.batch.redux/company'

export const BillingPageLegacy = (): React.Node => {
  const company = useSelector(currentCompanySelector)
  const billing = company.billing
  const planIsStripable = billing.plan.code !== 'enterprise' && billing.plan.code !== 'premier'
  const planIsManagedOuside =
    !planIsStripable ||
    (billing.plan.code.substring(0, 4) !== 'free' &&
      (billing.subscriptionStatus === 'none' || billing.subscriptionStatus === 'canceled'))
  return (
    <React.Fragment>
      <PlanSection company={company} planIsManagedOuside={planIsManagedOuside} />
      <React.Fragment>
        <AccountSection>
          <SectionInfo>
            <SectionTitle>Payment information</SectionTitle>
          </SectionInfo>
          <SectionForm>
            <Card company={company} elementId="stripe-card" />
          </SectionForm>
        </AccountSection>
        <AccountSection>
          <SectionInfo>
            <SectionTitle>Invoicing information</SectionTitle>
          </SectionInfo>
          <SectionForm>
            <BillingForm company={company} />
          </SectionForm>
        </AccountSection>
        <AccountSection>
          <SectionInfo>
            <SectionTitle id="invoice-history">Invoice history</SectionTitle>
          </SectionInfo>
          <SectionForm>
            <Invoices company={company} />
          </SectionForm>
        </AccountSection>
      </React.Fragment>
    </React.Fragment>
  )
}
