// @flow

import * as React from 'react'

type BlurValidationProps<T> = {
  value: T,
  validator: T => boolean,
  children: ({ onBlur: () => void, invalid: boolean, value: T, ... }) => React.Node,
  ...
}

export function BlurValidation<T>({
  children,
  validator,
  value,
  ...rest
}: BlurValidationProps<T>): React.Node {
  // const [touched, setTouched] = React.useState(false)
  const [invalid, setInvalid] = React.useState(false)
  const onBlur = React.useCallback(() => setInvalid(!validator(value)), [validator, value])

  return children({ ...rest, onBlur, invalid, value })
}
