// @flow

import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  HeaderBoxActions,
  BoxBody,
  BoxFooter,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper, Input } from 'components/form'

import { formatCode } from 'com.batch/labels/utils/format-code'

type LabelPopinProps = {
  close: () => void,
  onSubmit: (event: SyntheticEvent<HTMLFormElement>, labelName: string, labelCode: string) => void,
  ...
}

export const LabelPopin = ({ close, onSubmit }: LabelPopinProps): React.Node => {
  const [labelName, setLabelName] = React.useState('')
  const [labelCode, setLabelCode] = React.useState('')

  const onNameChange = React.useCallback(event => setLabelName(event.target.value), [])
  const onCodeChange = React.useCallback(event => setLabelCode(formatCode(event.target.value)), [])

  const onPressSubmit = React.useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      onSubmit(event, labelName, labelCode)
    },
    [onSubmit, labelName, labelCode]
  )

  return (
    <Box as="form" onSubmit={onPressSubmit} noBorder>
      <ThemeProvider theme={{ isEmpty: false }}>
        <BoxHeader>
          <HeaderBoxTitle title="New label" />
          <HeaderBoxActions>
            <Button kind="inline" onClick={close} type="button">
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>

        <BoxBody $padding>
          <InputWrapper label="Label name" htmlFor="label-name">
            <Input
              id="label-name"
              value={labelName}
              placeholder="Add label name..."
              onChange={onNameChange}
            />
          </InputWrapper>
          <InputWrapper label="Label code" htmlFor="label-code">
            <Input
              id="label-code"
              value={labelCode}
              placeholder="Add label code (optional) ..."
              onChange={onCodeChange}
            />
          </InputWrapper>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={close} type="button">
            Cancel
          </Button>
          <Button
            type="submit"
            kind="primary"
            intent="action"
            disabled={!labelName || labelName.length < 2}
          >
            Add label
          </Button>
        </BoxFooter>
      </ThemeProvider>
    </Box>
  )
}
