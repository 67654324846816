// @flow

import * as React from 'react'
import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { LoadingCellAnim } from 'components/common/empty-states'
import { TableTemplateCell } from 'components/common/table/index'

const randomSize = (minSize: number, maxSize: number) =>
  Math.floor(Math.random() * (maxSize - minSize)) + minSize
// empty template for metrics items
export const AnalyticsMetricsEmptyTemplate = (): React.Node => {
  return (
    <div style={{ margin: '37px 32px' }}>
      <MetricLabelEmpty size={React.useMemo(() => randomSize(44, 68), [])}>0</MetricLabelEmpty>
      <MetricValueEmpty size={React.useMemo(() => randomSize(58, 78), [])}>0</MetricValueEmpty>
      <MetricIndicatorEmpty size={React.useMemo(() => randomSize(44, 68), [])}>
        –
      </MetricIndicatorEmpty>
    </div>
  )
}

type ThemeProps = { isLoading?: boolean, ... }
type MetricItemEmptyProps = { size?: number, loading?: boolean, ... }
type ThemedMetricItemEmptyProps = PropsWithTheme<MetricItemEmptyProps, ThemeProps>

const MetricItemEmpty: StyledComponent<MetricItemEmptyProps, ThemeProps, HTMLElement> =
  styled.label`
  display: flex;
  justify-content: center;
  font-size: 11px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background-color: #b8b8b8;
    border-radius: 2px;
    min-width: ${(props: ThemedMetricItemEmptyProps) => props.size}px;
    height: 13px;

     ${(props: ThemedMetricItemEmptyProps) =>
       props.theme.isLoading &&
       css`
         animation-name: ${LoadingCellAnim};
         animation-duration: 2s;
         animation-iteration-count: infinite;
       `}
    }
  }
`

const MetricValueEmpty: StyledComponent<MetricItemEmptyProps, *, typeof MetricItemEmpty> = styled(
  MetricItemEmpty
)`
  margin-bottom: 20px;

  &::before {
    height: 21px;
    }
  }
`

const MetricLabelEmpty: StyledComponent<{ size?: number, ... }, *, typeof MetricItemEmpty> = styled(
  MetricItemEmpty
)`
  margin-bottom: 12px;    
  }
`

const MetricIndicatorEmpty: StyledComponent<{ size?: number, ... }, *, typeof MetricItemEmpty> =
  styled(MetricItemEmpty)`
  margin-bottom: O;    
  }
`

// Empty Template
export const AnalyticsListEmptyTemplate = (): React.Node => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" minSize={80} maxSize={100} />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}

// Empty Template
export const ReachListEmptyTemplate = (): React.Node => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" minSize={80} maxSize={100} />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}
