// @flow
import * as React from 'react'

import { EmptyField } from 'components/common/empty-states'
import { TextLabelWithIcon } from 'components/styled/text'

import { ProfileIdentifierContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'

type Props = {
  id: string,
  isLoading: boolean,
  ...
}

export const ProfileIdentifier = ({ id, isLoading }: Props): React.Node => {
  return (
    <ProfileIdentifierContainer className="fs-exclude">
      {isLoading ? (
        <React.Fragment>
          <EmptyField _width={123} _display="block" />
          <EmptyField _width={84} />
        </React.Fragment>
      ) : (
        <Copyable
          fullWidth={true}
          notificationText="Custom ID copied"
          label={<TextLabelWithIcon icon="identifier">Custom ID</TextLabelWithIcon>}
          value={id}
        />
      )}
    </ProfileIdentifierContainer>
  )
}
