// @flow

import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { type Dayjs, type DateRange } from 'com.batch.common/dayjs.custom'

import { setAnalyticsRange } from 'com.batch.redux/stat'
import { rangeSelector } from 'com.batch.redux/stat.selector.analytics'

type setRangeType = ({ +from: Dayjs, +to: Dayjs, ... }) => void
export const useRange = (): { range: DateRange, setRange: setRangeType, ... } => {
  const dispatch = useDispatch()
  const range = useSelector(rangeSelector)
  let [searchParams, setSearchParams] = useSearchParams()

  const setRange = ({ from, to }: { +from: Dayjs, +to: Dayjs, ... }) => {
    if (range.from.isSame(from, 'hour') && range.to.isSame(to, 'hour')) {
      return
    }
    searchParams.set('from', from.format('YYYYMMDD'))
    searchParams.set('to', to.format('YYYYMMDD'))
    setSearchParams(searchParams)

    return dispatch(setAnalyticsRange({ from, to }))
  }
  return { range, setRange }
}
