// @flow

import { type List } from 'immutable'
import { defer } from 'lodash-es'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { type StyledComponent } from 'styled-components'

import { useIsCurrentUserAllowedTo, useToggle } from 'components/_hooks'
import { Button, PermissionButton } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'

import { SidebarApp, Container as AppContainer } from './sidebar-app'
import { SidebarAppsList, Container as AppsContainer } from './sidebar-apps-list'
import { SidebarSearch, Container as SearchContainer } from './sidebar-search'
import { CURRENT_HEIGHT, PADDING, INNER_PADDING } from './sidebar.styles'

import { type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'
import { openNew } from 'com.batch.redux/app.action'
import { type ProjectRecord } from 'com.batch.redux/project.records'

type OwnProps = {
  apps: List<AppRecord>,
  currentApp: AppRecord,
  company: CompanyRecord,
  project: ?ProjectRecord,
  ...
}
type DispatchProps = {
  openNew: typeof openNew,
  ...
}
export type SidebarAppsProps = OwnProps & DispatchProps

export const SidebarApps = (props: SidebarAppsProps): React.Node => {
  const { currentApp, apps, company, openNew } = props
  const searchingState = useToggle()
  const [search, setSearch] = React.useState<string>('')
  const $search = React.useRef(null)

  const filteredApps: List<AppRecord> = filterApps(apps, currentApp, searchingState.value, search)

  const onEnableSearch = React.useCallback(() => {
    searchingState.open()
    defer(() => $search.current && $search.current.focus())
  }, [searchingState])
  const navigate = useNavigate()
  const isAllowedToAddNewApp = useIsCurrentUserAllowedTo(['company', 'apps:create-delete'])
  const onNewAppClick = React.useCallback(() => {
    openNew(window.location.pathname + window.location.search)
    navigate(generateUrl('app_new', { companyId: company.id }))
  }, [company.id, navigate, openNew])
  return (
    <Container>
      <Current>
        <SidebarSearch
          onClose={searchingState.close}
          onSearch={setSearch}
          search={search}
          style={{ display: searchingState.value ? 'flex' : 'none' }}
          inputRef={$search}
        />
        <CurrentApp style={{ display: searchingState.value ? 'none' : 'flex' }}>
          <SidebarApp app={currentApp} />
          {apps.size > 1 && (
            <Button
              kind="inline"
              intent="neutral"
              style={{ flex: '0 0 auto', margin: `0 ${INNER_PADDING - 2}px` }}
              onClick={onEnableSearch}
            >
              <Icon icon="search" />
            </Button>
          )}
        </CurrentApp>
      </Current>
      {apps.size > 1 && (
        <SidebarAppsList apps={filteredApps} company={company} currentApp={currentApp} />
      )}
      <CreateApp>
        <PermissionButton
          kind="inline"
          isAllowed={isAllowedToAddNewApp}
          notAllowedMessage="You don't have permission to create a new app."
          onClick={onNewAppClick}

          // prefix="add"
        >
          Add a new app or website
        </PermissionButton>
      </CreateApp>
    </Container>
  )
}

const filterApps = (
  apps: List<AppRecord>,
  currentApp: AppRecord,
  searching: boolean,
  search?: string
): List<AppRecord> => {
  if (!searching) return apps.filter(a => a.id !== currentApp.id)
  if (search === '' || !search) return apps
  return apps.filter(a => a.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
}

export const CreateApp: StyledComponent<*, *, HTMLElement> = styled.div`
  padding: 12px ${PADDING + INNER_PADDING}px;
  border-top: 1px solid ${colors.stroke};
`

export const CurrentApp: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${CURRENT_HEIGHT}px;
  position: absolute;
  overflow: hidden;

  ${AppContainer} {
    flex: 1 1 auto;
    color: ${colors.text};
    background: none;
    overflow: hidden;
  }
`

export const Current: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: ${CURRENT_HEIGHT}px;
  overflow: hidden;
  background: ${colors.fill};
  border: 1px solid ${colors.stroke};
  border-radius: 5px;
  box-shadow: 0 1px 3px ${colors.opacifyDefault};

  ${SearchContainer} {
    width: 100%;
    height: 100%;
  }

  & + ${CreateApp} {
    margin-top: ${CURRENT_HEIGHT + PADDING}px;
  }
`

export const Container: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${Current} {
    position: absolute;
    z-index: 10;
    left: ${PADDING}px;
    right: ${PADDING + 2}px;
  }

  ${AppsContainer} {
    flex: 0 1 auto;
    min-height: 120px;
    padding: ${CURRENT_HEIGHT + INNER_PADDING}px ${PADDING + 2}px ${INNER_PADDING - 2}px
      ${PADDING + 2}px;
  }

  ${CreateApp} {
    flex: 0 0 auto;
  }
`

export default SidebarApps
