// @flow

import * as Immutable from 'immutable'
import { type Map, type List, type Set, type RecordFactory, type RecordOf } from 'immutable'

import { type availableIcons } from 'components/common/svg-icon'

// -----------------------------------------------------------

export type attachmentKind = 'video' | 'audio' | 'image'

export type allowedActionKey =
  | 'batch.clipboard'
  | 'batch.deeplink'
  | 'batch.dismiss'
  | 'batch.ios_redirect_settings'
  | 'batch.ios_request_notifications'
  | 'batch.android_request_notifications'
  | 'batch.android_smart_reoptin'
  | 'batch.android_redirect_settings'
  | 'batch.ios_smart_reoptin'
  | 'batch.ios_tracking_consent'
  | 'batch.rating'
  | 'custom'

type SdkActionChoiceProps = {
  description: string,
  dropdownSprite: [number, number, number, number],
  icon: availableIcons,
  label: string,
  platforms: Set<Platforms>,
  sdkState: {
    installs: number,
    mlvl: number,
    public: string,
    ratio: number,
    state: 'ok' | 'warn' | 'danger' | 'loading',
    ...
  },
  value: allowedActionKey,
}
export const SdkActionChoiceFactory: RecordFactory<SdkActionChoiceProps> = Immutable.Record(
  ({
    value: 'batch.dismiss',
    label: '',
    description: '',
    icon: 'link',
    dropdownSprite: [0, 0, 0, 0],
    platforms: Immutable.Set(),
    sdkState: {
      state: 'ok',
      mlvl: 0,
      public: '',
      installs: 0,
      ratio: 0,
    },
  }: SdkActionChoiceProps)
)

export type SdkActionChoiceRecord = RecordOf<SdkActionChoiceProps>
const allPlatforms = ['ios', 'android']

export const sdkNativeActionsMap: { [allowedActionKey]: SdkActionChoiceRecord, ... } = {
  'batch.dismiss': SdkActionChoiceFactory({
    value: 'batch.dismiss',
    description: 'Closes the in-app message',
    label: 'Dismiss',
    icon: 'logout',
    dropdownSprite: [-64, -20, -20, -20],
    platforms: Immutable.Set(allPlatforms),
  }),
  'batch.deeplink': SdkActionChoiceFactory({
    value: 'batch.deeplink',
    label: 'Deeplink',
    description: 'Open the provided deeplink',
    icon: 'link',
    dropdownSprite: [-64, -64, -20, -64],
    platforms: Immutable.Set(allPlatforms),
  }),
  'batch.clipboard': SdkActionChoiceFactory({
    value: 'batch.clipboard',
    label: 'Clipboard',
    description: "Copy a text to the device's clipboard",
    icon: 'copy',
    dropdownSprite: [-64, -108, -20, -108],
    platforms: Immutable.Set(allPlatforms),
  }),
  'batch.ios_request_notifications': SdkActionChoiceFactory({
    value: 'batch.ios_request_notifications',
    label: 'Push opt-in prompt',
    description: 'Display push notification opt-in permission prompt to eligible users',
    icon: 'optin',
    dropdownSprite: [-64, -152, -20, -152],
    platforms: Immutable.Set(['ios']),
  }),
  'batch.android_request_notifications': SdkActionChoiceFactory({
    value: 'batch.android_request_notifications',
    label: 'Push opt-in prompt',
    description: 'Display push notification opt-in permission prompt to eligible users',
    icon: 'optin',
    dropdownSprite: [-64, -152, -20, -152],
    platforms: Immutable.Set(['android']),
  }),
  'batch.ios_smart_reoptin': SdkActionChoiceFactory({
    value: 'batch.ios_smart_reoptin',
    label: 'Smart push re-optin',
    description: 'Display push opt-in permission prompt or open notification settings',
    icon: 'smart',
    dropdownSprite: [-64, -196, -20, -196],
    platforms: Immutable.Set(['ios']),
  }),
  'batch.android_smart_reoptin': SdkActionChoiceFactory({
    value: 'batch.android_smart_reoptin',
    label: 'Smart push re-optin',
    description: 'Display push opt-in permission prompt or open notification settings',
    icon: 'smart',
    dropdownSprite: [-64, -196, -20, -196],
    platforms: Immutable.Set(['android']),
  }),
  'batch.rating': SdkActionChoiceFactory({
    value: 'batch.rating',
    label: 'Rating',
    description: 'Display in-app rating prompt',
    icon: 'star',
    dropdownSprite: [-64, -240, -20, -240],
    platforms: Immutable.Set(allPlatforms),
  }),
  'batch.ios_tracking_consent': SdkActionChoiceFactory({
    value: 'batch.ios_tracking_consent',
    label: 'Smart App tracking prompt',
    description: "Display app tracking permission prompt or open app's settings",
    icon: 'search',
    dropdownSprite: [-64, -284, -20, -284],
    platforms: Immutable.Set(['ios']),
  }),
  'batch.ios_redirect_settings': SdkActionChoiceFactory({
    value: 'batch.ios_redirect_settings',
    label: 'Redirect to settings',
    description: 'Open the notification settings for your app',
    icon: 'settings',
    dropdownSprite: [-64, -328, -20, -328],
    platforms: Immutable.Set(['ios']),
  }),
  'batch.android_redirect_settings': SdkActionChoiceFactory({
    value: 'batch.android_redirect_settings',
    label: 'Redirect to settings',
    description: 'Open the notification settings for your app',
    icon: 'settings',
    dropdownSprite: [-64, -328, -20, -328],
    platforms: Immutable.Set(['android']),
  }),
  custom: SdkActionChoiceFactory({
    value: 'custom',
    label: 'Custom',
    description: 'Allows you to write your JSON directly here',
    icon: 'code',
    dropdownSprite: [-64, -372, -20, -372],
    platforms: Immutable.Set(allPlatforms),
  }),
}

export const sdkNativeActions: List<SdkActionChoiceRecord> = new Immutable.List().push(
  ...[
    sdkNativeActionsMap['batch.dismiss'],
    sdkNativeActionsMap['batch.deeplink'],
    sdkNativeActionsMap['batch.clipboard'],
    sdkNativeActionsMap['batch.android_request_notifications'],
    sdkNativeActionsMap['batch.ios_request_notifications'],
    sdkNativeActionsMap['batch.android_smart_reoptin'],
    sdkNativeActionsMap['batch.ios_smart_reoptin'],
    sdkNativeActionsMap['batch.rating'],
    sdkNativeActionsMap['batch.ios_tracking_consent'],
    sdkNativeActionsMap['batch.android_redirect_settings'],
    sdkNativeActionsMap['batch.ios_redirect_settings'],
    sdkNativeActionsMap['custom'],
  ]
)

type StringValueProps = {
  touched: boolean,
  valid: boolean,
  value: string,
  ...
}
export const StringValueFactory: RecordFactory<StringValueProps> = Immutable.Record(
  ({
    valid: false,
    touched: false,
    value: '',
  }: StringValueProps)
)

export type StringValueRecord = RecordOf<StringValueProps>

export type PushContentLegacyProps = {
  message: StringValueRecord,
  title: StringValueRecord,
  deeplink: string,
  mediaKind: attachmentKind,
  mediaUrl: string,
  ...
}

export const PushContentLegacyFactory: RecordFactory<PushContentLegacyProps> = Immutable.Record(
  ({
    title: StringValueFactory(),
    message: StringValueFactory(),
    deeplink: '',
    mediaKind: 'image',
    mediaUrl: '',
  }: PushContentLegacyProps)
)

export type PushContentLegacyRecord = RecordOf<PushContentLegacyProps>

// -----------------------------------------------------------

export type AbTestedPushProps = {
  a: PushContentLegacyRecord,
  b: PushContentLegacyRecord,
  ...
}

export const AbTestedPushFactory: RecordFactory<AbTestedPushProps> = Immutable.Record(
  ({
    a: PushContentLegacyFactory(),
    b: PushContentLegacyFactory(),
  }: AbTestedPushProps)
)

export type AbTestedPushRecord = RecordOf<AbTestedPushProps>

// -----------------------------------------------------------

export type PushSettingsProps = {
  attachmentSkipRehost: boolean,
  collapseKey: string,
  expiration: ?number,
  hasCollapseKey: boolean,
  hasExpiration: boolean,
  iconUrl: string,
  payload: string,
  priority: 'NORMAL' | 'HIGH',
  attachmentUrl: string,
  attachmentKind: attachmentKind,
  deeplink: string,
  ...
}

export const PushSettingsFactory: RecordFactory<PushSettingsProps> = Immutable.Record(
  ({
    payload: '',
    iconUrl: '',
    priority: 'NORMAL',
    hasExpiration: false,
    expiration: 24,
    hasCollapseKey: false,
    collapseKey: 'default',
    attachmentSkipRehost: false,
    attachmentUrl: '',
    attachmentKind: 'image',
    deeplink: '',
  }: PushSettingsProps)
)

export type PushSettingsRecord = RecordOf<PushSettingsProps>

// -----------------------------------------------------------

type AdditionalActionProps = {
  data: string,
  label: string,
  mode: 'EVENT' | 'ADD_TAG' | 'REMOVE_TAG',
  name: string,
  tags: Array<string>,
  ...
}
export const AdditionalActionFactory: RecordFactory<AdditionalActionProps> = Immutable.Record(
  ({
    mode: 'EVENT',
    name: '',
    tags: [],
    label: '',
    data: '',
  }: AdditionalActionProps)
)

export type AdditionalActionRecord = RecordOf<AdditionalActionProps>

type SdkActionProps = {
  action: SdkActionChoiceRecord,
  additional: List<AdditionalActionRecord>,
  copyText: string,
  customActionArgs: string,
  customActionName: string,
  deeplinkInternal: boolean,
  deeplinkUrl: string,
  ...
}

export const SdkActionFactory: RecordFactory<SdkActionProps> = Immutable.Record(
  ({
    action: sdkNativeActions.get(0, SdkActionChoiceFactory()),
    deeplinkUrl: '',
    deeplinkInternal: false,
    customActionName: '',
    customActionArgs: '',
    copyText: '',
    additional: new Immutable.List(),
  }: SdkActionProps)
)

export type SdkActionRecord = RecordOf<SdkActionProps>

// -----------------------------------------------------------

export type InAppContentProps = {
  globalTapAction: SdkActionRecord,
  header: string,
  imageAlt: string,
  imageIsGif: boolean,
  imageUrl: string,
  linkOpenTarget: string,
  mainButtonAction: SdkActionRecord,
  mainButtonLabel: string,
  secondaryButtonAction: SdkActionRecord,
  secondaryButtonLabel: string,
  text: string,
  title: string,
  trackingId: string,
  webviewUrl: string,
  ...
}

export const InAppContentFactory: RecordFactory<InAppContentProps> = Immutable.Record(
  ({
    header: '',
    title: '',
    text: '',
    mainButtonAction: SdkActionFactory(),
    mainButtonLabel: '',
    secondaryButtonAction: SdkActionFactory(),
    secondaryButtonLabel: '',
    globalTapAction: SdkActionFactory(),
    imageUrl: '',
    imageAlt: '',
    imageIsGif: false,
    trackingId: '',
    webviewUrl: '',
    linkOpenTarget: 'in-app',
  }: InAppContentProps)
)

export type InAppContentRecord = RecordOf<InAppContentProps>

export type AbTestedInAppProps = {
  a: InAppContentRecord,
  b: InAppContentRecord,
  ...
}

export const AbTestedInAppFactory: RecordFactory<AbTestedInAppProps> = Immutable.Record(
  ({
    a: InAppContentFactory(),
    b: InAppContentFactory(),
  }: AbTestedInAppProps)
)

export type AbTestedInAppRecord = RecordOf<AbTestedInAppProps>

// -----------------------------------------------------------

type ContentStateProps = {
  campaignName: string,
  inapp: Map<string, AbTestedInAppRecord>,
  push: Map<string, AbTestedPushRecord>,
  pushSettings: PushSettingsRecord,
}

export const ContentStateFactory: RecordFactory<ContentStateProps> = Immutable.Record(
  ({
    campaignName: '',
    pushSettings: PushSettingsFactory(),
    inapp: Immutable.Map(),
    push: Immutable.Map(),
  }: ContentStateProps)
)

export type ContentStateRecord = RecordOf<ContentStateProps>
