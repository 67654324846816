// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Box } from 'components/common/box'
import { Grid } from 'components/common/grid'
import { MultiResultsBannerContainer } from 'components/common/navigation/multi-results-banner.styles'

import { AttributesContainer } from 'com.batch/profile/ui/components/attributes-block/attributes-block.styles'
import { EventListContainer } from 'com.batch/profile/ui/components/events-block/event-list/event-list.styles'
import { ProfileHeroContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

export const ProfileContainer: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled('div')`
  ${MultiResultsBannerContainer}, ${ProfileHeroContainer} {
    margin-bottom: 28px;
  }
`

export const ProfileBody: StyledComponent<*, *, *> = styled(Grid)`
  margin-bottom: -22px;
  > ${Box} {
    margin-bottom: 0;
  }
  ${EventListContainer}, ${AttributesContainer} {
    height: calc(100vh - 438px); // 438px = (top height + margins + blocks headers + 28px bottom)
  }
`
