// @flow

import * as React from 'react'
import { createRoot } from 'react-dom/client'

// eslint-disable-next-line import/no-cycle
import { type ConfirmModalProps, ConfirmModal } from 'components/common/confirm-modal'

export const confirm = (props: ConfirmModalProps): Promise<boolean> => {
  const body = document.body
  if (!body) throw new Error('confirm called in a non DOM context')
  const wrapper = body.appendChild(document.createElement('div'))
  const root = createRoot(wrapper)
  let resolver = () => undefined
  let rejecter = () => undefined
  const promise = new Promise<boolean>((resolve, reject) => {
    resolver = () => {
      root.unmount()
      setTimeout(() => wrapper.remove())
      resolve(true)
    }
    rejecter = () => {
      root.unmount()
      setTimeout(() => wrapper.remove())
      reject(false)
    }
  })
  root.render(<ConfirmModal {...props} resolver={resolver} rejecter={rejecter} />)
  return promise
}
