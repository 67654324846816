// @flow
import { type Dayjs } from 'dayjs'
import Immutable, {
  type RecordFactory,
  type RecordOf,
  type OrderedMap,
  type Set,
  type Map,
} from 'immutable'

import { generateTypedId } from 'com.batch.common/typed-id'

import { type langId } from '../store/message.state'
import { AgeFactory, type AgeRecord } from 'com.batch.redux/_records'
import { type attachmentKind } from 'com.batch.redux/content.records'

import { type HtmlEditorConfig } from 'com.batch/email/models/html-editor-config'

export type SmsTemplatesProps = {
  smsMessage: string,
}
export const SmsTemplatesFactory: RecordFactory<SmsTemplatesProps> = Immutable.Record({
  smsMessage: '',
})
export type SmsTemplatesRecord = RecordOf<SmsTemplatesProps>

export type SmsContentProps = {
  smsMessage: string,
  templates: SmsTemplatesRecord,
}
export const SmsContentFactory: RecordFactory<SmsContentProps> = Immutable.Record(
  ({
    smsMessage: '',
    templates: SmsTemplatesFactory(),
  }: SmsContentProps)
)
export type SmsContentRecord = RecordOf<SmsContentProps>

type SmsMessage = {
  localizedContent: OrderedMap<langId, SmsContentRecord>,
}
export const SmsMessageFactory: RecordFactory<SmsMessage> = Immutable.Record({
  localizedContent: Immutable.OrderedMap(),
})
export type SmsMessageRecord = RecordOf<SmsMessage>

export type EmailTemplatesProps = {
  html: ?string,
  subject: ?string,
  replyTo: ?string,
}
export const EmailTemplatesFactory: RecordFactory<EmailTemplatesProps> = Immutable.Record({
  html: null,
  subject: null,
  replyTo: null,
})
export type EmailTemplatesRecord = RecordOf<EmailTemplatesProps>

export type EmailContentProps = {
  replyTo: ?string,
  fromEmail: ?string,
  name: ?string,
  subject: ?string,
  html: ?string,
  templates: EmailTemplatesRecord,
  senderIdentityId?: number,
  isEmailUploading: boolean,
  loadingState: LoadingState,
  htmlEditorConfig: ?HtmlEditorConfig,
  isPristine: boolean,
}
export const EmailContentFactory: RecordFactory<EmailContentProps> = Immutable.Record(
  ({
    fromEmail: null,
    replyTo: null,
    name: null,
    subject: null,
    html: null,
    templates: EmailTemplatesFactory(),
    senderIdentityId: undefined,
    isEmailUploading: false,
    loadingState: 'INIT',
    htmlEditorConfig: null,
    isPristine: true,
  }: EmailContentProps)
)
export type EmailContentRecord = RecordOf<EmailContentProps>

type EmailMessage = {
  localizedContent: OrderedMap<langId, EmailContentRecord>,
}
export const EmailMessageFactory: RecordFactory<EmailMessage> = Immutable.Record({
  localizedContent: Immutable.OrderedMap(),
})
export type EmailMessageRecord = RecordOf<EmailMessage>

export const PUSH_PRIORITY = {
  PUSH_PRIORITY_UNSPECIFIED: '',
  PUSH_PRIORITY_NORMAL: 'Normal',
  PUSH_PRIORITY_HIGH: 'High',
}
export type PushPriority = $Keys<typeof PUSH_PRIORITY>

export const PUSH_NOTIF_TYPE = {
  PUSH_TYPE_UNSPECIFIED: '',
  PUSH_TYPE_ALERT: 'Alert',
  PUSH_TYPE_BACKGROUND: 'Background',
}
export type PushNotifType = $Keys<typeof PUSH_NOTIF_TYPE>

type PushContentTemplatesProps = {
  pushTitle: string,
  pushBody: string,
  pushPicture: string,
  pushIcon: string,
}
export const PushContentTemplatesFactory: RecordFactory<PushContentTemplatesProps> =
  Immutable.Record({
    pushTitle: '',
    pushBody: '',
    pushPicture: '',
    pushIcon: '',
  })
export type PushContentTemplatesRecord = RecordOf<PushContentTemplatesProps>

type PushMessageRaw = {
  pushTitle: string,
  pushBody: string,
  pushPicture: string,
  pushIcon: string,
  templates: PushContentTemplatesRecord,
  attachmentKind: attachmentKind,
  pushType: PushNotifType,
  deeplink: string,
}
export type PushMessageRawRecord = RecordOf<PushMessageRaw>
export const PushMessageRawFactory: RecordFactory<PushMessageRaw> = Immutable.Record(
  ({
    pushTitle: '',
    pushBody: '',
    pushPicture: '',
    pushIcon: '',
    attachmentKind: 'image',
    templates: PushContentTemplatesFactory(),
    pushType: 'PUSH_TYPE_ALERT',
    deeplink: '',
  }: PushMessageRaw)
)
type AndroidPushMessageRaw = {
  deeplink: string, // no deeplink = redirect to app
}
export type AndroidPushMessageRawRecord = RecordOf<AndroidPushMessageRaw>
export const AndroidPushMessageRawFactory: RecordFactory<AndroidPushMessageRaw> = Immutable.Record(
  ({
    deeplink: '',
  }: AndroidPushMessageRaw)
)
type IosPushMessageRaw = {
  deeplink: string,
  video: string,
  audio: string,
}
export type IosPushMessageRawRecord = RecordOf<IosPushMessageRaw>
export const IosPushMessageRawFactory: RecordFactory<IosPushMessageRaw> = Immutable.Record(
  ({
    deeplink: '',
    video: '',
    audio: '',
  }: IosPushMessageRaw)
)
type WebPushMessageRaw = {
  deeplink?: string,
}
export type WebPushMessageRawRecord = RecordOf<WebPushMessageRaw>
export const WebPushMessageRawFactory: RecordFactory<WebPushMessageRaw> = Immutable.Record(
  ({
    deeplink: undefined,
  }: WebPushMessageRaw)
)
type PushContent = {
  content: PushMessageRawRecord,
  androidContent: AndroidPushMessageRawRecord,
  iosContent: IosPushMessageRawRecord,
  webContent: WebPushMessageRawRecord,
}
export type PushContentRecord = RecordOf<PushContent>
export const PushContentFactory: RecordFactory<PushContent> = Immutable.Record(
  ({
    content: PushMessageRawFactory(),
    androidContent: AndroidPushMessageRawFactory(),
    iosContent: IosPushMessageRawFactory(),
    webContent: WebPushMessageRawFactory(),
  }: PushContent)
)

type PushSettingsAndroidOverride = {
  collapseKey: string,
  customPayload: string,
}
export const PushSettingsAndroidOverrideFactory: RecordFactory<PushSettingsAndroidOverride> =
  Immutable.Record(
    ({
      collapseKey: '',
      customPayload: '',
    }: PushSettingsAndroidOverride)
  )
export type PushSettingsAndroidOverrideRecord = RecordOf<PushSettingsAndroidOverride>

type PushSettingsIosOverride = {
  customPayload: string,
}
export const PushSettingsIosOverrideFactory: RecordFactory<PushSettingsIosOverride> =
  Immutable.Record(
    ({
      customPayload: '',
    }: PushSettingsIosOverride)
  )
export type PushSettingsIosOverrideRecord = RecordOf<PushSettingsIosOverride>

export type FilterPushTokensType = 'all' | 'collected' | 'imported'

type PushSettings = {
  priority: PushPriority,
  ttl: AgeRecord,
  ttlEnabled: boolean,
  customPayload: string,
  androidOverride: PushSettingsAndroidOverrideRecord,
  iosOverride: PushSettingsIosOverrideRecord,
  filterPushTokens: FilterPushTokensType,
}
export const PushSettingsFactory: RecordFactory<PushSettings> = Immutable.Record({
  priority: 'PUSH_PRIORITY_HIGH',
  ttl: AgeFactory(),
  ttlEnabled: false,
  customPayload: '',
  androidOverride: PushSettingsAndroidOverrideFactory(),
  iosOverride: PushSettingsIosOverrideFactory(),
  filterPushTokens: 'collected',
})
export type PushSettingsRecord = RecordOf<PushSettings>

type PushMessage = {
  localizedContent: OrderedMap<langId, PushContentRecord>,
}
export const PushMessageFactory: RecordFactory<PushMessage> = Immutable.Record(
  ({
    localizedContent: Immutable.OrderedMap(),
  }: PushMessage)
)
export type PushMessageRecord = RecordOf<PushMessage>

type MessageId = string
export type VariantId = 1 | 2 | 3 | 4
type ExperimentProps = {
  id: string,
  variants: Map<VariantId, MessageId>,
  selectedVariantId: VariantId,
  winningVariantSelectionDate: ?Dayjs,
  winnerVariantId: ?VariantId,
  enabled: boolean,
  locked: boolean, // au parsing, on va lock si running / stopped / completed
}
export const ExperimentFactory: RecordFactory<ExperimentProps> = Immutable.Record(
  ({
    id: generateTypedId('experiment'),
    variants: new Immutable.Map(),
    winningVariantSelectionDate: undefined,
    selectedVariantId: 1,
    winnerVariantId: null,
    enabled: false,
    locked: false,
  }: ExperimentProps)
)
export type ExperimentRecord = RecordOf<ExperimentProps>

type MessageConfig = {
  messageTypedId: MessageId,
  channel: ChannelUntilCleanup,
  pushSettings: PushSettingsRecord,
  platforms: Set<ProjectPlatforms>,
  experiment: ExperimentRecord,
  multilanguageEnabled: boolean,
}
export type MessageConfigRecord = RecordOf<MessageConfig>
export const MessageConfigFactory: RecordFactory<MessageConfig> = Immutable.Record(
  ({
    messageTypedId: generateTypedId('message'),
    channel: 'email',
    pushSettings: PushSettingsFactory(),
    platforms: new Immutable.Set(),
    experiment: ExperimentFactory(),
    multilanguageEnabled: false,
  }: MessageConfig)
)
