// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  useToggle,
  useQuery,
  useSingleParamCallback,
  useCampaignInfoFromRouter,
  useBlurOnClickCallback,
} from 'components/_hooks'
import { CampaignsListHeader } from 'components/campaign/campaign-list-header'
import { CampaignListRow, CampaignListEmptyTemplate } from 'components/campaign/campaign-list-utils'
import { CampaignBox, CampaignContent } from 'components/campaign/campaign-list.styles'
import { ExportModal } from 'components/campaign/export-modal'
import { useFilters } from 'components/campaign/filters/filters-provider'
import { BoxBody } from 'components/common/box'
import { Button } from 'components/common/button'
import { Wrapper, PushEmptyIcon } from 'components/common/empty-states'
import { TrackingContext } from 'components/common/page-tracker'
import { Pager } from 'components/common/pager'
import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableCellHeader,
  TableCellOrder,
} from 'components/common/table'
import { Separator } from 'components/styled/utils'

import { NoResultWrapper } from '../../modules/shared/ui/component/no-result-wrapper'
import { Icon } from '../common/svg-icon'
import { CampaignFactory, type State, type CampaignRecord } from 'com.batch.redux/_records'
import { currentAppCanPush, currentAppSelector } from 'com.batch.redux/app'
import {
  actions,
  campaignConfigSelector,
  countTotalSelector,
  countFilteredSelector,
  countLoadingSelector,
  listLoadingSelector,
  listSelector,
  listPagerLoadingSelector,
} from 'com.batch.redux/campaign'
import { switchPage } from 'com.batch.redux/campaign.action'
import { devApiKeyFeatureSelector } from 'com.batch.redux/company.selector'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'
import { FetchThemes } from 'com.batch.redux/theme'
import { ThemesSelector } from 'com.batch.redux/theme.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

export const CampaignList = (): React.Node => {
  const dispatch = useDispatch()
  const { schedulingType } = useCampaignInfoFromRouter()

  // ====================== REDUX STATE DATA
  const user = useSelector(currentUserSelector)
  const app = useSelector(currentAppSelector)
  const maybeProject = useSelector(optionalCurrentProjectSelector)
  const canPush = useSelector(currentAppCanPush)
  const config = useSelector(campaignConfigSelector)

  const campaigns = useSelector(listSelector)
  const loading = useSelector(listLoadingSelector)
  const pagerLoading = useSelector(listPagerLoadingSelector)
  const countFiltered = useSelector(countFilteredSelector)
  const countTotal = useSelector(countTotalSelector)
  const loadingCount = useSelector(countLoadingSelector)
  const themes = useSelector(ThemesSelector)
  const failure = useSelector((state: State) => state.campaign.failure)
  const hasDevApiKeyFeature = useSelector(devApiKeyFeatureSelector)

  const query = useQuery()
  const page = parseInt(query.get('page')) || 1

  // ====================== LOCAL STATE
  const TRACKING_CONTEXT = React.useMemo(
    () => ({
      eventLocation: 'campaigns list',
      searchEventCode: 'unset',
      pagerEventCode: 'CAMPAIGN_PAGER',
      schedulingType,
      appId: app.id,
    }),
    [app.id, schedulingType]
  )

  const [campaignListWhileLoading, setCampaignListWhileLoading] = React.useState<
    List<CampaignRecord>,
  >(
    campaigns.size > 0
      ? campaigns
      : new Immutable.List().push(
          CampaignFactory({ token: 'a' }),
          CampaignFactory({ token: 'b' }),
          CampaignFactory({ token: 'c' }),
          CampaignFactory({ token: 'd' })
        )
  )

  const exportModalToggle = useToggle()

  const { goToFilteredURL } = useFilters()

  // ====================== CACHE CAMPAIGNS SO LOADING STATE IS NICE
  React.useEffect(() => {
    if (!loading && campaigns.size !== campaignListWhileLoading.size && campaigns.size > 0) {
      setCampaignListWhileLoading(campaigns)
    }
  }, [loading, campaigns, campaignListWhileLoading, setCampaignListWhileLoading])

  // ====================== PROPS DERIVED VALUES
  const isAutomation: boolean = React.useMemo(
    () => schedulingType === 'automations',
    [schedulingType]
  )

  const hasThemes = React.useMemo(() => themes.size > 0, [themes.size])
  const shownCampaigns = React.useMemo(
    () => (loading ? campaignListWhileLoading : campaigns),
    [campaignListWhileLoading, campaigns, loading]
  )

  // 52px per line, 4µ4px for header
  const hasFooter = React.useMemo(() => countFiltered > 10 || loading, [countFiltered, loading])
  const minHeight = React.useMemo(
    () => 52 * (countFiltered % 10) + (hasFooter ? 52 : 0),
    [countFiltered, hasFooter]
  )

  const hasNoCampaign = React.useMemo(
    () => countTotal === 0 && !loadingCount,
    [countTotal, loadingCount]
  )

  // ====================== CALLBACKS
  const selectPage = React.useCallback(
    (page: number) => {
      dispatch(switchPage(page))
      goToFilteredURL('page', page)
    },
    [dispatch, goToFilteredURL]
  )

  const updateSort = React.useCallback(
    (field: string) => {
      goToFilteredURL('sort', {
        field,
        direction: config.sortBy === field ? (config.sortOrder === 'asc' ? 'dsc' : 'asc') : 'asc',
      })
    },
    [goToFilteredURL, config]
  )
  const onSortByName = useSingleParamCallback(updateSort, 'name')
  const onSortByDate = useSingleParamCallback(updateSort, 'date')
  const openExportModal = useBlurOnClickCallback(exportModalToggle.open)
  // ====================== INITIAL LOAD
  React.useEffect(() => {
    dispatch(FetchThemes())
    dispatch(actions.fetchCampaignsInit(app.id, schedulingType))
  }, [app.id, dispatch, schedulingType])

  return (
    <React.Fragment>
      <TrackingContext.Provider value={TRACKING_CONTEXT}>
        <CampaignsListHeader
          loading={loading}
          hasCampaigns={countTotal > 0}
          hasThemes={hasThemes}
          schedulingType={schedulingType}
          isAutomation={isAutomation}
          canPush={canPush}
        />
        <CampaignContent>
          <Wrapper
            style={{ width: '1134px' }}
            isLoading={loading}
            isEmpty={hasNoCampaign}
            isOverlayShown={hasNoCampaign && !failure}
            overlayProps={
              isAutomation
                ? {
                    status: 'empty-page',
                    title: 'Create your first automation',
                    content: <PushEmptyIcon />,
                    description:
                      'Automations are useful to manage a wide variety of use cases. Deliver the right message at the right time.',
                    links: [
                      {
                        name: 'Push notification',
                        href:
                          app.platform === 'webpush'
                            ? 'https://doc.batch.com/#web-push-notifications'
                            : 'https://doc.batch.com/#mobile-push-notifications',
                      },
                      {
                        name: 'In-app message',
                        href: 'https://help.batch.com/en/articles/3924443-push-in-app-campaigns-glossary',
                      },
                    ],
                  }
                : {
                    status: 'empty-page',
                    title: 'Create your first campaign',
                    content: <PushEmptyIcon />,
                    description:
                      "With push notifications, your users are one click away from re-visiting your app. It's the fastest and most efficient communication channel.",
                    links: [
                      {
                        name: 'Documentation',
                        href:
                          app.platform === 'webpush'
                            ? 'https://doc.batch.com/#web-push-notifications'
                            : 'https://doc.batch.com/#mobile-push-notifications',
                      },
                      {
                        name: 'Campaigns glossary',
                        href: 'https://help.batch.com/en/articles/3924443-push-in-app-campaigns-glossary',
                      },
                    ],
                  }
            }
          >
            <CampaignBox>
              <Wrapper
                isLoading={loading}
                isEmpty={hasNoCampaign}
                isOverlayShown={failure}
                overlayProps={{
                  status: 'error',
                  title: 'Something went wong',
                  description:
                    "This is not working properly. We are working to fix the problem and we'll be up and running shortly.",
                  links: [
                    {
                      name: 'Check the status page',
                      href: 'https://status.batch.com/',
                    },
                  ],
                }}
              >
                <BoxBody style={{ minHeight }}>
                  <Table
                    rowHeight={isAutomation ? 57 : 52}
                    forceEmpty={failure || loading || loadingCount}
                    style={{ borderRadius: 7, width: '100%' }}
                    template={
                      isAutomation
                        ? '36px 2fr 1.5fr minMax(80px, 140px) 120px 99px'
                        : '36px 2fr 220px minMax(70px, 110px) 130px 99px'
                    }
                  >
                    <TableHeader>
                      <TableCellHeader align="center" kind="checkbox" />
                      <TableCellOrder
                        sort={config.sortBy === 'name' && config.sortOrder}
                        onClick={onSortByName}
                      >
                        Name
                      </TableCellOrder>
                      <TableCellOrder
                        sort={config.sortBy === 'date' && config.sortOrder}
                        onClick={onSortByDate}
                      >
                        When
                      </TableCellOrder>
                      <React.Fragment>
                        <TableCellHeader>Delivery</TableCellHeader>
                        <TableCellHeader>Interaction</TableCellHeader>
                      </React.Fragment>
                      <TableCellHeader
                        style={{ overflow: 'unset' }}
                        align="right"
                        verticalAlign="center"
                      >
                        <Separator _margin="0 3px 0" display="inline-block" />
                        <Button addOn="prefix" onClick={openExportModal}>
                          <Icon icon="download" />
                          Export
                        </Button>
                      </TableCellHeader>
                    </TableHeader>
                    <NoResultWrapper
                      entityName="campaign"
                      isEmpty={
                        countTotal !== 0 && campaigns.size === 0 && !loading && !pagerLoading
                      }
                    >
                      <TableBody
                        emptyTemplate={<CampaignListEmptyTemplate />}
                        templateSize={campaigns.size !== 0 ? shownCampaigns.size : 10}
                      >
                        {shownCampaigns.map(campaign => (
                          <CampaignListRow
                            key={campaign.token}
                            campaign={campaign}
                            project={maybeProject}
                            isUserAllowedToEdit={user.permissionsForCurrentApp.has(
                              campaign.type === 'push' ? 'push:write' : 'in-app:write'
                            )}
                            app={app}
                            companyId={parseInt(app.companyId)}
                            allowEdit={
                              campaign.state !== 'COMPLETED' &&
                              user.permissionsForCurrentApp.has('push:write')
                            }
                            hasDevApiKeyFeature={hasDevApiKeyFeature}
                          />
                        ))}
                      </TableBody>
                      {hasFooter && (
                        <TableFooter>
                          <Pager
                            total={countFiltered}
                            selectPage={selectPage}
                            nbPerPage={10}
                            page={page}
                            forceEmpty={failure}
                            forceLoading={loading && pagerLoading}
                          />
                        </TableFooter>
                      )}
                    </NoResultWrapper>
                  </Table>
                </BoxBody>
              </Wrapper>
            </CampaignBox>
          </Wrapper>
        </CampaignContent>
      </TrackingContext.Provider>

      {exportModalToggle.value && (
        <ExportModal
          toggleOpen={exportModalToggle}
          schedulingType={schedulingType}
          activeFilters={config.filters}
        />
      )}
    </React.Fragment>
  )
}
