// @flow

import Immutable, { type Map } from 'immutable'
import { get as _get } from 'lodash-es'

import { dayjs } from 'com.batch.common/dayjs.custom'
import languages from 'com.batch.common/languages'
import regions from 'com.batch.common/regions'

import { type Install, type Profile, type ComputedNatives } from './models/profile.model'

import { LanguageFactory, RegionFactory } from 'com.batch.redux/_records'
import { parseNotif } from 'com.batch.redux/stat.api.debug'

import { type CustomEvent } from 'com.batch/profile/infra/debug/models/custom-event.model'
import { type Event } from 'com.batch/profile/infra/debug/models/event.model'
import { type MessagingEvent } from 'com.batch/profile/infra/debug/models/messaging-event.model'
import {
  type RawAttributeValue,
  type QueryValue,
  type Browser,
} from 'com.batch/profile/infra/debug/models/shared.model'
import { getPlatformName } from 'com.batch/profile/infra/formats/get-platform-name'
import {
  type CustomEventRecord,
  EventFactory,
  MessagingEventFactory,
  type MessagingEventRecord,
  type EventRecord,
  CustomEventFactory,
  OrchestrationMetadataFactory,
  EmailAdditionalDataFactory,
  PushAdditionalDataFactory,
  SMSAdditionalDataFactory,
} from 'com.batch/profile/models/event.records'
import {
  IdentityFactory,
  ProfileDataFactory,
  LastVisitFactory,
  SubscriptionFactory,
  SubscriptionEmailFactory,
  SubscriptionSmsFactory,
  type ProfileDataRecord,
  InstallFactory,
  SubscriptionPushFactory,
  type ProfilePlatformsRecord,
  ProfilePlatformsFactory,
  ProfileWebpushPlatformFactory,
} from 'com.batch/profile/models/profile.records'

export const parseCustomEventToRecord = (event: CustomEvent): CustomEventRecord => {
  let attributes = Object.keys(event.richAttributes || {}).reduce(
    (cAttr: Map<string, QueryValue>, caName) => {
      const queryValue = event.richAttributes[caName]
      return cAttr.set(caName, parseAttributeValueToQueryValue(queryValue))
    },
    Immutable.Map()
  )
  if (event.label) {
    attributes = attributes.set('label', {
      type: 'LABEL',
      stringValue: event.label,
    })
  }
  let customEvent = CustomEventFactory({
    name: event.name.name,
    label: event.label,
    attributes,
    tags: Immutable.Map(),
    sendDate: event.sendDate,
    source: event.source === 'TRIGGER_EVENTS_API' ? 'API' : event.source,
  })

  if (
    (event.source === 'TRIGGER_EVENTS_API' || event.source === 'SDK') &&
    event.apiKey.key &&
    event.apiKey.platform !== 'WINDOWS'
  ) {
    customEvent = customEvent.set('platform', event.apiKey.platform)
  }
  if (event.source === 'ANDROID' || event.source === 'IOS' || event.source === 'WEBPUSH') {
    customEvent = customEvent.set('platform', event.source)
  }
  return customEvent
}

export const parseChannel = (event: MessagingEvent): 'sms' | 'email' | 'push' => {
  if (event.channelType === 'CHANNEL_TYPE_EMAIL') {
    return 'email'
  }
  if (event.channelType === 'CHANNEL_TYPE_SMS') {
    return 'sms'
  }
  return 'push'
}

export const parseMessagingEventToRecord = (event: MessagingEvent): MessagingEventRecord | void => {
  if (
    event.metric &&
    (event.metric === 'SENT' ||
      event.metric === 'CLICK' ||
      event.metric === 'OPEN' ||
      event.metric === 'DELIVERED')
  ) {
    return MessagingEventFactory({
      eventID: event.eventID,
      metric: event.metric,
      eventDate: event.eventDate,
      channel: parseChannel(event),
      emailAdditionalData: event.emailAdditionalData
        ? EmailAdditionalDataFactory({
            mailID: event.emailAdditionalData.mailID,
          })
        : undefined,
      pushAdditionalData: event.pushAdditionalData
        ? PushAdditionalDataFactory({
            transmissionUnitID: event.pushAdditionalData.transmissionUnitID,
          })
        : undefined,
      smsAdditionalData: event.smsAdditionalData
        ? SMSAdditionalDataFactory({
            smsID: event.smsAdditionalData.smsID,
          })
        : undefined,
      orchestrationMetadata: OrchestrationMetadataFactory({
        orchestrationID: event.orchestrationMetadata.orchestrationID,
        orchestrationName: event.orchestrationMetadata.orchestrationName,
        orchestrationType: event.orchestrationMetadata.orchestrationType,
        stepID: event.orchestrationMetadata.stepID,
        stepName: event.orchestrationMetadata.stepName,
      }),
    })
  }
}

export const parseEventToRecord = (event: Event): EventRecord => {
  return EventFactory({
    customEvent: event.customEvent ? parseCustomEventToRecord(event.customEvent) : undefined,
    messagingEvent: event.messagingEvent
      ? parseMessagingEventToRecord(event.messagingEvent)
      : undefined,
  })
}

export const parseAttributeValueToQueryValue = (attributeValue: RawAttributeValue): QueryValue => {
  if (attributeValue.valueAttr) {
    return {
      type: attributeValue.valueAttr.type,
      stringValue: attributeValue.valueAttr.stringValue,
      boolValue: attributeValue.valueAttr.boolValue,
      longValue:
        attributeValue.valueAttr?.type === 'LONG'
          ? parseInt(attributeValue.valueAttr.longValue, 10)
          : undefined,
      doubleValue:
        attributeValue.valueAttr?.type === 'DOUBLE'
          ? parseFloat(attributeValue.valueAttr.doubleValue)
          : undefined,
      versionValue: attributeValue.valueAttr?.versionValue,
      urlValue: attributeValue.valueAttr?.urlValue,
      dateValue:
        attributeValue.valueAttr?.type === 'DATE' && attributeValue.valueAttr?.dateValue
          ? dayjs.utc(attributeValue.valueAttr.dateValue)
          : undefined,
    }
  }
  const objectV = attributeValue.objectAttr?.objectV
  if (objectV) {
    const obj: { [string]: QueryValue } = {}
    Object.keys(objectV).map(key => {
      const attr = objectV[key]
      obj[key] = parseAttributeValueToQueryValue(attr)
    })
    return {
      type: 'OBJECT',
      objectValue: obj,
    }
  }
  return {
    type: 'ARRAY',
    arrayValue: attributeValue.arrayAttr?.arrayV.map(av => parseAttributeValueToQueryValue(av)),
  }
}

export const parseBrowser = (browser: string): Browser => {
  switch (browser.toUpperCase()) {
    case 'CHROME':
      return 'CHROME'
    case 'SAFARI':
      return 'SAFARI'
    case 'EDGE':
      return 'EDGE'
    case 'FIREFOX':
      return 'FIREFOX'
    default:
      return 'UNKNOWN'
  }
}

export const parseProfilePlatformsToRecord = (
  profile: Profile,
  computedNatives: ComputedNatives,
  installs: Array<Install>
): ProfilePlatformsRecord => {
  let mobilePlatforms = []
  let webPushPlatforms = []

  profile.identifiersWithData.forEach(current => {
    const install = current.identifier.install

    if (install) {
      const installId = install.installId
      const platform = install.apiKey.platform

      const device = installs.find(f => f.installID.installId === installId)
      const recipient = profile.recipientsWithData?.find(
        f => f.recipient.pushToken?.install.installId === installId
      )

      const pushToken = recipient?.recipient.pushToken?.pushToken ?? ''

      switch (platform) {
        case 'WEBPUSH': {
          const browserType = device?.browser ?? ''

          return webPushPlatforms.push([
            installId,
            ProfileWebpushPlatformFactory({
              browser: parseBrowser(browserType),
              browserType,
              browserVersion: device?.browserVersion ?? '',
              installId,
              lastActivity: current.data.lastActivity ?? '',
              pushToken,
              subscriptionStatus: pushToken ? 'SUBSCRIBED' : 'UNSUBSCRIBED',
            }),
          ])
        }

        default: {
          const notifType = recipient?.data.pushTokenData.subscription.notifType.value
            ? parseNotif(recipient?.data.pushTokenData.subscription.notifType.value.toString())
            : []

          return mobilePlatforms.push([
            installId,
            InstallFactory({
              installID: installId,
              lastActivity: current.data.lastActivity ?? '',
              marketingName: device?.deviceType ?? '',
              notifType,
              platform,
              pushToken,
              subscriptionStatus: pushToken && notifType.length > 0 ? 'SUBSCRIBED' : 'UNSUBSCRIBED',
              type: device?.deviceType ?? getPlatformName(platform),
            }),
          ])
        }
      }
    }
  })

  profile.recipientsWithData?.forEach(current => {
    const importedPushToken = current.recipient.importedPushToken
    if (importedPushToken) {
      return mobilePlatforms.push([
        `imported_${importedPushToken.pushToken}`,
        InstallFactory({
          isImported: true,
          platform: importedPushToken.apiKey.platform,
          pushToken: importedPushToken.pushToken,
          subscriptionStatus: 'SUBSCRIBED',
        }),
      ])
    }
  })

  return ProfilePlatformsFactory({
    mobile: Immutable.Map(mobilePlatforms),
    webpush: Immutable.Map(webPushPlatforms),
  })
}

export const parseProfileModelToRecord = (
  profile: Profile,
  computedNatives: ComputedNatives,
  installs: Array<Install>
): ProfileDataRecord => {
  let pushSubscriptionStatus = 'UNSUBSCRIBED'

  return ProfileDataFactory({
    id: profile.id.data,
    identity: IdentityFactory({
      customUserId: profile.identifiers.customID,
      region: RegionFactory({
        value: computedNatives.profileRegion,
        label: _get(regions, computedNatives.profileRegion, computedNatives.profileRegion),
      }),
      language: LanguageFactory({
        value: computedNatives.profileLanguage,
        label: _get(languages, computedNatives.profileLanguage, computedNatives.profileLanguage),
      }),
    }),
    email: profile.recipients.email,
    phoneNumber: profile.recipients.phoneNumber,
    lastVisit: LastVisitFactory({
      platform: profile.lastVisitPlatform,
      date: profile.lastVisitDate,
    }),
    lastActivity: profile.lastActivity ?? '',
    subscription: SubscriptionFactory({
      emailMarketing: SubscriptionEmailFactory({
        status: profile.subscriptionStates?.marketing,
        lastEmailOpened: profile.lastEmailMarketingOpen,
        lastEmailClicked: profile.lastEmailMarketingClick,
      }),
      emailService: SubscriptionEmailFactory({
        status: profile.subscriptionStates?.transactional,
        lastEmailOpened: profile.lastEmailTransactionalOpen,
        lastEmailClicked: profile.lastEmailTransactionalClick,
      }),
      smsMarketing: SubscriptionSmsFactory({
        status: profile.smsSubscriptionStates?.marketing,
      }),
      push: SubscriptionPushFactory({
        status: pushSubscriptionStatus,
      }),
    }),
    platforms: parseProfilePlatformsToRecord(profile, computedNatives, installs),
    attributes: Object.keys(profile.profileAttributes || {}).reduce(
      (attr: Map<string, QueryValue>, name) => {
        const profileAttribute = profile.profileAttributes[name]
        return attr.set(name, parseAttributeValueToQueryValue(profileAttribute))
      },
      Immutable.Map()
    ),
    timezone: !computedNatives.profileTimezone.includes('GMT')
      ? computedNatives.profileTimezone
      : null,
  })
}
