// @flow
export const AGE = 'AGE'
export const BOOLEAN = 'BOOLEAN'
export const DATE = 'DATE'
export const EVENT = 'EVENT'
export const FLOAT = 'FLOAT'
export const INTEGER = 'INTEGER'
export const POSITION = 'POSITION'
export const RETARGETING = 'RETARGETING'
export const AUDIENCE = 'AUDIENCE'
export const SET = 'SET'
export const STRING = 'STRING'
export const TAG = 'TAG'
export const VERSION = 'VERSION'
export const URL = 'URL'
export default {
  AGE,
  BOOLEAN,
  DATE,
  EVENT,
  FLOAT,
  INTEGER,
  POSITION,
  RETARGETING,
  SET,
  AUDIENCE,
  STRING,
  TAG,
  VERSION,
  URL,
}
