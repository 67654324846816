// @flow

import { type Dayjs } from 'dayjs'
import Immutable, {
  type List,
  type Map,
  type RecordFactory,
  type RecordOf,
  type Set,
} from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type CampaignRecord } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type PushDataProps = {
  accepted: number,
  sent: number,
  sentAlg: number,
  sentKnownDi: number,
  sentNotifOn: number,
  open: number,
  openRate: number,
  openAlg: number,
  reengageRate: number,
  influencedOpen: number,
  devSent: number,
  devOpen: number,
  reengaged: number,
  unregistered: number,
}
export const PushDataFactory: RecordFactory<PushDataProps> = Immutable.Record(
  ({
    accepted: 0,
    sent: 0,
    sentAlg: 0,
    sentKnownDi: 0,
    sentNotifOn: 0,
    open: 0,
    openAlg: 0,
    openRate: 0,
    reengageRate: 0,
    influencedOpen: 0,
    devSent: 0,
    devOpen: 0,
    reengaged: 0,
    unregistered: 0,
  }: PushDataProps)
)

export type PushDataRecord = RecordOf<PushDataProps>

type PushWithVariantProps = {
  total: PushDataRecord,
  a: PushDataRecord,
  b: PushDataRecord,
}
export const PushWithVariantFactory: RecordFactory<PushWithVariantProps> = Immutable.Record(
  ({
    total: PushDataFactory(),
    a: PushDataFactory(),
    b: PushDataFactory(),
  }: PushWithVariantProps)
)

export type PushWithVariantRecord = RecordOf<PushWithVariantProps>

type ClickDataProps = {
  total: number,
  withAction: number,
  global: number,
  globalRate: number,
  cta1: number,
  cta1Rate: number,
  cta2: number,
  cta2Rate: number,
  webview: number,
  webviewRate: number,
  analyticsIds: Map<string, number>,
}
export const ClickDataFactory: RecordFactory<ClickDataProps> = Immutable.Record(
  ({
    total: 0,
    withAction: 0,
    global: 0,
    globalRate: 0,
    cta1: 0,
    cta1Rate: 0,
    cta2: 0,
    cta2Rate: 0,
    webview: 0,
    webviewRate: 0,
    analyticsIds: Immutable.Map(),
  }: ClickDataProps)
)

export type ClickDataRecord = RecordOf<ClickDataProps>

type InAppDataProps = {
  click: ClickDataRecord,
  display: number,
  supplied: number, // ce choix de woring est pas cohérent, supply serait mieux
  close: number,
  closeRate: number,
  triggerRate: number,
  clickRate: number,
}
export const InAppDataFactory: RecordFactory<InAppDataProps> = Immutable.Record(
  ({
    click: ClickDataFactory(),
    close: 0,
    closeRate: 0,
    display: 0,
    supplied: 0,
    triggerRate: 0,
    clickRate: 0,
  }: InAppDataProps)
)

export type InAppDataRecord = RecordOf<InAppDataProps>

type InAppWithVariantProps = {
  total: InAppDataRecord,
  a: InAppDataRecord,
  b: InAppDataRecord,
}
export const InAppWithVariantFactory: RecordFactory<InAppWithVariantProps> = Immutable.Record(
  ({
    total: InAppDataFactory(),
    a: InAppDataFactory(),
    b: InAppDataFactory(),
  }: InAppWithVariantProps)
)

export type InAppWithVariantRecord = RecordOf<InAppWithVariantProps>

type ExitDataProps = {
  push: number,
  query: number,
  event: number,
  stop: number,
  noToken: number,
  ...
}
export const ExitDataFactory: RecordFactory<ExitDataProps> = Immutable.Record(
  ({
    push: 0,
    query: 0,
    event: 0,
    stop: 0,
    noToken: 0,
  }: ExitDataProps)
)

export type ExitDataRecord = RecordOf<ExitDataProps>

type TriggerDataProps = {
  entered: number,
  waiting: number,
  exited: ExitDataRecord,
  ...
}
export const TriggerDataFactory: RecordFactory<TriggerDataProps> = Immutable.Record(
  ({
    entered: 0,
    waiting: 0,
    exited: ExitDataFactory(),
  }: TriggerDataProps)
)

export type TriggerDataRecord = RecordOf<TriggerDataProps>

type DataIdentifierProps = {
  kind: 'category' | 'groupId' | 'token',
  value: string,
  campaignInfo: ?CampaignRecord,
  ...
}
export const DataIdentifierFactory: RecordFactory<DataIdentifierProps> = Immutable.Record(
  ({
    kind: 'category',
    value: 'marketing',
    campaignInfo: null,
  }: DataIdentifierProps)
)

export type DataIdentifierRecord = RecordOf<DataIdentifierProps>

type DayDataProps = {
  period: Dayjs,
  id: DataIdentifierRecord,
  push: PushWithVariantRecord,
  errors: Map<string, number>,
  skipped: Map<string, number>,
  inapp: ?InAppWithVariantRecord,
  trigger: ?TriggerDataRecord,
  ...
}
export const DayDataFactory: RecordFactory<DayDataProps> = Immutable.Record(
  ({
    period: dayjs(),
    id: DataIdentifierFactory(),
    push: PushWithVariantFactory(),
    errors: Immutable.Map(),
    skipped: Immutable.Map(),
    inapp: null,
    trigger: null,
  }: DayDataProps)
)

export type DayDataRecord = RecordOf<DayDataProps>

type CampaignDataProps = {
  push: PushWithVariantRecord,
  errors: Map<string, number>,
  skipped: Map<string, number>,
  inapp: InAppWithVariantRecord,
  trigger: TriggerDataRecord,
  loading: boolean,
  lastSend: ?Dayjs,
  ...
}
export const CampaignDataFactory: RecordFactory<CampaignDataProps> = Immutable.Record(
  ({
    push: PushWithVariantFactory(),
    errors: Immutable.Map(),
    skipped: Immutable.Map(),
    inapp: InAppWithVariantFactory(),
    trigger: TriggerDataFactory(),
    loading: false,
    lastSend: null,
  }: CampaignDataProps)
)

export type CampaignDataRecord = RecordOf<CampaignDataProps>

export type fetchingState = 'INIT' | 'LOADING' | 'LOADED' | 'ERROR'

type DataCampaignStateProps = {
  data: List<DayDataRecord>,
  loading: Set<string>,
  loaded: Set<string>,
  campaign: Map<string, CampaignDataRecord>,
  status: fetchingState,
  ...
}
export const DataCampaignStateFactory: RecordFactory<DataCampaignStateProps> = Immutable.Record(
  ({
    data: new Immutable.List(),
    loaded: Immutable.Set(),
    loading: Immutable.Set(),
    campaign: Immutable.Map(),
    status: STATUS.INIT,
  }: DataCampaignStateProps)
)

export type DataCampaignStateRecord = RecordOf<DataCampaignStateProps>
