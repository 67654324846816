// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

import { type LabelRecord } from 'com.batch/labels/models/labels.records'

type DeleteProjectLabelAction = ReduxAction<'DELETE_PROJECT_LABEL', string>

export type DeleteProjectLabelFailureAction = ReduxAction<'DELETE_PROJECT_LABEL_FAILURE', string>

export type DeleteProjectLabelSuccessAction = ReduxAction<'DELETE_PROJECT_LABEL_SUCCESS', string>

export type DeleteProjectLabelActionType =
  | DeleteProjectLabelAction
  | DeleteProjectLabelSuccessAction
  | DeleteProjectLabelFailureAction

export const deleteLabel = (label: LabelRecord): DispatchExtraBoundFn<Promise<string>> => {
  return async (dispatch, getState, { metadataService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: metadataService
        .unsetLabels({
          projectKey: project.projectKey,
          labelCodes: [label.code],
        })
        .then(() => {
          dispatch(
            showToast({ kind: 'success', title: 'Delete success', message: 'Label deleted' })
          )
          return label.code
        })
        .catch(() => {
          dispatch(
            showToast({ kind: 'error', title: 'Delete error', message: 'Unable to delete label' })
          )
          return ''
        }),
      payload: label.code,
      actionName: 'DELETE_PROJECT_LABEL',
    })
  }
}
