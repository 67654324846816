// @flow

import { type Set } from 'immutable'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { ButtonNavLink } from 'components/common/button'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import { TableRow, TableCell, TableCellActions, TableTemplateCell } from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'
import { CampaignStateIcon } from 'components/project/campaign/common/campaign-state-icon'
import { Timezone } from 'components/styled/text'
import * as colors from 'components/styled/tokens/colors'

import { generateOrchestrationUrl } from 'com.batch.common/router/url-generator-helper'
import { campaignStateFormatter, percentage, kformat } from 'com.batch.common/utils'

import { type ReplicationOrchestrationMeta } from './replication-modal'
import { OrchestrationChannelFlags, OrchestrationNameWithFlagAndChannels } from './row.styles'

import { type ProjectRecord } from 'com.batch.redux/project.records'

import { type OrchestrationListStatsStateRecord } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { OrchestrationMenu } from 'com.batch/orchestration-list/ui/components/orchestration-menu'
import { When } from 'com.batch/orchestration-list/ui/components/when'

type RowProps = {
  partialOrchestration: PartialOrchestrationRecord,
  project: ProjectRecord,
  multipleChannelsSupported: boolean,
  schedulingType: schedulingType,
  data: OrchestrationListStatsStateRecord,
  openReplicationModalFor: ReplicationOrchestrationMeta => void,
}

const generateChannelIcon = (channel: ChannelUntilCleanup): availableIcons => {
  switch (channel) {
    case 'email':
      return 'mail'
    case 'sms':
      return 'sms'
    case 'push':
      return 'push'
    default:
      return 'push'
  }
}

const generateChannelLabel = (channel: ChannelUntilCleanup): string => {
  switch (channel) {
    case 'email':
      return 'Email'
    case 'sms':
      return 'SMS'
    case 'push':
      return 'Push'
    default:
      return 'Unknown'
  }
}

const channelWeight: { [ChannelUntilCleanup]: number } = {
  email: 1,
  push: 2,
  sms: 3,
}

const sortChannels = (channels: Set<ChannelUntilCleanup>): Array<ChannelUntilCleanup> => {
  return channels.toArray().sort((a, b) => (channelWeight[a] > channelWeight[b] ? 1 : -1))
}
export const Row = ({
  partialOrchestration,
  schedulingType,
  data,
  project,
  multipleChannelsSupported,
  openReplicationModalFor,
}: RowProps): React.Node => {
  const campaignWord = React.useMemo(() => {
    return schedulingType === 'automations' ? 'automation' : 'campaign'
  }, [schedulingType])
  const userIsAllowed = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const formUrl = React.useMemo(
    () =>
      generateOrchestrationUrl({
        channel: partialOrchestration.channels.first() ?? 'email',
        page: 'form',
        sendType: partialOrchestration.sendType,
        companyId: project.companyId,
        projectId: project.id,
        token: partialOrchestration.token,
      }),
    [partialOrchestration, project]
  )

  const onOpenReplicationModal = React.useCallback(
    () =>
      openReplicationModalFor({
        id: partialOrchestration.token,
        name: partialOrchestration.name,
        sendType: partialOrchestration.sendType,
        channels: partialOrchestration.channels,
      }),
    [
      openReplicationModalFor,
      partialOrchestration.token,
      partialOrchestration.name,
      partialOrchestration.sendType,
      partialOrchestration.channels,
    ]
  )

  const isOnlyChannelSms = React.useMemo(() => {
    return (
      partialOrchestration.channels.size === 1 && partialOrchestration.channels.first() === 'sms'
    )
  }, [partialOrchestration.channels])

  return (
    <TableRow
      key={partialOrchestration.token}
      style={{ height: multipleChannelsSupported ? 60 : 52 }}
    >
      <TableCell align="center" style={{ overflow: 'visible' }}>
        <Tooltip
          tooltip={
            <React.Fragment>
              This {campaignWord} is {campaignStateFormatter(partialOrchestration.state, true)}
            </React.Fragment>
          }
        >
          <CampaignStateIcon state={partialOrchestration.state} schedulingType={schedulingType} />
        </Tooltip>
      </TableCell>
      <TableCell>
        <OrchestrationNameWithFlagAndChannels $withAPIFlag={partialOrchestration.createdByApi}>
          <Tooltip
            tooltip={partialOrchestration.name}
            minWidth={Math.min(500, Math.max(50, partialOrchestration.name.length * 10))}
          >
            <Link
              style={{
                fontWeight: 500,
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              to={formUrl}
            >
              {partialOrchestration.name}
            </Link>
          </Tooltip>
          {partialOrchestration.createdByApi && <Timezone>API</Timezone>}
          {partialOrchestration.channels.size > 0 && multipleChannelsSupported && (
            <OrchestrationChannelFlags>
              {sortChannels(partialOrchestration.channels).map(channel => {
                return (
                  <li key={channel}>
                    <Icon icon={generateChannelIcon(channel)} />
                    <span>{generateChannelLabel(channel)}</span>
                  </li>
                )
              })}
            </OrchestrationChannelFlags>
          )}
        </OrchestrationNameWithFlagAndChannels>
      </TableCell>
      {!multipleChannelsSupported && (
        <TableCell>
          <Icon
            icon={generateChannelIcon(partialOrchestration.channels.first())}
            style={{ marginRight: '8px' }}
          />
          {generateChannelLabel(partialOrchestration.channels.first())}
        </TableCell>
      )}
      <TableCell style={{ paddingLeft: 22, overflow: 'initial' }}>
        <When partialOrchestration={partialOrchestration} />
      </TableCell>
      {['INIT', 'LOADING'].includes(data.loadingState) ? (
        <React.Fragment>
          <TableTemplateCell template="1fr" forceLoading />
          <TableTemplateCell template="1fr" forceLoading />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TableCell align="right">{data.delivered ? kformat(data.delivered) : '0'}</TableCell>
          <TableCell
            align="right"
            style={{ color: isOnlyChannelSms ? colors.textDisabled : colors.text }}
          >
            {isOnlyChannelSms ? '—' : data.openRate ? `${percentage(data.openRate, 2)}` : '0%'}
          </TableCell>
        </React.Fragment>
      )}
      <TableCellActions>
        <Tooltip tooltip="Edit" placement="top" minWidth={48}>
          <ButtonNavLink
            disabled={partialOrchestration.state === 'COMPLETED' && userIsAllowed}
            to={formUrl}
          >
            <Icon icon="edit" />
          </ButtonNavLink>
        </Tooltip>
        <OrchestrationMenu
          userHasWritePermission={userIsAllowed}
          data={{ partialOrchestration }}
          schedulingType={schedulingType}
          openReplication={onOpenReplicationModal}
        />
      </TableCellActions>
    </TableRow>
  )
}
