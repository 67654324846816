// @flow

import * as React from 'react'

import { DatePicker } from 'components/form'

import { type InputProps } from './helper'

export const InputDate = ({ condition, updateCondition, isInvalid }: InputProps): React.Node => {
  const onFocus = React.useCallback(() => {
    if (!condition.value.date.value && condition.value.date.valid) {
      updateCondition(
        condition.set(
          'value',
          condition.value.set('date', condition.value.date.set('valid', false))
        )
      )
    }
  }, [condition, updateCondition])

  const handleChangeDate = React.useCallback(
    date => {
      updateCondition(condition.set('value', condition.value.set('date', date)))
    },
    [condition, updateCondition]
  )
  return (
    <DatePicker
      hideFeedback
      invalid={isInvalid || !condition.value.date.valid}
      date={condition.value.date}
      onFocus={onFocus}
      onBlur={onFocus}
      onChange={handleChangeDate}
    />
  )
}
