// @flow

import styled, { type StyledComponent } from 'styled-components'

import { TableCell, TableCellHeader } from 'components/common/table/table.styles'
import { colors } from 'components/styled/tokens'

export const BouncePerMailboxProviderWrapper: StyledComponent<{ ... }, *, *> = styled.div`
  overflow-y: auto;
  border-radius: 8px;

  ${TableCell} {
    color: ${colors.textLight};
  }

  ${TableCellHeader} {
    color: ${colors.textNeutral};
  }
`

export const BounceCategoryHeader: StyledComponent<{ ... }, *, *> = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
`

export const BounceProviderCell: StyledComponent<{ ... }, *, *> = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.text};
`
