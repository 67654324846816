// @flow

import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'

import { type State, type CompanyRecord } from 'com.batch.redux/_records'
import { type UserStateRecord, type UserRecord, UserFactory } from 'com.batch.redux/user.records'

export const userStateSelector = (state: State): UserStateRecord => state.user
export const companyStateSelector = (state: State): CompanyRecord => state.company

const userIdsSelector = createSelector(companyStateSelector, c => c.users)
const usersMapSelector = createSelector(userStateSelector, u => u.entities)

export const currentUserSelector: State => UserRecord = createSelector(
  userStateSelector,
  (userState: UserStateRecord) =>
    userState.entities.get(userState.currentUserId ? userState.currentUserId : -1, UserFactory())
)
export const usersListSelector: State => List<UserRecord> = createSelector(usersMapSelector, map =>
  map.toList()
)
export const usersForCompanySelector: State => Map<number, UserRecord> = createSelector(
  userIdsSelector,
  usersMapSelector,
  (ids, users) => {
    let arrOfUsers = []
    ids.forEach(id => {
      arrOfUsers.push([id, users.get(id, UserFactory())])
    })

    return Immutable.Map(arrOfUsers)
  }
)
