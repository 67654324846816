// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { smsSenderSelector } from 'com.batch/sms/store/sms.selector'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { EditableField } from 'com.batch/message-builder/ui/components/editable-field'
import {
  CustomSenderContainer,
  FadeOverflow,
  MessageBubble,
  UnsubMessage,
} from 'com.batch/sms/ui/components/sms-preview/sms-preview.styles'

type Props = { messageId: string, ... }
export const SmsPreview = ({ messageId }: Props): React.Node => {
  const targetedUserbase = useSelector(subscriptionStatusSelector)
  const isMarketing = React.useMemo(() => targetedUserbase === 'marketing', [targetedUserbase])
  const sender = useSelector(smsSenderSelector)

  return (
    <React.Fragment>
      <CustomSenderContainer>{sender ?? 'Sender'}</CustomSenderContainer>
      <div style={{ overflowY: 'scroll', height: 300, width: 410, marginTop: 34, paddingLeft: 5 }}>
        <MessageBubble>
          <EditableField messageId={messageId} field="smsMessage"></EditableField>
          {isMarketing && (
            <UnsubMessage>
              STOP 36184
              <Tooltip
                tooltip="Unsubscribe message is a legal requirement in some countries. Batch adds it automatically if needed. Shortcode can differ from the preview."
                minWidth={600}
              >
                <Icon icon="help" style={{ marginLeft: 5 }} />
              </Tooltip>
            </UnsubMessage>
          )}
        </MessageBubble>
        <FadeOverflow />
      </div>
    </React.Fragment>
  )
}
