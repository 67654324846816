// @flow

import * as React from 'react'

import { AvatarNumber } from 'components/campaign/campaign-list.styles'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

type ToggleFiltersButtonProps = {
  count: number,
  onClick: any => void,
  ...
}

export const ToggleFiltersButton = ({ count, onClick }: ToggleFiltersButtonProps): React.Node => {
  if (count > 0) {
    return (
      <Button onClick={onClick} kind="inline" intent="action" addOn="prefix">
        <AvatarNumber>
          {count > 9 ? <Icon icon="add" size={8} style={{ marginTop: -1 }} /> : count}
        </AvatarNumber>
        More filters
      </Button>
    )
  }
  return (
    <Button kind="inline" intent="neutral" addOn="prefix" onClick={onClick}>
      <Icon icon="options" />
      More filters
    </Button>
  )
}
