// @flow

import * as React from 'react'

import { GrabbablePreview } from 'components/campaign/review/review/rsr.styles'

function getPosition(element: HTMLElement) {
  let yPosition = 0
  while (element) {
    yPosition += element.offsetTop - element.scrollTop + element.clientTop
    // $FlowFixMe t'es lourd gros
    element = element.offsetParent
  }
  return yPosition
}

const HEADER_MARGIN = 20
const MARGIN_BOTTOM = 20

type Props = {
  height: number,
  children: React.Node,
}

type RefElement = { current: null | HTMLElement }

export const PreviewScroller = ({ height, children }: Props): React.Node => {
  const [grabbed, setGrabbed] = React.useState<boolean>(false)
  const [availHeight, setAvailHeight] = React.useState<number>(2000)
  const offsetRef = React.useRef<number>(0)
  const refRef = React.useRef<number>(0)
  const lastScrollPosRef = React.useRef<number>(0)
  const elementRef: RefElement = React.useRef(null)
  const headerRef: RefElement = React.useRef(null)

  const getVisibleHeaderHeight = (): number => {
    let headerTop = 0
    let headerHeight = 0
    if (headerRef.current) {
      const dim = headerRef.current.getBoundingClientRect()
      headerTop = dim.top
      headerHeight = dim.height
    }
    const hh =
      headerTop > 0 ? headerHeight + headerTop : headerHeight + Math.max(-headerHeight, headerTop)
    return hh + HEADER_MARGIN
  }

  const updatePreviewPosition = React.useCallback((): void => {
    const vh = getVisibleHeaderHeight()
    if (elementRef.current) {
      elementRef.current.style.top = `${vh + offsetRef.current}px`
    }
  }, [])

  const limitOffset = React.useCallback(
    (offset: number, direction: 'top' | 'bottom'): number => {
      const top = elementRef.current ? getPosition(elementRef.current) : 0 // `getPosition` doit être typée ailleurs
      const bottom = top + height
      const vh = getVisibleHeaderHeight()
      const wh = window.innerHeight - MARGIN_BOTTOM
      let hiddenTop = 0
      let hiddenBottom = 0

      if (top < 0) hiddenTop = -top + vh
      else if (top < vh) hiddenTop = vh - top

      if (bottom > wh) hiddenBottom = bottom - wh

      if (direction === 'top') {
        const decalage = Math.abs(offset) - Math.abs(offsetRef.current)
        const grabbable = availHeight < height && window.innerWidth >= 680
        const grabOffset = offsetRef.current - Math.min(decalage, hiddenBottom)
        const nextHiddenBottom = grabOffset + height + vh - wh
        return nextHiddenBottom < 0 && grabbable ? grabOffset - nextHiddenBottom : grabOffset
      } else {
        return Math.min(offset, hiddenTop)
      }
    },
    [height, availHeight]
  )

  const onScroll = React.useCallback((): void => {
    const scrollPos = document.body ? document.body.getBoundingClientRect().top : 0
    const up = scrollPos > lastScrollPosRef.current
    lastScrollPosRef.current = scrollPos
    offsetRef.current = limitOffset(offsetRef.current, !up ? 'top' : 'bottom')
    updatePreviewPosition()
  }, [limitOffset, updatePreviewPosition])

  const calculateLimit = React.useCallback((): void => {
    setAvailHeight(window.innerHeight - getVisibleHeaderHeight())
    offsetRef.current = 0
    updatePreviewPosition()
  }, [updatePreviewPosition])

  const isGrabbable = React.useMemo(() => {
    return availHeight < height && window.innerWidth >= 680
  }, [availHeight, height])

  const onMouseMove = React.useCallback(
    (evt: MouseEvent): void => {
      evt.stopPropagation()
      evt.preventDefault()
      if (evt.screenY === refRef.current) return

      if (grabbed) {
        let offset = limitOffset(
          offsetRef.current + (evt.screenY - refRef.current),
          evt.screenY - refRef.current < 0 ? 'top' : 'bottom'
        )
        refRef.current = evt.screenY
        offsetRef.current = offset
        updatePreviewPosition()
      }
    },
    [grabbed, limitOffset, updatePreviewPosition]
  )

  const onMouseUp = React.useCallback((): void => {
    setGrabbed(false)
    refRef.current = 0
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
  }, [onMouseMove])

  const onMouseDown = React.useCallback(
    (evt: MouseEvent): void => {
      setGrabbed(true)
      refRef.current = evt.screenY
      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    },
    [onMouseMove, onMouseUp]
  )

  React.useEffect(() => {
    headerRef.current = document.getElementById('fixedReviewHead')
    setAvailHeight(window.innerHeight - getVisibleHeaderHeight())
    window.addEventListener('resize', calculateLimit)
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('resize', calculateLimit)
      window.removeEventListener('scroll', onScroll)
    }
  }, [calculateLimit, onScroll])

  React.useEffect(() => {
    updatePreviewPosition()
  }, [availHeight, updatePreviewPosition])

  return (
    <GrabbablePreview
      ref={elementRef}
      style={{ height }}
      grabbable={isGrabbable}
      grabbing={grabbed}
      onMouseDown={isGrabbable ? onMouseDown : null}
    >
      {children}
    </GrabbablePreview>
  )
}
