// @flow

import * as React from 'react'

type PageTrackerProps = {
  category: string,
  name: string,
  children: React.Node | null,
}

export const PageTracker: React.ComponentType<PageTrackerProps> = React.memo(
  ({ name, category, children }: PageTrackerProps) => {
    React.useEffect(() => {
      if (typeof window.analytics !== 'undefined' && typeof window.analytics.page === 'function') {
        // eslint-disable-next-line no-inner-declarations
        async function getConsentAndTrack() {
          const consent = await getConsent()
          let integrations: { [Destination]: any, ... } = destinations.reduce(
            (acc, current) => {
              acc[current] =
                current === 'Amplitude' && consent.includes(requiredConsentForDestination[current])
                  ? { session_id: window?.amplitudeSessionId ?? -1 }
                  : consent.includes(requiredConsentForDestination[current])
              return acc
            },
            ({}: { [Destination]: any, ... })
          )
          window.analytics.page(category, name, {}, { integrations })
        }

        getConsentAndTrack()
      }
    }, [category, name])
    return children
  }
)

export const trackingCategories = {
  appSettings: 'App Settings',
  data: 'Analytics',
  account: 'User Account',
  accountCompany: 'Company Account',
  push: 'Push Campaign',
  'in-app': 'In-App Campaign',
  email: 'Automations',
  campaigns: 'Campaigns',
  automations: 'Automations',
  register: 'Register',
  login: 'Login',
  userbase: 'Userbase',
}

export const trackingPages = {
  // settings
  integrate: 'Integrate',
  new: 'New',
  general: 'General',
  push: 'Push Settings',
  teamEdit: 'Edit Team',
  customData: 'Custom Data',
  themes: 'Themes List',
  themeNew: 'New Theme',
  themeEdit: 'Edit Theme',
  debugUser: 'Devices Debug',
  gdpr: 'GDPR Requests',
  debugTransac: 'Transactional Debug',
  label: 'Labels',
  capping: 'Cappings',
  profiles: 'Profiles',
  // data
  audience: 'Audiences',
  reach: 'Reach',
  pushOverview: 'Notifications',
  userbase: 'Userbase',
  profilebase: 'Profilebase',
  // account
  settings: 'Settings',
  security: 'Security',
  team: 'Team',
  companySettings: 'Company Settings',
  billing: 'Plans & Billing',
  privacy: 'GDPR & Privacy',
  // campaign
  list: 'List',
  edit: 'Edit',
  review: 'Review',
  data: 'Analytics',
  // register
  invite: 'Invite',
  password: 'Password',
  register: 'Register',
  congrats: 'Congratulations',
  '2FA': '2FA',
  company: 'Company',
  // login
  login: 'Login',
  login2FA: 'Login 2FA',
  resetRequest: 'Password Reset Request',
  reset: 'Password Reset',
  segments: 'Segments',
}

export const trackingEvents = {
  '2FA_CODES_COPY': '2FA Code Copied',
  '2FA_CODES_DL': '2FA Code Downloaded',
  ANALYTICS_EXPORT_CLICKED: 'Analytics Export Clicked',
  CAMPAIGN_EXPORT_CLICKED: 'Campaign Export Clicked',
  COLUMN_SORTED: 'Column Sorted',
  SEARCH_USERS: 'Users Searched',
  SEARCH_APPS: 'Apps Searched',
  SEARCH_LABELS: 'Labels Searched',
  FILTER_USERS: 'Users Filtered',
  SWITCH_APP: 'App Switched',
  MEDIA_UPL_CLICK: 'Campaign Media Button Clicked',
  SEARCH_ATTRIBUTES: 'Attributes searched',
  TEMPLATE_WIZARD: 'Dynamic Content Helper Clicked',
  TEMPLATE_WIZARD_ADD: 'Insert Code Button Clicked',
  THEMES_FILTERED: 'Themes filtered',
  THEME_ATTACHED: 'Theme Attached',
  CAMPAIGN_PAGER: 'Campaign List Page Changed',
  CERTIFICATE_UPLOAD: 'App Certificate Uploaded',
  APP_ADD_USER: 'App Added User',
  APP_REMOVE_USER: 'App Removed User',
  GDPR_DOWNLOAD: 'Gdpr Data File Downloaded',
  TEST_PUSH_SENT: 'Push Sent To Test Device',
}

export type TrackingEventCode = $Keys<typeof trackingEvents>
type Destination = 'All' | 'Intercom' | 'Amplitude' | 'Mixpanel'
type Consent = 'ESSENTIAL' | 'ANALYTICS' | 'MARKETING' | 'PERSONALIZATION'

const requiredConsentForDestination: { [Destination]: Consent, ... } = {
  All: 'ESSENTIAL',
  Intercom: 'MARKETING',
  Amplitude: 'ANALYTICS',
  Mixpanel: 'ANALYTICS',
}
const destinations: Array<Destination> = ['All', 'Intercom', 'Amplitude', 'Mixpanel']

// helper for parsing osano consent object
function gc(o: any, k: string) {
  return typeof o === 'object' && typeof o[k] === 'string' && o[k] === 'ACCEPT'
}

// resolves as an array of consent object (at least ['ESSENTIAL])
export async function getConsent(): Promise<Array<Consent>> {
  return new Promise(resolve => {
    if (typeof window.Osano === 'undefined') {
      resolve(['ESSENTIAL'])
    } else {
      window.Osano.cm.addEventListener('osano-cm-initialized', consentObject => {
        let c = []
        if (gc(consentObject, 'ESSENTIAL')) c.push('ESSENTIAL')
        if (gc(consentObject, 'ANALYTICS')) c.push('ANALYTICS')
        if (gc(consentObject, 'MARKETING')) c.push('MARKETING')
        if (gc(consentObject, 'PERSONALIZATION')) c.push('PERSONALIZATION')
        resolve(c)
      })
    }
  })
}

// if Segment is running, will ask osano for consent, and track an event on the consented integrations
export async function trackEvent(eventCode: TrackingEventCode, properties?: { ... }) {
  if (typeof window.analytics !== 'undefined' && typeof window.analytics.track === 'function') {
    const consent = await getConsent()
    let integrations: { [Destination]: any, ... } = destinations.reduce(
      (acc, current) => {
        acc[current] =
          current === 'Amplitude' && consent.includes(requiredConsentForDestination[current])
            ? { session_id: window?.amplitudeSessionId ?? -1 }
            : consent.includes(requiredConsentForDestination[current])
        return acc
      },
      ({}: { [Destination]: any, ... })
    )
    window.analytics.track(trackingEvents[eventCode], properties, { integrations })
  }
}

export function createEventTracker(eventCode: TrackingEventCode, properties?: { ... }): any {
  return () => trackEvent(eventCode, properties)
}

export const TrackingContext: React.Context<{
  eventLocation: string,
  searchEventCode: 'unset' | 'SEARCH_USERS' | 'SEARCH_LABELS' | 'SEARCH_APPS' | 'SEARCH_ATTRIBUTES',
  pagerEventCode: 'unset' | 'CAMPAIGN_PAGER',
  appId?: number,
  campaignType?: 'push' | 'in-app',
  schedulingType?: schedulingType,
  eventPlatform?: string,
  ...
}> = React.createContext({
  eventLocation: 'unset',
  searchEventCode: 'unset',
  pagerEventCode: 'unset',
  appId: undefined,
  campaignType: undefined,
  schedulingType: undefined,
  eventPlatform: 'unset',
})
