// @flow

import { type AllowedAudienceType } from 'com.batch/audience/model/audience-profile.records'

export const coerceAudienceType = (vale: AllowedAudienceType): string => {
  return vale === 'custom_ids'
    ? 'Custom ID'
    : vale === 'install_ids'
    ? 'Installation ID'
    : 'Advertising ID'
}
