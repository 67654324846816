// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Set } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { Box, BoxHeader, BoxBody, BoxSection, HeaderBoxTitle } from 'components/common/box'
import { Switch } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { DateTimePicker, InputWrapper, Radio, Input, Select } from 'components/form'
import * as colors from 'components/styled/tokens/colors'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { TimingOption } from './timing-option'

import { type pushCampaignSendType } from 'com.batch.redux/_records'

type WhenProps = {
  sendType: pushCampaignSendType,
  updateSendType: pushCampaignSendType => void,
  hideTrigger: boolean,
  hasEmailChannel: boolean,
  isTzAware: boolean,
  capping: ?number,
  updateCapping: (?number) => void,
  updateTzAware: boolean => void,
  start: ?Dayjs,
  updateStart: (?Dayjs) => void,
  end: ?Dayjs,
  updateEnd: (?Dayjs) => void,
  repeatFrequency: number,
  updateRepeatFrequency: number => void,
  repeatUnit: repeatUnitType,
  updateRepeatUnit: repeatUnitType => void,
  schedulingType: schedulingType,
}
type RepeatModeOption = { value: repeatUnitType, label: string, ... }

const repeatModeOptionToString = (opt: ?RepeatModeOption) => opt?.value ?? ''
const TIMING_OPT_MARGINS = [18, 0, 0, 0]
const REPEAT_OPTIONS: List<RepeatModeOption> = new Immutable.List().push(
  ...[
    { value: 'DAILY', label: 'day' },
    { value: 'WEEKLY', label: 'week' },
    { value: 'MONTHLY', label: 'month' },
  ]
)
export const When: React.ComponentType<WhenProps> = React.memo(
  ({
    sendType,
    updateSendType,
    hideTrigger,
    hasEmailChannel,
    isTzAware,
    updateTzAware,
    start,
    updateStart,
    capping,
    updateCapping,
    end,
    updateEnd,
    repeatFrequency,
    updateRepeatFrequency,
    repeatUnit,
    updateRepeatUnit,
    schedulingType,
  }: WhenProps): React.Node => {
    const isStartDayDisabled = React.useCallback(day => {
      return dayjs(day).isBefore(dayjs(), 'day')
    }, [])

    const [touched, setTouched] = React.useState<Set<string>>(Immutable.Set())
    const theme = useTheme()
    const createTzAwareUpdater = React.useCallback(
      (tzAware: boolean) => () => updateTzAware(tzAware),
      [updateTzAware]
    )
    const onChangeSendType = React.useCallback(
      sendType => () => updateSendType(sendType),
      [updateSendType]
    )
    const onCappingChange = React.useCallback(
      evt => {
        const newCapping = Number(evt.target.value)
        updateCapping(newCapping && newCapping > 0 ? newCapping : capping)
      },
      [capping, updateCapping]
    )
    const onToggleCapping = React.useCallback(
      (hasCapping: boolean) => updateCapping(hasCapping ? 1 : null),
      [updateCapping]
    )
    const onRepeatFrequencyChange = React.useCallback(
      evt => updateRepeatFrequency(Number(evt.target.value)),
      [updateRepeatFrequency]
    )
    const createOnFieldFocus = React.useCallback(
      field => () => setTouched(touched => touched.add(field)),
      []
    )
    const formatRepeatModeOption = React.useCallback(
      (opt: RepeatModeOption) => `${opt.label}${repeatFrequency > 1 ? 's' : ''}`,
      [repeatFrequency]
    )
    const onRepeatModeChange = React.useCallback(
      opt => updateRepeatUnit(opt?.value ?? 'DAILY'),
      [updateRepeatUnit]
    )
    // TODO vérifier BoxHeader
    return (
      <Box style={{ marginTop: 35 }} id="when">
        <BoxHeader
          style={{
            height: 'auto',
            display: 'block',
            padding: '16px 20px',
            ...(sendType === 'now'
              ? {
                  borderBottom: 0,
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }
              : {}),
          }}
        >
          <HeaderBoxTitle title="Timing" />
          {!theme?.disabledMode && (
            <div>
              {schedulingType === 'campaigns' && (
                <Grid
                  template={hideTrigger ? '1fr 1fr' : '1fr 1fr 1fr'}
                  margin={TIMING_OPT_MARGINS}
                  gap={12}
                >
                  <TimingOption
                    value="now"
                    isSelected={sendType === 'now'}
                    onChange={onChangeSendType('now')}
                    label="Now"
                  />
                  <TimingOption
                    value="scheduled"
                    isSelected={sendType === 'scheduled'}
                    onChange={onChangeSendType('scheduled')}
                    label="Scheduled"
                  />
                  {!hideTrigger && (
                    <TimingOption
                      value="trigger"
                      isSelected={sendType === 'trigger'}
                      onChange={onChangeSendType('trigger')}
                      label="Trigger"
                    />
                  )}
                </Grid>
              )}
            </div>
          )}
        </BoxHeader>

        {sendType !== 'now' && (
          <BoxBody style={{ overflow: 'hidden' }}>
            {sendType === 'scheduled' && (
              <BoxSection $padding>
                <InputWrapper
                  label="Send on"
                  hintMinSize={240}
                  hint={
                    hasEmailChannel ? (
                      <div style={{ textAlign: 'left' }}>
                        If you choose local time, profiles with no timezones will receive the
                        message at global time (UTC).
                      </div>
                    ) : null
                  }
                >
                  <Grid template={'370px 1fr'}>
                    <div style={{ marginRight: 15 }}>
                      <DateTimePicker
                        date={start}
                        onChange={updateStart}
                        onFocus={createOnFieldFocus('start')}
                        invalid={!start && touched.has('start')}
                        disabledDays={isStartDayDisabled}
                      />
                    </div>

                    <div
                      role="radiogroup"
                      aria-label="Timezone"
                      style={{ display: 'inline-flex', gap: 10 }}
                    >
                      <Radio
                        label="Local&nbsp;time"
                        onChange={createTzAwareUpdater(true)}
                        checked={isTzAware}
                      />

                      <Radio
                        label="Global time (UTC)"
                        onChange={createTzAwareUpdater(false)}
                        checked={!isTzAware}
                      />
                    </div>
                  </Grid>
                </InputWrapper>
              </BoxSection>
            )}
            {sendType === 'recurring' && (
              <BoxSection $padding>
                <Grid template="1fr 1px 1fr" gap={32}>
                  <InputWrapper label="First message sent on">
                    <DateTimePicker
                      date={start}
                      onChange={updateStart}
                      invalid={!start && touched.has('start')}
                      onFocus={createOnFieldFocus('start')}
                      disabledDays={isStartDayDisabled}
                    />
                  </InputWrapper>
                  <div style={{ borderLeft: '1px solid #F0F0F0', height: 65 }} />
                  <InputWrapper label="Until" style={{ marginTop: 0 }}>
                    <DateTimePicker
                      date={end}
                      onChange={updateEnd}
                      invalid={touched.has('end') && (!end || (!!start && end.isBefore(start)))}
                      onFocus={createOnFieldFocus('end')}
                      disabledDays={isStartDayDisabled}
                    />
                  </InputWrapper>
                </Grid>

                <Grid template="1fr 47%">
                  <InputWrapper label="Timezone">
                    <Grid template={'120px auto'}>
                      <Radio
                        label="Local time"
                        onChange={createTzAwareUpdater(true)}
                        checked={isTzAware}
                      />

                      <Radio
                        label="Global time (UTC)"
                        onChange={createTzAwareUpdater(false)}
                        checked={!isTzAware}
                      />
                    </Grid>
                  </InputWrapper>
                  <InputWrapper label="Frequency">
                    <Grid template="50px 150px">
                      <Input
                        value={repeatFrequency}
                        onChange={onRepeatFrequencyChange}
                        type="number"
                      />
                      <Select
                        options={REPEAT_OPTIONS}
                        optionFormatter={formatRepeatModeOption}
                        optionToString={repeatModeOptionToString}
                        value={REPEAT_OPTIONS.find(opt => opt.value === repeatUnit)}
                        onChange={onRepeatModeChange}
                      />
                    </Grid>
                  </InputWrapper>
                </Grid>
              </BoxSection>
            )}

            {sendType === 'recurring' && (
              <BoxSection
                style={{
                  borderTop: '1px solid #eeeeee',
                  padding: capping !== null ? '20px' : '20px 20px 12px 20px',
                }}
              >
                <InputWrapper
                  label={
                    <Switch
                      isActive={capping !== null}
                      onChange={onToggleCapping}
                      disabled={theme?.disabledMode}
                    >
                      <p
                        style={{
                          color: theme?.disabledMode ? colors.textDisabled : colors.text,
                        }}
                      >
                        Capping
                      </p>
                    </Switch>
                  }
                >
                  {capping !== null && (
                    <Grid template="65px 50px 1fr">
                      Send only
                      <Input value={capping} type="number" onChange={onCappingChange} />
                      notification{capping && capping > 1 ? 's' : ''} per user
                    </Grid>
                  )}
                </InputWrapper>
              </BoxSection>
            )}
          </BoxBody>
        )}
      </Box>
    )
  }
)

When.displayName = 'When'
