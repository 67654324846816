// @flow

// DATA ===============
import { useSelector } from 'react-redux'

import {
  type appPermissionType,
  type UserRecord,
  type companyPermissionType,
} from 'com.batch.redux/user.records'
import { currentUserSelector } from 'com.batch.redux/user.selector'
// TYPE ===============

// check[1] ====> crud access perm
// check[0] ===> app or company

export const useUserHasPermission = (
  user: UserRecord,
  check: ['app', appPermissionType] | ['company', companyPermissionType]
): boolean => {
  const allowed: boolean = // $FlowFixMe type refinement is broken here
    (check[0] === 'app' && user.permissionsForCurrentApp.has(check[1])) || // $FlowFixMe type refinement is broken here
    (check[0] === 'company' && user.permissionsForCurrentCompany.has(check[1]))
  return allowed
}

export const useIsCurrentUserAllowedTo = ([ressource, permission]:
  | ['app', appPermissionType]
  | ['company', companyPermissionType]): boolean => {
  const currentUser = useSelector(currentUserSelector)
  // $FlowExpectedError
  const allowed = useUserHasPermission(currentUser, [ressource, permission])
  return allowed
}
