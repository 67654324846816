// @flow
import * as React from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export const useResizeObserver = (): [React.Ref<any>, number, number] => {
  const ref = React.useRef<null | HTMLElement>(null)
  const [width, changeWidth] = React.useState(ref.current ? ref.current.clientWidth : 1)
  const [height, changeHeight] = React.useState(1)
  React.useEffect(() => {
    const element = ref.current
    if (element !== null) {
      const resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries)) {
            return
          }

          // Since we only observe the one element, we don't need to loop over the
          // array
          if (!entries.length) {
            return
          }

          const entry = entries[0]
          if (width !== entry.contentRect.width) changeWidth(entry.contentRect.width)
          if (height !== entry.contentRect.height) changeHeight(entry.contentRect.height)
        })
      })
      resizeObserver.observe(element)
      return () => resizeObserver.unobserve(element)
    }
  }, [height, ref, width])

  return [ref, width, height]
}

export const useWidthObserver = (
  ref: { current: null | HTMLElement },
  defaultWidth: number
): number => {
  // needed cause we use rqf to update state (we use rqf cause we had observer loops without)
  const mounted = React.useRef(false)
  const [width, changeWidth] = React.useState(
    ref?.current ? ref?.current.clientWidth : defaultWidth
  )

  React.useEffect(() => {
    const element = ref.current
    mounted.current = true
    if (element !== null) {
      const resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !mounted.current) {
            return
          }

          // Since we only observe the one element, we don't need to loop over the
          // array
          if (!entries.length) {
            return
          }

          const entry = entries[0]
          if (width !== entry.contentRect.width) changeWidth(entry.contentRect.width)
        })
      })
      resizeObserver.observe(element)
      return () => {
        mounted.current = false
        resizeObserver.unobserve(element)
      }
    }
  }, [ref, width])

  return width
}
