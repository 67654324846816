// @flow
const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
export function numberOnly(evt: KeyboardEvent): void {
  const iSNumber = numbers.includes(evt.key)
  const isDelete = evt.keyCode === 46 || evt.keyCode === 8
  const isArrow = evt.keyCode > 36 || evt.keyCode < 41
  const isTab = evt.keyCode === 9
  if (!evt.ctrlKey && !evt.metaKey && !iSNumber && !isArrow && !isDelete && !isTab) {
    evt.preventDefault()
  }
}
