/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'

import {
  Table,
  TableCellOrder,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
} from 'components/common/table'

import { numberFormat } from 'com.batch.common/utils'

import { ReachListEmptyTemplate } from './analytics-utils'

import { type ReachByDayRecord, ReachByDayFactory } from 'com.batch.redux/stat.records'

type sortableFields =
  | 'period'
  | 'tokens'
  | 'tokensNotifOn'
  | 'optOuts'
  | 'newOptins'
  | 'deletedTokens'

type ReachTableProps = {
  sortBy: sortableFields,
  sortOrder: 'asc' | 'dsc',
  updateSort: sortableFields => void,
  isWeb: boolean,
  isLoading: boolean,
  list: List<ReachByDayRecord>,
}

const emptyTable: List<ReachByDayRecord> = new Immutable.List().push(
  ...new Array(10).fill(ReachByDayFactory())
)

const ReachTableRaw = ({
  sortOrder,
  sortBy,
  updateSort,
  list,
  isWeb,
  isLoading,
}: ReachTableProps) => {
  return (
    <div>
      <Table template={`130px repeat(${isWeb ? 3 : 4}, 1fr)`}>
        <TableHeader>
          <TableCellOrder
            sort={sortBy === 'period' ? sortOrder : false}
            onClick={() => updateSort('period')}
          >
            Period
          </TableCellOrder>
          <TableCellOrder
            sort={sortBy === 'tokens' ? sortOrder : false}
            onClick={() => updateSort('tokens')}
            align="right"
          >
            {isWeb ? 'Total opt-ins' : 'Total Tokens'}
          </TableCellOrder>
          {!isWeb && (
            <TableCellOrder
              sort={sortBy === 'tokensNotifOn' ? sortOrder : false}
              onClick={() => updateSort('tokensNotifOn')}
              align="right"
            >
              Total Opt-ins
            </TableCellOrder>
          )}
          <TableCellOrder
            sort={sortBy === 'newOptins' ? sortOrder : false}
            onClick={() => updateSort('newOptins')}
            align="right"
          >
            New Opt-ins
          </TableCellOrder>
          {isWeb ? (
            <TableCellOrder
              align="right"
              sort={sortBy === 'deletedTokens' ? sortOrder : false}
              onClick={() => updateSort('deletedTokens')}
            >
              Opt-outs
            </TableCellOrder>
          ) : (
            <TableCellOrder
              align="right"
              sort={sortBy === 'optOuts' ? sortOrder : false}
              onClick={() => updateSort('optOuts')}
            >
              Opt-outs
            </TableCellOrder>
          )}
        </TableHeader>
        <TableBody emptyTemplate={<ReachListEmptyTemplate />}>
          {(isLoading || list.size <= 0 ? emptyTable : list).map((row, index) => {
            const newOptins = row.data.changes.newTokensNotifOn + row.data.changes.toNotifOn
            const optOuts = row.data.changes.deletedTokensNotifOn + row.data.changes.toNotifOff
            return (
              <TableRow key={index}>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{row.date.format('MMMM DD')}</TableCell>
                <TableCell align="right">{numberFormat(row.data.count.tokens)}</TableCell>
                {!isWeb && (
                  <TableCell align="right">{numberFormat(row.data.count.tokensNotifOn)}</TableCell>
                )}
                <TableCell align="right">
                  {newOptins === 0 ? '—' : numberFormat(newOptins)}
                </TableCell>
                {isWeb ? (
                  <TableCell align="right">
                    {row.data.changes.deletedTokens === 0
                      ? '—'
                      : numberFormat(row.data.changes.deletedTokens)}
                  </TableCell>
                ) : (
                  <TableCell align="right">{optOuts === 0 ? '—' : numberFormat(optOuts)}</TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}
export const ReachTable: React.ComponentType<ReachTableProps> =
  React.memo<ReachTableProps>(ReachTableRaw)
