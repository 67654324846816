// @flow

import { fetchOrchestrationsList, setPage } from './fetch-orchestrations-list'

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type DeleteOrchestrationAction = ReduxAction<'DELETE_ORCHESTRATION', string>
export type DeleteOrchestrationSuccessAction = ReduxAction<'DELETE_ORCHESTRATION_SUCCESS', string>
export type DeleteOrchestrationFailureAction = ReduxAction<
  'DELETE_ORCHESTRATION_FAILURE',
  { error: string, token: string },
>

export type DeleteOrchestrationActions =
  | DeleteOrchestrationAction
  | DeleteOrchestrationSuccessAction
  | DeleteOrchestrationFailureAction

export const deleteOrchestration = (token: string): DispatchExtraBoundFn<Promise<any>> => {
  return async (dispatch, getState, { orchestrationService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    try {
      dispatch(
        ({
          type: 'DELETE_ORCHESTRATION',
          payload: token,
        }: DeleteOrchestrationAction)
      )
      await orchestrationService.delete({ project, token })
      dispatch(
        ({
          type: 'DELETE_ORCHESTRATION_SUCCESS',
          payload: token,
        }: DeleteOrchestrationSuccessAction)
      )
      const result = await dispatch(
        fetchOrchestrationsList({ cacheMode: 'TRASH_AFTER_CURRENT_PAGE' })
      )
      if (result.entities.size === 0 && result.page > 1) {
        await dispatch(setPage(result.page - 1))
      }
    } catch (err) {
      dispatch(
        ({
          type: 'DELETE_ORCHESTRATION_FAILURE',
          payload: { error: err, token },
        }: DeleteOrchestrationFailureAction)
      )
      throw { token, error: err?.error?.errors?.[0]?.message ?? 'Unknown error' }
    }
  }
}
