// @flow

import { List } from 'immutable'

import { computeRate } from './compute'

import {
  type ClickLinkRecord,
  ClickLinkFactory,
} from 'com.batch/orchestration-analytics/models/click-links-detail.record'
import {
  type ClickDetail,
  type ClickLinkName,
  type ClickUrlCleaned,
} from 'com.batch/shared/infra/types/grpc-stats-service'

export const parseClickLinks = ({
  clickDetails,
}: {
  clickDetails: ?ClickDetail,
}): List<ClickLinkRecord> => {
  if (!clickDetails) return new List()

  const mapClicksDetails = (data: ClickLinkName[] | ClickUrlCleaned[]): ClickLinkRecord[] =>
    data.map((item: ClickLinkName | ClickUrlCleaned) => {
      return ClickLinkFactory({
        name: item.linkName ?? item.urlCleaned ?? '',
        count: item.count,
        unique: item.uniqueCount,
        rate: computeRate(item.uniqueCount, clickDetails?.uniqueCount ?? 0),
      })
    })

  return new List()
    .push(...mapClicksDetails(clickDetails.byLinkName ?? []))
    .push(...mapClicksDetails(clickDetails.byUrlCleaned ?? []))
}
