// @flow
import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map, type RecordFactory, type RecordOf } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type TestDeviceRecord } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type DebugInstallProps = {
  apiKey: string,
  loading: boolean,
  customId: ?string,
  timezone: string,
  platform: string,
  installationId: string,
  notificationStatus: string,
  device: ?TestDeviceRecord,
  attributes: Immutable.OrderedMap<string, any>,
  prettifiedValues: Immutable.Map<string, any>,
  ...
}

export const DebugInstallFactory: RecordFactory<DebugInstallProps> = Immutable.Record(
  ({
    apiKey: '',
    loading: false,
    customId: null,
    installationId: '',
    platform: 'ios',
    timezone: 'unknown',
    notificationStatus: 'unknown',
    device: null,
    attributes: Immutable.OrderedMap(),
    prettifiedValues: Immutable.Map(),
  }: DebugInstallProps)
)

export type DebugInstallRecord = RecordOf<DebugInstallProps>

type ErrorProps = {
  code: string,
  count: number,
  ...
}

export const ErrorFactory: RecordFactory<ErrorProps> = Immutable.Record(
  ({
    code: '',
    count: 0,
  }: ErrorProps)
)

export type ErrorRecord = RecordOf<ErrorProps>

type AnalyticDataProp = {
  daus: number,
  maus: number,
  starts: number,
  installs: number,
  pushes: number,
  transactions: number,
  tokens: number,
  deletedTokens: number,
  optOut: number,
  ...
}

export const AnalyticDataFactory: RecordFactory<AnalyticDataProp> = Immutable.Record(
  ({
    daus: 0,
    maus: 0,
    starts: 0,
    pushes: 0,
    installs: 0,
    transactions: 0,
    deletedTokens: 0,
    tokens: 0,
    optOut: 0,
  }: AnalyticDataProp)
)

export type AnalyticDataRecord = RecordOf<AnalyticDataProp>

type AnalyticByPeriodProp = {
  period: Dayjs,
  events: Map<string, number>,
  data: AnalyticDataRecord,
  ...
}
export const AnalyticByPeriodFactory: RecordFactory<AnalyticByPeriodProp> = Immutable.Record(
  ({
    period: dayjs(),
    events: Immutable.Map(),
    data: AnalyticDataFactory(),
  }: AnalyticByPeriodProp)
)

export type AnalyticByPeriodRecord = RecordOf<AnalyticByPeriodProp>

type AnalyticByRegionProp = {
  region: string,
  data: AnalyticDataRecord,
  ...
}
export const AnalyticByRegionFactory: RecordFactory<AnalyticByRegionProp> = Immutable.Record(
  ({
    region: '',
    data: AnalyticDataFactory(),
  }: AnalyticByRegionProp)
)

export type AnalyticByRegionRecord = RecordOf<AnalyticByRegionProp>

export type loadingState = 'INIT' | 'LOADING' | 'LOADED' | 'ERROR'

type AnalyticsConfigProps = {
  loadingOverview: loadingState,
  loadingRegion: loadingState,
  loadingPushOverview: loadingState,
  loadingReach: loadingState,
  granularity: 'day' | 'hour',
  activeTab: 'analytics' | 'push' | 'reach',
  from: Dayjs,
  to: Dayjs,
  secret: boolean,
  ...
}

export const AnalyticsConfigFactory: RecordFactory<AnalyticsConfigProps> = Immutable.Record(
  ({
    loadingOverview: STATUS.INIT,
    loadingRegion: STATUS.INIT,
    loadingPushOverview: STATUS.INIT,
    loadingReach: STATUS.INIT,
    granularity: 'day',
    activeTab: 'analytics',
    from: dayjs.utc().subtract(1, 'month').subtract(1, 'day').hour(0).minute(0).second(0),
    to: dayjs.utc().subtract(1, 'day').hour(0).minute(0).second(0),
    secret: false,
  }: AnalyticsConfigProps)
)

export type AnalyticsConfigRecord = RecordOf<AnalyticsConfigProps>

// TRANSAC DEBUG
type TransacDebugDetails = Array<{ token: string, date: number, sent_date: number, ... }>

export type TransacResponseProps = $ReadOnly<{
  error: boolean,
  recipientsCount: number,
  recipientsSample: Array<string>,
  recipientsType: Array<string>,
  failureMessage: string,
  failureCount: number,
  successCount: number,
  notFoundCount: number,
  unregistredCount: number,
  success: TransacDebugDetails,
  failure: TransacDebugDetails,
  feedback: TransacDebugDetails,
  groupId: string,
  pushDate: number,
  apiKey: string,
  labels: Array<string>,
  ...
}>

export const TransacResponseFactory: RecordFactory<TransacResponseProps> = Immutable.Record(
  ({
    error: false,
    recipientsCount: 0,
    recipientsSample: [],
    recipientsType: [],
    failureMessage: '',
    failureCount: 0,
    successCount: 0,
    notFoundCount: 0,
    unregistredCount: 0,
    success: [],
    failure: [],
    feedback: [],
    groupId: '',
    pushDate: 0,
    apiKey: '',
    labels: [],
  }: TransacResponseProps)
)

export type TransacResponseRecord = RecordOf<TransacResponseProps>

type DebugTransacProps = {
  loading: boolean,
  error: false | string,
  query: string,
  results: ?TransacResponseProps,
  ...
}
export const DebugTransacFactory: RecordFactory<DebugTransacProps> = Immutable.Record(
  ({
    loading: false,
    query: '',
    error: false,
    results: null,
  }: DebugTransacProps)
)

export type DebugTransacRecord = RecordOf<DebugTransacProps>

// DEBUG USER
type DebugUserProps = {
  loading: boolean,
  query: string,
  mode: 'advertising_id' | 'custom_id' | 'installation_id',
  results: List<DebugInstallRecord>,
  error: string | false,
  recentCustomIds: Array<string>,
  recentInstallationIds: Array<string>,
  ...
}
export const DebugUserFactory: RecordFactory<DebugUserProps> = Immutable.Record(
  ({
    loading: false,
    query: '',
    mode: 'advertising_id',
    results: new Immutable.List(),
    error: false,
    recentCustomIds: [],
    recentInstallationIds: [],
  }: DebugUserProps)
)

export type DebugUserRecord = RecordOf<DebugUserProps>

type ReachCountProps = {
  tokens: number,
  tokensNotifOn: number,
  ...
}
export const ReachCountFactory: RecordFactory<ReachCountProps> = Immutable.Record(
  ({
    tokens: 0,
    tokensNotifOn: 0,
  }: ReachCountProps)
)

export type ReachCountRecord = RecordOf<ReachCountProps>

type ReachChangesProps = {
  toNotifOn: number,
  toNotifOff: number,
  newTokens: number,
  newTokensNotifOn: number,
  deletedTokens: number,
  deletedTokensNotifOn: number,
  ...
}
export const ReachChangesFactory: RecordFactory<ReachChangesProps> = Immutable.Record(
  ({
    toNotifOn: 0,
    toNotifOff: 0,
    newTokens: 0,
    newTokensNotifOn: 0,
    deletedTokens: 0,
    deletedTokensNotifOn: 0,
  }: ReachChangesProps)
)

export type ReachChangesRecord = RecordOf<ReachChangesProps>

type ReachDataProps = {
  count: ReachCountRecord,
  changes: ReachChangesRecord,
  ...
}
export const ReachDataFactory: RecordFactory<ReachDataProps> = Immutable.Record(
  ({
    count: ReachCountFactory(),
    changes: ReachChangesFactory(),
  }: ReachDataProps)
)

export type ReachDataRecord = RecordOf<ReachDataProps>

type ReachByCountryProps = {
  country: string,
  data: ReachDataRecord,
  ...
}
export const ReachByCountryFactory: RecordFactory<ReachByCountryProps> = Immutable.Record(
  ({
    country: '',
    data: ReachDataFactory(),
  }: ReachByCountryProps)
)

export type ReachByCountryRecord = RecordOf<ReachByCountryProps>

type ReachByDayProps = {
  date: Dayjs,
  data: ReachDataRecord,
  byCountry: List<ReachByCountryRecord>,
  ...
}
export const ReachByDayFactory: RecordFactory<ReachByDayProps> = Immutable.Record(
  ({
    date: dayjs(),
    data: ReachDataFactory(),
    byCountry: new Immutable.List(),
  }: ReachByDayProps)
)

export type ReachByDayRecord = RecordOf<ReachByDayProps>

type StatStateProps = {
  analyticsByDate: List<AnalyticByPeriodRecord>,
  analyticsByRegion: List<AnalyticByRegionRecord>,
  config: AnalyticsConfigRecord,
  // debug: DebugStateRecord,
  reach: List<ReachByDayRecord>,
  userDebug: DebugUserRecord,
  ...
}
export const StatStateFactory: RecordFactory<StatStateProps> = Immutable.Record(
  ({
    reach: new Immutable.List(),
    analyticsByDate: new Immutable.List(),
    analyticsByRegion: new Immutable.List(),
    config: AnalyticsConfigFactory(),
    userDebug: DebugUserFactory(),
    // debug: DebugStateFactory()
  }: StatStateProps)
)

export type StatStateRecord = RecordOf<StatStateProps>

type GDPRByDayProps = {
  date: Dayjs,
  dashboard: number,
  api: number,
  mobile: number,
  ...
}
export const GDPRByDayFactory: RecordFactory<GDPRByDayProps> = Immutable.Record(
  ({
    date: dayjs(),
    dashboard: 0,
    api: 0,
    mobile: 0,
  }: GDPRByDayProps)
)

export type GDPRByDayRecord = RecordOf<GDPRByDayProps>
