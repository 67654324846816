// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ProfilesDistributionRecord } from 'com.batch/profilebase/models/profiles-distribution.records'

export type FetchProfilesDistributionAction = ReduxAction<'FETCH_PROFILES_DISTRIBUTION', null>

export type FetchProfilesDistributionSuccessAction = ReduxAction<
  'FETCH_PROFILES_DISTRIBUTION_SUCCESS',
  ProfilesDistributionRecord,
>
export type FetchProfilesDistributionFailureAction = ReduxAction<
  'FETCH_PROFILES_DISTRIBUTION_FAILURE',
  Error,
>

export type FetchProfilesDistributionActions =
  | FetchProfilesDistributionAction
  | FetchProfilesDistributionSuccessAction
  | FetchProfilesDistributionFailureAction

export const fetchProfilesDistribution = (): DispatchExtraBoundFn<
  Promise<ProfilesDistributionRecord>,
> => {
  return (dispatch, getState, { ucpDataService }) => {
    const { projectKey } = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: ucpDataService.fetchProfilesDistribution({ projectKey }),
      actionName: 'FETCH_PROFILES_DISTRIBUTION',
    })
  }
}
