// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { LabelFactory, type LabelRecord } from 'com.batch/labels/models/labels.records'
import { formatCode } from 'com.batch/labels/utils/format-code'

type SaveProjectLabelAction = ReduxAction<'SAVE_PROJECT_LABEL', LabelRecord>

export type SaveProjectLabelFailureAction = ReduxAction<'SAVE_PROJECT_LABEL_FAILURE', LabelRecord>

export type SaveProjectLabelSuccessAction = ReduxAction<'SAVE_PROJECT_LABEL_SUCCESS', LabelRecord>

export type SaveProjectLabelActionType =
  | SaveProjectLabelAction
  | SaveProjectLabelSuccessAction
  | SaveProjectLabelFailureAction

export const saveLabel = (label: LabelRecord): DispatchExtraBoundFn<Promise<LabelRecord>> => {
  return async (dispatch, getState, { metadataService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    // if label code is not provided, generate one from the description
    let labelCode = formatCode(label.code || label.description)

    // if label code already exists, suffix an incrementing number
    const existingLabels = state.projectLabels.labels
    let autoincrement = 1
    let incrementedLabelCode = labelCode
    while (existingLabels.some(existingLabel => incrementedLabelCode === existingLabel.code)) {
      incrementedLabelCode = `${labelCode}${autoincrement}`
      autoincrement++
    }

    const newLabel = LabelFactory({
      description: label.description,
      code: incrementedLabelCode,
    })

    return promiseActionCreator<LabelRecord>({
      dispatch,
      promise: metadataService
        .setLabels({
          projectKey: project.projectKey,
          labels: [{ code: newLabel.code, description: newLabel.description }],
        })
        .then(() => newLabel),
      payload: newLabel,
      actionName: 'SAVE_PROJECT_LABEL',
    })
  }
}
