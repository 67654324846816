// @flow

import { type Map } from 'immutable'
import * as React from 'react'

import { Table } from 'components/common/table'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { ArrayAttributeRow } from 'com.batch/profile/ui/components/attributes-block/array-attribute-row'
import { AttributesTableBody } from 'com.batch/profile/ui/components/attributes-block/attributes-block.styles'
import { AttributesEmptyTemplate } from 'com.batch/profile/ui/components/attributes-block/attributes-empty-template'
import { BooleanAttributeRow } from 'com.batch/profile/ui/components/attributes-block/boolean-attribute-row'
import { DateAttributeRow } from 'com.batch/profile/ui/components/attributes-block/date-attribute-row'
import { LabelAttributeRow } from 'com.batch/profile/ui/components/attributes-block/label-attribute-row'
import { NumberAttributeRow } from 'com.batch/profile/ui/components/attributes-block/number-attribute-row'
import { ObjectAttributeRow } from 'com.batch/profile/ui/components/attributes-block/object-attribute-row'
import { StringAttributeRow } from 'com.batch/profile/ui/components/attributes-block/string-attribute-row'
import { TagsAttributeRow } from 'com.batch/profile/ui/components/attributes-block/tags-attribute-row'
import { UrlAttributeRow } from 'com.batch/profile/ui/components/attributes-block/url-attribute-row'

type AttributesTableProps = {
  attributes: Map<string, QueryValue>,
  names: string[],
  isLoading: boolean,
  event?: string,
  ...
}

export const AttributesTable = ({
  attributes,
  names,
  isLoading,
  event,
}: AttributesTableProps): React.Node => {
  const renderAttribute = React.useCallback(
    name => {
      if (attributes) {
        const attribute = attributes.get(name)
        if (attribute) {
          switch (attribute.type) {
            case 'BOOL':
              return (
                <BooleanAttributeRow
                  key={name}
                  name={name}
                  value={attribute.boolValue}
                  isCopyable={!event}
                />
              )
            case 'LONG':
              return (
                <NumberAttributeRow
                  key={name}
                  name={name}
                  value={attribute.longValue}
                  isCopyable={!event}
                />
              )
            case 'DOUBLE':
              return (
                <NumberAttributeRow
                  key={name}
                  name={name}
                  value={attribute.doubleValue}
                  isCopyable={!event}
                />
              )
            case 'STRING':
              return (
                <StringAttributeRow
                  key={name}
                  name={name}
                  value={attribute.stringValue}
                  isCopyable={!event}
                />
              )
            case 'LABEL':
              return (
                <LabelAttributeRow
                  key={name}
                  name={name}
                  value={attribute.stringValue}
                  isCopyable={!event}
                />
              )
            case 'URL':
              return (
                <UrlAttributeRow
                  key={name}
                  name={name}
                  value={attribute.urlValue}
                  isCopyable={!event}
                />
              )
            case 'DATE':
              return (
                attribute.dateValue && (
                  <DateAttributeRow
                    key={name}
                    name={name}
                    value={attribute.dateValue}
                    isCopyable={!event}
                  />
                )
              )
            case 'OBJECT':
              return (
                event && (
                  <ObjectAttributeRow
                    key={name}
                    name={name}
                    allAttributes={attributes}
                    event={event}
                  />
                )
              )

            case 'ARRAY':
              return (
                attribute.arrayValue &&
                (event ? (
                  <ArrayAttributeRow
                    key={name}
                    name={name}
                    attributes={attribute.arrayValue}
                    allAttributes={attributes}
                    event={event}
                  />
                ) : (
                  <TagsAttributeRow
                    key={name}
                    name={name}
                    attributes={attribute.arrayValue}
                    isCopyable={!event}
                  />
                ))
              )
          }
        }
      }
      return null
    },
    [attributes, event]
  )
  return (
    <Table template="224px 1fr" striped={true}>
      <AttributesTableBody emptyTemplate={isLoading && <AttributesEmptyTemplate />}>
        {names.map(name => renderAttribute(name))}
      </AttributesTableBody>
    </Table>
  )
}
