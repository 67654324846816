// @flow
// ====================== LIBS
import * as React from 'react'

import { type availableIcons, Icon } from 'components/common/svg-icon'
import { TableCell } from 'components/common/table'
import { TextEmpty, TextLabelWithIcon } from 'components/styled/text'

import { AttributeRowContainer } from 'com.batch/profile/ui/components/attributes-block/attributes-block.styles'
import { useCreateCopyValueToClipboardCallback } from 'com.batch/shared/ui/hooks/use-create-copy-value-to-clipboard-callback'
import { useCreateNotifier } from 'com.batch/shared/ui/hooks/use-create-notifier'

type AttributeRowProps = {
  children: React.Node,
  isCopyable?: boolean,
  copyableNotification?: string,
  copyableValue?: string,
  name: string,
  icon: availableIcons,
  iconAction?: availableIcons,
  onClick?: () => void,
  ...
}

export const AttributeRow = ({
  children,
  copyableNotification,
  copyableValue,
  name,
  icon,
  iconAction,
  isCopyable = true,
  onClick,
}: AttributeRowProps): React.Node => {
  const notification = copyableNotification ?? `${name} copied`
  const notifySuccess = useCreateNotifier({ notificationText: notification, kind: 'success' })
  const copy = useCreateCopyValueToClipboardCallback({ notifySuccess })(copyableValue ?? '')

  const handleOnClick = React.useCallback(() => {
    if (onClick) {
      onClick()
    }
    if (isCopyable && copyableValue) {
      copy()
    }
  }, [onClick, isCopyable, copyableValue, copy])

  return (
    <AttributeRowContainer
      light={!!onClick}
      clickable={isCopyable || !!onClick}
      onClick={handleOnClick}
    >
      <TableCell>
        <TextLabelWithIcon icon={icon}>{name}</TextLabelWithIcon>
      </TableCell>
      <TableCell className="attribute-value">
        {children === '' ? (
          <TextEmpty>Empty value</TextEmpty>
        ) : (
          <React.Fragment>
            <span className="fs-exclude">{children}</span>
            {(isCopyable || iconAction) && (
              <Icon className="copy-to-clipboard" icon={iconAction ?? 'copy'} />
            )}
          </React.Fragment>
        )}
      </TableCell>
    </AttributeRowContainer>
  )
}
