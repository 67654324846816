// @flow
import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

type DataProps = {
  value: number,
  rate: number,
  unique: ?number,
}
type DataRecord = RecordOf<DataProps>
const DataFactory: RecordFactory<DataProps> = Immutable.Record(
  ({
    value: 0,
    rate: 0,
    unique: null,
  }: DataProps)
)

type BounceDataProps = {
  value: number,
  rate: number,
  hardBounce: number,
  softBounce: number,
  blockBounce: number,
}
export const BounceDataFactory: RecordFactory<BounceDataProps> = Immutable.Record({
  value: 0,
  rate: 0,
  hardBounce: 0,
  softBounce: 0,
  blockBounce: 0,
})
export type BounceDataRecord = RecordOf<BounceDataProps>

export type EmailProviderStatsProps = {
  delivered: DataRecord,
  open: DataRecord,
  click: DataRecord,
  bounce: BounceDataRecord,
  unsubscribe: DataRecord,
  mpp: DataRecord,
  sent: number,
}
export const EmailProviderStatsFactory: RecordFactory<EmailProviderStatsProps> = Immutable.Record({
  delivered: DataFactory(),
  open: DataFactory(),
  click: DataFactory(),
  bounce: BounceDataFactory(),
  unsubscribe: DataFactory(),
  mpp: DataFactory(),
  sent: 0,
})
export type EmailProviderStatsRecord = RecordOf<EmailProviderStatsProps>

export type EmailProviderProps = {
  name: string,
  stats: EmailProviderStatsRecord,
}
export const EmailProviderFactory: RecordFactory<EmailProviderProps> = Immutable.Record({
  name: '',
  stats: EmailProviderStatsFactory(),
})
export type EmailProviderRecord = RecordOf<EmailProviderProps>
