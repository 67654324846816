// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { requiredString } from 'com.batch.common/validators'

import { InlineEditor } from './inline-editor/inline-editor'

import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selector'
import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'
import { updatePushContentOld } from 'com.batch.redux/content'
import { type StringValueRecord } from 'com.batch.redux/content.records'
import { pushContentABForActiveLanguageSelector } from 'com.batch.redux/targeting.selector.composed'
import { setTemplateForField, TemplateCacheSelector } from 'com.batch.redux/template'

import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'

type EditableFieldProps = {
  field: 'title' | 'message',
  variant: 'a' | 'b',
  ...
}

/**
 * @deprecated use EditableField. Still used for push.
 */
export const LegacyEditableField = ({ field, variant }: EditableFieldProps): React.Node => {
  const dispatch = useDispatch()
  const inlineEditorConfig = useSelector(inlineEditorConfigSelector)
  const lang = useSelector(activeLanguageValueSelector)
  const onBlur = React.useCallback(
    selectionTuple => {
      dispatch({
        type: 'BLUR_INLINE_EDITOR',
        payload: { field, variant, selection: selectionTuple },
      })
    },
    [dispatch, field, variant]
  )

  const setEditing = React.useCallback(
    (editing: 'title' | 'message', caret: number) => {
      let newConfig = inlineEditorConfig
        .set('field', editing)
        .set('variant', variant)
        .set('selection', [caret, caret])
      dispatch(setInlineEditor(newConfig))
    },
    [inlineEditorConfig, dispatch, variant]
  )
  const templateCache = useSelector(TemplateCacheSelector)
  const updateContentAndTemplate = React.useCallback(
    (value: StringValueRecord) => {
      const key = `${field}-${variant}-${lang}`
      dispatch(setTemplateForField(key, value.value))
      dispatch(updatePushContentOld({ lang, variant, field, value }))
    },
    [dispatch, field, variant, lang]
  )
  const content = useSelector(pushContentABForActiveLanguageSelector)
  const variantContent = React.useMemo(() => content.get(variant), [content, variant])

  const isActive = React.useMemo(
    () =>
      inlineEditorConfig.field === field &&
      !inlineEditorConfig.personalizationModalIsOpen &&
      inlineEditorConfig.variant === variant,
    [
      field,
      inlineEditorConfig.personalizationModalIsOpen,
      inlineEditorConfig.field,
      inlineEditorConfig.variant,
      variant,
    ]
  )
  const onFocus = React.useCallback(() => {
    setEditing(field, 0)
  }, [field, setEditing])
  const onActivateAtPos = React.useCallback(
    (pos: number) => {
      setEditing(field, variantContent.get(field).value ? pos : 0)
    },
    [field, setEditing, variantContent]
  )
  const onChange = React.useCallback(
    (computedText: string) => {
      updateContentAndTemplate(
        variantContent
          .get(field)
          .set('value', computedText)
          .set('valid', requiredString(computedText))
      )
    },
    [field, updateContentAndTemplate, variantContent]
  )
  return (
    <InlineEditor
      messageId="default"
      selection={inlineEditorConfig.selection}
      onBlur={onBlur}
      canUseTemplating
      field={field}
      onFocus={onFocus}
      variant={variant}
      isActive={isActive}
      onActivateAtPos={onActivateAtPos}
      onChange={onChange}
      placeholder={field === 'message' ? 'Click to add text...' : 'Click to add title...'}
      value={variantContent.get(field).value.trim()}
      templateCache={templateCache}
    />
  )
}
