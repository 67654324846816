// @flow

import { List } from 'immutable'

import { type BounceCategoryRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'

export const bounceCatToReasons = (
  categories: List<BounceCategoryRecord>
): Array<{
  name: string,
  category: 'HARD' | 'SOFT' | 'BLOCK' | 'UNDETERMINED',
  count: number,
  ...
}> => {
  const reasons: Array<{
    name: string,
    category: 'HARD' | 'SOFT' | 'BLOCK' | 'UNDETERMINED',
    count: number,
    ...
  }> = []

  for (const category of categories) {
    const { name: categoryType, classifications } = category

    for (const classification of classifications) {
      const { name: classificationName, count: classificationCount } = classification

      const parsedReason = {
        name: classificationName,
        category: categoryType,
        count: classificationCount,
      }

      reasons.push(parsedReason)
    }
  }

  return reasons
}
