// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'
import { IconContainer } from 'components/common/svg-icon'
import * as colors from 'components/styled/tokens/colors'

export const SubNav: StyledComponent<*, *, *> = styled.nav`
  width: 100%;
  height: 55px;
  background: ${colors.fill};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid ${colors.stroke};
  display: flex;
  align-items: center;

  ${Button} {
    ${IconContainer} {
      color: ${colors.textLight};
    }

    &.active,
    &:hover {
      ${IconContainer} {
        color: ${colors.textNeutral};
      }
    }

    &:first-child {
      margin-left: 14px;
    }
  }
`

export const SubNavSeparator: StyledComponent<*, *, HTMLHRElement> = styled.hr`
  width: 1px;
  height: 24px;
  background: ${colors.stroke};
  margin: 0 16px 0 4px;
`
