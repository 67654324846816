// @flow
import Immutable, { type Map, type List } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import { browsersList } from 'com.batch/profile/constants/browsers'
import { platformsList } from 'com.batch/profile/constants/platforms'
import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
import {
  type ProfileStateRecord,
  type ProfileDataRecord,
  type InstallRecord,
  ProfileDataFactory,
  type ProfileWebpushPlatformsRecord,
} from 'com.batch/profile/models/profile.records'

type extract<T> = State => T

export const profileSelector = (state: State): ProfileStateRecord => {
  return state.profile
}

export const profileLoadingStateSelector: extract<fetchingState> = createSelector(
  profileSelector,
  profileState => profileState.loadingState
)

export const profileDataStateSelector: extract<ProfileDataRecord> = createSelector(
  profileSelector,
  profileState => profileState.data.get(profileState.profileId) ?? ProfileDataFactory()
)

export const eventsLoadingStateSelector: extract<fetchingState> = createSelector(
  profileDataStateSelector,
  profileDataState => profileDataState.eventsLoadingState
)

export const nbProfilesSelector: extract<number> = createSelector(
  profileSelector,
  profileState => profileState.data.size
)

export const profileIndexSelector: extract<number> = createSelector(profileSelector, profileState =>
  profileState.data.keySeq().indexOf(profileState.profileId)
)

export const mobilePlatformsSelector: extract<List<InstallRecord>> = createSelector(
  profileDataStateSelector,
  profileDataState => {
    const mobileInstalls = profileDataState.platforms.mobile
    let lastInstallByPlatform = []

    platformsList.forEach(platform => {
      const lastInstall = mobileInstalls
        .filter(install => !install.isImported && install.platform === platform)
        .sort((install1, install2) => {
          if (dayjs(install1.lastActivity).isAfter(dayjs(install2.lastActivity))) return -1
          if (dayjs(install1.lastActivity).isBefore(dayjs(install2.lastActivity))) return 1
          return 0
        })
        .first()
      if (lastInstall) lastInstallByPlatform.push([lastInstall.installID, lastInstall])
    })

    const importedInstall = mobileInstalls.filter(install => install.isImported)

    return Immutable.Map([...lastInstallByPlatform, ...importedInstall]).toList()
  }
)

export const webpushPlatformsSelector: extract<List<ProfileWebpushPlatformsRecord>> =
  createSelector(profileDataStateSelector, profileDataState => {
    const webpushInstalls = profileDataState.platforms.webpush

    const platformsToReturn: Map<string, ProfileWebpushPlatformsRecord> = [
      ...browsersList,
      null,
    ].reduce((acc, browser) => {
      const lastInstall = webpushInstalls
        .filter(install => install.browser === browser)
        .sort((install1, install2) => {
          if (dayjs(install1.lastActivity).isAfter(dayjs(install2.lastActivity))) return -1
          if (dayjs(install1.lastActivity).isBefore(dayjs(install2.lastActivity))) return 1
          return 0
        })
        .first()

      if (lastInstall) return acc.set(lastInstall.installId, lastInstall)
      return acc
    }, Immutable.Map())

    return platformsToReturn.toList()
  })

export const overviewPlatformsSelector: extract<Array<Platform>> = createSelector(
  profileDataStateSelector,
  profileDataState => {
    const mobileInstalls = profileDataState.platforms.mobile
    const webpushInstalls = profileDataState.platforms.webpush

    var result = []

    platformsList.map(platform => {
      const hasPlatform = mobileInstalls.some(install => install.platform === platform)
      if (hasPlatform) result.push(platform)
    })
    if (webpushInstalls.size > 0) result.push('WEBPUSH')

    return result
  }
)
