// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { type AppRecord } from 'com.batch.redux/_records'
import { currentAppSelector, currentProjectAppsSelector } from 'com.batch.redux/app'

export const useSelectedApp = (): AppRecord => {
  const apps = useSelector(currentProjectAppsSelector)
  const currentApp = useSelector(currentAppSelector)
  const firstApp = React.useMemo(() => apps.first(), [apps])
  return React.useMemo(() => {
    if (currentApp.id !== 0) {
      return currentApp
    }
    return firstApp
  }, [currentApp, firstApp])
}
