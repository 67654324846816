// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import * as colors from 'components/styled/tokens/colors'
import { opacifyHover, opacifyActive, textLight } from 'components/styled/tokens/colors'
import { blue } from 'components/styled/tokens/schemes'

import { type DropzoneState } from 'com.batch/email/models/dropzone-state'

export const ChoiceContainer: StyledComponent<{ ... }, { ... }, *> = styled.div`
  border: 1px dashed rgba(15, 15, 15, 0.12);
  border-radius: 12px;
  width: 496px;
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 12px;
  background: #fcfcfc;
  margin-bottom: 12px;
`
type ChoiceBoxProps = { mode?: DropzoneState, ... }

export const ChoiceBox: StyledComponent<ChoiceBoxProps, { ... }, *> = styled.form`
  border-radius: 8px;
  width: 100%;
  height: 216px;
  padding: 12px;
  cursor: pointer;
  flex-grow: 1;
  color: ${textLight};
  position: relative;

  &:hover {
    background: ${opacifyHover};
  }

  &:focus-within {
    background: ${opacifyHover};
    outline: 2px solid ${blue['30']};
  }

  &:active {
    background: ${opacifyActive};
  }

  &:focus-within button:focus {
    outline: none;
    box-shadow: none;
  }

  & button {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    border: 0;
  }

  & img {
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  & h2 {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  ${(props: ChoiceBoxProps) =>
    props.mode === 'loading' &&
    css`
      pointer-events: none;
      display: flex;
      flex-direction: column;
      place-content: center;
    `}
`

export const DashedDivider: StyledComponent<{ ... }, { ... }, *> = styled.hr`
  margin-block: 0;
  border-right: 1px dashed rgba(15, 15, 15, 0.12);
`

export const EmailUploadingContainer: StyledComponent<{ ... }, { ... }, *> = styled.div`
  text-align: center;

  & i {
    color: ${colors.fillAction};
  }

  & p {
    margin-bottom: 12px;
    font-size: 16px;
  }
`
