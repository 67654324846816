// @flow
import { type Dayjs } from 'dayjs'
import { type List } from 'immutable'
import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { FilterSelect } from 'components/filter'
import { LinkArrow } from 'components/styled/text'
import { colors } from 'components/styled/tokens'
import { textLight } from 'components/styled/tokens/colors'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { Volume } from './volume'
import { VolumeFilters, VolumeSection } from './volume.styles'

import { type ProjectRecord } from 'com.batch.redux/project.records'

import { getMonthList } from 'com.batch/account/infra/formats/get-month-list'
import {
  type PlatformSentVolumeRecord,
  type ProfileVolumeRecord,
} from 'com.batch/account/model/volumes.records'
import {
  AccountSection,
  SectionTitle,
  SectionIntro,
} from 'com.batch/account/ui/components/account.styles'
import { RemainingDayAlert } from 'com.batch/account/ui/components/volumes/remaining-day-alert'

type Props = {
  projects: List<ProjectRecord>,
  profileVolume: ProfileVolumeRecord,
  sentVolume: PlatformSentVolumeRecord,
}

export const VolumesSection = ({ projects, profileVolume, sentVolume }: Props): React.Node => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const qMonth = React.useMemo(() => decodeURI(search.get('month') ?? ''), [search])
  const qProject = React.useMemo(() => decodeURI(search.get('project') ?? ''), [search])
  const now = dayjs().utc()

  const buildSearch = React.useCallback(
    ({ project, month }: { project?: string | null, month?: string | null }) => {
      const queryArray = []

      if (project) queryArray.push(`project=${project}`)
      else if (qProject && project !== null) queryArray.push(`project=${qProject}`)

      if (month) queryArray.push(`month=${month}`)
      else if (qMonth && month !== null) queryArray.push(`month=${qMonth}`)

      return encodeURI(queryArray.join('&'))
    },
    [qMonth, qProject]
  )

  const optToString = React.useCallback((opt: any) => opt?.name ?? '', [])

  const onMonthChange = React.useCallback(
    opt => {
      navigate({
        search: encodeURI(buildSearch(opt?.name ? { month: opt.name } : { month: null })),
      })
    },
    [navigate, buildSearch]
  )

  const projectSelected = React.useMemo(
    () => projects.find(p => p.id === qProject) ?? projects.find(p => p.id === 'all'),
    [qProject, projects]
  )

  const onProjectChange = React.useCallback(
    opt => {
      navigate({
        search: buildSearch(opt?.id ? { project: opt.id } : { project: null }),
      })
    },
    [navigate, buildSearch]
  )

  const startedDate = React.useMemo(() => {
    if (projectSelected) {
      return projectSelected.createdAt
    }
    return projects
      .map(p => p.createdAt)
      .min()
      .startOf('month')
  }, [projectSelected, projects])

  const optionsMonth: List<{ name: string, date: Dayjs }> = React.useMemo(
    () => getMonthList(startedDate),
    [startedDate]
  )

  const monthSelected = React.useMemo(
    () => optionsMonth.find(p => p.name === qMonth),
    [qMonth, optionsMonth]
  )

  const searchingMonth = React.useMemo(() => {
    return optionsMonth.find(f => f.name === qMonth)
  }, [qMonth, optionsMonth])

  return (
    <AccountSection>
      <SectionIntro>
        <SectionTitle>Volumes</SectionTitle>
        <p>Track the essential usage metrics that drives your monthly invoice</p>
        <LinkArrow
          intent="action"
          target="_blank"
          href="https://batch.com/pricing"
          style={{ marginTop: 16 }}
        >
          Understand volume pricing
        </LinkArrow>
      </SectionIntro>
      <VolumeSection>
        <VolumeFilters>
          <FilterSelect
            isSearchable={false}
            isClearable
            options={optionsMonth}
            value={monthSelected}
            optionToString={optToString}
            onChange={onMonthChange}
            variant="filled"
            placeholder="Current month"
            style={{ color: textLight }}
          />
          <FilterSelect
            isSearchable={false}
            isClearable
            options={projects}
            value={projectSelected}
            optionToString={optToString}
            onChange={onProjectChange}
            variant="filled"
            placeholder="All projects"
            style={{ color: textLight }}
          />
        </VolumeFilters>
        <Volume
          icon="user"
          title={
            <React.Fragment>
              Monthly Active Profiles <span style={{ color: colors.textLight }}>(MAPs)</span>
            </React.Fragment>
          }
          desc="Number of unique active profiles on Batch platform"
          volume={profileVolume.active.total}
          loadingState={profileVolume.loadingState}
        />
        <Volume
          icon="mail"
          title="Emails"
          desc="Number of emails sent from Batch platform"
          volume={sentVolume.email.total}
          loadingState={sentVolume.loadingState}
        />
        <Volume
          icon="sms"
          title="SMS"
          desc={
            <React.Fragment>
              Number of SMS parts sent from Batch platform{' '}
              <Tooltip
                tooltip={
                  <div style={{ fontSize: '14px', textAlign: 'left' }}>
                    An SMS message is composed of one or several message parts depending on the
                    character count & type
                  </div>
                }
                minWidth={280}
                placement="bottom-start"
              >
                <Icon icon="help" size={12} className="tootlip-icon" />
              </Tooltip>
            </React.Fragment>
          }
          volume={sentVolume.sms.total}
          loadingState={sentVolume.loadingState}
        />
        <RemainingDayAlert searchingMonth={searchingMonth ? searchingMonth.date : now} />
      </VolumeSection>
    </AccountSection>
  )
}
