// @flow
import React, { PureComponent, type Node, type Element } from 'react'

export const Tab = (props: TabProps): React$Node => {
  return null
}
type TabsProps = {
  children: Array<Element<typeof Tab>>,
  ...
}
type TabsState = {
  active: number,
  ...
}

type TabProps = {
  title: string,
  default?: boolean,
  children: Node | Array<Node>,
  ...
}

export class Tabs extends PureComponent<TabsProps, TabsState> {
  constructor(props: TabsProps) {
    super(props)
    let active = 0
    props.children.forEach((tab, index) => {
      if (tab.props.default) {
        active = index
      }
    })
    this.state = {
      active,
    }
  }
  render(): React$Node {
    return (
      <div>
        <ul className="nav nav-pills">
          {this.props.children.map((tab, index) => {
            return (
              <li
                key={index}
                onClick={() => this.setState({ active: index })}
                className={index === this.state.active ? 'active' : ''}
              >
                <a href="#">{tab.props.title}</a>
              </li>
            )
          })}
        </ul>
        <div className="tab-content">
          {this.props.children
            .filter((v, k) => k === this.state.active)
            .map(tab => tab.props.children)}
        </div>
      </div>
    )
  }
}
