// @flow
/*
  file used for "fake" omnichanel push form, and for email campaign & recurring
  this is pretty garbage
*/
import { type Dayjs } from 'dayjs'
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { confirm } from 'components/common/confirm'
import { Loader } from 'components/common/loader/loader'
import { ChannelSelector } from 'components/project/campaign/common/channel-selector'
import { FormHeaderOmni } from 'components/project/campaign/common/fake-omni-header'
import { Targeting } from 'components/project/campaign/common/targeting'
import { Message } from 'components/project/campaign/push/message'
import { Settings } from 'components/project/campaign/push/settings'
import { When } from 'components/project/campaign/push/when'
import { Content } from 'components/styled/blocs'

import { generateTypedId } from 'com.batch.common/typed-id'

import { FakeOmniFooter } from './common/fake-omni-footer'
import { NoData } from './form.styles'

import { type pushCampaignSendType, type State } from 'com.batch.redux/_records'
import { currentAppSelector, currentProjectAppsSelector } from 'com.batch.redux/app'
import { fetchProjectApps } from 'com.batch.redux/app.action'
import {
  setActiveTranslation,
  toggleChannel,
  updatePushWhen,
} from 'com.batch.redux/campaign.action'
import { currentCampaign } from 'com.batch.redux/campaign.selector'
import { replacePushSettings, updateCampaignName } from 'com.batch.redux/content'
import { campaignNameSelector, pushSettingsSelector } from 'com.batch.redux/content.selector'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { resetQuery } from 'com.batch.redux/query/query'
import { resetTarget } from 'com.batch.redux/target/target'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'

import { initForm } from 'com.batch/orchestration/usecases/init-form'
import { updateName } from 'com.batch/orchestration/usecases/update-name'
import { TargetingWrapperCampaign } from 'com.batch/orchestration-journey/ui/components/sheet-settings.styles'

type OmniCampaignFormProps = { ... }

const formTheme = { kind: 'capture', disabledMode: false }

const hasQuerySelector = (state: State) =>
  state.query.getIn(['targeting', 'conditions'], Immutable.Map()).size > 0

export const OmniCampaignForm: React.ComponentType<OmniCampaignFormProps> = React.memo(() => {
  // ----- LOCAL STATE -------------
  const [loading, setLoading] = React.useState(true)
  const [loaded, setLoaded] = React.useState(false)
  const { token } = useParams()
  // -------------------------------

  // ----- REDUX -------------------
  const dispatch = useDispatch()
  const app = useSelector(currentAppSelector)
  const campaign = useSelector(currentCampaign)
  const campaignName = useSelector(campaignNameSelector)
  const settings = useSelector(pushSettingsSelector)
  const hasQuery = useSelector(hasQuerySelector)
  const currentProject = useSelector(currentProjectSelector)
  // -------------------------------

  // ----- CALLBACKS ---------------
  const onCampaignNameChange = React.useCallback(
    (name: string) => {
      dispatch(updateCampaignName(name))
      dispatch(updateName(name))
    },
    [dispatch]
  )
  const updateWhen = React.useCallback(
    ({ what, value }: { value: ?any, what: string, ... }) => {
      dispatch(updatePushWhen({ what, value }))
    },
    [dispatch]
  )
  const updateSendType = React.useCallback(
    (value: pushCampaignSendType) => {
      updateWhen({ what: 'sendType', value })
      updateWhen({ what: 'repeatFrequency', value: value === 'recurring' ? 1 : 0 })
      if (value === 'now') {
        updateWhen({ what: 'tzAware', value: true })
      }
      if (value !== 'recurring') {
        updateWhen({ what: 'end', value: null })
      }
    },
    [updateWhen]
  )
  const updateStart = React.useCallback(
    (value: ?Dayjs) => {
      updateWhen({ what: 'start', value })
    },
    [updateWhen]
  )
  const updateEnd = React.useCallback(
    (value: ?Dayjs) => {
      updateWhen({ what: 'end', value })
    },
    [updateWhen]
  )
  const updateTzAware = React.useCallback(
    (value: boolean) => {
      updateWhen({ what: 'tzAware', value })
    },
    [updateWhen]
  )
  const updateCapping = React.useCallback(
    (value: ?number) => {
      updateWhen({ what: 'capping', value })
    },
    [updateWhen]
  )

  const updateSettings = React.useCallback(
    settings => {
      dispatch(replacePushSettings(settings))
    },
    [dispatch]
  )

  const updateRepeatFrequency = React.useCallback(
    (value: number) => {
      updateWhen({ what: 'repeatFrequency', value })
    },
    [updateWhen]
  )

  const updateRepeatUnit = React.useCallback(
    (value: repeatUnitType) => {
      updateWhen({ what: 'repeatUnit', value })
    },
    [updateWhen]
  )

  const toggleChannelBound = React.useCallback(
    (channel: Channel) => {
      if (hasQuery) {
        confirm({
          title: 'Reset targeting configuration?',
          confirm: 'Yes, reset it',
          message: (
            <p>
              You are about to change channel used in this campaign. As you already configured some
              targeting conditions and some attributes may not be available on this new channel, we
              have to empty your current targeting configuration.
            </p>
          ),
        }).then(
          () => {
            dispatch(
              toggleChannel(
                campaign.channels.has(channel)
                  ? campaign.channels.delete(channel)
                  : campaign.channels.add(channel)
              )
            )
            dispatch(resetQuery({ queryId: 'targeting' }))
          },
          () => {}
        )
      } else {
        dispatch(
          toggleChannel(
            campaign.channels.has(channel)
              ? campaign.channels.delete(channel)
              : campaign.channels.add(channel)
          )
        )
      }
    },
    [campaign.channels, dispatch, hasQuery]
  )
  // -------------------------------

  React.useEffect(() => {
    dispatch(resetTarget(TargetStateFactory(), true))
    dispatch(
      initForm({
        schedulingType: 'campaigns',
        sendType: 'now',
        type: 'push',
        channel: 'push',
        messageTypedId: generateTypedId('message'),
      })
    )

    dispatch(
      toggleChannel(
        Immutable.Set(
          app.id === 0
            ? []
            : [app.platform === 'ios' ? 'ios' : app.platform === 'android' ? 'android' : 'webpush']
        )
      )
    )
  }, [app.id, app.platform, dispatch, token])

  // ----- FETCH APPS --------------
  React.useEffect(() => {
    if (!loaded && currentProject) {
      setLoading(true)
      dispatch(setActiveTranslation('default'))
      dispatch(fetchProjectApps(currentProject)).then(
        () => {
          setLoading(false)
          setLoaded(true)
        },
        () => {
          throw 'Unable to load apps'
        }
      )
    }
  }, [currentProject, dispatch, loaded])

  const apps = useSelector(currentProjectAppsSelector)

  const availableChannels: List<'ios' | 'webpush' | 'android'> = React.useMemo(() => {
    return apps
      .filter(app => app.validPushConfig)
      .map(app => app.platform)
      .reduce(
        // we use reduce because flow can't refine type on filter
        (acc, platform) =>
          platform === 'ios' || platform === 'webpush' || platform === 'android'
            ? acc.push(platform)
            : acc,
        new Immutable.List()
      )
  }, [apps])
  // -------------------------------

  return (
    <React.Fragment>
      <Helmet>
        <title>Campaign — New – {currentProject.name} – Batch</title>
      </Helmet>
      <FormHeaderOmni onChange={onCampaignNameChange} value={campaignName} />
      <Loader loading={loading} padding={100}>
        <Content>
          <ChannelSelector
            channels={availableChannels}
            selected={campaign.channels}
            toggleChannel={toggleChannelBound}
            isDisabled={false}
          />

          {campaign.channels.size === 0 && (
            <NoData>
              <p className="styled-title">No channel selected</p>
              <p>Please choose at least one channel to continue.</p>
            </NoData>
          )}
          <div
            style={{
              maxHeight: campaign.channels.size === 0 ? 0 : 'none',
              visibility: campaign.channels.size === 0 ? 'hidden' : 'inherit',
            }}
          >
            <ThemeProvider theme={formTheme}>
              <TargetingWrapperCampaign>
                <Targeting canvasMode={false} isProfile={false} id="default" />
              </TargetingWrapperCampaign>
              <When
                schedulingType={campaign.schedulingType}
                capping={campaign.capping}
                end={campaign.end}
                hideTrigger
                isTzAware={campaign.tzAware}
                repeatFrequency={campaign.repeatFrequency}
                repeatUnit={campaign.repeatUnit}
                sendType={campaign.sendType}
                start={campaign.start}
                updateCapping={updateCapping}
                updateEnd={updateEnd}
                hasEmailChannel={false}
                updateRepeatFrequency={updateRepeatFrequency}
                updateRepeatUnit={updateRepeatUnit}
                updateSendType={updateSendType}
                updateStart={updateStart}
                updateTzAware={updateTzAware}
              />
              <Message />
              <Settings channels={campaign.channels} settings={settings} update={updateSettings} />
            </ThemeProvider>
            <FakeOmniFooter />
          </div>
        </Content>
      </Loader>
    </React.Fragment>
  )
})

OmniCampaignForm.displayName = 'OmniCampaignForm'
