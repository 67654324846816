// @flow
import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

import { type TransmissionType } from 'com.batch/shared/models/transmission-type'

type EspDomainsProps = {
  bounceDomain: string,
  sendingDomain: string,
  transmissionTypes: Immutable.Set<TransmissionType>,
}

export const EspDomainFactory: RecordFactory<EspDomainsProps> = Immutable.Record(
  ({
    bounceDomain: '',
    sendingDomain: '',
    transmissionTypes: Immutable.Set(),
  }: EspDomainsProps)
)
export type EspDomainsRecord = RecordOf<EspDomainsProps>

type EspConfigurationProps = {
  subAccountId: number,
  domains: Immutable.List<EspDomainsRecord>,
  ...
}

export const EspConfigurationFactory: RecordFactory<EspConfigurationProps> = Immutable.Record(
  ({
    subAccountId: -1,
    domains: new Immutable.List(),
  }: EspConfigurationProps)
)
export type EspConfigurationRecord = RecordOf<EspConfigurationProps>
