// @flow
import * as React from 'react'

import { BoxBody } from 'components/common/box'
import { Skeleton } from 'components/common/empty-states'
import { Icon, type availableIcons } from 'components/common/svg-icon'

import { kformat } from 'com.batch.common/utils'

import { VolumeBox, VolumeDesc, VolumeTitle, VolumeNumber, VolumeError } from './volume.styles'

import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type Props = {
  loadingState: fetchingState,
  title: React.Node,
  desc: React.Node,
  icon: availableIcons,
  volume: number,
}
export const Volume = ({ loadingState, title, desc, icon, volume }: Props): React.Node => (
  <VolumeBox>
    <BoxBody>
      <div>
        <VolumeTitle>
          <Icon icon={icon} />
          <span>{title}</span>
        </VolumeTitle>
        <VolumeDesc>{desc}</VolumeDesc>
      </div>
      <Skeleton h={44} w={50} style={{ borderRadius: 8 }}>
        <VolumeNumber>{kformat(volume) ?? 0}</VolumeNumber>
      </Skeleton>

      {loadingState === STATUS.ERROR && (
        <VolumeError>
          <div>
            <Icon icon="error" />
            Can’t load metric
          </div>
        </VolumeError>
      )}
    </BoxBody>
  </VolumeBox>
)
