// @flow
import { pie as D3Pie } from 'd3-shape'
import * as React from 'react'
import { useTheme } from 'styled-components'

import {
  type ChartRefData,
  type ChartData,
  type PieData,
} from 'com.batch/shared/infra/types/chart-data'
import { Arc } from 'com.batch/shared/ui/component/charts/donut-chart/arc'
import {
  DonutChartContainer,
  Legend,
  LegendContainer,
  LoadingRect,
} from 'com.batch/shared/ui/component/charts/donut-chart/donut-chart.styles'
import { LegendIcon } from 'com.batch/shared/ui/component/charts/donut-chart/legend-icon'
import { LoadingArc } from 'com.batch/shared/ui/component/charts/donut-chart/loading-arc'

const generateRandomArrayForLoadingPie = () => {
  const randomValue = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min
  const firstValue = randomValue(1, 100)
  const secondValue = randomValue(1, 100 - firstValue)
  const thirdValue = 100 - (firstValue + secondValue)
  return [firstValue, secondValue, thirdValue]
}

type Props = {
  data: ChartData[],
  clickedIndex: number,
  setClickedIndex: (value: number) => void,
  middleText?: React.Node,
  width: number,
  height: number,
  hasTags?: boolean,
  ...
}

export const DonutChart = ({
  data,
  clickedIndex,
  setClickedIndex,
  middleText,
  width,
  height,
  hasTags = false,
}: Props): React.Node => {
  const { isLoading, isEmpty } = useTheme()
  const [activeIndex, setActiveIndex] = React.useState<number>(clickedIndex)
  const radius: number = React.useMemo(() => Math.min(width, height) / 2, [height, width])
  const isHover = React.useMemo(() => activeIndex !== clickedIndex, [clickedIndex, activeIndex])

  const dataWithRef: ChartRefData[] = React.useMemo(() => {
    return data.map((d, i) => ({
      ...d,
      ref: i,
    }))
  }, [data])

  const dataFiltered = React.useMemo(() => {
    return dataWithRef.filter(d => d.value > 0)
  }, [dataWithRef])

  const pie = React.useMemo(
    () =>
      D3Pie()
        .value(d => d.value)
        .sort(null)
        // gap between segments
        .padAngle(0.03)(dataFiltered),
    [dataFiltered]
  )

  const loadingPie = React.useMemo(
    () =>
      D3Pie()
        .value(d => d)
        .sort(null)
        // gap between segments
        .padAngle(0.03)(generateRandomArrayForLoadingPie()),
    []
  )

  const handleClick = React.useCallback(
    (statType: number) => () => {
      setActiveIndex(statType)
      setClickedIndex(statType)
    },
    [setClickedIndex]
  )

  const handleMouseEnter = React.useCallback(
    (statType: number) => () => {
      setActiveIndex(statType)
    },
    [setActiveIndex]
  )

  const handleMouseLeave = React.useCallback(() => {
    setActiveIndex(clickedIndex)
  }, [clickedIndex, setActiveIndex])

  const getColor = React.useCallback(
    index => {
      if (clickedIndex === index) {
        return data[index].color.active
      }
      if (isHover && activeIndex === index) {
        return data[index].color.hover
      }
      return data[index].color.default
    },
    [activeIndex, clickedIndex, data, isHover]
  )

  React.useEffect(() => {
    if (!isLoading) {
      const currentData = dataWithRef[activeIndex ?? 0]
      // if the activeIndex set by default have 0 as value, so we choose another index
      if (currentData.value === 0) {
        const nextData = dataWithRef.find(d => d.value > 0)
        if (nextData) {
          setActiveIndex(nextData.ref)
          setClickedIndex(nextData.ref)
        }
      }
    }
  }, [activeIndex, dataWithRef, isLoading, setActiveIndex, setClickedIndex])

  return (
    <React.Fragment>
      <DonutChartContainer>
        {middleText && !isEmpty ? middleText : null}
        <svg width={width} height={height} style={{ overflow: 'visible' }}>
          <g transform={`translate(${width / 2}, ${height / 2})`}>
            {isLoading || isEmpty
              ? loadingPie.map((d: any, index: number) => (
                  <LoadingArc key={index} d={d} radius={radius} />
                ))
              : pie.map((d: PieData) => (
                  <Arc
                    key={d.data.ref}
                    color={getColor(d.data.ref)}
                    pattern={dataWithRef[d.data.ref].color.pattern}
                    radius={radius}
                    onClick={handleClick(d.data.ref)}
                    onMouseEnter={handleMouseEnter(d.data.ref)}
                    onMouseLeave={handleMouseLeave}
                    clickedIndex={clickedIndex}
                    index={d.data.ref}
                    d={d}
                    rate={dataWithRef[d.data.ref].rate ?? 0}
                    hasTag={hasTags}
                  />
                ))}
          </g>
        </svg>
      </DonutChartContainer>
      <LegendContainer>
        {isLoading || isEmpty
          ? loadingPie.map((d: any, index: number) => (
              <Legend key={index}>
                <svg xmlns="http://www.w3.org/2000/svg" width={100} height={22} fill="none">
                  <LoadingRect x={4} y={4} width={14} height={14} rx={4} isEmpty={isEmpty} />
                  <LoadingRect x={26} y={4} width={64} height={14} rx={4} isEmpty={isEmpty} />
                </svg>
              </Legend>
            ))
          : dataWithRef.map(({ ref, label, value }) => (
              <Legend
                key={ref}
                onClick={handleClick(ref)}
                onMouseEnter={handleMouseEnter(ref)}
                onMouseLeave={handleMouseLeave}
                disabled={value === 0}
                active={activeIndex === ref}
              >
                <LegendIcon
                  pattern={data[ref].color.pattern}
                  isClicked={clickedIndex === ref}
                  color={getColor(ref)}
                />
                {label}
              </Legend>
            ))}
      </LegendContainer>
    </React.Fragment>
  )
}
