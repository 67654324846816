// @flow

import Immutable, { type List, type OrderedMap } from 'immutable'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { addPrefixOnValue } from './helpers'

import { type AttributeRecord } from 'com.batch.redux/_records'
import {
  type CustomDataSummary,
  type CustomDataValue,
} from 'com.batch.redux/adapters/ucp/models/custom-data'
import { parseCustomDataToAttributeRecords } from 'com.batch.redux/adapters/ucp/parse/parse-custom-data'
import { type UCPGateway } from 'com.batch.redux/corelogic/gateways/ucp.interface'

import { type Profile } from 'com.batch/profile/infra/debug/models/profile.model'

type LookUpProfileIdResponse = {
  profileIDs: {
    data: string,
    ...
  }[],
}

export type GetProfileResponse = {
  profile: Profile,
}

type fetchUnifiedCustomerDataRestResponse = { results: Array<CustomDataSummary> }
type fetchUnifiedCustomerDataValuesRestResponse = { results: Array<CustomDataValue> }

export const UCPRest: UCPGateway = {
  getProfile: async ({ project, profileID }) => {
    const response = await request.post<GetProfileResponse>(
      generateUrl('api_grpc_ucp_service', {
        methodName: 'GetProfile',
      }),
      {
        projectKey: {
          textual: {
            text: project.projectKey,
          },
        },
        profileID,
      }
    )

    return response.profile
  },
  lookUpProfileId: async ({ project, value }) => {
    const response = await request.post<LookUpProfileIdResponse>(
      generateUrl('api_grpc_ucp_service', {
        methodName: 'LookupProfileIDs',
      }),
      {
        projectKey: {
          textual: {
            text: project.projectKey,
          },
        },
        identifier: {
          customID: value,
        },
      }
    )
    const hasNoProfileID = Object.keys(response).length === 0

    return hasNoProfileID ? '' : response.profileIDs[0].data
  },
  fetchUnifiedCustomerData: async ({ project }): Promise<OrderedMap<string, AttributeRecord>> => {
    try {
      const { results } = await request.post<fetchUnifiedCustomerDataRestResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          projectKey: project.projectKey,
          methodName: 'GetCustomDataSummary',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
        }
      )

      if (!results) {
        return Immutable.OrderedMap()
      }

      return Immutable.OrderedMap(
        results
          .filter(result => result.type && !result.hidden)
          .map(result => [
            addPrefixOnValue({ type: result.type, value: result.key }),
            parseCustomDataToAttributeRecords(result),
          ])
      )
    } catch (err) {
      throw Immutable.OrderedMap() ?? 'Unknown error'
    }
  },
  fetchUnifiedCustomerDataValues: async ({
    project,
    key,
    type,
    searchTerm,
  }): Promise<List<string>> => {
    try {
      const keyWithoutPrefix = key.substring(2)

      const { results } = await request.post<fetchUnifiedCustomerDataValuesRestResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetCustomDataValues',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          key: type === 'TAGS' ? keyWithoutPrefix : key,
          type,
          startsWith: searchTerm,
        }
      )

      if (!results) {
        return new Immutable.List()
      }

      return new Immutable.List().push(...results.map(result => result.value))
    } catch (err) {
      return new Immutable.List()
    }
  },
  fetchUnifiedCustomerEventValues: async ({
    project,
    eventName,
    attributeName,
    type,
    searchTerm,
  }): Promise<List<string>> => {
    try {
      const eventNameWithoutPrefix = eventName.substring(2)

      const { results } = await request.post<fetchUnifiedCustomerDataValuesRestResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetCustomEventValues',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          attributeName,
          eventName: eventNameWithoutPrefix,
          type,
          startsWith: searchTerm,
        }
      )

      if (!results) {
        return new Immutable.List()
      }

      return new Immutable.List().push(...results.map(result => result.value))
    } catch (err) {
      return new Immutable.List()
    }
  },
}
