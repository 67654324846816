// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Tooltip } from 'components/common/tooltip'
import { SelectMultiLine } from 'components/form/fields/select/select-multi-line'
import { MAX_SEGMENTS_IN_ARRAY } from 'components/query/query.const'

import { generateUrl } from 'com.batch.common/router/router'

import { type InputProps } from './helper'

import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import {
  allSegmentsSelector,
  segmentLoadingStateSelector,
} from 'com.batch/segments/store/segments.selector'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

import { SegmentFactory, type SegmentRecord } from 'com.batch/segments/models/segment.records'
import { fetchSegmentList } from 'com.batch/segments/usecases/fetch-segment-list'
import { getSegmentsByName } from 'com.batch/segments/usecases/get-segments-by-name'

const OptionSegment = (
  option: SegmentRecord,
  { context }: { context: 'value' | 'menu' }
): React.Node => {
  return context === 'value' ? (
    <div>
      {option.displayName ? (
        <Tooltip tooltip={option.name}>
          <span>{option.displayName}</span>
        </Tooltip>
      ) : (
        option.name
      )}
    </div>
  ) : (
    <div>{option.displayName || option.name}</div>
  )
}

const segmentToString = (seg: ?SegmentRecord) => seg?.displayName ?? ''
export const InputSegment = ({
  condition,
  updateCondition,
  removeSelf,
  isDisabled,
}: InputProps): React.Node => {
  const { state } = useSelector(orchestrationMetaSelector)
  const allSegments = useSelector(allSegmentsSelector)
  const project = useSelector(currentProjectSelector)
  const { fetchingLoadingState: loadingState } = useSelector(segmentLoadingStateSelector)
  const dispatch = useDispatch()

  const loadOptions = React.useCallback(
    async (query: string) => {
      try {
        const resp = await dispatch(
          fetchSegmentList({
            page: 1,
            forceTrashCache: true,
            search: query ? query : '',
            sortDirection: 'asc',
            sortField: 'displayName',
            pageSize: 20,
          })
        )
        return resp.segments.filter(seg => !seg.deletedAt)
      } catch (err) {
        console.log(err)
        return new Immutable.List()
      }
    },
    [dispatch]
  )

  const buildViewOptionLink = React.useCallback(
    (seg: SegmentRecord) =>
      generateUrl('project_profiles_segment_edit', {
        companyId: project.companyId,
        projectId: project.id,
        activeTab: 'segments',
        code: seg.name,
      }),
    [project.companyId, project.id]
  )

  // get deleted/missing segments when not found
  React.useEffect(() => {
    const missingIds = condition.value.stringList.filter(
      stringValue => !allSegments.has(stringValue)
    )
    if (missingIds.size > 0 && loadingState === 'LOADED') {
      dispatch(getSegmentsByName(missingIds.toArray())).then(foundSegmentList => {
        // on draft campaign, we remove deleted segments from the condition
        const deletedSegments = foundSegmentList.filter(segment => Boolean(segment.deletedAt))
        if ((state === 'DRAFT' || state === 'STOPPED') && deletedSegments.size > 0) {
          dispatch(
            showToast({
              message: 'Some segments were deleted, we had to remove them. Check targeting.',
              type: 'warning',
            })
          )
          updateCondition(
            condition.set(
              'value',
              condition.value.set(
                'stringList',
                condition.value.stringList.filter(
                  name => !deletedSegments.some(seg => seg.name === name)
                )
              )
            )
          )
        }
      })
    }
  }, [
    allSegments,
    condition,
    condition.value.stringList,
    dispatch,
    loadingState,
    state,
    updateCondition,
  ])
  const pickedSegments: List<SegmentRecord> = React.useMemo(() => {
    return condition.value.stringList.map(name =>
      allSegments.get(name, SegmentFactory({ name, displayName: name }))
    )
  }, [allSegments, condition.value.stringList])
  const handleChange = React.useCallback(
    (value: List<SegmentRecord>) => {
      if (value.size === 0 && removeSelf) {
        removeSelf()
      } else {
        updateCondition(
          condition.set(
            'value',
            condition.value.set(
              'stringList',
              value.map(seg => seg.name)
            )
          )
        )
      }
    },
    [condition, removeSelf, updateCondition]
  )
  return (
    <SelectMultiLine
      maxItems={MAX_SEGMENTS_IN_ARRAY}
      isDisabled={Boolean(isDisabled)}
      loadOptions={loadOptions}
      itemName="segment"
      value={pickedSegments}
      onChange={handleChange}
      optionToString={segmentToString}
      buildViewOptionLink={buildViewOptionLink}
      optionFormatter={OptionSegment}
    />
  )
}
