// @flow
import styled, { type StyledComponent } from 'styled-components'

type Placement = 'top' | 'bottom' | 'left' | 'right'
type TooltipContainerProps = { toggle?: any => any, placement: Placement, ... }
export const TooltipContainer: StyledComponent<TooltipContainerProps, *, HTMLDivElement> =
  styled.div`
    z-index: 9999;
    opacity: 0;
    ${(props: TooltipContainerProps) => (!props.toggle ? 'pointer-events: none;' : '')}
    transition: opacity ease-in 0.1s;
    ${(props: TooltipContainerProps) => {
      switch (props.placement) {
        case 'top':
          return 'margin-top:-3px; padding:5px 0;'
        case 'right':
          return 'margin-left:3px; padding:0 5px;'
        case 'bottom':
          return 'margin-top:3px; padding:5px 0;'
        case 'left':
          return 'margin-left:-3px; padding:0 5px;'
      }
    }};
  `
type TooltipInnerProps = { inline?: boolean, noPadding?: boolean, ... }
export const TooltipInner: StyledComponent<TooltipInnerProps, *, HTMLDivElement> = styled.div`
  font-size: 12px;
  min-width: 120px;
  min-width: ${(props: TooltipInnerProps) => (props.inline ? 0 : 120)}px;
  max-width: 250px;
  padding: ${(props: TooltipInnerProps) => (props.noPadding ? '0' : '8px 12px 9px 12px')};
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: RGBA(34, 44, 59, 0.9);
  border-radius: 3px;
  hr {
    margin: 5px 0;
    opacity: 0.4;
  }
`
type TooltipArrowProps = { placement?: Placement, ... }
export const TooltipArrow: StyledComponent<TooltipArrowProps, { ... }, HTMLDivElement> = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  ${(props: TooltipArrowProps) => {
    switch (props.placement) {
      case 'top':
        return `bottom: 1px;
          left: 50%;
          margin-left: -5px;
          border-width: 5px 5px 0;
          border-top-color: RGBA(34, 44, 59, 0.9)`
      case 'right':
        return `top: 50%;
          left: 0;
          margin-top: -5px;
          border-width: 5px 5px 5px 0;
          border-right-color: RGBA(34, 44, 59, 0.9);`
      case 'bottom':
        return `top: 0;
          left: 50%;
          margin-left: -5px;
          border-width: 0 5px 5px;
          border-bottom-color: RGBA(34, 44, 59, 0.9);`
      case 'left':
        return `top: 50%;
          right: 0;
          margin-top: -5px;
          border-width: 5px 0 5px 5px;
          border-left-color: RGBA(34, 44, 59, 0.9);
          `
    }
  }};
`
