// @flow

import styled, { type StyledComponent } from 'styled-components'

type GridProps = {
  template: string,
  gap?: number,
  margin?: Array<number>,
  alignItems?: 'start' | 'end' | 'center' | 'stretch',
  ...
}

export const Grid: StyledComponent<GridProps, { ... }, HTMLDivElement> = styled.div`
  display: grid;
  align-items: ${(props: GridProps) => (props.alignItems ? props.alignItems : 'center')};
  grid-column-gap: ${(props: GridProps) => (typeof props.gap === 'number' ? props.gap : 10)}px;
  grid-template-columns: ${(props: GridProps) => props.template};
  margin: ${(props: GridProps) => (props.margin ? props.margin.map(m => `${m}px`).join(' ') : 0)};
`

// $FlowIgnore
export const FormGrid: StyledComponent<GridProps, { ... }, HTMLFormElement> = styled(Grid).attrs({
  as: 'form',
})``
