// @flow
import * as React from 'react'

import Highlight from 'components/common/highlight'
import { Tabs, Tab } from 'components/common/tabbed'

type AndroidSetupProps = {
  apiKey: string,
  devApiKey: string,
  packageManagerMinVersion: string,
  hasDevApiKeyFeature: boolean,
  ...
}
const AndroidSetup = ({
  apiKey,
  devApiKey,
  packageManagerMinVersion,
  hasDevApiKeyFeature,
}: AndroidSetupProps): React.Node => {
  return (
    <div>
      <p>
        <i>
          This guide assumes that you've already setup your Firebase project in your Android
          application.
          <br />
          If you didn't, please follow{' '}
          <a href="https://firebase.google.com/docs/android/setup">
            Firebase's Get Started tutorial
          </a>{' '}
          or use the Firebase wizard in Android studio.
        </i>
      </p>
      <p>
        Batch is compatible with <strong>Android 4.0.3 (API Level 15)</strong> and higher.
        <br />
        Simply add in your app's <code>build.gradle</code>:
      </p>
      <Highlight language="groovy">
        {`implementation 'com.batch.android:batch-sdk:${packageManagerMinVersion}+'`}
      </Highlight>
      <p>
        Using Batch might require you to{' '}
        <a href="https://developer.android.com/studio/write/java8-support">
          enable Java 8 support through desugaring
        </a>{' '}
        in your project.
        <br />
        You might need to add the following in your <code>build.gradle</code>:{' '}
      </p>
      <Highlight language="groovy">
        {`android {
  // ...
  compileOptions {
    sourceCompatibility JavaVersion.VERSION_1_8
    targetCompatibility JavaVersion.VERSION_1_8
  }
} `}
      </Highlight>
      <h3>Dependencies</h3>
      <p>
        In order to enable push notifications support, Batch requires Firebase Cloud Messaging
        11.0.0 or higher.
      </p>
      <Highlight language="groovy">
        {`implementation 'com.google.firebase:firebase-core:12.0.1'
implementation 'com.google.firebase:firebase-messaging:12.0.1'`}
      </Highlight>

      <p>
        If you want to allow Batch to fetch your Advertising ID and facilitate debugging your
        integration, you can also include firebase-analytics. Don't worry, it is entirely optional:
      </p>
      <Highlight language="groovy">
        {`implementation "com.google.firebase:firebase-analytics:12.0.1"

// Or, if you want an even lighter dependency:
      
implementation "com.google.android.gms:play-services-ads-identifier:15.0.1"`}
      </Highlight>
      <p>
        Note that adding this in your apps will enable Advertising ID collection in both Firebase
        and Batch. Your app must provide a privacy policy to comply with Play Store rules. You can
        always disable Advertising ID collection in Batch by calling setCanUseAdvertisingID(false)
        on Batch's Config object. Please see Firebase's documentation regarding how to disable it.
      </p>

      <h3>Integrate the SDK into your Activities/Application class</h3>
      <p>
        You are now ready to setup Batch. The <i>setConfig</i> function should be called only once
        during your application lifetime to set up the SDK. As an application can be started without
        entenring an activity, it is <strong>very</strong> important that you call any of Batch's
        global setup methods in an <i>Application</i> subclass.
      </p>
      <Tabs id="android-setup" bsStyle="pills" animation={false}>
        <Tab eventKey={0} title="Java">
          <Highlight language="groovy">
            {`public class YourApp extends Application
{
	@Override
	public void onCreate() 
	{
		super.onCreate();
		
        ${
          hasDevApiKeyFeature
            ? `// Batch.setConfig(new Config("${apiKey}")); // live
		Batch.setConfig(new Config("${devApiKey}")); // development`
            : `Batch.setConfig(new Config("${apiKey}"));`
        }
		registerActivityLifecycleCallbacks(new BatchActivityLifecycleHelper());
		// You should configure your notification's customization options here.
		// Not setting up a small icon could cause a crash in applications created with Android Studio 3.0 or higher.
		// More info in our "Customizing Notifications" documentation
		// Batch.Push.setSmallIconResourceId(R.drawable.ic_notification_icon);
	}
}`}
          </Highlight>
        </Tab>
        <Tab eventKey={1} title="Kotlin">
          <Highlight language="kotlin">
            {`class YourApp: Application() {
	override fun onCreate() {
		super.onCreate()
		
        ${
          hasDevApiKeyFeature
            ? `// Batch.setConfig(Config("${apiKey}")) // live
        Batch.setConfig(Config("${devApiKey}")) // development`
            : `Batch.setConfig(Config("${apiKey}"))`
        }
		registerActivityLifecycleCallbacks(BatchActivityLifecycleHelper())
		// You should configure your notification's customization options here.
		// Not setting up a small icon could cause a crash in applications created with Android Studio 3.0 or higher.
		// More info in our "Customizing Notifications" documentation
		// Batch.Push.setSmallIconResourceId(R.drawable.ic_notification_icon)
	}
}`}
          </Highlight>
        </Tab>
      </Tabs>

      <p>
        If you've got any Activity in <i>singleTop/singleTask/singleInstance </i>mode, please call
        Batch.onNewIntent() in its onNewIntent() method, or simply add it in all of your activities.
        <br />
        You can find more information on what you can customize on your notifications and how{' '}
        <a href="https://doc.batch.com/android/advanced/customizing-notifications.html">here</a>
      </p>

      <blockquote>
        <p>
          Do not forget to set your Application subclass in your AndroidManifest.xml, under{' '}
          <i>android:name</i> under <i>&lt;application&gt;</i>
        </p>
      </blockquote>
      <p>
        <strong>Congratulations on finishing the bulk of the integration!</strong>
        <br />
        After deploying a build to your device or an Google Play enabled simulator, open the Logcat
        tab of Android Studio. You should see the following logs:
      </p>
      <Highlight language="markdown">
        {hasDevApiKeyFeature
          ? `Batch (${packageManagerMinVersion}) is running in dev mode (your API key is a dev one)
Installation ID: < your installation ID >`
          : 'Installation ID: < your installation ID >'}
      </Highlight>
      <h3>What's next</h3>
      <p>Here are a couple of extra steps you need to take before releasing your app:</p>
      <ul>
        <li>
          <strong>Small icon / Accent color:</strong> Make sure the{' '}
          <a href="https://doc.batch.com/android/advanced/customizing-notifications.html#_small-notification-icon">
            small icon
          </a>{' '}
          you are using is opaque white. We also recommend you use an{' '}
          <a href="https://doc.batch.com/android/advanced/customizing-notifications.html#_setting-notification-accent-color">
            accent color
          </a>
          .
        </li>
        <li>
          <strong>Mobile Landings:</strong> Make sure{' '}
          <a href="https://doc.batch.com/android/mobile-landings.html">Mobile Landings</a> are set
          up correctly.
        </li>
        <li>
          <strong>Custom user identifier:</strong> Add support for{' '}
          <a href="https://doc.batch.com/android/custom-data/customid.html">
            custom user identifiers
          </a>{' '}
          if you are planning to use the{' '}
          <a href="https://doc.batch.com/api/transactional.html">Transactional</a> and{' '}
          <a href="https://doc.batch.com/api/custom-data-api/set-update.html">Custom Data</a> APIs.
        </li>
        <li>
          <strong> Batch HMS plug-in: </strong>
          If you want to set up a Huawei (HMS) integration,{' '}
          <a href="https://doc.batch.com/android/huawei">please follow this documentation</a>.
        </li>
        <li>
          <strong> Analytics: </strong>
          <a href="https://doc.batch.com/android/advanced/event-dispatchers">
            Add an event dispatcher
          </a>{' '}
          to automatically track your campaigns in your third-party analytics tool.
        </li>
      </ul>
    </div>
  )
}

export default AndroidSetup
