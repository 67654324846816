// @flow

import { type Set } from 'immutable'

import { type Dayjs } from '../common/dayjs.custom'
import {
  type CampaignABRecord,
  type CappingCategoryRecord,
  type AbTestedThemeCodeProps,
  type TriggerConfigRecord,
} from 'com.batch.redux/_records'

import { DELAY_MODE } from 'constants/common'

type whenEvent = {
  event: string,
  query?: ?{ ... },
}

type delayMode = 'IMMEDIATE' | 'TIMER'

type landingConfig = {
  theme: string,
  contents: Array<landingTrad>,
}

type landingTrad = {
  language: ?string,
  header?: string,
  title?: string,
  body?: string,
  image_description?: string,
  webview_url?: string,
  link_open_target?: string,
  image?: string,
  tracking_id?: string,
  actions: Array<any>,
  global_action: any,
}
type msgTrad = {
  language: ?string,
  title?: string,
  body: string,
  deeplink?: string,
  media?: { [key: 'picture' | 'audio' | 'video']: string },
}

type triggerConfig = {
  grace_period: ?{
    value: number,
    unit: 'HOURS' | 'DAYS' | 'MINUTES',
  },
  timer: ?{
    value: number,
    unit: 'HOURS' | 'DAYS' | 'MINUTES',
  },
  timer_reference?: ?string,
  instance_id_selector?: ?string,
  cancelTriggersList: ?Array<{
    eventName: string,
    query: ?{ ... },
  }>,
  when: {
    ...whenEvent,
    reset_timer?: boolean,
  },
  delay_mode: delayMode,
}
type inAppTriggerConfig = {
  when: 'now' | 'next_session' | { event: string, label: ?string },
  grace_period?: ?{
    every: number,
    unit: 'HOURS' | 'DAYS' | 'MINUTES',
  },
  capping?: ?number,
  priority?: 'STANDARD' | 'IMPORTANT' | 'CRITICAL',
}
type targetingType = {
  segments: Array<string>,
  languages?: Array<string>,
  regions?: Array<string>,
  custom_audiences?: Array<string>,
  just_in_time?: boolean,
  query?: any,
}

type landingABTested = {
  a: landingConfig,
  b: landingConfig,
  enable_variants: Array<'a' | 'b'>,
}
type apiMedial = {
  icon?: string,
  picture?: string,
  video?: string,
  audio?: string,
}
export default class ApiLocalCampaign {
  name: string
  live: boolean
  capping: ?number
  isTrigger: boolean
  time_to_live: ?number
  start_date: ?string
  push_time: ?string
  local_push_time: ?string
  custom_payload: ?string
  local_start_date: ?string
  end_date: ?string
  local_end_date: ?string
  landing: ?(landingABTested | landingConfig)
  trigger: triggerConfig | inAppTriggerConfig
  targeting: targetingType
  labels: ?Array<string>
  deeplink: null | string
  media: ?apiMedial
  priority: 'high' | 'normal'
  gcm_collapse_key: ?{
    enabled: boolean,
    key?: string,
  }
  recurrence: ?{
    repeat_unit: 'DAILY' | 'WEEKLY' | 'MONTHLY',
    repeat_frequency: ?number,
    end_date: ?string,
  }
  messages: ?(
    | { a?: Array<msgTrad>, b?: Array<msgTrad>, enable_variants: Array<'a' | 'b'> }
    | Array<msgTrad>
  )
  // ================================================>
  constructor() {
    this.isTrigger = false
    this.targeting = {
      segments: [],
    }
    // this.messages = []
  }
  // ================================================>
  setLive(live: boolean): ApiLocalCampaign {
    this.live = live
    return this
  }
  // ================================================>
  setName(name: string): ApiLocalCampaign {
    this.name = name
    return this
  }

  setLabels(labels: Set<CappingCategoryRecord>): ApiLocalCampaign {
    if (labels.size > 0) {
      this.labels = labels.toArray().map(l => l.code)
    } else {
      this.labels = null
    }
    return this
  }

  // ================================================>
  activateLanding({
    themes,
    abTesting,
    campaignType,
  }: {
    themes: AbTestedThemeCodeProps,
    abTesting: CampaignABRecord,
    campaignType: campaignType,
  }): ApiLocalCampaign {
    if (abTesting.enabled && campaignType === 'in-app') {
      this.landing = {
        enable_variants: [],
        a: { theme: themes.a ?? '', contents: [] },
        b: { theme: themes.b ?? '', contents: [] },
      }
    } else {
      this.landing = { theme: themes.a ?? '', contents: [] }
    }

    return this
  }

  // ================================================>
  addLandingTranslation(translation: landingTrad): ApiLocalCampaign {
    if (!this.landing || !Array.isArray(this.landing.contents)) {
      throw 'landing is not active'
    }
    this.landing.contents.push(translation)
    return this
  }

  // ================================================>
  addPushTranslation(
    translation: msgTrad,
    variant?: 'a' | 'b' | false,
    enabledVariant: boolean
  ): ApiLocalCampaign {
    if (variant) {
      if (Array.isArray(this.messages))
        throw new Error('Mixing ab and non ab is impossible, this check should only be for flow')
      let tempForFlow: {
        a?: Array<msgTrad>,
        b?: Array<msgTrad>,
        enable_variants: Array<'a' | 'b'>,
      } = !this.messages ? { enable_variants: [] } : this.messages

      if (!tempForFlow[variant]) {
        tempForFlow[variant] = []
      }
      if (!tempForFlow.enable_variants?.includes(variant) && enabledVariant) {
        tempForFlow.enable_variants.push(variant)
      }
      if (Array.isArray(tempForFlow[variant])) tempForFlow[variant].push(translation)
      this.messages = tempForFlow
    } else {
      if (this.messages && !Array.isArray(this.messages))
        throw new Error('Mixing ab and non ab is impossible, this check should only be for flow')
      let tempForFlow: Array<msgTrad> = !this.messages ? [] : this.messages
      tempForFlow.push(translation)
      this.messages = tempForFlow
    }
    return this
  }

  addInAppTranslation(
    translation: landingTrad,
    variant?: 'a' | 'b' | false,
    enabledVariant: boolean
  ): ApiLocalCampaign {
    if (variant) {
      if (this.landing?.contents)
        throw new Error('Mixing ab and non ab is impossible, this check should only be for flow')
      let tempForFlow: landingABTested = !this.landing
        ? { enable_variants: [], a: { theme: '', contents: [] }, b: { theme: '', contents: [] } }
        : this.landing

      if (!tempForFlow.enable_variants.includes(variant) && enabledVariant) {
        tempForFlow.enable_variants.push(variant)
      }
      tempForFlow[variant].contents.push(translation)
      this.landing = tempForFlow
    } else {
      if (!this.landing || !Array.isArray(this.landing.contents)) {
        throw 'landing is not active'
      }
      this.landing.contents.push(translation)
    }
    return this
  }

  // ================================================>
  setAudiences(audiences: Array<string>): ApiLocalCampaign {
    this.targeting.custom_audiences = audiences
    return this
  }
  setJustInTime(jit: boolean): ApiLocalCampaign {
    this.targeting.just_in_time = jit
    return this
  }
  // ================================================>
  setRegions(regions: Array<string>): ApiLocalCampaign {
    this.targeting.regions = regions
    return this
  }
  // ================================================>
  setLanguages(languages: Array<string>): ApiLocalCampaign {
    this.targeting.languages = languages
    return this
  }
  // ================================================>
  setQuery(query: string): ApiLocalCampaign {
    this.targeting.query = query
    return this
  }
  // ================================================>
  addCluster(clusterChar: string): ApiLocalCampaign {
    const segmentMap = {
      N: 'NEW',
      U: 'ONE_TIME',
      E: 'ENGAGED',
      D: 'DORMANT',
      I: 'IMPORTED',
    }
    this.targeting.segments.push(segmentMap[clusterChar])
    return this
  }
  // ================================================>
  setRange(start: ?Dayjs, end: ?Dayjs, local: boolean): ApiLocalCampaign {
    if (local) {
      this.local_start_date = start ? start.format('YYYY-MM-DDTHH:mm:00') : null
      this.local_end_date = end ? end.format('YYYY-MM-DDTHH:mm:00') : null
    } else {
      this.start_date = start ? start.format('YYYY-MM-DDTHH:mm:00') : null
      this.end_date = end ? end.format('YYYY-MM-DDTHH:mm:00') : null
    }
    return this
  }

  setTrigger(config: TriggerConfigRecord): ApiLocalCampaign {
    this.isTrigger = true
    this.start_date =
      config.hasStart && config.start ? config.start.format('YYYY-MM-DDTHH:mm:00') : null
    this.end_date = config.hasEnd && config.end ? config.end.format('YYYY-MM-DDTHH:mm:00') : null

    this.trigger = {
      grace_period: config.hasGrace
        ? {
            unit: 'MINUTES',
            value: !config.grace.seconds ? 0 : Math.floor(config.grace.seconds / 60),
          }
        : null,
      when: {
        event: config.enterEvent,
        query: !config.enterEventQuery ? null : config.enterEventQuery,
        reset_timer: config.resetTimerOnEvent,
      },
      instance_id_selector: !config.hasInstanceId || !config.instanceId ? null : config.instanceId, // l'api ne supporte pas les named dans ce cas là
      timer_reference:
        config.pushTimerMode === 'event' || !config.pushTimerReference
          ? null
          : `eventAttr('${config.pushTimerReference}')`,
      cancelTriggersList:
        config.delayMode === DELAY_MODE.TIMER && config.hasExitEvent
          ? config.exitEvents.toArray().map(ev => ({
              eventName: ev.eventId,
              query: ev.query,
            }))
          : null,
      timer: {
        unit: 'MINUTES',
        value: !config.pushTimer.seconds
          ? 0
          : Math.floor(
              (config.pushTimer.seconds * (config.pushTimerMode === 'before' ? -1 : 1)) / 60
            ),
      },
      delay_mode: config.delayMode,
    }
    this.capping = config.hasCapping ? config.capping : null
    return this
  }
  setWhen(
    sendType: 'now' | 'scheduled' | 'recurring' | 'trigger',
    start: ?Dayjs,
    end: ?Dayjs,
    local: boolean,
    repeatUnit: 'DAILY' | 'WEEKLY' | 'MONTHLY',
    repeatFrequency: ?number,
    capping: ?number
  ): ApiLocalCampaign {
    if (sendType === 'trigger') {
      return this
    }
    if (sendType === 'now') {
      this.push_time = 'now'
      return this
    }
    if (local) this.local_push_time = start ? start.format('YYYY-MM-DDTHH:mm:00') : null
    else this.push_time = start ? start.format('YYYY-MM-DDTHH:mm:00') : null
    if (sendType === 'scheduled') {
      return this
    }
    this.recurrence = {
      repeat_unit: repeatUnit,
      repeat_frequency: repeatFrequency,
      end_date: end ? end.format('YYYY-MM-DDTHH:mm:00') : null,
    }

    if (parseInt(capping)) {
      this.capping = capping
    } else {
      this.capping = null
    }
    return this
  }
  setCustomPayloadForInApp(payload: false | string): ApiLocalCampaign {
    this.custom_payload = payload ? payload : null
    return this
  }
  setAdvanced(
    priority: false | 'HIGH' | 'NORMAL',
    collapseKey: null | string,
    ttl: ?number,
    payload: false | string
  ): ApiLocalCampaign {
    this.custom_payload = payload ? payload : null
    this.deeplink = null
    this.time_to_live = ttl ? ttl * 3600 : ttl
    if (priority) {
      this.priority = priority === 'HIGH' ? 'high' : 'normal'
    }
    this.gcm_collapse_key = !collapseKey
      ? {
          enabled: false,
        }
      : {
          enabled: true,
          key: collapseKey,
        }

    return this
  }
  setMedia(
    icon: string | false,
    picture: string | false,
    video: string | false,
    audio: string | false
  ): ApiLocalCampaign {
    if (icon || picture || video || audio) {
      let media: apiMedial = {}
      if (icon) {
        media.icon = icon
      }
      if (picture) {
        media.picture = picture
      } else if (video) {
        media.video = video
      } else if (audio) {
        media.audio = audio
      }

      this.media = media
    }
    return this
  }
  // ================================================>
  // c'est moche parce que j'ai tenté de please flow. TODO FIXME
  setInAppTriggerConfig({
    trigger,
    priority,
    triggerLabel,
    grace,
    capping,
  }: {
    trigger: string,
    priority: ?inAppPriority,
    grace?: number,
    triggerLabel: ?string,
    capping: ?number,
  }): ApiLocalCampaign {
    let triggerTmp: inAppTriggerConfig = {
      when: 'now',
    }
    if (priority) {
      triggerTmp.priority =
        priority === 'standard' ? 'STANDARD' : priority === 'important' ? 'IMPORTANT' : 'CRITICAL'
    }
    if (trigger === 'now' || trigger === 'next_session') {
      triggerTmp.when = trigger
    } else {
      triggerTmp.when = ({
        event: trigger,
        label: null,
      }: { event: string, label: ?string })
      if (triggerLabel) {
        triggerTmp.when.label = triggerLabel
      }
    }
    if (grace) {
      triggerTmp.grace_period = {
        every: grace,
        unit: 'HOURS',
      }
    }
    if (capping) {
      triggerTmp.capping = capping
    } else {
      triggerTmp.capping = null
    }
    this.trigger = triggerTmp
    return this
  }
}
