// @flow
import Immutable, { type List } from 'immutable'
import { map as _map } from 'lodash-es'

import languagesRaw from 'com.batch.common/languages'
import regionsRaw from 'com.batch.common/regions'

import {
  LanguageFactory,
  type LanguageRecord,
  RegionFactory,
  type RegionRecord,
} from 'com.batch.redux/_records'
// all the languages we allow for targeting / translation
export const languages: List<LanguageRecord> = new Immutable.List().push(
  ..._map(languagesRaw, (v: string, k: string) =>
    LanguageFactory({
      value: k,
      label: v,
      nb: 0,
      tokens: 0,
      pcent: '0.0%',
    })
  )
)
// all the regions we allow for targeting / translation
export const regions: List<RegionRecord> = new Immutable.List().push(
  ..._map(regionsRaw, (v: string, k: string) =>
    RegionFactory({
      value: k,
      label: v,
      nb: 0,
      tokens: 0,
      pcent: '0.0%',
    })
  )
)
