// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper } from 'components/form/input-wrapper'

import { InputContainer } from '../styles'

export type ConfirmPasswordProps = {
  label?: ReactNode,
  hint?: ReactNode,
  feedback?: ReactNode,
  additional?: ReactNode,
  htmlFor?: string,
  invalid?: boolean,
  autoFocus?: boolean,
  values: [string, string],
  setValues: ([string, string]) => void,
  ...
}

const ConfirmPassword = ({
  label,
  hint,
  feedback,
  additional,
  htmlFor,
  invalid = false,
  autoFocus,
  values,
  setValues,
}: ConfirmPasswordProps): React.Node => {
  const [shown, setShown] = React.useState(false)

  const toggleShown = React.useCallback(() => setShown(shown => !shown), [])
  const onChange = React.useCallback(
    evt => setValues([evt.target.value, values[1]]),
    [setValues, values]
  )
  const onChangeConfirm = React.useCallback(
    evt => setValues([values[0], evt.target.value]),
    [setValues, values]
  )
  return (
    <InputWrapper
      label={label}
      hint={hint}
      feedback={feedback}
      additional={additional}
      htmlFor={htmlFor}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
        <InputContainer invalid={invalid}>
          <input
            id={htmlFor}
            autoFocus={Boolean(autoFocus)}
            placeholder="Password"
            type={shown ? 'text' : 'password'}
            style={{ flex: '1 1 auto', minWidth: 0 }}
            value={values[0]}
            onChange={onChange}
          />
          <div className="styled-input-addons styled-addon-suf">
            <Button
              type="button"
              kind="discreet"
              addOn="prefix"
              onClick={toggleShown}
              tabIndex="-1"
            >
              {shown ? (
                <React.Fragment>
                  <Icon icon="reveal-false" /> Hide
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Icon icon="reveal" /> Show
                </React.Fragment>
              )}
            </Button>
          </div>
        </InputContainer>
        <InputContainer invalid={invalid}>
          <input
            placeholder="Repeat password"
            type={shown ? 'text' : 'password'}
            style={{ flex: '1 1 auto', minWidth: 0 }}
            value={values[1]}
            onChange={onChangeConfirm}
          />
          <div className="styled-input-addons styled-addon-suf">
            <Button
              type="button"
              kind="discreet"
              addOn="prefix"
              onClick={toggleShown}
              tabIndex="-1"
            >
              {shown ? (
                <React.Fragment>
                  <Icon icon="reveal-false" /> Hide
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Icon icon="reveal" /> Show
                </React.Fragment>
              )}
            </Button>
          </div>
        </InputContainer>
      </div>
    </InputWrapper>
  )
}

export { ConfirmPassword }
