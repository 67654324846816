// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'

import { PushOverviewMetricValue } from 'components/analytics/analytics.styles'
import { MetricLabel } from 'components/campaign/review/data/metric'
import { FlexLineItem } from 'components/common/flexline'
import { Tooltip } from 'components/common/tooltip'

import { kformat, percentage } from 'com.batch.common/utils'

type PushOverviewMetricProps = {
  color: string,
  value: number,
  grow?: number,
  alignRight?: boolean,
  small?: boolean,
  isRatio?: boolean,
  borderLeft?: boolean,
  tooltip: ReactNode,
  label: string,
}

export function PushOverviewMetric({
  color,
  value,
  grow = 0,
  alignRight = false,
  isRatio = false,
  small = false,
  label,
  borderLeft = false,
  tooltip,
}: PushOverviewMetricProps): React.Node {
  return (
    <FlexLineItem
      grow={grow}
      bl={borderLeft}
      style={{
        boxShadow: `0 2px 0 0 ${color} inset`,
      }}
    >
      <Tooltip tooltip={tooltip} placement={grow ? 'left' : 'top'} maxWidth={240}>
        <div
          style={{
            margin: 24,
            display: 'flex',
            alignItems: alignRight ? 'flex-end' : 'flex-start',
            flexDirection: 'column',
          }}
        >
          <MetricLabel>{label}</MetricLabel>
          <PushOverviewMetricValue small={small}>
            {isRatio ? percentage(value, 2) : kformat(value)}
          </PushOverviewMetricValue>
        </div>
      </Tooltip>
    </FlexLineItem>
  )
}
