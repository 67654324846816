// @flow
import { addClassNamesToElement } from '@lexical/utils'
import {
  TextNode,
  type NodeKey,
  type EditorConfig,
  type LexicalNode,
  type SerializedTextNode,
} from 'lexical'

export class LogicalNode extends TextNode {
  constructor(text: string, key?: NodeKey): void {
    super(text, key)
  }

  static getType(): string {
    return 'batch-logical'
  }

  static clone(node: LogicalNode): LogicalNode {
    return new LogicalNode(node.__text, node.__key)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config)
    addClassNamesToElement(element, 'batch_templating__logical')
    return element
  }

  exportJSON(): SerializedTextNode {
    const json = super.exportJSON()
    json.type = LogicalNode.getType()
    return json
  }

  static importJSON(node: SerializedTextNode): LogicalNode {
    return new LogicalNode(node.text)
  }

  updateDOM(prevNode: TextNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config)
    return isUpdated || !$isLogicalNode(prevNode)
  }
}

export function $createLogicalNode(text: string): LogicalNode {
  return new LogicalNode(text)
}

export function $isLogicalNode(node: ?LexicalNode): boolean {
  return node instanceof LogicalNode
}
