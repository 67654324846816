// @flow

import { useSpring, animated, config } from '@react-spring/web'
import * as React from 'react'

const springConfig = config.gentle

type MobileSvgProps = { mode: 'normal' | 'active' | 'hover', ... }

export const MobileSvg = ({ mode }: MobileSvgProps): React.Node => {
  const active = mode === 'active'
  const hover = mode === 'hover'
  const phoneProps = useSpring({
    to: { opacity: active ? 0 : hover ? 1 : 0.6 },
    config: springConfig,
  })
  const overlayProps = useSpring({
    to: { opacity: active ? 1 : 0 },
    config: springConfig,
  })
  const animNotifProps = useSpring({
    to: {
      transform: `translate(0, ${active || hover ? 0 : -80})`,
      opacity: active || hover ? 1 : 0,
    },
    config: springConfig,
  })
  return (
    <svg viewBox="0 30 254 147" fill="none" style={{ pointerEvents: 'none' }}>
      <g>
        <rect x="17" y="36.0037" width="220" height="152" fill="transparent" />
        <animated.path
          {...overlayProps}
          fill="url(#mobileBorderActive)"
          d="M103.929 60.9326C101 63.8615 101 68.5756 101 78.0037V146.004C101 155.432 101 160.146 103.929 163.075C106.858 166.004 111.572 166.004 121 166.004H135C144.428 166.004 149.142 166.004 152.071 163.075C155 160.146 155 155.432 155 146.004V78.0037C155 68.5756 155 63.8615 152.071 60.9326C149.142 58.0037 144.428 58.0037 135 58.0037H121C111.572 58.0037 106.858 58.0037 103.929 60.9326ZM128 164.004C130.209 164.004 132 162.213 132 160.004C132 157.795 130.209 156.004 128 156.004C125.791 156.004 124 157.795 124 160.004C124 162.213 125.791 164.004 128 164.004ZM118 62.0037C118 60.8991 118.895 60.0037 120 60.0037H130C131.105 60.0037 132 60.8991 132 62.0037C132 63.1082 131.105 64.0037 130 64.0037H120C118.895 64.0037 118 63.1082 118 62.0037ZM137 60.0037C135.895 60.0037 135 60.8991 135 62.0037C135 63.1082 135.895 64.0037 137 64.0037C138.105 64.0037 139 63.1082 139 62.0037C139 60.8991 138.105 60.0037 137 60.0037Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <animated.path
          {...phoneProps}
          d="M103.929 60.9326C101 63.8615 101 68.5756 101 78.0037V146.004C101 155.432 101 160.146 103.929 163.075C106.858 166.004 111.572 166.004 121 166.004H135C144.428 166.004 149.142 166.004 152.071 163.075C155 160.146 155 155.432 155 146.004V78.0037C155 68.5756 155 63.8615 152.071 60.9326C149.142 58.0037 144.428 58.0037 135 58.0037H121C111.572 58.0037 106.858 58.0037 103.929 60.9326ZM128 164.004C130.209 164.004 132 162.213 132 160.004C132 157.795 130.209 156.004 128 156.004C125.791 156.004 124 157.795 124 160.004C124 162.213 125.791 164.004 128 164.004ZM118 62.0037C118 60.8991 118.895 60.0037 120 60.0037H130C131.105 60.0037 132 60.8991 132 62.0037C132 63.1082 131.105 64.0037 130 64.0037H120C118.895 64.0037 118 63.1082 118 62.0037ZM137 60.0037C135.895 60.0037 135 60.8991 135 62.0037C135 63.1082 135.895 64.0037 137 64.0037C138.105 64.0037 139 63.1082 139 62.0037C139 60.8991 138.105 60.0037 137 60.0037Z"
          fillRule="evenodd"
          fill="#99A1AA"
          clipRule="evenodd"
        />
        <rect x="103" y="66.0037" width="50" height="88" rx="6" fill="white" />

        <animated.rect
          {...overlayProps}
          x="103"
          y="66.0037"
          width="50"
          height="88"
          rx="6"
          fill="url(#mobileScreenGradientActive)"
          fillOpacity="0.02"
        />

        <animated.g {...animNotifProps}>
          <g filter="url(#shadowNotif)">
            <rect x="75" y="81.0037" width="106" height="32" rx="4" fill="white" />
            <path
              d="M79.8787 85.8823C79 86.761 79 88.1752 79 91.0037V103.004C79 105.832 79 107.246 79.8787 108.125C80.7574 109.004 82.1716 109.004 85 109.004H97C99.8284 109.004 101.243 109.004 102.121 108.125C103 107.246 103 105.832 103 103.004V91.0037C103 88.1752 103 86.761 102.121 85.8823C101.243 85.0037 99.8284 85.0037 97 85.0037H85C82.1716 85.0037 80.7574 85.0037 79.8787 85.8823ZM107 90.0037C107 89.0609 107 88.5894 107.293 88.2966C107.586 88.0037 108.057 88.0037 109 88.0037H159C159.943 88.0037 160.414 88.0037 160.707 88.2966C161 88.5894 161 89.0609 161 90.0037C161 90.9465 161 91.4179 160.707 91.7108C160.414 92.0037 159.943 92.0037 159 92.0037H109C108.057 92.0037 107.586 92.0037 107.293 91.7108C107 91.4179 107 90.9465 107 90.0037ZM107.293 95.2966C107 95.5894 107 96.0609 107 97.0037C107 97.9465 107 98.4179 107.293 98.7108C107.586 99.0037 108.057 99.0037 109 99.0037H165C165.943 99.0037 166.414 99.0037 166.707 98.7108C167 98.4179 167 97.9465 167 97.0037C167 96.0609 167 95.5894 166.707 95.2966C166.414 95.0037 165.943 95.0037 165 95.0037H109C108.057 95.0037 107.586 95.0037 107.293 95.2966ZM107.293 102.297C107 102.589 107 103.061 107 104.004C107 104.946 107 105.418 107.293 105.711C107.586 106.004 108.057 106.004 109 106.004H149C149.943 106.004 150.414 106.004 150.707 105.711C151 105.418 151 104.946 151 104.004C151 103.061 151 102.589 150.707 102.297C150.414 102.004 149.943 102.004 149 102.004H109C108.057 102.004 107.586 102.004 107.293 102.297Z"
              fill="#212B37"
              fillRule="evenodd"
              clipRule="evenodd"
              fillOpacity="0.16"
            />
            <rect x="74.5" y="80.5037" width="107" height="33" rx="4.5" stroke="#E3E3E3" />
          </g>
        </animated.g>
      </g>
      <defs>
        <filter
          id="shadowNotif"
          x="46"
          y="56.0037"
          width="164"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="14" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="mobileScreenGradientActive"
          x1="23.25"
          y1="0.00366223"
          x2="31.7149"
          y2="87.4505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#027bd8" />
          <stop offset="1" stopColor="#0e68aa" />
        </linearGradient>
        <linearGradient
          id="mobileBorderActive"
          x1="126.11"
          y1="58.0037"
          x2="137.883"
          y2="165.035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#027bd8" />
          <stop offset="1" stopColor="#0e68aa" />
        </linearGradient>
      </defs>
    </svg>
  )
}
