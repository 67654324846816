// @flow
// ====================== LIBS
import * as React from 'react'

// ====================== COMPONENTS
import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'

// ====================== TYPES
type NumberAttributeProps = {
  name: string,
  value?: number,
  isCopyable?: boolean,
  ...
}

export const NumberAttributeRow = ({
  name,
  value,
  isCopyable = true,
}: NumberAttributeProps): React.Node => {
  return (
    <AttributeRow
      name={name}
      icon="number"
      copyableValue={`${value ?? ''}`}
      isCopyable={isCopyable}
    >
      {value}
    </AttributeRow>
  )
}
