// @flow
import Immutable, { type Map } from 'immutable'

import {
  OrchestrationListStatsStateFactory,
  type OrchestrationListStatsStateRecord,
} from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

const computeRate = (value: number, ref: number) => (ref === 0 ? 0 : value / ref)

export const parseOrchestrationStatsToListStatsState = (
  stats: ?Array<OrchestrationStatsDynamicDimension>
): Map<string, OrchestrationListStatsStateRecord> => {
  return stats
    ? stats.reduce((acc, { currentPeriod }) => {
        const delivered = currentPeriod.delivered ?? 0
        const uniqueOpen = currentPeriod.uniqueOpen ?? 0
        const openRate = computeRate(uniqueOpen, delivered)

        return acc.set(
          currentPeriod.token,
          OrchestrationListStatsStateFactory({
            loadingState: 'LOADED',
            delivered,
            openRate,
          })
        )
      }, Immutable.Map())
    : Immutable.Map()
}
