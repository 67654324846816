// @flow
import { useCallback } from 'react'

export function useBlurOnClickCallback(
  onClickCallback: () => void,
  inputs: $ReadOnlyArray<mixed> = [],
  preventDefault?: boolean
): (evt: SyntheticMouseEvent<HTMLButtonElement>) => void {
  const onClick = useCallback(
    (evt: SyntheticMouseEvent<HTMLButtonElement>) => {
      if (preventDefault) evt.preventDefault()
      evt.currentTarget.blur()
      onClickCallback()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we use this as a replacement for useCallback
    [...inputs, onClickCallback]
  )
  return onClick
}
