// @flow

import styled, { type StyledComponent, css } from 'styled-components'

import { BoxHeader } from 'components/common/box'
import { IconContainer } from 'components/common/svg-icon'
import { EmptyCell, TableBody, TableCell, TableRow } from 'components/common/table'
import { TableEmptyContent } from 'components/common/table/table.styles'
import { Tag } from 'components/common/tags/tag.styles'
import { ButtonShowMore, TagsListContainer } from 'components/common/tags/tags-list.styles'
import { TextLabel } from 'components/styled/text'
import { textLight } from 'components/styled/tokens/colors'

type AttributeRowContainerProps = { clickable: boolean, light: boolean, ... }
export const AttributeRowContainer: StyledComponent<AttributeRowContainerProps, { ... }, *> =
  styled(TableRow)`
    cursor: ${(props: AttributeRowContainerProps) => (props.clickable ? 'pointer' : 'default')};

    &:hover .copy-to-clipboard {
      opacity: 1;
    }

    // display the "copy to clipboard" only if the button "show more" of the a tags list is not hovered

    &:hover:has(${ButtonShowMore}:hover) .copy-to-clipboard {
      opacity: 0;
    }

    &:after {
      z-index: 0;
    }

    ${TextLabel} {
      padding-left: 2px;
      font-weight: 400;
    }

    ${TagsListContainer} {
      padding: 8px 0;
    }

    ${Tag} {
      cursor: pointer;
    }

    .copy-to-clipboard {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      margin-left: 8px;
    }

    ${IconContainer} {
      margin-right: 3px;
    }

    ${TableEmptyContent} {
      display: block;
    }

    .attribute-value {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    ${(props: AttributeRowContainerProps) =>
      props.light &&
      css`
        ${TableCell}:last-child {
          color: ${textLight};
        }
      `};
  `

export const AttributesTableBody: StyledComponent<*, *, *> = styled(TableBody)`
  ${TableEmptyContent} {
    display: block;
    line-height: 0;
    padding: 10px 0;

    ${EmptyCell} {
      display: inline-block;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`

export const AttributesBoxHeader: StyledComponent<*, *, *> = styled(BoxHeader)`
  padding-right: 10px;
`

export const AttributesContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  overflow-y: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`
