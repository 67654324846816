// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { ConditionLine, FlexLine, FlexLineItem } from 'components/common/flexline'
import { Grid } from 'components/common/grid'
import { OptionPcentForLangRegion } from 'components/common/option-row-pcent'
import { Icon } from 'components/common/svg-icon'
import { Select, SelectMulti } from 'components/form'
import {
  OperatorAndNegateFormatter,
  type operatorAndNegate,
} from 'components/query/left/left-helper'

import { InOperator, NotInOperator } from 'com.batch.redux/query/query.records.operators'

const optToString = (value: ?$ReadOnly<{ label: string, ... }>) => value?.label ?? ''
const operators: List<operatorAndNegate> = new Immutable.List().push(
  ...[
    { operator: InOperator, negate: false },
    { operator: NotInOperator, negate: false },
  ]
)
const optionOperatorToString = (opt: ?operatorAndNegate) => opt?.operator.value ?? ''
type LangRegionTargetingProps = {
  lang: boolean,
  loading: boolean,
  hasQuery: boolean,
  updateLangRegion: (value: Array<langType>, isRegion: boolean) => void,
  options: List<langType>,
  values: List<langType>,
}

export const LangRegionTargeting = ({
  lang,
  loading,
  hasQuery,
  updateLangRegion,
  options,
  values,
}: LangRegionTargetingProps): React.Node => {
  const [isInverted, setIsInverted] = React.useState(
    options.size > 5 && options.size / 2 < values.size && values.size !== options.size
  )

  /*
    The above useState triggers before all redux store are correctly populated ; we use an effect
    and the flag isManuallyUpdated to prevent the useEffect from triggering once the user started to edit
  */
  const manuallyUpdated = React.useRef(false)
  React.useEffect(() => {
    if (!manuallyUpdated.current)
      setIsInverted(
        options.size > 5 && options.size / 2 < values.size && values.size !== options.size
      )
  }, [options.size, values.size, manuallyUpdated])

  const optionList = React.useMemo(() => new Immutable.List().push(...options), [options])
  const pickedOptions = React.useMemo(() => {
    if (isInverted) {
      return options.filter(lr => !values.includes(lr))
    } else {
      return values
    }
  }, [isInverted, options, values])
  const propagate = React.useCallback(
    (values: List<langType>, reverseValues: boolean) => {
      manuallyUpdated.current = true
      const valuesToDispatch = reverseValues
        ? optionList.filter(lr => !values.includes(lr))
        : values
      updateLangRegion(valuesToDispatch.toArray(), !lang)
    },
    [lang, optionList, updateLangRegion]
  )
  const onValueChange = React.useCallback(
    values => {
      if (values === null) {
        propagate(new Immutable.List(), isInverted)
      } else {
        propagate(values, isInverted)
      }
    },
    [isInverted, propagate]
  )
  const changeInvertMode = React.useCallback(
    (operator: ?operatorAndNegate) => {
      const invert: boolean = operator?.operator === NotInOperator
      propagate(values, true)
      setIsInverted(invert)
    },
    [propagate, values]
  )
  return (
    <ConditionLine key={lang ? 'lang' : 'region'}>
      <FlexLineItem grow={1}>
        <div className="condi">
          <div className="condi__logical condi__logical--disabled">AND</div>
          <div className="condi__header">
            <Icon icon={lang ? 'message' : 'place'} className="condi__header__icon" />
            <div className="condi__header__label condi__header__label--noevent condi__header__label--main">
              {lang ? 'Language' : 'Country'}
            </div>
            <div className="condi__header__phrase">
              <FlexLine>
                <FlexLineItem grow={1}>
                  <Grid template="auto 1fr">
                    <Select
                      style={{ width: 150 }}
                      options={operators}
                      optionToString={optionOperatorToString}
                      optionFormatter={OperatorAndNegateFormatter}
                      value={isInverted ? operators.get(1) : operators.get(0)}
                      onChange={changeInvertMode}
                    />
                    <SelectMulti
                      key={optionList.hashCode()}
                      optionToString={optToString}
                      value={pickedOptions}
                      onChange={onValueChange}
                      placeholder={
                        lang
                          ? 'Speak any language (pick to restrict)'
                          : 'Live in any country (pick to restrict)'
                      }
                      optionFormatter={OptionPcentForLangRegion}
                      isLoading={loading}
                      optionMenuShownCount={7}
                      options={optionList}
                    />
                  </Grid>
                </FlexLineItem>
              </FlexLine>
            </div>
          </div>
          {(!lang || hasQuery) && (
            <div className="condi__logical condi__logical--bottom condi__logical--disabled">
              AND
            </div>
          )}
        </div>
      </FlexLineItem>
    </ConditionLine>
  )
}
