// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { TextMuted } from 'components/app/push/settings.styles'
import { EventFormatter } from 'components/campaign/event-formatter'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Ellipsis, Timezone } from 'components/styled/text'
import { colors, schemes, Opacity } from 'components/styled/tokens'
import { textLight } from 'components/styled/tokens/colors'

import { dateTime } from 'com.batch.common/date-formats'
import { computeRepeatWord } from 'com.batch.common/utils'

import { type TriggerConfigRecord } from 'com.batch.redux/_records'

import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'
import { DELAY_MODE } from 'constants/common'

type WhenTriggerConfigProps = {
  config: TriggerConfigRecord,
  ...
}

const KeyValueTooltip: StyledComponent<*, *, HTMLElement> = styled.table`
  margin: -6px -4px -10px -4px;

  tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    overflow: hidden;
  }

  td {
    padding: 8px 2px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
  }
  td:first-child {
    font-weight: 600;
    color: ${schemes.lightlucent['100']};
  }
  td:last-child {
    padding-left: 12px;
    color: ${schemes.lightlucent['60']};
    font-weight: 400;
    text-overflow: ellipsis;
  }

  ${Timezone} {
    margin: 0 0 0 8px;
    color: ${colors.text};
    background: ${Opacity(colors.fill, 0.72)};
  }
`

const WhenTriggerConfig: React.ComponentType<WhenTriggerConfigProps> =
  React.memo<WhenTriggerConfigProps>(({ config }: WhenTriggerConfigProps) => {
    return (
      <Tooltip
        tooltip={
          <KeyValueTooltip>
            <tbody>
              <tr>
                <td>Trigger event</td>
                <td>
                  <EventFormatter
                    eventCode={config.enterEvent}
                    eventQuery={config.enterEventQuery}
                  />
                </td>
              </tr>
              {!config.hasExitEvent || config.isHackForMultiStep
                ? null
                : config.exitEvents.map((exitEvent, index) => (
                    <tr key={index}>
                      <td>
                        {index === 0
                          ? `Cancellation event${config.exitEvents.size > 1 ? 's' : ''}`
                          : ''}
                      </td>
                      <td>
                        <EventFormatter
                          eventCode={exitEvent.eventId}
                          eventQuery={exitEvent.query}
                        />
                      </td>
                    </tr>
                  ))}
              {config.hasStart && config.start && (
                <tr>
                  <td>Starting on</td>
                  <td>
                    <span style={{ display: 'flex' }}>
                      {config.start.format(dateTime)}
                      <Timezone>UTC</Timezone>
                    </span>
                  </td>
                </tr>
              )}
              {config.hasEnd && config.end && (
                <tr>
                  <td>Ending on</td>
                  <td>
                    <span style={{ display: 'flex' }}>
                      {config.end.format(dateTime)}
                      <Timezone>UTC</Timezone>
                    </span>
                  </td>
                </tr>
              )}
              {config.pushTimer && !config.isHackForMultiStep && (
                <tr>
                  <td>Delay</td>
                  <td>
                    <span style={{ display: 'flex' }}>
                      {config.delayMode === DELAY_MODE.TIMER ? (
                        <React.Fragment>
                          {config.pushTimer.fullText}
                          {!config.pushTimerReference
                            ? ''
                            : ` (${config.pushTimerMode} ${config.pushTimerReference})`}
                        </React.Fragment>
                      ) : (
                        'Immediately'
                      )}
                    </span>
                  </td>
                </tr>
              )}
              {config.hasGrace && (
                <tr>
                  <td>Grace period</td>
                  <td>{config.grace.fullText}</td>
                </tr>
              )}
            </tbody>
          </KeyValueTooltip>
        }
        placement="right"
      >
        <Grid
          template={'auto 1fr'}
          style={{ display: 'inline-grid', padding: 4, margin: -4, columnGap: '8px' }}
        >
          <Icon icon="mouse" />
          <Ellipsis>
            <EventFormatter isTooltipDisabled asText eventCode={config.enterEvent} />
          </Ellipsis>
        </Grid>
      </Tooltip>
    )
  })
export const When = ({
  partialOrchestration,
}: {
  partialOrchestration: PartialOrchestrationRecord,
  ...
}): React.Node => {
  if (partialOrchestration.triggerConfig) {
    return partialOrchestration.incomplete && !partialOrchestration.triggerConfig.enterEvent ? (
      <TextMuted>No entry event selected</TextMuted>
    ) : (
      <WhenTriggerConfig config={partialOrchestration.triggerConfig} />
    )
  }
  if (partialOrchestration.oneTime) {
    return (
      <Grid template="16px 1fr auto" gap={8}>
        <Icon icon="calendar" />
        <span>{partialOrchestration.oneTime?.sendDate.format('DD/MM/YYYY [at] HH:mm')}</span>
        {!partialOrchestration.oneTime?.localTimezoneDependant && <Timezone>UTC</Timezone>}
      </Grid>
    )
  }
  if (partialOrchestration.recurring) {
    return !partialOrchestration.recurring.start ? (
      <TextMuted>No start date selected</TextMuted>
    ) : (
      <Grid template="16px 1fr auto" gap={8}>
        <Icon style={{ color: textLight }} icon="campaign-recurring" />
        <span>
          Every{' '}
          {computeRepeatWord(
            partialOrchestration.recurring.repeatUnit,
            partialOrchestration.recurring.repeatFrequency,
            partialOrchestration.recurring?.start
          )}{' '}
          at {partialOrchestration.recurring?.start?.format('HH[h]mm')}
        </span>
        {!partialOrchestration.recurring?.localTimezoneDependant && <Timezone>UTC</Timezone>}
      </Grid>
    )
  }
  return null
}
