// @flow
// ====================== LIBS
import * as React from 'react'

// ====================== COMPONENTS
import { MultiResultsBannerContainer } from './multi-results-banner.styles'
import { Navigator } from './navigator'
// ====================== DATA

// ====================== TYPES
type MultiResultsBannerProps = {
  nbResults: number,
  currentResult: number,
  prev: () => void,
  next: () => void,
  ...
}

export const MultiResultsBanner = ({
  nbResults,
  currentResult,
  prev,
  next,
}: MultiResultsBannerProps): React.Node => {
  return (
    <MultiResultsBannerContainer>
      <p>
        Result {currentResult} of {nbResults} matching your search
      </p>
      <Navigator nbResults={nbResults} currentResult={currentResult} prev={prev} next={next} />
    </MultiResultsBannerContainer>
  )
}
