// @flow

import Immutable, { type List } from 'immutable'
import { get as _get } from 'lodash-es'
import request from 'superagent-interface-promise'

import { config } from 'com.batch.common/config'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router/router'

import {
  SafariWebsitesFactory,
  AppFactory,
  PushConfigFactory,
  HMSConfigFactory,
  P12ConfigFactory,
  P8ConfigFactory,
  GCMConfigFactory,
  WnsConfigFactory,
  VAPIDConfigFactory,
  CappingCategoryFactory,
  CappingFactory,
  type AppRecord,
  type CappingRecord,
  type CappingCategoryRecord,
  type PushConfigRecord,
  type CompanyRecord,
  type TestDeviceRecord,
  type SafariWebsitesRecord,
  type SafariWebsitesProps,
} from './_records'

import { InAppCappingFactory, type InAppCappingRecord } from 'com.batch.redux/capping.records'
import CompanyApi from 'com.batch.redux/company.api'
import * as DeviceApi from 'com.batch.redux/testDevice.api'
import { normalizeUser } from 'com.batch.redux/user.api'
import { type UserRecord, EntityLogFactory } from 'com.batch.redux/user.records'

import { parseFcmConfig } from 'com.batch/settings/infra/parses/parse-fcm-config'
import { type FcmConfigRaw } from 'com.batch/settings/infra/types/fcm-config-raw'

const demoAppIds = [5996, 10958, 24927, 28439]

export const sendGDPRrequest = ({
  app,
  mode,
  value,
  req,
}: {
  app: AppRecord,
  mode: 'custom_id' | 'installation_id' | 'advertising_id',
  value: string,
  req: 'remove' | 'review',
  ...
}): Promise<any> => {
  return request.post(`/api/app/${app.id}/gdpr`, {
    mode,
    request: req,
    value,
  })
}

export const fetchInAppCappings = ({
  app,
}: {
  app: AppRecord,
  ...
}): Promise<List<InAppCappingRecord>> => {
  const url = generateUrl('api_app_cappings_read', { appId: app.id })
  return request.get(url).then(
    resp => {
      return Immutable.List(
        resp.body.inAppCappings.map(data => {
          const isUnitInDays = data.level === 'global' && data.period % (3600 * 24) === 0
          return InAppCappingFactory(
            data.level === 'global'
              ? {
                  level: 'global',
                  capping: data.capping,
                  enabled: Boolean(data.enabled),
                  periodUnit: isUnitInDays ? 'd' : 'h',
                  periodValue: isUnitInDays ? data.period / (3600 * 24) : data.period / 3600,
                }
              : { level: 'session', enabled: Boolean(data.enabled), capping: data.capping }
          )
        })
      )
    },
    err => {
      throw err?.body?.errors[0]?.message ?? 'Unexpected error'
    }
  )
}

export const saveInAppCappings = ({
  app,
  pressure,
}: {
  app: AppRecord,
  pressure: List<InAppCappingRecord>,
  ...
}): Promise<any> => {
  const url = generateUrl('api_app_cappings_update', { appId: app.id })
  return request.post(url, {
    inAppCappings: pressure.map(data => {
      const level = data.get('level')
      let period = 0
      if (level === 'global') {
        period = data.get('periodValue') * 3600
        if (data.get('periodUnit') === 'd') {
          period *= 24
        }
      }

      return {
        level,
        capping: data.get('capping'),
        period,
        enabled: data.get('enabled'),
      }
    }),
  })
}

export const listGDPRrequests = ({ app }: { app: AppRecord, ... }): Promise<any> => {
  return request.get(`/api/app/${app.id}/gdpr`).then(
    resp => {
      return resp.body.map(req => {
        req.request_date = dayjs.utc(req.request_date, 'YYYY-MM-DD[T]HH:mm:ss')
        return req
      })
    },
    err => ({ error: true, err: err })
  )
}

export const normalizeApp = (raw: any): AppRecord => {
  const platform = _get(raw, 'platform', '')
  return AppFactory({
    id: _get(raw, 'id'),
    showIntegrate: _get(raw, 'getStarted'),
    projectKey: _get(raw, 'projectKey'),
    demo: demoAppIds.indexOf(_get(raw, 'id')) !== -1,
    archived: !!_get(raw, 'archived', false),
    name: _get(raw, 'name', ''),
    status: _get(raw, 'status', 'free'),
    openRateAlg: _get(raw, 'openRateAlg', 'ACCURATE') || 'ACCURATE',
    platform,
    icon: _get(raw, 'icon'),
    apiKey: _get(raw, 'apiKey'),
    devApiKey: _get(raw, 'devApiKey', ''),
    sdk: platform === 'windows' ? 'Windows' : _get(raw, 'sdk', ''),
    pushConfig: buildPushConfigFromRaw(
      _get(raw, 'platform', ''),
      _get(raw, 'platformInformations[0]', '')
    ),
    pushImported: _get(raw, 'pushImported', false),
    cappingCategories: new Immutable.List().push(
      ..._get(raw, 'cappingCategories', []).map(buildAppCategorieFromRaw)
    ),
    loadingState: 'LOADED',
    created: dayjs.utc(_get(raw, 'created'), 'YYYY/MM/DD HH:mm'),
    updated: dayjs.utc(_get(raw, 'updated'), 'YYYY/MM/DD HH:mm'),
    inboxSecret: _get(raw, 'inboxSecret'),
    defaultLanguageId: _get(raw, 'defaultLanguageId'),
    webpushAuth: _get(raw, 'webpushAuth'),
    ttlRetargeting: _get(raw, 'ttlRetargeting', null),
    ttlJourney: _get(raw, 'ttlJourney', null),
    bundleId: _get(raw, 'bundleId', ''),
    validPushConfig: _get(raw, 'validPushConfig', false),
    safariSetupValid: _get(raw, 'safariSetupValid', false),
    features: Immutable.Set(_get(raw, 'features', [])),
    companyId: _get(raw, 'company.id'),
    logs: new Immutable.List().push(
      ...(typeof raw.logs !== 'undefined'
        ? raw.logs.reverse().map(rl => {
            return EntityLogFactory({
              when: dayjs.utc(rl.createdAt),
              editor: rl.editor,
              message: rl.message,
              topic: rl.topic,
            })
          })
        : [])
    ),
    lastAccess: _get(raw, 'lastAccess')
      ? dayjs.utc(_get(raw, 'lastAccess'), 'YYYY/MM/DD HH:mm')
      : null,
  })
}
const buildPushConfigFromRaw: (
  platform: Platforms,
  raw: {
    alternateSenderIds: Array<string>,
    alternateServerApiKeys: Array<string>,
    certificateName: string,
    collapseKey: ?string,
    hmsAppId: ?string,
    hmsAppKey: ?string,
    hmsCallbackKey: ?string,
    hmsCallbackUrlToken: ?string,
    hmsCallbackUsername: ?string,
    gcmSenderId: ?string,
    devOrigins: Array<string>,
    maxRate: ?number,
    platform: Platforms,
    priority: 'HIGH' | 'NORMAL',
    pushKey: ?string,
    pushKeyExpirationDate: string,
    subdomain: ?string,
    ttl: ?number,
    apnsTopic: ?string,
    apnsTeamId: ?string,
    apnsKeyId: ?string,
    apnsPrivateKey: ?string,
    updated: string,
    uploaded: string,
    safariWebsiteName: string,
    safariWebsiteIcon: string,
    androidSmallIcon: string,
    safariWebsites: Array<SafariWebsitesProps>,
    safariOpenTracking: boolean,
    fcmServiceAccountKeys: ?Array<FcmConfigRaw>,
    ...
  }
) => PushConfigRecord = (platform, raw) => {
  switch (platform) {
    case 'windows':
      return PushConfigFactory({
        maxRate: raw.maxRate,
        wns: WnsConfigFactory({
          packageSID: raw.gcmSenderId || '',
          clientSecret: raw.pushKey || '',
        }),
      })
    case 'webpush': {
      let safari = new Immutable.List<SafariWebsitesRecord>()
      if (Array.isArray(raw.safariWebsites)) {
        safari = safari.push(...raw.safariWebsites.map(props => SafariWebsitesFactory(props)))
      }
      return PushConfigFactory({
        defaultTtl: raw.ttl,
        defaultPriority: raw.priority,
        maxRate: raw.maxRate,
        vapid: VAPIDConfigFactory({
          publicKey: raw.gcmSenderId || '',
          privateKey: raw.pushKey || '',
          subdomain: raw.subdomain || '',
        }),
        allowedDevOrigins: raw.devOrigins || [],
        safariWebsiteName: raw.safariWebsiteName,
        safariWebsiteIcon: raw.safariWebsiteIcon,
        androidSmallIcon: raw.androidSmallIcon,
        safariWebsites: safari,
        safariOpenTracking: raw.safariOpenTracking,
      })
    }
    case 'android': {
      const hmsConfig = !raw.hmsAppId
        ? null
        : HMSConfigFactory({
            appId: raw.hmsAppId,
            appKey: raw.hmsAppKey || '',
            callbackUrlToken: raw.hmsCallbackUrlToken || '',
            callbackUsername: raw.hmsCallbackUsername || '',
            callbackKey: raw.hmsCallbackKey || '',
          })
      let gcmConfigs =
        raw.gcmSenderId || raw.pushKey
          ? new Immutable.List().push(
              ...[
                GCMConfigFactory({
                  senderId: raw.gcmSenderId || '',
                  serverApiKey: raw.pushKey || '',
                }),
              ]
            )
          : new Immutable.List()
      if (gcmConfigs.size > 0 && raw.alternateSenderIds.length > 0) {
        raw.alternateSenderIds.forEach((senderId, index) => {
          gcmConfigs = gcmConfigs.push(
            GCMConfigFactory({
              senderId,
              serverApiKey:
                typeof raw.alternateServerApiKeys[index] !== 'undefined'
                  ? raw.alternateServerApiKeys[index]
                  : '',
            })
          )
        })
      }
      return PushConfigFactory({
        defaultTtl: raw.ttl,
        defaultPriority: raw.priority,
        defaultCollapseKey: raw.collapseKey || '',
        maxRate: raw.maxRate,
        gcm: gcmConfigs,
        hms: hmsConfig,
        fcm: new Immutable.List().push(
          ...(raw.fcmServiceAccountKeys
            ? raw.fcmServiceAccountKeys.map(config => parseFcmConfig(config.service_account_key))
            : [])
        ),
      })
    }
    case 'ios':
      if (raw.apnsPrivateKey && raw.apnsTeamId && raw.apnsKeyId && raw.apnsTopic) {
        return PushConfigFactory({
          defaultTtl: raw.ttl,
          defaultPriority: raw.priority,
          maxRate: raw.maxRate,
          p8: P8ConfigFactory({
            topic: raw.apnsTopic,
            uploadedOn: dayjs.utc(raw.uploaded ? raw.uploaded : raw.updated),
            teamId: raw.apnsTeamId || '',
            keyId: raw.apnsKeyId || '',
            privateKey: raw.apnsPrivateKey || '',
          }),
        })
      }
      if (raw.certificateName) {
        return PushConfigFactory({
          defaultTtl: raw.ttl,
          defaultPriority: raw.priority,
          maxRate: raw.maxRate,
          p12: P12ConfigFactory({
            certificateName: raw.certificateName,
            expireAt: dayjs.utc(raw.pushKeyExpirationDate, 'YYYY/MM/DD HH:mm'),
            uploadedOn: dayjs.utc(raw.uploaded ? raw.uploaded : raw.updated),
            valid: dayjs.utc(raw.pushKeyExpirationDate, 'YYYY/MM/DD HH:mm').isAfter(dayjs.utc()),
          }),
        })
      }
  }
  return PushConfigFactory({ vapid: VAPIDConfigFactory() })
}

const buildAppCategorieFromRaw = (cat: any) => {
  let cappings: List<CappingRecord> = new Immutable.List()
  if (!cat.cappings) {
    cat.cappings = []
  }
  cat.cappings.forEach(cap => {
    cappings = cappings.push(
      CappingFactory({
        id: cap.id,
        period: `${cap.cappingDurationValue}${cap.cappingDurationUnit
          .substring(0, 1)
          .toLowerCase()}`,
        capping: cap.count,
      })
    )
  })
  return CappingCategoryFactory({
    cappings,
    id: cat.id,
    internal: cat.code.substring(0, 1) === '_',
    code: cat.code,
    name: cat.name,
    enabled: cat.enabled,
  })
}

/**
 * parses device information from {}
 */
let parseDeviceInfo = (deviceData: any) => {
  return {
    brand: _get(deviceData, 'device_brand', ''),
    device: _get(deviceData, 'device_type', ''),
    distribution: _get(deviceData, 'distribution', ''),
    region: _get(deviceData, 'region', ''),
  }
}

/** get last_date unix tsp from {}, if available
 * returns an object with a when dayjs & an human readable duration
 */
let parseLastDate = (tmp: any) => {
  const ts = _get(tmp, 'last_date')
  let when = null
  let duration = null
  if (ts) {
    when = dayjs.unix(ts)
    duration = dayjs
      .duration(Number(when.format('x')) - Number(dayjs.utc().format('x')))
      .humanize(true)
  }
  return { when, duration }
}

export const integrateDone = (app: AppRecord): Promise<any> => {
  return request.post(`/api/app/${app.id}/getting-started`)
}

export const infos = (app: AppRecord, prod: boolean = false): Promise<any> => {
  return request
    .get(
      `/api/app/${app.id}/data/infos/${app.platform === 'webpush' ? 'prod' : prod ? 'prod' : 'dev'}`
    )
    .then(
      response => {
        let raw = _get(response, 'body.results')
        if (!raw || raw instanceof Array) {
          throw 'No info'
        } else {
          const startRaw = _get(raw, 'last_start')
          const pushRaw = _get(raw, 'last_push_token')
          const sdkRaw = _get(raw, 'general')
          return {
            token: {
              device: parseDeviceInfo(pushRaw),
              production: _get(pushRaw, 'production', false),
              date: parseLastDate(pushRaw),
              exist: pushRaw.length !== 0,
            },
            start: {
              ok: !(startRaw instanceof Array),
              date: parseLastDate(startRaw),
              device: parseDeviceInfo(startRaw),
            },
            sdk: sdkRaw,
            origin: raw,
          }
        }
      },
      error => {
        console.log(error)
      }
    )
}

export const fetchById = (
  id: number
): Promise<{ app: AppRecord, company: CompanyRecord, devices: Array<TestDeviceRecord>, ... }> => {
  return request.get(`/api/app/${id}`).then(
    response => {
      return {
        app: normalizeApp(response.body),
        company: CompanyApi.normalize(response.body.company),
        devices: response.body.testDevices.map(DeviceApi.normalizeDevice),
      }
    },
    () => {
      throw { error: 'Please reload the page in a few seconds' }
    }
  )
}

export const fetchAll = (companyId: number): Promise<List<AppRecord>> => {
  return request
    .get(config.common.urls.applications.replace('{companyId}', companyId?.toString() ?? ''))
    .then(response => {
      return new Immutable.List().push(...response.body.map(normalizeApp))
    })
}

export const fetchProjectApps = (
  companyId: number,
  projectId: string
): Promise<List<AppRecord>> => {
  const url = generateUrl('api_project_apps', { companyId, projectId })
  return request.get(url).then(response => {
    return new Immutable.List().push(...response.body.map(normalizeApp))
  })
}

export const saveCappingCategory = ({
  appId,
  categorie,
  context,
}: {
  appId: number,
  categorie: CappingCategoryRecord,
  context?: string,
  ...
}): Promise<{
  categorie: CappingCategoryRecord,
  categorieRecord: CappingCategoryRecord,
  context?: string,
  ...
}> => {
  return request.post(`/api/app/${appId}/capping`, { categorie, context }).then(
    response => {
      return {
        categorie: buildAppCategorieFromRaw(response.body).set('count', categorie.count),
        categorieRecord: categorie,
      }
    },
    err => {
      throw {
        categorieRecord: categorie,
        error: err.body,
      }
    }
  )
}

export const deleteCappingCategorie = ({
  appId,
  categorie,
}: {
  appId: number,
  categorie: CappingCategoryRecord,
  ...
}): Promise<{
  categorie: CappingCategoryRecord,
  categorieRecord: CappingCategoryRecord,
  ...
}> => {
  return request.delete(`/api/app/${appId}/capping/${categorie.id}`).then(
    response => {
      return {
        categorie: buildAppCategorieFromRaw(response.body),
        categorieRecord: categorie,
      }
    },
    err => {
      throw {
        categorieRecord: categorie,
        error: err.body,
      }
    }
  )
}

export const genVapid = (): Promise<{ publicKey: string, privateKey: string, ... }> =>
  request.get('/api/vapid').then(response => response.body)

export const checkWns = (
  app: AppRecord,
  packageId: string,
  secret: string
): Promise<{ app: AppRecord, packageId: string, secret: string, ... }> => {
  return request
    .post('/api/push/wns', {
      grant_type: 'client_credentials',
      client_id: packageId,
      client_secret: secret,
      scope: 'notify.windows.com',
    })
    .then(
      () => {
        return {
          app,
          packageId,
          secret,
        }
      },
      () => {
        throw { app }
      }
    )
}

export const sdksVersions = (): Promise<
  Array<{ sdk: string, latest: string, packager_str?: string, ... }>,
> => {
  return request.get('https://doc.batch.com/_api/sdk-latest.json').then(
    (response: {
      body: { [string]: { latest: string, packager_str?: string, ... }, ... },
      ...
    }): Array<{
      latest: string,
      sdk: string,
      packager_str?: string,
      ...
    }> => {
      return Object.keys(response.body).map(sdk => {
        return {
          ...response.body[sdk],
          sdk,
        }
      })
    }
  )
}
export const save = (app: AppRecord): Promise<{ app: AppRecord, new: true, ... }> => {
  if (app.id) {
    return request.post(`/api/app/${app.id}/save/settings`, app).then(response => {
      return { app: normalizeApp(response.body.result), new: false }
    })
  } else {
    const url = config.common.urls.createApp.replace(
      '{companyId}',
      (app.companyId?.toString() ?? '') || window.company.id
    )
    return request.post(url, app).then(response => {
      return { app: normalizeApp(response.body), new: true }
    })
  }
}

export const archive = (app: AppRecord): Promise<number> => {
  return request.post(`/api/app/${app.id}/archive`, app).then(
    () => {
      // not great, but will do the job for now
      // when / if we got down the SPA path, we'll have a route reducer we can dispatch to to trigger an url change
      window.location.href = '/'
      return app.id
    },
    response => {
      throw response
    }
  )
}

export const savePushConfig = (
  app: AppRecord,
  pushConfig: PushConfigRecord,
  file: ?File,
  password: ?string = null
): Promise<PushConfigRecord> => {
  const fd = new FormData()
  fd.append(
    'data', // $FlowFixMe method-unbinding
    typeof pushConfig.toJS === 'function' ? JSON.stringify(pushConfig.toJS()) : ''
  )
  if (file) fd.append('file', file)
  if (password) fd.append('password', password)
  try {
    return request
      .post(`/api/app/${app.id}/save/push-settings`)
      .send(fd)
      .then(response => {
        return buildPushConfigFromRaw(app.platform, response.body.result)
      })
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  }
}

export const updateAllowedUsers = (
  app: AppRecord,
  data: { allowedUsers: Array<number>, ... }
): Promise<Map<number, UserRecord>> => {
  const url = generateUrl('api_app_restrictions', { appId: app.id })
  return request
    .put(url)
    .send(data)
    .then(
      ({ body }) => Immutable.Map(body.map(raw => [raw.id, normalizeUser(raw)])),
      ({ body }) => {
        throw body
      }
    )
}

export const saveP12 = (
  app: AppRecord,
  file: File,
  password: ?string = null
): Promise<{ app: AppRecord, pushConfig: any, file: string, ... }> => {
  const fd = new FormData()
  fd.append('file', file)
  if (password) {
    fd.append('password', password)
  }
  return request
    .post(`/api/app/${app.id}/save/push-certificate`)
    .send(fd)
    .then(response => {
      return {
        app,
        pushConfig: response.body.result,
        file: file.name,
      }
    })
}

export const fetchAppStore = (appOrUrl: string | AppRecord): Promise<AppRecord> => {
  let requestedUrl = ''
  let appId = 0
  if (typeof appOrUrl === 'string') {
    requestedUrl = `/service/store/fetchurl?url=${encodeURIComponent(appOrUrl)}`
  } else {
    let bundleId = appOrUrl.bundleId
    appId = appOrUrl.id
    if (appOrUrl.platform === 'webpush') {
      const bundleIdUrl = new URL(bundleId)
      return request
        .post('/service/web/fetch', { url: appOrUrl.bundleId })
        .set('X-RateLimit-Id', bundleIdUrl.host)
        .then(
          response => {
            let app: {
              id?: number,
              name?: string,
              platform?: Platforms,
              icon?: string,
              bundleId?: string,
              ...
            } = {}
            app.id = appId
            app.name = _get(response.body, 'title', '')
            app.platform = 'webpush'
            app.icon = _get(response.body, 'icon', '')
            app.bundleId = typeof appOrUrl !== 'string' ? appOrUrl.bundleId : ''
            return app
          },
          () => {
            throw {
              id: appId,
              error: 'unable to fetch website data',
            }
          }
        )
    } else if (appOrUrl.platform === 'ios') {
      let temp = bundleId.match(/(\d{4,})/)
      if (temp) {
        bundleId = temp[0]
      }
    }
    requestedUrl = `/service/store/fetch/${appOrUrl.platform ? appOrUrl.platform : ''}/${bundleId}`
  }
  return request.get(requestedUrl).then(
    response => {
      let app: {
        id?: number,
        name?: string,
        platform?: Platforms,
        icon?: string,
        bundleId?: string,
        ...
      } = {}
      app.id = appId
      app.name = _get(response.body, 'title', '')
      app.platform = _get(response.body, 'platform', '')
      app.icon = _get(response.body, 'icon_url', '')
      app.bundleId = _get(response.body, 'id', '')
      return AppFactory(app)
    },
    () => {
      throw {
        id: appId,
        error: 'unable to fetch app data',
      }
    }
  )
}

export const addSafariWebsite = async ({
  app,
  domain,
  password,
  file,
}: {
  app: AppRecord,
  domain: string,
  password: string,
  file: File,
  ...
}): Promise<any> => {
  const url = generateUrl('api_app_safari_add_website', { appId: app.id })
  const fd = new FormData()
  fd.append('certificateContent', file)
  fd.append('domain', domain)
  fd.append('password', password)

  try {
    const response = await request.post(url).send(fd)
    return response.body
  } catch (err) {
    throw {
      error: err.body.errors[0].message,
    }
  }
}

export const deleteSafariWebsite = async ({
  app,
  websiteId,
}: {
  app: AppRecord,
  websiteId: string,
  ...
}): Promise<any> => {
  const url = generateUrl('api_app_safari_delete_website', {
    appId: app.id,
    safariWebsiteId: websiteId,
  })

  try {
    await request.delete(url)
    return websiteId
  } catch (err) {
    throw {
      error: err.body.errors[0].message,
    }
  }
}

export const updateSafariWebsite = async ({
  app,
  websiteId,
  domain,
  file,
  password,
}: {
  app: AppRecord,
  websiteId: string,
  domain: string,
  file: ?File,
  password: string,
  ...
}): Promise<any> => {
  const url = generateUrl('api_app_safari_edit_website', {
    appId: app.id,
    safariWebsiteId: websiteId,
  })

  const fd = new FormData()
  if (file) {
    fd.append('certificateContent', file)
    fd.append('password', password)
  }
  fd.append('domain', domain)

  try {
    const response = await request.post(url).send(fd)
    return response.body
  } catch (err) {
    throw {
      error: err.body.errors[0].message,
    }
  }
}

export const saveSafariWebsiteName = async ({
  app,
  safariWebsiteName,
}: {
  app: AppRecord,
  safariWebsiteName: string,
  ...
}): Promise<any> => {
  const url = generateUrl('api_app_safari_set_website_name', { appId: app.id })

  try {
    await request.post(url, { safariWebsiteName })
    return app.setIn(['pushConfig', 'safariWebsiteName'], safariWebsiteName)
  } catch (err) {
    throw {
      error: err.body.errors[0].message,
    }
  }
}

export const saveWebpushIcons = ({
  app,
  androidSmallIconUrl,
  safariWebsiteIconUrl,
}: {
  app: AppRecord,
  androidSmallIconUrl: string | null,
  safariWebsiteIconUrl: string | null,
  ...
}): Promise<{ androidSmallIconUrl: string, safariWebsiteIconUrl: string, ... }> => {
  const url = generateUrl('api_app_webpush_set_icons', { appId: app.id })

  return request
    .post(url, {
      androidSmallIconUrl,
      safariWebsiteIconUrl,
    })
    .then(() => ({
      safariWebsiteIconUrl,
      androidSmallIconUrl,
    }))
    .catch(err => {
      throw {
        error: err.body.errors[0].message,
      }
    })
}
