// @flow

import * as React from 'react'

import { Button } from 'components/common/button'
import Loader from 'components/common/loader-legacy'
import { Tooltip } from 'components/common/tooltip'

import { kformat } from 'com.batch.common/utils'

import { type EstimateRecord } from 'com.batch.redux/_records'

type EstimateProps = {
  estimate: EstimateRecord,
  tokenMode: boolean,
  hideLoader?: boolean,
  refreshEstimate: () => void,
  style?: { [string]: number | string, ... },
}

const Estimate = ({
  estimate,
  tokenMode,
  refreshEstimate,
  hideLoader = false,
  style,
}: EstimateProps): React.Node => {
  return (
    <Tooltip
      tooltip={
        estimate.error
          ? 'Unable to estimate'
          : `This campaign might reach ${
              tokenMode ? estimate.matching.count.toLocaleString() : estimate.matchingInstalls
            } ${tokenMode ? 'optin' : ''} users`
      }
    >
      <div style={style}>
        <h3 className="audience__title">Estimated reach</h3>
        <div className="audience__reach">
          {!hideLoader && (
            <Loader overlay={false} height={26} size="tiny" left loading={estimate.loading}>
              <strong
                style={{
                  color: '#383838',
                }}
              >
                {estimate.error && 'Unable to estimate'}
                {!estimate.error && tokenMode && `${kformat(estimate.matching.count)} tokens`}
                {!estimate.error && !tokenMode && `${kformat(estimate.matchingInstalls)} installs`}
              </strong>{' '}
              <div
                style={{
                  display: 'inline-block',
                  color: 'unset',
                }}
              >
                /&nbsp;
                {!estimate.error && tokenMode && `${kformat(estimate.all.count)} total push tokens`}
                {!estimate.error && !tokenMode && `${kformat(estimate.installs)} total installs`}
                {estimate.error && (
                  <Button
                    style={{ marginTop: '-5px', height: 28 }}
                    intent="action"
                    kind="primary"
                    onClick={refreshEstimate}
                  >
                    retry
                  </Button>
                )}
              </div>
            </Loader>
          )}
        </div>
      </div>
    </Tooltip>
  )
}

export default Estimate
