// @flow

import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { SeparatorLine } from 'components/campaign/campaign-list.styles'
import { StandalonePreviewConnected } from 'components/campaign/preview/standalone-preview'
import { ClickDetailPopin } from 'components/campaign/review/data/click-detail-popin'
import { Box, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Wrapper } from 'components/common/empty-states'

import { formatNumberWithSpaces, numberFormat, percentage } from 'com.batch.common/utils'

import {
  InAppStatsContainer,
  InAppStatsHeader,
  Variant,
  VariantContent,
  VariantMetrics,
  VariantPreview,
  VariantsContainer,
  VariantTitle,
} from './abtesting.styles'
import Metric from './metric'

import { type abTestingFormattedData, ttSentText } from '../review.data.selectors'
import { type AppRecord, type CampaignRecord } from 'com.batch.redux/_records'
import { type InAppWithVariantRecord } from 'com.batch.redux/dataCampaign.records'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'

type Props = {
  data: abTestingFormattedData,
  inAppData: InAppWithVariantRecord,
  variantsThemes: AbTestedThemeRecord,
  campaign: CampaignRecord,
  app: AppRecord,
}

export const ABTesting = ({
  data,
  inAppData,
  variantsThemes,
  campaign,
  app,
}: Props): React.Node => {
  const variants = React.useMemo(
    () => campaign.abtesting.activeVariants,
    [campaign.abtesting.activeVariants]
  )
  const config = React.useMemo(
    () => ({
      a: variants.includes('a') ? (variants.includes('b') ? '50' : 100) : 0,
      b: variants.includes('b') ? (variants.includes('a') ? '50' : 100) : 0,
    }),
    [variants]
  )
  const winner = data.a.openRate > data.b.openRate ? 'a' : 'b'

  const getWebviewClickDetails = React.useCallback(
    (variant: string) => {
      const unknown =
        inAppData[variant].click.webview -
        inAppData[variant].click.analyticsIds.reduce((a, b) => a + b, 0)
      return [
        ...inAppData[variant].click.analyticsIds
          .map((value: number, label: string) => ({ label, value }))
          .toList()
          .toArray(),
        unknown > 0 && {
          label: 'unknown',
          value: unknown,
        },
      ]
    },
    [inAppData]
  )

  return (
    <Box>
      <ThemeProvider
        theme={{
          platform: app.platform,
          campaignType: campaign.type,
        }}
      >
        <Wrapper
          isLoading={data.loading}
          isOverlayShown={!data || data.loading}
          isEmpty={!data || data.loading}
          overlayProps={{ title: 'No data for this campaign', status: 'empty' }}
        >
          <BoxHeader>
            <HeaderBoxTitle title="A/B Testing" />
          </BoxHeader>

          <VariantsContainer>
            {['a', 'b'].map(variant => {
              return (
                <Variant bl={variant === 'b'} key={variant}>
                  <VariantTitle winner={winner === variant && campaign.type !== 'in-app'}>
                    Version {variant.toUpperCase()} <span>({config[variant]}%)</span>
                  </VariantTitle>
                  <VariantContent>
                    <VariantPreview>
                      {campaign.type === 'in-app' ? (
                        <StandalonePreviewConnected
                          variant={variant}
                          previewMode="landing"
                          previewWeb=""
                          schedulingType="automations"
                        />
                      ) : (
                        <StandalonePreviewConnected
                          variant={variant}
                          previewMode="normal"
                          previewWeb=""
                        />
                      )}
                    </VariantPreview>
                    <VariantMetrics>
                      {campaign.type === 'in-app' ? (
                        <InAppStatsContainer>
                          <div style={{ display: 'flex', gap: 24 }}>
                            <div>
                              <InAppStatsHeader>Devices synced</InAppStatsHeader>
                              <div style={{ fontWeight: 500, fontSize: 16 }}>
                                {formatNumberWithSpaces(inAppData[variant].supplied)}
                              </div>
                            </div>

                            <div>
                              <InAppStatsHeader>Displayed</InAppStatsHeader>
                              <div style={{ fontWeight: 500, fontSize: 16 }}>
                                {formatNumberWithSpaces(inAppData[variant].display)}
                              </div>
                            </div>
                          </div>
                          <SeparatorLine style={{ marginBlock: 10 }} />

                          {variantsThemes[variant]?.fields.some(
                            (field, key) => key === 'cta1' && field.hidden === false
                          ) && (
                            <React.Fragment>
                              <InAppStatsHeader>
                                {
                                  variantsThemes[variant]?.fields.find(
                                    (field, key) => key === 'cta1'
                                  ).label
                                }{' '}
                                :
                              </InAppStatsHeader>
                              <div style={{ color: '#383838' }}>
                                <span style={{ fontSize: 28 }}>
                                  {percentage(inAppData[variant].click.cta1Rate, 2)}
                                </span>{' '}
                                <span>
                                  {inAppData[variant].click.cta1 !== 0
                                    ? formatNumberWithSpaces(inAppData[variant].click.cta1)
                                    : ''}
                                </span>
                              </div>
                            </React.Fragment>
                          )}

                          {variantsThemes[variant]?.fields.some(
                            (field, key) => key === 'cta2' && field.hidden === false
                          ) && (
                            <React.Fragment>
                              <InAppStatsHeader style={{ marginTop: 14 }}>
                                {
                                  variantsThemes[variant]?.fields.find(
                                    (field, key) => key === 'cta2'
                                  ).label
                                }{' '}
                                :
                              </InAppStatsHeader>
                              <div style={{ color: '#383838' }}>
                                <span style={{ fontSize: 28 }}>
                                  {percentage(inAppData[variant].click.cta2Rate, 2)}
                                </span>{' '}
                                <span>
                                  {inAppData[variant].click.cta2 !== 0
                                    ? formatNumberWithSpaces(inAppData[variant].click.cta2)
                                    : ''}
                                </span>
                              </div>
                            </React.Fragment>
                          )}

                          {variantsThemes[variant]?.fields.some(
                            (field, key) => key === 'global' && field.hidden === false
                          ) && (
                            <React.Fragment>
                              <InAppStatsHeader>Global action :</InAppStatsHeader>
                              <div style={{ color: '#383838' }}>
                                <span style={{ fontSize: 28 }}>
                                  {percentage(inAppData[variant].click.globalRate, 2)}
                                </span>{' '}
                                <span>
                                  {inAppData[variant].click.global !== 0
                                    ? formatNumberWithSpaces(inAppData[variant].click.global)
                                    : ''}
                                </span>
                              </div>
                            </React.Fragment>
                          )}

                          {variantsThemes[variant]?.payloadVars.kind === 'webview' && (
                            <React.Fragment>
                              <InAppStatsHeader>All buttons :</InAppStatsHeader>
                              <div style={{ color: '#383838' }}>
                                <span style={{ fontSize: 28 }}>
                                  {percentage(inAppData[variant].click.webviewRate, 2)}
                                </span>{' '}
                                <span>
                                  {inAppData[variant].click.webview !== 0
                                    ? formatNumberWithSpaces(inAppData[variant].click.webview)
                                    : ''}
                                </span>
                                <div>
                                  <ClickDetailPopin
                                    data={getWebviewClickDetails(variant)}
                                    display={inAppData[variant].display}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          )}

                          {variantsThemes[variant]?.fields.some(
                            (field, key) =>
                              ['global', 'cta1', 'cta2'].includes(key) ||
                              variantsThemes[variant]?.payloadVars.kind === 'webview'
                          ) && <SeparatorLine style={{ marginBlock: 10 }} />}

                          <InAppStatsHeader>Close</InAppStatsHeader>
                          <div style={{ color: '#383838' }}>
                            <span style={{ fontSize: 28 }}>
                              {percentage(inAppData[variant].closeRate, 2)}
                            </span>{' '}
                            <span>
                              {inAppData[variant].close !== 0
                                ? formatNumberWithSpaces(inAppData[variant].close)
                                : ''}
                            </span>
                          </div>
                        </InAppStatsContainer>
                      ) : (
                        <React.Fragment>
                          <Metric small value={data[variant].sent}>
                            Sent
                          </Metric>
                          <br />
                          <Metric
                            small
                            isRatio
                            value={data[variant].openRate}
                            tooltip={
                              <div style={{ fontWeight: 500 }}>
                                {numberFormat(data[variant].openAlg)}{' '}
                                <span style={{ fontWeight: 300 }}>Opens</span> /{' '}
                                {numberFormat(data[variant].sentAlg)}{' '}
                                <span style={{ fontWeight: 300 }}>
                                  {ttSentText[app?.openRateAlg ?? 'ACCURATE']}
                                </span>
                              </div>
                            }
                          >
                            Open rate
                          </Metric>
                          <br />
                          <Metric
                            small
                            isRatio
                            value={
                              data[variant].reengaged
                                ? data[variant].reengaged /
                                  (data[variant].open + data[variant].influencedOpen)
                                : 0
                            }
                          >
                            Reengage
                          </Metric>
                        </React.Fragment>
                      )}
                    </VariantMetrics>
                  </VariantContent>
                </Variant>
              )
            })}
          </VariantsContainer>
        </Wrapper>
      </ThemeProvider>
    </Box>
  )
}
