// / @flow

import * as React from 'react'

import { Radio } from 'components/form'

import { type InputProps } from './helper'

export const InputBoolean = ({ condition, updateCondition }: InputProps): React.Node => {
  const onRadioChange = React.useCallback(
    (bool: boolean) => () => {
      updateCondition(condition.set('value', condition.value.set('boolean', bool)))
    },
    [condition, updateCondition]
  )

  return (
    <div style={{ paddingTop: 8 }} role="radiogroup">
      <Radio label="Yes" onChange={onRadioChange(true)} checked={condition.value.boolean} />
      <Radio
        label="No"
        style={{ marginLeft: 20 }}
        onChange={onRadioChange(false)}
        checked={!condition.value.boolean}
      />
    </div>
  )
}
