// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import {
  useToggle,
  useIsCurrentUserAllowedTo,
  usePopper,
  sameWidth,
  useCurrentCompanyHasFeature,
} from 'components/_hooks'
import {
  CampaignHeader,
  CampaignListHeader as Header,
  CampaignListTitle,
  CampaignSearchContainer,
  CampaignTitleColumn,
  SeparatorLine,
} from 'components/campaign/campaign-list.styles'
import { CreateCampaignDropdown } from 'components/campaign/dropdowns/create-campaign-dropdown'
import { FiltersButtons } from 'components/campaign/filters/filters-buttons'
import { useFilters } from 'components/campaign/filters/filters-provider'
import { Button, PermissionButton } from 'components/common/button'
import { EmptyField } from 'components/common/empty-states'
import { FlexLine } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import { ActionsGroup, HeaderActions } from 'components/styled/blocs'

import { generateCampaignUrl } from 'com.batch.common/router'

import { CampaignFilters, CampaignPortaledFilters } from './campaign-list-filters'

import { FilterSearch } from '../filter/filter-search/filter-search'
import { currentAppSelector } from 'com.batch.redux/app'
import { campaignConfigSelector, countFilteredSelector } from 'com.batch.redux/campaign'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'
import { type UserRecord } from 'com.batch.redux/user.records'
import { currentUserSelector } from 'com.batch.redux/user.selector'

type CampaignHeaderProps = {
  schedulingType: schedulingType,
  loading: boolean,
  hasCampaigns: boolean,
  hasThemes: boolean,
  isAutomation: boolean,
  canPush: boolean,
}

export const CampaignsListHeader = ({
  loading,
  schedulingType,
  hasCampaigns,
  hasThemes,
  isAutomation,
  canPush,
}: CampaignHeaderProps): React.Node => {
  const filtersState = useToggle()
  const searchRef = React.useRef<React.ElementRef<any>>()
  const config = useSelector(campaignConfigSelector)
  const app = useSelector(currentAppSelector)
  const project = useSelector(optionalCurrentProjectSelector)
  const countFiltered = useSelector(countFilteredSelector)
  const user: UserRecord = useSelector(currentUserSelector)
  const isAllowedToUpdatePush = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const { goToFilteredURL } = useFilters()
  const isWebPush = app.platform === 'webpush'

  const hasPushPermission: boolean = React.useMemo(
    () => user.permissionsForCurrentApp.has('push:write'),
    [user]
  )

  const updateQuery = React.useCallback(
    (query: string) => {
      goToFilteredURL('query', query)
    },
    [goToFilteredURL]
  )

  React.useEffect(() => {
    if (document.body) {
      document.body.style.overflow = filtersState.value ? 'hidden' : 'visible'
    }
  }, [filtersState.value])
  const [headerRef, filtersRef] = usePopper({
    modifiers: [
      sameWidth,
      { name: 'applyStyles' },
      { name: 'eventListeners' },
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
    ],
    strategy: 'fixed',
    placement: 'bottom',
  })
  const handleOnSubmit = React.useCallback(
    e => {
      e.preventDefault()
      filtersState.close()
      searchRef.current?.blur()
    },
    [filtersState, searchRef]
  )
  const currentCompanyCanUseFakeOmniForm = useCurrentCompanyHasFeature(
    'cep-show-legacy-campaign-omni'
  )

  const currentCompanyCanUseInApp = useCurrentCompanyHasFeature('inapp-campaigns')
  const hideInAppOption = React.useMemo(
    () => (Boolean(project) && !currentCompanyCanUseInApp) || isWebPush,
    [currentCompanyCanUseInApp, isWebPush, project]
  )

  return (
    <CampaignHeader openFilters={filtersState.value} ref={headerRef}>
      <Header openFilters={filtersState.value}>
        <div className="campaign-header-row">
          <CampaignTitleColumn>
            <CampaignListTitle>{isAutomation ? 'Automations' : 'Campaigns'}</CampaignListTitle>
            {(loading || hasCampaigns) && (
              <div className="campaigns-results">
                <p className="results">
                  {loading ? (
                    <EmptyField _width={80} forceLoading as="span" _display="inline" />
                  ) : (
                    <React.Fragment>{countFiltered} results</React.Fragment>
                  )}
                </p>
              </div>
            )}
          </CampaignTitleColumn>
          <HeaderActions style={{ flex: '1', alignItems: 'center' }}>
            <ActionsGroup>
              {(!project || !currentCompanyCanUseFakeOmniForm) && schedulingType === 'campaigns' ? (
                <PermissionButton
                  kind="primary"
                  intent="action"
                  to={generateCampaignUrl('form', 'campaigns', {
                    companyId: app.companyId,
                    appId: app.id,
                    campaignType: 'push',
                    id: 'new',
                  })}
                  disabled={!canPush || !hasPushPermission}
                  check={['app', 'push:write']}
                  isAllowed={isAllowedToUpdatePush}
                >
                  New campaign
                </PermissionButton>
              ) : schedulingType === 'automations' && hideInAppOption ? (
                <PermissionButton
                  kind="primary"
                  intent="action"
                  to={generateCampaignUrl('form', 'automations', {
                    companyId: app.companyId,
                    appId: app.id,
                    campaignType: 'push',
                    id: 'new',
                  })}
                  disabled={!canPush || !hasPushPermission}
                  check={['app', 'push:write']}
                  isAllowed={isAllowedToUpdatePush}
                >
                  New automation
                </PermissionButton>
              ) : (
                <CreateCampaignDropdown
                  companyId={app.companyId ?? 0}
                  projectId={project?.id}
                  hasThemes={hasThemes}
                  isAutomation={isAutomation}
                  schedulingType={schedulingType}
                />
              )}
            </ActionsGroup>
          </HeaderActions>
        </div>

        {!hasCampaigns ? (
          <div style={{ height: 54 }} /> // filler
        ) : (
          <React.Fragment>
            <div>
              <ThemeProvider theme={{ kind: 'filter' }}>
                <FlexLine style={{ justifyContent: 'space-between', marginBottom: 8 }}>
                  <CampaignSearchContainer
                    onSubmit={handleOnSubmit}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    <FilterSearch
                      ref={searchRef}
                      style={{
                        marginRight: 16,
                      }}
                      identifier={isAutomation ? 'automations' : 'campaigns'}
                      value={config.filters.query}
                      onChange={updateQuery}
                      expandable={false}
                    />
                  </CampaignSearchContainer>

                  {filtersState.value ? (
                    <div className="close-filters-button">
                      <Button kind="inline" addOn="prefix" onClick={filtersState.close}>
                        <Icon icon="close" />
                        Close&nbsp;filters
                      </Button>
                      <CampaignPortaledFilters closeFilters={filtersState.close} ref={filtersRef}>
                        <CampaignFilters
                          isAutomation={isAutomation}
                          isWebPush={isWebPush}
                          type={schedulingType}
                          closeFilters={filtersState.close}
                        />
                      </CampaignPortaledFilters>
                    </div>
                  ) : (
                    <FiltersButtons
                      isWebPush={isWebPush}
                      isAutomation={isAutomation}
                      filtersState={filtersState}
                    />
                  )}
                </FlexLine>
              </ThemeProvider>
            </div>
            <div className="separator-row">
              <SeparatorLine />
            </div>
          </React.Fragment>
        )}
      </Header>
    </CampaignHeader>
  )
}
