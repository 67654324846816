// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { Button, ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableBody,
  TableCell,
  TableCellActions,
  TableRow,
} from 'components/common/table/index'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'
import { Copy } from 'components/form'
import { EmptyText } from 'components/styled/empty'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'

import { type ExportJobRecord, type DataExportJobRecord } from './../redux/console.records'
import { ExportForm } from './export-form'

import { Section } from '../console.style'
import { type AppRecord } from 'com.batch.redux/_records'

type AppExportPropsType = {
  app: AppRecord,
  exportJob: ExportJobRecord,
  fetchExportJobs: ({ app: AppRecord }) => Promise<List<ExportJobRecord>>,
  createExportJob: ({ app: AppRecord, exportJob: DataExportJobRecord }) => Promise<{
    request_id: string,
  }>,
}

const exportKind = {
  campaign: 'Push campaign',
  'in-app-campaign': 'In-app campaign',
  transactional: 'Transactional',
  reachability: 'Reachability',
  userbase: 'Userbase',
}

const AppExport = ({
  app,
  exportJob,
  fetchExportJobs,
  createExportJob,
}: AppExportPropsType): React.Node => {
  const [opened, setOpened] = React.useState<boolean>(false)

  React.useEffect(() => {
    fetchExportJobs({ app })
  }, [app, fetchExportJobs])

  return (
    <React.Fragment>
      <Section>
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button
            kind="primary"
            intent="action"
            onClick={() => setOpened(true)}
            disabled={!app.features.has('export-events') && !app.features.has('export-userbase')}
          >
            Request a new export job
          </Button>
        </div>

        <Table template="repeat(6, 1fr) 99px">
          <TableHeader>
            <TableCellHeader>Requested</TableCellHeader>
            <TableCellHeader>Request Id</TableCellHeader>
            <TableCellHeader>Kind</TableCellHeader>
            <TableCellHeader>From</TableCellHeader>
            <TableCellHeader>To</TableCellHeader>
            <TableCellHeader>Status</TableCellHeader>
            <div />
          </TableHeader>
          <TableBody>
            {exportJob.entities.map(e => {
              return (
                <TableRow key={e.id}>
                  <TableCell>
                    {dayjs
                      .duration(
                        dayjs()
                          .utc()
                          .diff(e.requestDate || dayjs())
                      )
                      .humanize()}{' '}
                    ago
                  </TableCell>
                  <TableCell>
                    <Copy value={e.id} />
                  </TableCell>
                  <TableCell>
                    {e.entity === 'userbase' || e.entity === 'reachability' ? (
                      <Tooltip tooltip={e.ids.join(', ')}>
                        <span>
                          <ButtonLink
                            kind="inline"
                            style={{ height: 28 }}
                            addOn="suffix"
                            href={generateUrl('analytics_userbase', {
                              companyId: app.companyId,
                              appId: app.id,
                            })}
                          >
                            <span>{!!e.entity && exportKind[e.entity]}</span>
                            <Icon icon="arrow-long-right" />
                          </ButtonLink>
                        </span>
                      </Tooltip>
                    ) : (
                      <React.Fragment>
                        {e.entity === 'campaign' && (
                          <ButtonLink
                            kind="inline"
                            style={{ height: 28 }}
                            addOn="suffix"
                            href={generateUrl('campaign_data', {
                              companyId: app.companyId,
                              appId: app.id,
                              campaignType: 'push',
                              id: e.identifier,
                              tab: 'analytics',
                            })}
                          >
                            Push
                            <Icon icon="arrow-long-right" />
                          </ButtonLink>
                        )}
                        {e.entity === 'in-app-campaign' && (
                          <ButtonLink
                            kind="inline"
                            style={{ height: 28 }}
                            addOn="suffix"
                            href={generateUrl('campaign_data', {
                              companyId: app.companyId,
                              appId: app.id,
                              campaignType: 'in-app',
                              id: e.identifier,
                              tab: 'analytics',
                            })}
                          >
                            In-App
                            <Icon icon="arrow-long-right" />
                          </ButtonLink>
                        )}
                        {e.entity === 'transactional' && e.identifier && <Tag>{e.identifier}</Tag>}
                      </React.Fragment>
                    )}
                  </TableCell>
                  <TableCell>{e.from ? dayjs(e.from).format('DD/MM/YYYY') : 'NA'}</TableCell>
                  <TableCell>
                    {e.to
                      ? e.to === 'now'
                        ? dayjs(e.requestDate).format('DD/MM/YYYY')
                        : dayjs(e.to).format('DD/MM/YYYY')
                      : 'NA'}
                  </TableCell>
                  <TableCell>
                    <Tag>{e.status.toLowerCase()}</Tag>
                  </TableCell>
                  <TableCellActions>
                    <ButtonLink
                      style={{ height: 28 }}
                      disabled={e.status !== 'SUCCESS'}
                      href={generateUrl('app_export_download', {
                        appId: app.id,
                        companyId: app.companyId,
                        requestId: e.id,
                      })}
                    >
                      <Icon icon="download" />
                    </ButtonLink>
                  </TableCellActions>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {exportJob.entities.size === 0 && (
          <EmptyText style={{ marginTop: 30 }}>No export Jobs are been resquested yet.</EmptyText>
        )}
      </Section>

      <ExportForm
        opened={opened}
        close={() => setOpened(false)}
        app={app}
        createExportJob={createExportJob}
        fetchExportJobs={fetchExportJobs}
      />
    </React.Fragment>
  )
}

export { AppExport }
