// @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Hint } from 'components/common/hint'
import { Icon } from 'components/common/svg-icon'
import { LinkDocumentation, Title } from 'components/styled/text'
import { texts } from 'components/styled/tokens'
import { Separator } from 'components/styled/utils'

import { capitalize, kformat, percentage, pluralizeAndKformat } from 'com.batch.common/utils'

import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { EmailAnalyticsFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { statTypeColors } from 'com.batch/orchestration-analytics/constants/stat-type-colors'
import { computeRate } from 'com.batch/orchestration-analytics/infra/parses/compute'
import { STAT_TYPE } from 'com.batch/orchestration-analytics/infra/types/stat-type'
import { BounceReportBlock } from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block'
import { ClicksPerUrlBlock } from 'com.batch/orchestration-analytics/ui/components/clicks-per-url-block/clicks-per-url-block'
import { DeliveryReportBlock } from 'com.batch/orchestration-analytics/ui/components/delivery-report-block'
import {
  StatsBlocksRow,
  StatsContainer,
} from 'com.batch/orchestration-analytics/ui/components/orchestration-analytics/orchestration-analytics.styles'
import { StatsPerMailboxProvider } from 'com.batch/orchestration-analytics/ui/components/stats-per-mailbox-provider/stats-per-mailbox-provider'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { type ChartData } from 'com.batch/shared/infra/types/chart-data'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { MetricBlock } from 'com.batch/shared/ui/component/metric-block/metric-block'
import { MetricBlockCard } from 'com.batch/shared/ui/component/metric-block/metric-block.styles'
import { Trend, TrendTooltip } from 'com.batch/shared/ui/component/trend/trend'
import { STATUS } from 'constants/common'

type EmailAnalyticsProps = {
  loadingState: LoadingState,
}

export const EmailAnalytics = ({ loadingState }: EmailAnalyticsProps): React.Node => {
  const dispatch = useDispatch()
  const { token }: { token: string } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const isAutomation = React.useMemo(() => schedulingType === 'automations', [schedulingType])
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { stats, providers, bounces } = React.useMemo(
    () => analyticsMap.getIn([token, 'channels', 'email'], EmailAnalyticsFactory()),
    [analyticsMap, token]
  )
  const { filters, dateRange } = useGetAnalyticsFilters()
  const categories = React.useMemo(() => bounces.get('categories'), [bounces])
  const isLoading = React.useMemo(
    () => [STATUS.LOADING, STATUS.INIT].includes(loadingState),
    [loadingState]
  )
  const isEmpty = React.useMemo(
    () => !stats && loadingState === STATUS.LOADED,
    [stats, loadingState]
  )
  const isOverlayShown = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])

  const data: ChartData[] = React.useMemo(
    () => [
      {
        value: stats?.delivered.value ?? 0,
        rate: stats?.delivered.rate ?? 0,
        label: capitalize(STAT_TYPE.DELIVERED),
        color: statTypeColors[STAT_TYPE.DELIVERED].chart,
      },
      {
        value: stats?.sending.value ?? 0,
        rate: stats?.sending.rate ?? 0,
        label: capitalize(STAT_TYPE.SENDING),
        color: statTypeColors[STAT_TYPE.SENDING].chart,
      },
      {
        value: stats?.bounce.value ?? 0,
        rate: stats?.bounce.rate ?? 0,
        label: capitalize(STAT_TYPE.BOUNCED),
        color: statTypeColors[STAT_TYPE.BOUNCED].chart,
      },
      {
        value: stats?.skippedSuppressionList.value ?? 0,
        rate: stats?.skippedSuppressionList.rate ?? 0,
        label: capitalize(STAT_TYPE.SKIPPED),
        color: statTypeColors[STAT_TYPE.SKIPPED].chart,
      },
    ],
    [stats]
  )

  const nonMachineOpenRate = React.useMemo(() => {
    if (stats) {
      const { open, mpp, delivered } = stats
      if (typeof open?.unique === 'number' && typeof mpp?.unique === 'number')
        return computeRate(open.unique - mpp.unique, delivered.value)
    }
    return 0
  }, [stats])

  React.useEffect(() => {
    if (token) {
      const tokens = new Immutable.List().push(token)
      dispatch(
        fetchDynamicStats({
          tokens,
          filters,
          dateRange,
          config: 'orchestrationStatsByClassification',
        })
      ).catch(() => {})
    }
  }, [dispatch, token, filters, dateRange])

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ margin: '0 0 24px', alignItems: 'flex-end' }}>
        <Title>
          <Icon icon="mail" />
          Email
        </Title>
        <LinkDocumentation
          target="_blank"
          href="https://help.batch.com/en/articles/8058036-email-analytics-glossary#h_94c527c82b"
          intent="action"
          style={{ marginLeft: 'auto' }}
        >
          Understand email metrics
        </LinkDocumentation>
      </ChannelSectionTitle>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={
          loadingState === STATUS.ERROR
            ? GlobalErrorOverlayProps
            : {
                status: 'empty',
                title: `No data for this ${
                  schedulingType === 'campaigns' ? 'campaign' : 'automation'
                }`,
                description: '',
              }
        }
        style={isOverlayShown ? { overflow: 'hidden' } : {}}
        boxed
      >
        <StatsBlocksRow marginBottom>
          <MetricBlockCard>
            <MetricBlock
              variant="success"
              label={capitalize(STAT_TYPE.DELIVERED)}
              value={kformat(stats?.delivered.value ?? 0)}
              subtitle={`out of ${kformat(stats?.sent.value ?? 0)} sent`}
              trend={
                dateRange && isAutomation ? (
                  <Trend
                    rate={stats?.delivered.trend ?? 0}
                    tooltip={
                      <TrendTooltip
                        metricContent={
                          <span>
                            <strong>{kformat(stats?.delivered.previousPeriodValue ?? 0)}</strong>{' '}
                            delivered email
                            {stats?.delivered.previousPeriodValue ?? 0 === 1 ? '' : 's'}
                          </span>
                        }
                        dateRange={dateRange}
                      />
                    }
                  />
                ) : undefined
              }
            />
          </MetricBlockCard>
          <MetricBlockCard>
            <Grid gap={0} template="1fr 1px 1fr" alignItems="stretch">
              <MetricBlock
                variant="action"
                label={capitalize(STAT_TYPE.OPENED)}
                value={
                  <span>
                    {percentage(stats?.open.rate ?? 0, 2, false, false)}
                    <span style={texts.metric}>%</span>
                  </span>
                }
                subtitle={
                  <span>
                    {pluralizeAndKformat('unique open', stats?.open.unique ?? 0)}{' '}
                    <Hint
                      size={12}
                      icon="help"
                      placement="bottom"
                      style={{ paddingLeft: 0 }}
                      minTooltipWidth={270}
                      arrow={false}
                    >
                      <p style={{ textAlign: 'start' }}>
                        Opened excluding machine opens: {percentage(nonMachineOpenRate, 2)}
                        <br />
                        Machine opens: {percentage(stats?.mpp.rate, 2)}
                      </p>
                    </Hint>
                  </span>
                }
                trend={
                  dateRange && isAutomation ? (
                    <Trend
                      rate={stats?.open.trend ?? 0}
                      tooltip={
                        <TrendTooltip
                          metricContent={
                            <span>
                              <strong>{percentage(stats?.open.previousPeriodRate ?? 0, 2)}</strong>{' '}
                              opened emails
                            </span>
                          }
                          dateRange={dateRange}
                        />
                      }
                    />
                  ) : undefined
                }
              />
              <Separator _margin="12px 0 12px 0" fullHeight />
              <MetricBlock
                variant="feature"
                label={capitalize(STAT_TYPE.CLICKED)}
                value={
                  <span>
                    {percentage(stats?.click.rate ?? 0, 2, false, false)}
                    <span style={texts.metric}>%</span>
                  </span>
                }
                subtitle={pluralizeAndKformat('unique click', stats?.click.unique ?? 0)}
                hint="Unsubscribe link clicks are ignored"
                trend={
                  dateRange && isAutomation ? (
                    <Trend
                      rate={stats?.click.trend ?? 0}
                      tooltip={
                        <TrendTooltip
                          metricContent={
                            <span>
                              <strong>{percentage(stats?.click.previousPeriodRate ?? 0, 2)}</strong>{' '}
                              clicked emails
                            </span>
                          }
                          dateRange={dateRange}
                        />
                      }
                    />
                  ) : undefined
                }
              />
            </Grid>
          </MetricBlockCard>
          <MetricBlockCard>
            <Grid gap={0} template="1fr 1px 1fr" alignItems="stretch">
              <MetricBlock
                variant="danger"
                label={capitalize(STAT_TYPE.BOUNCED)}
                value={
                  <span>
                    {percentage(stats?.bounce.rate ?? 0, 2, false, false)}
                    <span style={texts.metric}>%</span>
                  </span>
                }
                subtitle={`${kformat(stats?.bounce.value ?? 0)} unique bounces`}
                hint="Based on emails sent"
                trend={
                  dateRange && isAutomation ? (
                    <Trend
                      rate={stats?.bounce.trend ?? 0}
                      isGrowthPositive={false}
                      tooltip={
                        <TrendTooltip
                          metricContent={
                            <span>
                              <strong>
                                {percentage(stats?.bounce.previousPeriodRate ?? 0, 2)}
                              </strong>{' '}
                              bounced emails
                            </span>
                          }
                          dateRange={dateRange}
                        />
                      }
                    />
                  ) : undefined
                }
              />
              <Separator _margin="12px 0 12px 0" fullHeight />
              <MetricBlock
                variant="neutral"
                label={capitalize(STAT_TYPE.UNSUBSCRIBED)}
                value={percentage(stats?.unsubscribe.rate ?? 0, 2)}
                subtitle={`${kformat(stats?.unsubscribe.unique ?? 0)} unique unsub`}
                trend={
                  dateRange && isAutomation ? (
                    <Trend
                      rate={stats?.unsubscribe.trend ?? 0}
                      isGrowthPositive={false}
                      tooltip={
                        <TrendTooltip
                          metricContent={
                            <span>
                              <strong>
                                {percentage(stats?.unsubscribe.previousPeriodRate ?? 0, 2)}
                              </strong>{' '}
                              unsubscribed emails
                            </span>
                          }
                          dateRange={dateRange}
                        />
                      }
                    />
                  ) : undefined
                }
              />
            </Grid>
          </MetricBlockCard>
        </StatsBlocksRow>
      </Wrapper>

      <StatsPerMailboxProvider />

      <StatsBlocksRow style={isOverlayShown ? { flexWrap: 'nowrap' } : {}}>
        <Wrapper
          isLoading={isLoading}
          isEmpty={isEmpty}
          isOverlayShown={isOverlayShown}
          overlayProps={GlobalErrorOverlayProps}
          boxed
          style={isOverlayShown ? { width: 412 } : {}}
        >
          <DeliveryReportBlock stats={data} />
        </Wrapper>
        <Wrapper
          isLoading={isLoading}
          isEmpty={false}
          isOverlayShown={isOverlayShown}
          overlayProps={GlobalErrorOverlayProps}
          boxed
        >
          <BounceReportBlock bounceCategories={categories} providers={providers} token={token} />
        </Wrapper>
      </StatsBlocksRow>
      <ClicksPerUrlBlock schedulingType={schedulingType} />
    </StatsContainer>
  )
}
