// @flow
import Immutable, { type RecordFactory, type RecordOf, type List, type Map } from 'immutable'

import { type SegmentRecord } from 'com.batch/segments/models/segment.records'

type SegmentStateProps = {
  segments: Map<string, SegmentRecord>,
  segmentsPerPage: Map<number, List<string>>,
  loadingState: LoadingState,
  mutationLoadingState: LoadingState,
  search: string,
  sortBy: ?'displayName' | 'updatedAt',
  sortDirection: ?'asc' | 'dsc',
  page: number,
  nbPerPage: number,
  total: number,
  totalMatching: number,
}

export const SegmentStateFactory: RecordFactory<SegmentStateProps> = Immutable.Record(
  ({
    segments: new Immutable.Map(),
    segmentsPerPage: new Immutable.Map(),
    loadingState: 'INIT',
    mutationLoadingState: 'INIT',
    search: '',
    sortBy: undefined,
    sortDirection: undefined,
    page: 1,
    nbPerPage: 10,
    total: 0,
    totalMatching: 0,
  }: SegmentStateProps)
)

export type SegmentStateRecord = RecordOf<SegmentStateProps>
