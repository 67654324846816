// @flow

import * as React from 'react'

import { Radio } from 'components/form'

import { TimingLabel } from './when.styles'
import { whenSvg } from './when.svg'

import { type pushCampaignSendType } from 'com.batch.redux/_records'

type TimingOptionProps = {
  onChange: () => void,
  isSelected?: boolean,
  value: pushCampaignSendType,
  label: string,
  ...
}

export const TimingOption: React.ComponentType<TimingOptionProps> = React.memo(
  ({ isSelected, label, onChange, value }: TimingOptionProps) => {
    const IconComponent = whenSvg[value]
    return (
      <TimingLabel isSelected={Boolean(isSelected)} onClick={onChange}>
        <Radio checked={isSelected} onChange={() => {}} label={label} />
        <IconComponent isSelected={Boolean(isSelected)} />
      </TimingLabel>
    )
  }
)

TimingOption.displayName = 'TimingOption'
