// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map } from 'immutable'
import * as React from 'react'
import DayPicker from 'react-day-picker'
import { createPortal } from 'react-dom'

import {
  type dateShortcut,
  type allTimeRangeShortcutType,
  DatePickerShortcuts,
} from 'components/form/fields/date-picker/date-picker-shortcuts'

import { dayjs, type DateRange } from 'com.batch.common/dayjs.custom'

import {
  DatePickerPopoverContainer,
  DayElements,
  Caption,
  DatePickerNavbar,
  WeekdayElement,
} from './date-picker.styles'

type DateRangePickerPopoverProps = {
  position?: 'right' | 'left',
  shortcuts?: List<dateShortcut | allTimeRangeShortcutType>,
  setRange?: DateRange => any,
  from: ?Dayjs,
  disabledDays?: (day: Date) => boolean,
  to: ?Dayjs,
  setFrom: (?Dayjs) => void,
  setTo: (?Dayjs) => void,
  close: (range?: ?$ReadOnly<DateRange>) => void,
  clearErrors: () => void,
  setDay: Dayjs => void,
  setInputs: ((Map<string, string>) => Map<string, string>) => void,
}

export const DateRangePickerPopover: React.ComponentType<DateRangePickerPopoverProps> =
  React.forwardRef(
    (
      {
        position,
        shortcuts,
        disabledDays,
        from,
        to,
        setRange,
        close,
        clearErrors,
        setDay,
        setInputs,
        setFrom,
        setTo,
      }: DateRangePickerPopoverProps,
      ref
    ): React.Node => {
      let modalRoot = document.getElementById('select-root')
      if (!modalRoot) {
        modalRoot = document.createElement('div')
        modalRoot.setAttribute('id', 'select-root')
        document.body?.appendChild(modalRoot)
      }

      const activeDays = React.useMemo(() => {
        let tmp = []
        if (from) tmp.push(from)
        if (to) tmp.push(to)
        return new Immutable.List().push(...tmp)
      }, [from, to])

      const [dayOverview, setDayOverview] = React.useState<?Dayjs>(null)

      const modifiers: {
        firstWeekDay?: Date => boolean,
        lastWeekDay?: Date => boolean,
        from?: Date | '',
        to?: Date | '',
        highlighted?: (Date => boolean) | { from: Date, to: Date, ... },
        overview?: Date => boolean,
        ...
      } = {}
      modifiers.firstWeekDay = (day: Date) => dayjs.utc(day).isoWeekday() === 1
      modifiers.lastWeekDay = (day: Date) => dayjs.utc(day).isoWeekday() === 7

      if (!!from && !!to) {
        modifiers.from = from.toDate()
        modifiers.to = to.toDate()
        modifiers.highlighted = {
          from: from.local().toDate(),
          to: to.startOf('day').local().toDate(),
        }
      } else if (!!from && !!dayOverview && !from.isSame(dayOverview, 'day')) {
        modifiers.from =
          !!dayOverview && !from.isSameOrAfter(dayOverview, 'day') ? from.toDate() : ''
        modifiers.to = !!dayOverview && !from.isSame(dayOverview, 'day') ? dayOverview.toDate() : ''

        modifiers.highlighted = (day: Date) =>
          dayjs.utc(day).isSameOrBefore(dayOverview, 'day') &&
          dayjs.utc(day).isSameOrAfter(from, 'day') &&
          from.isBefore(dayOverview)

        modifiers.overview = (day: Date) =>
          dayjs.utc(day).isSame(dayOverview, 'day') && dayOverview.isAfter(from, 'day')
      }
      return modalRoot
        ? createPortal(
            <DatePickerPopoverContainer
              width={shortcuts ? 684 : 540}
              position={position}
              isRange
              data-testid="DayPicker_dropdown"
              ref={ref}
            >
              <DayPicker
                disabledDays={disabledDays}
                captionElement={Caption}
                firstDayOfWeek={1}
                modifiers={modifiers}
                month={from ? from.toDate() : dayjs.utc().toDate()}
                numberOfMonths={2}
                onDayClick={d => setDay(dayjs.utc(d))}
                renderDay={(date: Date) => DayElements(date)}
                navbarElement={DatePickerNavbar}
                selectedDays={(day: Date) => {
                  const isSelected =
                    (!!from && dayjs.utc(day).startOf('day').isSame(from, 'day')) ||
                    (!!to && dayjs.utc(day).startOf('day').isSame(to, 'day'))
                  return isSelected
                }}
                weekdayElement={WeekdayElement}
                onDayMouseEnter={day => setDayOverview(dayjs.utc(day))}
              />
              {!!shortcuts && (
                <DatePickerShortcuts
                  shortcuts={shortcuts}
                  reset={() => {
                    setInputs(inputs => inputs.set('from', '').set('to', ''))
                    close()
                  }}
                  setDay={({ from, to }) => {
                    if (!!from && !!to) {
                      clearErrors()
                      setFrom(from)
                      setTo(to)

                      setInputs(inputs => {
                        const newInputs = inputs
                          .set('from', from.startOf('day').local().format('DD/MM/YYYY'))
                          .set('to', to.startOf('day').local().format('DD/MM/YYYY'))
                        return newInputs
                      })
                      const newRange = { from: from, to: to }
                      if (setRange) {
                        setRange(newRange)
                      }
                      close(newRange)
                    }
                  }}
                  activeDays={new Immutable.List().push(...activeDays)}
                />
              )}
            </DatePickerPopoverContainer>,
            modalRoot
          )
        : null
    }
  )
