// @flow

import { useCombobox } from 'downshift'
import Immutable, { type List } from 'immutable'
import { debounce } from 'lodash-es'
import * as React from 'react'

import { useWidthObserver, usePopper, useToggle } from 'components/_hooks'
import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'
import { WrapperSearchInput, SelectSearchInput } from 'components/filter/filter-search.styles'
import { SelectDropdown } from 'components/form/fields/select/select-dropdown'
import {
  selectPopperConfig,
  type SelectMenuPropsType,
} from 'components/form/fields/select/select.helper'

import { type BaseFilterSelectProps } from './filter-select'

export function FilterSelectSearch<T>({
  options,
  loadOptions,
  onChange,
  optionToString,
  style,
  placeholder,
  optionMenuShownCount,
  optionMenuHeight,
  optionMenuStyle,
  menuStyle,
  value,
  optionFormatter,
  menuOffset = 42,
  isDisabled,
  expandable = false,
  _width = 180,
}: BaseFilterSelectProps<T>): React.Node {
  const [isExpanded, setExpanded] = React.useState(false)
  const [showDropdown, setShowDropdown] = React.useState(false)
  const [localOptions, setLocalOptions] = React.useState<List<T>>(options ?? new Immutable.List())
  const [triggerRef, popperRef, popperInstance] = usePopper(selectPopperConfig)
  const loadingState = useToggle()
  const inputRef = React.useRef()
  const items = React.useMemo(() => localOptions.toArray(), [localOptions])

  React.useEffect(() => {
    if (value) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }

    return () => {}
  }, [value])

  const {
    isOpen,
    getItemProps,
    getToggleButtonProps,
    getMenuProps,
    closeMenu,
    selectedItem,
    getInputProps,
    setHighlightedIndex,
    isClearable = true,
    openMenu,
    setInputValue,
    highlightedIndex,
  } = useCombobox({
    items,
    selectedItem: value ?? null,
    itemToString: optionToString,
    circularNavigation: true,
    onInputValueChange: changes => {
      if (changes.type === useCombobox.stateChangeTypes.InputChange) {
        if (loadOptions) {
          loadOptionsDebounced.current(changes.inputValue)
        } else {
          setLocalOptions(
            (options ? options : new Immutable.List()).filter(option =>
              optionToString(option).toLowerCase().startsWith(changes.inputValue.toLowerCase())
            )
          )
        }
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      setInputValue('')
      if (!isOpen) {
        setLocalOptions(options ?? new Immutable.List())
        loadOptionsDebounced.current(inputRef?.current?.value ?? '')
        setShowDropdown(false)
        setExpanded(false)
      } else
        setTimeout(() => {
          setInputValue('')
          inputRef.current?.select(), 0
        })
      setExpanded(true)

      // Displays the popper after the expanded animation
      setTimeout(() => {
        popperInstance?.forceUpdate()
        setShowDropdown(true)
      }, 200)
    },
    onSelectedItemChange: changes => {
      onChange(changes.selectedItem)
      setLocalOptions(options ?? new Immutable.List())
      setInputValue('')
    },
  })

  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })

  const containerProps = getToggleButtonProps({
    ref: triggerRef,
    type: 'button',
    style: {
      ...style,
      textAlign: 'left',
      padding: '0 0 0 10px',
      width: expandable ? (isExpanded ? 170 : _width) : '100%',
    },
  })

  const inputProps = getInputProps({
    ref: inputRef,
  })

  const loadOptionsDebounced = React.useRef(
    debounce(
      (q: string) => {
        if (loadOptions) {
          loadingState.open()
          loadOptions(q).then(options => {
            loadingState.close()
            setLocalOptions(options)
            popperInstance?.update()
          })
        }
      },
      200,
      { trailing: true }
    )
  )

  const width = useWidthObserver(triggerRef, 200)

  const isOptionSelected = React.useCallback((option: T) => option === value, [value])

  const handleOnClickClose = React.useCallback(
    evt => {
      evt?.stopPropagation()
      onChange(null)
      closeMenu()
    },
    [onChange, closeMenu]
  )

  const handleOnClickSelectedItem = React.useCallback(
    evt => {
      evt.stopPropagation()
      openMenu()
    },
    [openMenu]
  )
  React.useLayoutEffect(() => {
    if (isOpen) {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }],
      }))
    } else {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }],
      }))
    }
  }, [isOpen, popperInstance])
  return (
    <React.Fragment>
      {selectedItem ? (
        <Button
          addOn="suffix"
          addOnGap={22}
          kind="inline"
          isActive={isOpen || selectedItem}
          {...containerProps}
          className="ooo"
          onClick={handleOnClickSelectedItem}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon="filter" style={{ marginRight: 10 }} />
            {optionToString(selectedItem)}
          </span>
          {isClearable && (
            <Button kind="discreet" as="span" role="button" onClick={handleOnClickClose}>
              <Icon color="currentColor" icon="close" />
            </Button>
          )}
        </Button>
      ) : (
        <Button
          style={{ width: 120, textAlign: 'left' }}
          addOn="suffix"
          kind="inline"
          isActive={isOpen}
          disabled={Boolean(isDisabled)}
          {...containerProps}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon="filter" style={{ marginRight: 10 }} />
            {placeholder ?? 'Filter by'}
          </span>
        </Button>
      )}

      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        width={width + menuOffset}
        optionMenuShownCount={optionMenuShownCount}
        optionMenuHeight={optionMenuHeight}
        getItemProps={getItemProps}
        optionToString={optionToString}
        noResultNode={localOptions.size === 0 ? 'No options available' : undefined}
        inMenuSearchInput={
          <WrapperSearchInput>
            <div className="styled-input-addons styled-addon-pre">
              <Icon icon="search" size={14} color="currentColor" thickness={1.3} />
            </div>
            <SelectSearchInput {...inputProps} placeholder="Search..." />
          </WrapperSearchInput>
        }
        menuStyle={menuStyle}
        optionMenuStyle={{ ...optionMenuStyle, width: 'calc(100% - 4px)', marginLeft: 2 }}
        optionFormatter={optionFormatter}
        options={localOptions}
        isOpen={showDropdown && isOpen}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        isOptionSelected={isOptionSelected}
      />
    </React.Fragment>
  )
}
