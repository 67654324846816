// @flow

import * as React from 'react'

export const PopinContext: React$Context<{
  count: number,
  updateCount: number => void,
  ...
}> = React.createContext({
  count: 0,
  updateCount: () => {},
})
