// @flow
import { type Dayjs } from 'dayjs'
import Immutable, { type RecordFactory, type RecordOf, type List } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

export type DataPointProps = {
  value: number,
  date: Dayjs,
}

export const DataPointFactory: RecordFactory<DataPointProps> = Immutable.Record(
  ({
    value: 0,
    date: dayjs(),
  }: DataPointProps)
)

export type DataPointRecord = RecordOf<DataPointProps>

type DataSetProps = {
  color: string,
  label: string,
  total: number,
  data: List<DataPointRecord>,
  ...
}

export const DataSetFactory: RecordFactory<DataSetProps> = Immutable.Record(
  ({
    color: '#000000',
    label: '',
    total: 0,
    data: new Immutable.List(),
  }: DataSetProps)
)

export type DataSetRecord = RecordOf<DataSetProps>

export const getChartFakeData = ({
  periods = 28,
  color = '#b8b8b8',
  label = '',
  total = 0,
}: {
  periods?: number,
  color?: string,
  label?: string,
  total?: number,
  ...
}): List<DataSetRecord> => {
  if (periods <= 0 || isNaN(periods)) {
    return new Immutable.List()
  }
  return new Immutable.List().push(
    ...[
      DataSetFactory({
        label,
        total,
        color,
        data: new Immutable.List().push(
          ...new Array(periods)
            .fill()
            .map((p, i) =>
              DataPointFactory({ value: Math.random() * 100, date: dayjs().add(i, 'day') })
            )
        ),
      }),
    ]
  )
}
export function roundedRect({
  x,
  y,
  w,
  h,
  r,
  tl,
  tr,
  bl,
  br,
}: {
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  tl: boolean,
  tr: boolean,
  bl: boolean,
  br: boolean,
  ...
}): string {
  var retval
  retval = 'M' + (x + r) + ',' + y
  retval += 'h' + (w - 2 * r)
  if (tr) {
    retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r
  } else {
    retval += 'h' + r
    retval += 'v' + r
  }
  retval += 'v' + (h - 2 * r)
  if (br) {
    retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + r
  } else {
    retval += 'v' + r
    retval += 'h' + -r
  }
  retval += 'h' + (2 * r - w)
  if (bl) {
    retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + -r
  } else {
    retval += 'h' + -r
    retval += 'v' + -r
  }
  retval += 'v' + (2 * r - h)
  if (tl) {
    retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r
  } else {
    retval += 'v' + -r
    retval += 'h' + r
  }
  retval += 'z'
  return retval
}
