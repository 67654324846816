// @flow
import { parseBaseStats } from './parse-base-stats'

import {
  type OrchestrationStatsRecord,
  OrchestrationStatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

export const parseStatsByChannel = (
  stats: ?Array<OrchestrationStatsDynamicDimension>
): OrchestrationStatsRecord => {
  const channelIndex = stats ? stats[0].dimensionNames.indexOf('channel') : -1
  const parsed = OrchestrationStatsFactory(
    stats && channelIndex !== -1
      ? stats.reduce((acc, { dimensionValues, currentPeriod, previousPeriod }) => {
          const channel = dimensionValues[channelIndex]
          return {
            ...acc,
            [channel]: parseBaseStats(currentPeriod, previousPeriod),
          }
        }, {})
      : {}
  )
  return parsed
}
