// @flow

import { type ReduxAction } from 'com.batch.redux/_records'

export type FlagIncompleteAction = ReduxAction<
  'FLAG_INCOMPLETE',
  { messageId: string, incomplete: boolean, ... },
>

export const flagIncomplete = ({
  messageId,
  incomplete,
}: {
  messageId: string,
  incomplete: boolean,
  ...
}): FlagIncompleteAction => ({
  type: 'FLAG_INCOMPLETE',
  payload: {
    messageId,
    incomplete,
  },
})
