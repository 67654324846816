// @flow

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type ProviderModel } from 'com.batch.redux/adapters/project/models/esp-configuration'
import { parseEspConfigurationModelToRecords } from 'com.batch.redux/adapters/project/parse/parse'
import { type ProjectGateway } from 'com.batch.redux/corelogic/gateways/project.interface'
import { type EspConfigurationRecord } from 'com.batch.redux/corelogic/records/esp-configuration.records'

type LookUpProfileIdResponse = {
  profileIDs: {
    data: string,
  }[],
}

type fetchEspConfigurationRestResponse = { provider: ProviderModel }

export const ProjectRest: ProjectGateway = {
  lookUpProfileId: async ({ project, value }) => {
    const response = await request.post<LookUpProfileIdResponse>(
      generateUrl('api_grpc_oursql_service', {
        methodName: 'LookupProfileIDs',
      }),
      {
        projectKey: {
          textual: {
            text: project.projectKey,
          },
        },
        identifier: {
          customID: value,
        },
      }
    )
    return response
  },

  sendTestEmail: async ({ project, content, recipients }): Promise<void> => {
    for (const oneRecipient of recipients.split(',')) {
      await request
        .post(
          generateUrl('api_grpc_email_test_sender_service', {
            methodName: 'Send',
          }),
          {
            projectKey: {
              textual: {
                text: project.projectKey,
              },
            },
            message: {
              subject: content.templates.subject,
              content: content.templates.html,
              to: oneRecipient,
              replyTo: content.templates.replyTo,
              from: {
                email: content.fromEmail,
                name: content.name,
              },
            },
          }
        )
        .catch(e => Promise.reject(e))
    }
  },

  fetchEspConfiguration: async ({ company, project }): Promise<EspConfigurationRecord> => {
    const url = generateUrl('api_project_esp_configuration', {
      companyId: company.id,
      projectId: project.id,
    })
    const response = await request.get<fetchEspConfigurationRestResponse>(url)
    return parseEspConfigurationModelToRecords(response.provider)
  },
}
