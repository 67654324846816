// @flow

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type EmailContentRecord } from 'com.batch/message/models/message.records'

export type SendTestEmailSuccessAction = {
  type: 'SEND_TEST_EMAIL_SUCCESS',
  payload: null,
  ...
}

export type SendTestEmailFailureAction = {
  type: 'SEND_TEST_EMAIL_FAILURE',
  payload: {
    error: { errors: Array<{ message: string, ... }>, ... },
    ...
  },
  ...
}

export const sendTestEmail = (
  content: EmailContentRecord,
  recipients: string
): DispatchExtraBoundFn<Promise<any>> => {
  return async (dispatch, getState, { projectGateway }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    try {
      return await promiseActionCreator({
        dispatch,
        promise: projectGateway.sendTestEmail({ project, content, recipients }),
        actionName: 'SEND_TEST_EMAIL',
      })
    } catch (error) {
      console.log(error, { error })
    }
  }
}

// SendTestEmailAction | SendTestEmailSuccessAction | SendTestEmailFailureAction
