// @flow
import { type Dayjs } from 'dayjs'
import Immutable, { type List } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

export const getMonthList = (startedDate: Dayjs): List<{ name: string, date: Dayjs }> => {
  const optList = []
  let checkDate = dayjs().startOf('month')
  while (checkDate.isSameOrAfter(startedDate.startOf('month'))) {
    optList.push({
      name: checkDate.isSame(dayjs(), 'month') ? 'Current month' : checkDate.format('MMMM YYYY'),
      date: checkDate,
    })
    checkDate = checkDate.startOf('month').subtract(1, 'month')
  }

  return new Immutable.List().push(...optList)
}
