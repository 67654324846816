// @flow

import styled, { type StyledComponent } from 'styled-components'

import * as colors from 'components/styled/tokens/colors'

export const MultiResultsBannerContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  background-color: ${colors.fillNeutral};
  color: ${colors.textContrast};
  font-weight: 500;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
`
