// @flow
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { FilterSelectMulti } from 'components/filter/filter-select-multi/filter-select-multi'

import { contents } from '../steps-analytics/steps-analytics-row'
import { orchestrationAnalyticsFiltersSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { updateAnalyticsFilters } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'

type StepOption = {
  step: string,
  label: string,
  channel: ChannelUntilCleanup,
}

export const StepFilter = (): React.Node => {
  const dispatch = useDispatch()
  const { token }: { token: string } = useParams()
  const { nodesMap } = useSelector(journeyTreeSelector)
  const getAnalyticsFilters = useSelector(orchestrationAnalyticsFiltersSelector)
  const filters = React.useMemo(() => getAnalyticsFilters(token), [getAnalyticsFilters, token])
  const steps: List<StepOption> = React.useMemo(
    () =>
      nodesMap.reduce((acc: List<StepOption>, node) => {
        if (node.type !== 'MESSAGE') return acc
        return acc.push({
          step: node.id,
          label: node.label,
          channel: node.messageConfig.channel,
        })
      }, new Immutable.List()),
    [nodesMap]
  )
  const selectedSteps = React.useMemo(
    () => steps.filter(step => filters.get('step', Immutable.Map()).includes(step.step)),
    [steps, filters]
  )

  const onStepChange = React.useCallback(
    (steps: List<StepOption>) => {
      dispatch(
        updateAnalyticsFilters({
          token,
          filters:
            steps.size > 0
              ? filters.set(
                  'step',
                  steps.map(step => step.step)
                )
              : filters.delete('step'),
        })
      )
    },
    [dispatch, token, filters]
  )
  const optionToString = React.useCallback((option: ?StepOption) => {
    if (option) return option.label || contents[option.channel]?.label
    return ''
  }, [])
  const formatTerm = React.useCallback(
    (count: number) => {
      if (count === 1) {
        return selectedSteps.first()?.label || contents[selectedSteps.first()?.channel].label
      } else {
        return `${count} steps`
      }
    },
    [selectedSteps]
  )

  return (
    <FilterSelectMulti
      id="analytics-filter-step"
      options={steps}
      value={selectedSteps}
      onChange={onStepChange}
      optionToString={optionToString}
      placeholder={'All steps'}
      term={formatTerm}
      toggleButtonWidth={321}
      menuOffset={100}
      isSearchable={false}
    />
  )
}
