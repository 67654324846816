// @flow
import Immutable from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateTypedId } from 'com.batch.common/typed-id'

import {
  ExperimentFactory,
  MessageConfigFactory,
  PushSettingsFactory,
} from '../../../message/models/message.records'
import { MessageStateFactory } from 'com.batch/message/store/message.state'
import { buildLangAndRegionFromRowTargeting } from 'com.batch.redux/target/target.helper'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'
import { type UserRecord } from 'com.batch.redux/user.records'

import { parseMessagesForCampaign } from 'com.batch/message/infra/parses/messages.parse'
import { coerceNumberToVariantId } from 'com.batch/message/models/message.helper'
import { type OrchestrationCampaignParserResult } from 'com.batch/orchestration/infra/parses/orchestration.parse'
import {
  type OrchestrationChannels,
  type DashboardConfig,
} from 'com.batch/orchestration/infra/types/orchestration.types'
import {
  type OrchestrationCampaign,
  type EmailContentForCampaign,
} from 'com.batch/orchestration-campaign/infra/types/orchestration-campaign.types'
import {
  OrchestrationCampaignFactory,
  OneTimeFactory,
  RecurrentFactory,
} from 'com.batch/orchestration-campaign/models/campaign.records'
import { type OrchestrationPush } from 'com.batch/push/infra/types/push.message.types'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'

type OrchestrationCampaignParser = ({
  campaign: OrchestrationCampaign,
  messages?: {
    [string]:
      | { emailChannel: EmailContentForCampaign }
      | { smsMessage: SmsContent }
      | { pushMessage: OrchestrationPush },
  },
  config: DashboardConfig,
  state: campaignStateType,
  channels: ?OrchestrationChannels,
  id: string,
  user: UserRecord,
  name: string,
  labelCodes: Array<string>,
  orchestrationSource: 'DASHBOARD' | 'API',
}) => OrchestrationCampaignParserResult

export const parseOrchestrationCampaign: OrchestrationCampaignParser = ({
  campaign,
  messages,
  config,
  state,
  channels,
  id,
  name,
  user,
  labelCodes,
  orchestrationSource,
}) => {
  let queries = []
  const experimentOS = campaign.content.experiment
  const experimentDashboard = experimentOS
    ? ExperimentFactory({
        id: experimentOS.id,
        locked: state !== 'DRAFT',
        selectedVariantId: experimentOS.winningVariantSelectionDate
          ? coerceNumberToVariantId(experimentOS.variants.find(v => v?.weight === 100)?.id)
          : 1,
        enabled:
          experimentOS.status === 'EXPERIMENT_STATUS_ONGOING' ||
          experimentOS.status === 'EXPERIMENT_STATUS_COMPLETED',
        variants: Immutable.Map(
          experimentOS.variants.map(v => [coerceNumberToVariantId(v.id), v.messageReference])
        ),
        winnerVariantId: experimentOS.winningVariantSelectionDate
          ? coerceNumberToVariantId(experimentOS.variants.find(v => v?.weight === 100)?.id)
          : undefined,
        winningVariantSelectionDate: experimentOS.winningVariantSelectionDate
          ? dayjs.utc(experimentOS.winningVariantSelectionDate)
          : undefined,
      })
    : ExperimentFactory({ locked: state !== 'DRAFT' })

  if (campaign.content.query)
    queries.push({
      queryId: 'targeting',
      query: typeof campaign.content.query === 'string' ? campaign.content.query : '',
      eventId: '',
      retries: 0,
    })

  // incomplete campaign might not have a typedId so generate one for them
  let multilanguageEnabled = false
  let firstMessageId = generateTypedId('message')
  let subscriptionStatus = 'marketing'
  let message = MessageStateFactory()
  let pushSettings = PushSettingsFactory()
  let platforms = Immutable.Set<ProjectPlatforms>()
  if (messages) {
    const parsed = parseMessagesForCampaign({
      messages,
      config,
    })

    message = parsed.message
    subscriptionStatus = parsed.subscriptionStatus
    firstMessageId = parsed.messageTypedId
    if (parsed.messageConfigs[firstMessageId].pushSettings)
      pushSettings = parsed.messageConfigs[firstMessageId].pushSettings
    if (parsed.messageConfigs[firstMessageId].platforms)
      platforms = parsed.messageConfigs[firstMessageId].platforms
    multilanguageEnabled = parsed.multilanguageEnabled
  }
  const frequencyUnit = campaign.scheduling.recurrent?.recurrence ?? 'WEEKLY'
  if (frequencyUnit === 'HOURLY') {
    throw new Error('Dashboard does not accept hourly recurrent campaigns')
  }
  const { languages, regions, languagesInverted, regionsInverted } =
    buildLangAndRegionFromRowTargeting({ targeting: campaign.content.targeting, user })
  return {
    campaign: OrchestrationCampaignFactory({
      sendType: campaign.scheduling.recurrent ? 'recurring' : 'scheduled',
      tzAware: Boolean(campaign.scheduling.localTimezoneDependant),
      oneTime: OneTimeFactory({
        sendDate: campaign.scheduling.oneTime?.pushDate
          ? dayjs.utc(campaign.scheduling.oneTime?.pushDate, 'YYYYMMDDHHmmss')
          : null,
      }),
      recurrent: RecurrentFactory({
        startDate: campaign.scheduling.recurrent?.startDate
          ? dayjs.utc(campaign.scheduling.recurrent?.startDate, 'YYYYMMDDHHmmss')
          : null,
        endDate: campaign.scheduling.recurrent?.endDate
          ? dayjs.utc(campaign.scheduling.recurrent?.endDate, 'YYYYMMDDHHmmss')
          : null,
        hasCapping: Boolean(campaign.content.capping),
        capping: campaign.content.capping ? campaign.content.capping : 1,
        frequency: campaign.scheduling.recurrent?.frequency ?? 1,
        recurrence: frequencyUnit,
      }),
      messageConfig: MessageConfigFactory({
        messageTypedId: firstMessageId,
        pushSettings,
        platforms,
        multilanguageEnabled,
        experiment: experimentDashboard,
        channel: channels?.includes('EMAIL')
          ? 'email'
          : channels?.includes('PUSH')
            ? 'push'
            : 'sms',
      }),
    }),
    message,
    state,
    channels: Immutable.Set([
      ...(channels?.includes('EMAIL') ? ['email'] : []),
      ...(channels?.includes('SMS') ? ['sms'] : []),
      ...(channels?.includes('PUSH') ? ['push'] : []),
    ]),
    id,
    name,
    labelCodes: Immutable.Set(labelCodes),
    incomplete: config?.incomplete ?? false,
    createdByApi: orchestrationSource !== 'DASHBOARD',
    queries,
    targets: Immutable.Map([
      [
        'default',
        TargetStateFactory({
          languages,
          languagesInverted,
          regions,
          regionsInverted,
          subscriptionStatus,
        }),
      ],
    ]),
  }
}
