// @flow
import styled, { css, type PropsWithTheme, type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { colors, schemes } from 'components/styled/tokens'

import { PLATFORM_BUTTOM_THEME } from 'com.batch/profile/constants/platform-button-theme'
import { type PlatformKindType } from 'com.batch/profile/models/profile.records'
import { ProfileMockupOptinContainer } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/profile-mockup-optin.styles'

const HOVER_ANIMATION = 'all 0.1s ease-out'

type PlatformButtonTheme = { platformButtomKind: PlatformKindType }

type PlatformNameProps = { platform: string }
type PlatformNamePropsWithTheme = PropsWithTheme<PlatformNameProps, PlatformButtonTheme>
export const PlatformName: StyledComponent<PlatformNameProps, PlatformButtonTheme, HTMLDivElement> =
  styled.div`
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
    color: ${(props: PlatformNamePropsWithTheme) =>
      PLATFORM_BUTTOM_THEME[props.theme.platformButtomKind ?? 'native'].color.title};

    ${IconContainer} {
      font-size: 12px;
      margin-right: 8px;
      svg {
        fill: ${(props: PropsWithTheme<PlatformNameProps, any>) =>
          props.platform === 'android' ? '#8cc052' : colors.textLight};
      }
    }

    span {
      color: ${colors.text};

      &.profile_platform_version {
        color: ${colors.textLight};
      }
    }
  `

export const LastVisitDate: StyledComponent<{}, PlatformButtonTheme, HTMLDivElement> = styled.div`
  color: ${colors.textLight};
  line-height: 20px;
`

export const PlatformDetails: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  padding: 14px 0 14px 16px;
`

export const Debug: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: transparent;
  backdrop-filter: blur(0px);
  transition: ${HOVER_ANIMATION};

  & > div {
    display: none;
  }

  ${IconContainer} {
    margin-left: 4px;
  }
`

export const PlatformStatus: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  position: relative;

  ${ProfileMockupOptinContainer} {
    max-height: 75px;
  }
`

const PLATFORM_STYLES = css`
  width: 100%;
  max-width: 348px;
  background-color: ${colors.fill};
  border: 1px solid ${colors.stroke};
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(15, 15, 15, 0.06);
  overflow: hidden;
`

export const PlatformButtonSkeleton: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  ${PLATFORM_STYLES};
  padding: 14px 12px 14px 16px;
`

export const PlatformButtonContainer: StyledComponent<{}, PlatformButtonTheme, HTMLDivElement> =
  styled.div`
    ${PLATFORM_STYLES};
    display: grid;
    grid-template-columns: minmax(100px, 1fr) 140px;
    cursor: pointer;
    user-select: none;
    transition: ${HOVER_ANIMATION};

    ${(p: PropsWithTheme<{}, PlatformButtonTheme>) =>
      p.theme.platformButtomKind === 'imported' &&
      css`
        background-image: url('/medias/img/patterns/stripe-patterns.svg');
        background-size: 5%;
      `}

    &:hover {
      background-color: ${schemes.grayscale['05']};
      border-color: ${colors.strokeHover};
      box-shadow: 0px 1px 3px 0px rgba(15, 15, 15, 0.06);

      ${PlatformName}, ${LastVisitDate} {
        color: ${colors.textNeutral};
      }
    }

    &:focus,
    &:active {
      background-color: ${schemes.grayscale['10']};
      border-color: ${colors.strokeHover};

      ${PlatformName}, ${LastVisitDate} {
        color: ${colors.textNeutral};
      }
    }

    &:hover,
    &:focus,
    &:active {
      ${Debug} {
        backdrop-filter: blur(3px) grayscale(75%);
        color: ${colors.textAction};

        & > div {
          display: block;
        }
      }
    }
  `
