// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Grid } from 'components/common/grid'
import { InputWrapperContainer } from 'components/form/input-wrapper.styles'
import { darklucent } from 'components/styled/tokens/schemes'

type ConfigGridContainerProps = {
  gap: number,
  ...
}

export const ConfigGridContainer: StyledComponent<ConfigGridContainerProps, { ... }, *> = styled(
  Grid
)`
  row-gap: ${(props: ConfigGridContainerProps) => `${props.gap / 2}px`};

  ${InputWrapperContainer} {
    margin: 0;
  }

  .separator:not(:last-child) {
    grid-column: 1 / -1;
    border-bottom: 1px solid ${darklucent[20]};
  }
`
