// @flow
import { createSelector } from 'reselect'

import { capitalize } from 'com.batch.common/utils'

import { profileDataStateSelector } from 'com.batch/profile/store/profile.selector'
import { type State } from 'com.batch.redux/_records'

import { retrieveFirstNameAndLastName } from 'com.batch/profile/infra/parses/retrieve-firstname-and-lastname'
import { type ProfileDataRecord } from 'com.batch/profile/models/profile.records'

export const getFullName: (state: State) => ?string = createSelector(
  profileDataStateSelector,
  ({ attributes }: ProfileDataRecord) => {
    const { firstName, lastName } = retrieveFirstNameAndLastName(attributes)
    if (firstName && lastName) return `${capitalize(firstName)} ${capitalize(lastName)}`
    return null
  }
)
