// @flow
import Immutable, { type List, type RecordFactory, type RecordOf } from 'immutable'

export type BounceClassificationProps = { name: string, count: number }
export type BounceClassificationRecord = RecordOf<BounceClassificationProps>

export const BounceClassificationFactory: RecordFactory<BounceClassificationProps> =
  Immutable.Record({
    name: '',
    count: 0,
  })

export type BounceCategoryName = 'HARD' | 'SOFT' | 'BLOCK' | 'UNDETERMINED'
export type BounceCategoryProps = {
  name: BounceCategoryName,
  count: number,
  rate: number,
  classifications: List<BounceClassificationRecord>,
}

export type BounceCategoryRecord = RecordOf<BounceCategoryProps>
export const BounceCategoryFactory: RecordFactory<BounceCategoryProps> = Immutable.Record(
  ({
    name: 'UNDETERMINED',
    count: 0,
    rate: 0,
    classifications: new Immutable.List(),
  }: BounceCategoryProps)
)

type DataProps = {
  value: number,
  rate: number,
  unique: ?number,
  trend: ?number,
  previousPeriodRate: ?number,
  previousPeriodValue: ?number,
}
type DataRecord = RecordOf<DataProps>
const DataFactory: RecordFactory<DataProps> = Immutable.Record(
  ({
    value: 0,
    rate: 0,
    unique: null,
    trend: null,
    previousPeriodRate: null,
    previousPeriodValue: null,
  }: DataProps)
)

export type StatsProps = {
  sent: DataRecord,
  bounce: DataRecord,
  click: DataRecord,
  mpp: DataRecord,
  delivered: DataRecord,
  open: DataRecord,
  unsubscribe: DataRecord,
  skippedSuppressionList: DataRecord,
  sending: DataRecord,
  injection: number,
  spam: number,
  deliveredSMSNbParts: number,
}
export const StatsFactory: RecordFactory<StatsProps> = Immutable.Record({
  sent: DataFactory(),
  bounce: DataFactory(),
  click: DataFactory(),
  mpp: DataFactory(),
  delivered: DataFactory(),
  open: DataFactory(),
  unsubscribe: DataFactory(),
  skippedSuppressionList: DataFactory(),
  sending: DataFactory(),
  injection: 0,
  spam: 0,
  deliveredSMSNbParts: 0,
})
export type StatsRecord = RecordOf<StatsProps>

export type OrchestrationStatsProps = {
  email: ?StatsRecord,
  sms: ?StatsRecord,
  push: ?StatsRecord,
}
export const OrchestrationStatsFactory: RecordFactory<OrchestrationStatsProps> = Immutable.Record({
  email: null,
  sms: null,
  push: null,
})
export type OrchestrationStatsRecord = RecordOf<OrchestrationStatsProps>
