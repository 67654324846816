// @flow

import type { List, Map, OrderedMap, OrderedSet, RecordFactory, RecordOf, Set } from 'immutable' // eslint-disable-line

import { type Dayjs } from 'dayjs'
import * as Immutable from 'immutable'

import { type availableIcons } from 'components/common/svg-icon'
import * as colorsLegacy from 'components/styled/colors'
import { colors } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type SegmentStateRecord } from '../modules/segments/store/segments.state'
import { type ProfileAudienceStateRecord } from 'com.batch/audience/store/audience-profile.state'
import { type LabelStateRecord } from 'com.batch/labels/store/labels.state'
import { type MessageStateRecord } from 'com.batch/message/store/message.state'
import { type InlineEditorStateRecord } from 'com.batch/message-builder/store/inline-editor.state'
import { type OrchestrationStateRecord } from 'com.batch/orchestration/store/orchestration.state'
import { type OrchestrationAnalyticsRecord } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { type OrchestrationListStateRecord } from 'com.batch/orchestration-list/store/orchestration-list.state'
import { BillingFactory, type BillingRecord } from 'com.batch.redux/billing.records'
import { ContentStateFactory, type ContentStateRecord } from 'com.batch.redux/content.records'
import { type InjectedApi } from 'com.batch.redux/corelogic/gateways/api'
import { type SenderIdentityStateRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'
import {
  CampaignDataFactory,
  type CampaignDataRecord,
  type DataCampaignStateRecord,
} from 'com.batch.redux/dataCampaign.records'
import { type ProjectStateRecord } from 'com.batch.redux/project.records'
import { type QueryRecord } from 'com.batch.redux/query/query.records'
import { type StatStateRecord } from 'com.batch.redux/stat.records'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'
import { type TemplateState } from 'com.batch.redux/template'
import { type ThemeStateRecord } from 'com.batch.redux/theme.records'
import { type ToasterStateRecord } from 'com.batch.redux/toaster.records'
import {
  type EntityLogRecord,
  UserFactory,
  type UserRecord,
  type UserStateRecord,
} from 'com.batch.redux/user.records'

import { VolumesFactory, type VolumesRecord } from 'com.batch/account/model/volumes.records'
import { type ProfileStateRecord } from 'com.batch/profile/models/profile.records'
import { type ProfilebaseType } from 'com.batch/profilebase/infra/types/profilebase.type'
import { type FCMConfigRecord } from 'com.batch/settings/models/fcm-config.records'
import { DELAY_MODE, STATUS } from 'constants/common'

export type pushCampaignSendType = 'now' | 'scheduled' | 'recurring' | 'trigger'

type CappingProps = {
  period: string,
  capping: number | '',
  id: 'new' | number,
}

type AnyMapString = Map<string, any>

export const TimeIntervalUnitLabels = {
  s: 'second',
  m: 'minute',
  d: 'day',
  h: 'hour',
}
export type intervalUnit = $Keys<typeof TimeIntervalUnitLabels>

type AgeProps = {
  inputValue: string,
  seconds: number,
  valid: boolean,
  unit: intervalUnit,
  value: number,
  label: string,
  fullText: string,
}

export const AgeFactory: RecordFactory<AgeProps> = Immutable.Record(
  ({
    inputValue: '',
    seconds: 0,
    valid: true,
    unit: 's',
    value: 0,
    label: '',
    fullText: '',
  }: AgeProps)
)

export type AgeRecord = RecordOf<AgeProps>

type SdkProps = {
  kind: string,
  icon: availableIcons,
  version: ?string,
  packageManagerMinVersion: ?string,
  color: string,
  legacy: boolean,
  allowedPlatforms: Set<Platforms>,
  ...
}

export const SdkFactory: RecordFactory<SdkProps> = Immutable.Record(
  ({
    kind: 'iOS',
    icon: 'ios',
    version: '',
    packageManagerMinVersion: '',
    color: '#142840',
    legacy: false,
    allowedPlatforms: Immutable.Set(),
  }: SdkProps)
)

export type SdkRecord = RecordOf<SdkProps>

type SdkFeatureProps = {
  supported: boolean,
  nb: number,
  pcent: number,
  ...
}
export const SdkFeatureFactory: RecordFactory<SdkFeatureProps> = Immutable.Record(
  ({
    supported: false,
    nb: 0,
    pcent: 0,
  }: SdkFeatureProps)
)

export type SdkFeatureRecord = RecordOf<SdkFeatureProps>

type SdkSupportsProps = {
  landing: SdkFeatureRecord,
  inapp: SdkFeatureRecord,
  banner: SdkFeatureRecord,
  scrolling: SdkFeatureRecord,
  gif: SdkFeatureRecord,
  html: SdkFeatureRecord,
  modal: SdkFeatureRecord,
  image: SdkFeatureRecord,
  webview: SdkFeatureRecord,
  ...
}
export const SdkSupportsFactory: RecordFactory<SdkSupportsProps> = Immutable.Record(
  ({
    landing: SdkFeatureFactory(),
    inapp: SdkFeatureFactory(),
    banner: SdkFeatureFactory(),
    scrolling: SdkFeatureFactory(),
    gif: SdkFeatureFactory(),
    html: SdkFeatureFactory(),
    modal: SdkFeatureFactory(),
    image: SdkFeatureFactory(),
    webview: SdkFeatureFactory(),
  }: SdkSupportsProps)
)

export type SdkSupportsRecord = RecordOf<SdkSupportsProps>

export type Variant = 'a' | 'b'

type CampaignABProps = {
  enabled: boolean,
  activeVariants: Set<Variant>,
  ...
}
export const CampaignABFactory: RecordFactory<CampaignABProps> = Immutable.Record(
  ({
    enabled: false,
    activeVariants: Immutable.Set(['a']),
  }: CampaignABProps)
)

export type CampaignABRecord = RecordOf<CampaignABProps>

type CustomAudienceProps = {
  id: number,
  name: string,
  description: string,
  idsCount: number,
  estimate: ?number | 'error',
  partial: boolean,
  deleted: boolean,
  type: 'custom_ids' | 'advertising_ids' | 'install_ids',
  isIndexing: boolean,
  updatedAt: Dayjs,
  ...
}

export const CustomAudienceFactory: RecordFactory<CustomAudienceProps> = Immutable.Record(
  ({
    id: 0,
    name: '',
    description: '',
    idsCount: 0,
    estimate: null,
    partial: false,
    deleted: false,
    type: 'custom_ids',
    isIndexing: false,
    updatedAt: dayjs(),
  }: CustomAudienceProps)
)

export type CustomAudienceRecord = RecordOf<CustomAudienceProps>

export type CustomAudienceConfigProps = {
  sortBy: string,
  sortOrder: 'dsc' | 'asc',
  page: number,
  ...
}
type CustomAudienceStateProps = {
  creating: boolean,
  customAudienceLoadingState: fetchingState,
  entities: Map<string, CustomAudienceRecord>,
  count: number,
  idsPerPage: Map<number, List<string>>,
  status: fetchingState,
  ...
}

export const CustomAudienceStateFactory: RecordFactory<CustomAudienceStateProps> = Immutable.Record(
  {
    creating: false,
    customAudienceLoadingState: STATUS.INIT,
    entities: Immutable.Map(),
    count: 0,
    idsPerPage: Immutable.Map(),
    status: STATUS.INIT,
  }
)

export type CustomAudienceStateRecord = RecordOf<CustomAudienceStateProps>

export type TriggerConfigProps = {
  hasExitEvent: boolean,
  hasGrace: boolean,
  hasCapping: boolean,
  hasStart: boolean,
  hasEnd: boolean,
  enterEvent: string,
  enterEventQuery: ?{ ... },
  pushTimer: AgeRecord,
  pushTimerReference: string,
  pushTimerMode: 'event' | 'before' | 'after',
  instanceId: string,
  hasInstanceId: boolean,
  resetTimerOnEvent: boolean,
  exitEvents: List<{ eventId: string, query: ?{ ... }, ... }>,
  grace: AgeRecord,
  capping: number,
  start: ?Dayjs,
  end: ?Dayjs,
  delayMode: 'TIMER' | 'IMMEDIATE',
  isHackForMultiStep: boolean,
}
export const TriggerConfigFactory: RecordFactory<TriggerConfigProps> = Immutable.Record(
  ({
    enterEvent: '',
    hasInstanceId: false,
    enterEventQuery: null,
    instanceId: '',
    pushTimer: AgeFactory({
      inputValue: '1',
      seconds: 3600,
      valid: true,
      unit: 'h',
      label: 'hour',
      fullText: '1 hour',
    }),
    pushTimerReference: '',
    pushTimerMode: 'event',
    resetTimerOnEvent: true,
    hasExitEvent: true,
    hasStart: false,
    hasEnd: false,
    hasGrace: true,
    hasCapping: false,
    exitEvents: new Immutable.List().push({ eventId: '', query: null }),
    grace: AgeFactory({
      inputValue: '1',
      seconds: 3600,
      valid: true,
      unit: 'h',
      label: 'hour',
      fullText: '1 hour',
    }),
    capping: 0,
    start: null,
    end: null,
    delayMode: DELAY_MODE.TIMER,
    isHackForMultiStep: false,
  }: TriggerConfigProps)
)
export type TriggerConfigRecord = RecordOf<TriggerConfigProps>

export type AbTestedThemeCodeProps = $ReadOnly<{
  a: ?string,
  b: ?string,
}>

export const AbTestedThemeCodeFactory: RecordFactory<AbTestedThemeCodeProps> = Immutable.Record(
  ({
    a: null,
    b: null,
  }: AbTestedThemeCodeProps)
)

export type AbTestedThemeCodeRecord = RecordOf<AbTestedThemeCodeProps>

type CampaignProps = {
  id: string,
  appId: number,
  channels: Set<Channel>,
  _id: string,
  devOnly: boolean,
  api: boolean,
  archived: boolean,
  stats: CampaignDataRecord,
  token: string,
  customAudiences: Set<CustomAudienceRecord>,
  type: campaignType,
  triggerConfig: TriggerConfigRecord,
  inAppPriority: inAppPriority,
  trigger: string,
  triggerLabel: ?string,
  defaultMessage: string,
  defaultTitle: string,
  grace: string,
  data: ContentStateRecord,
  name: string,
  state: campaignStateType,
  targeting: any,
  variantsThemeCodes: AbTestedThemeCodeRecord,
  inappJustInTime: boolean,
  start: ?Dayjs,
  end: ?Dayjs,
  repeatFrequency: number,
  repeatUnit: repeatUnitType,
  sendType: pushCampaignSendType,
  loading: boolean,
  saving: boolean,
  tzAware: boolean,
  abtesting: CampaignABRecord,
  pickedLabels: Set<CappingCategoryRecord>,
  hasLanding: boolean,
  capping: ?number,
  gracePeriod: number,
  translations: Immutable.OrderedSet<string>,
  schedulingType: schedulingType,
}

export const CampaignFactory: RecordFactory<CampaignProps> = Immutable.Record(
  ({
    id: '',
    appId: 0,
    channels: Immutable.Set(),
    _id: 'new',
    devOnly: false,
    api: false,
    archived: false,
    inappJustInTime: false,
    stats: CampaignDataFactory(),
    token: 'new',
    customAudiences: Immutable.Set(),
    type: 'push',
    triggerConfig: TriggerConfigFactory(),
    inAppPriority: 'standard',
    trigger: 'next_session',
    triggerLabel: null,
    defaultMessage: '',
    defaultTitle: '',
    grace: '',
    data: ContentStateFactory(),
    name: '',
    state: 'NEW',
    targeting: undefined,
    variantsThemeCodes: AbTestedThemeCodeFactory(),
    start: null,
    end: null,
    saving: false,
    repeatFrequency: 0,
    repeatUnit: 'DAILY',
    sendType: 'scheduled',
    loading: false,
    tzAware: true,
    abtesting: CampaignABFactory(),
    pickedLabels: Immutable.OrderedSet(),
    hasLanding: false,
    capping: null,
    gracePeriod: 0,
    translations: new Immutable.OrderedSet(),
    schedulingType: 'campaigns',
  }: CampaignProps),
  'Campaign'
)

export type CampaignRecord = RecordOf<CampaignProps>

type CampaignFilterProps = {
  category: 'status' | 'sources' | 'when' | 'channels',
  name: string,
  label: string,
  ...
}
export const CampaignFilterFactory: RecordFactory<CampaignFilterProps> = Immutable.Record(
  ({
    category: 'status',
    name: '',
    label: '',
  }: CampaignFilterProps)
)

export type CampaignFilterRecord = RecordOf<CampaignFilterProps>

type DateRangeFilterProps = {
  from: Dayjs,
  to: Dayjs,
}
export const DateRangeFilterFactory: RecordFactory<DateRangeFilterProps> = Immutable.Record(
  ({
    from: dayjs(),
    to: dayjs(),
  }: DateRangeFilterProps)
)

export type DateRangeFilterRecord = RecordOf<DateRangeFilterProps>

type CampaignActiveFiltersProps = {
  query: string,
  commons: Immutable.Set<CampaignFilterRecord>,
  labels: Immutable.Set<number>,
  dateRange: ?DateRangeFilterRecord,
}
export const CampaignActiveFiltersFactory: RecordFactory<CampaignActiveFiltersProps> =
  Immutable.Record({
    query: '',
    commons: Immutable.Set(),
    labels: Immutable.Set(),
    dateRange: null,
  })
export type CampaignActiveFiltersRecord = RecordOf<CampaignActiveFiltersProps>

export type CampaignConfigProps = {
  type: campaignType,
  schedulingType: schedulingType,
  activeLanguageId: ?string,
  abtesting: Variant,
  previewSourceKind: 'custom_id' | 'user_id',
  previewSourceProfileId: string,
  previewSourceValue: string,
  previewState: fetchingState,
  advancedPaneOpened: boolean,
  focusedLandingField: string,
  editing: string,
  queryParsed: boolean,
  contentParsed: boolean,
  sortBy: string,
  sortOrder: 'dsc' | 'asc',
  page: number,
  filters: CampaignActiveFiltersRecord,
  filtersFilled: boolean,
  ...
}
export const CampaignConfigFactory: RecordFactory<CampaignConfigProps> = Immutable.Record(
  ({
    type: 'in-app',
    schedulingType: 'automations',
    activeLanguageId: 'default',
    abtesting: 'a',
    advancedPaneOpened: false,
    focusedLandingField: '',
    previewSourceKind: 'user_id',
    previewSourceProfileId: '',
    previewSourceValue: '',
    previewState: 'INIT',
    editing: 'new',
    queryParsed: true,
    contentParsed: false,
    sortBy: 'id',
    sortOrder: 'dsc',
    filters: CampaignActiveFiltersFactory(),
    filtersFilled: false,
    page: 1,
  }: CampaignConfigProps)
)
export type CampaignConfigRecord = RecordOf<CampaignConfigProps>

type CampaignStateProps = {
  config: CampaignConfigRecord,
  countTotal: number,
  countFiltered: number,
  loadingCount: boolean,
  loadingTest: boolean,
  replication: Immutable.List<any>,
  loading: boolean,
  pagerLoading: boolean,
  entities: Immutable.Map<string, CampaignRecord>,
  idsPerPage: Immutable.Map<number, Immutable.List<string>>,
  failure: boolean,
  data: Immutable.List<any>,
  exportLoaded: boolean,
  ...
}

export const CampaignStateFactory: RecordFactory<CampaignStateProps> = Immutable.Record(
  ({
    config: CampaignConfigFactory(),
    countTotal: 0,
    countFiltered: 0,
    loadingCount: true,
    loadingTest: false,
    replication: new Immutable.List(),
    loading: true,
    pagerLoading: false,
    entities: Immutable.Map().set('new', CampaignFactory()),
    idsPerPage: Immutable.Map(),
    failure: false,
    data: new Immutable.List(),
    exportLoaded: false,
  }: CampaignStateProps)
)

export type CampaignStateRecord = RecordOf<CampaignStateProps>
type ReplicationProps = {
  appId: number | string,
  companyId: ?number,
  platform: ?availableIcons,
  name: string,
  icon: string,
  kind: campaignType,
  error: string,
  loading: boolean,
  token: string,
  schedulingType: schedulingType,
  ...
}
export const ReplicationFactory: RecordFactory<ReplicationProps> = Immutable.Record(
  ({
    appId: '',
    companyId: null,
    platform: undefined,
    name: '',
    icon: '',
    kind: 'push',
    error: '',
    loading: true,
    pagerLoading: false,
    token: '',
    schedulingType: 'campaigns',
  }: ReplicationProps)
)

export type ReplicationRecord = RecordOf<ReplicationProps>

type LanguageProps = {
  value: string,
  label: string,
  pretty: string,
  nb: number,
  valid: boolean,
  tokens: number,
  content: any,
  pcent: string,
  ...
}

export const LanguageFactory: RecordFactory<LanguageProps> = Immutable.Record(
  ({
    value: '',
    label: '',
    pretty: '',
    nb: 0,
    content: undefined,
    valid: false,
    tokens: 0,
    pcent: '-',
  }: LanguageProps)
)

export type LanguageRecord = RecordOf<LanguageProps>

type RegionProps = {
  value: string,
  label: string,
  pretty: string,
  nb: number,
  tokens: number,
  pcent: string,
  ...
}
export type RegionRecord = RecordOf<RegionProps>

export const RegionFactory: RecordFactory<RegionProps> = Immutable.Record(
  ({
    value: '',
    label: '',
    pretty: '',
    nb: 0,
    tokens: 0,
    pcent: '-',
  }: RegionProps)
)

type FieldProps = {
  disabled: boolean,
  shown: boolean,
  label: string,
  data: string,
  ...
}

export const FieldFactory: RecordFactory<FieldProps> = Immutable.Record(
  ({
    disabled: false,
    shown: false,
    label: '',
    data: '',
  }: FieldProps)
)

export type FieldRecord = RecordOf<FieldProps>

export const CappingFactory: RecordFactory<CappingProps> = Immutable.Record(
  ({
    period: '',
    capping: '',
    id: 'new',
  }: CappingProps)
)

export type CappingRecord = RecordOf<CappingProps>

type CappingCategoryProps = {
  id: 'new' | number,
  count: number,
  code: string,
  name: string,
  enabled: boolean,
  internal: boolean,
  dirty: boolean,
  cappings: List<CappingRecord>,
}
export const CappingCategoryFactory: RecordFactory<CappingCategoryProps> = Immutable.Record(
  ({
    id: 'new',
    code: '',
    count: 0,
    name: '',
    enabled: true,
    internal: false,
    dirty: false,
    cappings: new Immutable.List<CappingRecord>(),
  }: CappingCategoryProps)
)

export type CappingCategoryRecord = RecordOf<CappingCategoryProps>

// ===================================
// PUSH CONFIG
// ===================================
type WnsConfigProps = {
  packageSID: string,
  clientSecret: string,
  loading: boolean,
  checked: boolean,
}
export const WnsConfigFactory: RecordFactory<WnsConfigProps> = Immutable.Record(
  ({
    packageSID: '',
    clientSecret: '',
    loading: false,
    checked: false,
  }: WnsConfigProps)
)

export type WnsConfigRecord = RecordOf<WnsConfigProps>

type VAPIDConfigProps = {
  publicKey: string,
  privateKey: string,
  subdomain: string,
  loading: boolean,
  ...
}
export const VAPIDConfigFactory: RecordFactory<VAPIDConfigProps> = Immutable.Record(
  ({
    publicKey: '',
    loading: false,
    privateKey: '',
    subdomain: '',
  }: VAPIDConfigProps)
)

export type VAPIDConfigRecord = RecordOf<VAPIDConfigProps>

export type SafariWebsitesProps = {
  id: string,
  domain: string,
  certificateName: string,
  certificateExpirationDate: string,
  uploadedAt: string,
}
export const SafariWebsitesFactory: RecordFactory<SafariWebsitesProps> = Immutable.Record(
  ({
    id: '',
    domain: '',
    certificateName: '',
    certificateExpirationDate: '',
    uploadedAt: '',
  }: SafariWebsitesProps)
)

export type SafariWebsitesRecord = RecordOf<SafariWebsitesProps>

type GCMConfigProps = {
  senderId: string,
  serverApiKey: string,
  ...
}
export const GCMConfigFactory: RecordFactory<GCMConfigProps> = Immutable.Record(
  ({
    senderId: '',
    serverApiKey: '',
  }: GCMConfigProps)
)

export type GCMConfigRecord = RecordOf<GCMConfigProps>

type P12ConfigProps = {
  certificateName: string,
  uploadedOn: Dayjs,
  expireAt: ?Dayjs,
  valid: boolean,
  error: ?string,
}
export const P12ConfigFactory: RecordFactory<P12ConfigProps> = Immutable.Record(
  ({
    certificateName: '',
    uploadedOn: dayjs(),
    expireAt: undefined,
    valid: false,
    error: undefined,
  }: P12ConfigProps)
)

export type P12ConfigRecord = RecordOf<P12ConfigProps>

type P8ConfigProps = {
  valid: boolean,
  uploadedOn: Dayjs,
  topic: string,
  teamId: string,
  keyId: string,
  privateKey: string,
  error: ?string,
}

export const P8ConfigFactory: RecordFactory<P8ConfigProps> = Immutable.Record(
  ({
    topic: '',
    teamId: '',
    uploadedOn: dayjs(),
    keyId: '',
    privateKey: '',
    valid: false,
    error: undefined,
  }: P8ConfigProps)
)

export type P8ConfigRecord = RecordOf<P8ConfigProps>

type HMSConfigProps = {
  appId: string,
  appKey: string,
  callbackUrlToken: string,
  callbackUsername: string,
  callbackKey: string,
}
export const HMSConfigFactory: RecordFactory<HMSConfigProps> = Immutable.Record(
  ({
    appId: '',
    appKey: '',
    callbackUrlToken: '',
    callbackUsername: '',
    callbackKey: '',
  }: HMSConfigProps)
)

export type HMSConfigRecord = RecordOf<HMSConfigProps>

type PushConfigProps = {
  loading: boolean,
  defaultPriority: 'HIGH' | 'NORMAL',
  defaultTtl: ?number,
  defaultCollapseKey: string,
  maxRate: ?number,
  loadingDevOrigins: boolean,
  p12: ?P12ConfigRecord,
  p8: ?P8ConfigRecord,
  gcm: List<GCMConfigRecord>,
  fcm: List<FCMConfigRecord>,
  vapid: ?VAPIDConfigRecord,
  wns: ?WnsConfigRecord,
  hms: ?HMSConfigRecord,
  allowedDevOrigins: Array<string>,
  safariWebsiteName: string,
  safariWebsiteIcon: string,
  androidSmallIcon: string,
  safariWebsites: List<SafariWebsitesRecord>,
  safariOpenTracking: boolean,
}

export const PushConfigFactory: RecordFactory<PushConfigProps> = Immutable.Record(
  ({
    loading: false,
    defaultPriority: 'HIGH',
    maxRate: null,
    defaultTtl: undefined,
    defaultCollapseKey: 'default',
    p12: undefined,
    p8: undefined,
    gcm: new Immutable.List(),
    fcm: new Immutable.List(),
    vapid: undefined,
    wns: undefined,
    hms: undefined,
    loadingDevOrigins: false,
    allowedDevOrigins: [],
    safariWebsiteName: '',
    safariWebsiteIcon: '',
    androidSmallIcon: '',
    safariWebsites: new Immutable.List(),
    safariOpenTracking: true,
  }: PushConfigProps)
)

export type PushConfigRecord = RecordOf<PushConfigProps>

export type openRateAlgEnum = 'ACCURATE' | 'ACCURATE_DIRECT' | 'LEGACY' | 'LEGACY_DIRECT'
export type fetchingState = 'INIT' | 'LOADING' | 'LOADED' | 'ERROR'

type AppProps = {
  id: number,
  projectKey: ?string,
  demo: boolean,
  bundleId: string,
  webpushAuth: ?string,
  openRateAlg: openRateAlgEnum,
  apiKey: string,
  devApiKey: ?string,
  name: string,
  loadingState: fetchingState,
  labelsCountLoadingState: fetchingState,
  archived: boolean,
  platform: Platforms,
  inboxSecret: ?string,
  defaultLanguageId: ?string,
  created: ?Dayjs,
  updated: ?Dayjs,
  pushConfig: PushConfigRecord,
  icon: string,
  lastPushTestProd: boolean,
  sdk: string,
  features: Set<FeatureCode>,
  companyId?: number,
  pushImported: boolean,
  status: string,
  pushToken: string,
  validPushConfig: boolean,
  ttlRetargeting: ?number,
  ttlJourney: ?number,
  cappingCategories: List<CappingCategoryRecord>,
  safariSetupValid: boolean,
  lastAccess: Dayjs | null,
  showIntegrate: boolean,
  logs: List<EntityLogRecord>,
}

export const AppFactory: RecordFactory<AppProps> = Immutable.Record(
  ({
    id: 0,
    projectKey: null,
    bundleId: '',
    loadingState: STATUS.INIT,
    labelsCountLoadingState: STATUS.INIT,
    openRateAlg: 'ACCURATE',
    webpushAuth: undefined,
    created: undefined,
    updated: undefined,
    demo: false,
    archived: false,
    apiKey: '',
    devApiKey: undefined,
    name: '',
    platform: '',
    inboxSecret: undefined,
    pushConfig: PushConfigFactory(),
    defaultLanguageId: null,
    icon: '',
    lastPushTestProd: true,
    sdk: '',
    features: Immutable.Set(),
    companyId: undefined,
    pushImported: false,
    status: 'free',
    pushToken: '',
    validPushConfig: false,
    ttlRetargeting: null,
    ttlJourney: null,
    cappingCategories: new Immutable.List(),
    logs: new Immutable.List(),
    lastAccess: null,
    showIntegrate: false,
    safariSetupValid: false,
  }: AppProps)
)

export type AppRecord = RecordOf<AppProps>

type GDPRRecordProps = {
  name: string,
  address: string,
  address2: string,
  city: string,
  zip: string,
  country: string,
  dpoName: string,
  dpoEmail: string,
  categories: Set<string>,
  disabled: boolean,
  external: boolean,
  created: ?Dayjs,
  updated: ?Dayjs,
  companyId: ?number,
}

export const GDPRRecordFactory: RecordFactory<GDPRRecordProps> = Immutable.Record(
  ({
    disabled: false,
    external: false,
    name: '',
    address: '',
    address2: '',
    city: '',
    zip: '',
    country: '',
    dpoName: '',
    dpoEmail: '',
    categories: Immutable.Set(),
    created: null,
    updated: null,
    companyId: null,
  }: GDPRRecordProps)
)

export type GDPRRecord = RecordOf<GDPRRecordProps>

export type SAMLProps = {
  loginUrl: ?string,
  certificate: ?string,
  entityId: ?string,
  isEnabled: boolean,
  certificateExpiration: ?Dayjs,
}

export const SAMLRecordFactory: RecordFactory<SAMLProps> = Immutable.Record(
  ({
    loginUrl: '',
    certificate: '',
    entityId: '',
    isEnabled: false,
    certificateExpiration: null,
  }: SAMLProps)
)

export type SAMLRecord = RecordOf<SAMLProps>

type CompanyProps = {
  id: number,
  deletedAt: ?Dayjs,
  externalId: string,
  enforce2FA: boolean,
  name: string,
  avatarUrl: ?string,
  loading: boolean,
  hasEditorialDashboard: boolean,
  plan: ?string,
  disabledFeaturesCode: OrderedSet<FeatureCode>,
  additionalFeaturesCode: OrderedSet<FeatureCode>,
  planFeaturesCode: OrderedSet<FeatureCode>,
  overridedSeats: ?number,
  seats: number,
  usedSeats: number,
  webpushEnabled: boolean,
  users: OrderedSet<number>,
  restKey?: string,
  gdpr: GDPRRecord,
  sfid: ?string,
  billing: BillingRecord,
  volumes: VolumesRecord,
  saml: ?SAMLRecord,
  logs: List<EntityLogRecord>,
}
export const CompanyFactory: RecordFactory<CompanyProps> = Immutable.Record(
  ({
    id: 0,
    deletedAt: null,
    externalId: '',
    enforce2FA: false,
    hasEditorialDashboard: false,
    name: '',
    avatarUrl: null,
    plan: null,
    disabledFeaturesCode: Immutable.OrderedSet(),
    additionalFeaturesCode: Immutable.OrderedSet(),
    planFeaturesCode: Immutable.OrderedSet(),
    users: Immutable.OrderedSet(),
    overridedSeats: null,
    seats: 0,
    usedSeats: 0,
    webpushEnabled: false,
    restKey: undefined,
    loading: false,
    sfid: undefined,
    gdpr: GDPRRecordFactory(),
    billing: BillingFactory(),
    volumes: VolumesFactory(),
    saml: SAMLRecordFactory(),
    logs: new Immutable.List(),
  }: CompanyProps)
)

export type CompanyRecord = RecordOf<CompanyProps>

type TestDeviceProps = {
  loading: boolean,
  loadingFor: 'save' | 'delete' | 'send' | '',
  dirty: boolean,
  name: string,
  kind: 'custom_id' | 'advertising_id' | 'installation_id' | 'token',
  value: string,
  distribution: boolean,
  id: number,
  appId: number,
  created: ?Dayjs,
  updated: ?Dayjs,
}

export const TestDeviceFactory: RecordFactory<TestDeviceProps> = Immutable.Record(
  ({
    loading: false,
    loadingFor: '',
    dirty: false,
    name: '',
    kind: 'installation_id',
    value: '',
    distribution: true,
    id: 0,
    appId: 0,
    created: null,
    updated: null,
  }: TestDeviceProps)
)

export type TestDeviceRecord = RecordOf<TestDeviceProps>

export type EventDataAttributeProps = {
  // __LABEL__ and __TAG__ are not available in the CEP
  type: 'URL' | 'STRING' | 'BOOLEAN' | 'INTEGER' | 'DATE' | '__LABEL__' | '__TAG__',
  // label and tag are not available in the CEP
  kind: 'attribute' | 'tag' | 'label',
  name: string,
  icon: availableIcons,
}
export const EventDataAttributeFactory: RecordFactory<EventDataAttributeProps> = Immutable.Record(
  ({
    type: 'STRING',
    kind: 'label',
    name: '',
    icon: 'text',
  }: EventDataAttributeProps)
)

export type EventDataAttributeRecord = RecordOf<EventDataAttributeProps>

export type AttributeProp = {
  loading: boolean,
  hidden: boolean,
  lastCount: ?Dayjs,
  lastUpdate: ?Dayjs,
  name: ?string,
  label: string,
  id: string,
  icon: availableIcons,
  cleanId: string,
  overridenType: ?string,
  lastReceivedType: string,
  type: string,
  native: boolean,
  category:
    | 'attribute'
    | 'event'
    | 'tag'
    | 'user_attribute'
    | 'user_tag'
    | 'user_event'
    | 'batch_attribute'
    | 'batch_event',
  allowedKeys: OrderedSet<EventDataAttributeRecord>,
}

export const AttributeFactory: RecordFactory<AttributeProp> = Immutable.Record(
  ({
    loading: false,
    hidden: false,
    lastCount: null,
    lastUpdate: null,
    name: null,
    label: '',
    id: '',
    icon: 'default',
    cleanId: '',
    overridenType: null,
    lastReceivedType: 'STRING',
    type: 'STRING',
    native: false,
    category: 'attribute',
    allowedKeys: Immutable.OrderedSet(),
  }: AttributeProp)
)

export type AttributeRecord = RecordOf<AttributeProp>

export type AttributeCategory = {
  id: 'native' | 'custom' | 'user' | null,
  count: number,
  active: boolean,
  icon: availableIcons,
  label: string,
  locked: boolean,
  ...
}

type AttributeStateConfigProps = {
  attributeLoadingState: fetchingState,
  valuesLoaded: boolean,
  devMode: boolean,
  filterTerm: string,
  filterCategory: ?string,
  profileDataMode: boolean,
  ...
}

export const AttributeStateConfigFactory: RecordFactory<AttributeStateConfigProps> =
  Immutable.Record({
    attributeLoadingState: STATUS.INIT,
    valuesLoaded: false,
    devMode: false,
    filterTerm: '',
    filterCategory: null,
    profileDataMode: false,
  })

export type AttributeStateConfigRecord = RecordOf<AttributeStateConfigProps>

type AttributeStateProps = {
  config: AttributeStateConfigRecord,
  entities: OrderedMap<string, AttributeRecord>,
  profileDataMode: boolean,
  ...
}

export const AttributeStateFactory: RecordFactory<AttributeStateProps> = Immutable.Record(
  ({
    config: AttributeStateConfigFactory(),
    entities: Immutable.OrderedMap(),
    profileDataMode: false,
  }: AttributeStateProps)
)

export type AttributeStateRecord = RecordOf<AttributeStateProps>

type AppStateProps = {
  current: AppRecord,
  entities: List<AppRecord>,
  sdks: List<SdkRecord>,
  integratePopin: boolean,
  loading: boolean,
  loaded: boolean,
  user: UserRecord,
  newAppBackUrl: string,
}

export const AppStateFactory: RecordFactory<AppStateProps> = Immutable.Record(
  ({
    current: AppFactory(),
    entities: new Immutable.List(),
    sdks: new Immutable.List().push(
      SdkFactory({
        icon: 'ios',
        kind: 'iOS',
        allowedPlatforms: Immutable.Set(['ios']),
        color: '#333333',
      }),
      SdkFactory({
        icon: 'android',
        kind: 'Android',
        allowedPlatforms: Immutable.Set(['android']),
        color: '#7AC162',
      }),
      SdkFactory({
        icon: 'windows',
        kind: 'Windows',
        allowedPlatforms: Immutable.Set(['windows']),
        color: '#1666B4',
      }),
      SdkFactory({
        icon: 'browser',
        kind: 'Web',
        allowedPlatforms: Immutable.Set(['webpush']),
        color: '#22A0DC',
      }),
      SdkFactory({
        icon: 'react-native',
        kind: 'React Native',
        allowedPlatforms: Immutable.Set(['android', 'ios']),
        color: '#61dafb',
      }),
      SdkFactory({
        icon: 'cordova',
        kind: 'Cordova',
        allowedPlatforms: Immutable.Set(['android', 'ios']),
        color: '#333333',
      }),
      SdkFactory({
        icon: 'air',
        kind: 'Air',
        allowedPlatforms: Immutable.Set(['android', 'ios']),
        color: '#DE383D',
        legacy: true,
      }),
      SdkFactory({
        kind: 'Unity',
        allowedPlatforms: Immutable.Set(['android', 'ios']),
        color: '#000',
        legacy: true,
      }),
      SdkFactory({
        icon: 'flutter',
        kind: 'Flutter',
        allowedPlatforms: Immutable.Set(['ios', 'android']),
        color: '#0175CE',
      })
    ),
    loading: false,
    loaded: false,
    integratePopin: window.location.href.indexOf('popin=integrate') !== -1,
    newAppBackUrl: '',
    user: UserFactory(),
  }: AppStateProps)
)

export type AppStateRecord = RecordOf<AppStateProps>

type ClusterDataProps = {
  installs: ?number,
  tokens: ?number,
  tokenRate: ?number,
  optinRate: ?number,
  notifsOn: ?number,
}

export const ClusterDataFactory: RecordFactory<ClusterDataProps> = Immutable.Record(
  ({
    installs: null,
    tokens: null,
    tokenRate: null,
    optinRate: null,
    notifsOn: null,
  }: ClusterDataProps)
)

export type ClusterDataRecord = RecordOf<ClusterDataProps>

type ClusterRatioProps = {
  mode: 'tokenRate' | 'optinRate',
  desc: string,
}

export const ClusterRatioFactory: RecordFactory<ClusterRatioProps> = Immutable.Record(
  ({
    mode: 'tokenRate',
    desc: '% algorithm = opt-ins / installs',
  }: ClusterRatioProps)
)

export type ClusterRatioRecord = RecordOf<ClusterRatioProps>

type ClusterProps = {
  name: string,
  code: string,
  desc: ?string,
  color: string,
  related: ?string,
  active: boolean,
  all: ClusterDataRecord,
  alive: ClusterDataRecord,
}

export const ClusterFactory: RecordFactory<ClusterProps> = Immutable.Record(
  ({
    name: '',
    color: '',
    active: false,
    code: '',
    desc: '',
    related: '',
    all: ClusterDataFactory(),
    alive: ClusterDataFactory(),
  }: ClusterProps)
)

export type ClusterRecord = RecordOf<ClusterProps>

type ClusterStateProps = {
  status: fetchingState,
  loading: boolean,
  showAlive: boolean,
  all: ClusterDataRecord,
  alive: ClusterDataRecord,
  N: ClusterRecord,
  E: ClusterRecord,
  D: ClusterRecord,
  Du: ClusterRecord,
  Er: ClusterRecord,
  Np: ClusterRecord,
  Dp: ClusterRecord,
  I: ClusterRecord,
}

export const ClusterStateFactory: RecordFactory<ClusterStateProps> = Immutable.Record(
  ({
    status: STATUS.INIT,
    loading: false,
    showAlive: false,
    all: ClusterDataFactory(),
    alive: ClusterDataFactory(),
    N: ClusterFactory({
      code: 'N',
      color: colors.fillTech,
      name: 'New',
      related: 'Np',
      desc: "Newcomers to your app that haven't yet established themselves as engaged users",
    }),
    E: ClusterFactory({
      code: 'E',
      color: colorsLegacy.good.shade.s1,
      name: 'Engaged',
      related: 'Er',
      desc: 'Regular users of your app that are actively using it.',
    }),
    D: ClusterFactory({
      code: 'D',
      color: colors.textWarning,
      name: 'Dormant',
      related: 'Dp',
      desc: 'Users who were engaged at one point, but have not launched your app for a long time.',
    }),
    Du: ClusterFactory({
      code: 'Du',
      color: colors.textDanger,
      name: 'One time',
      related: 'Du',
      desc: 'Users who did not come back after they first launched your app.',
    }),
    Np: ClusterFactory({
      code: 'Np',
      color: '#EFC954',
      name: 'New Promising',
      desc: 'Users who are about to become engaged.',
    }),
    Er: ClusterFactory({
      code: 'Er',
      color: '#67B758',
      name: 'Engaged risky',
      desc: 'Users with a high risk of becoming dormant.',
    }),
    Dp: ClusterFactory({
      code: 'Dp',
      color: '#C3562E',
      name: 'Dormant Promising',
      desc: 'Users who are about to become engaged.',
    }),
    I: ClusterFactory({
      code: 'I',
      color: colors.fillFeature,
      name: 'Imported',
      desc: 'Imported push tokens. These users are progressively analysed and transferred to other segments.',
    }),
  }: ClusterStateProps)
)

export type ClusterStateRecord = RecordOf<ClusterStateProps>

type TemplateResultProps = {
  installId: string,
  result: string,
}

export const TemplateResultFactory: RecordFactory<TemplateResultProps> = Immutable.Record(
  ({
    installId: '',
    result: '',
  }: TemplateResultProps)
)

export type TemplateResultRecord = RecordOf<TemplateResultProps>

type TemplateProps = {
  text: string,
  parsing: boolean,
  error: ?string,
  results: List<TemplateResultRecord>,
}

export const TemplateFactory: RecordFactory<TemplateProps> = Immutable.Record(
  ({
    text: '',
    parsing: false,
    error: '',
    results: new Immutable.List(),
  }: TemplateProps)
)

export type TemplateRecord = RecordOf<TemplateProps>

type EstimateTokensProps = {
  count: number,
  biggestTimezone: number,
  optIns: number,
}
export const EstimateTokensFactory: RecordFactory<EstimateTokensProps> = Immutable.Record(
  ({
    count: 0,
    biggestTimezone: 0,
    optIns: 0,
  }: EstimateTokensProps)
)

export type EstimateTokensRecord = RecordOf<EstimateTokensProps>

type EstimateProps = {
  loading: boolean,
  error: boolean,
  installs: number,
  matchingInstalls: number,
  all: EstimateTokensRecord,
  matching: EstimateTokensRecord,
}

export const EstimateFactory: RecordFactory<EstimateProps> = Immutable.Record(
  ({
    installs: 0,
    matchingInstalls: 0,
    error: false,
    loading: false,
    all: EstimateTokensFactory(),
    matching: EstimateTokensFactory(),
  }: EstimateProps)
)

export type EstimateRecord = RecordOf<EstimateProps>

export type AttributeValueProps = {
  value: string | number | Dayjs,
  pretty: string,
  pcent: string,
  installs: number,
  tokens: number,
  opacity: ?number,
}
export const AttributeValueFactory: RecordFactory<AttributeValueProps> = Immutable.Record(
  ({
    value: '',
    pretty: '',
    pcent: '',
    installs: 0,
    tokens: 0,
    opacity: 1,
  }: AttributeValueProps)
)

export type AttributeValueRecord = RecordOf<AttributeValueProps>

type AttributeValuesListProps = {
  loading: boolean,
  restricted: boolean,
  canDisplayLabels: boolean,
  count: number,
  page: number,
  mode: string,
  values: Map<string, List<AttributeValueRecord>>,
  shown: List<AttributeValueRecord>,
}
export const AttributeValuesListFactory: RecordFactory<AttributeValuesListProps> = Immutable.Record(
  {
    loading: false,
    restricted: false,
    canDisplayLabels: false,
    count: 0,
    page: 1,
    mode: '__default',
    values: Immutable.Map(),
    shown: new Immutable.List(),
  }
)
export type AttributeValuesListRecord = RecordOf<AttributeValuesListProps>

export type AttributeValueState = Map<string, AttributeValuesListRecord>

export type State = {
  +app: AppStateRecord,
  +attribute: AttributeStateRecord,
  +attrValue: AttributeValueState,
  +audience: CustomAudienceStateRecord,
  +campaign: CampaignStateRecord,
  +dataCampaign: DataCampaignStateRecord,
  +cluster: ClusterStateRecord,
  +template: TemplateState,
  +content: ContentStateRecord,
  +message: MessageStateRecord,
  +company: CompanyRecord,
  +toaster: ToasterStateRecord,
  +testDevice: OrderedMap<number, TestDeviceRecord>,
  +stat: StatStateRecord,
  +targeting: AnyMapString,
  +target: Map<string, TargetStateRecord>,
  +theme: ThemeStateRecord,
  +user: UserStateRecord,
  +query: Map<string, QueryRecord>,
  +project: ProjectStateRecord,
  +orchestrationList: OrchestrationListStateRecord,
  +audienceProfile: ProfileAudienceStateRecord,
  +profile: ProfileStateRecord,
  +profilebase: ProfilebaseType,
  +senderIdentity: SenderIdentityStateRecord,
  +orchestrationAnalytics: Map<string, OrchestrationAnalyticsRecord>,
  +orchestration: OrchestrationStateRecord,
  +inlineEditor: InlineEditorStateRecord,
  +projectLabels: LabelStateRecord,
  +segments: SegmentStateRecord,
}
export type Extract<T> = State => T
export type Dispatch = (
  | ReduxAction<string, any>
  | Promise<any>
  | DispatchExtraBoundFn<*>
  | ((dispatch: Dispatch, getState: GetState) => ReduxAction<string, any> | Promise<any>)
) => any
export type GetState = () => State

export type BatchChoice = {
  value: string,
  label: string,
  icon?: availableIcons,
  lock?: false | string,
  empty?: false | string,
  ...
}
export type ReduxAction<T, P> = $ReadOnly<{ type: T, payload: P, ... }>
export type DispatchExtraBoundFn<P> = (
  dispatch: Dispatch,
  getState: GetState,
  extra: InjectedApi
) => P
export type DispatchBoundFn<P> = (dispatch: Dispatch, getState: GetState) => P
export type DispatchOnlyBoundFn<P> = (dispatch: Dispatch) => P
