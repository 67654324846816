// @flow
import * as React from 'react'
import { type Node } from 'react'

type ReviewItemProps = {
  kind?: Node,
  children: Node,
  style?: { ... },
  ...
}

const ReviewItem = ({ kind, children, style }: ReviewItemProps): React.Node => {
  return (
    <div className="prr__item__sub" style={style}>
      {kind && <div className="prr__item__sub__kind">{kind}</div>}
      <div className="prr__item__sub__content" style={{ width: '100%' }}>
        {children}
      </div>
    </div>
  )
}
export default ReviewItem
