// @flow
import React from 'react'

export const useCreateCopyValueToClipboardCallback = ({
  notifySuccess,
}: {
  notifySuccess: () => void,
  ...
}): ((value: string) => (evt?: SyntheticMouseEvent<*>) => Promise<void>) =>
  React.useCallback(
    (value: string) => async () => {
      try {
        await navigator.clipboard.writeText(value)

        notifySuccess()
      } catch (err) {
        console.log('Oops, unable to copy')
      }
    },
    [notifySuccess]
  )
