// @flow
import * as React from 'react'

import { FilterSearch } from 'components/filter'

type Props = {
  onChange: (terms: string) => void,
  ...
}

export const FilteredEventsProvider = ({ onChange }: Props): React.Node => {
  const [terms, setTerms] = React.useState('')

  React.useEffect(() => {
    onChange(terms)
  }, [terms, onChange])

  return (
    <FilterSearch
      style={{ marginLeft: 'auto', width: 'auto' }}
      width={97}
      expandedMaxWidth={220}
      onChange={setTerms}
      placeholder={'Search events...'}
      value={terms}
      isSensitive
    />
  )
}
