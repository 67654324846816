import Immutable from 'immutable'

import * as Types from 'com.batch.common/legacy-query-types'

export default Immutable.fromJS([
  {
    value: 'exists',
    label: 'Exists',
    api: '$exists',
    forcedInputType: 'boolean',
    for: [
      Types.DATE,
      Types.FLOAT,
      Types.INTEGER,
      Types.BOOLEAN,
      Types.SET,
      Types.STRING,
      Types.VERSION,
      Types.RETARGETING,
      Types.URL,
    ],
  },
  {
    value: '<',
    label: 'is Lower than',
    api: '$lt',
    phrase: 'is lower than',
    countPhrase: 'less than',
    reversedCountPhrase: 'more than or exactly',
    for: [Types.VERSION, Types.INTEGER, Types.FLOAT, Types.DATE, Types.EVENT, Types.AGE],
  },
  {
    value: '≤',
    label: 'is Lower than or Equal to',
    api: '$lte',
    phrase: 'is lower than (or equal to)',
    countPhrase: 'less than or equal to',
    reversedCountPhrase: 'more than',
    for: [Types.VERSION, Types.INTEGER, Types.FLOAT, Types.DATE, Types.AGE],
  },
  {
    value: '=',
    label: 'is Equal to',
    phrase: 'is',
    countPhrase: '',
    reversedCountPhrase: '',
    api: '$eq',
    default: true,
    for: [
      Types.STRING,
      Types.VERSION,
      Types.INTEGER,
      Types.FLOAT,
      Types.BOOLEAN,
      Types.EVENT,
      Types.AGE,
    ],
  },
  {
    value: '≥',
    label: 'is Greater than or Equal to',
    api: '$gte',
    phrase: 'is greater than (or equal to)',
    reversedCountPhrase: 'less than or exactly',
    countPhrase: 'more than or equal to',
    for: [Types.VERSION, Types.INTEGER, Types.FLOAT, Types.DATE, Types.EVENT, Types.AGE],
  },
  {
    value: '>',
    label: 'is Greater than',
    api: '$gt',
    phrase: 'is greater than',
    reversedCountPhrase: 'less than',
    countPhrase: 'more than',
    for: [Types.VERSION, Types.INTEGER, Types.FLOAT, Types.DATE, Types.AGE],
  },
  {
    value: 'startsWith',
    label: 'Starts with',
    phrase: 'starts with',
    api: '$startsWith',
    for: [Types.STRING],
    forcedInputType: 'chars',
  },
  {
    value: 'endsWith',
    label: 'Ends with',
    phrase: 'ends with',
    api: '$endsWith',
    for: [Types.STRING],
    forcedInputType: 'chars',
  },
  {
    value: 'IN',
    label: 'is One of',
    api: '$in',
    arrayValue: true,
    forcedInputType: 'multi',
    default: true,
    phrase: 'is one of',
    singlePhrase: 'is',
    for: [Types.STRING, Types.VERSION, Types.SET],
  },
  {
    value: 'NOT IN',
    label: 'is Not One of',
    api: '$notIn',
    forcedInputType: 'multi',
    arrayValue: true,
    default: false,
    phrase: 'is not one of',
    singlePhrase: 'is not',
    for: [Types.STRING, Types.VERSION, Types.SET],
  },
  {
    value: 'contains any of',
    label: 'Contains any of',
    api: '$contains',
    phrase: 'contains some of those tags:',
    singlePhrase: 'contains the tag',
    arrayValue: true,
    forcedInputType: 'multi',
    default: true,
    for: [Types.TAG, Types.AUDIENCE],
  },
  {
    value: 'contains all of',
    label: 'Contains all of',
    phrase: 'contains all of those tags:',
    singlePhrase: 'contains the tag',
    api: '$containsAll',
    arrayValue: true,
    forcedInputType: 'multi',
    for: [Types.TAG, Types.AUDIENCE],
  },
  {
    value: 'does not contain some of',
    label: 'Does not contain some of',
    singlePhrase: 'does not contains the tag',
    api: '$doesNotContainSome',
    phrase: 'does not contains some of those tags:',
    arrayValue: true,
    forcedInputType: 'multi',
    for: [Types.TAG, Types.AUDIENCE],
  },
  {
    value: 'does not contain all',
    label: 'Does not contain all',
    api: '$doesNotContainAll',
    phrase: 'does not contains all of those tags:',
    singlePhrase: 'does not contains the tag',
    arrayValue: true,
    forcedInputType: 'multi',
    for: [Types.TAG, Types.AUDIENCE],
  },
])
