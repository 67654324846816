// @noflow
import { get as _get } from 'lodash-es'
import request from 'superagent-interface-promise'

const clusterKeys = ['N', 'Np', 'E', 'Er', 'I', 'D', 'Du', 'Dp']
const related = { N: 'Np', E: 'Er', D: 'Dp' }
const mainClusters = ['N', 'E', 'Du', 'D']

const buildRatios = (data, overridenTokenCount = 0, overridenAliveTokenCount = 0) => {
  try {
    data.all.optinRate = data.all.tokens ? data.all.notifsOn / data.all.tokens : 0
    data.alive.optinRate = data.alive.tokens ? data.alive.notifsOn / data.alive.tokens : 0
    data.all.tokenRate = data.all.installs
      ? (overridenTokenCount > 0 ? overridenTokenCount : data.all.tokens) / data.all.installs
      : 0
    data.alive.tokenRate = data.alive.installs
      ? (overridenAliveTokenCount > 0 ? overridenAliveTokenCount : data.alive.tokens) /
        data.alive.installs
      : 0
  } catch (err) {
    console.log(err)
  }
  return data
}
const sumKey = (arr, key) => {
  let total = 0
  mainClusters.forEach(clusterKey => {
    total += _get(arr, `${clusterKey}.${key}`, 0)
  })
  return total
}

// make sure we never have install < token, or token < tokenNotifOn
const ensureCohesion = clusterData => {
  clusterData.all.tokens =
    clusterData.all.installs === 0 // 0 installs = imported
      ? clusterData.all.tokens
      : Math.min(clusterData.all.installs, clusterData.all.tokens)
  clusterData.alive.tokens =
    clusterData.alive.installs === 0
      ? clusterData.alive.tokens
      : Math.min(clusterData.alive.installs, clusterData.alive.tokens)
  clusterData.all.notifsOn = Math.min(clusterData.all.notifsOn, clusterData.all.tokens)
  clusterData.alive.notifsOn = Math.min(clusterData.alive.notifsOn, clusterData.alive.tokens)
  return clusterData
}

export default {
  fetchUserbase: appId => {
    return request.get(`/api/app/${appId}/data/userbase`).then(response => {
      const data = _get(response, 'body.results[0]', {})
      let results = {}
      /* CAVEAT :
            - token.total inscludes dead users
            - token.notifsOn DOES NOT includes dead users
        */

      clusterKeys.forEach(k => {
        results[k] = {
          all: {
            notifsOn:
              _get(data, `clusters.${k}.tokens.notifOn`, 0) +
              _get(data, `clusters.${_get(related, k, 'none')}.tokens.notifOn`, 0) +
              _get(data, `clusters.${k}.tokens.deadNotifOn`, 0) +
              _get(data, `clusters.${_get(related, k, 'none')}.tokens.deadNotifOn`, 0),
            tokens:
              _get(data, `clusters.${k}.tokens.total`, 0) +
              _get(data, `clusters.${_get(related, k, 'none')}.tokens.total`, 0),
            installs:
              _get(data, `clusters.${k}.users.total`, 0) +
              _get(data, `clusters.${_get(related, k, 'none')}.users.total`, 0),
          },
          alive: {
            notifsOn:
              _get(data, `clusters.${k}.tokens.notifOn`, 0) +
              _get(data, `clusters.${_get(related, k, 'none')}.tokens.notifOn`, 0),
            tokens:
              _get(data, `clusters.${k}.tokens.total`, 0) +
              _get(data, `clusters.${_get(related, k, 'none')}.tokens.total`, 0) -
              _get(data, `clusters.${k}.tokens.dead`, 0) -
              _get(data, `clusters.${_get(related, k, 'none')}.tokens.dead`, 0),
            installs:
              _get(data, `clusters.${k}.users.total`, 0) +
              _get(data, `clusters.${_get(related, k, 'none')}.users.total`, 0) -
              _get(data, `clusters.${k}.users.dead`, 0) -
              _get(data, `clusters.${_get(related, k, 'none')}.users.dead`, 0),
          },
        }
        results[k] = buildRatios(ensureCohesion(results[k]))
      })

      results.alive = {
        notifsOn: sumKey(results, 'alive.notifsOn'),
        tokens: sumKey(results, 'alive.tokens'),
        installs: sumKey(results, 'alive.installs'),
      }
      results.all = {
        notifsOn: sumKey(results, 'all.notifsOn'),
        tokens: sumKey(results, 'all.tokens'),
        installs: sumKey(results, 'all.installs'),
      }
      results.loading = false
      results = buildRatios(results)
      return results
    })
  },
}
