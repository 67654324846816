// @flow

import Immutable, { type List } from 'immutable'
import { get as _get, isNil as _isNil, map as _map, sortBy as _sortBy } from 'lodash-es'
import request from 'superagent-interface-promise'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type AppRecord, TestDeviceFactory } from 'com.batch.redux/_records'
import {
  type DebugInstallRecord,
  type TransacResponseProps,
  DebugInstallFactory,
  TransacResponseFactory,
} from 'com.batch.redux/stat.records'

// bool stuff i still don't really get
const NotifKind = {
  None: 0,
  Badge: 1 << 0,
  Sound: 1 << 1,
  Alert: 1 << 2,
}

// -----------------------------------------------------------------------
// replaceDate
// shity utility that attempts to detect & replace tsp date or js date by moments
// -----------------------------------------------------------------------
const replaceDate = (key: string, value: number | { [string]: string, ... }): any => {
  if (_isNil(value)) return value
  if (
    typeof value === 'number' &&
    (key.startsWith('date(') || key === 'last_date' || key === 'first_date')
  ) {
    let date = dayjs.unix(value).utc() // $FlowFixMe[method-unbinding]
    return date.isValid ? date : value
  }
  if (typeof value === 'string' && key.startsWith('date(')) {
    let date = dayjs.utc(value)
    return date.isValid() ? date : value
  }
  if (typeof value === 'object') {
    _map(value, (v, k: $Keys<typeof value>) => {
      // $FlowIssue
      if (typeof value === 'object') value[k] = replaceDate(k, v)
    })
    return value
  }
  return value
}

// checks if a feature is present
const notifTypeContains = (notifType: number, kind: number): boolean => (notifType & kind) === kind

// returns the notif type as a human readddable string
export const parseNotifToString = (NotifTypeStringOrNumber: string): string => {
  const features = parseNotif(NotifTypeStringOrNumber)
  if (features.length === 0) {
    return 'Off'
  } else {
    return `On (${features.join(', ')})`
  }
}

export const parseNotif = (NotifTypeStringOrNumber: string): string[] => {
  let NotifType = parseInt(NotifTypeStringOrNumber)
  let features = []
  if (notifTypeContains(NotifType, NotifKind.Badge)) {
    features.push('badge')
  }
  if (notifTypeContains(NotifType, NotifKind.Sound)) {
    features.push('sound')
  }
  if (notifTypeContains(NotifType, NotifKind.Alert)) {
    features.push('alert')
  }
  return features
}

// -----------------------------------------------------------------------
// last devices seen
// query stephane for info about the app
// -----------------------------------------------------------------------
export const recentDevices = ({
  app,
}: {
  app: AppRecord,
  ...
}): Promise<{ customIds: Array<string>, installationIds: Array<string>, ... }> => {
  return request.get(`/api/app/${app.id}/data/infos/prod`).then(
    response => {
      return {
        customIds: response.body.results.custom_id_discovery,
        installationIds: response.body.results.di_discovery,
      }
    },
    () => {
      throw 'yeah we do not care about this one'
    }
  )
}

// -----------------------------------------------------------------------
// NORMALIZE
// Normalize for transac api debug data
// -----------------------------------------------------------------------
export const nomalizeDebugTransac = (raw: any): TransacResponseProps => {
  return TransacResponseFactory({
    error: _get(raw, 'error', false),
    recipientsCount: _get(raw, 'input_nb_recipient', 0),
    recipientsSample: _get(raw, 'input_recipients', []),
    recipientsType: _get(raw, 'input_recipient_type', []),
    failureMessage: buildFailureMessage(_get(raw, 'summary.failure', undefined)),
    failureCount: _get(raw, 'summary.nb_failure', 0),
    successCount: _get(raw, 'summary.nb_success', 0),
    notFoundCount: _get(raw, 'summary.not_found', 0),
    unregistredCount: _get(raw, 'summary.unregistered', 0),
    success: buildTransacDetailsFromRaw(raw, 'success'),
    failure: buildTransacDetailsFromRaw(raw, 'failure'),
    feedback: buildTransacDetailsFromRaw(raw, 'feedback'),
    groupId: _get(raw, 'input.groupId', _get(raw, 'summary.group_id', '')),
    pushDate: _get(raw, 'input.pushDate', 0),
    apiKey: _get(raw, 'input.apiKey.key', ''),
    labels: _get(raw, 'input.marketingPressureLabels', []),
  })
}

const buildTransacDetailsFromRaw = (raw: any, kind: string) => {
  return raw[kind]
    ? raw[kind].map(e => ({ token: e.token, date: e.date, sent_date: e.sent_date }))
    : []
}

const buildFailureMessage = (raw: any) => {
  const failure = typeof raw === 'undefined' ? {} : raw
  return Object.keys(failure)
    .reduce((arr, value) => {
      arr.push(`${value}: ${failure[value]}`)
      return arr
    }, [])
    .join(', ')
}

// -----------------------------------------------------------------------
// lookForUser
// query stephane for transact api debug data
// -----------------------------------------------------------------------
export const queryTransacRequestId = ({
  appId,
  requestId,
}: {
  appId: number,
  requestId: string,
  ...
}): Promise<any> => {
  return request.get(`/api/app/${appId}/data/transac-infos/${requestId}`).then(
    response => {
      const res = response.body.results
      if (res.input instanceof Array && res.input.length === 0) {
        res.error = true
        res.summary = {
          group_id: res.input instanceof Array ? null : res.input.groupId,
        }
      }
      return { results: res, requestId }
    },
    error => {
      throw error.body
    }
  )
}

// -----------------------------------------------------------------------
// lookForUser
// query stephane for install data
// -----------------------------------------------------------------------
export const lookForUser = (
  appId: number,
  query: string,
  kind: string = 'di'
): Promise<List<DebugInstallRecord>> => {
  let params: { [string]: string, ... } = {}
  params[kind] = query
  return request.post(`/api/app/${appId}/data/user/search`, params).then(
    response => {
      try {
        let data = _get(response, 'body.results', [])
        let clean: List<DebugInstallRecord> = new Immutable.List()
        data.forEach(d => {
          let user = DebugInstallFactory()
          let keys = Object.keys(d)
          // Use a custom sorting function that removes date(), to keep alphabetical ordering
          keys = _sortBy(keys, [
            key => {
              if (key.startsWith('date(')) {
                return key.substring(5)
              }
              return key
            },
          ])
          keys.forEach(key => {
            let value = d[key]
            if (key.substring(0, 6) !== 'count(') {
              switch (key) {
                case 'b.state':
                case 'wpp_auth':
                case 'wpp_p256dh':
                case 'production':
                  break
                case 'di':
                  user = user.set('installationId', value)
                  break
                case 'bundle_id':
                  user = user.set('attributes', user.attributes.set('b.bundle_id', value))
                  break
                case 'api_key':
                  user = user.set('apiKey', value)
                  break
                case 'custom_id':
                  user = user.set('customId', value)
                  break
                case 'platform':
                case 'timezone':
                  user = user.set(key, value)
                  break
                case 'push_token':
                  user = user.set(
                    'device',
                    TestDeviceFactory({
                      kind: 'token',
                      value:
                        _get(d, 'wpp_auth', false) && _get(d, 'wpp_p256dh', false)
                          ? JSON.stringify({
                              endpoint: value,
                              keys: {
                                p256dh: d.wpp_p256dh,
                                auth: d.wpp_auth,
                              },
                            })
                          : value,
                      distribution: _get(d, 'production', false),
                    })
                  )
                  break
                case 'b.install_date':
                case 'last_inapp_display':
                case 'b.last_push_date':
                case 'b.last_visit_date':
                  if (value) {
                    user = user.set(
                      'attributes',
                      user.attributes.set(
                        key === 'last_inapp_display' ? 'b.last_inapp_display' : key,
                        dayjs.unix(value).utc()
                      )
                    )
                  }
                  break
                case 'notification_type':
                  if (value !== null) {
                    user = user.set('notificationStatus', parseNotifToString(value))
                  }
                  break
                case 'prettifiedValues':
                  user = user.set('prettifiedValues', Immutable.Map(value))
                  break
                case 'b.city_code':
                  user = user.set(
                    'attributes',
                    user.attributes.set('b.city_code', value === 0 ? null : value)
                  )
                  break
                default: {
                  let cleanKey = key
                  if (key.startsWith('date(')) {
                    cleanKey = cleanKey.replace('date(', '').replace(')', '')
                    user = user.set(
                      'attributes',
                      user.attributes.set(cleanKey, replaceDate(key, value))
                    )
                  } else {
                    if (cleanKey.substring(0, 2) === 'e.') {
                      value = replaceDate(cleanKey, value)
                    }
                    user = user.set('attributes', user.attributes.set(cleanKey, value))
                  }
                }
              }
            }
          })
          clean = clean.push(user)
        })
        return clean
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    error => {
      throw _get(error, 'errors[0].message', 'We were unable to process your request.')
    }
  )
}
