// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'

type FormTypes = {
  kind?: 'capture' | 'filter',
  size?: 'normal' | 'small',
  horizontal?: boolean,
  children: ReactNode,
  onSubmit?: () => any,
  ...
}

const Form = ({
  kind = 'capture',
  size = 'normal',
  horizontal = false,
  children,
  onSubmit,
  ...rest
}: FormTypes): React.Node => {
  const onSubmitHandler = React.useCallback(
    evt => {
      evt.preventDefault()
      if (typeof onSubmit === 'function') onSubmit()
    },
    [onSubmit]
  )
  return (
    <ThemeProvider theme={{ kind, size, horizontal }}>
      <form {...rest} noValidate onSubmit={onSubmitHandler}>
        {children}
      </form>
    </ThemeProvider>
  )
}

export { Form }
