// @flow
import Immutable, {
  type RecordFactory,
  type RecordOf,
  type List,
  type Map,
  type Set,
  type OrderedSet,
} from 'immutable' // eslint-disable-line

import { type AttributeRecord, AttributeFactory } from 'com.batch.redux/_records'

type QbType =
  | 'AGE'
  | 'BOOLEAN'
  | 'DATE'
  | 'EVENT'
  | 'FLOAT'
  | 'INTEGER'
  | 'POSITION'
  | 'RETARGETING'
  | 'SET'
  | 'STRING'
  | 'TAG'
  | 'VERSION'

type QbFuncVariantProps = {
  regex: RegExp,
  api: string,
  args: OrderedSet<string>,
}

export const QbFuncVariantFactory: RecordFactory<QbFuncVariantProps> = Immutable.Record(
  ({
    regex: /^[A-Z_]+$/,
    api: '',
    args: Immutable.OrderedSet(),
  }: QbFuncVariantProps)
)

export type QbFuncVariantRecord = RecordOf<QbFuncVariantProps>

type QbFuncProps = {
  label: string,
  value: string,
  returns: QbType,
  for: Set<QbType>,
  variants: List<QbFuncVariantRecord>,
  ...
}

export const QbFuncFactory: RecordFactory<QbFuncProps> = Immutable.Record(
  ({
    label: '',
    value: '',
    returns: 'STRING',
    for: Immutable.Set(),
    variants: new Immutable.List(),
  }: QbFuncProps)
)

export type QbFuncRecord = RecordOf<QbFuncProps>

type QbOperatorProps = {
  value: string,
  api: string,
  for: Set<QbType>,
  ...
}
export const QbOperatorFactory: RecordFactory<QbOperatorProps> = Immutable.Record(
  ({
    value: '',
    api: '',
    for: Immutable.Set(),
  }: QbOperatorProps)
)

export type QbOperatorRecord = RecordOf<QbOperatorProps>

type QbConditionProps = {
  operator: QbOperatorRecord,
  func: ?QbFuncRecord,
  args: Map<string, string | number>,
  attribute: AttributeRecord,
  valid: boolean,
  negate: boolean,
  value: any,
  isLogical: false,
}
export const QbConditionFactory: RecordFactory<QbConditionProps> = Immutable.Record(
  ({
    operator: QbOperatorFactory(),
    func: null,
    args: Immutable.Map(),
    attribute: AttributeFactory(),
    valid: false,
    negate: false,
    value: null,
    isLogical: false,
  }: QbConditionProps)
)

export type QbConditionRecord = RecordOf<QbConditionProps>

type QbLogicalProps = {
  children: List<QbConditionRecord | QbLogicalRecord>,
  kind: 'and' | 'or',
  isLogical: true,
}

export const QbLogicalFactory: RecordFactory<QbLogicalProps> = Immutable.Record(
  ({
    children: new Immutable.List(),
    kind: 'and',
    isLogical: true,
  }: QbLogicalProps)
)

export type QbLogicalRecord = RecordOf<QbLogicalProps>
