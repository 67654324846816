// @flow

export type Font = {
  font: string,
  size: string,
  lineHeight: string,
  family: string,
  weight: string,
  ...
}

const batchDefaultFonts =
  "-apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Helvetica, Arial, serif"

/*
 * Aussi appelé t-base sur figma
 */
export const base: Font = {
  font: `400 14px/20px ${batchDefaultFonts}`,
  size: '14px',
  lineHeight: '20px',
  family: batchDefaultFonts,
  weight: '400',
}
export const label: Font = {
  font: `500 14px/20px ${batchDefaultFonts}`,
  size: '14px',
  lineHeight: '20px',
  family: batchDefaultFonts,
  weight: '500',
}
export const code = {
  font: `500 14px/20px ${batchDefaultFonts}`,
  size: '14px',
  lineHeight: '20px',
  family: "'SF Mono', SFMono-Regular, ui-monospace, 'DejaVu Sans Mono', Menlo, Consolas, monospace",
  weight: '500',
}

export const h1 = {
  font: `400 28px/36px ${batchDefaultFonts}`,
  size: '28px',
  lineHeight: '36px',
  family: batchDefaultFonts,
  weight: '400',
}
export const h2 = {
  font: `500 16px/20px ${batchDefaultFonts}`,
  size: '16px',
  lineHeight: '20px',
  family: batchDefaultFonts,
  weight: '500',
}
export const metric = {
  font: `400 24px/32px ${batchDefaultFonts}`,
  size: '24px',
  lineHeight: '32px',
  family: batchDefaultFonts,
  weight: '400',
}
export const metricXl = {
  font: `300 40px/44px ${batchDefaultFonts}`,
  size: '40px',
  lineHeight: '44px',
  family: batchDefaultFonts,
  weight: '300',
}
export const legend = {
  font: `500 12px/20px ${batchDefaultFonts}`,
  size: '12px',
  lineHeight: '20px',
  family: batchDefaultFonts,
  weight: '500',
}
