// @flow

import * as React from 'react'

const HighlightMatch = ({ value, match }: { value: string, match: string, ... }): React.Node => {
  let start = 0
  let end = 0
  let matched = false
  if (match) {
    start = value.toLowerCase().indexOf(match.toLowerCase())
    if (start !== -1) {
      matched = true
      end = start + match.length
    }
  }
  if (matched) {
    return (
      <React.Fragment>
        {value.substring(0, start)}
        <em>{value.substring(start, end)}</em>
        {value.substring(end)}
      </React.Fragment>
    )
  } else {
  }
  return value
}

export default HighlightMatch
