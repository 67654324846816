// @flow

// ====================== LIBS
import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'

// ====================== DATA
import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import {
  ProjectStateFactory,
  type ProjectStateRecord,
  type AppFilterRecord,
  type State,
} from './console.records'

import {
  type ReduxAction,
  type AppRecord,
  type Dispatch,
  type DispatchOnlyBoundFn,
} from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { normalizeApp } from 'com.batch.redux/app.api'
import { normalizeProject } from 'com.batch.redux/project.api'
import { ProjectFactory, type ProjectRecord } from 'com.batch.redux/project.records'

const ProjectStateSelector = (state: State) => state.project
export const projectsSelector: State => List<ProjectRecord> = createSelector(
  ProjectStateSelector,
  (projectState: ProjectStateRecord) => {
    const idsList = projectState.idsPerPage.get(projectState.page, new Immutable.List())
    return idsList.map(id => projectState.entities.get(id, ProjectFactory()))
  }
)

type SetPageAction = ReduxAction<'SET_PROJECTS_PAGE', { page: number, nbPerPage: number, ... }>
export const setPage = (page: number, nbPerPage: number = 10): SetPageAction => {
  return {
    type: 'SET_PROJECTS_PAGE',
    payload: { page, nbPerPage },
  }
}

type SetFilterAction = ReduxAction<'SET_PROJECTS_FILTER', AppFilterRecord>
export const setFilter = (filters: AppFilterRecord): SetFilterAction => {
  return {
    type: 'SET_PROJECTS_FILTER',
    payload: filters,
  }
}

type LoadProjectsAction = ReduxAction<'FETCH_PROJECTS', null>
export const loadProjects = (): LoadProjectsAction => {
  return {
    type: 'FETCH_PROJECTS',
    payload: null,
  }
}

export type LoadProjectsSuccessAction = ReduxAction<
  'FETCH_PROJECTS_SUCCESS',
  {
    page: number,
    entities: List<ProjectRecord>,
    count: number,
    nbPerPage: number,
    apps: Map<number, AppRecord>,
    ...
  },
>
export const loadProjectsSuccess = (payload: {
  page: number,
  entities: List<ProjectRecord>,
  apps: Map<number, AppRecord>,
  count: number,
  nbPerPage: number,
  ...
}): LoadProjectsSuccessAction => {
  return {
    type: 'FETCH_PROJECTS_SUCCESS',
    payload,
  }
}
type FetchProjectAction = ReduxAction<'FETCH_PROJECT', null>
type FetchProjectSuccessAction = ReduxAction<
  'FETCH_PROJECT_SUCCESS',
  { project: ProjectRecord, apps: List<AppRecord>, ... },
>
type FetchProjectFailureAction = ReduxAction<'FETCH_PROJECT_FAILURE', null>

export const fetchProject =
  (
    projectKey: string
  ): DispatchOnlyBoundFn<Promise<{ project: ProjectRecord, apps: List<AppRecord> }>> =>
  (dispatch: Dispatch) =>
    promiseActionCreator({
      actionName: 'FETCH_PROJECT',
      dispatch,
      promise: request.get(generateUrl('console_api_project', { projectKey })).then(raw => {
        const project = normalizeProject(raw)
        const apps = new Immutable.List<AppRecord>().push(...raw.apps.map(normalizeApp))
        apps.forEach(app => {
          dispatch({ type: 'FETCH_APP_SUCCESS', payload: app })
        })
        return { project, apps }
      }),
      payload: null,
    })
type SaveProjectAction = ReduxAction<'SAVE_PROJECT', null>
type SaveProjectSuccessAction = ReduxAction<'SAVE_PROJECT_SUCCESS', ProjectRecord>
type SaveProjectFailureAction = ReduxAction<'SAVE_PROJECT_FAILURE', string>

export const saveProject =
  (project: ProjectRecord, appIds: Array<number>): DispatchOnlyBoundFn<Promise<ProjectRecord>> =>
  (dispatch: Dispatch) =>
    promiseActionCreator({
      actionName: 'SAVE_PROJECT',
      dispatch,
      promise: request
        .post(generateUrl('console_api_project_save'), { project, appIds })
        .then(normalizeProject, err => {
          throw err?.error?.errors?.[0]?.message ?? 'Unknown error'
        }),
      payload: null,
    })

type LoadProjectsFailureAction = ReduxAction<'FETCH_PROJECTS_FAILURE', string>
export const loadProjectsFailure = (err: string): LoadProjectsFailureAction => {
  return {
    type: 'FETCH_PROJECTS_FAILURE',
    payload: err,
  }
}

type alloweProjectActions =
  | SetPageAction
  | LoadProjectsAction
  | LoadProjectsSuccessAction
  | LoadProjectsFailureAction
  | SetFilterAction
  | FetchProjectAction
  | FetchProjectSuccessAction
  | FetchProjectFailureAction
  | SaveProjectAction
  | SaveProjectSuccessAction
  | SaveProjectFailureAction
export const projectReducer = (
  state: ProjectStateRecord = ProjectStateFactory(),
  action: alloweProjectActions
): ProjectStateRecord => {
  switch (action.type) {
    case 'SAVE_PROJECT_SUCCESS':
      return state.setIn(['entities', action.payload.projectKey], action.payload)
    case 'SET_PROJECTS_FILTER':
      return state.set('filters', action.payload).set('page', 1).set('idsPerPage', Immutable.Map())
    case 'SET_PROJECTS_PAGE':
      return state.set('page', action.payload.page).set('nbPerPage', action.payload.nbPerPage)
    case 'FETCH_PROJECT':
    case 'FETCH_PROJECTS':
      return state.set('loading', true)
    case 'FETCH_PROJECT_SUCCESS':
      return state
        .setIn(['entities', action.payload.project.projectKey], action.payload.project)
        .set('loading', false)
    case 'FETCH_PROJECTS_SUCCESS':
      return state
        .set(
          'entities',
          state.entities.merge(
            action.payload.entities.map(project => [project.projectKey, project])
          )
        )
        .set(
          'idsPerPage',
          state.idsPerPage.set(
            action.payload.page,
            action.payload.entities.map(p => p.projectKey)
          )
        )
        .set('page', action.payload.page)
        .set('count', action.payload.count)
        .set('loading', false)
        .set('nbPerPage', action.payload.nbPerPage)
    default:
      return state
  }
}
