// @flow

import * as React from 'react'

import { EmptyField } from 'components/common/empty-states'

import { randomSize } from 'com.batch.common/utils'

import {
  EventRowDetails,
  EventRowContentInformation,
  EventRowContentSkeleton,
} from '../event-row/event-row.styles'
import { Timeline } from '../timeline/timeline'

export const EventRowSkeleton = (): React.Node => {
  const randomSizeMemo = React.useMemo(() => randomSize(150, 200), [])

  return (
    <EventRowContentSkeleton style={{ gap: '14px', alignItems: 'center' }}>
      <EmptyField _width={30} _height={14} />
      <Timeline />
      <EventRowContentInformation style={{ height: '36px' }}>
        <EventRowDetails $isToggable style={{ gap: 10 }}>
          <EmptyField _width={randomSizeMemo} _height={14} />
        </EventRowDetails>
      </EventRowContentInformation>
    </EventRowContentSkeleton>
  )
}
