// @noflow
import Immutable from 'immutable'

import * as Types from 'com.batch.common/legacy-query-types'

export default Immutable.fromJS([
  {
    label: 'Time',
    value: 'age',
    returns: Types.AGE,
    for: [Types.DATE],
    variants: [
      {
        regex: /age\(([0-9a-zA-Z_.]{1,50})\)/,
        api: 'age(__ATTR__)',
        args: ['__ATTR__'],
      },
    ],
  },
  {
    label: 'Date',
    value: 'date',
    for: [Types.DATE],
    returns: Types.DATE,
    variants: [
      {
        api: 'date(__ATTR__)',
        regex: /date\(([0-9a-zA-Z_.]{1,50})\)/,
        args: ['__ATTR__'],
      },
    ],
  },
  {
    label: 'Next anniversary',
    value: 'nextBirthday',
    for: [Types.DATE],
    returns: Types.AGE,
    variants: [
      {
        regex: /nextBirthday\(([0-9a-zA-Z_.]{1,50})\)/,
        api: 'nextBirthday(__ATTR__)',
        args: ['__ATTR__'],
      },
    ],
  },
  {
    label: 'Last anniversary',
    value: 'lastBirthday',
    for: [Types.DATE],
    returns: Types.AGE,
    variants: [
      {
        regex: /lastBirthday\(([0-9a-zA-Z_.]{1,50})\)/,
        api: 'lastBirthday(__ATTR__)',
        args: ['__ATTR__'],
      },
    ],
  },
  {
    label: 'isNear',
    value: 'isNear',
    id: 'isNear',
    for: [Types.POSITION],
    variants: [
      {
        api: 'isNear(lat:__LAT__, lng:__LNG__, radius:__RADIUS__, expiration:__MAXAGE__)',
        regex:
          /isNear\(lat:\s*(-?\d*[.(?=\d)]?\d*)\s*,\s*lng:\s*(-?\d*[.(?=\d)]?\d*),\s*radius:\s*(\d*)k?m?\s*,\s*expiration:\s*(\d*[hd]?)\s*\)/,
        args: ['__LAT__', '__LNG__', '__RADIUS__', '__MAXAGE__'],
      },
      {
        api: 'isNear(lat:__LAT__, lng:__LNG__, radius:__RADIUS__, expiration:__MAXAGE__)',
        regex: /isNear\((-?\d*[.(?=\d)]?\d*),(-?\d*[.(?=\d)]?\d*),(\d*)k?m?,(\d*[hd]?)\)/,
        args: ['__LAT__', '__LNG__', '__RADIUS__', '__MAXAGE__'],
      },
      {
        api: 'isNear(lat:__LAT__, lng:__LNG__, radius:__RADIUS__)',
        regex:
          /isNear\(lat:\s*(-?\d*[.(?=\d)]?\d*),\s*lng:\s*(-?\d*[.(?=\d)]?\d*)\s*,\s*radius:(\d*)m?\)/,
        args: ['__LAT__', '__LNG__', '__RADIUS__'],
      },
      {
        api: 'isNear(lat:__LAT__, lng:__LNG__, radius:__RADIUS__)',
        regex: /isNear\((-?\d*[.(?=\d)]?\d*),(-?\d*[.(?=\d)]?\d*),(\d*)m?\)/,
        args: ['__LAT__', '__LNG__', '__RADIUS__'],
      },
    ],
  },
  {
    label: 'Collection',
    value: 'content',
    for: [Types.TAG],
    returns: Types.TAG,
    variants: [
      {
        api: '__ATTR__',
        regex: /^(u{0,1}t.[0-9a-zA-Z_]{1,50})/,
        args: ['__ATTR__'],
      },
    ],
  },
  {
    label: 'Count',
    value: 'countForTag',
    for: [Types.TAG],
    returns: Types.INTEGER,
    variants: [
      {
        api: 'count(__ATTR__)',
        regex: /^count\((u{0,1}t.[0-9a-zA-Z_]{1,50})\)/,
        args: ['__ATTR__'],
      },
    ],
  },
  {
    label: '',
    value: 'lastCampaign',
    for: [Types.RETARGETING],
    returns: Types.RETARGETING,
    variants: [
      {
        api: "last(__ATTR__, campaign:'__TOKEN__')",
        regex: /last\((be.sent|be.opened|be.displayed)\s*,\s*campaign:\s*'([0-9a-z]{32})'\s*\)/,
        args: ['__ATTR__', '__TOKEN__'],
      },
      {
        api: "last(__ATTR__, campaign:'__TOKEN__')",
        regex: /last\((be.sent|be.opened|be.displayed)\s*,\s*'([0-9a-z]{32})'\s*\)/,
        args: ['__ATTR__', '__TOKEN__'],
      },
    ],
  },
  {
    label: 'Last event',
    value: 'ageLastEvent',
    for: [Types.EVENT],
    returns: Types.AGE,
    variants: [
      {
        api: 'age(last(__ATTR__))',
        regex: /age\(last\((e.[0-9a-zA-Z_]{1,50})\)\)/,
        args: ['__ATTR__'],
      },
      {
        api: "age(last(__ATTR__, label:'__LABEL__'))",
        regex: /age\(last\((e.[0-9a-zA-Z_]{1,50})\s*,\s*label\s*:\s*'(.{1,200})'\)\)/,
        args: ['__ATTR__', '__LABEL__'],
      },
      {
        api: "age(last(__ATTR__, tag:'__TAG__'))",
        regex: /age\(last\((e.[0-9a-zA-Z_]{1,50})\s*,\s*tag\s*:\s*'(.{1,90})'\)\)/,
        args: ['__ATTR__', '__TAG__'],
      },
      {
        api: "age(last(__ATTR__, data:'__KEY__', value:__VALUE__))",
        regex:
          /age\(last\((e.[0-9a-zA-Z_]{1,50})\s*,\s*data\s*:\s*'([0-9a-zA-Z_]{1,90})'\s*,\s*value\s*:\s*(.{1,90})\s*\)\)/,
        args: ['__ATTR__', '__KEY__', '__VALUE__'],
      },
      {
        api: "age(last(__ATTR__, label:'__LABEL__'))",
        regex: /age\(lastForLabel\((e.[0-9a-zA-Z_]{1,50}),\s?'(.{1,200})'\)\)/,
        args: ['__ATTR__', '__LABEL__'],
      },
    ],
  },
  {
    label: 'Count',
    value: 'count',
    for: [Types.EVENT],
    returns: Types.INTEGER,
    variants: [
      {
        api: 'count(__ATTR__)',
        regex: /count\((e.[0-9a-zA-Z_]{1,50})\)/s,
        args: ['__ATTR__'],
      },
      {
        api: "count(__ATTR__, label:'__LABEL__')",
        regex: /count\((e.[0-9a-zA-Z_]{1,50})\s*,\s*label:\s*'(.{1,200})'\s*\)/s,
        args: ['__ATTR__', '__LABEL__'],
      },
      {
        api: "count(__ATTR__, tag:'__TAG__')",
        regex: /count\((e.[0-9a-zA-Z_]{1,50})\s*,\s*tag:\s*'(.{1,90})'\s*\)/s,
        args: ['__ATTR__', '__TAG__'],
      },
      {
        api: "count(__ATTR__, data:'__KEY__', value:__VALUE__)",
        regex:
          /count\((e.[0-9a-zA-Z_]{1,50})\s*,\s*data\s*:\s*'([0-9a-zA-Z_]{1,90})'\s*,\s*value\s*:\s*(.{1,90})\s*\)/s,
        args: ['__ATTR__', '__KEY__', '__VALUE__'],
      },
      {
        api: "countForLabel(__ATTR__, label:'__LABEL__')",
        regex: /countForLabel\((e.[0-9a-zA-Z_]{1,50}),\s*'(.{1,200})'\)/s,
        args: ['__ATTR__', '__LABEL__'],
      },
    ],
  },
  {
    label: 'Count since',
    value: 'countSince',
    for: [Types.EVENT],
    returns: Types.INTEGER,
    variants: [
      {
        api: 'count(__ATTR__, period:__PERIOD__)',
        regex: /count\((e.[0-9a-zA-Z_]{1,50})\s*,\s*period:\s*([0-9dh]{2,6})\s*\)/s,
        args: ['__ATTR__', '__PERIOD__'],
      },
      {
        api: 'countForLastPeriod(__ATTR__, period:__PERIOD__)',
        regex: /countForLastPeriod\((e.[0-9a-zA-Z_]{1,50}),\s?([0-9dh]{2,6})\s*\)/s,
        args: ['__ATTR__', '__PERIOD__'],
      },
      {
        api: "count(__ATTR__, label:'__LABEL__', period:__PERIOD__)",
        regex:
          /count\((e.[0-9a-zA-Z_]{1,50})\s*,\s*label:\s*'(.{1,200})'\s*,\s*period:\s*([0-9dh]{2,6})\s*\)/s,
        args: ['__ATTR__', '__LABEL__', '__PERIOD__'],
      },
      {
        api: "count(__ATTR__, tag:'__TAG__', period:__PERIOD__)",
        regex:
          /count\((e.[0-9a-zA-Z_]{1,50})\s*,\s*tag:\s*'(.{1,90})'\s*,\s*period:\s*([0-9dh]{2,6})\s*\)/s,
        args: ['__ATTR__', '__TAG__', '__PERIOD__'],
      },
      {
        api: "count(__ATTR__, data:'__KEY__', value:__VALUE__, period:__PERIOD__)",
        regex:
          /count\((e.[0-9a-zA-Z_]{1,50})\s*,\s*data\s*:\s*'([0-9a-zA-Z_]{1,90})'\s*,\s*value\s*:\s*(.{1,90})\s*,\s*period:\s*([0-9dh]{2,6})\s*\)/s,
        args: ['__ATTR__', '__KEY__', '__VALUE__', '__PERIOD__'],
      },
      {
        api: "countForLastPeriodAndLabel(__ATTR__, period:'__PERIOD__', label:'__LABEL__')",
        regex:
          /countForLastPeriodAndLabel\((e.[0-9a-zA-Z_]{1,50}),\s?'([0-9dh]{2,6})',\s?'(.{1,200})'\)/s,
        args: ['__ATTR__', '__PERIOD__', '__LABEL__'],
      },
    ],
  },
  // {
  //   label: 'First event',
  //   value: 'ageFirstEvent',
  //   for: [Types.EVENT],
  //   returns: Types.AGE,
  //   variants: [
  //     {
  //       api: 'age(first(__ATTR__))',
  //       regex: /age\(first\((e.[a-zA-Z_]{1,50})\)\)/,
  //       args: ['__ATTR__']
  //     },
  //     {
  //       api: 'age(firstForLabel(__ATTR__,\'__LABEL__\'))',
  //       regex: /age\(firstForLabel\((e.[a-zA-Z_]{1,50}),\s?'(.{1,60})'\)\)/,
  //       args: ['__ATTR__', '__LABEL__']
  //     }
  //   ]
  // }
])
