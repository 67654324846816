// @flow

import Immutable, { type Map } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import { Condition } from 'components/targeting/condition'

import { createOnKeyDownHandler } from 'com.batch/shared/ui/create-handler-onkeydown'

type Props = {
  toggleLogical: (id: any) => void,
  canUseEventCountPeriod: boolean,
  canQueryEventData: boolean,
  app: any,
  platform: string,
  ttlRetargeting: number,
  level?: number,
  logical: string,
  updateCondition: () => void,
  attributesValues: any,
  attributesValuesLoading: any,
  createAttributeValue: (a: string, b: string, c?: string) => void,
  deleteCondition: () => void,
  toggleCondition: () => void,
  root: Map<string, any>,
  openPicker: (id: any) => void,
  addLogical: (id: any) => void,
  deleteLogical: (id: any) => void,
}

export const Logical = ({
  canUseEventCountPeriod,
  canQueryEventData,
  app,
  platform,
  ttlRetargeting,
  level = 1,
  logical,
  updateCondition,
  toggleLogical,
  attributesValues,
  attributesValuesLoading,
  createAttributeValue,
  deleteCondition,
  toggleCondition,
  root,
  openPicker,
  addLogical,
  deleteLogical,
}: Props): React.Node => {
  const buttonRef = React.useRef<?HTMLElement>(null)

  const handleOpenPicker = React.useCallback(() => {
    openPicker(root.get('id'))
  }, [openPicker, root])

  const handleAddLogical = React.useCallback(() => {
    addLogical(root.get('id'))
    buttonRef.current?.blur()
  }, [addLogical, root])

  const handleDeleteLogical = React.useCallback(() => {
    deleteLogical(root.get('id'))
  }, [deleteLogical, root])

  const handleToggleLogical = React.useCallback(() => {
    toggleLogical(root.get('id'))
  }, [toggleLogical, root])

  const child = root.get('child')
  let previousWasLogical = false

  return (
    <div className="tg">
      <div className="tg__conditionsWrap">
        <div className="tg__conditionsWrap__conditions">
          {child?.map(row => {
            const content = row.get('value')
            if (row.get('kind') === 'logical') {
              const charId = row.get('id').charCodeAt(0)
              const schemeId = charId % 7
              previousWasLogical = true
              return (
                <div className={`tg_logicalAndStruf logicalScheme-${schemeId}`} key={row.get('id')}>
                  {child.last() !== row && (
                    <div
                      className="tg_logicalAndStruf__operator condi__logical condi__logical--bottom"
                      onClick={handleToggleLogical}
                      onKeyDown={createOnKeyDownHandler(handleToggleLogical)}
                      role="button"
                      tabIndex={0}
                    >
                      {logical}
                    </div>
                  )}
                  <div className="tg_logicalAndStruf__stuff">
                    <Logical
                      root={row}
                      app={app}
                      canQueryEventData={canQueryEventData}
                      platform={platform}
                      logical={content}
                      level={level + 1}
                      ttlRetargeting={ttlRetargeting}
                      openPicker={openPicker}
                      deleteLogical={deleteLogical}
                      toggleCondition={toggleCondition}
                      addLogical={addLogical}
                      toggleLogical={toggleLogical}
                      updateCondition={updateCondition}
                      deleteCondition={deleteCondition}
                      attributesValues={attributesValues}
                      attributesValuesLoading={attributesValuesLoading}
                      createAttributeValue={createAttributeValue}
                      canUseEventCountPeriod={canUseEventCountPeriod}
                    />
                  </div>
                </div>
              )
            } else {
              const cond = (
                <div className="tg_logicalAndStruf" key={row.get('id')}>
                  {child.last() !== row && (
                    <div
                      className="tg_logicalAndStruf__operator condi__logical condi__logical--bottom"
                      onClick={handleToggleLogical}
                      onKeyDown={createOnKeyDownHandler(handleToggleLogical)}
                      role="button"
                      tabIndex={0}
                    >
                      {logical}
                    </div>
                  )}
                  <div className="tg_logicalAndStruf__stuff">
                    <Condition
                      app={app}
                      ttlRetargeting={ttlRetargeting}
                      platform={platform}
                      canQueryEventData={canQueryEventData}
                      canUseEventCountPeriod={canUseEventCountPeriod}
                      previousWasLogical={previousWasLogical}
                      id={row.get('id')}
                      attribute={content.get('attribute')}
                      args={content.get('args')}
                      value={
                        Immutable.isImmutable(content.get('value')) &&
                        content.getIn(['attribute', 'id']).substr(0, 3) !== 'bt.'
                          ? content.get('value').toJS()
                          : content.get('value', '')
                      }
                      operator={content.get('operator') ? content.get('operator').toJS() : null}
                      operators={content.get('operators') ? content.get('operators').toJS() : []}
                      func={content.get('func') ? content.get('func').toJS() : null}
                      inputType={content.get('inputType', 'text')}
                      valid={content.get('valid', false)}
                      functions={content.get('functions') ? content.get('functions').toJS() : []}
                      negate={content.get('negate')}
                      update={updateCondition}
                      toggle={toggleCondition}
                      remove={deleteCondition}
                      createAttributeValue={createAttributeValue}
                      loading={
                        !!attributesValuesLoading.get(row.getIn(['value', 'attribute', 'id']))
                      }
                      attributeValues={attributesValues
                        .get(row.getIn(['value', 'attribute', 'id']))
                        .toJS()}
                    />
                  </div>
                </div>
              )
              previousWasLogical = false
              return cond
            }
          })}
        </div>

        <div className="tg__conditionsWrap--actions">
          <FlexLine>
            <FlexLineItem grow={1}>
              <Button
                kind="primary"
                intent="action"
                addOn="prefix"
                addOnGap={6}
                height={24}
                onClick={handleOpenPicker}
              >
                <Icon icon="add" />
                <span style={{ marginBottom: 1 }}>Add conditions</span>
              </Button>
            </FlexLineItem>
            <FlexLineItem style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                height={24}
                kind="inline"
                addOn="prefix"
                addOnGap={6}
                disabled={level > 3}
                ref={buttonRef}
                onClick={handleAddLogical}
              >
                <Icon icon="add" />
                <span style={{ marginBottom: 1 }}>New and/or subgroup</span>
              </Button>

              <Button
                style={{ marginLeft: 8 }}
                onClick={handleDeleteLogical}
                disabled={root.get('id') === 'root'}
                kind="discreet"
                intent="danger"
              >
                <Icon icon="delete" />
              </Button>
            </FlexLineItem>
          </FlexLine>
        </div>
      </div>
    </div>
  )
}
