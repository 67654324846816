// @flow

import * as React from 'react'

import { TableTemplateCell } from 'components/common/table'

import { randomSize } from 'com.batch.common/utils'

const GdprListEmptyTemplateRaw = () => {
  return (
    <React.Fragment>
      <TableTemplateCell template="15px" />
      <TableTemplateCell template={`${randomSize(50, 90)}%`} />
      <TableTemplateCell template="71px" />
      <TableTemplateCell template={`${randomSize(15, 25)}% ${randomSize(40, 60)}%`} gap={9} />
      <TableTemplateCell template="168px" />
    </React.Fragment>
  )
}
export const GdprListEmptyTemplate: React.ComponentType<{ ... }> =
  React.memo(GdprListEmptyTemplateRaw)
