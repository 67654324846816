// @flow

import styled, { css, type StyledComponent } from 'styled-components'

type OverlayContainerProps = {
  align?: 'flex-start' | 'center' | 'flex-end',
  isPageWrapper?: boolean,
  ...
}
export const OverlayContainer: StyledComponent<OverlayContainerProps, { ... }, HTMLElement> =
  styled.div`
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    background: ${(props: OverlayContainerProps) =>
      props.isPageWrapper ? 'rgba(248, 248, 248, 0.95)' : 'rgba(255, 255, 255, 0.94)'};
    ${(props: OverlayContainerProps) =>
      props.isPageWrapper &&
      css`
        padding-top: 80px;
      `}
    align-items: ${(p: OverlayContainerProps) => p.align ?? 'center'};
    z-index: 12;

    @media (max-width: 700px) {
      width: calc(100% + 64px);
      left: -32px;
    }
  `
type WrapperContainerProps = { isOverlayShown: boolean, isPageWrapper: boolean, ... }
export const WrapperContainer: StyledComponent<WrapperContainerProps, *, HTMLElement> = styled.div`
  position: relative;
  ${(props: WrapperContainerProps) =>
    props.isPageWrapper
      ? css`
          ${props.isOverlayShown &&
          css`
            min-height: 530px;
            width: 100%;
          `}
          > ${OverlayContainer} {
            align-items: flex-start;
          }
        `
      : css`
          ${props.isOverlayShown &&
          css`
            width: 100%;
            border-radius: 3px;
            overflow: hidden;
          `}
          > ${OverlayContainer} {
            border-radius: 4px;
            align-items: center;
          }
        `}
`
