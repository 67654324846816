// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const TokenCellsSize = [50, 60, 40, 60, 30, 50, 60, 70, 40, 50]
type UploadAudiencesContainerProps = { side: 'right' | 'left', hasFile?: boolean, ... }
export const UploadAudiencesContainer: StyledComponent<
  UploadAudiencesContainerProps,
  { ... },
  HTMLElement,
> = styled.div`
  height: 440px;
  ${(props: UploadAudiencesContainerProps) =>
    props.side === 'right'
      ? css`
          display: flex;
          justify-content: center;
          padding: ${(p: any) => (p.hasFile ? '53px 22px 40px 22px' : '22px')};
          background-color: ${schemes.grayscale['01']};
          border-left: 1px solid ${colors.stroke};
        `
      : css`
          padding: 29px 22px;
        `}
`

export const PreviewAudiences: StyledComponent<{ ... }, { ... }, HTMLElement> = styled.div`
  > div {
    width: 100%;
    height: 36px;
    border-top: 1px solid ${schemes.darklucent['05']};

    > div:first-of-type {
      text-align: center;
      color: ${colors.textLight};
      font-weight: 500;
    }
  }
`
type TokensIdsTooltipProps = { isTokens?: boolean, ... }
export const TokensIdsTooltip: StyledComponent<TokensIdsTooltipProps, { ... }, HTMLElement> =
  styled.div`
    padding: 6px 7px;
    text-align: left;
    min-width: 128px;

    ${(props: TokensIdsTooltipProps) =>
      props.isTokens &&
      css`
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      `}
    > div {
      &:first-of-type {
        font-weight: 300;
        font-size: 16px;
        color: #fff;
      }

      &:last-of-type {
        font-size: 12px;
        letter-spacing: -0.01em;
        color: rgba(255, 255, 255, 0.68);
      }
    }
  `

export const EstimateContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: baseline;
`
