// @flow

import styled, { type StyledComponent, type PropsWithTheme } from 'styled-components'

import { MetricContainer } from './metric'

type VariantTitleProps = { winner: boolean, ... }
export const VariantTitle: StyledComponent<VariantTitleProps, *, HTMLElement> = styled.h4`
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.02em;

  span {
    display: inline-block;
    vertical-align: baseline;
    color: #848689;
  }
  &:after {
    display: inline-block;
    vertical-align: baseline;
    content: 'Winner';
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    background-color: #79b34d;
    padding: 6px 8px;
    border-radius: 2px;
    margin: 0px 0 0 22px;
    visibility: ${(props: VariantTitleProps) => (props.winner ? 'visible' : 'hidden')};
  }
`
type ThemeProps = { platform: string, campaignType: campaignType, ... }
type OnlyThemeProps = PropsWithTheme<{ ... }, ThemeProps>
export const VariantPreview: StyledComponent<{ ... }, ThemeProps, HTMLElement> = styled.div`
  display: block;
  flex: 2;
  max-width: ${(props: OnlyThemeProps) => (props.theme.platform === 'webpush' ? '440px' : '268px')};
  height: 100%;
`

export const VariantMetrics: StyledComponent<*, ThemeProps, HTMLElement> = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 16px 0 0 0;
  margin: 0 0 0
    ${(props: OnlyThemeProps) => (props.theme.campaignType === 'in-app' ? '21px' : '46px')};

  ${MetricContainer} {
    min-width: 120px;
    margin: 0 26px 26px 0;
  }
`

export const VariantContent: StyledComponent<{ ... }, ThemeProps, HTMLElement> = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 12px 0px 0 -10px;
  margin: ${(props: OnlyThemeProps) =>
    props.theme.platform === 'webpush' ? '12px -32px 0 -32px' : '12px 0 0 -10px'};
`

export const Variant: StyledComponent<*, ThemeProps, HTMLElement> = styled.div`
  width: 50%;
  height: ${(props: OnlyThemeProps) => {
    if (props.theme.campaignType === 'in-app') {
      return '700px'
    } else if (props.theme.platform === 'webpush') {
      return '500px'
    } else return '400px'
  }};
  padding: 34px 32px 0 32px;
  border-right: 1px solid #f3f3f3;
  overflow: hidden;

  &:last-child {
    border-right: none;
  }

  ${VariantTitle} {
    margin: 0 0 22px 0;
  }
`

export const VariantsContainer: StyledComponent<{ ... }, ThemeProps, HTMLElement> = styled.div`
  display: flex;

  @media (max-width: ${(props: OnlyThemeProps) =>
      props.theme.platform === 'webpush' ? '1280px' : '1180px'}) and (min-width: 980px),
    (max-width: 880px) {
    flex-direction: column;

    ${Variant} {
      width: 100%;
      flex: 0 0 100%;
      border-right: none;
      border-bottom: 1px solid #f3f3f3;
    }
  }
`

export const InAppStatsHeader: StyledComponent<{ ... }, { ... }, HTMLElement> = styled.div`
  margin-bottom: 4px;
  color: #707070;
`

export const InAppStatsContainer: StyledComponent<{ ... }, { ... }, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
`
