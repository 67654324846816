// @flow

import {
  type ReduxAction,
  type PushConfigRecord,
  type DispatchExtraBoundFn,
} from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentAppSelector } from 'com.batch.redux/app'
import { savePushConfig } from 'com.batch.redux/app.api'

type SavePushSettingsActions = ReduxAction<'SAVE_PUSH_SETTINGS', null>

export type SavePushSettingsSuccessAction = ReduxAction<
  'SAVE_PUSH_SETTINGS_SUCCESS',
  PushConfigRecord,
>
export type SavePushSettingsFailureAction = ReduxAction<
  'SAVE_PUSH_SETTINGS_FAILURE',
  { error: string, ... },
>

export type savePushSettingsActions =
  | SavePushSettingsActions
  | SavePushSettingsSuccessAction
  | SavePushSettingsFailureAction

export const savePushSettings = (
  pushConfig: PushConfigRecord
): DispatchExtraBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    const app = currentAppSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: savePushConfig(app, pushConfig).then(
        pushConfig => pushConfig,
        error => {
          throw error
        }
      ),
      actionName: 'SAVE_PUSH_SETTINGS',
    })
  }
}
