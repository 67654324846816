// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { Button, useBlurOnClickCallback } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import {
  NotifierMessageContainer,
  NotifierIcon,
  NotifierContent,
  NotifierClose,
  NotifierLink,
} from './notifier.styles'

import { dismissMessage } from 'com.batch.redux/toaster'
import { type ToasterRecord } from 'com.batch.redux/toaster.records'

type NotifierMessageProps = {
  msg: ToasterRecord,
  sticky: boolean,
  stop?: boolean,
  style?: { [string]: string | number, ... },
  ...
}

// ====================== CURRENT COMPONENT
export const NotifierMessage = ({
  msg,
  sticky = false,
  stop,
  style,
}: NotifierMessageProps): React.Node => {
  // ====================== Component state
  const [tick, setTick] = React.useState(6000)

  const dispatch = useDispatch()
  const dismissMe = React.useCallback(() => {
    dispatch(dismissMessage(msg))
  }, [dispatch, msg])

  const callBackDissmiss = useBlurOnClickCallback(dismissMe)

  // ====================== Use effect
  React.useEffect(() => {
    if (!stop && !sticky) {
      if (tick > 0) {
        let timer = setTimeout(() => setTick(tick - 1000), 1000)
        return () => clearTimeout(timer)
      } else {
        dismissMe()
      }
    }
  }, [dismissMe, sticky, stop, tick])

  // ====================== Render
  return (
    <ThemeProvider theme={{ kind: msg.kind, size: msg.size }}>
      <NotifierMessageContainer style={style} noTimer={false}>
        <NotifierIcon>
          <Icon icon={msg.icon} />
        </NotifierIcon>

        <NotifierContent _hasLinks={msg.links.length > 0}>
          <div className="styled-notifier-msg">
            {msg.title ? (
              <h4>{msg.title}</h4>
            ) : (
              msg.s === 'normal' && <h4>{msg.kind === 'success' ? 'Success' : 'Error'} action</h4>
            )}
            {!!msg.message && <p>{msg.message}</p>}
            {!!msg.error && <p>{msg.error}</p>}
          </div>

          {msg.links.length > 0 && (
            <div className="styled-notifier-links">
              {msg.links.map((link, i) => (
                <NotifierLink key={i} intent="action" href={link.href} target="_blank">
                  {link.name ? link.name : link.href}
                </NotifierLink>
              ))}
            </div>
          )}
        </NotifierContent>

        <NotifierClose style={{ paddingLeft: 0 }}>
          <span>
            <Button
              data-testid="dismissMessageBtn"
              onClick={callBackDissmiss}
              kind="inlineDark"
              aria-label="Close notification"
            >
              <Icon icon="close" size={10} />
            </Button>
          </span>
        </NotifierClose>
      </NotifierMessageContainer>
    </ThemeProvider>
  )
}
