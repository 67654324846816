// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { Filter } from 'components/campaign/filters/filter'
import { Button } from 'components/common/button'
import { EmptyField } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { FilterSearch } from 'components/filter'
import { Checkbox } from 'components/form'

import { generateUrl } from 'com.batch.common/router'
import { randomSize } from 'com.batch.common/utils'

import {
  type CampaignActiveFiltersRecord,
  type CappingCategoryRecord,
} from 'com.batch.redux/_records'
import { currentAppSelector, labelsSelector } from 'com.batch.redux/app'

type LabelFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord,
  onChange: any => void,
  onClear: () => void,
  ...
}

export const LabelFilter = ({
  localActiveFilters,
  onChange,
  onClear,
}: LabelFilterProps): React.Node => {
  const app = useSelector(currentAppSelector)
  const labels = useSelector(labelsSelector)
  const [filteredLabels, setFilteredLabels] = React.useState<List<CappingCategoryRecord>>(labels)
  const [labelsQuery, setLabelsQuery] = React.useState<string>('')
  const selectedLabels = React.useMemo(() => {
    return labels.filter(l => localActiveFilters.labels.toArray().includes(l.id))
  }, [labels, localActiveFilters.labels])

  React.useEffect(() => {
    setFilteredLabels(
      labels.filter(
        l => labelsQuery === '' || l.name.toLowerCase().indexOf(labelsQuery.toLowerCase()) !== -1
      )
    )
  }, [labels, labelsQuery])

  return (
    <Filter
      isEmbedded={true}
      title="Labels"
      needUpgrade={!app.features.has('label')}
      companyId={app.companyId}
      clearButton={
        selectedLabels.size > 0 && (
          <Button
            style={{ height: 28, display: 'inline-flex' }}
            kind="inline"
            addOn="prefix"
            onClick={onClear}
          >
            <Icon icon="close" />
            {`Clear ${selectedLabels.size} item${selectedLabels.size > 1 ? 's' : ''}`}
          </Button>
        )
      }
      emptyState={
        labels.size === 0
          ? {
              style: { alignItems: 'center' },
              status: 'empty',
              title: 'No labels created.',
              links: [
                {
                  name: 'Create your first one',
                  href: generateUrl('app_settings', {
                    companyId: app.companyId,
                    appId: app.id,
                    activeTab: 'labels',
                  }),
                  intent: 'action',
                },
              ],
            }
          : undefined
      }
    >
      <FilterSearch
        placeholder="Search..."
        value={labelsQuery}
        onChange={setLabelsQuery}
        style={{ marginTop: 12 }}
        expandable={false}
      />

      <div className="label-list">
        {!app.features.has('label') ? (
          Array(4)
            .fill()
            .map((_, i) => (
              <EmptyField
                key={i}
                _width={randomSize(2, 100)}
                style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}
              >
                <div className="labels"></div>
              </EmptyField>
            ))
        ) : labels.size > 0 && filteredLabels.size === 0 ? (
          <div className="no-labels">No labels is matching your search.</div>
        ) : labels.size === 0 ? (
          Array(5)
            .fill()
            .map((_, i) => (
              <EmptyField
                key={i}
                _width={randomSize(2, 100)}
                style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}
              >
                <div className="labels"></div>
              </EmptyField>
            ))
        ) : (
          filteredLabels.map(label => (
            <Checkbox
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              key={label.id}
              name={label.code}
              label={label.name}
              className="labels_checks"
              checked={selectedLabels.includes(label)}
              onChange={() => onChange(label)}
            />
          ))
        )}
      </div>
    </Filter>
  )
}
