// @flow
import { schemes, colors } from 'components/styled/tokens'

type PlatformButtonThemeType = {
  [key: string]: {
    color: {
      title: string,
      subtitle: string,
      optin: string,
      optinExpended: string,
    },
    background: {
      optin: string,
    },
  },
}

export const PLATFORM_BUTTOM_THEME: PlatformButtonThemeType = {
  native: {
    color: {
      title: schemes.grayscale['70'],
      subtitle: colors.textLight,
      optin: colors.textSuccess,
      optinExpended: colors.textSuccess,
    },
    background: {
      optin: colors.opacifySuccess,
    },
  },
  imported: {
    color: {
      title: colors.textLight,
      subtitle: colors.textLight,
      optin: colors.textDisabled,
      optinExpended: colors.textNeutral,
    },
    background: {
      optin: schemes.darklucent['10'],
    },
  },
}
