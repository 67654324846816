// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { Text } from 'components/authentication/auth.styles'
import { getLoginMethod, getSSOUrl } from 'components/authentication/login/api'
import { type LoginMethod } from 'components/authentication/login/login'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button, ButtonLink } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Form } from 'components/form'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { currentUserSelector } from 'com.batch.redux/user.selector'

export const RefreshAuthProvider = (): React.Node => {
  const user = useSelector(currentUserSelector)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [open, setOpen] = React.useState<boolean>(false)
  React.useEffect(() => {
    const interval = setInterval(() => {
      request
        .get(generateUrl('api_login_status'))
        .then(() => {
          setOpen(false)
        })
        .catch(({ status }: { status: ?number, ... }) => {
          if (status === 401 || status === 403) {
            setOpen(true)
          }
        })
    }, 60000)
    return () => clearInterval(interval)
  }, [setOpen, open])

  const close = React.useCallback(() => {}, [])

  const redirectToSSOURL = React.useCallback(() => {
    getSSOUrl(user.email, window.location.href, false).then((url: string) => {
      window.location = url
    })
  }, [user.email])

  const onSubmit = React.useCallback(() => {
    setLoading(true)
    getLoginMethod(user.email).then((method: LoginMethod) => {
      if (method === 'sso') {
        return redirectToSSOURL()
      }
      window.location = generateUrl('login_password', { 'redirect-top': window.location.href })
    })
  }, [user.email, redirectToSSOURL])

  return (
    <Popin opened={open} close={close} style={{ overflowY: 'hidden' }}>
      <Box style={{ width: 400 }}>
        <BoxHeader style={{ border: 0 }}>
          <HeaderBoxTitle title="Please authenticate to continue" />
        </BoxHeader>
        <BoxBody $padding style={{ paddingTop: 0, paddingBottom: 10 }}>
          <Text style={{ fontSize: 14 }}>
            Your session has expired, please authenticate to return to your dashboard.
          </Text>
          <Text light style={{ fontSize: 14, marginTop: 6 }}>
            We require regular authentication to guarantee your safety.
          </Text>
        </BoxBody>
        <BoxFooter style={{ border: 0 }}>
          <FooterBoxActions>
            <ButtonLink href={generateUrl('login')} kind="secondary" intent="neutral" type="button">
              Log me out
            </ButtonLink>
            <Form onSubmit={onSubmit}>
              <Button type="submit" kind="primary" intent="action" isLoading={loading}>
                Log me back in
              </Button>
            </Form>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
