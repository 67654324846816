// @flow

import Immutable, { type Map } from 'immutable'

import * as queryApi from './query.api'
import {
  QueryFactory,
  LogicalNodeFactory,
  type QueryRecord,
  type ConditionRecord,
  type LogicalNodeRecord,
} from './query.records'

import { type ReduxAction } from 'com.batch.redux/_records'

import { type InitFormAction } from 'com.batch/orchestration/usecases/init-form'

export const api = queryApi

// ====================== ACTIONS

type QueryAction<N, P> = {
  type: N,
  payload: { ...P, queryId: string },
  ...
}
// add condition
export type AddConditionAction = QueryAction<
  'T_ADD_CONDITION',
  { condition: ConditionRecord, parentId: string, conditionId: string },
>
export const addCondition = ({
  queryId,
  parentId,
  condition,
}: {
  queryId: string,
  parentId: string,
  condition: ConditionRecord,
  ...
}): AddConditionAction => {
  const conditionId = api.generateConditionId()
  return {
    type: 'T_ADD_CONDITION',
    payload: {
      queryId,
      parentId,
      conditionId,
      condition,
    },
  }
}

// query parsed

export type QueryParsedAction = QueryAction<'T_QUERY_PARSED', { query: QueryRecord }>
export type QueryFailedAction = QueryAction<'T_QUERY_FAILED', { error: string }>
// update condition
export type UpdateConditionAction = QueryAction<
  'T_UPDATE_CONDITION',
  { condition: ConditionRecord, id: string },
>
export const updateCondition = ({
  queryId,
  id,
  condition,
}: {
  queryId: string,
  id: string,
  condition: ConditionRecord,
  ...
}): UpdateConditionAction => ({
  type: 'T_UPDATE_CONDITION',
  payload: {
    queryId,
    id,
    condition,
  },
})
// negate
type NegateAction = QueryAction<'T_NEGATE', { id: string }>
export const negate = ({ queryId, id }: { queryId: string, id: string, ... }): NegateAction => ({
  type: 'T_NEGATE',
  payload: {
    queryId,
    id,
  },
})
// un-negate
type UnNegateAction = QueryAction<'T_UN_NEGATE', { id: string }>
export const unNegate = ({
  queryId,
  id,
}: {
  queryId: string,
  id: string,
  ...
}): UnNegateAction => ({
  type: 'T_UN_NEGATE',
  payload: {
    queryId,
    id,
  },
})

// remove condition
type RemoveConditionAction = QueryAction<'T_REMOVE_CONDITION', { id: string }>
export const removeCondition = ({
  queryId,
  id,
}: {
  queryId: string,
  id: string,
  ...
}): RemoveConditionAction => ({
  type: 'T_REMOVE_CONDITION',
  payload: { queryId, id },
})

// update logical node
type UpdateNodeAction = QueryAction<'T_UPDATE_NODE', { position: string, value: 'and' | 'or' }>
export const updateNode = ({
  queryId,
  value,
  position,
}: {
  queryId: string,
  value: 'and' | 'or',
  position: string,
}): UpdateNodeAction => ({
  type: 'T_UPDATE_NODE',
  payload: { queryId, position, value },
})

// add logical node
type AddNodeAction = QueryAction<'T_ADD_NODE', { position: string, value: LogicalNodeRecord }>
export const addNode = ({
  queryId,
  value,
  position,
}: {
  queryId: string,
  value: 'and' | 'or' | 'not',
  position: string,
  ...
}): AddNodeAction => ({
  type: 'T_ADD_NODE',
  payload: {
    queryId,
    position,
    value: LogicalNodeFactory({ id: api.generateConditionId(), value }),
  },
})

// reset query
type ResetQueryAction = QueryAction<'T_QUERY_RESET', { queryId: string, query: QueryRecord }>
export const resetQuery = ({
  queryId,
  query,
}: {
  queryId: string,
  query?: QueryRecord,
  ...
}): ResetQueryAction => ({
  type: 'T_QUERY_RESET',
  payload: {
    queryId,
    query: query ? query : QueryFactory(),
  },
})

// empty all queries
type EmptyQueriesAction = ReduxAction<'T_QUERY_EMPTY', null>
export const emptyQueries = (): EmptyQueriesAction => ({
  type: 'T_QUERY_EMPTY',
  payload: null,
})

// copy query
type CopyQueryAction = QueryAction<'T_QUERY_COPY', { targetId: string }>
export const copyQuery = ({
  queryId,
  targetId,
}: {
  queryId: string,
  targetId: string,
  ...
}): CopyQueryAction => ({
  type: 'T_QUERY_COPY',
  payload: {
    queryId,
    targetId,
  },
})

export type QueryReducerActions =
  | AddConditionAction
  | AddNodeAction
  | RemoveConditionAction
  | QueryParsedAction
  | UpdateConditionAction
  | UpdateNodeAction
  | NegateAction
  | UnNegateAction
  | ResetQueryAction
  | InitFormAction
  | CopyQueryAction
  | QueryFailedAction
  | EmptyQueriesAction

export const actions = {
  updateNode,
  addCondition,
  addNode,
  removeCondition,
  updateCondition,
  negate,
  unNegate,
  copyQuery,
  resetQuery,
}
// ====================== REDUCER
export function singleQueryReducer(state: QueryRecord, action: QueryReducerActions): QueryRecord {
  switch (action.type) {
    case 'T_NEGATE': {
      const singleDescendantOfOurNot = api.getFromTree(state.tree, action.payload.id)
      const newTreeOrString = api.updateTree(
        state.tree,
        action.payload.id,
        LogicalNodeFactory({
          value: 'not',
          id: api.generateConditionId(),
          descendants: new Immutable.List().push(singleDescendantOfOurNot),
        })
      )
      return typeof newTreeOrString === 'string' ? state : state.set('tree', newTreeOrString)
    }
    case 'T_UN_NEGATE': {
      const parentId = api.getParentId(state.tree, action.payload.id, state.tree.id)
      const singleDescendantOfOurNot = api.getFromTree(state.tree, action.payload.id)
      const newTreeOrString = api.updateTree(state.tree, parentId, singleDescendantOfOurNot)
      return typeof newTreeOrString === 'string' ? state : state.set('tree', newTreeOrString)
    }
    case 'T_ADD_CONDITION':
      return state
        .set(
          'conditions',
          state.conditions.set(action.payload.conditionId, action.payload.condition)
        )
        .set('tree', api.addToTree(state.tree, action.payload.parentId, action.payload.conditionId))
    case 'T_UPDATE_NODE':
      return state.set(
        'tree',
        api.updateTreeValue(state.tree, action.payload.position, action.payload.value)
      )
    case 'T_ADD_NODE':
      return state.set(
        'tree',
        api.addToTree(state.tree, action.payload.position, action.payload.value)
      )
    case 'T_REMOVE_CONDITION':
      return state
        .set('conditions', state.conditions.remove(action.payload.id))
        .set('tree', api.deleteFromTree(state.tree, action.payload.id))
    case 'T_UPDATE_CONDITION':
      return state.set(
        'conditions',
        state.conditions.set(action.payload.id, action.payload.condition)
      )
    default:
      return state
  }
}

export function QueryReducer(
  state: Map<string, QueryRecord> = Immutable.Map(),
  action: QueryReducerActions
): Map<string, QueryRecord> {
  switch (action.type) {
    case 'T_ADD_CONDITION':
    case 'T_REMOVE_CONDITION':
    case 'T_UPDATE_NODE':
    case 'T_ADD_NODE':
    case 'T_NEGATE':
    case 'T_UN_NEGATE':
    case 'T_UPDATE_CONDITION':
      return state.set(
        action.payload.queryId,
        singleQueryReducer(state.get(action.payload.queryId, QueryFactory()), action)
      )
    case 'T_QUERY_PARSED':
      return state.set(action.payload.queryId, action.payload.query)
    case 'T_QUERY_FAILED':
      return state.set(
        action.payload.queryId,
        state.get(action.payload.queryId, QueryFactory()).set('error', action.payload.error)
      )
    case 'T_QUERY_RESET':
      return state.set(action.payload.queryId, action.payload.query)
    case 'T_QUERY_COPY':
      return state.set(action.payload.targetId, state.get(action.payload.queryId, QueryFactory()))
    case 'INIT_FORM':
    case 'T_QUERY_EMPTY':
      return Immutable.Map()
    default:
      return state
  }
}
