// @flow

import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'
import { fillBackground } from 'components/styled/tokens/colors'

export const EmailBuilderContainer: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  background-color: ${fillBackground};
  background-image: url('/medias/img/builders/email-background.webp');
  background-repeat: repeat;
  background-size: 420px 400px;
  padding: 32px 32px 0 32px;
  height: 100%;
`

type EmailClientProps = { $transparentBg: boolean, ... }
export const EmailClient: StyledComponent<EmailClientProps, {}, HTMLDivElement> = styled.div`
  background-color: rgba(
    255,
    255,
    255,
    ${(p: EmailClientProps) => (p.$transparentBg ? '0.6' : '1')}
  );
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: hidden;
  border: 2px solid ${colors.stroke};
  border-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`
