// @flow

import { type ReduxAction } from 'com.batch.redux/_records'

import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'

export type UpdateOrchestrationCampaignAction = ReduxAction<
  'UPDATE_ORCHESTRATION_CAMPAIGN',
  OrchestrationCampaignRecord,
>

export const updateOrchestrationCampaign: OrchestrationCampaignRecord => UpdateOrchestrationCampaignAction =
  campaign => ({
    type: 'UPDATE_ORCHESTRATION_CAMPAIGN',
    payload: campaign,
  })
