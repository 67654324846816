// @flow

import { scaleBand, scaleLinear } from 'd3-scale'
import { type List } from 'immutable'
import * as React from 'react'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { ChartBarSkeleton, ChartBar } from './styles'

import { DataPointFactory, roundedRect, type DataSetRecord } from '../chart-helper'

const DOMAINFORMAT = 'YYYYMMDDHH'

type BarProps = {
  sets: List<DataSetRecord>,
  xScale: typeof scaleBand,
  yScale: typeof scaleLinear,
  fadeAllBut: ?number,
  loading?: boolean,
}

const BarBase = ({ sets, xScale, yScale, fadeAllBut, loading }: BarProps): React.Node => {
  const setsForChart = React.useMemo(() => {
    return sets.toArray().map((set, setKey) => {
      return {
        color: loading ? '#c1c1c1' : set.color,
        data: set.data.map((point, k) => {
          const sumPrevious = sets.reduce((acc, current, indice) => {
            return acc + (indice <= setKey ? current.data.get(k, DataPointFactory()).value : 0)
          }, 0)
          const sumNext = sets.reduce((acc, current, indice) => {
            return acc + (indice >= setKey ? current.data.get(k, DataPointFactory()).value : 0)
          }, 0)
          const y = yScale(sumPrevious, 0)

          const h = yScale(0) - yScale(point.value)
          const borderBottom = sumPrevious - point.value === 0 && point.value > 0
          const borderTop = sumNext - point.value === 0 && point.value > 0
          return {
            date: point.date,
            path: roundedRect({
              x: xScale(point.date.format(DOMAINFORMAT)),
              y: y,
              w: xScale.bandwidth(),
              h,
              r: Math.min(h / 2, Math.min(xScale.bandwidth() / 2, 4)),
              tl: borderTop,
              tr: borderTop,
              bl: borderBottom,
              br: borderBottom,
            }),
          }
        }),
      }
    })
  }, [sets, xScale, yScale, loading])
  return (
    <g>
      {setsForChart.map((set, setKey) => (
        <g key={setKey}>
          {set.data.map((point, k) => {
            return loading ? (
              <ChartBarSkeleton d={point.path} key={k} fill={set.color} />
            ) : (
              <ChartBar
                d={point.path}
                key={k}
                opacity={Boolean(fadeAllBut) && fadeAllBut !== k ? 0.6 : 1}
                fill={
                  point.date.isSame(dayjs.utc(), 'day')
                    ? `url(#diagonalHatch-${set.color})`
                    : set.color
                }
              />
            )
          })}
        </g>
      ))}
    </g>
  )
}

export const Bar: React.ComponentType<BarProps> = React.memo<BarProps>(BarBase)

Bar.displayName = 'Bar'
