// @flow

import { arc as D3Arc } from 'd3-shape'
import * as React from 'react'

import { type Pattern, type PieData } from 'com.batch/shared/infra/types/chart-data'
import { RatioTag } from 'com.batch/shared/ui/component/charts/donut-chart/ratio-tag'

type Props = {
  pattern: ?Pattern,
  color: ?string,
  onClick: (index: number) => void,
  onMouseLeave: () => void,
  onMouseEnter: (index: number) => void,
  clickedIndex: ?number,
  index: number,
  radius: number,
  d: PieData,
  rate: number,
  hasTag: boolean,
  ...
}

export const arcWidth = 30
const activeArcWidth = 34
// export const arcWidth = 37
// const activeArcWidth = 42
const outerOffset = 1.15
const outerOffsetClicked = 1.2
const paddingAngle = 0.05

export const Arc = ({
  pattern,
  color,
  onClick,
  clickedIndex,
  index,
  onMouseLeave,
  onMouseEnter,
  radius,
  rate,
  d,
  hasTag,
}: Props): React.Node => {
  const innerRadius = radius - arcWidth
  const innerRadiusClicked = radius - activeArcWidth
  const outerRadiusClicked = radius + activeArcWidth - arcWidth

  const isClicked = React.useMemo(() => clickedIndex === index, [clickedIndex, index])

  const generateArc = React.useMemo(() => {
    if (isClicked) {
      return D3Arc()
        .innerRadius(innerRadiusClicked)
        .outerRadius(outerRadiusClicked)
        .padAngle(paddingAngle)
    }
    return D3Arc().innerRadius(innerRadius).outerRadius(radius).padAngle(paddingAngle)
  }, [isClicked, innerRadius, radius, innerRadiusClicked, outerRadiusClicked])

  const generateOuterArc = React.useMemo(() => {
    if (isClicked) {
      return D3Arc()
        .innerRadius(innerRadiusClicked * outerOffsetClicked)
        .outerRadius(outerRadiusClicked * outerOffsetClicked)
    }
    return D3Arc()
      .innerRadius(innerRadius * outerOffset)
      .outerRadius(radius * outerOffset)
  }, [isClicked, innerRadius, radius, innerRadiusClicked, outerRadiusClicked])

  const arc = React.useMemo(() => {
    return generateArc(d)
  }, [d, generateArc])

  const centroidTag = React.useMemo(() => {
    return generateOuterArc.centroid(d)
  }, [d, generateOuterArc])

  const segment = React.useMemo(() => {
    const startAngle = generateArc.startAngle()(d)
    const endAngle = generateArc.endAngle()(d)
    const arcSize = endAngle - startAngle
    return radius * arcSize
  }, [d, generateArc, radius])

  return (
    <React.Fragment>
      <path
        data-testid={`arc_${index}`}
        d={arc}
        fill={color}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        strokeWidth={3}
        stroke={color}
        onClick={onClick}
        style={{ transition: 'd 0.1s ease-out', cursor: 'pointer' }}
      />
      {pattern && (
        <React.Fragment>
          {pattern.content}
          <path
            d={arc}
            fill={`url(#${pattern.id})`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            strokeWidth={3}
            stroke={`url(#${pattern.id})`}
            onClick={onClick}
            style={{ transition: 'd 0.1s ease-out', cursor: 'pointer', opacity: 0.12 }}
          />
        </React.Fragment>
      )}

      {hasTag && segment > 50 && (
        <RatioTag
          x={centroidTag[0]}
          y={centroidTag[1]}
          rate={rate}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
    </React.Fragment>
  )
}
