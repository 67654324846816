// @flow

import Immutable, { type RecordOf, type RecordFactory, type Map } from 'immutable'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { type PlatformType, type SourceType } from 'com.batch/profile/models/profile.records'

export type OrchestrationMetadataProps = {
  orchestrationID: string,
  orchestrationName: string,
  orchestrationType?:
    | 'ORCHESTRATION_TYPE_ONETIME'
    | 'ORCHESTRATION_TYPE_TRIGGER'
    | 'ORCHESTRATION_TYPE_RECURRENT'
    | 'ORCHESTRATION_TYPE_SEND_TEST'
    | 'ORCHESTRATION_TYPE_TRANSACTIONAL',
  stepID: string,
  stepName: string,
  contentLanguage: string,
  variantID: string,
}

export const OrchestrationMetadataFactory: RecordFactory<OrchestrationMetadataProps> =
  Immutable.Record(
    ({
      orchestrationID: '',
      orchestrationName: '',
      orchestrationType: undefined,
      stepID: '',
      stepName: '',
      contentLanguage: '',
      variantID: '',
    }: OrchestrationMetadataProps)
  )

export type OrchestrationMetadataRecord = RecordOf<OrchestrationMetadataProps>

export type ProfileMetadataProps = {
  profileID: string,
  customID: string,
  emailAddress: string,
  phoneNumber: string,
}

export const ProfileMetadataFactory: RecordFactory<ProfileMetadataProps> = Immutable.Record(
  ({
    profileID: '',
    customID: '',
    emailAddress: '',
    phoneNumber: '',
  }: ProfileMetadataProps)
)

export type ProfileMetadataRecord = RecordOf<ProfileMetadataProps>

export type AdditionalDataProps = {
  bounceType: string,
  bounceCode: string,
  bounceCodeLabel: string,
}

export const AdditionalDataFactory: RecordFactory<AdditionalDataProps> = Immutable.Record(
  ({
    bounceType: '',
    bounceCode: '',
    bounceCodeLabel: '',
  }: AdditionalDataProps)
)

export type AdditionalDataRecord = RecordOf<AdditionalDataProps>

export type EmailAdditionalDataProps = {
  mailID: string,
  mailboxProvider: string,
  clickURL: string,
  clickURLName: string,
}

export const EmailAdditionalDataFactory: RecordFactory<EmailAdditionalDataProps> = Immutable.Record(
  ({
    mailID: '',
    mailboxProvider: '',
    clickURL: '',
    clickURLName: '',
  }: EmailAdditionalDataProps)
)

export type EmailAdditionalDataRecord = RecordOf<EmailAdditionalDataProps>

export type SMSAdditionalDataProps = {
  smsID: string,
  countryCode: string,
  nbParts: number,
}

export const SMSAdditionalDataFactory: RecordFactory<SMSAdditionalDataProps> = Immutable.Record(
  ({
    smsID: '',
    countryCode: '',
    nbParts: 0,
  }: SMSAdditionalDataProps)
)

export type SMSAdditionalDataRecord = RecordOf<SMSAdditionalDataProps>

export type PushAdditionalDataProps = {
  transmissionID: string,
  transmissionUnitID: string,
  apiKey: string,
  platform: string,
  installID: string,
}

export const PushAdditionalDataFactory: RecordFactory<PushAdditionalDataProps> = Immutable.Record(
  ({
    transmissionID: '',
    transmissionUnitID: '',
    apiKey: '',
    platform: '',
    installID: '',
  }: PushAdditionalDataProps)
)

export type PushAdditionalDataRecord = RecordOf<PushAdditionalDataProps>

export type Metric = 'SENT' | 'DELIVERED' | 'OPEN' | 'CLICK'

type CustomEventProps = {
  name: string,
  label: string,
  platform: PlatformType,
  attributes: Map<string, QueryValue>,
  tags: Map<string, Array<string>>,
  sendDate: string,
  source: SourceType,
}
export const CustomEventFactory: RecordFactory<CustomEventProps> = Immutable.Record(
  ({
    name: '',
    label: '',
    platform: 'ANDROID',
    attributes: Immutable.Map(),
    tags: Immutable.Map(),
    sendDate: '',
    source: 'PROFILE_API',
  }: CustomEventProps)
)

export type CustomEventRecord = RecordOf<CustomEventProps>

type MessagingEventProps = {
  eventID: string,
  metric: Metric,
  eventDate: number,
  channel: 'sms' | 'email' | 'push',
  orchestrationMetadata: OrchestrationMetadataRecord,
  profileMetadata: ProfileMetadataRecord,
  additionalData?: AdditionalDataRecord,
  emailAdditionalData?: EmailAdditionalDataRecord,
  smsAdditionalData?: SMSAdditionalDataRecord,
  pushAdditionalData?: PushAdditionalDataRecord,
}
export const MessagingEventFactory: RecordFactory<MessagingEventProps> = Immutable.Record(
  ({
    eventID: '',
    metric: 'SENT',
    eventDate: 0,
    channel: 'email',
    orchestrationMetadata: OrchestrationMetadataFactory(),
    profileMetadata: ProfileMetadataFactory(),
    additionalData: undefined,
    emailAdditionalData: undefined,
    smsAdditionalData: undefined,
    pushAdditionalData: undefined,
  }: MessagingEventProps)
)

export type MessagingEventRecord = RecordOf<MessagingEventProps>

type EventProps = {
  customEvent?: CustomEventRecord,
  messagingEvent?: MessagingEventRecord,
}
export const EventFactory: RecordFactory<EventProps> = Immutable.Record(
  ({
    customEvent: undefined,
    messagingEvent: undefined,
  }: EventProps)
)

export type EventRecord = RecordOf<EventProps>
