// @flow
import { type Dayjs } from 'dayjs'
import Immutable, { type RecordFactory, type RecordOf, type Set } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  type pushCampaignSendType,
  type TriggerConfigRecord,
  type fetchingState,
} from 'com.batch.redux/_records'

type oneTypeProps = {
  sendDate: Dayjs,
  localTimezoneDependant: boolean,
}
export const OneTimeFactory: RecordFactory<oneTypeProps> = Immutable.Record(
  ({
    sendDate: dayjs(),
    localTimezoneDependant: false,
  }: oneTypeProps)
)

export type OneTimeRecord = RecordOf<oneTypeProps>

// recurrent

type recurrentProps = {
  start: ?Dayjs,
  end: ?Dayjs,
  localTimezoneDependant: boolean,
  repeatUnit: repeatUnitType,
  repeatFrequency: number,
}

export const RecurrentFactory: RecordFactory<recurrentProps> = Immutable.Record(
  ({
    start: null,
    end: null,
    localTimezoneDependant: false,
    repeatFrequency: 0,
    repeatUnit: 'DAILY',
  }: recurrentProps)
)

export type RecurrentRecord = RecordOf<recurrentProps>

type PartialOrchestrationProps = {
  token: string,
  incomplete: boolean,
  createdByApi: boolean,
  loadingState: fetchingState,
  triggerConfig: ?TriggerConfigRecord,
  oneTime: ?OneTimeRecord,
  recurring: ?RecurrentRecord,
  channels: Set<ChannelUntilCleanup>,
  routingMode: 'campaigns' | 'automations',
  name: string,
  state: campaignStateType,
  sendType: pushCampaignSendType,
}

export const PartialOrchestrationFactory: RecordFactory<PartialOrchestrationProps> =
  Immutable.Record(
    ({
      token: '',
      incomplete: false,
      createdByApi: false,
      channels: Immutable.Set([]),
      loadingState: 'LOADED',
      triggerConfig: null,
      oneTime: null,
      recurring: null,
      routingMode: 'campaigns',
      name: '',
      state: 'NEW',
      sendType: 'now',
    }: PartialOrchestrationProps)
  )
export type PartialOrchestrationRecord = RecordOf<PartialOrchestrationProps>
