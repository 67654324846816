// @flow

import { memoize as _memoize } from 'lodash-es'
import * as React from 'react'
import { createSelector } from 'reselect'

import { ClickDetailPopin } from './data/click-detail-popin'

import {
  type Variant,
  type AppRecord,
  type CampaignRecord,
  CappingCategoryFactory,
  type CappingCategoryRecord,
} from 'com.batch.redux/_records'
import { AbTestedInAppFactory, type AbTestedInAppRecord } from 'com.batch.redux/content.records'
import {
  CampaignDataFactory,
  type CampaignDataRecord,
  type InAppDataRecord,
  type PushDataRecord,
} from 'com.batch.redux/dataCampaign.records'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'

export const campaignDataSelector: (state: ?CampaignDataRecord) => CampaignDataRecord = state =>
  typeof state !== 'undefined' && state !== null ? state : CampaignDataFactory()

const cacheKeyBuilder = (data: ?CampaignDataRecord, campaign: CampaignRecord): string => {
  return `${!data ? 'undefined' : data.hashCode()}-${!campaign ? 'undef' : campaign.hashCode()}`
}

const matchCappingCategories = (code: string, app: AppRecord): CappingCategoryRecord => {
  const filtered = app.cappingCategories.filter(category => category.code === code)
  if (filtered.size === 1) return filtered.get(0, CappingCategoryFactory())
  if (code === 'MARKETING_PRESSURE') return CappingCategoryFactory({ name: 'Campaigns capping' })
  return CappingCategoryFactory({ name: code })
}

export type summaryData = {
  loading: boolean,
  empty: boolean,
  items: Array<{
    label: string,
    tooltip?: string,
    value: number,
    color: string,
    ratio?: boolean,
  }>,
}

export const ttOpenRate = {
  ACCURATE: 'Opened notifications (direct & influenced) / Notifications sent to opt-in users',
  ACCURATE_DIRECT: 'Opened notifications / Notifications sent to opt-in users',
  LEGACY: 'Opened notifications (direct & influenced) / Notifications sent',
  LEGACY_DIRECT: 'Opened notifications / Notifications sent',
}

export const ttSentText = {
  ACCURATE: 'Sent to opt-in users',
  ACCURATE_DIRECT: 'Sent to opt-in users',
  LEGACY: 'Sent',
  LEGACY_DIRECT: 'Sent',
}
type summarySelectorType = (
  data: ?CampaignDataRecord,
  campaign: CampaignRecord,
  app: AppRecord,
  variantsThemes: AbTestedThemeRecord
) => summaryData
const summarySelectorRaw: summarySelectorType = (
  data,
  campaign,
  app,
  variantsThemes
): summaryData => {
  const isPush = campaign.type.toLocaleLowerCase() === 'push'
  const isTrigger = isPush && campaign.sendType === 'trigger'
  const isWebview = variantsThemes.a && variantsThemes.a.payloadVars.kind === 'webview' // @todo analytics ab inapp
  const d = !data ? CampaignDataFactory() : data
  const dd = deliverySelector(d, campaign, app)
  const pushProvider =
    app.platform === 'ios' ? 'Apple' : app.platform === 'android' ? 'Google' : 'the push provider'

  const directOnly = app.openRateAlg.indexOf('_DIRECT') !== -1
  const items = [
    {
      label: isPush ? 'Sent' : 'Displayed',
      value: isPush ? d.push.total.sent : d.inapp.total.display,
      color: '#02a0e3',
      tooltip: isPush
        ? `Accepted by ${pushProvider} but not necessarily delivered to the end device. ${
            d.push.total.devSent ? `${d.push.total.devSent} on the DEV API Key` : ''
          }`
        : 'Number of times the campaign was displayed.',
    },
    {
      label: isPush ? 'Opened' : 'Clicked',
      value: isPush
        ? directOnly
          ? d.push.total.open
          : d.push.total.open + d.push.total.influencedOpen
        : d.inapp.total.click.total,
      color: '#70a647',
      tooltip: `${
        isPush
          ? directOnly
            ? 'Users who tapped or clicked on the notification (direct).'
            : 'Users who tapped or clicked on the notification (direct), and users who opened the app in a 3 hours range afterwards (influenced).'
          : isWebview
            ? 'Number of clicks on action button(s)'
            : 'Users who clicked one of the 2 buttons of the in-app message.'
      } ${d.push.total.devOpen ? `${d.push.total.devOpen} on the DEV API Key` : ''}`,
    },
    {
      label: isPush ? 'Open rate' : 'Click rate',
      value: isPush ? d.push.total.openRate : d.inapp.total.clickRate,
      color: '#9ec97f',
      ratio: true,
      tooltip: isPush ? ttOpenRate[app.openRateAlg] : 'Clicked / Displayed',
    },
  ]
  if (campaign.sendType !== 'recurring') {
    if (isTrigger) {
      items.splice(0, 0, {
        label: 'Entered',
        value: d.trigger.entered,
        color: '#2c3747',
        tooltip:
          'Number of time the trigger event was triggered and an installation enterred the story',
      })
    } else {
      if (isPush && d.push.total.accepted && app.platform === 'android') {
        items.splice(0, 0, {
          label: 'Accepted',
          value: d.push.total.accepted,
          color: '#3ebdb7',
          tooltip:
            'Feedbacks for all providers are synchrone, meaning we know as soon as we’ve send them the push request if they managed to send the notification or if the push token is no longer valid. Huawei (HMS) is asynchrone, meaning we will first have all request accepted, and at later time we’ll be notified by Huawei if the managed to send the notification or not',
        })
      }
      items.splice(0, 0, {
        label: isPush ? 'Target' : 'Devices synced',
        value: isPush ? dd.total : d.inapp.total.supplied,
        color: '#2c3747',
        tooltip: isPush
          ? 'Total number of installations considered for this campaign.'
          : 'Number of unique devices that were targeted by the campaign and have downloaded it locally',
      })
    }
  }

  if (isPush) {
    items.splice(2, 0, {
      label: 'Bounced',
      value: d.errors.reduce((prev, err) => prev + err, 0) + d.push.total.unregistered,
      color: '#da314b',
      tooltip: `Sent to ${pushProvider} but not to the end device because of errors or uninstallations.`,
    })
  }

  return {
    loading: data ? data.loading : true,
    empty: isPush
      ? d.push.total.devSent === 0 &&
        d.push.total.sent === 0 &&
        d.errors.size === 0 &&
        d.trigger.entered === 0
      : d.inapp.total.supplied + d.inapp.total.click.total + d.inapp.total.close === 0,
    items,
  }
}

export const summarySelector: summarySelectorType = _memoize(summarySelectorRaw, cacheKeyBuilder)

export type metric = {
  label: string,
  value: number,
  tooltip?: string,
  ...
}
export type deliverySlice = {
  label: string,
  icon: 'send' | 'undelivered',
  title: string,
  description: (platform?: string) => string,
  color: string,
  value: number,
  metrics: Array<metric & { details?: Array<metric>, color?: string, ... }>,
}
export type deliveryData = {
  loading: boolean,
  empty: boolean,
  total: number,
  categories: Array<deliverySlice>,
}

const getPlatformWording = (platform?: string): string => {
  let items = { ios: 'Apple', android: 'Google', windows: 'Microsoft', webpush: 'Web Push APIs' }
  return platform && items[platform] ? items[platform] : 'the platform push APIs'
}

type DeliverySelectorType = (
  data: ?CampaignDataRecord,
  campaign: CampaignRecord,
  app: AppRecord
) => {
  categories: Array<deliverySlice>,
  empty: boolean,
  loading: boolean,
  total: number,
}

const deliverySelectorRaw: DeliverySelectorType = (
  data,
  campaign,
  app
): {
  categories: Array<deliverySlice>,
  empty: boolean,
  loading: boolean,
  total: number,
} => {
  const isPush = campaign.type.toLocaleLowerCase() === 'push'
  const loading = data ? data.loading : true
  const d = !data ? CampaignDataFactory() : data
  const row = d.push.total
  const sents: deliverySlice = {
    label: 'SENT',
    icon: 'send',
    color: '#6eab41',
    value: row.sent,
    title: 'Push notifications successfully sent',
    description: platform =>
      `Accepted by ${getPlatformWording(
        platform
      )} but not necessarily delivered to the end device.`,
    metrics: [
      {
        label: 'OPT-INS',
        value: row.sentNotifOn,
        tooltip:
          'Notifications sent to installs who were opted in to receive push notification last time Batch SDK saw them.',
      },
      {
        label: 'OPT-OUTS',
        value: row.sent - row.sentNotifOn - (row.sent - row.sentKnownDi),
        color: '#EAF0F0',
        tooltip:
          'Notifications sent to installs who were opted out (and are unlikely to see the notification) last time Batch saw them.',
      },
      {
        label: 'IMPORTED',
        value: row.sent - row.sentKnownDi,
        color: '#5D619C',
        tooltip: "Notifications sent to imported push tokens, that Batch SDK hasn't seen yet.",
      },
      {
        label: 'DEV API KEY',
        value: row.devSent,
        color: 'slategray',
        tooltip: 'Notifications sent to for the DEV API Key.',
      },
    ],
  }

  const skipped = d.skipped.reduce((prev, skipped) => prev + skipped, 0)
  const errors = d.errors.reduce((prev, err) => prev + err, 0)
  const undelivered: deliverySlice = {
    label: 'UNDELIVERED',
    icon: 'undelivered',
    color: '#da314b',
    title: 'Push notifications not delivered',
    description: platform =>
      `Rejected by ${getPlatformWording(platform)} or skipped because of a capping rule.`,
    value: skipped + errors + row.unregistered,
    metrics: [
      {
        label: 'SKIPPED',
        value: skipped,
        color: '#ffa440',
        tooltip: 'The notification was skipped because of capping limit.',
        details: d.skipped
          .map((nb, key) => {
            const capping = matchCappingCategories(key, app)
            return { value: nb, label: `capping on ${capping.name}` }
          })
          .toList()
          .toArray(),
      },
      {
        label: 'UNINSTALLS',
        value: row.unregistered,
        color: '#e1586d',
        tooltip:
          'We tried to send the campaign to those devices but they no longer have the app installed',
      },
      {
        label: 'ERRORS',
        value: errors,
        color: '#c1233b',
        tooltip: 'Errors sent by the push provider (FCM / APNS).',
        details: d.errors
          .map((nb, key) => {
            return { value: nb, label: key.toLowerCase() }
          })
          .toList()
          .toArray(),
      },
    ],
  }

  return {
    loading: loading,
    empty: isPush
      ? row.sent === 0 && d.errors.size === 0 && d.skipped.size === 0 && d.trigger.entered === 0
      : d.inapp.total.supplied === 0,
    total: sents.value + undelivered.value,
    categories: [sents, undelivered],
  }
}

export const deliverySelector: DeliverySelectorType = _memoize(deliverySelectorRaw, cacheKeyBuilder)

export type abTestingFormattedData = {
  loading: boolean,
  a: PushDataRecord,
  b: PushDataRecord,
}

const isFieldShowed = (
  variantsThemes: AbTestedThemeRecord,
  variant: Variant,
  cta: 'cta1' | 'cta2' | 'global'
) => variantsThemes[variant]?.fields.some((field, key) => key === cta && field.hidden === false)

type tooltipType =
  | false
  | {
      descr?: string | React.Node,
      details: Array<{ label: string, value: number, ... }>,
      ...
    }

type ctaItem = {
  appendNode?: React.Node,
  count: number,
  label?: string,
  notASource?: boolean,
  ratio?: number,
  tooltip: tooltipType,
  ...
}
const addCtas = (
  items: Array<ctaItem>,
  data: InAppDataRecord,
  translations: AbTestedInAppRecord,
  variantsThemes: AbTestedThemeRecord
) => {
  const showCta1A = isFieldShowed(variantsThemes, 'a', 'cta1')
  const showCta1B = isFieldShowed(variantsThemes, 'b', 'cta1')

  if (showCta1A || showCta1B) {
    let descr = ''
    if (showCta1A) {
      descr += `Version A: ${translations.a.mainButtonLabel} (${translations.a.mainButtonAction.action.label})`
    }
    if (showCta1B) {
      descr += `${showCta1A ? ' - ' : ''}Version B: ${translations.b.mainButtonLabel} (${
        translations.b.mainButtonAction.action.label
      })`
    }

    items.push({
      label: 'Main button',
      tooltip: {
        descr,
        details: [],
      },
      count: data.click.cta1,
      ratio: data.click.cta1 / data.display,
    })
  }

  const showCta2A = isFieldShowed(variantsThemes, 'a', 'cta2')
  const showCta2B = isFieldShowed(variantsThemes, 'b', 'cta2')
  if (showCta2A || showCta2B) {
    let descr = ''
    if (showCta2A) {
      descr += `Version A: ${translations.a.secondaryButtonLabel} (${translations.a.secondaryButtonAction.action.label})`
    }
    if (showCta2B) {
      descr += `${showCta2A ? ' - ' : ''}Version B: ${translations.b.secondaryButtonLabel} (${
        translations.b.secondaryButtonAction.action.label
      })`
    }

    items.push({
      label: 'Secondary button',
      tooltip: {
        descr,
        details: [],
      },
      count: data.click.cta2,
      ratio: data.click.cta2 / data.display,
    })
  }

  const showGlobalA = isFieldShowed(variantsThemes, 'a', 'global')
  const showGlobalB = isFieldShowed(variantsThemes, 'b', 'global')
  if (showGlobalA || showGlobalB) {
    let descr = ''
    if (showGlobalA) {
      descr += `Version A: ${translations.a.globalTapAction.action.label}`
    }
    if (showGlobalB) {
      descr += `${showGlobalA ? ' - ' : ''}Version B: ${
        translations.a.globalTapAction.action.label
      }`
    }

    items.push({
      label: 'Global action',
      tooltip: {
        descr,
        details: [],
      },
      count: data.click.global,
      ratio: data.click.global / data.display,
    })
  }

  return items
}

export const abTestingSelector: (state: ?CampaignDataRecord) => abTestingFormattedData =
  createSelector(campaignDataSelector, (data: CampaignDataRecord) => {
    return {
      loading: data.loading,
      a: data.push.a,
      b: data.push.b,
    }
  })

export type performanceItem = {
  appendNode?: React.Node,
  count: number,
  label?: string,
  notASource?: boolean,
  ratio?: number,
  tooltip: tooltipType,
  ...
}

export const performanceSelector = (
  data: ?CampaignDataRecord,
  app: AppRecord,
  campaign: CampaignRecord,
  variantsThemes: AbTestedThemeRecord
): Array<{
  title: string,
  ratio?: number,
  items: Array<performanceItem>,
  ...
}> => {
  if (!data || (campaign.hasLanding && !variantsThemes.a && !variantsThemes.b)) {
    return []
  }
  const openRateAlg = app.openRateAlg
  const legacy = openRateAlg.indexOf('LEGACY') !== -1
  const direct = openRateAlg.indexOf('DIRECT') !== -1
  const langCode = campaign.translations.first() || 'en'
  const translations = campaign.data.inapp.get(langCode, AbTestedInAppFactory())
  let res: Array<{
    items: Array<performanceItem | ctaItem>,
    ratio?: number,
    title: string,
    ...
  }> = []
  if (campaign.type.toLocaleLowerCase() === 'push') {
    let sends = [
      {
        count: legacy ? data.push.total.sent : data.push.total.sentNotifOn,
        tooltip: {
          descr: `Notifications sent${legacy ? '' : ' to opt-ins users'}.`,
          details: [],
        },
      },
    ]
    res.push({
      title: legacy ? 'Sent' : 'Opt-ins',
      items: sends,
    })
    let opens = [
      {
        label: 'Direct',
        count: data.push.total.open,
        ratio: data.push.total.open / data.push.total.sentNotifOn,
        tooltip: {
          details: [],
          descr: 'Users who tapped or clicked on the notification.',
        },
      },
    ]
    if (!direct) {
      opens.push({
        label: 'Influenced',
        count: data.push.total.influencedOpen,
        ratio: data.push.total.influencedOpen / data.push.total.sentNotifOn,
        notASource: campaign.hasLanding,
        tooltip: {
          details: [],
          descr:
            'Users who received the notification and did not click on it, but opened the app in a 3 hours range afterwards',
        },
      })
    }
    res.push({
      title: 'All opens',
      ratio: data.push.total.openRate,
      items: opens,
    })
    if (campaign.hasLanding) {
      res.push({
        title: 'Landing display',
        items: [
          {
            label: 'Landing display',
            tooltip: false,
            count: data.inapp.total.display,
          },
        ],
      })
      let itemsClick: Array<ctaItem | performanceItem> = []
      if (data.inapp.total.click.cta1) {
        itemsClick = addCtas(itemsClick, data.inapp.total, translations, variantsThemes)
        const unknown =
          data.inapp.total.click.total -
          data.inapp.total.click.cta1 -
          data.inapp.total.click.cta2 -
          data.inapp.total.click.global -
          data.inapp.total.click.webview
        if (unknown > 0) {
          itemsClick.push({
            label: 'Unknown',
            tooltip: false,
            count: unknown,
            ratio: data.inapp.total.display ? unknown / data.inapp.total.display : 0,
          })
        }
      } else {
        itemsClick = [
          {
            label: 'Landing clicks',
            tooltip: false,
            count: data.inapp.total.click.total,
            ratio: data.inapp.total.display
              ? data.inapp.total.click.total / data.inapp.total.display
              : 0,
          },
        ]
      }
      res.push({
        title: 'Mobile Landing',
        items: itemsClick,
      })
    } else {
      res.push({
        title: 'Reengaged',
        items: [
          {
            count: data.push.total.reengaged,
            tooltip: {
              descr:
                'Users who received the notification and became engaged 3 days after receiving it.',
              details: [],
            },
            ratio:
              data.push.total.reengaged / (data.push.total.open + data.push.total.influencedOpen),
          },
        ],
      })
    }
  } else {
    res.push({
      title: 'Displayed',
      items: [
        {
          tooltip: {
            descr: 'Number of times the campaign was displayed',
            details: [],
          },
          count: data.inapp.total.display,
        },
      ],
    })
    let items: Array<performanceItem> = []
    if (
      variantsThemes.a?.payloadVars.kind === 'webview' ||
      variantsThemes.b?.payloadVars.kind === 'webview'
    ) {
      const clicksDetailsArray = data.inapp.total.click.analyticsIds
        .map((value: number, label: string) => ({ label, value }))
        .toList()
        .toArray()
      const unknownClicks =
        data.inapp.total.click.webview -
        data.inapp.total.click.analyticsIds.reduce((a, b) => a + b, 0)
      const clickDetails =
        unknownClicks > 0
          ? [
              ...clicksDetailsArray,
              {
                label: 'unknown',
                value:
                  data.inapp.total.click.webview -
                  data.inapp.total.click.analyticsIds.reduce((a, b) => a + b, 0),
              },
            ]
          : clicksDetailsArray
      items.push({
        label: 'Webview buttons',
        tooltip: false,
        appendNode: ((clickDetails.length > 0 ? (
          <ClickDetailPopin data={clickDetails} display={data.inapp.total.display} />
        ) : undefined): any),
        count: data.inapp.total.click.webview,
        ratio: data.inapp.total.click.webviewRate,
      })
    }

    if (
      variantsThemes.a?.payloadVars.kind !== 'webview' ||
      variantsThemes.b?.payloadVars.kind !== 'webview'
    ) {
      items = addCtas(items, data.inapp.total, translations, variantsThemes)

      const unknown =
        data.inapp.total.click.total -
        data.inapp.total.click.cta1 -
        data.inapp.total.click.cta2 -
        data.inapp.total.click.global -
        data.inapp.total.click.webview
      if (unknown > 0) {
        items.push({
          label: 'Unknown',
          title: 'Unknown',
          tooltip: false,
          count: unknown,
          ratio: unknown / data.inapp.total.display,
        })
      }
    }
    items.push({
      label: 'Close',
      tooltip: {
        descr: 'Number of clicks on native Close button',
        details: [],
      },
      count: data.inapp.total.close,
      ratio: data.inapp.total.display ? data.inapp.total.close / data.inapp.total.display : 0,
    })
    res.push({
      // @todo analytics ab webview
      title: variantsThemes.a && variantsThemes.a.payloadVars.kind === 'webview' ? '' : 'Clicked',
      items,
    })
  }
  return res
}

export const journeySelector = (
  data: ?CampaignDataRecord
): Array<{
  title: string,
  ratio?: number,
  items: Array<performanceItem>,
  ...
}> => {
  if (!data) {
    return []
  }

  let res = [
    {
      title: 'Entered',
      items: [{ tooltip: false, count: data.trigger.entered }],
    },
    {
      title: 'Currently waiting',
      items: [
        {
          tooltip: {
            descr: 'Users currently waiting for the push timer to expire',
            details: [],
          },
          count: data.trigger.waiting,
        },
      ],
    },
    {
      title: '',
      items: [
        {
          label: 'sent',
          count: data.push.total.sent,
          tooltip: {
            descr: 'Notifications sent',
            details: [],
          },
        },
        {
          label: 'Exited',
          count:
            data.trigger.exited.query +
            data.trigger.exited.event +
            data.trigger.exited.stop +
            data.trigger.exited.noToken +
            data.skipped.reduce((acc, current) => acc + current, 0) +
            data.push.total.unregistered,
          tooltip: {
            descr: 'Users who exited the journey',
            details: [
              { label: 'Targeting mismatch', value: data.trigger.exited.query },
              { label: 'Cancellation event', value: data.trigger.exited.event },
              { label: 'Campaign ended', value: data.trigger.exited.stop },
              {
                label: 'Skipped (capping limits)',
                value: data.skipped.reduce((acc, current) => acc + current, 0),
              },
              {
                label: 'No push token',
                value: data.trigger.exited.noToken,
              },
              {
                label: 'Uninstall',
                value: data.push.total.unregistered,
              },
            ],
          },
        },
      ],
    },
  ]

  return res
}
