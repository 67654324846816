// @flow

/**
 *
 * Ce composant va remplacer nos 3 overlay d'empty state actuels :
 *    - NoData / NoDataScoped -> empty ou error, avec un refresh btn optionnel et des liens optionnels
 *    - RestrictedAccess ->  resticted ou upgrade, la version avec les gros icons, et un lien vers les permissions si c'est restricted ou un lien custom optionnel
 *    - PlanSection -> resticted ou upgrade, avec des liens optioonnels
 *
 * On a donc :
 *  - 3 modes page (gros icons quand il y en a, gros texte) pour les soucis de permissions ou d'upgrade,
 *  - 4 modes blocs (petits icons) pour les cas no data, error, permission ou upgrade
 */

import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { LinkArrow, LinkDoc } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'

import { LockIcon, CrownIcon } from './overlay.icons'
import {
  RestrictedAccessContainer,
  RestrictedAccessContent,
  RestrictedAccessIcon,
  ScopedIconContainer,
  EmptyStateDescription,
  NoDataContainer,
  EmptyStateSubtitle,
  EmptyStateTitle,
} from './overlay.styles'

type OverlayLink = {
  name: string,
  href: string,
  isDocLink?: boolean,
  intent?: 'action' | 'neutral',
}

export type OverlayProps =
  | {
      status: 'upgrade-page',
      title?: string,
      style?: { [string]: string | number, ... },
      companyId: number,
      description?: React.Node,
      link?: OverlayLink,
    }
  | {
      status: 'restricted-page',
      title: string,
      style?: { [string]: string | number, ... },
      description?: React.Node,
    }
  | {
      status: 'upgrade' | 'restricted',
      title: string,
      style?: { [string]: string | number, ... },
      description?: React.Node,
      links?: Array<OverlayLink>,
    }
  | {
      status: 'error' | 'empty' | 'error-page' | 'empty-page',
      title?: string,
      subtitle?: React.Node,
      style?: { [string]: string | number, ... },
      description?: React.Node,
      content?: React.Node,
      refresh?: () => void,
      links?: Array<OverlayLink>,
    }

export const Overlay = ({
  title,
  description,
  status,
  style,
  ...options
}: OverlayProps): React.Node => {
  const handleOnClickUpgradeNow = React.useCallback(() => {
    window.location.href = generateUrl('company_billing', {
      companyId: options.companyId,
    })
  }, [options.companyId])
  if (status === 'upgrade-page' || status === 'restricted-page') {
    return (
      <RestrictedAccessContainer style={style}>
        <RestrictedAccessIcon>
          {status === 'upgrade-page' ? <CrownIcon /> : <LockIcon />}
        </RestrictedAccessIcon>
        <RestrictedAccessContent>
          <EmptyStateTitle kind="restrictedAccess">{title}</EmptyStateTitle>

          {(!!description ||
            options.companyId ||
            options.link ||
            options.links ||
            status === 'restricted-page') && (
            <EmptyStateDescription>{description}</EmptyStateDescription>
          )}

          <div className="styled-restrict-access-links">
            {status === 'upgrade-page' && !!options.companyId && (
              <React.Fragment>
                <Button kind="primary" intent="action" onClick={handleOnClickUpgradeNow}>
                  Upgrade now
                </Button>
                {!!options.link && <LinkDoc href={options.link.href}>{options.link.name}</LinkDoc>}
              </React.Fragment>
            )}

            {status === 'restricted-page' && (
              <LinkDoc href="https://doc.batch.com/dashboard/settings/company-settings#managing-permissions">
                Learn about permissions
              </LinkDoc>
            )}
          </div>
        </RestrictedAccessContent>
      </RestrictedAccessContainer>
    )
  } else {
    return (
      <NoDataContainer style={style}>
        {status !== 'empty' && status !== 'empty-page' && (
          <ScopedIconContainer
            intent={
              status === 'error' || status === 'error-page'
                ? 'failed'
                : status === 'upgrade'
                  ? 'upgrade'
                  : 'permission'
            }
          >
            <Icon
              icon={
                status === 'error' || status === 'error-page'
                  ? 'cross'
                  : status === 'upgrade'
                    ? 'crown'
                    : 'lock'
              }
              size={status === 'error' || status === 'error-page' ? 10 : 13}
            />
          </ScopedIconContainer>
        )}

        <EmptyStateTitle kind={status.indexOf('page') !== -1 ? 'noData' : 'scoped'}>
          {title}
        </EmptyStateTitle>

        {(!!description ||
          options.content ||
          options.link ||
          options.links ||
          options.refresh ||
          status === 'restricted-page') && (
          <EmptyStateDescription kind={status.indexOf('page') !== -1 ? 'noData' : 'scoped'}>
            {description}
          </EmptyStateDescription>
        )}
        {Boolean(options.content) && <div style={{ marginBottom: 20 }}>{options.content}</div>}
        {Boolean(options.subtitle) && <EmptyStateSubtitle>{options.subtitle}</EmptyStateSubtitle>}
        {!!options.links && (
          <div className="styled-empty-state-links">
            {options.links.map((link, index) =>
              link.isDocLink ? (
                <LinkDoc href={link.href} key={index}>
                  {link.name}
                </LinkDoc>
              ) : (
                <LinkArrow key={index} href={link.href} intent={link.intent}>
                  {link.name}
                </LinkArrow>
              )
            )}
          </div>
        )}

        {Boolean(options.refresh) && (
          <div className="styled-empty-state-links">
            <Button kind="inline" addOn="prefix" onClick={options.refresh}>
              <Icon icon="reload" />
              Refresh page
            </Button>
          </div>
        )}
      </NoDataContainer>
    )
  }
}
