// @flow
import {
  createPopper,
  type Instance,
  type OptionsGeneric,
  type StrictModifiers,
  type Modifier,
} from '@popperjs/core'
import * as React from 'react'

type ReactRef<T> = { current: T | null }

/*
  very rough helper to use popper : pass 2 element ref & profit
  could be improved with more config
*/
export const usePopper = (
  opt: $Shape<OptionsGeneric<StrictModifiers>>
): [ReactRef<any>, ReactRef<any>, Instance | null] => {
  const triggerRef = React.useRef<null | HTMLElement>(null)
  const popperRef = React.useRef<null | HTMLElement>(null)
  const popperInstanceRef = React.useRef<null | Instance>(null)
  React.useEffect(() => {
    if (triggerRef.current && popperRef.current) {
      popperInstanceRef.current = createPopper<StrictModifiers>(
        triggerRef.current,
        popperRef.current,
        opt
      )
    }
  }, [triggerRef, popperRef, opt])

  return [triggerRef, popperRef, popperInstanceRef.current]
}

export const sameWidth: Modifier<*, *> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    // $FlowFixMe
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  },
}

export const minWidth: Modifier<*, *> = {
  name: 'minWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width + 20}px`
  },
  effect: ({ state }) => {
    // $FlowFixMe
    state.elements.popper.style.minwidth = `${state.elements.reference.offsetWidth + 20}px`
  },
}
