// @flow

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentCompanySelector } from 'com.batch.redux/company'
import { type EspConfigurationRecord } from 'com.batch.redux/corelogic/records/esp-configuration.records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type FetchEspConfigurationeAction = {
  type: 'FETCH_ESP_CONFIGURATION',
  ...
}

type FetchEspConfigurationeSuccessAction = {
  type: 'FETCH_ESP_CONFIGURATION_SUCCESS',
  payload: EspConfigurationRecord,
  ...
}
type FetchEspConfigurationeFailureAction = {
  type: 'FETCH_ESP_CONFIGURATION_FAILURE',
  ...
}

export type FetchEspConfigurationActions =
  | FetchEspConfigurationeAction
  | FetchEspConfigurationeSuccessAction
  | FetchEspConfigurationeFailureAction

export const fetchEspConfiguration = (): DispatchExtraBoundFn<Promise<EspConfigurationRecord>> => {
  return async (dispatch, getState, { projectGateway }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    const company = currentCompanySelector(state)
    return promiseActionCreator({
      dispatch,
      promise: projectGateway.fetchEspConfiguration({
        company,
        project,
      }),
      actionName: 'FETCH_ESP_CONFIGURATION',
    })
  }
}
