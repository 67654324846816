// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { type MessagingEventRecord } from 'com.batch/profile/models/event.records'
import { ChannelBadgeContainer } from 'com.batch/profile/ui/components/events-block/event-row/event-row.styles'

export const statusConfig = {
  SENT: {
    icon: null,
    label: 'sent',
    variant: 'neutral',
  },
  DELIVERED: {
    icon: 'inbox',
    label: 'delivered',
    variant: 'success',
  },
  OPEN: {
    icon: 'view',
    label: 'opened',
    variant: 'action',
  },
  CLICK: {
    icon: 'click',
    label: 'clicked',
    variant: 'feature',
  },
}

const channelConfig = {
  email: {
    icon: 'mail',
    label: 'Email',
  },
  sms: {
    icon: 'sms',
    label: 'SMS',
  },
  push: {
    icon: 'push',
    label: 'Push',
  },
}

type Props = {
  event: MessagingEventRecord,
}

export const ChannelBadge = ({ event }: Props): React.Node => {
  const config = channelConfig[event.channel]
  const status = statusConfig[event.metric]
  return (
    <ChannelBadgeContainer variant={status.variant}>
      <Icon icon={status?.icon ?? config.icon} />
      {config.label} {status?.label}
    </ChannelBadgeContainer>
  )
}
