// @flow

import { findChannelFromActionType } from 'com.batch/profilebase/infra/formats/find-channel-from-action-type'
import {
  ChannelsDistributionStateFactory,
  type ChannelsDistributionStateRecord,
} from 'com.batch/profilebase/models/sub-distribution.records'
import {
  type FetchWebpushSubDistributionActions,
  type FetchPushSubDistributionActions,
  type FetchSmsSubDistributionActions,
  type FetchEmailSubDistributionActions,
} from 'com.batch/profilebase/usecases/fetch-sub-distribution'
import { STATUS } from 'constants/common'

const initialState = ChannelsDistributionStateFactory()

type ChannelsDistributionActions =
  | FetchWebpushSubDistributionActions
  | FetchPushSubDistributionActions
  | FetchSmsSubDistributionActions
  | FetchEmailSubDistributionActions

export function ChannelsDistributionReducer(
  state: ChannelsDistributionStateRecord = initialState,
  action: ChannelsDistributionActions
): ChannelsDistributionStateRecord {
  switch (action.type) {
    case 'FETCH_EMAIL_SUB_DISTRIBUTION':
    case 'FETCH_PUSH_SUB_DISTRIBUTION':
    case 'FETCH_SMS_SUB_DISTRIBUTION':
    case 'FETCH_WEBPUSH_SUB_DISTRIBUTION': {
      const channel = findChannelFromActionType(action.type)
      return state.set(channel, state.get(channel).set('loadingState', STATUS.LOADING))
    }

    case 'FETCH_PUSH_SUB_DISTRIBUTION_SUCCESS':
    case 'FETCH_WEBPUSH_SUB_DISTRIBUTION_SUCCESS': {
      const channel = findChannelFromActionType(action.type)
      return state.set(
        channel,
        state
          .get(channel)
          .set('loadingState', STATUS.LOADED)
          .set('distribution', action.payload.subDistribution)
      )
    }

    case 'FETCH_EMAIL_SUB_DISTRIBUTION_SUCCESS':
    case 'FETCH_SMS_SUB_DISTRIBUTION_SUCCESS': {
      const channel = findChannelFromActionType(action.type)
      return state.set(
        channel,
        state.get(channel).set('loadingState', STATUS.LOADED).set('distribution', action.payload)
      )
    }

    case 'FETCH_EMAIL_SUB_DISTRIBUTION_FAILURE':
    case 'FETCH_PUSH_SUB_DISTRIBUTION_FAILURE':
    case 'FETCH_SMS_SUB_DISTRIBUTION_FAILURE':
    case 'FETCH_WEBPUSH_SUB_DISTRIBUTION_FAILURE': {
      const channel = findChannelFromActionType(action.type)
      return state.set(channel, state.get(channel).set('loadingState', STATUS.ERROR))
    }

    default:
      return state
  }
}
