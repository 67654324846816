// @flow

import * as React from 'react'

import { MediaUrlHintContainer, MediaUrlTag } from './media-url.styles'

type MediaUrlHintProps = {
  minHeight?: number,
  minWidth?: number,
  ratio?: number,
  isPersonnalizationEnabled: boolean,
  allowedMedia: Array<'image' | 'audio' | 'video'>,
  ...
}

export const MediaUrlHint = ({
  allowedMedia,
  minHeight,
  isPersonnalizationEnabled,
  minWidth,
}: MediaUrlHintProps): React.Node => {
  const onlyImages = allowedMedia.length === 1 && allowedMedia.includes('image')
  return (
    <MediaUrlHintContainer>
      <div>
        <div className="styled-med-url-hint-title">
          <div>Upload an image ...</div>
          <div>
            <MediaUrlTag dark>png</MediaUrlTag>
            <MediaUrlTag dark>jpg</MediaUrlTag>
          </div>
        </div>
        <p>Use an image from your computer.</p>
        {minWidth !== 0 && minHeight !== 0 ? (
          <p>
            Width & height must be at least {minWidth}px & {minHeight}px
          </p>
        ) : minWidth !== 0 ? (
          <p>Width must be at least {minWidth}px</p>
        ) : (
          minHeight !== 0 && <p>Height must be at least {minHeight}px</p>
        )}
      </div>
      <div>
        <div className="styled-med-url-hint-title">... or use a URL</div>
        {onlyImages ? (
          <p>
            {isPersonnalizationEnabled
              ? 'You can also personalize your image URL by adding Custom Data or Trigger event data.'
              : 'You can also provide an URL to the image you want to use.'}
          </p>
        ) : (
          <React.Fragment>
            <p> By providing a URL, you can add an image, audio or video/gif.</p>
            {isPersonnalizationEnabled && (
              <p>
                You can also personalize your URL by inserting either Custom data or Trigger event
                data.
              </p>
            )}
          </React.Fragment>
        )}
      </div>
    </MediaUrlHintContainer>
  )
}
