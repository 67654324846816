// @flow

export const mapLinkName = (linkName: string): string => {
  switch (linkName) {
    case 'batch::unsub':
      return 'Batch unsubscribe link'
    default:
      return linkName
  }
}
