// @flow
import { type Set, type Map } from 'immutable'

import { type MessageStateRecord } from 'com.batch/message/store/message.state'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'
import { type UserRecord } from 'com.batch.redux/user.records'

import { type Orchestration } from 'com.batch/orchestration/infra/types/orchestration.types'
import { parseOrchestrationCampaign } from 'com.batch/orchestration-campaign/infra/parses/orchestration-campaign.parse'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { parseOrchestrationJourney } from 'com.batch/orchestration-journey/infra/parses/orchestration-journey.parse'
import {
  type JourneySettingsRecord,
  type JourneyNodeRecord,
} from 'com.batch/orchestration-journey/models/journey.records'

type queryToParse = { queryId: string, query: string, eventId: string, retries: number, ... }
export type OrchestrationJourneyParserResult = {
  settings: JourneySettingsRecord,
  nodes: Map<string, JourneyNodeRecord>,
  rootId: string,
  queries: Array<queryToParse>,
  targets: Map<string, TargetStateRecord>,
  message: MessageStateRecord,
  state: campaignStateType,
  channels: Set<ChannelUntilCleanup>,
  incomplete: boolean,
  id: string,
  name: string,
  labelCodes: Set<string>,
  createdByApi: boolean,
}

export type OrchestrationCampaignParserResult = {
  campaign: OrchestrationCampaignRecord,
  queries: Array<queryToParse>,
  targets: Map<string, TargetStateRecord>,
  message: MessageStateRecord,
  state: campaignStateType,
  channels: Set<ChannelUntilCleanup>,
  incomplete: boolean,
  id: string,
  name: string,
  labelCodes: Set<string>,
  createdByApi: boolean,
}
export type OrchestrationParserResult =
  | OrchestrationJourneyParserResult
  | OrchestrationCampaignParserResult

type OrchestrationParser = ({
  orchestration: Orchestration,
  user: UserRecord,
}) => OrchestrationParserResult

export const parseOrchestration: OrchestrationParser = ({ orchestration, user }) => {
  if (orchestration.journey) {
    return parseOrchestrationJourney({
      journey: orchestration.journey,
      config: orchestration.dashboardConfig,
      state: orchestration.orchestrationState ?? 'DRAFT',
      channels: orchestration.orchestrationChannels,
      id: orchestration.id ?? '',
      user,
      name: orchestration.name,
      labelCodes: orchestration.journey.settings.labels ?? [],
      orchestrationSource: orchestration.orchestrationSource
    })
  } else if (orchestration.campaign) {
    return parseOrchestrationCampaign({
      campaign: orchestration.campaign,
      user,
      messages: orchestration.messages,
      config: orchestration.dashboardConfig,
      state: orchestration.orchestrationState ?? 'DRAFT',
      channels: orchestration.orchestrationChannels,
      id: orchestration.id ?? '',
      name: orchestration.name,
      labelCodes: orchestration.campaign.content.labels ?? [],
      orchestrationSource: orchestration.orchestrationSource,
    })
  }

  throw new Error('Unhandled orchestration kind')
}
