// @noflow
// ────────────────────────────────────────────────────────────────────────────────
// SUBSCRIBES TO ACTIONS, AND TRIGGERS NEW ACTIONS
// ────────────────────────────────────────────────────────────────────────────────

import { ofType } from 'redux-observable'
import { merge, of, timer } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, debounce, filter, map, switchMap, takeUntil } from 'rxjs/operators' // eslint-disable-line no-unused-vars

import { generateUrl } from 'com.batch.common/router'

import {
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGNS_INIT,
  UPDATE_CAMPAIGN_CONFIG,
} from 'com.batch.redux/action-name'
import { actions } from 'com.batch.redux/campaign'

const NB_PER_PAGE = 10
// ────────────────────────────────────────────────────────────────────────────────
export const loadCampaignsEpic = (action$, state$) => {
  let debounceDelay = 500
  return action$.pipe(
    filter(action => {
      // overcomplicated stuff to prevent trigger on an already loaded page
      if (action.type === FETCH_CAMPAIGNS_INIT) {
        debounceDelay = 100 // we debounce 0.1s
        return true
      } else if (action.type === UPDATE_CAMPAIGN_CONFIG) {
        const { config, previousConfig } = action.payload
        const hasEqualsFilters = config.delete('page').equals(previousConfig.delete('page'))
        debounceDelay = hasEqualsFilters ? 100 : 500 // we debounce 0.5s if we switch page
        return !(
          hasEqualsFilters && state$.value.campaign.getIn(['idsPerPage', config.get('page')], false)
        )
      }
      return false
    }),
    debounce(() => timer(debounceDelay)),
    switchMap(() => {
      const st = state$.value
      const config = st.campaign.get('config')
      const page = config.get('page')
      const query = config.filters.query
      const sortBy = config.get('sortBy')
      const sortOrder = config.get('sortOrder')
      return merge(
        of(actions.fetchCampaigns()),
        ajax({
          url: generateUrl('api_push_campaigns', {
            appId: st.app.getIn(['current', 'id']),
            type: config.get('schedulingType') === 'campaigns' ? 'ONE_SHOT' : 'AUTOMATION',
            filters: {
              name: query,
              from: config.getIn(['filters', 'dateRange', 'from'])
                ? config.getIn(['filters', 'dateRange', 'from']).format('YYYY-MM-DD')
                : null,
              to: config.getIn(['filters', 'dateRange', 'to'])
                ? config.getIn(['filters', 'dateRange', 'to']).format('YYYY-MM-DD')
                : null,
              status: config.filters.commons
                .filter(f => f.category === 'status')
                .map(f => f.name)
                .toArray(),
              sources: config.filters.commons
                .filter(f => f.category === 'sources')
                .map(f => f.name)
                .toArray(),
              when: config.filters.commons
                .filter(f => f.category === 'when')
                .map(f => f.name)
                .toArray(),
              channels: config.filters.commons
                .filter(f => f.category === 'channels')
                .map(f => f.name)
                .toArray(),
              labels: config.getIn(['filters', 'labels']).toArray(),
            },
            sortBy: sortBy,
            sortOrder: sortOrder,
            range: `${(page - 1) * NB_PER_PAGE}-${page * NB_PER_PAGE}`,
          }),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          method: 'GET',
        }).pipe(
          map(e => {
            const response = e.response
            if (!Object.prototype.hasOwnProperty.call(response, 'results')) {
              return actions.fetchCampaignsFailure(response)
            } else {
              return actions.fetchCampaignsSuccess({
                entities: response.results,
                countFiltered: response.countFiltered,
                countTotal: response.countTotal,
                page,
              })
            }
          }),
          catchError(error => {
            return of(actions.fetchCampaignsFailure(error))
          }),
          takeUntil(action$.pipe(ofType(FETCH_CAMPAIGNS))), // aborts the XHR on new request
          catchError(error => actions.fetchCampaignsFailure(error))
        )
      )
    })
  )
}
