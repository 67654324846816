// @flow

import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'
import { stroke } from 'components/styled/tokens/colors'

export const NoData: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  background: #f3f3f3;
  border: 1px dashed ${stroke};
  box-shadow: 0px 1px 4px rgba(50, 54, 57, 0.02);
  height: 366px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
  font-weight: 500;
  text-align: center;
  font-size: 13px;
  color: ${colors.textLight};
  .styled-title {
    font-size: 14px;
    line-height: 24px;
    color: ${colors.textNeutral};
  }
`
