// @flow

import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

export type DurationUnit = 'HOURS' | 'DAYS'

type CappingRuleProps = {
  labelCode: string,
  durationValue: number,
  durationUnit: DurationUnit,
  capping: number,
  isPersisted: boolean,
  ...
}

export type CappingRuleRecord = RecordOf<CappingRuleProps>

export const CappingRuleFactory: RecordFactory<CappingRuleProps> = Immutable.Record(
  ({
    labelCode: '',
    durationValue: 1,
    durationUnit: 'HOURS',
    capping: 1,
    isPersisted: false,
  }: CappingRuleProps)
)
