// @flow

import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'
import { computeRepeatWord } from 'com.batch.common/utils'

import { FooterContainer } from './fake-omni-footer.styles'

import { type State } from 'com.batch.redux/_records'
import { saveProjectCampaign } from 'com.batch.redux/campaign.action'
import { currentCampaign, currentCampaignAppsSelector } from 'com.batch.redux/campaign.selector'
import { campaignNameSelector } from 'com.batch.redux/content.selector'
import { campaignErrorSelector } from 'com.batch.redux/project-error.selector'
import { formatFakeMultiPushCampaignForAPI } from 'com.batch.redux/project.api'
import { getAPIQueryForIdSelector } from 'com.batch.redux/query/query.selector'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'

const contentSelector = (state: State) => state.content
const targetSelector = (state: State) => state.target.get('default', TargetStateFactory())

type FakeOmniFooterProps = {}

export const FakeOmniFooter: React.ComponentType<FakeOmniFooterProps> = React.memo(() => {
  const [saving, setSaving] = React.useState<Set<'live' | 'draft'>>(Immutable.Set())
  const dispatch = useDispatch()
  const errors = useSelector(campaignErrorSelector)
  const content = useSelector(contentSelector)
  const campaign = useSelector(currentCampaign)
  const apps = useSelector(currentCampaignAppsSelector)
  const campaignName = useSelector(campaignNameSelector)
  const getQuery = useSelector(getAPIQueryForIdSelector)
  const targeting = useSelector(targetSelector)
  const user = useSelector(currentUserSelector)
  const schedulingTypeFromUrl = useGetSchedulingTypeFromUrl()

  const save = React.useCallback(
    (live: boolean) => {
      if (saving.size > 0) return
      setSaving(saving => saving.add(live ? 'live' : 'draft'))
      // turfu form en mode push, qui va créer x campaign legcay
      const apiCampaign = formatFakeMultiPushCampaignForAPI({
        campaign,
        apps,
        live,
        user,
        content,
        query: getQuery('targeting'),
        targeting,
      })

      dispatch(saveProjectCampaign(apiCampaign)).then(
        () => {
          const app = apps.first()
          setSaving(saving => saving.remove(live ? 'live' : 'draft'))

          window.location.href = generateUrl('campaign_list', {
            companyId: app.companyId,
            appId: app.id,
            schedulingType: 'campaigns',
          })
        },
        err => {
          setSaving(saving => saving.remove(live ? 'live' : 'draft'))
          console.error(err)
        }
      )
    },
    [saving, apps, campaign, content, dispatch, getQuery, user, targeting]
  )

  const message = React.useMemo(() => {
    return (
      <React.Fragment>
        <p>
          You are about to save and run the <ConfirmHighlight>{campaignName}</ConfirmHighlight>
          {schedulingTypeFromUrl === 'campaigns' ? 'campaign' : 'automation'}.
        </p>

        <p>
          {(() => {
            const sendType = campaign.sendType
            if (sendType === 'now' || !campaign.start) {
              return (
                <React.Fragment>
                  It will start <ConfirmHighlight>right now</ConfirmHighlight>. A started campaign
                  cannot be stopped
                </React.Fragment>
              )
            } else if (sendType === 'scheduled' || sendType === 'recurring') {
              return (
                <React.Fragment>
                  It will start on{' '}
                  <ConfirmHighlight>
                    {campaign.start && campaign.start.format('dddd, MMMM D, YYYY')} at{' '}
                    {campaign.start && campaign.start.format('HH:mm')}
                  </ConfirmHighlight>
                  {sendType === 'recurring' && (
                    <React.Fragment>
                      {' '}
                      every{' '}
                      <ConfirmHighlight>
                        {computeRepeatWord(
                          campaign.repeatUnit,
                          campaign.repeatFrequency,
                          campaign.start
                        )}
                      </ConfirmHighlight>
                      {campaign.end && (
                        <React.Fragment>
                          {' '}
                          and it will end on{' '}
                          <ConfirmHighlight>
                            {campaign.end && campaign.end.format('dddd, MMMM D, YYYY')} at{' '}
                            {campaign.end && campaign.end.format('HH:mm')}
                          </ConfirmHighlight>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {campaign.tzAware ? " in the user's timezone" : ' UTC'}
                </React.Fragment>
              )
            }
          })()}
          .
        </p>
      </React.Fragment>
    )
  }, [
    campaignName,
    campaign.sendType,
    campaign.start,
    campaign.end,
    campaign.repeatUnit,
    campaign.repeatFrequency,
    campaign.tzAware,
    schedulingTypeFromUrl,
  ])

  const confirmSaveAndRun = React.useCallback(() => {
    confirm({
      title: 'Save and run campaign',
      message: message,
      confirm: 'Send',
    }).then(
      () => {
        save(true)
      },
      () => {}
    )
  }, [message, save])

  const saveAsDraft = React.useCallback(() => save(false), [save])

  return (
    <FooterContainer>
      <span style={{ flexGrow: 1 }} />
      <span style={{ color: colors.textDanger, cursor: 'default' }}>
        {errors.size > 0 && (
          <Tooltip
            isTooltipEmpty={errors.size === 0}
            minWidth={320}
            offset={[0, 10]}
            tooltip={
              <div style={{ textAlign: 'left' }}>
                <h2
                  style={{
                    fontSize: '1.2em',
                    borderBottom: '1px dotted #FFF',
                    paddingBottom: 4,
                  }}
                >
                  Please fix the following errors:
                </h2>
                {errors
                  .map((errArray, group) => ({ err: errArray, group }))
                  .toList()
                  .map(({ err, group }) => {
                    return (
                      <div key={group} style={{ marginTop: 7 }}>
                        <strong>{group}</strong>
                        <ul style={{ marginLeft: 20 }}>
                          {err.map((e, k) => (
                            <li key={k}>{e}</li>
                          ))}
                        </ul>
                      </div>
                    )
                  })}
              </div>
            }
          >
            <span>
              {errors.size} error{errors.size > 1 ? 's' : ''} &nbsp;
              <Icon icon="help" />
            </span>
          </Tooltip>
        )}
      </span>

      <Grid template="auto auto">
        {['NEW', 'DRAFT'].includes(campaign.state) && (
          <Button
            onClick={saveAsDraft}
            disabled={errors.size > 0 || saving.size > 0}
            isLoading={saving.includes('draft')}
          >
            {campaign.state === 'DRAFT' ? 'Update' : 'Save'} draft
          </Button>
        )}
        <Button
          kind="primary"
          intent="action"
          onClick={confirmSaveAndRun}
          disabled={errors.size > 0 || saving.size > 0 || campaign.state === 'COMPLETED'}
          isLoading={saving.includes('live')}
        >
          {campaign.state === 'NEW' && <React.Fragment>Save &amp; run</React.Fragment>}
          {(campaign.state === 'RUNNING' || campaign.state === 'STOPPED') && (
            <React.Fragment>Update</React.Fragment>
          )}
          {campaign.state === 'DRAFT' && <React.Fragment>Update &amp; run</React.Fragment>}
          {campaign.state === 'COMPLETED' && <React.Fragment>Completed campaign</React.Fragment>}
        </Button>
      </Grid>
    </FooterContainer>
  )
})

FakeOmniFooter.displayName = ' FakeOmniFooter'
