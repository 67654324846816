// @flow

import { useState, useCallback } from 'react'

export type StateToggler = {
  value: boolean,
  toggle: () => void,
  open: () => void,
  close: () => void,
}

export function useToggle(init: boolean = false): StateToggler {
  const [bool, setBoolean] = useState(init)
  const toggle = useCallback(() => {
    setBoolean(bool => !bool)
  }, [])
  const close = useCallback(() => setBoolean(false), [])
  const open = useCallback(() => setBoolean(true), [])
  return { value: bool, toggle, close, open }
}
