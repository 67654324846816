// @flow

export const findChannelFromActionType = (
  actionType: string
): 'email' | 'push' | 'sms' | 'webpush' => {
  if (actionType.includes('EMAIL')) return 'email'
  if (actionType.includes('WEBPUSH')) return 'webpush'
  if (actionType.includes('SMS')) return 'sms'
  return 'push'
}
