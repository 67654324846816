// @flow
import Immutable, { type RecordFactory, type RecordOf, type List } from 'immutable'

import { type LabelRecord } from '../models/labels.records'

type LabelStateProps = {
  labels: List<LabelRecord>,
  loadingState: LoadingState,
  ...
}

export const LabelStateFactory: RecordFactory<LabelStateProps> = Immutable.Record(
  ({
    labels: new Immutable.List(),
    loadingState: 'INIT',
  }: LabelStateProps)
)

export type LabelStateRecord = RecordOf<LabelStateProps>
