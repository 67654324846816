// @flow
import React from 'react'

import { confirm } from 'components/common/confirm'

export const useLeaveConfirmation = ({
  isPristine,
  runtimeCheck = () => true,
}: {
  isPristine: boolean,
  runtimeCheck?: () => boolean,
  ...
}): ({ confirmBeforeAction: () => Promise<boolean>, ... }) => {
  // Promise à appeler avant ses actions de close, e.g: avant de faire un popinState.close sur une modale avec un ToggleState
  const confirmBeforeAction = React.useCallback(() => {
    return new Promise(resolve => {
      if (isPristine && runtimeCheck()) {
        // Le form n'a pas été touché : on confirm la sortie de suite
        resolve(true)
      } else {
        // Le form a été touché :
        confirm({
          title: 'Leave this page?',
          message: <p>Changes that you made may be lost.</p>,
          confirm: 'Yes, leave it',
          sensitive: true,
        })
          .then(() => resolve(true))
          .catch(() => resolve(false))
      }
    })
  }, [isPristine, runtimeCheck])

  const onBeforeUnload = React.useCallback(
    event => {
      if (!isPristine || !runtimeCheck()) {
        event.preventDefault()
        event.returnValue = true
      }
    },
    [isPristine, runtimeCheck]
  )
  // Dès que isPristine change, on register ou non la popup de confirm leave native
  React.useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [onBeforeUnload])

  return { confirmBeforeAction }
}
