// @flow

import {
  ProfilesDistributionStateFactory,
  type ProfilesDistributionStateRecord,
} from 'com.batch/profilebase/models/profiles-distribution.records'
import { type FetchProfilesDistributionActions } from 'com.batch/profilebase/usecases/fetch-profiles-distribution'
import { type FetchPushSubDistributionActions } from 'com.batch/profilebase/usecases/fetch-sub-distribution'
import { STATUS } from 'constants/common'

const initialState = ProfilesDistributionStateFactory()

type ProfilesDistributionAction = FetchProfilesDistributionActions | FetchPushSubDistributionActions

export function profilesDistributionReducer(
  state: ProfilesDistributionStateRecord = initialState,
  action: ProfilesDistributionAction
): ProfilesDistributionStateRecord {
  switch (action.type) {
    case 'FETCH_PROFILES_DISTRIBUTION':
      return state.set('loadingState', STATUS.LOADING)
    case 'FETCH_PROFILES_DISTRIBUTION_SUCCESS':
      return state
        .set('loadingState', STATUS.LOADED)
        .set(
          'distribution',
          state.distribution
            .set('total', action.payload.total)
            .set('email', action.payload.email)
            .set('phoneNumber', action.payload.phoneNumber)
            .set('install', action.payload.install)
            .set('customId', action.payload.customId)
            .set('push', action.payload.push)
            .set('webpush', action.payload.webpush)
            .set('orphans', action.payload.orphans)
        )
    case 'FETCH_PROFILES_DISTRIBUTION_FAILURE':
      return state.set('loadingState', STATUS.ERROR)

    case 'FETCH_PUSH_SUB_DISTRIBUTION_SUCCESS':
      return state.set(
        'distribution',
        state.distribution.set('importedTokens', action.payload.importedTokens)
      )
    case 'FETCH_PUSH_SUB_DISTRIBUTION_FAILURE':
      return state.set('distribution', state.distribution.set('importedTokens', 0))
    default:
      return state
  }
}
