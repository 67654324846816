// @flow
import * as React from 'react'

import {
  type OrchestrationAnalyticsDateRangeRecord,
  type OrchestrationAnalyticsFilters,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

export const useUpdateFiltersUrl = ({
  filters,
  dateRange,
  isAutomation,
}: {
  filters: OrchestrationAnalyticsFilters,
  dateRange: ?OrchestrationAnalyticsDateRangeRecord,
  isAutomation: boolean,
}): void => {
  React.useEffect(() => {
    // Apply filters to URL
    let qs = new URLSearchParams(window.location.search)
    if (dateRange) {
      qs.set('from', dateRange.from.format('YYYY-MM-DD'))
      qs.set('to', dateRange.to.format('YYYY-MM-DD'))
    } else if (isAutomation) {
      // Nécessaire pour pouvoir restaurer le fait qu'il n'y ait pas de filtre date range
      qs.set('from', '')
      qs.set('to', '')
    }

    if (filters.size > 0) {
      filters.forEach((values, key) => {
        qs.set(key, values.toArray().join(','))
      })
    }
    history.pushState(null, '', '?' + qs.toString())
  }, [filters, dateRange, isAutomation])
}
