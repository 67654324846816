// @flow

import Immutable from 'immutable'
import * as React from 'react'

import { useToggle } from 'components/_hooks/use-toggle'
import { Separator } from 'components/app/custom-data/custom-data.styles'
import { Box, BoxHeader, HeaderBoxTitle, HeaderBoxActions } from 'components/common/box'
import { Button } from 'components/common/button'
import { LinkDoc, Title } from 'components/styled/text'

import { SettingsCommon } from './settings-common'
import { SettingsWebPushCommon } from './settings-webpush-common'
import { SettingsWebPushIcons } from './settings-webpush-icons'
import { SettingsWebPushSafari } from './settings-webpush-safari'
import { AllowedDevOrigins } from './webpush/allowed-dev-origins'

import { type PushConfigRecord, type AppRecord } from 'com.batch.redux/_records'
import { openIntegrate } from 'com.batch.redux/app.action'
import { type MessageType } from 'com.batch.redux/toaster'

type SettingsWebPushProps = {
  config: PushConfigRecord,
  advanced: boolean,
  app: AppRecord,
  webpushAuth: string,
  openIntegrate: typeof openIntegrate,
  savePushConfig: (config: PushConfigRecord, file: ?File, password: ?string) => Promise<void>,
  showToast: MessageType => any,
  ...
}

const commonOptions = Immutable.Set(['ttl'])

export const SettingsWebPush: React.ComponentType<SettingsWebPushProps> = React.memo(
  ({
    config,
    advanced,
    webpushAuth,
    app,
    openIntegrate,
    savePushConfig,
    showToast,
  }: SettingsWebPushProps) => {
    const safariTabState = useToggle(false)
    return (
      <React.Fragment>
        <Title overEmptyState>Web Push Settings</Title>
        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="Website Push configuration" />
            {advanced && (
              <HeaderBoxActions>
                <LinkDoc href="https://doc.batch.com/web/overview" intent="action" target="_blank">
                  Help
                </LinkDoc>
                <Separator style={{ marginLeft: 0 }} />
                <Button
                  kind="inline"
                  intent="neutral"
                  style={{ margin: 0 }}
                  isActive={!safariTabState.value}
                  onClick={safariTabState.close}
                >
                  Chrome, Firefox...
                </Button>
                <Button
                  kind="inline"
                  style={{ margin: 0 }}
                  intent="neutral"
                  isActive={safariTabState.value}
                  onClick={safariTabState.open}
                >
                  Safari APNS
                </Button>
              </HeaderBoxActions>
            )}
          </BoxHeader>

          {!safariTabState.value ? (
            <SettingsWebPushCommon
              config={config}
              advanced={advanced}
              webpushAuth={webpushAuth}
              openIntegrate={openIntegrate}
              savePushConfig={savePushConfig}
              showToast={showToast}
            />
          ) : (
            <SettingsWebPushSafari
              app={app}
              config={config}
              openIntegrate={openIntegrate}
              showToast={showToast}
            />
          )}
        </Box>

        {advanced && (
          <React.Fragment>
            <SettingsWebPushIcons config={config} app={app} />
            <AllowedDevOrigins config={config} app={app} />
            <SettingsCommon
              config={config}
              updateConfig={savePushConfig}
              show={commonOptions}
              app={app}
              showToast={showToast}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
)
