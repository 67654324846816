// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'
import { type FilterVariant } from 'components/filter/filter-select/filter-select'
import * as colors from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'

type Props = {
  variant: FilterVariant,
  isOptionSelected: boolean,
}

export const FilterButton: StyledComponent<Props, *, typeof Button> = styled(Button)`
  ${({ variant }: Props) =>
    variant === 'filled' &&
    `
    background-color: ${schemes.darklucent['20']};
    > span {
      color: ${colors.text};
    }
    &:hover {
      background-color: ${schemes.darklucent['20']};
      > span {
        color: ${colors.text};
      }
    }
  `}
  & > span {
    display: flex;
    align-items: center;
  }
`
