// @flow

import { get as _get } from 'lodash-es'
import request from 'superagent-interface-promise'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'

import { DataExportJobFactory, type DataExportJobRecord } from './console.records'

import { type AppRecord } from 'com.batch.redux/_records'

// ====================== FECTCH SPECIFIC EXPORT JOB
export const fetchSpecificExportJobs = ({
  app,
  requestId,
}: {
  app: AppRecord,
  requestId: number,
  ...
}): void => {
  return request.get(generateUrl('console_api_export_job', { appId: app.id, requestId })).then(
    response => console.log(response),
    error => console.log(error)
  )
}
// Normalize export Job
export const normalizeExportJob = (raw: {
  id: string,
  uri: string,
  request_date: string,
  status: string,
  request: {
    ids: Array<'install_id' | 'custom_id' | 'advertising_id'>,
    form?: string,
    to?: string,
    events: Array<
      'push_sent' | 'push_open' | 'push_error' | 'inapp_shown' | 'inapp_clicked' | 'inapp_closed',
    >,
    ...
  },
  ...
}): DataExportJobRecord => {
  let uriArray = _get(raw, 'uri').split('/')
  let kindExport = uriArray[4]
  let id = uriArray[4] !== 'userbase' ? uriArray[5] : ''
  const rawFrom = _get(raw, 'request.from', false)
  const rawTo = _get(raw, 'request.to', false)
  return DataExportJobFactory({
    entity: kindExport,
    kind: null,
    identifier: id,
    id: _get(raw, 'id'),
    status: _get(raw, 'status'),
    uri: _get(raw, 'uri'),
    requestDate: dayjs.utc(_get(raw, 'request_date')),
    events: _get(raw, 'request.events'),
    ids: _get(raw, 'request.ids'),
    from: rawFrom ? dayjs.utc(rawFrom) : null,
    to: rawTo ? (rawTo === 'now' ? 'now' : dayjs.utc(rawTo)) : null,
  })
}
