// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Fliper, FliperContainer } from 'components/common/fliper'
import { createEventTracker, trackEvent } from 'components/common/page-tracker'
import { Icon, IconContainer } from 'components/common/svg-icon'
import { InputContainer } from 'components/form'
import { stroke, good } from 'components/styled/colors'
import { Link } from 'components/styled/text'

import { RegisterBlock } from './register.styles'

export type Register2FARecoveryProps = {
  codes: string[],
  active?: boolean,
  ...
}

export const Register2FARecovery = ({
  codes,
  active = true,
}: Register2FARecoveryProps): React.Node => {
  const [copied, setCopied] = React.useState(false)

  const $input = React.useRef(null)

  const _onCopy = React.useCallback((e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault()

    if ($input.current) $input.current.select()
    document.execCommand('copy')
    trackEvent('2FA_CODES_COPY')
    if ($input.current) $input.current.blur()

    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }, [])

  return (
    <RegisterBlock active={active}>
      <h3>Save your emergency recovery codes</h3>
      <p>
        The codes below will be necessary to access your account if you can't confirm your second
        authentication factor.
        <br /> Each of these codes can only be used once.
      </p>
      <CodesContainer>
        <ul>
          {codes.map((c, i) => (
            <Code key={i}>{c}</Code>
          ))}
        </ul>
        <textarea defaultValue={codes.join('\n')} ref={$input} />
      </CodesContainer>
      <RecoveryActions>
        <Fliper>
          {copied ? (
            <CopiedMessage>
              <Icon icon="check" /> Copied to the clipboard
            </CopiedMessage>
          ) : (
            <Link style={{ display: 'block', width: '100%' }} onClick={_onCopy}>
              Copy to the clipboard <Icon icon="copy" style={{ margin: '0 4px 0 6px' }} />
            </Link>
          )}
        </Fliper>
        <Link
          download="recovery_codes.txt"
          onClick={createEventTracker('2FA_CODES_DL')}
          href={`data:text/plain;charset=utf-8,${encodeURIComponent(codes.join('\n'))}`}
        >
          Download as .txt <Icon icon="download" />
        </Link>
      </RecoveryActions>
    </RegisterBlock>
  )
}

export const Code: StyledComponent<*, *, HTMLElement> = styled.li`
  position: relative;
  padding: 0.8em 1em;

  &:before,
  &:after {
    position: absolute;
    content: '';
  }
  &:before {
    bottom: -1px;
    left: 10px;
    right: 10px;
    border-bottom: 1px solid ${stroke.lighter};
  }
  &:after {
    right: -1px;
    top: 8px;
    bottom: 8px;
    width: 0px;
    border-right: 1px solid ${stroke.lighter};
  }
`

export const CodesContainer: StyledComponent<*, *, typeof InputContainer> = styled(InputContainer)`
  position: relative;
  margin: 24px -2px 18px -2px;
  border-radius: 3px;
  overflow: hidden;

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  ${Code} {
    display: block;
    width: 50%;
  }

  textarea {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }
`

export const CopiedMessage: StyledComponent<*, *, HTMLElement> = styled.span`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: ${good.shade.s4};
  cursor: default;

  ${IconContainer} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin: -5px 7px -5px 0;
    padding: 0 0 2px 0;
    font-size: 10px;
    background-color: ${good.shade.s1};
    border-radius: 8px;

    path {
      stroke: #fff;
      stroke-width: 2px;
    }
  }
`

export const RecoveryActions: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -8px 0;
  padding: 0 1px;

  > ${FliperContainer}, ${Link} {
    width: 50%;
  }
`

export default Register2FARecovery
