// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { getVariantLabel } from 'com.batch.common/utils'

import { getPushContentError, getPushSettingsErrors } from './push.selector.helper'

import {
  getLangFromContent,
  type MessageIdGetterSelector,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { type State } from 'com.batch.redux/_records'

import { getMessageIds, getMessageKey } from 'com.batch/message/models/message.helper'
import {
  PushMessageFactory,
  PushContentFactory,
  type PushMessageRecord,
  type PushContentRecord,
  type PushSettingsRecord,
} from 'com.batch/message/models/message.records'

export const getPushMessageSelector =
  (state: State): (string => PushMessageRecord) =>
  messageId =>
    state.message.push.getIn([messageId], PushMessageFactory())

export const getPushSettingsSelector: State => ({
  stepMessageNodeId: ?string,
}) => PushSettingsRecord = createSelector(
  getMessageConfigSelector,
  getMessageConfig =>
    ({ stepMessageNodeId }) =>
      getMessageConfig({ stepMessageNodeId }).pushSettings
)

export const getPushContentSelector: MessageIdGetterSelector<Map<string, PushContentRecord>> =
  createSelector(
    getPushMessageSelector,
    getPushMessage => messageId =>
      getPushMessage(messageId).get('localizedContent', Immutable.Map())
  )

export const getPushContentForActiveLanguageSelector: MessageIdGetterSelector<PushContentRecord> =
  createSelector(
    getPushContentSelector,
    previewLanguageSelector,
    (getData, langId) => messageId => {
      const data = getData(messageId)
      return data.get(langId ?? 'default', PushContentFactory())
    }
  )

type getErrorsForMessage = ({ stepMessageNodeId: ?string }) => Map<string, Array<string>>
export const getPushErrorsSelector: State => getErrorsForMessage = createSelector(
  getPushMessageSelector,
  getMessageConfigSelector,
  (getMessage, getConfig) =>
    ({ stepMessageNodeId }) => {
      const config = getConfig({ stepMessageNodeId })
      const settings = config.pushSettings
      const abEnabled = config.experiment.enabled
      const messageTypedIdList = getMessageIds(config)
      const settingsErrors = getPushSettingsErrors(settings)
      const contentErrors: Array<[string, Array<string>]> = []
      messageTypedIdList.forEach((messageId, index) => {
        const message = getMessage(messageId)
        const localizedContent = message.get('localizedContent', Immutable.Map())
        const languages = getLangFromContent(localizedContent, config.multilanguageEnabled)
        languages.forEach(lang => {
          const contentForLang = localizedContent.get(lang.value, PushContentFactory())
          let localErrors = getPushContentError(contentForLang)
          if (localErrors.length > 0)
            contentErrors.push([
              getMessageKey(lang) + (abEnabled ? ` ${getVariantLabel(index)}` : ''),
              localErrors,
            ])
        })
      })
      return Immutable.Map([
        ...(settingsErrors.length > 0 ? [['Settings', settingsErrors]] : []),
        ...contentErrors,
      ])
    }
)
