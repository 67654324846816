// @flow

import React from 'react'
import { useDispatch } from 'react-redux'

import { showToast } from 'com.batch.redux/toaster'

export const useCreateNotifier = ({
  notificationText,
  kind,
}: {
  notificationText: string,
  kind: 'success' | 'error',
  ...
}): (() => void) => {
  const dispatch = useDispatch()

  return React.useCallback(() => {
    dispatch(showToast({ message: notificationText, kind }))
  }, [dispatch, kind, notificationText])
}
