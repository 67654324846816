// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  orchestrationAnalyticsDateRangeSelector,
  orchestrationAnalyticsFiltersSelector,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import {
  type OrchestrationAnalyticsDateRangeRecord,
  type OrchestrationAnalyticsFilters,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

export const useGetAnalyticsFilters = (): {
  filters: OrchestrationAnalyticsFilters,
  dateRange: ?OrchestrationAnalyticsDateRangeRecord,
} => {
  const { token }: { token: string } = useParams()
  const getAnalyticsFilters = useSelector(orchestrationAnalyticsFiltersSelector)
  const filters = React.useMemo(() => getAnalyticsFilters(token), [getAnalyticsFilters, token])
  const getAnalyticsDateRange = useSelector(orchestrationAnalyticsDateRangeSelector)
  const dateRange = React.useMemo(
    () => getAnalyticsDateRange(token),
    [getAnalyticsDateRange, token]
  )

  return { filters, dateRange }
}
