// @flow
import Immutable, { type List } from 'immutable'

import { type FetchReachabilityTimeserieResponse } from 'com.batch/profilebase/infra/ucp-data-service.interface'
import {
  type ReachabilityTimeserieRecord,
  ReachabilityTimeserieFactory,
  type ReachabilityCounterRecord,
  ReachabilityCounterFactory,
} from 'com.batch/profilebase/models/reachability-timeserie.records'

export type ParseReachabilityResponse = {
  counter: ReachabilityCounterRecord,
  timeserie: List<ReachabilityTimeserieRecord>,
}

export const parseReachabilityResponse = (
  response: FetchReachabilityTimeserieResponse
): ParseReachabilityResponse => {
  const reachabilitiesTimeSeries =
    response.emailReachability && response.emailReachability.reachabilities
      ? response.emailReachability.reachabilities
      : response.smsReachability && response.smsReachability.reachabilities
        ? response.smsReachability.reachabilities
        : response.pushReachability && response.pushReachability.reachabilities
          ? response.pushReachability.reachabilities
          : []

  return {
    counter: ReachabilityCounterFactory({
      subscriptionsLastMonth: response.totalSubscriptions,
      unsubscriptionsLastMonth: response.totalUnsubscriptions,
    }),
    timeserie: reachabilitiesTimeSeries.reduce((acc, value) => {
      return acc.push(
        ReachabilityTimeserieFactory({
          subscriptions: value.subscriptions ?? value.optIns ?? 0,
          unsubscriptions: value.unsubscriptions ?? value.optOuts ?? 0,
          date: value.date,
          period: value.period,
        })
      )
    }, new Immutable.List()),
  }
}
