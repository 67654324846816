// @flow
import { type PushPlatformFilterType } from 'com.batch/shared/infra/types/push-platform-filter.type'

export const PUSH_PLATFORM_FILTER: Array<PushPlatformFilterType> = [
  {
    filter: 'all-mobile',
    icon: 'mobile',
    kind: 'categorie',
    label: 'Mobile app',
    title: 'App push',
    value: 'PLATFORM_FILTER_UNSPECIFIED',
  },
  {
    filter: 'ios',
    icon: 'ios',
    kind: 'subCategorie',
    label: 'iOS only',
    title: 'iOS push',
    value: 'PLATFORM_FILTER_IOS',
  },
  {
    filter: 'android',
    icon: 'android',
    kind: 'subCategorie',
    label: 'Android only',
    title: 'Android push',
    value: 'PLATFORM_FILTER_ANDROID',
  },
  {
    filter: 'webpush',
    icon: 'world',
    kind: 'categorie',
    label: 'Website',
    title: 'Web push',
    value: 'PLATFORM_FILTER_ANDROID',
  },
]
