// @flow

import { NavLink } from 'react-router-dom'
import styled, { css, type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'

const ItemStyle = css`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1 0 auto;
  flex-direction: ${(props: any) => (props.column ? 'column' : 'row')};
  margin: ${(props: any) => (props.theme.collapsed ? '0' : '0 2px')};
  padding: ${(props: any) => (props.tiny ? '3px' : props.theme.padding)};
  justify-content: center;
  font-size: ${(props: any) => props.theme.fs}px;
  font-weight: ${(props: any) => props.theme.fw};
  color: ${(props: any) => (props.active ? props.theme.active.fg : props.theme.default.fg)};
  background-color: ${(props: any) =>
    props.active ? props.theme.active.bg : props.theme.default.bg};
  letter-spacing: ${(props: any) => props.theme.letterSpacing};
  text-transform: ${(props: any) => props.theme.textTransform};
  text-align: center;
  line-height: ${(props: any) => props.theme.fs + 4}px;
  border: none;
  border-radius: ${(props: any) => (props.theme.collapsed ? 0 : props.theme.br)}px;
  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};
  outline: none;
  cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props: any) => (props.disabled && !!props.to ? 'none' : 'auto')};
  ${(props: any) =>
    !props.active &&
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        color: ${props.theme.hover.fg};
        background-color: ${props.theme.hover.bg};
      }
    `}

  i {
    display: ${(props: any) => (props.column ? 'block' : 'inline-block')};
    margin: ${(props: any) => (props.column ? '4px 8px 4px 8px' : '0 8px 0 0')};
  }
  ${IconContainer} {
    margin: 0 -2px;
  }

  &:first-child {
    margin-left: 0;
    border-radius: ${(props: any) =>
      props.theme.collapsed
        ? `${props.theme.br}px 0 0 ${props.theme.br}px`
        : `${props.theme.br}px`};
  }
  &:last-child {
    margin-right: 0;
    border-radius: ${(props: any) =>
      props.theme.collapsed
        ? `0 ${props.theme.br}px ${props.theme.br}px 0`
        : `${props.theme.br}px`};
  }
`

export const ToggleButton: StyledComponent<*, *, HTMLElement> = styled.button`
  ${ItemStyle}
`
export const ToggleLink: StyledComponent<*, *, typeof NavLink> = styled(NavLink)`
  ${ItemStyle}
  &.active {
    color: ${(props: any) => props.theme.active.fg};
    background-color: ${(props: any) => props.theme.active.bg};
  }
`
type ToggleContainerProps = { width?: number, inline?: boolean, ... }
export const ToggleContainer: StyledComponent<ToggleContainerProps, *, HTMLElement> = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height: 36px;
  justify-content: space-evenly;
  width: ${(props: ToggleContainerProps) =>
    props.width ? `${props.width}px` : props.inline ? 'auto' : '100%'};
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.02);
`
