// @flow
import * as new_colors from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'

export const normal = {
  fs: 11,
  fw: 600,
  collapsed: true,
  padding: '9px 16px',
  br: 6,
  textTransform: 'uppercase',
  letterSpacing: '0.6px',
  active: {
    bg: new_colors.fillAction,
    fg: new_colors.textContrast,
  },
  hover: {
    bg: new_colors.opacifyHover,
    fg: new_colors.textNeutral,
  },
  default: {
    bg: schemes.darklucent['05'],
    fg: new_colors.textLight,
  },
}

export const filter = {
  fs: 12,
  fw: 600,
  collapsed: false,
  padding: '9px 14px',
  br: 3,
  textTransform: 'none',
  letterSpacing: '0.4px',
  active: {
    bg: 'hsla(214, 24%, 19%, 0.05)',
    fg: 'hsl(214, 25%, 12%)',
  },
  hover: {
    bg: 'transparent',
    fg: 'hsl(214, 25%, 20%)',
  },
  default: {
    bg: 'transparent',
    fg: 'hsl(214, 7%, 47%)',
  },
}
