// @flow
import styled, { css, type StyledComponent } from 'styled-components'

export const Scrollable: StyledComponent<*, { ... }, *> = styled.div`
  overflow-y: auto;
  height: 264px;
  width: 560px;
  @media (min-width: 750px) {
    min-width: 600px;
  }

  label {
    display: flex;
    width: 100%;
  }

  .styled-option-label {
    display: flex;
    width: 100%;
  }

  padding: 10px 8px 8px 8px;
  ${(props: { sep?: boolean, ... }) =>
    props.sep &&
    css`
      border-bottom: 1px solid #f2f3f6;
    `};
`

export const LabelCount: StyledComponent<*, { ... }, *> = styled.span`
  padding: 0 0 0 10px;
  color: #9fa5b5;
  justify-content: right;
`

export const LabelRow: StyledComponent<*, { ... }, *> = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
`

export const NewLabelRow: StyledComponent<*, { ... }, *> = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  font-weight: 500;
  color: #707070;
  padding: 8px 12px;

  :hover {
    background-color: rgba(15, 15, 15, 0.08);
    border-radius: 6px;
  }

  :active {
    background-color: rgba(15, 15, 15, 0.12);
    border-radius: 6px;
  }
`

export const LabelSearch: StyledComponent<*, { ... }, *> = styled.span`
  color: black;
`

export const LabelDescription: StyledComponent<*, { ... }, *> = styled.span`
  flex: 1;
  justify-content: left;
`

export const SearchContainer: StyledComponent<*, { ... }, *> = styled.div`
  padding: 8px 10px 8px 10px;
  width: 100%;
  border-bottom: 1px solid rgba(15, 15, 15, 0.12);
`
