// @flow
import { validateUrl } from 'com.batch.common/utils'

type validatorType = (value: any) => ?string

const required: validatorType = value =>
  value && (typeof value !== 'string' || value.length > 0) ? undefined : 'Required'

const maxLength: (max: number) => validatorType = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const minLength: (min: number) => validatorType = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined

const number: validatorType = (value: ?any) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined

const email: validatorType = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

const url: validatorType = value => (value && !validateUrl(value) ? 'Invalid URL' : undefined)

const json: validatorType = value => {
  if (!value) return undefined
  try {
    JSON.parse(value)
    return undefined
  } catch (err) {
    return 'Invalid JSON'
  }
}

export default {
  required,
  number,
  email,
  json,
  url,
  max50: (maxLength(50): validatorType),
  min3: (minLength(3): validatorType),
}
