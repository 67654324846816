// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { devkitUUID } from 'com.batch.common/utils'

import { showToast } from 'com.batch.redux/toaster'

type IframeListenerProps = {
  src: string,
  trackingId?: string,
  title: string,
  ...
}

export const IframeListener = ({
  src,
  trackingId,
  title,
  ...rest
}: IframeListenerProps): React.Node => {
  const dispatch = useDispatch()
  const ref = React.useRef<?HTMLIFrameElement>(null)
  const reply = React.useCallback(
    (taskID, error, result) => {
      ref.current?.contentWindow.postMessage(
        {
          from: 'batch-in_app-sdk-callback',
          taskID,
          error,
          result,
        },
        '*'
      )
    },
    [ref]
  )

  const dispatchDebugToast = React.useCallback(
    (message: string) => {
      dispatch(showToast({ message, kind: 'info', icon: 'infos' }))
    },
    [dispatch]
  )

  const handleBatchMessage = React.useCallback(
    event => {
      const eventData = event.data
      if (
        eventData !== null &&
        typeof eventData === 'object' &&
        eventData.from === 'batch-in_app-sdk'
      ) {
        const method = eventData.method
        const args = eventData.args
        const taskID = eventData.taskID
        console.info(taskID, method, args)
        // All Batch messages require a reply
        // Some will only require an identifier
        // Others require a more complex payload
        // Ones that don't really expect an answer can get anything, so send "ok"
        // Note: undefined (or null) can be replied

        /* On affiche l'analytics ID et le nom de l'action (et non le nom de la fonction js) en priorité
- si deeplink : 
exemple 1 
*Opens link with "analyticsid" in internal web browser*
exemple 2 
*Opens link with "analyticsid" in a new window*
exemple 3 
*Opens link without tracking id set*
*internal web browser* si `li:true` et *a new window* si `li:false` et si pas d'argument on affiche rien
- si dismiss : 
*Dismisses In-App message with "analyticsid"*

peut être un ellipsis au bout de x caractères ?
Et ne pas oublier le CTA cf figma pour ouvrir la console log (in a new window) ? */

        let response: ?string = 'ok'
        let knownMethod = true
        switch (method.toLowerCase()) {
          case 'performaction':
            if (args.name === 'batch.deeplink') {
              dispatchDebugToast(
                `Opens link ${args.analyticsID ? ` with "${args.analyticsID}"` : ''} ${
                  typeof args.li !== 'undefined'
                    ? args.li
                      ? 'in internal web browser'
                      : 'in a new window'
                    : ''
                }`
              )
            } else if (args.name === 'batch.dismiss') {
              dispatchDebugToast(
                `Dismisses In-App message ${args.analyticsID ? ` with "${args.analyticsID}"` : ''}`
              )
            } else {
              dispatchDebugToast(
                `Performs ${args.name}${args.analyticsID ? ` with ${args.analyticsID}` : ''}`
              )
            }
            break
          case 'opendeeplink':
            dispatchDebugToast(
              `Opens link ${args.analyticsID ? ` with "${args.analyticsID}"` : ''} ${
                args.openInApp ? 'in internal web browser' : 'in a new window'
              }`
            )
            break
          case 'dismiss':
            dispatchDebugToast(
              `Dismisses In-App message ${args.analyticsID ? ` with "${args.analyticsID}"` : ''}`
            )
            break
          case 'getcustomuserid':
            response = undefined
            break
          case 'getattributionid':
          case 'getinstallationid':
            response = devkitUUID()
            break
          case 'gettrackingid':
            response = !trackingId ? trackingId : 'no_tracking_id_set'
            break
          case 'getcustompayload':
            response = JSON.stringify({
              foo: 'bar',
              utm_source: 'batch',
            })
            break
          case 'getcustomlanguage':
            response = (navigator?.language ?? 'en').substr(0, 2)
            break
          case 'getcustomregion':
            response = (navigator?.language ?? 'en-US').substr(3)
            break
          default:
            knownMethod = false
            break
        }
        reply(taskID, knownMethod ? undefined : `unknown method ${method}`, response)
      }
    },
    [dispatchDebugToast, reply, trackingId]
  )

  React.useEffect(() => {
    window.addEventListener('message', handleBatchMessage)
    return () => {
      window.removeEventListener('message', handleBatchMessage)
    }
  }, [handleBatchMessage])

  // eslint-disable-next-line react/iframe-missing-sandbox
  return <iframe {...rest} ref={ref} src={src} border="0" title={title} />
}
