// @flow

import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { ToggleContainer, ToggleButton, ToggleLink } from './tabletoggle.styles'
import * as themes from './tabletoggle.theme'

type TableToggleProps = {
  children: React.Node,
  width?: number,
  kind?: 'normal' | 'filter',
  className?: string,
  style?: { [string]: string | number, ... },
  ...
}

type TableToggleItemProps = {
  children: React.Node,
  active?: boolean,
  disabled?: boolean,
  exact?: boolean,
  to?: string,
  isActive?: () => boolean,
  tiny?: boolean,
  sec?: boolean,
  col?: boolean,
  onClick?: any => any,
  className?: string,
  style?: { [string]: string | number, ... },
  ...
}

export const TableToggle = ({
  children,
  kind = 'normal',
  width,
  className,
  style,
}: TableToggleProps): React.Node => {
  return (
    <ThemeProvider theme={themes[kind]}>
      <ToggleContainer width={width} className={className} style={style}>
        {children}
      </ToggleContainer>
    </ThemeProvider>
  )
}

export const TableToggleItem: React.ComponentType<TableToggleItemProps> = React.forwardRef<
  TableToggleItemProps,
  *,
>((props: TableToggleItemProps, forwardedRef) => {
  const buttonRef = React.useRef(null)
  // this hook allows forwardedRef to point at buttonRef
  React.useImperativeHandle(
    forwardedRef,
    () => (buttonRef && buttonRef.current ? buttonRef.current : null),
    [buttonRef]
  )
  const _onClick = React.useCallback(
    (e: SyntheticEvent<HTMLElement>) => {
      if (buttonRef.current) {
        buttonRef.current.blur()
      }
      if (props.onClick && !props.disabled) {
        props.onClick(e)
      }
    },
    [props]
  )
  const { children, active, disabled, tiny, style, col, to, exact, isActive, sec, className } =
    props
  if (to) {
    return (
      <ToggleLink to={to} end={exact} isActive={isActive} onClick={_onClick} style={style}>
        {children}
      </ToggleLink>
    )
  }
  return (
    <ToggleButton
      style={style}
      active={active}
      disabled={disabled}
      column={col}
      ref={buttonRef}
      tiny={tiny}
      sec={sec}
      onClick={_onClick}
      className={className}
    >
      {children}
    </ToggleButton>
  )
})
