// @flow

import Immutable, {
  type Map,
  type List,
  type OrderedSet,
  type OrderedMap,
  type RecordFactory,
  type RecordOf,
} from 'immutable'
import { createSelector } from 'reselect'

import { textUsesTemplating } from 'com.batch.common/utils'

import {
  type TemplateRecord,
  type TemplateResultRecord,
  type DispatchBoundFn,
  type Dispatch,
  type State,
  type EstimateRecord,
  TemplateFactory,
} from 'com.batch.redux/_records'

type extract<T> = State => T

// =========== INITIAL STATE
type TemplateStateProps = {
  cache: Map<string, TemplateRecord>,
  keyForField: OrderedMap<string, string>,
  loading: boolean,
  pinnedInstallIds: OrderedSet<string>,
  preferedInstallId: ?string,
}
const TemplateStateFactory: RecordFactory<TemplateStateProps> = Immutable.Record(
  ({
    cache: Immutable.Map(),
    loading: false,
    keyForField: Immutable.OrderedMap(),
    pinnedInstallIds: Immutable.OrderedSet(),
    preferedInstallId: null,
  }: TemplateStateProps)
)

export type TemplateState = RecordOf<TemplateStateProps>
const initialState: TemplateState = TemplateStateFactory()

// =========== SELECTORS

export const TemplateStateSelector = (state: State): TemplateState => state.template
export const InstallIdSelector: extract<?string> = createSelector(
  TemplateStateSelector,
  s => s.preferedInstallId
)
export const TemplateCacheSelector: extract<Map<string, TemplateRecord>> = createSelector(
  TemplateStateSelector,
  s => s.cache
)

export const PreferredTemplateCache: extract<Map<string, TemplateRecord>> = createSelector(
  TemplateCacheSelector,
  InstallIdSelector,
  (cache, id) => {
    // on trie les résultats & on met celle qui match l'install en prems
    return cache.map(templateResult =>
      templateResult.set(
        'results',
        templateResult.results.sort(resultA => (resultA.installId === id ? -1 : 1))
      )
    )
  }
)

// =========== ACTIONS
type TemplateParseAction = { payload: TemplateRecord, type: 'PARSE_TEMPLATE', ... }
export type TemplateFailAction = {
  payload: TemplateRecord,
  type: 'PARSE_TEMPLATE_FAILURE',
  ...
}
type TemplateSuccessAction = {
  payload: TemplateRecord,
  type: 'PARSE_TEMPLATE_SUCCESS',
  ...
}
type TemplateCheckAction = {
  payload: {
    id: string,
    text: ?string,
    ...
  },
  type: 'SET_TEMPLATE_FOR_FIELD',
  ...
}
export const setTemplateForField: (id: string, text: string) => DispatchBoundFn<any> =
  (id, text) => (dispatch, getState) => {
    const hasTemplate = textUsesTemplating(text)
    const state = getState()
    const computedValue = hasTemplate ? text : null
    if (state.template.keyForField.get(id) !== computedValue) {
      dispatch({
        type: 'SET_TEMPLATE_FOR_FIELD',
        payload: {
          text: computedValue,
          id,
        },
      })
    }
    if (hasTemplate && !state.template.cache.get(text, false)) {
      dispatch(parseTemplate(text))
    }
  }
export const parseTemplate: (text: string) => TemplateParseAction = text => {
  return {
    type: 'PARSE_TEMPLATE',
    payload: TemplateFactory({
      text,
      error: null,
      parsing: true,
      results: new Immutable.List(),
    }),
  }
}
export const parseTemplateFailed: (text: string, error: string) => TemplateFailAction = (
  text,
  error
) => {
  return {
    type: 'PARSE_TEMPLATE_FAILURE',
    payload: TemplateFactory({
      text,
      error,
      parsing: false,
      results: new Immutable.List(),
    }),
  }
}
export const parseTemplateSucceeed: (
  text: string,
  results: List<TemplateResultRecord>
) => TemplateSuccessAction = (text, results) => {
  return {
    type: 'PARSE_TEMPLATE_SUCCESS',
    payload: TemplateFactory({
      text,
      error: null,
      parsing: false,
      results,
    }),
  }
}

type TemplateChangePreferedInstallAction = {
  payload: {
    installId: string,
    ...
  },
  type: 'TEMPLATE_SET_INSTALL',
  ...
}

export const changePreferedInstall: (installId: string) => (dispatch: Dispatch) => void =
  installId => dispatch => {
    dispatch(ToggleFakeLoader(true))
    setTimeout(() => {
      dispatch(ToggleFakeLoader(false))
      dispatch({
        type: 'TEMPLATE_SET_INSTALL',
        payload: { installId },
      })
    }, 250)
  }

type TargetingEstimateSuccessAction = {
  payload: {
    sample_debug?: Array<string>,
    ...
  },
  type: 'TARGETING_ESIMATE_SUCCESS',
  ...
}

type ToggleFakeLoaderAction = {
  payload: boolean,
  type: 'TEMPLATE_FAKE_LOADER',
  ...
}

export const ToggleFakeLoader: (loading: boolean) => ToggleFakeLoaderAction = loading => {
  return {
    type: 'TEMPLATE_FAKE_LOADER',
    payload: loading,
  }
}

type TemplateActions =
  | TemplateParseAction
  | TargetingEstimateSuccessAction
  | TemplateCheckAction
  | TemplateFailAction
  | TemplateSuccessAction
  | TemplateChangePreferedInstallAction
  | ToggleFakeLoaderAction
  | {
      payload: EstimateRecord,
      type: 'TARGETING_ESTIMATE_SUCCESS',
      ...
    }

// =========== REDUCER

const templateReducer: (state: TemplateState, action: TemplateActions) => TemplateState = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'TEMPLATE_SET_INSTALL':
      return state.set('preferedInstallId', action.payload.installId)
    // case 'TARGETING_ESTIMATE_SUCCESS':
    //   if (
    //     typeof action.payload.sample_debug == 'undefined' ||
    //     !Array.isArray(action.payload.sample_debug) ||
    //     action.payload.sample_debug.length === 0
    //   ) {
    //     return state
    //   } else {
    //     return state.set(
    //       'pinnedInstallIds',
    //       state.pinnedInstallIds.add(action.payload.sample_debug[0])
    //     )
    //   }
    case 'SET_TEMPLATE_FOR_FIELD':
      if (action.payload.text) {
        return state.setIn(['keyForField', action.payload.id], action.payload.text)
      } else {
        return state.deleteIn(['keyForField', action.payload.id])
      }
    case 'TEMPLATE_FAKE_LOADER':
      return state.set('loading', action.payload)
    case 'PARSE_TEMPLATE_SUCCESS':
    case 'PARSE_TEMPLATE_FAILURE':
    case 'PARSE_TEMPLATE':
      if (action.type === 'PARSE_TEMPLATE_SUCCESS') {
        let ids: OrderedSet<string> = Immutable.OrderedSet()
        let preferedInstallId = state.preferedInstallId
        let found = false
        action.payload.results
          .filter(row => !!row.installId)
          .forEach(row => {
            ids = ids.add(row.installId)
            if (!preferedInstallId) {
              preferedInstallId = row.installId
            }
            if (preferedInstallId === row.installId) {
              found = true
            }
          })
        // if installIds changed, we need to set a new preferedInstallId
        if (!found && ids.size) {
          preferedInstallId = ids.first()
        }
        state = state.set('pinnedInstallIds', ids).set('preferedInstallId', preferedInstallId)
      }
      return state
        .setIn(['cache', action.payload.text], action.payload)
        .set('loading', action.type === 'PARSE_TEMPLATE')
    default:
      return state
  }
}
export default templateReducer
