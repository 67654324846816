// @flow

import { type List } from 'immutable'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

export const getAdditionnalsData = (
  apiKeys: List<{ key: string, platform: 'ios' | 'android' | 'webpush' }>
): Promise<ApiKeyAdditionalData[]> => {
  const promises = apiKeys.map(apiKey => {
    return fetchAdditionnalData(apiKey.key, apiKey.platform)
  })

  return Promise.all(promises).then(results => {
    const validResults = results.filter(result => result !== undefined)

    return Promise.resolve(validResults)
  })
}
type boolValue = { boolValue: boolean }
type intValue = { int64Value: string }
type unsetValue = {}
type stringValues = { stringValues: string[] }

type AdditionalDataValues = boolValue | intValue | stringValues | unsetValue

type ApiKeyAdditionalData = {
  apiKey: { key: string, platform: 'ios' | 'android' | 'webpush', ... },
  additionalData: {
    values: { [string]: AdditionalDataValues, ... },
    ...
  },
  ...
}

type FetchAdditionalDataResponse = {
  apiKeyAdditionalData: ApiKeyAdditionalData,
  ...
}

type FetchAdditionnalDataFn = (apiKey: string, platform: Platforms) => Promise<ApiKeyAdditionalData>

export const fetchAdditionnalData: FetchAdditionnalDataFn = async (
  apiKey: string,
  platform: Platforms
) => {
  return request
    .post<FetchAdditionalDataResponse>(
      generateUrl('api_grpc_metadata_service', { methodName: 'GetAdditionalData' }),
      {
        apiKey: { key: apiKey, platform: platform.toUpperCase() },
        skipCache: true,
      }
    )
    .then(({ apiKeyAdditionalData }) => {
      return Promise.resolve(apiKeyAdditionalData)
    })
}

type SaveAdditionalDataFn = (
  valuesToSave: {
    apiKey: string,
    platform: Platforms,
    source: string,
    value: boolean,
    ...
  }[]
) => Promise<void>

export const saveAdditionalData: SaveAdditionalDataFn = (valuesToSave): Promise<any> => {
  const promises = valuesToSave.map(values => {
    return setAdditionalData({
      apiKey: values.apiKey,
      platform: values.platform.toUpperCase(),
      key: values.source,
      value: values.value,
    })
  })

  return Promise.all(promises)
}

type SetAdditionalDataFn = ({
  apiKey: string,
  platform: string,
  key: string,
  value: boolean,
  ...
}) => Promise<void>
export const setAdditionalData: SetAdditionalDataFn = ({ apiKey, platform, key, value }) => {
  return request.post(
    generateUrl('api_grpc_metadata_service', { methodName: 'SetAdditionalData' }),
    {
      apikey: { key: apiKey, platform: platform },
      key,
      value: { boolValue: value },
    }
  )
}
