// @flow
import { type Map } from 'immutable'

import { type ReduxAction } from 'com.batch.redux/_records'

import { type JourneyNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'

export type SetNodesAction = ReduxAction<
  'SET_JOURNEY_NODES',
  { nodes: Map<string, JourneyNodeRecord>, rootId: string, ... },
>

export const setNodes = ({
  nodes,
  rootId,
}: {
  nodes: Map<string, JourneyNodeRecord>,
  rootId: string,
  ...
}): SetNodesAction => {
  const action: SetNodesAction = {
    type: 'SET_JOURNEY_NODES',
    payload: { nodes, rootId },
  }

  return action
}
