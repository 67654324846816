// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { ButtonLink } from 'components/common/button'
import Loader from 'components/common/loader-legacy'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { generateOrchestrationUrl } from 'com.batch.common/router'

import {
  ReplicateInfo,
  PickableApp,
  PickableAppName,
  PickableAppIcon,
  PickableAppPlatform,
  PickableStatus,
  PickableAction,
} from './replicate.styles'

import { type ReplicationRecord } from 'com.batch.redux/_records'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'

type ReplicateReviewProps = {
  results: List<ReplicationRecord>,
  ...
}

const ReplicateReview = ({ results }: ReplicateReviewProps): React.Node => {
  const nbOk = results.filter(r => !r.error).size
  const maybeProject = useSelector(optionalCurrentProjectSelector)
  return (
    <div>
      {nbOk === 0 ? (
        <ReplicateInfo>
          <h6 style={{ color: 'crimson' }}>We were unable to replicate this campaign</h6>
          <p>You can over the red cross to see why the replication failed.</p>
        </ReplicateInfo>
      ) : (
        <ReplicateInfo>
          <h6>You're not done yet!</h6>
          <p>
            We tried to replicate this campaign on {results.size} app{results.size > 1 && 's'}.{' '}
            {results.size > 1 ? 'These campaigns were created ' : 'This campaign was created '}
            as drafts. <br />
            You will need to review {results.size > 1 ? 'them' : 'it'} before sending.
          </p>
        </ReplicateInfo>
      )}
      {results.map((res, index) => {
        return (
          <PickableApp key={index}>
            <PickableAppIcon icon={res.icon}>
              <Icon icon={res.platform !== 'webpush' ? 'mobile' : 'computer'} size={12} />
            </PickableAppIcon>
            <PickableAppPlatform>
              {res.platform && <Icon icon={res.platform} size={9} />}
            </PickableAppPlatform>
            <PickableAppName>{res.name}</PickableAppName>
            <PickableStatus>
              {res.loading ? (
                <Loader loading size="tiny" left />
              ) : res.error ? (
                <Tooltip tooltip={res.error}>
                  <Icon icon="error" size={12} color="#DF465F" />
                </Tooltip>
              ) : (
                <Icon icon="success" size={12} color="#93C271" />
              )}
            </PickableStatus>
            <PickableAction>
              <ButtonLink
                kind="primary"
                intent="action"
                href={generateOrchestrationUrl({
                  page: 'form',
                  projectId: maybeProject?.id,
                  channel: { appId: parseInt(res.appId) },
                  companyId: res.companyId ?? 0,
                  sendType:
                    res.kind === 'in-app'
                      ? 'in-app'
                      : res.schedulingType === 'automations'
                        ? 'trigger'
                        : 'scheduled',
                  token: res.token,
                })}
                disabled={res.loading || !!res.error}
                target="_blank"
              >
                Edit campaign
              </ButtonLink>
            </PickableAction>
          </PickableApp>
        )
      })}
    </div>
  )
}

export default ReplicateReview
