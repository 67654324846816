// @flow
import * as React from 'react'

type OpacityEditorProps = {
  onChange: number => void,
  value: number,
  ...
}

export const OpacityEditor = ({ value, onChange }: OpacityEditorProps): React.Node => {
  const [display, setDisplay] = React.useState<?number>(value)
  const [invalid, setInvalid] = React.useState(false)
  const update = React.useCallback(
    evt => {
      const val = parseInt(evt.target.value)
      if (!isNaN(val) && val > 0 && val <= 100) {
        onChange(val)
        setInvalid(false)
      } else {
        setInvalid(true)
      }
      setDisplay(evt.target.value)
    },
    [onChange]
  )
  return (
    <input
      type="number"
      style={{ paddingRight: '30px' }}
      onChange={update}
      value={display}
      className={`form-control v ${invalid ? 'ng-invalid ng-touched' : ''}`}
    />
  )
}
