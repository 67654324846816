// @flow

import { currentProjectSelector } from '../../../redux/project.selector'
import { type VariantId } from '../../message/models/message.records'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'

export type SelectExperimentWinnerFailureAction = {
  type: 'SELECT_EXPERIMENT_WINNER_FAILURE',
  payload: {
    error: {
      message: string,
    },
  },
}

export type SelectExperimentWinnerActions = SelectExperimentWinnerFailureAction

export const selectExperimentWinner =
  ({
    orchestrationId,
    variantId,
  }: {
    orchestrationId: string,
    variantId: VariantId,
  }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { orchestrationService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: orchestrationService.selectWinningVariant({
        project,
        orchestrationId,
        winningVariantId: variantId,
      }),
      actionName: 'SELECT_EXPERIMENT_WINNER',
    })
  }
