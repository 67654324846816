// @flow

import { type Dayjs } from 'dayjs'
import * as React from 'react'

import Hint from 'components/common/hint'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { formatPrice } from 'com.batch.common/utils'

import {
  PlanArea,
  PlanSection,
  PlanPriceSection,
  PlanName,
  PlanPrice,
  PlanFrequency,
  PlanTrialUntil,
  PlanSeats,
} from './plan.styles'

import { type PlanRecord } from 'com.batch.redux/billing.records'

type PlanProps = {
  plan: PlanRecord,
  autorenew: boolean,
  trial: ?PlanRecord,
  trialUntil: ?Dayjs,
  currency: 'usd' | 'eur',
  cycle: 'monthly' | 'yearly' | null,
  nextInvoice: ?Dayjs,
  cancelUnpaidAt: ?Dayjs,
  ...
}

export const Plan = ({
  plan,
  autorenew,
  nextInvoice,
  cancelUnpaidAt,
  cycle,
  currency,
  trial,
  trialUntil,
}: PlanProps): React.Node => {
  const price = currency === 'usd' ? plan.usd : plan.eur
  const now = dayjs.utc()
  const withTrial =
    trial !== null &&
    typeof trial !== 'undefined' &&
    trial.weight > plan.weight &&
    (!trialUntil || trialUntil.isAfter(now))
  const daysRemain = trialUntil ? Math.round(dayjs.duration(trialUntil.diff(now)).asDays()) : 0
  return (
    <React.Fragment>
      <PlanArea withTrial={withTrial}>
        <PlanSection>
          <PlanName>{plan.name}</PlanName>
          <PlanSeats>
            {nextInvoice &&
              !cancelUnpaidAt &&
              `${autorenew ? 'Next payment on' : 'Will be canceled on'} ${nextInvoice.format(
                'DD/MM/YYYY'
              )}`}
            {cancelUnpaidAt && `Will be canceled on ${cancelUnpaidAt.format('DD/MM/YYYY')}`}
          </PlanSeats>
        </PlanSection>
        {nextInvoice && (
          <PlanPriceSection>
            <PlanPrice>
              {price && cycle === 'monthly' && formatPrice(price.monthly, currency, true)}
              {price && cycle === 'yearly' && formatPrice(price.yearly, currency, true)}
            </PlanPrice>
            <PlanFrequency>{cycle}</PlanFrequency>
          </PlanPriceSection>
        )}
      </PlanArea>
      {withTrial && trial && (
        <PlanArea trial>
          <PlanSection>
            <PlanName>{trial.name} free trial</PlanName>
          </PlanSection>
          {trialUntil && (
            <PlanTrialUntil>
              {daysRemain} day{daysRemain > 1 && 's'} remaining
              <Hint>
                Your plan{' '}
                {plan.code !== trial.code ? `will switch to ${plan.name}` : 'billing will start '}{' '}
                on {trialUntil.format('DD/MM/YYYY')}
              </Hint>
            </PlanTrialUntil>
          )}
        </PlanArea>
      )}
    </React.Fragment>
  )
}
