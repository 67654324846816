// @flow
import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { LoadingSvgStrokeAnim } from 'components/common/empty-states'
import { color } from 'components/styled/placeholder'

type ThemeProps = { isLoading?: boolean, ... }
type LineChartPathProps = { color?: string, ... }
type ThemedLineChartProps = PropsWithTheme<LineChartPathProps, ThemeProps>
export const LineChartPath: StyledComponent<LineChartPathProps, ThemeProps, *> = styled.path`
  stroke: ${(props: ThemedLineChartProps) => props.color || color};
  stroke-width: 1.6px;
  fill: transparent;
  ${(props: ThemedLineChartProps) =>
    props.theme.isLoading &&
    css`
      animation-name: ${LoadingSvgStrokeAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`
export const LineChartArea: StyledComponent<
  { color?: string, ... },
  { isLoading: boolean, isEmpty: boolean, ... },
  *,
> = styled.path`
  fill: ${(
    props: PropsWithTheme<{ color?: string, ... }, { isEmpty: boolean, isLoading: boolean, ... }>
  ) => props.color || color};
  stroke: transparent;
  opacity: 0.13;
`

export const LineChartPointer: StyledComponent<{ ... }, { ... }, *> = styled.circle`
  r: 6px;
  stroke: #fefefe;
  stroke-width: 3px;
`

export const LineChartGuide: StyledComponent<{ ... }, { ... }, *> = styled.line`
  stroke: #e8eaed;
  stroke-dasharray: 5, 5;
`
