// @flow

import * as React from 'react'

import { Wrapper, type OverlayProps } from 'components/common/empty-states'

import { generateUrl } from 'com.batch.common/router'

type FilterContentProps = {
  children: React.Node,
  needUpgrade?: boolean,
  companyId?: number,
  emptyState?: OverlayProps,
  ...
}

export const FilterContent = ({
  children,
  needUpgrade,
  companyId,
  emptyState,
}: FilterContentProps): React.Node => {
  const links = React.useMemo(
    () => [
      {
        name: 'Upgrade your plan',
        href: generateUrl('company_billing', {
          companyId: companyId ?? 0,
        }),
        intent: 'action',
      },
    ],
    [companyId]
  )
  return (
    <Wrapper
      isEmpty={needUpgrade || !!emptyState}
      isOverlayShown={needUpgrade || !!emptyState}
      isLoading={false}
      overlayProps={
        !needUpgrade && emptyState
          ? emptyState
          : {
              style: { alignItems: 'center' },
              title: 'Feature activation',
              status: 'upgrade',
              links,
            }
      }
    >
      {children}
    </Wrapper>
  )
}
