// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type RecordOf, type RecordFactory, type List, type Map } from 'immutable'

import { type availableIcons } from 'components/common/svg-icon'

import * as Functions from './query.records.functions'
import { type FunctionRecord } from './query.records.functions'
import * as Operators from './query.records.operators'
import { type OperatorRecord } from './query.records.operators'
import { type OursQLType } from './query.types'

import { type AgeRecord, AgeFactory } from 'com.batch.redux/_records'

export type { FunctionRecord } from './query.records.functions'
export type { OperatorRecord } from './query.records.operators'
/* 
  we create a subtype of AttributeRecord, because we need both data and event data :
  - event data for trigger campaign event filtering
  - native / custom / user data for base query trageting
*/

export const allFunctions: List<FunctionRecord> = new Immutable.List().push(
  Functions.AgeFunction,
  Functions.DateFunction,
  Functions.NextBirthdayFunction,
  Functions.LastBirthdayFunction,
  Functions.IsNearFunction,
  Functions.CountFunction,
  Functions.CountSinceFunction
)

export const allOperators: List<OperatorRecord> = new Immutable.List().push(
  Operators.ExistsOperator,
  Operators.LowerOperator,
  Operators.LowerOrEqualOperator,
  Operators.EqualOperator,
  Operators.GreaterOrEqualOperator,
  Operators.GreaterOperator,
  Operators.AgeLowerOperator,
  Operators.AgeLowerOrEqualOperator,
  Operators.AgeEqualOperator,
  Operators.AgeGreaterOrEqualOperator,
  Operators.AgeGreaterOperator,
  Operators.DateLowerOperator,
  Operators.DateLowerOrEqualOperator,
  Operators.DateGreaterOrEqualOperator,
  Operators.DateGreaterOperator,
  Operators.StartsWithOperator,
  Operators.EndsWithOperator,
  Operators.InOperator,
  Operators.NotInOperator,
  Operators.ContainsOperator,
  Operators.ContainsAll,
  Operators.DoesNotContainSomeOperator,
  Operators.DoesNotContainAllOperator
)

type QueryAttributeProps = {
  api: string,
  label: string,
  icon: availableIcons,
  type: OursQLType,
  eventAttributes: List<QueryAttributeRecord>,
}
export const QueryAttributeFactory: RecordFactory<QueryAttributeProps> = Immutable.Record(
  ({
    api: '__UNSET__',
    label: 'string',
    icon: 'default',
    type: 'STRING',
    eventAttributes: new Immutable.List(),
  }: QueryAttributeProps)
)

export type QueryAttributeRecord = RecordOf<QueryAttributeProps>

export type InputType =
  | 'InputAge'
  | 'InputBoolean'
  | 'InputDate'
  | 'InputGoogleMap'
  | 'InputFloat'
  | 'InputInteger'
  | 'InputString'
  | 'InputDeviceList'
  | 'InputStringList'
  | 'InputPrettyList'
  | 'InputAudience'
  | 'InputSegment'

export type DateInputProps = {
  inputValue: string,
  hourValue: string,
  minuteValue: string,
  value: Dayjs | null,
  valid: boolean,
}

export const DateInputFactory: RecordFactory<DateInputProps> = Immutable.Record(
  ({
    inputValue: '',
    hourValue: '',
    minuteValue: '',
    value: null,
    valid: true,
  }: DateInputProps)
)

export type DateInputRecord = RecordOf<DateInputProps>

type ConditionInputProps = {
  number: number,
  string: string,
  boolean: boolean,
  stringList: List<string>,
  numberList: List<number>,
  age: AgeRecord,
  date: DateInputRecord,
  mode: InputType,
}
export const ConditionInputFactory: RecordFactory<ConditionInputProps> = Immutable.Record(
  ({
    number: NaN,
    string: '',
    boolean: true,
    stringList: new Immutable.List(),
    numberList: new Immutable.List(),
    age: AgeFactory({ unit: 'd' }),
    date: DateInputFactory(),
    mode: 'InputString',
  }: ConditionInputProps)
)
export type ConditionInputRecord = RecordOf<ConditionInputProps>

/* je fais le choix de figer pour typer ici
  on aurait aussi pu avoir une Map<string, any> rattaché à chque function
  mais vu l'usage actuel / envisagé sur le dashboard, je pense que ça suffit & que ça simplifiera 
  certains trucs
*/

type FunctionParamsProps = {
  lat: number,
  lng: number,
  radius: number,
  age: AgeRecord,
  campaignToken: string,
}
export const FunctionParamsFactory: RecordFactory<FunctionParamsProps> = Immutable.Record(
  ({
    lat: NaN,
    lng: NaN,
    radius: 500,
    age: AgeFactory(),
    campaignToken: '',
  }: FunctionParamsProps)
)

export type FunctionParamsRecord = RecordOf<FunctionParamsProps>

type ConditionProps = {
  operator: OperatorRecord,
  functions: List<FunctionRecord>,
  value: ConditionInputRecord,
  functionParams: ?FunctionParamsRecord,
  attribute: ?QueryAttributeRecord,
  eventFilters: List<ConditionRecord>,
  isEventFilterNegated: ?boolean,
}
export const ConditionFactory: RecordFactory<ConditionProps> = Immutable.Record(
  ({
    operator: Operators.ExistsOperator,
    functions: new Immutable.List(),
    value: ConditionInputFactory(),
    functionParams: null,
    attribute: null,
    eventFilters: new Immutable.List(),
    isEventFilterNegated: null,
  }: ConditionProps)
)

export type ConditionRecord = RecordOf<ConditionProps>

type LogicalNodeProps = {
  id: string,
  value: 'and' | 'or' | 'not',
  descendants: List<LogicalNodeRecord | string>,
}
export const LogicalNodeFactory: RecordFactory<LogicalNodeProps> = Immutable.Record(
  ({
    id: 'root',
    value: 'and',
    descendants: new Immutable.List(),
  }: LogicalNodeProps)
)

export type LogicalNodeRecord = RecordOf<LogicalNodeProps>

export type QueryProps = {
  conditions: Map<string, ConditionRecord>,
  tree: LogicalNodeRecord,
  eventId: string,
  error: string,
}
export const QueryFactory: RecordFactory<QueryProps> = Immutable.Record(
  ({
    conditions: Immutable.Map(),
    tree: LogicalNodeFactory(),
    eventId: '',
    error: '',
  }: QueryProps)
)

export type QueryRecord = RecordOf<QueryProps>
