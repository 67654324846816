// @flow

import * as React from 'react'

import { BoxInfo } from 'components/styled/blocs'
import { Link } from 'components/styled/text'

export const InitialNotice = (): React.Node => {
  return (
    <BoxInfo style={{ padding: '16px 20px' }}>
      <p>
        The Batch dashboard needs your FCM Sender ID and your FCM Server API Key. Both are available
        in your{' '}
        <Link intent="action" href="https://console.firebase.google.com" target="blank">
          Firebase Console
        </Link>
        .
      </p>
      <p>
        If you need to change your Sender ID but don't want to lose any token in the process, you
        can provide multiple Sender IDs, and Batch will use the appropriate one when sending
        notifications.
      </p>
    </BoxInfo>
  )
}
