/* eslint-disable react/jsx-no-bind */
// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import DayPicker from 'react-day-picker'
import { createPortal } from 'react-dom'

import {
  type dateShortcut,
  DatePickerShortcuts,
} from 'components/form/fields/date-picker/date-picker-shortcuts'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  DayElements,
  Caption,
  DatePickerNavbar,
  WeekdayElement,
  DatePickerPopoverContainer,
} from './date-picker.styles'

type DatePickerPopoverProps = {
  shortcuts?: List<dateShortcut>,
  datePikerProps: { disabledDays?: Date => boolean },
  position?: 'right' | 'left',
  value: ?Dayjs,
  setDay: Dayjs => void,
}

export const DatePickerPopover: React.ComponentType<DatePickerPopoverProps> = React.forwardRef(
  (
    { datePikerProps, value, position, setDay, shortcuts }: DatePickerPopoverProps,
    ref
  ): React.Node => {
    const valueDateFormat = React.useMemo(
      () => (!!value && value.isValid() ? value.toDate() : null),
      [value]
    )
    const activeDays = React.useMemo(
      () => new Immutable.List().push(...(value ? [value] : [])),
      [value]
    )

    let modalRoot = document.getElementById('select-root')
    if (!modalRoot) {
      modalRoot = document.createElement('div')
      modalRoot.setAttribute('id', 'select-root')
      document.body?.appendChild(modalRoot)
    }

    return modalRoot
      ? createPortal(
          <DatePickerPopoverContainer
            width={284}
            position={position}
            data-testid="DayPicker_dropdown"
            ref={ref}
          >
            <DayPicker
              {...datePikerProps}
              firstDayOfWeek={1}
              selectedDays={valueDateFormat}
              onDayClick={d => setDay(dayjs.utc(d).hour(0).minute(0))}
              weekdayElement={WeekdayElement}
              renderDay={DayElements}
              navbarElement={DatePickerNavbar}
              captionElement={Caption}
              month={valueDateFormat}
            />

            {!!shortcuts && (
              <DatePickerShortcuts
                shortcuts={shortcuts}
                setDay={({ day }) => setDay(day ?? dayjs())}
                activeDays={activeDays}
              />
            )}
          </DatePickerPopoverContainer>,
          modalRoot
        )
      : null
  }
)
