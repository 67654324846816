// @flow
import * as React from 'react'

import { Button } from 'components/common/button'
import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
import { ProfileHeroFooterItem } from 'com.batch/profile/ui/components/profile-hero/profile-hero-footer-item'
import {
  ProfileFooterContainer,
  Reachability,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { ProfilePlatformsCounter } from 'com.batch/profile/ui/components/profile-hero/profile-platforms-counter'

type Props = {
  isBodyShown: boolean,
  onToggle: (isToggled: boolean) => void,
  email: ?string,
  phoneNumber: ?string,
  platforms: Array<Platform>,
}

export const ProfileHeroFooter = ({
  onToggle,
  isBodyShown,
  email,
  phoneNumber,
  platforms,
}: Props): React.Node => {
  const handleOnClickToggle = React.useCallback(() => {
    onToggle(!isBodyShown)
  }, [isBodyShown, onToggle])
  return (
    <ProfileFooterContainer
      isBodyShown={isBodyShown}
      role="button"
      onClick={handleOnClickToggle}
      aria-expanded={isBodyShown}
      aria-controls="profile-hero"
    >
      {!isBodyShown && (
        <Reachability>
          <Skeleton w={130} h={19}>
            <ProfileHeroFooterItem
              item={platforms.length > 0 ? <ProfilePlatformsCounter platforms={platforms} /> : null}
              emptyMessage="No platform"
            />
          </Skeleton>

          <Skeleton w={108} h={19}>
            <ProfileHeroFooterItem
              icon="mail-rounded"
              item={<span className="fs-exclude">{email}</span>}
              emptyMessage="No email address"
            />
          </Skeleton>

          <Skeleton w={108} h={19}>
            <ProfileHeroFooterItem
              icon="sms"
              item={<span className="fs-exclude">{phoneNumber}</span>}
              emptyMessage="No phone number"
            />
          </Skeleton>
        </Reachability>
      )}
      <Button kind="discreet">
        {isBodyShown ? (
          <Skeleton w={105} h={19}>
            Less details <Icon icon="chevron-up" />
          </Skeleton>
        ) : (
          <Skeleton w={105} h={19}>
            <React.Fragment>
              More details <Icon icon="chevron-down" />
            </React.Fragment>
          </Skeleton>
        )}
      </Button>
    </ProfileFooterContainer>
  )
}
