// @flow
import styled, { type StyledComponent, type PropsWithTheme } from 'styled-components'

import { colors } from 'components/styled/tokens'

type SegmentLabelProps = { color: string, checked: boolean, ... }
type ThemeProps = { disabledMode: boolean, ... }
type ThemedSegmentProps = PropsWithTheme<SegmentLabelProps, ThemeProps>

export const SegmentLabel: StyledComponent<SegmentLabelProps, *, HTMLLabelElement> = styled.label`
  border: ${(props: ThemedSegmentProps) => (props.checked ? 2 : 1)}px solid
    ${(props: ThemedSegmentProps) => (props.checked ? props.color : colors.stroke)};
  border-radius: 6px;
  display: inline-flex;
  flex-grow: 1;
  padding: 10px 13px;
  pointer-events: ${(p: ThemedSegmentProps) => (p.theme.disabledMode ? 'none' : 'unset')};
  margin: ${(props: ThemedSegmentProps) => (props.checked ? '0 7px 0 0' : '1px 8px 1px 1px')};
  cursor: pointer;
  font-weight: 500;
  color: ${colors.text};
  span {
    margin: 0 10px 0 10px;
  }
  &:last-child {
    margin-right: 0;
  }
`
export const QueryReviewContainer: StyledComponent<{ ... }, *, HTMLDivElement> = styled.div`
  pre {
    border-radius: 0px;
  }
  .hljs {
    padding: 16px;
  }
`
