// @flow

import { type SegmentRecord } from '../models/segment.records'
import { type FetchProfileHashAction } from 'com.batch.redux/target/target'

export const fetchEstimateForSegmentName = ({
  segment,
}: {
  segment: SegmentRecord,
}): FetchProfileHashAction => {
  const initAction: FetchProfileHashAction = {
    type: 'T_ESTIMATE_FETCH_PROFILE_HASH',
    payload: {
      id: `segment-${segment.name}`,
      hash: -1,
      retries: 0,
      languages: [],
      regions: [],
      segments: [],
      apps: [],
      query: segment.query,
    },
  }
  return initAction
}
