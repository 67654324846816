// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'

export const FiltersButtonsWrapper: StyledComponent<*, *, *> = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  ${Button} {
    overflow: inherit;
  }
`
