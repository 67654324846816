// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Title } from 'components/styled/text'
import { texts } from 'components/styled/tokens'
import { Separator } from 'components/styled/utils'

import { capitalize, kformat, percentage } from 'com.batch.common/utils'

import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { STAT_TYPE } from 'com.batch/orchestration-analytics/infra/types/stat-type'
import { type StatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import {
  StatsBlocksRow,
  StatsContainer,
} from 'com.batch/orchestration-analytics/ui/components/orchestration-analytics/orchestration-analytics.styles'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { MetricBlock } from 'com.batch/shared/ui/component/metric-block/metric-block'
import { MetricBlockCard } from 'com.batch/shared/ui/component/metric-block/metric-block.styles'
import { Trend, TrendTooltip } from 'com.batch/shared/ui/component/trend/trend'
import { STATUS } from 'constants/common'

type SmsAnalyticsProps = {
  loadingState: LoadingState,
}

export const SmsAnalytics = ({ loadingState }: SmsAnalyticsProps): React.Node => {
  const { token }: { token: string } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const isAutomation = React.useMemo(() => schedulingType === 'automations', [schedulingType])
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const stats: ?StatsRecord = React.useMemo(
    () => analyticsMap.getIn([token, 'channels', 'sms', 'stats']),
    [analyticsMap, token]
  )
  const isLoading = React.useMemo(
    () => [STATUS.LOADING, STATUS.INIT].includes(loadingState),
    [loadingState]
  )
  const isOverlayShown = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])
  const { dateRange } = useGetAnalyticsFilters()

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ margin: '0 0 24px' }}>
        <Title>
          <Icon icon="sms" />
          SMS
        </Title>
      </ChannelSectionTitle>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={GlobalErrorOverlayProps}
        boxed
      >
        <StatsBlocksRow>
          <MetricBlockCard>
            <MetricBlock
              variant="success"
              label={capitalize(STAT_TYPE.DELIVERED)}
              value={kformat(stats?.delivered.value ?? 0)}
              subtitle={`out of ${kformat(stats?.sent.value ?? 0)} sent`}
              hint={`Message parts: ${kformat(stats?.deliveredSMSNbParts ?? 0)}`}
              trend={
                dateRange && isAutomation ? (
                  <Trend
                    rate={stats?.delivered.trend ?? 0}
                    tooltip={
                      <TrendTooltip
                        metricContent={
                          <span>
                            <strong>{kformat(stats?.delivered.previousPeriodValue ?? 0)}</strong>{' '}
                            delivered SMS
                          </span>
                        }
                        dateRange={dateRange}
                      />
                    }
                  />
                ) : undefined
              }
            />
          </MetricBlockCard>
          <MetricBlockCard>
            <Grid gap={0} template="1fr 1px 1fr" alignItems="stretch">
              <MetricBlock
                variant="danger"
                label={capitalize(STAT_TYPE.BOUNCED)}
                value={
                  <span>
                    {percentage(stats?.bounce.rate ?? 0, 2, false, false)}
                    <span style={texts.metric}>%</span>
                  </span>
                }
                subtitle={`${kformat(stats?.bounce.value ?? 0)} unique bounces`}
                trend={
                  dateRange && isAutomation ? (
                    <Trend
                      rate={stats?.bounce.trend ?? 0}
                      isGrowthPositive={false}
                      tooltip={
                        <TrendTooltip
                          metricContent={
                            <span>
                              <strong>
                                {percentage(stats?.bounce.previousPeriodRate ?? 0, 2)}
                              </strong>{' '}
                              bounced SMS
                            </span>
                          }
                          dateRange={dateRange}
                        />
                      }
                    />
                  ) : undefined
                }
              />

              <Separator _margin="12px 0 12px 0" fullHeight />
              <MetricBlock
                variant="neutral"
                label={capitalize(STAT_TYPE.UNSUBSCRIBED)}
                value={percentage(stats?.unsubscribe.rate ?? 0, 2)}
                subtitle={`${kformat(stats?.unsubscribe.unique ?? 0)} unique unsub`}
                trend={
                  dateRange && isAutomation ? (
                    <Trend
                      rate={stats?.unsubscribe.trend ?? 0}
                      isGrowthPositive={false}
                      tooltip={
                        <TrendTooltip
                          metricContent={
                            <span>
                              <strong>
                                {percentage(stats?.unsubscribe.previousPeriodRate ?? 0, 2)}
                              </strong>{' '}
                              unsubscribed emails
                            </span>
                          }
                          dateRange={dateRange}
                        />
                      }
                    />
                  ) : undefined
                }
              />
            </Grid>
          </MetricBlockCard>
        </StatsBlocksRow>
      </Wrapper>
    </StatsContainer>
  )
}
