// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useSetEditingCampaignFromRouterLegacy, useToggle } from 'components/_hooks'
import { InAppTrigger } from 'components/campaign/inapp-trigger'
import { PushWhenConnected } from 'components/campaign/push-when'
import { Alert } from 'components/common/alert'
import { Banner } from 'components/common/banner'
import { ButtonLink } from 'components/common/button'
import Loader from 'components/common/loader-legacy'
import NoData from 'components/common/nodata'
import {
  PageTracker,
  trackingCategories,
  TrackingContext,
  trackingPages,
} from 'components/common/page-tracker'
import { ScrollTracker, Sticky } from 'components/common/scroll'
import { Content } from 'components/styled/blocs'
import { TargetingForm } from 'components/targeting/targeting-form'

import { generateUrl } from 'com.batch.common/router'

import { Content as ContentConnected } from './content'
import { FormReview } from './form-review'
import { FormHead } from './header'

import { currentAppCanPush, currentAppSelector } from 'com.batch.redux/app'
import { sdkSupportsSelector } from 'com.batch.redux/attribute'
import { visibleEventsSelector } from 'com.batch.redux/attribute.selector'
import { FetchThemes } from 'com.batch.redux/theme'
import {
  InAppVariantsThemeSelector,
  themesLoadingStateSelector,
  ThemesSelector,
} from 'com.batch.redux/theme.selector'

export const LegacyCampaignForm = (): React.Node => {
  const app = useSelector(currentAppSelector)
  const { campaign, campaignFetchingState } = useSetEditingCampaignFromRouterLegacy(true)
  // local state
  const [isHeaderStuck, setIsHeaderStuck] = React.useState(false)
  const errorReportOpenState = useToggle()
  const [activeStep, setActiveStep] = React.useState('')

  // redux
  const dispatch = useDispatch()
  const canPush = useSelector(currentAppCanPush)
  const sdkSupports = useSelector(sdkSupportsSelector)
  const theme = useSelector(InAppVariantsThemeSelector).a // a/b non supporté sur le landing
  const themeLoadingState = useSelector(themesLoadingStateSelector)
  const events = useSelector(visibleEventsSelector)
  const themes = useSelector(ThemesSelector)

  // memo
  const isLoadingTheme = React.useMemo(
    () => themeLoadingState === 'INIT' || themeLoadingState === 'LOADING',
    [themeLoadingState]
  )
  const type = React.useMemo(() => campaign.type, [campaign.type])
  // effects
  React.useEffect(() => {
    if (themeLoadingState === 'INIT') dispatch(FetchThemes())
  }, [dispatch, themeLoadingState])
  // callbacks
  const onActiveStepChangeCreator = React.useCallback(step => () => setActiveStep(step), [])
  const TRACKING_CONTEXT = React.useMemo(
    () => ({
      eventPlatform: app.platform,
      searchEventCode: 'SEARCH_ATTRIBUTES',
      eventLocation: type === 'push' ? 'Push form' : 'In-App form',
      pagerEventCode: 'unset',
    }),
    [app.platform, type]
  )

  const hasTriggerFeature = React.useMemo(
    () =>
      app.platform === 'webpush'
        ? app.features.includes('webpush-trigger-campaigns')
        : app.features.includes('trigger-campaigns'),
    [app.features, app.platform]
  )

  if (campaignFetchingState === 'LOADING') return <Loader loading={true} height={400} size="fat" />

  if (campaignFetchingState === 'ERROR')
    return (
      <div className="text-center">
        <p>We were unable to load your campaign data.</p>
        <p>
          <br />{' '}
        </p>
        <ButtonLink kind="primary" intent="action" href={window.location.href}>
          Reload the page
        </ButtonLink>
      </div>
    )

  return (
    <PageTracker
      category={trackingCategories[campaign.type]}
      name={campaign.id ? trackingPages.edit : trackingPages.new}
    >
      <TrackingContext.Provider value={TRACKING_CONTEXT}>
        {campaign.archived && <Banner title="This campaign is archived!" />}
        <Loader loading={false} overlay>
          {themes.size === 0 && type === 'in-app' && !isLoadingTheme ? (
            <NoData title="No theme found" error icon="image">
              <div>
                <p>
                  You need to have at least one theme setup for this app in order to create an
                  In-App Campaign
                </p>
                <ButtonLink
                  kind="primary"
                  intent="action"
                  href={generateUrl('app_settings', {
                    companyId: app.companyId,
                    appId: app.id,
                    activeTab: 'themes',
                  })}
                >
                  Create a theme
                </ButtonLink>
              </div>
            </NoData>
          ) : (
            <React.Fragment>
              <Sticky onStickyChange={setIsHeaderStuck}>
                <FormHead
                  pickedLabels={campaign.pickedLabels}
                  closeErrors={errorReportOpenState.close}
                  errorReportOpened={errorReportOpenState.value}
                  campaignType={type}
                  schedulingType={campaign.schedulingType}
                  campaignState={campaign.state}
                  stuck={isHeaderStuck}
                  activeStep={activeStep}
                />
              </Sticky>
              <Content style={{ paddingTop: 0 }}>
                {!canPush && type !== 'in-app' && (
                  <div>
                    <Banner
                      icon="danger"
                      intent="danger"
                      content="Your app push settings are not valid, you won't be able to update this campaign until you fix this."
                      link={{
                        kind: 'basic',
                        name: 'Push settings',
                        href: generateUrl('app_settings', {
                          companyId: app.companyId,
                          appId: app.id,
                          activeTab: 'push',
                        }),
                      }}
                    />
                    <br />
                  </div>
                )}
                {sdkSupports.inapp.supported === false && type === 'in-app' && (
                  <Alert icon="danger" kind="warning">
                    We couldn't find any installation using the required Batch SDK version for
                    In-App Campaigns. You can still use this form for testing purposes, but you'll
                    need to update your Batch SDK version if you want to use the feature.
                  </Alert>
                )}
                <ScrollTracker
                  id="who"
                  anchorOffset={-140}
                  onScrollEnter={onActiveStepChangeCreator('who')}
                >
                  {campaign.devOnly && (
                    <Alert icon="danger" kind="warning">
                      This campaign has been created via the API on the <strong>DEV</strong> api key
                      and did only push <strong>DEV</strong> installs. You need to use the API
                      update route to make any modification.
                    </Alert>
                  )}
                  <TargetingForm
                    header="Targeting"
                    estimateMode={type === 'in-app' ? 'installs' : 'tokens'}
                  />
                </ScrollTracker>

                <ScrollTracker
                  id="when"
                  anchorOffset={-140}
                  onScrollEnter={onActiveStepChangeCreator('when')}
                >
                  {type === 'in-app' && <InAppTrigger isNewCampaign={campaign.state === 'NEW'} />}
                  {type === 'push' && (
                    <PushWhenConnected
                      app={app}
                      events={events}
                      campaign={campaign}
                      hasTriggerFeature={hasTriggerFeature}
                      isWeb={app.platform === 'webpush'}
                    />
                  )}
                </ScrollTracker>

                <ScrollTracker
                  id="what"
                  anchorOffset={-140}
                  onScrollEnter={onActiveStepChangeCreator('what')}
                >
                  {sdkSupports.landing.supported === false &&
                    type === 'push' &&
                    campaign.hasLanding && (
                      <Alert icon="danger" kind="warning">
                        We couldn't find any installation using the required Batch SDK version for
                        Mobile Landing. You can still use this form for testing purposes, but you'll
                        need to update your Batch SDK version if you want to use the feature.
                      </Alert>
                    )}
                  {sdkSupports.banner.supported === false &&
                    (type !== 'push' || campaign.hasLanding) &&
                    !!theme &&
                    theme.payloadVars.kind === 'banner' && (
                      <Alert icon="danger" kind="warning">
                        We couldn't find any installation using the required Batch SDK version for
                        banners. You can still use this form for testing purposes, but you'll need
                        to update your Batch SDK version if you want to use the feature.
                      </Alert>
                    )}
                  {sdkSupports.webview.supported === false &&
                    (type !== 'push' || campaign.hasLanding) &&
                    !!theme &&
                    theme.payloadVars.kind === 'webview' && (
                      <Alert icon="danger" kind="warning">
                        We couldn't find any installation using the required Batch SDK version for
                        webviews. You can still use this form for testing purposes, but you'll need
                        to update your Batch SDK version if you want to use the feature.
                      </Alert>
                    )}
                  {sdkSupports.modal.supported === false &&
                    (type !== 'push' || campaign.hasLanding) &&
                    !!theme &&
                    theme.payloadVars.kind === 'modal' && (
                      <Alert icon="danger" kind="warning">
                        We couldn't find any installation using the required Batch SDK version for
                        modals. You can still use this form for testing purposes, but you'll need to
                        update your Batch SDK version if you want to use the feature.
                      </Alert>
                    )}
                  {sdkSupports.image.supported === false &&
                    (type !== 'push' || campaign.hasLanding) &&
                    !!theme &&
                    theme.payloadVars.kind === 'image' && (
                      <Alert icon="danger" kind="warning">
                        We couldn't find any installation using the required Batch SDK version for
                        image themes. You can still use this form for testing purposes, but you'll
                        need to update your Batch SDK version if you want to use the feature.
                      </Alert>
                    )}
                  <ContentConnected
                    campaign={campaign}
                    app={app}
                    themes={themes}
                    sdkSupports={sdkSupports}
                  />
                </ScrollTracker>

                <ScrollTracker
                  id="review"
                  anchorOffset={-140}
                  onScrollEnter={onActiveStepChangeCreator('review')}
                >
                  <FormReview
                    campaign={campaign}
                    openErrors={errorReportOpenState.open}
                    app={app}
                  />
                </ScrollTracker>
              </Content>
            </React.Fragment>
          )}
        </Loader>
      </TrackingContext.Provider>
    </PageTracker>
  )
}
