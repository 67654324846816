// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { TableTemplateCell } from 'components/common/table'

export const EmptyLabelRow: React.ComponentType<{}> = React.memo((): React.Node => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
})

export const PerviewFormContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  padding: 10px 14px;
  background: #fafafa;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`
