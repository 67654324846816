// @flow

import { get } from 'lodash-es'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { Button, ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Form, InputWrapper, Input, Checkbox, AuthCode } from 'components/form'
import { LinkArrow } from 'components/styled/text'

import { generateUrl, trimUnsecureDomain } from 'com.batch.common/router'

import { check2FA, recover2FA } from './api'
import { LoginPage, LogoutButtonContainer } from './login.styles'

import { AuthStep, AuthStepFooter, Title, Text } from '../auth.styles'
import { currentUserSelector } from 'com.batch.redux/user.selector'

export const Login2FA = (): React.Node => {
  const [recovering, setRecovering] = React.useState(false)
  const [remember, setRemember] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [recoveryCode, setRecoveryCode] = React.useState('')
  const [error, setError] = React.useState('')
  const { email } = useSelector(currentUserSelector)

  const redirectOnOk = () => {
    const params = new URLSearchParams(window.location.search)
    const redirectTo = params.get('redirect-to')
    window.location = redirectTo ? trimUnsecureDomain(redirectTo) : generateUrl('dashboard')
  }

  const onBlur = React.useCallback(
    (code: string) => {
      if (code.length === 0) {
        setError('')
      } else if (code.length !== 6) {
        setError('Your code should be composed of 6 digits.')
      } else {
        setLoading(true)
        check2FA({ email, code, remember }).then(redirectOnOk, err => {
          setError(err[0].message)
          setLoading(false)
        })
      }
    },
    [email, remember]
  )

  const onSubmit = React.useCallback(() => {
    if (recovering) {
      setLoading(true)
      recover2FA({ code: recoveryCode }).then(redirectOnOk, err => {
        setError(err[0].message)
        setLoading(false)
      })
    }
  }, [recovering, recoveryCode])

  const onRecoveryCodeChange = React.useCallback(evt => setRecoveryCode(evt.target.value), [])

  const onAuthCodeFocus = React.useCallback(() => {
    if (code.length === 6 && !!error) {
      setCode('')
      setError('')
    }
  }, [code, error])

  const onRecoveringChange = React.useCallback(() => {
    setError('')
    setRecovering(prev => !prev)
  }, [])

  const onRememberChange = React.useCallback(() => setRemember(prev => !prev), [])

  return (
    <LoginPage>
      <LogoutButtonContainer>
        <ButtonLink
          kind="inline"
          addOn="prefix"
          href={generateUrl('logout', {
            _csrf_token: get(document.querySelector('head > meta[name=logout-csrf]'), 'content'),
          })}
        >
          <Icon icon="logout" />
          Log out
        </ButtonLink>
      </LogoutButtonContainer>
      <Form onSubmit={onSubmit}>
        <AuthStep>
          <Title style={{ margin: '0 0 0 -6px' }}>
            {recovering ? 'Recovery codes' : 'Two-factor auth'}
          </Title>
          {recovering ? (
            <Text>
              If you've lost access to your authentication app, you can use one of your recovery
              codes here to access your account.
            </Text>
          ) : (
            <Text>
              To continue, please enter the 6-digit verification code generated by your two-factor
              authentication app.
            </Text>
          )}
          {recovering ? (
            <InputWrapper feedback={error}>
              <Input
                invalid={!!error}
                placeholder="Enter your recovery code"
                value={recoveryCode}
                onChange={onRecoveryCodeChange}
                aria-label="Recovery code"
              />
            </InputWrapper>
          ) : (
            <AuthCode
              loading={loading}
              onBlur={onBlur}
              inputMode="numeric"
              pattern="[0-9]*"
              autoComplete="one-time-code"
              onChange={setCode}
              onFocus={onAuthCodeFocus}
              invalid={!!error}
              feedback={error}
              value={code}
            />
          )}
          <LinkArrow style={{ marginTop: '12px' }} onClick={onRecoveringChange}>
            {recovering ? 'Use a classic authentication code' : 'Use a recovery code'}
          </LinkArrow>
          <AuthStepFooter>
            {recovering ? (
              <Button
                kind="primary"
                intent="action"
                isLoading={loading}
                style={{ minWidth: 100 }}
                type="submit"
              >
                Log in
              </Button>
            ) : (
              <Checkbox
                label="Trust this device and don't ask me for a code for 3 months"
                checked={remember}
                onChange={onRememberChange}
              />
            )}
          </AuthStepFooter>
        </AuthStep>
      </Form>
    </LoginPage>
  )
}

export default Login2FA
