// @flow
import styled, { type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { TextEmpty } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

type CopyableContainerProps = { fullWidth?: boolean, ... }
export const CopyableContainer: StyledComponent<CopyableContainerProps, { ... }, HTMLElement> =
  styled.div`
    cursor: pointer;
    width: ${(props: CopyableContainerProps) => (props.fullWidth ? '100%' : 'fit-content')};
    max-width: 100%;

    &:hover div svg {
      color: ${colors.textLight};
    }
    &:hover ${IconContainer} {
      opacity: 1;
    }

    &:active div svg {
      color: ${colors.text};
    }
  `

export const CopyableTextEmpty: StyledComponent<*, *, *> = styled(TextEmpty)`
  margin-top: 6px;
`
type CopyableTextProps = { isFirst?: boolean, ... }
export const CopyableText: StyledComponent<CopyableTextProps, *, HTMLElement> = styled.div`
  margin-top: ${(props: CopyableTextProps) => (props.isFirst ? 0 : '6px')};
  display: flex;
  color: ${colors.text};

  ${IconContainer} {
    margin-left: 8px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    svg:hover {
      color: ${colors.textNeutral};
    }

    svg:active {
      color: ${colors.text};
    }
  }
`

export const CopyableToken: StyledComponent<*, *, HTMLElement> = styled.div`
  cursor: pointer;
  background-color: ${colors.opacifyDefault};
  border-radius: 5px;
  width: 100%;

  &:hover {
    background-color: ${colors.opacifyHover};
  }

  &:active {
    background-color: ${colors.opacifyActive};

    ${IconContainer} svg {
      color: ${colors.text};
    }
  }

  ${CopyableText} {
    padding: 2px 5px;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;

    svg {
      margin-right: 6px;
    }
  }

  &:hover ${IconContainer} {
    opacity: 1;
    color: ${colors.textLight};
  }
`
