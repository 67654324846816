// @flow
import * as React from 'react'

export const CrownIcon = (): React.Node => (
  <svg
    width="148"
    height="137"
    viewBox="0 0 148 137"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g filter="url(#filter0_d)">
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="31"
        y="38"
        width="86"
        height="71"
      >
        <path
          d="M44.1968 39.1628L32.2625 58.7691C32.0907 59.0513 31.9969 59.3741 31.9907 59.7044L31.1745 102.961C31.1154 106.095 33.6394 108.667 36.7735 108.667H111.227C114.361 108.667 116.885 106.095 116.826 102.961L116.01 59.7044C116.004 59.3741 115.91 59.0513 115.738 58.7691L103.804 39.1628C103.35 38.4178 102.333 38.2643 101.68 38.8421L79.1966 58.7315C78.8553 59.0334 78.4154 59.2 77.9598 59.2H70.0407C69.5851 59.2 69.1452 59.0334 68.8039 58.7315L46.3203 38.8421C45.6671 38.2643 44.6503 38.4178 44.1968 39.1628Z"
          fill="url(#paint0_linear)"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="18" y="6" width="112" height="112" fill="url(#paint1_linear)" />
        <g filter="url(#filter1_d)">
          <path
            d="M23.3197 39.8021C23.1166 37.9962 25.3514 36.9942 26.5646 38.3473L46.1256 60.1654C46.5341 60.621 47.2631 60.5669 47.5998 60.056L72.4417 22.3648C73.1794 21.2455 74.8213 21.2455 75.5589 22.3648L100.401 60.056C100.738 60.5669 101.467 60.621 101.875 60.1654L121.436 38.3473C122.649 36.9942 124.884 37.9962 124.681 39.802L117.368 104.803C117.107 107.126 115.435 109.026 113.134 109.44C107.06 110.534 93.7957 112.4 74.0003 112.4C54.205 112.4 40.9406 110.534 34.8667 109.44C32.5658 109.026 30.8937 107.126 30.6323 104.803L23.3197 39.8021Z"
            fill="#FFCD3C"
          />
        </g>
      </g>
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="23"
        y="21"
        width="102"
        height="92"
      >
        <path
          d="M23.3192 39.8021C23.1161 37.9962 25.351 36.9942 26.5641 38.3473L46.1251 60.1654C46.5336 60.621 47.2626 60.5669 47.5993 60.056L72.4413 22.3648C73.1789 21.2455 74.8208 21.2455 75.5584 22.3648L100.4 60.056C100.737 60.5669 101.466 60.621 101.875 60.1654L121.436 38.3473C122.649 36.9942 124.884 37.9962 124.68 39.802L117.44 104.159C117.135 106.869 115.178 109.086 112.491 109.554C106.152 110.658 93.1238 112.4 73.9999 112.4C54.8759 112.4 41.8473 110.658 35.509 109.554C32.8219 109.086 30.8643 106.869 30.5594 104.159L23.3192 39.8021Z"
          fill="url(#paint2_linear)"
        />
      </mask>
      <g mask="url(#mask1)">
        <rect x="18" y="6" width="112" height="112" fill="url(#paint3_linear)" />
        <path
          d="M18 95.6C18 95.6 41.3333 99.8 74 99.8C106.667 99.8 130 95.6 130 95.6"
          stroke="#FFD65F"
          strokeWidth="1.86667"
        />
        <path
          d="M18 93.7334C18 93.7334 41.3333 97.9334 74 97.9334C106.667 97.9334 130 93.7334 130 93.7334"
          stroke="#DFA700"
          strokeWidth="1.86667"
        />
        <path
          d="M18 86.7334C18 86.7334 41.3333 90.9334 74 90.9334C106.667 90.9334 130 86.7334 130 86.7334"
          stroke="#FFD65F"
          strokeWidth="1.86667"
        />
        <path
          d="M18 84.8667C18 84.8667 41.3333 89.0667 74 89.0667C106.667 89.0667 130 84.8667 130 84.8667"
          stroke="#DFA700"
          strokeWidth="1.86667"
        />
        <g style={{ mixBlendMode: 'luminosity' }}>
          <rect x="18" y="6" width="112" height="112" fill="url(#pattern0)" fillOpacity="0.04" />
        </g>
        <rect x="74" y="6" width="56" height="112" fill="black" fillOpacity="0.02" />
        <path d="M118.8 6H130V118H114.133L118.8 6Z" fill="black" fillOpacity="0.03" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-4.40001"
        y="-14.5333"
        width="156.8"
        height="156.8"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1.86667" />
        <feGaussianBlur stdDeviation="11.2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="filter1_d"
        x="10.24"
        y="7.992"
        width="127.52"
        height="117.008"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="-0.466667" />
        <feGaussianBlur stdDeviation="6.53334" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0" transform="scale(0.00416667)" />
      </pattern>
      <linearGradient
        id="paint0_linear"
        x1="18.2336"
        y1="68.7667"
        x2="130"
        y2="68.7667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8E6A00" />
        <stop offset="1" stopColor="#6C5100" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="1.26087"
        y1="56.6154"
        x2="147.044"
        y2="56.6154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8E6A00" />
        <stop offset="1" stopColor="#6C5100" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="17.9998"
        y1="75.7667"
        x2="130"
        y2="75.7667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCD3C" />
        <stop offset="1" stopColor="#FFC007" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="18"
        y1="76.4421"
        x2="130"
        y2="76.4421"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCD3C" />
        <stop offset="1" stopColor="#FFC007" />
      </linearGradient>
      <image id="image0" width="240" height="240" />
    </defs>
  </svg>
)

export const LockIcon = (): React.Node => (
  <svg
    width="130"
    height="160"
    viewBox="0 0 130 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g filter="url(#filter0_d)">
      <g clipPath="url(#clip0)">
        <rect x="19" y="66" width="92" height="74" rx="9" fill="#969CA6" />
        <rect x="19" y="125" width="92" height="2" fill="#6C737E" />
        <rect x="19" y="127" width="92" height="2" fill="#ACB1B9" />
        <rect x="19" y="117" width="92" height="2" fill="#6C737E" />
        <rect x="19" y="119" width="92" height="2" fill="#ACB1B9" />
        <rect x="19" y="109" width="92" height="2" fill="#6C737E" />
        <rect x="19" y="111" width="92" height="2" fill="#ACB1B9" />
        <rect x="19" y="66" width="92" height="5" fill="url(#paint0_linear)" fillOpacity="0.18" />
        <rect x="19" y="136" width="92" height="4" fill="url(#paint1_linear)" fillOpacity="0.1" />
        <rect x="102" y="66" width="9" height="74" fill="url(#paint2_linear)" fillOpacity="0.08" />
        <g style={{ mixBlendMode: 'luminosity' }}>
          <rect x="-9" y="20" width="240" height="240" fill="url(#pattern0)" fillOpacity="0.04" />
        </g>
      </g>
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="35"
        y="18"
        width="60"
        height="48"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.25 29.5C54.8947 29.5 46.5 37.8947 46.5 48.25L46.5 66L35.5 66L35.5 48.25C35.5 31.8195 48.8195 18.5 65.25 18.5C81.6805 18.5 95 31.8195 95 48.25L95 66L84 66L84 48.25C84 37.8947 75.6053 29.5 65.25 29.5Z"
          fill="#C9C9C9"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="19" y="14" width="92" height="52" fill="#C9C9C9" />
        <rect x="19" y="14" width="92" height="52" fill="url(#paint3_radial)" fillOpacity="0.38" />
        <path
          d="M45 47.75C45 36.2901 54.2901 27 65.75 27C77.2099 27 86.5 36.2901 86.5 47.75V66H45V47.75Z"
          fill="url(#paint4_radial)"
          fillOpacity="0.04"
        />
        <g style={{ mixBlendMode: 'luminosity' }}>
          <rect
            x="-24.5"
            y="-54.5"
            width="240"
            height="240"
            fill="url(#pattern1)"
            fillOpacity="0.04"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-1.90735e-06"
        y="-1.90735e-06"
        width="130"
        height="159.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="0.5" />
        <feGaussianBlur stdDeviation="9.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0" transform="scale(0.00416667)" />
      </pattern>
      <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0" transform="scale(0.00416667)" />
      </pattern>
      <linearGradient
        id="paint0_linear"
        x1="65"
        y1="66"
        x2="65"
        y2="71"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.48" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="65"
        y1="136"
        x2="65"
        y2="140"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.38" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="102"
        y1="97.6377"
        x2="111"
        y2="97.6377"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.34" />
        <stop offset="1" />
      </linearGradient>
      <radialGradient
        id="paint3_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(64.2269 66) rotate(-88.7291) scale(52.2865 46.7731)"
      >
        <stop stopColor="#4B4B4B" />
        <stop offset="1" stopColor="white" stopOpacity="0.84" />
      </radialGradient>
      <radialGradient
        id="paint4_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(65.522 66) rotate(-90) scale(39 53.5736)"
      >
        <stop />
        <stop offset="0.188272" />
        <stop offset="1" stopOpacity="0.22" />
      </radialGradient>
      <clipPath id="clip0">
        <rect x="19" y="66" width="92" height="74" rx="9" fill="white" />
      </clipPath>
      <image id="image0" width="240" height="240" />
    </defs>
  </svg>
)
