// @flow

import * as Immutable from 'immutable'
import { type RecordFactory, type RecordOf } from 'immutable'

type ServiceAccountKeyProps = {
  type: 'service_account',
  projectId: string,
  privateKeyId: string,
  privateKey: string,
  clientEmail: string,
  clientId: string,
  authUri: string,
  tokenUri: string,
  authProviderX509CertUrl: string,
  clientX509CertUrl: string,
  universeDomain: string,
}
export const ServiceAccountKeyFactory: RecordFactory<ServiceAccountKeyProps> = Immutable.Record(
  ({
    type: 'service_account',
    projectId: '',
    privateKeyId: '',
    privateKey: '',
    clientEmail: '',
    clientId: '',
    authUri: '',
    tokenUri: '',
    authProviderX509CertUrl: '',
    clientX509CertUrl: '',
    universeDomain: '',
  }: ServiceAccountKeyProps)
)

export type ServiceAccountKeyRecord = RecordOf<ServiceAccountKeyProps>

type FCMConfigProps = {
  projectId: string,
  serviceAccountKey: ServiceAccountKeyRecord,
  ...
}
export const FCMConfigFactory: RecordFactory<FCMConfigProps> = Immutable.Record(
  ({
    projectId: '',
    serviceAccountKey: ServiceAccountKeyFactory(),
  }: FCMConfigProps)
)

export type FCMConfigRecord = RecordOf<FCMConfigProps>
