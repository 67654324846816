// @flow

import { type Set } from 'immutable'

import { type ReduxAction } from 'com.batch.redux/_records'

export type SetLabelsAction = ReduxAction<'SET_ORCHESTRATION_LABELS', Set<string>>

export const setOrchestrationLabelCodes: (Set<string>) => SetLabelsAction = (
  labelCodes: Set<string>
) => ({
  type: 'SET_ORCHESTRATION_LABELS',
  payload: labelCodes,
})
