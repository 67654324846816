// @flow
import Immutable from 'immutable'

import { buildAgeFromDuration } from 'com.batch.common/utils'

import { AgeFactory } from 'com.batch.redux/_records'

import {
  PushSettingsAndroidOverrideFactory,
  AndroidPushMessageRawFactory,
  PushContentFactory,
  PushMessageRawFactory,
  PushContentTemplatesFactory,
  type PushContentRecord,
  type PushMessageRecord,
  type PushSettingsRecord,
  PushSettingsFactory,
  PushMessageFactory,
  IosPushMessageRawFactory,
  WebPushMessageRawFactory,
  PushSettingsIosOverrideFactory,
} from 'com.batch/message/models/message.records'
import { parseFilterPushTokens } from 'com.batch/orchestration/infra/parses/push-token-target.parse'
import { type OrchestrationPush } from 'com.batch/push/infra/types/push.message.types'

export const parsePush = (
  raw: OrchestrationPush
): {
  message: PushMessageRecord,
  settings: PushSettingsRecord,
  platforms: Immutable.Set<ProjectPlatforms>,
  contentFullyEmpty: boolean,
  contentInvalid: boolean,
} => {
  let contentInvalid = false
  let contentFullyEmpty = false
  const tuplesLangValueArray: ?Array<[string, PushContentRecord]> = raw.localizedPush?.map(
    ({ language, region, pushMessageContentTemplate }) => {
      const lang = language && region ? `${language}-${region}` : language ? language : 'default'
      // proto duration format is like "1s" for 1 second
      const p = pushMessageContentTemplate
      const pushContentRecord = PushContentFactory({
        content: PushMessageRawFactory({
          pushTitle: p.content.title,
          pushBody: p.content.body,
          pushPicture: p.content.picture ? p.content.picture.url : '',
          pushIcon: p.androidOverride?.icon?.url ?? p.webPushOverride?.icon?.url ?? '',
          templates: PushContentTemplatesFactory({
            pushTitle: p.content.title,
            pushBody: p.content.body,
            pushPicture: p.content.picture ? p.content.picture.url : '',
            pushIcon: p.androidOverride?.icon?.url ?? p.webPushOverride?.icon?.url ?? '',
          }),
          deeplink: p.content.deepLink ?? '',
        }),
        androidContent: AndroidPushMessageRawFactory({
          deeplink: p?.androidOverride?.deepLink ?? '',
        }),
        iosContent: IosPushMessageRawFactory({
          deeplink: p?.iosOverride?.deepLink ?? '',
          video: p?.iosOverride?.iosVideo?.url ?? '',
          audio: p?.iosOverride?.iosAudio?.url ?? '',
        }),
        webContent: WebPushMessageRawFactory({
          deeplink: p?.webPushOverride?.deepLink ?? '',
        }),
      })
      return [lang, pushContentRecord]
    }
  )
  const platforms: Array<ProjectPlatforms> =
    raw.pushPlatforms
      ?.map(platform => {
        switch (platform) {
          case 'PUSH_PLATFORM_ANDROID':
            return 'android'
          case 'PUSH_PLATFORM_IOS':
            return 'ios'
          case 'PUSH_PLATFORM_WEB':
            return 'webpush'
          default:
            return null
        }
      })
      .filter(Boolean) ?? []
  if (raw.localizedPush && tuplesLangValueArray) {
    const { pushMessageContentTemplate: first } = raw.localizedPush[0]
    return {
      message: PushMessageFactory({
        localizedContent: Immutable.OrderedMap(tuplesLangValueArray),
      }),
      settings: PushSettingsFactory({
        ttl: raw.ttl ? buildAgeFromDuration(raw.ttl) : AgeFactory(),
        ttlEnabled: Boolean(raw.ttl),
        priority: raw.priority,
        customPayload: first.content.customPayload,
        filterPushTokens: parseFilterPushTokens(raw.filterPushTokens),
        androidOverride: PushSettingsAndroidOverrideFactory({
          collapseKey: first.androidOverride?.collapseKey ?? '',
          customPayload: first.androidOverride?.customPayload ?? '',
        }),
        iosOverride: PushSettingsIosOverrideFactory({
          customPayload: first.iosOverride?.customPayload ?? '',
        }),
      }),
      platforms: Immutable.Set(platforms),
      contentFullyEmpty,
      contentInvalid,
    }
  } else {
    return {
      message: PushMessageFactory(),
      settings: PushSettingsFactory(),
      platforms: Immutable.Set(),
      contentFullyEmpty,
      contentInvalid,
    }
  }
}
