// @flow
import * as Immutable from 'immutable'
import { type RecordFactory, type RecordOf, type Map, type Set } from 'immutable'

import {
  type SmsMessageRecord,
  type EmailMessageRecord,
  type PushMessageRecord,
} from '../models/message.records'

type messageId = string

export type langId = string

type MessageStateProps = {
  previewLanguage: langId,
  updatedMessageIds: Set<messageId>,
  email: Map<messageId, EmailMessageRecord>,
  sms: Map<messageId, SmsMessageRecord>,
  push: Map<messageId, PushMessageRecord>,
}

export const MessageStateFactory: RecordFactory<MessageStateProps> = Immutable.Record(
  ({
    previewLanguage: 'default',
    updatedMessageIds: Immutable.Set(),
    email: Immutable.Map(),
    sms: Immutable.Map(),
    push: Immutable.Map(),
  }: MessageStateProps)
)
export type MessageStateRecord = RecordOf<MessageStateProps>
