// @flow

import * as React from 'react'

import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { OperatorIcon } from '../query.styles'
import { type OperatorRecord } from 'com.batch.redux/query/query.records'

export type operatorAndNegate = {
  operator: OperatorRecord,
  negate: boolean,
}

export const OperatorAndNegateFormatter = ({ operator, negate }: operatorAndNegate): React.Node => {
  const isNegated =
    negate || operator.value === 'notIn' || operator.value.substring(0, 14) === 'doesNotContain'

  return (
    <FlexLine style={{ minHeight: 0 }}>
      <OperatorIcon style={{ flex: '0 0 auto' }}>
        {isNegated && (
          <svg viewBox="0 0 19 20" className="OperatorIcon--strikethrough">
            <line
              x1="15"
              x2="4"
              y1="5"
              y2="15"
              strokeWidth="1.4"
              stroke={colors.textDanger}
              strokeLinecap="round"
            />
          </svg>
        )}
        <Icon icon={operator.icon} thickness={1.5} className="OperatorIcon--icon" />
      </OperatorIcon>
      <FlexLineItem ellipsis>{negate ? operator.negate : operator.label}</FlexLineItem>
    </FlexLine>
  )
}
