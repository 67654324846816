// @flow

import Immutable, { type List } from 'immutable'

import { type ReduxAction } from 'com.batch.redux/_records'

import { CappingRuleFactory, type CappingRuleRecord } from 'com.batch/capping/model/capping-rule'

export type NewCappingRulesActionType = ReduxAction<'NEW_CAPPING_RULES', List<CappingRuleRecord>>

export const newCappingRules = (labelCode: string): NewCappingRulesActionType => {
  const cappingRule = new Immutable.List().push(
    ...[
      CappingRuleFactory({
        labelCode: labelCode,
        durationValue: 1,
        durationUnit: 'HOURS',
        capping: 1,
      }),
    ]
  )

  return {
    type: 'NEW_CAPPING_RULES',
    payload: cappingRule,
  }
}
