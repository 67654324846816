// @flow
import styled, { type StyledComponent } from 'styled-components'

import { textLight } from 'components/styled/tokens/colors'

export const LocalTimeTitle: StyledComponent<*, *, *> = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${textLight};
`
