// @flow
import Immutable, { type List, type Map, type Set } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  campaignMessageConfigSelector,
  messageNodesSelector,
} from './orchestration.composed.selectors'

import { labelsSelector } from 'com.batch/labels/store/labels.selector'
import { messageStateSelector } from 'com.batch/message/store/message.selector'
import { type OrchestrationStateRecord } from 'com.batch/orchestration/store/orchestration.state'
import { type fetchingState, type pushCampaignSendType, type State } from 'com.batch.redux/_records'

import { LabelFactory, type LabelRecord } from 'com.batch/labels/models/labels.records'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import {
  journeyErrorDraftSelector,
  journeyErrorSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'

type Extract<T> = State => T

export const orchestrationStateSelector: Extract<OrchestrationStateRecord> = state =>
  state.orchestration

const CAMPAIGNS_SENDTYPES = ['scheduled', 'now']

export const orchestrationMetaSelector: Extract<{
  id: string,
  loadingState: fetchingState,
  name: string,
  schedulingType: schedulingType,
  sendType: pushCampaignSendType,
  state: campaignStateType,
  version: number,
  savedVersion: number,
  channels: Set<ChannelUntilCleanup>,
}> = createSelector(orchestrationStateSelector, os => ({
  id: os.id,
  loadingState: os.loadingState,
  name: os.name,
  schedulingType: CAMPAIGNS_SENDTYPES.includes(os.campaign.sendType) ? 'campaigns' : 'automations',
  sendType: os.campaign.sendType,
  state: os.state,
  version: os.version,
  savedVersion: os.savedVersion,
  channels: os.channels,
}))

export const orchestrationCampaignSelector: Extract<OrchestrationCampaignRecord> = state =>
  state.orchestration.campaign

export const campaignDraftErrorSelector: Extract<List<string>> = createSelector(
  orchestrationMetaSelector,
  ({ name }) => {
    const err: Array<string> = []
    if (!name) err.push('Enter a name to save.')
    return new Immutable.List().push(...err)
  }
)

export const orchestrationHashSelector: Extract<number> = createSelector(
  orchestrationStateSelector,
  orchestration => orchestration.hashCode()
)

export const campaignErrorSelector: Extract<List<string>> = createSelector(
  orchestrationCampaignSelector,
  orchestrationMetaSelector,
  messageStateSelector,
  campaignDraftErrorSelector,
  (campaign, meta, content, errors) => {
    const err: Array<string> = errors.toArray()
    if (
      campaign.messageConfig.experiment.enabled &&
      campaign.messageConfig.experiment.variants.size < 2
    )
      err.push('At least 2 variants are required for A/B testing.')
    if (campaign.sendType === 'scheduled') {
      if (!campaign.oneTime.sendDate) err.push('Send date is missing.')
      if (campaign.oneTime.sendDate && campaign.oneTime.sendDate.isBefore(dayjs.utc()))
        err.push('Send date is in the past.')
    }
    if (campaign.sendType === 'recurring') {
      if (!campaign.recurrent.startDate)
        err.push('Recurring automation requires a valid start date.')
      if (
        (meta.state === 'NEW' || meta.state === 'DRAFT') &&
        campaign.recurrent.startDate &&
        campaign.recurrent.startDate.isBefore(dayjs.utc())
      )
        err.push('Start date is in the past.')
      if (campaign.recurrent.endDate && campaign.recurrent.endDate.isBefore(dayjs.utc()))
        err.push('End date is in the past.')
      if (
        campaign.recurrent.startDate &&
        campaign.recurrent.endDate &&
        campaign.recurrent.endDate.isBefore(campaign.recurrent.startDate)
      )
        err.push('End date is before start date.')
    }

    return new Immutable.List().push(...err)
  }
)

export const emptyMessageErrorsSelector: Extract<Map<string, Array<string>>> = () => Immutable.Map()

export const orchestrationErrorsSelector: Extract<List<string>> = createSelector(
  orchestrationMetaSelector,
  journeyErrorSelector,
  campaignErrorSelector,
  ({ sendType }, journeyErr, campaignErr) => (sendType === 'trigger' ? journeyErr : campaignErr)
)

export const orchestrationDraftSelector: Extract<List<string>> = createSelector(
  orchestrationMetaSelector,
  journeyErrorDraftSelector,
  campaignDraftErrorSelector,
  ({ sendType }, journeyDraftErr, campaignDraftErr) =>
    sendType === 'trigger' ? journeyDraftErr : campaignDraftErr
)

export const orchestrationLabelSelector: Extract<List<LabelRecord>> = createSelector(
  orchestrationStateSelector,
  labelsSelector,
  (orchestration, labelsState) =>
    orchestration.labelCodes.toList().map(code => {
      const match = labelsState.labels.find(label => label.code === code)
      return match
        ? match
        : LabelFactory({
            code,
            isDeleted: true,
          })
    })
)

export const orchestrationIDSelector: Extract<string> = createSelector(
  orchestrationStateSelector,
  orchestration => orchestration.id
)

export const orchestrationPlatformsSelector: Extract<Set<ProjectPlatforms>> = createSelector(
  orchestrationMetaSelector,
  campaignMessageConfigSelector,
  messageNodesSelector,
  (meta, campaignMessageConfig, nodes) => {
    if (meta.sendType === 'trigger') {
      let platforms = new Immutable.Set<ProjectPlatforms>()
      for (const [, { messageConfig }] of nodes.entries()) {
        platforms = platforms.union(messageConfig.platforms)
        if (platforms.size === 3) break
      }
      return platforms
    }
    return campaignMessageConfig.platforms
  }
)
