// @flow

import Immutable, { type Map, type RecordOf, type RecordInstance } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  BoxBody,
  BoxSection,
  BoxFooter,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Select, AutoCompleteMulti, InputWrapper } from 'components/form'
import { LinkDocumentation } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import {
  type AttributeRecord,
  type AttributeProp,
  AttributeFactory,
  type State,
} from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { searchOptions } from 'com.batch.redux/attribute.api'
import { allAttrSelector } from 'com.batch.redux/attribute.selector'
import {
  AdditionalActionFactory,
  type SdkActionRecord,
  type AdditionalActionRecord,
} from 'com.batch.redux/content.records'
import { fetchEventData } from 'com.batch.redux/stat.api'

const attributeNameRegex = /^[a-zA-Z0-9_]{1,30}$/g

type CtaModalProps = {
  action: SdkActionRecord,
  update: SdkActionRecord => void,
  close: () => void,
  editedIndex: number | null,
  mode: 'EVENT' | 'ADD_TAG' | 'REMOVE_TAG',
}

const stringToLabelValue = (value: string) => ({
  label: value,
  value,
})

const optionToStringCollection = (
  option: ?(
    | AttributeRecord
    | RecordOf<AttributeProp>
    | { id: string, label: string, ... }
    | (RecordInstance<AttributeProp> & $ReadOnly<AttributeProp>)
  )
) => option?.label ?? ''

const optionToStringLabel = (option: ?{ value: string, label: string, ... }) => option?.label ?? ''

const optionToStringTag = (option: ?string) => option ?? ''

const optionFormatter = (option: string) => (typeof option === 'string' ? option : option.label)

export const CtaModal = ({
  action,
  mode,
  close,
  editedIndex,
  update,
}: CtaModalProps): React.Node => {
  // ---------------- local state
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const [additional, setAdditional] = React.useState<AdditionalActionRecord>(
    typeof editedIndex === 'number'
      ? action.additional.get(editedIndex, AdditionalActionFactory({ mode }))
      : AdditionalActionFactory({
          mode,
        })
  )

  // ---------------- callbacks
  const optionsSelector = React.useCallback(
    (state: State) => {
      const options = allAttrSelector(state)
        .filter(
          attr => !attr.hidden && (mode === 'EVENT' ? attr.type === 'EVENT' : attr.type === 'TAG')
        )
        .toList()
      if (
        additional.name !== '' &&
        options.filter(attr => attr.id === additional.name).size === 0
      ) {
        return options.push(
          AttributeFactory({ id: additional.name, label: additional.name.substring(2) })
        )
      }
      return options
    },
    [additional.name, mode]
  )

  const confirmAdditional = React.useCallback(() => {
    let err = []
    if (additional.name === '')
      err.push([
        'name',
        additional.mode === 'EVENT' ? 'Please pick an event.' : 'Please pick a collection.',
      ])
    if (additional.tags.length === 0 && additional.mode !== 'EVENT')
      err.push(['tags', 'Please select at least one tag.'])

    setErrors(Immutable.Map(err))
    if (err.length === 0) {
      update(
        action.set(
          'additional',
          typeof editedIndex === 'number'
            ? action.additional.set(editedIndex, additional)
            : action.additional.push(additional)
        )
      )
      close()
    }
  }, [action, additional, editedIndex, update, close])

  // ---------------- redux
  const options = useSelector(optionsSelector)
  const app = useSelector(currentAppSelector)

  // ---------------- memo
  const isExistingTagCollection = React.useMemo(() => {
    return options.some(option => option.id === additional.get('name') && option.type === 'TAG')
  }, [options, additional])

  const optionCreatorCollection = React.useMemo(() => {
    return mode === 'REMOVE_TAG'
      ? undefined
      : text => {
          if (text.match(attributeNameRegex)) {
            return {
              id: `${mode === 'EVENT' ? 'e.' : 't.'}${text}`,
              label: text,
            }
          }
          throw new Error('Invalid name')
        }
  }, [mode])

  const optionCreatorLabel = React.useCallback(text => {
    if (text.length > 0 && text.length < 200) {
      return { value: text, label: text }
    }
    throw new Error('Invalid label')
  }, [])

  const optionCreatorTag = React.useCallback(text => {
    if (text.length > 0 && text.length < 200) {
      return text
    }
    throw new Error('Invalid tag')
  }, [])

  const loadOptions = React.useCallback(
    search => {
      if (isExistingTagCollection) {
        if (mode !== 'EVENT') {
          return searchOptions({ app, search, attributeId: additional.name })
        } else {
          return fetchEventData({
            appId: app.id,
            query: search,
            eventId: additional.name,
            eventDataType: 'label',
            eventDataAttributeKey: 'label',
          }).then(({ body }) => {
            try {
              return new Immutable.List().push(
                ...body.results[additional.name]
                  .filter(item => item.value !== '')
                  .map(item => stringToLabelValue(item.value))
              )
            } catch (_) {
              return new Immutable.List()
            }
          })
        }
      }
      return Promise.resolve(new Immutable.List())
    },
    [additional.name, app, mode, isExistingTagCollection]
  )

  const handleChangeName = React.useCallback(
    option => {
      if (option) setAdditional(additional.set('name', option.id))
    },
    [additional]
  )

  const handleChangeLabel = React.useCallback(
    option => {
      setAdditional(additional.set('label', option ? option.value : ''))
    },
    [additional]
  )

  const handleChangeTag = React.useCallback(
    tagList => {
      setAdditional(additional.set('tags', tagList ? tagList.toArray() : []))
    },
    [additional]
  )

  return (
    <Popin close={close} opened>
      <Box style={{ width: 840 }} noBorder>
        <BoxHeader>
          <HeaderBoxTitle
            prefix={
              mode === 'EVENT' ? (
                <Icon
                  icon="campaign-trigger"
                  thickness={1.3}
                  style={{ marginRight: 8, color: colors.text }}
                />
              ) : mode === 'ADD_TAG' ? (
                <Icon icon="label" thickness={1.3} style={{ marginRight: 8, color: colors.text }} />
              ) : (
                mode === 'REMOVE_TAG' && (
                  <Icon
                    icon="label"
                    thickness={1.3}
                    style={{ marginRight: 8, color: colors.text }}
                  />
                )
              )
            }
            title={
              mode === 'EVENT'
                ? 'Track event'
                : mode === 'ADD_TAG'
                  ? 'Set tag'
                  : mode === 'REMOVE_TAG'
                    ? ' Remove tag'
                    : ''
            }
          />
          <HeaderBoxActions>
            <Button onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody style={{ overflow: 'hidden' }}>
          <Grid template="1fr 380px" alignItems="stretch">
            <div style={{ padding: '20px 12px 20px 20px' }}>
              <InputWrapper
                label={mode === 'EVENT' ? 'Event' : 'Collection'}
                feedback={errors.get('name')}
              >
                <Select
                  onChange={handleChangeName}
                  optionCreator={optionCreatorCollection}
                  optionToString={optionToStringCollection}
                  options={options}
                  placeholder={
                    mode === 'EVENT'
                      ? 'Pick or type an event name'
                      : mode === 'REMOVE_TAG'
                        ? 'Pick a collection'
                        : 'Pick or type a collection'
                  }
                  isSearchable
                  invalid={errors.has('name')}
                  value={options.find(e => e.id === additional.name)}
                />
              </InputWrapper>
              <InputWrapper
                label={
                  mode === 'EVENT' ? (
                    <React.Fragment>
                      Label <span style={{ color: '#777d87' }}>(optional)</span>
                    </React.Fragment>
                  ) : (
                    'Tag value'
                  )
                }
                feedback={errors.get('tags')}
              >
                {mode === 'EVENT' ? (
                  <Select
                    isDisabled={!additional.name}
                    key={additional.name}
                    optionToString={optionToStringLabel}
                    onChange={handleChangeLabel}
                    optionCreator={optionCreatorLabel}
                    isClearable
                    isSearchable
                    placeholder="Create or select label..."
                    value={additional.label === '' ? null : stringToLabelValue(additional.label)}
                    loadOptions={additional.name ? loadOptions : undefined}
                  />
                ) : (
                  <AutoCompleteMulti
                    optionToString={optionToStringTag}
                    isDisabled={!additional.name}
                    onChange={handleChangeTag}
                    optionCreator={optionCreatorTag}
                    key={additional.name}
                    optionFormatter={optionFormatter}
                    isClearable
                    placeholder={
                      mode === 'REMOVE_TAG' ? 'Select a tag...' : 'Create or select a tag...'
                    }
                    invalid={errors.has('tags')}
                    loadOptions={loadOptions}
                    value={
                      additional.name
                        ? new Immutable.List().push(...additional.tags)
                        : new Immutable.List()
                    }
                  />
                )}
              </InputWrapper>
            </div>
            {mode === 'EVENT' ? (
              <BoxSection back style={{ padding: '20px 20px 32px 20px', borderColor: '#e6e6e6' }}>
                <p>Track when your users interact with your message button.</p>
                <p style={{ marginTop: 4 }}>
                  Give a name to your event directly in the form or search for events that exist in
                  the system.
                </p>
                <p style={{ marginTop: 12 }}>
                  <em>Examples:</em>
                </p>
                <ul style={{ listStyle: 'none', marginTop: 4 }}>
                  <li style={{ marginTop: 4 }}>
                    Event: <code>inapp_confirm_button</code>
                  </li>
                  <li style={{ marginTop: 4 }}>
                    Label: <code>campaign_spring2021_NEW</code>
                  </li>
                </ul>

                <LinkDocumentation
                  href="https://help.batch.com/en/articles/5376282-how-to-use-in-app-interaction-event-tracking"
                  target="_blank"
                  intent="action"
                  style={{ marginTop: 18 }}
                >
                  How to use In-app interaction event tracking?
                </LinkDocumentation>
              </BoxSection>
            ) : (
              <BoxSection back style={{ padding: '20px 20px 32px 20px', borderColor: '#e6e6e6' }}>
                {mode === 'REMOVE_TAG' ? (
                  <React.Fragment>
                    <p>Remove a tag when your users interact with your message button. </p>
                    <p style={{ marginTop: 4 }}>
                      Search for the collection and tag(s) that exist in the system.
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p>Set a tag when your users interact with your message button.</p>
                    <p style={{ marginTop: 4 }}>
                      Create a new collection and the tag(s) to add to your users, or search for
                      tags that exist in the system.
                    </p>
                    <p style={{ marginTop: 12 }}>
                      <em>Example:</em>
                    </p>
                    <ul style={{ listStyle: 'none', marginTop: 4 }}>
                      <li style={{ marginTop: 4 }}>
                        Tags: <code>inapp_button_click_list</code>
                      </li>
                      <li style={{ marginTop: 4 }}>
                        Tag: <code>rating_confirm</code>
                      </li>
                    </ul>
                  </React.Fragment>
                )}
                <LinkDocumentation
                  href="https://help.batch.com/en/articles/5376297-how-to-use-in-app-interactions-tags"
                  target="_blank"
                  intent="action"
                  style={{ marginTop: 18 }}
                >
                  How to use In-App interactions tags?
                </LinkDocumentation>
              </BoxSection>
            )}
          </Grid>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={close}>
            Cancel
          </Button>
          <Button
            kind="primary"
            intent="action"
            onClick={confirmAdditional}
            disabled={additional.name === '' || (additional.tags.length === 0 && mode !== 'EVENT')}
          >
            Add action
          </Button>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
