// @flow
import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type DurationUnit } from 'com.batch/capping/model/capping-rule'
import { generateProjectKeyBlockRequest } from 'com.batch/shared/infra/generate-block-request'

export type GetProjectInfoResponse = {
  name: string,
  labels: Array<{
    description: string,
    code: string,
  }>,
  labelCappings?: { [string]: { cappings: Array<CappingRulePayload> } },
  smsProviders?: SmsProviders,
}

export type SetLabelsResponse = {
  labels: Array<{
    description: string,
    code: string,
  }>,
}

export type UnsetLabelsResponse = {
  labelCodes: Set<string>,
}

export type CappingRulePayload = {
  durationUnit: DurationUnit,
  durationValue: number,
  capping: number,
  label: string,
}

export type SmsProviders = {
  alphanumericSenderIDs?: string[],
  maxParts?: number,
}

export const metadataService = {
  fetchProject: async ({ projectKey }: { projectKey: string }): Promise<GetProjectInfoResponse> => {
    return request
      .post<GetProjectInfoResponse>(
        generateUrl('api_grpc_metadata_service', {
          methodName: 'GetProjectInfo',
        }),
        { projectKey }
      )
      .catch(error => {
        throw new Error(
          `Error while loading project metadata for project ${projectKey}: ${error.message}`
        )
      })
  },
  setLabels: async ({
    projectKey,
    labels,
  }: {
    projectKey: string,
    labels: Array<$ReadOnly<{ description: string, code: string }>>,
  }): Promise<SetLabelsResponse> => {
    return request
      .post<SetLabelsResponse>(
        generateUrl('api_grpc_metadata_service', { methodName: 'SetLabels' }),
        {
          labels,
          ...generateProjectKeyBlockRequest(projectKey),
        }
      )
      .catch(error => {
        throw new Error(
          `Error while loading project metadata for project ${projectKey}: ${error.message}`
        )
      })
  },
  unsetLabels: async ({
    projectKey,
    labelCodes,
  }: {
    projectKey: string,
    labelCodes: Array<string>,
  }): Promise<UnsetLabelsResponse> => {
    return request.post<UnsetLabelsResponse>(
      generateUrl('api_grpc_metadata_service', { methodName: 'UnsetLabels' }),
      {
        labelCodes,
        ...generateProjectKeyBlockRequest(projectKey),
      }
    )
  },
  setCappingRules: async ({
    cappingRules,
    projectKey,
    label,
  }: {
    cappingRules: Map<
      string,
      Array<{
        label: string,
        durationValue: number,
        durationUnit: DurationUnit,
        capping: number,
      }>,
    >,
    projectKey: string,
    label: string,
  }): Promise<void> => {
    return request
      .post<void>(
        generateUrl('api_grpc_metadata_service', { methodName: 'SetMarketingPressureCappings' }),
        {
          labelCappings: {
            [label]: {
              cappings: cappingRules.get(label) || [],
            },
          },
          ...generateProjectKeyBlockRequest(projectKey),
        }
      )
      .catch(response => {
        if (response.status === 400 && response.error?.errors?.length > 0) {
          return Promise.reject(response.error.errors[0]?.description)
        } else {
          throw new Error(`Unknown error while saving capping rules for project ${projectKey}`)
        }
      })
  },
  unsetCappingRules: async ({
    projectKey,
    labelCodes,
  }: {
    projectKey: string,
    labelCodes: Array<string>,
  }): Promise<void> => {
    return request.post<void>(
      generateUrl('api_grpc_metadata_service', { methodName: 'UnsetMarketingPressureCappings' }),
      {
        labelCodes,
        ...generateProjectKeyBlockRequest(projectKey),
      }
    )
  },
}
