// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'

type FetchMonthlyActiveProfilesAction = ReduxAction<'FETCH_MONTHLY_ACTIVE_PROFILES', null>
type FetchMonthlyActiveProfilesSuccessAction = ReduxAction<
  'FETCH_MONTHLY_ACTIVE_PROFILES_SUCCESS',
  number,
>
type FetchMonthlyActiveProfilesFailureAction = ReduxAction<
  'FETCH_MONTHLY_ACTIVE_PROFILES_FAILURE',
  string,
>

export type FetchMonthlyActiveProfilesActions =
  | FetchMonthlyActiveProfilesAction
  | FetchMonthlyActiveProfilesSuccessAction
  | FetchMonthlyActiveProfilesFailureAction

export const fetchMonthlyActiveProfiles =
  ({
    projectKeys,
    month,
  }: {
    projectKeys: Array<string>,
    month: string,
  }): DispatchExtraBoundFn<Promise<any>> =>
  async (dispatch, getState, { ucpDataService }) =>
    promiseActionCreator({
      dispatch,
      promise: ucpDataService.getMonthlyActiveProfiles({
        projectKeys,
        month,
      }),
      actionName: 'FETCH_MONTHLY_ACTIVE_PROFILES',
    })
