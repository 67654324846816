// @flow

import { type Map } from 'immutable'
import * as React from 'react'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'
import { useAttributesSheet } from 'com.batch/profile/ui/components/events-block/attributes-sheet/attributes-sheet-provider'

type Props = {
  name: string,
  attributes: Array<QueryValue>,
  allAttributes: Map<string, QueryValue>,
  event: string,
  ...
}

export const ArrayAttributeRow = ({
  name,
  attributes,
  event,
  allAttributes,
}: Props): React.Node => {
  const { open } = useAttributesSheet()

  const handleOnClick = React.useCallback(() => {
    open(allAttributes, event)
  }, [open, allAttributes, event])

  const attributeType = React.useMemo(() => {
    const plural = attributes.length === 1 ? '' : 's'
    if (attributes[0]?.type === 'OBJECT') return `object${plural}`
    return `string${plural}`
  }, [attributes])

  return (
    <AttributeRow
      name={name}
      icon="collection"
      isCopyable={false}
      onClick={handleOnClick}
      iconAction="external"
    >
      Array of {attributes.length} {attributeType}
    </AttributeRow>
  )
}
