// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { HardLinkOrNavLink } from 'components/navigation/hard-link-or-nav-link'

import { generateSettingsUrl } from 'com.batch.common/router'

import { currentAppSelector } from 'com.batch.redux/app'
import { currentCompanySelector } from 'com.batch.redux/company'
import { currentUserSelector } from 'com.batch.redux/user.selector'

export const AppSettingsMenu = (): React.Node => {
  const app = useSelector(currentAppSelector)
  const user = useSelector(currentUserSelector)
  const company = useSelector(currentCompanySelector)

  // Restriction for team
  const userCanAdministrate = user.permissionsForCurrentCompany.has('administrate')

  return (
    <React.Fragment>
      <HardLinkOrNavLink
        hardReload={false}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          activeTab: 'global',
        })}
      >
        General
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={false}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          activeTab: 'push',
        })}
      >
        Push settings
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={false}
        disabled={!userCanAdministrate}
        addOn={!userCanAdministrate ? 'suffix' : undefined}
        $withIconSuffix={!userCanAdministrate}
        as={userCanAdministrate ? NavLink : 'button'}
        url={
          !userCanAdministrate
            ? ''
            : generateSettingsUrl({
                companyId: company.id,
                channel: { appId: app.id },
                activeTab: 'team',
              })
        }
      >
        <span>Team</span>
        {!userCanAdministrate && (
          <Tooltip
            tooltip="Only an administrator can view and edit users access"
            placement="bottom"
          >
            <span className="styled-button-suffix" style={{ marginLeft: 4 }}>
              <Icon icon="lock" size={12} />
            </span>
          </Tooltip>
        )}
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={false}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          activeTab: 'custom-data',
        })}
      >
        Custom data
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={false}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          activeTab: 'audiences',
        })}
      >
        Audiences
      </HardLinkOrNavLink>
      {app.platform !== 'windows' && app.platform !== 'webpush' && (
        <HardLinkOrNavLink
          hardReload={false}
          url={generateSettingsUrl({
            companyId: company.id,
            channel: { appId: app.id },
            activeTab: 'themes',
          })}
        >
          Themes
        </HardLinkOrNavLink>
      )}
      <HardLinkOrNavLink
        hardReload={false}
        style={{ marginRight: 12 }}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          activeTab: 'debug',
        })}
      >
        Debug
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={false}
        style={{ marginRight: 12 }}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          activeTab: 'privacy',
        })}
      >
        Privacy
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={false}
        style={{ marginRight: 12 }}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          activeTab: 'labels',
        })}
      >
        Labels
      </HardLinkOrNavLink>
      <HardLinkOrNavLink
        hardReload={false}
        url={generateSettingsUrl({
          companyId: company.id,
          channel: { appId: app.id },
          activeTab: 'cappings',
        })}
      >
        Cappings
      </HardLinkOrNavLink>
    </React.Fragment>
  )
}
