// @flow

import styled, { type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { colors, schemes } from 'components/styled/tokens'

export const EmptyTitle: StyledComponent<*, *, HTMLElement> = styled.h4`
  flex: 0 0 auto;
  max-width: 88%;
  font-size: 15px;
  font-size: 1.06em;
  font-weight: 500;
  color: ${colors.text};
  text-align: center;
`

export const EmptyText: StyledComponent<*, *, HTMLElement> = styled.p`
  flex: 0 0 auto;
  max-width: 88%;
  font-size: 1em;
  font-weight: 400;
  color: ${colors.textLight};
  text-align: center;
`

export const EmptyImage: StyledComponent<*, *, HTMLElement> = styled.img`
  display: block;
  flex: 0 1 auto;
  max-width: 100%;
`

export const EmptyLinks: StyledComponent<*, *, HTMLElement> = styled.div`
  a {
    display: inline-block;

    + a {
      margin: 0 0 0 32px;
    }
  }
`
type EmptyProps = { small?: boolean, ... }
export const Empty: StyledComponent<EmptyProps, *, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 50;
  padding: calc(26px + 1%) 26px 26px 26px;
  font-size: ${(props: EmptyProps) => (props.small ? 13 : 14)}px;

  a {
    font-size: 1em;
    font-weight: 500;
    color: ${colors.textAction};

    &:hover,
    &:focus {
      color: ${schemes.blue['70']};
    }
    &:active {
      color: ${schemes.blue['80']};
    }

    ${IconContainer} {
      margin: 0 0 0 0.3em;
    }
  }

  ${EmptyTitle} + ${EmptyText}, ${EmptyTitle} + a, ${EmptyTitle} + ${EmptyLinks} {
    margin: 0.7em 0 0 0;
  }
  ${EmptyText} + a, ${EmptyText} + ${EmptyLinks} {
    margin: 0.6em 0 0 0;
  }
  ${EmptyTitle} + ${EmptyText} + a, ${EmptyTitle} + ${EmptyText} + ${EmptyLinks} {
    margin: 1.8em 0 0 0;
  }
  ${EmptyImage} {
    margin: 1.8em auto 0 auto;

    & + ${EmptyText}, & + a,
    & + ${EmptyLinks} {
      margin: 1.4em 0 0 0;
    }
  }
`
