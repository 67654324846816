// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { SeparatorLine } from 'components/campaign/campaign-list.styles'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { textLight } from 'components/styled/tokens/colors'

import { pluralize, textUsesTemplating } from 'com.batch.common/utils'

import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { InfoPanelCounterContainer } from 'com.batch/message-builder/ui/components/info-panel/info-panel.styles'
import { STOP_MSG_LENGTH } from 'com.batch/sms/constants/charset-counts'
import { countSmsMessage } from 'com.batch/sms/usecases/count-sms-message'
import { validateSmsMessage } from 'com.batch/sms/usecases/validate-sms-fields'

interface InfoPanelCounterProps {
  value: string;
}

export const SmsMessageInfoPanel: React.ComponentType<InfoPanelCounterProps> = ({
  value,
}: InfoPanelCounterProps) => {
  const targetedUserbase = useSelector(subscriptionStatusSelector)
  const isMarketing = React.useMemo(() => targetedUserbase === 'marketing', [targetedUserbase])
  const count = React.useMemo(
    () => countSmsMessage({ message: value, countStop: isMarketing }),
    [isMarketing, value]
  )
  const hasVariable = React.useMemo(() => textUsesTemplating(value), [value])

  const partsCount = React.useMemo(() => {
    if (count.mode === 'GSM7') {
      if (count.parts > 1) {
        return 153
      } else {
        return 160
      }
    } else {
      if (count.parts > 1) {
        return 67
      } else {
        return 70
      }
    }
  }, [count])

  const validationState = React.useMemo(() => {
    const err = validateSmsMessage({ message: value, parts: count.parts })
    if (err.size === 0) return 'success'
    if (err.has('MSG_TOO_LONG')) return 'error'
    return 'default'
  }, [count.parts, value])

  return (
    <InfoPanelCounterContainer $state={validationState}>
      <Icon icon="arrow-double-horizontal" className="section-icon" thickness={1.3} />
      <span className="styled-counter" data-testid="char-count">
        {hasVariable && '≈ '}
        {pluralize('character', count.count)}
        {hasVariable && (
          <Tooltip
            tooltip="Since you are using dynamic content, character count may differ."
            minWidth={210}
          >
            <Icon icon="help" size={12} style={{ marginLeft: 7, color: textLight }} />
          </Tooltip>
        )}
      </span>
      {isMarketing && (
        <span className="styled-hint" data-testid="stop-count" style={{ fontSize: 14 }}>
          {`STOP counts for ${STOP_MSG_LENGTH} chars `}
        </span>
      )}

      <SeparatorLine style={{ marginBlock: 10 }} />
      <div data-testid="message-parts">
        {count.parts} message part{count.parts > 1 ? 's' : ''}{' '}
        <span className="styled-hint">({count.mode})</span>
      </div>
      <span className="styled-hint" style={{ fontSize: 14 }}>
        {`1 part is ${partsCount} characters `}
      </span>
    </InfoPanelCounterContainer>
  )
}
