// @flow

import { type Set } from 'immutable'
import * as React from 'react'
import { useRef } from 'react'
import { ThemeProvider } from 'styled-components'

import { useToggle } from 'components/_hooks'
import { SwitchButton } from 'components/common/button'

import { convertArrayPlatformToPreviewPlatform, textUsesTemplating } from 'com.batch.common/utils'

import { PushLayerBar } from './layer/push-layer-bar'

import { type AppRecord, type CampaignABRecord } from 'com.batch.redux/_records'
import { type PushSettingsRecord } from 'com.batch.redux/content.records'

import { type InlineEditorConfigRecord } from 'com.batch/message-builder/models/message-builder.records'
import {
  InlineContainer,
  Preview,
  PreviewContainer,
  PreviewMockup,
  PushBuilderToolbar,
  StickyInfoPanelContainer,
  type ThemeProps,
  VariantToggler,
} from 'com.batch/message-builder/ui/components'
import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'

const previewPlatformMatcher: { [PreviewPlatform]: Channel, ... } = {
  android: 'android',
  ios: 'ios',
  webMac: 'webpush',
  webWin: 'webpush',
  webIos: 'webpush',
  webAndroid: 'webpush',
}

type VariantProps = {
  abtesting: CampaignABRecord,
  apps: Set<AppRecord>,
  editorConfig: InlineEditorConfigRecord,
  setInlineEditor: typeof setInlineEditor,
  openFileUploader: () => void,
  openMediaPopin: () => void,
  draggingState: draggingState,
  pushSettings: PushSettingsRecord,
  toggleVariant: ('a' | 'b') => void,
  variant: 'a' | 'b',
  previewPlatform: PreviewPlatform,
  setPreviewPlatform: PreviewPlatform => void,
}

export const Variant = ({
  abtesting,
  toggleVariant,
  draggingState,
  variant,
  editorConfig,
  apps,
  pushSettings,
  openFileUploader,
  openMediaPopin,
  setInlineEditor,
  previewPlatform,
  setPreviewPlatform,
}: VariantProps): React.Node => {
  // --- local state
  const expandedToggle = useToggle()

  const setEditing = React.useCallback(
    (editing, caret: number) => {
      let newConfig = editorConfig
        .set('variant', variant)
        .set('field', editing)
        .set('selection', [caret, caret])
      if (editing !== 'title' && editing !== 'message') {
        setInlineEditor(newConfig.set('selection', [0, 0]))
      } else {
        setInlineEditor(newConfig)
      }
    },
    [editorConfig, setInlineEditor, variant]
  )
  const editing = React.useMemo(
    () => (editorConfig.variant === variant ? editorConfig.field : null),
    [editorConfig.field, editorConfig.variant, variant]
  )

  const variantEnabled = abtesting.activeVariants.has(variant)

  const targetedPlatforms = React.useMemo(() => Array.from(apps.map(p => p.platform)), [apps])

  const availablePreviewPlatforms = React.useMemo(
    () => convertArrayPlatformToPreviewPlatform(targetedPlatforms),
    [targetedPlatforms]
  )

  const previewedApp = React.useMemo(() => {
    return apps.find(app => app.platform === previewPlatformMatcher[previewPlatform])
  }, [apps, previewPlatform])

  React.useEffect(() => {
    setPreviewPlatform(availablePreviewPlatforms.length > 0 ? availablePreviewPlatforms[0] : 'ios')
  }, [availablePreviewPlatforms, setPreviewPlatform])

  // La notif est scrollée pour être affichée en entier (e.g: si image ou long texte)
  // Trigger: Upload d'image, changement de plateforme, appui bouton expand
  const previewContainer = useRef<?HTMLElement>(null)
  React.useLayoutEffect(() => {
    if (previewContainer.current) {
      previewContainer.current.scrollTop = previewContainer.current.scrollHeight
    }
  }, [previewPlatform, expandedToggle.value, pushSettings.attachmentUrl])

  const openReplaceMedia = React.useCallback(() => {
    if (textUsesTemplating(pushSettings.attachmentUrl) || pushSettings.attachmentKind !== 'image') {
      openMediaPopin()
    } else {
      openFileUploader()
    }
  }, [openFileUploader, openMediaPopin, pushSettings.attachmentKind, pushSettings.attachmentUrl])

  const previewTheme = React.useMemo<ThemeProps>(
    () => ({
      previewPlatform,
      openReplaceMedia,
      hasIcon: pushSettings.iconUrl !== '',
      hasMedia: pushSettings.attachmentUrl !== '',
      isExpanded: expandedToggle.value,
      abTestingEnabled: abtesting.enabled,
      draggingState,
      isFocused: editing === 'message' || editing === 'title',
      app: previewedApp,
    }),
    [
      previewPlatform,
      openReplaceMedia,
      pushSettings.iconUrl,
      pushSettings.attachmentUrl,
      expandedToggle.value,
      draggingState,
      editing,
      previewedApp,
      abtesting,
    ]
  )

  const onVariantToggle = React.useCallback(() => {
    toggleVariant(variant)
  }, [toggleVariant, variant])

  return (
    <React.StrictMode>
      <ThemeProvider theme={previewTheme}>
        <InlineContainer
          enabled={abtesting.enabled ? variantEnabled : true}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <StickyInfoPanelContainer id={`js-variant-${variant}`} />
          {abtesting.enabled && (
            <VariantToggler active={variantEnabled} $variant={variant}>
              <SwitchButton isActive={variantEnabled} onChange={onVariantToggle}>
                <strong>Version {variant.toUpperCase()}</strong>
                <span>
                  {variantEnabled
                    ? `${100 / abtesting.activeVariants.size}% of targeted users`
                    : "Won't be send"}
                </span>
              </SwitchButton>
            </VariantToggler>
          )}
          <PushLayerBar
            hasIcon={pushSettings.iconUrl !== ''}
            showIcon={targetedPlatforms.some(platform =>
              ['android', 'webpush', 'windows'].includes(platform)
            )}
            hasMedia={pushSettings.attachmentUrl !== ''}
            openFileUploader={openFileUploader}
            openMediaPopin={openMediaPopin}
            setEditing={setEditing}
          />

          <PreviewMockup />
          <PreviewContainer ref={previewContainer} previewPlatform={previewPlatform}>
            <Preview previewPlatform={previewPlatform} variant={variant} />
          </PreviewContainer>
          <PushBuilderToolbar
            availablePreviewPlatforms={availablePreviewPlatforms}
            previewPlatform={previewPlatform}
            isExpanded={expandedToggle.value}
            setPreviewPlatform={setPreviewPlatform}
            toggleIsExpanded={expandedToggle.toggle}
          />
        </InlineContainer>
      </ThemeProvider>
    </React.StrictMode>
  )
}
