// @flow

import * as React from 'react'

import { Avatar } from 'components/common/avatar'
import { Icon } from 'components/common/svg-icon'

import { ucFirst } from 'com.batch.common/utils'

import { type UserRecord } from 'com.batch.redux/user.records'

type UserAvatarProps = {
  user: UserRecord,
  size?: number,
  style?: any,
}

export function UserAvatar({ user, size = 26, style }: UserAvatarProps): React.Node {
  return (
    <Avatar
      style={{ margin: '0', verticalAlign: 'middle', fontSize: size, ...style }}
      size={size}
      color="#99A1AA"
      url={user.avatarUrl || undefined}
      placeholder={user.isInvite ? <Icon icon="mail" /> : ucFirst(user.firstName)}
    />
  )
}
