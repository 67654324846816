// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Grid } from 'components/common/grid'
import {
  Table,
  TableBody,
  TableCell,
  TableCellHeader,
  TableHeader,
  TableRow,
} from 'components/common/table'
import { TableContainer } from 'components/common/table/table.styles'
import { Tint } from 'components/styled/colors'
import { schemes } from 'components/styled/tokens'
import { fillBackground } from 'components/styled/tokens/colors'
import { Scrollbar } from 'components/styled/utils'

export const ProcessingAgreement = (): React.Node => (
  <AgreementContainer>
    <h4>
      BATCH.COM
      <br />
      DATA PROCESSING AGREEMENT
    </h4>
    <p>
      According to the nature of the tools and services of Batch and according to the Guidelines of
      EDPB dated 2 September 2020, Parties have defined together the nature of the processing as
      follows:
    </p>
    <Table
      template="50px 100px 75px 100px 100px 150px 100px 75px 100px"
      style={{ width: '1500px' }}
    >
      <TableHeader>
        <TableCellHeader></TableCellHeader>
        <TableCellHeader>Purpose</TableCellHeader>
        <TableCellHeader>Controller</TableCellHeader>
        <TableCellHeader>Processor</TableCellHeader>
        <TableCellHeader>Categories of data</TableCellHeader>
        <TableCellHeader>Categories of data subjects</TableCellHeader>
        <TableCellHeader>Retention duration</TableCellHeader>
        <TableCellHeader>Country</TableCellHeader>
        <TableCellHeader>Sub-contractor</TableCellHeader>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>1</TableCell>
          <TableCell>Performance of the contract</TableCell>
          <TableCell>Client</TableCell>
          <TableCell>Batch</TableCell>
          <TableCell>Data provided by Client</TableCell>
          <TableCell>Clients and prospects of the Client</TableCell>
          <TableCell>Contract + 1 year</TableCell>
          <TableCell>EU</TableCell>
          <TableCell>OVH</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>2</TableCell>
          <TableCell>Contractual relationship follow-up</TableCell>
          <TableCell>Batch</TableCell>
          <TableCell>-</TableCell>
          <TableCell>
            Employees of the Client; invoicing data; contractual data; litigation data
          </TableCell>
          <TableCell>Clients and prospects of the Client</TableCell>
          <TableCell>Contract + 5 years</TableCell>
          <TableCell>EU</TableCell>
          <TableCell>OVH</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>3</TableCell>
          <TableCell>Prospection</TableCell>
          <TableCell>Batch</TableCell>
          <TableCell>-</TableCell>
          <TableCell>Contact details of employees of the Client</TableCell>
          <TableCell>Employees of the Client</TableCell>
          <TableCell>Contract + 3 years</TableCell>
          <TableCell>EU</TableCell>
          <TableCell>OVH</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    <h5>1. DEFINITIONS</h5>
    <p>
      Otherwise specifically expressed in the present DPA, the terms "<b>Authority</b>", "
      <b>Personal Data</b>", "<b>Data Subject</b>", "<b>BCR</b>", "<b>Controller</b>", "
      <b>Processor</b>", "<b>Subcontractor</b>", "<b>Processing</b>" and "
      <b>Personal Data Breach</b>" shall have the same meaning as in the Regulation 2016/679 of 27
      April 2016 on the protection of natural persons with regard to the processing of personal data
      and on the free movement of such data ("<b>GDPR</b>").
    </p>
    <Grid template="auto auto">
      <p>
        <b>Applicable data protection Laws</b>
      </p>
      <p>
        Any and all national, European and international laws, regulations, statutes and other
        norms, applicable to the Processing of Personal Data set out in the present agreement,
        including the GDPR, the French law dated 6 January 1978, the applicable e-privacy
        regulations. Applicable data protection Laws are interpreted by the national Authorities and
        the EDPB.
      </p>
    </Grid>
    <h5>2. OBLIGATIONS OF BATCH.COM</h5>
    <p>
      Batch GDPR obligations are limited to the following. Batch warrants the Client against any
      verification, action or procedure raised by a third party based on the following obligations,
      shall keep the Client harmless and pay all fees, condemnations, attorneys’ fees and other sums
      that the Client shall bear due to these procedures.
    </p>
    <h6>2.1. Processing operation carried out on behalf of the Client</h6>
    <p>
      Batch shall process Personal Data only on documented instructions from the Client or according
      to the Agreement, including with regard to transfers of personal data to a third country,
      unless required to do so by Union or Member State law to which Batch is subject. In such a
      case, Batch shall inform the Client of that legal requirement before processing, unless that
      law prohibits such information on important grounds of public interest.
    </p>
    <p>
      Batch shall keep and maintain an up-to-dated record of instructions from the Client. This
      document shall contain the mention provided by article 30 of the GDPR. Furthermore, the
      Processing implied by the Agreement and/or the Processing provided in this Appendix III shall
      be deemed as an instruction of the Client and therefore, recorded by Batch.
    </p>
    <p>
      Although Batch is not a law firm or a law company, shall Batch estimate, in its opinion, that
      a documented instruction from the Client regarding the Processing infringes the GDPR or other
      Applicable data protection Law, Batch shall immediately inform the Client, being specified
      that the Client shall remain the final and sole responsible of the validity of the said
      instruction.
    </p>
    <h6>2.2. Accountability</h6>
    <p>
      Batch has implemented internal procedures allowing the demonstration of the respect of the
      Applicable data protection Laws as Processor.
    </p>
    <p>
      On a reasonable basis, Batch agrees that the Client may, with a 15 days written prior notice,
      request information necessary to demonstrate compliance with the obligations laid down in this
      DPA and, accordingly, send specific questions and requests of documentation. These questions
      shall be limited to the respect of the Applicable data protection Laws in the framework of the
      performance of the Agreement. Batch shall answer promptly (and in case within one month). By
      exception, Batch shall answer within the reasonable timeframes provided in case of request or
      control of a data protection Authority.
    </p>
    <h6>2.3. Assistance of the Client</h6>
    <p>
      Batch undertakes to assist the Client regarding specific and written request in relation to
      rights or obligations as provided by articles 32 to 36 of the GDPR, taking into account the
      Processing concerned and the information available to Batch, and notably:
    </p>
    <ul>
      <li>
        the performance and/or enforcement of rights of data subjects (for instance, portability,
        access, etc…);
      </li>
      <li>the participation to a PIA.</li>
    </ul>
    <p>
      Accordingly, the Client shall provide Batch with a specific and circumstantial request,
      allowing Batch to know precisely the nature of the questions and its tasks. Batch shall answer
      promptly. Parties agrees that should such assistance be beyond an hour per week, Batch shall
      be entitled to invoice over time according to a 1000€/day rate.
    </p>
    <p>
      Batch shall perform the Agreement with reasonable efforts (obligation de moyens). However, in
      case of security breach, Batch undertakes to (i) alert promptly the Client and provide him
      with reasonable and necessary information (to the extent possible to Batch), (ii) assist the
      Client and, the case may be, the data protection Authority, and (iii) assist on a reasonable
      basis the Client for remedies. Batch shall cooperate with the Client in case of request or
      control of a data protection Authority.
    </p>
    <h6>2.4. Sub-contracting</h6>
    <p>
      From a general point of view, Batch shall engage another processor without any specific
      information or authorization from the Client where no Personal Data are concerned. However, in
      case of sub-contracting for Processing of Personal Data, Batch shall be entitled to engage
      another Processor with a prior specific notice of the Client. The Client may refuse such
      sub-contracting for cause (juste motif). Any silence beyond 15 days shall be deemed as a tacit
      acceptation.
    </p>
    <p>
      Batch shall only engage another Processor who are able to ensure the compliance of the
      Processing to the Applicable data protection Laws. Processors shall provide for sufficient
      guarantees to implement appropriate technical and organizational measures in such a manner
      that processing will meet the requirements of Applicable data protection Laws and ensure the
      protection of the rights of the data subject.
    </p>
    <p>
      Where that other Processor fails to fulfil its data protection obligations, Batch shall remain
      liable, according to the Agreement, to the Client for the performance of that other
      processor's obligations.
    </p>
    <p>At the execution date, the Processors are:</p>
    <Table template="100px 200px 150px 100px 100px 100px 100px">
      <TableHeader>
        <TableCellHeader>Processing</TableCellHeader>
        <TableCellHeader>Name / address of the Subprocessor</TableCellHeader>
        <TableCellHeader>Subcontracting representatives</TableCellHeader>
        <TableCellHeader>Place of the subprocessing</TableCellHeader>
        <TableCellHeader>Categories of Personal Data</TableCellHeader>
        <TableCellHeader>Categories of Data Subjects</TableCellHeader>
        <TableCellHeader>Retention Duration by the Subprocessor</TableCellHeader>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>1</TableCell>
          <TableCell rowSpan={3}>OVH, 2 rue Kellermann - 59100 Roubaix</TableCell>
          <TableCell rowSpan={3}>Octave Klaba</TableCell>
          <TableCell rowSpan={3}>EU</TableCell>
          <TableCell>Data provided by Client</TableCell>
          <TableCell>Clients and prospects of the Client</TableCell>
          <TableCell>Contract + 1 year</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>2</TableCell>
          <TableCell>
            Employees of the Client; invoicing data; contractual data; litigation data
          </TableCell>
          <TableCell>Clients and prospects of the Client</TableCell>
          <TableCell>Contract + 5 years</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>3</TableCell>
          <TableCell>Contact details of employees of the Client</TableCell>
          <TableCell>Employees of the Client</TableCell>
          <TableCell>Contract + 3 years</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    <h6>2.5. Confidentiality of Personal Data</h6>
    <p>
      Batch ensures that persons authorized to process the Personal Data have committed themselves
      to confidentiality or are under an appropriate statutory obligation of confidentiality in the
      performance of the Supply Agreement.
    </p>
    <p>
      Accordingly, Batch informs the Client that all employment agreements with employees contain a
      confidentiality provision as well as supply agreements with Subprocessors, without prejudice
      to the legal and/or mandatory applicable rules. Furthermore, Batch provided an
      awareness-raising and training of staff involved in Processing operations.
    </p>
    <p>
      Should Batch is required to disclose Personal Data by Applicable data protection Law or a
      court or other tribunal of competent jurisdiction or to a Authority or governmental Authority,
      Batch shall (to the extent possible to Batch) notify the Client of such obligation and shall
      reasonably cooperate with the Client to protect the confidentiality of the Personal Data.
    </p>
    <h6>2.6. Privacy by design</h6>
    <p>
      Batch has implemented internal procedures allowing the demonstration of the respect of privacy
      by design principles.
    </p>
    <h6>2.7. Security / security breach of Personal Data</h6>
    <p>
      Batch has implemented appropriate technical and organizational measures to ensure a level of
      security appropriate to the risk aiming at:
    </p>
    <ul>
      <li>
        ensuring the ongoing confidentiality, integrity, availability and resilience of processing
        systems and services according to the state of art and the present Agreement and;
      </li>
      <li>
        restoring the availability and access to Personal Data in a timely manner in the event of a
        physical or technical incident according to the state of art and the present Agreement
      </li>
    </ul>
    <p>
      Accordingly, Batch has taken into account the state of art, the modalities and nature of the
      Processing as described therebefore, and the security requirements as provided by French laws
      applicable to Batch.
    </p>
    <p>
      Batch shall maintain and update these technical and organizational measures and, the case may
      be, implement new technical and organizational measures as long as the Agreement is in force.
    </p>
    <p>
      Batch shall inform the Client of any Personal Data Breach in relation to the Personal Data.
      This communication shall be “without undue delay after becoming aware of a Personal Data
      Breach” according to article 33.2 of the GDPR. Batch undertakes to implement and/or propose to
      the Client any necessary remedy to (i) identify the origin, nature and consequences of such
      Data Protection Breach and/or (ii) limit or neutralize the consequences.
    </p>
    <p>The information provided to the Client according to the present Section, shall :</p>
    <ul>
      <li>
        describe the nature of the personal data breach including where possible, the categories and
        approximate number of Data Subjects concerned and the categories and approximate number of
        Personal Data records concerned;
      </li>
      <li>
        communicate the name and contact details of the person where more information can be
        obtained;
      </li>
      <li>describe the likely consequences of the Personal Data Breach;</li>
      <li>
        describe the measures taken or proposed to be taken by the Controller to address the
        Personal Data Breach, including, where appropriate, measures to mitigate its possible
        adverse effects.
      </li>
    </ul>
    <p>
      Where, and in so far as, it is not possible to provide the information at the same time, the
      information may be provided in phases without undue further delay.
    </p>
    <p>
      Batch undertakes to make its reasonable efforts to assist the Client in the framework of its
      obligations of notification of Personal Data Breach to the applicable Authorities as well as,
      the case may be, to the Data Subjects.
    </p>
    <h6>2.8. Transfer of Personal Data to third countries</h6>
    <p>At the execution date, Batch shall not transfer any Personal Data outside the EU.</p>
    <h6>2.9. Personal Data retention duration</h6>
    <p>
      Batch shall retain Client’s Personal Data according to the durations defined by the Client and
      to the Processing specifications as set out therebefore. The Client acknowledges that Batch
      shall retain the Client’s data for a duration of 12 months as from the end of this Agreement
      if the Client does not provide for a duration. The Client shall bear sole liability regarding
      the definition of retention duration. After the duration, Batch shall erase Client’s data. In
      case of Personal Data erasure, Batch shall keep all necessary evidences of such erasure.
    </p>

    <h5>3. OBLIGATIONS OF THE CLIENT</h5>
    <p>
      The GDPR obligations of the Client are limited to the following. The Client warrants Batch
      against any verification, action or procedure raised by a third party based on the following
      obligations, shall keep Batch harmless and pay all fees, condemnations, attorneys’ fees and
      other sums that Batch shall bear due to these procedures.
    </p>
    <p>
      The Client declares and warrants that he has properly (i) informed Data Subjects and provided
      to them with all mandatory mentions and information regarding their rights and/or (ii)
      collected the consent of the Data Subjects according to the Applicable data protection Laws.
    </p>
    <p>
      The Client warrants that he has implemented appropriate technical and organizational measures
      to ensure a level of security appropriate to the risk aiming at:
    </p>
    <ul>
      <li>
        ensuring the ongoing confidentiality, integrity, availability and resilience of processing
        systems and services according to the state of art and the present Agreement and;{' '}
      </li>
      <li>
        restoring the availability and access to personal data in a timely manner in the event of a
        physical or technical incident according to the state of art and the present Agreement
      </li>
    </ul>
    <p>
      As controller, the Client undertakes and warrants that he has defined, for each category of
      Personal Data and each purpose, a retention duration in accordance with the GDPR and the
      Applicable data protection Laws. The Client undertakes to communicate by written to Batch
      these durations.
    </p>
  </AgreementContainer>
)

export const AgreementContainer: StyledComponent<*, *, *> = styled.div`
  max-height: 400px;
  padding: 26px 24px;
  overflow: scroll;
  line-height: 1.4;
  border: 1px solid ${schemes.darklucent['30']};
  background: ${schemes.grayscale['01']};
  box-shadow: 0 1px 3px ${schemes.darklucent['02']};
  border-radius: 4px;
  ${Scrollbar({ color: Tint(fillBackground, 34), background: schemes.grayscale['01'] })}
  ${TableContainer} {
    max-width: 580px;
    overflow-x: auto;
  }
  p,
  ul,
  h6 {
    margin: 0 0 1em 0;
    line-height: 1.6em;
  }
  h4 {
    margin: 0 0 1.3em 0;
    font-size: 1.3em;
    line-height: 1.4;
  }
  h5 {
    font-size: 1.2em;
    margin: 2em 0 1em 0;
  }
  h6 {
    margin: 2em 0 0.5em 0;
    font-weight: 600;
  }
  ul {
    padding: 0 0 0 25px;
  }

  *:last-child {
    margin-bottom: 0;
  }
`
