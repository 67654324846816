// @flow

import * as React from 'react'

import { WrappedValidatedInput } from 'components/campaign/form/validated-input'
import { Switch } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper, MediaUrl } from 'components/form'
import { PaddedBox, VariantPreview, VariantTitle } from 'components/styled/text'

import validators from 'com.batch.common/form.validators'

import { type AppRecord } from '../../../redux/_records'
import { updatePushContentMEP } from 'com.batch.redux/content'
import { type PushContentLegacyRecord } from 'com.batch.redux/content.records'

type ContentPushVariantProps = {
  content: PushContentLegacyRecord,
  hasTemplate: boolean,
  isTitleRequired: boolean,
  lang: string,
  show: () => any,
  shown: boolean,
  showVariantInfo: boolean,
  toggle: boolean => any,
  updatePushContent: typeof updatePushContentMEP,
  variant: 'a' | 'b',
  weight: number,
  app: AppRecord,
  campaignHasLanding: boolean,
  ...
}

const trim = (str: string) => str.trim()

export const ContentPushVariant = ({
  content,
  hasTemplate,
  isTitleRequired,
  lang,
  show,
  shown,
  showVariantInfo,
  toggle,
  updatePushContent,
  variant,
  weight,
  app,
  campaignHasLanding,
}: ContentPushVariantProps): React.Node => {
  const platform = app.platform
  const iOS = platform === 'ios'
  const android = platform === 'android'
  const allowedMedia = iOS ? ['image', 'audio', 'video'] : ['image']

  const updateDeeplinkContent = React.useCallback(
    value => {
      updatePushContent({
        lang,
        variant,
        content: {
          key: 'deeplink',
          value: trim(value),
        },
      })
    },
    [lang, updatePushContent, variant]
  )

  const updateMediaContent = React.useCallback(
    media => {
      updatePushContent({
        lang,
        variant,
        content: {
          key: 'mediaUrl',
          value: media.mediaUrl,
        },
      })
      updatePushContent({
        lang,
        variant,
        content: {
          key: 'mediaKind',
          value: media.mediaKind,
        },
      })
    },
    [lang, updatePushContent, variant]
  )

  const updateTitleContent = React.useCallback(
    value => {
      updatePushContent({
        lang,
        variant,
        content: {
          key: 'title',
          value: content.title.set('value', value),
        },
      })
    },
    [content.title, lang, updatePushContent, variant]
  )

  const updateMessageContent = React.useCallback(
    value => {
      updatePushContent({
        lang,
        variant,
        content: {
          key: 'message',
          value: content.message.set('value', value),
        },
      })
    },
    [content.message, lang, updatePushContent, variant]
  )

  return (
    <PaddedBox first={variant === 'a'} style={{ paddingBottom: variant === 'a' ? 0 : 30 }}>
      {showVariantInfo && (
        <React.Fragment>
          <VariantTitle variant={variant} disabled={weight === 0}>
            <Switch
              onChange={toggle}
              isActive={weight > 0}
              style={{
                width: '100%',
                justifyContent: 'flex-start',
                textAlign: 'left',
                marginLeft: 0,
              }}
            >
              <div className="styled-variant-text">
                Version {variant.toUpperCase()} ({weight}%)
              </div>
            </Switch>
          </VariantTitle>
          <VariantPreview onClick={show} active={shown}>
            <Icon icon="view" size={10} />
          </VariantPreview>
        </React.Fragment>
      )}
      <InputWrapper>
        <WrappedValidatedInput
          touched={false}
          onChange={updateTitleContent}
          value={content.title.value}
          valid={Boolean(isTitleRequired || validators.required(content.title.value))}
          name={`title-${variant}-${lang}`}
          rows={1}
          type={hasTemplate ? 'template' : 'text'}
          placeholder={`Notification title ${isTitleRequired ? '' : ' (optional)'}`}
        />

        <WrappedValidatedInput
          value={content.message.value}
          touched={false}
          valid={Boolean(isTitleRequired || validators.required(content.message.value))}
          onChange={updateMessageContent}
          name={`message-${variant}-${lang}`}
          rows={2}
          type={hasTemplate ? 'template' : 'text'}
          placeholder="Write the message here..."
        />
      </InputWrapper>

      <MediaUrl
        onChange={updateMediaContent}
        value={{
          mediaKind: content.mediaKind,
          mediaUrl: content.mediaUrl || '',
        }}
        label={iOS ? 'Custom image, audio or video' : 'Custom image'}
        platforms={[app.platform]}
        allowedMedia={allowedMedia}
        showTemplateWizard={app.features.has('macro')}
        imageMinWidth={300}
        imageMinHeight={200}
        imageRecommandation={{
          message: android
            ? 'Landscape image. Width must be at least 300px, height at least 200px.'
            : 'Width must be at least 300px, height at least 200px.',
          docLinkText: 'Learn more',
          docLinkUrl:
            app.platform === 'webpush'
              ? 'https://help.batch.com/en/articles/4227847-how-can-i-attach-an-image-to-a-web-push-notification'
              : 'https://help.batch.com/en/articles/3993058-what-is-the-best-image-size-for-my-push-notification',
        }}
        optional
        style={{ marginTop: 0 }}
      />
      <div style={{ marginTop: 20 }}>
        <WrappedValidatedInput
          onChange={updateDeeplinkContent}
          value={content.deeplink}
          valid={true}
          touched={false}
          label="Deeplink"
          optional
          name={`deeplink-${variant}-${lang}`}
          disabled={campaignHasLanding}
          placeholder={campaignHasLanding ? 'Deeplink are not compatible with mobile landing' : ''}
          hint="Link triggered when the user opens the notification."
          hintMinSize={240}
          type={app.features.has('macro') ? 'template' : 'text'}
          rows={1}
        />
      </div>
    </PaddedBox>
  )
}
