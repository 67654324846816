// @flow

import * as React from 'react'

import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Tester } from 'components/tester/tester'

type ContentOptionProps = {
  hasAbTesting: boolean,
  abTestingInitiallyEnabled: boolean,
  togglePushAbTesting: (state: boolean) => any,
  type: 'in-app' | 'push' | 'email',
  valid: boolean,
  campaignId: string | null,
}

export const ContentOption = ({
  hasAbTesting,
  abTestingInitiallyEnabled,
  togglePushAbTesting,
  type,
  valid,
  campaignId,
}: ContentOptionProps): React.Node => {
  return (
    <FlexLine>
      {/* On ne peut pas désactiver ABTesting si la campagne existe déjà.  */}
      {(campaignId === null || !abTestingInitiallyEnabled) && (
        <FlexLineItem>
          <Button
            kind="secondary"
            intent="neutral"
            onClick={() => togglePushAbTesting(!hasAbTesting)}
          >
            {hasAbTesting ? 'Undo' : 'Start'} A/B Testing
          </Button>
        </FlexLineItem>
      )}
      <FlexLineItem>
        {type === 'push' && (
          <Tester
            isThemeTester={false}
            valid={valid}
            btnText={type === 'push' ? 'Send a test' : 'Preview on my phone'}
          />
        )}
      </FlexLineItem>
    </FlexLine>
  )
}
