// @flow

import * as React from 'react'
import { createPortal } from 'react-dom'

import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import { DocLink, LinkArrow } from 'components/styled/text'

import {
  BannerContainer,
  BannerTextContainer,
  BannerLinkContainer,
  BannerCloseContainer,
  BannerIconContainer,
} from './banner.styles'

type BannerProps = {
  kind?: 'global' | 'bloc',
  intent?: 'danger' | 'blocked' | 'limited',
  title?: string,
  content?: string,
  link?: { kind: 'doc', href: string, ... } | { kind: 'basic', name: string, href: string, ... },
  hasCloseButton?: boolean,
  icon?: availableIcons,
  style?: { ... },
}

const BannerRaw = ({
  kind,
  intent = 'blocked',
  title,
  content,
  link,
  hasCloseButton,
  icon,
  style = {},
}: BannerProps): React.Node => {
  const [hide, setHide] = React.useState<boolean>(false)

  if (hide) return null
  return (
    <BannerContainer
      intent={intent}
      kind={kind}
      hide={hide}
      className="styled-banner"
      style={style}
    >
      <Grid
        template={`${icon ? '34px' : ''} minmax(100px, 1fr) ${link ? 'auto' : ''} ${
          hasCloseButton ? '39px' : ''
        }`}
        gap={0}
      >
        {!!icon && (
          <BannerIconContainer>
            <div style={{ marginTop: -2 }}>
              <Icon icon={icon} />
            </div>
          </BannerIconContainer>
        )}

        <BannerTextContainer hasContent={!!content}>
          <h4>{title}</h4>
          <p>{content}</p>
        </BannerTextContainer>

        {!!link && (
          <BannerLinkContainer>
            {link.kind === 'doc' ? (
              <DocLink intent="dark" href={link.href}>
                Documentation
              </DocLink>
            ) : (
              link.kind === 'basic' && (
                <LinkArrow intent="dark" href={link.href}>
                  {link.name}
                </LinkArrow>
              )
            )}
          </BannerLinkContainer>
        )}

        {!!hasCloseButton && (
          <BannerCloseContainer>
            <Button
              kind="inlineDark"
              intent="neutral"
              style={{ height: 28 }}
              onClick={() => setHide(!hide)}
              data-testid="test_banner_close"
            >
              <Icon icon="close" />
            </Button>
          </BannerCloseContainer>
        )}
      </Grid>
    </BannerContainer>
  )
}

const BannerPortal = ({ kind = 'global', ...rest }: BannerProps): React.Node => {
  const globalBannersContainer = document.getElementById('global-banners-container')

  const elem = kind === 'global' ? globalBannersContainer : null

  if (elem) return createPortal(<BannerRaw {...rest} kind={kind} />, elem)
  else return <BannerRaw {...rest} kind={kind} />
}

export const Banner: React.ComponentType<BannerProps> = BannerPortal
