// @flow

import {
  type OrchestrationAnalyticsFilters,
  type OrchestrationAnalyticsDateRangeRecord,
} from '../store/orchestration-analytics.state'
import { type DispatchBoundFn } from 'com.batch.redux/_records'

export type UpdateAnalyticsFiltersAction = {
  type: 'UPDATE_ANALYTICS_FILTERS',
  payload: {
    token: string,
    filters: OrchestrationAnalyticsFilters,
  },
}

export const updateAnalyticsFilters = (payload: {
  token: string,
  filters: OrchestrationAnalyticsFilters,
}): DispatchBoundFn<UpdateAnalyticsFiltersAction> => {
  return dispatch => {
    return dispatch({
      type: 'UPDATE_ANALYTICS_FILTERS',
      payload,
    })
  }
}

export type UpdateAnalyticsDateRangeAction = {
  type: 'UPDATE_ANALYTICS_DATE_RANGE',
  payload: {
    token: string,
    dateRange: ?OrchestrationAnalyticsDateRangeRecord,
  },
}

export const updateAnalyticsDateRange = (payload: {
  token: string,
  dateRange: ?OrchestrationAnalyticsDateRangeRecord,
}): DispatchBoundFn<UpdateAnalyticsDateRangeAction> => {
  return dispatch => {
    return dispatch({
      type: 'UPDATE_ANALYTICS_DATE_RANGE',
      payload,
    })
  }
}
