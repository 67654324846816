// @flow

import * as React from 'react'

// eslint-disable-next-line import/no-cycle
import { Button } from 'components/common/button'
import { ConfirmFooter } from 'components/common/confirm.styles'

type Props = {
  onClickCancel: () => void,
  cancel: string,
  onClickConfirm: () => void,
  confirm?: string,
  sensitive?: boolean,
  confirmDisabled?: boolean,
  isLoading?: boolean,
}

export const ConfirmModalFooter = ({
  onClickCancel,
  cancel,
  onClickConfirm,
  confirm,
  sensitive,
  confirmDisabled,
  isLoading,
}: Props): React.Node => {
  return (
    <ConfirmFooter>
      <Button kind="inline" onClick={onClickCancel}>
        {cancel}
      </Button>
      <Button
        kind="primary"
        intent={sensitive ? 'danger' : 'action'}
        onClick={onClickConfirm}
        disabled={confirmDisabled}
        isLoading={isLoading}
      >
        {confirm}
      </Button>
    </ConfirmFooter>
  )
}
