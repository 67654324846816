// @flow

import { type OrderedSet } from 'immutable'
import { get as _get } from 'lodash-es'

import {
  AbTestedInAppFactory,
  type AbTestedInAppProps,
  AbTestedPushFactory,
  type AbTestedPushProps,
  type attachmentKind,
  ContentStateFactory,
  type ContentStateRecord,
  type PushContentLegacyProps,
  PushSettingsFactory,
  type PushSettingsProps,
  type PushSettingsRecord,
  type SdkActionRecord,
  type StringValueRecord,
} from './content.records'

import {
  type DispatchBoundFn,
  type LanguageRecord,
  type PushConfigRecord,
  type ReduxAction,
} from 'com.batch.redux/_records'
import { normalizeApp } from 'com.batch.redux/app.api'
import { currentCampaign } from 'com.batch.redux/campaign.selector'

type updateCampaignNameAction = {
  type: 'UPDATE_CAMPAIGN_NAME',
  payload: string,
}
export const updateCampaignName = (
  payload: $PropertyType<updateCampaignNameAction, 'payload'>
): updateCampaignNameAction => {
  return {
    type: 'UPDATE_CAMPAIGN_NAME',
    payload,
  }
}

// ====================== PUSH SETTINGS
type updatePushSettingsAction = {
  type: 'UPDATE_PUSH_SETTINGS',
  payload: {
    field: $Keys<PushSettingsProps>,
    value: any,
  },
}
export const updatePushSettings = (
  field: $Keys<PushSettingsProps>,
  value: any
): updatePushSettingsAction => {
  return {
    type: 'UPDATE_PUSH_SETTINGS',
    payload: {
      field,
      value,
    },
  }
}

type replacePushSettingsAction = ReduxAction<'REPLACE_PUSH_SETTINGS', PushSettingsRecord>

export const replacePushSettings = (payload: PushSettingsRecord): replacePushSettingsAction => {
  return {
    type: 'REPLACE_PUSH_SETTINGS',
    payload,
  }
}

// Action utilisée par le formulaire push MEP
type updatePushContentMEPAction = {
  type: 'UPDATE_PUSH_CONTENT_MEP',
  payload: {
    lang: string,
    variant: $Keys<AbTestedPushProps>,
    content:
      | { key: 'title' | 'message', value: StringValueRecord, ... }
      | { key: 'mediaUrl' | 'deeplink', value: string, ... }
      | { key: 'mediaKind', value: attachmentKind, ... },
  },
}
export const updatePushContentMEP = (
  payload: $PropertyType<updatePushContentMEPAction, 'payload'>
): updatePushContentMEPAction => {
  return {
    type: 'UPDATE_PUSH_CONTENT_MEP',
    payload: payload,
  }
}

// Action utilisée par le fake-omni-push
type updatePushContentOldAction = {
  type: 'UPDATE_PUSH_CONTENT_OLD',
  payload: {
    lang: string,
    variant: $Keys<AbTestedPushProps>,
    field: $Keys<PushContentLegacyProps>,
    value: StringValueRecord,
  },
}
export const updatePushContentOld = (
  payload: $PropertyType<updatePushContentOldAction, 'payload'>
): updatePushContentOldAction => {
  return {
    type: 'UPDATE_PUSH_CONTENT_OLD',
    payload: payload,
  }
}

// ====================== INAPP CONTENT
type updateInAppActionProps = {
  field:
    | 'header'
    | 'title'
    | 'text'
    | 'mainButtonLabel'
    | 'secondaryButtonLabel'
    | 'imageUrl'
    | 'linkOpenTarget'
    | 'webviewUrl'
    | 'imageAlt'
    | 'trackingId',
  value: string,
  lang: string,
  variant: $Keys<AbTestedInAppProps>,
}

export type updateInAppContentAction = ReduxAction<
  'UPDATE_INAPP_CONTENT',
  {
    ...updateInAppActionProps,
    translations: OrderedSet<string>,
  },
>
export type updateInAppContentActionCreator = updateInAppActionProps => updateInAppContentAction

export const updateInAppContent = ({
  lang,
  field,
  value,
  variant,
}: updateInAppActionProps): DispatchBoundFn<updateInAppContentAction> => {
  return (dispatch, getState) => {
    const campaign = currentCampaign(getState())
    return dispatch({
      type: 'UPDATE_INAPP_CONTENT',
      payload: { lang, field, value, translations: campaign.translations, variant },
    })
  }
}

// ====================== INAPP IS GIF
type updateInAppCIsGifAction = {
  type: 'UPDATE_INAPP_IS_GIF',
  payload: {
    lang: string,
    value: boolean,
    translations: OrderedSet<string>,
  },
}
export const updateInAppIsGif = ({
  lang,
  value,
}: {
  lang: string,
  value: boolean,
}): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const campaign = currentCampaign(getState())
    return dispatch({
      type: 'UPDATE_INAPP_IS_GIF',
      payload: {
        lang,
        value,
        translations: campaign.translations,
      },
    })
  }
}

// ====================== INAPP CTA
type updateInAppCtaAction = {
  type: 'UPDATE_INAPP_CTA',
  payload: {
    lang: string,
    cta: 'mainButtonAction' | 'secondaryButtonAction' | 'globalTapAction',
    value: SdkActionRecord,
    variant: $Keys<AbTestedPushProps>,
  },
}
export const updateInAppCta = (
  payload: $PropertyType<updateInAppCtaAction, 'payload'>
): updateInAppCtaAction => {
  return {
    type: 'UPDATE_INAPP_CTA',
    payload: payload,
  }
}
type CampaignAddTranslationAction = {
  type: 'ADD_CAMPAIGN_TRANSLATION',
  payload: LanguageRecord,
}
type CampaignSetCampaignLandingAction = {
  type: 'SET_CAMPAIGN_LANDING',
  payload: { active: boolean, ... },
}
export type CampaignContentLoadedAction = {
  type: 'EDITING_CAMPAIGN_CONTENT_LOADED',
  payload: ContentStateRecord,
}

type SetEditingCampaignAction = {
  type: 'SET_EDITING_CAMPAIGN',
  payload: {
    token: string,
    type: 'push' | 'inapp',
    lang: string,
    pushConfig: PushConfigRecord,
    recurringLegacyDisabled: boolean,
  },
}

export type ContentActions =
  | SetEditingCampaignAction
  | updateCampaignNameAction
  | updatePushContentMEPAction
  | updatePushContentOldAction
  | updatePushSettingsAction
  | replacePushSettingsAction
  | updateInAppContentAction
  | updateInAppCtaAction
  | updateInAppCIsGifAction
  | CampaignAddTranslationAction
  | CampaignSetCampaignLandingAction
  | CampaignContentLoadedAction

// ====================== INIT STATE

let pushSettings = PushSettingsFactory()
if (_get(window, 'initialData.app', false)) {
  const app = normalizeApp(window.initialData.app)
  const config = app.pushConfig
  pushSettings = PushSettingsFactory({
    priority: config.defaultPriority,
    collapseKey: config.defaultCollapseKey,
    hasExpiration: !!config.defaultTtl,
    expiration: config.defaultTtl || 0,
    hasCollapseKey: !!config.defaultCollapseKey,
  })
}

export default function ContentReducer(
  state: ContentStateRecord = ContentStateFactory({ pushSettings }),
  action: ContentActions
): ContentStateRecord {
  switch (action.type) {
    case 'SET_EDITING_CAMPAIGN':
      if (action.payload.token === 'new') {
        return ContentStateFactory({
          pushSettings: action.payload.type === 'push' ? pushSettings : PushSettingsFactory(),
        })
      }
      return state
    case 'EDITING_CAMPAIGN_CONTENT_LOADED':
      return action.payload
    case 'ADD_CAMPAIGN_TRANSLATION':
      return state.set(
        'inapp',
        state.inapp.set(
          action.payload.value,
          state.inapp.get(action.payload.value, AbTestedInAppFactory())
        )
      )
    case 'UPDATE_CAMPAIGN_NAME':
      return state.set('campaignName', action.payload)
    case 'REPLACE_PUSH_SETTINGS':
      return state.set('pushSettings', action.payload)
    case 'UPDATE_PUSH_SETTINGS':
      if (action.payload.field === 'attachmentKind') {
        return state.set(
          'pushSettings',
          state.pushSettings.set('attachmentKind', action.payload.value).set('attachmentUrl', '')
        )
      }
      return state.setIn(['pushSettings', action.payload.field], action.payload.value)
    case 'UPDATE_PUSH_CONTENT_OLD': {
      // kinda complex but flow complains about setIn so...
      const abtestedOld = state.push.get(action.payload.lang, AbTestedPushFactory())
      const pushContentOld = abtestedOld.get(action.payload.variant)
      return state.set(
        'push',
        state.push.set(
          action.payload.lang,
          abtestedOld.set(
            action.payload.variant,
            pushContentOld.set(action.payload.field, action.payload.value)
          )
        )
      )
    }
    case 'UPDATE_PUSH_CONTENT_MEP': {
      // kinda complex but flow complains about setIn so...
      const abtested = state.push.get(action.payload.lang, AbTestedPushFactory())
      const pushContent = abtested.get(action.payload.variant)
      const key: $Keys<PushContentLegacyProps> = action.payload.content.key
      return state.set(
        'push',
        state.push.set(
          action.payload.lang,
          abtested.set(action.payload.variant, pushContent.set(key, action.payload.content.value))
        )
      )
    }
    case 'UPDATE_INAPP_CONTENT': {
      const abtestedInapp = state.inapp.get(action.payload.lang, AbTestedInAppFactory())
      const inAppContent = abtestedInapp.get(action.payload.variant)

      return state.set(
        'inapp',
        state.inapp.set(
          action.payload.lang,
          abtestedInapp.set(
            action.payload.variant,
            inAppContent.set(action.payload.field, action.payload.value)
          )
        )
      )
    }

    case 'UPDATE_INAPP_CTA': {
      // Je passe par des variables ici car si je .setIn à la chaine on perd une des deux variantes lors d'un NEW
      const abTestedInApp = state.inapp.get(action.payload.lang, AbTestedInAppFactory())
      const inAppContentVariant = abTestedInApp.get(action.payload.variant)

      return state.set(
        'inapp',
        state.inapp.set(
          action.payload.lang,
          abTestedInApp.set(
            action.payload.variant,
            inAppContentVariant.set(action.payload.cta, action.payload.value)
          )
        )
      )
    }
    default:
      return state
  }
}
