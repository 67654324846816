// @flow

import { type Map } from 'immutable'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

// ====================== TYPES

type FetchSenderIdentitiesAction = {
  type: 'FETCH_SENDER_IDENTITIES',
  ...
}

export type FetchSenderIdentitiesSuccessAction = {
  type: 'FETCH_SENDER_IDENTITIES_SUCCESS',
  payload: Map<number, SenderIdentityRecord>,
  ...
}
type FetchSenderIdentitiesFailureAction = {
  type: 'FETCH_SENDER_IDENTITIES_FAILURE',
  ...
}

export type FetchSenderIdentitiesActions =
  | FetchSenderIdentitiesAction
  | FetchSenderIdentitiesSuccessAction
  | FetchSenderIdentitiesFailureAction

export const fetchSenderIdentities = (): DispatchExtraBoundFn<
  Promise<Map<number, SenderIdentityRecord>>,
> => {
  return (dispatch, getState, { senderIdentityGateway }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: senderIdentityGateway.fetchSenderIdentities(project.projectKey),
      actionName: 'FETCH_SENDER_IDENTITIES',
    })
  }
}
