// @flow

import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

type BoxedCheckboxLabelProps = { isSelected: boolean, isDisabled: ?boolean, ... }
export const BoxedCheckboxLabel: StyledComponent<BoxedCheckboxLabelProps, *, *> = styled.label`
cursor: pointer;
width: 100%;
height: 78px;
margin-right: 12px;
padding: 12px;

border-radius: 8px;
overflow: hidden;
border: none;
outline: ${(p: BoxedCheckboxLabelProps) =>
  p.isSelected && !p.isDisabled
    ? `2px solid ${colors.fillAction};`
    : p.isDisabled
      ? `1px solid ${colors.stroke};`
      : `1px solid ${colors.stroke};`}
background: ${(p: BoxedCheckboxLabelProps) => (p.isSelected ? colors.fill : colors.fillBackground)};
.styled-boxedcheckbox-title {
  font-size: 14px;

  font-weight: 500;
  color:${(p: BoxedCheckboxLabelProps) =>
    p.isDisabled && !p.isSelected ? colors.textDisabled : colors.text};
  letter-spacing: -0.01em;
}
.styled-boxedcheckbox-text {
  font-weight: normal;
  font-size: 14px;
  color:${(p: BoxedCheckboxLabelProps) =>
    p.isDisabled && !p.isSelected ? colors.textDisabled : colors.text};
  line-height: 16px;
  letter-spacing: -0.01em;
  }

`
