// @flow

import { get as _get } from 'lodash-es'

import {
  DemoCodeFactory,
  AppDemoCodeFactory,
  type DemoCodeRecord,
  type AppDemoCodeRecord,
} from './console.records'

export const normalizeDemoCodes = (raw: any): DemoCodeRecord => {
  return DemoCodeFactory({
    id: _get(raw, 'id'),
    description: _get(raw, 'description'),
    ios: AppDemoCodeFactory({
      id: _get(raw, 'ios_id', ''),
      name: _get(raw, 'ios_name', ''),
      apiKey: _get(raw, 'ios_api_key', ''),
    }),
    android: AppDemoCodeFactory({
      id: _get(raw, 'andoid_id', ''),
      name: _get(raw, 'android_name', ''),
      apiKey: _get(raw, 'android_api_key', ''),
    }),
  })
}

export const normalizeCode = (raw: any): AppDemoCodeRecord => {
  return AppDemoCodeFactory({
    id: _get(raw, 'id', ''),
    name: _get(raw, 'name', 'Unknown app'),
    apiKey: _get(raw, 'apiKey', ''),
  })
}
