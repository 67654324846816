// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

type PickableAppProps = { pickable?: boolean, noHover?: boolean, ... }
export const PickableApp: StyledComponent<PickableAppProps, { ... }, HTMLElement> = styled.label`
  display: flex;
  padding: 12px 24px;
  margin: 0;
  align-items: center;
  background-color: #ffffff;
  cursor: ${(props: PickableAppProps) => (props.pickable ? 'pointer' : 'default')};

  ${(props: PickableAppProps) =>
    !props.noHover
      ? css`
          &:hover {
            background-color: ${colors.opacifyHover};
          }
        `
      : ''}

  &:first-of-type {
    padding: 14px 24px 12px 24px;
  }
  &:last-child {
    padding: 12px 24px 16px 24px;
  }
`
export const PickableAppPlatform: StyledComponent<*, *, HTMLElement> = styled.span`
  flex-basis: 20px;
  margin: 0 12px 0 0;
  text-align: center;
`
type PickableAppIconProps = { icon?: ?string, ... }
export const PickableAppIcon: StyledComponent<PickableAppIconProps, { ... }, HTMLElement> =
  styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 0 0 36px;
    width: 36px;
    height: 36px;
    margin: 0 10px 0 0;
    overflow: hidden;
    border-radius: 3px;
    background-image: url(${(props: PickableAppIconProps) => (props.icon ? props.icon : 'none')});
    background-size: cover;
    background-position: center;
    color: ${colors.textLight};
    background-color: ${(props: PickableAppIconProps) =>
      props.icon ? '#ffffff' : colors.fillBackground};

    ${IconContainer} {
      display: ${(props: PickableAppIconProps) => (props.icon ? 'none' : 'block')};
    }
  `
export const PickableAppCheck: StyledComponent<*, *, HTMLElement> = styled.span`
  flex: 0 0 31px;
  input {
    border: 1px solid #e3e0e0;
    border-radius: 2px;
    width: 21px;
    height: 21px;
  }
`
export const PickableAppName: StyledComponent<*, *, HTMLElement> = styled.span`
  flex-grow: 1;
  white-space: nowrap;
  color: #2c3747;
  overflow: hidden;
  margin: 0 22px 0 0;
  white-space: nowrap;
  min-width: 0;
  text-overflow: ellipsis;
`
export const PickableAppEstimate: StyledComponent<*, *, HTMLElement> = styled.span`
  flex: 0 0 120px;
  text-align: right;
  color: #adb1b8;
  font-weight: 500;
`
export const PickableStatus: StyledComponent<*, *, HTMLElement> = styled.span`
  flex: 0 0 35px;
  cursor: pointer;
`
export const PickableAction: StyledComponent<*, *, HTMLElement> = styled.span`
  flex-basis: auto;
`

export const AppScrollableContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  overflow-y: auto;
  height: 256px;

  ${PickableApp} {
    margin: 0;
  }
`

export const ReplicateSearch: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  padding: 0 26px;
  border-bottom: 1px solid #f7f7f7;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01);

  input {
    padding: 18px 0;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    border: none;
    outline: none;
    &:hover,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #949aa3;
    }
  }
`

export const ReplicateInfo: StyledComponent<*, *, HTMLElement> = styled.div`
  color: #aaaaaa;
  border-bottom: 1px solid #f5f5f9;
  padding: 22px 26px;

  h6,
  p {
    line-height: 1.5;
  }
  h6 {
    margin: 0 0 6px 0;
    font-size: 14px;
    font-weight: 500;
    color: #383838;
  }
`
