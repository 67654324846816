// @flow
import Immutable, { type Map, type Set } from 'immutable'

import {
  type PushSettingsRecord,
  type EmailMessageRecord,
  type SmsMessageRecord,
  type PushMessageRecord,
} from '../../models/message.records'
import { MessageStateFactory, type MessageStateRecord } from 'com.batch/message/store/message.state'

import { parseEmailForCampaign, parseEmail } from 'com.batch/email/infra/parses/email.parse'
import { type EmailContent } from 'com.batch/email/infra/types/email.message.types'
import { type DashboardConfig } from 'com.batch/orchestration/infra/types/orchestration.types'
import { type EmailContentForCampaign } from 'com.batch/orchestration-campaign/infra/types/orchestration-campaign.types'
import { parsePush } from 'com.batch/push/infra/parses/push.parse'
import { type OrchestrationPush } from 'com.batch/push/infra/types/push.message.types'
import { parseSms } from 'com.batch/sms/infra/parses/sms.parse'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'

export type messageParserConfig = {
  contentFullyEmpty: boolean,
  contentInvalid: boolean,
  channel: ChannelUntilCleanup,
  multilanguageEnabled: boolean,
  pushSettings?: PushSettingsRecord,
  platforms?: Set<ProjectPlatforms>,
}

/*

  this is ultra-shitty
  UJ / trigger uses EmailContent
  campaign / recurring uses EmailContentForCampaign

  we need to handle both cases here
  and flow can't refine correctly so we need to duplicate

*/

export const parseMessages = ({
  messages,
  config,
}: {
  config: DashboardConfig,
  messages: {
    [string]: { email: EmailContent } | { sms: SmsContent } | { push: OrchestrationPush },
  },
}): {
  message: MessageStateRecord,
  subscriptionStatus: 'marketing' | 'fullbase',
  messageConfigs: { [string]: messageParserConfig },
} => {
  let emails: Map<string, EmailMessageRecord> = Immutable.Map()
  let sms: Map<string, SmsMessageRecord> = Immutable.Map()
  let pushes: Map<string, PushMessageRecord> = Immutable.Map()
  let subscriptionStatus: 'marketing' | 'fullbase' = 'marketing'
  let messageConfigs: { [string]: messageParserConfig } = {}
  if (messages) {
    Object.keys(messages).forEach(messageId => {
      if (messages[messageId].push) {
        const frozenPushForFlow = messages[messageId].push
        const parsed = parsePush(frozenPushForFlow)
        pushes = pushes.set(messageId, parsed.message)
        messageConfigs[messageId] = {
          pushSettings: parsed.settings,
          contentFullyEmpty: parsed.contentFullyEmpty,
          contentInvalid: parsed.contentInvalid,
          channel: 'push',
          platforms: parsed.platforms,
          multilanguageEnabled: (frozenPushForFlow.localizedPush?.length ?? 0) > 1,
        }
      }
      if (messages[messageId].email) {
        const frozenEmailForFlow = messages[messageId].email

        if (messages[messageId].email.target === 'Fullbase') {
          subscriptionStatus = 'fullbase'
        }

        if (frozenEmailForFlow.localizedEmails) {
          const parsed = parseEmail(frozenEmailForFlow, config?.emailAdditionnalConfig?.[messageId])
          emails = emails.set(messageId, parsed.message)
          messageConfigs[messageId] = {
            contentFullyEmpty: parsed.contentFullyEmpty,
            contentInvalid: parsed.contentInvalid,
            channel: 'email',
            multilanguageEnabled: (frozenEmailForFlow?.localizedEmails?.length ?? 0) > 1,
          }
        }
      }
      if (messages[messageId].sms) {
        const frozenSmsForFlow = messages[messageId].sms

        // ☢️ default value is not passed, and it is inverted on SMS, TRANSACTIONAL is default
        if (frozenSmsForFlow.target === 'MARKETING') {
          subscriptionStatus = 'marketing'
        } else {
          subscriptionStatus = 'fullbase'
        }
        const parsed = parseSms(frozenSmsForFlow, subscriptionStatus === 'marketing')

        sms = sms.set(messageId, parsed.message)
        messageConfigs[messageId] = {
          contentFullyEmpty: parsed.contentFullyEmpty,
          contentInvalid: parsed.contentInvalid,
          channel: 'sms',
          multilanguageEnabled: (frozenSmsForFlow?.localizedSms?.length ?? 0) > 1,
        }
      }
    })
  }
  return {
    message: MessageStateFactory({ email: emails, sms, push: pushes }),
    subscriptionStatus,
    messageConfigs,
  }
}

export const parseMessagesForCampaign = ({
  messages,
  config,
}: {
  config: DashboardConfig,
  messages: {
    [string]:
      | { emailChannel: EmailContentForCampaign }
      | { smsMessage: SmsContent }
      | { pushMessage: OrchestrationPush },
  },
}): {
  message: MessageStateRecord,
  subscriptionStatus: 'marketing' | 'fullbase',
  messageConfigs: { [string]: messageParserConfig },
  messageTypedId: string,
  multilanguageEnabled: boolean,
} => {
  let multilanguageEnabled = false
  let emails: Map<string, EmailMessageRecord> = Immutable.Map()
  let sms: Map<string, SmsMessageRecord> = Immutable.Map()
  let pushes: Map<string, PushMessageRecord> = Immutable.Map()
  let subscriptionStatus: 'marketing' | 'fullbase' = 'marketing'
  let messageTypedId = ''
  let messageConfigs: { [string]: messageParserConfig } = {}
  if (messages) {
    Object.keys(messages).forEach(messageId => {
      if (messages[messageId].pushMessage) {
        messageTypedId = messageId
        const frozenPushForFlow = messages[messageId].pushMessage
        if ((frozenPushForFlow.localizedPush?.length ?? 0) > 1) multilanguageEnabled = true
        const parsed = parsePush(frozenPushForFlow)
        pushes = pushes.set(messageId, parsed.message)
        messageConfigs[messageId] = {
          contentFullyEmpty: parsed.contentFullyEmpty,
          contentInvalid: parsed.contentInvalid,
          channel: 'push',
          pushSettings: parsed.settings,
          platforms: parsed.platforms,
          multilanguageEnabled: (frozenPushForFlow.localizedPush?.length ?? 0) > 1,
        }
      }
      if (messages[messageId].emailChannel) {
        const frozenEmailForFlow = messages[messageId].emailChannel
        if ((frozenEmailForFlow.email.localizedEmails?.length ?? 0) > 1) multilanguageEnabled = true
        messageTypedId = messageId
        if (messages[messageId].emailChannel.email.emailTarget === 'Fullbase') {
          subscriptionStatus = 'fullbase'
        }

        if (frozenEmailForFlow.email) {
          const parsed = parseEmailForCampaign(
            frozenEmailForFlow,
            config?.emailAdditionnalConfig?.[messageId]
          )
          emails = emails.set(messageId, parsed.message)
          messageConfigs[messageId] = {
            contentFullyEmpty: parsed.contentFullyEmpty,
            contentInvalid: parsed.contentInvalid,
            channel: 'email',
            multilanguageEnabled: (frozenEmailForFlow.email.localizedEmails?.length ?? 0) > 1,
          }
        }
      }
      if (messages[messageId].smsMessage) {
        messageTypedId = messageId
        const frozenSmsForFlow = messages[messageId].smsMessage
        if ((frozenSmsForFlow.localizedSms?.length ?? 0) > 1) multilanguageEnabled = true
        // default value is not passed, and for campaign we use OrchestrationSms TransmissionType with a default to marketing
        if (frozenSmsForFlow.target === 'TRANSACTIONAL') {
          subscriptionStatus = 'fullbase'
        } else {
          subscriptionStatus = 'marketing'
        }
        const parsed = parseSms(frozenSmsForFlow, subscriptionStatus === 'marketing')
        sms = sms.set(messageId, parsed.message)
        messageConfigs[messageId] = {
          contentFullyEmpty: parsed.contentFullyEmpty,
          contentInvalid: parsed.contentInvalid,
          channel: 'sms',
          multilanguageEnabled: (frozenSmsForFlow?.localizedSms?.length ?? 0) > 1,
        }
      }
    })
  }
  return {
    message: MessageStateFactory({ email: emails, sms, push: pushes }),
    subscriptionStatus,
    messageConfigs,
    messageTypedId,
    multilanguageEnabled,
  }
}
