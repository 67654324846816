// @flow

import * as React from 'react'

import RsrCondition from './rsr-condition'

import { type QbLogicalRecord } from 'com.batch.redux/targeting.records'

type RsrLogicalProps = {
  logical: QbLogicalRecord,
  root?: boolean,
  level?: number,
  getCampaignName: (token: string) => string,
}

const RsrLogical = ({ logical, root, level = 0, getCampaignName }: RsrLogicalProps): React.Node => {
  const schemeId = level
  const st = root ? { margin: 0 } : {}
  return (
    <div className="tg" style={st}>
      <div className="tg__conditionsWrap">
        <div className="tg__conditionsWrap__conditions">
          {logical.children.map((child, index) => {
            if (child.isLogical) {
              return (
                <div className={`tg_logicalAndStruf logicalScheme-${schemeId}`} key={index}>
                  {logical.children.last() !== child && (
                    <div
                      className="tg_logicalAndStruf__operator condi__logical condi__logical--bottom"
                      style={{ cursor: 'default' }}
                    >
                      {logical.kind}
                    </div>
                  )}
                  {
                    /* $FlowFixMe flow sait pas refine le type sur les records */
                    <RsrLogical
                      logical={child}
                      level={level + 1}
                      getCampaignName={getCampaignName}
                      last={index === logical.children.count() - 1 && root}
                    />
                  }
                </div>
              )
            } else {
              return (
                <div className="tg_logicalAndStruf" key={index}>
                  {logical.children.last() !== child && (
                    <div
                      className="tg_logicalAndStruf__operator condi__logical condi__logical--bottom"
                      style={{ cursor: 'default' }}
                    >
                      {logical.kind}
                    </div>
                  )}
                  <div className="tg_logicalAndStruf__stuff">
                    {
                      // $FlowFixMe flow sait pas refine le type sur les records
                      <RsrCondition
                        condition={child}
                        getCampaignName={getCampaignName}
                        last={index === logical.children.count() - 1 && root}
                      />
                    }
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default RsrLogical
