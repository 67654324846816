// @flow

import Immutable, { type Set, type List, type Map, type OrderedMap } from 'immutable'
import { createSelector } from 'reselect'

import {
  appCanQueryAttribute,
  appCanQueryEvent,
  appCanQueryNative,
  appCanQueryUserData,
  currentAppSelector,
} from './app'
import {
  CampaignTokenAttribute,
  CustomIDAttribute,
  EmailAttribute,
  EmailMarketingAttribute,
  filterCategory,
  attributeIsEvent,
  isAttributeMatchingTerm,
  RegionAttribute,
  TimeZoneAttribute,
} from './attribute.api'

import {
  type AttributeStateConfigRecord,
  SdkSupportsFactory,
  AttributeValuesListFactory,
  AttributeFactory,
  EventDataAttributeFactory,
  type AttributeCategory,
  type AttributeValueState,
  type AttributeValuesListRecord,
  type SdkSupportsRecord,
  type AttributeRecord,
  type State,
} from 'com.batch.redux/_records'
import { type QbConditionRecord } from 'com.batch.redux/targeting.records'

export const allAttrSelector = (state: State): OrderedMap<string, AttributeRecord> =>
  state.attribute.entities
// gets the custom data (attribute, event, transaction & tags) for the current app
export const customSelector: State => OrderedMap<string, AttributeRecord> = createSelector(
  allAttrSelector,
  attributes => attributes.filter(attr => !attr.native)
)

// gets the native attributes for the current app
export const nativeSelector: State => OrderedMap<string, AttributeRecord> = createSelector(
  allAttrSelector,
  attributes => attributes.filter(attr => attr.native)
)

// gets the map of all values, mapped by attribute id
export const valueSelector = (state: State): AttributeValueState => {
  return state.attrValue
}

export const geoipCityNameGetterSelector: State => string => string = createSelector(
  valueSelector,
  allValues => {
    const values = allValues.getIn(['b.city_code', 'values', '__default'], new Immutable.List())
    return (id: string) => {
      return values.find(v => v.value.toString() === id.toString())?.pretty ?? id
    }
  }
)

// gets the corrent input attribute filter string
export const categorySelector: State => ?string = (state: State) =>
  state.attribute.config.filterCategory

// gets the corrent input attribute filter string
export const termSelector: State => string = (state: State) => state.attribute.config.filterTerm

// gets the corrent devMode
export const devModeSelector: State => boolean = (state: State) => state.attribute.config.devMode

// gets the current platform
export const platformSelector: State => string = (state: State) => state.app.current.platform

// gets the current config
export const configSelector: State => AttributeStateConfigRecord = (state: State) =>
  state.attribute.config

// gets the custom events for the current app
export const eventSelector: State => OrderedMap<string, AttributeRecord> = createSelector(
  customSelector,
  custom => custom.filter(attr => attr.type === 'EVENT')
)
export const visibleEventsSelector: State => List<AttributeRecord> = createSelector(
  eventSelector,
  platformSelector,
  (events, platform) => {
    const baseMobileEvents: Array<AttributeRecord> = [
      AttributeFactory({
        label: 'INSTALLATION',
        id: 'be.install',
        cleanId: 'install',
        lastReceivedType: 'EVENT',
        type: 'EVENT',
        native: true,
      }),
      AttributeFactory({
        label: 'INSTALLED THEN OPTED-IN',
        id: 'be.install_optin',
        cleanId: 'install_optin',
        lastReceivedType: 'EVENT',
        type: 'EVENT',
        native: true,
        allowedKeys: Immutable.OrderedSet.of(
          EventDataAttributeFactory({
            type: 'DATE',
            kind: 'attribute',
            name: 'install_date',
          })
        ),
      }),
    ]

    const baseWebpushEvents: Array<AttributeRecord> = [
      AttributeFactory({
        label: 'SUBSCRIPTION',
        id: 'be.first_subscription_optin',
        cleanId: 'first_subscription_optin',
        lastReceivedType: 'EVENT',
        type: 'EVENT',
        native: true,
      }),
    ]
    const attributeToPrepend =
      platform === '' ? [] : platform === 'webpush' ? baseWebpushEvents : baseMobileEvents
    return events
      .filter(e => !e.hidden)
      .toList()
      .unshift(...attributeToPrepend)
  }
)
export const visibleInstallEventsSelector: State => List<AttributeRecord> = createSelector(
  visibleEventsSelector,
  events => events.filter(e => e.id.substring(0, 3) !== 'ue.').toList()
)
// gets the tags for the current app
export const tagSelector = (state: State): OrderedMap<string, AttributeRecord> =>
  filterCategory(state.attribute.entities, ['tag'])
// gets the tags for the current app
export const userTagSelector = (state: State): OrderedMap<string, AttributeRecord> =>
  filterCategory(state.attribute.entities, ['user_tag'])

// gets the custom attributes for the current app
export const attributeSelector = (state: State): OrderedMap<string, AttributeRecord> => {
  return filterCategory(state.attribute.entities, ['attribute'])
}
// gets the custom user data (ingestion api) for the current app
export const userDataSelector = (state: State): OrderedMap<string, AttributeRecord> =>
  filterCategory(state.attribute.entities, ['user_attribute'])

// gets the number of custom attributes for the current app
export const countAttributesSelector = (state: State): number => state.attribute.entities.size

// END DUPLICATED

// gets all the attribute matching the filters (category & search by name)
export const allAttrFilteredSelector: State => OrderedMap<string, AttributeRecord> = createSelector(
  allAttrSelector,
  termSelector,
  categorySelector,
  devModeSelector,
  currentAppSelector,
  (attrs, term, cat, devMode, app) => {
    let category: Array<
      | 'attribute'
      | 'event'
      | 'tag'
      | 'user_attribute'
      | 'user_tag'
      | 'user_event'
      | 'batch_attribute'
      | 'batch_event'
      | string,
    > = []
    switch (cat) {
      case 'native':
        category = ['batch_attribute', 'batch_event']
        break
      case 'custom':
        category = ['attribute', 'tag', 'event']
        break
      case 'user':
        category = ['user_attribute', 'user_tag', 'user_event']
    }
    return (category.length ? filterCategory(attrs, category) : attrs).filter(a => {
      return (
        (!a.hidden || devMode) &&
        !(a.id === 'be.displayed' && (app.platform === 'windows' || app.platform === 'webpush')) &&
        (a.label.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
          (!!a.name && a.name.toLowerCase().indexOf(term.toLowerCase()) !== -1))
      )
    })
  }
)

// must contains the same filters as the targetingAttributeSelector
export const targetingAttributeSelector: (state: State) => OrderedMap<string, AttributeRecord> =
  createSelector(allAttrSelector, platformSelector, (attributes, platform) => {
    const attributesToExcludes = ['b.region', 'b.language', 'b.browser']
    const categoriesToExcludes = ['user_event']

    if (platform === 'webpush') {
      attributesToExcludes.push('be.displayed')
      categoriesToExcludes.push('event')
    }

    return attributes.filter(attribute => {
      return (
        !attributesToExcludes.includes(attribute.id) &&
        !categoriesToExcludes.includes(attribute.category) &&
        !attribute.hidden
      )
    })
  })

// must contains the same filters as the targetingAttributeSelector
export const targetingAttributeFilteredSelector: (
  state: State
) => OrderedMap<string, AttributeRecord> = createSelector(
  targetingAttributeSelector,
  termSelector,
  categorySelector,
  appCanQueryEvent,
  (attributes, term, cat, eventAllowed) => {
    let category: Array<
      | 'attribute'
      | 'event'
      | 'tag'
      | 'user_attribute'
      | 'user_tag'
      | 'user_event'
      | 'batch_attribute'
      | 'batch_event'
      | string,
    > = []
    switch (cat) {
      case 'native':
        category = ['batch_attribute', 'batch_event']
        break
      case 'custom':
        category = ['attribute', 'tag', 'event']
        break
      case 'user':
        category = ['user_attribute', 'user_tag', 'user_event']
    }

    return (category.length ? filterCategory(attributes, category) : attributes).filter(
      attribute => {
        return (
          isAttributeMatchingTerm(attribute, term) && (!attributeIsEvent(attribute) || eventAllowed)
        )
      }
    )
  }
)

// gets the attributes category, the count of attributes per category and wether or not the user can query them
export const attributesCategoriesSelector: State => List<AttributeCategory> = createSelector(
  categorySelector,
  targetingAttributeSelector,
  appCanQueryNative,
  appCanQueryAttribute,
  appCanQueryUserData,
  appCanQueryEvent,
  (
    activeCategory,
    targetingAttribute,
    nativeAllowed,
    attributeAllowed,
    userDataAllowed,
    eventAllowed
  ) => {
    const countNativeAttribute = filterCategory(targetingAttribute, [
      'batch_attribute',
      'batch_event',
    ]).size

    const countCustomInstallData = filterCategory(targetingAttribute, [
      'attribute',
      'tag',
      ...(eventAllowed ? ['event'] : []),
    ]).size

    const countCustomUserData = filterCategory(targetingAttribute, [
      'user_attribute',
      'user_tag',
      ...(eventAllowed ? ['user_event'] : []),
    ]).size

    return new Immutable.List().push(
      {
        count: countNativeAttribute + countCustomInstallData + countCustomUserData,
        id: null,
        active: activeCategory === null,
        label: 'All',
        locked: !nativeAllowed,
        icon: nativeAllowed ? 'details' : 'lock',
      },
      {
        count: countNativeAttribute,
        id: 'native',
        active: activeCategory === 'native',
        label: 'Native Attributes',
        locked: !nativeAllowed,
        icon: nativeAllowed ? 'mobile' : 'lock',
      },
      {
        count: countCustomInstallData,
        id: 'custom',
        active: activeCategory === 'custom',
        label: 'Custom install data',
        locked: !attributeAllowed,
        icon: attributeAllowed ? 'download' : 'lock',
      },
      {
        count: countCustomUserData,
        id: 'user',
        active: activeCategory === 'user',
        label: 'Custom user data',
        locked: !userDataAllowed,
        icon: userDataAllowed ? 'event' : 'lock',
      }
    )
  }
)

export const userbaseAttrSelector: State => List<AttributeRecord> = createSelector(
  allAttrFilteredSelector,
  appCanQueryEvent,
  (attributes, eventAllowed) => {
    return attributes
      .filter(
        a =>
          a.id !== 'b.last_visit_date' &&
          a.id !== 'b.last_push_date' &&
          a.id !== 'b.is_push_optin' &&
          a.id !== 'bt.custom_audiences' &&
          a.id !== 'b.install_date' &&
          a.id !== 'b.browser_model' &&
          a.id !== 'b.os_model' &&
          a.id !== 'b.device_category' &&
          a.id !== 'b.position' &&
          a.id.substr(0, 3) !== 'be.' &&
          a.id !== 'b.transaction_tracked' &&
          (!attributeIsEvent(a) || eventAllowed)
      )
      .toList()
  }
)

export const userbaseAttrListSelecor: State => List<AttributeRecord> = createSelector(
  userbaseAttrSelector,
  attributes => attributes.toList()
)

const allowedOpacities = [1, 1, 1, 1, 1]
const restrictedOpacities = [1, 0.7, 0.5, 0.3, 0.1]

// recompute the data for an attribute. memoized with lodash
const singleAttrValues = (config: AttributeValuesListRecord, allowed: boolean) => {
  const page = config.page
  const mode = config.mode
  const opacities = allowed ? allowedOpacities : restrictedOpacities

  return config
    .set(
      'shown',
      new Immutable.List().push(
        ...config.values
          .get(mode, new Immutable.List())
          .slice((page - 1) * 5, page * 5)
          .map((v, indice) => v.set('opacity', opacities[indice]))
      )
    )
    .set('restricted', !allowed)
    .set('canDisplayLabels', !config.values.get('__default', new Immutable.List()).isEmpty())
}

// recompute all the data, indexed by attributeId
export const allAttrFilteredValuesSelector: State => Map<string, AttributeValuesListRecord> =
  createSelector(
    allAttrFilteredSelector,
    valueSelector,
    appCanQueryAttribute,
    (attrs, values, allowed) => {
      const keys = attrs.keySeq().toJS() // the attributes we are looking for data

      return values
        .filter((data, key) => keys.indexOf(key) !== -1) // if our data key match a required attr
        .map((config, key) => {
          if (key === 'b.language') {
            // Remove the language tags from the values we display in the userbase page
            return singleAttrValues(
              config.setIn(
                ['values', config.mode],
                config.values
                  .get(config.mode, new Immutable.List())
                  .filter(v => typeof v.value !== 'string' || v.value.indexOf('-') === -1)
              ),
              allowed
            )
          } else return singleAttrValues(config, allowed)
        })
    }
  )

// gets all conditions $FlowFixMe it is fine
const conditionsSelector = (state: State): Map<string, QbConditionRecord> =>
  state.targeting.get('conditions', Immutable.Map())

// extract the attributes ids
export const usedAttributesSelector: State => Set<string> = createSelector(
  conditionsSelector,
  conditions => {
    return conditions.map(c => c.getIn(['attribute', 'id'], '')).toSet()
  }
)
// get the values for all stuff used in the current query (+lvl/mlvl for landing / in-app)
export const usedAttributesValuesSelector: State => Map<string, List<any>> = createSelector(
  usedAttributesSelector,
  valueSelector,
  (ids, values) => {
    return ids
      .add('lvl')
      .add('b.mlvl')
      .toMap()
      .map(attrId => {
        return values
          .getIn([attrId ? attrId : '', 'values', '__default'], new Immutable.List())
          .sort((a, b) => (a.installs < b.installs ? 1 : -1))
      })
  }
)
const allValues = (state: State) => state.attrValue
const mlvlLoading = createSelector(allValues, valuesByAttr =>
  valuesByAttr.getIn(['b.mlvl', 'loading'], true)
)

export const sdkMatchingTargetVersionSelector: State => number => number = createSelector(
  allValues,
  valuesByAttr => {
    const sdkApiLevels = valuesByAttr.getIn(['lvl', 'values', '__default'], new Immutable.List())
    return (sdkTargetApiLevel: number) => {
      if (sdkApiLevels.size === 0) return 1
      const total = sdkApiLevels.reduce((acc, current) => acc + current.installs, 0)
      const installs = sdkApiLevels.reduce(
        (acc, current) =>
          parseInt(current.value) >= sdkTargetApiLevel ? acc + current.installs : acc,
        0
      )
      return installs / total
    }
  }
)

export const requiredMlvlForFeature = {
  'batch.ios_redirect_settings': 5,
  'batch.ios_request_notifications': 4,
  'batch.android_request_notifications': 12,
  'batch.android_redirect_settings': 12,
  'batch.android_smart_reoptin': 12,
  'batch.ios_smart_reoptin': 5,
  'batch.ios_tracking_consent': 8,
  'batch.user.event': 8,
  'batch.user.tag': 8,
  'batch.group': 8,
  'batch.clipboard': 10,
  'batch.rating': 10,
  landing: 1,
  inapp: 2,
  'theme:banner': 4,
  'theme:modal': 6,
  'theme:image': 6,
  'theme:webview': 10,
}

// create a SdkSupportsRecord based on mlvl for inapp, landing & banners
export const sdkSupportsSelector: State => SdkSupportsRecord = createSelector(
  allValues,
  mlvlLoading,
  (valuesByAttr, loading) => {
    const keys = [
      'landing',
      'inapp',
      'banner',
      'gif',
      'html',
      'modal',
      'image',
      'scrolling',
      'webview',
    ]
    let supports: SdkSupportsRecord = SdkSupportsFactory()
    if (loading) {
      // while we are still loading mlvl, consider the feature is supported
      return supports
        .set('landing', supports.landing.set('supported', true))
        .set('inapp', supports.inapp.set('supported', true))
        .set('banner', supports.banner.set('supported', true))
        .set('webview', supports.banner.set('supported', true))
        .set('scrolling', supports.scrolling.set('supported', true))
        .set('gif', supports.gif.set('supported', true))
        .set('html', supports.html.set('supported', true))
        .set('image', supports.image.set('supported', true))
        .set('modal', supports.modal.set('supported', true))
    }
    let total = 0
    valuesByAttr
      .getIn(['b.mlvl', 'values', '__default'], new Immutable.List())
      .forEach(valueRow => {
        const nb = parseInt(valueRow.installs)
        total = total + nb
        if (parseInt(valueRow.get('value')) >= 1) {
          supports = supports.set(
            'landing',
            supports.landing.set('supported', true).set('nb', supports.landing.nb + nb)
          )
        }
        if (parseInt(valueRow.get('value')) >= 2) {
          supports = supports.set(
            'inapp',
            supports.inapp.set('supported', true).set('nb', supports.inapp.nb + nb)
          )
        }
        if (parseInt(valueRow.get('value')) >= 4) {
          supports = supports.set(
            'banner',
            supports.inapp.set('supported', true).set('nb', supports.banner.nb + nb)
          )
        }
        if (parseInt(valueRow.get('value')) >= 6) {
          supports = supports.set(
            'gif',
            supports.gif.set('supported', true).set('nb', supports.gif.nb + nb)
          )
          supports = supports.set('html', supports.gif)
          supports = supports.set('modal', supports.gif)
          supports = supports.set('image', supports.gif)
          supports = supports.set('scrolling', supports.gif)
        }
        if (parseInt(valueRow.get('value')) >= 8) {
          supports = supports.set(
            'webview',
            supports.gif.set('supported', true).set('nb', supports.gif.nb + nb)
          )
        }
      })

    keys.forEach(key => {
      supports = supports.set(
        key,
        supports
          .get(key)
          .set('pcent', total && supports.get(key).nb ? supports.get(key).nb / total : 0)
      )
    })
    return supports
  }
)

export const usedAttributesValuesLoadingSelector: State => Map<string, boolean> = createSelector(
  usedAttributesSelector,
  valueSelector,
  (ids, values) => {
    return ids.toMap().map(attrId => values.get(attrId ?? '', AttributeValuesListFactory()).loading)
  }
)

export const attributeForCustomDataSelector: State => List<AttributeRecord> = createSelector(
  customSelector,
  attributes => {
    return attributes.toList()
  }
)

export const personalisationAttrSelector: State => OrderedMap<string, AttributeRecord> =
  createSelector(allAttrSelector, configSelector, (attributes, config) => {
    let res = attributes.filter(attr => !attr.native)

    if (config.profileDataMode) {
      res = res
        .set(EmailAttribute.id, EmailAttribute)
        .set(CustomIDAttribute.id, CustomIDAttribute)
        .set(CampaignTokenAttribute.id, CampaignTokenAttribute)
        .set(EmailMarketingAttribute.id, EmailMarketingAttribute)
        .set(TimeZoneAttribute.id, TimeZoneAttribute)
        .set(RegionAttribute.id, RegionAttribute)
    }
    return res
  })
