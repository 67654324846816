// @flow

import * as React from 'react'

// we do not import from / to avoid circular dependency
import {
  Button,
  ButtonLink,
  ButtonNavLink,
  type ButtonProps,
} from 'components/common/button/button.styles'
import { Tooltip } from 'components/common/tooltip'

// NB : Change props
export type PermissionButtonProps = {
  ...ButtonProps,
  notAllowedMessage?: string,
  isAllowed: boolean,
  ...
}

export const PermissionButton = ({
  notAllowedMessage,
  isAllowed,
  ...buttonProps
}: {
  ...ButtonProps,
  notAllowedMessage?: string,
  isAllowed: boolean,
  ...
}): React.Node => {
  const { to, href, children } = buttonProps

  if (isAllowed) {
    if (to) return <ButtonNavLink {...buttonProps}>{children}</ButtonNavLink>
    else if (href) return <ButtonLink href={to} {...buttonProps} />
    else return <Button {...buttonProps}> {children} </Button>
  }

  return (
    <Tooltip
      minWidth={200}
      tooltip={notAllowedMessage ?? 'This feature requires higher permissions level'}
    >
      <span>
        <Button {...buttonProps} disabled>
          {children}
        </Button>
      </span>
    </Tooltip>
  )
}
