// @flow

import { type Map } from 'immutable'
import * as React from 'react'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'
import { useAttributesSheet } from 'com.batch/profile/ui/components/events-block/attributes-sheet/attributes-sheet-provider'

type Props = {
  name: string,
  allAttributes: Map<string, QueryValue>,
  event: string,
  ...
}

export const ObjectAttributeRow = ({ name, event, allAttributes }: Props): React.Node => {
  const { open } = useAttributesSheet()

  const handleOnClick = React.useCallback(() => {
    open(allAttributes, event)
  }, [open, allAttributes, event])

  return (
    <AttributeRow
      name={name}
      icon="object"
      isCopyable={false}
      onClick={handleOnClick}
      iconAction="external"
    >
      Object
    </AttributeRow>
  )
}
