// @flow
import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type DailyVolumeProps = {
  date: string,
  sent: number,
  cumulativeSent: number,
}
export const DailyVolumeFactory: RecordFactory<DailyVolumeProps> = Immutable.Record(
  ({
    date: '',
    sent: 0,
    cumulativeSent: 0,
  }: DailyVolumeProps)
)
export type DailyVolumeRecord = RecordOf<DailyVolumeProps>

type VolumeDataProps = {
  total: number,
  daily: Array<DailyVolumeRecord>,
}
export const VolumeDataFactory: RecordFactory<VolumeDataProps> = Immutable.Record(
  ({
    total: 0,
    daily: [],
  }: VolumeDataProps)
)
export type VolumeDataRecord = RecordOf<VolumeDataProps>

type PlatformSentVolumeProps = {
  loadingState: fetchingState,
  email: VolumeDataRecord,
  sms: VolumeDataRecord,
}
export const PlatformSentVolumeFactory: RecordFactory<PlatformSentVolumeProps> = Immutable.Record(
  ({
    loadingState: STATUS.INIT,
    email: VolumeDataFactory(),
    sms: VolumeDataFactory(),
  }: PlatformSentVolumeProps)
)
export type PlatformSentVolumeRecord = RecordOf<PlatformSentVolumeProps>

type ProfileVolumeProps = {
  loadingState: fetchingState,
  active: VolumeDataRecord,
}
export const ProfileVolumeFactory: RecordFactory<ProfileVolumeProps> = Immutable.Record(
  ({
    loadingState: STATUS.INIT,
    active: VolumeDataFactory(),
  }: ProfileVolumeProps)
)
export type ProfileVolumeRecord = RecordOf<ProfileVolumeProps>

type VolumesProps = {
  profile: ProfileVolumeRecord,
  sent: PlatformSentVolumeRecord,
}
export const VolumesFactory: RecordFactory<VolumesProps> = Immutable.Record(
  ({
    profile: ProfileVolumeFactory(),
    sent: PlatformSentVolumeFactory(),
  }: VolumesProps)
)
export type VolumesRecord = RecordOf<VolumesProps>
