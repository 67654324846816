// @flow

import * as React from 'react'

import { Input } from 'components/form'
import { BlurValidation } from 'components/form/blur-validation'

import { requiredString } from 'com.batch.common/validators'

import { HeaderContainer, HeaderContent } from './fake-omni-header.styles'

type FormHeaderOmniProps = {
  value: string,
  onChange: string => void,
}

export const FormHeaderOmni: React.ComponentType<FormHeaderOmniProps> = React.memo(
  ({ value, onChange }: FormHeaderOmniProps) => {
    const handleChange = React.useCallback(
      evt => {
        onChange(evt.target.value)
      },
      [onChange]
    )
    return (
      <HeaderContainer>
        <HeaderContent>
          <BlurValidation validator={requiredString} value={value}>
            {({ value, invalid, onBlur }) => (
              <Input
                value={value}
                autoFocus
                onChange={handleChange}
                onBlur={onBlur}
                invalid={invalid}
                placeholder="Campaign name"
                className="styled-nameInput"
                style={{ borderRadius: 6 }}
              />
            )}
          </BlurValidation>
        </HeaderContent>
      </HeaderContainer>
    )
  }
)

FormHeaderOmni.displayName = 'FormHeaderOmni'
