// @flow

import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { BoxBody, BoxSection } from 'components/common/box'
import { Button } from 'components/common/button'
import { LoadingSectionAnim } from 'components/common/empty-states/'
import { IconContainer } from 'components/common/svg-icon'
import { SPACING } from 'components/styled/const'
import { Empty } from 'components/styled/empty'
import { LabelPlaceholder } from 'components/styled/placeholder'
import { colors, schemes } from 'components/styled/tokens'

type ThemeProps = { isLoading?: boolean, isEmpty?: boolean, ... }
type ThemedAttributeAreaProps = PropsWithTheme<{ ... }, ThemeProps>

export const AttributeArea: StyledComponent<{ ... }, ThemeProps, HTMLElement> = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px 0 8px 8px;
  overflow: scroll;
  background: #2a3139;
  ${(p: ThemedAttributeAreaProps) =>
    p.theme.isLoading
      ? css`
          animation-name: ${LoadingSectionAnim};
          animation-duration: 2s;
          animation-iteration-count: infinite;
        `
      : p.theme.isEmpty &&
        css`
          background: ${schemes.grayscale['30']};
        `}

  @media (max-width: 979px) {
    position: static;
    padding-left: 0;
  }

  &::-webkit-scrollbar {
    width: 14px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    background: #edeef0;
    background: hsla(0, 0%, 85%, 0.22);
    border-radius: 16px;
    border: 4px solid #2a3139;
  }
`

export const SearchAttributeClear: StyledComponent<*, *, HTMLElement> = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  padding: 0 0 1px 0;
  background-color: hsla(0, 0%, 100%, 0.1);
  border: none;
  border-radius: 20px;
  outline: none;

  ${IconContainer} {
    line-height: 8px;
  }
`

export const SearchAttribute: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  padding: 0 14px 0 9px;

  input {
    background: transparent;
    border: transparent;
    outline: none;
    placeholder: #626875;
    color: ${colors.fill};
    flex: 1 0 230px;
    padding: 8px 12px 8px 0;
  }
`
export const Attribute: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  margin-left: 15px;
  padding: 3px 0;
  background: #222930;
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
`
export const AttributeLabel: StyledComponent<*, *, HTMLElement> = styled.em`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  color: #b59d68;
`
export const AttributeValue: StyledComponent<*, *, HTMLElement> = styled.span`
  margin-left: 10px;
  color: #738290;
`
export const InstallSummary: StyledComponent<
  *,
  *,
  HTMLElement,
  // $FlowFixMe
> = styled(BoxSection)`
  flex: 2 1 500px;
  background: #fff;
  border-radius: 0 0 0 5px;
  overflow: hidden;
`

export const DebugResultSummary: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 54px;
  padding: 4px 8px 4px 22px;
  margin: 14px 0 32px 0;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;

  background: linear-gradient(-8deg, #949aa3, #868d97);
  border-radius: 5px;
  box-shadow: 0 1px 4px hsla(0, 0%, 0%, 0.01);

  span {
    flex: 1 0 auto;
    margin: 0 20px 0 0;
    line-height: 18px;
  }
  ${Button} {
    margin: 0 8px 0 0;
    &:last-child {
      margin: 0;
    }
  }

  @media (max-width: 1200px) {
    justify-content: center;
    padding: 8px 10px 8px 22px;
    text-align: center;

    p {
      width: 100%;
      margin: 0 0 8px 0;
    }
  }
`

export const InstallArea: StyledComponent<
  *,
  *,
  HTMLElement,
  // $FlowFixMe
> = styled(BoxBody)`
  display: flex;
  align-items: stretch;

  @media (max-width: 979px) {
    display: block;
  }
`

export const InstallLabel: StyledComponent<
  { stack?: boolean, ... },
  *,
  HTMLElement,
  // $FlowFixMe
> = styled(LabelPlaceholder.withComponent('span'))`
  text-transform: uppercase;
  color: #6c727d;
  ${(props: { stack?: boolean, ... }) =>
    props.stack
      ? css`
          flex-grow: 2;
        `
      : css`
          flex: 0 0 200px;
        `}
  padding-left: 20px;
  display: flex;
  font-size: 11px;
  color: #717782;
  letter-spacing: 0.4px;
  align-items: center;

  ${IconContainer} {
    color: #9a9fa7;
  }
  a {
    font-weight: 500;
    color: ${colors.textAction};
  }
`
export const InstallValue: StyledComponent<
  *,
  *,
  HTMLElement,
  // $FlowFixMe
> = styled(LabelPlaceholder.withComponent('div'))`
  flex-grow: 1;
  padding-right: ${SPACING * 2}px;
  font-size: 13px;
`
export const InstallRowSeparator: StyledComponent<*, *, HTMLElement> = styled.div`
  flex: 0 0 1px;
  height: 60px;
  background: ${schemes.grayscale['20']};
`
export const InstallRow: StyledComponent<
  *,
  *,
  HTMLElement,
  // $FlowFixMe
> = styled(BoxSection)`
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid ${schemes.grayscale['20']};
  border-right: none;
  &:last-child {
    border-bottom: none;
  }
`

export const InstallReachLabel: StyledComponent<
  *,
  *,
  HTMLElement,
  // $FlowFixMe
> = styled(LabelPlaceholder)``
export const InstallReach: StyledComponent<
  *,
  *,
  HTMLElement,
  // $FlowFixMe
> = styled(LabelPlaceholder.withComponent('strong'))``

export const DebugEmptyText: StyledComponent<*, *, HTMLElement> = styled.div``
export const DebugEmptyImage: StyledComponent<*, *, HTMLElement> = styled.div`
  max-height: 100vh;
`
export const DebugEmpty: StyledComponent<
  *,
  *,
  HTMLElement,
  // $FlowFixMe
> = styled(Empty)`
  padding-top: 380px;
  height: 100%;
  max-height: none !important;
  overflow: hidden;
`
export const DebugTriggerHour: StyledComponent<*, *, HTMLElement> = styled.span`
  color: ${colors.textLight};
`
