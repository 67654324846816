// @flow

import * as React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import { BreakpointDetector, type Breakpoint } from 'components/common/breakpoint-detector'

import { Beamer } from './beamer'
import { SidebarDashboard } from './sidebar-dashboard'
import { SidebarSettings } from './sidebar-settings'

import { appsSelector, currentAppSelector } from '../../../redux/app'
import { currentCompanySelector } from '../../../redux/company'
import { openNew } from 'com.batch.redux/app.action'
import { projectMatchingCurrentAppSelector } from 'com.batch.redux/project.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

type SidebarProps = {
  kind: 'dashboard' | 'account',
}

export const Sidebar = ({ kind }: SidebarProps): React.Node => {
  const node = document.getElementById('side_nav')
  const dispatch = useDispatch()
  const apps = useSelector(appsSelector)
  const currentApp = useSelector(currentAppSelector)
  const user = useSelector(currentUserSelector)
  const project = useSelector(projectMatchingCurrentAppSelector)
  const company = useSelector(currentCompanySelector)
  const onOpenNew = React.useCallback((backUrl: string) => dispatch(openNew(backUrl)), [dispatch])
  const onRender = React.useCallback(
    (b: Breakpoint) => {
      const isMobile = b === 'mobile'

      return kind === 'dashboard' ? (
        <React.Fragment>
          <Beamer />
          <SidebarDashboard
            apps={apps}
            currentApp={currentApp}
            company={company}
            user={user}
            project={project}
            openNew={onOpenNew}
            isMobile={isMobile}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Beamer />
          <SidebarSettings user={user} company={company} isMobile={isMobile} project={project} />
        </React.Fragment>
      )
    },
    [apps, company, currentApp, kind, onOpenNew, project, user]
  )

  if (!node) return null
  return ReactDOM.createPortal(<BreakpointDetector render={onRender} />, node)
}
const placeholder = document.getElementById('sidebar__placeholder')
placeholder && placeholder.parentNode && placeholder.parentNode.removeChild(placeholder)
