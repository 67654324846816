// @flow

import { createSelector } from 'reselect'

import { type CompanyRecord, type DispatchBoundFn, type State } from './_records'
import { promiseActionCreator } from './actionCreator'
import * as api from './project.api'
import { type ProjectRecord, type ProjectStateRecord } from './project.records'

import { firstAppOfProjectSelector } from 'com.batch.redux/app'
import { type FetchEspConfigurationActions } from 'com.batch.redux/corelogic/usecases/project/esp-configuration/fetch-esp-configuration'
import { buildInitState } from 'com.batch.redux/project.api'

import { type FetchSspConfigurationActions } from 'com.batch/sms/usecases/fetch-ssp-configuration'

type ProjectActions =
  | updateProjectAction
  | updateProjectSuccessAction
  | updateProjectFailureAction
  | FetchEspConfigurationActions
  | FetchSspConfigurationActions

type extract<T> = State => T

export default function ProjectReducer(
  state: ProjectStateRecord = buildInitState(),
  action: ProjectActions
): ProjectStateRecord {
  switch (action.type) {
    case 'UPDATE_PROJECT':
      return state.setIn(['entities', action.payload.id, 'loadingState'], 'LOADING')
    case 'UPDATE_PROJECT_SUCCESS':
      return state.setIn(['entities', action.payload.id], action.payload)
    case 'UPDATE_PROJECT_FAILURE':
      return state.setIn(['entities', action.payload.project.id, 'loadingState'], 'ERROR')
    case 'FETCH_ESP_CONFIGURATION_SUCCESS': {
      const projectID = state.currentProjectId
      if (projectID) {
        return state.setIn(['entities', projectID, 'espConfiguration'], action.payload)
      }
      return state
    }
    case 'FETCH_SSP_CONFIGURATION_SUCCESS': {
      const projectID = state.currentProjectId
      if (projectID) {
        return state.setIn(['entities', projectID, 'sspConfiguration'], action.payload)
      }
      return state
    }
    default:
      return state
  }
}

export type updateProjectAction = {
  type: 'UPDATE_PROJECT',
  payload: ProjectRecord,
  ...
}
export type updateProjectSuccessAction = {
  type: 'UPDATE_PROJECT_SUCCESS',
  payload: ProjectRecord,
  ...
}
export type updateProjectFailureAction = {
  type: 'UPDATE_PROJECT_FAILURE',
  payload: { project: ProjectRecord, errors: Array<{ message: string, ... }>, ... },
  ...
}

export const updateProject = ({
  company,
  project,
}: {
  company: CompanyRecord,
  project: ProjectRecord,
  ...
}): DispatchBoundFn<Promise<any>> => {
  return dispatch =>
    promiseActionCreator({
      actionName: 'UPDATE_PROJECT',
      dispatch,
      promise: api.updateNameProject(company, project),
      payload: project,
    })
}

export const projectCanQueryEvents: extract<boolean> = createSelector(
  firstAppOfProjectSelector,
  app => {
    if (!app) return true
    return app.features.includes('query-events')
  }
)
