// @flow

import * as React from 'react'
import styled from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

type ReviewFieldProps = {
  children?: React.Node,
  placeholder?: string,
  isFooter?: boolean,
  onPlaceholderClick?: () => any,
  style?: any,
}

const ReviewValue = styled.span`
  display: inline-flex;
  height: 19px;
  padding: 0 4px;
  color: ${(p: { hasValue?: boolean, ... }) =>
    p.hasValue ? colors.textTech : colors.textDisabled};
  line-height: 1.4;
  white-space: nowrap;
  background-color: ${(p: { hasValue?: boolean, ... }) =>
    p.hasValue ? colors.opacifyTech : schemes.darklucent['05']};
  border-radius: 4px;

  .EventFormatter--Filters {
    margin-right: -4px;
    border-radius: 0 4px 4px 0;

    &:hover {
      color: ${colors.textTech};
      background-color: ${colors.opacifyTech};
    }
  }
`

const ReviewField = ({
  children,
  placeholder,
  isFooter,
  onPlaceholderClick,
  style,
}: ReviewFieldProps): React.Node => (
  <ReviewValue
    isFooter={isFooter}
    hasValue={Boolean(children)}
    onClick={children ? onPlaceholderClick : null}
    style={style}
  >
    {children ? children : placeholder}
  </ReviewValue>
)

export default ReviewField
