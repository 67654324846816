// @flow

import styled, { type StyledComponent } from 'styled-components'

export const IFramePreviewSwitchContainer: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  flex-grow: 20;
  background: #fdfdfd;
  position: relative;
  height: 100%;
`

export const IFrameContainer: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
