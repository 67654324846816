// @flow

import * as React from 'react'

import { ButtonLink, ButtonNavLink } from 'components/common/button'
import { type availableIcons, Icon } from 'components/common/svg-icon'

export const HardLinkOrNavLink = ({
  hardReload,
  url,
  isActive,
  children,
  icon,
  ...rest
}: {
  hardReload: boolean,
  url: string,
  isActive?: boolean,
  children: React.Node,
  icon?: availableIcons,
  className?: string,
  ...
}): React.Node => {
  const className = rest.className
    ? `${rest.className} ${isActive ? 'active' : ''}`
    : `${isActive ? 'active' : ''}`
  return hardReload ? (
    <ButtonLink
      {...rest}
      style={{ marginRight: 12 }}
      isActive={isActive}
      href={url}
      className={className}
    >
      {icon && <Icon icon={icon} />}
      <span className="styled-button-text">{children}</span>
    </ButtonLink>
  ) : (
    <ButtonNavLink
      {...rest}
      style={{ marginRight: 12 }}
      isActive={isActive}
      to={url}
      className={className}
    >
      {icon && <Icon icon={icon} />}
      <span className="styled-button-text">{children}</span>
    </ButtonNavLink>
  )
}
