// @flow

import { type ServiceAccountKeyRaw } from 'com.batch/settings/infra/types/fcm-config-raw'

export const validateFcmConfig = (raw: ServiceAccountKeyRaw): ?string => {
  const urlRegex = /^(https?:\/\/)?(www\.)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (raw.type !== 'service_account') return 'Invalid type'
  if (!emailRegex.test(raw.client_email)) return 'Invalid client_email'
  if (!urlRegex.test(raw.auth_uri)) return 'Invalid auth_uri'
  if (!urlRegex.test(raw.token_uri)) return 'Invalid token_uri'
  if (!urlRegex.test(raw.auth_provider_x509_cert_url)) return 'Invalid auth_provider_x509_cert_url'
  if (!urlRegex.test(raw.client_x509_cert_url)) return 'Invalid client_x509_cert_url'
  return null
}
