// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { TableCell, TableRow } from 'components/common/table'
import { colors } from 'components/styled/tokens'

import { kformat } from 'com.batch.common/utils'

import { StepMessageName } from './steps-analytics.styles'

import { formatRate } from '../format-rate'

import { type OrchestrationStepRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-step.record'

type StepsAnalyticsRowProps = {
  step: OrchestrationStepRecord,
  label: string,
  isDeletedStep: boolean,
}

export const contents = {
  email: {
    icon: 'mail',
    label: 'Email message',
  },
  sms: {
    icon: 'sms',
    label: 'SMS message',
  },
  push: {
    icon: 'push',
    label: 'Push message',
  },
}

const UnavailableMetric = () => <span style={{ color: colors.textDisabled }}>—</span>

export const StepsAnalyticsRow = ({
  step,
  label,
  isDeletedStep,
}: StepsAnalyticsRowProps): React.Node => {
  const { delivered, open, click, bounce, unsubscribe } = step.stats
  const color = React.useMemo(
    () => (isDeletedStep ? colors.textDisabled : colors.textNeutral),
    [isDeletedStep]
  )
  return (
    <TableRow style={{ columnGap: 10, minHeight: 52 }}>
      <TableCell>
        <StepMessageName $isDeleted={isDeletedStep}>
          {step.channel ? (
            <React.Fragment>
              <Icon icon={contents[step.channel].icon} />
              {label || contents[step.channel].label}
            </React.Fragment>
          ) : (
            label || 'Message'
          )}
        </StepMessageName>
      </TableCell>
      <TableCell align="right" style={{ marginRight: 58, color }}>
        {kformat(delivered.value)}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 76, color }}>
        {step.channel === 'email' || step.channel === 'push' ? (
          formatRate(open.rate)
        ) : (
          <UnavailableMetric />
        )}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 78, color }}>
        {step.channel === 'email' ? formatRate(click.rate) : <UnavailableMetric />}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 62, color }}>
        {formatRate(bounce.rate)}
      </TableCell>
      <TableCell align="right" style={{ marginRight: 38, color }}>
        {step.channel === 'email' || step.channel === 'sms' ? (
          formatRate(unsubscribe.rate)
        ) : (
          <UnavailableMetric />
        )}
      </TableCell>
    </TableRow>
  )
}
