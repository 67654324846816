// @flow

import styled, {
  css,
  type StyledComponent,
  type CSSRules,
  type PropsWithTheme,
} from 'styled-components'

export const Phrase: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: inline;
  margin: 0 0 0 8px;
`

const PhrasePart: StyledComponent<*, *, HTMLSpanElement> = styled.span`
  display: inline-block;
  margin: 0 6px 0 0;
  font-size: 13px;
  line-height: 28px;
  color: #7f8691;

  &:empty {
    display: none;
  }
`

export const ValueStyles: CSSRules = css`
  display: inline-block;
  position: relative;
  margin: 0 11px 0 4px;
  line-height: 28px;
  color: #12181e;

  &:before {
    position: absolute;
    top: 4px;
    right: -3px;
    height: 20px;
    left: -4px;
    content: '';
    background-color: hsla(210, 25%, 9%, 0.04);
    border-radius: 2px;
  }

  ${(props: PropsWithTheme<{ comma: boolean, ... }, *>) =>
    props.comma
      ? css`
          &:after {
            position: absolute;
            right: -9px;
            bottom: 0;
            content: ',';
            font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Helvetica,
              Arial, serif;
            color: #a9aeb5;
          }
        `
      : ''}
`

export const Value: StyledComponent<*, *, HTMLSpanElement> = styled.span`
  ${ValueStyles}
`

export const ValueLink: StyledComponent<*, *, HTMLAnchorElement> = styled.a`
  &,
  &:visited {
    ${ValueStyles};
    text-decoration: none;
  }

  &:hover {
    color: #000000;
    text-decoration: underline;
  }
`

export const EventLabel: StyledComponent<*, *, *> = styled(PhrasePart)`
  position: relative;
  z-index: 10;
  margin: 0 12px 0 4px;
  font-size: 12px;
  font-family: 'Fira Code', 'Menlo', 'Segoe UI mono', monospace;
  line-height: 28px;
  color: #8893af;
  text-transform: lowercase;
  letter-spacing: -0.2px;

  &:before {
    position: absolute;
    z-index: -1;
    top: 3px;
    right: -4px;
    height: 21px;
    left: -4px;
    content: '';
    border: 1px solid #e4e6ed;
    background-color: #fafafb;
    border-radius: 3px;
  }
`

export const Operator: StyledComponent<*, *, *> = styled(PhrasePart)`
  color: #7f8691;
`
export const Verb: StyledComponent<*, *, *> = styled(PhrasePart)`
  color: #757c88;
`
export const Func: StyledComponent<*, *, *> = styled(PhrasePart)`
  color: #757c88;
`
