// @flow
import * as React from 'react'

import { ScrollTracker } from 'components/common/scroll'
import { Content } from 'components/styled/blocs'

import { ABTesting } from './data/abtesting'
import { Delivery } from './data/delivery'
import Performance from './data/performance'
import { RecurringData } from './data/recurring'
import Summary from './data/summary'
import {
  abTestingSelector,
  deliverySelector,
  journeySelector,
  performanceSelector,
  summarySelector,
} from './review.data.selectors'

import { type AppRecord, type CampaignRecord } from 'com.batch.redux/_records'
import {
  type CampaignDataRecord,
  InAppWithVariantFactory,
} from 'com.batch.redux/dataCampaign.records'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'

type ReviewScreenDataProps = {
  campaign: CampaignRecord,
  app: AppRecord,
  variantsThemes: AbTestedThemeRecord,
  data: ?CampaignDataRecord,
  hasDevApiKeyFeature: boolean,
  onScrollEnter: (step: string) => void,
}
const ANCHOR_OFFSET = -180
const ReviewScreenData = ({
  data,
  campaign,
  onScrollEnter,
  variantsThemes,
  app,

  hasDevApiKeyFeature,
}: ReviewScreenDataProps): React.Node => {
  const createOnScrollEnter = React.useCallback(
    (step: string) => () => onScrollEnter(step),
    [onScrollEnter]
  )

  React.useEffect(() => window.scrollTo(0, 0), [])
  return (
    <Content>
      <ScrollTracker
        id="summary"
        anchorOffset={ANCHOR_OFFSET}
        onScrollEnter={createOnScrollEnter('summary')}
      >
        <Summary data={summarySelector(data, campaign, app, variantsThemes)} campaign={campaign} />
      </ScrollTracker>
      <ScrollTracker
        id="performance"
        anchorOffset={ANCHOR_OFFSET}
        onScrollEnter={createOnScrollEnter('performance')}
      >
        <Performance
          data={performanceSelector(data, app, campaign, variantsThemes)}
          loading={!data || data.loading}
        />
      </ScrollTracker>
      {campaign.abtesting.enabled && (
        <ScrollTracker
          id="ab-testing"
          anchorOffset={ANCHOR_OFFSET}
          onScrollEnter={createOnScrollEnter('ab')}
        >
          <ABTesting
            data={abTestingSelector(data)}
            inAppData={data?.inapp ?? InAppWithVariantFactory()}
            variantsThemes={variantsThemes}
            campaign={campaign}
            app={app}
          />
        </ScrollTracker>
      )}
      {campaign.type.toLowerCase() === 'push' && (
        <ScrollTracker
          id="delivery"
          anchorOffset={ANCHOR_OFFSET}
          onScrollEnter={createOnScrollEnter('delivery')}
        >
          <Delivery
            data={deliverySelector(data, campaign, app)}
            campaign={campaign}
            platform={app.platform}
            hasDevApiKeyFeature={hasDevApiKeyFeature}
          />
        </ScrollTracker>
      )}
      {campaign.sendType === 'trigger' && (
        <ScrollTracker
          id="journey"
          anchorOffset={ANCHOR_OFFSET}
          onScrollEnter={createOnScrollEnter('journey')}
        >
          <Performance journey data={journeySelector(data)} loading={!data || data.loading} />
        </ScrollTracker>
      )}
      {(campaign.sendType === 'recurring' ||
        campaign.sendType === 'trigger' ||
        campaign.type.toLowerCase() !== 'push') && (
        <ScrollTracker
          id="history"
          anchorOffset={ANCHOR_OFFSET}
          onScrollEnter={createOnScrollEnter('history')}
        >
          <RecurringData app={app} campaign={campaign} />
        </ScrollTracker>
      )}
    </Content>
  )
}

export default ReviewScreenData
