// @flow
import styled, { css, type StyledComponent, type PropsWithTheme } from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import { builtEmptyStateAnimation } from 'components/common/empty-states/empty-states.styles'
import * as colors from 'components/styled/tokens/colors'

const BORDER_RADIUS = '8px'
const SPACING = 8
type TableCellProps = {
  align?: 'right' | 'left' | 'center',
  kind?: 'checkbox' | 'toggle' | 'input',
  isMultiline?: boolean,
  noOverflow?: boolean,
  sortable?: boolean,
  ...
}
export const TableCell: StyledComponent<TableCellProps, *, HTMLElement> = styled.div`
  justify-self: ${(props: TableCellProps) => props.align};
  display: flex;
  color: ${colors.text};
  align-items: center;
  overflow-x: ${(props: TableCellProps) => (props.noOverflow ? 'hidden' : 'visible')};
  text-overflow: ellipsis;

  ${(props: TableCellProps) =>
    props.isMultiline &&
    css`
      align-items: flex-start;
      flex-direction: column;
      padding-top: 8px;
      padding-bottom: 8px;
    `}

  ${(props: TableCellProps) =>
    props.kind === 'input' &&
    css`
      width: 99%;
      & > div {
        width: inherit;
      }
    `}
 
  ${(props: TableCellProps) =>
    props.kind === 'checkbox' &&
    css`
      margin-left: 0 !important;
      justify-content: center;
    `}

  ${(props: TableCellProps) =>
    props.kind === 'toggle' &&
    css`
      label {
        margin-right: 8px;
      }
    `}

  ${(props: TableCellProps) =>
    props.align === 'right'
      ? css`
          margin-right: ${({ sortable }: TableCellProps) => (sortable ? '34px' : '12px')};
        `
      : css`
          margin-left: 12px;
        `}
`
type TableTemplateCellWrapperProps = { isMultiline?: boolean, ... }
export const TableTemplateCellWrapper: StyledComponent<TableTemplateCellWrapperProps, *, *> =
  styled.div`
    ${(props: TableTemplateCellWrapperProps) =>
      props.isMultiline &&
      css`
        padding-top: 10px;
        padding-bottom: 6px;
        ${TableEmptyContent} {
          display: inline-block;
          width: 100%;
        }
      `}
  `
type TableEmptyContentProps = {
  align?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end',
  ...
}

type TableEmptyContentWithThemeProps = PropsWithTheme<TableEmptyContentProps, ThemeProps>
export const TableEmptyContent: StyledComponent<TableEmptyContentProps, ThemeProps, *> = styled.div`
  display: flex;
  justify-content: ${(props: TableEmptyContentProps) => props.align ?? 'left'};
  min-height: ${(props: TableEmptyContentWithThemeProps) => `${props.theme?.rowHeight ?? '48'}px`};
  align-items: center;
  ${(props: TableEmptyContentProps) =>
    props.align === 'end' || props.align === 'flex-end'
      ? css`
          margin-right: 12px;
        `
      : css`
          margin-left: 12px;
        `}
`
type TableRowProps = { isEditing?: boolean, ... }
type ThemeProps = { template: string, rowHeight?: string, ... }
type ThemedTableRowProps = PropsWithTheme<TableRowProps, ThemeProps>
export const TableRow: StyledComponent<TableRowProps, ThemeProps, HTMLElement> = styled.div`
  display: grid;
  grid-template-columns: ${(props: ThemedTableRowProps) => props.theme?.template ?? 'auto'};
  grid-column-gap: ${SPACING}px;
  align-items: center;
  min-height: ${(props: ThemedTableRowProps) => `${props.theme?.rowHeight ?? '48'}px`};
  position: relative;

  ${(props: ThemedTableRowProps) =>
    props.isEditing &&
    css`
      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${colors.strokeAction};
      }
    `}

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: -1px;
    background-color: ${colors.stroke};
    left: 12px;
    right: 12px;
    z-index: 1;
  }

  ${TableCell}:first-child, ${TableTemplateCellWrapper}:first-child {
    margin-left: ${SPACING * 2.5}px;
  }
  ${TableCell}:last-child, ${TableTemplateCellWrapper}:last-child {
    margin-right: ${SPACING * 2.5}px;
  }
  ${TableTemplateCellWrapper} ${TableEmptyContent}:not(:last-child) {
    margin-bottom: 3px;
  }
`
type TableContainerProps = { striped?: boolean, ... }
export const TableContainer: StyledComponent<TableContainerProps, *, HTMLElement> = styled.div`
  position: relative;
  margin-bottom: ${SPACING * 5}px;
  border-radius: ${BORDER_RADIUS};
  background-color: ${colors.fill};
  border: 1px solid ${colors.stroke};
  box-shadow:
    0px 1px 2px rgba(68, 70, 73, 0.03),
    0px 2px 4px rgba(68, 70, 73, 0.03),
    0px 4px 8px rgba(68, 70, 73, 0.03),
    0px 8px 16px rgba(68, 70, 73, 0.03);

  ${(props: TableContainerProps) =>
    props.striped &&
    css`
      > div ${TableRow}:nth-of-type(even) {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.01) calc(0% + 26px),
          rgba(0, 0, 0, 0.01) calc(100% - 26px),
          rgba(0, 0, 0, 0) 100%
        );
      }
    `}

  ${TableRow}:last-child {
    border-bottom: none;

    &:after {
      background-color: transparent;
    }
  }
`
type TableOrderContainerProps = {
  align?: 'right' | 'left',
  isActive: boolean,
  style?: { ... },
  ...
}
export const TableOrderContainer: StyledComponent<TableOrderContainerProps, *, HTMLElement> =
  styled.div`
    justify-self: ${(props: TableOrderContainerProps) => props.align};

    ${Button} {
      &:focus {
        background-color: transparent;
      }

      &:hover {
        background-color: ${colors.opacifyHover};
      }
      svg {
        opacity: 0.6;
      }
      ${(props: TableOrderContainerProps) =>
        props.isActive &&
        css`
          color: ${colors.text};

          svg {
            opacity: 1;
            transition: all ease-in 0.3s;
            fill: ${colors.text};
            stroke: ${colors.text};
          }
        `}
    }
  `
type TableCellHeaderProps = {
  align?: 'right' | 'left' | 'center',
  verticalAlign?: 'center' | 'top' | 'bottom',
  ...
}
export const TableCellHeader: StyledComponent<TableCellHeaderProps, *, HTMLElement> = styled.div`
  justify-self: ${(props: TableCellHeaderProps) => props.align};
  font-weight: 500;
  font-size: 1em;
  color: ${colors.text};
  color: #4f4f4f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  display: flex;
  ${(props: TableCellHeaderProps) =>
    props.align === 'right'
      ? css`
          margin-right: 12px;
        `
      : props.align !== 'center' &&
        css`
          margin-left: 12px;
        `};
  ${({ verticalAlign }: TableCellHeaderProps) =>
    verticalAlign === 'center'
      ? css`
          align-items: center;
        `
      : verticalAlign === 'top'
        ? css`
            align-items: flex-start;
          `
        : verticalAlign === 'bottom' &&
          css`
            align-items: flex-end;
          `};
`
type TableHeaderProps = { rowHeight?: string, ... }
type ThemedTableHeaderProps = PropsWithTheme<TableHeaderProps, ThemeProps>
export const TableHeader: StyledComponent<TableHeaderProps, ThemeProps, HTMLElement> = styled.div`
  border-bottom: 1px solid ${colors.stroke};
  min-height: ${(props: ThemedTableHeaderProps) => `${props.theme?.rowHeight ?? '48'}px`};
  display: grid;
  grid-template-columns: ${(props: ThemedTableHeaderProps) => props.theme.template};
  grid-column-gap: ${SPACING}px;
  align-items: center;
  font-weight: 500;

  ${TableOrderContainer}:first-child {
    margin-left: ${SPACING}px;
  }

  ${TableCellHeader}:first-child {
    margin-left: ${SPACING * 2.5}px;
  }
  ${TableOrderContainer}:last-child {
    margin-right: ${SPACING}px;
  }
`

export const TableCellActions: StyledComponent<*, { ... }, HTMLElement> = styled.div`
  display: flex;
  justify-content: flex-end;

  ${Button} {
    height: 35px;
    width: 36px;

    &:nth-child(2n) {
      margin-left: ${SPACING / 2}px;
    }

    &:last-child {
      margin-right: 12px;
    }
  }
`

type TableFooterProps = { rowHeight?: string }
type ThemedTableFooterProps = PropsWithTheme<TableFooterProps, ThemeProps>
export const TableFooter: StyledComponent<*, *, HTMLElement> = styled.div`
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: ${colors.fill};
  display: flex;
  min-height: ${(props: ThemedTableFooterProps) => `${props.theme?.rowHeight ?? '48'}px`};
  align-items: center;
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  border-top: 1px solid ${colors.stroke};
`

export const FooterEditable: StyledComponent<*, *, HTMLElement> = styled.div`
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: rgb(252, 252, 252);
  border-top: 1px solid rgb(227, 227, 227);
  padding: 0 11px;

  & > * {
    padding: 0;
  }

  & > *:last-child {
    padding-right: 0;
  }
  & > *:first-child {
    padding-left: 0;
  }
`
type EmptyCellProps = { $width?: string, $marginRight?: number, $theme?: 'loading' | 'empty', ... }
export const EmptyCell: StyledComponent<EmptyCellProps, *, HTMLElement> = styled.div`
  height: 14px;
  min-width: 14px;
  width: ${(props: EmptyCellProps) => props.$width ?? '100%'};
  border-radius: 2px;
  margin-right: ${(props: EmptyCellProps) => props.$marginRight ?? 0}px;

  ${(p: EmptyCellProps) =>
    p.$theme === 'loading'
      ? builtEmptyStateAnimation()
      : css`
          background-color: ${colors.textDisabled};
        `}
`
