// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { useToggle } from 'components/_hooks'

import { SmsLayerBarSendTest } from './sms-layer-bar-send-test'

import { previewLanguageSelector } from 'com.batch/message/store/message.selector'

import { LayerBarWrapper } from 'com.batch/message-builder/ui/components/layerbar.styles'
import { PreviewProfilePopin } from 'com.batch/shared/ui/component/popins/preview-profile-popin'
import { PreviewAs } from 'com.batch/shared/ui/component/preview-as'
import { PopinSendTestSms } from 'com.batch/sms/ui/components/popin-send-test-sms'
import { updateSmsTemplate } from 'com.batch/sms/usecases/update-sms-template'

type SmsLayerBarProps = { messageId: string, ... }
export const SmsLayerBar = ({ messageId }: SmsLayerBarProps): React.Node => {
  const dispatch = useDispatch()
  const popinSmsSendTestState = useToggle()
  const previewProfilePopinState = useToggle()
  const lang = useSelector(previewLanguageSelector)

  const handleSendTestClick = React.useCallback(() => {
    popinSmsSendTestState.open()
  }, [popinSmsSendTestState])

  const handlePreviewAsClick = React.useCallback(() => {
    previewProfilePopinState.open()
  }, [previewProfilePopinState])

  const updateTemplate = React.useCallback(() => {
    dispatch(
      updateSmsTemplate({
        lang,
        messageId,
      })
    )
  }, [dispatch, lang, messageId])

  return (
    <React.Fragment>
      <ThemeProvider theme={{ disabledMode: false }}>
        <PopinSendTestSms messageId={messageId} togglePopin={popinSmsSendTestState} />
        <PreviewProfilePopin
          togglePopin={previewProfilePopinState}
          updateTemplate={updateTemplate}
        />
      </ThemeProvider>
      <LayerBarWrapper $itemsCount={2}>
        <SmsLayerBarSendTest handleSendTestClick={handleSendTestClick} messageId={messageId} />
        <PreviewAs handlePreviewAsClick={handlePreviewAsClick} updateTemplate={updateTemplate} />
      </LayerBarWrapper>
    </React.Fragment>
  )
}
