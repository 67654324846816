// @flow

import * as React from 'react'

import Highlight from 'components/common/highlight'
import { Tabs, Tab } from 'components/common/tabbed'

type SetupWinProps = {
  apiKey: string,
  devApiKey: string,
  hasDevApiKeyFeature: boolean,
  ...
}
const SetupWin = ({ apiKey, devApiKey, hasDevApiKeyFeature }: SetupWinProps): React.Node => {
  return (
    <div>
      <h3>Install the SDK</h3>
      <p>
        The Windows SDK is a WinRT component compatible with Windows 8.1, Windows Phone 8.1 and the
        UWP (Windows 10).
      </p>
      <p>
        Since it is distributed using NuGet, you can install it from Visual Studio: right click on
        your project and select "Manage NuGet Packages".
      </p>
      <p>Then, search for "BatchSDK" and install it.</p>
      <p>You can also use the NuGet CLI:</p>
      <div className="text-left" style={{ marginTop: '10px' }}>
        <pre className="clean">
          <code className="hljs java">PM> Install-Package BatchSDK</code>
        </pre>
      </div>
      <h3>Integrating Batch in your Application class</h3>
      <Tabs id="setup-win" bsStyle="pills">
        <Tab eventKey={0} title="Windows 8.1">
          <Highlight language="cs">
            {`using BatchSDK;

namespace MyApp
{
    sealed partial class App : Application
    {
      public App()
        {
            ...

            // Enable dev logs
            Batch.Core.SetDebugWriter((s) => { System.Diagnostics.Debug.WriteLine(s); });
            Batch.Core.EnableDebugLogs = true;

            ${
              hasDevApiKeyFeature
                ? `// Start Batch with the DEV API Key
            Batch.Core.Start("${devApiKey}");
            // TODO : switch to live api key before store release
            // Batch.Core.Start("${apiKey}");`
                : `// Start Batch
            Batch.Core.Start("${apiKey}");`
            }
            
        }

        protected override void OnLaunched(LaunchActivatedEventArgs e)
        {
            Batch.Core.HandleApplicationStart(e.Arguments);
            ...
        }
    }
}`}
          </Highlight>
        </Tab>
        <Tab eventKey={1} title="UWP (Windows 10)">
          <Highlight language="cs">{`using BatchSDK;

namespace MyApp
{
    sealed partial class App : Application
    {
      public App()
        {
            ...

            // Enable dev logs
            Batch.Core.SetDebugWriter((s) => { System.Diagnostics.Debug.WriteLine(s); });
            Batch.Core.EnableDebugLogs = true;

            ${
              hasDevApiKeyFeature
                ? `// Start Batch with the DEV API Key
            Batch.Core.Start("${devApiKey}");
            // TODO : switch to live api key before store release
            // Batch.Core.Start("${apiKey}");`
                : `// Start Batch 
            Batch.Core.Start("${apiKey}");`
            }
           
        }

        protected override void OnLaunched(LaunchActivatedEventArgs e)
        {
            Batch.Core.HandleApplicationStart(e.Arguments);
            ...
        }

        protected override void OnActivated(IActivatedEventArgs args)
        {
            Batch.Core.HandleApplicationStart((args as ToastNotificationActivatedEventArgs)?.Argument);
            ...
        }
    }
}`}</Highlight>
        </Tab>
      </Tabs>
      If you're targeting Windows 8.1 (or Windows Phone 8.1), don't forget to enable Toast
      capabilities in your Application's Manifest.
      <img
        alt="Visual Studio Manifest Editor"
        src="//batch-doc.s3.amazonaws.com/Windows/VSManifestToast8.1.png"
      />
    </div>
  )
}

export default SetupWin
