// @flow

import { type Map } from 'immutable'

import { type ReduxAction } from 'com.batch.redux/_records'

import {
  type EmailMessageRecord,
  type SmsMessageRecord,
  type PushMessageRecord,
} from 'com.batch/message/models/message.records'

export type UpdateAllContentAction = ReduxAction<
  'UPDATE_ALL_CONTENT',
  {
    email: Map<string, EmailMessageRecord>,
    sms: Map<string, SmsMessageRecord>,
    push: Map<string, PushMessageRecord>,
  },
>
export const updateAllContent = ({
  email,
  sms,
  push,
}: {
  +email: Map<string, EmailMessageRecord>,
  +sms: Map<string, SmsMessageRecord>,
  +push: Map<string, PushMessageRecord>,
  ...
}): UpdateAllContentAction => {
  return {
    type: 'UPDATE_ALL_CONTENT',
    payload: { email, sms, push },
  }
}
