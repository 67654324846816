/* eslint-disable react/jsx-no-bind */
// @flow

import { List } from 'immutable'
import * as React from 'react'

import { filters } from 'components/campaign/campaign-list-utils'
import { Filter } from 'components/campaign/filters/filter'
import { FilterSelectMulti } from 'components/filter'
import { Checkbox } from 'components/form'

import {
  type CampaignActiveFiltersRecord,
  type CampaignFilterRecord,
} from 'com.batch.redux/_records'

type ChannelFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord,
  onChange: any => void,
  isEmbedded: boolean,
  ...
}

export const ChannelFilter = ({
  localActiveFilters,
  onChange,
  isEmbedded,
}: ChannelFilterProps): React.Node => {
  const channels = React.useMemo(() => {
    return filters.filter(f => f.category === 'channels')
  }, [])
  const selectedChannels = React.useMemo(() => {
    let selected: List<CampaignFilterRecord> = new List()
    channels.map(st => {
      if (localActiveFilters.commons.has(st)) {
        selected = selected.push(st)
      }
    })
    return selected
  }, [localActiveFilters, channels])

  return (
    <Filter title="Channels" isEmbedded={isEmbedded}>
      {isEmbedded ? (
        channels.map(channel => (
          <Checkbox
            key={channel.name}
            name={channel.name}
            label={channel.label}
            checked={localActiveFilters.commons.has(channel)}
            onChange={() => onChange(channel)}
            className={`channel_${channel.name}`}
          />
        ))
      ) : (
        <FilterSelectMulti
          options={channels.toList()}
          value={selectedChannels}
          onChange={onChange}
          isSearchable={false}
          placeholder="Any channel"
          optionToString={opt => opt?.label ?? ''}
          term={count => (count === 1 ? selectedChannels.first().label : `${count} channels`)}
        />
      )}
    </Filter>
  )
}
