// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { ReplicateConnected } from 'components/campaign/review/replicate-connected'
import { BoxFooter, FooterBoxActions } from 'components/common/box'
import { Button, ButtonNavLink, useBlurOnClickCallback } from 'components/common/button'
import { Tooltip } from 'components/common/tooltip'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { Icon } from '../common/svg-icon'
import { currentAppSelector } from 'com.batch.redux/app'
import { archive } from 'com.batch.redux/campaign.action'
import { currentCampaign } from 'com.batch.redux/campaign.selector'

import { FCM_ALERT_DATE } from 'com.batch/settings/constants/fcm-dates'

type CampaignFormActionsProps = {
  archive: typeof archive,
  save: () => any,
  saveAsDraft: () => any,
  saveAndKeep: () => any,
  dataUrl: string,
  canArchive: boolean,
  canDraft: boolean,
  canViewData: boolean,
  canUpdate: boolean,
  invalid: boolean,
  loading: boolean,
  isArchived: boolean,
  campaignRequireTheme: boolean,
  hasTheme: boolean,
  ...
}

export const CampaignFormActions = (props: CampaignFormActionsProps): React.Node => {
  const {
    archive,
    save,
    saveAsDraft,
    loading,
    dataUrl,
    saveAndKeep,
    canArchive,
    canViewData,
    canUpdate,
    canDraft,
    isArchived,
    campaignRequireTheme,
    hasTheme,
  } = props
  const [replicateIsOpen, updateReplicateIsOpen] = React.useState(false)
  // USe selector
  const campaign = useSelector(currentCampaign)
  const { platform, pushConfig } = useSelector(currentAppSelector)

  const replicateOnBlur = useBlurOnClickCallback(() => updateReplicateIsOpen(true), [])
  const saveAsDraftOnblur = useBlurOnClickCallback(() => saveAsDraft(), [saveAsDraft])
  const saveAndRuntOnblur = useBlurOnClickCallback(() => save(), [save])

  const hasLegacyConfig = React.useMemo(() => {
    if (campaign.type !== 'in-app' && platform === 'android') {
      const now = dayjs.utc()
      const fcmDeadline = dayjs.utc(FCM_ALERT_DATE, 'YYYY-MM-DD')
      if (now.isSameOrAfter(fcmDeadline)) {
        return !pushConfig.fcm || pushConfig.fcm.size === 0
      }
      return false
    }
  }, [campaign.type, platform, pushConfig.fcm])

  const handleOnClickArchive = React.useCallback(() => {
    archive(campaign)
  }, [archive, campaign])

  const handleOnClickDuplicate = React.useCallback(() => {
    updateReplicateIsOpen(false)
  }, [])

  const shouldDisableReplicate = React.useMemo(() => {
    if (campaign.type === 'in-app') {
      return !hasTheme
    }
    if (campaign.type === 'push') {
      return campaignRequireTheme ? !hasTheme : false
    }

    return false
  }, [campaign.type, campaignRequireTheme, hasTheme])

  return (
    <BoxFooter isEditable={canArchive}>
      {canArchive && (
        <Button intent="danger" kind="inline" onClick={handleOnClickArchive} disabled={loading}>
          Delete
        </Button>
      )}
      <FooterBoxActions>
        {canArchive && (
          <React.Fragment>
            <Tooltip
              tooltip="You cannot replicate this campaign because its theme has been deleted"
              placement="left"
              isTooltipEmpty={!shouldDisableReplicate}
            >
              <div>
                <Button
                  kind="primary"
                  intent="neutral"
                  disabled={loading || shouldDisableReplicate}
                  onClick={replicateOnBlur}
                >
                  Replicate
                </Button>
              </div>
            </Tooltip>

            <ReplicateConnected opened={replicateIsOpen} close={handleOnClickDuplicate} />
          </React.Fragment>
        )}
        {canViewData && (
          <ButtonNavLink kind="inline" intent="neutral" to={dataUrl}>
            Analytics
          </ButtonNavLink>
        )}
        {canDraft && !hasLegacyConfig && (
          <Button
            kind="primary"
            intent="neutral"
            disabled={loading || isArchived}
            onClick={saveAsDraftOnblur}
          >
            Save as draft
          </Button>
        )}
        {canDraft && !hasLegacyConfig && (
          <Button
            kind="primary"
            addOn="suffix"
            intent="action"
            disabled={loading || isArchived}
            onClick={saveAndRuntOnblur}
          >
            Save &amp; run
            <Icon icon="send" />
          </Button>
        )}

        {canUpdate && !hasLegacyConfig && (
          <Button kind="primary" disabled={loading} onClick={saveAndKeep} intent="action">
            Update
          </Button>
        )}
      </FooterBoxActions>
    </BoxFooter>
  )
}
