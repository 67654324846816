// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { Grid } from 'components/common/grid'
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes } from 'components/styled/tokens'

const CertifColors = {
  success: '#5cb85c',
  error: '#a94442',
  warning: '#8a6d3b',
}

export const TextMuted: StyledComponent<*, *, *> = styled.p`
  font-size: 1em;
  color: #cccfda;
`

export const HeaderSettingsList: StyledComponent<*, *, *> = styled.div`
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  color: #c6c8cd;
  font-weight: 600;
  padding: 8px 0;
`

const SPACING = 64
const SPACING_MARGIN = SPACING / 2
type csUplProps = { isActive: boolean, isRejected: boolean, isAccepted: boolean, ... }
export const CertifSettingsUpload: StyledComponent<csUplProps, *, *> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 5px;

  background: url('/medias/img/cloud-certificate.svg') no-repeat center 80px;
  background-size: auto 35%;
  background-position: 50% 38%;

  overflow: hidden;
  padding-top: 12%;
  margin: ${SPACING_MARGIN}px;
  width: calc(100% - ${SPACING}px);
  height: calc(100% - ${SPACING}px);

  border: 2px dashed #e4e6ed;
  border-radius: 5px;
  background-color: rgba(239, 240, 244, 0.08);
  transition:
    background-color 0.5s ease,
    border-color 0.3s ease;

  p {
    font-weight: 600;
    margin-right: 8px;
  }

  p,
  button {
    position: relative;
    z-index: 100;
  }
  &:after {
    display: block;
    content: '${(p: csUplProps) =>
      p.isRejected ? 'Not a valid file type' : 'Now, let’s drop your file to start upload'}';
    position: absolute;
    bottom: 3.5 * $spacing;
    left: 50%;
    color: ${colors.textActionContrast};
    font-weight: 600;
    letter-spacing: 0.4px;
    transform: translate3d(-50%, 100px, 0);
    background: white;
    padding: 16px 24px;
    border-radius: 3px;
    z-index: 999;
    box-shadow:
      0px 1px 3px ${schemes.darklucent['10']},
      0px 6px 8px ${schemes.darklucent['20']};
    transition: transform 0.3s ease;
  }

  ${(props: csUplProps) =>
    props.isActive &&
    css`
      background-color: ${colors.opacifyAction};
      border-color: ${schemes.blue['30']};

      p,
      button {
        opacity: 0;
      }

      &:after {
        transform: translate3d(-50%, 0, 0);
      }
    `}
  ${(props: csUplProps) =>
    props.isRejected &&
    css`
      background-color: ${colors.opacifyDanger};
      border-color: ${schemes.red['40']};

      p,
      button {
        opacity: 0;
      }

      &:after {
        transform: translate3d(-50%, 0, 0);
        color: ${colors.textDanger};
      }
    `}
`
type CertifSettingsInfoProps = { noBorder?: boolean, ... }
export const CertifSettingsInfo: StyledComponent<CertifSettingsInfoProps, *, *> = styled.div`
  display: flex;
  position: relative;
  padding: 28px 24px;
  line-height: 1.2em;
  ${(props: CertifSettingsInfoProps) =>
    !props.noBorder &&
    css`
      border-bottom: 1px solid #eff0f4;
    `}

  label {
    width: 100px;
    margin-right: 10px;
    margin-bottom: 0px;

    & + span {
      font-size: 1em;
    }
  }
`
type CertifExpirDateProps = { intent: 'success' | 'error' | 'warning', ... }
export const CertifExpirDate: StyledComponent<CertifExpirDateProps, *, *> = styled.span`
  display: block;
  margin-top: 5px;
  color: ${(props: CertifExpirDateProps) => CertifColors[props.intent]};
`
export const CertifExpirStatus: StyledComponent<CertifExpirDateProps, *, *> = styled.span`
  display: block;
  position: absolute;
  top: 36px;
  right: 40px;

  color: ${(props: CertifExpirDateProps) => CertifColors[props.intent]};
`
type CertifExpirRelativeProps = { muted?: boolean, ... }
export const CertifExpirRelative: StyledComponent<CertifExpirRelativeProps, *, *> = styled.span`
  display: block;
  font-size: 34px;
  font-weight: 300;
  margin-top: -17px;
  line-height: 40px;

  ${(props: CertifExpirRelativeProps) =>
    props.muted &&
    css`
      color: #cccfda;
    `}
`
type CertifSettingsExtraProps = { empty?: boolean, ... }
export const CertifSettingsExtra: StyledComponent<CertifSettingsExtraProps, *, *> = styled.div`
  display: flex;
  align-items: center;

  ${(props: CertifSettingsExtraProps) =>
    props.empty
      ? css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #e4e6ed;

          p {
            max-width: 240px;
            margin-top: 14px;
            text-align: center;
            color: #aaa;
          }
        `
      : css`
          height: 48px;

          label {
            min-width: 80px;
            margin: 0 10px 0 0;
            padding-top: 2px;
          }
        `}
`

export const CertifSettingsDocLink: StyledComponent<*, *, *> = styled.span`
  position: absolute;
  left: 22px;
  bottom: 24px;
`
type UploadAreaProps = { review?: boolean, ... }
export const UploadArea: StyledComponent<UploadAreaProps, *, *> = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 0;

  ${(props: UploadAreaProps) =>
    props.review &&
    css`
      padding: 32px;
    `};
`

export const CertifFileReview: StyledComponent<*, *, *> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 2px dashed rgb(239, 240, 244);
  border-radius: 5px;
  background-color: rgba(239, 240, 244, 0.08);
  padding: 0 32px 0 0;
  margin: 0 0 20px 0;
`

export const CertifFileStatus: StyledComponent<CertifExpirDateProps, *, *> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 2px 8px 0 6px;
  color: ${(props: CertifExpirDateProps) => CertifColors[props.intent]};
`

export const CertifFileFeature: StyledComponent<*, *, *> = styled.div`
  flex: 1;
  min-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-right: 40px;
  margin-top: -2px;

  &:last-child {
    flex: none;
  }

  label {
    display: block;
    margin-bottom: 4px;
  }
  span {
    font-weight: 600;
  }
`

export const SettingsPushGrid: StyledComponent<{ platform: 'ios' | 'android' | 'web', ... }, *, *> =
  styled(Grid)`
    grid-template-areas: 'rate rate rate' 'expir priority key';
    grid-template-columns: ${'1fr 1fr 1fr'};
    grid-template-rows: 1fr 1fr;

    .styled-push-rate {
      grid-area: rate;
    }

    ${'' /* grid-template-columns: repeat(auto-fill, minmax(calc(33% - 10px), 1fr)); */}
  `

const minWidthSettings = {
  ios: {
    rate: ['100%', '100%'],
    other: ['50%', 'calc(50% - 10px)'],
  },
  android: {
    rate: ['100%', '100%'],
    other: ['50%', '33%'],
  },
  webpush: {
    rate: ['100%', 'calc(66% - 10px)'],
    other: ['100%', 'calc(34% - 10px)'],
  },
}
type SettingsPushItemProps = { platform: 'ios' | 'android' | 'webpush', isRate?: boolean, ... }
export const SettingsPushItem: StyledComponent<SettingsPushItemProps, *, *> = styled.div`
  padding: 20px;
  flex-grow: 1;
  align-self: stretch;
  min-width: 100%;
  border-bottom: 1px solid ${colorsLegacy.stroke.lighter};
  border-right: none;

  @media (min-width: 660px) {
    min-width: ${(p: SettingsPushItemProps) =>
      minWidthSettings[p.platform][p.isRate ? 'rate' : 'other'][0]};
    border-bottom: ${(p: SettingsPushItemProps) =>
      p.platform === 'android' || p.isRate ? `1px solid ${colorsLegacy.stroke.lighter}` : 'none'};

    ${(p: SettingsPushItemProps) =>
      p.platform !== 'webpush' &&
      css`
        &:nth-child(2) {
          border-right: 1px solid ${colorsLegacy.stroke.lighter};
        }
      `}
  }

  @media (min-width: 979px) {
    min-width: ${(p: SettingsPushItemProps) =>
      minWidthSettings[p.platform][p.isRate ? 'rate' : 'other'][1]};

    ${(p: SettingsPushItemProps) =>
      p.isRate
        ? css`
            border-bottom: ${p.platform === 'webpush'
              ? 'none'
              : `1px solid ${colorsLegacy.stroke.lighter}`};
            border-right: ${p.platform === 'webpush'
              ? `1px solid ${colorsLegacy.stroke.lighter}`
              : 'none'};
          `
        : css`
            border: none;
            border-right: 1px solid ${colorsLegacy.stroke.lighter};
          `}
  }

  &:last-of-type {
    border-right: none;
    border-bottom: none;
  }
`
// ====================== TEXT COLOR
const TextColor = {
  default: colors.text,
  disabled: colors.textDisabled,
  option: colors.textLight,
  label: colors.textNeutral,
  success: colorsLegacy.good.shade.s4,
  warning: colors.textWarning,
  danger: colors.textDanger,
}
type TextProps = {
  kind: 'default' | 'disabled' | 'option' | 'label' | 'success' | 'warning' | 'danger',
  size?: number,
  weight?: number,
  ...
}
export const Text: StyledComponent<TextProps, *, *> = styled.div`
  color: ${(p: TextProps) => TextColor[p.kind]};
  /* font-size: ${(p: TextProps) => (p.size ? p.size : 13)}px; */
  font-size: 1em;
  font-weight: ${(p: TextProps) => (p.weight ? p.weight : 400)};
`

export const Title: StyledComponent<*, *, HTMLElement> = styled.div`
  margin-bottom: 12px;
  font-weight: 500;
`
