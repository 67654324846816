// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

export type TagVariant = 'default' | 'success'

const cssTag = css`
  padding: 2px 4px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  cursor: default;
  white-space: nowrap;
`
type TagProps = { variant?: TagVariant, ... }
export const Tag: StyledComponent<TagProps, *, HTMLSpanElement> = styled.span`
  ${cssTag}
  ${(props: TagProps) =>
    (!props.variant || props.variant === 'default') &&
    css`
      color: ${colors.text};
      background-color: ${colors.fillDisabled};
    `}
`
