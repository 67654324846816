// @flow
import * as React from 'react'

import { ConfirmModalFooter } from 'components/common/confirm-modal-footer'
import { AuthCode } from 'components/form'

import { type UserRecord } from 'com.batch.redux/user.records'

import { securityService } from 'com.batch/shared/infra/security-service.api'
import {
  ConfirmMFA,
  ConfirmMFAFooter,
} from 'com.batch/shared/ui/component/confirm-mfa/confirm-mfa.styles'

type Props = {
  onSuccess: () => void,
  onCancel: () => void,
  confirm?: string,
  cancel: string,
  sensitive?: boolean,
  user: UserRecord,
  authRequired: boolean,
}

export const TotpMfa = ({
  onSuccess,
  onCancel,
  confirm,
  cancel,
  sensitive,
  user,
  authRequired,
}: Props): React.Node => {
  const [code, setCode] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const handleOnFocus2FA = React.useCallback(() => {
    if (!isLoading && code.length === 6 && !!error) {
      setError('')
      setCode('')
    }
  }, [code.length, error, isLoading])

  const isConfirmBtnDisabled = React.useMemo<boolean>(
    () => authRequired && code.length !== 6,
    [authRequired, code.length]
  )

  const handleOnClickConfirm = React.useCallback(() => {
    if (!authRequired) {
      return onSuccess()
    }
    if (isLoading) return
    setIsLoading(true)
    securityService
      .validateTotp({ code, email: user.email })
      .then(onSuccess)
      .catch(error => {
        setError(error.message)
        setIsLoading(false)
      })
  }, [code, authRequired, isLoading, onSuccess, user.email])

  return (
    <ConfirmMFAFooter>
      {authRequired && (
        <ConfirmMFA>
          <p>Please confirm by entering your 2FA verification code</p>
          <AuthCode
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            onChange={setCode}
            onFocus={handleOnFocus2FA}
            invalid={Boolean(error)}
            feedback={error}
            value={code}
            disabled={isLoading}
          />
        </ConfirmMFA>
      )}
      <ConfirmModalFooter
        sensitive={sensitive}
        cancel={cancel}
        confirm={confirm}
        onClickCancel={onCancel}
        onClickConfirm={handleOnClickConfirm}
        confirmDisabled={isConfirmBtnDisabled}
        isLoading={isLoading}
      />
    </ConfirmMFAFooter>
  )
}
