// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { EventFormatter } from 'components/campaign/event-formatter'
import { FlexLine, FlexLineItem, FlexLineItemTitle } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import * as colorsLegacy from 'components/styled/colors'
import { colors } from 'components/styled/tokens'
import { ReviewBigText } from 'components/styled/text'

import { kformat, capitalize } from 'com.batch.common/utils'

import { type CampaignRecord, type State } from 'com.batch.redux/_records'
import { currentCampaign } from 'com.batch.redux/campaign.selector'
import { requiredMlvlForCampaignSelector } from 'com.batch.redux/sdk.selector'
import { estimateForCampaign } from 'com.batch.redux/stat.api'
import { estimateSelector } from 'com.batch.redux/targeting'

const emptyList = new Immutable.List()
// temp selector
const localSelectors = {
  appId: (s: State) => s.app.current.id,
  clusters: (s: State) => s.targeting.get('clusters', emptyList),
  targeting: (s: State) => s.targeting.get('query', null),
  languages: (s: State) => s.targeting.get('languages', emptyList),
  regions: (s: State) => s.targeting.get('regions', emptyList),
}

const InappSpecificReview = ({ inapp }: { inapp: CampaignRecord, ... }): React.Node => {
  const estimate = useSelector(estimateSelector)
  const [mlvlFilteredMatching, setMlvlFilteredMatching] = React.useState(0)
  const targetMlvl = useSelector(requiredMlvlForCampaignSelector)
  const campaign = useSelector(currentCampaign)
  const appId = useSelector(localSelectors.appId)
  const clusters = useSelector(localSelectors.clusters)
  const targeting = useSelector(localSelectors.targeting)
  const regions = useSelector(localSelectors.regions)
  const languages = useSelector(localSelectors.languages)
  const params = React.useMemo(
    () => ({
      appId,
      forInApp: true,
      campaign,
      clusters,
      query: targeting,
      languages,
      regions,
      requiredMlvl: targetMlvl,
    }),
    [appId, campaign, clusters, languages, regions, targetMlvl, targeting]
  )
  React.useEffect(() => {
    if (params.requiredMlvl > 1) {
      setMlvlFilteredMatching(0)
      estimateForCampaign({
        ...params,
        clusters: params.clusters.toJS(),
        languages: params.languages.toJS(),
        regions: params.regions.toJS(),
      }).then(
        ({ body }) => {
          setMlvlFilteredMatching(body?.results?.matching_install ?? 0)
        },
        () => {}
      )
    }
  }, [params])

  const reach: number = React.useMemo(() => estimate.getIn(['installs', 'matching'], 0), [estimate])
  const ratio = React.useMemo(
    () => (reach === 0 ? 1 : mlvlFilteredMatching / reach),
    [reach, mlvlFilteredMatching]
  )
  return (
    <div>
      <FlexLine
        separator
        style={{
          padding: '15px 26px 15px 20px',
        }}
        top
      >
        <FlexLineItem grow={1}>
          <FlexLineItemTitle>Reach</FlexLineItemTitle>
          <ReviewBigText>
            {estimate.get('loading') ? '~' : kformat(reach ?? '~')}{' '}
            {reach > 0 && ratio !== 1 && targetMlvl > 1 && (
              <Tooltip
                tooltip={
                  'The estimated reach has been updated based on your app SDK version distribution.'
                }
              >
                <span>
                  <Icon icon="arrow-long-right" size={13} />
                  <span
                    style={{
                      paddingLeft: 6,
                      color:
                        ratio > 0.2
                          ? 'inherit'
                          : ratio > 0.01
                            ? colors.textWarning
                            : colors.textDanger,
                    }}
                  >
                    {kformat(mlvlFilteredMatching)}
                  </span>
                </span>
              </Tooltip>
            )}
          </ReviewBigText>
        </FlexLineItem>
      </FlexLine>
      <FlexLine
        separator
        style={{
          padding: '15px 26px 15px 20px',
        }}
        top
      >
        <FlexLineItem grow={1}>
          <FlexLineItemTitle>Trigger</FlexLineItemTitle>
          <ReviewBigText>
            <EventFormatter eventCode={inapp.trigger} eventLabel={inapp.triggerLabel} />
          </ReviewBigText>
        </FlexLineItem>
        {parseInt(inapp.capping) > 0 && (
          <FlexLineItem>
            <FlexLineItemTitle>Capping</FlexLineItemTitle>
            <ReviewBigText>{inapp.capping} x</ReviewBigText>
          </FlexLineItem>
        )}
        <FlexLineItem>
          <FlexLineItemTitle>Priority</FlexLineItemTitle>
          <ReviewBigText>{capitalize(inapp.inAppPriority)}</ReviewBigText>
        </FlexLineItem>
        {inapp.gracePeriod > 0 && (
          <FlexLineItem>
            <FlexLineItemTitle>Grace period</FlexLineItemTitle>
            <ReviewBigText>
              {inapp.gracePeriod % 24 === 0
                ? `${inapp.gracePeriod / 24} day${inapp.gracePeriod / 24 > 1 ? 's' : ''}`
                : `${inapp.gracePeriod} hour${inapp.gracePeriod > 1 ? 's' : ''}`}
            </ReviewBigText>
          </FlexLineItem>
        )}
      </FlexLine>
      {(inapp.start || inapp.end) && (
        <FlexLine separator style={{ padding: '15px 26px 15px 20px ' }} top>
          {inapp.start && (
            <FlexLineItem>
              <FlexLineItemTitle>starting</FlexLineItemTitle>
              <ReviewBigText>{inapp.start.format('YYYY/MM/DD')}</ReviewBigText>
            </FlexLineItem>
          )}
          {inapp.start && (
            <FlexLineItem grow={1}>
              <FlexLineItemTitle>at</FlexLineItemTitle>
              <ReviewBigText>{inapp.start.format('HH:mm')} </ReviewBigText>{' '}
              {inapp.tzAware ? "(in the user's timezone)" : '(utc)'}
            </FlexLineItem>
          )}
          {inapp.end && (
            <FlexLineItem>
              <FlexLineItemTitle>ending</FlexLineItemTitle>
              <ReviewBigText>{inapp.end.format('YYYY/MM/DD')}</ReviewBigText>
            </FlexLineItem>
          )}
          {inapp.end && (
            <FlexLineItem>
              <FlexLineItemTitle>at</FlexLineItemTitle>
              <ReviewBigText>{inapp.end.format('HH:mm')} </ReviewBigText>{' '}
              {inapp.tzAware ? "(in the user's timezone)" : '(utc)'}
            </FlexLineItem>
          )}
        </FlexLine>
      )}
    </div>
  )
}
export default InappSpecificReview
