// @flow
import { type List, type OrderedMap } from 'immutable'
import * as React from 'react'

import { Box, BoxBody, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Skeleton, Wrapper } from 'components/common/empty-states'

import { EventEmptyList } from './event-empty-list'
import { EventList } from './event-list/event-list'

import { type EventRecord } from 'com.batch/profile/models/event.records'
import { AttributesSheetProvider } from 'com.batch/profile/ui/components/events-block/attributes-sheet/attributes-sheet-provider'
import { statusConfig } from 'com.batch/profile/ui/components/events-block/event-row/channel-badge'
import { LocalTimeTitle } from 'com.batch/profile/ui/components/events-block/events-blocks.styles'
import { FilteredEventsProvider } from 'com.batch/profile/ui/components/events-block/filtered-events-provider'

type EventsBlockProps = {
  isLoading: boolean,
  events: OrderedMap<string, List<EventRecord>>,
}

export const EventsBlock = ({ isLoading, events }: EventsBlockProps): React.Node => {
  const [nbEvents, setNbEvents] = React.useState<number>(0)
  const [filteredEvents, setFilteredEvents] =
    React.useState<OrderedMap<string, List<EventRecord>>>(events)
  const isOverlayShown = React.useMemo(
    () => (events.size === 0 || nbEvents === 0) && !isLoading,
    [events.size, isLoading, nbEvents]
  )

  const handleOnFilterChange = React.useCallback(
    terms => {
      let nb = 0
      const newEvents = events.map(e => {
        return e.filter(v => {
          const subjects = []
          if (v.customEvent) {
            subjects.push(v.customEvent.name)
          }
          if (v.messagingEvent) {
            subjects.push(statusConfig[v.messagingEvent?.metric]?.label)
            subjects.push(v.messagingEvent?.channel)
            subjects.push(v.messagingEvent?.orchestrationMetadata.orchestrationName)
            subjects.push(v.messagingEvent?.orchestrationMetadata.stepName)
          }
          const matchFilter = subjects.some(subject => {
            return (subject ?? '').toLowerCase().includes(terms.toLowerCase())
          })
          if (matchFilter) {
            nb++
          }
          return matchFilter
        })
      })
      setFilteredEvents(newEvents)
      setNbEvents(nb)
    },
    [events]
  )

  return (
    <Box>
      <BoxHeader style={{ paddingRight: 10 }}>
        <Skeleton w={80} h={22}>
          <HeaderBoxTitle
            title={
              <React.Fragment>
                History<LocalTimeTitle>&nbsp;in your local time</LocalTimeTitle>
              </React.Fragment>
            }
          />
        </Skeleton>
        <Skeleton w={96} h={14}>
          <FilteredEventsProvider onChange={handleOnFilterChange} />
        </Skeleton>
      </BoxHeader>

      <BoxBody>
        <Wrapper
          isEmpty={isOverlayShown || isLoading}
          isLoading={isLoading}
          isOverlayShown={isOverlayShown}
          overlayProps={{
            status: 'empty',
            ...(events.size === 0
              ? {
                  title: 'No history available',
                  description: 'This profile has not triggered any event in the last month',
                }
              : {
                  title: 'No recent matching history',
                  description: 'No matching event found in the last 30 days',
                }),
          }}
        >
          {isLoading ? (
            <EventEmptyList />
          ) : (
            <AttributesSheetProvider>
              <EventList events={filteredEvents} hasEvents={!isOverlayShown} />
            </AttributesSheetProvider>
          )}
        </Wrapper>
      </BoxBody>
    </Box>
  )
}
