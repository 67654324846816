// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { DocLink } from 'components/styled/text'

import { CONTENT_MEDIA_URL } from './media-url-utils'
import {
  MediaUrlInfoSection,
  MediaUrlSectionHeader,
  MediaUrlTag,
  MediaUrlParagraph,
} from './media-url.styles'

type SectionsProps = {
  kind: 'image' | 'video' | 'audio',
  isDynamic: boolean,
  ...
}

export const SectionFormat = ({
  kind,
  imageRecommandation,
}: SectionsProps & {
  imageRecommandation?: {
    message: React.Node,
    docLinkText: string,
    docLinkUrl: string,
    ...
  },
  ...
}): React.Node => {
  return (
    <MediaUrlInfoSection>
      <MediaUrlSectionHeader template="minmax(100px, 1fr) auto">
        <h4>Format</h4>
        <div>
          {CONTENT_MEDIA_URL[kind].formats.map(format => (
            <MediaUrlTag key={format}>{format}</MediaUrlTag>
          ))}
        </div>
      </MediaUrlSectionHeader>

      {kind === 'image' ? (
        imageRecommandation && (
          <React.Fragment>
            {!!imageRecommandation.message && (
              <MediaUrlParagraph hasLinkBelow={!!imageRecommandation.docLinkText}>
                {imageRecommandation.message}
              </MediaUrlParagraph>
            )}
            <DocLink href={imageRecommandation.docLinkUrl} intent="action" target="_blank">
              {imageRecommandation.docLinkText}
            </DocLink>
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <MediaUrlParagraph>Your URL must start with «https://»</MediaUrlParagraph>
          <MediaUrlParagraph hasLinkBelow>
            Minimize your file size to improve performance on user devices (battery life, data plan,
            etc)
          </MediaUrlParagraph>
          <DocLink
            href={
              kind === 'audio'
                ? 'https://doc.batch.com/dashboard/push/message-edition#audio'
                : 'https://doc.batch.com/dashboard/push/message-edition#video--gif'
            }
            intent="action"
            target="_blank"
          >
            Learn more
          </DocLink>
        </React.Fragment>
      )}
    </MediaUrlInfoSection>
  )
}

export const SectionHosting = ({ kind, isDynamic }: SectionsProps): React.Node => {
  return (
    <MediaUrlInfoSection>
      <MediaUrlSectionHeader template="auto minmax(100px, 1fr)">
        <Icon icon="upload" />
        <h4>File hosting</h4>
      </MediaUrlSectionHeader>

      {kind === 'image' && !isDynamic ? (
        <MediaUrlParagraph>
          This file will be cached and served from our servers, you don’t need to perform any
          particular action.
        </MediaUrlParagraph>
      ) : (
        <MediaUrlParagraph>
          This file is generated dynamically and will be served from its current location, pay
          attention to possible traffic surges.
        </MediaUrlParagraph>
      )}
    </MediaUrlInfoSection>
  )
}

export const SectionDynamicUrl = (): React.Node => {
  return (
    <MediaUrlInfoSection>
      <MediaUrlSectionHeader template="auto minmax(100px, 1fr)">
        <Icon icon="macros" />
        <h4>Dynamic URL</h4>
      </MediaUrlSectionHeader>

      <MediaUrlParagraph hasLinkBelow>
        You can add dynamic data to customize your media URL
      </MediaUrlParagraph>

      <DocLink
        href="https://doc.batch.com/guides/message-personalization/basics#dynamic-content"
        intent="action"
        target="_blank"
      >
        Learn more
      </DocLink>
    </MediaUrlInfoSection>
  )
}
