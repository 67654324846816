/* eslint-disable react/jsx-no-bind */
// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'

import { Avatar } from 'components/common/avatar'
import { ButtonNavLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import Loader from 'components/common/loader-legacy'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableBody,
  TableCell,
  TableFooter,
  TableCellActions,
  TableRow,
} from 'components/common/table/index'
import { Tag } from 'components/common/tag'
import { FilterSearch } from 'components/filter'
import { LightText } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { numberFormat } from 'com.batch.common/utils'

import { setPage, appsPageSelector, updateFilters } from './../redux/app'

import { CompanyPicker } from '../company/company-picker'
import { Title, Subtitle, Ellipsis } from '../console.style'
import { type State } from '../redux/console.records'
import { CompanyFactory } from 'com.batch.redux/_records'

const appsLoadingSelector = (state: State) => state.app.loading
const companiesSelector = (state: State) => state.company.entities
const pageSelector = (state: State) => state.app.page
const countSelector = (state: State) => state.app.count
const nbPerPageSelector = (state: State) => state.app.nbPerPage
const filtersSelector = (state: State) => state.app.filters

export const AppList = (): React.Node => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search])
  const dispatch = useDispatch()
  const apps = useSelector(appsPageSelector)
  const companies = useSelector(companiesSelector)
  const loading = useSelector(appsLoadingSelector)
  const page = useSelector(pageSelector)
  const count = useSelector(countSelector)
  const nbPerPage = useSelector(nbPerPageSelector)
  const filters = useSelector(filtersSelector)

  React.useEffect(() => {
    const targetPage = params.has('page') ? parseInt(params.get('page')) : 1
    const targetQuery = params.has('query') ? params.get('query') || '' : ''
    const targetFilterCompany =
      params.has('companyId') && !!params.get('companyId')
        ? parseInt(params.get('companyId'))
        : null

    if (targetPage !== page) {
      dispatch(setPage(targetPage))
    }

    if (targetQuery !== filters.query || targetFilterCompany !== filters.companyId) {
      dispatch(
        updateFilters(
          filters
            .set('query', targetQuery)
            .set(
              'companyId',
              targetFilterCompany ? parseInt(targetFilterCompany) : targetFilterCompany
            )
        )
      )
    }
  }, [location, filters, page, params, dispatch])

  return (
    <React.Fragment>
      <Grid template="1fr 400px 210px">
        <Grid alignItems="end" template="110px 1fr">
          <Title>All apps</Title>
          <Subtitle>
            {numberFormat(count)} app{count > 0 && 's'}
          </Subtitle>
        </Grid>
        <CompanyPicker
          isClearable
          placeholder="Filter by company"
          companyId={filters.companyId}
          setCompany={c => {
            navigate({
              search: `?page=1&query=${encodeURIComponent(filters.query)}&companyId=${
                c ? c.id : ''
              }`,
            })
          }}
        />
        <div>
          <FilterSearch
            expandable={false}
            identifier="ApiKey, name or id"
            value={filters.get('query')}
            onChange={q => {
              navigate({
                search: `?page=1&query=${encodeURIComponent(q)}&companyId=${
                  filters.companyId ? filters.companyId : ''
                }`,
              })
            }}
          />
        </div>
      </Grid>

      <br />

      <Loader loading={loading} overlay>
        <Table
          kind="light"
          style={{ tableLayout: 'fixed', width: '100%' }}
          template="100px 2fr repeat(3, 1fr) 40px 78px"
        >
          <TableHeader>
            <TableCellHeader>Id</TableCellHeader>
            <TableCellHeader>Name</TableCellHeader>
            <TableCellHeader>Company</TableCellHeader>
            <TableCellHeader>Plan</TableCellHeader>
            <TableCellHeader>Last access</TableCellHeader>
            <TableCellHeader>Prj</TableCellHeader>
            <div />
          </TableHeader>

          <TableBody>
            {apps.map((app, i) => {
              let cId = app.companyId ? app.companyId : 0

              return (
                <TableRow key={i}>
                  <TableCell>
                    <NavLink to={`/console/apps/${app.id}`}>{app.id}</NavLink>
                  </TableCell>
                  <TableCell>
                    <Grid template="38px 1fr">
                      <Avatar
                        placeholder={app.name.split(' ')[0]}
                        platform={app.platform}
                        url={app.icon}
                        style={{ marginRight: 12 }}
                      />
                      <Ellipsis>
                        <NavLink to={`/console/apps/${app.id}`}>{app.name}</NavLink>
                      </Ellipsis>
                    </Grid>
                  </TableCell>
                  <TableCell noOverflow>
                    <Grid template="38px 1fr">
                      <Avatar
                        url={companies.get(cId, CompanyFactory()).avatarUrl}
                        placeholder={companies.get(cId, CompanyFactory()).name}
                        color={'#1c2e43'}
                        style={{ marginRight: 12 }}
                      />

                      <Ellipsis>
                        <NavLink to={`/console/company/${cId}`}>
                          {companies.get(cId, CompanyFactory({ name: 'loading ...' })).name ||
                            'No name'}
                        </NavLink>
                      </Ellipsis>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Tag type="label">
                      {
                        companies.get(cId, CompanyFactory({ name: 'loading ...' })).billing.plan
                          .name
                      }
                    </Tag>
                  </TableCell>
                  <TableCell style={{ flexDirection: 'column', alignItems: 'baseline' }}>
                    {!!app.lastAccess && (
                      <React.Fragment>
                        {app.lastAccess.format('DD/MM/YY')}
                        <LightText>
                          (
                          {dayjs
                            .duration(
                              dayjs()
                                .utc()
                                .diff(dayjs.utc(app.lastAccess ?? '') || dayjs.utc())
                            )
                            .humanize()}{' '}
                          ago)
                        </LightText>
                      </React.Fragment>
                    )}
                  </TableCell>
                  <TableCell>
                    <ButtonNavLink
                      style={{ height: 'inherit', width: 'inherit' }}
                      kind="inline"
                      intent="action"
                      to={
                        app.projectKey
                          ? `/console/projects/${app.projectKey ?? ''}`
                          : `/console/projects/new?appIds=${app.id}-${app.platform}`
                      }
                    >
                      <Icon icon={app.projectKey ? 'view' : 'add'} />
                    </ButtonNavLink>
                  </TableCell>
                  <TableCellActions>
                    <ButtonNavLink
                      style={{ height: 'inherit', width: 'inherit' }}
                      kind="inline"
                      intent="action"
                      to={`/console/apps/${app.id}`}
                    >
                      Edit
                    </ButtonNavLink>
                  </TableCellActions>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            {count > 0 && (
              <Pager
                total={count}
                page={page}
                nbPerPage={nbPerPage}
                selectPage={(page: number) => {
                  navigate({
                    search: `?query=${params.get('query') || ''}&page=${page}&companyId=${
                      params.get('companyId') || ''
                    }`,
                  })
                }}
              />
            )}
          </TableFooter>
        </Table>

        <div style={{ textAlign: 'center', color: '#8f959e' }}>
          <small>
            listing {count} app{count > 0 && 's'}
          </small>
        </div>
      </Loader>
    </React.Fragment>
  )
}
