// @flow

import Immutable from 'immutable'

import { type VariantId } from 'com.batch/message/models/message.records'
import { computeRate } from 'com.batch/orchestration-analytics/infra/parses/compute'
import {
  type OrchestrationStatsByVariantRecord,
  OrchestrationVariantFactory,
  VariantStatsFactory,
  type VariantStatsProps,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-variant.record'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

export const convertStringToVariantId = (s: string): ?VariantId => {
  const num = parseInt(s)
  if (num === 1 || num === 2 || num === 3 || num === 4) return num
  return undefined
}

export const parseStatsByVariant = (
  stats: ?Array<OrchestrationStatsDynamicDimension>
): OrchestrationStatsByVariantRecord => {
  const variantIndex = stats ? stats[0].dimensionNames.indexOf('ab_testing_variant') : -1

  // Si le retour est vide alors on renvoie une liste vide
  if (!stats || variantIndex === -1) return new Immutable.List()

  let statsByVariant = new Immutable.List()
    .push(
      ...stats.map(({ dimensionValues, currentPeriod }) => {
        const sent = currentPeriod.sent ?? 0
        const delivered = currentPeriod.delivered ?? 0
        const click = currentPeriod.click ?? 0
        const uniqueClick = currentPeriod.uniqueClick ?? 0
        const bounce = currentPeriod.bounce ?? 0
        const open = currentPeriod.open ?? 0
        const uniqueOpen = currentPeriod.uniqueOpen ?? 0
        const unsubscribe = currentPeriod.unsubscribe ?? 0
        const uniqueUnsubscribe = currentPeriod.uniqueUnsubscribe ?? 0

        const variantId: ?VariantId = convertStringToVariantId(dimensionValues[variantIndex])

        // Normalement ce n'est pas un cas possible
        if (!variantId) return OrchestrationVariantFactory()

        // computed values
        const bounceRate = computeRate(bounce, sent)
        const clickRate = computeRate(uniqueClick, delivered)
        const deliveredRate = computeRate(delivered, sent)
        const unsubscribeRate = computeRate(uniqueUnsubscribe, delivered)
        const openRate = computeRate(uniqueOpen, delivered)

        return OrchestrationVariantFactory({
          variantId,
          stats: VariantStatsFactory({
            delivered: Immutable.Record({ value: delivered, rate: deliveredRate, winner: false })(),
            open: Immutable.Record({ value: open, rate: openRate, winner: false })(),
            click: Immutable.Record({ value: click, rate: clickRate, winner: false })(),
            bounce: Immutable.Record({ value: bounce, rate: bounceRate, winner: false })(),
            unsubscribe: Immutable.Record({
              value: unsubscribe,
              rate: unsubscribeRate,
              winner: false,
            })(),
          }),
        })
      })
    )
    .sort((a, b) => (a.variantId > b.variantId ? 1 : -1))

  // Détermination des winners pour chaque metrique, sur leur rate
  ;[
    { metricKey: 'open' },
    { metricKey: 'click' },
    { metricKey: 'bounce', lowIsPositive: true },
    { metricKey: 'unsubscribe', lowIsPositive: true },
  ].forEach(
    ({
      metricKey,
      lowIsPositive = false,
    }: {
      metricKey: $Keys<VariantStatsProps>,
      lowIsPositive?: boolean,
    }) => {
      const maxIndexes = statsByVariant
        .map((variant, index) => ({ index, rate: variant.stats[metricKey].rate }))
        // On garde tous les variants qui sont égaux à la valeur max (si égalité, si qu'un win la plupart du temps on aura qu'un seul index)
        .filter(({ rate }) => {
          // 0 n'est pas considéré comme un win, sauf si une valeur basse est considérée comme bien
          if (rate === 0 && !lowIsPositive) return false

          return (
            rate ===
            (lowIsPositive
              ? statsByVariant.minBy(variant => variant.stats[metricKey].rate).stats[metricKey].rate
              : statsByVariant.maxBy(variant => variant.stats[metricKey].rate).stats[metricKey]
                  .rate)
          )
        })
        // On garde l'index pour pouvoir ensuite set le winner
        .map(({ index }) => index)

      maxIndexes.forEach(index => {
        statsByVariant = statsByVariant.setIn([index, 'stats', metricKey, 'winner'], true)
      })
    }
  )

  return statsByVariant
}
