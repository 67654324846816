// @flow

import * as React from 'react'

import ReviewField from 'components/campaign/review-field'
import { Box, BoxBody, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import Highlight from 'components/common/highlight'

import { Item, FadedLabel } from './rsr.styles'

import { type advancedPushSettings } from '../review-screen-review'

type RSRSettingsProps = {
  advancedPushSettings: advancedPushSettings,
  ...
}

const RSRSettings = ({ advancedPushSettings }: RSRSettingsProps) => {
  let cleanJson = ''
  try {
    cleanJson = JSON.stringify(JSON.parse(advancedPushSettings.payload || ''), null, 4)
  } catch (err) {
    cleanJson = advancedPushSettings.payload ? advancedPushSettings.payload : ''
  }

  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title="Settings" />
      </BoxHeader>
      <BoxBody>
        {advancedPushSettings.isPush && (
          <Item name="Priority">
            <ReviewField>{advancedPushSettings.priority?.toUpperCase() ?? 'HIGH'}</ReviewField>
          </Item>
        )}
        {advancedPushSettings.isPush && (
          <Item name="Expiration (TTL)" borderTop={Boolean(advancedPushSettings.priority)}>
            {typeof advancedPushSettings.ttl === 'number' ? (
              `${advancedPushSettings.ttl} hours`
            ) : (
              <FadedLabel>—</FadedLabel>
            )}
          </Item>
        )}

        <Item
          name="Custom Payload"
          borderTop={advancedPushSettings.isPush}
          vertical={!!advancedPushSettings.payload}
        >
          {typeof advancedPushSettings.payload === 'string' &&
          advancedPushSettings.payload !== '' ? (
            <Highlight language="json">{cleanJson}</Highlight>
          ) : (
            <FadedLabel>—</FadedLabel>
          )}
        </Item>
      </BoxBody>
    </Box>
  )
}

export default (React.memo<RSRSettingsProps>(RSRSettings): React.ComponentType<RSRSettingsProps>)
