// @flow
import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

const themes = {
  action: {
    bg: colors.opacifyAction,
    fc: colors.textAction,
  },
  feature: {
    bg: colors.opacifyFeature,
    fc: colors.fillFeature,
  },
}
type TagContainerProps = { $kind: $Keys<typeof themes>, ... }
const TagContainer: StyledComponent<TagContainerProps, *, HTMLSpanElement> = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: ${(p: TagContainerProps) => themes[p.$kind].fc};
  padding: 4px 7px;
  border-radius: 100px;
  background: ${(p: TagContainerProps) => themes[p.$kind].bg};

  & + & {
    margin: 0 0 0 5px;
  }
`

export type TagProps = {
  intent?: $Keys<typeof themes>,
  children: React.Node,
  ...
}

export const Tag = ({ children, intent, ...rest }: TagProps): React.Node => (
  <TagContainer {...rest} $kind={intent || 'action'}>
    {children}
  </TagContainer>
)
