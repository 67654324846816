// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type SaveAudienceAction = ReduxAction<'SAVE_AUDIENCE', string>

export type SaveAudienceSuccessAction = ReduxAction<'SAVE_AUDIENCE_SUCCESS', string>
export type SaveAudienceFailureAction = ReduxAction<
  'SAVE_AUDIENCE_FAILURE',
  { error: string, name: string, ... },
>

export type SaveAudienceActions =
  | SaveAudienceAction
  | SaveAudienceSuccessAction
  | SaveAudienceFailureAction

export const saveAudience = (
  name: string,
  displayName: string,
  identifierType: string,
  file?: File,
  onProgress?: (p: number) => void
): DispatchExtraBoundFn<Promise<any>> => {
  return async (dispatch, getState, { audienceServiceApi }): Promise<any> => {
    const state = getState()
    const project = currentProjectSelector(state)
    if (file)
      return promiseActionCreator({
        dispatch,
        promise: audienceServiceApi
          .saveAudience({
            projectKey: project.projectKey,
            name,
            displayName,
            identifierType,
            file,
            onProgress,
          })
          .then(
            () => name,
            error => {
              throw { name, error: error.body.errors[0].message }
            }
          ),
        payload: name,
        actionName: 'SAVE_AUDIENCE',
      })
  }
}
