// @flow

import { createSelector } from 'reselect'

import { type State } from 'com.batch.redux/_records'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { type InlineEditorConfigRecord } from 'com.batch/message-builder/models/message-builder.records'

export const inlineEditorConfigSelector: State => InlineEditorConfigRecord = (state: State) =>
  state.inlineEditor

export const isFieldActiveSelector: (
  field: MessageBuilderEditableField
) => State => boolean = field =>
  createSelector(
    inlineEditorConfigSelector,
    (config: InlineEditorConfigRecord) =>
      config.field === field && !config.personalizationModalIsOpen && config.variant === 'a'
  )
