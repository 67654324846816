// @flow

import * as React from 'react'
import { type Node } from 'react'

const FormRow = ({ children }: { children: Node, ... }): React.Node => (
  <div className="styled-row-form">{children}</div>
)

export { FormRow }
