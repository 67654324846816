// @flow

import Immutable from 'immutable'
import { get as _get } from 'lodash-es'

import { type availableIcons } from 'components/common/svg-icon'

import * as App from './app.action'
import * as Attribute from './attribute'
import * as AttributeValue from './attributeValue'
import * as Audience from './audience'
import * as Campaign from './campaign'
import {
  type GetBillingDetailsFailureAction,
  type PersistBillingFailureAction,
  type PersistBillingSuccessAction,
  type resetRestApiKeyFailureAction,
  type resetRestApiKeySuccessAction,
  type SGF,
  type SGS,
  type updateCompanyFailureAction,
  type updateCompanySuccessAction,
} from './company'
import * as DataCampaign from './dataCampaign'
import * as Stat from './stat'
import * as Template from './template'
import * as TestDevice from './testDevice'
import { type deleteTokenFailureAction } from './testDevice'
import * as Theme from './theme'
import { ToasterFactory, type ToasterRecord, type ToasterStateRecord } from './toaster.records'

import { pluralize } from '../common/utils'
import {
  type DeleteProjectLabelFailureAction,
  type DeleteProjectLabelSuccessAction,
  type DeleteProjectLabelActionType,
} from '../modules/labels/usecases/delete-label'
import { type PasteMessageNodeAction } from '../modules/orchestration-journey/usecases/paste-message-node'
import { type CreateSenderIdentityActions } from 'com.batch.redux/corelogic/usecases/sender-identity/create-sender-identities'
import { type DeleteSenderIdentityActions } from 'com.batch.redux/corelogic/usecases/sender-identity/delete-sender-identities'
import { type UpdateSenderIdentityActions } from 'com.batch.redux/corelogic/usecases/sender-identity/update-sender-identities'
import { type FetchUnifiedCustomerDataSummary } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-summary'
import { type FetchUnifiedCustomerDataValuesFailureAction } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-values'
import { type FetchUnifiedCustomerEventValuesFailureAction } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-event-values'
import {
  type updateProjectFailureAction,
  type updateProjectSuccessAction,
} from 'com.batch.redux/project'
import { type QueryFailedAction } from 'com.batch.redux/query/query'
import * as User from 'com.batch.redux/user'

import { type DeleteAudiencesActions } from 'com.batch/audience/usecases/delete-audience'
import { type FetchAudienceListFailureAction } from 'com.batch/audience/usecases/fetch-audience-list'
import { type SaveAudienceFailureAction } from 'com.batch/audience/usecases/save-audience'
import { type DeleteCappingRulesActionType } from 'com.batch/capping/usecases/delete-capping-rules'
import { type SaveCappingRulesActionType } from 'com.batch/capping/usecases/save-capping-rules'
import {
  type SendTestEmailFailureAction,
  type SendTestEmailSuccessAction,
} from 'com.batch/email/usecases/send-test-email'
import {
  type SaveDragDropHtmlFailureAction,
  type SaveDragDropOptimizedTemplateFailureAction,
} from 'com.batch/email/usecases/update-content'
import { type SaveProjectLabelActionType } from 'com.batch/labels/usecases/save-label'
import {
  type FetchTemplateFailureAction,
  type FetchTemplatesFailureAction,
} from 'com.batch/message/usecases/fetch-templates'
import { type fetchOrchestrationFailureAction } from 'com.batch/orchestration/usecases/fetch-orchestration'
import {
  type SaveOrchestrationSuccessAction,
  type SaveOrchestrationFailureAction,
} from 'com.batch/orchestration/usecases/persist-orchestration'
import { type replicateOrchestrationFailureAction } from 'com.batch/orchestration/usecases/replicate-orchestration'
import { type SelectExperimentWinnerActions } from 'com.batch/orchestration-analytics/usecases/select-experiment-winner'
import {
  type ChangeOrchestrationStateFailureAction,
  type ChangeOrchestrationStateSuccessAction,
} from 'com.batch/orchestration-list/usecases/change-orchestration-state'
import {
  type DeleteOrchestrationFailureAction,
  type DeleteOrchestrationSuccessAction,
} from 'com.batch/orchestration-list/usecases/delete-orchestration'
import { type FetchOrchestrationsListFailureAction } from 'com.batch/orchestration-list/usecases/fetch-orchestrations-list'
import { type searchProfileIdByIdentifierAction } from 'com.batch/profile/usecases/search-profile-by-identifier'
import { type switchProfileAction } from 'com.batch/profile/usecases/switch-profile'
import { type FetchProfilesDistributionFailureAction } from 'com.batch/profilebase/usecases/fetch-profiles-distribution'
import {
  type FetchSmsReachabilityTimeserieStateFailureAction,
  type FetchEmailReachabilityTimeserieStateFailureAction,
  type FetchPushReachabilityTimeserieStateFailureAction,
} from 'com.batch/profilebase/usecases/fetch-reachability-timeserie'
import {
  type FetchSmsSubDistributionFailureAction,
  type FetchPushSubDistributionFailureAction,
  type FetchEmailSubDistributionFailureAction,
} from 'com.batch/profilebase/usecases/fetch-sub-distribution'
import {
  type SendTestPushSuccessAction,
  type SendTestPushFailureAction,
} from 'com.batch/push/usecases/send-test-push'
import { type DeleteSegmentActionType } from 'com.batch/segments/usecases/delete-segment'
import { type SaveSegmentActionType } from 'com.batch/segments/usecases/save-segment'
import { type UpdateSegmentActionType } from 'com.batch/segments/usecases/update-segment'
import { type savePushSettingsActions } from 'com.batch/settings/usecases/save-push-settings'
import {
  CAPPING_POSITIVE_VALUE,
  CAPPING_RULES_LIMIT,
  CAPPING_UNKNOWN_LABEL,
  CAPPING_RULES_PROJECT_LIMIT,
  CAPPING_DURATION_LIMIT,
} from 'com.batch/shared/infra/metadata-service/metadata-service-error-messages'
import {
  type SendTestSmsFailureAction,
  type SendTestSmsSuccessAction,
} from 'com.batch/sms/usecases/send-test-sms'

// ====================== ACTIONS TYPES
export type MessageType = {
  kind?: 'success' | 'error' | 'info',
  size?: 'small' | 'normal',
  title?: string,
  message: string,
  icon?: availableIcons,
  links?: Array<{ name: string, href: string, ... }>,
  ...
}

type dismissMessageAction = {
  type: 'DISMISS_MESSAGE',
  payload: ToasterRecord,
  ...
}

export type showToastAction = {
  type: 'SHOW_MESSAGE',
  payload: {
    kind: 'success' | 'error' | 'info',
    size: 'small' | 'normal',
    message: string,
    icon: availableIcons | null,
    links: Array<{ name: string, href: string, ... }>,
    ...
  },
  ...
}

export type showToastLegacyAction = {
  type: 'SHOW_MESSAGE_LEGACY',
  payload: {
    message: string,
    kind: 'success' | 'error',
    error: any | null,
    icon: availableIcons | null,
    ...
  },
  ...
}

export type uploadErrorAction = {
  type: 'UPLOAD_ERROR',
  payload: { error: string, ... },
  ...
}

export type estimateFailureActionType = {
  type: 'TARGETING_ESTIMATE_FAILURE',
  payload: { error: any, ... },
  ...
}

// ====================== ACTIONS
export const dismissMessage: (msg: ToasterRecord) => dismissMessageAction = msg => ({
  type: 'DISMISS_MESSAGE',
  payload: msg,
})

export const showToastLegacy: (
  message: string,
  error?: any,
  kind?: 'success' | 'error',
  icon?: availableIcons
) => showToastLegacyAction = (message, error = null, kind = 'error', icon = null) => {
  return {
    type: 'SHOW_MESSAGE_LEGACY',
    payload: { message, error, kind, icon },
  }
}

export const showToast: MessageType => showToastAction = ({
  kind = 'error',
  size = 'small',
  title = '',
  message = '',
  icon = null,
  links = ([]: Array<{ href: string, name: string, ... }>),
}) => ({
  type: 'SHOW_MESSAGE',
  payload: { kind, size, title, message, icon, links },
})

// ====================== GENERATE MESSAGE RECORD
const generateToast: ({
  title?: string,
  message: string,
  kind: 'success' | 'error' | 'info',
  size?: 'small' | 'normal',
  error?: any,
  links?: Array<{ name: string, href: string, ... }>,
  icon?: availableIcons | null,
  ...
}) => ToasterRecord = ({
  title = '',
  message,
  kind,
  error,
  size = 'small',
  links,
  icon = null,
}) => {
  const id = Math.floor(Math.random() * 10000)
  return ToasterFactory({
    id,
    title,
    message: `${message}${typeof error === 'string' ? ` ${error}` : ''}`,
    links,
    kind,
    s: size,
    icon: icon ?? (kind === 'success' ? 'success' : 'error'),
  })
}

// ====================== INITIAL STATE
const q = [
  ...(window?.initialData?.successes ?? []).map(message =>
    generateToast({ message, kind: 'success' })
  ),
  ...(window?.initialData?.failures ?? []).map(message =>
    generateToast({ message, kind: 'error' })
  ),
]

export const initialState: ToasterStateRecord = new Immutable.List().push(...q)

// ====================== COMBINE ACTIONS
type MessageActions =
  | FetchTemplateFailureAction
  | FetchTemplatesFailureAction
  | dismissMessageAction
  | replicateOrchestrationFailureAction
  | App.updateAllowedUsersSuccessAction
  | App.updateAllowedUsersFailureAction
  | updateCompanySuccessAction
  | updateCompanyFailureAction
  | resetRestApiKeySuccessAction
  | resetRestApiKeyFailureAction
  | PersistBillingSuccessAction
  | PersistBillingFailureAction
  | GetBillingDetailsFailureAction
  | User.updatePermissionsSuccess
  | User.deleteUserSuccessAction
  | User.updateUserSuccessAction
  | User.updateUserPasswordSuccessAction
  | User.ResendInviteSuccessAction
  | User.InviteUserSuccessAction
  | User.updateUserFailureAction
  | QueryFailedAction
  | User.updatePermissionsFailure
  | User.deleteUserFailureAction
  | User.InviteUserFailureAction
  | User.enable2FASuccessAction
  | User.disable2FASuccessAction
  | User.disable2FAFailureAction
  | Stat.lookForUserFailureAction
  | Template.TemplateFailAction
  | SaveOrchestrationFailureAction
  | SaveOrchestrationSuccessAction
  | showToastAction
  | uploadErrorAction
  | DeleteOrchestrationFailureAction
  | DeleteOrchestrationSuccessAction
  | SGF
  | SGS
  | DataCampaign.FetchCampaignsDataFailureAction
  | DataCampaign.fetchNotificationsFailureAction
  | App.fetchAppFailureAction
  | Stat.fetchAnalyticsByRegionFailureAction
  | Stat.fetchAnalyticsByDayFailureAction
  | Stat.fetchReachFailureAction
  | App.savePushConfigFailureAction
  | App.saveAppSuccessAction
  | App.saveAppFailureAction
  | App.archiveAppFailureAction
  | App.checkWnsAction
  | App.checkWnsFailureAction
  | App.checkWnsSuccessAction
  | App.saveCappingCategorieSuccessAction
  | App.saveCappingCategorieFailureAction
  | App.deleteCappingCategorieSuccessAction
  | App.deleteCappingCategorieFailureAction
  | App.SetWebpushIconsSuccessAction
  | App.SetWebpushIconsFailureAction
  | App.saveSafariWebsiteNameSuccessAction
  | App.addSafariWebsiteSuccessAction
  | App.updateSafariWebsiteSuccessAction
  | App.updateSafariWebsiteFailureAction
  | App.addSafariWebsiteFailureAction
  | App.deleteSafariWebsiteSuccessAction
  | App.deleteSafariWebsiteFailureAction
  | App.saveSafariWebsiteNameFailureAction
  | App.saveDevOriginsSuccessAction
  | App.deleteDevOriginsSuccessAction
  | AttributeValue.fetchAttributesValuesfailure
  | TestDevice.saveDeviceFailureAction
  | TestDevice.sendTestSuccessAction
  | TestDevice.sendTestFailureAction
  | estimateFailureActionType
  | Theme.ThemeSaveSuccessAction
  | Theme.ThemeSaveFailureAction
  | Theme.ArchiveThemeActionSuccess
  | Theme.LinkThemeActionSuccess
  | Theme.LinkThemeActionFailure
  | Theme.AttachThemesActionSuccess
  | Campaign.saveProjectCampaignSuccessAction
  | Campaign.saveProjectCampaignFailureAction
  | Campaign.setCampaignStateFailureAction
  | Campaign.saveCampaignSuccessAction
  | Campaign.saveCampaignFailureAction
  | Campaign.archivedCampaignSuccess
  | Campaign.fetchCampaignFailureAction
  | Campaign.fetchCampaignsFailureAction
  | Campaign.setCampaignStateSuccess
  | Audience.deleteCustomAudienceSuccessAction
  | Audience.deleteCustomAudienceFailureAction
  | Audience.saveCustomAudienceSuccessAction
  | Audience.saveCustomAudienceFailureAction
  | Audience.createCustomAudienceSuccessAction
  | Audience.createCustomAudienceFailureAction
  | Attribute.saveAttributeSuccessAction
  | Attribute.saveAttributeFailureAction
  | showToastLegacyAction
  | deleteTokenFailureAction
  | updateProjectSuccessAction
  | updateProjectFailureAction
  | SendTestEmailFailureAction
  | SendTestEmailSuccessAction
  | searchProfileIdByIdentifierAction
  | switchProfileAction
  | FetchUnifiedCustomerDataSummary
  | FetchUnifiedCustomerDataValuesFailureAction
  | FetchUnifiedCustomerEventValuesFailureAction
  | ChangeOrchestrationStateFailureAction
  | ChangeOrchestrationStateSuccessAction
  | FetchOrchestrationsListFailureAction
  | CreateSenderIdentityActions
  | UpdateSenderIdentityActions
  | DeleteSenderIdentityActions
  | SaveDragDropHtmlFailureAction
  | SaveDragDropOptimizedTemplateFailureAction
  | FetchAudienceListFailureAction
  | SaveAudienceFailureAction
  | DeleteAudiencesActions
  | fetchOrchestrationFailureAction
  | savePushSettingsActions
  | SendTestSmsFailureAction
  | SendTestSmsSuccessAction
  | SaveProjectLabelActionType
  | DeleteProjectLabelActionType
  | SaveCappingRulesActionType
  | DeleteCappingRulesActionType
  | PasteMessageNodeAction
  | DeleteProjectLabelSuccessAction
  | DeleteProjectLabelFailureAction
  | SendTestPushSuccessAction
  | SendTestPushFailureAction
  | FetchSmsSubDistributionFailureAction
  | FetchPushSubDistributionFailureAction
  | FetchEmailSubDistributionFailureAction
  | FetchProfilesDistributionFailureAction
  | FetchEmailReachabilityTimeserieStateFailureAction
  | FetchSmsReachabilityTimeserieStateFailureAction
  | FetchPushReachabilityTimeserieStateFailureAction
  | UpdateSegmentActionType
  | SaveSegmentActionType
  | DeleteSegmentActionType
  | SelectExperimentWinnerActions

// ====================== REDUCER
function messageReducer(
  state: ToasterStateRecord = initialState,
  action: MessageActions
): ToasterStateRecord {
  switch (action.type) {
    case 'DELETE_PROJECT_LABEL_FAILURE':
      return state.push(
        generateToast({
          message: 'Delete error',
          kind: 'error',
        })
      )
    case 'DELETE_PROJECT_LABEL_SUCCESS':
      return state.push(
        generateToast({
          message: 'Delete success',
          kind: 'success',
        })
      )
    case 'PASTE_MESSAGE_NODE':
      return state.push(
        generateToast({
          message: 'Message step duplicated',
          kind: 'success',
        })
      )
    case 'SAVE_PUSH_SETTINGS_SUCCESS':
      return state.push(
        generateToast({
          message: 'Push configuration saved',
          kind: 'success',
        })
      )
    case 'SAVE_PUSH_SETTINGS_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to update the push configuration',
          error: action.payload.error,
          kind: 'error',
        })
      )
    case 'DELETE_ORCHESTRATION_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to delete your orchestration',
          error: action.payload.error,
          kind: 'error',
        })
      )
    case 'DELETE_ORCHESTRATION_SUCCESS':
      return state.push(
        generateToast({
          message: 'Orchestration successfully deleted',
          kind: 'success',
        })
      )
    case 'SAVE_ORCHESTRATION_SUCCESS':
      return state.push(
        generateToast({
          message: 'Orchestration saved',
          kind: 'success',
        })
      )

    case 'SAVE_ORCHESTRATION_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save your orchestration',
          error: action.payload,
          kind: 'error',
        })
      )
    case 'FETCH_ORCHESTRATION_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to fetch your orchestration',
          error: action.payload,
          kind: 'error',
        })
      )
    case 'FETCH_ORCHESTRATIONS_LIST_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load your data',
          error: action.payload,
          kind: 'error',
        })
      )

    case 'T_QUERY_FAILED':
      return state.push(
        generateToast({
          message: 'Targeting error',
          error: action.payload.error,
          kind: 'error',
        })
      )
    case 'CHANGE_ORCHESTRATION_STATE_SUCCESS':
      return state.push(
        generateToast({
          message: `Orchestration successfully ${
            action.payload.state === 'RUNNING' ? 'launched' : 'stopped'
          }`,
          kind: 'success',
        })
      )

    case 'CHANGE_ORCHESTRATION_STATE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to update orchestration state',
          error: action.payload.error,
          kind: 'error',
        })
      )
    case 'FETCH_NOTIFICATIONS_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load notifications data',
          error: action.payload.error,
          kind: 'error',
        })
      )
    case 'UPDATE_PROJECT_SUCCESS':
      return state.push(generateToast({ message: 'Project saved', kind: 'success' }))
    case 'REPLICATE_ORCHESTRATION_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to replicate',
          error: action.payload,
          kind: 'error',
        })
      )
    case 'UPDATE_PROJECT_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable save project',
          error: action.payload.errors[0]?.message ?? '',
          kind: 'error',
        })
      )

    case 'UPDATE_USER_PERMISSIONS_SUCCESS':
      return state.push(generateToast({ message: 'User permissions saved', kind: 'success' }))

    case 'DELETE_USER_FOR_COMPANY_SUCCESS':
      return state.push(generateToast({ message: 'User deleted', kind: 'success' }))

    case 'UPDATE_ALLOWED_USERS_SUCCESS':
      return state.push(generateToast({ message: 'User access saved', kind: 'success' }))

    case 'UPDATE_ALLOWED_USERS_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to update team access.',
          kind: 'error',
        })
      )

    case 'UPDATE_USER_SUCCESS':
      return state.push(generateToast({ message: 'Account informations saved', kind: 'success' }))

    case 'UPDATE_COMPANY_SUCCESS':
      return state.push(generateToast({ message: 'Account settings saved!', kind: 'success' }))

    case 'RESET_REST_API_KEY_SUCCESS':
      return state.push(generateToast({ message: 'REST API Key reset', kind: 'success' }))

    case 'ENABLE_2FA_SUCCESS':
      return state.push(generateToast({ message: '2FA enabled', kind: 'success' }))

    case 'DISABLE_2FA_SUCCESS':
      return state.push(generateToast({ message: '2FA disabled', kind: 'success' }))

    case 'UPDATE_USER_PASSWORD_SUCCESS':
      return state.push(generateToast({ message: 'Password saved', kind: 'success' }))

    case 'RESEND_INVITE_SUCCESS':
    case 'INVITE_USER_SUCCESS':
      return state.push(generateToast({ message: 'Invitation sent', kind: 'success' }))

    case 'PERSIST_BILLING_INFO_SUCCESS':
      return state.push(generateToast({ message: 'Payment informations saved', kind: 'success' }))

    case 'UPDATE_USER_FAILURE':
    case 'UPDATE_COMPANY_FAILURE':
    case 'RESET_REST_API_KEY_FAILURE':
    case 'PERSIST_BILLING_INFO_FAILURE':
    case 'GET_BILLING_DETAILS_FAILURE':
    case 'UPDATE_USER_PERMISSIONS_FAILURE':
    case 'DELETE_USER_FOR_COMPANY_FAILURE':
    case 'INVITE_USER_FAILURE':
    case 'DISABLE_2FA_FAILURE':
      return state.push(
        generateToast({
          message: _get(action.payload, 'errors[0].message', 'Unknown error'),
          kind: 'error',
        })
      )

    case 'DEBUG_USER_FAILURE':
      return state.push(generateToast({ message: action.payload, kind: 'error' }))

    case 'PARSE_TEMPLATE_FAILURE':
      return state.push(
        generateToast({
          message: action.payload.error ?? 'Failed to parse template!',
          kind: 'error',
        })
      )

    case 'SHOW_MESSAGE':
      return state.push(
        generateToast({
          kind: action.payload.kind,
          size: action.payload.size,
          message: action.payload.message,
          icon: action.payload.icon,
          links: action.payload.links,
        })
      )

    case 'SHOW_MESSAGE_LEGACY':
      return state.push(
        generateToast({
          message: action.payload.message,
          kind: action.payload.kind,
          icon: action.payload.icon,
        })
      )

    case 'UPLOAD_ERROR':
      return state.push(
        generateToast({
          message: `Unable to upload: ${action.payload.error}`,
          kind: 'error',
        })
      )

    case 'SAVE_GDPR_SUCCESS':
      return state.push(
        generateToast({
          message: "GDPR's Data Processing Agreement saved!",
          kind: 'success',
        })
      )

    case 'SAVE_GDPR_FAILURE':
      return state.push(
        generateToast({
          message: `Unable to save: ${_get(action.payload, 'errors[0].message', 'Unknown error')}`,
          kind: 'error',
        })
      )

    case 'FETCH_APP_BY_ID_FAILURE':
      return state.push(
        generateToast({
          message: `Unable to load app: ${action.payload?.error ?? ''}`,
          kind: 'error',
        })
      )

    case 'FETCH_CAMPAIGNS_DATA_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load campaign data',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'FETCH_ANALYTIC_BY_REGION_FAILURE':
      return state.push(
        generateToast({ message: 'Unable to load regional analytics', kind: 'error' })
      )

    case 'FETCH_ANALYTIC_BY_DATE_FAILURE':
    case 'FETCH_REACH_FAILURE':
      return state.push(generateToast({ message: 'Unable to load analytics', kind: 'error' }))

    case 'SAVE_PUSH_CONFIG_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save your settings',
          error: _get(action.payload, 'body.errors[0].message', 'Unexpected error'),
          kind: 'error',
        })
      )

    case 'SAVE_APP_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save your settings',
          error: _get(action.payload, 'body.errors[0].message', 'Unexpected error'),
          kind: 'error',
        })
      )

    case 'ARCHIVE_APP_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to archive your app',
          error: _get(action.payload, 'body.errors[0].message', 'Unexpected error'),
          kind: 'error',
        })
      )

    // case 'CHECK_WNS':
    //   return state.push(
    //     generateToast({
    //       message: 'Checking WNS configuration...',
    //       kind: 'success'
    //     })
    //   )

    case 'CHECK_WNS_FAILURE':
      return state.push(
        generateToast({
          message: 'WNS configuration invalid',
          kind: 'error',
        })
      )

    case 'CHECK_WNS_SUCCESS':
      return state.push(generateToast({ message: 'WNS configuration saved', kind: 'success' }))

    case 'PT_SAVE_DEVICE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save your test device',
          error: _get(action.payload, 'error.errors[0].message', 'Unknown error'),
          kind: 'error',
        })
      )

    case 'FETCH_ATTRIBUTES_VALUES_FAILURE':
    case 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_FAILURE':
    case 'FETCH_UNIFIED_CUSTOMER_DATA_VALUES_FAILURE':
    case 'FETCH_UNIFIED_CUSTOMER_EVENT_VALUES_FAILURE':
      return state.push(
        generateToast({
          title: 'Technical issue occurred',
          message: 'Unable to load your data',
          kind: 'error',
        })
      )

    case 'PT_TEST_DEVICE_SUCCESS':
      return state.push(
        generateToast({
          message: _get(action.payload, 'message', 'The test has been sent successfully!'),
          kind: 'success',
        })
      )

    case 'PT_TEST_DEVICE_FAILURE':
      return state.push(
        generateToast({
          kind: 'error',
          message: 'Push test has failed',
          error: _get(action.payload, 'error', _get(action.payload, 'error.errors[0].message', '')),
        })
      )

    case 'PT_DELETE_DEVICE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to delete your test device',
          error: _get(action.payload, 'error.errors[0].message', 'Unknown error'),
          kind: 'error',
        })
      )

    case 'SAVE_CAMPAIGN_FAILURE':
    case 'SAVE_PROJECT_CAMPAIGN_FAILURE':
      return state.push(
        generateToast({
          message: action.payload,
          kind: 'error',
        })
      )

    case 'SET_CAMPAIGN_STATE_FAILURE':
      return state.push(
        generateToast({
          message: action.payload.err,
          kind: 'error',
        })
      )

    case 'ARCHIVE_CAMPAIGN_SUCCESS':
      return state.push(
        generateToast({
          message: 'Campaign deleted',
          kind: 'success',
        })
      )

    case 'SET_CAMPAIGN_STATE_SUCCESS':
      return state.push(
        generateToast({
          message: action.payload.state === 'STOPPED' ? 'Campaign stopped' : 'Campaign running',
          kind: 'success',
        })
      )

    case 'SAVE_PROJECT_CAMPAIGN_SUCCESS': {
      const plural = action.payload.apiCampaign.apps.length > 1 ? 's' : ''
      return state.push(
        generateToast({
          message: !action.payload.apiCampaign.live
            ? `Campaign${plural} saved as draft`
            : action.payload.apiCampaign?.push_time === 'now'
              ? `Campaign${plural} sent`
              : `Running campaign${plural} saved`,
          kind: 'success',
        })
      )
    }

    case 'SAVE_CAMPAIGN_SUCCESS':
      return state.push(
        generateToast({
          message: !action.payload.live
            ? 'Campaign saved as draft'
            : action.payload.sendType === 'now'
              ? 'Campaign sent'
              : 'Running campaign saved',
          kind: 'success',
        })
      )

    case 'TARGETING_ESTIMATE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to estimate',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'SAVE_PRESSURE_SUCCESS':
      return state.push(
        generateToast({
          kind: 'success',
          message: `${
            window.location.pathname.includes('settings/cappings') ? 'Cappings' : 'Label'
          } saved`,
        })
      )

    case 'DELETE_PRESSURE_SUCCESS':
      return state.push(
        generateToast({
          kind: 'success',
          message: 'Label deleted',
        })
      )

    case 'SAVE_PRESSURE_FAILURE':
    case 'DELETE_PRESSURE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save label',
          error: _get(action.payload, 'error.errors[0].message', 'Unexpected error'),
          kind: 'error',
        })
      )

    case 'THEME_SAVE_SUCCESS':
      return state.push(
        generateToast({
          kind: 'success',
          message: action.payload.isDuplication ? 'Theme replicated' : 'Theme saved',
        })
      )

    case 'THEME_SAVE_FAILURE':
      return state.push(
        generateToast({
          kind: 'error',
          message: _get(action.payload, 'error.errors[0].message', 'Unknow error'),
        })
      )

    case 'THEME_ARCHIVE_SUCCESS':
      return state.push(
        generateToast({
          kind: 'success',
          message: 'Theme deleted',
        })
      )

    case 'THEME_APP_LINK_SUCCESS':
      if (!action.payload.link) {
        return state.push(
          generateToast({
            message: 'Theme removed from this app',
            kind: 'success',
          })
        )
      }
      return state

    case 'THEME_APP_LINK_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to attach/detach Theme',
          error: _get(action.payload, 'body.errors[0].message', 'Unknow error'),
          kind: 'error',
        })
      )

    case 'ATTACH_THEMES_APP_SUCCESS':
      return state.push(
        generateToast({
          message: `Theme${action.payload.themes.size > 1 ? 's' : ''} attached to this app`,
          kind: 'success',
        })
      )

    case 'FETCH_CAMPAIGN_FAILURE':
    case 'FETCH_CAMPAIGNS_FAILURE': {
      let error = _get(action.payload, 'error', false)
      error = error ? `Error: ${error}` : 'Please try again in a few seconds'
      return state.push(
        generateToast({ message: 'Unable to load your campaign', error, kind: 'error' })
      )
    }

    case 'DISMISS_MESSAGE':
      return state.filter(message => message.id !== action.payload.id)

    case 'CREATE_CUSTOM_AUDIENCE_SUCCESS':
    case 'SAVE_CUSTOM_AUDIENCE_SUCCESS':
      return state.push(
        generateToast({
          message: 'Audience saved',
          kind: 'success',
        })
      )

    case 'CREATE_CUSTOM_AUDIENCE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to create your custom audience',
          error: _get(action, 'payload.error', ''),
          kind: 'error',
        })
      )

    case 'SAVE_CUSTOM_AUDIENCE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save your custom audience',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'DELETE_CUSTOM_AUDIENCE_SUCCESS':
      return state.push(
        generateToast({
          message: 'Audience deleted',
          kind: 'success',
        })
      )

    case 'DELETE_CUSTOM_AUDIENCE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to delete your custom audience',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'SAVE_ATTRIBUTE_SUCCESS':
      return state.push(
        generateToast({
          message: 'Attribute saved',
          kind: 'success',
        })
      )

    case 'SAVE_ATTRIBUTE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save the attribute',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'ADD_SAFARI_WEBSITE_SUCCESS':
    case 'UPDATE_SAFARI_WEBSITE_SUCCESS':
      return state.push(
        generateToast({
          message: 'Allowed domain saved',
          kind: 'success',
        })
      )

    case 'DELETE_SAFARI_WEBSITE_SUCCESS':
      return state.push(generateToast({ message: 'Allowed domain deleted', kind: 'success' }))

    case 'SAVE_SAFARI_WEBSITE_NAME_SUCCESS':
      return state.push(
        generateToast({
          message: 'Website name saved',
          kind: 'success',
        })
      )

    case 'SET_WEBPUSH_ICONS_SUCCESS':
      return state.push(
        generateToast({
          message: 'Web push icons saved',
          kind: 'success',
        })
      )

    case 'SAVE_DEV_ORIGINS_SUCCESS':
      return state.push(
        generateToast({
          message: 'Dev origin saved',
          kind: 'success',
        })
      )

    case 'DELETE_DEV_ORIGINS_SUCCESS':
      return state.push(
        generateToast({
          message: 'Dev origin deleted',
          kind: 'success',
        })
      )

    case 'ADD_SAFARI_WEBSITE_FAILURE':
    case 'UPDATE_SAFARI_WEBSITE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save allowed domain.',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'SAVE_SAFARI_WEBSITE_NAME_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save website name.',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'SET_WEBPUSH_ICONS_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to save icons.',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'DELETE_SAFARI_WEBSITE_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to delete website.',
          error: action.payload.error,
          kind: 'error',
        })
      )

    case 'SEND_TEST_EMAIL_SUCCESS':
      return state.push(
        generateToast({
          message: 'Email(s) successfully sent',
          kind: 'success',
        })
      )

    case 'SEND_TEST_EMAIL_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to send email',
          error: `(${action.payload.error?.errors?.[0]?.message ?? 'Unexpected error'})`,
          kind: 'error',
        })
      )

    case 'FETCH_TEMPLATE_FAILURE':
    case 'FETCH_TEMPLATES_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to update your preview',
          error: action.payload.error.message,
          kind: 'error',
        })
      )

    case 'CREATE_SENDER_IDENTITY_SUCCESS': {
      return state.push(
        generateToast({
          message: 'Sender setting created',
          kind: 'success',
        })
      )
    }

    case 'CREATE_SENDER_IDENTITY_FAILURE': {
      return state.push(
        generateToast({
          message: 'Unable to create sender setting',
          error: action.payload.error,
          kind: 'error',
        })
      )
    }

    case 'UPDATE_SENDER_IDENTITY_SUCCESS': {
      return state.push(
        generateToast({
          message: 'Sender setting updated',
          kind: 'success',
        })
      )
    }

    case 'UPDATE_SENDER_IDENTITY_FAILURE': {
      return state.push(
        generateToast({
          message: 'Unable to update sender setting',
          error: action.payload.error,
          kind: 'error',
        })
      )
    }

    case 'DELETE_SENDER_IDENTITY_SUCCESS': {
      return state.push(
        generateToast({
          message: 'Sender setting deleted',
          kind: 'success',
        })
      )
    }

    case 'DELETE_SENDER_IDENTITY_FAILURE': {
      return state.push(
        generateToast({
          message: 'Unable to delete sender setting',
          error: action.payload.error,
          kind: 'error',
        })
      )
    }
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_FAILURE':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT_FAILURE': {
      return state.push(
        generateToast({
          message: 'Error while saving this template. Please try again or contact us.',
          error: action.payload.error,
          kind: 'error',
        })
      )
    }

    case 'FETCH_AUDIENCE_LIST_FAILURE': {
      return state.push(
        generateToast({
          message: "Couldn't fetch audience list",
          error: action.payload,
          kind: 'error',
        })
      )
    }

    case 'DELETE_AUDIENCE_SUCCESS': {
      return state.push(
        generateToast({
          message: 'Audience successfully deleted',
          error: action.payload,
          kind: 'success',
        })
      )
    }
    case 'SAVE_AUDIENCE_FAILURE': {
      const exist = action.payload.error.includes('already exist')
      return state.push(
        generateToast({
          message: `${
            exist
              ? `Unable to create your audience. The audience named ${action.payload.name} already exist.`
              : ' Unable to save your audience.'
          }`,
          kind: 'error',
        })
      )
    }
    case 'SEND_TEST_SMS_SUCCESS':
      return state.push(
        generateToast({
          message: 'SMS is sending...',
          kind: 'success',
        })
      )
    case 'SEND_TEST_SMS_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to send SMS',
          error: `(${action.payload.error?.errors?.[0]?.message ?? 'Unexpected error'})`,
          kind: 'error',
        })
      )
    case 'SAVE_CAPPING_RULES_SUCCESS':
      return state.push(
        generateToast({
          message: 'Capping saved',
          kind: 'success',
        })
      )
    case 'SAVE_CAPPING_RULES_FAILURE': {
      const error = action.payload?.toLowerCase() ?? 'Unexpected error'

      switch (error) {
        case CAPPING_POSITIVE_VALUE:
          return state.push(
            generateToast({
              message: 'Capping value must be greater than zero',
              kind: 'error',
            })
          )
        case CAPPING_UNKNOWN_LABEL:
          return state.push(
            generateToast({
              message: 'Label associated to capping not found',
              kind: 'error',
            })
          )
        case CAPPING_RULES_PROJECT_LIMIT:
          return state.push(
            generateToast({
              message: 'Cappings per project limit reached',
              kind: 'error',
            })
          )
        case CAPPING_RULES_LIMIT:
          return state.push(
            generateToast({
              message: 'Capping rules per label limit reached',
              kind: 'error',
            })
          )
        case CAPPING_DURATION_LIMIT:
          return state.push(
            generateToast({
              message: 'Capping duration must be less than 30 days or 720 hours',
              kind: 'error',
            })
          )
        default:
          return state.push(
            generateToast({
              message: 'Unable to save capping',
              kind: 'error',
            })
          )
      }
    }
    case 'DELETE_CAPPING_RULES_SUCCESS':
      return state.push(
        generateToast({
          message: 'Capping deleted',
          kind: 'success',
        })
      )
    case 'DELETE_CAPPING_RULES_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to delete capping',
          kind: 'error',
        })
      )
    case 'SEND_TEST_PUSH_SUCCESS': {
      const { totalTargeted, totalSent } = action.payload
      return state.push(
        generateToast({
          message: `Push test successfully sent to ${totalSent ?? 0} of ${pluralize(
            'targeted device',
            totalTargeted ?? 0
          )}`,
          kind: 'success',
        })
      )
    }
    case 'SEND_TEST_PUSH_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to send push',
          error: `(${action.payload.error?.errors?.[0]?.message ?? 'Unexpected error'})`,
          kind: 'error',
        })
      )

    case 'FETCH_EMAIL_SUB_DISTRIBUTION_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load email distribution analytics',
          error: action.payload,
          kind: 'error',
        })
      )
    case 'FETCH_SMS_SUB_DISTRIBUTION_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load sms distribution analytics',
          error: action.payload,
          kind: 'error',
        })
      )
    case 'FETCH_PUSH_SUB_DISTRIBUTION_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load sms distribution analytics',
          error: action.payload,
          kind: 'error',
        })
      )

    case 'FETCH_PROFILES_DISTRIBUTION_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load profiles analytics',
          error: action.payload,
          kind: 'error',
        })
      )

    case 'FETCH_PUSH_REACHABILITY_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load push reachability',
          error: action.payload,
          kind: 'error',
        })
      )

    case 'FETCH_SMS_REACHABILITY_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load SMS reachability',
          error: action.payload,
          kind: 'error',
        })
      )

    case 'FETCH_EMAIL_REACHABILITY_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to load email reachability',
          error: action.payload,
          kind: 'error',
        })
      )
    case 'UPDATE_SEGMENT_SUCCESS':
      return state.push(
        generateToast({
          message: 'Segment updated',
          kind: 'success',
        })
      )
    case 'UPDATE_SEGMENT_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to update segment',
          kind: 'error',
        })
      )
    case 'SAVE_SEGMENT_SUCCESS':
      return state.push(
        generateToast({
          message: 'Segment created',
          kind: 'success',
        })
      )
    case 'SAVE_SEGMENT_FAILURE':
      // managed in usecase
      break
    case 'DELETE_SEGMENT_SUCCESS':
      return state.push(
        generateToast({
          message: 'Segment deleted',
          kind: 'success',
        })
      )
    case 'DELETE_SEGMENT_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to delete segment',
          kind: 'error',
        })
      )
    case 'SELECT_EXPERIMENT_WINNER_FAILURE':
      return state.push(
        generateToast({
          message: 'Unable to pick winner',
          error: action.payload,
          kind: 'error',
        })
      )
  }

  return state
}

export default messageReducer
