// @flow
import Immutable from 'immutable'

import { type DeleteSegmentActionType } from '../usecases/delete-segment'
import {
  type SetPageSegmentAction,
  type FetchSegmentActionType,
} from '../usecases/fetch-segment-list'
import { type SaveSegmentActionType } from '../usecases/save-segment'
import {
  SegmentStateFactory,
  type SegmentStateRecord,
} from 'com.batch/segments/store/segments.state'

import { type GetSegmentsByNameActionType } from 'com.batch/segments/usecases/get-segments-by-name'
import { type UpdateSegmentActionType } from 'com.batch/segments/usecases/update-segment'

type SegmentAction =
  | FetchSegmentActionType
  | GetSegmentsByNameActionType
  | DeleteSegmentActionType
  | SaveSegmentActionType
  | UpdateSegmentActionType
  | SetPageSegmentAction
const initialState = SegmentStateFactory()

export function segmentsReducer(
  state: SegmentStateRecord = initialState,
  action: SegmentAction
): SegmentStateRecord {
  switch (action.type) {
    case 'SET_PAGE_SEGMENT_LIST':
      return state.set('page', action.payload)
    case 'FETCH_SEGMENT_LIST': {
      return state.set('loadingState', 'LOADING')
    }
    case 'FETCH_SEGMENT_LIST_SUCCESS': {
      return (
        state
          .set('loadingState', 'LOADED')
          .set(
            'segments',
            state.segments.merge(
              Immutable.Map(action.payload.segments.map(segment => [segment.name, segment]))
            )
          )
          .set('total', action.payload.total)
          .set('totalMatching', action.payload.totalMatching)
          .set('page', action.payload.page)
          .set('nbPerPage', action.payload.nbPerPage)
          // if trash cache is true, we want to trash segmentsPerPage to reinitialize it
          .set(
            'segmentsPerPage',
            action.payload.trashCache
              ? Immutable.Map([
                  [action.payload.page, action.payload.segments.map(segment => segment.name)],
                ])
              : state.segmentsPerPage.set(
                  action.payload.page,
                  action.payload.segments.map(segment => segment.name)
                )
          )
          .set('search', action.payload.search)
          .set('sortDirection', action.payload.sortDirection)
          .set('sortBy', action.payload.sortBy)
      )
    }
    case 'FETCH_SEGMENT_LIST_FAILURE':
      return state.set('loadingState', 'ERROR')

    case 'GET_SEGMENTS_BY_NAME_SUCCESS':
      return state.set(
        'segments',
        Immutable.Map(action.payload.map(segment => [segment.name, segment])).merge(state.segments)
      )
    case 'SAVE_SEGMENT':
      return state.set('mutationLoadingState', 'LOADING')
    case 'SAVE_SEGMENT_SUCCESS':
      return state.set('mutationLoadingState', 'LOADED')
    case 'SAVE_SEGMENT_FAILURE':
      return state.set('mutationLoadingState', 'ERROR')
    case 'UPDATE_SEGMENT':
      return state.set('mutationLoadingState', 'LOADING')
    case 'UPDATE_SEGMENT_SUCCESS':
      return state.set('mutationLoadingState', 'LOADED')
    case 'UPDATE_SEGMENT_FAILURE':
      return state.set('mutationLoadingState', 'ERROR')
    case 'DELETE_SEGMENT':
      return state.set('loadingState', 'LOADING')
    case 'DELETE_SEGMENT_SUCCESS':
      return state.set('loadingState', 'LOADED')
    case 'DELETE_SEGMENT_FAILURE':
      return state.set('loadingState', 'ERROR')
    default:
      return state
  }
}
