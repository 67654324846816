// @flow
import Immutable from 'immutable'

import { LabelStateFactory, type LabelStateRecord } from 'com.batch/labels/store/labels.state'

import { type CancelNewCappingRulesActionType } from 'com.batch/capping/usecases/cancel-new-capping-rules'
import { type DeleteCappingRulesActionType } from 'com.batch/capping/usecases/delete-capping-rules'
import { type NewCappingRulesActionType } from 'com.batch/capping/usecases/new-capping-rules'
import { type SaveCappingRulesActionType } from 'com.batch/capping/usecases/save-capping-rules'
import { type DeleteProjectLabelActionType } from 'com.batch/labels/usecases/delete-label'
import { type FetchProjectLabelsAndCappingRulesActionType } from 'com.batch/labels/usecases/fetch-labels-and-capping-rules'
import { type SaveProjectLabelActionType } from 'com.batch/labels/usecases/save-label'
import { type UpdateProjectLabelActionType } from 'com.batch/labels/usecases/update-label'

const initialState = LabelStateFactory()

export function labelsReducer(
  state: LabelStateRecord = initialState,
  action:
    | FetchProjectLabelsAndCappingRulesActionType
    | SaveProjectLabelActionType
    | DeleteProjectLabelActionType
    | UpdateProjectLabelActionType
    | SaveCappingRulesActionType
    | DeleteCappingRulesActionType
    | NewCappingRulesActionType
    | CancelNewCappingRulesActionType
): LabelStateRecord {
  switch (action.type) {
    case 'SAVE_PROJECT_LABEL':
      return state
    case 'SAVE_PROJECT_LABEL_SUCCESS':
      return state.set('labels', state.labels.push(action.payload))
    case 'SAVE_PROJECT_LABEL_FAILURE':
      return state.set(
        'labels',
        state.labels.filter(label => label.code !== action.payload)
      )
    case 'UPDATE_PROJECT_LABEL':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload.code ? label.set('isMutating', true) : label
        )
      )
    case 'UPDATE_PROJECT_LABEL_SUCCESS': {
      const code = action.payload.code
      return state.set(
        'labels',
        state.labels.map(label => (label.code === code ? action.payload : label))
      )
    }
    case 'UPDATE_PROJECT_LABEL_FAILURE':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload.code ? label.set('isMutating', false) : label
        )
      )
    case 'DELETE_PROJECT_LABEL_FAILURE':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload ? label.set('isMutating', false) : label
        )
      )
    case 'DELETE_PROJECT_LABEL':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload ? label.set('isMutating', true) : label
        )
      )
    case 'DELETE_PROJECT_LABEL_SUCCESS':
      return state.set(
        'labels',
        state.labels.filter(label => label.code !== action.payload)
      )
    case 'FETCH_PROJECT_LABELS_AND_CAPPING_RULES':
      return state.set('loadingState', 'LOADING')
    case 'FETCH_PROJECT_LABELS_AND_CAPPING_RULES_SUCCESS':
      return state.set('loadingState', 'LOADED').set('labels', action.payload)
    case 'FETCH_PROJECT_LABELS_AND_CAPPING_RULES_FAILURE':
      return state.set('loadingState', 'ERROR')
    case 'SAVE_CAPPING_RULES':
      return state
    case 'SAVE_CAPPING_RULES_FAILURE':
      return state
    case 'SAVE_CAPPING_RULES_SUCCESS': {
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload.first().labelCode
            ? label.set(
                'cappingRules',
                action.payload.map(cappingRule => cappingRule.set('isPersisted', true))
              )
            : label
        )
      )
    }
    case 'DELETE_CAPPING_RULES':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload ? label.set('isMutating', true) : label
        )
      )
    case 'DELETE_CAPPING_RULES_FAILURE':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload ? label.set('isMutating', false) : label
        )
      )
    case 'DELETE_CAPPING_RULES_SUCCESS':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload ? label.set('cappingRules', new Immutable.List()) : label
        )
      )
    case 'NEW_CAPPING_RULES':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.code === action.payload.first().labelCode
            ? label.set('cappingRules', action.payload)
            : label
        )
      )
    case 'CANCEL_NEW_CAPPING_RULES':
      return state.set(
        'labels',
        state.labels.map(label =>
          label.cappingRules.some(cappingRule => !cappingRule.isPersisted)
            ? label.set('cappingRules', new Immutable.List())
            : label
        )
      )
    default:
      return state
  }
}
