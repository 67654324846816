import { combineReducers } from 'redux'

import { ChannelsDistributionReducer } from 'com.batch/profilebase/store/channels-distribution.reducer'
import { ChannelsReachabilityReducer } from 'com.batch/profilebase/store/channels-reachability.reducer'
import { profilesDistributionReducer } from 'com.batch/profilebase/store/profiles-distribution.reducer'

export const profilebaseReducer = combineReducers({
  profilesDistribution: profilesDistributionReducer,
  channelsDistribution: ChannelsDistributionReducer,
  channelsReachability: ChannelsReachabilityReducer,
})
