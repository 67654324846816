// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Condition from 'components/campaign/review/review/conditions/rsr-condition'
import Logical from 'components/campaign/review/review/conditions/rsr-logical'
import { CustomAudience } from 'components/campaign/review/review/custom-audience'
import Highlight from 'components/common/highlight'
import Loader from 'components/common/loader-legacy'
import ReviewItem from 'components/targeting/review-item'

import { type State, CampaignFactory } from 'com.batch.redux/_records'
import { fetchCampaign } from 'com.batch.redux/campaign.action'
import { fullTreeAsRecordSelector } from 'com.batch.redux/targeting'
import {
  reviewLanguagesSelector,
  reviewRegionsSelector,
  clustersTargeting,
  customAudiencesSelector,
} from 'com.batch.redux/targeting.selector.composed'

const targetingSelector = (state: State) => state.targeting
const campaignsSelector = (state: State) => state.campaign.entities

export const ReviewTargeting = (): React.Node => {
  // redux
  const tree = useSelector(fullTreeAsRecordSelector)
  const clusters = useSelector(clustersTargeting)
  const targeting = useSelector(targetingSelector)
  const loading = targeting.get('awaitingParse', false)
  const campaigns = useSelector(campaignsSelector)
  const languages = useSelector(reviewLanguagesSelector)
  const regions = useSelector(reviewRegionsSelector)
  const pickedAudiences = useSelector(customAudiencesSelector)
  const queryError = targeting.get('queryError', '')
  const query = targeting.get('query') ? JSON.stringify(targeting.get('query'), null, 2) : ''
  const dispatch = useDispatch()

  const getCampaignName = React.useCallback(
    (token: string): string => {
      if (!token) {
        return ''
      }
      const campaign = campaigns.get(token, CampaignFactory())
      if (campaign._id === 'new' && !campaign.loading) {
        dispatch(
          fetchCampaign({
            token,
            withMacro: false,
            withEstimate: false,
            redirectOnFail: false,
            usedByRetargeting: true,
          })
        )
      }
      return campaign.name !== '' ? campaign.name : token
    },
    [campaigns, dispatch]
  )

  // $FlowFixMe flow sait pas refine le type sur les records
  const condition: ?QbConditionRecord = tree && !tree.isLogical ? tree : null
  // $FlowFixMe flow sait pas refine le type sur les records
  const logical: ?QbLogicalRecord = tree && tree.isLogical ? tree : null

  return (
    <div className="prr__item">
      <ReviewItem style={{ borderTop: 'none' }}>It will be sent to users who:</ReviewItem>
      <ReviewItem kind="Are">
        <div>
          {clusters
            .filter(c => c.get('active'))
            .map(cluster => {
              const className = `targeting targeting--cluster targeting--cluster--${cluster.get(
                'code',
                ''
              )}`
              return (
                <span className={className} key={cluster.get('code')}>
                  {cluster.get('name')}
                </span>
              )
            })}
        </div>
      </ReviewItem>
      <ReviewItem kind={regions.invert ? 'Does not live in' : 'Live in'}>
        <div>
          {regions.data.size === 0
            ? 'any country'
            : regions.data.map(region => {
                return (
                  <span className="targeting" key={region.get('value')}>
                    <img
                      src={`/medias/img/flags/${region.get('value').toLowerCase()}.png`}
                      alt={`Flag of ${region.get('label')}`}
                    />
                    &nbsp;{region.get('label')}
                  </span>
                )
              })}
        </div>
      </ReviewItem>
      <ReviewItem kind={languages.invert ? 'Does not speak' : 'Speak'}>
        <div>
          {languages.data.size === 0
            ? 'any language'
            : languages.data.map(lang => {
                return (
                  <span className="targeting" key={lang.get('value')}>
                    {lang.get('label')}
                  </span>
                )
              })}
        </div>
      </ReviewItem>
      {pickedAudiences.size > 0 && (
        <ReviewItem kind="Custom audiences">
          <div>
            {pickedAudiences.map(audience => {
              return (
                <CustomAudience
                  key={audience.id}
                  audience={audience}
                  last={pickedAudiences.last() === audience}
                />
              )
            })}
          </div>
        </ReviewItem>
      )}
      {((logical && logical.children.size > 0) || condition) && !loading && (
        <div style={{ borderTop: '1px solid #EFF0F4' }}>
          {queryError && query ? (
            <Highlight language="js">{query}</Highlight>
          ) : (
            <React.Fragment>
              {logical && <Logical logical={logical} root getCampaignName={getCampaignName} />}
              {condition && (
                <Condition
                  condition={condition}
                  root
                  getCampaignName={getCampaignName}
                  last={true}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}
      {loading && query && (
        <ReviewItem Kind="Match">
          <Loader loading={true} overlay>
            <Highlight language="js">{query}</Highlight>
          </Loader>
        </ReviewItem>
      )}
    </div>
  )
}
