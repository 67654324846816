// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { textAction, textLight, textNeutral } from 'components/styled/tokens/colors'
import { base, label } from 'components/styled/tokens/texts'

export const ClickPerUrlRow: StyledComponent<*, *, *> = styled.div`
  height: 64px;
  padding: 13px 0 13px 0;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 88px 112px;
  column-gap: 32px;
  align-items: center;
  align-content: center;
`

export const ClickPerUrlTitle: StyledComponent<*, *, *> = styled.a`
  grid-row: 1;
  text-overflow: ellipsis;
  color: ${textAction};
  font: ${label.font};
  overflow: hidden;
  white-space: nowrap;
`
export const ClickPerUrlSubtitle: StyledComponent<*, *, *> = styled.div`
  grid-row: 2;
  font: ${base.font};
  text-overflow: ellipsis;
  color: ${textLight};
  overflow: hidden;
  white-space: nowrap;
  align-self: start;
`

const valuesSharedStyle = css`
  font-size: 20px;

  &:before {
    font: ${base.font};
    color: ${textLight};
  }
`

export const ClickPerUrlUniqueClick: StyledComponent<*, *, *> = styled.div`
  ${valuesSharedStyle};
  grid-column: 2;
  &:before {
    content: 'Unique clicks';
    display: block;
  }
`
export const ClickPerUrlClickDistribution: StyledComponent<*, *, *> = styled.div`
  ${valuesSharedStyle};
  grid-column: 3;
  &:before {
    content: 'Click distribution';
    display: block;
  }
  &:after {
    content: '%';
    font: ${base.font};
    color: ${textNeutral};
  }
`
