// @flow

import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import thunk from 'redux-thunk'

import ErrorCatcher from 'components/common/error-catcher'

import { ConsoleRouter } from './console-router'
import { GlobalStyle } from './console.style'
import { appReducer } from './redux/app'
import { codeReducer } from './redux/codes'
import { companyReducer } from './redux/company'
import {
  loadUsersEpic,
  loadCompanyEpic,
  loadAppsEpic,
  loadProjectsEpic,
} from './redux/console.epic'
import { exportReducer } from './redux/export'
import { projectReducer } from './redux/project'
import { userReducer } from './redux/user'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const rootEpic = combineEpics(loadUsersEpic, loadCompanyEpic, loadAppsEpic, loadProjectsEpic)
const epicMiddleware = createEpicMiddleware()
const middlewares = [thunk, epicMiddleware]
const store = createStore(
  combineReducers({
    user: userReducer,
    company: companyReducer,
    app: appReducer,
    export: exportReducer,
    project: projectReducer,
    code: codeReducer,
  }),
  composeEnhancers(applyMiddleware(...middlewares))
)
epicMiddleware.run(rootEpic)
const AppRaw = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <ErrorCatcher>
        <Provider store={store}>
          <BrowserRouter>
            <ConsoleRouter />
          </BrowserRouter>
        </Provider>
      </ErrorCatcher>
    </React.Fragment>
  )
}

const App = AppRaw

export default (App: React.ComponentType<{ ... }>)
