// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type List } from 'immutable'
import URLSearchParams from 'url-search-params'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type State, type DispatchBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { legacyPromiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentAppSelector } from 'com.batch.redux/app'
import * as apiAnalytics from 'com.batch.redux/stat.api.analytics'
import * as apiDebug from 'com.batch.redux/stat.api.debug'
import {
  type StatStateRecord,
  type AnalyticByRegionRecord,
  type AnalyticByPeriodRecord,
  type ReachByDayRecord,
  type DebugInstallRecord,
  StatStateFactory,
  DebugUserFactory,
  AnalyticsConfigFactory,
  // DebugTransacFactory
} from 'com.batch.redux/stat.records'

import { STATUS } from 'constants/common'

// set range from url if avail
const queryString = new URLSearchParams(window.location.search)
const qFrom = queryString.get('from')
const initFrom = dayjs.utc(qFrom, 'YYYYMMDD')
const qTo = queryString.get('to')
const initTo = dayjs.utc(qTo, 'YYYYMMDD').endOf('day')
const initialState = StatStateFactory(
  qFrom && initFrom.isValid() && qTo && initTo.isValid()
    ? {
        config: AnalyticsConfigFactory({
          from: initFrom,
          to: initTo,
        }),
      }
    : {}
)

// ====================== ACTIONS TYPE
type setAnalyticsActiveTabAction = {
  type: 'SET_ANALYTIC_ACTIVE_TAB',
  payload: 'analytics' | 'push',
  ...
}

type toggleSecretAction = {
  type: 'TOGGLE_ANALYTICS_SECRET',
  payload: null,
  ...
}

type setAnalyticsRangeAction = {
  type: 'SET_ANALYTIC_RANGE',
  payload: {
    from: Dayjs,
    to: Dayjs,
    ...
  },
  ...
}

type fetchRecentDevicesAction = {
  type: 'FETCH_RECENT_DEVICES',
  payload: null,
  ...
}
type fetchRecentDevicesFailureAction = {
  type: 'FETCH_RECENT_DEVICES_FAILURE',
  payload: {
    error: string,
    ...
  },
  ...
}
type fetchRecentDevicesSuccesAction = {
  type: 'FETCH_RECENT_DEVICES_SUCCESS',
  payload: { customIds: Array<string>, installationIds: Array<string>, ... },
  ...
}

type fetchAnalyticsByDayAction = {
  type: 'FETCH_ANALYTIC_BY_DATE',
  payload: null,
  ...
}
export type fetchAnalyticsByDayFailureAction = {
  type: 'FETCH_ANALYTIC_BY_DATE_FAILURE',
  payload: {
    error: string,
    ...
  },
  ...
}
type fetchAnalyticsByDaySuccesAction = {
  type: 'FETCH_ANALYTIC_BY_DATE_SUCCESS',
  payload: List<AnalyticByPeriodRecord>,
  ...
}

type fetchReachAction = {
  type: 'FETCH_REACH',
  payload: null,
  ...
}
export type fetchReachFailureAction = {
  type: 'FETCH_REACH_FAILURE',
  payload: {
    error: string,
    ...
  },
  ...
}
type fetchReachSuccesAction = {
  type: 'FETCH_REACH_SUCCESS',
  payload: List<ReachByDayRecord>,
  ...
}

type fetchAnalyticsByRegionAction = {
  type: 'FETCH_ANALYTIC_BY_REGION',
  payload: null,
  ...
}
export type fetchAnalyticsByRegionFailureAction = {
  type: 'FETCH_ANALYTIC_BY_REGION_FAILURE',
  payload: {
    error: string,
    ...
  },
  ...
}
type fetchAnalyticsByRegionSuccesAction = {
  type: 'FETCH_ANALYTIC_BY_REGION_SUCCESS',
  payload: List<AnalyticByRegionRecord>,
  ...
}

type debugReset = {
  type: 'DEBUG_RESET',
  payload: null,
  ...
}

type lookForUserAction = {
  type: 'DEBUG_USER',
  meta: {
    refresh: boolean,
    ...
  },
  payload: {
    mode: 'advertising_id' | 'custom_id' | 'installation_id',
    query: 'string',
    ...
  },
  ...
}
type lookForUserSuccessAction = {
  type: 'DEBUG_USER_SUCCESS',
  meta: {
    refresh: boolean,
    query: string,
    ...
  },
  payload: List<DebugInstallRecord>,
  ...
}
export type lookForUserFailureAction = {
  type: 'DEBUG_USER_FAILURE',
  meta: {
    refresh: boolean,
    query: string,
    ...
  },
  payload: string,
  ...
}

type AnalyticsActions =
  | toggleSecretAction
  | setAnalyticsActiveTabAction
  | fetchAnalyticsByDayAction
  | fetchAnalyticsByDayFailureAction
  | fetchAnalyticsByDaySuccesAction
  | fetchReachAction
  | fetchReachFailureAction
  | fetchReachSuccesAction
  | fetchAnalyticsByRegionAction
  | fetchAnalyticsByRegionFailureAction
  | fetchAnalyticsByRegionSuccesAction
  | setAnalyticsRangeAction
  | debugReset
  | lookForUserAction
  | lookForUserSuccessAction
  | lookForUserFailureAction
  | fetchRecentDevicesAction
  | fetchRecentDevicesSuccesAction
  | fetchRecentDevicesFailureAction

// ====================== ACTIONS

export const fetchRecentDevices =
  (): DispatchBoundFn<
    Promise<
      ReduxAction<
        'FETCH_RECENT_DEVICES',
        { customIds: Array<string>, installationId: Array<string>, ... },
      >,
    >,
  > =>
  (dispatch, getState) => {
    const state: State = getState()
    const app = currentAppSelector(state)
    return legacyPromiseActionCreator({
      dispatch,
      payload: null,
      promise: apiDebug.recentDevices({
        app,
      }),
      actionName: 'FETCH_RECENT_DEVICES',
    })
  }

export const toggleSecret = (): toggleSecretAction => {
  return {
    type: 'TOGGLE_ANALYTICS_SECRET',
    payload: null,
  }
}
export const resetDebug = (): ReduxAction<'DEBUG_RESET', null> => {
  return { type: 'DEBUG_RESET', payload: null }
}
export const setAnalyticsTab =
  (payload: 'analytics' | 'push' | 'reach'): DispatchBoundFn<void> =>
  (dispatch, getState) => {
    const state: State = getState()
    const { from, to } = state.stat.config
    if (state.stat.config.activeTab !== payload)
      dispatch({
        type: 'SET_ANALYTIC_ACTIVE_TAB',
        payload,
      })
    if (payload === 'analytics') {
      if (
        state.stat.config.loadingOverview !== STATUS.LOADING &&
        state.stat.analyticsByDate.size === 0
      )
        dispatch(fetchAnalyticsByDay({ from, to, dimension: 'none', devMode: false }))
      if (
        state.stat.config.loadingRegion !== STATUS.LOADING &&
        state.stat.analyticsByRegion.size === 0
      )
        dispatch(fetchAnalyticsByRegion({ from, to, dimension: 'none', devMode: false }))
    }

    if (payload === 'reach') {
      if (state.stat.config.loadingReach !== STATUS.LOADING && state.stat.reach.size === 0)
        dispatch(fetchReach({ from, to }))
    }
  }

export const setAnalyticsRange =
  ({ from, to }: { +from: Dayjs, +to: Dayjs, ... }): DispatchBoundFn<any> =>
  (dispatch, getState) => {
    const state: State = getState()
    if (state.stat.config.from.isSame(from, 'hour') && state.stat.config.to.isSame(to, 'hour')) {
      return
    }
    dispatch({
      type: 'SET_ANALYTIC_RANGE',
      payload: {
        from,
        to,
      },
    })
    if (state.stat.config.activeTab === 'analytics') {
      dispatch(fetchAnalyticsByDay({ from, to, dimension: 'none', devMode: false }))
      dispatch(fetchAnalyticsByRegion({ from, to, dimension: 'none', devMode: false }))
    }

    if (state.stat.config.activeTab === 'reach') {
      dispatch(fetchReach({ from, to }))
    }
  }

export const fetchAnalyticsByDay =
  ({
    devMode = false,
    dimension = 'none',
    from,
    to,
    doubleRange = true,
  }: {
    devMode: boolean,
    dimension: 'none' | 'events',
    from: Dayjs,
    to: Dayjs,
    doubleRange?: boolean,
    ...
  }): DispatchBoundFn<Promise<any>> =>
  (dispatch, getState) => {
    const state: State = getState()
    const app = currentAppSelector(state)
    const start = from.hour(0)
    const end = to.hour(23).minute(59)
    const diff = end.unix() - start.unix()
    // if we got a small range, we switch to hourly granularity
    const granularity = diff <= 80 * 3600 ? 'hour' : 'day'

    return legacyPromiseActionCreator({
      dispatch,
      payload: null,
      promise: apiAnalytics.fetchAnalyticsByDay({
        app,
        start: doubleRange ? start.subtract(diff, 'second') : start, // start is set so we double the range to compute progression
        end,
        granularity,
        devMode,
        dimension,
      }),
      actionName: 'FETCH_ANALYTIC_BY_DATE',
    })
  }

export const fetchReach =
  ({ from, to }: { from: Dayjs, to: Dayjs, ... }): DispatchBoundFn<Promise<any>> =>
  (dispatch, getState) => {
    const state: State = getState()
    const app = currentAppSelector(state)
    return legacyPromiseActionCreator({
      dispatch,
      payload: null,
      promise: apiAnalytics.fetchReach({ app, start: from, end: to }),
      actionName: 'FETCH_REACH',
    })
  }

export const fetchAnalyticsByRegion =
  ({
    devMode = false,
    from,
    to,
  }: {
    devMode: boolean,
    from: Dayjs,
    to: Dayjs,
    ...
  }): DispatchBoundFn<Promise<any>> =>
  (dispatch, getState) => {
    const state: State = getState()
    const app = currentAppSelector(state)
    return legacyPromiseActionCreator({
      dispatch,
      payload: null,
      promise: apiAnalytics.fetchAnalyticsByRegion({ app, start: from, end: to, devMode }),
      actionName: 'FETCH_ANALYTIC_BY_REGION',
    })
  }

export const queryUser = ({
  appId,
  mode,
  query,
  refresh = false,
}: {
  appId: number,
  mode: 'advertising_id' | 'custom_id' | 'installation_id',
  query: string,
  refresh?: boolean,
  ...
}): DispatchBoundFn<Promise<any>> => {
  const kind = mode === 'advertising_id' ? 'aid' : mode === 'installation_id' ? 'di' : 'cid'
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { mode, query },
      meta: { refresh, mode, query },
      promise: apiDebug.lookForUser(appId, query, kind),
      actionName: 'DEBUG_USER',
    })
}

// ====================== REDUCER
export default function statReducer(
  state: StatStateRecord = initialState,
  action: AnalyticsActions
): StatStateRecord {
  switch (action.type) {
    case 'FETCH_RECENT_DEVICES_SUCCESS':
      return state.set(
        'userDebug',
        state.userDebug
          .set('recentCustomIds', action.payload.customIds)
          .set('recentInstallationIds', action.payload.installationIds)
      )
    case 'TOGGLE_ANALYTICS_SECRET':
      return state.set('config', state.config.set('secret', !state.config.secret))
    case 'SET_ANALYTIC_ACTIVE_TAB':
      return state.set('config', state.config.set('activeTab', action.payload))
    case 'SET_ANALYTIC_RANGE':
      return state
        .set('analyticsByDate', new Immutable.List())
        .set('analyticsByRegion', new Immutable.List())
        .set(
          'config',
          state.config
            .set('from', action.payload.from.hour(0).minute(0).second(0))
            .set('to', action.payload.to.hour(23).minute(59).second(59))
        )

    case 'FETCH_REACH':
      return state.set('config', state.config.set('loadingReach', STATUS.LOADING))
    case 'FETCH_REACH_FAILURE':
      return state.set('config', state.config.set('loadingReach', STATUS.ERROR))
    case 'FETCH_ANALYTIC_BY_DATE':
      return state.set('config', state.config.set('loadingOverview', STATUS.LOADING))
    case 'FETCH_ANALYTIC_BY_REGION':
      return state.set('config', state.config.set('loadingRegion', STATUS.LOADING))
    case 'FETCH_ANALYTIC_BY_DATE_FAILURE':
      return state.set('config', state.config.set('loadingOverview', STATUS.ERROR))
    case 'FETCH_ANALYTIC_BY_REGION_FAILURE':
      return state.set('config', state.config.set('loadingRegion', STATUS.ERROR))
    case 'FETCH_ANALYTIC_BY_DATE_SUCCESS':
      return state
        .set('config', state.config.set('loadingOverview', STATUS.LOADED))
        .set('analyticsByDate', action.payload)
    case 'FETCH_REACH_SUCCESS':
      return state
        .set('config', state.config.set('loadingReach', STATUS.LOADED))
        .set('reach', action.payload)
    case 'FETCH_ANALYTIC_BY_REGION_SUCCESS':
      return state
        .set('config', state.config.set('loadingRegion', STATUS.LOADED))
        .set('analyticsByRegion', action.payload)

    case 'DEBUG_USER': {
      const requestId = action.payload.query
      if (action.meta.refresh) {
        const position = state.userDebug.results.findIndex(
          install => install.installationId === requestId
        )
        if (position === -1) {
          return state
        }
        return state.setIn(['userDebug', 'results', position, 'loading'], true)
      } else {
        const userDebug = state.userDebug
          .set('loading', true)
          .set('error', false)
          .set('mode', action.payload.mode)
          .set('query', action.payload.query)
        return state.set('userDebug', userDebug)
      }
    }
    case 'DEBUG_USER_FAILURE': {
      const requestId = action.meta.query
      if (action.meta.refresh) {
        const position = state.userDebug.results.findIndex(
          install => install.installationId === requestId
        )
        if (position === -1) {
          return state
        }
        return state.setIn(['userDebug', 'results', position, 'loading'], false)
      } else {
        const userDebug = state.userDebug.set('error', action.payload).set('loading', false)
        return state.set('userDebug', userDebug)
      }
    }
    case 'DEBUG_USER_SUCCESS': {
      const payload = action.payload
      const requestId = action.meta.query
      const first = payload.first()
      if (action.meta.refresh) {
        const position = state.userDebug.results.findIndex(
          install => install.installationId === requestId
        )
        if (position === -1) {
          return state
        }
        if (!first) return state
        const du = state.userDebug.set('results', state.userDebug.results.set(position, first))
        return state.set('userDebug', du)
      } else {
        const deb = state.userDebug.set('loading', false).set('results', payload)
        return state.set('userDebug', deb)
      }
    }

    case 'DEBUG_RESET':
      return state.set('userDebug', DebugUserFactory())

    default:
      return state
  }
}
