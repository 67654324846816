// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'

import { useCurrentCompanyHasOneFeatureAmongst } from 'components/_hooks'
import { useSelectedApp } from 'components/app/use-selected-app'
import { ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { SubNavSeparator } from 'components/navigation/sub-nav.styles'
import { useProjectChannels } from 'components/project/use-project-channels'

import { generateUrl, generateProfilesUrl } from 'com.batch.common/router'

import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { currentCompanySelector } from 'com.batch.redux/company'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export const DataMenu = (): React.Node => {
  const { hasOneCepChannelConfigured } = useProjectChannels()
  const app = useSelectedApp()
  const apps = useSelector(currentProjectAppsSelector)
  const company = useSelector(currentCompanySelector)
  const currentProject = useSelector(currentProjectSelector)
  const hasPushChannel = React.useMemo(() => {
    return apps.some(app => ['webpush', 'ios', 'android'].includes(app.platform))
  }, [apps])

  const companyHasOneLegacyForm = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-campaign',
    'cep-show-legacy-campaign-omni',
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
    'inapp-campaigns',
  ])

  const matchProfiles = useMatch(
    '/:companyId/projects/:projectId/apps/:appId/profiles/:activeTab/*'
  )
  const matchProjectProfiles = useMatch('/:companyId/projects/:projectId/profiles/:activeTab/*')

  const activeTab = React.useMemo(
    () => matchProfiles?.params?.activeTab ?? matchProjectProfiles?.params?.activeTab ?? '',
    [matchProjectProfiles?.params?.activeTab, matchProfiles?.params?.activeTab]
  )

  return (
    <React.Fragment>
      {hasPushChannel && (
        <ButtonLink
          hardReload
          href={generateUrl('project_profiles_app', {
            companyId: company.id,
            projectId: currentProject.id,
            appId: app.id,
          })}
          isActive={activeTab === ''}
          className={activeTab === '' ? 'active' : ''}
          data-testid="subnav-overview"
          style={{ marginRight: 12 }}
        >
          <span className="styled-button-text">Overview</span>
        </ButtonLink>
      )}
      {hasOneCepChannelConfigured && (
        <ButtonLink
          hardReload
          isActive={activeTab === 'segments'}
          className={activeTab === 'segments' ? 'active' : ''}
          href={generateProfilesUrl({
            companyId: company.id,
            projectId: currentProject.id,
            activeTab: 'segments',
          })}
          addOn="prefix"
          addOnGap={8}
          data-testid="subnav-segments"
          style={{ marginRight: 12 }}
        >
          <Icon icon="segments" />
          <span className="styled-button-text">Segments</span>
        </ButtonLink>
      )}
      <ButtonLink
        hardReload
        isActive={activeTab === 'audiences'}
        className={activeTab === 'audiences' ? 'active' : ''}
        href={generateProfilesUrl({
          companyId: company.id,
          projectId: currentProject.id,
          activeTab: 'audiences',
          ...(hasOneCepChannelConfigured ? {} : { appId: app.id }),
        })}
        addOn="prefix"
        addOnGap={8}
        data-testid="subnav-audiences"
        style={{ marginRight: 12 }}
      >
        <Icon icon="audiences" />
        <span className="styled-button-text">Audiences</span>
      </ButtonLink>
      {companyHasOneLegacyForm && (
        <ButtonLink
          hardReload
          isActive={activeTab === 'custom-data'}
          className={activeTab === 'custom-data' ? 'active' : ''}
          href={generateProfilesUrl({
            companyId: company.id,
            appId: app.id,
            projectId: currentProject.id,
            activeTab: 'custom-data',
          })}
          addOn="prefix"
          addOnGap={8}
          data-testid="subnav-custom-data"
          style={{ marginRight: 12 }}
        >
          <Icon icon="table" />
          <span className="styled-button-text">Custom data</span>
        </ButtonLink>
      )}
      <ButtonLink
        hardReload
        isActive={activeTab === 'privacy'}
        className={activeTab === 'privacy' ? 'active' : ''}
        href={generateProfilesUrl({
          companyId: company.id,
          appId: app.id,
          projectId: currentProject.id,
          activeTab: 'privacy',
        })}
        addOn="prefix"
        addOnGap={8}
        data-testid="subnav-privacy"
        style={{ marginRight: 12 }}
      >
        <Icon icon="privacy" />
        <span className="styled-button-text">Privacy center</span>
      </ButtonLink>
      {hasOneCepChannelConfigured && (
        <React.Fragment>
          <SubNavSeparator />
          <ButtonLink
            hardReload
            isActive={activeTab === 'find'}
            className={activeTab === 'find' ? 'active' : ''}
            href={generateProfilesUrl({
              companyId: company.id,
              projectId: currentProject.id,
              activeTab: 'find',
            })}
            addOn="prefix"
            addOnGap={8}
            data-testid="subnav-find"
            style={{ marginRight: 12 }}
          >
            <Icon icon="search" />
            <span className="styled-button-text">Search Profiles</span>
          </ButtonLink>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
