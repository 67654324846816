// @flow

import Immutable, { type List, type OrderedSet } from 'immutable'
import { createSelector } from 'reselect'
import request from 'superagent-interface-promise'

import { generateUrl } from 'com.batch.common/router'

import { CompanyStateFactory, type CompanyStateRecord, type State } from './console.records'

import {
  type SAMLRecord,
  CompanyFactory,
  type CompanyRecord,
  type DispatchOnlyBoundFn,
  type ReduxAction,
} from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { type BillingRecord } from 'com.batch.redux/billing.records'
import { normalizeCompany } from 'com.batch.redux/company.api'

type CreateCompanyAction = {
  type: 'CREATE_COMPANY',
  payload: null,
  ...
}
type CreateCompanySuccessAction = {
  type: 'CREATE_COMPANY_SUCCESS',
  payload: CompanyRecord,
  ...
}
type CreateCompanyFailureAction = {
  type: 'CREATE_COMPANY_FAILURE',
  payload: null,
  ...
}
export const createCompany =
  (name: string): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch => {
    return promiseActionCreator({
      actionName: 'CREATE_COMPANY',
      dispatch,
      promise: request.post(generateUrl('console_api_company_persist'), { name }).then(
        ({ body }) => {
          return normalizeCompany(body)
        },
        err => {
          alert(
            'There was an error during company creation. Please open the console logs & notify @cedric'
          )
          console.log(err)
        }
      ),
      payload: null,
    })
  }

type LoadCompaniesAction = {
  type: 'LOAD_COMPANIES',
  payload: null,
  ...
}
type LoadCompaniesSuccessAction = {
  type: 'LOAD_COMPANIES_SUCCESS',
  payload: { entities: List<CompanyRecord>, ... },
  ...
}
type LoadCompaniesFailureAction = {
  type: 'LOAD_COMPANIES_FAILURE',
  payload: string | null,
  // payload: null
  ...
}
type LoadCompaniesListSuccessAction = {
  type: 'LOAD_COMPANIES_LIST_SUCCESS',
  payload: { count: number, entities: List<CompanyRecord>, nbPerPage: number, page: number, ... },
  ...
}
type LoadCompaniesListFailureAction = {
  type: 'LOAD_COMPANIES_LIST_FAILURE',
  payload: null,
  ...
}

type FetchCompanyAction = {
  type: 'FETCH_COMPANY',
  payload: null,
  ...
}
type FetchCompanySuccessAction = {
  type: 'FETCH_COMPANY_SUCCESS',
  payload: CompanyRecord,
  ...
}
type FetchCompanyFailureAction = {
  type: 'FETCH_COMPANY_FAILURE',
  payload: null,
  ...
}

type SetSalesForceIdAction = {
  type: 'SET_SALESFORCE_ID',
  payload: CompanyRecord,
  ...
}
type SetSalesForceIdSuccessAction = {
  type: 'SET_SALESFORCE_ID_SUCCESS',
  payload: CompanyRecord,
  ...
}
type SetSalesForceIdFailureAction = {
  type: 'SET_SALESFORCE_ID_FAILURE',
  payload: CompanyRecord,
  ...
}
export const setSalesForceId =
  (company: CompanyRecord): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'SET_SALESFORCE_ID',
      dispatch,
      promise: request
        .put(generateUrl('console_api_company_update_salesforce', { companyId: company.id }), {
          salesforceId: company.sfid,
        })
        .then(
          ({ body }) => normalizeCompany(body),
          err => {
            console.warn(err)
            return company
          }
        ),
      payload: company,
    })

export const fetchCompany =
  (id: number): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'FETCH_COMPANY',
      dispatch,
      promise: request
        .get(generateUrl('console_api_company', { companyId: id }))
        .then(({ body }) => normalizeCompany(body)),
      payload: null,
    })

export const fetchCompanies =
  (ids: Array<number>, count?: number): DispatchOnlyBoundFn<Promise<any>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'LOAD_COMPANIES',
      dispatch,
      promise: request
        .get(
          generateUrl('console_api_companies', { count: count ?? 10, companyIds: ids.join(',') })
        )
        .then(({ body }) => {
          return {
            ...body,
            entities: new Immutable.List().push(...body.entities.map(normalizeCompany)),
          }
        }),
      payload: null,
    })

export const loadCompanies = (): LoadCompaniesAction => {
  return { type: 'LOAD_COMPANIES', payload: null }
}
export const loadCompaniesSuccess = ({
  entities,
}: {
  entities: List<CompanyRecord>,
  ...
}): ReduxAction<'LOAD_COMPANIES_SUCCESS', { entities: List<CompanyRecord>, ... }> => {
  return { type: 'LOAD_COMPANIES_SUCCESS', payload: { entities } }
}
export const loadCompaniesFailure = (
  error: string
): ReduxAction<'LOAD_COMPANIES_FAILURE', string> => {
  return { type: 'LOAD_COMPANIES_FAILURE', payload: error }
}

export const loadCompaniesListSuccess = ({
  count,
  entities,
  nbPerPage,
  page,
}: {
  count: number,
  entities: List<CompanyRecord>,
  nbPerPage: number,
  page: number,
  ...
}): ReduxAction<
  'LOAD_COMPANIES_LIST_SUCCESS',
  {
    count: number,
    entities: List<CompanyRecord>,
    nbPerPage: number,
    page: number,
    ...
  },
> => {
  return { type: 'LOAD_COMPANIES_LIST_SUCCESS', payload: { count, entities, nbPerPage, page } }
}
export const loadCompaniesListFailure = (
  error: string
): ReduxAction<'LOAD_COMPANIES_LIST_FAILURE', string> => {
  return { type: 'LOAD_COMPANIES_LIST_FAILURE', payload: error }
}

type UpdateCompaniesPageAction = {
  type: 'UPDATE_COMPANIES_PAGE',
  payload: number,
  ...
}
export const setPage = (page: number): UpdateCompaniesPageAction => ({
  type: 'UPDATE_COMPANIES_PAGE',
  payload: page,
})

type UpdateFilterAction = {
  type: 'UPDATE_COMPANIES_FILTER',
  payload: string,
  ...
}

export const setFilter = (filter: string): UpdateFilterAction => ({
  type: 'UPDATE_COMPANIES_FILTER',
  payload: filter,
})

type ToggleGdprExternalFlagAction = {
  type: 'TOGGLE_GDRP_EXTERNAL',
  payload: null,
  ...
}
type ToggleGdprExternalFlagSuccessAction = {
  type: 'TOGGLE_GDRP_EXTERNAL_SUCCESS',
  payload: CompanyRecord,
  ...
}
type ToggleGdprExternalFlagFailureAction = {
  type: 'TOGGLE_GDRP_EXTERNAL_FAILURE',
  payload: null,
  ...
}
export const toggleGdprExternalFlag =
  (company: CompanyRecord): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'TOGGLE_GDRP_EXTERNAL',
      dispatch,
      promise: request
        .post(generateUrl('console_api_company_toggle_gdpr', { companyId: company.id }))
        .then(({ body }) => normalizeCompany(body)),
      payload: null,
    })

type UpdatePlanAction = {
  type: 'UPDATE_PLAN',
  payload: null,
  ...
}
type UpdatePlanSuccessAction = {
  type: 'UPDATE_PLAN_SUCCESS',
  payload: CompanyRecord,
  ...
}
type UpdatePlanFailureAction = {
  type: 'UPDATE_PLAN_FAILURE',
  payload: { company: CompanyRecord, billing: BillingRecord, error: string, ... },
  ...
}
export const updatePlan =
  ({
    company,
    billing,
  }: {
    company: CompanyRecord,
    billing: BillingRecord,
    ...
  }): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'UPDATE_PLAN',
      dispatch,
      promise: request
        .post(generateUrl('console_api_company_update_plan', { companyId: company.id }), {
          overcharge: billing.overcharge,
          plan: billing.plan.code,
          trial: !billing.trial ? null : billing.trial.code,
          trialUntil: !billing.trialUntil ? null : billing.trialUntil.format('YYYY-MM-DD 23:59'),
        })
        .then(
          ({ body }) => normalizeCompany(body),
          err => {
            console.log(err)
            throw {
              company,
              billing,
              error: err.body.errors[0].message,
            }
          }
        ),
      payload: null,
    })

type UpdateSamlAction = {
  type: 'UPDATE_SAML',
  payload: null,
  ...
}
type UpdateSamlSuccessAction = {
  type: 'UPDATE_SAML_SUCCESS',
  payload: CompanyRecord,
  ...
}
type UpdateSamlFailureAction = {
  type: 'UPDATE_SAML_FAILURE',
  payload: { company: CompanyRecord, saml: SAMLRecord, error: string, ... },
  ...
}

export const updateSaml =
  ({
    company,
    saml,
  }: {
    company: CompanyRecord,
    saml: SAMLRecord,
    ...
  }): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'UPDATE_SAML',
      dispatch,
      promise: request
        .put(
          generateUrl('console_api_company_update_saml', { companyId: company.id }),
          saml.isEnabled
            ? {
                ...saml.toObject(),
              }
            : { isEnabled: false }
        )
        .then(
          ({ body }) => normalizeCompany(body),
          err => {
            console.log(err)
            throw {
              company,
              saml,
              error: err.body.errors[0].message,
            }
          }
        ),
      payload: null,
    })

type PreviewDeleteCompanyAction = {
  type: 'PREVIEW_DELETE_COMPANY',
  payload: null,
  ...
}
type PreviewDeleteCompanySuccessAction = {
  type: 'PREVIEW_DELETE_COMPANY_SUCCESS',
  payload: null,
  ...
}
type PreviewDeleteCompanyFailureAction = {
  type: 'PREVIEW_DELETE_COMPANY_FAILURE',
  payload: null,
  ...
}

export const previewDeleteCompany =
  ({
    companyId,
  }: {
    companyId: number,
    ...
  }): DispatchOnlyBoundFn<
    Promise<{
      nbCampaignsToStop: number,
      nbApiKeys: number,
      nbAppsToArchive: number,
      nbPushCampaignsToArchive: number,
      nbUsersToDelete: number,
      ...
    }>,
  > =>
  dispatch =>
    promiseActionCreator({
      actionName: 'PREVIEW_DELETE_COMPANY',
      dispatch,
      promise: request
        .delete(generateUrl('console_api_delete_company_preview', { companyId }))
        .then(response => response.body)
        .catch(err => {
          console.log(err)
          throw {
            error: err.body.errors[0].message,
          }
        }),
      payload: null,
    })

type DeleteCompanyAction = {
  type: 'DELETE_COMPANY',
  payload: null,
  ...
}
type DeleteCompanySuccessAction = {
  type: 'DELETE_COMPANY_SUCCESS',
  payload: null,
  ...
}
type DeleteCompanyFailureAction = {
  type: 'DELETE_COMPANY_FAILURE',
  payload: null,
  ...
}

export const deleteCompany =
  ({
    code,
    companyId,
    companyIdValue,
    email,
  }: {
    code: string,
    companyId: number,
    companyIdValue: number,
    email: string,
    ...
  }): DispatchOnlyBoundFn<Promise<void>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'DELETE_COMPANY',
      dispatch,
      promise: request
        .delete(generateUrl('console_api_delete_company', { companyId }), {
          companyId: companyIdValue,
        })
        .set('X-2FA-code', code)
        .set('X-RateLimit-Id', `${email}-2fa`)
        .catch(err => {
          console.log(err)
          throw {
            error: err.body.errors[0].message,
          }
        }),
      payload: null,
    })

type ToggleEditoAction = {
  type: 'TOGGLE_EDITO',
  payload: null,
  ...
}
type ToggleEditoSuccessAction = {
  type: 'TOGGLE_EDITO_SUCCESS',
  payload: CompanyRecord,
  ...
}
type ToggleEditoFailureAction = {
  type: 'TOGGLE_EDITO_FAILURE',
  payload: null,
  ...
}
export const toggleEdito =
  (company: CompanyRecord): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'TOGGLE_EDITO',
      dispatch,
      promise: request
        .post(generateUrl('console_api_company_toggle_edito', { companyId: company.id }))
        .then(({ body }) => normalizeCompany(body)),
      payload: null,
    })

type SetSeatsAction = {
  type: 'SET_SEATS',
  payload: null,
  ...
}
type SetSeatsSuccessAction = {
  type: 'SET_SEATS_SUCCESS',
  payload: CompanyRecord,
  ...
}
type SetSeatsFailureAction = {
  type: 'SET_SEATS_FAILURE',
  payload: null,
  ...
}
export const setSeats =
  (company: CompanyRecord, seats: ?number): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'SET_SEATS',
      dispatch,
      promise: request
        .post(
          generateUrl('console_api_company_update_seats', { companyId: company.id }),
          seats ? { seats } : {}
        )
        .then(({ body }) => normalizeCompany(body)),
      payload: null,
    })

type UpdateCompanyFeaturesAction = {
  type: 'UPDATE_COMPANY_FEATURES',
  payload: null,
  ...
}
type UpdateCompanyFeaturesSuccessAction = {
  type: 'UPDATE_COMPANY_FEATURES_SUCCESS',
  payload: CompanyRecord,
  ...
}
type UpdateCompanyFeaturesFailureAction = {
  type: 'UPDATE_COMPANY_FEATURES_FAILURE',
  payload: { company: CompanyRecord, error: string, ... },
  ...
}
export const updateCompanyFeatures =
  ({
    company,
    additionals,
    disabled,
  }: {
    company: CompanyRecord,
    additionals: OrderedSet<string>,
    disabled: OrderedSet<string>,
    ...
  }): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'UPDATE_COMPANY_FEATURES',
      dispatch,
      promise: request
        .post(generateUrl('console_api_company_update_features', { companyId: company.id }), {
          additionals,
          disabled,
        })
        .then(
          ({ body }) => normalizeCompany(body),
          err => {
            console.log(err)
            throw {
              company,
              error: err.body.errors[0].message,
            }
          }
        ),
      payload: null,
    })

type toggleEnforce2faAction = {
  type: 'TOGGLE_ENFORCE_2FA',
  ...
}
type toggleEnforce2faSuccessAction = {
  type: 'TOGGLE_ENFORCE_2FA_SUCCESS',
  payload: CompanyRecord,
  ...
}
type toggleEnforce2faFailureAction = {
  type: 'TOGGLE_ENFORCE_2FA_FAILURE',
  payload: null,
  ...
}
export const toggleEnforce2fa =
  (company: CompanyRecord): DispatchOnlyBoundFn<Promise<CompanyRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'TOGGLE_ENFORCE_2FA',
      dispatch,
      promise: request
        .post(generateUrl('console_api_company_toggle_2fa', { companyId: company.id }))
        .then(({ body }) => normalizeCompany(body)),
      payload: null,
    })

type allowedCompanyActions =
  | LoadCompaniesAction
  | LoadCompaniesSuccessAction
  | LoadCompaniesFailureAction
  | LoadCompaniesListSuccessAction
  | LoadCompaniesListFailureAction
  | CreateCompanyAction
  | CreateCompanySuccessAction
  | CreateCompanyFailureAction
  | FetchCompanyAction
  | FetchCompanySuccessAction
  | FetchCompanyFailureAction
  | ToggleGdprExternalFlagAction
  | ToggleGdprExternalFlagSuccessAction
  | ToggleGdprExternalFlagFailureAction
  | UpdateCompanyFeaturesAction
  | UpdateCompanyFeaturesSuccessAction
  | UpdateCompanyFeaturesFailureAction
  | SetSeatsAction
  | SetSeatsSuccessAction
  | SetSeatsFailureAction
  | ToggleEditoAction
  | ToggleEditoSuccessAction
  | ToggleEditoFailureAction
  | UpdatePlanAction
  | UpdatePlanSuccessAction
  | UpdatePlanFailureAction
  | UpdateSamlAction
  | UpdateSamlSuccessAction
  | UpdateSamlFailureAction
  | DeleteCompanyAction
  | DeleteCompanySuccessAction
  | DeleteCompanyFailureAction
  | PreviewDeleteCompanyAction
  | PreviewDeleteCompanySuccessAction
  | PreviewDeleteCompanyFailureAction
  | toggleEnforce2faAction
  | toggleEnforce2faSuccessAction
  | toggleEnforce2faFailureAction
  | SetSalesForceIdAction
  | SetSalesForceIdSuccessAction
  | SetSalesForceIdFailureAction
  | UpdateCompaniesPageAction
  | UpdateFilterAction

// ====================== SELECTOR
const companyStateSelector = (state: State) => state.company

export const companiesSelector: State => List<CompanyRecord> = createSelector(
  companyStateSelector,
  (companyState: CompanyStateRecord) => {
    const idsList = companyState.idsPerPage.get(companyState.page, new Immutable.List().push())
    return idsList.map(id => companyState.entities.get(id, CompanyFactory()))
  }
)

// ====================== REDUCER
export const companyReducer = (
  state: CompanyStateRecord = CompanyStateFactory(),
  action: allowedCompanyActions
): CompanyStateRecord => {
  switch (action.type) {
    case 'SET_SALESFORCE_ID':
      return state.set(
        'entities',
        state.entities.set(action.payload.id, action.payload.set('loading', true))
      )

    case 'UPDATE_SAML_SUCCESS':
      return state
        .set('loading', false)
        .set('entities', state.entities.set(action.payload.id, action.payload))

    case 'UPDATE_SAML_FAILURE':
      return state.set('loading', false)

    case 'TOGGLE_GDRP_EXTERNAL_SUCCESS':
    case 'UPDATE_COMPANY_FEATURES_SUCCESS':
    case 'UPDATE_PLAN_SUCCESS':
    case 'SET_SEATS_SUCCESS':
    case 'TOGGLE_EDITO_SUCCESS':
    case 'TOGGLE_ENFORCE_2FA_SUCCESS':
    case 'SET_SALESFORCE_ID_SUCCESS':
    case 'FETCH_COMPANY_SUCCESS':
    case 'CREATE_COMPANY_SUCCESS':
      return state.set('entities', state.entities.set(action.payload.id, action.payload))

    case 'UPDATE_COMPANIES_PAGE':
      return state.set('page', action.payload)

    case 'UPDATE_COMPANIES_FILTER':
      return state.set('filter', action.payload).set('page', 1).set('idsPerPage', Immutable.Map())

    case 'UPDATE_SAML':
    case 'LOAD_COMPANIES':
      return state.set('loading', true)

    case 'LOAD_COMPANIES_SUCCESS': {
      let entMap = state.entities
      action.payload.entities.forEach(company => {
        entMap = entMap.set(company.id, company)
      })
      return state.set('entities', entMap).set('loading', false)
    }
    case 'LOAD_COMPANIES_LIST_SUCCESS': {
      let entitiesMap = state.entities
      let ids: Array<number> = []
      let page: number = action.payload.page

      action.payload.entities.forEach(company => {
        if (company.id) {
          entitiesMap = entitiesMap.set(company.id, company)
          ids.push(company.id)
        }
      })

      return state
        .set('count', action.payload.count)
        .set('loading', false)
        .set('entities', entitiesMap)
        .set('page', page)
        .set('nbPerPage', action.payload.nbPerPage)
        .set('idsPerPage', state.idsPerPage.set(page, new Immutable.List().push(...ids)))
    }
    case 'LOAD_COMPANIES_LIST_FAILURE':
    case 'LOAD_COMPANIES_FAILURE':
      return state.set('loading', false)
  }

  return state
}
