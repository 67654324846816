// @flow

import * as React from 'react'

import { Grid } from 'components/common/grid'

import { type AttributeValueRecord } from 'com.batch.redux/_records'

export const OptionPcent = (
  option: AttributeValueRecord,
  { context }: { context: 'value' | 'menu', ... }
): React.Node => {
  return context === 'value' ? (
    option.pretty
  ) : (
    <Grid template="1fr auto">
      <span>{option.pretty}</span>
      <em>{option.pcent}</em>
    </Grid>
  )
}
export const OptionPcentForLangRegion = (
  option: langType,
  { context }: { context: 'value' | 'menu', ... }
): React.Node => {
  return context === 'value' ? (
    option.label
  ) : (
    <Grid template="1fr auto" style={{ width: '100%' }}>
      <span>{option.label}</span>
      <em style={{ textAlign: 'right' }}>{option.pcent}</em>
    </Grid>
  )
}
