// @flow
import styled, { type PropsWithTheme, type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import { FilterSearchContainer } from 'components/filter/filter-search.styles'
import { colors } from 'components/styled/tokens'

type ProfileSearchContainerProps = { isEmpty: boolean, ... }

export const ProfileSearchContainer: StyledComponent<
  ProfileSearchContainerProps,
  { ... },
  HTMLDivElement,
> = styled.div`
  display: flex;
  background: ${(props: PropsWithTheme<{ isEmpty: boolean, ... }, any>) =>
    props.isEmpty ? 'transparent' : colors.opacifyActive};
  border-radius: 6px;

  ${FilterSearchContainer} {
    background: ${(props: PropsWithTheme<{ isEmpty: boolean, ... }, any>) =>
      props.isEmpty ? 'auto' : 'none'};
  }

  ${Button} {
    background: none;
  }
`
