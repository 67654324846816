// @flow

import Immutable, { type RecordOf, type RecordFactory } from 'immutable'

type DataProps = {
  value: number,
  rate: number,
}
export type DataRecord = RecordOf<DataProps>
export const DataFactory: RecordFactory<DataProps> = Immutable.Record(
  ({
    value: 0,
    rate: 0,
  }: DataProps)
)

type BounceDetailProps = {
  total: number,
  block: DataRecord,
  hard: DataRecord,
  soft: DataRecord,
}
export type BounceDetailRecord = RecordOf<BounceDetailProps>
export const BounceDetailFactory: RecordFactory<BounceDetailProps> = Immutable.Record(
  ({
    total: 0,
    block: DataFactory(),
    hard: DataFactory(),
    soft: DataFactory(),
  }: BounceDetailProps)
)
