// @flow

import styled, { type StyledComponent } from 'styled-components'

import * as colors from 'components/styled/tokens/colors'

export const NavigatorContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
`
type EllipseProps = { active: boolean, ... }
export const Ellipse: StyledComponent<EllipseProps, *, HTMLElement> = styled.div`
  background-color: ${(props: EllipseProps) =>
    props.active ? colors.textContrast : colors.strokeDark};
  width: 7px;
  height: 7px;
  border-radius: 50%;
`
