// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { ButtonLink } from 'components/common/button'
import Loader from 'components/common/loader-legacy'
import { Icon } from 'components/common/svg-icon'

import { formatPrice } from 'com.batch.common/utils'

import { InvoiceTable } from './plan.styles'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { getInvoices } from 'com.batch.redux/billing.api'
import { type InvoiceRecord } from 'com.batch.redux/billing.records'

type InvoicesProps = {
  company: CompanyRecord,
  ...
}

export const Invoices = ({ company }: InvoicesProps): React.Node => {
  const [loading, updateLoading] = React.useState<boolean>(true)
  const [invoices, updateInvoices] = React.useState<List<InvoiceRecord>>(new Immutable.List())
  React.useEffect(() => {
    getInvoices(company).then(receivedInvoices => {
      updateInvoices(receivedInvoices)
      updateLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id])

  return (
    <Loader overlay loading={loading}>
      {invoices.size === 0 ? (
        <p style={{ marginTop: 7, color: '#898989', textAlign: 'left' }}>
          You don't have any invoices yet.
        </p>
      ) : (
        <InvoiceTable>
          <colgroup>
            <col style={{ width: 90 }} />
            <col style={{ width: 80 }} />
            <col />
            <col style={{ width: 190 }} />
          </colgroup>
          <thead>
            <tr>
              <th>Date</th>
              <th style={{ textAlign: 'right' }}>Amount</th>
              <th className="tdStatus">Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(invoice => (
              <tr key={invoice.id}>
                <td>{invoice.periodTo.format('DD/MM/YYYY')}</td>
                <td className="tdNumber">{formatPrice(invoice.total / 100, invoice.currency)}</td>
                <td className="tdStatus">{invoice.paid ? 'Paid' : 'Unpaid'}</td>
                <td>
                  {/* Focus missing */}
                  <ButtonLink
                    kind="inline"
                    target="_blank"
                    href={`/${company.id || ''}/invoice/${invoice.id}`}
                  >
                    <Icon icon="view" />
                  </ButtonLink>
                </td>
              </tr>
            ))}
          </tbody>
        </InvoiceTable>
      )}
    </Loader>
  )
}
