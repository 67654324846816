// @flow

import { type Dayjs } from 'dayjs'
import { type List } from 'immutable'
import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'

import { LogDate, LogDay, LogMessage } from './console.style'

import { type EntityLogRecord } from 'com.batch.redux/user.records'

type EntityLogProps = {
  logs: List<EntityLogRecord>,
  ...
}

export const EntityLog = ({ logs }: EntityLogProps): React.Node => {
  const logsByDays = logs.reduce(
    (acc: Array<{ day: Dayjs, logs: Array<EntityLogRecord>, ... }>, curr: EntityLogRecord) => {
      if (acc.length === 0 || !acc[acc.length - 1].day.isSame(curr.when, 'day')) {
        acc.push({
          day: curr.when,
          logs: [curr],
        })
      } else {
        acc[acc.length - 1].logs.push(curr)
      }
      return acc
    },
    []
  )
  return (
    <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 440, padding: '0 10px' }}>
      {logsByDays.map(({ day, logs }, i) => (
        <div key={i}>
          <LogDay>
            <Grid template="1fr 20px">
              {day.format('MMMM DD YYYY')}
              <Icon icon="chevron-down" />
            </Grid>
          </LogDay>
          {logs.map((log, j) => (
            <Grid template="50px 60px 1fr" alignItems="start" key={j} style={{ marginBottom: 8 }}>
              <LogDate>{log.when.format('HH:mm:ss')}</LogDate>

              <Tooltip placement="left" tooltip={log.editor}>
                <div>
                  <Tag
                    type={log.editor === 'user' ? 'label' : 'main'}
                    style={{
                      textAlign: 'center',
                      overflow: 'hidden',
                      width: '100%',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {log.editor.replace('@batch.com', '')}
                  </Tag>
                </div>
              </Tooltip>
              <LogMessage>{log.message}</LogMessage>
            </Grid>
          ))}
        </div>
      ))}
    </div>
  )
}
