// @flow

import { animated } from '@react-spring/web'
import { type ComponentType } from 'react'
import styled, { type StyledComponent, css } from 'styled-components'

import { schemes } from 'components/styled/tokens'

import { type placement } from './tooltip'

type TooltipInnerProps = {
  inline?: boolean,
  noPadding?: boolean,
  maxWidth?: number,
  minWidth?: number,
  ...
}
export const TooltipInner: StyledComponent<TooltipInnerProps, *, HTMLDivElement> = styled.div`
  font-size: 12px;
  min-width: ${(props: TooltipInnerProps) => (props.minWidth ? props.minWidth : 120)}px;
  ${(props: TooltipInnerProps) =>
    props.maxWidth
      ? css`
          max-width: ${props.maxWidth}px;
        `
      : css`
          max-width: 30vw;
        `}
  padding: ${(props: TooltipInnerProps) => (props.noPadding ? '0' : '8px 12px 9px 12px')};
  color: #fff;
  text-align: center;
  text-decoration: none;

  box-shadow:
    0 1px 4px rgba(15, 15, 15, 0.04),
    0 1px 12px rgba(15, 15, 15, 0.08);
  border-radius: 8px;
  hr {
    margin: 5px 0;
    opacity: 0.4;
  }
`

export const TooltipContainer: StyledComponent<
  { toggle?: boolean, shown: boolean, placement: placement, ... },
  *,
  ComponentType<typeof animated.div>,
> = styled(animated.div)`
  position: relative;
  background-color: ${schemes.darklucent['90']};
  box-shadow:
    0 1px 4px rgba(15, 15, 15, 0.04),
    0 1px 12px rgba(15, 15, 15, 0.08);
  border-radius: 8px;

  .styled-arrow {
    position: absolute;
    width: 15px;
    height: 16px;
    fill: ${schemes.darklucent['90']};

    text-align: initial;

    > svg {
      position: absolute;
    }
  }
  &[data-placement^='top'] > .styled-arrow {
    bottom: 0;
    margin-left: 2px;
    &::after,
    > svg {
      top: 15px;
      transform: rotate(90deg);
    }
  }

  &[data-placement^='bottom'] > .styled-arrow {
    top: 0;
    margin-left: 2px;
    > svg {
      bottom: 15px;
      transform: rotate(-90deg);
    }
  }

  &[data-placement^='left'] > .styled-arrow {
    right: 0;

    &::after,
    > svg {
      top: calc(50% - 3px);
      left: 14px;
    }
  }
  &[data-placement^='right'] > .styled-arrow {
    left: 0;
    &::after,
    > svg {
      transform: rotate(-180deg);
      top: calc(50% - 3px);
      right: 14px;
    }
  }
  &[data-placement^='right-end'] > .styled-arrow {
    margin-top: 10px;
  }
  &[data-placement^='right-start'] > .styled-arrow {
    margin-top: -20px;
  }
`
