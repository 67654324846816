// @flow

import * as React from 'react'

import { Tooltip } from 'components/common/tooltip'

import { formatAudienceType } from 'com.batch.common/utils'

import { Value } from './conditions/rsr-condition.styles'

import { type CustomAudienceRecord } from 'com.batch.redux/_records'

export const CustomAudience = ({
  audience,
  last,
}: {
  audience: CustomAudienceRecord,
  last: boolean,
  ...
}): React.Node => {
  return (
    <Tooltip
      tooltip={
        <div>
          <strong>{formatAudienceType(audience.type)}</strong> {audience.name}
          {audience.deleted ? (
            <span
              style={{
                background: 'tomato',
                display: 'inline-block',
                borderRadius: '4px',
                padding: '2px 5px',
              }}
            >
              DELETED
            </span>
          ) : null}
        </div>
      }
      key={audience.id}
    >
      <Value comma={!last}>{audience.description || audience.name}</Value>
    </Tooltip>
  )
}
