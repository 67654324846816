/* eslint-disable react/jsx-no-bind */
// @flow

import { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo, useQuery } from 'components/_hooks'
import { InvitationTag, UserNameInline } from 'components/account/team/team.styles'
import { UserAvatar } from 'components/account/team/user-avatar'
import {
  isUserAllowedOnApp,
  getPermissionsForUserAndCompany,
  permissionNameFormatter,
  ROLES,
} from 'components/account/team/utils'
import { Box } from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { Wrapper, GlobalErrorOverlayProps, EmptyField } from 'components/common/empty-states'
import { TrackingContext } from 'components/common/page-tracker'
import { Pager } from 'components/common/pager'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableCellHeader,
  TableRow,
  TableCell,
  TableFooter,
  TableBody,
} from 'components/common/table'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'
import { Header, HeaderTitle, HeaderActions } from 'components/styled/blocs'
import { Title, Subtitle, LinkArrow } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'
import { ucFirst } from 'com.batch.common/utils'

import { AppTeamPopin } from './app-team-popin'
import { EmptyTeamRow } from './app-team.styles'

import { type AppRecord } from 'com.batch.redux/_records'
import { updateAllowedUsers } from 'com.batch.redux/app.action'
import { listUsersByCompany } from 'com.batch.redux/user'
import {
  usersForCompanySelector,
  userStateSelector,
  companyStateSelector,
} from 'com.batch.redux/user.selector'

import { STATUS } from 'constants/common'

type AppTeamProps = {
  app: AppRecord,
  edit: boolean,
  ...
}

const nbPerPage = 8

const TRACKING_CONTEXT = {
  eventLocation: 'app team',
  searchEventCode: 'SEARCH_USERS',
  pagerEventCode: 'unset',
}

const coerceOrder = (value: ?string) => (value === 'asc' || value === 'dsc' ? value : 'asc')
const coerceField = (value: ?string) =>
  value === 'granted' || value === 'email' || value === 'firstName' ? value : 'email'

export const AppTeam = ({ app, edit }: AppTeamProps): React.Node => {
  const navigate = useNavigate()
  const query = useQuery()
  const dispatch = useDispatch()

  // ====================== REDUX
  const users = useSelector(usersForCompanySelector)
  const company = useSelector(companyStateSelector)
  const { loadingState } = useSelector(userStateSelector)

  const isUsersLoaded = loadingState === STATUS.LOADED

  const hasAppRestrictions = app.features.has('app-user-restriction')

  const notReallyLoaded = React.useMemo(
    () => !isUsersLoaded || (isUsersLoaded && users.size === 0),
    [isUsersLoaded, users]
  )

  React.useEffect(() => {
    if (notReallyLoaded) dispatch(listUsersByCompany(company))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id])

  // current query string param, with defaults
  const qSortBy = coerceField(query.get('sortBy'))
  const qSortOrder = coerceOrder(query.get('sortOrder'))
  const qPage = query.get('page') ? parseInt(query.get('page')) : 1

  const queryParams = {
    sortBy: qSortBy,
    page: qPage,
    sortOrder: qSortOrder,
  }

  // build query string based on partial params & current query string
  const buildSearch = React.useMemo(
    () =>
      ({
        page,
        sortBy,
        sortOrder,
      }: {
        page?: number,
        sortBy?: 'granted' | 'email' | 'firstName',
        sortOrder?: 'dsc' | 'asc',
        ...
      }) => {
        let cleanPage = typeof page === 'number' ? page : qPage
        let cleanSortBy = typeof sortBy === 'string' ? sortBy : qSortBy
        let cleanSortOrder: 'dsc' | 'asc' = typeof sortOrder === 'string' ? sortOrder : qSortOrder
        return `page=${cleanPage}&sortBy=${cleanSortBy}&sortOrder=${cleanSortOrder}`
      },
    [qPage, qSortBy, qSortOrder]
  )

  // filters users with q=?
  const filteredUsers = React.useMemo(
    () => users.filter(user => isUserAllowedOnApp({ app, company, user })),
    [company, users, app]
  )

  const sortedUsers = React.useMemo(
    () =>
      filteredUsers.toList().sort((a, b) => {
        const dir = qSortOrder === 'asc' ? 1 : -1
        switch (qSortBy) {
          case 'email':
            return a.email > b.email ? dir : -dir
          default:
            return (isUserAllowedOnApp({ app, company, user: a }) ? 1 : 0) >
              (isUserAllowedOnApp({ app, company, user: b }) ? 1 : 0)
              ? dir
              : -dir
        }
      }),
    [app, company, filteredUsers, qSortOrder, qSortBy]
  )

  // slice a page of the sorted users
  const pagedUsers = React.useMemo(
    () => sortedUsers.slice((qPage - 1) * nbPerPage, qPage * nbPerPage),
    [sortedUsers, qPage]
  )

  // empty state constants
  const errors = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])
  const noDataInit = React.useMemo(
    () => users.size === 0 && !isUsersLoaded && !errors,
    [users.size, isUsersLoaded, errors]
  )
  const isEmpty = React.useMemo(
    () => (users.size === 0 && isUsersLoaded) || errors || !hasAppRestrictions,
    [users.size, isUsersLoaded, errors, hasAppRestrictions]
  )
  const isLoading = React.useMemo(
    () => loadingState === STATUS.LOADING || loadingState === STATUS.INIT,
    [loadingState]
  )

  const updateAllowedUsersBound = React.useCallback(
    (allowedUsers: Set<number>) => {
      return dispatch(updateAllowedUsers(app, allowedUsers))
    },
    [app, dispatch]
  )
  const isAllowedToUpdate = useIsCurrentUserAllowedTo(['company', 'users'])
  return (
    <TrackingContext.Provider value={TRACKING_CONTEXT}>
      <ThemeProvider theme={{ kind: 'filter', isLoading, isEmpty: isEmpty || noDataInit }}>
        <AppTeamPopin
          updateAllowedUsers={updateAllowedUsersBound}
          opened={edit}
          close={() =>
            navigate(
              generateUrl('app_team_show', {
                ...queryParams,
                companyId: company.id,
                appId: app.id,
              })
            )
          }
          users={users}
          app={app}
          company={company}
        />
        <Wrapper
          isOverlayShown={Boolean(errors) || !hasAppRestrictions}
          isLoading={isLoading || notReallyLoaded || noDataInit}
          isEmpty={isEmpty}
          overlayProps={
            errors
              ? GlobalErrorOverlayProps
              : {
                  status: 'upgrade-page',
                  companyId: app.companyId ?? 0,
                  title: 'Activate this feature',
                  description:
                    'Team enables your tech, marketing and editorial teams to work together on the same interface with different features accesses.',
                }
          }
        >
          <Header noZindex style={{ marginTop: 0 }}>
            <HeaderTitle>
              <Title overEmptyState>Team access</Title>

              <Subtitle>
                {isUsersLoaded && loadingState !== STATUS.ERROR ? (
                  <React.Fragment>
                    {sortedUsers.size} user{sortedUsers.size > 1 && 's'}
                    <React.Fragment>
                      &nbsp;ha{sortedUsers.size > 1 ? 've' : 's'} access to this app
                    </React.Fragment>
                  </React.Fragment>
                ) : (
                  <EmptyField _width={269} />
                )}
              </Subtitle>
            </HeaderTitle>
            <HeaderActions>
              <PermissionButton
                kind="primary"
                intent="action"
                to={generateUrl('app_team_edit', {
                  ...queryParams,
                  companyId: company.id,
                  appId: app.id,
                })}
                isAllowed={isAllowedToUpdate}
                disabled={isLoading || loadingState === STATUS.ERROR}
              >
                Edit access
              </PermissionButton>
            </HeaderActions>
          </Header>
          <Box style={{ overflow: 'hidden' }}>
            <Table template={'1fr 340px'} rowHeight={52}>
              <TableHeader>
                <TableCellOrder
                  sort={qSortOrder}
                  onClick={() =>
                    navigate({
                      search: buildSearch({
                        sortOrder: qSortOrder === 'asc' ? 'dsc' : 'asc',
                        sortBy: 'email',
                      }),
                    })
                  }
                >
                  Users
                </TableCellOrder>
                <TableCellHeader>Permissions</TableCellHeader>
              </TableHeader>
              <TableBody emptyTemplate={<EmptyTeamRow />} templateSize={8}>
                {pagedUsers.map(user => {
                  const userPerm = getPermissionsForUserAndCompany(user, company)

                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        <UserAvatar user={user} size={24} style={{ marginRight: '10px' }} />
                        {user.isInvite ? (
                          <Tooltip
                            placement="right"
                            tooltip="The account is awaiting confirmation."
                          >
                            <InvitationTag>Invite</InvitationTag>
                          </Tooltip>
                        ) : (
                          <UserNameInline>
                            {ucFirst(user.firstName)} {ucFirst(user.lastName)}
                          </UserNameInline>
                        )}
                        <LinkArrow
                          target="_blank"
                          href={generateUrl('user_show', {
                            ...queryParams,
                            companyId: company.id,
                            userId: user.id,
                          })}
                          style={{ fontWeight: 400, marginLeft: 8, lineHeight: 'inherit' }}
                        >
                          {user.email}
                        </LinkArrow>
                      </TableCell>
                      <TableCell>
                        <div>
                          {ROLES.filter(
                            r =>
                              userPerm.has(r.id) &&
                              !r.autoGrantedWith.reduce(
                                (acc, current) => acc || userPerm.has(current),
                                false
                              )
                          ).map((p, k) => (
                            <Tag type="label" style={{ marginRight: '3px' }} key={k}>
                              {permissionNameFormatter(p.id)}
                            </Tag>
                          ))}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              {!(isUsersLoaded && sortedUsers.size <= nbPerPage) && (
                <TableFooter>
                  <Pager
                    total={sortedUsers.size}
                    nbPerPage={nbPerPage}
                    page={qPage}
                    selectPage={page => navigate({ search: buildSearch({ page }) })}
                  />
                </TableFooter>
              )}
            </Table>
          </Box>
        </Wrapper>
      </ThemeProvider>
    </TrackingContext.Provider>
  )
}
