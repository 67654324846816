// @flow

import { type ReduxAction } from 'com.batch.redux/_records'

export type CancelNewCappingRulesActionType = ReduxAction<'CANCEL_NEW_CAPPING_RULES', null>

export const cancelNewCappingRules = (): CancelNewCappingRulesActionType => {
  return {
    type: 'CANCEL_NEW_CAPPING_RULES',
    payload: null,
  }
}
