// @flow
export const STOP_MSG_LENGTH = 11 // \nSTOP 36184

export const gsm7Charset =
  '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1bÆæßÉ !"#$%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà€^{}[]~|\\'
export const gsm7CharsetCounts: Array<{ value: string, count: number, ... }> = [
  // 1 cost
  { value: 'A', count: 1 },
  { value: 'B', count: 1 },
  { value: 'C', count: 1 },
  { value: 'D', count: 1 },
  { value: 'E', count: 1 },
  { value: 'F', count: 1 },
  { value: 'G', count: 1 },
  { value: 'H', count: 1 },
  { value: 'I', count: 1 },
  { value: 'J', count: 1 },
  { value: 'K', count: 1 },
  { value: 'L', count: 1 },
  { value: 'M', count: 1 },
  { value: 'N', count: 1 },
  { value: 'O', count: 1 },
  { value: 'P', count: 1 },
  { value: 'Q', count: 1 },
  { value: 'R', count: 1 },
  { value: 'S', count: 1 },
  { value: 'T', count: 1 },
  { value: 'U', count: 1 },
  { value: 'V', count: 1 },
  { value: 'W', count: 1 },
  { value: 'X', count: 1 },
  { value: 'Y', count: 1 },
  { value: 'Z', count: 1 },
  { value: 'a', count: 1 },
  { value: 'b', count: 1 },
  { value: 'c', count: 1 },
  { value: 'd', count: 1 },
  { value: 'e', count: 1 },
  { value: 'f', count: 1 },
  { value: 'g', count: 1 },
  { value: 'h', count: 1 },
  { value: 'i', count: 1 },
  { value: 'j', count: 1 },
  { value: 'k', count: 1 },
  { value: 'l', count: 1 },
  { value: 'm', count: 1 },
  { value: 'n', count: 1 },
  { value: 'o', count: 1 },
  { value: 'p', count: 1 },
  { value: 'q', count: 1 },
  { value: 'r', count: 1 },
  { value: 's', count: 1 },
  { value: 't', count: 1 },
  { value: 'u', count: 1 },
  { value: 'v', count: 1 },
  { value: 'w', count: 1 },
  { value: 'x', count: 1 },
  { value: 'y', count: 1 },
  { value: 'z', count: 1 },
  { value: '0', count: 1 },
  { value: '1', count: 1 },
  { value: '2', count: 1 },
  { value: '3', count: 1 },
  { value: '4', count: 1 },
  { value: '5', count: 1 },
  { value: '6', count: 1 },
  { value: '7', count: 1 },
  { value: '8', count: 1 },
  { value: '9', count: 1 },
  { value: ':', count: 1 },
  { value: ';', count: 1 },
  { value: '<', count: 1 },
  { value: '=', count: 1 },
  { value: '>', count: 1 },
  { value: '?', count: 1 },
  { value: '¡', count: 1 },
  { value: '¿', count: 1 },
  { value: '!', count: 1 },
  { value: '"', count: 1 },
  { value: '#', count: 1 },
  { value: '¤', count: 1 },
  { value: '%', count: 1 },
  { value: '&', count: 1 },
  { value: '(', count: 1 },
  { value: ')', count: 1 },
  { value: "'", count: 1 },
  { value: '*', count: 1 },
  { value: '+', count: 1 },
  { value: ',', count: 1 },
  { value: '-', count: 1 },
  { value: '.', count: 1 },
  { value: '/', count: 1 },
  { value: 'Ä', count: 1 },
  { value: 'Ö', count: 1 },
  { value: 'Ñ', count: 1 },
  { value: 'Ü', count: 1 },
  { value: '§', count: 1 },
  { value: 'ä', count: 1 },
  { value: 'ö', count: 1 },
  { value: 'ñ', count: 1 },
  { value: 'ü', count: 1 },
  { value: 'à', count: 1 },
  { value: '@', count: 1 },
  { value: '£', count: 1 },
  { value: '$', count: 1 },
  { value: '¥', count: 1 },
  { value: 'è', count: 1 },
  { value: 'é', count: 1 },
  { value: 'ù', count: 1 },
  { value: 'ì', count: 1 },
  { value: 'ò', count: 1 },
  { value: 'Ç', count: 1 },
  { value: 'Ø', count: 1 },
  { value: 'ø', count: 1 },
  { value: 'Å', count: 1 },
  { value: 'å', count: 1 },
  { value: 'Δ', count: 1 },
  { value: '_', count: 1 },
  { value: 'Φ', count: 1 },
  { value: 'Γ', count: 1 },
  { value: 'Λ', count: 1 },
  { value: 'Ω', count: 1 },
  { value: 'Π', count: 1 },
  { value: 'Ψ', count: 1 },
  { value: 'Σ', count: 1 },
  { value: 'Θ', count: 1 },
  { value: 'Ξ', count: 1 },
  { value: 'Æ', count: 1 },
  { value: 'æ', count: 1 },
  { value: 'ß', count: 1 },
  { value: 'É', count: 1 },
  { value: ' ', count: 1 },
  { value: '\n', count: 1 },
  { value: '\r', count: 1 },
  { value: '\x1b', count: 1 },
  // 2 cost
  { value: '€', count: 2 },
  { value: '^', count: 2 },
  { value: '{', count: 2 },
  { value: '}', count: 2 },
  { value: '[', count: 2 },
  { value: ']', count: 2 },
  { value: '~', count: 2 },
  { value: '|', count: 2 },
  { value: '\\', count: 2 },
]

export const unicodeCharsReplacedWithGsm7Chars = [
  { unicode: '\\u00ab', count: 1 },
  { unicode: '\\u00bb', count: 1 },
  { unicode: '\\u201c', count: 1 },
  { unicode: '\\u201d', count: 1 },
  { unicode: '\\u02ba', count: 1 },
  { unicode: '\\u02ee', count: 1 },
  { unicode: '\\u201f', count: 1 },
  { unicode: '\\u275d', count: 1 },
  { unicode: '\\u275e', count: 1 },
  { unicode: '\\u301d', count: 1 },
  { unicode: '\\u301e', count: 1 },
  { unicode: '\\uff02', count: 1 },
  { unicode: '\\u2018', count: 1 },
  { unicode: '\\u2019', count: 1 },
  { unicode: '\\u02bb', count: 1 },
  { unicode: '\\u02c8', count: 1 },
  { unicode: '\\u02bc', count: 1 },
  { unicode: '\\u02bd', count: 1 },
  { unicode: '\\u02b9', count: 1 },
  { unicode: '\\u201b', count: 1 },
  { unicode: '\\uff07', count: 1 },
  { unicode: '\\u00b4', count: 1 },
  { unicode: '\\u02ca', count: 1 },
  { unicode: '\\u0060', count: 1 },
  { unicode: '\\u02cb', count: 1 },
  { unicode: '\\u275b', count: 1 },
  { unicode: '\\u275c', count: 1 },
  { unicode: '\\u0313', count: 1 },
  { unicode: '\\u0314', count: 1 },
  { unicode: '\\ufe10', count: 1 },
  { unicode: '\\ufe11', count: 1 },
  { unicode: '\\u00f7', count: 1 },
  { unicode: '\\u00bc', count: 1 },
  { unicode: '\\u00bd', count: 1 },
  { unicode: '\\u00be', count: 1 },
  { unicode: '\\u29f8', count: 1 },
  { unicode: '\\u0337', count: 1 },
  { unicode: '\\u0338', count: 1 },
  { unicode: '\\u2044', count: 1 },
  { unicode: '\\u2215', count: 1 },
  { unicode: '\\uff0f', count: 1 },
  { unicode: '\\u29f9', count: 2 },
  { unicode: '\\u29f5', count: 2 },
  { unicode: '\\u20e5', count: 2 },
  { unicode: '\\ufe68', count: 2 },
  { unicode: '\\uff3c', count: 2 },
  { unicode: '\\u0332', count: 1 },
  { unicode: '\\uff3f', count: 1 },
  { unicode: '\\u20d2', count: 2 },
  { unicode: '\\u20d3', count: 2 },
  { unicode: '\\u2223', count: 2 },
  { unicode: '\\uff5c', count: 2 },
  { unicode: '\\u23b8', count: 2 },
  { unicode: '\\u23b9', count: 2 },
  { unicode: '\\u23d0', count: 2 },
  { unicode: '\\u239c', count: 2 },
  { unicode: '\\u239f', count: 2 },
  { unicode: '\\u23bc', count: 1 },
  { unicode: '\\u23bd', count: 1 },
  { unicode: '\\u2015', count: 1 },
  { unicode: '\\ufe63', count: 1 },
  { unicode: '\\uff0d', count: 1 },
  { unicode: '\\u2010', count: 1 },
  { unicode: '\\u2022', count: 1 },
  { unicode: '\\u2043', count: 1 },
  { unicode: '\\ufe6b', count: 1 },
  { unicode: '\\uff20', count: 1 },
  { unicode: '\\ufe69', count: 1 },
  { unicode: '\\uff04', count: 1 },
  { unicode: '\\u01c3', count: 1 },
  { unicode: '\\ufe15', count: 1 },
  { unicode: '\\ufe57', count: 1 },
  { unicode: '\\uff01', count: 1 },
  { unicode: '\\ufe5f', count: 1 },
  { unicode: '\\uff03', count: 1 },
  { unicode: '\\ufe6a', count: 1 },
  { unicode: '\\uff05', count: 1 },
  { unicode: '\\ufe60', count: 1 },
  { unicode: '\\uff06', count: 1 },
  { unicode: '\\u0326', count: 1 },
  { unicode: '\\ufe50', count: 1 },
  { unicode: '\\u3001', count: 1 },
  { unicode: '\\ufe51', count: 1 },
  { unicode: '\\uff0c', count: 1 },
  { unicode: '\\uff64', count: 1 },
  { unicode: '\\u2768', count: 1 },
  { unicode: '\\u276a', count: 1 },
  { unicode: '\\ufe59', count: 1 },
  { unicode: '\\uff08', count: 1 },
  { unicode: '\\u27ee', count: 1 },
  { unicode: '\\u2985', count: 1 },
  { unicode: '\\u2769', count: 1 },
  { unicode: '\\u276b', count: 1 },
  { unicode: '\\ufe5a', count: 1 },
  { unicode: '\\uff09', count: 1 },
  { unicode: '\\u27ef', count: 1 },
  { unicode: '\\u2986', count: 1 },
  { unicode: '\\u204e', count: 1 },
  { unicode: '\\u2217', count: 1 },
  { unicode: '\\u229b', count: 1 },
  { unicode: '\\u2722', count: 1 },
  { unicode: '\\u2723', count: 1 },
  { unicode: '\\u2724', count: 1 },
  { unicode: '\\u2725', count: 1 },
  { unicode: '\\u2731', count: 1 },
  { unicode: '\\u2732', count: 1 },
  { unicode: '\\u2733', count: 1 },
  { unicode: '\\u273a', count: 1 },
  { unicode: '\\u273b', count: 1 },
  { unicode: '\\u273c', count: 1 },
  { unicode: '\\u273d', count: 1 },
  { unicode: '\\u2743', count: 1 },
  { unicode: '\\u2749', count: 1 },
  { unicode: '\\u274a', count: 1 },
  { unicode: '\\u274b', count: 1 },
  { unicode: '\\u29c6', count: 1 },
  { unicode: '\\ufe61', count: 1 },
  { unicode: '\\uff0a', count: 1 },
  { unicode: '\\u02d6', count: 1 },
  { unicode: '\\ufe62', count: 1 },
  { unicode: '\\uff0b', count: 1 },
  { unicode: '\\u3002', count: 1 },
  { unicode: '\\ufe52', count: 1 },
  { unicode: '\\uff0e', count: 1 },
  { unicode: '\\uff61', count: 1 },
  { unicode: '\\uff10', count: 1 },
  { unicode: '\\uff11', count: 1 },
  { unicode: '\\uff12', count: 1 },
  { unicode: '\\uff13', count: 1 },
  { unicode: '\\uff14', count: 1 },
  { unicode: '\\uff15', count: 1 },
  { unicode: '\\uff16', count: 1 },
  { unicode: '\\uff17', count: 1 },
  { unicode: '\\uff18', count: 1 },
  { unicode: '\\uff19', count: 1 },
  { unicode: '\\u02d0', count: 1 },
  { unicode: '\\u02f8', count: 1 },
  { unicode: '\\u2982', count: 1 },
  { unicode: '\\ua789', count: 1 },
  { unicode: '\\ufe13', count: 1 },
  { unicode: '\\uff1a', count: 1 },
  { unicode: '\\u204f', count: 1 },
  { unicode: '\\ufe14', count: 1 },
  { unicode: '\\ufe54', count: 1 },
  { unicode: '\\uff1b', count: 1 },
  { unicode: '\\ufe64', count: 1 },
  { unicode: '\\uff1c', count: 1 },
  { unicode: '\\u0347', count: 1 },
  { unicode: '\\ua78a', count: 1 },
  { unicode: '\\ufe66', count: 1 },
  { unicode: '\\uff1d', count: 1 },
  { unicode: '\\ufe65', count: 1 },
  { unicode: '\\uff1e', count: 1 },
  { unicode: '\\ufe16', count: 1 },
  { unicode: '\\ufe56', count: 1 },
  { unicode: '\\uff1f', count: 1 },
  { unicode: '\\uff21', count: 1 },
  { unicode: '\\u1d00', count: 1 },
  { unicode: '\\uff22', count: 1 },
  { unicode: '\\u0299', count: 1 },
  { unicode: '\\uff23', count: 1 },
  { unicode: '\\u1d04', count: 1 },
  { unicode: '\\uff24', count: 1 },
  { unicode: '\\u1d05', count: 1 },
  { unicode: '\\uff25', count: 1 },
  { unicode: '\\u1d07', count: 1 },
  { unicode: '\\uff26', count: 1 },
  { unicode: '\\ua730', count: 1 },
  { unicode: '\\uff27', count: 1 },
  { unicode: '\\u0262', count: 1 },
  { unicode: '\\uff28', count: 1 },
  { unicode: '\\u029c', count: 1 },
  { unicode: '\\uff29', count: 1 },
  { unicode: '\\u026a', count: 1 },
  { unicode: '\\uff2a', count: 1 },
  { unicode: '\\u1d0a', count: 1 },
  { unicode: '\\uff2b', count: 1 },
  { unicode: '\\u1d0b', count: 1 },
  { unicode: '\\uff2c', count: 1 },
  { unicode: '\\u029f', count: 1 },
  { unicode: '\\uff2d', count: 1 },
  { unicode: '\\u1d0d', count: 1 },
  { unicode: '\\uff2e', count: 1 },
  { unicode: '\\u0274', count: 1 },
  { unicode: '\\uff2f', count: 1 },
  { unicode: '\\u1d0f', count: 1 },
  { unicode: '\\uff30', count: 1 },
  { unicode: '\\u1d18', count: 1 },
  { unicode: '\\uff31', count: 1 },
  { unicode: '\\uff32', count: 1 },
  { unicode: '\\u0280', count: 1 },
  { unicode: '\\uff33', count: 1 },
  { unicode: '\\ua731', count: 1 },
  { unicode: '\\uff34', count: 1 },
  { unicode: '\\u1d1b', count: 1 },
  { unicode: '\\uff35', count: 1 },
  { unicode: '\\u1d1c', count: 1 },
  { unicode: '\\uff36', count: 1 },
  { unicode: '\\u1d20', count: 1 },
  { unicode: '\\uff37', count: 1 },
  { unicode: '\\u1d21', count: 1 },
  { unicode: '\\uff38', count: 1 },
  { unicode: '\\uff39', count: 1 },
  { unicode: '\\u028f', count: 1 },
  { unicode: '\\uff3a', count: 1 },
  { unicode: '\\u1d22', count: 1 },
  { unicode: '\\u02c6', count: 2 },
  { unicode: '\\u0302', count: 2 },
  { unicode: '\\uff3e', count: 2 },
  { unicode: '\\u1dcd', count: 2 },
  { unicode: '\\u2774', count: 2 },
  { unicode: '\\ufe5b', count: 2 },
  { unicode: '\\uff5b', count: 2 },
  { unicode: '\\u2775', count: 2 },
  { unicode: '\\ufe5c', count: 2 },
  { unicode: '\\uff5d', count: 2 },
  { unicode: '\\uff3b', count: 2 },
  { unicode: '\\uff3d', count: 2 },
  { unicode: '\\u02dc', count: 1 },
  { unicode: '\\u02f7', count: 2 },
  { unicode: '\\u0303', count: 2 },
  { unicode: '\\u0330', count: 2 },
  { unicode: '\\u0334', count: 2 },
  { unicode: '\\u223c', count: 2 },
  { unicode: '\\uff5e', count: 2 },
  { unicode: '\\u00a0', count: 1 },
  { unicode: '\\u2000', count: 1 },
  { unicode: '\\u2001', count: 1 },
  { unicode: '\\u2002', count: 1 },
  { unicode: '\\u2003', count: 1 },
  { unicode: '\\u2004', count: 1 },
  { unicode: '\\u2005', count: 1 },
  { unicode: '\\u2006', count: 1 },
  { unicode: '\\u2007', count: 1 },
  { unicode: '\\u2008', count: 1 },
  { unicode: '\\u2009', count: 1 },
  { unicode: '\\u200a', count: 1 },
  { unicode: '\\u200b', count: 1 },
  { unicode: '\\u202f', count: 1 },
  { unicode: '\\u205f', count: 1 },
  { unicode: '\\u3000', count: 1 },
  { unicode: '\\ufeff', count: 1 },
  { unicode: '\\u008d', count: 1 },
  { unicode: '\\u009f', count: 1 }, // Ÿ
  { unicode: '\\u0178', count: 1 }, // Ÿ don't ask me why ...
  { unicode: '\\u0080', count: 1 },
  { unicode: '\\u0090', count: 1 },
  { unicode: '\\u009b', count: 1 },
  { unicode: '\\u0010', count: 1 },
  { unicode: '\\u0009', count: 1 },
  { unicode: '\\u0000', count: 1 },
  { unicode: '\\u0003', count: 1 },
  { unicode: '\\u0004', count: 1 },
  { unicode: '\\u0017', count: 1 },
  { unicode: '\\u0019', count: 1 },
  { unicode: '\\u0011', count: 1 },
  { unicode: '\\u0012', count: 1 },
  { unicode: '\\u0013', count: 1 },
  { unicode: '\\u0014', count: 1 },
  { unicode: '\\u2017', count: 1 },
  { unicode: '\\u2014', count: 1 },
  { unicode: '\\u2013', count: 1 },
  { unicode: '\\u201a', count: 1 },
  { unicode: '\\u2039', count: 1 },
  { unicode: '\\u203a', count: 1 },
  { unicode: '\\u203c', count: 1 },
  { unicode: '\\u201e', count: 1 },
  { unicode: '\\u2026', count: 1 },
  { unicode: '\\u2028', count: 1 },
  { unicode: '\\u2029', count: 1 },
  { unicode: '\\u2060', count: 1 },
]
