// @flow

import styled, {
  css,
  keyframes,
  type KeyFrames,
  type StyledComponent,
  type PropsWithTheme,
} from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const DonutChartContainer: StyledComponent<*, *, *> = styled.div`
  position: relative;
  overflow: visible;
`

export const LoadingSvgStrokeAnim: KeyFrames = keyframes`
    0% {
        fill: ${schemes.darklucent['05']}
    }
    50% {
        fill: ${colors.stroke}
    }
    100% {
        fill: ${schemes.darklucent['05']}
    }
`

export const LoadingPath: StyledComponent<*, *, *> = styled.path`
  animation-name: ${LoadingSvgStrokeAnim};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

export const PlaceholderPath: StyledComponent<*, *, *> = styled.path`
  fill: ${schemes.grayscale['30']};
`

export const LoadingRect: StyledComponent<{ isEmpty: boolean, ... }, *, *> = styled.rect`
  ${(props: PropsWithTheme<{ isEmpty: boolean, ... }, any>) =>
    props.isEmpty
      ? css`
          fill: ${schemes.grayscale['30']};
        `
      : css`
          animation-name: ${LoadingSvgStrokeAnim};
          animation-duration: 2s;
          animation-iteration-count: infinite;
        `}
`

export const Legend: StyledComponent<
  { disabled?: boolean, active?: boolean, ... },
  *,
  HTMLButtonElement,
> = styled.button`
  display: inline-flex;
  text-align: center;
  align-items: center;
  border: 0;
  font-size: 14px;
  color: ${(props: PropsWithTheme<{ disabled?: boolean, active?: boolean, ... }, any>) => {
    if (props.disabled) return colors.textDisabled
    return props.active ? colors.textNeutral : colors.textLight
  }};
  font-weight: 500;
  padding: 0;
  gap: 4px;
`

export const LegendContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: block;
  text-align: center;
  margin-top: 20px;

  ${Legend}:not(:last-child) {
    margin-right: 8px;
  }
`
