// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map, type OrderedSet, type Set } from 'immutable'
import { createSelector } from 'reselect'

import { languages, regions } from './lang-region'

import {
  type AppRecord,
  type CampaignConfigRecord,
  CampaignFactory,
  type CampaignRecord,
  type CampaignStateRecord,
  type LanguageRecord,
  type RegionRecord,
  type State,
} from 'com.batch.redux/_records'
import {
  currentAppSelector,
  currentProjectAppsSelector,
  restrictedLanguagesSelector,
  restrictedRegionsSelector,
} from 'com.batch.redux/app'
import { valueSelector } from 'com.batch.redux/attribute'
import { type ContentStateRecord } from 'com.batch.redux/content.records'
import { pushSettingsSelector } from 'com.batch.redux/content.selector'
import { CampaignDataFactory, type CampaignDataRecord } from 'com.batch.redux/dataCampaign.records'
import { ThemeFactory, type ThemeRecord } from 'com.batch.redux/theme.records'

import { journeySettingsSelector } from 'com.batch/orchestration-journey/models/journey.selectors'

type extract<T> = State => T

// ==-- GLOBAL ----------------------------

// returns the campaign slice of our global state
export function campaignSelector(state: State): CampaignStateRecord {
  return state.campaign
}

export const currentCampaign: (state: State) => CampaignRecord = createSelector(
  campaignSelector,
  (campaign: CampaignStateRecord) => {
    const editing = campaign.config.editing
    const entities = campaign.get('entities')
    if (typeof entities !== 'undefined') {
      return entities.get(editing, CampaignFactory())
    } else {
      throw 'not found'
    }
  }
)

export const currentCampaignAppsSelector: extract<Set<AppRecord>> = createSelector(
  currentCampaign,
  currentProjectAppsSelector,
  (campaign, apps) =>
    campaign.channels
      .filter(channel => channel !== 'email')
      .map(channel => {
        const app = apps.find(app => app.platform === channel)
        return app
      })
)

export const canPickImportedSelector: extract<boolean> = createSelector(
  currentCampaignAppsSelector,
  apps => apps.filter(app => app.pushImported).size > 0
)

export const triggerEventSelector: extract<string> = createSelector(
  currentCampaign,
  journeySettingsSelector,
  (campaign, settings) =>
    settings.entryEvents.size > 0
      ? settings.entryEvents.first().name
      : campaign.sendType === 'trigger'
        ? campaign.triggerConfig.enterEvent
        : ''
)

export const campaignStateSelector: (state: State) => campaignStateType = createSelector(
  currentCampaign,
  campaign => campaign.state
)

export const campaignHasAbTesting: extract<boolean> = createSelector(
  currentCampaign,
  campaign => campaign.abtesting.enabled
)

export const campaignAbTestingVariants: extract<Set<'a' | 'b'>> = createSelector(
  currentCampaign,
  campaign => campaign.abtesting.activeVariants
)

export const campaignHasLandingSelector: extract<boolean> = createSelector(
  currentCampaign,
  campaign => campaign.hasLanding
)

export const campaignReplicationSelector = (state: State): List<any> => {
  return state.campaign.replication
}

export const campaignConfigSelector: extract<CampaignConfigRecord> = createSelector(
  campaignSelector,
  campaign => campaign.config
)

export const dynamicPreviewSourceKindSelector: extract<string> = createSelector(
  campaignConfigSelector,
  config => config.previewSourceKind
)

export const dynamicPreviewSourceValueSelector: extract<string> = createSelector(
  campaignConfigSelector,
  config => config.previewSourceValue
)

export const dynamicPreviewSourceProfileIdSelector: extract<string> = createSelector(
  campaignConfigSelector,
  config => config.previewSourceProfileId
)

export const campaignRequireThemeSelector: extract<boolean> = createSelector(
  currentCampaign,
  campaign => {
    return campaign.hasLanding || campaign.type === 'in-app'
  }
)

export const advancedPaneOpenedSelector: extract<boolean> = createSelector(
  campaignConfigSelector,
  config => config.advancedPaneOpened
)
export const activeVariantIdSelector: extract<'a' | 'b'> = createSelector(
  campaignConfigSelector,
  config => config.abtesting
)

export const campaignEntitiesSelector: extract<Map<string, CampaignRecord>> = createSelector(
  campaignSelector,
  cs => cs.entities
)

export const landingFocusedFieldSelector: extract<string> = createSelector(
  campaignConfigSelector,
  config => config.focusedLandingField
)

export const campaignTypeSelector: extract<campaignType> = createSelector(
  campaignConfigSelector,
  config => config.type
)

export const listLoadingSelector: extract<boolean> = createSelector(
  campaignSelector,
  campaignState => campaignState.loading
)

export const listPagerLoadingSelector: extract<boolean> = createSelector(
  campaignSelector,
  campaignState => campaignState.pagerLoading
)

export const countTotalSelector: extract<number> = createSelector(
  campaignSelector,
  campaignState => campaignState.countTotal
)

export const countFilteredSelector: extract<number> = createSelector(
  campaignSelector,
  campaignState => campaignState.countFiltered
)

export const countLoadingSelector: extract<boolean> = createSelector(
  campaignSelector,
  campaignState => campaignState.loadingCount
)

const displayedIdsSelector = createSelector(
  campaignSelector,
  campaignConfigSelector,
  (c: CampaignStateRecord, config: CampaignConfigRecord) =>
    c.idsPerPage.get(config.page, new Immutable.List())
)

const entitiesSelector = createSelector(campaignSelector, campaignState => campaignState.entities)

const campaignStatSelector = (state: State) => state.dataCampaign.get('campaign')

// merges stat into current page campaign list
export const listSelector: extract<List<CampaignRecord>> = createSelector(
  displayedIdsSelector,
  entitiesSelector,
  campaignStatSelector,
  (ids, entities, stats) =>
    ids.map(id =>
      entities.get(id, CampaignFactory()).set('stats', stats.get(id, CampaignDataFactory()))
    )
)

export const currentCampaignData: extract<?CampaignDataRecord> = createSelector(
  campaignStatSelector,
  currentCampaign,
  (data, campaign) => {
    return data.get(campaign.token)
  }
)

// ==-- TRIGGER ---------------------------
// returns the trigger of the inapp
export const triggerSelector: extract<string> = createSelector(
  currentCampaign,
  inapp => inapp.trigger
)

// returns the trigger of the inapp
export const inAppPrioritySelector: extract<inAppPriority> = createSelector(
  currentCampaign,
  inapp => inapp.inAppPriority
)

// when the trigger is an event, the user can pick a single label to watch
export const triggerLabelSelector: extract<string> = createSelector(currentCampaign, inapp =>
  inapp.triggerLabel ? inapp.triggerLabel : ''
)

// returns the capping of the inapp
export const cappingSelector: extract<number> = createSelector(currentCampaign, inapp =>
  inapp.capping ? inapp.capping : 0
)

export const gracePeriodSelector: extract<number> = createSelector(
  currentCampaign,
  inapp => inapp.gracePeriod
)

export const startSelector: extract<?Dayjs> = createSelector(currentCampaign, inapp => inapp.start)
export const tzAwareSelector: extract<boolean> = createSelector(
  currentCampaign,
  inapp => inapp.tzAware
)
export const endSelector: extract<?Dayjs> = createSelector(currentCampaign, inapp => inapp.end)

// ==-- CONTENT ---------------------------

// returns the whole data (messages) of the inapp
export const contentSelector = (state: State): ContentStateRecord => state.content

export const translationsIdsSelector: extract<OrderedSet<string>> = createSelector(
  currentCampaign,
  campaign => {
    return campaign.translations
  }
)

// returns the active language id
export const activeLanguageIdSelector: extract<?string> = createSelector(
  campaignConfigSelector,
  config => config.activeLanguageId
)

const langOrRegionSorter: (
  a: LanguageRecord | RegionRecord,
  b: LanguageRecord | RegionRecord
) => 1 | -1 = (a, b) => {
  if (parseInt(a.nb) < parseInt(b.nb)) {
    return 1
  }
  if (parseInt(a.nb) > parseInt(b.nb)) {
    return -1
  }
  return a.label < b.label ? -1 : 1
}

//
/**
 * enrich the languages list from the stat api attribute values
 * MEP
 * @deprecated
 */
export const enrichedLanguagesSelector: extract<List<LanguageRecord>> = createSelector(
  valueSelector,
  values => {
    const languagesValues = values.getIn(
      ['b.language', 'values', '__default'],
      new Immutable.List()
    )
    return languages
      .map(lang => {
        const match = languagesValues.find(lv => lv.value.toString() === lang.value, false)
        return match
          ? lang.set('nb', match.installs).set('tokens', match.tokens).set('pcent', match.pcent)
          : lang
      })
      .sort(langOrRegionSorter)
      .map(lang => lang.set('pretty', lang.label))
  }
)

export const targetingLangSelector: extract<List<LanguageRecord>> = createSelector(
  enrichedLanguagesSelector,
  restrictedLanguagesSelector,
  (langs, restricted) => {
    if (restricted.size !== 0) {
      langs = langs.filter(lg => restricted.has(lg.value))
    }
    return langs.filter(lg => lg.value !== 'default')
  }
)

// enrich the regions list from the stat api attribute values
export const enrichedRegionsSelector: extract<List<RegionRecord>> = createSelector(
  valueSelector,
  values => {
    const regionsValues = values.getIn(['b.region', 'values', '__default'], new Immutable.List())

    return regions
      .map(reg => {
        const match = regionsValues.find(rv => rv.value.toString() === reg.value, false)
        return match
          ? reg.set('nb', match.installs).set('tokens', match.tokens).set('pcent', match.pcent)
          : reg
      })
      .sort(langOrRegionSorter)
  }
)

export const targetingRegionSelector: extract<List<RegionRecord>> = createSelector(
  enrichedRegionsSelector,
  restrictedRegionsSelector,
  (regions, restricted) => {
    regions = regions.filter(
      lr => lr.value !== 'XX' && lr.value !== 'ZZ' && lr.value !== 'UNDEFINED'
    )
    if (restricted.size === 0) {
      return regions
    } else {
      return regions.filter(rg => restricted.has(rg.get('value')))
    }
  }
)

// returns the list of languages not picked in current in app
/**
 * MEP lang picker
 * @deprecated
 */
export const pickableLanguagesSelector: extract<List<LanguageRecord>> = createSelector(
  translationsIdsSelector,
  enrichedLanguagesSelector,
  (alreadyPickedIds, richLangs) => {
    return richLangs.filter(lang => !alreadyPickedIds.has(lang.value) && lang.value !== 'default')
  }
)
// returns the list of languages picked in current in app
export const pickedLanguagesSelector: extract<List<LanguageRecord>> = createSelector(
  translationsIdsSelector,
  enrichedLanguagesSelector,
  (alreadyPickedIds, richLangs) => {
    return alreadyPickedIds.toList().map(v => {
      return richLangs.find(rl => rl.get('value') === v)
    })
  }
)

export const mandatoryLanguageSelector: extract<LanguageRecord> = createSelector(
  currentAppSelector,
  enrichedLanguagesSelector,
  (app, richLangs) => {
    return richLangs.find(lang => lang.get('value') === 'default')
  }
)
export const activeLanguageSelector: extract<?LanguageRecord> = createSelector(
  enrichedLanguagesSelector,
  activeLanguageIdSelector,
  (richLangs, value) => {
    return richLangs.find(l => l.value === value)
  }
)

export const activeLanguageValueSelector: State => string = createSelector(
  activeLanguageSelector,
  (language: ?LanguageRecord) => language?.value ?? 'default'
)

const themesSelector = (state: State) => state.theme.entities
const inAppThemeCodes = createSelector(currentCampaign, campaign => campaign.variantsThemeCodes)

// @todo a/b themeCodes returns a AbRecord not a string code now
export const inAppTheme: extract<ThemeRecord> = createSelector(
  themesSelector,
  inAppThemeCodes,
  (themes, themeCodes) => {
    if (!themeCodes) {
      return ThemeFactory()
    }
    return themes
      .filter(t => t.code === themeCodes)
      .toList()
      .get(0, ThemeFactory())
  }
)

export const advancedPushSettingsSelector: extract<{
  deeplink: ?string,
  payload: ?string,
  priority: 'HIGH' | 'NORMAL',
  ttl: ?number,
  collapse: ?string,
  isPush: boolean,
  ...
}> = createSelector(currentCampaign, pushSettingsSelector, (campaign, data) => {
  return {
    isPush: campaign.type.toLowerCase() === 'push',
    deeplink: campaign.hasLanding ? null : data.deeplink,
    payload: data.payload,
    priority: data.priority,
    ttl: data.hasExpiration ? data.expiration : null,
    collapse: data.hasCollapseKey ? data.collapseKey : null,
  }
})
