// @flow

// ========================================================
// APP
// ========================================================
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const POPIN_INTEGRATE_OPEN = 'POPIN_INTEGRATE_OPEN'
export const POPIN_INTEGRATE_CLOSE = 'POPIN_INTEGRATE_CLOSE'
export const POPIN_NEW_OPEN = 'POPIN_NEW_OPEN'
export const POPIN_NEW_CLOSE = 'POPIN_NEW_CLOSE'
export const FETCH_APP_BY_ID = 'FETCH_APP_BY_ID'
export const FETCH_APP_BY_ID_SUCCESS = 'FETCH_APP_BY_ID_SUCCESS'
export const FETCH_APP_BY_ID_FAILURE = 'FETCH_APP_BY_ID_FAILURE'

export const SAVE_PRESSURE = 'SAVE_PRESSURE'
export const SAVE_PRESSURE_SUCCESS = 'SAVE_PRESSURE_SUCCESS'
export const SAVE_PRESSURE_FAILURE = 'SAVE_PRESSURE_FAILURE'
export const DELETE_PRESSURE = 'DELETE_PRESSURE'
export const DELETE_PRESSURE_SUCCESS = 'DELETE_PRESSURE_SUCCESS'
export const DELETE_PRESSURE_FAILURE = 'DELETE_PRESSURE_FAILURE'

export const UPDATE_PRESSURE_CATEGORIE = 'UPDATE_PRESSURE_CATEGORIE'
export const RESET_PRESSURE_CATEGORIE = 'RESET_PRESSURE_CATEGORIE'
export const UPDATE_PRESSURE = 'UPDATE_PRESSURE'
export const REMOVE_PRESSURE = 'REMOVE_PRESSURE'

export const GET_CAMPAIGNS_FROM_OPTIONS = 'GET_CAMPAIGNS_FROM_OPTIONS'
export const REPLICATE_CLEAR_RESULTS = 'REPLICATE_CLEAR_RESULTS'
export const REPLICATE_CAMPAIGNS = 'REPLICATE_CAMPAIGNS'
export const REPLICATE_CAMPAIGN_SUCCESS = 'REPLICATE_CAMPAIGN_SUCCESS'
export const REPLICATE_CAMPAIGN_FAILURE = 'REPLICATE_CAMPAIGN_FAILURE'

export const FETCH_APPS = 'FETCH_APPS'
export const FETCH_APPS_SUCCESS = 'FETCH_APPS_SUCCESS'
export const FETCH_APPS_FAILURE = 'FETCH_APPS_FAILURE'

export const FETCH_PROJECT_APPS = 'FETCH_PROJECT_APPS'
export const FETCH_PROJECT_APPS_SUCCESS = 'FETCH_PROJECT_APPS_SUCCESS'
export const FETCH_PROJECT_APPS_FAILURE = 'FETCH_PROJECT_APPS_FAILURE'

export const FETCH_SDKS = 'FETCH_SDKS'
export const FETCH_SDKS_SUCCESS = 'FETCH_SDKS_SUCCESS'
export const FETCH_SDKS_FAILURE = 'FETCH_SDKS_FAILURE'

export const SAVE_APP = 'SAVE_APP'
export const SAVE_APP_SUCCESS = 'SAVE_APP_SUCCESS'
export const SAVE_APP_FAILURE = 'SAVE_APP_FAILURE'

export const SAVE_PUSH_CONFIG = 'SAVE_PUSH_CONFIG'
export const SAVE_PUSH_CONFIG_SUCCESS = 'SAVE_PUSH_CONFIG_SUCCESS'
export const SAVE_PUSH_CONFIG_FAILURE = 'SAVE_PUSH_CONFIG_FAILURE'

export const UPLOAD_P12 = 'UPLOAD_P12'
export const UPLOAD_P12_SUCCESS = 'UPLOAD_P12_SUCCESS'
export const UPLOAD_P12_FAILURE = 'UPLOAD_P12_FAILURE'

export const SAVE_P12 = 'SAVE_P12'
export const SAVE_P12_SUCCESS = 'SAVE_P12_SUCCESS'
export const SAVE_P12_FAILURE = 'SAVE_P12_FAILURE'

export const ARCHIVE_APP = 'ARCHIVE_APP'
export const ARCHIVE_APP_SUCCESS = 'ARCHIVE_APP_SUCCESS'
export const ARCHIVE_APP_FAILURE = 'ARCHIVE_APP_FAILURE'

export const FETCH_APPSTORE = 'FETCH_APPSTORE'
export const FETCH_APPSTORE_SUCCESS = 'FETCH_APPSTORE_SUCCESS'
export const FETCH_APPSTORE_FAILURE = 'FETCH_APPSTORE_FAILURE'

export const CHECK_WNS = 'CHECK_WNS'
export const CHECK_WNS_SUCCESS = 'CHECK_WNS_SUCCESS'
export const CHECK_WNS_FAILURE = 'CHECK_WNS_FAILURE'

export const GEN_VAPID = 'GEN_VAPID'
export const GEN_VAPID_SUCCESS = 'GEN_VAPID_SUCCESS'
export const GEN_VAPID_FAILURE = 'GEN_VAPID_FAILURE'

export const UPDATE_LOCAL_APP = 'UPDATE_LOCAL_APP'

export const SAVE_DEV_ORIGINS = 'SAVE_DEV_ORIGINS'
export const SAVE_DEV_ORIGINS_SUCCESS = 'SAVE_DEV_ORIGINS_SUCCESS'
export const SAVE_DEV_ORIGINS_FAILURE = 'SAVE_DEV_ORIGINS_FAILURE'

export const DELETE_DEV_ORIGINS = 'DELETE_DEV_ORIGINS'
export const DELETE_DEV_ORIGINS_SUCCESS = 'DELETE_DEV_ORIGINS_SUCCESS'
export const DELETE_DEV_ORIGINS_FAILURE = 'DELETE_DEV_ORIGINS_FAILURE'

// ========================================================
// COMPANY
// ========================================================
export const FETCH_COMPANY = 'FETCH_COMPANY'
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'

// ========================================================
// PUSHTOKENS
// ========================================================
export const FETCH_TOKENS_SUCCESS = 'FETCH_TOKENS_SUCCESS'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_TOKEN_SUCCESS = 'SAVE_TOKEN_SUCCESS'
export const SAVE_TOKEN_FAILURE = 'SAVE_TOKEN_FAILURE'
export const DELETE_TOKEN = 'DELETE_TOKEN'
export const DELETE_TOKEN_SUCCESS = 'DELETE_TOKEN_SUCCESS'
export const DELETE_TOKEN_FAILURE = 'DELETE_TOKEN_FAILURE'
export const TEST_TOKEN = 'TEST_TOKEN'
export const TEST_TOKEN_SUCCESS = 'TEST_TOKEN_SUCCESS'
export const TEST_TOKEN_FAILURE = 'TEST_TOKEN_FAILURE'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'

// ========================================================
// THEMES
// ========================================================
export const FETCH_THEMES = 'FETCH_THEMES'
export const FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS'
export const FETCH_THEMES_FAILURE = 'FETCH_THEMES_FAILURE'

// ========================================================
// CAMPAIGN
// ========================================================
export const DISPLAY_CAMPAIGN_ADVCANCED = 'DISPLAY_CAMPAIGN_ADVCANCED'

export const FETCH_CAMPAIGNS_INIT = 'FETCH_CAMPAIGNS_INIT'

export const UPDATE_PUSH_WHEN = 'UPDATE_PUSH_WHEN'

export const TOGGLE_CAMPAIGN_LABEL = 'TOGGLE_CAMPAIGN_LABEL'

export const FETCH_CAMPAIGN = 'FETCH_CAMPAIGN'
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS'
export const FETCH_CAMPAIGN_FAILURE = 'FETCH_CAMPAIGN_FAILURE'

export const SEARCH_CAMPAIGNS = 'SEARCH_CAMPAIGNS'
export const SEARCH_CAMPAIGNS_SUCCESS = 'SEARCH_CAMPAIGNS_SUCCESS'
export const SEARCH_CAMPAIGNS_FAILURE = 'SEARCH_CAMPAIGNS_FAILURE'

export const SET_CAMPAIGN_LANDING = 'SET_CAMPAIGN_LANDING'

export const SWITCH_PAGE = 'SWITCH_PAGE'

export const SAVE_CAMPAIGN = 'SAVE_CAMPAIGN'
export const SAVE_CAMPAIGN_SUCCESS = 'SAVE_CAMPAIGN_SUCCESS'
export const SAVE_CAMPAIGN_FAILURE = 'SAVE_CAMPAIGN_FAILURE'

export const ARCHIVE_CAMPAIGN = 'ARCHIVE_CAMPAIGN'
export const ARCHIVE_CAMPAIGN_SUCCESS = 'ARCHIVE_CAMPAIGN_SUCCESS'
export const ARCHIVE_CAMPAIGN_FAILURE = 'ARCHIVE_CAMPAIGN_FAILURE'

export const SET_EDITING_CAMPAIGN = 'SET_EDITING_CAMPAIGN'

export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS'
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS'
export const FETCH_CAMPAIGNS_FAILURE = 'FETCH_CAMPAIGNS_FAILURE'

export const FETCH_CAMPAIGN_LABELS_COUNTS = 'FETCH_CAMPAIGN_LABELS_COUNTS'
export const FETCH_CAMPAIGN_LABELS_COUNTS_SUCCESS = 'FETCH_CAMPAIGN_LABELS_COUNTS_SUCCESS'
export const FETCH_CAMPAIGN_LABELS_COUNTS_FINAL = 'FETCH_CAMPAIGN_LABELS_COUNTS_FINAL'
export const FETCH_CAMPAIGN_LABELS_COUNTS_FAILURE = 'FETCH_CAMPAIGN_LABELS_COUNTS_FAILURE'

export const UPDATE_CAMPAIGN_CONFIG = 'UPDATE_CAMPAIGN_CONFIG'

export const RESET_CAMPAIGN_ATTACHMENT = 'RESET_CAMPAIGN_ATTACHMENT'

export const UPDATE_LANDING_THEME = 'UPDATE_LANDING_THEME'

export const UPDATE_IA_TRIGGER = 'UPDATE_IA_TRIGGER'

export const UPDATE_IA_TRIGGER_LABEL = 'UPDATE_IA_TRIGGER_LABEL'

export const UPDATE_IA_CAPPING = 'UPDATE_IA_CAPPING'

export const UPDATE_IA_GRACE = 'UPDATE_IA_GRACE'

export const UPDATE_CAMPAIGN_STATE = 'UPDATE_CAMPAIGN_STATE'

export const UPDATE_CAMPAIGN_START = 'UPDATE_CAMPAIGN_START'

export const UPDATE_CAMPAIGN_END = 'UPDATE_CAMPAIGN_END'

export const UPDATE_CAMPAIGN_TZ_AWARE = 'UPDATE_CAMPAIGN_TZ_AWARE'

export const SET_CAMPAIGN_STATE = 'SET_CAMPAIGN_STATE'
export const SET_CAMPAIGN_STATE_SUCCESS = 'SET_CAMPAIGN_STATE_SUCCESS'
export const SET_CAMPAIGN_STATE_FAILURE = 'SET_CAMPAIGN_STATE_FAILURE'

export const ADD_CAMPAIGN_TRANSLATION = 'ADD_CAMPAIGN_TRANSLATION'

export const SET_CAMPAIGN_ACTIVE_TRANSLATION = 'SET_CAMPAIGN_ACTIVE_TRANSLATION'

export const REMOVE_CAMPAIGN_TRANSLATION = 'REMOVE_CAMPAIGN_TRANSLATION'

export const PUSH_ENABLE_ABTESTING = 'PUSH_ENABLE_ABTESTING'
export const PUSH_DISABLE_ABTESTING = 'PUSH_DISABLE_ABTESTING'
export const PUSH_ABTESTING_TOGGLE_VARIANT = 'PUSH_ABTESTING_TOGGLE_VARIANT'
export const PUSH_ABTESTING_SHOW_VARIANT = 'PUSH_ABTESTING_SHOW_VARIANT'

export const PRESSURE_UPDATE = 'PRESSURE_UPDATE'
export const PRESSURE_SAVE = 'PRESSURE_SAVE'
export const PRESSURE_SAVE_SUCCESS = 'PRESSURE_SAVE_SUCCESS'
export const PRESSURE_SAVE_FAILURE = 'PRESSURE_SAVE_FAILURE'
