// @flow

import { type OrderedMap } from 'immutable'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type IdentifierKind } from 'com.batch/profile/constants/identifier-kind'
import { convertIdentifierKind } from 'com.batch/profile/infra/formats/convert-identifier-kind'
import { getDefaultProfileId } from 'com.batch/profile/infra/formats/get-default-profile-id'
import { type ProfileDataRecord } from 'com.batch/profile/models/profile.records'

type SearchProfileIdByIdentifierAction = {
  type: 'SEARCH_PROFILE_ID_BY_IDENTIFIER',
  ...
}
type SearchProfileIdByIdentifierSuccessAction = {
  type: 'SEARCH_PROFILE_ID_BY_IDENTIFIER_SUCCESS',
  payload: {
    profileId: ?string,
    data: OrderedMap<string, ProfileDataRecord>,
    ...
  },
  ...
}
type SearchProfileIdByIdentifierFailureAction = {
  type: 'SEARCH_PROFILE_ID_BY_IDENTIFIER_FAILURE',
  payload: {
    error: {
      errors: {
        message: string,
        ...
      }[],
      ...
    },
    ...
  },
  ...
}

export type searchProfileIdByIdentifierAction =
  | SearchProfileIdByIdentifierAction
  | SearchProfileIdByIdentifierSuccessAction
  | SearchProfileIdByIdentifierFailureAction

export const searchProfileByIdentifier = ({
  identifier,
  identifierKind,
}: {
  identifier: string,
  identifierKind: IdentifierKind,
  ...
}): DispatchExtraBoundFn<Promise<void>> => {
  return async (dispatch, getState, { debugGateway }) => {
    const project = currentProjectSelector(getState())
    const kind = convertIdentifierKind(identifierKind)

    dispatch({
      type: 'SEARCH_PROFILE_ID_BY_IDENTIFIER',
      payload: null,
    })

    try {
      const profilesData = await debugGateway.fetchProfiles({
        project,
        identifier,
        kind,
      })

      const profileId = getDefaultProfileId(profilesData)
      const eventsData = await debugGateway.fetchEvents({
        project,
        profileId,
      })

      const data = profilesData.set(
        profileId,
        profilesData.get(profileId)?.set('events', eventsData)
      )

      dispatch({
        type: 'SEARCH_PROFILE_ID_BY_IDENTIFIER_SUCCESS',
        payload: { data, profileId },
      })
    } catch (e) {
      console.log(e)
      dispatch({
        type: 'SEARCH_PROFILE_ID_BY_IDENTIFIER_FAILURE',
        payload: e,
      })
      throw e
    }
  }
}
