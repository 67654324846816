// @flow

import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import {
  NotifArea,
  NotifContentTextTitle,
  NotifContentBrowserThumb,
  NotifContentTextMessage,
  NotifContentText,
} from 'components/campaign/preview/preview.styles'
import { webpush } from 'components/campaign/preview/preview.theme'
import { Banner } from 'components/common/banner'
import {
  Box,
  BoxHeader,
  BoxBody,
  BoxSection,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper, Input } from 'components/form'
import { Ellipsis } from 'components/styled/text'

type SafariWebsiteNameModalProps = {
  name: string,
  close: () => any,
  save: (localValue: string) => any,
  opened: boolean,
  isEditing: boolean,
  ...
}

export const SafariWebsiteNameModal = ({
  close,
  name,
  save,
  opened,
  isEditing,
}: SafariWebsiteNameModalProps): React.Node => {
  const [localValue, updateLocalValue] = React.useState<string>(name ?? '')
  const onWebsiteNameChange = React.useCallback(evt => updateLocalValue(evt.target.value), [])
  const isAllowedToUpdate = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
  const onSave = React.useCallback(() => save(localValue), [localValue, save])
  return (
    <ThemeProvider theme={webpush}>
      <Popin
        opened={opened}
        close={close}
        style={{ maxWidth: '1200px', minWidth: '800px', overflowY: 'hidden' }}
      >
        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="Configure website name" />
            <HeaderBoxActions>
              <Button onClick={close}>
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <Banner
            kind="bloc"
            title="Choose website name carefully. It is definitive per user, and future updates will only apply to new subscribers. "
            intent="danger"
          />
          <BoxBody style={{ overflow: 'hidden' }}>
            <Grid template="1fr 1fr" gap={0}>
              <BoxSection $padding style={{ border: 'none', marginRight: 0 }}>
                <InputWrapper label="Website name" htmlFor="website-name">
                  <Input
                    id="website-name"
                    placeholder="My website name"
                    name="websiteName"
                    onChange={onWebsiteNameChange}
                    value={localValue}
                  />
                </InputWrapper>
              </BoxSection>
              <BoxSection $padding style={{ backgroundColor: '#FAFAFA' }}>
                <NotifArea browser="safari" os="mac" style={{ flexDirection: 'column' }}>
                  <div style={{ display: 'flex', marginBottom: '5px' }}>
                    <span
                      style={{
                        display: 'flex',
                        width: '250px',
                        textTransform: 'uppercase',
                        color: '#797979',
                        marginRight: '16px',
                      }}
                    >
                      <NotifContentBrowserThumb browser="safari" os="mac" />
                      <Ellipsis title={localValue}>{localValue}</Ellipsis>
                    </span>
                    <span style={{ color: '#797979' }}>now</span>
                  </div>
                  <NotifContentText>
                    <NotifContentTextTitle browser="safari">Push title</NotifContentTextTitle>
                    <NotifContentTextMessage browser="safari" os="mac">
                      Text content of this push.
                    </NotifContentTextMessage>
                  </NotifContentText>
                </NotifArea>
              </BoxSection>
            </Grid>
          </BoxBody>
          <BoxFooter isEditable>
            <PermissionButton kind="inline" onClick={close} isAllowed={isAllowedToUpdate}>
              Cancel
            </PermissionButton>
            <PermissionButton
              intent="action"
              isAllowed={isAllowedToUpdate}
              disabled={!localValue || (isEditing && localValue === name)}
              onClick={onSave}
            >
              Save name
            </PermissionButton>
          </BoxFooter>
        </Box>
      </Popin>
    </ThemeProvider>
  )
}
