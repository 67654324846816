// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { createPortal } from 'react-dom'

import { useResizeObserver } from 'components/_hooks'
import { ChannelFilter } from 'components/campaign/filters/channel-filter'
import { DateRangeFilter } from 'components/campaign/filters/date-range-filter'
import { useFilters } from 'components/campaign/filters/filters-provider'
import { LabelFilter } from 'components/campaign/filters/label-filter'
import { SourceFilter } from 'components/campaign/filters/source-filter'
import { StatusFilter } from 'components/campaign/filters/status-filter'
import { TypeFilter } from 'components/campaign/filters/type-filter'
import { Button } from 'components/common/button'
import { FlexLine } from 'components/common/flexline'

import {
  CampaignFilterGrid,
  CampaignContainer,
  BackDropContainer,
  CampaignFooter,
  CampaignsFilters,
  MultiFilters,
} from './campaign-list.styles'

import { type CappingCategoryRecord } from 'com.batch.redux/_records'

type CampaignFiltersProps = {
  type: schedulingType,
  closeFilters: () => void,
  isWebPush: boolean,
  isAutomation: boolean,
}

type CampaignPortaledFiltersProp = {
  children: React.Node,
  closeFilters: () => void,
}

// ----- Portal -------

const CampaignPortaledFilters: React.ComponentType<CampaignPortaledFiltersProp> = React.forwardRef(
  ({ children, closeFilters }: CampaignPortaledFiltersProp, ref) => {
    let campaignsFiltersRoot = document.getElementById('campaigns-filters-root')
    if (!campaignsFiltersRoot) {
      campaignsFiltersRoot = document.createElement('div')
      campaignsFiltersRoot.setAttribute('id', 'campaigns-filters-root')
      campaignsFiltersRoot.style.zIndex = '14'
      campaignsFiltersRoot.style.position = 'relative'
      document.body?.prepend(campaignsFiltersRoot)
    }
    // eslint-disable-next-line no-unused-vars
    const [containerRef, _, height] = useResizeObserver()

    React.useEffect(() => {
      if (document.body?.style) document.body.style.minHeight = `${height + 240}px`
      return () => {
        if (document.body?.style) document.body.style.minHeight = '0px'
      }
    }, [height])

    return createPortal(
      <div ref={ref}>
        <CampaignsFilters closeFilters={closeFilters}>
          <CampaignContainer ref={containerRef}>{children}</CampaignContainer>
        </CampaignsFilters>
        <BackDropContainer onClick={closeFilters} />
      </div>,
      campaignsFiltersRoot
    )
  }
)

// Component
export const CampaignFilters: React.ComponentType<CampaignFiltersProps> = React.forwardRef(
  ({ closeFilters, isWebPush, isAutomation }: CampaignFiltersProps, ref) => {
    const {
      filters,
      updateLocalDateRange,
      localActiveFilters,
      setLocalActiveFilters,
      updateLocalFilters,
      gotToURLWithoutFilters,
      goToURLWithNewFilters,
    } = useFilters()

    React.useEffect(() => {
      setLocalActiveFilters(localActiveFilters.set('query', filters.query))
    }, [filters.query, localActiveFilters, setLocalActiveFilters])

    const updateLocalLabels = React.useCallback(
      (label: CappingCategoryRecord) => {
        setLocalActiveFilters(
          localActiveFilters.set(
            'labels',
            localActiveFilters.labels.has(parseInt(label.id))
              ? localActiveFilters.labels.delete(parseInt(label.id))
              : localActiveFilters.labels.add(parseInt(label.id))
          )
        )
      },
      [localActiveFilters, setLocalActiveFilters]
    )

    const clearLabels = React.useCallback(
      () => setLocalActiveFilters(localActiveFilters.set('labels', Immutable.Set())),
      [localActiveFilters, setLocalActiveFilters]
    )

    const props = {
      isEmbedded: true,
      isAutomation: isAutomation,
      localActiveFilters: localActiveFilters,
      onChange: updateLocalFilters,
    }

    return (
      <div ref={ref}>
        <CampaignFilterGrid columnCount={4}>
          {isAutomation ? (
            <MultiFilters>
              {!isWebPush && <ChannelFilter {...props} />}
              <StatusFilter {...props} />
            </MultiFilters>
          ) : (
            <StatusFilter {...props} />
          )}
          <DateRangeFilter {...props} onChange={updateLocalDateRange} />
          <LabelFilter {...props} onChange={updateLocalLabels} onClear={clearLabels} />
          {isAutomation ? (
            <MultiFilters>
              <SourceFilter {...props} />
              <TypeFilter {...props} />
            </MultiFilters>
          ) : (
            <SourceFilter {...props} />
          )}
        </CampaignFilterGrid>
        <CampaignFooter>
          <FlexLine
            vertical={false}
            style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}
          >
            <div className="button-footer-wrapper">
              <Button
                kind="inline"
                onClick={() => {
                  gotToURLWithoutFilters()
                  closeFilters()
                }}
              >
                Clear filters
              </Button>
            </div>

            <div className="button-footer-wrapper">
              <Button
                kind="secondary"
                intent="action"
                onClick={() => {
                  goToURLWithNewFilters()
                  closeFilters()
                }}
              >
                Apply filters
              </Button>
            </div>
          </FlexLine>
        </CampaignFooter>
      </div>
    )
  }
)
export { CampaignPortaledFilters }

CampaignFilters.displayName = 'CampaignListFilters'
