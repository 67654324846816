// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import {
  BrowserIcon,
  ReceivedDate,
  Title,
  Message,
  Notif,
  NotifSettings,
  Sender,
  Thumb,
} from 'components/project/campaign/push/preview/platform/web-mac.styles'

import { MediaField } from './media-field'

import { type ThemeProps } from 'com.batch/message-builder/ui/components/common'
import { EditableField } from 'com.batch/message-builder/ui/components/editable-field'

type WebMacPreviewProps = { messageId: string }
export const WebMacPreview = ({ messageId }: WebMacPreviewProps): React.Node => {
  const { app, isExpanded } = useTheme<ThemeProps>()
  const website = React.useMemo(() => {
    const tmp = app?.bundleId.replace('http://', '').replace('https://', '').split('/')
    if (tmp) {
      return tmp[0]
    }
  }, [app?.bundleId])

  return (
    <Notif>
      <NotifSettings>
        <Icon icon="options" style={{ marginRight: 10 }} />
        <Icon icon="chevron-down" />
      </NotifSettings>
      {isExpanded && (
        <MediaField messageId={messageId} field="media">
          {({ url, overlay }) => <Thumb $url={url}>{overlay}</Thumb>}
        </MediaField>
      )}
      <Title>
        <EditableField field="pushTitle" messageId={messageId} />
      </Title>
      <ReceivedDate>Now</ReceivedDate>
      <BrowserIcon>
        <img src="/medias/icons/chrome-colored.svg" />
      </BrowserIcon>
      <Sender>{website}</Sender>
      <Message>
        <EditableField field="pushBody" messageId={messageId} />
      </Message>
    </Notif>
  )
}
