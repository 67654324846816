// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { HeaderBoxTitle } from 'components/common/box/box'
import { BoxBody, BoxHeader, HeaderBoxActions } from 'components/common/box/box.styles'
import { Button } from 'components/common/button/button.styles'
import { PermissionButton } from 'components/common/button/permission-button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'
import { blue } from 'components/styled/tokens/schemes'

import {
  CappingRuleFactory,
  type CappingRuleRecord,
  type DurationUnit,
} from 'com.batch/capping/model/capping-rule'
import { CappingRuleBox, RowContainer } from 'com.batch/capping/ui/components/capping.styles'
import {
  MAXIMUM_DAYS,
  MAXIMUM_HOURS,
  Rule,
  type DurationUnitOption,
} from 'com.batch/capping/ui/components/rule'
import { type LabelRecord } from 'com.batch/labels/models/labels.records'

const MAX_CAPPING_RULES_PER_LABEL = 4

export const CappingRuleBlock = ({
  label,
  onSave,
  onDelete,
  onCancelNewCappingRules,
  isEditing,
  onEdit,
}: {
  label: LabelRecord,
  onSave: (cappingRules: List<CappingRuleRecord>, labelCode: string) => void,
  onDelete: (cappingRules: List<CappingRuleRecord>) => void,
  onCancelNewCappingRules: (labelCode: string) => void,
  isEditing: boolean,
  onEdit: (labelCode: string | null) => void,
  ...
}): React.Node => {
  const { cappingRules } = label

  const [internalCappingRules, setInternalCappingRules] =
    React.useState<List<CappingRuleRecord>>(cappingRules)

  const isPersisted = React.useMemo(
    () => cappingRules.some(rule => rule.isPersisted),
    [cappingRules]
  )

  const handleEdit = React.useCallback(() => {
    onEdit(label.code)
  }, [onEdit, label.code])

  const handleSave = React.useCallback(() => {
    onSave(internalCappingRules, label.code)
  }, [onSave, internalCappingRules, label.code])

  const handleCancel = React.useCallback(() => {
    if (isPersisted) {
      setInternalCappingRules(cappingRules)
      onEdit(null)
    } else {
      onCancelNewCappingRules(label.code)
    }
  }, [isPersisted, cappingRules, onCancelNewCappingRules, label, onEdit])

  const handleAddAnotherRule = React.useCallback(() => {
    setInternalCappingRules(
      internalCappingRules.push(CappingRuleFactory({ labelCode: label.code }))
    )
  }, [internalCappingRules, label.code])

  // removes leading zeros and special characters
  const formatNumber = React.useCallback(
    (input: number) => parseInt(input.toString().replace(/^0+/, '')),
    []
  )

  const handleOnChangeInputValue = React.useCallback(
    (event: SyntheticEvent<HTMLInputElement>, index: number) => {
      const { valueAsNumber, name } = event.currentTarget

      const nameCasted = ((name: any): $Keys<CappingRuleRecord>)

      const updatedCappingRules = internalCappingRules.update(index, cappingRule =>
        cappingRule.set(nameCasted, formatNumber(valueAsNumber))
      )

      setInternalCappingRules(updatedCappingRules)
    },
    [internalCappingRules, formatNumber]
  )

  const handleOnChangeDropdownValue = React.useCallback(
    (value: DurationUnit, index: number) => {
      const keyName = (('durationUnit': any): $Keys<CappingRuleRecord>)

      const updatedCappingRules = internalCappingRules.update(index, cappingRule =>
        cappingRule.set(keyName, value)
      )

      setInternalCappingRules(updatedCappingRules)
    },
    [internalCappingRules]
  )

  const handleDelete = React.useCallback(() => {
    onDelete(cappingRules)
  }, [onDelete, cappingRules])

  const createOnRemoveRule = React.useCallback(
    (index: number) => () => setInternalCappingRules(internalCappingRules.delete(index)),
    [internalCappingRules]
  )

  const createHandleOnChangeInputValue = React.useCallback(
    (index: number) => (event: SyntheticEvent<HTMLInputElement>) => {
      handleOnChangeInputValue(event, index)
    },
    [handleOnChangeInputValue]
  )

  const createHandleOnChangeDropdownValue = React.useCallback(
    (index: number) => (option: ?DurationUnitOption) => {
      handleOnChangeDropdownValue(option?.value ?? 'DAYS', index)
    },
    [handleOnChangeDropdownValue]
  )

  React.useEffect(() => {
    if (isEditing) return
    setInternalCappingRules(cappingRules)
  }, [cappingRules, setInternalCappingRules, isEditing])

  const maxDurationExceeded = React.useMemo(() => {
    return internalCappingRules.some(
      rule =>
        (rule.durationValue > MAXIMUM_HOURS && rule.durationUnit === 'HOURS') ||
        (rule.durationValue > MAXIMUM_DAYS && rule.durationUnit === 'DAYS')
    )
  }, [internalCappingRules])

  const noValue = React.useMemo(
    () => internalCappingRules.some(rule => !rule.capping || !rule.durationValue),
    [internalCappingRules]
  )

  return (
    <CappingRuleBox
      style={{ border: isEditing ? `2px solid ${blue[50]}` : `1px solid ${colors.stroke}` }}
    >
      <BoxHeader style={{ padding: '0 8px 0 20px' }}>
        <HeaderBoxTitle title={label.description} />
        <HeaderBoxActions style={{ gap: isEditing ? 8 : 4, marginRight: 0 }}>
          {isEditing ? (
            <Button onClick={handleCancel}>Cancel</Button>
          ) : (
            <PermissionButton
              kind="inline"
              onClick={handleEdit}
              isAllowed={true}
              data-testid="button_capping_edit"
            >
              <Icon icon="edit" />
            </PermissionButton>
          )}
          {isEditing ? (
            <Tooltip
              tooltip={
                maxDurationExceeded
                  ? 'Impossible to save a rule with a duration greater than 30 days.'
                  : 'Impossible to save a rule with no value.'
              }
              isTooltipEmpty={!maxDurationExceeded && !noValue}
              placement="bottom"
            >
              <div>
                <Button
                  kind="secondary"
                  onClick={handleSave}
                  type="submit"
                  intent="action"
                  data-testid="button_capping_save"
                  disabled={noValue || maxDurationExceeded}
                >
                  Save
                </Button>
              </div>
            </Tooltip>
          ) : (
            <PermissionButton
              kind="inline"
              onClick={handleDelete}
              isAllowed={true}
              data-testid="button_capping_delete"
            >
              <Icon icon="delete" />
            </PermissionButton>
          )}
        </HeaderBoxActions>
      </BoxHeader>
      <BoxBody style={{ padding: '12px 20px', backgroundColor: colors.fillDepth }}>
        {internalCappingRules.map((cappingRule, index) => (
          <RowContainer key={index}>
            <Rule
              cappingRule={cappingRule}
              onChangeInput={createHandleOnChangeInputValue(index)}
              onChangeDropdown={createHandleOnChangeDropdownValue(index)}
              isEditing={isEditing}
              testId={`capping_rule_${index}`}
            />
            {isEditing && internalCappingRules.size > 1 && (
              <PermissionButton
                onClick={createOnRemoveRule(index)}
                isAllowed={true}
                data-testid={`button_capping_delete_rule_${index}`}
                style={{ marginRight: 8 }}
              >
                <Icon icon="delete" />
              </PermissionButton>
            )}
            {isEditing && index === internalCappingRules.size - 1 && (
              <Tooltip
                tooltip="Impossible to create more than 4 rules per label."
                isTooltipEmpty={internalCappingRules.size < MAX_CAPPING_RULES_PER_LABEL}
                placement="bottom"
              >
                <div>
                  <Button
                    onClick={handleAddAnotherRule}
                    disabled={internalCappingRules.size >= MAX_CAPPING_RULES_PER_LABEL}
                    data-testid="button_capping_add_rule"
                  >
                    <Icon icon="add" style={{ paddingRight: '8px' }} />
                    Add another rule
                  </Button>
                </div>
              </Tooltip>
            )}
          </RowContainer>
        ))}
      </BoxBody>
    </CappingRuleBox>
  )
}
