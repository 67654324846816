// @flow

import { type Dayjs } from 'dayjs'
import Immutable from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type BounceDetailRecord } from 'com.batch/orchestration-analytics/models/bounce-detail-record'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'

export const getDailyRange = ({
  bounces,
  from,
  to,
}: {
  bounces: Immutable.Map<string, BounceDetailRecord>,
  from: Dayjs,
  to: Dayjs,
}): BarChartData[] => {
  const diff = to.diff(from, 'day') + 1
  const computedDateRange = Array.from({ length: diff }, (_, index) => from.add(index, 'day'))
  let isFirst = true
  return computedDateRange.reduce((range, date) => {
    const bounce = bounces.get(date.format('YYYY-MM-DD'))
    if (isFirst) {
      if (!bounce) return range
    }
    isFirst = false
    range.push({
      date: date,
      hard: {
        value: bounce?.hard?.value ?? 0,
        rate: bounce?.hard?.rate ?? 0,
      },
      soft: {
        value: bounce?.soft?.value ?? 0,
        rate: bounce?.soft?.rate ?? 0,
      },
      block: {
        value: bounce?.block?.value ?? 0,
        rate: bounce?.block?.rate ?? 0,
      },
    })
    return range
  }, [])
}

export const getTemporalityRange = ({
  bounces,
  from,
  to,
  unit,
}: {
  bounces: Immutable.Map<string, BounceDetailRecord>,
  from: Dayjs,
  to: Dayjs,
  unit: 'week' | 'month',
}): BarChartData[] => {
  const computedDateRange = Array.from({ length: to.diff(from, 'day') + 1 }, (_, index) =>
    from.add(index, 'day')
  )
  let total = 0
  let hard = 0
  let soft = 0
  let block = 0
  let hasStarted = false
  let dateFrom: Dayjs
  let isFirstTemporality = true
  return computedDateRange.reduce((range, date) => {
    const bounce = bounces.get(date.format('YYYY-MM-DD'))
    const isStartOfTemporality =
      unit === 'week' ? date.day() === 1 : date.date() === date.startOf(unit).date()
    if (!hasStarted && bounce && bounce?.total > 0) {
      dateFrom = isStartOfTemporality
        ? date
        : unit === 'week'
          ? date.startOf(unit).add(1, 'day')
          : date.startOf(unit)
      hasStarted = true
    } else if (isStartOfTemporality) {
      dateFrom = date
    }
    total += bounce?.total ?? 0
    hard += bounce?.hard?.value ?? 0
    soft += bounce?.soft?.value ?? 0
    block += bounce?.block?.value ?? 0
    const isToday = date.format('YYYYMMDD') === dayjs.utc().format('YYYYMMDD')
    const isEndOfTemporality =
      unit === 'week' ? date.day() === 0 : date.date() === date.endOf(unit).date()
    const isEndOfRange = date.format('YYYYMMDD') === to.format('YYYYMMDD')
    if (hasStarted && (isToday || isEndOfTemporality || isEndOfRange)) {
      if (isFirstTemporality) {
        isFirstTemporality = false
      }
      range.push({
        date: dateFrom,
        hard: {
          value: hard,
          rate: total > 0 ? hard / total : 0,
        },
        soft: {
          value: soft,
          rate: total > 0 ? soft / total : 0,
        },
        block: {
          value: block,
          rate: total > 0 ? block / total : 0,
        },
      })
      total = 0
      hard = 0
      soft = 0
      block = 0
    }

    return range
  }, [])
}
