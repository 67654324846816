// @flow
import styled, { type StyledComponent, css } from 'styled-components'

import { LoadingSvgAnim } from 'components/common/empty-states'
import { texts, schemes } from 'components/styled/tokens'

export const SvgTimeline: StyledComponent<*, *, *> = styled.svg`
  #timeline-item-gradient-start {
    .stop1 {
      stop-color: white;
      stop-opacity: 0;
    }
    .stop2 {
      stop-color: white;
      stop-opacity: 1;
    }
    .stop3 {
      stop-color: white;
      stop-opacity: 1;
    }
    .stop4 {
      stop-color: white;
      stop-opacity: 0;
    }
  }
  #timeline-item-gradient-end {
    .stop1 {
      stop-color: blue;
      stop-opacity: 1;
    }
    .stop2 {
      stop-color: blue;
      stop-opacity: 0;
    }
  }
`

type TimelineItemHoverWrapperProps = { x: number, ... }
export const TimelineItemHoverWrapper: StyledComponent<TimelineItemHoverWrapperProps, *, *> =
  styled.g`
    transform: ${(props: TimelineItemHoverWrapperProps) => `translate3d(${props.x}px, 0px, 0px)`};
    transition: all 0.1s ease-out 0s;
  `

export const TimeItem: StyledComponent<*, *, *> = styled.text`
  ${texts.legend}
`

type TimelineItemSkeletonProps = { isLoading?: boolean, isEmpty?: boolean, ... }
export const TimelineItemSkeleton: StyledComponent<TimelineItemSkeletonProps, *, *> = styled.rect`
  ${(p: TimelineItemSkeletonProps) =>
    p.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}

  ${(p: TimelineItemSkeletonProps) =>
    p.isEmpty &&
    css`
      fill: ${schemes.grayscale['10']};
    `}
`
