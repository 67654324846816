// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type List } from 'immutable'
import { get as _get, forEach as _forEach } from 'lodash-es'
import request from 'superagent-interface-promise'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'

import { type AppRecord } from 'com.batch.redux/_records'
import {
  type AnalyticByPeriodRecord,
  type ReachByDayRecord,
  type AnalyticByRegionRecord,
  type GDPRByDayRecord,
  ReachCountFactory,
  ReachDataFactory,
  GDPRByDayFactory,
  ReachChangesFactory,
  ReachByDayFactory,
  AnalyticByPeriodFactory,
  AnalyticByRegionFactory,
  AnalyticDataFactory,
} from 'com.batch.redux/stat.records'

// ====================== FETCH DATA BY PERIOD
export const fetchAnalyticsByDay = ({
  app,
  start,
  end,
  devMode,
  dimension,
  granularity = 'day',
}: {
  app: AppRecord,
  start: Dayjs,
  end: Dayjs,
  devMode: boolean,
  dimension: 'none' | 'events',
  granularity: 'day' | 'hour',
  ...
}): Promise<List<AnalyticByPeriodRecord>> => {
  return request
    .get(
      `/api/app/${app.id}/data/analytics/${start.format('YYYYMMDD')}/${end.format(
        'YYYYMMDD'
      )}/${dimension}/${devMode ? 'dev' : 'prod'}/${granularity}`
    )
    .then(
      response => {
        const data = _get(response.body, 'results.data[0]', {})
        const periods = _get(response.body, 'results.periods', [])
        let results = []
        periods.forEach((periodString, indice) => {
          let events = []
          _forEach(data, (values, key: string) => {
            if (key.substring(0, 2) === 'e.' || key.substring(0, 3) === 'ue.') {
              events.push([key, values[indice]])
            }
          })
          results.push(
            AnalyticByPeriodFactory({
              period: dayjs.utc(
                periodString,
                granularity === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH'
              ),
              data: AnalyticDataFactory({
                daus: _get(data, ['daus', indice], 0),
                maus: _get(data, ['maus', indice], 0),
                starts: _get(data, ['starts', indice], 0),
                installs: _get(data, ['installs', indice], 0),
                transactions: _get(data, ['transactions_sum', indice], 0),
                tokens: _get(data, ['push_token', indice], 0),
                pushes: _get(data, ['pushs_sent', indice], 0),
                deletedTokens: _get(data, ['push_tokens_deleted', indice], 0),
                optOut: _get(data, ['pushs_opt-out', indice], 0),
              }),
              events: Immutable.Map(events),
            })
          )
        })
        return new Immutable.List().push(...results)
      },
      error => {
        throw error.body
      }
    )
}

// ====================== FETCH DATA BY REGION
export const fetchAnalyticsByRegion = ({
  app,
  start,
  end,
  devMode,
}: {
  app: AppRecord,
  start: Dayjs,
  end: Dayjs,
  devMode: boolean,
  ...
}): Promise<List<AnalyticByRegionRecord>> => {
  return request
    .get(
      `/api/app/${app.id}/data/analytics/${start.format('YYYYMMDD')}/${end.format(
        'YYYYMMDD'
      )}/region/${devMode ? 'dev' : 'prod'}`
    )
    .then(
      response => {
        const data = _get(response.body, 'results.data', [])
        const results = data.map(regionByDay => {
          return AnalyticByRegionFactory({
            region: _get(regionByDay, ['dimensions', 'region'], ''),
            data: AnalyticDataFactory({
              daus: _get(regionByDay, 'daus', 0),
              maus: _get(regionByDay, 'maus', 0),
              starts: _get(regionByDay, 'starts', 0),
              installs: _get(regionByDay, 'installs', 0),
              transactions: _get(regionByDay, 'transactions_sum', 0),
              tokens: _get(regionByDay, 'push_token', 0),
              pushes: _get(regionByDay, 'pushs_sent', 0),
              deletedTokens: _get(regionByDay, 'push_tokens_deleted', 0),
              optOut: _get(regionByDay, 'pushs_opt-out', 0),
            }),
          })
        })
        return new Immutable.List().push(...results)
      },
      error => {
        throw error.body
      }
    )
}

// -----------------------------------------------------------------------
// fetchReach
// query Stephane for reach data
// -----------------------------------------------------------------------
type fetchReachResponse = {
  results: Array<{
    period: string,
    count: {
      tokens: number,
      tokensNotifOn: number,
      ...
    },
    changes: {
      toNotifOn: number,
      toNotifOff: number,
      newTokens: number,
      newTokensNotifOn: number,
      deletedTokens: number,
      deletedTokensNotifOn: number,
      ...
    },
    byCountry: [
      {
        country: string,
        count: {
          tokens: number,
          tokensNotifOn: number,
          ...
        },
        changes: {
          toNotifOn: number,
          toNotifOff: number,
          newTokens: number,
          newTokensNotifOn: number,
          deletedTokens: number,
          deletedTokensNotifOn: number,
          ...
        },
        ...
      },
    ],
    ...
  }>,
  ...
}

const rawToData = (data: {
  count: {
    tokens: number,
    tokensNotifOn: number,
    ...
  },
  changes: {
    toNotifOn: number,
    toNotifOff: number,
    newTokens: number,
    newTokensNotifOn: number,
    deletedTokens: number,
    deletedTokensNotifOn: number,
    ...
  },
  ...
}) => {
  return ReachDataFactory({
    count: ReachCountFactory({
      tokens: data.count.tokens,
      tokensNotifOn: data.count.tokensNotifOn,
    }),
    changes: ReachChangesFactory({
      toNotifOn: _get(data, ['changes', 'toNotifOn'], 0),
      toNotifOff: _get(data, ['changes', 'toNotifOff'], 0),
      newTokens: _get(data, ['changes', 'newTokens'], 0),
      newTokensNotifOn: _get(data, ['changes', 'newTokensNotifOn'], 0),
      deletedTokens: _get(data, ['changes', 'deletedTokens'], 0),
      deletedTokensNotifOn: _get(data, ['changes', 'deletedTokensNotifOn'], 0),
    }),
  })
}

export const fetchReach = ({
  app,
  start,
  end,
}: {
  app: AppRecord,
  start: Dayjs,
  end: Dayjs,
  ...
}): Promise<List<ReachByDayRecord>> => {
  return request
    .get<fetchReachResponse>(
      `/api/app/${app.id}/data/reach/${start.format('YYYYMMDD')}/${end.format('YYYYMMDD')}`
    )
    .then(
      response => {
        return Immutable.List(
          response.body.results.map(rawDay => {
            return ReachByDayFactory({
              date: dayjs.utc(rawDay.period, 'YYYY-MM-DD'),
              data: rawToData(rawDay), // handle by country stuff when stephane will
            })
          })
        )
      },
      error => {
        throw error.error
      }
    )
}

export const fetchGDPR = ({
  app,
  start,
  end,
}: {
  app: AppRecord,
  start: Dayjs,
  end: Dayjs,
  ...
}): Promise<{ error?: boolean, hasData: boolean, data: List<GDPRByDayRecord>, ... }> => {
  let obj: { [string]: GDPRByDayRecord, ... } = {} // en key la date YYYY-MM-DD et en value un record
  let s = dayjs(start)
  let total = 0
  while (s.isBefore(end)) {
    obj[s.format('YYYY-MM-DD')] = GDPRByDayFactory({
      date: dayjs(s),
    })
    s = s.add(1, 'day')
  }

  return request
    .get(
      generateUrl('api_data_gdpr', {
        appId: app.id,
        from: start.format('YYYYMMDD'),
        to: end.format('YYYYMMDD'),
      })
    )
    .then(response => {
      const arrByDay = response?.body?.results?.byPeriod ?? []
      const objTotal = response?.body?.results?.total ?? {}
      const hasData =
        _get(objTotal, 'dashboard', 0) + _get(objTotal, 'sdk', 0) + _get(objTotal, 'api', 0) > 0
      arrByDay.forEach(rawDay => {
        const rec = GDPRByDayFactory({
          date: dayjs.utc(rawDay.period, 'YYYY-MM-DD'),
          dashboard:
            _get(rawDay, 'dashboard.remove-data', 0) + _get(rawDay, 'dashboard.review-data', 0),
          api: _get(rawDay, 'api.remove-data', 0) + _get(rawDay, 'api.review-data', 0),
          mobile: _get(rawDay, 'sdk.remove-data', 0) + _get(rawDay, 'sdk.review-data', 0),
        })
        obj[rawDay.period] = rec
        total = total + rec.mobile + rec.api + rec.dashboard
      })
      return { hasData, data: new Immutable.List().push(...(total > 0 ? Object.values(obj) : [])) }
    })
    .catch(() => ({ error: true }))
}
