// @flow
import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

import { type MessageBuilderField } from 'com.batch/message-builder/models/message-builder-field'

type InlineEditorStateProps = {
  variant: 'a' | 'b',
  selection: [number, number],
  field: MessageBuilderField,
  personalizationModalIsOpen: boolean,
}
export type InlineEditorStateRecord = RecordOf<InlineEditorStateProps>

export const InlineEditorStateFactory: RecordFactory<InlineEditorStateProps> = Immutable.Record(
  ({
    variant: 'a',
    selection: [0, 0],
    field: null,
    personalizationModalIsOpen: false,
  }: InlineEditorStateProps)
)
