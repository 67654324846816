// @flow

import {
  type IdentifierKind,
  type IdentifierKindCC,
} from 'com.batch/profile/constants/identifier-kind'

export const convertIdentifierKind = (identifierKind: IdentifierKind): IdentifierKindCC => {
  switch (identifierKind) {
    case 'custom_id':
      return 'customID'
    case 'installation_id':
      return 'installID'
    default:
      return 'email'
  }
}

export const stringifyIdentifierKind = (identifierKind: IdentifierKindCC): string => {
  switch (identifierKind) {
    case 'customID':
      return 'customID'
    case 'installID':
      return 'installID'
    default:
      return 'email'
  }
}
