// @flow
import Immutable, { type Map } from 'immutable'
import { map as _map, get as _get, toPairs as _toPairs, keyBy as _keyBy } from 'lodash-es'
import request from 'superagent-interface-promise'

import { ensureSameOrderForRoles } from 'components/account/team/utils'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router/router'

import { type CompanyRecord } from 'com.batch.redux/_records'
import {
  type UserRecord,
  type onboardingStepType,
  type groupOnlyPermissionType,
  UserFactory,
  CompanyUserPermissionsFactory,
  EntityLogFactory,
} from 'com.batch.redux/user.records'

export const normalizeUser = (raw: {
  id: number,
  externalId: string,
  email: string,
  nextEmail: string,
  firstname: string,
  invitedAt?: string,
  avatarUrl: ?string,
  isCurrentlyInvited?: boolean,
  createdAt: string,
  lastLogin: ?string,
  lastAccess: ?string,
  onboardingCompletedSteps?: Array<onboardingStepType>,
  hasTotpSecret: boolean,
  hasCompanyWithTwoFactorAuthEnforcement: boolean,
  name: string,
  restrictedLanguages: string[],
  roles?: ?(string[]),
  restrictedRegions: string[],
  companiesPermissions: {
    [number]: {
      permissions: Array<groupOnlyPermissionType>,
      allowedApps: Array<number>,
      ...
    },
    ...
  },
  logs?: Array<{
    createdAt: string,
    editor: string,
    topic: 'security' | 'info',
    message: string,
    ...
  }>,
  tokens?: Array<{ context: string, value: string, ... }>,
  mfaMethod: 'sso' | 'totp' | 'password',
  ...
}): UserRecord => {
  const companiesPermissions = _map(
    raw.companiesPermissions,
    (
      {
        permissions,
        allowedApps,
      }: { permissions: Array<groupOnlyPermissionType>, allowedApps: Array<number>, ... },
      companyId: string
    ) => {
      return [
        parseInt(companyId),
        CompanyUserPermissionsFactory({
          permissions: Immutable.OrderedSet(ensureSameOrderForRoles(permissions)),
          apps: Immutable.Set(allowedApps),
        }),
      ]
    }
  )

  return UserFactory({
    id: raw.id,
    externalId: raw.externalId,
    email: raw.email,
    nextEmail: raw.nextEmail,
    firstName: raw.firstname,
    avatarUrl: raw.avatarUrl,
    lastInvite:
      typeof raw.invitedAt === 'string' ? dayjs.utc(raw.invitedAt, 'YYYY-MM-DD HH:mm:ss') : null,
    isInvite: !!raw.isCurrentlyInvited,
    lastName: raw.name,
    roles:
      typeof raw.roles !== 'undefined' && Array.isArray(raw.roles)
        ? Immutable.OrderedSet(raw.roles)
        : Immutable.OrderedSet(),
    onboardingStep:
      typeof raw.onboardingCompletedSteps !== 'undefined'
        ? Immutable.OrderedSet(raw.onboardingCompletedSteps)
        : Immutable.OrderedSet(['password', '2fa', 'company']),
    createdAt: dayjs(raw.createdAt, 'YYYY/MM/DD HH:mm'),
    lastLogin: raw.lastLogin ? dayjs(raw.lastLogin, 'YYYY/MM/DD HH:mm') : null,
    lastAccess: raw.lastAccess ? dayjs(raw.lastAccess) : null,
    securedBy2FA: raw.hasTotpSecret,
    mustBeSecuredBy2FA: raw.hasCompanyWithTwoFactorAuthEnforcement,
    restrictedLanguages: Immutable.Set(raw.restrictedLanguages),
    restrictedRegions: Immutable.Set(raw.restrictedRegions),
    permissionsForCurrentCompany: Immutable.Set(_get(window, 'userPermissionsForCompany', [])),
    permissionsForCurrentApp: Immutable.Set(_get(window, 'userPermissionsForApp', [])),
    companiesPermissions: Immutable.Map(companiesPermissions),
    logs: new Immutable.List().push(
      ...(typeof raw.logs !== 'undefined'
        ? raw.logs.reverse().map(rl => {
            return EntityLogFactory({
              when: dayjs.utc(rl.createdAt),
              editor: rl.editor,
              message: rl.message,
              topic: rl.topic,
            })
          })
        : [])
    ),
    tokens: Immutable.Map(
      typeof raw.tokens !== 'undefined'
        ? raw.tokens.reduce(
            (tokens, { context, value }) => {
              tokens[context] = value
              return tokens
            },
            ({}: { [string]: string, ... })
          )
        : {}
    ),
    mfaMethod: raw.mfaMethod,
  })
}

export const listByCompany = (company: CompanyRecord): Promise<Map<number, UserRecord>> => {
  const url = generateUrl('api_user_list_by_company', { companyId: company.id })

  return request
    .get(url)
    .then(response => {
      const users = _toPairs(_keyBy(response.body, 'id')).map(([id, user]) => [
        parseInt(id),
        normalizeUser(user),
      ])

      return Immutable.Map(users)
    })
    .catch(error => {
      console.error(error)
      throw { error: 'Unable to retrieve company users' }
    })
}

export const updateUser = (user: UserRecord): Promise<UserRecord> => {
  return request.post(generateUrl('api_account_update'), user).then(
    response => {
      return normalizeUser(response.body)
    },
    response => {
      throw {
        user,
        errors: response.body.errors,
      }
    }
  )
}

export const updatePassword = ({
  user,
  currentPassword,
  newPassword,
}: {
  user: UserRecord,
  currentPassword: string,
  newPassword: string,
  ...
}): Promise<UserRecord> => {
  return request
    .post(generateUrl('api_account_password'), { currentPassword, newPassword })
    .set('X-RateLimit-Id', `${user.email}-password`)
    .then(
      response => {
        return normalizeUser(response.body)
      },
      response => {
        throw {
          user,
          errors: response.body.errors,
        }
      }
    )
}

export const inviteUser = ({
  user,
  company,
}: {
  user: UserRecord,
  company: CompanyRecord,
  ...
}): Promise<{ user: UserRecord, company: CompanyRecord, ... }> => {
  const url = generateUrl('api_user_invite', {
    companyId: company.id,
  })
  let data = {
    email: user.email,
    permissions: user.companiesPermissions
      .getIn([company.id, 'permissions'], new Immutable.List())
      .toIndexedSeq(),
    allowedApps: user.companiesPermissions.getIn([company.id, 'apps'], Immutable.Set()).toArray(),
  }
  const canUseAppRestriction =
    (company.planFeaturesCode.has('app-user-restriction') ||
      company.additionalFeaturesCode.has('app-user-restriction')) &&
    !company.disabledFeaturesCode.has('app-user-restriction')

  if (!canUseAppRestriction) {
    delete data.allowedApps
  }

  return request.post(url, data).then(
    ({ body }) => ({
      user: normalizeUser(body),
      company,
    }),
    ({ body }) => {
      throw body
    }
  )
}

export const resendInvite = (company: CompanyRecord, user: UserRecord): Promise<any> => {
  const url = generateUrl('api_user_invite_resend', {
    userId: user.id,
    companyId: company.id,
  })
  return request.put(url).then(
    ({ body }) => normalizeUser(body),
    () => {
      throw user
    }
  )
}

export const deleteUserForCompany = (
  company: CompanyRecord,
  user: UserRecord
): Promise<UserRecord> => {
  const url = generateUrl('api_company_delete_user_for_company', {
    companyId: company.id,
    userId: user.id,
  })
  return request.delete(url).then(
    () => user,
    ({ body }) => {
      throw body
    }
  )
}

export const updatePermissions = (
  company: CompanyRecord,
  user: UserRecord
): Promise<UserRecord> => {
  const companyId = company.id
  if (!companyId) {
    throw new Error("Company's ID must be defined.")
  }

  const url = generateUrl('api_user_update_permissions', {
    companyId: companyId,
    userId: user.id,
  })
  let data = {
    companyPermissions: user.companiesPermissions
      .getIn([companyId, 'permissions'], new Immutable.List())
      .toIndexedSeq(),
    allowedApps: user.companiesPermissions.getIn([companyId, 'apps'], Immutable.Set()).toArray(),
  }
  const canUseAppRestriction =
    (company.planFeaturesCode.has('app-user-restriction') ||
      company.additionalFeaturesCode.has('app-user-restriction')) &&
    !company.disabledFeaturesCode.has('app-user-restriction')

  if (!canUseAppRestriction) {
    delete data.allowedApps
  }

  return request
    .put(url)
    .send(data)
    .then(
      ({ body }) => normalizeUser(body),
      ({ body }) => Promise.reject({ errors: body.errors, user })
    )
}

export const disable2FA = (user: UserRecord): Promise<UserRecord> => {
  return request.post(generateUrl('api_2fa_disable')).then(
    () => {
      return user.set('securedBy2FA', false).set('loading', false)
    },
    ({ body }) => {
      throw {
        user,
        errors: body.errors,
      }
    }
  )
}

export const enable2FA = ({
  user,
  code,
  totpUri,
}: {
  user: UserRecord,
  code: string,
  totpUri: string,
  ...
}): Promise<{ user: UserRecord, codes: Array<string>, ... }> => {
  return request.post(generateUrl('api_2fa_enable'), { totpCode: code, totpUri }).then(
    response => {
      return {
        user: user.set('securedBy2FA', true).set('loading', false),
        codes: response.body.codes,
      }
    },
    ({ body }) => {
      throw {
        user,
        errors: body.errors,
      }
    }
  )
}

export const generate2FA: () => Promise<{ svg: string, uri: string, ... }> = () => {
  return request.get(generateUrl('api_2fa_generate')).then(response => {
    return { svg: response.body.qrCodeSvg, uri: response.body.totpUri }
  })
}
