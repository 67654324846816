// @flow

import { type List } from 'immutable'

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ProfileAudienceRecord } from 'com.batch/audience/model/audience-profile.records'

type FetchAudiencesByNameAction = ReduxAction<'FETCH_AUDIENCES_BY_NAME', null>

export type FetchAudiencesByNameSuccessAction = ReduxAction<
  'FETCH_AUDIENCES_BY_NAME_SUCCESS',
  List<ProfileAudienceRecord>,
>
export type FetchAudiencesByNameFailureAction = ReduxAction<
  'FETCH_AUDIENCES_BY_NAME_FAILURE',
  string,
>

export type FetchAudiencesByNameActions =
  | FetchAudiencesByNameAction
  | FetchAudiencesByNameSuccessAction
  | FetchAudiencesByNameFailureAction

export const fetchAudiencesByName = ({
  names,
}: {
  names: Array<string>,
  ...
}): DispatchExtraBoundFn<Promise<any>> => {
  return async (dispatch, getState, { audienceServiceApi }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    console.log('names', names)
    return promiseActionCreator({
      dispatch,
      promise: audienceServiceApi.findAudiencesByName({
        projectKey: project.projectKey,
        namesAndIdx: names.map(name => ({ name })),
      }),
      actionName: 'FETCH_AUDIENCES_BY_NAME',
    })
  }
}
