// @flow
import * as React from 'react'

export function useCombinedRefs(
  outerRef: React$ElementRef<React$AbstractComponent<*, *>>,
  innerRef: React$ElementRef<React$AbstractComponent<*, *>>
): React$ElementRef<React$AbstractComponent<*, *>> {
  const targetRef = React.useRef()
  React.useEffect(() => {
    if (typeof outerRef === 'function') {
      outerRef(targetRef.current)
    } else if (outerRef !== null) {
      outerRef.current = targetRef.current
    }
    if (typeof innerRef === 'function') {
      innerRef(targetRef.current)
    } else if (innerRef !== null) {
      innerRef.current = targetRef.current
    }
  }, [outerRef, innerRef])

  return targetRef
}
