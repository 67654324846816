// @flow

import { defer } from 'lodash-es'
import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { CodesButton } from 'components/authentication/register/register-2fa'
import { Register2FARecovery as Settings2FARecovery } from 'components/authentication/register/register-2fa-recovery'
import { Register2FASetup as Settings2FASetup } from 'components/authentication/register/register-2fa-setup'
import {
  RegisterBlock as SettingsBlock,
  RegisterSuccessLabel,
} from 'components/authentication/register/register.styles'
import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'

import { Icon } from '../common/svg-icon'
import { generate2FA } from 'com.batch.redux/user.api'
import { type UserRecord } from 'com.batch.redux/user.records'

export type Security2FAProps = {
  user: UserRecord,
  enable2FA: ({ user: UserRecord, code: string, totpUri: string }) => Promise<any>,
  close: () => void,
}

export const Security2FA = ({ user, enable2FA, close }: Security2FAProps): React.Node => {
  const [step, setStep] = React.useState(0)
  const [QRCodeUri, setQRCodeUri] = React.useState('')
  const [totpUri, setTotpUri] = React.useState('')
  const [codes, setCodes] = React.useState([])

  const _validate = React.useCallback(
    (code: string) => {
      // $FlowFixMe THUNKABLE
      return enable2FA({ user, code, totpUri }).then(res => {
        if (typeof res.errors === 'undefined') {
          setCodes(res.codes)
          defer(() => setStep(1))
          return true
        } else {
          return res.errors[0].message
        }
      })
    },
    [enable2FA, totpUri, user]
  )

  React.useEffect(() => {
    generate2FA().then(({ svg, uri }) => {
      setQRCodeUri(URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' })))
      setTotpUri(uri)
    })
  }, [])

  return (
    <Popin opened close={close} style={{ overflowY: 'hidden' }}>
      <Box style={{ width: 500 }}>
        <BoxHeader>
          <HeaderBoxTitle
            title="Enable two-factor authentication"
            suffix={
              step >= 1 && (
                <RegisterSuccessLabel
                  style={{ margin: '-5px 0 -5px 8px', transform: 'translate3d(0, -2px, 0)' }}
                >
                  Enabled
                </RegisterSuccessLabel>
              )
            }
          />
          <HeaderBoxActions>
            <Button onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody2FA style={{ maxHeight: 'calc(90vh - 180px)', overflowY: 'auto' }}>
          {step === 0 && <Settings2FASetup qrCodeUri={QRCodeUri} validate={_validate} />}
          {step === 1 && <Settings2FARecovery codes={codes} active={false} />}
        </BoxBody2FA>
        <BoxFooter isEditable={step === 0}>
          {step === 0 && (
            <Button kind="inline" intent="neutral" onClick={close}>
              Cancel
            </Button>
          )}
          {step === 1 && (
            <Button kind="primary" intent="action" onClick={close}>
              {CodesButton}
            </Button>
          )}
        </BoxFooter>
      </Box>
    </Popin>
  )
}

export const BoxBody2FA: StyledComponent<*, { ... }, typeof BoxBody> = styled(BoxBody)`
  padding: 6px 6px 4px 6px;
  max-height: 64vh;
  overflow: auto;

  ${SettingsBlock} {
    animation: none;

    }
  }
  ${SettingsBlock}:after{
    top: -6px;
  }
  ${SettingsBlock}:first-child:after{
    display: none;
  }
  ${SettingsBlock} + ${SettingsBlock} {
    margin: 8px 0 0 0;
  }
`

export default Security2FA
