// @flow

import styled, { type StyledComponent } from 'styled-components'

import { HintIcon } from 'components/common/hint'
import { LoaderContainer } from 'components/common/loader/loader.styles'
import { OptionContainer } from 'components/form/fields/checkbox.styles'
import { DayPickerContainer } from 'components/form/fields/date-picker/date-picker.styles'
import { InputContainer } from 'components/form/styles'
import { colors } from 'components/styled/tokens'

export const DatesWrapper: StyledComponent<{ ... }, *, *> = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  .row-split {
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${HintIcon} {
    vertical-align: baseline;
    margin:-6px 0 -6px 0';
    color: ${colors.textDisabled};
    cursor: pointer;
    &:hover {
      color: ${colors.text};
    }
  }
  .dates-label {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #494949;
    text-align: left;
    font-weight: 500;
  }
`

export const LoaderText: StyledComponent<*, *, *> = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 343px;
  .loader-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 12px;
    $color: ${colors.text};
  }
  .loader-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.textLight};
  }
  ${LoaderContainer} {
    min-height: 36px;
    margin-bottom: 20px;
  }
`

export const CustomInputWrapper: StyledComponent<*, *, *> = styled.div`
  ${HintIcon} {
    vertical-align: baseline;
    padding: 0px;
    margin: -6px 0 5px 0;
    color: ${colors.textDisabled};
    cursor: pointer;
    &:hover {
      color: ${colors.text};
    }
  }
  width: 100%;
  display: flex;
  margin-top: 22px;
  flex-direction: column;
  .row-split {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 16px;
    margin-bottom: 10px;
    &:nth-child(2) {
      margin-bottom: 0px;
    }
  }
  .row-split-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 13px;
  }
  &:nth-child(1) {
    margin-top: 0px;
  }
  .label-modal {
    line-height: 16px;
    font-size: 14px;
    color: #494949;
    font-weight: 500;
    letter-spacing: 0em;
    margin-right: 6px;
  }
`

export const ExportModalContainer: StyledComponent<*, *, *> = styled.div`
  height: 409px;
  width: 715px;
  .modal-title {
    color: ${colors.text};
    font-weight: 500;
    font-size: 16px;
  }

  ${OptionContainer} {
    .styled-option-symbol,
    .styled-option-novalue {
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      font-size: 12px;
    }
  }

  .split-data-labels {
    color: ${colors.text};
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 17px;
    font-weight: normal;
    margin-left: 8px;
    margin-right: 12px;
  }

  /*** Specific to DateRangePicker ***/
  ${DayPickerContainer} {
    ${InputContainer} {
      background: white;
    }
  }
`
