// @flow
import { type DispatchBoundFn } from 'com.batch.redux/_records'
import { currentProjectPushDefaultSettingsSelector } from 'com.batch.redux/app'

import { type PushSettingsRecord } from 'com.batch/message/models/message.records'

type UpdatePushSettingsPayload = {
  stepMessageNodeId: ?string,
  settings: PushSettingsRecord,
}
export type UpdatePushSettingsAction = {
  type: 'UPDATE_PUSH_SETTINGS',
  payload: UpdatePushSettingsPayload,
}

export const updatePushSettings = (
  payload: UpdatePushSettingsPayload
): UpdatePushSettingsAction => ({
  type: 'UPDATE_PUSH_SETTINGS',
  payload,
})

export const initDefaultAdvancedSettingsForCampaign = (): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    if (
      !state.orchestration.id &&
      state.orchestration.campaign.sendType !== 'trigger' &&
      state.orchestration.campaign.messageConfig.channel === 'push' &&
      state.orchestration.campaign.messageConfig.messageTypedId &&
      ['NEW', 'DRAFT'].includes(state.orchestration.state)
    ) {
      const settings = currentProjectPushDefaultSettingsSelector(state)
      dispatch(updatePushSettings({ stepMessageNodeId: undefined, settings }))
    }
  }
}
