// @flow

import * as React from 'react'

import { type availableIcons, Icon } from 'components/common/svg-icon'

type NoDataProps = {
  title: string,
  error?: boolean,
  icon?: availableIcons,
  children: React.Node,
  ...
}

const NoData = ({ title, error = false, icon = 'danger', children }: NoDataProps): React.Node => {
  return (
    <div className={`nodata ${error ? 'nodata--error' : ''}`}>
      <Icon
        icon={icon}
        className="nodata__icon"
        size={36}
        thickness={2}
        style={{ marginTop: 18 }}
      />
      <h3 className="nodata__title">{title}</h3>
      <h5 className="nodata__msg">{children}</h5>
    </div>
  )
}

export default NoData
