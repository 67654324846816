// @flow
import Immutable, { type Map, type RecordFactory, type RecordOf } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import { type TransmissionType } from 'com.batch/shared/models/transmission-type'
import { STATUS } from 'constants/common'

// ====================== MODELS ====================== //

type SenderIdentityProps = {
  id: number,
  sendingName: string,
  sendingPrefix: string,
  sendingDomain: string,
  transmissionTypes: Immutable.Set<TransmissionType>,
  ...
}

export const SenderIdentityFactory: RecordFactory<SenderIdentityProps> = Immutable.Record(
  ({
    id: -1,
    sendingName: '',
    sendingPrefix: '',
    sendingDomain: '',
    transmissionTypes: Immutable.Set(),
  }: SenderIdentityProps)
)
export type SenderIdentityRecord = RecordOf<SenderIdentityProps>

// ====================== REDUCER ====================== //

type SenderIdentityStateProps = {
  entities: Map<number, SenderIdentityRecord>,
  selected: {
    previous: SenderIdentityRecord,
    current: SenderIdentityRecord,
    hasChanged: boolean,
    usedByCount: number,
    ...
  },
  loadingState: fetchingState,
  creating: boolean,
  ...
}

export const SenderIdentityStateFactory: RecordFactory<SenderIdentityStateProps> = Immutable.Record(
  ({
    entities: Immutable.Map(),
    loadingState: STATUS.INIT,
    selected: {
      previous: SenderIdentityFactory(),
      current: SenderIdentityFactory(),
      hasChanged: false,
      usedByCount: 0,
    },
    creating: false,
  }: SenderIdentityStateProps)
)
export type SenderIdentityStateRecord = RecordOf<SenderIdentityStateProps>
