// @flow
import styled, { type PropsWithTheme, type StyledComponent, css } from 'styled-components'

import { Box } from 'components/common/box'
import { Label } from 'components/common/label/label.styles'
import { IconContainer } from 'components/common/svg-icon'
import { TableContainer } from 'components/common/table/table.styles'
import { Ellipsis } from 'components/styled/text'
import { colors, schemes } from 'components/styled/tokens'
import { text, textSuccess } from 'components/styled/tokens/colors'
import { darklucent, grayscale } from 'components/styled/tokens/schemes'

import { AttributeRowContainer } from 'com.batch/profile/ui/components/attributes-block/attributes-block.styles'
import {
  TimeContent,
  TimelineContent,
} from 'com.batch/profile/ui/components/events-block/timeline/timeline.styles'

export const EventRowContent: StyledComponent<*, *, *> = styled.div`
  padding-left: 12px;
  padding-right: 20px;
  display: flex;

  ${TimeContent} {
    margin-top: 11px;
    margin-right: 24px;
  }
`

type EventRowDetailsProps = { $isToggable: boolean, ... }

export const EventRowDetails: StyledComponent<EventRowDetailsProps, *, *> = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${colors.textLight};

  > :first-child {
    font-weight: 500;
    color: ${colors.textNeutral};
  }

  .toggle-icon {
    display: none;
  }

  ${(props: PropsWithTheme<EventRowDetailsProps, any>) =>
    props.$isToggable &&
    css`
      &:hover {
        color: ${colors.textNeutral};
        cursor: pointer;

        ${EventRowDetailsSource} {
          svg,
          ${EventRowDetailsDay}, rect {
            fill: ${grayscale[50]};
            color: ${grayscale[50]};
          }
        }

        .toggle-icon {
          display: block;
          color: ${colors.textLight};
        }
      }
    `}
`

export const EventTooltipContainer: StyledComponent<*, *, *> = styled.div`
  text-align: left;
  font-size: 14px;

  .tz {
    margin-left: 6px;
    font-weight: 600;
  }
`

export const EventRowDetailsDay: StyledComponent<*, *, *> = styled.div`
  color: ${colors.textLight};
  position: relative;
  margin-left: 8px;

  &::before {
    content: '.';
    position: absolute;
    left: -6px;
    bottom: 3px;
  }
`

export const EventRowContentInformation: StyledComponent<{ expanded?: boolean, ... }, *, *> =
  styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    width: 100%;

    ${(props: PropsWithTheme<{ expanded?: boolean, ... }, any>) =>
      props.expanded &&
      css`
        gap: 8px;
      `}
    ${Label} {
      margin-right: 4px;
    }

    ${TableContainer} {
      margin-top: 8px;
      margin-bottom: 8px;
      background: ${schemes.grayscale['02']};
      border: 1px solid ${schemes.darklucent['20']};
      border-radius: 8px;

      ${AttributeRowContainer} {
        min-height: 40px;
      }
    }
  `

export const EventRowDetailsSource: StyledComponent<*, *, *> = styled.span`
  display: flex;
  cursor: pointer;

  &:hover svg,
  &:hover ${EventRowDetailsDay}, &:hover rect {
    fill: ${colors.textNeutral};
    color: ${colors.textNeutral};
  }
`

export const AttributesContainer: StyledComponent<*, *, *> = styled.span`
  & button {
    height: 24px;
    padding: 6px;
  }
`

export const ChannelBadgeContainer: StyledComponent<*, *, *> = styled(Label)`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  padding: 2px 8px;
  gap: 6px;
`

type MessagingEventNameProps = { $twoLines?: boolean, ... }

export const MessagingEventName: StyledComponent<MessagingEventNameProps, *, *> = styled.div`
  display: inline-flex;
  max-width: 100%;
  align-items: flex-end;

  ${(props: MessagingEventNameProps) =>
    props.$twoLines &&
    css`
      ${Ellipsis} {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        align-self: stretch;
        white-space: normal;
      }
    `}
  > ${IconContainer} {
    margin-left: 8px;
    color: ${colors.textLight};
  }
`

export const MessagingEventText: StyledComponent<*, *, *> = styled.div`
    overflow: hidden;
    max-width: 100%;
    display: flex;
    flex-flow: column;
    gap: 2px;

    a {
        margin-left: 4px;
    }

    ${MessagingEventName} {
        > ${IconContainer} {
            display: none;
            position: relative;
            top: -3px;
        }

        &:hover {
            > ${IconContainer} {
                display: block;
            }
        }
    }


    ${MessagingEventName}:first-child a {
        font-weight: 500;
        text-overflow: ellipsis;
        color: ${colors.textNeutral};
    }

    ${MessagingEventName}:nth-child(2) a {
    {
        color: ${colors.textLight};
        font-weight: 400;
        text-overflow: ellipsis;
    }
`

export const MessagingEventRowBox: StyledComponent<*, *, *> = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 166px;
  width: 100%;
  gap: 8px;
  min-height: 92px;
  z-index: 0;
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;
    padding: 8px 8px 12px 8px;
    overflow: hidden;

    ${ChannelBadgeContainer} {
      margin-bottom: 6px;
    }
  }

  > svg {
    width: 166px;
    height: 100%;
    border-left: 1px solid ${darklucent[20]};
    background: ${grayscale['02']};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

type MessagingEventRowContainerProps = { $variant?: string, ... }

export const MessagingEventRowContainer: StyledComponent<MessagingEventRowContainerProps, *, *> =
  styled.div`
    display: flex;
    align-items: flex-start;
    padding-left: 12px;
    padding-right: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: rgb(225, 225, 225);
      width: 1.5px;
      top: 0;
      left: 90.5px;
      height: 12px;

      ${(props: MessagingEventRowContainerProps) => {
        if (!props.$variant) {
          return null
        }
        switch (props.$variant) {
          case 'action':
            return css`
              background: ${colors.textAction};
            `
          case 'feature':
            return css`
              background: ${colors.textFeature};
            `
          case 'neutral':
            return css`
              background: ${text};
            `
          case 'success':
            return css`
              background: ${textSuccess};
            `
          default:
            return css`
              background: ${text};
            `
        }
      }}
    }

    &::after {
      content: '';
      position: absolute;
      background: rgb(225, 225, 225);
      width: 1.5px;
      bottom: 0;
      left: 90.5px;
      height: 12px;
    }

    ${TimeContent} {
      margin-top: 23px;
      margin-right: 10px;
    }
  `

type LightMessagingEventContainerProps = { $variant: string, $nextVariant?: string, ... }

export const LightMessagingEventContainer: StyledComponent<
  LightMessagingEventContainerProps,
  *,
  *,
> = styled.div`
  display: flex;
  padding-left: 12px;
  padding-right: 20px;
  position: relative;
  padding-bottom: 4px;
  padding-top: 12px;

  &::before {
    content: '';
    position: absolute;
    background: rgb(225, 225, 225);
    ${(props: LightMessagingEventContainerProps) => {
      if (!props.$nextVariant) {
        return null
      }
      switch (props.$nextVariant) {
        case 'action':
          return css`
            background: ${colors.textAction};
          `
        case 'feature':
          return css`
            background: ${colors.textFeature};
          `
        case 'neutral':
          return css`
            background: ${text};
          `
        case 'success':
          return css`
            background: ${textSuccess};
          `
        default:
          return css`
            background: ${text};
          `
      }
    }}
    width: 1.5px;
    margin-top: -12px;
    left: 90.5px;
    height: 12px;
  }

  &::after {
    content: '';
    position: absolute;
    background: rgb(225, 225, 225);
    ${(props: LightMessagingEventContainerProps) => {
      switch (props.$variant) {
        case 'action':
          return css`
            background: ${colors.textAction};
          `
        case 'feature':
          return css`
            background: ${colors.textFeature};
          `
        case 'neutral':
          return css`
            background: ${text};
          `
        case 'success':
          return css`
            background: ${textSuccess};
          `
        default:
          return css`
            background: ${text};
          `
      }
    }}
    width: 1.5px;
    bottom: 0;
    left: 90.5px;
    height: 4px;
  }

  ${TimeContent} {
    margin-top: 3px;
    margin-right: 18px;
  }
`

export const MessagingEventTooltip: StyledComponent<*, *, *> = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  text-align: left;

  > :first-child {
    font-weight: 500;
  }

  > :last-child {
    color: ${colors.textContrastLight};
  }
`

export const EventRowContentSkeleton: StyledComponent<*, *, *> = styled(EventRowContent)`
  padding-left: 30px;

  ${EventRowContentInformation} {
    justify-content: center;
  }

  ${TimelineContent} {
    &::before {
      top: -7px;
      right: calc(50% - 1px);
    }

    &::after {
      bottom: -10px;
      top: 0;
      right: calc(50% - 1px);
    }
  }
`
