// @noflow
import metrics from '@batch.com/metrics-client'

const routesRegex = require('../../public/json/routes-regex.json')

if ('performance' in window && 'PerformanceObserver' in window) {
  var performanceObserver = new PerformanceObserver(list => {
    list.getEntries().forEach(entry => {
      if (entry.entryType === 'resource' && entry.name.match('dashboard.*api')) {
        routesRegex.forEach(route => {
          let url = new URL(entry.name)
          try {
            let regexp = new RegExp(route.routeRegex)
            if (regexp.test(url.pathname)) {
              metrics
                .histogram('front_async_http_request_response_time')
                .label('route', route.routeName)
                .observe(entry.duration / 1000)
              metrics
                .counter('front_async_http_request_count')
                .label('route', route.routeName)
                .inc()
            }
          } catch (e) {
            // Handle old browsers which can't read elaborate regexp
          }
        })
      } else if (entry.entryType === 'navigation') {
        routesRegex.forEach(route => {
          let url = new URL(entry.name)
          try {
            let regexp = new RegExp(route.routeRegex)
            if (regexp.test(url.pathname)) {
              metrics
                .histogram('front_sync_http_request_response_time')
                .label('route', route.routeName)
                .observe(entry.duration / 1000)
              metrics.counter('front_sync_http_request_count').label('route', route.routeName).inc()
            }
          } catch (e) {
            // Handle old browsers which can't read elaborate regexp
          }
        })
      } else if (entry.entryType === 'paint') {
        // I'm unable to get this event occured
        // console.log('paint event')
      }
    })
  })
  performanceObserver.observe({
    entryTypes: ['resource', 'paint', 'navigation'],
  })
}

if (window.metricsDsn) {
  metrics.collect(window.metricsDsn)
}
