// @flow

import styled, { type StyledComponent, type PropsWithTheme } from 'styled-components'

import * as colors from 'components/styled/tokens/colors'

export type TrendContainerProps = { $color: 'neutral' | 'danger' | 'success' }
export const TrendContainer: StyledComponent<TrendContainerProps, {}, HTMLDivElement> = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  cursor: default;

  color: ${({ $color }: PropsWithTheme<TrendContainerProps, {}>) =>
    $color === 'neutral'
      ? colors.textLight
      : $color === 'success'
        ? colors.textSuccess
        : colors.textDanger};
`
