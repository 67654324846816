// @flow

import styled, { type StyledComponent } from 'styled-components'

export const AddBlockContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  text-align: center;
  padding: 40px;

  > h3 {
    margin-bottom: 10px;
    color: #242e3b;
    font-size: 14px;
  }

  > p {
    margin-bottom: 20px;
  }
`
