// @flow

import * as React from 'react'

import { ButtonLink } from 'components/common/button'
import Highlight from 'components/common/highlight'

import { type SdkRecord } from 'com.batch.redux/_records'

type SetupAirProps = {
  apiKey: string,
  devApiKey: string,
  senderId: ?string,
  bundleId: string,
  platform: Platforms,
  sdk: SdkRecord,
  hasDevApiKeyFeature: boolean,
  ...
}
const SetupAir = ({
  apiKey,
  devApiKey,
  senderId,
  bundleId,
  platform,
  sdk,
  hasDevApiKeyFeature,
}: SetupAirProps): React.Node => {
  return (
    <div>
      <h3>Intallation</h3>
      <p>
        Batch Air Native Extension <em>(ANE)</em> is compatible with both <strong>iOS 6+</strong>{' '}
        and <strong>Android 2.3+</strong>. It supports <strong>AIR 4.0+</strong> and{' '}
        <strong>Flex SDK 4.12</strong> or higher is required. You can download the latest version at
        the{' '}
        <a href="http://flex.apache.org/installer.html" target="_blank">
          Apache Website
        </a>
        .
      </p>
      <ButtonLink
        href={`https://download.batch.com/sdk/air/BatchSDK-air-${
          sdk && !!sdk.version ? sdk.version : ''
        }.zip`}
        kind="primary"
        intent="action"
        style={{ height: 28 }}
      >
        Download the ANE ({sdk.version})
      </ButtonLink>
      <ButtonLink
        style={{ height: 28 }}
        href="http://help.adobe.com/en_US/air/build/WS597e5dadb9cc1e0253f7d2fc1311b491071-8000.html"
      >
        How to add an ANE to your App
      </ButtonLink>
      {platform == 'android' && (
        <div>
          <h3>Configure your app permissions</h3>
          <p>You should add it in your app.xml under the android manifestAdditions tag:</p>
          <Highlight language="xml">{`
<android>
<manifestAdditions><![CDATA[
    <manifest android:installLocation="auto">

        <!-- Permissions -->
        <uses-permission android:name="android.permission.INTERNET"/>
        <uses-permission android:name="android.permission.WAKE_LOCK"/>

        <permission android:name="${bundleId}.permission.C2D_MESSAGE" android:protectionLevel="signature"/>
        <uses-permission android:name="${bundleId}.permission.C2D_MESSAGE"/>

        <uses-permission android:name="android.permission.VIBRATE" />
        <uses-permission android:name="com.google.android.c2dm.permission.RECEIVE"/>
    </manifest>

]]></manifestAdditions>
  </android>
            `}</Highlight>
          <h3>Starting the SDK</h3>
          <Highlight language="actionscript">{`<fx:Script>
<![CDATA[
    import com.batch.ane.Batch;
    import com.batch.ane.BatchPush;
    import com.batch.ane.Config;

    // Build config
    ${
      hasDevApiKeyFeature
        ? `var config:Config = new Config().setAndroidAPIKey("${devApiKey}"); // dev api key
    // var config:Config = new Config().setAndroidAPIKey("${apiKey}"); // live api key`
        : `var config:Config = new Config().setAndroidAPIKey("${apiKey}");`
    }

    // Set-up GCM id
    BatchPush.setGCMSenderId("${senderId || ''}");

    // Call batch start with the config
    Batch.start(config);

    // Request push
    BatchPush.registerForRemoteNotifications();
]]>
</fx:Script>
`}</Highlight>
        </div>
      )}
      {platform == 'ios' && (
        <div>
          <h3>Add the entitlement to your app.xml</h3>
          <p>You should add it in your app.xml under the android manifestAdditions tag:</p>
          <Highlight language="xml">{`<iPhone>
    <Entitlements>
        <![CDATA[
            <key>aps-environment</key>
            <string>production</string>
        ]]>
    </Entitlements>
</iPhone>
      `}</Highlight>
          <blockquote>
            <p>
              Set Production as the string value when you are signing your app with a production
              certificate, and Development if you are using a development certificate.
            </p>
          </blockquote>
          <h3>Starting the SDK</h3>
          <Highlight language="actionscript">{`<fx:Script>
    <![CDATA[
        import com.batch.ane.Batch;
        import com.batch.ane.BatchPush;
        import com.batch.ane.Config;

        // Build config
        ${
          hasDevApiKeyFeature
            ? `var config:Config = new Config().setiOSAPIKey("${devApiKey}}"); // dev api key
        // var config:Config = new Config().setiOSAPIKey("${apiKey}}"); // live api key`
            : `var config:Config = new Config().setiOSAPIKey("${apiKey}}"); // Batch api key`
        }

        // Call batch start with the config
        Batch.start(config);

        // Request push
        BatchPush.registerForRemoteNotifications(); /* You can move this line somewhere else, like at the end of a welcome screen if you dont want the push popup to pop at the start of your app on iOS */
    ]]>
</fx:Script>
`}</Highlight>
        </div>
      )}
    </div>
  )
}

export default SetupAir
