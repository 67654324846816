// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { AudienceList as AppAudienceList } from 'components/app/audience/audience-list'
import { CustomData } from 'components/app/custom-data/custom-data'
import { useSelectedApp } from 'components/app/use-selected-app'
import { Banner } from 'components/common/banner'
import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'
import { Userbase } from 'components/data/userbase'
import { Content } from 'components/styled/blocs'

import { GDPRApp } from '../app/privacy/gdpr-app'
import { currentAppSelector } from 'com.batch.redux/app'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

import { AudienceList } from 'com.batch/audience/ui/pages/audience-list'
import { Profile } from 'com.batch/profile/ui/pages/profile'
import { SegmentRouter } from 'com.batch/segments/ui/pages/segment-router'

export const DataWrapper = (): React.Node => {
  const currentApp = useSelector(currentAppSelector)
  const app = useSelectedApp()
  const { tab, action } = useParams()

  const user = useSelector(currentUserSelector)
  const project = useSelector(currentProjectSelector)
  const page = React.useMemo(() => {
    switch (tab) {
      case 'privacy': {
        const isNewAction = action === 'new'
        return (
          <PageTracker category={trackingCategories.userbase} name={trackingPages.gdpr}>
            <GDPRApp app={app} newPopinOpened={isNewAction} />
          </PageTracker>
        )
      }
      case 'custom-data':
        return (
          <PageTracker category={trackingCategories.userbase} name={trackingPages.customData}>
            <CustomData app={app} />
          </PageTracker>
        )
      case 'audiences':
        return (
          <PageTracker category={trackingCategories.userbase} name={trackingPages.audience}>
            {currentApp.id === 0 ? <AudienceList /> : <AppAudienceList app={currentApp} />}
          </PageTracker>
        )
      case 'segments':
        return (
          <PageTracker category={trackingCategories.userbase} name={trackingPages.segments}>
            <SegmentRouter />
          </PageTracker>
        )
      case 'find':
        return (
          <PageTracker category={trackingCategories.userbase} name={trackingPages.profiles}>
            <Profile />
          </PageTracker>
        )
      default:
        return <Userbase />
    }
  }, [tab, app, currentApp, action])

  if (project.id === '') {
    return <Userbase />
  }

  return (
    <React.Fragment>
      {!user.permissionsForCurrentCompany.has('group:app') && (
        <Banner
          intent="limited"
          icon="view"
          title="You are on read-only mode, some features may be unavailable."
        />
      )}
      <Content top>{page}</Content>
    </React.Fragment>
  )
}
