// @flow
import * as React from 'react'

import * as Themes from 'components/campaign/preview/preview.theme'

import { type AppRecord } from 'com.batch.redux/_records'

type Dimensions = { width: number, height: number }

type PreviewFitterProps = {
  app: AppRecord,
  previewWeb: string,
  fit: 'width' | 'height',
  render: (dim: Dimensions) => React.Node,
}

export const PreviewFitter = ({ app, previewWeb, fit, render }: PreviewFitterProps): React.Node => {
  const [dimensions, setDimensions] = React.useState<Dimensions>({ width: 0, height: 0 })
  const elementRef = React.useRef<?HTMLElement>(null)
  const parentNodeRef = React.useRef<?HTMLElement>(null)
  const rqfRef = React.useRef<?number>(null)

  const updateDimensions = React.useCallback(() => {
    if (elementRef.current && parentNodeRef.current) {
      const element = elementRef.current
      const parentNode = parentNodeRef.current

      element.style.width = '0'
      element.style.height = '0'
      element.style.overflow = 'hidden'

      const computedStyle = window.getComputedStyle(parentNode)
      const pl = parseInt(computedStyle.getPropertyValue('padding-left'), 10) || 0
      const pr = parseInt(computedStyle.getPropertyValue('padding-right'), 10) || 0
      const pt = parseInt(computedStyle.getPropertyValue('padding-top'), 10) || 0
      const pb = parseInt(computedStyle.getPropertyValue('padding-bottom'), 10) || 0

      const width = parentNode.clientWidth - pl - pr
      const height = parentNode.clientHeight - pt - pb

      element.style.width = 'auto'
      element.style.height = 'auto'
      element.style.overflow = 'visible'

      setDimensions({ width, height })
    }
  }, [])

  const onResize = React.useCallback(() => {
    if (rqfRef.current !== null) return
    rqfRef.current = window.requestAnimationFrame(() => {
      rqfRef.current = null
      updateDimensions()
    })
  }, [updateDimensions])

  React.useEffect(() => {
    const element = elementRef.current
    parentNodeRef.current = element?.parentNode instanceof HTMLElement ? element.parentNode : null

    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
      if (rqfRef.current !== null) {
        window.cancelAnimationFrame(rqfRef.current)
        rqfRef.current = null
      }
    }
  }, [onResize])

  const dim = React.useMemo(() => {
    const platform: Platforms = app.platform
    const platformTheme = previewWeb === 'android' ? 'android' : platform
    const themeHeight =
      platformTheme !== '' && platformTheme !== 'mobile'
        ? Themes[platformTheme]?.height ?? '50px'
        : '50px'
    const height = parseInt(themeHeight.replace('px', ''), 10)
    const width = 400
    const ratio =
      fit === 'height'
        ? Math.min(1, dimensions.height / height)
        : Math.min(1, dimensions.width / width)
    return {
      width: Math.floor(width * ratio),
      height: Math.floor(height * ratio),
    }
  }, [app.platform, dimensions.height, dimensions.width, fit, previewWeb])

  return <div ref={elementRef}>{render(dim)}</div>
}
