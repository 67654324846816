// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { Icon, IconContainer } from 'components/common/svg-icon'

const Highlight = css`
  position: relative;
  font-weight: 500;
  color: #90a83b;

  &:after {
    display: block;
    position: absolute;
    top: -1px;
    right: -2px;
    bottom: -1px;
    left: -2px;
    content: '';
    background-color: hsla(74, 48%, 56.3%, 0.2);
    border-radius: 2px;
  }
`

export const TreeText: StyledComponent<*, *, HTMLSpanElement> = styled.span`
  font-family: 'SF Mono', 'Fira Code', 'Menlo', 'Segoe UI mono', monospace;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.2px;

  &::selection {
    background-color: hsla(0, 0%, 100%, 0.22);
    color: inherit;
  }
`

// $FlowFixMe withComponent
export const TreeLabel: StyledComponent<*, *, *> = styled(TreeText.withComponent('label'))`
  color: #acc55a;
  margin-bottom: 0;
  word-break: keep-all;

  > em {
    ${Highlight}
  }
`

// $FlowFixMe withComponent
export const TreeCount: StyledComponent<*, *, *> = styled(TreeText.withComponent('div'))`
  font-weight: 400;
  color: #888586;
  flex-grow: 1;
`

// $FlowFixMe withComponent
export const TreeValue: StyledComponent<*, *, *> = styled(TreeText.withComponent('section'))`
  color: #cad1d9;
  font-weight: 200;
  word-break: break-word;

  > em {
    ${Highlight}
  }

  a {
    color: inherit;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }
`

export const TreeValueString: StyledComponent<*, *, *> = styled(TreeValue)`
  color: #ecd194;
`
export const TreeValueInt: StyledComponent<*, *, *> = styled(TreeValue)`
  color: #e94ba1;
`
export const TreeValueBool: StyledComponent<*, *, *> = styled(TreeValueInt)``
type TreeContentProps = { open: boolean, isArray: boolean, ... }
export const TreeContent: StyledComponent<TreeContentProps, { ... }, *> = styled(TreeValue)`
  background: gold;
  ${(props: TreeContentProps) =>
    !props.open &&
    css`
      display: none;
    `}
  ${(props: TreeContentProps) =>
    props.isArray &&
    css`
      background: crimson;
    `}
`
export const TreeHandleButton: StyledComponent<*, *, *> = styled(Icon).attrs({
  size: 10,
  color: ['#8E8E8E', '#525252'],
})`
  display: inline-flex;
  vertical-align: 1px;
  margin: 0 5px 0 4px;
`
export const TreeHandleCount: StyledComponent<*, *, *> = styled(TreeText)`
  display: inline-block;
  vertical-align: 0;
  margin: 0 2px 0 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Helvetica, Arial,
    serif;
  font-size: 12px;
  font-weight: 400;
  color: #797979;
`
export const TreeHandle: StyledComponent<*, *, *> = styled.span`
  display: inline-block;
  color: #aaaaaa;
  cursor: pointer;

  &::selection {
    background-color: hsla(0, 0%, 100%, 0.22);
    color: inherit;
  }
`
type TreeProps = { level: number, final: boolean, ... }
export const Tree: StyledComponent<TreeProps, { ... }, HTMLDivElement> = styled.div`
  background: #222930;
  margin: ${(props: TreeProps) => (props.level === 0 ? '15px' : '0px')};
  padding: ${(props: TreeProps) => (props.level === 0 ? '12px' : '0 0 0 20px')};
  ${(props: TreeProps) =>
    props.final &&
    css`
      display: flex;

      ${TreeLabel} {
        &:after {
          content: ':';
          padding-right: 5px;
          color: #fff;
        }
      }
    `}
`
export const TreeTitle: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Helvetica, Arial,
    serif;
  cursor: pointer;
  user-select: none;

  ${IconContainer} {
    color: hsla(210, 4%, 49%, 1);
    margin: 0 3px 0 0;
  }

  > ${TreeLabel} {
    font-family: inherit;
    color: #fff;
    line-height: 15px;
    padding-right: 8px;
    cursor: inherit;
  }

  > ${TreeCount} {
    font-family: inherit;
    line-height: 15px;
  }

  + ${TreeHandle} {
    margin-top: 8px;

    ${TreeHandleButton} {
      display: none;
    }
  }
`
