// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type Map, type Set } from 'immutable'
import {
  clone as _clone,
  cloneDeep as _cloneDeep,
  get as _get,
  map as _map,
  set as _set,
  unset as _unset,
} from 'lodash-es'
import request from 'superagent-interface-promise'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'

import {
  CampaignFactory,
  type CampaignRecord,
  type CappingCategoryRecord,
  type DispatchBoundFn,
  EstimateFactory,
  type LanguageRecord,
  type ReduxAction,
  ReplicationFactory,
  TriggerConfigFactory,
  type Variant,
} from 'com.batch.redux/_records'
import * as ACT from 'com.batch.redux/action-name'
import { SWITCH_PAGE } from 'com.batch.redux/action-name'
import { legacyPromiseActionCreator, promiseActionCreator } from 'com.batch.redux/actionCreator'
import { labelsSelector } from 'com.batch.redux/app'
import api from 'com.batch.redux/campaign.api'
import { type allowedActionKey, type ContentStateRecord } from 'com.batch.redux/content.records'
import { type ApiPushCampaign } from 'com.batch.redux/project.api.model'
import {
  addCondition,
  TARGETING_INIT,
  toggleCondition,
  updateCondition,
} from 'com.batch.redux/targeting'
import { apiCampaignSelector } from 'com.batch.redux/targeting.selector.composed'
import { setTemplateForField } from 'com.batch.redux/template'
import { type ThemeRecord } from 'com.batch.redux/theme.records'

export const toggleJIT = (): ReduxAction<'CAMPAIGN_TOGGLE_JIT', { ... }> => ({
  type: 'CAMPAIGN_TOGGLE_JIT',
  payload: {},
})

export const fetchCampaignsLabelsCount = (
  appId: number,
  type: ?campaignType
): DispatchBoundFn<any> => {
  return (dispatch, getState) => {
    const state = getState()
    let labels = state.app.get('current').get('cappingCategories')

    return promiseActionCreator({
      actionName: 'FETCH_CAMPAIGN_LABELS_COUNTS',
      dispatch,
      promise: request
        .get(
          generateUrl('api_push_campaigns_count_by_label', {
            appId: appId,
            type: type?.toUpperCase() || 'ALL',
          })
        )
        .then(
          response => {
            labels = labels.map(cat => cat.set('count', _get(response.body, cat.id, 0) * 1))
            dispatch({
              type: ACT.FETCH_CAMPAIGN_LABELS_COUNTS_FINAL,
              payload: labels,
            })
          },
          error => {
            throw error?.body?.errors[0]?.message ?? 'Unknown error'
          }
        ),
      payload: null,
    })
  }
}

export const buildCampaignsFromOptions = (
  data: Array<{
    inappTriggerEvent: ?string,
    inappTriggerLabel: ?string,
    inappTriggerType: ?string,
    label: string,
    repeatFrequency: ?number,
    repeatUnit: 'DAILY' | 'WEEKLY' | 'MONTHLY',
    startDate: ?string,
    timezoneDependant: '1' | '0',
    triggerEnterEvent: string,
    type: 'PUSH' | 'LOCAL' | 'TRIGGER',
    value: string,
    ...
  }>
): {
  payload: Map<string, CampaignRecord>,
  type: string,
  ...
} => {
  let obj: { [string]: CampaignRecord, ... } = {}
  data.forEach(d => {
    obj[d.value] = CampaignFactory({
      token: d.value,
      sendType:
        d.type === 'TRIGGER' ? 'trigger' : d.repeatFrequency !== null ? 'recurring' : 'scheduled',
      triggerConfig: TriggerConfigFactory({ enterEvent: d.triggerEnterEvent }),
      trigger: d.inappTriggerType?.toLowerCase() ?? '',
      triggerLabel: d.inappTriggerLabel ?? d.inappTriggerEvent?.replace('e.', ''),
      repeatFrequency: d.repeatFrequency || 0,
      repeatUnit: d.repeatUnit,
      tzAware: d.timezoneDependant === '1',
      start: d.startDate ? dayjs.utc(d.startDate ?? '', 'YYYY/MM/DD HH:mm') : null,
      name: d.label,
    })
  })
  return {
    type: ACT.GET_CAMPAIGNS_FROM_OPTIONS, // $FlowFixMe ugly as fuck
    payload: Immutable.Map(obj),
  }
}

export const setCampaignStateStopped = ({
  campaign,
  appId,
}: {
  appId: number,
  campaign: CampaignRecord,
  ...
}): DispatchBoundFn<any> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { token: campaign.token },
      promise: api.pause({ appId, id: campaign.id, token: campaign.token }),
      actionName: ACT.SET_CAMPAIGN_STATE,
    })
}

export const setCampaignStateRunning = ({
  campaign,
  appId,
}: {
  campaign: CampaignRecord,
  appId: number,
  ...
}): DispatchBoundFn<any> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { token: campaign.token },
      promise: api.play({ appId, id: campaign.id, token: campaign.token }),
      actionName: ACT.SET_CAMPAIGN_STATE,
    })
}

export const toggleState = ({
  campaign,
  appId,
}: {
  campaign: CampaignRecord,
  appId: number,
  ...
}): DispatchBoundFn<any> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { token: campaign.token },
      promise:
        campaign.state === 'RUNNING'
          ? api.pause({ appId, id: campaign.id, token: campaign.token })
          : api.play({ appId, id: campaign.id, token: campaign.token }),
      actionName: ACT.SET_CAMPAIGN_STATE,
    })
}

export const uploadError = (
  message: string
): { payload: { error: string, ... }, type: 'UPLOAD_ERROR', ... } => {
  return {
    type: ACT.UPLOAD_ERROR,
    payload: { error: message },
  }
}

export const archive = (campaign: CampaignRecord): DispatchBoundFn<any> => {
  return (dispatch, getState) => {
    const state = getState()
    const appId = state.app.current.id
    const companyId = state.app.current.companyId
    const schedulingType = campaign.schedulingType
    const token = campaign.token
    const id = campaign.id
    return legacyPromiseActionCreator({
      dispatch,
      payload: { token },
      promise: api.archiveCampaign({ appId, id, token }),
      actionName: ACT.ARCHIVE_CAMPAIGN,
      successCallback: () => {
        window.location.href = generateUrl('campaign_list', {
          companyId,
          appId,
          schedulingType,
        })
      },
    })
  }
}

export const updatePushWhen = ({
  what,
  value,
}: {
  value: ?any,
  what: string,
}): ReduxAction<'UPDATE_PUSH_WHEN', { value: ?any, what: string, ... }> => {
  return {
    type: 'UPDATE_PUSH_WHEN',
    payload: { what, value },
  }
}

const offsetTimeIfNeeded = (dateTime: any) => {
  const format = 'YYYY-MM-DDTHH:mm:00'
  const now = dayjs.utc()
  const mom = dayjs.utc(dateTime, format)
  if (mom.isBefore(now)) {
    let offseted = dayjs().utc().add(1, 'day')
    offseted.hour(mom.hour()).minute(mom.minute())
    return offseted.format(format)
  }
  return dateTime
}

const offsetEndTimeIfNeeded = (dateTime: any, startTime: any) => {
  const format = 'YYYY-MM-DDTHH:mm:00'
  const mom = dayjs.utc(dateTime, format)
  const start = dayjs.utc(startTime, format)
  if (mom.isSameOrBefore(start)) {
    return start.add(2, 'day').format(format)
  }
  return dateTime
}
export const clearReplicateResult = (): ReduxAction<'REPLICATE_CLEAR_RESULTS', null> => {
  return {
    type: ACT.REPLICATE_CLEAR_RESULTS,
    payload: null,
  }
}

const iosActionName: Array<allowedActionKey> = [
  'batch.ios_request_notifications',
  'batch.ios_redirect_settings',
  'batch.ios_smart_reoptin',
  'batch.ios_tracking_consent',
]
const androidActionName: Array<allowedActionKey> = [
  'batch.android_request_notifications',
  'batch.android_redirect_settings',
  'batch.android_smart_reoptin',
]

function replaceMirrorAction(actio: any, sourcePlatform: ?string, targetPlatform: ?string) {
  let action = _clone(actio)
  if (sourcePlatform === 'ios' && targetPlatform === 'android') {
    if (
      [
        'batch.ios_request_notifications',
        'batch.ios_redirect_settings',
        'batch.ios_smart_reoptin',
      ].includes(action.action)
    ) {
      action.action = action.action.replace('ios', 'android')
    }
  }
  if (sourcePlatform === 'android' && targetPlatform === 'ios') {
    if (
      [
        'batch.android_request_notifications',
        'batch.android_redirect_settings',
        'batch.android_smart_reoptin',
      ].includes(action.action)
    ) {
      action.action = action.action.replace('android', 'ios')
    }
  }
  return action
}

// rien de typé ici c'est NUUUL
// l'idée est "juste" de dégager les actions spé iOS
function updateUnhandlediOSAction(actio: any) {
  let action = _clone(actio)
  action.args = iosActionName.includes(action.action) ? {} : action.args
  action.action = iosActionName.includes(action.action) ? 'batch.dismiss' : action.action
  if (action.action === 'batch.group' && Array.isArray(action.args?.actions)) {
    action.args.actions = action.args.actions.map(tuple => {
      if (typeof tuple[0] === 'string' && iosActionName.includes(tuple[0])) {
        tuple[0] = 'batch.dismiss'
      }
      return tuple
    })
  }
  return action
}
function updateUnhandledAndroidAction(actio: any) {
  let action = _clone(actio)
  action.args = androidActionName.includes(action.action) ? {} : action.args
  action.action = androidActionName.includes(action.action) ? 'batch.dismiss' : action.action
  if (action.action === 'batch.group' && Array.isArray(action.args?.actions)) {
    action.args.actions = action.args.actions.map(tuple => {
      if (typeof tuple[0] === 'string' && androidActionName.includes(tuple[0])) {
        tuple[0] = 'batch.dismiss'
      }
      return tuple
    })
  }
  return action
}

export function replaceOrDropAction(
  actio: any,
  sourcePlatform: ?string,
  targetPlatform: ?string
): { action: string, args: { ... }, ... } {
  let action = replaceMirrorAction(actio, sourcePlatform, targetPlatform)
  if (targetPlatform !== 'ios') {
    action = updateUnhandlediOSAction(action)
  }
  if (targetPlatform !== 'android') {
    action = updateUnhandledAndroidAction(action)
  }
  return action
}

export const replicate = (ids: Array<number>): DispatchBoundFn<any> => {
  return (dispatch, getState) => {
    const state = getState()
    const kind = state.campaign.config.type
    const schedulingType = state.campaign.config.schedulingType
    const clean = _cloneDeep(apiCampaignSelector(state))
    const currentApp = state.app.current
    const records = ids.map(appId => {
      const app = state.app.entities.find(a => a.id === appId, state.app.current)
      return ReplicationFactory({
        appId: appId,
        companyId: state.app.current.companyId,
        platform: app.platform ? app.platform : undefined,
        icon: app.icon,
        name: app.name,
        kind: kind,
        schedulingType,
      })
    })
    const dateFields = [
      'local_start_date',
      'start_date',
      'local_end_date',
      'end_date',
      'push_time',
      'local_push_time',
      'recurrence.end_date',
    ]
    // INIT THE STUFF
    dispatch({ type: ACT.REPLICATE_CAMPAIGNS, payload: records })
    records.forEach(app => {
      // SINGLE APP REPLICATION BEHAVIOR
      let duplicated = _clone(clean)
      // lorsqu'on duplique sur la même app, on rajoute le prefix (copy)
      if (app.appId === currentApp.id) {
        duplicated.setName(`(copy) ${duplicated.name}`)
      }
      dateFields.forEach(f => {
        if (_get(duplicated, f)) _set(duplicated, f, offsetTimeIfNeeded(_get(duplicated, f)))
      })

      // check end > start
      if (_get(duplicated, 'local_end_date') && _get(duplicated, 'local_start_date')) {
        _set(
          duplicated,
          'local_end_date',
          offsetEndTimeIfNeeded(
            _get(duplicated, 'local_end_date'),
            _get(duplicated, 'local_start_date')
          )
        )
      }
      // check end > start
      if (_get(duplicated, 'recurrence.end_date') && _get(duplicated, 'local_push_time')) {
        _set(
          duplicated,
          'recurrence.end_date',
          offsetEndTimeIfNeeded(
            _get(duplicated, 'recurrence.end_date'),
            _get(duplicated, 'local_push_time')
          )
        )
      }
      if (app.platform !== 'android' && app.platform !== 'webpush') {
        _unset(duplicated, 'gcm_collapse_key')
        _unset(duplicated, 'media.icon')
      }
      if (app.platform === 'webpush') {
        _unset(duplicated, 'payload')
      }
      if (app.platform === 'ios') {
        _unset(duplicated, 'media.video')
        _unset(duplicated, 'media.audio')
      }
      if (app.platform !== 'ios' && app.platform !== 'android') {
        _unset(duplicated, 'priority')
      }
      duplicated.live = false
      if (duplicated.landing) {
        const variants = ['a', 'b']
        duplicated.landing = _clone(duplicated.landing)

        if (duplicated.landing.enable_variants) {
          variants.forEach(variant => {
            duplicated.landing[variant].contents = _clone(duplicated.landing[variant].contents).map(
              c => {
                let local = _clone(c)
                local.actions = local.actions.map(act =>
                  replaceOrDropAction(act, currentApp.platform, app.platform)
                )
                if (typeof local.global_action !== 'undefined') {
                  local.global_action = replaceOrDropAction(
                    _clone(local.global_action),
                    currentApp.platform,
                    app.platform
                  )
                }
                return local
              }
            )
          })
        } else {
          duplicated.landing.contents = _clone(duplicated.landing.contents).map(c => {
            let local = _clone(c)
            local.actions = local.actions.map(act =>
              replaceOrDropAction(act, currentApp.platform, app.platform)
            )
            if (typeof local.global_action !== 'undefined') {
              local.global_action = replaceOrDropAction(
                _clone(local.global_action),
                currentApp.platform,
                app.platform
              )
            }
            return local
          })
        }
      }
      api
        .saveCampaign({
          appId: app.appId,
          campaign: duplicated,
          token: null,
          kind,
        })
        .then(
          result => {
            dispatch({
              type: ACT.REPLICATE_CAMPAIGN_SUCCESS,
              payload: { token: result.token, app: app.appId },
            })
          },
          error => {
            if (typeof error === 'string') {
              const reg = /Label `([a-zA-Z0-9]+)` does not exist./g
              const matches = reg.exec(error)
              if (matches && matches.length === 2) {
                const labelCode = matches[1]
                const labels = state.app.current.cappingCategories.filter(l => l.code === labelCode)
                const labelName = labels.size === 1 ? ` (${labels.first()?.name ?? ''})` : ''
                error = `Label with code ${labelCode}${labelName} does not exist, you have to create it on this app, or to remove it before trying to replicate the campagin.`
              }
            }
            dispatch({
              type: ACT.REPLICATE_CAMPAIGN_FAILURE,
              payload: { error, app: app.appId },
            })
          }
        )
    })
  }
}

// used to call template_parse on potential macro fields
const flattenFieldValue = (data: ContentStateRecord) => {
  let flat: { [string]: any, ... } = {}
  data.push.forEach((ab, lang) => {
    flat[`title-a-${lang}`] = ab.a.title.value
    flat[`message-a-${lang}`] = ab.a.message.value
    flat[`deeplink-a-${lang}`] = ab.a.deeplink
    flat[`mediaKind-a-${lang}`] = ab.a.mediaKind
    flat[`mediaUrl-a-${lang}`] = ab.a.mediaUrl
    flat[`title-b-${lang}`] = ab.b.title.value
    flat[`message-b-${lang}`] = ab.b.message.value
    flat[`deeplink-b-${lang}`] = ab.b.deeplink
    flat[`mediaKind-b-${lang}`] = ab.b.mediaKind
    flat[`mediaUrl-b-${lang}`] = ab.b.mediaUrl
  })
  flat['payload'] = data.pushSettings.payload
  data.inapp.forEach((inapp, lang) => {
    flat[`inapp-title-a-${lang}`] = inapp.a.title
    flat[`inapp-text-a-${lang}`] = inapp.a.text
    flat[`inapp-header-a-${lang}`] = inapp.a.header
    flat[`inapp-mainButtonLabel-a-${lang}`] = inapp.a.mainButtonLabel
    flat[`inapp-mainButtonLabel-deeplinkUrl-a-${lang}`] = inapp.a.mainButtonAction.deeplinkUrl
    flat[`inapp-mainButtonLabel-copyText-a-${lang}`] = inapp.a.mainButtonAction.copyText
    flat[`inapp-secondaryButtonLabel-a-${lang}`] = inapp.a.secondaryButtonLabel
    flat[`inapp-secondaryButtonLabel-deeplinkUrl-a-${lang}`] =
      inapp.a.secondaryButtonAction.deeplinkUrl
    flat[`inapp-secondaryButtonLabel-copyText-a-${lang}`] = inapp.a.secondaryButtonAction.copyText
    flat[`inapp-imageUrl-a-${lang}`] = inapp.a.imageUrl
    flat[`inapp-title-b-${lang}`] = inapp.b.title
    flat[`inapp-text-b-${lang}`] = inapp.b.text
    flat[`inapp-header-b-${lang}`] = inapp.b.header
    flat[`inapp-mainButtonLabel-b-${lang}`] = inapp.b.mainButtonLabel
    flat[`inapp-mainButtonLabel-deeplinkUrl-b-${lang}`] = inapp.a.mainButtonAction.deeplinkUrl
    flat[`inapp-mainButtonLabel-copyText-b-${lang}`] = inapp.a.mainButtonAction.copyText
    flat[`inapp-secondaryButtonLabel-b-${lang}`] = inapp.b.secondaryButtonLabel
    flat[`inapp-secondaryButtonLabel-deeplinkUrl-b-${lang}`] =
      inapp.a.secondaryButtonAction.deeplinkUrl
    flat[`inapp-secondaryButtonLabel-copyText-b-${lang}`] = inapp.a.secondaryButtonAction.copyText
    flat[`inapp-imageUrl-b-${lang}`] = inapp.b.imageUrl
  })
  return flat
}

export const toggleLabel = (
  label: CappingCategoryRecord
): ReduxAction<'TOGGLE_CAMPAIGN_LABEL', CappingCategoryRecord> => {
  return {
    type: ACT.TOGGLE_CAMPAIGN_LABEL,
    payload: label,
  }
}
export const updateTimezoneAware = (
  tzAware: boolean
): ReduxAction<'UPDATE_CAMPAIGN_TZ_AWARE', boolean> => {
  return {
    type: ACT.UPDATE_CAMPAIGN_TZ_AWARE,
    payload: tzAware,
  }
}
export const fetchCampaignsInit = (
  appId: number,
  type: schedulingType
): ReduxAction<'FETCH_CAMPAIGNS_INIT', { appId: number, type: schedulingType, ... }> => {
  return {
    type: ACT.FETCH_CAMPAIGNS_INIT,
    payload: { appId, type },
  }
}
export const fetchCampaigns = (
  appId: number
): ReduxAction<'FETCH_CAMPAIGNS', { appId: number, ... }> => {
  return {
    type: ACT.FETCH_CAMPAIGNS,
    payload: { appId },
  }
}

export const switchPage = (page: number): ReduxAction<'SWITCH_PAGE', { page: number, ... }> => {
  return {
    type: SWITCH_PAGE,
    payload: { page },
  }
}

// changes the edited campaing
// trigers a loading if campaign not in state
// if the state is ready, triggers a query parsing
export const setEditingCampaign =
  ({
    token,
    type,
    schedulingType,
    estimate = true,
    recurringLegacyDisabled = false,
  }: {
    estimate: boolean,
    token: string,
    type: campaignType,
    schedulingType: schedulingType,
    recurringLegacyDisabled: boolean,
  }): DispatchBoundFn<any> =>
  (dispatch, getState) => {
    const state = getState()
    const app = state.app.current
    dispatch({
      type: ACT.SET_EDITING_CAMPAIGN,
      payload: { token, type, schedulingType, pushConfig: app.pushConfig, recurringLegacyDisabled },
    })

    // might avoid useless fetch if already in local state, but would need to handle cache & co

    if (token === 'new') {
      let retargeting = null
      // si on a une query string & qu'on est sur une nouvelle campagne, on check si c'est du retargeting
      const qs = window.location.search
      if (
        qs &&
        (window.location.pathname.indexOf('/campaigns/new') !== -1 ||
          window.location.pathname.indexOf('/push/new') !== -1)
      ) {
        const tmp = qs.split('&')

        if (tmp.length === 3 && tmp[0].substring(0, 5) === '?ret=') {
          retargeting = {
            token: tmp[0].substring(5),
            attr: tmp[1].substring(2) === 'send' ? 'sent' : 'opened',
            negate: tmp[2].substring(2) === '1',
          }
        }
      }
      if (estimate) {
        dispatch({
          type: TARGETING_INIT,
          payload: state.targeting,
        })
      }
      setTimeout(() => {
        if (retargeting) {
          const attr = state.attribute.entities.get(`be.${retargeting.attr}`)
          dispatch(addCondition({ attribute: attr, logicalId: 'root' }))
          if (retargeting.negate) {
            dispatch(toggleCondition('a0'))
          }
          dispatch(
            updateCondition({
              id: 'a0',
              changes: {
                what: '__TOKEN__',
                value: retargeting.token,
              },
            })
          )
        }
      }, 2)
    } else {
      dispatch(
        fetchCampaign({ token, withEstimate: estimate, withMacro: true, redirectOnFail: true })
      )
    }
  }

export const fetchCampaign = ({
  token,
  withEstimate = true,
  withMacro = false,
  redirectOnFail = true,
  usedByRetargeting = false,
}: {
  redirectOnFail: boolean,
  token: string,
  usedByRetargeting?: boolean,
  withEstimate: boolean,
  withMacro: boolean,
  ...
}): DispatchBoundFn<any> => {
  return (dispatch, getState) => {
    const state = getState()
    const labels = labelsSelector(state)
    const app = state.app.current
    const appId = app.id
    const type = state.campaign.config.type
    const schedulingType: schedulingType = state.campaign.config.schedulingType
    const listUrl: string = generateUrl('campaign_list', {
      companyId: app.companyId,
      appId: appId,
      schedulingType,
    })
    dispatch({
      type: ACT.FETCH_CAMPAIGN,
      payload: { token },
    })
    api
      .fetchCampaign({
        appId,
        token,
      })
      .then(
        rawCampaign => {
          if (
            (rawCampaign.type === 'LOCAL' && type !== 'in-app' && !usedByRetargeting) ||
            (rawCampaign.type === 'PUSH' && type !== 'push' && !usedByRetargeting)
          ) {
            dispatch({
              type: ACT.FETCH_CAMPAIGN_FAILURE,
              payload: {
                error: 'Unable to load your campaign - wrong campaign kind',
              },
            })
            if (redirectOnFail) {
              window.location.href = listUrl
            }
          } else {
            const campaign = api.rawCampaignToRecord(rawCampaign, labels)
            if (state.campaign.config.editing === token) {
              dispatch({
                type: 'EDITING_CAMPAIGN_CONTENT_LOADED',
                payload: campaign.data,
              })
            }

            dispatch({
              type: ACT.FETCH_CAMPAIGN_SUCCESS,
              payload: campaign,
            })
            dispatch({
              type: ACT.SET_CAMPAIGN_ACTIVE_TRANSLATION,
              payload: campaign.translations.find(l => l === 'default'),
            })
            if (app.features.includes('macro') && withMacro) {
              _map(flattenFieldValue(campaign.data), (value, key) => {
                dispatch(setTemplateForField(key, value))
              })
            }
            if (withEstimate) {
              dispatch({
                type: TARGETING_INIT,
                payload: campaign.get('targeting').set('estimate', EstimateFactory()),
              })
              // const query = campaign.get('targeting').get('query')
              // if (query) {
              //   dispatch({
              //     type: 'T_NEW_QUERY',
              //     payload: {
              //       query: JSON.stringify(query),
              //       queryId: 'targeting',
              //       retries: 0,
              //       eventId: ''
              //     }
              //   })
              // }
              if (campaign.triggerConfig.enterEventQuery) {
                dispatch({
                  type: 'T_NEW_QUERY',
                  payload: {
                    query: JSON.stringify(campaign.triggerConfig.enterEventQuery),
                    queryId: 'enterEvent',
                    retries: 0,
                    eventId: campaign.triggerConfig.enterEvent,
                  },
                })
              }
              if (campaign.triggerConfig.exitEvents.size > 0) {
                campaign.triggerConfig.exitEvents.forEach((ev, key) => {
                  if (ev.query) {
                    dispatch({
                      type: 'T_NEW_QUERY',
                      payload: {
                        query: JSON.stringify(ev.query),
                        queryId: `exitEvent-${key}`,
                        retries: 0,
                        eventId: ev.eventId,
                      },
                    })
                  }
                })
              }
            }
          }
        },
        error => {
          dispatch({
            type: ACT.FETCH_CAMPAIGN_FAILURE,
            payload: {
              error: error,
            },
          })

          if (redirectOnFail) {
            // window.location.href = listUrl
          }
        }
      )
  }
}

export const setCampaignLanding = (
  active: boolean
): ReduxAction<'SET_CAMPAIGN_LANDING', { active: boolean, ... }> => {
  return {
    type: ACT.SET_CAMPAIGN_LANDING,
    payload: { active },
  }
}
export const searchCampaigns = ({
  appId,
  query = '',
  kind = 'PUSH',
}: {
  appId: number,
  kind: string,
  query: string,
  ...
}): DispatchBoundFn<Promise<any>> => {
  return dispatch => {
    return legacyPromiseActionCreator({
      dispatch,
      promise: api.findCampaigns({ appId, query, kind }),
      payload: {},
      actionName: ACT.SEARCH_CAMPAIGNS,
    })
  }
}

export const saveProjectCampaign =
  (campaignAPI: ApiPushCampaign): DispatchBoundFn<Promise<Array<string>>> =>
  (dispatch, getState) => {
    const state = getState()
    const companyId = state.company.id
    return promiseActionCreator({
      dispatch,
      promise: api.saveCampaignForProject({ companyId, campaignAPI }),
      payload: {},
      actionName: 'SAVE_PROJECT_CAMPAIGN',
    })
  }

export const saveCampaign =
  (live: boolean): DispatchBoundFn<any> =>
  (dispatch, getState) => {
    const state = getState()
    const appId = state.app.current.id
    const companyId = state.app.current.companyId
    const token = state.campaign.config.editing
    const kind = state.campaign.config.type
    const schedulingType = state.campaign.config.schedulingType

    let clean = apiCampaignSelector(state)

    if (typeof live === 'boolean') {
      clean.setLive(live)
    }

    let sendType = state.campaign.entities.get('new', CampaignFactory()).sendType

    return legacyPromiseActionCreator({
      dispatch,
      promise: api.saveCampaign({ appId, campaign: clean, token, kind, sendType }),
      payload: {},
      actionName: ACT.SAVE_CAMPAIGN,
      successCallback: () => {
        window.location.href = generateUrl('campaign_list', {
          companyId: companyId,
          appId: appId,
          schedulingType,
        })
      },
    })
  }

export const fetchCampaignsSuccess =
  ({
    entities,
    countFiltered,
    countTotal,
    page,
  }: {
    countFiltered: number,
    countTotal: number,
    entities: Array<jsonCampaignType>,
    page: number,
    ...
  }): DispatchBoundFn<any> =>
  (dispatch, getState) => {
    const state = getState()
    const labels = labelsSelector(state)
    return dispatch({
      type: ACT.FETCH_CAMPAIGNS_SUCCESS,
      payload: {
        entities: entities.map(c => api.rawCampaignToRecord(c, labels)),
        countFiltered,
        countTotal,
        page,
      },
    })
  }
export const fetchCampaignsFailure = (
  appId: number
): ReduxAction<'FETCH_CAMPAIGNS_FAILURE', { appId: number, ... }> => {
  return {
    type: ACT.FETCH_CAMPAIGNS_FAILURE,
    payload: { appId },
  }
}

export const setAdvancedConfig = (
  active: boolean
): ReduxAction<'DISPLAY_CAMPAIGN_ADVCANCED', boolean> => {
  return { type: ACT.DISPLAY_CAMPAIGN_ADVCANCED, payload: active }
}

export const updateState = (
  state: campaignStateType
): ReduxAction<'UPDATE_CAMPAIGN_STATE', campaignStateType> => {
  return { type: ACT.UPDATE_CAMPAIGN_STATE, payload: state }
}
export const updateTrigger = (trigger: string): ReduxAction<'UPDATE_IA_TRIGGER', string> => {
  return { type: ACT.UPDATE_IA_TRIGGER, payload: trigger }
}
export const updateTheme = ({
  theme,
  variant,
}: {
  theme: ThemeRecord,
  variant: Variant,
  ...
}): ReduxAction<'UPDATE_LANDING_THEME', { theme: ThemeRecord, variant: Variant, ... }> => {
  return { type: ACT.UPDATE_LANDING_THEME, payload: { theme, variant } }
}
export const updateInAppPriority = (
  priority: inAppPriority
): ReduxAction<'UPDATE_INAPP_PRIORITY', inAppPriority> => {
  return { type: 'UPDATE_INAPP_PRIORITY', payload: priority }
}
export const updateTriggerLabel = (
  label: ?string
): ReduxAction<'UPDATE_IA_TRIGGER_LABEL', ?string> => {
  return { type: ACT.UPDATE_IA_TRIGGER_LABEL, payload: label }
}
export const updateCapping = (capping: number = 0): ReduxAction<'UPDATE_IA_CAPPING', number> => {
  return { type: ACT.UPDATE_IA_CAPPING, payload: capping }
}
export const updateGrace = (grace: number = 0): ReduxAction<'UPDATE_IA_GRACE', number> => {
  return { type: ACT.UPDATE_IA_GRACE, payload: grace }
}
export const updateRange = (
  date: ?Dayjs = null,
  end: boolean = false
): ReduxAction<'UPDATE_CAMPAIGN_START' | 'UPDATE_CAMPAIGN_END', ?Dayjs> => {
  return {
    type: end ? ACT.UPDATE_CAMPAIGN_END : ACT.UPDATE_CAMPAIGN_START,
    payload: date,
  }
}

export const addTranslation =
  ({ lang }: { lang: LanguageRecord, ... }): DispatchBoundFn<any> =>
  dispatch => {
    dispatch({ type: ACT.ADD_CAMPAIGN_TRANSLATION, payload: lang })
    dispatch(setActiveTranslation(lang.value))
  }
export const setActiveTranslation = (
  lang: string
): ReduxAction<'SET_CAMPAIGN_ACTIVE_TRANSLATION', string> => {
  return { type: 'SET_CAMPAIGN_ACTIVE_TRANSLATION', payload: lang }
}

export const removeTranslation =
  ({ lang }: { lang: LanguageRecord, ... }): DispatchBoundFn<any> =>
  (dispatch, getState) => {
    const state = getState()

    if (state.campaign.config.activeLanguageId === lang.value) {
      dispatch(setActiveTranslation('default'))
    }
    dispatch({ type: ACT.REMOVE_CAMPAIGN_TRANSLATION, payload: lang })
  }

export const togglePushAbTesting = (activate: boolean): DispatchBoundFn<any> => {
  return dispatch => {
    dispatch({
      type: activate ? ACT.PUSH_ENABLE_ABTESTING : ACT.PUSH_DISABLE_ABTESTING,
      payload: null,
    })
    // restore default variant when disabling
    if (!activate) {
      dispatch(pushShowVariant('a'))
    }
  }
}

export const togglePushVariant = (
  variant: string
): ReduxAction<'PUSH_ABTESTING_TOGGLE_VARIANT', { variant: string, ... }> => {
  return {
    type: ACT.PUSH_ABTESTING_TOGGLE_VARIANT,
    payload: { variant },
  }
}

export const pushShowVariant = (
  variant: string
): ReduxAction<'PUSH_ABTESTING_SHOW_VARIANT', { variant: string, ... }> => {
  return {
    type: ACT.PUSH_ABTESTING_SHOW_VARIANT,
    payload: { variant },
  }
}

export type toggleChannelAction = ReduxAction<'CAMPAIGN_TOGGLE_CHANNELS', Set<Channel>>
export const toggleChannel = (channels: Set<Channel>): toggleChannelAction => {
  return {
    type: 'CAMPAIGN_TOGGLE_CHANNELS',
    payload: channels,
  }
}
