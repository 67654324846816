// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type replicateOrchestrationAction = ReduxAction<
  'REPLICATE_ORCHESTRATION',
  { id: string, targetProjectKey: string, ... },
>
export type replicateOrchestrationSuccessAction = ReduxAction<
  'REPLICATE_ORCHESTRATION_SUCCESS',
  string,
>
export type replicateOrchestrationFailureAction = ReduxAction<
  'REPLICATE_ORCHESTRATION_FAILURE',
  string,
>

export const replicateOrchestration = ({
  id,
  targetProject,
}: {
  id: string,
  targetProject: ProjectRecord,
  ...
}): DispatchExtraBoundFn<Promise<string>> => {
  return async (dispatch, getState, { orchestrationService }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    try {
      dispatch(
        ({
          type: 'REPLICATE_ORCHESTRATION',
          payload: { id, targetProjectKey: targetProject.projectKey },
        }: replicateOrchestrationAction)
      )
      const replicatedCampaignToken = await orchestrationService.replicate({
        token: id,
        targetProject,
        project,
      })
      dispatch({
        type: 'REPLICATE_ORCHESTRATION_SUCCESS',
        payload: replicatedCampaignToken,
      })
      return replicatedCampaignToken
    } catch (err) {
      console.log(err)
      dispatch({
        type: 'REPLICATE_ORCHESTRATION_FAILURE',
        payload: err.error?.errors?.[0]?.message ?? 'Unknown error',
      })
      throw err
    }
  }
}
