// @flow

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  type ReduxAction,
  type DispatchOnlyBoundFn,
  type DispatchBoundFn,
} from 'com.batch.redux/_records'

export function useAction<T, R>(
  action: T => DispatchOnlyBoundFn<any> | DispatchBoundFn<any> | ReduxAction<string, any>
): T => R {
  const dispatch = useDispatch()
  return useCallback(
    (params: T) => {
      return dispatch(action(params))
    },
    [dispatch, action]
  )
}
