// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { useIntercomVerticalPadding } from 'components/_hooks/use-intercom-vertical-padding'
import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import {
  generateOrchestrationListUrl,
  generateOrchestrationUrl,
} from 'com.batch.common/router/url-generator-helper'

import { FooterOrchestrationContainer } from './footer-orchestration.styles'

import { campaignMessageErrorsSelector } from 'com.batch/message/store/message.composed.selector'
import {
  emptyMessageErrorsSelector,
  orchestrationDraftSelector,
  orchestrationErrorsSelector,
  orchestrationStateSelector,
} from 'com.batch/orchestration/store/orchestration.selectors'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import {
  estimateProfileGetterSelector,
  subscriptionStatusSelector,
} from 'com.batch.redux/target/target.selector'

import { persistOrchestration } from 'com.batch/orchestration/usecases/persist-orchestration'
import { getConfirmWordings } from 'com.batch/orchestration-list/ui/components/confirm-wording'
import { useLeaveConfirmation } from 'com.batch/shared/ui/hooks/use-leave-confirmation'

type FooterOrchestrationProps = {
  isFixed: boolean,
  channel?: ChannelUntilCleanup,
  multilanguageEnabledOnCampaign?: boolean,
}
export const FooterOrchestration: React.ComponentType<FooterOrchestrationProps> = React.memo(
  ({ isFixed, channel, multilanguageEnabledOnCampaign }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const estimateGetter = useSelector(estimateProfileGetterSelector)
    const estimate = React.useMemo(() => estimateGetter('default'), [estimateGetter])
    const orchestrationState = useSelector(orchestrationStateSelector)
    const orchestrationErrors = useSelector(orchestrationErrorsSelector)
    const messageErrorsSelector = React.useMemo(
      () =>
        orchestrationState.campaign.sendType === 'trigger'
          ? emptyMessageErrorsSelector
          : campaignMessageErrorsSelector,
      [orchestrationState.campaign.sendType]
    )
    const messageErrors = useSelector(messageErrorsSelector)
    const errorCount = React.useMemo(
      () => orchestrationErrors.size + messageErrors.size,
      [orchestrationErrors, messageErrors]
    )
    const hasUnsavedModifications = React.useMemo(
      () => orchestrationState.version !== orchestrationState.savedVersion,
      [orchestrationState.savedVersion, orchestrationState.version]
    )
    useLeaveConfirmation({ isPristine: !hasUnsavedModifications })
    const targetingMode = useSelector(subscriptionStatusSelector)
    const draftErrors = useSelector(orchestrationDraftSelector)
    const project = useSelector(currentProjectSelector)
    const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])
    const orchestrationWord = React.useMemo(() => {
      return orchestrationState.campaign.sendType === 'recurring' ||
        orchestrationState.campaign.sendType === 'trigger'
        ? 'automation'
        : 'campaign'
    }, [orchestrationState.campaign.sendType])
    useIntercomVerticalPadding(72)
    const createOnClickSave = React.useCallback(
      (forceRunning: boolean) => () => {
        const showConfirm =
          forceRunning || (Boolean(orchestrationState.id) && orchestrationState.state === 'RUNNING')
        const redirectAfterSave =
          orchestrationState.state !== 'DRAFT' ||
          forceRunning ||
          orchestrationState.campaign.sendType !== 'trigger'
        const onResolve = (token: string) => {
          if (redirectAfterSave) {
            const url = generateOrchestrationListUrl({
              companyId: project.companyId,
              projectId: project.id,
              channel: 'email',
              scheduling: orchestrationWord === 'campaign' ? 'campaigns' : 'automations',
            })
            navigate(url)
          } else {
            const url = generateOrchestrationUrl({
              token,
              page: 'form',
              companyId: project.companyId,
              projectId: project.id,
              channel: channel ?? null,
              sendType: orchestrationState.campaign.sendType,
            })
            if (!orchestrationState.id) navigate(url, { replace: true })
          }
        }
        const onReject = () => {}

        if (!showConfirm) {
          dispatch(
            persistOrchestration({
              incomplete: errorCount > 0,
              forceRunning,
            })
          ).then(onResolve, onReject)
        } else {
          const wordings = getConfirmWordings({
            orchestration: orchestrationState,
            estimate,
            isTransactional: targetingMode === 'fullbase',
            channel: orchestrationState.campaign.sendType === 'trigger' ? null : channel,
          })
          confirm({
            ...wordings.run,
          }).then(() => {
            dispatch(
              persistOrchestration({
                incomplete: false,
                forceRunning,
              })
            ).then(onResolve, onReject)
          }, onReject)
        }
      },
      [
        orchestrationState,
        project.companyId,
        project.id,
        orchestrationWord,
        dispatch,
        errorCount,
        estimate,
        targetingMode,
        navigate,
        channel,
      ]
    )
    const tooltipPropsDraft = React.useMemo(() => {
      const count = draftErrors.size
      return {
        minWidth: 150,
        isTooltipEmpty: count === 0,
        placement: 'top',
        offset: [0, 20],
        tooltip: (
          <div style={{ textAlign: 'left' }}>
            {count > 1 || multilanguageEnabledOnCampaign ? (
              <React.Fragment>
                <strong>Your {orchestrationWord} has errors:</strong>
                <ul style={{ marginTop: 2 }}>
                  {draftErrors.map((err, index) => (
                    <li key={index} style={{ textAlign: 'left', marginLeft: 18 }}>
                      {err}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ) : draftErrors.size > 0 ? (
              draftErrors.first()
            ) : null}
          </div>
        ),
      }
    }, [draftErrors, multilanguageEnabledOnCampaign, orchestrationWord])

    const tooltipPropsLive = React.useMemo(() => {
      const messageErrs = orchestrationState.campaign.sendType !== 'trigger' ? messageErrors : null
      const count = orchestrationErrors.size + (messageErrs?.size ?? 0)
      return {
        minWidth: 300,
        isTooltipEmpty: count === 0,
        placement: 'top',
        offset: [0, 20],
        tooltip: (
          <div style={{ textAlign: 'left' }}>
            {count > 1 || multilanguageEnabledOnCampaign ? (
              <React.Fragment>
                <strong>Your {orchestrationWord} has errors:</strong>
                <ul style={{ marginTop: 2 }}>
                  {orchestrationErrors.map((err, index) => (
                    <li key={index} style={{ textAlign: 'left', marginLeft: 18 }}>
                      {err}
                    </li>
                  ))}
                </ul>
                {!!messageErrs &&
                  messageErrs.toArray().map(([lang, errorsByLang]) => (
                    <React.Fragment key={lang}>
                      {multilanguageEnabledOnCampaign && (
                        <strong style={{ display: 'inline-block', marginTop: 5 }}>{lang}</strong>
                      )}
                      <ul style={multilanguageEnabledOnCampaign ? { marginLeft: 10 } : {}}>
                        {errorsByLang.map(err => (
                          <li key={err} style={{ textAlign: 'left', marginLeft: 18 }}>
                            {err}
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ) : orchestrationErrors.size > 0 ? (
              orchestrationErrors.first()
            ) : (
              messageErrs?.first()
            )}
          </div>
        ),
      }
    }, [
      orchestrationState.campaign.sendType,
      messageErrors,
      orchestrationErrors,
      multilanguageEnabledOnCampaign,
      orchestrationWord,
    ])

    return (
      orchestrationState.state !== 'COMPLETED' &&
      userHasWritePermission && (
        <FooterOrchestrationContainer $isFixed={isFixed}>
          {orchestrationState.state !== 'RUNNING' && (
            <Tooltip {...tooltipPropsDraft}>
              <span>
                <Button
                  intent="action"
                  kind={orchestrationState.state === 'RUNNING' ? 'primary' : 'secondary'}
                  isLoading={orchestrationState.savingState === 'LOADING'}
                  onClick={createOnClickSave(false)}
                  data-testid="btn-save-keep-state"
                  style={{ height: 36, width: 90 }}
                  disabled={draftErrors.size > 0 || !hasUnsavedModifications}
                  addOn={
                    Boolean(orchestrationState.id) && !hasUnsavedModifications
                      ? 'prefix'
                      : undefined
                  }
                >
                  {Boolean(orchestrationState.id) && !hasUnsavedModifications && (
                    <Icon icon="check" />
                  )}
                  {Boolean(orchestrationState.id) && !hasUnsavedModifications
                    ? 'Saved'
                    : orchestrationState.id
                      ? 'Save'
                      : 'Save draft'}
                </Button>
              </span>
            </Tooltip>
          )}
          <Tooltip {...tooltipPropsLive}>
            <span>
              <Button
                intent="action"
                isLoading={orchestrationState.savingState === 'LOADING'}
                data-testid="btn-save-force-state-running"
                kind={'primary'}
                onClick={createOnClickSave(true)}
                style={{ height: 36 }}
                disabled={
                  errorCount > 0 ||
                  (!hasUnsavedModifications && orchestrationState.state === 'RUNNING')
                }
                addOn="suffix"
              >
                {orchestrationState.state === 'RUNNING' ? 'Update' : 'Run'}

                <Icon icon="send" />
              </Button>
            </span>
          </Tooltip>
        </FooterOrchestrationContainer>
      )
    )
  }
)

FooterOrchestration.displayName = 'FooterOrchestration'
