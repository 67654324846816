// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink as Link } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { DateRangePicker } from 'components/form'
import { Header, HeaderTitle, HeaderActions } from 'components/styled/blocs'
import { SubNav, SubNavLink } from 'components/styled/subnav'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { useGenerateAnalyticsUrl } from 'com.batch.common/router'

import { useRange } from './use-range'

import { useCurrentCompanyHasOneFeatureAmongst } from '../_hooks/use-company-feature'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'

let ranges = [
  {
    name: 'Last 30 days',
    from: dayjs.utc().endOf('day').subtract(30, 'day'),
    to: dayjs().utc().endOf('day'),
  },
  {
    name: 'Last 90 days',
    from: dayjs.utc().endOf('day').subtract(90, 'day'),
    to: dayjs().utc().endOf('day'),
  },
  {
    name: 'Current month',
    from: dayjs().utc().startOf('month'),
    to: dayjs().utc().endOf('day'),
  },
]
for (let i = 1; i < 5; i++) {
  ranges.push({
    name: dayjs().subtract(i, 'month').startOf('month').format('MMMM YYYY'),
    from: dayjs.utc().subtract(i, 'month').startOf('month'),
    to: dayjs.utc().subtract(i, 'month').endOf('month'),
  })
}
const RANGES = new Immutable.List().push(...ranges)
const isDatePassed = (day: Date) => dayjs.utc().hour(23).minute(59).add(1, 'hour').isBefore(day)

export const AnalyticsMenu = (): React.Node => {
  const { range, setRange } = useRange()
  const getUrl = useGenerateAnalyticsUrl()
  /*
    if this menu is shown, audience will always be shown
    reach & notifications requires a non in-app page avaliable
  */
  const maybeProject = useSelector(optionalCurrentProjectSelector)
  const cepCompanyCanUseLegacyPush = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-campaign',
    'cep-show-legacy-campaign-omni',
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
  ])
  const showPushRelatedPages = Boolean(cepCompanyCanUseLegacyPush || !maybeProject)
  return (
    <Header>
      <HeaderTitle>
        <SubNav>
          <SubNavLink role="link" as={Link} to={getUrl({})} end>
            Audience
          </SubNavLink>
          {showPushRelatedPages && (
            <React.Fragment>
              <SubNavLink
                role="link"
                as={Link}
                to={getUrl({ tab: 'reach' })}
                style={{ alignItems: 'center' }}
              >
                Reach
              </SubNavLink>
              <SubNavLink role="link" as={Link} to={getUrl({ tab: 'push' })}>
                Notifications
              </SubNavLink>
            </React.Fragment>
          )}
        </SubNav>
      </HeaderTitle>
      <HeaderActions>
        <ThemeProvider theme={{ kind: 'filter' }}>
          <DateRangePicker
            range={range}
            shortcuts={RANGES}
            setRange={setRange}
            position="right"
            disabledDays={isDatePassed}
            changePlaceholderOnFocus={true}
            style={{ maxWidth: 260 }}
            icon="calendar"
            placeholder="Last month"
          />
        </ThemeProvider>
      </HeaderActions>
    </Header>
  )
}
