// @flow

import { type Map } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { AttributesTable } from 'com.batch/profile/ui/components/attributes-block/attributes-table'
import { useAttributesSheet } from 'com.batch/profile/ui/components/events-block/attributes-sheet/attributes-sheet-provider'
import { AttributesContainer } from 'com.batch/profile/ui/components/events-block/event-row/event-row.styles'

type Props = {
  attributes: Map<string, QueryValue>,
  eventName: string,
  isShown: boolean,
}

export const EventRowAttributes = ({ attributes, eventName, isShown }: Props): React.Node => {
  const [attributeNames, setAttributeNames] = React.useState<string[]>([])
  const { open } = useAttributesSheet()

  React.useEffect(() => {
    let names: Array<string> = []
    if (attributes.size > 0) {
      names = names.concat(attributes.keySeq().toArray())
    }
    setAttributeNames(names.sort((a, b) => a.localeCompare(b)))
  }, [attributes])

  const handleClickSheetBtn = React.useCallback(
    () => open(attributes, eventName),
    [attributes, eventName, open]
  )

  return (
    <AttributesContainer>
      {isShown && (
        <React.Fragment>
          <AttributesTable
            attributes={attributes}
            event={eventName}
            names={attributeNames}
            isLoading={false}
          />
          <Button kind="inline" intent="neutral" onClick={handleClickSheetBtn}>
            View complete data as JSON
            <Icon style={{ marginLeft: '7px' }} icon="view" />
          </Button>
        </React.Fragment>
      )}
    </AttributesContainer>
  )
}
