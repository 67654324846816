// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Grid } from 'components/common/grid'
import { red } from 'components/styled/tokens/schemes'

export const LegacyBannerContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  padding: 16px 9px 16px 20px;
  background: ${red[50]};

  ${Grid} {
    > p {
      color: #ffffff;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`
