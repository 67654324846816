// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import {
  fillAction,
  fillBackground,
  strokeDanger,
  textLight,
} from 'components/styled/tokens/colors'
import { bluelucent, darklucent } from 'components/styled/tokens/schemes'

export const DropzoneContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  .error {
    color: ${strokeDanger};
  }
`

export const DropzoneTitle: StyledComponent<*, *, HTMLParagraphElement> = styled.p`
  font-weight: 500;
`
type DropZoneProps = { isActive: boolean, hasError: boolean, isLoading: boolean, ... }
export const Dropzone: StyledComponent<DropZoneProps, { ... }, HTMLDivElement> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  min-height: 56px;
  padding: 8px 160px;
  margin: 8px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed ${darklucent[20]};
  background: ${fillBackground};

  > p {
    color: ${textLight};
    font-weight: 500;
  }

  ${(p: DropZoneProps) =>
    !p.isLoading &&
    css`
      cursor: pointer;
    `};

  ${(p: DropZoneProps) =>
    (p.hasError || p.isActive) &&
    css`
      background: linear-gradient(0deg, ${bluelucent['02']} 0%, ${bluelucent['02']} 100%),
        ${fillBackground};
      border-color: ${fillAction};
    `};

  ${(p: DropZoneProps) =>
    p.hasError &&
    css`
      border-color: ${strokeDanger};

      > p {
        font-weight: 400;
      }
    `};
`
