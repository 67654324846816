// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { ButtonLink } from 'components/common/button'
import { Code } from 'components/common/code'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { trackEvent } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableCellHeader,
  TableRow,
  TableCell,
  TableCellActions,
  TableBody,
} from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'
import { EmptyTitle, EmptyText, Empty } from 'components/styled/empty'
import { Timezone } from 'components/styled/text'

import { formatIdentifier } from 'com.batch.common/utils'

import { GdprListEmptyTemplate } from './gdpr.styles'
import { type GDPRrequest, type urlParams } from './gdpr.types'

type GdprListProps = {
  filteredRequests: Array<GDPRrequest>,
  sortBy: string,
  sortOrder: string,
  updateUrl: urlParams => void,
  platform: Platforms,
  ...
}

export const GdprList = ({
  filteredRequests,
  platform,
  sortBy,
  sortOrder,
  updateUrl,
}: GdprListProps): React.Node => {
  const theme = useTheme()
  // empty constants
  const isEmpty = theme && theme.isEmpty
  const isLoading = theme && theme.isLoading
  const noMatch = filteredRequests.length === 0
  const createOnSort = React.useCallback(
    (field: string) => () => {
      updateUrl({
        sortOrder: sortOrder === 'asc' ? 'dsc' : 'asc',
        sortBy: field,
      })
    },
    [sortOrder, updateUrl]
  )
  return (
    <React.Fragment>
      <Table
        template={'100px 100px 100px minMax(120px, 400px) 1fr 50px'}
        style={{ tableLayout: 'fixed' }}
        rowHeight={isEmpty || isLoading ? 60 : 52}
      >
        <TableHeader>
          <TableCellOrder
            sort={sortBy === 'status' ? (sortOrder === 'asc' ? 'asc' : 'dsc') : false}
            onClick={createOnSort('status')}
          >
            #
          </TableCellOrder>

          <TableCellOrder
            sort={sortBy === 'request_type' ? (sortOrder === 'asc' ? 'asc' : 'dsc') : false}
            onClick={createOnSort('request_type')}
          >
            Type
          </TableCellOrder>

          <TableCellOrder
            sort={sortBy === 'request_date' ? (sortOrder === 'asc' ? 'asc' : 'dsc') : false}
            onClick={createOnSort('request_date')}
          >
            Date
          </TableCellOrder>

          <TableCellHeader>Identifier</TableCellHeader>
          <TableCellHeader>Request token</TableCellHeader>
          <div />
        </TableHeader>

        <TableBody emptyTemplate={<GdprListEmptyTemplate />} templateSize={10}>
          {filteredRequests.map(req => (
            <TableRow key={req.request_id}>
              <TableCell>
                <Tooltip
                  tooltip={
                    req.error
                      ? req.error
                      : req.status === 'pending'
                        ? 'Process can take up to 2 weeks'
                        : ''
                  }
                  isTooltipEmpty={!req.error}
                >
                  <Icon
                    color={req.error ? '#DA2F4A' : req.status === 'pending' ? '#565990' : '#4DB24D'}
                    icon={req.error ? 'error' : req.status === 'pending' ? 'pending' : 'success'}
                  />
                </Tooltip>
              </TableCell>
              <TableCell>{req.request_type === 'remove' ? 'Erasure' : 'Access'}</TableCell>
              <TableCell>{req.request_date.format('DD/MM/YYYY')}</TableCell>
              <TableCell>
                <FlexLine style={{ minWidth: 0 }}>
                  <FlexLineItem ellipsis>{formatIdentifier(platform, req.id.type)}</FlexLineItem>
                  <FlexLineItem ellipsis style={{ color: '#707782' }}>
                    {req.id.value}
                  </FlexLineItem>
                  {req.trigger_type === 'api' && (
                    <FlexLineItem>
                      <Timezone style={{ minWidth: 25 }}>API</Timezone>{' '}
                    </FlexLineItem>
                  )}
                </FlexLine>
              </TableCell>
              <TableCell>
                <Code size="small">{req.request_id}</Code>
              </TableCell>
              {req.request_type === 'review' && req.status === 'done' && !req.error && (
                <TableCellActions>
                  <Tooltip tooltip="Download">
                    <ButtonLink
                      style={{ height: 28 }}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        trackEvent('GDPR_DOWNLOAD', {
                          request_token: req.request_id,
                          id: req.id.type,
                        })
                      }}
                      href={req.data_url ?? ''}
                      disabled={!req.data_url}
                    >
                      <Icon icon="download" />
                    </ButtonLink>
                  </Tooltip>
                </TableCellActions>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {noMatch && !isLoading && !isEmpty && (
        <Empty style={{ height: 200, position: 'relative' }}>
          <EmptyTitle>No request to display</EmptyTitle>
          <EmptyText>
            No request is matching your current filters. Please try with different ones
          </EmptyText>
        </Empty>
      )}
    </React.Fragment>
  )
}
