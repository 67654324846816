// @flow

import Immutable, { type RecordFactory, type RecordOf } from 'immutable'

type SegmentProps = {
  name: string,
  displayName: string,
  query: string,
  campaignCount: number,
  campaignRunningCount: number,
  automationCount: number,
  automationRunningCount: number,
  deletedAt?: string,
}

export type SegmentRecord = RecordOf<SegmentProps>

export const SegmentFactory: RecordFactory<SegmentProps> = Immutable.Record(
  ({
    name: '',
    displayName: '',
    query: '',
    campaignCount: 0,
    automationCount: 0,
    campaignRunningCount: 0,
    automationRunningCount: 0,
    deletedAt: undefined,
  }: SegmentProps)
)
