// @flow

import styled, { css, type CSSRules, type StyledComponent } from 'styled-components'

import * as colorsLegacy from 'components/styled/colors'
import { colors } from 'components/styled/tokens'

import { InputContainer } from '../styles'

const currentColorInputStyled = css`
  background-color: transparent;
  border: 0;
  margin: -10px -12px;
  color: white;
`

// ==================== STYLED COMPONENT
const CopyInputContainer: StyledComponent<*, *, typeof InputContainer> = styled(InputContainer)`
  ${(p: any) => p.currentColor && currentColorInputStyled}

  position: relative;

  &:focus-within {
    box-shadow: 0 0 0 1px ${colors.stroke};
    ${(p: any) => p.currentColor && currentColorInputStyled}
  }

  > span {
    display: flex;
    align-items: center;
    position: absolute;
    right: 44px;
    font-size: 12px;
    font-weight: 500;
    color: ${colors.textAction};
    text-transform: uppercase;
    background: ${colors.fill};
    border-radius: 5px;
    transition: opacity 0.2s ease;
  }
`

export const styleInput: CSSRules = css`
  font-size: ${(props: any) => (props.currentColor ? '20px' : '6px')};
`

// ==================== exports
export { CopyInputContainer }
