// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'

export const LegacyBlockContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  > ${Button} {
    padding-left: 0;
  }
`
