// @flow

import Immutable, { type OrderedMap } from 'immutable'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { TextualProjectKeyModelFactory } from 'com.batch.redux/adapters/project/models/project-key'
import { type SenderIdentityModel } from 'com.batch.redux/adapters/sender-identity/models/sender-identity'
import {
  parseSenderIdentityModelToRecords,
  parseSenderIdentityRecordToModel,
} from 'com.batch.redux/adapters/sender-identity/parse/parse'
import { type SenderIdentityGateway } from 'com.batch.redux/corelogic/gateways/sender-identity.interface'
import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'

// =============== RESPONSE MODELS
type fetchSenderIdentitiesRestResponse = { senderIdentities: Array<SenderIdentityModel> }
type createSenderIdentityRestResponse = { id: number }
type countBySenderIdentityResponse = { count?: number }

/**
 * Generate sender identity grpc service url for the given method
 */
const generateUrlForMethod = (methodName: string): string => {
  return generateUrl('api_grpc_email_sender_identity_service', { methodName })
}

// =============== GATEWAY IMPLEMENTATION
export const SenderIdentityRest: SenderIdentityGateway = {
  fetchSenderIdentities: async (projectKey): Promise<OrderedMap<number, SenderIdentityRecord>> => {
    const url = generateUrlForMethod('List')
    const payload = {
      projectKey: TextualProjectKeyModelFactory(projectKey),
    }
    const { senderIdentities } = await request.post<fetchSenderIdentitiesRestResponse>(url, payload)
    if (!senderIdentities) {
      return Immutable.OrderedMap()
    }
    return senderIdentities.reduce((acc, curr) => {
      return acc.set(curr.id, parseSenderIdentityModelToRecords(curr))
    }, Immutable.OrderedMap())
  },

  createSenderIdentity: async (projectKey, senderIdentity): Promise<SenderIdentityRecord> => {
    const url = generateUrlForMethod('Create')
    const payload = { senderIdentity: parseSenderIdentityRecordToModel(senderIdentity, projectKey) }
    const { id } = await request.post<createSenderIdentityRestResponse>(url, payload)
    payload.senderIdentity.id = id
    return parseSenderIdentityModelToRecords(payload.senderIdentity)
  },

  updateSenderIdentity: async (projectKey, senderIdentity): Promise<SenderIdentityRecord> => {
    const url = generateUrlForMethod('Update')
    const payload = { senderIdentity: parseSenderIdentityRecordToModel(senderIdentity, projectKey) }
    await request.post<void>(url, payload)
    return parseSenderIdentityModelToRecords(payload.senderIdentity)
  },

  deleteSenderIdentity: async (projectKey, senderIdentityId): Promise<number> => {
    const url = generateUrlForMethod('Delete')
    const payload = {
      id: senderIdentityId,
      projectKey: TextualProjectKeyModelFactory(projectKey),
    }
    await request.post<void>(url, payload)
    return payload.id
  },

  countBySenderIdentity: async (projectKey, senderIdentityId): Promise<number> => {
    const url = generateUrl('api_grpc_orchestration_service', {
      methodName: 'CountBySenderIdentity',
    })
    const payload = {
      senderIdentityId: senderIdentityId.toString(),
      projectKey: TextualProjectKeyModelFactory(projectKey),
    }
    const { count } = await request.post<countBySenderIdentityResponse>(url, payload)
    return count ?? 0
  },
}
