// @flow

import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { FileIconContainer } from './uploadFiles.styles'

type FileIconProps = {
  extension?: string,
  isInvalid: boolean,
  ...
}

const FileIcon = ({ extension, isInvalid }: FileIconProps): React.Node => {
  return (
    <FileIconContainer isInvalid={isInvalid} hasExtension={!!extension}>
      <Icon icon="file-icon" size={32} />
      <span>{extension}</span>
    </FileIconContainer>
  )
}

export default FileIcon
