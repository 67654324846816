// @flow

import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import * as schemes from 'components/styled/tokens/schemes'

export const InputButton: StyledComponent<*, *, *> = styled(Button)`
  color: ${schemes.grayscale['60']};

  &:hover {
    color: ${schemes.grayscale['80']};
  }
`
