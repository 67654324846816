// @flow

import Immutable, { type Set } from 'immutable'

import { type TargetStateRecord } from './target.records'

import { type UserRecord } from '../user.records'
import { languages, regions } from 'com.batch.redux/lang-region'

import { consistentLocaleCase } from 'com.batch/orchestration/infra/parses/consistent-locale-case'
import { type Targeting } from 'com.batch/orchestration-campaign/infra/types/orchestration-campaign.types'

export const getLangAndRegionArray = ({
  targetingState,
  user,
}: {
  targetingState: TargetStateRecord,
  user: UserRecord,
}): { languages: Array<string>, regions: Array<string> } => {
  const pickableLanguages =
    user.restrictedLanguages.size === 0
      ? languages
      : languages.filter(lg => user.restrictedLanguages.has(lg.value))
  const pickableRegions =
    user.restrictedRegions.size === 0
      ? regions
      : regions.filter(rg => user.restrictedRegions.has(rg.value))
  return {
    languages:
      targetingState.languagesInverted && targetingState.languages.size > 0
        ? pickableLanguages
            .filter(lg => !targetingState.languages.has(lg.value))
            .map(lg => lg.value)
            .toArray()
        : targetingState.languages.toArray(),
    regions:
      targetingState.regionsInverted && targetingState.regions.size > 0
        ? pickableRegions
            .filter(rg => !targetingState.regions.has(rg.value))
            .map(rg => rg.value)
            .toArray()
        : targetingState.regions.toArray(),
  }
}

export const buildLangAndRegionFromRowTargeting = ({
  targeting,
  user,
}: {
  targeting: ?Targeting,
  user: UserRecord,
}): {
  languages: Set<string>,
  languagesInverted: boolean,
  regions: Set<string>,
  regionsInverted: boolean,
} => {
  const pickableLanguages =
    user.restrictedLanguages.size === 0
      ? languages
      : languages.filter(lg => user.restrictedLanguages.has(lg.value))
  const pickableRegions =
    user.restrictedRegions.size === 0
      ? regions
      : regions.filter(rg => user.restrictedRegions.has(rg.value))

  const languagesSelected = Immutable.Set(targeting?.languages ?? []).map(consistentLocaleCase)
  const languagesInverted = languagesSelected.size > pickableLanguages.size / 2
  const regionsSelected = Immutable.Set(targeting?.regions ?? [])
  const regionsInverted = regionsSelected.size > pickableRegions.size / 2
  return {
    languages: languagesInverted
      ? pickableLanguages
          .filter(lg => !languagesSelected.has(lg.value))
          .map(lg => lg.value)
          .toSet()
      : languagesSelected,
    languagesInverted,
    regions: regionsInverted
      ? pickableRegions
          .filter(rg => !regionsSelected.has(rg.value))
          .map(rg => rg.value)
          .toSet()
      : regionsSelected,
    regionsInverted,
  }
}
