// @flow

import { useTransition, animated } from '@react-spring/web'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { NotifierMessage } from './notifier-message'
import { NotifierContainer } from './notifier.styles'

import { type State } from 'com.batch.redux/_records'
import { type ToasterRecord } from 'com.batch.redux/toaster.records'

// ====================== CONFIG
const springConfig = { tension: 350, friction: 20 }
const toastSelector = (state: State) => state.toaster

const NotifierMessageAnimated = animated(NotifierMessage)

// ====================== CURRENT COMPONENT
const NotifierRaw = () => {
  const [stop, setStop] = React.useState<boolean>(false)

  // ====================== redux state
  const alerts = useSelector(toastSelector)
  const alertsArray: Array<ToasterRecord> = React.useMemo(
    () => alerts.toArray().reverse(),
    [alerts]
  )

  // ====================== Component constants
  const transition = useTransition(alertsArray, {
    keys: item => item.id,
    from: { opacity: 0, height: 0, transform: 'translate3d(0, -50px, 0)' },
    enter: item => async next => {
      let msgElement = document.getElementById(`batch_toast_${item.id}`)
      await next({
        height: msgElement ? msgElement.offsetHeight + 11 : 0,
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      })
    },
    leave: () => async next => {
      await next({ opacity: 0, height: 0 })
    },
    config: { ...springConfig },
  })

  const onNotifierHover = React.useCallback((isStop: boolean) => () => setStop(isStop), [])

  // ====================== Render
  return (
    <NotifierContainer
      id="notifier"
      onMouseEnter={onNotifierHover(true)}
      onMouseLeave={onNotifierHover(false)}
    >
      {transition(({ opacity, height, transform }, item) => {
        return (
          item && (
            <animated.div style={{ opacity, height, overflow: 'visible' }}>
              <div id={`batch_toast_${item.id}`}>
                <NotifierMessageAnimated msg={item} stop={stop} style={{ transform }} />
              </div>
            </animated.div>
          )
        )
      })}
    </NotifierContainer>
  )
}

const Notifier: React.ComponentType<{}> = React.memo(NotifierRaw)
export default Notifier
