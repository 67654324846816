// @flow
export type TextualProjectKeyModel = {
  text?: string,
}

export type BinaryProjectKeyModel = {
  data?: string,
}

export type ProjectKeyModel = {
  binary?: BinaryProjectKeyModel,
  data?: string,
  textual?: TextualProjectKeyModel,
}

export const TextualProjectKeyModelFactory = (key: string): ProjectKeyModel => {
  return {
    textual: {
      text: key,
    },
  }
}
