// @flow

import styled, {
  css,
  keyframes,
  type StyledComponent,
  type PropsWithTheme,
} from 'styled-components'

import { Box, BoxBody } from 'components/common/box'
import { FlexLine } from 'components/common/flexline'
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes } from 'components/styled/tokens'

export const CappingBox: StyledComponent<{ isEditing?: boolean, ... }, *, *> = styled(Box)`
  position: relative;

  ${(props: { isEditing?: boolean, ... }) =>
    props.isEditing &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0px;
        height: calc(100% + 2px);
        width: 10px;
        border-left: 2px solid ${colors.strokeAction};
        z-index: 11;
        border-radius: 6px;
      }
    `}
`

export const CappingRow: StyledComponent<*, *, typeof FlexLine> = styled(FlexLine)`
  padding: 0 22px;
  height: 44px;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: -1px;
    background-color: ${colorsLegacy.stroke.lighter};
    left: 12px;
    right: 12px;
  }

  & > div {
    padding: 0 6px 0 0;
  }

  & > p {
    color: ${colors.textLight};
  }

  &:last-child {
    border-bottom: none;
  }

  input {
    text-align: left;
  }
`

export const CappingData: StyledComponent<*, *, HTMLElement> = styled.div`
  padding: 2px 4px;
  line-height: 16px;
  background-color: ${schemes.darklucent['10']};
  border-radius: 3px;
`

export const NoLabelsCapping: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  font-size: 13px;
  background-color: ${schemes.darklucent['05']};
  color: ${colors.textLight};
  border-radius: 4px;

  span {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: ${colors.textNeutral};
  }
`

export const PressureCategoryList: StyledComponent<*, *, typeof BoxBody> = styled(BoxBody)`
  max-height: calc(100vh - 250px);
  overflow: auto;

  & > div {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 14px 22px;
    font-size: 13px;
    line-height: 16px;
    color: ${colors.text};
    border-bottom: 1px solid ${colorsLegacy.stroke.lighter};

    &:hover {
      background-color: ${colors.fillBackground};
      cursor: pointer;
    }

    &:last-of-type {
      border-bottom: none;
    }

    & > span {
      padding-left: 8px;
      min-width: fit-content;
    }
  }
`

const AnimationSDKLoading = keyframes`
  0% {
    background: ${schemes.darklucent['30']};
  }
  50% {
    background: ${schemes.darklucent['10']};
  }
  100% {
    background: ${schemes.darklucent['30']};
  }
`

export const SdkBadge: StyledComponent<{ ratio: number, isLoading: boolean, ... }, *, *> =
  styled.span`
    padding: 2px 3px;
    margin-left: 4px;
    font-size: 10px;
    font-weight: 600;
    color: ${(props: PropsWithTheme<{ isLoading: boolean, ratio: number, ... }, any>) =>
      props.isLoading ? 'transparent' : '#fff'};

    background: ${(props: PropsWithTheme<{ isLoading: boolean, ratio: number, ... }, any>) =>
      props.isLoading
        ? schemes.darklucent['20']
        : props.ratio > 0.2
          ? schemes.grayscale['40']
          : colors.fillWarning};
    border-radius: 3px;
    ${(props: PropsWithTheme<{ isLoading: boolean, ratio: number, ... }, any>) =>
      props.isLoading &&
      css`
        animation-name: ${AnimationSDKLoading};
        animation-duration: 2s;
        animation-iteration-count: infinite;
      `}
  `
