// @flow

import * as React from 'react'
import styled, {
  css,
  ThemeContext,
  type StyledComponent,
  type PropsWithTheme,
} from 'styled-components'

import { AvatarContent } from 'components/common/avatar.styles'
import { builtEmptyStateAnimation } from 'components/common/empty-states/'
import { colors, schemes } from 'components/styled/tokens'

type AppAvatarProps = {
  icon: string,
  name: string,
  loading?: boolean,
  style?: any,
  size?: number,
  ...
}
type AvatarContainerProps = { size: number, loader?: boolean, ... }
type AvatarContainerThemeProps = { isEmpty?: boolean, ... }
type AvatarContainerPropsWithTheme = PropsWithTheme<AvatarContainerProps, AvatarContainerThemeProps>
const AppAvatarContainer: StyledComponent<AvatarContainerProps, AvatarContainerThemeProps, *> =
  styled(AvatarContent)`
    width: ${(p: AvatarContainerPropsWithTheme) => (!p.size ? '120px' : `${p.size}px`)};
    height: ${(p: AvatarContainerPropsWithTheme) => (!p.size ? '120px' : `${p.size}px`)};
    margin-bottom: 20px;
    border-radius: 15px;
    mask: url("data:image/svg+xml,%3Csvg width='182' height='182' viewBox='0 0 182 182' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 64C0 33.8301 0 18.7452 9.37258 9.37258C18.7452 0 33.8301 0 64 0H118C148.17 0 163.255 0 172.627 9.37258C182 18.7452 182 33.8301 182 64V118C182 148.17 182 163.255 172.627 172.627C163.255 182 148.17 182 118 182H64C33.8301 182 18.7452 182 9.37258 172.627C0 163.255 0 148.17 0 118V64Z' fill='black'/%3E%3C/svg%3E")
      0 0/100% 100%;

    ${(p: AvatarContainerPropsWithTheme) =>
      p.loader
        ? builtEmptyStateAnimation()
        : p.theme.isEmpty &&
          css`
            background: ${schemes.grayscale['30']};
          `}

    > span {
      color: ${colors.text};
      font-size: 64px;
      font-weight: 500;
    }
  `

export const AppAvatar = ({
  icon,
  name,
  loading,
  style,
  size = 120,
}: AppAvatarProps): React.Node => {
  const theme = React.useContext(ThemeContext)

  const isLoading = loading || (theme && theme.isLoading)
  const isEmpty = theme && theme.isEmpty

  return (
    <AppAvatarContainer
      size={size}
      url={!!icon && !isLoading ? icon : ''}
      loader={isLoading}
      color="linear-gradient(0deg, #DDDDDD, #DDDDDD)"
      style={style}
    >
      {!isLoading && !icon && !isEmpty && <span>{name.split('')[0].toUpperCase()}</span>}
    </AppAvatarContainer>
  )
}
