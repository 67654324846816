// @flow
import { type ReduxAction, type pushCampaignSendType } from 'com.batch.redux/_records'

export type InitFormAction = ReduxAction<
  'INIT_FORM',
  {
    type: campaignType,
    schedulingType: schedulingType,
    sendType: pushCampaignSendType,
    channel: ChannelUntilCleanup,
    messageTypedId: string,
  },
>

// TODO certainement le déplacer
export const initForm = ({
  type,
  schedulingType,
  sendType,
  channel,
  messageTypedId,
}: {
  type: campaignType,
  schedulingType: schedulingType,
  sendType: pushCampaignSendType,
  channel: ChannelUntilCleanup,
  messageTypedId: string,
}): InitFormAction => {
  return {
    type: 'INIT_FORM',
    payload: {
      type,
      schedulingType,
      sendType,
      channel,
      messageTypedId,
    },
  }
}
