// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { type StateToggler } from 'components/_hooks'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Input } from 'components/form'

import { saveStringValueInLocalStorage } from 'com.batch/shared/usecases/save-restore-local'
import { sendTestSms } from 'com.batch/sms/usecases/send-test-sms'

export const savedNumberKey = 'sendTestSmsNumbers'
type PopinSendTestSmsProps = {
  messageId: string,
  togglePopin: StateToggler,
}

export const PopinSendTestSms = ({ messageId, togglePopin }: PopinSendTestSmsProps): React.Node => {
  const [value, setValue] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const dispatch = useDispatch()
  const onPhoneNumberChange = React.useCallback(event => {
    setValue(event.target.value)
  }, [])
  const onSubmit = React.useCallback(
    async evt => {
      setIsLoading(true)
      evt.preventDefault()
      saveStringValueInLocalStorage({
        key: savedNumberKey,
        value,
      })

      dispatch(sendTestSms({ messageId, recipient: value }))
        .then(() => {
          togglePopin.close()
        })
        .catch(() => {}) // handled by _failure action
        .finally(() => {
          setValue('')
          setIsLoading(false)
        })
    },
    [dispatch, messageId, togglePopin, value]
  )

  return (
    <Popin
      opened={togglePopin.value}
      close={togglePopin.close}
      style={{ width: 480, overflowY: 'hidden' }}
    >
      <form onSubmit={onSubmit}>
        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="Send a test" />
            <HeaderBoxActions>
              <Button kind="inline" intent="neutral" onClick={togglePopin.close} type="button">
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody $padding>
            <Input
              autoFocus
              type="tel"
              placeholder="Enter +33000000000"
              data-testid="phone-number-input"
              value={value}
              onChange={onPhoneNumberChange}
            />
          </BoxBody>
          <BoxFooter isEditable>
            <Button kind="inline" onClick={togglePopin.close} type="button">
              Cancel
            </Button>
            <FooterBoxActions>
              <Button
                kind="primary"
                intent="action"
                type="submit"
                isLoading={isLoading}
                data-testid="send-test-button"
              >
                Send test
              </Button>
            </FooterBoxActions>
          </BoxFooter>
        </Box>
      </form>
    </Popin>
  )
}
