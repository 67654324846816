// @flow
import React from 'react'

type Size = {
  width: ?number,
  height: ?number,
  ...
}

export const useWindowSize = (): Size => {
  const [size, setSize] = React.useState<Size>({
    width: null,
    height: null,
  })

  React.useLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return size
}
