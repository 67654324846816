// @flow

import Immutable, { type Set, type List } from 'immutable'
import * as React from 'react'

import { Filter } from 'components/campaign/filters/filter'
import { Icon } from 'components/common/svg-icon'
import { FilterSelectMulti } from 'components/filter'
import * as schemes from 'components/styled/tokens/schemes'

type ChannelsFilterProps = {
  value: Set<ChannelUntilCleanup>,
  onChange: (Set<ChannelUntilCleanup>) => void,
}

const options = new Immutable.List().push(...['email', 'push', 'sms'])
const optToString = (opt: ?ChannelUntilCleanup) => opt ?? ''

export const ChannelsFilter = ({ value, onChange }: ChannelsFilterProps): React.Node => {
  const formatOptionWord = React.useCallback((option: ChannelUntilCleanup) => {
    switch (option) {
      case 'sms':
        return 'SMS'
      case 'email':
        return 'Email'
      case 'push':
        return 'Push'
      default:
        return ''
    }
  }, [])

  const onSelect = React.useCallback(
    (values: List<ChannelUntilCleanup>) => {
      onChange(values.toSet())
    },
    [onChange]
  )

  const formatTerm = React.useCallback(
    (count: number) => (count === 1 ? formatOptionWord(value.first()) : `${count} channels`),
    [formatOptionWord, value]
  )

  const formatOption = React.useCallback(
    (option: ChannelUntilCleanup) => {
      return (
        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {formatOptionWord(option)}
          <Icon icon={option === 'email' ? 'mail' : option} />
        </span>
      )
    },
    [formatOptionWord]
  )
  return (
    <Filter title="Channel">
      <FilterSelectMulti
        options={options}
        value={value.toList()}
        onChange={onSelect}
        placeholder="All channels"
        term={formatTerm}
        isSearchable={false}
        optionToString={optToString}
        optionFormatter={formatOption}
        menuOffset={20}
        style={{
          minWidth: '140px',
          maxWidth: '170px',
        }}
        optionMenuStyle={{ minWidth: '140px', maxWidth: '170px' }}
      />
    </Filter>
  )
}
