// @flow

import styled, { css, type StyledComponent } from 'styled-components'

import { textLight } from 'components/styled/tokens/colors'
import { grayscale } from 'components/styled/tokens/schemes'
import { block } from 'components/styled/tokens/shadows'

type MessageBubbleProps = { border?: boolean, ... }
export const MessageBubble: StyledComponent<MessageBubbleProps, { ... }, HTMLDivElement> =
  styled.div`
    background: ${grayscale['05']};
    display: grid;
    width: 386px;
    padding: 10px;
    border-radius: 14px;
    position: relative;

    ${(p: MessageBubbleProps) =>
      p.border
        ? css`
            border: 1px solid #d3d3d3;
            box-shadow: ${block};
          `
        : css`
            margin-bottom: 50px;
          `};

    :after {
      bottom: -0.1rem;
      content: ${(p: MessageBubbleProps) =>
        p.border
          ? "url('/medias/icons/msg-bubble-comma.svg')"
          : "url('/medias/icons/msg-bubble-comma-no-border.svg')"};
      position: absolute;
      transform: ${(p: MessageBubbleProps) =>
        p.border ? 'translate(-8.1px, 7px)' : 'translate(-5.3px, 6px)'};
    }
  `

export const CustomSenderContainer: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  margin-top: 70px;
  margin-left: 101px;
  width: 195px;
  font-weight: 500;
  text-align: center;
`

export const UnsubMessage: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  color: ${textLight};
  padding-inline: 6px;
  margin-top: 4px;
`
export const FadeOverflow: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  content: '';
  height: 70px;
  background: linear-gradient(
    to bottom,
    rgba(253, 253, 253, 0) 0%,
    rgba(253, 253, 253, 0.4) 15%,
    rgba(248, 248, 248, 0.7) 35%,
    rgba(248, 248, 248, 1) 55%
  );
  position: fixed;
  bottom: 0;
  width: 390px;
`
