// @flow

import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Tooltip } from 'components/common/tooltip'
import { Radio } from 'components/form/fields/radio'

import { BoxedRadioLabel } from './boxed-radio.styles'

type BoxedRadioProps = {
  checked: boolean,
  title?: string,
  description?: React.Node,
  isDisabled?: boolean,
  onChange: () => void,
  tooltip?: string,
  style?: Object,
}

export const BoxedRadio = ({
  checked,
  title,
  description,
  isDisabled,
  onChange,
  tooltip,
  style,
}: BoxedRadioProps): React.Node => {
  const labelId = React.useId()
  return (
    <Tooltip isTooltipEmpty={!tooltip} tooltip={tooltip ?? ''} placement="right" minWidth={213}>
      <BoxedRadioLabel style={{ ...style }} $isSelected={checked} $isDisabled={isDisabled}>
        <Grid template="20px 1fr" gap={0}>
          <Radio
            ariaLabelledBy={labelId}
            disabled={isDisabled}
            checked={checked}
            onChange={onChange}
            style={{ marginRight: 9 }}
          />

          <span id={labelId} className="styled-boxedradio-title">
            {title}
          </span>
        </Grid>
        <div className="styled-boxedradio-text">{description}</div>
      </BoxedRadioLabel>
    </Tooltip>
  )
}
