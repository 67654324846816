// @flow

import * as React from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'components/common/svg-icon'

import {
  BrowserName,
  ContentContainer,
  Header,
  Message,
  Notif,
  NotifContent,
  Sender,
  SmallIcon,
  Thumb,
  Title,
} from './web-win.styles'

import {
  LegacyEditableField,
  LegacyMediaField,
  type ThemeProps,
} from 'com.batch/message-builder/ui/components/common'

type WebWinProps = { variant: 'a' | 'b', ... }

export const WebWin = ({ variant }: WebWinProps): React.Node => {
  const { app } = useTheme<ThemeProps>()
  const website = React.useMemo(() => {
    const tmp = app?.bundleId.replace('http://', '').replace('https://', '').split('/')
    if (tmp) {
      return tmp[0]
    }
  }, [app?.bundleId])
  return (
    <Notif>
      <LegacyMediaField variant={variant} field="media">
        {({ url, overlay }) => <Thumb $url={url}>{overlay}</Thumb>}
      </LegacyMediaField>
      <NotifContent>
        <Header>
          <img src="/medias/icons/chrome-colored.svg" alt="Google Chrome icon" />
          <BrowserName>Google Chrome</BrowserName>
          <div>
            <Icon icon="close" />
          </div>
        </Header>
        <ContentContainer>
          <LegacyMediaField variant={variant} field="icon">
            {({ url, overlay }) => <SmallIcon $url={url}>{overlay}</SmallIcon>}
          </LegacyMediaField>
          <Title>
            <LegacyEditableField field="title" variant={variant} />
          </Title>
          <Message>
            <LegacyEditableField field="message" variant={variant} />
          </Message>
          <Sender>{website}</Sender>
        </ContentContainer>
      </NotifContent>
    </Notif>
  )
}
