// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { AppAvatar } from 'components/common/avatar'
import { Grid } from 'components/common/grid'
import { Ellipsis } from 'components/styled/text'

import { type AppRecord } from 'com.batch.redux/_records'

// ====================== THEME FORMAT SELECT
export type ThemeFormat = { label: string, value: string, ... }

export const ThemeFormatsList: List<ThemeFormat> = new Immutable.List().push(
  ...[
    { label: 'Fullscreen', value: 'universal' },
    { label: 'Banner', value: 'banner' },
    { label: 'Modal', value: 'modal' },
    { label: 'Image', value: 'image' },
    { label: 'WebView', value: 'webview' },
  ]
)

// ====================== SELECT : APP OPTION
export const AppOption = (app: AppRecord, nbThemes: number): React.Node => (
  <Grid template="20px 1fr">
    <AppAvatar
      placeholder={app.name.split(' ')[0]}
      platform={app.platform}
      url={app.icon}
      size={20}
    />
    <Ellipsis>
      {app.name} ({nbThemes})
    </Ellipsis>
  </Grid>
)
