// @flow

import Immutable, { type List, type Set, type OrderedMap } from 'immutable'
import { cloneDeep as _cloneDeep, clone as _clone, first as _first, get as _get } from 'lodash-es'
import { createSelector } from 'reselect'

import { trackEvent } from 'components/common/page-tracker'

import { legacyPromiseActionCreator, promiseActionCreator } from './actionCreator'

import {
  type Variant,
  type State,
  type AppRecord,
  type TestDeviceRecord,
  type DispatchBoundFn,
  type DispatchOnlyBoundFn,
} from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import {
  campaignConfigSelector,
  currentCampaign,
  currentCampaignAppsSelector,
} from 'com.batch.redux/campaign.selector'
import { apiCampaignSelector } from 'com.batch.redux/targeting.selector.composed'
import { PreferredTemplateCache } from 'com.batch.redux/template'
import * as api from 'com.batch.redux/testDevice.api'
import { FieldFactory, ThemeFactory } from 'com.batch.redux/theme.records'

type extract<T> = State => T
// ========================================================
// INITIAL STATE
// ========================================================
const rawDevices = _get(window, 'initialData.app.testDevices', false)
let devices: OrderedMap<number, TestDeviceRecord> = Immutable.OrderedMap()
if (rawDevices) {
  rawDevices.forEach(raw => {
    const d = api.normalizeDevice(raw)
    devices = devices.set(d.id, d)
  })
}
const initialState: OrderedMap<number, TestDeviceRecord> = devices

// ========================================================
// SELECTORS
// ========================================================
const stateSelector = (state: State) => state.testDevice

export const devicesSelector: extract<List<TestDeviceRecord>> = createSelector(
  stateSelector,
  devices => {
    return devices.toList()
  }
)
// ========================================================
// ACTIONS
// ========================================================

// ===== SAVE =============================================
type saveDeviceAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_SAVE_DEVICE',
  ...
}
type saveDeviceSuccessAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_SAVE_DEVICE_SUCCESS',
  ...
}
export type saveDeviceFailureAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_SAVE_DEVICE_FAILURE',
  ...
}
export const saveDevice = (device: TestDeviceRecord): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    const app = currentAppSelector(state)
    return legacyPromiseActionCreator({
      dispatch,
      payload: { app, device },
      promise: api.saveDevice({ app, device }),
      actionName: 'PT_SAVE_DEVICE',
    })
  }
}

type ListDevicesAction = {
  type: 'LIST_DEVICES_FOR_APPS',
  payload: null,
  ...
}
type ListDevicesSuccessAction = {
  type: 'LIST_DEVICES_FOR_APPS_SUCCESS',
  payload: List<TestDeviceRecord>,
  ...
}
type ListDevicesFailureAction = {
  type: 'LIST_DEVICES_FOR_APPS_FAILURE',
  payload: null,
  ...
}
export const listDevices =
  (apps: Set<AppRecord>): DispatchOnlyBoundFn<Promise<any>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'LIST_DEVICES_FOR_APPS',
      dispatch,
      promise: api.getTestDevicesForApps(apps),
      payload: null,
    })

// ===== TEST THEME ==========================================

export const sendTestForTheme = (device: TestDeviceRecord): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    const app = currentAppSelector(state)
    const theme = state.theme.entities.get(state.theme.editing, ThemeFactory())
    const data = state.theme.sample.data
    let formated: { [string]: any, ... } = {}
    let temp = [
      ['theme', theme.code],
      ['title', !theme.fields.get('title', FieldFactory()).hidden ? data.get('title') : null],
      ['body', !theme.fields.get('text', FieldFactory()).hidden ? data.get('text') : null],
      ['header', !theme.fields.get('header', FieldFactory()).hidden ? data.get('header') : null],
      ['image', !theme.fields.get('image', FieldFactory()).hidden ? data.get('imageUrl') : null],
      [
        'webview_url',
        !theme.fields.get('webviewUrl', FieldFactory()).hidden ? 'https://batch.com' : null,
      ],
      ['global_action', null],
    ]

    temp.forEach(kv => {
      if (kv[1]) formated[kv[0]] = kv[1]
    })
    if (!theme.fields.get('cta1', FieldFactory()).hidden) {
      formated.action1 = { label: data.mainButtonLabel }
    }
    if (!theme.fields.get('cta2', FieldFactory()).hidden) {
      formated.action2 = { label: data.secondaryButtonLabel }
    }
    return legacyPromiseActionCreator({
      dispatch,
      payload: { app, device },
      promise: api.test({
        appId: app.id,
        device,
        message: {
          body: `Open for a preview of theme ${theme.name}`,
          title: 'Batch Theme Test',
          deeplink: '',
        },
        landing: formated.theme ? formated : null,
        advanced: {
          customPayload: null,
        },
        media: {},
      }),
      actionName: 'PT_TEST_DEVICE',
    })
  }
}

const prepareCampaignForTest = ({
  apiCampaign,
  hasABTesting,
  hasLanding,
  type,
  language,
  variant,
  replaceWithTemplate,
}: {
  apiCampaign: { ... },
  hasABTesting: boolean,
  hasLanding: boolean,
  type: campaignType,
  language: string,
  variant: 'a' | 'b',
  replaceWithTemplate: string => string,
  ...
}) => {
  let message = {
    title: '',
    body: 'Here is a preview of your inapp campaign',
    deeplink: '',
  }
  // si on ne clone pas ici on altère le résultat memoizé du sélecteur de campagne, & on se tire une balle dans le pied
  if (type === 'push') {
    if (hasABTesting) {
      message = _clone(
        _first(
          _get(apiCampaign, `messages.${variant}`, []).filter(
            tr => tr.language === language || (language === 'default' && tr.language === null)
          )
        )
      )
    } else {
      message = _clone(
        _first(
          _get(apiCampaign, 'messages', []).filter(
            tr => tr.language === language || (language === 'default' && tr.language === null)
          )
        )
      )
    }
  }

  message.title = replaceWithTemplate(message.title)
  message.body = replaceWithTemplate(message.body)

  if (message.deeplink) {
    message.deeplink = replaceWithTemplate(message.deeplink)
  }

  type tempFormattedType = {
    body: string,
    title: string,
    header: string,
    image: ?string,
    trackingId: ?string,
    webview_url: ?string,
    action1?: string,
    action2?: string,
    theme?: ?string,
    global_action?: any,
    ...
  }

  let formatted: tempFormattedType
  if (type === 'in-app' || hasLanding) {
    const trad = _first(
      _get(
        apiCampaign,
        hasABTesting && type === 'in-app' ? `landing.${variant}.contents` : 'landing.contents',
        []
      ).filter(tr => tr.language === language || (language === 'default' && tr.language === null))
    )

    const actions: Array<any> = _get(trad, 'actions', [])
    formatted = {
      theme: _get(
        apiCampaign,
        hasABTesting && type === 'in-app' ? `landing.${variant}.theme` : 'landing.theme',
        null
      ),
      title: replaceWithTemplate(_get(trad, 'title', null)),
      body: replaceWithTemplate(_get(trad, 'body', null)),
      header: replaceWithTemplate(_get(trad, 'header', null)),
      image: replaceWithTemplate(_get(trad, 'image', null)),
      imageDescription: replaceWithTemplate(_get(trad, 'image_description', null)),
      trackingId: _get(trad, 'tracking_id', null),
      webview_url: replaceWithTemplate(_get(trad, 'webview_url', null)),
      link_open_target: _get(trad, 'link_open_target', null),
      action1:
        actions.length >= 1
          ? applyPersonalisationOnAction(actions[0], replaceWithTemplate)
          : undefined,
      action2:
        actions.length === 2
          ? applyPersonalisationOnAction(actions[1], replaceWithTemplate)
          : undefined,
      global_action: _get(trad, 'global_action') ? trad.global_action : undefined,
    }
  }

  let replacedMedia = _get(apiCampaign, 'media', {})
  if (replacedMedia.icon) replacedMedia.icon = replaceWithTemplate(replacedMedia.icon)
  if (replacedMedia.picture) replacedMedia.picture = replaceWithTemplate(replacedMedia.picture)
  if (replacedMedia.video) replacedMedia.video = replaceWithTemplate(replacedMedia.video)
  if (replacedMedia.audio) replacedMedia.audio = replaceWithTemplate(replacedMedia.audio)

  const rawDeeplink = _get(apiCampaign, 'deeplink', null)
  const deeplink = rawDeeplink ? replaceWithTemplate(rawDeeplink) : null
  const rawCustomPayload = _get(apiCampaign, 'custom_payload', null)
  const customPayload = rawCustomPayload ? replaceWithTemplate(rawCustomPayload) : null
  return {
    message,
    deeplink,
    media: replacedMedia,
    landing: formatted?.theme ? formatted : null,
    customPayload,
  }
}

// ===== CAMPAIGN TEST =======================================
export const sendTestForCampaign = ({
  device,
  forcedApp,
  variant,
}: {
  device: TestDeviceRecord,
  forcedApp?: AppRecord,
  variant?: Variant,
  ...
}): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    const templateOverride = PreferredTemplateCache(state)
    const app = forcedApp || currentAppSelector(state)
    const campaign = currentCampaign(state)
    const config = campaignConfigSelector(state)
    const apiCampaign = _cloneDeep(apiCampaignSelector(state))
    const variantToSend = variant ?? config.get('abtesting')
    const replaceWithTemplate = (text: string) => {
      const res = templateOverride.get(text, text)
      if (res && typeof res === 'object' && res.results && res.results.size > 0) {
        const first = res.results.get(0)
        if (first) {
          return first.result
        }
      }
      return text
    }

    const { message, media, landing, customPayload } = prepareCampaignForTest({
      apiCampaign,
      hasABTesting: campaign.abtesting.enabled,
      hasLanding: campaign.hasLanding,
      type: campaign.type,
      variant: variantToSend,
      replaceWithTemplate,
      language: config.activeLanguageId ?? 'default',
    })

    trackEvent('TEST_PUSH_SENT', { location: 'campaign form' })
    return legacyPromiseActionCreator({
      dispatch,
      payload: { app, device },
      promise: api.test({
        appId: app.id,
        device,
        message,
        media,
        advanced: {
          customPayload,
        },
        landing,
      }),
      actionName: 'PT_TEST_DEVICE',
    })
  }
}

export const sendTestCampaignForProject = (
  device: TestDeviceRecord
): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    const campaign = currentCampaign(state)
    const apps = currentCampaignAppsSelector(state)
    const config = campaignConfigSelector(state)
    const apiCampaign = _cloneDeep(apiCampaignSelector(state))
    const variant = config.get('abtesting')
    const templateOverride = PreferredTemplateCache(state)
    const replaceWithTemplate = (text: string) => {
      const res = templateOverride.get(text, text)
      if (res && typeof res === 'object' && res.results && res.results.size > 0) {
        const first = res.results.get(0)
        if (first) {
          return first.result
        }
      }
      return text
    }

    const { message, media, deeplink, landing, customPayload } = prepareCampaignForTest({
      apiCampaign,
      hasABTesting: campaign.abtesting.enabled,
      hasLanding: campaign.hasLanding,
      type: campaign.type,
      variant,
      replaceWithTemplate,
      language: config.activeLanguageId ?? 'default',
    })

    trackEvent('TEST_PUSH_SENT', { location: 'campaign form' })

    const sendTestAllApps = Promise.all(
      apps.map(app => {
        return api
          .test({
            appId: app.id,
            device,
            message,
            media,
            advanced: {
              deeplink,
              customPayload,
            },
            landing,
          })
          .then(
            res => res,
            res => res
          )
      })
    ).then(all => {
      const totalSent = all.reduce((acc, res) => acc + res.sends, 0)
      if (totalSent === 0) {
        throw new Error('No installation found')
      }
      return all
    })

    return legacyPromiseActionCreator({
      dispatch,
      payload: { device },
      promise: sendTestAllApps,
      actionName: 'PT_TEST_DEVICE',
    })
  }
}

function applyPersonalisationOnAction(action: any, replaceWithTemplate: string => string) {
  const applyPersonalisation = (obj: any) => {
    if (Array.isArray(obj)) {
      return obj.map(item => applyPersonalisation(item))
    }
    if (typeof obj === 'object' && obj !== null) {
      return Object.entries(obj).reduce((acc: { [string]: any | string | {} }, [key, value]) => {
        acc[key] = applyPersonalisation(value)
        return acc
      }, {})
    }
    if (typeof obj === 'string') {
      return replaceWithTemplate(obj)
    }
    return obj
  }

  let res = {
    ...action,
    label: replaceWithTemplate(action.label),
  }

  if (action.args !== undefined) {
    res.args = {}
    const actionEntries = Object.entries(action.args)
    for (let [key, value] of actionEntries) {
      if (key === 'actions' && Array.isArray(value)) {
        res.args.actions = value.map(item => applyPersonalisation(item))
      } else if (typeof value === 'object' && value !== null) {
        res.args[key] = applyPersonalisation(value)
      } else if (typeof value === 'string') {
        res.args[key] = replaceWithTemplate(value)
      }
    }
  }
  return res
}

// ===== SAVE =============================================
type sendTestAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_TEST_DEVICE',
  ...
}
export type sendTestSuccessAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_TEST_DEVICE_SUCCESS',
  ...
}
export type sendTestFailureAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_TEST_DEVICE_FAILURE',
  ...
}
export const sendTest = ({
  app,
  device,
  mediaPicture,
  mediaIcon,
  mediaAudio,
  mediaVideo,
  messageTitle,
  messageBody = '✋ Batch is working!',
  deeplink,
  customPayload,
  landing,
  context,
}: {
  app: AppRecord,
  device: TestDeviceRecord,
  mediaPicture?: ?string,
  mediaIcon?: ?string,
  mediaAudio?: ?string,
  mediaVideo?: ?string,
  deeplink?: ?string,
  messageTitle?: ?string,
  messageBody?: string,
  customPayload?: string,
  landing?: ?any,
  context?: string,
  ...
}): DispatchBoundFn<Promise<any>> => {
  if (!mediaPicture) {
    mediaPicture = 'https://static.batch.com/dashboard/push-test-custom-image.jpg'
  }
  if (!messageTitle || typeof messageTitle !== 'string') {
    messageTitle = `Hello ${device.name ? device.name : 'stranger'}`
  }

  trackEvent('TEST_PUSH_SENT', { location: context })

  return dispatch =>
    promiseActionCreator({
      dispatch,
      payload: { app, device },
      promise: api.test({
        appId: app.id,
        device,
        message: {
          title: messageTitle,
          body: messageBody,
        },
        media: {
          picture: mediaPicture,
          icon: mediaIcon,
          audio: mediaAudio,
          video: mediaVideo,
        },
        advanced: {
          deeplink,
          customPayload,
        },
        landing,
      }),
      actionName: 'PT_TEST_DEVICE',
    })
}
// ===== DELETE ============================================
type deleteDeviceAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_DELETE_DEVICE',
  ...
}
type deleteDeviceSuccessAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_DELETE_DEVICE_SUCCESS',
  ...
}
export type deleteTokenFailureAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_DELETE_DEVICE_FAILURE',
  ...
}
export const deleteDevice = (device: TestDeviceRecord): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    return legacyPromiseActionCreator({
      dispatch,
      payload: { device },
      promise: api.deleteDevice({ device, app: currentAppSelector(state) }),
      actionName: 'PT_DELETE_DEVICE',
    })
  }
}

// ===== UPDATE =============================================
type updateDeviceAction = {
  payload: { device: TestDeviceRecord, ... },
  type: 'PT_UPDATE_DEVICE',
  ...
}

export const updateDevice = function (device: TestDeviceRecord): updateDeviceAction {
  return {
    payload: { device },
    type: 'PT_UPDATE_DEVICE',
  }
}

type fetchTokensSuccessAction = {
  type: 'PT_FETCH_DEVICES_SUCCESS',
  payload: List<TestDeviceRecord>,
  ...
}

type pushDeviceActions =
  | updateDeviceAction
  | deleteDeviceAction
  | deleteDeviceSuccessAction
  | deleteTokenFailureAction
  | saveDeviceAction
  | saveDeviceSuccessAction
  | saveDeviceFailureAction
  | sendTestAction
  | sendTestSuccessAction
  | sendTestFailureAction
  | fetchTokensSuccessAction
  | ListDevicesAction
  | ListDevicesSuccessAction
  | ListDevicesFailureAction

// ========================================================
// REDUCER
// ========================================================
export default function pushtokenReducer(
  state: OrderedMap<number, TestDeviceRecord> = initialState,
  action: pushDeviceActions
): OrderedMap<number, TestDeviceRecord> {
  switch (action.type) {
    case 'PT_DELETE_DEVICE_SUCCESS':
      return state.remove(action.payload.device.id)
    case 'PT_SAVE_DEVICE_SUCCESS':
      // $FlowFixMe reported on Flow Discord, no idea what we need
      return state.set<number, TestDeviceRecord>(
        (action.payload.device.id: number),
        (action.payload.device: TestDeviceRecord)
      )
    case 'LIST_DEVICES_FOR_APPS_SUCCESS':
    case 'PT_FETCH_DEVICES_SUCCESS': {
      let tokens: OrderedMap<number, TestDeviceRecord> = Immutable.OrderedMap()
      action.payload.forEach(token => {
        tokens = tokens.set(token.id, token)
      })
      return state.merge<number, TestDeviceRecord>(tokens)
    }
    default:
      return state
  }
}
