// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type DeleteAudienceListAction = ReduxAction<'DELETE_AUDIENCE', string>

export type DeleteAudienceListSuccessAction = ReduxAction<'DELETE_AUDIENCE_SUCCESS', string>
export type DeleteAudienceListFailureAction = ReduxAction<
  'DELETE_AUDIENCE_FAILURE',
  { error: string, name: string, ... },
>

export type DeleteAudiencesActions =
  | DeleteAudienceListAction
  | DeleteAudienceListSuccessAction
  | DeleteAudienceListFailureAction

export const deleteAudience = ({
  name,
}: {
  name: string,
  ...
}): DispatchExtraBoundFn<Promise<any>> => {
  return async (dispatch, getState, { audienceServiceApi }): Promise<any> => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: audienceServiceApi.deleteAudience({ projectKey: project.projectKey, name }).then(
        () => name,
        error => {
          throw { name, error }
        }
      ),
      payload: name,
      actionName: 'DELETE_AUDIENCE',
    })
  }
}
