// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { DateRangePicker } from 'components/form'
import {
  type allTimeRangeShortcutType,
  type dateShortcut,
} from 'components/form/fields/date-picker/date-picker-shortcuts'

import { type DateRange, dayjs } from 'com.batch.common/dayjs.custom'

import { type DateRangeFilterRecord } from 'com.batch.redux/_records'

type DateRangerContentFilterProps = {
  selectedDateRange?: DateRangeFilterRecord | null,
  onChange?: (?DateRange) => void,
  onClose?: (?DateRange) => void,
  isEmbedded: boolean,
  shortcuts?: List<dateShortcut | allTimeRangeShortcutType>,
  ...
}

export const DateRangerContentFilter = ({
  selectedDateRange,
  onChange,
  onClose,
  isEmbedded,
  shortcuts,
}: DateRangerContentFilterProps): React.Node => {
  const onDisabledDays = React.useCallback(
    (day: Date) => dayjs().subtract(1, 'day').isBefore(day, 'day'),
    []
  )
  return (
    <DateRangePicker
      changePlaceholderOnFocus={true}
      shortcuts={shortcuts && !isEmbedded ? shortcuts : undefined}
      hasInputs={true} // to replace by isEmbedded when the new filters select are ready
      style={{ zIndex: 14 }}
      dropdown={false}
      icon="calendar"
      range={selectedDateRange}
      placeholder="Any time"
      setRange={onChange}
      onClose={onClose}
      disabledDays={onDisabledDays}
      singleDayRange={true}
    />
  )
}
