// @flow

import { textUsesTemplating } from 'com.batch.common/utils'

import { fetchTemplates } from './fetch-templates'
import { type UpdateAllContentAction } from './update-all-content'

import { type DispatchBoundFn } from 'com.batch.redux/_records'

export const triggerTemplateEvaluation = (): DispatchBoundFn<UpdateAllContentAction> => {
  return (dispatch, getState) => {
    const state = getState()
    let { email, sms, push } = state.message

    const contentsToTemplate = []

    email.forEach(({ localizedContent }, messageId) =>
      localizedContent.forEach((content, lang) => {
        if (textUsesTemplating(content.subject)) {
          contentsToTemplate.push({
            messageId,
            lang,
            field: 'subject',
            value: content.subject ?? '',
            type: 'TEMPLATE',
          })
        }
        if (textUsesTemplating(content.html)) {
          contentsToTemplate.push({
            messageId,
            lang,
            field: 'html',
            value: content.html ?? '',
            type: 'HTML_TEMPLATE',
          })
        }
        if (textUsesTemplating(content.html)) {
          contentsToTemplate.push({
            messageId,
            lang,
            field: 'replyTo',
            value: content.replyTo ?? '',
            type: 'TEMPLATE',
          })
        }
      })
    )
    sms.forEach(({ localizedContent }, messageId) =>
      localizedContent.forEach((content, lang) => {
        if (textUsesTemplating(content.smsMessage)) {
          contentsToTemplate.push({
            messageId,
            lang,
            field: 'smsMessage',
            value: content.smsMessage,
            type: 'TEMPLATE',
          })
        }
      })
    )
    push.forEach(({ localizedContent }, messageId) => {
      localizedContent.forEach(({ content }, lang) => {
        if (textUsesTemplating(content.pushTitle)) {
          contentsToTemplate.push({
            messageId,
            lang,
            parent: 'content',
            field: 'pushTitle',
            value: content.pushTitle ?? '',
            type: 'TEMPLATE',
          })
        }
        if (textUsesTemplating(content.pushBody)) {
          contentsToTemplate.push({
            messageId,
            lang,
            parent: 'content',
            field: 'pushBody',
            value: content.pushBody ?? '',
            type: 'TEMPLATE',
          })
        }
        if (textUsesTemplating(content.pushPicture)) {
          contentsToTemplate.push({
            messageId,
            lang,
            parent: 'content',
            field: 'pushPicture',
            value: content.pushPicture ?? '',
            type: 'TEMPLATE',
          })
        }
        if (textUsesTemplating(content.pushIcon)) {
          contentsToTemplate.push({
            messageId,
            lang,
            parent: 'content',
            field: 'pushIcon',
            value: content.pushIcon ?? '',
            type: 'TEMPLATE',
          })
        }
      })
    })

    if (contentsToTemplate.length === 0)
      return dispatch({
        type: 'UPDATE_ALL_CONTENT',
        payload: { email, sms, push },
      })

    return dispatch(fetchTemplates(contentsToTemplate))
      .then(res => {
        res.forEach(({ messageId, lang, parent, field, template, value }) => {
          const matchEmail = email.find((_, emailId) => emailId === messageId)
          const matchSms = sms.find((_, smsId) => smsId === messageId)
          if (matchEmail) {
            email = email.setIn(
              [messageId, 'localizedContent', lang, 'templates', field],
              template ?? value
            )
          } else if (matchSms) {
            sms = sms.setIn(
              [messageId, 'localizedContent', lang, 'templates', field],
              template ?? value
            )
          } else {
            push = push.updateIn([messageId, 'localizedContent', lang, parent], content => {
              if (content) return content.setIn(['templates', field], template ?? value)
            })
          }
        })

        dispatch({
          type: 'UPDATE_ALL_CONTENT',
          payload: { email, sms, push },
        })
      })
      .catch(e => {
        console.error(
          'Error while fetching templates:\n',
          e.error?.errors?.[0]?.message ?? 'Unknown error'
        )
      })
  }
}
