// @flow

import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical'

export const lexicalInitState =
  (value: string): function =>
  () => {
    const root = $getRoot().setDirection(null).setFormat('').setIndent(0)
    const paragraphNode = $createParagraphNode().setDirection(null).setIndent(0).setFormat('')
    const textNode = $createTextNode(value).setMode('normal').setDetail(0).setStyle('').setFormat(0)
    paragraphNode.append(textNode)
    root.append(paragraphNode)
  }
