// @flow
import * as React from 'react'

import { useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Well } from 'components/styled/blocs'

import { type AppRecord, type TestDeviceRecord, TestDeviceFactory } from 'com.batch.redux/_records'
import { infos, integrateDone } from 'com.batch.redux/app.api'
import * as tokenApi from 'com.batch.redux/testDevice.api'

type IntegrationCheckerProps = {
  app: AppRecord,
  valid: boolean,
}
type Step = 'start' | 'token' | 'send' | 'receive'

export const IntegrationChecker = ({ app, valid }: IntegrationCheckerProps): React.Node => {
  const [step, setStep] = React.useState<Step>('start')
  const loadingState = useToggle()
  const [device, setDevice] = React.useState<{ device: string, brand: string, region: string }>({
    device: '',
    brand: '',
    region: '',
  })
  const [testDevice, setTestDevice] = React.useState<?TestDeviceRecord>(null)
  const [stringDateTokenSeen, setStringDateTokenSeen] = React.useState('')
  const [error, setError] = React.useState('')

  const onCheckStart = React.useCallback(() => {
    loadingState.open()
    infos(app).then(
      response => {
        const startPassed = response.start.ok
        const tokenPassed = response.token.exist
        const token = response.origin.last_push_token.token
        loadingState.close()
        setStep(startPassed ? (tokenPassed && token !== '' ? 'send' : 'token') : 'start')
        setStringDateTokenSeen(
          tokenPassed
            ? response.token.date.duration
            : startPassed
              ? response.start.date.duration
              : ''
        )
        setDevice(
          tokenPassed
            ? response.token.device
            : startPassed
              ? response.start.device
              : { device: '', brand: '', region: '' }
        )
        setError(
          startPassed
            ? tokenPassed
              ? undefined
              : 'No push token found for this install.'
            : `Hmm... Unable to find a start event from ${app.name}. This might take a few minutes.`
        )
        setTestDevice(
          tokenPassed
            ? TestDeviceFactory({
                value: 'INTEGRATION_TOKEN',
                kind: 'token',
                distribution: !!response.token.distribution,
              })
            : undefined
        )
      },
      response => {
        console.log(response)
        loadingState.close()
      }
    )
  }, [app, loadingState])
  const onDone = React.useCallback(() => {
    loadingState.open()
    integrateDone(app).then(
      () =>
        (window.location.href = '/{companyId}/apps/{appId}'
          .replace('{companyId}', app.companyId?.toString() ?? '')
          .replace('{appId}', app.id?.toString() ?? '')),
      loadingState.close
    )
  }, [app, loadingState])
  const onTestDevice = React.useCallback(() => {
    loadingState.open()
    if (testDevice) {
      tokenApi
        .test({
          appId: app.id,
          device: testDevice,
          message: { body: '✋ Batch is working!', title: 'Hello stranger!' },
          media: { picture: 'https://static.batch.com/dashboard/push-test-custom-image.jpg' },
          advanced: false,
          landing: false,
        })
        .then(
          res => {
            console.log(res)
            loadingState.close()
            setStep('receive')
            setError(undefined)
          },
          err => {
            console.log(err)
            loadingState.close()
            setError(err.error)
          }
        )
    }
  }, [app.id, loadingState, testDevice])
  const onReset = React.useCallback(() => {
    setDevice({ device: '', brand: '', region: '' })
  }, [])
  const classes = React.useMemo(() => {
    return [
      `pb__step ${step !== 'start' ? 'pb__step--done' : ''}`,
      `pb__step ${step === 'send' || step === 'receive' ? 'pb__step--done' : ''}`,
      `pb__step ${step === 'receive' ? 'pb__step--done' : ''}`,
    ]
  }, [step])

  const stepData = React.useMemo(() => {
    const iOS = app.platform === 'ios'

    switch (step) {
      case 'start':
        return error ? (
          <div>
            <p className="text-danger">{error}</p>
            <br />
            <Button
              isLoading={loadingState.value}
              onClick={onCheckStart}
              intent="action"
              kind="primary"
            >
              Retry
            </Button>
          </div>
        ) : (
          <div>
            {app.platform === 'webpush' ? (
              <p>
                Ok, lets make sure you visited the website at least once and subscribed to push
                notification.
                <br />
                Then cross your fingers and press the button.
              </p>
            ) : (
              <p>
                Ok, lets make sure you have launched the app on your phone at least once
                {app.platform === 'ios' ? ' and accepted push notification' : ''}.<br />
                Then lock the phone, cross your fingers and press the button.
              </p>
            )}
            <br />
            <Button
              kind="primary"
              isLoading={loadingState.value}
              onClick={onCheckStart}
              intent="action"
              disabled={!valid}
            >
              I am ready
            </Button>
          </div>
        )

      case 'token':
      case 'send':
        return (
          <div>
            <p>
              We got a start
              {app.platform === 'webpush' ? '' : ' for '}
              {iOS ? (
                <span>{device?.device?.toUpperCase() ?? 'unknown device'}</span>
              ) : (
                <span>
                  {device.brand && <strong>{device.brand.toUpperCase()}</strong>}
                  {device.device ? `(${device?.device?.toUpperCase()}` : ''}
                </span>
              )}
              , connecting from{' '}
              {device.region && (
                <img
                  src={`/medias/img/flags/${device.region.toLowerCase()}.png`}
                  style={{ margin: '-2px 4px 0 4px' }}
                  alt={`Flag of ${device.region}`}
                />
              )}
              <strong>{stringDateTokenSeen}</strong>
            </p>
            <br />
            {error && step === 'start' ? (
              <div>
                <p className="text-danger">
                  {error}
                  <br />
                </p>
                <p>
                  <Button onClick={onCheckStart} kind="primary" intent="action">
                    Try again
                  </Button>
                </p>
              </div>
            ) : (
              <div>
                {error && <p className="text-danger">{error}</p>}
                <br />
                <p>
                  {!error && <Button onClick={onCheckStart}>Not me, look again</Button>}
                  <Button
                    onClick={error ? onCheckStart : onTestDevice}
                    kind="primary"
                    intent="action"
                    isLoading={loadingState.value}
                  >
                    {error ? 'Try again' : 'Yes, that would be me!'}
                  </Button>
                </p>
              </div>
            )}
          </div>
        )
      case 'receive':
        return (
          <div>
            <p>
              Message sent! Did you get it ?<br /> (Note that delivery might take up to a minute in
              poor networking conditions).
            </p>
            <br />
            <Button onClick={onReset} isLoading={loadingState.value}>
              No, I'd like to restart
            </Button>
            <Button onClick={onDone} kind="primary" intent="action">
              Yes! I am done
            </Button>
          </div>
        )
    }
  }, [
    app.platform,
    device.brand,
    device.device,
    device.region,
    error,
    loadingState.value,
    onCheckStart,
    onTestDevice,
    onDone,
    step,
    stringDateTokenSeen,
    onReset,
    valid,
  ])
  return (
    <div>
      <ul className="pb">
        <li className={classes[0]}>
          <span className="pb__step__label">Start detected</span>
        </li>
        <li className={classes[1]}>
          <span className="pb__step__label">Token detected</span>
        </li>
        <li className={classes[2]}>
          <span className="pb__step__label">Successfully sent</span>
        </li>
        <li className="pb__step">
          <span className="pb__step__label">Notification received</span>
        </li>
      </ul>

      <Well style={{ marginTop: '-40px' }}>{stepData}</Well>
    </div>
  )
}
