// @flow

import Immutable from 'immutable'
import * as React from 'react'

import { Select } from 'components/form'

import { type AttributeRecord } from 'com.batch.redux/_records'

type ConditionFunctionProps = {
  functions: Array<QBFunction>,
  active: ?QBFunction,
  attribute: AttributeRecord,
  canUseEventCountPeriod: boolean,
  onFunctionChanged: (func: QBFunction) => void,
  ...
}

export const ConditionFunction = ({
  functions,
  active,
  attribute,
  canUseEventCountPeriod,
  onFunctionChanged,
}: ConditionFunctionProps): React.Node => {
  const functionsList = React.useMemo(
    () =>
      new Immutable.List().push(
        ...[
          ...functions,
          ...(attribute.type === 'DATE'
            ? [
                {
                  $api: '$exists',
                  id: '$exists',
                  label: 'Has any value',
                  value: 'exists',
                },
              ]
            : []),
        ]
      ),
    [attribute.type, functions]
  )
  const selectedFunction = React.useMemo(() => {
    if (!active) {
      return attribute.type === 'DATE' ? functionsList.find(f => f.value === 'exists') : null
    }
    return active
  }, [active, attribute.type, functionsList])

  if (functions.length < 2) {
    return null
  } else {
    let classes = ['condi__builder__func']
    if (attribute.id.substr(0, 2) === 'e.') {
      classes.push('condi__builder__func--short')
      functions = functions.filter(f => f.value !== 'countSince' || canUseEventCountPeriod)
    }

    return (
      <div className={classes.join(' ')}>
        <Select
          isSearchable={false}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={maybeFunc => {
            if (maybeFunc) {
              console.log(maybeFunc)
              onFunctionChanged(maybeFunc)
            } else {
              console.log('ioo')
            }
          }}
          // eslint-disable-next-line react/jsx-no-bind
          optionToString={func => func?.label ?? ''}
          options={functionsList}
          value={selectedFunction}
        />
      </div>
    )
  }
}
