// @flow

import { type FetchProfilesDistributionResponse } from 'com.batch/profilebase/infra/ucp-data-service.interface'
import {
  type ProfilesDistributionRecord,
  ProfilesDistributionFactory,
} from 'com.batch/profilebase/models/profiles-distribution.records'

export const parseProfilesDistributionResponse = ({
  profiles,
  profilesWithEmail,
  profilesWithPhoneNumber,
  profilesWithInstall,
  profilesWithCustomId,
  profilesWithMobileInstall,
  profilesWithWebpushInstall,
  orphanProfiles,
}: FetchProfilesDistributionResponse): ProfilesDistributionRecord =>
  ProfilesDistributionFactory({
    total: parseInt(profiles) || 0,
    email: parseInt(profilesWithEmail) || 0,
    phoneNumber: parseInt(profilesWithPhoneNumber) || 0,
    install: parseInt(profilesWithInstall) || 0,
    customId: parseInt(profilesWithCustomId) || 0,
    push: parseInt(profilesWithMobileInstall) || 0,
    webpush: parseInt(profilesWithWebpushInstall) || 0,
    orphans: parseInt(orphanProfiles) || 0,
  })
