// @flow
import { MOCKUP } from 'com.batch/profile/constants/platform-mockup-rules'

export const getPlatformMockup = (deviceName: string): string => {
  const device: string = deviceName.toUpperCase()

  // ETAPE 1 : On check (via la regexp) dans le listing de nos mockup, si on en veut un spécifique
  const mockupRulesFound = MOCKUP.find(f => device.match(f.regExp))

  // ETAPE 2 : On veut un mockup spécifique
  if (mockupRulesFound) {
    // Récupération du model du device
    const getModel = device.match(mockupRulesFound.regExp)
    const model = getModel ? getModel[0] : ''

    // Récupération de la version du desvice, par default 0
    const version = parseInt(model.replace(/[^0-9]/g, ''), 10)

    // ETAPE 2.1 : On regarde si il fait partie des exceptions
    // Permet de surcharger la régle principal
    const findException = mockupRulesFound.exceptions.reduce((accumulator, excpt) => {
      // les exceptions sont un tableaux de string qui nous permet de différencier un model spécifique
      // par exemple ['14', 'pro']
      const hasException = excpt.nameIncludes.every(w =>
        device.toUpperCase().includes(w.toUpperCase())
      )

      return hasException ? excpt.mockup : accumulator
    }, null)
    if (findException) return findException

    // ETAPE 2.2 : On applique la régle principale défini dans l'object rule
    return mockupRulesFound.rule.find(f => version >= f.from)?.mockup ?? mockupRulesFound.default
  }

  return '/medias/img/mockup/mobile/default.svg'
}
